import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/musicDealers?include=tagSystem`, {
  ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)