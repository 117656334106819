import React from "react";
import { Link } from "react-router-dom";
import { Drawer, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { getStoredUser, getUseTools, getChannelCanLicense, channelPathGenerator, addHttpsToUrl, rootPath, getTitleByLang, getMainTranslation, slugify, getActiveSection } from "../../../api-client/core/authentication/utils";
import {get} from "lodash";
import {request} from "../../../api-client";
import "./NavBar.css";
import "layouts/Home/Home.css";
import localization from "../../../config/localization";
import SearchByTags from "./SearchByTags";
import { getSocialLinkIcon } from "../../../components/Letflow/SocialLinks";
import Logo from "../../../assets/img/logo-black.png";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {makeFitImageUrl} from "../../../utils";
import browserHistory from "../../../utils/browserHistory";
import DefaultAvatar from "../../../assets/img/default-avatar.png";


class MobileSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [],
      selectedTags: [],
      openEditDialog: false,
    }
  }
  
  componentDidMount = () => {
    if (this.props.channel.navbarContentCreatorType) {
      this.fetchTagGroups()
        .then(this.requestOnlyAvailableTags)
    }
  }

  fetchTagGroups = () =>  {
    const { channel } = this.props;
    const content = channel.navbar_search_type === "material" ? "material" : "content"
    let paginationConfig = {};
    
    if (channel.navbar_search_type === "material") {
        paginationConfig = { filterBy: [
            { column: 'content_creations', filter: true},
            { column: 'inheritable', filter: "true"},
            { column: 'show_on_smart_search', filter: '1'},
            { column: 'type', filter: `${channel.navbarContentCreatorType.slug}`},
            { column: 'channel_id', filter: `${channel.id}`}
        ]};

    } else {
        paginationConfig = { filterBy:  [
            { column: 'type', filter: `${channel.navbarContentCreatorType.slug}`},
            { column: 'creation_group', filter: '0'},
            { column: 'show_on_smart_search', filter: '1'},
            { column: 'channel_id', filter: `${channel.id}`}
          ]};
    }
    
    
    return request.tagGroup.getAll(paginationConfig, content)
      .then(tagGroups => {
        let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
        
        if(this.props.channel.tagGroups.length) {
          this.props.channel.tagGroups.filter(tg => tg.target === content)
            .forEach(channelTg => {
              availableTagGroups.forEach(tg => {
                if (tg.id === channelTg.id) {
                  tg.order = channelTg.order
                  tg.view_type = channelTg.view_type
                }
              })
            })
        }
      
        this.setState({ tagGroups: availableTagGroups })
      })
  }

  checkPermissions = (entity) => this.props.channel.client.permissions.some(permission => permission.entity === entity);

  makeSidebarButton = (element, className = "sidebar-link") => {
    switch (element.data_source) {
      case "my_list":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={'#'}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                  this.props.onClose()
                  this.validateLoginRedirect(channelPathGenerator('mi-lista'))
                }}>{getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
        case "playlist_link":
          return(
            <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={'#'}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                  this.props.onClose()
                  this.validateLoginRedirect(channelPathGenerator('playlists'))
                }}>{getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
      case "link":
        const domain = window.location.hostname.replace('www.','');
        let isSameDomain = element.metadata.link.startsWith(`${window.location.protocol}//${window.location.hostname}`) || element.metadata.link.startsWith(`${window.location.protocol}//${domain}`);

        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`} >
              <Link
                to={isSameDomain ? channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')) : addHttpsToUrl(element.metadata.link)}
                rel="nofollow"
                onClick={e => {
                  e.preventDefault()
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                  if (!element.new_tab) {
                    if (!isSameDomain) {
                      window.location.href = addHttpsToUrl(element.metadata.link)
                    }else {
                      browserHistory.push(channelPathGenerator(element.metadata.link.split('//')[1].split('/').slice(1).join('/')))
                    }
                  } else {
                    window.open(addHttpsToUrl(element.metadata.link), '_blank').focus();
                  }
                }}>{getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
      case "section":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`} >
              <Link
                to={rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`)}
                onClick={e => {
                  e.preventDefault()
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  this.props.onClose();
                  browserHistory.push(rootPath(`/home/${element.metadata.target_section_id}-${slugify(element.metadata.target_section_name).replace(/-/g, '_')}`))
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
            </div>
          </div>)
      case "contact_form":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                // to={channelPathGenerator(`contacto`)}
                to={"#"}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title", true)})
                  this.setState({sidebar: false, showContactDialog: true})
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}>
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
            </div>
          </div>)
      case "home":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={rootPath()}
                onClick={(e) => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  if(getActiveSection()) {
                    e.preventDefault();
                    browserHistory.push(rootPath())
                  }

                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >{getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
      case "content_creator":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
      case "catalog" :
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}</Link>
            </div>
          </div>)
      case "video_content_creation":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
            </div>
          </div>)
      case "single_content_creator":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
          <Link
            to={channelPathGenerator(`contenido/${element.content_creator_id}-${slugify(element.content_creator_name)}?origin=${encodeURIComponent(getMainTranslation(element, "title"))}`)}
            onClick={e => {
              analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
              this.props.onClose()
              // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
            }}
          >
            {getTitleByLang(element.translations, this.state.language, element.title)}
          </Link>
            </div>
          </div>)
      case "smart_search":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={channelPathGenerator(`busqueda-inteligente`)}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
            </div>
          </div>)
      case "channel_playlist":
        return(
          <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to={channelPathGenerator(`browse/${element.id}-${slugify(element.title)}`)}
                onClick={() => {
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  this.props.onClose()
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
            </div>
          </div>)
      case "drop_down":
        return(
          <div className="channel-sidebar-link-container"  style={{paddingBottom: 5}}>
            <div className={`${className} show-link-mobile`}>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  analyticsInstance.menu({click_on: getMainTranslation(element, "title")})
                  let parent = e.target.parentNode.querySelector('.drop-down-selector')
                  if(parent) {
                    e.target.parentNode.classList.contains("drop-down-selected") ?  e.target.parentNode.classList.remove("drop-down-selected") :  e.target.parentNode.className += " drop-down-selected"
                    parent.classList.contains("drop-down-selector-hidden") ? parent.classList.remove("drop-down-selector-hidden") : parent.className += " drop-down-selector-hidden"
                  }
                  // GAevent('Search', "Navbar", element.translations.filter(element=> element.locale == "es").title || element.title)
                }}
              >
                {getTitleByLang(element.translations, this.state.language, element.title)}
              </Link>
              <div className={"drop-down-selector drop-down-selector-hidden"}>
                {this.props.navbarElements
                  .filter(subElement => !!subElement.parent && subElement.parent == element.id)
                  .map(subElement =>
                    this.makeSidebarButton(subElement, "sidebar-link")
                  )
                }
              </div>
            </div>
          </div>
        )
    }
  }

  requestOnlyAvailableTags = ({
    clientId = this.props.channel.client.id,
    searchGroup = this.props.channel.navbar_search_type === "material" ? "contentCreation" : "contentCreator",
    type = this.props.channel.navbarContentCreatorType.slug,
    lastTagId = null,
    channelId = this.props.channel ? this.props.channel.id : null
  } = {}) =>
    request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      contentCreatorType: type,
      tags: this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id),
      notTags: [],
      lastTagId,
      channelId
    })
    .then(availableTags => {
      const mappedAvailableTags = availableTags.map(x => x.id)

      return this.setState({
        tagGroups: this.state.tagGroups.map(tgs => ({
          ...tgs,
          tags: tgs.tags.map(tag => ({
            ...tag,
            state: ['selected'].includes(tag.state) ? tag.state : (mappedAvailableTags.includes(tag.id) ? 'available' : 'disable')
          }))
        })) 
      })
    })

  render() {
   const {open, onClose, channelCreator, navbarElements, availableLanguages, language, channel, changeLang} = this.props;
   const imageLogo = get(channel, "sidebarImage", channel.image);


	return (
		<Drawer
			id="navbar-drawer"
			PaperProps={{ style: { backgroundColor: "var(--sidebar-background-color, rgb(247, 247, 247))", borderTopRightRadius: 10, borderBottomRightRadius: 10 } }}
			open={open}
			onClose={onClose}
		>
			<div className="sidebar">
        <div className="sidebar-top-container">
          <div className="sidebar-idiom-container">
            {availableLanguages.length > 1 &&
            <>
              {availableLanguages.map(lang => {
                return (
                  <div
                    style={{
                      fontWeight: language === lang ? 'bold' : 'normal',
                      cursor: 'pointer',
                      float: "right",
                      position: "relative",
                      marginRight: "20px",
                      color: "var(--sidebar-font-color, black)"
                    }}
                    onClick={() => changeLang(lang)}
                  >
                    {lang.toUpperCase()}
                  </div>
                )
              })}
            </>}
          </div>
          <IconButton onClick={onClose} style={{ top: 10 }}>
						<Close style={{color: "var(--sidebar-font-color, black)"}}/>
					</IconButton>
				</div>

				<div className="sidebar-container">
					{getStoredUser() ?
					  <div>
              <div className="sidebar-profile-image" onClick={this.props.openProfileDialog} style={getStoredUser().image ? {backgroundImage: `url(${request.file.image.makeCroppedUrl(getStoredUser().image.id, 200, 200)})`} : {backgroundImage: `url(${DefaultAvatar})`}}/>
              <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                <Link className="sidebar-user-name" onClick={onClose} to={channel.community ? channelPathGenerator(`profile`) : channelPathGenerator(`mi_perfil`)}>
                  {getStoredUser().name}
                </Link>
                <br />
                {!!getStoredUser() &&
                  <Link
                    to={'#'}
                    style={{
                      fontWeight:'normal',
                      cursor: 'pointer',
                      color: "var(--sidebar-font-color, black)",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => request.authentication.logout()}
                  >
                    {localization.get("navbar.logout")}
                  </Link>
                }
              </div>
						</div>
            : channel.show_login_button ?
              <Link
                style={{
                fontWeight: 'normal',
                marginBottom: 20,
                fontSize: 20,
                cursor: 'pointer',
                color: "var(--sidebar-font-color, black)",
                display: "flex",
                justifyContent: "center",
              }}
                to={channelPathGenerator(`login`)}>
                {channel ? channel.login : "Login"}
              </Link>
            : null
					}
          <div style={{marginTop: 10}}>        
          {channel.navbarContentCreatorType ?
            <SearchByTags
              onClose={() => this.props.onClose()}
              tagGroups={this.state.tagGroups}
              handleSelectedTagsChanged={(selectedTags) => {
                this.setState(state => ({
                  ...state,
                  tagGroups: state.tagGroups.map(tgs => ({
                    ...tgs,
                    tags: tgs.tags.map(tag => ({
                      ...tag,
                      state: selectedTags.includes(tag.id) ? 'selected' : "available"
                    }))
                  }))
                }), () => this.requestOnlyAvailableTags({}))
              }}
            />
            :
            <>
              {!channel.navbar_tabs && navbarElements
                .filter(element => !element.parent)
                .map(element => this.makeSidebarButton(element, 'sidebar-link'))
              }

              {!!(channel.use_brand_button && get(getStoredUser(), "brand.link")) && 
                <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                  <div className={`sidebar-link show-link-mobile`}>
                    <Link
                      to={addHttpsToUrl(get(getStoredUser(), "brand.link"))}
                      rel="nofollow"
                      target="_blank"
                    >
                      {get(getStoredUser(), "brand.button_name")}
                    </Link>
                  </div>
                </div>
              }
              {!!getStoredUser() && channel && channel.community &&
                <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                  <div className={`sidebar-link show-link-mobile`}>
                    <Link
                      onClick={() => this.props.onClose()}
                      to={channelPathGenerator(`profile`)}>{localization.get("navbar.my_profile")}</Link>
                  </div>
                </div>
              }
              {!!getStoredUser() && channel && channel.paymentGateways && channel.paymentGateways.length > 0 &&
                <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                  <div className={`sidebar-link show-link-mobile`}>
                    <Link
                      onClick={() =>this.props.onClose()}
                      to={channelPathGenerator(`mis-compras`)}>{localization.get("title.my_purchases")}</Link>
                  </div>
                </div>
              }

              {channel && channel.community && !channelCreator && !getStoredUser() &&
                <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                  <div className={`sidebar-link show-link-mobile`}>
                    <Link
                      onClick={() =>this.props.onClose()}
                      to={channelPathGenerator(`profile`)}>
                        {localization.get("navbar.profile.create_button")}
                    </Link>
                  </div>
                </div>
              }
            
              {!!getUseTools() &&
                <>
                  <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                    <div className={`sidebar-link `}>
                      <Link
                        onClick={() =>this.props.onClose()}
                        to={channelPathGenerator(`playlists`)}>{localization.get("navbar.playlists")}</Link>
                    </div>
                  </div>
                  {this.checkPermissions('pitch') &&
                    <div className="sidebar-link-container" style={{paddingBottom: 5}}>
                      <div className={`sidebar-link `}>
                        <Link
                          onClick={() => this.props.onClose()}
                          to={channelPathGenerator(`projects`)}>{localization.get("navbar.projects")}</Link>
                      </div>
                    </div>
                  }
                  {this.checkPermissions('reel') &&
                    <div className="sidebar-link-container" style={{paddingBottom: 5}}>
                      <div className={`sidebar-link `}>
                        <Link
                          onClick={() => this.props.onClose()}
                          to={channelPathGenerator(`reels`)}>{localization.get("navbar.reels")}</Link>
                      </div>
                    </div>
                  }
                  {this.checkPermissions('client_file') &&
                    <div className="sidebar-link-container" style={{paddingBottom: 5}}>
                      <div className={`sidebar-link `}>
                        <Link
                          onClick={() => this.props.onClose()}
                          to={channelPathGenerator(`drive`)}>{localization.get("drive")}
                        </Link>
                      </div>
                    </div>
                  }
                  {getChannelCanLicense() && this.checkPermissions('music') &&
                    <div className="sidebar-link-container"  style={{paddingBottom: 5}}>
                      <div className={`sidebar-link `}>
                        <Link
                          onClick={() =>this.props.onClose()}
                          to={channelPathGenerator(`account/licenses`)}>{localization.get("licenses")}</Link>
                      </div>
                    </div>
                  }
                </>
              }
            </>
            }
          </div>
				</div>
        <div className="sidebar-footer">
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap:"wrap"}}>
              {channel.socialLinks.map(socialLink => 
                <Link className="footer-social-link"
                  onClick={() => analyticsInstance.footerInteraction({type: "Social_Network"})}
                  style={{display: 'flex', alignItems: 'center'}}
                  target="_blank"
                  to={addHttpsToUrl(socialLink.url)}
                  rel="nofollow">
                  {getSocialLinkIcon(socialLink.type, "var(--sidebar-font-color, black)")}
                </Link>
              )}
            </div>
            {imageLogo && 
              <div style={{position: "relative", float: "left", maxWidth: "100%"}}>
                <img 
                  style={{ maxWidth: "100%" }}
                  src={imageLogo ? makeFitImageUrl({image: imageLogo}, 250, 40, '', 'png') : Logo}
                  alt={`Logo - ${channel.name}`} 
                />
              </div>
            }
          </div>
			</div>
		</Drawer>
	);
} 
}

export default MobileSidebar;