import React from 'react'
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/lib/Async";

class CatalogSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      catalog: [{value: 0, label: localization.get('all')}],
    }
  }

  componentDidMount = () => {
    let selectedCatalogFromLocalStorage = JSON.parse(localStorage.getItem("selectedCatalog"));
    if (selectedCatalogFromLocalStorage) {
      this.setState({catalog: selectedCatalogFromLocalStorage});
      this.props.onChange(selectedCatalogFromLocalStorage)
    }
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({catalog: nextProps.catalog || JSON.parse(localStorage.getItem("selectedCatalog")) || [{value: 0, label: localization.get('all')}]})
  };

  render = () => {
    return (
      <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
        <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
          <span>{localization.get('catalogs')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 5,
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={(e) => {
              localStorage.setItem("selectedCatalog", `${JSON.stringify(e)}`);
              this.props.onChange(e)
            }}
            value={this.state.catalog}
            defaultOptions
            isDisabled={this.props.disabled}
            isClearable={true}
            loadOptions={inputValue => {
              let filters = [{ column: "name", filter: inputValue }]
              if (this.props.hasRelation) {
                filters.push({column: this.props.hasRelation, filter: true })
              }
              return request.catalog
                .getAll({filterBy: filters})
                .then(catalogs => catalogs.map(catalog => ({value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})`})))
                .then(catalogs => catalogs.sort((a, b) => a.label.localeCompare(b.label)))
              }
            }
          />
        </div>
      </div>
    )
  }
}

CatalogSelection.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CatalogSelection
