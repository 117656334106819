import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveClient} from "../authentication/utils";

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/files?include=image;audio;audio.waveform;binary;video.thumbnail;tags`, { ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
  .then(response => response.json())
  .then(json => json.meta ? json : json.data)