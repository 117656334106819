import React from 'react'
import {Add} from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";

class HomeAddButton extends React.Component {
  constructor(props){
    super(props)
  }

  state = {
    isBottom: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkIfBottom)
  }

  checkIfBottom = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if ((window.innerHeight + window.outerHeight) <= Math.ceil(window.pageYOffset)) {
        this.setState({isBottom: true})
      } else {
        this.setState({isBottom: false})
      }
    } else {
      if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= window.outerHeight) {
        this.setState({isBottom: true})
      } else {
        this.setState({isBottom: false})
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.checkIfBottom)
  }

  render = () =>
    <Button
      style={{
        position: "fixed",
        backgroundColor: "black",
        bottom: this.state.isBottom ? 100 : 80,
        right: this.props.right ? this.props.right :"64px",
        zIndex: "100"
      }}
      onClick={this.props.onClick} size="lg" justIcon round>
      <this.props.icon style={{position: 'absolute', top: 12}}/>
    </Button>
}

HomeAddButton.defaultProps = {
  icon: Add
}

export default HomeAddButton
