// const unwantedStyles = ['color-rgba', 'color-rgb', 'bgcolor-rgb', 'bgcolor-rgba'];
const unwantedStyles = ['bgcolor-'];
const reservedEventCalendarText = "[[EVENTCALENDAR]]";

export const clearUnwantedStateStyles = (state) => {
  state.blocks = state.blocks.map(b => {b.inlineStyleRanges = b.inlineStyleRanges.filter(isr => unwantedStyles.every(s => isr.style.indexOf(s) == -1)); return b;});
  return state;
}

const blockHasReservedText = (block) => block.text.trim() == reservedEventCalendarText; 

export const replaceEventCalendarText = (state) => {
  state && state.blocks && state.blocks.forEach(b => {
    if (blockHasReservedText(b)) {
      let type = "EVENT_CALENDAR_LEFT";
      if (b.data && b.data['text-align']) {
        let alignment = b.data['text-align'];
        type = alignment == 'right' ? "EVENT_CALENDAR_RIGHT" : alignment == 'center' ? "EVENT_CALENDAR" : "EVENT_CALENDAR_LEFT";
      }
      b.text = '';
      b.type = type;
    }
  });
  return state;
}

export const hasReservedEventCalendarText = (state) => {
  return state && state.blocks && state.blocks.some(b => blockHasReservedText(b));
}