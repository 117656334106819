import { getActiveChannel } from '../authentication/utils';
import {post} from '../utils'

const url = (host) => (`${host}/channels/${getActiveChannel()}/contentCreations/renameMasively`);

export default host => (rename, materialIds, contentCreatorType) => {
  return post(url(host), JSON.stringify({
    name_prefix: rename,
    material_ids: materialIds,
    contentCreatorType: contentCreatorType,
  }))
}
