const IFRAME_ID = "downloader-iframe";

const document = host => ({
  getTermsOfUseDownloadUrl: getTermsOfUseDownloadUrl(host),
  downloadTermsOfUse: () => downloadFile(getTermsOfUseDownloadUrl(host)),
  downloadPrivacyPolicy: () => downloadFile(`${host}/documents/privacyPolicy/download`),
});

const getTermsOfUseDownloadUrl = host => `${host}/documents/termsOfUse/download`;

const downloadFile = url => getIframe().setAttribute("src", url);

const getIframe = () => {
  if (!window.document.getElementById(IFRAME_ID)) {
    createIframe();
  }

  return window.document.getElementById(IFRAME_ID);
};

const createIframe = () => {
  const iframe = window.document.createElement("iframe");

  iframe.setAttribute("id", IFRAME_ID);
  iframe.style.display = "none";

  window.document.getElementById("root").appendChild(iframe);
};

export default document;
