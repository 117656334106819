import React from "react";
import "./Reels.css";
import localization from "../../../config/localization";
import IconButton from "@material-ui/core/IconButton";
import {request} from "../../../api-client";
import HomeAddButton from "../../../components/Letflow/Button/HomeAddButton";
import ClientFileUploader from "../../../components/Letflow/ClientFileUploader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Tooltip
} from "@material-ui/core";
import {Clear, Close, PlusOne, Settings, Style} from "@material-ui/icons";
import FilesTable from "../../../components/Letflow/FilesTable";
import ReelForm from "./Form";
import TrackResultWithPagination from "../../../components/Letflow/TrackResultWithPagination";
import ReelTable from "./Table";
import {FormViewContainer} from "../../../components/Letflow/ViewContainer";
import InputLabel from "@material-ui/core/InputLabel";
import SelectMaterial from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import HomeItemDescriptionTags from "../HomeElements/HomeItemDescriptionTags";
import _ from 'lodash'
import ClientSocialLinksDialog from "../HomeElements/ClientSocialLinksDialog";
import Chip from "@material-ui/core/Chip";
import {ImagePicker} from "../../../components/Letflow/Form";
import {
  channelPathGenerator,
  getActiveChannel,
  homePathGenerator,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import browserHistory from "../../../utils/browserHistory";
import {UploadMany} from "../Drive/UploadMany";


class Reels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: props.client || null,
      tags: [],
      selectedTags: [],
      files: [],
      showFileUploadDialog: false,
      data: [],
      search: '',
      openTagDialog: false,
      showSocialLinkDialog: false,
      showShareDialog: false,
      selectedFiles: [],
      reel: "",
      creatingFile: false,
      reelTitle: '',
      reelColor: '#FFFFFF',
      reelViewType: 'grid',
      viewMode: 'table',
      showReelForm: false,
      showFileSelector: false,
      storageDialog: false,
      showEditThumbnailDialog: false,
      thumbnailImage: { src: null, file: null },
      searchTags: [],
      thumbnailFile: {},
      //key needed to force re-rendering of component after adding a new file
      key: 0,
      currentPage: this.props.match.params.id || 0,
      shareUrl: false,
      showUploadManyFileUploadDialog: false,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("navbar.reels"));
    document.getElementsByTagName("html")[0].scrollTop = 0;
    this.requestFiles({});

    this.requestTags()
  };

  requestTags = () =>
    request.clientTags.getAll({
      filterBy: [{column: "is_reel", filter: true},{column: 'order_by', filter: "name"},{column: 'sort_type', filter: "asc"}]
    }).then(tags => this.setState({tags, searchTags: tags},  () => this.addSelectedToTagList()));

  addSelectedToTagList = () =>
    this.setState({
      searchTags:
        this.state.tags.map(tag => ({
          ...tag,
          state: this.state.selectedTags.find(selectedTag => selectedTag == tag.id)
            ? "selected" : "available"
        }))
    });

  handleTagSearch = () =>
    this.setState({
      searchTags: this.state.tags.filter(tag => {
        return tag.name.indexOf(this.state.searchTagByText) !== -1;
      })
    });

  requestReel = id =>
    request.clientReels.get(id)
      .then(response => {
        let reel = {
          id: response.id,
          title: response.title,
          color: response.color,
          secondColor: response.second_color,
          thirdColor: response.third_color,
          fourthColor: response.fourth_color,
          viewType: response.view_type,
        };

        return this.setState({
            reel: reel,
            selectedFiles: response.clientFiles
          })
        }
      );

  updateReelFiles = id =>
    request.clientReels.get(id)
      .then(response =>
        this.setState({selectedFiles: this.state.selectedFiles.map(element => {
            const currentFile = response.clientFiles.filter(file => file.id === element.id)
            if(currentFile.length < 1){
              return element
            }
            return currentFile[0]
          })
        })
      );

  socialLinkDialog = () =>
    <ClientSocialLinksDialog
      open={this.state.openClientSettingDialog}
      handleClose={() => this.setState({openClientSettingDialog: false})}
    />

  showSocialLinkDialog = () => this.setState({openClientSettingDialog: true});
  handleDrawerToggle = () => this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  handleCloseDialog = () => this.setState({showFileUploadDialog: false, creatingFile: false, editingFile: false}, () => this.requestTags());
  handleEdit = reel => this.requestReel(reel.id).then(this.setState({viewMode: "create"}));
  handleCreate = () => this.setState({viewMode: "create", reel: "", selectedFiles: []});
  handleEditFile = file => this.setState({showFileUploadDialog: true, editingFile: file});
  handleThumbnailEdit = file => this.setState({reelId: file.reelId, showEditThumbnailDialog: true, thumbnailFile: file ,thumbnailImage: file.video.thumbnail || { src: null, file: null }})
  handleCloseTagDialog = () => this.setState({showTagDialog: false, searchTagByText: ""});
  //MOBILE ONLY
  handleAddFile = () => this.setState({showFileSelector: true});
  handleCloseUploadManyDialog = () => this.setState({showUploadManyFileUploadDialog: false},
    () => this.requestFiles({}).then(this.clearAllTags))

  onSelectedTagChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) {
      this.setState(
        {selectedTags:
            this.state.selectedTags.filter(tags => tags !== selectedTag)
        }, () => this.requestFiles({}).then(() => this.updateTagColor(selectedTag, "available")))
    }else {
      this.state.selectedTags.push(selectedTag);

      this.setState({selectedTags: this.state.selectedTags}, () => this.requestFiles({}).then(() => this.updateTagColor(selectedTag, "selected")))
    }
  };

  onSelectedFilesChanged = selectedFile => {
    if (this.state.selectedFiles.includes(selectedFile)) {
      this.setState({selectedFiles: this.state.selectedFiles.filter(files => files.id !== selectedFile.id)})
    }else {
      this.setState({selectedFiles: this.state.selectedFiles.concat([selectedFile])})
    }
  };

  addManyFiles = files => {
    //merge files from reels and files selected
    let selectedFiles = _.union(this.state.selectedFiles, files.filter(file =>
      !this.state.selectedFiles.map(file => file.id).includes(file.id)
    ));

      this.setState({selectedFiles: selectedFiles})
    };

  requestFiles = ({page = this.state.currentPage, perPage = 12}) => {
    let filter = [];

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('search')) {
      filter.push({column: "id_from_search", filter: urlParams.get('search')});
    }

    browserHistory.push(getActiveChannel() ?  channelPathGenerator(`reels?page=${page}`) : homePathGenerator(`reels?page=${page}`))
    this.setState({currentPage: page})
    this.state.selectedTags.length > 0 && filter.push({column: "tags_ids", filter: this.state.selectedTags || ""});
    this.state.search !== "" && filter.push({ column: "q", filter: this.state.search || "" });
    filter.push({column: "is_reel", filter: true});
    filter.push({column: "type", filter: "video"});

    return request.clientFile.getAllForReels(
      {
        page,
        perPage,
        filterBy: filter
      }
    ).then(res => this.setState({files: res})).then(() => this.setState({key: Math.random()}))
  };

  clearAllTags = () =>
    this.setState({selectedTags: [], searchTags : this.state.searchTags.map(tag => ({
        ...tag,
        state: "available"
      })
    )}, () => this.requestFiles({}));

  updateTagColor = (selectedTag, value) => {
    this.setState({searchTags: this.state.searchTags.map(tag => {
        if(tag.id === selectedTag) {
          return {...tag, state: value}
        }
        return tag
      })
    })
  }

  onSelectedTagDeleted = id => this.setState({selectedTags: this.state.selectedTags.filter(tag => tag !== id)})


  tagDialog = () =>
    <Dialog open={this.state.showTagDialog} onEscapeKeyDown={() => this.handleCloseTagDialog()} onBackdropClick={() => this.handleCloseTagDialog()} style={{padding: 10, margin: "-30px"}}>
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={() => this.handleCloseTagDialog()}>
        <Close />
      </IconButton>
      <DialogTitle>{localization.get('tags')}</DialogTitle>
      <DialogContent style={{minHeight: 300, minWidth: 300}}>
        {this.state.searchTags.length > 0 &&
        [1,2,3,4,5,6,7,8,9,10,null].map(element => {
          const tags = this.state.searchTags.filter(tag => tag.level === element)
          return (
            tags.length > 0 &&
              <div style={{width: "100%", marginBottom: 10}}>
                <HomeItemDescriptionTags
                  displayOnMobile={true}
                  outlined
                  tags={tags}
                  onSelectedTagChanged={this.onSelectedTagChanged}/>
              </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <div style={{float: "left", position: "relative", width: "100%", textAlign: "center",}}>
          <Button className="reels-button" onClick={() => this.handleCloseTagDialog()} style={{background: "black", color: "white"}}>
            {localization.get('home.smart_search.search').toUpperCase()}
          </Button>
        </div>
      </DialogActions>
    </Dialog>

  showReelForm = () =>
    <Dialog open={this.state.showReelForm} onBackdropClick={() => this.setState({showReelForm: false, editingReel: false})} style={{padding: 10}}>
      <FormViewContainer
        title={localization.get('reel.create')}
        submitRequest={this.handleSubmit}
        onBack={() => this.setState({showReelForm: false, editingReel: false})}
        style={{minWidth: "450px", width: "100%", display: "table"}}
        color="black"
        smallExtraSpace={true}
      >
        <FormControl style={{ marginTop: 5, width: "100%", padding: "0 15px" , float: "left", position: "relative"}}>
          <TextField
            style={{ marginBottom: 20 }}
            value={this.state.reelTitle}
            label={localization.get("reels.title")}
            onChange={e => this.setState({reelTitle: e.target.value})}
            fullWidth
          />
          <label> {localization.get('reel.background_color')} </label>
          <input type="color" value={this.state.reelColor} style={{marginBottom: 20}} onChange={(event => this.setState({reelColor:event.target.value}))}/>
          <FormControl fullWidth>
            <InputLabel>{localization.get("reel.view_type")}</InputLabel>
            <SelectMaterial
              value={this.state.reelViewType}
              onChange={(e) => this.setState({reelViewType: e.target.value})}
              style={{marginBottom: "20px"}}
            >
              <MenuItem value='grid' >{localization.get('grid')}</MenuItem>
              <MenuItem value='grid_with_banner' >{localization.get('grid_with_banner')}</MenuItem>
            </SelectMaterial>
          </FormControl>
        </FormControl>
      </FormViewContainer>
    </Dialog>

  onSubmitThumbnail = () => {
      request.clientFile
        .updateThumbnail({
          fileId: this.state.thumbnailFile.id,
          image: this.state.thumbnailImage.file
        })
        .then(() => this.requestFiles({}))
        .then(() => this.state.reelId && this.updateReelFiles(this.state.reelId))
        .then(() => this.handleCloseThumbnailDialog())
  };

  handleCloseThumbnailDialog = () => this.setState({reelId: null, showEditThumbnailDialog: false, thumbnailFile: {}, thumbnailImage: { src: null, file: null } })

  makeThumbnailEditDialog = () => {
    return (
      <Dialog open={this.state.showEditThumbnailDialog} onClose={this.handleCloseThumbnailDialog} onBackdropClick={this.handleCloseThumbnailDialog}>
        <DialogTitle>{localization.get('reel.edit_thumbnail_dialog_title')}</DialogTitle>
        <DialogContent style={{minWidth: 500}}>
          <p>{localization.get('reel.edit_thumbnail_dialog_description')}</p>
          <br/>
          <ImagePicker
            buttonText={localization.get('form.select_image')}
            image={ this.state.thumbnailImage.src }
            onImageChange={ thumbnailImage => this.setState({ thumbnailImage })}
            color={'default'}
            style={{backgroundColor: "black"}}
            accept="image/png|jpg|jpeg"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseThumbnailDialog} disabled={this.state.sending}>
            {localization.get("cancel")}
          </Button>
          <Button onClick={this.onSubmitThumbnail} disabled={this.state.sending}>
            {localization.get("send")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  };

  render = () => {
    return (
      <div id="innerinner" style={{background: "rgb(247, 247, 247)"}}>
        {this.makeThumbnailEditDialog()}
        <ClientFileUploader
          show={this.state.showFileUploadDialog}
          handleClose={this.handleCloseDialog}
          requestFiles={this.requestFiles}
          creatingFile={this.state.creatingFile}
          file={this.state.editingFile || false}
          isReel={true}
          onSelectedTagDeleted={id => this.onSelectedTagDeleted(id)}
        />
        <UploadMany
          open={this.state.showUploadManyFileUploadDialog}
          forReels
          handleClose={this.handleCloseUploadManyDialog}
          tags={this.state.searchTags}
          onSelectedTagDeleted={ id => this.setState({tags: this.state.tags.filter(tag => tag.id !== id)})}
          requestTags={this.requestTags}
        />
        {this.socialLinkDialog()}
        {this.tagDialog()}
        {this.showReelForm()}
        <div className="reels-content">
          {/*Content*/}
            {this.state.files &&
              <>
                <div className="reels-maker">
                  <div style={{width: "100%", float: "left", position: "relative", flexDirection: "row", marginTop: 20, display: "flex",justifyContent: "center"}}>
                    <h1 style={{color: "black", fontSize: "1.825em", lineHeight: "1.4em", margin: "20px 0 10px"}}> {localization.get('navbar.reels')} </h1>
                    <div style={{position: "absolute", right: 0, top: 20}}>
                        <IconButton
                          style={{ height: 35, width: 35 }}
                          onClick={this.showSocialLinkDialog}
                        >
                          <Tooltip  title={localization.get('reel.settings')} leaveDelay={1} placement='top'>
                            <Settings />
                          </Tooltip>
                        </IconButton>
                    </div>
                  </div>
                  <div className="reel-body">
                    {this.state.viewMode === 'create' ?
                      <ReelForm
                        selectedFiles={this.state.selectedFiles}
                        removeFileFromReel={this.onSelectedFilesChanged}
                        handleThumbnailEdit={this.handleThumbnailEdit}
                        reel={this.state.reel}
                        changeViewMode={() => this.setState({viewMode: 'table', selectedFiles: []})}
                        handleAddFile={this.handleAddFile}
                      />
                      :
                      <>
                        <ReelTable
                        handleEdit={this.handleEdit}
                        />
                        <div className="reels-button"
                         style={{
                           width: 150,
                           position: "relative",
                           float: "left",
                           marginTop: 20,
                           marginLeft: "calc( 50% - 75px )",
                           marginBottom: 30,
                           textAlign: "center",
                        }}
                         onClick={this.handleCreate}>{localization.get('reel.create').toUpperCase()}</div>
                      </>

                    }
                  </div>
                </div>
                <div className={this.state.showFileSelector ? 'reel-files-table' : 'reel-files-table hide-file-selector'}>
                  <div className={this.state.showFileSelector ? 'reel-files-clear' : 'reel-files-clear hide-file-selector'} onClick={() => this.setState({showFileSelector: false})}>
                    <Clear/>
                  </div>
                  <h3 style={{color: "black"}}> {localization.get('reel.files')} </h3>
                  <div style={{height: 70}}>
                    <FormControl style={{ marginTop: 5, width: 180, padding: "0 15px 0 0" , float: "left", position: "relative"}}>
                      <TextField
                        style={{ marginBottom: 20 }}
                        value={this.state.search}
                        label={localization.get("search.reel_files")}
                        onChange={e => this.setState({search: e.target.value}, () => setTimeout(this.requestFiles({}), 200))}
                        fullWidth
                      />
                    </FormControl>
                    <div style={{marginTop: 10, position: "relative", float:"left"}} onClick={() =>  this.setState({showTagDialog: true})}>
                      <IconButton >
                        <Tooltip  title={localization.get('reel.filter_tag')} leaveDelay={1} placement='top'>
                          <Style/>
                        </Tooltip>
                      </IconButton>
                    </div>
                    {this.state.selectedTags.length > 0 &&
                    <div style={{marginTop: 17, position: "relative", float:"left"}}>
                      <Chip
                        variant="outlined"
                        style={{
                          margin: "0 4px",
                          border: "1px solid #0000003d",
                          backgroundColor: 'darkgray',
                          color: 'white',
                          width: 125,
                        }}
                        onDelete={this.clearAllTags}
                        label={localization.get('files.clear_tags') + ' ' + this.state.selectedTags.length}
                      />
                    </div>
                    }
                  </div>
                  {this.state.files.meta &&
                    <TrackResultWithPagination
                      rows={
                        <FilesTable
                          key={this.state.key}
                          onSelectFile={this.onSelectedFilesChanged}
                          files={this.state.files}
                          selectedFiles={this.state.selectedFiles.map(file => file.id)}
                          addMany={this.addManyFiles}
                          onSelectTag={this.onSelectedTagChanged}
                          addingFiles={this.state.viewMode === "create"}
                          requestFiles={this.requestFiles}
                          handleEdit={this.handleEditFile}
                          handleThumbnailEdit={this.handleThumbnailEdit}
                          handleCloseSidebar={() => this.setState({showFileSelector: false})}
                        />
                      }
                      onPageChange={page => this.requestFiles({page: page})}
                      pagination={this.state.files.meta}
                    />
                  }
                </div>

              </>
            }
          <HomeAddButton
            onClick={() => this.setState({ showUploadManyFileUploadDialog: true })}
            right={125}
            icon={PlusOne}
          />
          <div className={!this.state.showFileSelector && 'hide-file-selector'}><HomeAddButton  onClick={() => this.setState({showFileUploadDialog: true, creatingFile: true})}/></div>
        </div>
      </div>
    );
  };
}

export default Reels;