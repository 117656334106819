import React from "react";
import localization from "../../../../config/localization";

import {
  getStoredChannel,
  getStoredUser,
  setPageTitle,
  getActiveClient
} from "../../../../api-client/core/authentication/utils";
import { AccountBox, AddPhotoAlternateOutlined } from "@material-ui/icons";
import { Formik } from "formik";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import { getUpdateFormImageUrlFromItem, validateUrl } from "../../../../utils";
import * as BFH from "../../../../components/Letflow/Form/BloomcloudsFormHelpers";
import {request} from "../../../../api-client";
import SocialLinks from "../../../../components/Letflow/SocialLinks";
import FilePondUploader from "../../../../components/Letflow/FilePondUploader";
import Unsplash from "../../../../components/Letflow/Unsplash";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import {stateToHTML} from "draft-js-export-html";
import { ImagePicker } from "../../../../components/Letflow/Form";
import TalentProfileModal from "../TalentProfileModal";
import browserHistory from "../../../../utils/browserHistory";
import FormikTranslatableInputs from "../../../../components/Letflow/TranslatableInputs/FormikTranslatableInputs";
import {clearUnwantedStateStyles} from "../../../../components/Letflow/RichTextEditor/utils";
import {get} from 'lodash';
import analyticsInstance from "../../../../analytics/analyticsInstance";
export default class extends React.Component {

  constructor(props) {
    super(props);
    let currentChannel = getStoredChannel();
    let contentCreator = props.creator;
    let isEditorial = contentCreator && contentCreator.type === 'editorial';
    let validations = get(currentChannel, 'community.preferences.validations', null)

    let translations = getStoredChannel().languages.map(lang => lang.language).map(language => {
      if (contentCreator) {
        const translation = contentCreator.translations.find(tr => tr.locale === language)

        if (translation) {
          const description = translation.description ? (isEditorial ? convertFromRaw(JSON.parse(translation.description)) : stateFromHTML(translation.description)) : new EditorState.createEmpty();

          return {
            language: translation.locale,
            description: translation.description ? EditorState.createWithContent(description) : description,
          };
        }
      }

      return  {
        language: language,
        description: new EditorState.createEmpty(),
      }
    })


    this.state = {
      id: contentCreator ? contentCreator.id : null,
      contentCreator: contentCreator,
      translations: translations,
      disableForm: false,
      loaded: true,
      countries: [],
      viewType: 'grid',
      contentCreatorType: '',
      contentCreatorTypeIsEditorial: isEditorial,
      wizardStep: !contentCreator && "1",
      showErrors: false,
      validations,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.talent_info'))
    document.getElementsByTagName('html')[0].scrollTop = 0;
    request.country.getAll()
      .then(countries => this.setState({countries}))
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (nextProps.creator) {
      this.setState({creator: nextProps.creator})
    }
  };

  initialValues = (contentCreator) => {
    let coverImage;
    let image;
    if (contentCreator) {
       image = contentCreator.image && { id: contentCreator.image.id, src: getUpdateFormImageUrlFromItem(contentCreator), file: null }
       coverImage = contentCreator.coverImage && { src: getUpdateFormImageUrlFromItem({ image: contentCreator.coverImage }), file: null }
    }
    return {
      name: contentCreator ? contentCreator.name : "",
      phone: contentCreator ? contentCreator.phone : "",
      countryId: contentCreator && contentCreator.country ? contentCreator.country.id : "",
      socialLinks: contentCreator ? contentCreator.socialLinks : [],
      profilePhotoRadio: "filepond",
      profilePhoto: image ? image : {src: null, filename: null},
      profilePhotoId: image ? image.id : '',
      profilePhotoPreview: '',
      unsplashProfilePhoto: '',
      coverImageRadio: "filepond",
      coverImage: coverImage ? coverImage : {src: null, file: null},
      coverImageId: contentCreator && contentCreator.coverImage ? contentCreator.coverImage.id : '',
      coverImagePreview: '',
      unsplashCoverImage: '',
      viewType: (contentCreator && contentCreator.view_type == 'carousel') ? 'carousel' : 'grid',
    }
  };

  validate = values => {
    const {validations} = this.state
    const errors = {};
    if (!values.name) {
      errors.name = localization.get("form.validation.required");
    }

    if (get(validations, 'country', "false") == "true" && !values.countryId) {
      errors.countryId = localization.get("form.validation.required");
    }

    if (get(validations, 'tel', "false") == "true" &&  !values.phone) {
      errors.phone = localization.get("form.validation.required");
    }

    return errors;
  };

  checkBeforeSubmit= values => {
    for (let sl of values.socialLinks) {
      if (sl.url) {
        if (sl.type == 'tiktok') {
          if (sl.url.indexOf(' ') >= 0) {
            GlobalSnackbar.show({message: localization.get('form.validate_social_url.chars_spaces', sl.type.capitalize()), type: GlobalSnackbarTypes.ERROR});
            return;
          }
        } else if ((sl.url.indexOf(' ') >= 0) || (sl.url.indexOf('@') >= 0)) {
          GlobalSnackbar.show({message: localization.get('form.validate_social_url.chars', sl.type.capitalize()), type: GlobalSnackbarTypes.ERROR});
          return false;
        }
        if (!sl.url.includes('https://')) {
          if(validateUrl(sl.url)) {
            let splittedUrl = sl.url.split('//');
            sl.url = `https://${(splittedUrl.length > 1) ? splittedUrl[1] : splittedUrl[0]}`;
          } else {
            GlobalSnackbar.show({message: localization.get('form.validate_social_url.https', sl.type.capitalize()), type: GlobalSnackbarTypes.ERROR});
            return false;
          }
        }
      }
    };

    const validations = this.state.validations;
    
    if (get(validations, 'description', "false") == "true" && !this.state.translations.some(t => t.description.getCurrentContent().hasText())) {
      GlobalSnackbar.show({message: localization.get('form.validate_bio'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }
    
    if (get(validations, 'profileImage', "false") == "true" &&  !values.profilePhotoId) {
      GlobalSnackbar.show({message: localization.get('form.validate_files.profile_image'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (get(validations, 'coverImage', "false") == "true" &&  !values.coverImageId) {        
      GlobalSnackbar.show({message: localization.get('form.validate_files.cover_image'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    return true;
  }

  async submit(values) {
    let currentUser = getStoredUser();
    let canBeActive = true;
    let currentChannel = getStoredChannel();
    let catalog = (currentChannel.community && currentChannel.community.catalog) ? currentChannel.community.catalog.id : null;
    let catalogs = catalog ? [catalog] : [];
    let isEditorial = this.state.contentCreator && this.state.contentCreator.type === 'editorial';

    if (this.state.translations.some(tr => {
      let description = tr.description;
      let descriptionContent = description && description.getCurrentContent();
      return descriptionContent && descriptionContent.hasText();
    }) && (!(this.state.contentCreator && this.state.contentCreator.image) && !(values.profilePhotoId || values.unsplashProfilePhoto))) {
      canBeActive = false;
    }

    let contentCreatorData = {
      email: currentUser.email,
      phone: values.phone,
      translations: this.state.translations.map(tr => ({
        language: tr.language,
        description: tr.description && (isEditorial ?
          (stateToHTML(tr.description.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.description.getCurrentContent())))) :
          stateToHTML(tr.description.getCurrentContent())),
        name: values.name,
      })),
      imageId: this.state.contentCreator && this.state.contentCreator.image && (this.state.contentCreator.image.id == values.profilePhotoId) ? null : values.profilePhotoId,
      coverImageId: this.state.contentCreator && this.state.contentCreator.coverImage && (this.state.contentCreator.coverImage.id == values.coverImageId) ? null : values.coverImageId,
      countryId: values.countryId,
      socialLinks: values.socialLinks,
      contentCreatorTypeId: this.state.contentCreator ? this.state.contentCreator.type.id : this.state.contentCreatorType.id,
      unsplashCoverImage: values.unsplashCoverImage,
      unsplashProfileImage: values.unsplashProfilePhoto,
      viewType: values.viewType
    };

    if (!canBeActive) contentCreatorData.active = "0";

    let response;
    if (this.state.contentCreator) {
      contentCreatorData.id = this.state.contentCreator.id;
      response = await request.channelContentCreator.update(contentCreatorData)
        .then(() => analyticsInstance.community({name: contentCreatorData.name, action: "Update_Info"}))
        .then(() => this.props.requestCreator())
        .then(() => GlobalSnackbar.show({
          type: GlobalSnackbarTypes.SUCCESS,
          message: localization.get('form.updated_correctly'),
        }))
        .catch(GlobalSnackbar.showGenericError());
    } else {
      contentCreatorData.translations = this.state.translations.map(tr => ({
        language: tr.language,
        description: tr.description && (isEditorial ?
          (stateToHTML(tr.description.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.description.getCurrentContent())))) :
          stateToHTML(tr.description.getCurrentContent())),
        name: values.name,
      }));
      contentCreatorData.onlyArgentina = "false";
      contentCreatorData.startActivity = null;
      contentCreatorData.observation = "";
      contentCreatorData.selectedCatalogs = catalogs;
      contentCreatorData.active = canBeActive ? "1" : "0";
      response = await request.channelContentCreator.create(contentCreatorData)
        .then(() => analyticsInstance.community({name: contentCreatorData.name, action: "Welcome"}))
        .then(() => this.props.requestCreator())
        .then(() => browserHistory.push("tags?wizardStep=2"));
    }
    return response
  }

  renderShowModal = () => {
    return <TalentProfileModal onModalEnd={(cct) => this.setState({contentCreatorType: cct})}/>
  }
  
  render = () => {
    return (
      <div className="talent-profile-section">
        <div className="talent-profile-section-title" style={ this.state.wizardStep ? {justifyContent: 'space-between'} : {}}>
            {this.state.wizardStep && <div className="talent-profile-section-title-label">{localization.get("talent_profile_wizard_step", this.state.wizardStep)}</div>}
            <div className="talent-profile-section-title">
              <div className="talent-profile-section-title-logo">
                <AccountBox style={{ fontSize: "16px", color: "#676767" }}/>
              </div>
              <div className="talent-profile-section-title-label">{localization.get("talent_profile.general_info")}</div>
            </div>
          </div>
        <div>
          {!this.state.contentCreator && this.renderShowModal()}
          {(this.state.loaded || this.state.contentCreatorType) &&
            <Formik
              initialValues={this.initialValues(this.state.contentCreator)}
              validate={this.validate}
              onSubmit={(values, actions) => {
                this.setState({disableForm: true});
                if (this.checkBeforeSubmit(values)) {
                  this.submit(values)
                    .then((response) => {
                      this.setState({ disableForm: false, submitted: true });
                    })
                    .catch(e => {
                      console.log(e)
                      GlobalSnackbar.showGenericError();
                      this.setState({disableForm: false});
                    })
                } else {
                  this.setState({disableForm: false});
                }
              }}
            >
              {formikProps =>
                <React.Fragment>
                  <FormView
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                    onChange={(field, value) => {
                      formikProps.setFieldValue(field, value);
                    }}
                    countries={this.state.countries}
                    onSubmit={(e) => {
                      this.setState({showErrors: true});
                      formikProps.handleSubmit(e);
                    }}
                    submitted={this.state.submitted}
                    formikProps={formikProps}
                    translations={this.state.translations}
                    onContentChange={(e, field) => this.setState({[field]: e})}
                    showErrors={this.state.showErrors}
                    disableForm={this.state.disableForm}
                    updateDisableForm={value => this.setState({disableForm: value})}
                    validations={this.state.validations}
                  />
                </React.Fragment>
              }
            </Formik>
          }
        </div>
      </div>
    );
  }
}

const FormView = ({
  errors,
  touched,
  submitted,
  onChange,
  onSubmit,
  formikProps,
  countries,
  translations,
  onContentChange,
  showErrors,
  disableForm,
  updateDisableForm,
  validations
}) => {
  let formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path);
  let makePhotoInput = (radioField, photoField, idField, unsplashField, previewField) => (
    <BFH.InputContainer>
      <FormControl>
        <RadioGroup
          name={radioField}
          value={formikProps.values[radioField]}
          onChange={(e) => {
            formikProps.setFieldValue(radioField, e.target.value);
            formikProps.setFieldValue(idField, '');
            formikProps.setFieldValue(photoField, {src: null, filename: null});
            formikProps.setFieldValue(unsplashField, '');
          }}
          // onBlur={() => formikProps.setFieldTouched(radioField, true)}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('file.upload')}/>
          <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
        </RadioGroup>
      </FormControl>
      <div className="media-input-container">
        {!formikProps.values[idField] && formikProps.values[radioField] === 'filepond' && 
          <div className="filepond-img-container">
            <AddPhotoAlternateOutlined style={{ fontSize: "100px" }}></AddPhotoAlternateOutlined>
          </div>
        }
        <div className="filepond-container">
          {formikProps.values[radioField] === 'filepond' ?
            <>
              {formikProps.values[idField] && formikProps.values[photoField] && <div style={{paddingBottom: '20px'}}><ImagePicker image={formikProps.values[photoField].src} previewOnly={true}/></div>}
              <FilePondUploader
                id={"image"}
                type={"image"}
                file={formikProps.values[photoField]}
                allowedTypes={["image/*"]}
                onIdChange={(image) => {
                  formikProps.setFieldValue(idField, image ? image.id : null);
                  formikProps.setFieldValue(photoField, {src: null, filename: null });
                  image && formikProps.setFieldValue(previewField, formNewUrl(image.path));
                }}
                setPreview={(type) => {
                  let item = {image: {id: formikProps.values[idField], src: formikProps.values[previewField]}}
                  formikProps.setFieldValue(photoField, {src: getUpdateFormImageUrlFromItem(item)})
                }}
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                clientId={getActiveClient()}
              />
              <div className="filepond-clarification">{localization.get('helper_text.images_width_2048')}</div>
            </>
            :
            <Unsplash imageAmount={6} profile={true} onSelectImage={(url, name) => formikProps.setFieldValue(unsplashField, {url, name})}/>
          }
        </div>
      </div>
    </BFH.InputContainer>
  );

  return (
    <BFH.FormContainer errors={errors} touched={touched} onChange={onChange}>
      <BFH.SectionTitle>{localization.get("general_information")}</BFH.SectionTitle>
      {/** Name */}
      <BFH.InputContainer>
        <BFH.FieldTitle>{localization.get("form.fullname") + " / " +  localization.get("talent_profile.company") + "*"}</BFH.FieldTitle>
        <BFH.Input
          field="name"
          type="text"
          value={formikProps.values.name}
        />
      </BFH.InputContainer>
      
      <BFH.DoubleInputContainer>
        {/** Country */}
        <BFH.InputContainer>
          <BFH.FieldTitle>{localization.get("form.country") + (get(validations, 'country', "false") == "true" ? "*" : "")}</BFH.FieldTitle>
          <BFH.Select
            field="countryId"
            options={countries.map(x => ({ value: x.id, label: x.name }))}
            value={formikProps.values.countryId}
          />
        </BFH.InputContainer>
        {/** Phone */}
        <BFH.InputContainer>
          <BFH.FieldTitle>{localization.get("form.phone") + (get(validations, 'tel', "false") == "true" ? "*" : "")}</BFH.FieldTitle>
          <BFH.Input
            field="phone"
            type="text"
            value={formikProps.values.phone}
            placeholder={localization.get("form.phone_placeholder")}
          />
        </BFH.InputContainer>
      </BFH.DoubleInputContainer>

      <BFH.InputContainer>
        <BFH.FieldTitle>{localization.get('social_links')}</BFH.FieldTitle>
        <SocialLinks
          socialLinks={formikProps.values.socialLinks}
          onSocialLinksChange={(socialLinks) => onChange('socialLinks', socialLinks)}
          bloomCloudsView={true}
        />
      </BFH.InputContainer>
      <BFH.InputContainer>
        <FormikTranslatableInputs
          isEditorialContent={true}
          bloomCloudsView={true}
          children={getStoredChannel().languages.map(lang => lang.language).map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <BFH.TranslateEditor
                  errors={errors}
                  touched={touched}
                  onChange={value => {
                    const newTranslations = translations.map(tr => {
                      if (tr.language === language) {
                        return {...tr, description: value}
                      }
                      return tr
                    });

                    onContentChange(newTranslations, 'translations')
                  }}
                  label={localization.get("description")}
                  field={`description${language}`}
                  type="text"
                  value={currentLangTranslation.description}
                />
              ]
            }
          })}
        />
      </BFH.InputContainer>
      
      {/** Profile photo */}
      <BFH.SectionTitle>{localization.get("form.select_image_profile") + (get(validations, 'profileImage', "false") == "true" ? "*" : "")}</BFH.SectionTitle>
      {makePhotoInput("profilePhotoRadio", "profilePhoto", "profilePhotoId", "unsplashProfilePhoto", "profilePhotoPreview")}
      
      {/** Home photo */}
      <BFH.SectionTitle>{localization.get("form.select_image_cover") + (get(validations, 'coverImage', "false") == "true" ? "*" : "")}</BFH.SectionTitle>
      {makePhotoInput("coverImageRadio", "coverImage", "coverImageId", "unsplashCoverImage", "coverImagePreview")}
      
      {/** Material Style */}
      <BFH.SectionTitle>{localization.get("talent_profile.style")}</BFH.SectionTitle>
      <BFH.FieldTitle>{localization.get("talent_profile.view_type_helper")}</BFH.FieldTitle>
      <BFH.InputContainer>
        <FormControl>
          <RadioGroup
            name={"viewType"}
            value={formikProps.values.viewType}
            onChange={(e) => {
              formikProps.setFieldValue('viewType', e.target.value);
            }}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="grid" control={<Radio color="default"/>} label={localization.get('select.grid')}/>
            <FormControlLabel value="carousel" control={<Radio color="default"/>} label={localization.get('select.carousel')}/>
          </RadioGroup>
        </FormControl>
      </BFH.InputContainer>
      
      <BFH.SubmitButton
        onClick={onSubmit}
        disabled={submitted || disableForm}
        hasErrors={showErrors && Object.keys(errors).length > 0}
      />
    </BFH.FormContainer>
  );
};