import React, {Component} from "react";
import {Link} from "react-router-dom";
import ServerSidePaginationTable from "../../components/Letflow/Table/ServerSidePaginationTable";
import localization from "../../config/localization";
import TableButtonsBuilder from "../../components/Letflow/Table/Buttons/TableButtonsBuilder";
import browserHistory from "../../utils/browserHistory";
import AllViewContainer from "../../components/Letflow/ViewContainer/AllViewContainer";
import {request} from "../../api-client";
import moment from "moment";
import {getActiveClient, userIsSysAdmin} from "../../api-client/core/authentication/utils";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import {FormControl, Radio, RadioGroup} from "@material-ui/core/index";

const perPage = 12;

const fetchPitches = (
  { page, filterBy },
  onlyForActiveClient,
  showNonParticipatingConversations
) => {
  const options = {};

  if (onlyForActiveClient) {
    options.clientId = getActiveClient();
  }

  if (showNonParticipatingConversations) {
    options.includeAll = true;
  }

  // Filters

  // Project Name Filter

  let projectNameFilter = filterBy.find(x => x.column === "project_name");

  if (projectNameFilter) {
    options.projectName = projectNameFilter.filter;
  }

  // Project Name Filter

  let clientIdFilter = filterBy.find(x => x.column === "client_id");

  if (clientIdFilter) {
    options.clientId = clientIdFilter.filter;
  }

  return request.pitch.list(page + 1, perPage, options);
};

const columns = (onDelete, showClient = true, total) => {
  const arr = [];

  arr.push({
    Header: localization.get("project"),
    accessor: "project_name",
    sortable: false
  });

  if (showClient) {
    arr.push({
      Header: localization.get("clients"),
      id: 'client_id',
      accessor: 'clients',
      Cell: ({ value }) => value.map(client => client.name).join(", "),
      sortable: false,
      Filter: ({ filter, onChange }) => (
        <ClientFilter filter={filter} onChange={onChange} />
      )
    });
  }

  arr.push(
    {
      Header: localization.get("created_at"),
      accessor: "created_at",
      Cell: ({ value }) => <span>{moment(value).format("DD-MM-YYYY")}</span>,
      filterable: false,
      sortable: false
    },
    {
      id: "actions",
      sortable: false,
      filterable: false,
      Cell: ({ original }) => {
        const builder = new TableButtonsBuilder();
        if (original.user_belongs_to_pitch) {
          builder.withDelete(
            original.project_name,
            () => request.pitch.delete(original.id),
            state => {
              if (state === "passed") {
                onDelete(original);
              }
            }
          );
        }
        return builder.build();
      },
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{total}</span>
    }
  );

  return arr;
};

const getTrProps = threadUrl => (_, row) => ({
  onClick: () => {
    if (row) {
      browserHistory.push(threadUrl(row.original.id));
    }
  },
  style: {
    cursor: row ? "pointer" : undefined
  }
});

const onAdd = createUrl => browserHistory.push(createUrl);

export default class extends Component {
  state = {
    showNonParticipatingConversations: false,
    showPitches: 'mine_only'
  };

  constructor() {
    super();
    document.getElementsByTagName("html")[0].scrollTop = 0;
    this.serverSidePaginationTableRef = React.createRef();
  }

  render = () => {
    const { threadUrl, createUrl, onlineVideosUrl } = this.props;

    return (
      <React.Fragment>
        {/*<ReceiveEmailsFromPitches />*/}
        <AllViewContainer onAdd={createUrl && (() => onAdd(createUrl))}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <div>
                    <ShowAllPitches
                      showPitches={this.state.showPitches}
                      onChange={value =>
                        this.setState({ showPitches: value, showNonParticipatingConversations: value === 'all' }, () =>
                          this.serverSidePaginationTableRef.current.forceUpdate()
                        )
                      }
                    />
                    {onlineVideosUrl && (
                      <Link style={{marginLeft: 10, marginTop: 15, float: "left", position: "relative",}} to={onlineVideosUrl}>
                        <span>{localization.get("pitches.view_online_videos")}</span>
                      </Link>
                    )}
                  </div>
                  <div style={{ width: "100%", float: "left", position: "relative" }}>
                    <ServerSidePaginationTable
                      ref={this.serverSidePaginationTableRef}
                      columns={columns(
                        ({ id }) => this.serverSidePaginationTableRef.current.removeById(id),
                        !this.props.onlyForActiveClient,
                        this.state.total
                      )}
                      requestTableData={options =>
                        fetchPitches(
                          options,
                          this.props.onlyForActiveClient,
                          this.state.showNonParticipatingConversations
                        ).then(response => {
                          this.setState({total: response.meta.total});
                          return response;
                        })
                      }
                      perPage={perPage}
                      getTrProps={getTrProps(threadUrl)}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </AllViewContainer>
      </React.Fragment>
    );
  };
}

export const ShowAllPitches = ({ showPitches, onChange }) => {
  if (!userIsSysAdmin()) {
    return null;
  }

  return (
    <FormControl style={{float: "left", position: "relative"}}>
      <RadioGroup
        name="showPitches"
        value={showPitches}
        onChange={e => onChange(e.target.value)}
        style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
      >
        <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('all')} />
        <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="mine_only" control={<Radio color="default" />} label={localization.get('pitches.view_mine')} />
      </RadioGroup>
    </FormControl>
  );
};

export class ReceiveEmailsFromPitches extends Component {
  state = {
    value: null
  };

  componentDidMount = () => {
    request.pitch
      .isSubscribedToPitches()
      .then(value => this.setState({ value }));
  };

  render = () => {
    if (this.state.value === null) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.value}
            onChange={() => {
              this.setState({ value: !this.state.value }, () => {
                request.pitch.subscribeToPitches(this.state.value);
              });
            }}
            color="default"
          />
        }
        label={localization.get("receive_emails_from_pitches")}
      />
    );
  };
}

export class ClientFilter extends Component {
  state = {
    clients: [],
    current: undefined
  };

  componentDidMount = () => {
    request.client.getAll().then(res => this.setState({ clients: res }));
  };

  render = () => {
    return (
      <select
        onChange={event => this.props.onChange(event.target.value)}
        style={{ width: "100%" }}
        value={this.props.filter ? this.props.filter.value : "all"}
      >
        <option value={undefined}> </option>
        {this.state.clients.map(x => (
          <option value={x.id}>{x.name}</option>
        ))}
      </select>
    );
  };
}
