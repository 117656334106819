import Home from "../../layouts/Home";
import {makeRoutes, publicRoutes, redirectTo, route} from "./utils";
import {homePathGenerator} from "../../api-client/core/authentication/utils";
import Channel from "../../layouts/Channel";

const allowed = () => publicRoutes.concat([
  route("/channel/:channelId-:channelName", Channel),
  route(`/home/:clientId-:clientName`, Home)
]);

const def = client => route("/", redirectTo(homePathGenerator(``, client)));

export default client => makeRoutes(allowed(client), [], def(client));
