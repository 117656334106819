import React from "react";
import {ServerSidePaginationTable} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {getMonthName} from "../../../utils";

const Table = () => {

  let table

  const requestTableData = paginationConfig => request.artist.getNewMusics(paginationConfig)

  const currentMonth = new Date().getMonth() + 1;

  return (
    <ServerSidePaginationTable
      ref={r => table = r}
      columns={[
        {
          Header: localization.get('table.artist'),
          accessor: "name",
          Filter: ({ filter, onChange }) => (
            <input
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ''}
              placeholder={localization.get('table.search')}
            />
          ),
        },
        {
          Header: getMonthName(currentMonth),
          id: "month-0",
          accessor: 'month-0',
          filterable: false,
          sortable: true,
          width: 150,
          style: {
            textAlign: 'right',
          },
          headerStyle: {
            textAlign: 'center',
          }
        },
        {
          Header: getMonthName((currentMonth + 10) % 12 + 1), // 1 month ago
          id: "month-1",
          accessor: 'month-1',
          filterable: false,
          sortable: true,
          width: 150,
          style: {
            textAlign: 'right',
          },
          headerStyle: {
            textAlign: 'center',
          }
        },
        {
          Header: getMonthName((currentMonth + 9) % 12 + 1), // 2 months ago
          id: "month-2",
          accessor: 'month-2',
          filterable: false,
          sortable: true,
          width: 150,
          style: {
            textAlign: 'right',
          },
          headerStyle: {
            textAlign: 'center',
          }
        },
        {
          Header: localization.get('table.without_bichannel'),
          id: "temp",
          accessor: 'temp',
          filterable: false,
          sortable: true,
          width: 150,
          style: {
            textAlign: 'right',
          },
          headerStyle: {
            textAlign: 'center',
          }
        }
      ]}
      requestTableData={requestTableData}
    />
  )
}


export default Table