import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  name,
  email,
  phone,
  translations,
  socialLinks,
  type,
  image,
  selectedMusics,
  selectedUsers,
  isDemo,
  birthdate,
  countryId,
  audios
}) => post(`${host}/public/artists/demos`, makeFormData({
  name,
  email,
  phone,
  translations,
  type,
  image,
  selectedMusics,
  selectedUsers,
  isDemo,
  birthdate,
  audios,
  countryId,
  socialLinks
}))
  .then(response => response.json())
