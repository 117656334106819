import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host, artistId, options) =>
  appendPaginationQueryParamsToUrl(`${host}/public/artists/${artistId}/musics?include=tags;audio;audio.waveform;favorite;albums.image`, {...options, alreadyHasQueryParams: true})

export default host => (artistId, options) =>
  get(url(host, artistId, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


