import React from "react";
import {TextValidator} from 'react-material-ui-form-validator'
import localization from "../../../config/localization";
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import {FormControl, FormControlLabel, Checkbox} from "@material-ui/core";
import {request} from "api-client";
import AsyncSelect from "react-select/lib/Async";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

class FormView extends React.Component {

  state = {
    types: [
      {id: 'music', label: localization.get('music')},
    ],
  };

  findMissingFields = () => this.child.findMissingFields()

  render = () => {
    let {disabled, translations, onChangeTranslation, onChange, tagGroupType, onTagGroupChange, onTagCategoryChange, selectedTagGroup, disableTagGroups = false, order, types, showOnSmartSearch, onShowOnSmartSearchChange, childrens, parents} = this.props;

    return (
      <div>
        <FormControl fullWidth>
          <span>{localization.get('category')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('select')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onTagCategoryChange}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
            value={tagGroupType}
            loadOptions={(inputValue, callback) => {
              callback(types.filter(i =>
                i.label.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputValue.toLowerCase())
              ))
            }}
            defaultOptions={types.sort((a, b) => a.label.localeCompare(b.label))}
            disabled={disabled || disableTagGroups}
          />
        </FormControl>

        <br/>
        {tagGroupType &&
          <FormControl fullWidth>
          <span>{localization.get('tag_groups')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
              theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('select')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onTagGroupChange}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
            value={selectedTagGroup}
            loadOptions={(inputValue) =>
            request.tagGroup.getAllPrivate({filterBy: [{column: "name", filter: inputValue}, {column: "type", filter: tagGroupType.value}]})
              .then(tagGroups => tagGroups.sort((a, b) => a.name.localeCompare(b.name))
              .map(tagGroup => ({value: tagGroup.id, label: tagGroup.name, types: tagGroup.types, hasChildrens: tagGroup.childrens.length > 0 })))}
            defaultOptions
          />
            {!!selectedTagGroup && selectedTagGroup.types.length > 1 && <span>Esta etiqueta se vera en: {selectedTagGroup.types.filter(type => type.contentCreatorType).map(type => type.contentCreatorType.name).join()}</span>}
          </FormControl>
        }

        <br/>
        <TextValidator
          style={{ width: "100%" }}
          id="order"
          label={localization.get('form.order')}
          name="order"
          value={order}
          onChange={onChange}
          disabled={disabled}
          validators={['isNumber']}
          errorMessages={[localization.get('validator.is_number')]}
        />
        <FormControlLabel
          style={{float: "left", width: "100%",marginTop: 30}}
          control={
            <Checkbox
              style={{color: "black"}}
              checked={showOnSmartSearch}
              onChange={onShowOnSmartSearchChange}
            />
          }
          label={localization.get('form.show_on_smart_search')}
        />

        <br/>
        <br/>
        <TranslatableInputs
          ref={instance => this.child = instance}
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  id={`name${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.name')}
                  name={`name${language}`}
                  value={currentLangTranslation.name}
                  onChange={e => onChangeTranslation(e.target.value, "name", language)}
                />
              ]
            }
          })}
        />
        {selectedTagGroup && 
        <FormControl fullWidth>
          <span>{localization.get('tag.parents')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('tag.parents')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              value={parents}
              isMulti
              isDisabled
            />
            <br/>
            {selectedTagGroup.hasChildrens && 
              <>
                <span>{localization.get('tag.childrens')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('tag.childrens')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={(childrens) => onChange({target: {id: 'childrens', value: childrens}})}
                  value={childrens}
                  isMulti
                  defaultOptions
                  loadOptions={inputValue =>
                    request.tag.getAll({
                      filterBy: [{ column: "name", filter: inputValue }, {column: "parent_tag_group_id", filter: selectedTagGroup.value}]
                    }).then(res => res.map(tg => ({ value: tg.id, label: tg.name })))
                  }
                />
              </>
            }
          </FormControl>
          }
      </div>
    )
  }
}

export default FormView
