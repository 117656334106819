import React, {useState} from "react";
import _DatePicker from "react-datepicker";
import classNames from "classnames";
import moment from "moment";
import localization from "../../../config/localization";
import "./WebLikeFormHelpers.css";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";

export const FormContainer = ({ children, errors, touched, onChange }) => (
  <div style={{ width: "100%", maxWidth: 500, padding: 20, margin: "auto" }}>
    {React.Children.map(children, child =>
      React.cloneElement(child, { errors, touched, onChange })
    )}
  </div>
);

export const Title = ({ children }) => (
  <h1 className="web-like-h1">{children}</h1>
);

export const SectionTitle = ({ children }) => (
  <h4 className="web-like-h4">{children}</h4>
);

export const FieldTitle = ({ children }) => (
  <div style={{ color: "#808080", marginTop: 16, lineHeight: 0.5 }}>
    {children}
  </div>
);

export const Input = ({
  field,
  errors,
  touched,
  type,
  placeholder,
  onChange,
}) => (
  <p>
    <input
      className={classNames("web-like-input", {
        "web-like-error": Boolean(touched[field] && errors[field])
      })}
      type={type}
      placeholder={placeholder}
      onChange={e => onChange(field, e.target.value)}
    />
    {touched[field] && errors[field] && (
      <label className="web-like-error">{errors[field]}</label>
    )}
  </p>
);

export const Checkbox = ({
  field,
  errors,
  touched,
  onChange,
  label,
  style = {}
}) => (
  <p style={style}>
    <input
      className={classNames("web-like-input", {
        "web-like-error": Boolean(touched[field] && errors[field])
      })}
      type="checkbox"
      onChange={e => onChange(field, e.target.value)}
      style={{width: "auto"}}
    />
    {label && (
      <label>{label}</label>
    )}
    {touched[field] && errors[field] && (
      <label className="web-like-error">{errors[field]}</label>
    )}
  </p>
);


export const DatePicker = ({
  field,
  errors,
  touched,
  type,
  placeholder,
  onChange,
  onBlur
}) => {
  const [date, setDate] = useState();

  return (
    <p>
      <div
        className={classNames("web-like-input", {
          "web-like-error": Boolean(touched[field] && errors[field])
        })}
        type={type}
        placeholder={placeholder}
        style={{ backgroundColor: "white", padding: 0 }}
      >
        <_DatePicker
          selected={date}
          customInput={<DatePickerInput />}
          dateFormat={"dd/MM/yyyy"}
          onChange={date => {
            onChange(
              field,
              moment(date).format("YYYY-MM-DD")
            );
            setDate(date);
          }}
          onClickOutside={onBlur}
          showYearDropdown
        />
      </div>
      {touched[field] && errors[field] && (
        <label className="web-like-error">{errors[field]}</label>
      )}
    </p>
  );
};

const DatePickerInput = ({ onClick, value }) => (
  <div onClick={onClick} className="web-like-datepicker">
    {value || <span style={{ color: "#ccc" }}>dd/mm/yyyy</span>}
  </div>
);

export const Select = ({
  errors,
  touched,
  onChange,
  field,
  placeholder,
  options
}) => (
  <p>
    <select
      className={classNames("web-like-select", {
        "web-like-error": Boolean(touched[field] && errors[field])
      })}
      style={{
        height: 40,
        fontSize: 14,
        backgroundColor: "#fff"
      }}
      onChange={e => onChange(field, e.target.value)}
    >
      <option value={""}>{placeholder}</option>
      {options.map(o => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
    {touched[field] && errors[field] && (
      <label className="web-like-error">{errors[field]}</label>
    )}
  </p>
);

export const TextArea = ({
  errors,
  touched,
  onChange,
  field,
  type,
  placeholder
}) => (
  <p>
    <textarea
      className={classNames("web-like-textarea", {
        "web-like-error": Boolean(touched[field] && errors[field])
      })}
      type={type}
      placeholder={placeholder}
      onChange={e => onChange(field, e.target.value)}
    />
    {touched[field] && errors[field] && (
      <label className="web-like-error">{errors[field]}</label>
    )}
  </p>
);

export const MediaPicker = ({
  errors,
  touched,
  onChange,
  field,
  title,
  accept
}) => (
  <p
    className={classNames("web-like-media-picker-container", {
      "web-like-error": Boolean(touched[field] && errors[field])
    })}
  >
    <label className="web-like-media-picker-title">{title}</label>
    <input
      type="file"
      accept={accept}
      multiple
      onChange={e => onChange(field, e.target.files)}
    />
    {touched[field] && errors[field] && (
      <label className="web-like-error">{errors[field]}</label>
    )}
  </p>
);

export const TagGroup = ({ field, label, tags, selectedTags, onChange }) => {
  return (
    <div style={{marginBottom: 5}}>
      <label style={{color: "#3C4858"}}>{label}</label>
      <div
        style={{
          display: "flex",
          marginTop: 3,
          flexWrap: "wrap"
        }}
      >
        {tags.map((tag, key) => (
          <div
            className={classNames("voice-talent-tag", {
              selected: selectedTags.includes(tag)
            })}
            key={key}
            onClick={() => {
              if (selectedTags.includes(tag)) {
                onChange(
                  field,
                  selectedTags.filter(selectedTag => selectedTag !== tag)
                );
              } else {
                if (
                  selectedTags.filter(
                    selectedTag => selectedTag.tagGroupId === tag.tagGroupId
                  ).length > 3
                ) {
                  GlobalSnackbar.show({
                    message: localization.get("public_form.max_tag_per_group"),
                    type: GlobalSnackbarTypes.ERROR
                  });
                } else {
                  onChange(field, selectedTags.concat(tag));
                }
              }
            }}
          >
            {tag.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export const SubmitButton = ({ onClick, disabled, hasErrors }) => {
  return (
    <>
      {/** Button */}
      <p className="web-like-submit">
        <div
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
        >
          {localization.get("form.send")}
        </div>
      </p>
      {/** Error Message */}
      {hasErrors && (
        <div style={{ marginTop: "1rem", textAlign: "center", color: "red" }}>
          {localization.get("form.errors_present")}
        </div>
      )}
    </>
  );
};
