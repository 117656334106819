import {
  get
} from "../utils";
import {getActiveClient} from "../authentication/utils";

export default host => (musicId, client) => {
  let url = `${host}/clients/${client}/files/${musicId}?include=audio:url`;

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
    .then(data => data.audio.url)
}