import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

class All extends Component {

  componentDidMount = () => {
      document.title = localization.get('title.catalogs') + ' - Feater'
  }

  onAdd = () => {
    let url = '';
    if(this.props.location.pathname.includes('music')) {
      url = makeUrlWithTableParams(`music_catalogs/create`);
    } else if (this.props.location.pathname.includes('talents')) {
      url = makeUrlWithTableParams(`talents_catalogs/create`);
    } else {
      url = makeUrlWithTableParams(`audiovisual_catalogs/create`);
    }
    this.props.history.push(url)
  }

  render = () =>
    <AllViewContainer onAdd={this.onAdd} >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default All;
