import {
  get
} from "../utils";

export default host => (artistId, audioId) => {
  let url = `${host}/public/artists/${artistId}/audios/${audioId}`;

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
    .then(data => data.url)
}
