import {post} from "../utils";
import {makeJsonData} from "./utils";

export default host => ({
    name,
    visibility,
    type,
    email,
    detail,
    client,
    googleAnalyticsId,
    googleTagManagerId,
    customUrl,
    channelContentCreatorTypes,
    translations,
    catalogs,
    socialLinks,
    imageId,
    loginImageId,
    footerImageId,
    sidebarImageId,
    faviconId,
    showBanner,
    showAdvancedSearch,
    showTagsSearch,
    showSupport,
    links,
    showAbout,
    tags,
    navbarElements,
    binaryId,
    policyId,
    bloomcloudChannel,
    loginType,
    languages,
    canDownloadTracks,
    keywordsSEO,
    descriptionSEO,
    tagGroups,
    supportNegativeSearch,
    trackingGroup,
    showLoginButton,
    preRollVideoId,
    extraRegistration,
    showTagSelection,
    searchType,
    hasEvents,
    showFilterOnSmartSearch,
    radioEnabled,
    radioLink,
    commercialMail,
    addresses,
    channelShipments,
    communityCatalog,
    communityCCTypes,
    communityTranslations,
    hasCommunity,
    customShare,
    channelRedirects,
    googleAdsensePublisherId,
    adUnitHomeDesktop,
    adUnitHomeMobile,
    adUnitContentDesktop,
    adUnitContentMobile,
    showDate,
    noIndex,
    customScripts,
    isDemo,
    skipTimePreRoll,
    communityRequiresApproval,
    communityPreferences,
    communityEmail,
    useLicenses,
    usePlaylists,
    useBrandButton,
    editableFor,
    purpose,
    mobileNavbarType,
    useSidebarDesktop
    }) => {
  return post(`${host}/channels`,
    makeJsonData({
        name,
        visibility,
        type,
        email,
        detail,
        client,
        googleAnalyticsId,
        googleTagManagerId,
        customUrl,
        channelContentCreatorTypes,
        translations,
        catalogs,
        socialLinks,
        imageId,
        loginImageId,
        footerImageId,
        sidebarImageId,
        faviconId,
        showBanner,
        showAdvancedSearch,
        showTagsSearch,
        showSupport,
        links,
        showAbout,
        tags,
        navbarElements,
        binaryId,
        policyId,
        bloomcloudChannel,
        loginType,
        languages,
        canDownloadTracks,
        keywordsSEO,
        descriptionSEO,
        tagGroups,
        supportNegativeSearch,
        trackingGroup,
        showLoginButton,
        preRollVideoId,
        extraRegistration,
        showTagSelection,
        searchType,
        hasEvents,
        showFilterOnSmartSearch,
        radioEnabled,
        radioLink,
        commercialMail,
        addresses,
        channelShipments,
        communityCatalog,
        communityCCTypes,
        communityTranslations,
        hasCommunity,
        customShare,
        channelRedirects,
        googleAdsensePublisherId,
        adUnitHomeDesktop,
        adUnitHomeMobile,
        adUnitContentDesktop,
        adUnitContentMobile,
        showDate,
        noIndex,
        customScripts,
        isDemo,
        skipTimePreRoll,
        communityRequiresApproval,
        communityPreferences,
        communityEmail,
        useLicenses,
        usePlaylists,
        useBrandButton,
        editableFor,
        purpose,
        mobileNavbarType,
        useSidebarDesktop
    })
  )
    .then(response => response.json())
    .then(response => response.data)
}