import React, {Component} from "react";

import {request} from "api-client";
import Form from '../Form'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  requestTagGroups = (tagSystemId) => request.tagGroup.getAllOfTypeMusic(tagSystemId)
    .then(tgs => {
      this.refs.form.setTagGroups(tgs, !tagSystemId);
      return tgs
    })

  requestCreation = async (state) => {
    this.setState({ disabled: true })

    const {
      active,
      showInRecents,
      code,
      title,
      selectedTags,
      selectedAlbums,
      bichannel,
      artist,
      inactiveReason,
      inactiveReasonDropdown,
      owner,
      audioId,
      audioAiffId,
      videoId,
      selectedBatchAudioFile
    } = state
    

    return request.music.create(
      {
        active,
        showInRecents,
        code,
        title,
        tagIds: selectedTags,
        audioId,
        audioAiffId,
        videoId,
        albumIds: selectedAlbums && [selectedAlbums.value.albumId],
        bichannel,
        artistId: artist.value,
        inactiveReason,
        owner,
        catalogId: selectedAlbums && selectedAlbums.value.catalogId,
        batchAudioId: selectedBatchAudioFile && selectedBatchAudioFile.value
      })
      .then(() => this.props.history.push(makeUrlWithTableParams('/panel/musics')))
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => this.setState({ image })

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('music.create_music')}
        submitRequest={this.requestCreation}
        initializationRequest={() => this.requestTagGroups()}
        trackCreation={true}
        requestTagGroups={this.requestTagGroups}
      />
    )
  }
}
