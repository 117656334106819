import {isNullOrUndefined} from "util";

export const makeFormData = (translations, tagGroupId, order, showOnSmartSearch, childrens) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
    })
  }

  if (!isNullOrUndefined(showOnSmartSearch)) form.append('show_on_smart_search', showOnSmartSearch ? 1 : 0)
  if (tagGroupId) form.append('tag_group_id', tagGroupId)
  if (order) form.append('order', order)

  childrens.map((children) => form.append(`children_ids[]`, children))

  return form
}

export const root = '/tags'

export const includeQuery = 'include=group.types;parents'