import "layouts/Home/Home.css";
import React from "react";
import pagesStyle from "../../../assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";
import localization from "../../../config/localization";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import styled from "styled-components";
import {addHttpToUrl } from "../../../utils";
import {getActiveSection, getStoredChannel, rootPath} from "../../../api-client/core/authentication/utils";
import {getSocialLinkIcon} from "../../../components/Letflow/SocialLinks";
import Logo from "../../../assets/img/logo-white.png";
import analyticsInstance from "../../../analytics/analyticsInstance";
import browserHistory from "../../../utils/browserHistory";
import {makeFitImageUrl} from "../../../utils";
import {makeCustomLink, makeCustomClick} from "./utils";
import {get} from "lodash";
import {convertFromRaw, EditorState} from "draft-js";
import RichEditor from "../../../components/Letflow/RichTextEditor/EditorialEditor";
import {Dialog} from "@material-ui/core"
import Contact from "../../Home/Contact";
import {request} from "../../../api-client";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: "close",
      height: "0%",
      showContactDialog: false,
      editorContent: null,
    };
  }

  fixResponsiveEmbedSize = () => {
    let language = localization.getLanguage();
    const currentTranslation = get(this.props.channel, 'footer_preferences.translations', []).find(tr => tr.locale === language)

    let editorContent = currentTranslation && JSON.parse(currentTranslation.title);
    if (!editorContent) return;
    let entityMap = editorContent.entityMap;
    if (entityMap) {
      Object.entries(entityMap).forEach(([k,v]) => {
        let isEmbed = v.type == "EMBEDDED_LINK";
        let isImg = v.type == "IMAGE";
        if ( isEmbed || isImg ) {
          if (v.data && v.data.width) {
            let maxWidth = (window.innerWidth < 600) ? (window.innerWidth - 40) : (window.innerWidth - 140);
            let width = v.data.width.endsWith("px") ? v.data.width.substr(0, v.data.width.length -2) : null;
            if (!width || (width > maxWidth)) {
              if (isEmbed) {
                v.data.width = `${maxWidth}px`;
                v.data.height = `${Math.round(maxWidth/16*9)}px`;
              } else if (isImg) {
                v.data.width = v.data.width == "auto" ? "auto" : `${maxWidth}px`;
                v.data.height = "auto";
              }
            }
          }
        }
      })
      if (window.innerWidth <= 600) {
        let blocks = editorContent.blocks;
        if (blocks) {
          blocks.map(b => {
            b.inlineStyleRanges = b.inlineStyleRanges.map(isr => {
              isr.style = (isr.style && isr.style.startsWith("fontsize-")) ? `fontsize-${Math.round(Number(isr.style.split("fontsize-")[1]) * this.state.mobileFontResize)}` : isr.style;
              return isr;
            });
            return b;
          });
        }
      }
    }
    this.setState({editorContent: editorContent});
  }


  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({cardAnimation: ""});
      }.bind(this),
      700
    )
    this.fixResponsiveEmbedSize();
  }


  footerLogo = () => {
    const {channel} = this.props
    const socialPosition = get(channel, "footer_preferences.social_position", "center");
    const useSocials = get(channel, "footer_preferences.footer_social_with_logo", "1") == "1";

    const image = get(channel, "footerImage") || get(channel, "image");

    return (
      <div className="footer-column">
        <Link
          className="channel-navbar-image-container-link"
          style={{width: "auto"}}
          onClick={(e) => {
            if(getActiveSection()) {
              e.preventDefault();
              browserHistory.push(rootPath())
            }
          }}
          to={rootPath()}>
          <img 
            style={{marginBottom: 15, position: "relative", float: "left", maxWidth: "100%"}}
            src={image ? makeFitImageUrl({image: image}, 250, 40, '', 'png') : Logo}
            alt={`Logo - ${channel.name}`} />
          </Link>
          {useSocials && this.footerSocials(socialPosition)}
      </div>
    );
  }

  footerSocials = (socialPosition = "center") => {
    const {channel} = this.props

    return (
      <div style={{display: "flex", flexDirection: "row", justifyContent: socialPosition === "left" ? "start" : socialPosition === "right" ? "end" : "center", flexWrap:"wrap"}}>
        {channel.socialLinks.map(socialLink => 
          <Tooltip title={`${socialLink.type.capitalize()}`} placement="bottom">
            <Link className="footer-social-link"
              onClick={() => analyticsInstance.footerInteraction({type: "Social_Network"})}
              style={{display: 'flex', alignItems: 'center'}}
              target="_blank"
              to={addHttpToUrl(socialLink.url)}
              rel="nofollow">
              {getSocialLinkIcon(socialLink.type)}
            </Link>
          </Tooltip>
        )}
      </div>
    )
  }
  

  

  render() {
    const {channel, footerElements} = this.props;
    const footerType = get(channel, 'footer_preferences.type', "simple");
    const footerMargin = get(channel, 'footer_preferences.margin', "default");
    
    return (
      <footer style={{padding: 0, position: (this.props.modalFooter || this.props.loginChannel ) && 'fixed' }}>
        <Dialog open={this.state.showContactDialog} onBackdropClick={() => this.setState({showContactDialog: false})} maxWidth="false">
          <div style={{width: window.innerWidth <= 800 ? "75vw" :700 }}>
            <Contact
              modal={true}
              onSubmit={(name, email, description) => request.channel.contact(name, email, description)}
              afterSubmit={() => this.setState({showContactDialog: false})}/>
          </div>
        </Dialog>
        <div className="footer-bottom" style={{animation: channel && "ease-in 1s", bottom: 0}}>
          <div className="container-footer">            
          {footerType === "free" && typeof footerElements != 'undefined' &&
            <EditorContainer mobileDefaultFontSize={Math.round(14 * this.state.mobileFontResize)}>
              {this.state.editorContent && <RichEditor
                editorState={EditorState.createWithContent(convertFromRaw(this.state.editorContent))}
                readOnly={true}
                onChange={() => console.log('read only')}
              />}
            </EditorContainer>
          }
          {footerType === "advanced" && typeof footerElements != 'undefined' &&
            <MobileDiv2 style={{margin: footerMargin == "default" ? "30px 0px" : "0"}} full={true}>
              {get(channel, 'footer_preferences.logo_side', "left") === "left" && this.footerLogo()}
              {get(channel, 'footer_preferences.logo_side', "left") === "right" && get(channel, 'footer_preferences.footer_social_with_logo', "1") == "0" && <div className="footer-column">{this.footerSocials()}</div>}
              {footerElements.filter(e => !e.parent).map(element => 
                <div className="footer-column">
                  <FooterTitle style={{fontWeight: "bold", margin: "10px 0"}}>{element.title}</FooterTitle>  
                  <FooterColumnContainer>
                    {footerElements.filter(e => e.parent == element.id).map(element => 
                      <Link
                        className="link-footer"
                        to={makeCustomLink(element)}
                        onClick={event => {
                          if (element.data_source == "contact_form") {
                            event.stopPropagation()
                            event.preventDefault()
                            this.setState({showContactDialog: true})
                          }
                          makeCustomClick(event, element)
                        }}
                        style={{textAlign: "center", margin: window.innerWidth > 600 ? "7px 5px": "7px 10px"}}
                      >
                        {element.title}
                      </Link>
                    )}
                  </FooterColumnContainer>
                </div>
              )}
              {get(getStoredChannel(), 'footer_preferences.logo_side', "left") === "right" && this.footerLogo()}
              {get(channel, 'footer_preferences.logo_side', "left") === "left" && get(channel, 'footer_preferences.footer_social_with_logo', "1") == "0" && <div className="footer-column">{this.footerSocials()}</div>}  
            </MobileDiv2>
          }
          {footerType !== "simple" && typeof footerElements != 'undefined' && 
          <>
            <div style={{width: "100%", height: "1px", background: "var(--footer-font-color, white)", opacity: "0.5"}}></div>
            <div style={{display: "flex"}}>
              {!!channel && (channel.bloomcloud_channel || channel.demo) &&
                <a style={{color: 'inherit', margin: "7px 15px 0px 0px"}} onClick={() => analyticsInstance.footerInteraction({type: "Feater"})} href="http://feater.me" target="_blank">
                  <img style={{ width: 60, filter: 'brightness(0.5) opacity(0.5)' }} src={Logo} alt="Logo" />
                </a>
              }
              <FooterDocumentLink
                requestRoute={(channel && channel.binary) ? channel.binary.url : `${process.env.REACT_APP_API_HOST}/documents/privacyPolicy/download?locale=${localization.getLanguage()}`}
                title={localization.get('footer.privacy_policy')}
              />
              <FooterDocumentLink
                requestRoute={(channel && channel.policy) ? channel.policy.url : `${process.env.REACT_APP_API_HOST}/documents/termsOfUse/download?locale=${localization.getLanguage()}`}
                title={localization.get('footer.term_of_use')}
              />
            </div>
          </>
        }

          {(footerType == "simple" || typeof footerElements == 'undefined') &&
            <div className="row-footer-bottom">
            <MobileDiv>
              {!!channel && channel.bloomcloud_channel &&
              <span style={{ marginRight: 13, marginTop: -5}}>
                <a style={{color: 'inherit'}} onClick={() => analyticsInstance.footerInteraction({type: "Feater"})} href="http://feater.me" target="_blank">
                  <img style={{ width: 60, filter: 'brightness(0.5) opacity(0.5)' }} src={Logo} alt="Logo" />
                </a>
              </span>
              }
              {channel && channel.binary &&
                <FooterDocumentLink
                  requestRoute={channel.binary.url}
                  title={localization.get('footer.privacy_policy')}
                />
              }
              {channel && channel.policy &&
                <FooterDocumentLink
                  requestRoute={channel.policy.url}
                  title={localization.get('footer.term_of_use')}
                />
              }
            </MobileDiv>

            <MobileUl className="nowrap">
              {channel && !!channel.show_support && !!channel.email &&
                <li>
                  <Link to={"#"} onClick={(e) => {
                    e.preventDefault()
                    this.setState({showContactDialog:true})
                  }}>
                    <span className="link-footer">{localization.get("footer.support")}</span>
                  </Link>
                </li>
              }
              {!!channel && channel.socialLinks && channel.socialLinks.length > 0 ?
                <li>
                  {channel.socialLinks.map(socialLink => {
                    return (
                      <Tooltip title={`${socialLink.type.capitalize()}`} placement="bottom">
                        {/* <Link className="footer-social-link" style={{top: channel && !!channel.show_support && !!channel.email ? 10 : 0}} target="_blank" to={addHttpToUrl(socialLink.url)} rel="nofollow"> */}
                        <Link className="footer-social-link" onClick={() => analyticsInstance.footerInteraction({type: "Social_Network"})} style={{display: 'flex', alignItems: 'center'}} target="_blank" to={addHttpToUrl(socialLink.url)} rel="nofollow">
                          {getSocialLinkIcon(socialLink.type)}
                        </Link>
                      </Tooltip>
                    )
                  })
                  }
                </li>
              : null
              }
            </MobileUl>
          </div>
          }
        </div>
      </div>
    </footer>
    );
  }
}

const FooterDocumentLink = withStyles({
  title: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
})(({ classes, requestRoute, title }) => {
  return (
    <Link to={requestRoute} target="_blank" rel="nofollow">
      <h6 style={{ marginRight: 20, color: "var(--footer-font-color, white)" }}>
        <small className={classes.title}>
          {title}
        </small>
      </h6>
    </Link>
  );
});

export default withStyles(pagesStyle)(Footer);

const MobileDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

const MobileUl = styled.ul`
margin: -5px;
place-self: end;
display: flex;
flex-direction: row;
align-items: center;
@media (max-width: 600px) {
  margin: 0;
  place-self: center;
}
`;

const MobileDiv2 = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: ${props => props.full ? "space-between" : "space-around"};
@media (max-width: 600px) {
  flex-direction: column;
  align-items: center;
}
`;

const FooterTitle = styled.h4`
font-weight: bold;
text-align: center;
margin: 0px 0px 10px!important;
@media (max-width: 600px) {
  margin: 15px 0;  
}
`;

const FooterColumnContainer = styled.div`
display: flex;
flex-direction: column;
@media (max-width: 600px) {
  flex-direction: row;
  flex-wrap: wrap;
}
`

const EditorContainer = styled.div`
  .rdw-link-decorator-icon {
    display: none;
  }
  overflow-wrap: break-word;
`;