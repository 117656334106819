export const LicenseTypeIds = {
  CORPORATIVO_CANALES_PROPIOS: 1,
  PUBLICIDAD_INSTITUCIONAL: 2,
  PUBLICIDAD_INSTITUCIONAL_ARGENTINA: 3,
  isLicenseType: function(typeId) {
    return (
      typeId === this.CORPORATIVO_CANALES_PROPIOS ||
      typeId === this.PUBLICIDAD_INSTITUCIONAL ||
      typeId === this.PUBLICIDAD_INSTITUCIONAL_ARGENTINA
    );
  },
  toArray: () => [
    LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS,
    LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL,
    LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA,
  ],
};

export const MediaTypeIds = {
  INTERNET: 1,
  RADIO: 2,
  TELEVISION: 3,
  ALL_MEDIA: 4,
  toArray: () => [MediaTypeIds.INTERNET, MediaTypeIds.RADIO, MediaTypeIds.TELEVISION, MediaTypeIds.ALL_MEDIA],
  includesInternet: function(typeId) {
    return typeId === this.INTERNET || typeId === this.TELEVISION || typeId === this.ALL_MEDIA;
  },
};

export const RegionIds = {
  GLOBAL: 1,
  NORTH_AMERICA: 2,
  LATAM: 3,
  EUROPE: 4,
  ASIA: 5,
  PACIFIC: 6,
  ARGENTINA: 7,
};

export const CountriesForRegion = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  ALL: 4,
};

export const Exclusivity = {
  NONE: 1,
  FOREVER: 2,
  THREE_MONTHS: 3,
  TWELVE_MONTHS: 4,
};

export const SadaicCategories = {
  RESTRINGIDO_I: 1,
  RESTRINGIDO_II_III: 2,
  RESTRINGIDO_IV: 3,
  USO_TOTAL: 4,
  VPNT: 5,
  INCLUSION_EN_TV: 6
};

export const SadaicDurations = {
  SIX_MONTHS: 180,
  TWELVE_MONTHS: 360,
};

export const SadaicSeconds = {
  NONE: -1,
  UP_TO_TEN: 1,
  UP_TO_TWENTY: 2,
  UP_TO_THIRTY: 3,
  UP_TO_FORTY_FIVE: 4,
  UP_TO_FIFTY: 5,
  UP_TO_SIXTY: 6,
  MORE_THAN_SIXTY: 7,
  UP_TO_FIFTEEN: 8
};

export const InternetBudgets = {
  UP_TO_2000: 1,
  UP_TO_5000: 2,
  MORE_THAN_5000: 3,
};
