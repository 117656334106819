import {put,} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => (files ,material) => {
  const reel = {}
  reel.files_ids = [];

  files.forEach(file =>
    reel.files_ids.push(file.id)
  )

  reel.title = material.title
  reel.color = material.color
  reel.secondColor = material.secondColor
  reel.thirdColor = material.thirdColor
  reel.fourthColor = material.fourthColor
  reel.viewType = material.viewType

  return put(`${host}/clients/${getActiveClient()}/reel/${material.id}`, JSON.stringify(reel))
    .then(response => response.json())
}