import React, {Component} from "react";
import _ from "lodash";
import {IconButton,  Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {
  Exclusivity,
  InternetBudgets,
  LicenseTypeIds,
  MediaTypeIds,
  SadaicCategories,
  SadaicDurations,
  SadaicSeconds,
} from "./utils/constants";
import LicenseTypeScreen from "./screens/LicenseTypeScreen";
import MediaSelectionScreen from "./screens/MediaSelectionScreen";
import "./style/LicenseWizard.css";
import RegionSelectionScreen from "./screens/RegionSelectionScreen";
import SadaicPlansScreen from "./screens/SadaicPlansScreen";
import AdditionalsScreen from "./screens/AdditionalsScreen";
import LicenseWizardSidebar from "./LicenseWizardSidebar";
import {request} from "../../../api-client";
import PersonalizeLicenseScreen from "./screens/PersonalizeLicense";
import browserHistory from "utils/browserHistory";
import GlobalLicenseWizardDialog from "../Dialog/GlobalLicenseWizardDialog";
import { regionIdAndCountriesForRegionToExtent} from "./utils/mappers";
import EventManager, {events} from "../../../utils/EventManager";
import SadaicPriceCalculator from "./utils/SadaicPriceCalculator";
import classNames from "classnames";
import localization from "../../../config/localization"
import {
  channelPathGenerator,
  getStoredUser,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import {get} from "lodash";

class LicenseWizard extends Component {
  static makeDefaultSadaicVersions = () => [
    { label: "Comercial", seconds: SadaicSeconds.NONE, reductions: 0 },
    { label: "Version 1", seconds: SadaicSeconds.NONE, reductions: 0 },
    { label: "Version 2", seconds: SadaicSeconds.NONE, reductions: 0 },
    { label: "Version 3", seconds: SadaicSeconds.NONE, reductions: 0 },
    { label: "Version 4", seconds: SadaicSeconds.NONE, reductions: 0 },
    { label: "Version 5", seconds: SadaicSeconds.NONE, reductions: 0 },
  ];

  constructor(props) {
    super(props);
    this.state = this.makeDefaultState();
    this.screenHistory = [];
    this.stateHistory = [];
  }

  makeDefaultState = () => ({
    // license state
    licenseType: LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS,
    regions: [],
    medias: [],
    reductions: 0,
    exclusivity: Exclusivity.NONE,
    extraDuration: false,
    biChannel: false,
    editionAccordingToSeconds: false,
    clientName: get(getStoredUser(), "brand.name", (this.props.client && this.props.client.name)) || "",
    brandName: "",
    productName: "",
    campaignName: "",
    sadaicDuration: SadaicDurations.SIX_MONTHS,
    sadaicCategory: SadaicCategories.RESTRINGIDO_I,
    sadaicVersions: LicenseWizard.makeDefaultSadaicVersions(),
    internetBudget: InternetBudgets.UP_TO_5000,
    enableLicenseButton: false,
    unlimitedReductions: false,
    sending: false,

    // component state
    currentScreen: null,
    prices: null,
    discount: 0,
    requestExclusivity: false,
    exclusivityReason: "",
  });

  componentDidUpdate = prevProps => {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.handleShow();
      }
    }
  };

  handleShow = () => {
    if (this.props.initializeWithValues) {
      this.setState({
        ...this.makeDefaultState(),
        ...this.props.initializeWithValues,
        currentScreen: this.licenseTypeScreen(),
      });
    } else {
      this.setState({ ...this.makeDefaultState(), currentScreen: this.personalizeLicenseScreen() });
    }
    this.screenHistory = [];
  };

  onChangeDiscount = discount => this.setState({discount: discount})

  //#region Screens

  makeScreen = ({
    showPreviousButton = () => false,
    showNextButton = () => false,
    render = null,
    nextScreen = () => null,
    enableLicenseButton = () => false,
  }) => ({
    showPreviousButton,
    showNextButton,
    render,
    nextScreen,
    enableLicenseButton,
  });

  licenseTypeScreen = () => {
    return this.makeScreen({
      showNextButton: () => this.state.licenseType !== null,
      render: () => (
        <LicenseTypeScreen
          client={this.props.client}
          licenseType={this.state.licenseType}
          reductions={this.state.reductions}
          unlimitedReductions={this.state.unlimitedReductions}
          onChange={v => this.setState(v)}
        />
      ),
      nextScreen: () => {
        switch (this.state.licenseType) {
          case LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS:
            return this.personalizeLicenseScreen();
          case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL:
            return this.mediaSelectionScreen();
          default:
            return this.sadaicPlansScreen();
        }
      },
    });
  };

  mediaSelectionScreen = () => {
    return this.makeScreen({
      showPreviousButton: () => true,
      showNextButton: () => this.state.medias.length > 0,
      render: () => (
        <MediaSelectionScreen
          medias={MediaTypeIds.toArray()}
          internetBudget={this.state.internetBudget}
          selectedMedias={this.state.medias}
          onChange={v => this.setState(v)}
        />
      ),
      nextScreen: () => {
        const medias = this.state.medias;
        if (medias.length === 1 && medias[0] === MediaTypeIds.INTERNET) {
          return this.additionalsScreen({ dontShow: ["extraDuration", "reductions"] });
        }
        return this.regionSelectionScreen();
      },
    });
  };

  regionSelectionScreen = () => {
    return this.makeScreen({
      showPreviousButton: () => true,
      showNextButton: () => this.state.regions.length > 0,
      render: () => (
        <RegionSelectionScreen
          selectedRegions={this.state.regions}
          onChange={newRegions => this.setState({ regions: newRegions })}
        />
      ),
      nextScreen: () => {
        const medias = this.state.medias;
        const dontShow = ["threeMonthExclusivity"];
        if (medias.includes(MediaTypeIds.ALL_MEDIA)) {
          dontShow.push("biChannel", "editionAccordingToSeconds");
        }
        if (medias.includes(MediaTypeIds.TELEVISION) || medias.includes(MediaTypeIds.RADIO)) {
          dontShow.push("editionAccordingToSeconds");
        }
        return this.additionalsScreen({ dontShow });
      },
    });
  };

  sadaicPlansScreen = () => {
    return this.makeScreen({
      showPreviousButton: () => true,
      showNextButton: () =>
        this.state.sadaicVersions.some(x => x.seconds != SadaicSeconds.NONE) ||
        this.state.sadaicCategory === SadaicCategories.VPNT ||
        this.state.sadaicCategory === SadaicCategories.INCLUSION_EN_TV,
      render: () => (
        <SadaicPlansScreen
          sadaicCategory={this.state.sadaicCategory}
          sadaicDuration={this.state.sadaicDuration}
          sadaicVersions={this.state.sadaicVersions}
          onChange={e => {
            this.setState(e);
          }}
        />
      ),
      nextScreen: () => this.personalizeLicenseScreen(),
    });
  };

  additionalsScreen = ({ dontShow = [] } = {}) => {
    return this.makeScreen({
      showPreviousButton: () => true,
      showNextButton: () => true,
      render: () => (
        <AdditionalsScreen
          exclusivity={this.state.exclusivity}
          extraDuration={this.state.extraDuration}
          biChannel={this.state.biChannel}
          editionAccordingToSeconds={this.state.editionAccordingToSeconds}
          reductions={this.state.reductions}
          onChange={modifiedProperties => this.setState(modifiedProperties)}
          dontShow={dontShow}
        />
      ),
      nextScreen: () => this.personalizeLicenseScreen(),
    });
  };

  personalizeLicenseScreen = () => {
    return this.makeScreen({
      showPreviousButton: () => false,
      showNextButton: () => false,
      enableLicenseButton: () =>
        this.state.campaignName.length > 0 &&
        this.state.clientName.length > 0 &&
        this.state.brandName.length > 0 &&
        (!this.state.requestExclusivity || this.state.exclusivityReason.length > 0),
      render: () => (
        <PersonalizeLicenseScreen
          campaignName={this.state.campaignName}
          campaignDescription={this.state.campaignDescription}
          clientName={this.state.clientName}
          brandName={this.state.brandName}
          requestExclusivity={this.state.requestExclusivity}
          exclusivityReason={this.state.exclusivityReason}
          onChange={e => this.setState(e)}
        />
      ),
    });
  };

  //#endregion

  render = () => {

    if (!this.props.show) return null;

    return (
      <div className="license-wizard">
        <div className={"license-wizard-background"} />
        <div className={"license-wizard-container"}>
          <div className={"license-wizard-main"}>
            <div className={"license-wizard-main-title"}>
              <div>{localization.get("license_wizard.licenses")}</div>
            </div>
            <div className={"license-wizard-main-content"}>{this.currentScreen()}</div>
            <div className={"license-wizard-main-navigation-buttons"}>
              <IconButton
                style={{ visibility: this.buttonVisibility("previous"), height: "60px", width: "60px" }}
                onClick={this.previousScreen}
              >
                <ArrowBack />
              </IconButton>
              <div className={classNames("license-wizard-next-button", { hidden: !this.showNavigationButton("next") })}>
                <div style={{ visibility: this.buttonVisibility("next"), display: "flex", alignItems: "center" }}>
                  <Typography style={{ marginRight: 10 }}>{localization.get('license_wizard.next')}</Typography>
                  <IconButton style={{ height: "60px", width: "60px" }} onClick={this.nextScreen}>
                    <ArrowForward />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          {this.makeSidebar()}
        </div>
      </div>
    );
  };

  currentScreen = () => this.state.currentScreen && this.state.currentScreen.render();

  nextScreen = () => {
    const currentScreen = this.state.currentScreen;
    const currentState = _.cloneDeep(this.state);
    this.stateHistory.push(currentState);
    this.setState({ currentScreen: currentScreen.nextScreen() });
  };

  previousScreen = () => {
    const { clientName, productName, campaignName, brandName } = this.state;
    const previousState = this.stateHistory.pop();
    this.setState({ ...previousState, clientName, productName, campaignName, brandName });
  };

  buttonVisibility = type => (this.showNavigationButton(type) ? "visible" : "hidden");

  showNavigationButton = type =>
    this.state.currentScreen && this.state.currentScreen[type === "next" ? "showNextButton" : "showPreviousButton"]();

  makeSidebar = () => {
    const openLicenseConfirmationDialog = (currency, price) => 
      this.submitLicense().then(license => {
        GlobalLicenseWizardDialog.hide();
        browserHistory.push(channelPathGenerator(`account/licenses?new_license_id=${license.id}`));
        EventManager.getInstance().dispatch(events.LICENSED_MUSIC, license);
      })

    const onLicenseClick = () => {
      if (this.state.licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA) {
        openLicenseConfirmationDialog(
          "ARS",
          new SadaicPriceCalculator(
            this.state.prices,
            this.state.sadaicVersions.filter(version => version.seconds != SadaicSeconds.NONE),
            this.state.sadaicCategory,
            this.state.sadaicDuration
          ).calculate()
        );
      } else if (this.state.licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL && !userIsSysAdmin()) {
        if(!this.state.sending) {
          this.setState({sending:true}, () => {
            this.submitLicense().then(license => {
              GlobalLicenseWizardDialog.hide();
              GlobalSnackbar.show({
                message: localization.get('license.pend'),
                type: GlobalSnackbarTypes.SUCCESS,
              })
              EventManager.getInstance().dispatch(events.LICENSED_MUSIC, license);

              this.setState({sending: false})
            })
          })
        }
      } else {
        this.licenseRequestWrapper(request.license.getPriceForLicense)
        .then(price => {
          openLicenseConfirmationDialog("USD", price);
        });
      }
    };

    return (
      <LicenseWizardSidebar
        {...this.props}
        {...this.state}
        onChangeDiscount={this.onChangeDiscount}
        discount={this.state.discount}
        sadaicVersions={this.state.sadaicVersions.filter(version => version.seconds != SadaicSeconds.NONE)}
        enableLicenseButton={this.state.currentScreen && this.state.currentScreen.enableLicenseButton()}
        onLicenseClick={onLicenseClick}
        licensePreviewUrl={this.makeLicensePreviewUrl}
      />
    );
  };

  makeLicensePreviewUrl = () => this.licenseRequestWrapper(request.license.getLicensePreviewUrl);

  submitLicense = () => this.props.toApprove ? this.licenseRequestWrapper(request.license.approve, this.props.toApprove) : this.licenseRequestWrapper(request.license.create);

  licenseRequestWrapper = (handler, toApprove = null) => {
    const extents_ids = this.state.regions.map(region =>
      regionIdAndCountriesForRegionToExtent(region.id, region.countriesForRegion)
    );

    const versions = this.state.sadaicVersions
      .filter(sv => sv.seconds !== SadaicSeconds.NONE)
      .map(sv => ({ length_id: sv.seconds, reductions: sv.reductions }));

    const project = {
      allow_spotlight: false,
      customer: this.state.clientName,
      product: this.state.productName,
      title: this.state.campaignName,
      brand: this.state.brandName,
    };
    const license = {
      license_type_id: this.state.licenseType,
      bichannel: this.state.biChannel,
      budget_id: this.state.internetBudget,
      category_id: this.state.sadaicCategory,
      duration: this.state.sadaicDuration,
      edition: this.state.editionAccordingToSeconds,
      exclusivity: this.state.exclusivity === Exclusivity.THREE_MONTHS,
      extension: this.state.extraDuration,
      extents_ids,
      regions_ids: this.state.regions.map(region => region.id),
      medias_ids: this.state.medias,
      modification: true,
      music_id: this.props.track.id,
      reductions: this.state.reductions,
      unlimited_reductions: this.state.unlimitedReductions,
      versions,
      project,
      client_id: this.props.client.id,
      discount: this.state.discount,
      request_exclusivity: this.state.requestExclusivity,
      exclusivity_reason: this.state.exclusivityReason,
    }

    return toApprove ? handler(toApprove.toApprove, license) : handler(license);
  };
}

LicenseWizard.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  track: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    waveform: PropTypes.string.isRequired,
  }).isRequired,
  client: PropTypes.shape({
    id: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  initializeWithValues: PropTypes.object,
};

export default LicenseWizard;
