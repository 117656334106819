import React from "react";
import {ClientSidePaginationTable} from "../../components/Letflow/Table";
import ErrorBoundary from "../../components/Letflow/ErrorBoundary";

const BaseTable = ({ title, data, columns }) =>
  <ErrorBoundary>
    <h3>{title}</h3>
    <ClientSidePaginationTable
      data={data}
      columns={columns}
      filterable={false}
      showPagination={false}
      sortable={false}
      zebraStyle={false}
    />
  </ErrorBoundary>

export default BaseTable