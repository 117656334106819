import get from './get'
import getAll from './getAll'
import del from './delete'
import create from './create'
import update from './update'
import makeMain from './makeMain'

export default host => ({
  get: get(host),
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  update: update(host),
  makeMain: makeMain(host),
})