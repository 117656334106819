import React from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, MenuItem, Radio, RadioGroup, Select, FormControl, FormControlLabel, Button } from "@material-ui/core";
import TagSelection from "../TagSelection";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import { get } from "lodash";
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import { getActiveChannel, getStoredChannel } from "../../../api-client/core/authentication/utils";

class UpdateManyMaterialsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagGroupsAll: [],
      selectedTags: [],
      retagMethod: "add",
      updateVisible: "keep",
      updateDownloadable: "keep",
      updateAccessLevel: "keep",
      loading: true,
    }
  }

  componentDidMount = () => {
    console.log(this.props.contentCreatorType)
    if (this.props.contentCreatorType) {
    request.tagGroup.getAll({filterBy: [
        { column: 'type', filter: this.props.contentCreatorType.slug },
        { column: 'inheritable', filter: true }
      ]})
      .then(tags => this.setState({ tagGroupsAll: tags, loading: false }))
    }
  }

  clearAllRetags = () =>
    new Promise(resolve => {
      this.setState( {
        selectedRetags: [],
        tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    })

    updateRetagColor = (selectedTag, value) =>
      this.setState({
        tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
            return {...tagGroup,
              tags: tagGroup.tags.map(tag => {
                if (tag.id === selectedTag) {
                  return {...tag, state: value}
                }
                return tag
              })
            }
          }
        )
      })

    onSelectedRetagsChanged = selectedTag => {
      if (this.state.selectedTags.includes(selectedTag)) {
        this.setState({
          selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag),
        }, () => this.updateRetagColor(selectedTag, "available"))
      }else {
        this.state.selectedTags.push(selectedTag);
        this.setState({selectedTags: this.state.selectedTags}, () => this.updateRetagColor(selectedTag, "selected"))
      }
    };


  handleSubmit = () => {
    if (this.state.selectedTags.length > 0) {
      GlobalConfirmationDialog.showDoubleConfirmation({
        dialogTitle: localization.get('form.retag'),
        elementName: localization.get('form.double_confirmation_retag_many.label'),
        content: "confirmation_dialog.delete_many_materials",
        auxiliaryInformation: localization.get('form.double_confirmation_retag_many.aux.creator', this.props.selectedContentCreations.length),
        doubleConfirmation: true,
        doubleConfirmationText: "form.double_confirmation_retag_many",
        confirmationLabel: "form.retag",
        onSuccessText: localization.get('form.retag.success'),
        onFailText: localization.get('form.dialog.fail'),
        updateRequest: () => request.contentCreation.retagManyInChannel(getActiveChannel(), {
          reTags: this.state.selectedTags,
          retagMethod: this.state.retagMethod,
          updateVisible: this.state.updateVisible,
          updateAccessLevel: this.state.updateAccessLevel,
          updateDownloadable: this.state.updateDownloadable,
          contentCreatorTypeId: this.props.contentCreatorType.id,
          contentCreationsIds: this.props.selectedContentCreations,
        }).then(this.handleClose),
      })
    }
  }
  
  handleClose = () => {
    this.setState({
      selectedTags: [],
      retagMethod: "add",
      updateVisible: "keep",
      updateDownloadable: "keep",
      updateAccessLevel: "keep"
    });
    this.clearAllRetags();
    this.props.onClose();
  }

  render = () => {

    return (
      <Dialog open={this.props.open} onClose={this.handleClose} onBackdropClick={this.handleClose}>
        <DialogTitle>{localization.get('form.batch_retag_alert')}</DialogTitle>
        <DialogContent style={{textAlign: "center"}}>
          <h4 style={{textAlign: "left", color: "red"}}>{localization.get('form.retag.method')}</h4>
          <Select
            style={{width: "100%"}}
            onChange={event => this.setState({retagMethod: event.target.value})}
            value={this.state.retagMethod}
          >
            <MenuItem value="add">{localization.get('form.retag.add')}</MenuItem>
            <MenuItem value="remove">{localization.get('form.retag.remove')}</MenuItem>
            <MenuItem value="mirror">{localization.get('form.retag.mirror')}</MenuItem>
          </Select>
          <h4 style={{textAlign: "left", marginTop: 20, position: "relative", float: "left"}}>{localization.get('form.tags')}</h4>
          {this.state.loading ? <p>Loading...</p> :
            <TagSelection
              homeElementTagSelection={true}
              allowUnselected={false}
              data={this.state.tagGroupsAll}
              onSelectedTagsChanged={tagId => this.onSelectedRetagsChanged(tagId)}
              clearAllTags={this.clearAllRetags}
            />
          }
          <br/>
          <br/>
          <FormControl style={{float: "left", position: "relative", width: "100%"}}>
            <h4 style={{textAlign: "left"}} >{localization.get('form.downloadable')}</h4>
            <RadioGroup
                name="updateDownloadable"
                value={this.state.updateDownloadable}
                onChange={event => this.setState({ updateDownloadable: event.target.value })}
                style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
                <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
                <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.downloadable')} />
                <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.downloadable.not')} />
            </RadioGroup>
            <h4 style={{textAlign: "left"}} >{localization.get('form.visibility')}</h4>
            <RadioGroup
                name="updateVisible"
                value={this.state.updateVisible}
                onChange={event => this.setState({ updateVisible: event.target.value })}
                style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
              <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.visible')} />
              <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.visible_not')} />
            </RadioGroup>
          </FormControl>
          {get(getStoredChannel(), 'client.has_access_level') &&   
            <>
              <h4 style={{textAlign: "left"}} >{localization.get('form.update_access_level')}</h4>
              <RadioGroup
                name="updateAccessLevel"
                value={this.state.updateAccessLevel}
                onChange={event => this.setState({ updateAccessLevel: event.target.value })}
                style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
              >
                <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
                <FormControlLabel value={"0"} control={<Radio color="default"/>} label={localization.get('smart_search.none')} />
                {["1","2","3","4","5"].map(level =>
                  <FormControlLabel
                      value={level}
                      control={<Radio color="default"/>}
                      label={level}
                  />
                )}
              </RadioGroup>
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit}>
            {localization.get('form.retag')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default UpdateManyMaterialsDialog;