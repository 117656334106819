import "./index.css";

const separateFirstFromLastName = fullName => {
  try {
    const splitted = fullName.split(" ");
    const firstName = splitted.slice(0, 1).join("");
    const lastName = splitted.slice(1).join(" ");

    return {
      firstName,
      lastName
    };
  } catch (e) {
    return {
      firstName: fullName,
      lastName: undefined
    };
  }
};

const initializeFreshchat = (id, name, email, roleId, clientId) => {
  try {
    window.fcWidget.init({
      token: process.env.REACT_APP_FRESHCHAT_ID,
      host: "https://wchat.freshchat.com",
      externalId: id,
      config: {
        headerProperty: {
          hideChatButton: true,
          direction: "ltr"
        }
      }
    });
    window.fcWidget.user.setProperties({
      ...separateFirstFromLastName(name),
      email,
      roleId,
      clientId
    });
  } catch (e) {
    console.warn("Could not initialize FreshChat");
  }
};

const data = {
  loaded: false,
  id: undefined,
  name: undefined,
  email: undefined,
  roleId: undefined,
  clientId: undefined
};

const freshChat = {
  anonymous: () => {
    data.id = undefined;
    data.name = undefined;
    data.email = undefined;
    data.roleId = undefined;
    data.clientId = undefined;
  },
  loggedIn: (id, name, email, roleId, clientId) => {
    data.id = id;
    data.name = name;
    data.email = email;
    data.roleId = roleId;
    data.clientId = clientId;
  },
  open: ({ channel } = {}) => {
    if (!data.loaded) {
      var script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", "https://wchat.freshchat.com/js/widget.js");
      script.onload = () => {
        data.loaded = true;
        initializeFreshchat(
          data.id,
          data.name,
          data.email,
          data.roleId,
          data.clientId
        );
        window.fcWidget.open({ name: channel });
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    } else {
      window.fcWidget.open({ name: channel });
    }
  }
};

export default freshChat;
