import {ItemSelectionTables} from "../../../../components/Letflow/Table";
import localization from "../../../../config/localization";
import TableButton from "../../../../components/Letflow/Table/Buttons/TableButton";
import {Visibility} from "@material-ui/icons";
import {request} from "../../../../api-client";
import React from "react";

export const ContentSelectionTables = ({onSelectedItemsChanged, onRemovedItemsChanged, selectedContent, removedContent, channelId, onOrderChange}) =>
  <ItemSelectionTables
    columns={[
      {Header: localization.get('table.name'), accessor: 'name'},
      {
        Header: localization.get('table.category'),
        id: 'content_creator_type',
        accessor: value => value.type ? value.type.name : '-',
        sortable: false,
        filterable: false
      }
    ]}
    extraButtonFactories={[
      creator => <TableButton title={localization.get('button.see_home')} onClick={() => window.open(`${window.location.origin}/preview/content_creators/${creator.hashcode}` , "_blank")}><Visibility/></TableButton>,
    ]}
    requestPaginatedItems={paginatedConfig => request.contentCreator.getCandidates(channelId, paginatedConfig)}
    selectedItemsChanged={onSelectedItemsChanged}
    removedItemsChanged={onRemovedItemsChanged}
    selectedTableTitle={<h4>{localization.get('form.selected_content')}</h4>}
    toSelectTableTitle={<h4>{localization.get('form.select_content')}</h4>}
    selected={selectedContent}
    removed={removedContent}
    channelPlaylist
    onOrderChange={onOrderChange}
  />
