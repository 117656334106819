import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveClient} from "../authentication/utils";

const url = (host, options, files) => appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/files/ids?ids=${files}&include=image;audio;audio.waveform;binary;video`, { ...options,
  alreadyHasQueryParams: true
})

export default host => (options, files) =>
  get(url(host, options, files))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)