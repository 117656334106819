import _ from "lodash";
import {LicenseTypeIds} from "../../../components/Letflow/LicenseWizard/utils/constants";

const secondPairs = [[1, 10], [2, 20], [3, 30], [4, 45], [5, 50], [6, 60], [7, 70], [8, 15]];

const durationPairs = [[180, 6], [360, 12]];

export const mapDurationToApiDuration = duration => durationPairs.find(pair => pair[1] == duration)[0];

export const mapSecondsToApiSeconds = seconds => secondPairs.find(pair => pair[1] == seconds)[0];

const mapDuration = duration => durationPairs.find(pair => pair[0] == duration)[1];

const mapSeconds = seconds => secondPairs.find(pair => pair[0] == seconds)[1];

const mapApiLength = (category, duration) => seconds => ({
  category: parseInt(category[0]),
  months: mapDuration(parseInt(duration[0])),
  seconds: mapSeconds(parseInt(seconds[0])),
  price: parseInt(seconds[1].money)
});

const mapApiDuration = category => duration => _.entries(duration[1].length).map(mapApiLength(category, duration));

const mapApiCategories = category => _.entries(category[1].duration).map(mapApiDuration(category));

/**
 * @description Maps api sadaic prices into an array of { category, months, seconds, price } so the front can read it.
 */
const mapSadaicPricesFromApi = apiPrices => {
  return _.flatMap(
    _.flatMap(
      _.entries(apiPrices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA].category).map(
        mapApiCategories
      )
    )
  );
};

export default mapSadaicPricesFromApi;
