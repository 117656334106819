import {Howl, Howler,} from 'howler'
import {Subject} from 'rxjs';

class SoundPlayer {

  constructor() {

    this.currentSound = null
    
    this.currentSoundId = null

    this.trackEnded$ = new Subject()

    this.trackPaused$ = new Subject()

    this.trackStartedPlaying$ = new Subject()

    this.trackCurrentTimeInSeconds$ = 0
  }

  play = (audio, second = 0) => {

    if (this.currentSound !== null) {
      this.currentSound.unload()
    }

    this.currentSound = new Howl({
      src: audio,
      html5: true,
      onend: () => this.trackEnded$.next({}),
      onplay: () => this.trackStartedPlaying$.next({}),
    })

    if ( second > 0 ) this.playCurrentTrackAtSecond(second)
    
    this.currentSoundId = this.currentSound.play()
  }

  playCurrentTrackAtSecond = second => {
    if (this.currentSound) {
      this.currentSound.seek(second)
    }
  }

  isPlaying = () => {
    if (this.currentSound) {
      return this.currentSound.playing(this.currentSoundId)
    }
    return false
  }

  isUnavailable = () => {
    if (this.currentSound) {
      return this.currentSound.state() === 'unloaded' || this.currentSound.state() === 'loading'
    }
    return true
  }

  pause = () => {
    if (this.currentSound) {
      this.trackPaused$.next({})
      this.currentSound.pause(this.currentSoundId)
    }
  }

  resume = () => {
    if (this.currentSound) {
      this.currentSound.play(this.currentSoundId)
    }
  }

  /**
   * @param {number} volume from 0 - 1
   */
  setVolume = volume => Howler.volume(volume)

  getVolume = () => Howler.volume()

  getCurrentTrackPlaytimeInSeconds = () => {
    if (this.currentSound) {
      const seconds = this.currentSound.seek()
      if (isNaN(seconds)) {
        return 0
      }
      return seconds
    }
    return 0
  }

  getCurrentTrackDurationInSeconds = () => {
    if (this.currentSound) {
      return this.currentSound.duration()
    } else {
      return 0
    }
  }

}

export default SoundPlayer