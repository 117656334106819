import {get} from "../utils";
import {getActiveChannel} from "../authentication/utils";

const url = (host, musicIds, clientId) => {
  const musicIdsForUrl = musicIds.join(",");
  const include = "albums;audio.waveform;albums.image;tags;artist";
  let url = `${host}/${getActiveChannel() ? `channels/${getActiveChannel()}/` : ""}musics/suggestions?include=${include}&music_ids=${musicIdsForUrl}`;
  if (clientId) {
      url += `&client_id=${clientId}`;
  }
  return url;
};

export default host => (musicIds, clientId = null) =>
  get(url(host, musicIds, clientId))
    .then(res => res.json())
    .then(res => res.data);
