import {post} from '../utils'

export default host => (channelId, {
  name,
  productIds,
  imageId,
}) => {
  return post(`${host}/channels/${channelId}/contentPacks`,JSON.stringify({
    "name": name,
    "product_ids": productIds,
    "imageId": imageId,
  }))
    .then(response => response.json())
}