import React, {Component} from "react";

import FormViewContainer from "components/Letflow/ViewContainer/FormViewContainer";
import {TextField, Typography} from "@material-ui/core";
import {request} from "../../api-client";

import browserHistory from "../../utils/browserHistory";
import MessageCreator from "./MessageCreator";
import localization from "../../config/localization";
import ConversationUsersSelector from "./UsersSelector/UsersSelectorContainer";

export default class extends Component {

  state = {
    subject: "",
    firstMessageContent: ""
  };

  constructor(props) {
    super(props);
    this.selectUserRef = React.createRef();
  }

  submitRequest = async () => {
    const requestConversationCreation = users => request.messaging
      .createConversation({
        users,
        subject: this.state.subject,
        initialMessage: this.state.firstMessageContent
      })
      .then(() => browserHistory.goBack())

    const users = await this.selectUserRef.current.getUsers()

    await requestConversationCreation(users)
  }

  render = () => {
    return (
      <FormViewContainer
        title={localization.get('new_conversation')}
        submitRequest={this.submitRequest}
        onBack={() => this.props.history.goBack()}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
      >
        <Sep />
        {/** Users selector */}
        <ConversationUsersSelector
          {...this.props}
          ref={this.selectUserRef}
        />
        <hr />
        <Sep />
        {/** Subject */}
        <Typography variant="title">{localization.get('first_message')}</Typography>
        <Sep />
        <TextField
          label={localization.get('conversation.subject')}
          value={this.state.subject}
          onChange={e => this.setState({ subject: e.target.value })}
          multiline
          fullWidth
        />
        <Sep />
        {/** First message */}
        <MessageCreator
          content={this.state.firstMessageContent}
          onChange={e => this.setState({ firstMessageContent: e })}
        />
      </FormViewContainer>
    );
  }
}

const Sep = () => <div style={{ marginBottom: 20 }} />;
