import {appendPaginationQueryParamsToUrl, del, get, post, put} from "../utils";
import JSZip from "jszip";
import {request} from "../../index";

/**
 * @typedef CreateData
 * @property project_name
 * @property project_details
 * @property participants
 * @property first_message
 * @property client_id
 */

/**
 * @typedef Material
 * @property type
 * @property url
 * @property video_file
 * @property playlist_id
 */


export default host => ({
  /**
   * @param {CreateData} data
   */
  create: async (data, { addAllAdmins } = {}) => {
    const form = new FormData();

    form.append("project_name", data.project_name);
    form.append("project_details", data.project_details);

    if (data.clients_ids) form.append("client_id", data.client_id);

    if (data.first_message) form.append("first_message", data.first_message);

    if (!data.participants) {
      data.participants = [];
    }

    data.participants.forEach((participant, i) => {
      form.append(`participants[${i}][id]`, participant.id);
      form.append(`participants[${i}][is_admin]`, participant.is_admin ? 1 : 0);
    });

    data.clients_ids.forEach(clientId => {
      form.append('clients_ids[]', clientId);
    });

    let url = `${host}/pitches`;

    if (addAllAdmins) {
      url += "?add_all_administrators";
    }

    return await post(url, form).then(res => res.json());
  },




  getAllForClient: (clientId, includeAll = false, options) =>
    get( appendPaginationQueryParamsToUrl(`${host}/pitches?client_id=${clientId}&include=featuredImage.image&order_by=created_at&sort_type=DESC&includeAll=${includeAll}`, {
      ...options,
      alreadyHasQueryParams: true
      })
    )
    .then(res => res.json())
    .then(json => json.data),


  list: async (page, perPage, options = {}) => {
    let url = `${host}/pitches?include=clients&page=${page}&per_page=${perPage}`;

    if (options.clientId) {
      url += `&client_id=${options.clientId}`;
    }

    if (options.projectName) {
      url += `&project_name=${options.projectName}`;
    }

    if (options.projectDetails) {
      url += `&project_details=${options.projectDetails}`;
    }

    if (options.includeAll) {
      url += `&include_non_participation=true`;
    }

    if (options.clientId) {
      url += `&client_id=${options.clientId}`;
    }

    const res = await get(url).then(res => res.json());

    delete res.meta.data;

    res.data = res.data.map(pitch => {
      // this property is undefined when pitches for this user are requested,
      // this means the property should always be true
      if (pitch.user_belongs_to_pitch === undefined) {
        pitch.user_belongs_to_pitch = true;
      }

      return pitch;
    });

    return res;
  },

  show: pitchId => get(`${host}/pitches/${pitchId}`).then(res => res.json()),

  delete: pitchId => del(`${host}/pitches/${pitchId}`),

  showFirstMessages: (pitchId, { filterByMessage } = {}) => {
    let url = `${host}/pitches/${pitchId}/messages/first?`;
    if (filterByMessage) url += "content=" + filterByMessage + "&";
    url += "result_seen=true";
    return get(url).then(res => res.json());
  },

  showLastMessages: (pitchId, { filterByMessage } = {}) => {
    let url = `${host}/pitches/${pitchId}/messages/last?`;
    if (filterByMessage) url += "content=" + filterByMessage + "&";
    url += "result_seen=true";
    return get(url).then(res => res.json());
  },

  showNextMessages: (pitchId, messageId, { filterByMessage, amount } = {}) => {
    let url = `${host}/pitches/${pitchId}/messages/${messageId}/next?`;
    if (filterByMessage) url += "content=" + filterByMessage + "&";
    if (amount) url += "amount=" + amount + "&";
    url += "result_seen=true";
    return get(url).then(res => res.json());
  },

  showPreviousMessages: (
    pitchId,
    messageId,
    { filterByMessage, amount } = {}
  ) => {
    let url = `${host}/pitches/${pitchId}/messages/${messageId}/previous?`;
    if (filterByMessage) url += "content=" + filterByMessage + "&";
    if (amount) url += "amount=" + amount + "&";
    url += "result_seen=true";
    return get(url).then(res => res.json());
  },

  sendMessage: (pitchId, message) =>
    post(`${host}/pitches/${pitchId}/messages`, JSON.stringify({ message })),

  /**
   * @param {Material} material
   */
  sendMaterial1: async (pitchId, material, onProgress) => {
    const form = new FormData();

    switch (material.type) {
      case "url":
        form.append("url", material.url);
        break;
      case "video":
        form.append(
          "video_file",
          material.video_file,
          material.video_file.name
        );
        break;
      case "audio":
        form.append(
          "audio_file",
          material.audio_file,
          material.audio_file.name
        );
        break;
      case "other":
        if (material.other_files.length === 1) {
          form.append(
            "other_file",
            material.other_files[0],
            material.other_files[0].name
          );
        }
        if (material.other_files.length > 1) {
          const zip = new JSZip();

          material.other_files.forEach(x => {
            zip.file(x.name, x);
          });

          await zip.generateAsync({ type: "blob" }).then(blob => {
            form.append(
              "other_file",
              blob,
              material.other_files_name
                ? material.other_files_name + ".zip"
                : "compilation.zip"
            );
          });
        }
        break;
      case "playlist":
        form.append("playlist_id", material.playlist_id);
        break;
      default:
        throw new Error("Invalid type: " + material.type);
    }

    form.append("type", material.type);
    if (material.message) form.append("message", material.message);

    return await post(
      `${host}/pitches/${pitchId}/materials`,
      form,
      null,
      onProgress
    );
  },

  sendMaterial:  async (pitchId, material, onProgress) => {
    const presignedUrlForm = new FormData();
    const messageForm = new FormData();
    let file;

    switch (material.type) {
      case "url":
        presignedUrlForm.append("url", material.url);
        break;
      case "video":
        file = material.video_file;
        presignedUrlForm.append('video_file', material.video_file.name);
        break;
      case "image":
        file = material.image_file;
        presignedUrlForm.append('image_file', material.image_file.name);
        break;
      case "audio":
        file = material.audio_file;
        presignedUrlForm.append('audio_file', material.audio_file.name);
        break;
      case "other":
        if (material.other_files.length === 1) {
          file = material.other_files[0]
          presignedUrlForm.append('other_file', material.other_files[0].name)
        }
        if (material.other_files.length > 1) {
          const zip = new JSZip();

          material.other_files.forEach(x => {
            zip.file(x.name, x);
          });

           await zip.generateAsync({ type: "blob" }).then(blob => {

            presignedUrlForm.append('other_file', material.other_files_name ? material.other_files_name + ".zip" : "compilation.zip");

             file = blob
          });
        }
        break;
      case "playlist":
        presignedUrlForm.append("playlist_id", material.playlist_id);
        break;
      default:
        throw new Error("Invalid type: " + material.type);
    }

    presignedUrlForm.append("type", material.type);
    messageForm.append("type", material.type);
    if (material.message) messageForm.append("message", material.message);

    return await post(
      `${host}/pitches/${pitchId}/generatePresignedUrl`,
      presignedUrlForm,
      null
    )
      .then(response => response.json())
      .then( response => {
        if(response.presigned_url != null && response.file_path != null) {

          messageForm.append("path", response.file_path);
          return put(
            response.presigned_url,
            file,
            {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': 'attachment'
            },
            onProgress,
            true,
            false
          ).then( () => {
              return post(
                `${host}/pitches/${pitchId}/sendMessageWithMaterial`,
                messageForm
              )}
            )
        }
      })
      .catch((e) => request.log(JSON.stringify(e), 'error'))
  },

  addParticipant: (pitchId, userId) =>
    post(
      `${host}/pitches/${pitchId}/participants`,
      JSON.stringify({ id: userId})
    ),

  removeParticipant: (pitchId, userId) =>
    del(`${host}/pitches/${pitchId}/participants/${userId}`),

  promoteParticipant: (pitchId, userId) =>
    post(`${host}/pitches/${pitchId}/participants/${userId}/promote`),

  demoteParticipant: (pitchId, userId) =>
    post(`${host}/pitches/${pitchId}/participants/${userId}/demote`),

  publicMaterial: (pitchId, messageId) =>
    get(`${host}/public/pitches/${pitchId}/materials/${messageId}`).then(res =>
      res.json()
    ),

  publicApprovedMaterial: (pitchId, messageId) =>
    get(`${host}/public/online_videos/${pitchId}/materials/${messageId}`).then(res =>
      res.json()
    ),

  shareMaterial: (messageId, pitchId, emails, clientId) =>
    post(
      `${host}/pitches/${pitchId}/shareMaterial`,
      JSON.stringify({ message_id: messageId, emails, client_id: clientId })
    )
      .then(res => res.json())
      .then(res => res.data),

  shareApprovedMaterial: (approvedMaterialId, pitchId, emails, clientId) =>
    post(
      `${host}/online_videos/${pitchId}/shareMaterial`,
      JSON.stringify({ message_id: approvedMaterialId, emails, client_id: clientId })
    )
      .then(res => res.json())
      .then(res => res.data),

  isSubscribedToPitches: () =>
    get(`${host}/pitches/receive_emails`)
      .then(res => res.json())
      .then(res => res.value),

  subscribeToPitches: value =>
    put(`${host}/pitches/receive_emails`, JSON.stringify({ value })),

  isSubscribedToPitch: pitchId =>
    get(`${host}/pitches/receive_emails/${pitchId}`).then(res => res.json()),

  subscribeToPitch: (pitchId, value) =>
    put(`${host}/pitches/receive_emails/${pitchId}`, JSON.stringify({ value })),

  downloadMaterialLink: material =>
    `${material}`,

  deleteMessage: id => del(`${host}/pitches/messages/${id}`),

  update : ({id, name, description, status, clientsIds}) => put(`${host}/pitches/${id}`,
    JSON.stringify({ project_name: name, project_details: description, status: status, clients_ids: clientsIds}))
    .then(res => res.json())
    .then(res => res.data),

  updateOrder : (clientId, order) => put(`${host}/clients/${clientId}`,
    JSON.stringify({ pitches_ids: order }))
    .then(res => res.json())
    .then(res => res.data),

  getUnreadMessages: userId =>
    get(`${host}/pitches/unread/${userId}`)
      .then(res => res.json()),

});
