import "./FilesTable.css";
import React from "react";
import localization from "../../../config/localization";
import {Checkbox} from "@material-ui/core";
import Button from "../../CustomButtons/Button";
import _ from 'lodash'
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import {request} from "../../../api-client";
import File from "./File";

class FilesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files || [],
      selectedFiles: this.props.selectedFiles,
      addedFiles: [],
      selectAll: false,
      addingFiles: this.props.addingFiles || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({files: nextProps.files});
    if(this.state.selectedFiles !== nextProps.selectedFiles){
      this.setState({selectedFiles: nextProps.selectedFiles})
    }
    if(this.state.addingFiles !== nextProps.addingFiles){
      this.setState({addingFiles: nextProps.addingFiles})
    }
  }

  addToChecked = selectedFile => {
    if (this.state.addedFiles.includes(selectedFile)) {
      this.setState({addedFiles: this.state.addedFiles.filter(files => files !== selectedFile), selectAll: false})
    }else {
      this.setState({addedFiles: this.state.addedFiles.concat([selectedFile])},
        () => _.intersection(this.state.files.data.map(file => file.id), this.state.addedFiles).length === this.state.files.data.length &&
        this.setState({selectAll: true})
      )
    }
  };

  checkAllFiles = () => {
    if (_.intersection(this.state.files.data, this.state.addedFiles).length === this.state.files.data.length) {
      this.setState({addedFiles: [], selectAll: false})
    }else {
      this.setState({addedFiles: this.state.files.data, selectAll: true})
    }
  };

  handleDelete = file =>
    GlobalConfirmationDialog.showDelete(
      { elementName: file.title,
        deleteRequest: () => request.clientFile.delete(file.id).then(() => this.props.requestFiles({})).then(() => this.forceUpdate())
      });

  addSelected = () => {
    this.props.addMany(this.state.addedFiles);
    this.props.handleCloseSidebar()
  };

  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
  };

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  };

  fullScreen = () => {
    this.videoRef.requestFullscreen()
  };

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()
    }
  };


  render = () => {
    const files = this.state.files.data || this.state.files;
    return (
      <React.Fragment>
        <div className="file-table-container">

          {files.length > 0 ?
            <div className="file-table-head">
              {this.state.addingFiles &&
              <Checkbox
                checked={this.state.selectAll}
                className="check-all-checkbox"
                onChange={this.checkAllFiles}
                color="default"
              />
              }
              <div className="file-table-title">
              </div>
              {this.state.addedFiles.length ?
                <Button
                  className="reels-button"
                  style={{float: "right", width: 120, fontSize: 10, margin: "5px 20px", background: "black"}}
                  onClick={() => this.addSelected()}
                >
                  {localization.get('reel.add_selected').toUpperCase()}
                </Button> : ""
              }
            </div>
            :
            <div className="file-table-head" style={{textAlign:"center", padding: 10}}>
              {localization.get("reel.empty_files")}
            </div>
          }
          {files.filter(file => file.video).length > 0 &&
            (files.filter(file => file.video).map((file) => (
              !this.state.selectedFiles.includes(file.id) &&
               <File
                  video={file}
                  addedFiles={this.state.addedFiles}
                  handleEdit={this.props.handleEdit}
                  handleThumbnailEdit={this.props.handleThumbnailEdit}
                  addToChecked={this.addToChecked}
                  handleDelete={this.handleDelete}
                  onSelectFile={this.props.onSelectFile}
                  selectedFiles={this.state.selectedFiles}
                  addingFiles={this.state.addingFiles}
                  onSelectTag={this.props.onSelectTag}
               />
              )
            )
          )
          }
        </div>
      </React.Fragment>
    )
  }
}

export default FilesTable;