import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  originalMusicId,
  state,
  }) =>
  post(`${host}/originalMusic/${originalMusicId}`, addPutMethodToForm(makeFormData({
    state
  })))
  .then(response => response)