import React, {Component} from "react";
import PropTypes from "prop-types";
import localization from "../../../config/localization";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import EventManager, {events} from "../../../utils/EventManager";

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      doubleConfirmationText: ''
    };
  }

  handleClose = state => {
    const { onClose } = this.props;
    this.setState({doubleConfirmationText: ''})
    EventManager.getInstance().dispatch(
      events.CONFIRMATION_DIALOG_CLOSED,
      this
    );
    onClose && onClose(state);
  };

  handleSubmit = () => {
    const { request } = this.props;

    this.setState({ submitting: true });
    request()
      .then(() => {
        this.setState({ submitting: false });
        this.handleClose("success");
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  render = () => {
    const { submitting } = this.state;
    const {
      open,
      title,
      content,
      confirmationLabel,
      disableSubmit,
      hideCancel,
      doubleConfirmation,
      doubleConfirmationText,
      elementName,
      auxiliaryInformation,
      exitButtonText,
    } = this.props;

    const confirmationButtonText = confirmationLabel || localization.get("confirmation_dialog.accept");
    const exitButtonLabel = exitButtonText || localization.get("confirmation_dialog.cancel");
    return (
      <Dialog open={open} onClose={this.handleClose} disableBackdropClick>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{minWidth: 350}}>
          <React.Fragment>{content}</React.Fragment>
          {auxiliaryInformation &&
            <>
              <br/>
              <React.Fragment>{auxiliaryInformation}</React.Fragment>
            </>
          }
          {doubleConfirmation &&
            <TextField
              style={{width: "100%", marginTop: 20}}
              label={localization.get(doubleConfirmationText)}
              name="doubleConfirmationText"
              value={this.state.doubleConfirmationText}
              onChange={(e) => this.setState({doubleConfirmationText: e.target.value})}
            />
          }
        </DialogContent>
        <DialogActions>
          {!hideCancel && (
            <Button disabled={submitting} onClick={this.handleClose}>
              {exitButtonLabel}
            </Button>
          )}

          <Button
            disabled={submitting || disableSubmit || (doubleConfirmation && this.state.doubleConfirmationText !== elementName)}
            onClick={this.handleSubmit}
          >
            {confirmationButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  confirmationLabel: PropTypes.string,
  request: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disableSubmit: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

export default ConfirmationDialog;
