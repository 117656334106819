import {ROLES} from "../authentication/utils";
import {isNullOrUndefined} from "util";
import moment from "moment";

/**
 * @typedef {object} Client
 * @property {*} client_id
 * @property {*} role_id
 * @property {*} can_license
 */

/**
 * @typedef {object} RequestData
 * @property {string} name
 * @property {string} old_password
 * @property {string} password
 * @property {string} email
 * @property {*} imageFile
 * @property {*} roleId
 * @property {boolean} canCreateClients
 * @property {Client[]} client_roles
 * @property {string} description
 */

/**
 * @param {RequestData} data
 */
export const makeFormData = data => {

  const form = new FormData();

  if (data.name) form.append("name", data.name);
  if (!isNullOrUndefined(data.email)) form.append("email", data.email);
  if (data.roleId) form.append("role_id", data.roleId);
  if (data.client_roles && [ROLES.MULTI_CLIENT, ROLES.SINGLE_CLIENT, ROLES.CONTENT_SUPERVISOR].includes(data.roleId)) {
    data.client_roles.forEach((cr, index) => {
      form.append(`client_roles[${index}][client_id]`, cr.client_id);
      form.append(`client_roles[${index}][role_id]`, cr.role_id);
      form.append(`client_roles[${index}][can_license]`, cr.can_license ? 1 : 0);
      form.append(`client_roles[${index}][access_level]`, cr.access_level);
      form.append(`client_roles[${index}][allow_edit]`, cr.allow_edit ? 1 : 0);
    });
  }
  if (data.oldPassword) form.append("old_password", data.oldPassword);
  if (data.password) form.append("password", data.password);
  if (data.selectedArtists) data.selectedArtists.forEach(x => form.append('artists_ids[]', x))
  if (data.selectedCreators) data.selectedCreators.forEach(x => form.append('content_creators_ids[]', x))
  if (data.selectedClouds) data.selectedClouds.forEach(x => form.append('clouds_ids[]', x))
  if (data.selectedChannels) data.selectedChannels.forEach((x,i) => {
    form.append(`channels[${i}][id]`, x.value)
    form.append(`channels[${i}][restricted]`, x.restricted ? 1 : 0)
    form.append(`channels[${i}][tags_ids]`, x.restrictedTags)
  })
  if (data.language) form.append("language", data.language)
  if (data.imageFile) form.append("image", data.imageFile, data.imageFile.name);
  if (data.selectedCatalogs) data.selectedCatalogs.forEach(x => form.append('catalogs_ids[]', x))
  if (data.clientForEditor) form.append("client_id_for_editors", data.clientForEditor)
  if (data.provider) form.append("provider", data.provider === "default" ? null : data.provider)
  if (data.provider) form.append("provider", data.provider === "default" ? null : data.provider)
  if (data.gcbaId) form.append("gcbaId", data.gcbaId)
  if (data.mibaId) form.append("mibaId", data.mibaId)
  if (data.password) form.append("password", data.password)


  if (!isNullOrUndefined(data.artistView)) form.append("artist_view", data.artistView ? 1 : 0);

  if (!isNullOrUndefined(data.canEditAdmins)) form.append("can_edit_admins", data.canEditAdmins ? 1 : 0);
  if (!isNullOrUndefined(data.canCreateClients)) form.append("can_create_clients", data.canCreateClients ? 1 : 0);
  if (!isNullOrUndefined(data.canSeeLicenses)) form.append("can_see_licenses", data.canSeeLicenses ? 1 : 0);
  if (!isNullOrUndefined(data.canCreateContentCreators)) form.append("can_create_content_creators", data.canCreateContentCreators ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessClients)) form.append("can_access_clients", data.canAccessClients ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessRanking)) form.append("can_access_ranking", data.canAccessRanking ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessConfig)) form.append("can_access_config", data.canAccessConfig ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessLicense)) form.append("can_access_license", data.canAccessLicense ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessReports)) form.append("can_access_reports", data.canAccessReports ? 1 : 0);
  if (!isNullOrUndefined(data.canAccessMusic)) form.append("can_access_music", data.canAccessMusic ? 1 : 0);
  if (!isNullOrUndefined(data.clearClouds)) form.append("clear_clouds", data.clearClouds ? 1 : 0);
  if (!isNullOrUndefined(data.brand)) form.append("brand_id", data.brand);


  if (!isNullOrUndefined(data.description)) form.append("description", data.description);

  if (data.address) {
    form.append(`address[name]`, data.address.name);
    // form.append(`address[active]`, data.address.active ? 1 : 0);
    form.append(`address[address]`, data.address.address);
    form.append(`address[city]`, data.address.city);
    form.append(`address[zip_code]`, data.address.zip_code);
    form.append(`address[google_maps_link]`, data.address.google_maps_link || "");
    form.append(`address[phone]`, data.address.phone || "");
    form.append(`address[description]`, data.address.description || "");
  }

  if (!isNullOrUndefined(data.birthdate)) form.append("birth_date", moment(data.birthdate).format('YYYY-MM-DD'));
  if (!isNullOrUndefined(data.identityNumber)) form.append("identity_number", data.identityNumber);

  return form;
};
