import React, {Component} from "react";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import localization from '../../../../../config/localization'

export class AddMediaSampleDialogContent extends Component {
  
  state = {
    type: "url",
    url: "",
    video: null,
    audio: null,
  };

  render = () => {
    return (
      <React.Fragment>
        <FormControl fullWidth>
          <InputLabel>{localization.get('account.banner.material.type')}</InputLabel>
          <Select
            value={this.state.type}
            onChange={e => {
              this.setState({ type: e.target.value, url: "", video: null, audio: null });
            }}
          >
            <MenuItem value="url">URL</MenuItem>
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="audio">Audio</MenuItem>
          </Select>
        </FormControl>
        {this.renderSampleInput()}
      </React.Fragment>
    );
  };

  renderSampleInput = () => {
    switch (this.state.type) {
      case "url":
        return (
          <TextField
            label={localization.get('account.banner.material.url')}
            value={this.state.url}
            onChange={e => this.setState({ url: e.target.value })}
            disabled={this.props.disabled}
            fullWidth
          />
        );
      case "video":
        return this.renderFilePicker("videoInputRef", "video/*", "video", localization.get('account.banner.material.select_video'));
      case "audio":
        return this.renderFilePicker("audioInputRef", "audio/*", "audio", localization.get('account.banner.material.select_audio'));
    }
  };

  renderFilePicker = (refName, accept, type, label) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        ref={r => (this[refName] = r)}
        style={{ display: "none" }}
        type="file"
        accept={accept}
        disabled={this.props.disabled}
        onChange={e => this.setState({ [type]: e.target.files[0] })}
      />
      <Button
        style={{ backgroundColor: "black", color: "white", width: "100%", marginTop: 12 }}
        onClick={() => this[refName].click()}
      >
        {label}
      </Button>
      {this.state[type] && <span style={{ marginTop: 10 }}>{this.state[type].name}</span>}
    </div>
  );
}
