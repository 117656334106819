import moment from "moment";

const cleanNullOrUndefined = (value) => {
  return (value === null || value === undefined) ? "" : value;
}

export const makeJsonData = ( { dates } ) => {
  const form = {};
  if (dates.length > 0) {
    dates.sort((d1, d2) => (d1.date && d2.date && moment.utc(d1.date).isBefore(moment.utc(d2.date))) ? -1 : 1);
    form.events = dates.map(
      (d) => {
        let momentDate = moment.utc(d.date).set({second: 0})
        return {
          date: momentDate.format('YYYY-MM-DD HH:mm:ss'),
          time: momentDate.format('HH:mm:ss'),
          duration: cleanNullOrUndefined(d.duration),
          link: cleanNullOrUndefined(d.link),
          button_text: cleanNullOrUndefined(d.linkText),
          button_start_date: d.activationDate ? moment.utc(d.activationDate).set({second: 0}).format('YYYY-MM-DD HH:mm:ss') : '',
          clarification: cleanNullOrUndefined(d.clarification)
        }
      }
    )
  }else {
    form.no_events = true;
  }

  return JSON.stringify(form);
}
