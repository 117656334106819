import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

class ContentCreators extends Component {
  state = {
    artists: [],
    perPage: 20,
    page: 0
  };

  componentDidMount = () => {
    document.title = localization.get("content_creators") + " - Feater";
  };

  onAdd = !this.props.isDemo && (() => this.props.history.push(makeUrlWithTableParams(this.props.isTalent ? `/panel/talents/content_creators/create` : this.props.isAudiovisual ? `/panel/audiovisual/content_creators/create` : `/panel/editorial/content_creators/create`)));

  render = () => (
    <AllViewContainer onAdd={this.onAdd} ref="viewContainer">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default ContentCreators;
