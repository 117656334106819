import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {request} from "api-client";
import localization from "config/localization";
import PendingTable from "./PendingTable";

export default class extends Component {

  state = {
    clients: [],
    clientId: null,
    from: null,
    to: null
  }

  componentDidMount = () => {
    document.title = localization.get('title.licenses_report') + ' - Feater'

    request.client.getWithLicense().then(clients => this.setState({ clients }));
  }

  render = () =>
    <AllViewContainer
      onAdd={this.onAdd}
      ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <PendingTable {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>


}