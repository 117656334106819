import {get} from '../utils'
import {includeQuery, root} from './utils'

export default host => (tagSystemId = null, showOnSmartSearch = false) => {
  const appendtagSystemId = tagSystemId ? `&tag_system_id=${tagSystemId}` : '&system_only=true';
  const appendShowOnSmartSearch = showOnSmartSearch ? `&show_on_smart_search=1` : "";

  return get(`${host}${root}?type=music&creation_group=0&${includeQuery}${appendtagSystemId}${appendShowOnSmartSearch}`)
    .then(response => response.json())
    .then(json => json.data)
}

