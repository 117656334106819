import React from "react";
import {ImagePicker} from "../../../components/Letflow/Form";
import {TextValidator} from "react-material-ui-form-validator";
import localization from '../../../config/localization';
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

class FormView extends React.Component {

  findMissingFields = () => this.child.findMissingFields()

  render = () => {
    const {
      disabled,
      translations,
      onChangeTranslation,
      onChange,
      image,
      onImageChange,
      allowImage,
      allowAudio,
      allowVideo,
      allowBinary,
      allowLink,
      onAllowImageChange,
      onAllowAudioChange,
      onAllowVideoChange,
      onAllowBinaryChange,
      onAllowLinkChange,
      limit,
      visibility,
      onClientChange,
      client,
      allowEmbed,
      onAllowEmbedChange,
      type,
      synonyms,
      downloadable,
    } = this.props
    return (
      <div>
        {visibility === 'private' &&
        <React.Fragment>
          <br />
          <FormControl fullWidth>
            <span>{localization.get('select_client')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onClientChange}
              value={client}
              defaultOptions
              loadOptions={inputValue =>{
                let filters = [{column: "name", filter: inputValue}]
                return request.client
                  .getAll({filterBy: filters}, '')
                  .then(res => res.map(client => ({value: client.id, label: client.name})))
              }}
            />
          </FormControl>
        </React.Fragment>
        }

        <TranslatableInputs
          ref={instance => this.child = instance}
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  id={`name${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.name')}
                  name={`name${language}`}
                  value={currentLangTranslation.name}
                  onChange={e => onChangeTranslation(e.target.value, "name", language)}
                />
              ]
            }
          })}
        />
        <TextValidator
          style={{width: "100%"}}
          id="synonyms"
          label={localization.get('form.synonyms')}
          name="synonyms"
          value={synonyms}
          onChange={onChange}
          disabled={disabled}
        />


        {type === 'audiovisual' &&
        <>

          <br/>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={downloadable}
                onChange={() => {
                  onChange({target: {value: !downloadable, id: "downloadable"}});
                }}
              />
            }
            label={localization.get("form.downloadable")}
          />
        <span style={{marginTop: 20, width: "100%", position: "relative", float: "left"}}>{localization.get("table.allowed_materials")}</span>
        <FormControl style={{marginTop: 20, display: "flex", flexDirection: "row"}} fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowImage}
                onChange={() => {
                  onAllowImageChange(!allowImage);
                }}
              />
            }
            label={localization.get("table.allow_image")}
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowAudio}
                onChange={() => {
                  onAllowAudioChange(!allowAudio);
                }}
              />
            }
            label={localization.get("table.allow_audio")}
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowVideo}
                onChange={() => {
                  onAllowVideoChange(!allowVideo);
                }}
              />
            }
            label={localization.get("table.allow_video")}
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowBinary}
                onChange={() => {
                  onAllowBinaryChange(!allowBinary);
                }}
              />
            }
            label={localization.get("table.allow_binary")}
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowLink}
                onChange={() => {
                  onAllowLinkChange(!allowLink);
                }}
              />
            }
            label={localization.get("table.allow_link")}
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={allowEmbed}
                onChange={() => {
                  onAllowEmbedChange(!allowEmbed);
                }}
              />
            }
            label={localization.get("table.allow_embed")}
        />
          </FormControl>

          <br/>

        <br/>
        <TextValidator
          style={{ width: "100%" }}
          id="limit"
          type={'number'}
          label={localization.get('form.limit')}
          name="limit"
          value={limit}
          onChange={onChange}
        />

        </>
        }
        <br/>
        <br/>
        <ImagePicker buttonText={localization.get('form.select_image')} image={image && image.src} onImageChange={onImageChange} disabled={disabled} />
        <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_recommended', 400)}</div>
      </div>
    )
  }
}

export default FormView