import React from "react";
import {Cancel, Done} from "@material-ui/icons";
import {
  TableApprovedButton,
  TableButton,
  TableDeleteButton,
  TableDowloadButton,
  TableEditButton,
  TableNotifyButton,
  TableReviewButton
} from "..";
import TableAddToPlaylistButton from "./TableAddToPlaylistButton";
import TableShareButton from "./TableShareButton";
import TableDuplicatePlaylistButton from "./TableDuplicatePlaylistButton";
import TableHomeButton from "./TableHomeButton";
import TableHomeEditorButton from "./TableHomeEditorButton";
import TableSendMessageButton from './TableSendMessageButton';
import {
  userCanAddTracksToPlaylists,
  userCanDeleteItems,
  userCanDuplicatePlaylists,
  userCanEditItems,
  userCanRequestReservations,
  userCanSharePlaylists,
  userIsSupervisor
} from "../../../../api-client/core/authentication/utils";
import localization from "../../../../config/localization";
import TableRequestReservationButton from "./TableRequestReservationButton";
import TablePlayButton from "./TablePlayButton";

class TableButtonsBuilder {
  _buttons = [];

  withDownload = (download, { title, value, type } = {}) => {
    this._buttons.push(<TableDowloadButton download={download} title={title} value={value} type={type} />);
    return this;
  };

  withApproved = (itemInformation, requestApproved, alertClosed = () => {}, disabled = false, albums) => {
    this._buttons.push(<TableApprovedButton itemInformation={itemInformation} requestApproved={requestApproved} alertClosed={alertClosed} disabled={disabled} albums={albums}/>);
    return this;
  };

  withReview = (itemInformation, requestReview, alertClosed = () => {}) => {
    this._buttons.push(<TableReviewButton itemInformation={itemInformation} requestReview={requestReview} alertClosed={alertClosed} />);
    return this;
  };

  withDelete = (itemInformation, requestDelete, alertClosed = () => {}, doubleConfirmation = false, auxiliaryInformation="", color) => {
    if (userCanDeleteItems()) {
      this._buttons.push(<TableDeleteButton itemInformation={itemInformation} requestDelete={requestDelete} alertClosed={alertClosed} doubleConfirmation={doubleConfirmation} auxiliaryInformation={auxiliaryInformation} color={color}/>);
    }
    return this;
  };

  withNotify = (itemInformation, requestNotify, alertClosed = () => {}) => {
    this._buttons.push(<TableNotifyButton itemInformation={itemInformation} requestNotify={requestNotify} alertClosed={alertClosed} />);
    return this;
  };

  withSendMessage = usersIds => {
    this._buttons.push(<TableSendMessageButton usersIds={usersIds} />);
    return this;
  };

  withEdit = (path, color) => {
    if (userCanEditItems()) {
      this._buttons.push(<TableEditButton color={color} path={path} />);
    }
    return this;
  };

  withHome = path => {
    this._buttons.push(<TableHomeButton path={path} />);
    return this;
  }

  withHomeEditor = path => {
    this._buttons.push(<TableHomeEditorButton path={path} />);
    return this;
  }

  withNewPlay = (tracks, playIndex = 0, color = undefined) => {
    this._buttons.push(<TablePlayButton tracks={tracks} playIndex={playIndex} color={color} />);
    return this;
  };

  withOther = customButton => {
    this._buttons.push(customButton);
    return this;
  };

  withAddToPlaylist = (clientId, selectedTrack, type, handleClose, history, match) => {
    if (userCanAddTracksToPlaylists()) {
      this._buttons.push(
        <TableAddToPlaylistButton clientId={clientId} selectedTrack={selectedTrack} type={type} handleClose={handleClose} history={history} match={match} />
      );
    }
    return this;
  };

  withShare = (entity, id, color, onClick = () => null) => {
    if (userCanSharePlaylists()) {
      this._buttons.push(<TableShareButton entity={entity} id={id} color={color} onClick={onClick}/>);
    }
    return this;
  };

  withDuplicatePlaylist = (clients, playlistId) => {
    if (userCanDuplicatePlaylists()) {
      this._buttons.push(<TableDuplicatePlaylistButton clients={clients} playlistId={playlistId} />);
    }
    return this;
  };

  withHandleReservation = ({ onAcceptReservation, onRefuseReservation }) => {
    if (userIsSupervisor()) {
      this._buttons.push(
        <TableButton onClick={onAcceptReservation} title={localization.get("reservations.accept")}>
          <Done />
        </TableButton>
      );
      this._buttons.push(
        <TableButton onClick={onRefuseReservation} title={localization.get("reservations.decline")}>
          <Cancel />
        </TableButton>
      );
    }
    return this;
  };

  withRequestReservation = (track, onDialogClosed) => {
    if (userCanRequestReservations()) {
      this._buttons.push(<TableRequestReservationButton track={track} onClose={onDialogClosed} />);
    }
    return this;
  };

  build = () => (
    <div style={{ float: "right", minHeight: 35  }}>
      {this._buttons.map((button, index) => (
        <span key={index}>{button}</span>
      ))}
    </div>
  );
}

export default TableButtonsBuilder;
