import React, {Component} from "react";
import {getContentFromMaterial, getMaterialOriginalFileName} from ".";
import styled from "styled-components";


class ImageMessage extends Component {
  render = () => {
    const { message } = this.props;

    const imageSrc = getContentFromMaterial(message);

    return (
      <div style={{ marginTop: 10 }}>
        <ImgR
          alt="photo"
          src={imageSrc}
          controls
        />
        <p>{getMaterialOriginalFileName(imageSrc)}</p>
      </div>
    );
  };
}

export default ImageMessage;

const ImgR = styled.img`
max-width: 600px;
@media (max-width: 950px) {
  max-width: 400px;
}
@media (max-width: 600px) {
    max-width: 300px;
}
@media (max-width: 450px) {
  max-width: 250px;
}
`;
