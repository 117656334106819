import React, {Component} from 'react';
import localization from "../../../config/localization";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel, Radio, RadioGroup,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import AsyncSelect from "react-select/lib/Async";
import TagSelection from "../../../components/Letflow/TagSelection";
import {request} from "../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import TextField from "@material-ui/core/TextField/TextField";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {Link} from "react-router-dom";

const fileTypes = [ 'video', 'binary', 'image', 'audio',];

class CreateMaterialsByLink extends Component{
  state = {
    links: [],
    link: null,
    tagGroups: [],
    selectedTags: [],
    disabled: false,
    allowDownload: false,
    namePrefix: "",
    selectedLabel: {value: "all", label: "All"},
    selectedFileTypes: fileTypes,
    filename: "",
    uploadType: "link",
    disableForm: false,
    files: [],
  }

  componentDidUpdate  = (prevProps, prevState) => {
    if(this.props.open && this.props.open !== prevProps.open) {
      this.setState({allowDownload: this.props.contentCreatorType.downloadable})
      let paginationConfig = {filterBy: [{ column: 'is_talent', filter: this.props.isTalent ? 1 : 0  }, { column: 'for_creations', filter: 1  }]}

      if (this.props.contentCreatorType.client_id) {
        paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: 'client_id', filter: this.props.contentCreatorType.client_id}])}
      }

      if (this.props.cloudId) {
        paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: 'cloud_id', filter: this.props.cloudId}])}
      }

      request.batchUpload.getAll(paginationConfig)
        .then(links => this.setState({
          links: links.map(link => ({
            value: link.id,
            disabled: link.blocked,
            protected: link.protected,
            label: `${link.name} (${link.files_count}) ${link.blocked ? `*${localization.get("batch_upload.using")}*` : ""}`,
            count: link.files_count,
            fileLabels: (link.file_labels && link.file_labels.length > 0) ? link.file_labels.filter(label => label !== "").map(label => {
              return {value: label, label: label}
            }) : [],
            token: link.token,
          }))
        }))
        .then(this.requestTags)
    }
  }

  onFileAdd = (files) => this.setState({ files })
  onFileDelete = (fileId) => this.setState({ files: this.state.files.filter(file => file.id !== fileId) })

  requestTags = () => request.tagGroup.getAll({filterBy: [{ column: 'type', filter: `${this.props.contentCreatorType.slug}`},{column: 'inheritable', filter: true}]})
    .then((tagGroups) => this.setState({tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}))

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

  handleClose = (submited) =>
    this.setState({
      links: [],
      link: null,
      tagGroups: [],
      selectedTags: [],
      disabled: false,
      allowDownload: this.props.contentCreatorType.downloadable,
      namePrefix: "",
      selectedLabel: {value: "all", label: localization.get("label.not_use")},
      filename: "",
      selectedFileTypes: fileTypes,
      uploadType: "link",
      disableForm: false,
      files: [],
    }, () => this.props.onClose(submited))


  handleSubmit = () => {
    if (this.state.uploadType === "link") {
      if (!this.state.link) {
        GlobalSnackbar.show({
          message: localization.get("content_creators.must_select_link"),
          type: GlobalSnackbarTypes.ERROR
        })
        return;
      }

      if (this.state.selectedFileTypes.length === 0) {
        GlobalSnackbar.show({
          message: localization.get("content_creators.must_select_content_type"),
          type: GlobalSnackbarTypes.ERROR
        })
        return;
      }

      if (this.state.link.count > 5) {
        GlobalConfirmationDialog.show({
          title: localization.get("content.store_many_confirmation"),
          content: localization.get("content.store_many_confirmation_description"),
          hideCancel: false,
          request: this.storeManyByLinkRequest
        })
      } else {
        return this.storeManyByLinkRequest()
      }
    }else if(this.state.uploadType === "pc") {
      if(this.state.files.length === 0) {
        GlobalSnackbar.show({
          type: GlobalSnackbarTypes.ERROR,
          message: localization.get('files.must_upload')
        })
        return false;
      }else {
        this.storeManyRequest()
      }
    }
  }

  storeManyByLinkRequest = () =>  new Promise(resolve => {
      this.setState({disabled: true}, () =>
        request.contentCreation.storeByLink({
          creatorId: this.props.creatorId,
          linkId: this.state.link.value,
          selectedTags: this.state.selectedTags,
          allowDownload: this.state.allowDownload,
          namePrefix: this.state.namePrefix,
          selectedLabel: this.state.selectedLabel.value,
          filename: this.state.filename,
          fileTypes: this.state.selectedFileTypes
        })
          .then(response => GlobalSnackbar.show({
            message: localization.get("content_creations.added", response.count),
            type: GlobalSnackbarTypes.SUCCESS
          }))
          .then(this.handleClose)
          .catch(() => {
            GlobalSnackbar.showGenericError();
            this.handleClose(true);
          })
          .finally(() => resolve())
      )
    })

  storeManyRequest = () =>
    this.setState({disabled: true}, () =>
      request.contentCreation.storeMany({
        creatorId: this.props.creatorId,
        selectedTags: this.state.selectedTags,
        allowDownload: this.state.allowDownload,
        namePrefix: this.state.namePrefix,
        files: this.state.files,
      })
        .then(response => GlobalSnackbar.show({
          message: localization.get("content_creations.added", response.count),
          type: GlobalSnackbarTypes.SUCCESS
        }))
        .then(this.handleClose)
        .catch(() => {
          GlobalSnackbar.showGenericError();
          this.handleClose();
        })
    )

  render() {
    const {disabled, tagGroups} = this.state;

    return(
      <Dialog open={this.props.open} >
        <DialogTitle>
          {localization.get('content_creator.create_materials_by_link')}
          <br/>
          <FormControl style={{float: "left", position: "relative", width: "100%"}}>
            <RadioGroup
              name="uploadType"
              value={this.state.uploadType}
              onChange={event => this.setState({ uploadType: event.target.value })}
              style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel value="link" control={<Radio color="default" />} label={localization.get('batch_upload.upload_from_platform')} />
              <FormControlLabel value="pc" control={<Radio color="default" />} label={localization.get('client_file.upload_from_computer')} />
            </RadioGroup>
          </FormControl>
          {this.state.uploadType === 'link' &&
            <>
              <label>{localization.get("links")}</label>
              <AsyncSelect
                ref="select"
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={(link) => this.setState({
                  link,
                  selectedLabel: {value: "all", label: localization.get("label.not_use")}
                })}
                value={this.state.link}
                defaultOptions={this.state.links}
                isClearable={true}
                isOptionDisabled={(option) => option.disabled}
                loadOptions={(inputValue, callback) => {
                  callback(this.state.links.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                }}
              />
              {!!this.state.link && !!this.state.link.fileLabels && this.state.link.fileLabels.length > 0 &&
                <>
                  <FormControl fullWidth>
                    <label>{localization.get("label")}</label>
                    <AsyncSelect
                      ref="select"
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(selectedLabel) => this.setState({selectedLabel, namePrefix: `${selectedLabel.value !== "all" ? selectedLabel.label: ""}${this.state.namePrefix}`})}
                      value={this.state.selectedLabel}
                      defaultOptions={[{
                        value: "all",
                        label: localization.get("label.not_use")
                      }].concat(this.state.link.fileLabels)}
                      isClearable={true}
                      loadOptions={(inputValue, callback) => {
                        callback(this.state.link.fileLabels.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                    />
                    {this.state.selectedLabel && this.state.selectedLabel.value !== 'all' &&
                      <Link
                        to={`${process.env.REACT_APP_FRONT_HOST}/public/batch_upload/20${this.state.link.token.split("").reverse().join("")}-1?folder=${this.state.selectedLabel.value}`}
                        target={"_blank"}
                      >
                        {localization.get('label.go_to')}
                      </Link>
                    }
                  </FormControl>
                </>
              }
            </>
          }
        </DialogTitle>
        <DialogContent style={{width: window.innerWidth > 600 ? 600 : "100%", minHeight: 480}}>
          {this.state.uploadType === 'pc' ?
            <div style={{width: "100%", position: "relative", float: "left"}}>
              <FilePondUploader
                disableForm={this.state.disabled}
                updateDisableForm={(val) => this.setState({disabled: val})}
                onIdChange={(batchFiles) => this.onFileAdd(batchFiles)}
                isMultiple={true}
                maxFiles={1000}
                batchUpload={true}
                onBatchFileDelete={(fileId) => this.onFileDelete(fileId)}
                clientId={this.props.clientId}
              />
            </div>
            :
            <div style={{width: "100%", position: "relative", float: "left", marginTop: 15}}>
              <TextField
                style={{marginBottom: 15}}
                placeholder={localization.get('search.by_filename')}
                value={this.state.filename}
                onChange={e => this.setState({filename: e.target.value})}
                helperText={localization.get('search.by_filename_helper')}
                fullWidth
              />
              <br/>
              <label>{localization.get("catalog.content_type")}</label>
              <div>
                {fileTypes.map(type =>
                  <FormControlLabel
                    style={{position: "relative", float: "left"}}
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={this.state.selectedFileTypes.includes(type)}
                        onChange={() => {
                          if (this.state.selectedFileTypes.includes(type)) {
                            this.setState({selectedFileTypes: this.state.selectedFileTypes.filter(t => type !== t)})
                          } else {
                            this.state.selectedFileTypes.push(type);
                            this.setState({selectedFileTypes: this.state.selectedFileTypes})
                          }
                        }}
                      />
                    }
                    label={localization.get(type)}
                  />
                )}
              </div>
            </div>
          }
          <div style={{ width: "100%", position: "relative", float: "left" }}>
            <FormControlLabel
              style={{width: "100%", position: "relative", float: "left", marginTop: 15}}
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  disabled={this.state.link && this.state.link.protected}
                  checked={this.state.allowDownload}
                  onChange={() => this.setState({allowDownload: !this.state.allowDownload})}
                />
              }
              label={localization.get('form.allow_download')}
            />
            <br />
            <TextField
              placeholder={localization.get('content_creations.prefix_name')}
              value={this.state.namePrefix}
              onChange={e => this.setState({namePrefix: e.target.value})}
              helperText={localization.get('content_creations.prefix_name_helper')}
              fullWidth
            />
            <br />
            <br />
            <label>{localization.get("blog.tags")}</label>
            <TagSelection cardView data={tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged} disabled={disabled} showFilter/>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.disabled} onClick={this.handleClose}>{localization.get('cancel')}</Button>
          <Button disabled={this.state.disabled} onClick={this.handleSubmit}>
            {this.state.disabled ?
              <img style={{width: 30, height: 30}} src={LoadingSpinner} alt="Loading logo"/>
              :
              localization.get('save')
            }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CreateMaterialsByLink;