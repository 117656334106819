import React, {Component} from 'react';
import FormView from './FormView'
import FormViewContainer from '../../../components/Letflow/ViewContainer/FormViewContainer';
import localization from '../../../config/localization'
import {request} from "api-client";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import tag from '../../../api-client/core/tag';


class FormContainer extends Component {

  state = {
    translations: AvailableLanguages.map(language => ({
      language: language,
      name: "",
    })),
    type: '',
    order: '',
    disabled: false,
    types: [
      {value: 'music', label: localization.get('music')},
    ],
    selectedTypes: [],
    groupType: 'content',
    client: null,
    isAdmin: false,
    parent: null,
    id: null
  }

  componentDidMount = () => {
    if (this.props.location.pathname.includes('create')) {
      this.requestContentCreatorTypes()
    }
  }

  setTagGroup = tagGroup => {
    const translations = AvailableLanguages.map(language => {
      const translation = tagGroup.translations.find(tr => tr.locale ===language)

      return {
        language: language,
        name: translation ? translation.name : "",
      };
    })

    this.requestContentCreatorTypes().then(() => {
        return this.setState({
          id: tagGroup.id,
          translations: translations,
          type: tagGroup.type,
          order: tagGroup.order,
          selectedTypes: tagGroup.types.filter(tgTypes => this.state.types.find(creationType => tgTypes.type === creationType.value)).map(type => ({value: type.type, label: this.state.types.find(creationType => type.type === creationType.value).label})),
          groupType: tagGroup.creation_group ? 'material' : tagGroup.inheritable ? 'both' : 'content',
          isAdmin: tagGroup.is_admin ? 1 : 0,
          parent: tagGroup.parent && {value: tagGroup.parent.id, label: tagGroup.parent.name},
          childrens: tagGroup.childrens.map(tagGroup => ({value: tagGroup.id, label: tagGroup.name})),
        })
    })
  }

  requestContentCreatorTypes = () => {
    if (!this.props.isMusic) {
      let  pagination = {orderBy: {column: "name", type: "ASC"},
        filterBy: [
          {column: "visibility", filter: 'private'},
          {column: "is_talent", filter: this.props.isTalent ? 1 : 0},
          {column: "for_create", filter: true },
          {column: "dashboard_combos", filter: true }
        ]
      }
      if (this.props.isEditorial) pagination = { ...pagination, filterBy: pagination.filterBy.concat([{ column: 'type', filter: this.props.isEditorial ? "editorial" : "audiovisual" }])}

      return request.contentCreatorType.getAll(pagination)
        .then(contentCreatorTypes => {
          let types = contentCreatorTypes.map(contentCreatorType => ({value: contentCreatorType.slug, label: contentCreatorType.name, id: contentCreatorType.id}))
          this.setState({types})
        })
    } else {
      return new Promise(resolve => {
        resolve()
      })
    }
  }


  onChange = e => this.setState({[e.target.id]: e.target.value })

  submitRequest = () => this.props.submitRequest(this.state)

  render = () => {

    const { translations, types, type, disabled, order, groupType, client, isAdmin } = this.state

    const { disableTypes } = this.props

    return (
      <FormViewContainer
        title={this.props.title}
        submitRequest={this.submitRequest}
        onBack={() => this.props.history.goBack()}
        initializationRequest={this.props.initializationRequest}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
        hasTranslatableInputs
      >
        <FormView
          ref={instance => this.child = instance}
          disabled={disabled}
          translations={translations}
          onChangeTranslation={(value, target, language) => {
            const translations = this.state.translations.map(tr => {
              if (tr.language === language) {
                return {...tr, [target]: value}
              }
              return tr
            });

            this.setState({ translations });
          }}
          type={type}
          order={order}
          types={types}
          selectedTypes={this.state.selectedTypes}
          onTypeChange={selectedTypes => this.setState({selectedTypes})}
          onChange={this.onChange}
          disableTypes={disableTypes}
          isMusic={this.props.isMusic}
          groupType={groupType}
          isAdmin={isAdmin}
          parent={this.state.parent}
          isTalent={this.props.isTalent}
          isEditorial={this.props.isEditorial}
          isAudiovisual={this.props.isAudiovisual}
          id={this.state.id}
          childrens={this.state.childrens}
        />
      </FormViewContainer>
    )
  }
}

export default FormContainer
