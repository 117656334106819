import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  subtitle,
  translations,
  video,
  active,
  contentCreatorId,
  image,
  audio,
  selectedTags,
  videoId,
  imageId,
  audioId,
  binaryId,
  link,
  batchVideoId,
  batchImageId,
  batchAudioId,
  spanishSubtitlesId,
  englishSubtitlesId,
  italianSubtitlesId,
  germanSubtitlesId,
  frenchSubtitlesId,
  spanishSubtitles,
  englishSubtitles,
  italianSubtitles,
  germanSubtitles,
  frenchSubtitles,
  demo,
  releaseDate,
  expireDate,
  embedSource,
  embedUrl,
  downloadable,
  onlyArgentina,
  unsplashImage,
  requireDrm,
  requireLogin,
  hasPreRoll,
  limited,
  viewLimit,
  timeToTrack,
  allowDownload,
  allowedRegions,
  hasRegionBlock,
  inappropriateContent,
  driveImage,
  driveVideo,
  driveAudio,
  driveBinary,
  extraInformation,
  isProtected,
  createdWhere,
  channelId,
  accessLevel,
}) => post(`${host}/contentCreations`, makeFormData({
  subtitle,
  translations,
  video,
  active,
  contentCreatorId,
  image,
  audio,
  selectedTags,
  videoId,
  imageId,
  audioId,
  binaryId,
  link,
  batchVideoId,
  batchImageId,
  batchAudioId,
  spanishSubtitlesId,
  englishSubtitlesId,
  italianSubtitlesId,
  germanSubtitlesId,
  frenchSubtitlesId,
  spanishSubtitles,
  englishSubtitles,
  italianSubtitles,
  germanSubtitles,
  frenchSubtitles,
  demo,
  releaseDate,
  expireDate,
  embedSource,
  embedUrl,
  downloadable,
  onlyArgentina,
  unsplashImage,
  requireDrm,
  requireLogin,
  hasPreRoll,
  limited,
  viewLimit,
  timeToTrack,
  allowDownload,
  allowedRegions,
  hasRegionBlock,
  inappropriateContent,
  driveImage,
  driveVideo,
  driveAudio,
  driveBinary,
  extraInformation,
  isProtected,
  createdWhere,
  channelId,
  accessLevel,
})).then(response => response.json())
