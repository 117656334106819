import React, {Component} from 'react'
import {Lock} from '@material-ui/icons'
import {TableButton} from '..';
import localization from '../../../../config/localization';
import GlobalConfirmationDialog from '../../Dialog/GlobalConfirmationDialog';
import {MenuItem, Select, TextField} from '@material-ui/core';
import {request} from '../../../../api-client';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../Snackbar/GlobalSnackbar';
import {monthsToDays} from '../../../../api-client/core/reservation';
import EventManager, {events} from '../../../../utils/EventManager';

const TableRequestReservationButton = ({ track, onClose }) => {

  let months = 6
  let description = ''

  const showReservationDialog = () =>
    GlobalConfirmationDialog.show({
      title: localization.get('reservations.request_title'),
      content: <RequestReservationContent
        months={months}
        description={description}
        onDurationChanged={x => months = x}
        onDescriptionChanged={x => description = x} track={track}
      />,
      request: requestReservation,
      onClose: onClose,
    })

  const requestReservation = () =>
    request.reservation.create(monthsToDays(months), track.id, description)
      .then(() => {
        GlobalSnackbar.show({
          message: `El pedido se envio con exito, se le notificara en cuanto su pedido sea evaluado por un administrador`,
          type: GlobalSnackbarTypes.SUCCESS,
        })
        EventManager.getInstance().dispatch(events.RESERVATION_REQUESTED, { track })
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: `No se pudo pedir la reserva de este tema, intente nuevamente mas tarde`,
          type: GlobalSnackbarTypes.ERROR,
        })
      })

  return <TableButton onClick={showReservationDialog} title={localization.get('reservations.request_title')}><Lock /></TableButton>
}

export class RequestReservationContent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      months: props.months,
      description: props.description,
    }
  }

  render = () => {
    const { track, onDurationChanged, onDescriptionChanged } = this.props
    return (
      <div >
        <div dangerouslySetInnerHTML={{ __html: localization.get('reservations.request_reservation_message', track.title) }} />
        <div />
        <Select
          value={this.state.months}
          onChange={e => {
            this.setState({ months: e.target.value })
            onDurationChanged(e.target.value)
          }}
          fullWidth
        >
          <MenuItem value={6}>{localization.get('reservations.months', 6)}</MenuItem>
          <MenuItem value={12}>{localization.get('reservations.months', 12)}</MenuItem>
        </Select>
        <div style={{ marginTop: '5px' }}>{localization.get('reservations.request_reservation_message_2')}</div>
        <TextField value={this.state.description} onChange={e => {
          this.setState({ description: e.target.value })
          onDescriptionChanged(e.target.value)
        }} fullWidth multiline />
      </div>
    )
  }
}

export default TableRequestReservationButton