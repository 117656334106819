export default {
  "license_type": {
    "1": {
      "credits": 70,
      "reductions": {
        "1-10": {
          "surcharge": 0.5
        }
      },
      "unlimited_reductions": {
        "credits": 1000
      }
    },
    "2": {
      "media": {
        "1": {
          "budget": {
            "1": {
              "credits": 200
            },
            "2": {
              "credits": 400
            },
            "3": {
              "credits": 600
            }
          },
          "reductions": {
            "1-2": {
              "surcharge": 0.3
            },
            "3-5": {
              "surcharge": 0.25
            },
            "6-": {
              "surcharge": 0
            }
          },
          "perpetual_exlusivity": {
            "credits": 1000
          },
          "bichannel": {
            "credits": 300
          },
          "edition": {
            "credits": 100
          }
        },
        "2": {
          "extent": {
            "1": {
              "credits": 10000
            },
            "2": {
              "credits": 1600
            },
            "3": {
              "credits": 600
            },
            "4": {
              "credits": 1000
            },
            "5": {
              "credits": 1300
            },
            "6": {
              "credits": 2200
            },
            "7": {
              "credits": 750
            },
            "8": {
              "credits": 1200
            },
            "9": {
              "credits": 1700
            },
            "10": {
              "credits": 2800
            },
            "11": {
              "credits": 750
            },
            "12": {
              "credits": 1200
            },
            "13": {
              "credits": 1700
            },
            "14": {
              "credits": 2800
            },
            "15": {
              "credits": 750
            },
            "16": {
              "credits": 1200
            },
            "17": {
              "credits": 1700
            },
            "18": {
              "credits": 2800
            }
          },
          "reductions": {
            "1-2": {
              "surcharge": 0.3
            },
            "3-5": {
              "surcharge": 0.25
            },
            "6-": {
              "surcharge": 0
            }
          },
          "perpetual_exlusivity": {
            "credits": 1000
          },
          "bichannel": {
            "credits": 300
          },
          "edition": {
            "credits": 0
          }
        },
        "3": {
          "extent": {
            "1": {
              "credits": 20000
            },
            "2": {
              "credits": 3200
            },
            "3": {
              "credits": 1200
            },
            "4": {
              "credits": 2000
            },
            "5": {
              "credits": 2700
            },
            "6": {
              "credits": 4500
            },
            "7": {
              "credits": 1500
            },
            "8": {
              "credits": 2500
            },
            "9": {
              "credits": 3300
            },
            "10": {
              "credits": 5600
            },
            "11": {
              "credits": 1500
            },
            "12": {
              "credits": 2500
            },
            "13": {
              "credits": 3300
            },
            "14": {
              "credits": 5600
            },
            "15": {
              "credits": 1500
            },
            "16": {
              "credits": 2500
            },
            "17": {
              "credits": 3300
            },
            "18": {
              "credits": 5600
            }
          },
          "reductions": {
            "1-2": {
              "surcharge": 0.3
            },
            "3-5": {
              "surcharge": 0.25
            },
            "6-": {
              "surcharge": 0
            }
          },
          "perpetual_exlusivity": {
            "credits": 1000
          },
          "bichannel": {
            "credits": 300
          },
          "edition": {
            "credits": 0
          },
          "modification": {
            "credits": 0
          }
        },
        "4": {
          "extent": {
            "1": {
              "credits": 25000
            },
            "2": {
              "credits": 4000
            },
            "3": {
              "credits": 1500
            },
            "4": {
              "credits": 2500
            },
            "5": {
              "credits": 3500
            },
            "6": {
              "credits": 5500
            },
            "7": {
              "credits": 1900
            },
            "8": {
              "credits": 3100
            },
            "9": {
              "credits": 4200
            },
            "10": {
              "credits": 7000
            },
            "11": {
              "credits": 1900
            },
            "12": {
              "credits": 3100
            },
            "13": {
              "credits": 4200
            },
            "14": {
              "credits": 7000
            },
            "15": {
              "credits": 1900
            },
            "16": {
              "credits": 3100
            },
            "17": {
              "credits": 4200
            },
            "18": {
              "credits": 7000
            },
          },
          "reductions": {
            "1-2": {
              "surcharge": 0.5
            },
            "3-5": {
              "surcharge": 0.6
            },
            "6-": {
              "surcharge": 0
            }
          },
          "perpetual_exlusivity": {
            "credits": 0
          },
          "bichannel": {
            "credits": 0
          },
          "edition": {
            "credits": 0
          },
          "modification": {
            "credits": 0
          }
        }
      }
    },
    "3": {
      "category": {
        "1": {
          "duration": {
            "6": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            },
            "12": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            }
          }
        },
        "2": {
          "duration": {
            "6": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            },
            "12": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            }
          }
        },
        "3": {
          "duration": {
            "6": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            },
            "12": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            }
          }
        },
        "4": {
          "duration": {
            "6": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            },
            "12": {
              "length": {
                "1": {
                  "money": 35000
                },
                "2": {
                  "money": 49700
                },
                "3": {
                  "money": 73700
                },
                "4": {
                  "money": 92800
                },
                "5": {
                  "money": 120800
                },
                "6": {
                  "money": 128300
                },
                "7": {
                  "money": 148600
                }
              }
            }
          }
        }
      }
    }
  }
}