import React from "react";
import {Card, Dialog, DialogContent, IconButton, Tooltip, withStyles} from "@material-ui/core";
import {makeFitImageUrlWithSize, makeWaveformUrl, downloadFromUrl, isIOSDevice} from "../../../utils";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  Close,
  Pause,
  PlayArrow,
  Share,
  CloudDownload,
  Info,
  Edit,
  Delete,
} from "@material-ui/icons";
import styled from "styled-components";
import ShakaPlayer from "../ShakaPlayer";
import {makeImageUrlWithSize, emptyImage, onImageError} from "../../../layouts/Home/HomeElements/utils";
import EmbededContent from "../../../layouts/Home/ContentCreator/EmbededContent";
import EventManager, {events} from "../../../utils/EventManager";
import './index.css'
import {request} from "../../../api-client";
import AddToPlaylist from "../AddToPlaylist";
import ShareOnSocials from "../ShareOnSocials";
import AudioImage from "../../../assets/img/Letflow/SmartSearch/audio.png";
import NoImage from "../../../assets/img/Letflow/FileExtentions/zip.png";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import localization from "../../../config/localization";
import {
  addHttpsToUrl,
  channelCanAddToFavorites,
  channelPathGenerator,
  getActiveChannel,
  getActiveClient,
  getUseTools,
  getStoredUser,
  getStoredChannel, getMainTranslation, slugify,
  userCanEditCreators,
  userHasRestrictedAccess,
} from "../../../api-client/core/authentication/utils";
import { Link } from "react-router-dom";
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import GlobalSnackbar,{GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import Button from "../../CustomButtons/Button";
import {get} from "lodash"
import GlobalRedirectLoginConfirmationDialog from "../Dialog/GlobalRedirectLoginConfirmationDialog";
import browserHistory from "../../../utils/browserHistory";
import ProductDialog from "../ProductsDialog";
import analyticsInstance from "../../../analytics/analyticsInstance";
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import RichEditor from "../RichTextEditor/EditorialEditor";
import {convertFromRaw, EditorState} from "draft-js";

const styles = {
  dialogPaper: {
      minHeight: '100vh',
      minWidth: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      backgroundColor: "rgba(0,0,0,0.8)",
      padding: window.innerWidth > 600 ? '40px' : "0px",
      height: '100vh',
      width: '100vw'
  },
  dialogPaperMobile: {
    minHeight: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#EFEFEF',
    borderRadius: '10px',
    padding: '20px',
    height: '80vh',
    fontSize: '0.8em'
  }
};

const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "4px 0",
    maxWidth: "350px",
},
})(Tooltip);

class ContentCreationPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.state = {
      currentContentCreation: this.props.currentContentCreation, 
      autoPlay: false,
      shareUrl: "",
      hidden: true,
      noAuthorLink: this.props.noAuthorLink,
      hideAuthor: this.props.hideAuthor,
      playing: null,
      showInappropriate: true,
      hideInappropriate: false,
      openProductDialog: false,
      isGift: false,
      productId: null,
    }
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e) {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 37 && this.props.onPrevArrow) {
      this.handlePrevArrow();
    } else if (e.keyCode === 39 && this.props.onNextArrow) {
      this.handleNextArrow();
    }
  }


  getAudio = currentContentCreation => {
    return {
      id: currentContentCreation.id,
      fetchAudioUrl: () => Promise.resolve(currentContentCreation.audio.url),
      title: currentContentCreation.title,
      subtitle: currentContentCreation.contentCreator && currentContentCreation.contentCreator.name,
      image: currentContentCreation.contentCreator ? makeImageUrlWithSize(currentContentCreation.contentCreator, 'xs') : makeImageUrlWithSize(currentContentCreation, 'xs'),
      waveform: makeWaveformUrl(currentContentCreation),
    }
  }

  componentWillReceiveProps(nextProps) {
    let showContent = nextProps.currentContentCreation && (nextProps.currentContentCreation.video || nextProps.currentContentCreation.embededContent || nextProps.currentContentCreation.audio);
    if (!nextProps.currentContentCreation) {
      this.setState({currentContentCreation: null, hidden: true, hideInappropriate: false});
    } else if (!this.props.currentContentCreation || (nextProps.currentContentCreation && this.props.currentContentCreation.id !==  nextProps.currentContentCreation.id)) {
      this.setState({currentContentCreation: null, hidden: true, hideInappropriate: false},
        () => {
          if (nextProps.currentContentCreation.blocked_for_country) 
          GlobalConfirmationDialog.show({
            title: localization.get("content.not_available"),
            content: getStoredChannel() && getStoredChannel().region_blocked_text ?
              (getStoredChannel().region_blocked_text.startsWith('<') ?
                  <div dangerouslySetInnerHTML={{__html: getStoredChannel().region_blocked_text}}/> :
                  <RichEditor
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(getStoredChannel().region_blocked_text)))}
                    readOnly={true}
                    onChange={() => console.log('read only')}
                  />
              )
              : localization.get("content.not_available_for_country"),
            hideCancel: true,
            request: () => Promise.resolve(),
            confirmationLabel: localization.get('content.not_available_done'),
          });

          if (nextProps.currentContentCreation.require_access_level) {
            GlobalConfirmationDialog.show({
              title: localization.get("content.access_denied"),
              content: localization.get("content.not_enough_permissions"),
              onClose: () => this.handleClose(),
              hideCancel: true,
              request: () => Promise.resolve().then(this.handleClose),
            })
          }

          if (nextProps.currentContentCreation.limit_views_reached) EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
          return this.setState({currentContentCreation: nextProps.currentContentCreation, hidden: !showContent},
             () => {
              if (nextProps.currentContentCreation.link) {
                window.open(addHttpsToUrl(nextProps.currentContentCreation.link, '_blank'))
                this.handleClose()
              }else{ 
                this.getDownloadHistories();
              }
          })
        })
    }
    this.setSoundbarSongAsPlayingIfPresent();
    setTimeout(() => this.state.hidden && this.setState({hidden: false}), 5000);
  }

  getDownloadHistories = () => {
    if (!getActiveChannel() || getStoredChannel().visibility === "public" || !this.state.currentContentCreation.allow_download) {
      return this.setState({currentContentCreation: {...this.state.currentContentCreation, downloadHistories: ""}});
    }

    return request.contentCreation.showDownloadHistories(this.state.currentContentCreation.id)
      .then(histories => {
        const hist = histories.length === 0 ? localization.get('download.no_histories') : histories.reduce((acc, curr) => {
          return `${acc} ${localization.get('download.by')}: ${curr.user.name} ${localization.get('download.in')} ${curr.created_at} \n`;
        }, "")

        this.setState({currentContentCreation: {...this.state.currentContentCreation, downloadHistories: hist}})
      })
  }

  componentDidMount = () => {
    this.trackPausedSubscription = GlobalSoundBar.trackPaused$().subscribe(this.handleTrackPausedViaSoundbar);
    this.trackStartedPlayingSubscription = GlobalSoundBar.trackStartedPlaying$().subscribe(this.handleTrackStartedPlayingInSoundbar);
    this.setSoundbarSongAsPlayingIfPresent();
  };

  getType = (content) => {
    if (content.video) {
      return "Video"
    }else if (content.audio) {
      return "Audio";
    }else if (content.binary) {
      return "Binary";
    }else if (content.embededContent) {
      return "Embed";
    }else {
      return "Image"
    }
  }

  setSoundbarSongAsPlayingIfPresent = () => {
    if (!GlobalSoundBar.isPlaying() || !this.state.currentContentCreation || !this.state.currentContentCreation.audio) {
      return;
    }

    const alreadyPlayingTrackInSoundbar = GlobalSoundBar.currentTrackIsTitled(this.state.currentContentCreation.title);

    if (alreadyPlayingTrackInSoundbar) {
      this.setState({ playing: this.state.currentContentCreation.id });
    }
  };

  handleTrackPausedViaSoundbar = () => {
    this.setState({ playing: null });
  };

  handleTrackStartedPlayingInSoundbar = () => {
    if (!this.state.currentContentCreation || !this.state.currentContentCreation.audio) {
      this.setState({ playing: null });
      return;
    }
    
    const isCurrentTrack = GlobalSoundBar.currentTrackIsTitled(this.state.currentContentCreation.title);
    
    if (isCurrentTrack) {
      this.setState({ playing: this.state.currentContentCreation.id });
    } else {
      this.setState({ playing: null });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyDown);
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    EventManager.getInstance().unsubscribe(events.OPEN_EDITING_MODAL, () => null)
    this.trackPausedSubscription.unsubscribe();
    this.trackStartedPlayingSubscription.unsubscribe();
  }

  handlePlayButtonPress = (currentContentCreation) => () => {

    // GAevent('Contenido', "Play_Audio", currentContentCreation.title)

    analyticsInstance.playContent({
      name: getMainTranslation(currentContentCreation, "title"),
      type: "Audio",
      content: !!currentContentCreation.contentCreator && getMainTranslation(currentContentCreation.contentCreator, "name")
    })
    this.setState({ playing: currentContentCreation.id });

    GlobalSoundBar.loadSingleTrackAndPlay(this.getAudio(currentContentCreation));
  };
  
  handlePauseButtonPress = () => {
    this.setState({ playing: null });
    GlobalSoundBar.pause();
  };

  onFavorite = (creation) => {
    analyticsInstance.favorite({
      name: getMainTranslation(creation, "title"),
      type: "Material",
      content: !creation.favorite ? "Add" : "Remove"
    })
    if(!creation.favorite) {
      this.addToFavorites(creation.id)
    } else  {
      this.removeFromFavorites(creation.id)
    }
  }

  addToFavorites = () =>
    this.setState({
      currentContentCreation: {
        ...this.state.currentContentCreation,
        favorite: true,
      }
    }, () => {
      analyticsInstance.favorite({
        name: getMainTranslation(this.state.currentContentCreation, "title"),
        type: "Material",
        content: "Add",
      })
      return request.contentCreationChannelFavorite.add(this.state.currentContentCreation.id)
    })


  removeFromFavorites = () =>
    this.setState({
      currentContentCreation: {
        ...this.state.currentContentCreation,
        favorite: false,
      }
    }, () => {
      analyticsInstance.favorite({
        name: getMainTranslation(this.state.currentContentCreation, "title"),
        type: "Material",
        content: "Remove",
      })

      return request.contentCreatorChannelFavorite.remove(this.state.currentContentCreation.id)
    })

  downloadCreation = () => {
    let item = this.state.currentContentCreation;
    analyticsInstance.download({
      name: getMainTranslation(item, "title"),
      type: this.props.isPublic ? "Share" : "Channel",
      from: "Material_For_Download"
    })


    GlobalSnackbar.show({
      type: GlobalSnackbarTypes.SUCCESS,
      message: localization.get("downloading")
    })

    if(item.audio) {
      return window.open(item.audio.url, '_blank')
    }
    
    let download = url => window.open(url, '_blank')

    if((/iPhone|iPad|iPod/i.test(navigator.userAgent) || window.safari !== undefined)) {
      // safari doesnt allow window.open inside async calls
      var windowRef=window.open();
      download = url => windowRef.location = addHttpsToUrl(url);
    }

    return request.contentCreation.getDownloadLink(item.id)
      .then(response => {
        const filename =
            item.video ? item.video.original_name.split(".").pop() :
            item.binary ? item.binary.original_name.split(".").pop() :
            item.audio ? item.audio.original_name :
            item.image ? item.image.original_name : ".";
        const fileExt =  filename.split('.').pop()

        let url = response.url
        if(url.endsWith(".")) url = `${response.url}${fileExt}`
        
        downloadFromUrl(url, item.title, fileExt, 'octet/steam')
      })
      .catch(GlobalSnackbar.showGenericError)
  }
    
  handleClose = () =>
    !this.state.openProductDialog && this.setState({
      openProductDialog: false,
      isGift: false,
      productId: null
    }, this.props.handleClose);


  handlePrevArrow = () => {
    this.props.onPrevArrow && this.props.onPrevArrow();
  }
  handleNextArrow = () => {
    this.props.onNextArrow && this.props.onNextArrow();
  }



  onBuyProduct = (isGift=false) =>
    !getStoredUser() ?
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator(`login?redirect_to=${window.location.pathname}`))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      }) :
      this.openProductDialog(this.state.currentContentCreation, isGift)

  openProductDialog = (content, isGift=false) =>
    this.setState({
      openProductDialog: true,
      productTargetId: content.id,
      productName: content.name || content.title,
      isGift: isGift,
    })

  storeInappropriate = () => localStorage.setItem("over_18", true);

  render() {
    let currentContentCreation = this.state.currentContentCreation
    const { classes } = this.props;

    return (
      <Dialog 
        open={this.props.show} 
        fullScreen={true} 
        onClose={this.handleClose}
        onEscapeKeyDown={this.handleClose}
        classes={{paper: classes.dialogPaper}}
        style={this.props.navbarColor ? {"--main-color": "var(--secondary-color, #EFEFEF)", "--main-font-color": "var(--navbar-text-color, black)"} : {}}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.handleClose()
        }}
        disableEnforceFocus>
        <ProductDialog
          open={this.state.openProductDialog}
          productTargetId={this.state.productTargetId}
          productTargetType={"contentCreation"}
          name={this.state.productName}
          imageUrl={this.state.productImageUrl}
          productId={this.state.productId}
          returnUrl={this.state.productReturnUrl}
          onClose={() => this.setState({
            openProductDialog: false,
            productTargetId: null,
            productName: "",
            productImageUrl: "",
            productId: null,
            productReturnUrl: null,
            isGift: false,
          })}
          isGift={this.state.isGift}
        />
        <IconButton 
          className="content-creation-player-close" style={{position: "absolute", right: "calc(2% + 2px)", top: 5, color: "white", minWidth: 0, padding: "10px 10px", zIndex: "1"}} 
          onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.handleClose()}}>
            <Close style={{fontSize: 35}}/>
        </IconButton>
        {this.props.onPrevArrow && <div className="creation-player-left-arrow" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.handlePrevArrow()}}><ArrowBackIosRounded style={{ marginLeft: "-3px" }}/></div>}
        {this.props.onNextArrow && <div className="creation-player-right-arrow" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.handleNextArrow()}}><ArrowForwardIosRounded style={{ marginRight: "-5px" }}/></div>}
        {currentContentCreation &&
          <ShareOnSocials
            show={this.state.showShareDialog}
            handleClose={() => this.setState({showShareDialog: false})}
            url={this.state.shareUrl}
            darkMode={true}
            onClick={(e) => {e.stopPropagation(); e.preventDefault();}}
            shareTitle={currentContentCreation.title}
          />
        }
        {currentContentCreation && currentContentCreation.audio ? 
          <Card className="creation-player-audio-card" onClick={(e) => {e.stopPropagation(); e.preventDefault();}}>
            <div className="creation-player-audio">
              <div style={currentContentCreation.image ? {backgroundImage: `url(${makeImageUrlWithSize(currentContentCreation, 'md')}), url(${emptyImage})`} : {backgroundImage: `url(${AudioImage})`, backgroundPositionX: "center"}}
               className="creation-player-audio-image"
              >
                {this.state.playing === currentContentCreation.id ? (
                    <IconButton onClick={this.handlePauseButtonPress} className="creation-player-audio-pause-button">
                      <Pause className="creation-player-audio-pause-icon"/>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={this.handlePlayButtonPress(currentContentCreation)}
                      className="creation-player-audio-play-button"
                    >
                      <PlayArrow className="creation-player-audio-play-icon"/>
                    </IconButton>
                  )}
              </div>
              <div className="creation-player-audio-info">
                <div className={"creation-player-audio-data"}>
                  {currentContentCreation.contentCreator && !this.props.hideAuthor && 
                    <div className={"creation-player-author"}>
                      {/* <div
                        className={"creation-player-author-image"}
                        style={{backgroundImage: `url(${!!currentContentCreation.contentCreator.image ? makeImageUrlWithSize(currentContentCreation.contentCreator, 'xs') : NoProfile})`}}
                      /> */}
                      {this.state.noAuthorLink ?
                        <a className="creation-player-author-name">{currentContentCreation.contentCreator.name}</a>
                        :
                        <Link
                        className={"creation-player-author-name"}
                        to={this.state.noAuthorLink ? null : (getActiveChannel() ?
                          channelPathGenerator(this.props.isPublic ? `public/content_creators/${currentContentCreation.contentCreator.hashcode}` : `contenido/${currentContentCreation.contentCreator.id}-${currentContentCreation.contentCreator.name}`) :
                          `public/content_creators/${currentContentCreation.contentCreator.hashcode}`)}
                        onClick={e => {
                          if (this.props.onCreatorClick) {
                            this.props.onCreatorClick()
                          }   
                        }}
                        >{currentContentCreation.contentCreator.name}</Link>
                      }
                    </div>
                  }

                  <h3 style={{color: "black", fontWeight: "300", margin: 0}}>{currentContentCreation.description && (currentContentCreation.description.length > 100 ? currentContentCreation.description.substring(0,100).concat("...") : currentContentCreation.description)}</h3>
                  {currentContentCreation.tags && currentContentCreation.tags.length > 0 &&
                    <div className={"creation-tags"}>
                      <h3 style={{textDecoration: "underline"}}>{localization.get("content_creation.tags")}</h3>
                      {currentContentCreation.tags.slice(0,5)
                        .map((t) => {
                          if (get(currentContentCreation, "contentCreator.type") || this.props.type){ 
                            const type = get(currentContentCreation, "contentCreator.type") ? currentContentCreation.contentCreator.type : this.props.type;
                            
                            return <Link 
                              onClick={this.handleClose}
                              style={{textDecoration: "none", color: "var(--main-font-color, black)",}}
                              to={channelPathGenerator(`busqueda-inteligente/content_creations/${type.id}-${slugify(type.name)}?type=${type.slug}&tags_ids=${t.id}`)}>
                                {t.name}
                                </Link>;
                          }
                          return <div>{t.name}</div>
                        })}
                    </div>
                  }
                </div>
                <div style={{display: "flex", flexDirection: "row", width: 100, justifyContent: "flex-end"}} className="creation-player-audio-socials">
                {!this.props.isPublic &&
                  <>
                    {!!getStoredUser() && getActiveChannel() && channelCanAddToFavorites() && (!!currentContentCreation.favorite ?
                      <StyledTooltip placement="top" title={localization.get("soundbar.remove_from_favorites")}>
                        <Star
                          className="creation-player-fav-icon"
                          style={{cursor: "pointer", height: 24,width: 24, marginRight: 5}} onClick={this.removeFromFavorites}
                        />
                      </StyledTooltip>
                      :
                      <StyledTooltip placement="top" title={localization.get("soundbar.add_to_favorites")}>
                        <Star
                          className="creation-player-unfav-icon"
                          style={{cursor: "pointer", height: 24,width: 24, marginRight: 5}} onClick={this.addToFavorites}
                        />
                      </StyledTooltip>
                    )}
                  {!!getStoredUser() && getActiveClient() && getUseTools() &&
                      <AddToPlaylist
                        item={currentContentCreation}
                        clientId={getActiveClient()}
                        content={"contentCreations"}
                        icon={<StyledTooltip placement="top" title={localization.get("add_to_playlist")}>
                          <List className="creation-player-playlist" style={{cursor: "pointer", height: 22,width: 24, marginRight: 5, marginTop: 2}}/>
                        </StyledTooltip>}
                      />
                    }
                    {!!getStoredUser() && getActiveChannel() &&
                      <StyledTooltip placement="top" title={localization.get("share")}>
                        <Share
                          style={{fontSize: 24, cursor: "pointer", marginTop: 2, marginRight: 5}}
                          onClick={() => request.contentCreation.shareByChannel(currentContentCreation.id)
                            .then(response => this.setState({
                              showShareDialog: true,
                              shareUrl: response.url,
                            }))
                            .then(() => analyticsInstance.share({
                              name: getMainTranslation(currentContentCreation, "title"),
                              type: "Material",
                            }))
                          }
                        />
                      </StyledTooltip>
                    }
                    {!!userCanEditCreators() &&
                      <StyledTooltip title={localization.get('edit')} placement="top">
                        <Edit style={{fontSize: 24, cursor: "pointer", marginTop: 2, marginRight: 5}}
                         onClick={() => EventManager.getInstance().dispatch(events.OPEN_EDITING_MODAL, {
                          ...currentContentCreation,
                          typeSlug: get(currentContentCreation, "contentCreator.type") ? get(currentContentCreation, "contentCreator.type.slug") : this.props.typeSlug
                        })} />
                      </StyledTooltip>
                    }
                    {!!userCanEditCreators() && this.props.onDelete &&
                      <StyledTooltip title={localization.get('delete')} placement="top">
                        <Delete style={{fontSize: 24, cursor: "pointer", marginTop: 2, marginRight: 5}}
                          onClick={() =>
                            GlobalConfirmationDialog.showDelete({
                              dialogTitle: `${localization.get('tooltip.delete')} ${currentContentCreation.title}`,
                              content: localization.get('confirmation_dialog.delete_content_message'),
                              doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
                              elementName: localization.get("delete"),
                              deleteRequest: () => request.contentCreation.delete(currentContentCreation.id)
                                .then(() => {
                                  this.handleClose()
                                  if (this.props.onDelete) {
                                    this.props.onDelete(currentContentCreation.id)
                                  }
                                }),
                              doubleConfirmation: true,
                              auxiliaryInformation: "",
                            })
                          }
                        />
                      </StyledTooltip>
                    }
                  </>
                }
                
                  {(!!currentContentCreation.allow_download || this.props.allowDownload) &&
                    <>
                      <StyledTooltip placement="top" title={localization.get("download")}>
                        <CloudDownload
                          style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                          onClick={() => this.props.handleDownload ? this.props.handleDownload() : this.downloadCreation()}
                        />
                      </StyledTooltip>
                      {currentContentCreation.allow_download && !userHasRestrictedAccess() &&
                        <StyledTooltip
                          placement="top"
                          title={<div style={{ whiteSpace: 'pre-line' }}>{currentContentCreation.downloadHistories}</div>}
                        >
                          <Info
                            style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                          />
                        </StyledTooltip>
                      }
                    </>
                  }
                  {!!currentContentCreation.payment_required &&
                    <StyledTooltip placement="top" title={localization.get("download")}>
                      <ShoppingCartOutlinedIcon
                        style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                        onClick={() => this.onBuyProduct()}
                      />
                    </StyledTooltip>
                  }
                </div>
              </div>
            </div>
          </Card>
        :
          currentContentCreation ?
            <div style={{padding:0, height: "100%", overflow: 'hidden', width: "100%", minWidth: 200, display: "grid", position: "relative", margin: "auto"}}>
              {this.state.hidden && <img style={{width: 80, height: 80, top: "calc(50% - 40px)", left: "calc(50% - 40px)", position: "absolute"}} src={LoadingSpinner} alt="Loading logo"/>}
              <DialogContent className="content-creation-player-dialog" style={{padding: 0, height: "fit-content", maxHeight: "100%", overflow: 'hidden', maxWidth: "90%", display: this.state.hidden ? "none" : "flex", backgroundColor: "var(--main-color, #EFEFEF)", color: "var(--main-font-color, black)", position: "relative", margin: "auto", flexDirection: "row"}} onClick={(e) => {e.stopPropagation(); e.preventDefault();}}>
                {currentContentCreation &&
                  <>
                    <div style={(currentContentCreation.video || currentContentCreation.embededContent) && {width: "fit-content", overflow: "hidden"}} className={(currentContentCreation.inappropriate_content && this.state.showInappropriate) ? "content-creation-player-video content-creation-player-blured-dialog" : "content-creation-player-video"}>
                      {currentContentCreation.video ?
                        <ShakaPlayer
                          material={currentContentCreation}
                          video={currentContentCreation.video}
                          videoTitle={currentContentCreation.title}
                          subtitles={currentContentCreation.video.subtitles}
                          playsInline={true}
                          thumbnail={currentContentCreation.image ? makeFitImageUrlWithSize(currentContentCreation, 'lg') : makeFitImageUrlWithSize({image: currentContentCreation.video.thumbnail}, 'lg')}
                          preview={false}
                          styles={{margin: 0, width: "100%", minWidth: Math.floor((window.innerWidth - 80) * 0.9 - (window.innerWidth <= 650 ? 60 : 260))}}
                          // autoPlay={window.innerWidth <= 650 || this.state.autoPlay }
                          autoPlay={true}
                          setAutoPlay={(value) => this.setState({autoPlay: value})}
                          isBanner
                          muted={isIOSDevice()}
                        />
                        : currentContentCreation.embededContent ?
                          <EmbededContent
                            material={currentContentCreation}
                            content={currentContentCreation.embededContent}
                            index={currentContentCreation.id}
                            currentIndex={currentContentCreation.id}
                            width={Math.floor((window.innerWidth - 80) * 0.9 - (window.innerWidth <= 650 ? 60 : 260))}
                            height={Math.floor(((window.innerWidth - 80) * 0.9 - (window.innerWidth <= 650 ? 60 : 260)) / 1.75)}
                          /> :
                          currentContentCreation.image ?
                            <>
                              <img
                                src={currentContentCreation ? makeFitImageUrlWithSize (currentContentCreation, 'lg') : ''}
                                className={(currentContentCreation.binary || currentContentCreation.link) ? "content-creation-player-image content-creation-player-binary-image" : "content-creation-player-image"}
                                onLoad={() => setTimeout(() => this.setState({hidden: false}), 100)}
                                onError={onImageError}
                              />
                              {(currentContentCreation.binary || currentContentCreation.link) && 
                                <div className="download-button-container">
                                  <span className="download-button" 
                                    onClick={() => {
                                    currentContentCreation.link ? 
                                      window.open(addHttpsToUrl(currentContentCreation.link, '_blank')) :
                                      window.open(currentContentCreation.binary.url, '_blank')
                                    }}>
                                        {localization.get(currentContentCreation.link ? "visit" : (currentContentCreation.binary.original_name.includes('.pdf') ? 'open_pdf' : 'download'))}
                                    </span>
                                </div>
                              }
                            </>
                            : 
                          currentContentCreation.binary ? 
                            <>
                              <img
                                src={NoImage}
                                className={"content-creation-player-image content-creation-player-binary-image"}
                                onLoad={() => setTimeout(() => this.setState({hidden: false}), 500)}
                              />
                              <div className="download-button-container">
                                <span className="download-button" onClick={() => {window.open(currentContentCreation.binary.url, '_blank')}}>
                                {localization.get(currentContentCreation.binary.original_name.includes('.pdf') ? 'open_pdf' : 'download')}
                                  </span>
                              </div>
                            </>
                          : <></>
                      }
                    </div>
                    <div className={"creation-player-info"}>
                      {(!this.props.isPublic || currentContentCreation && (currentContentCreation.comments || currentContentCreation.payment_required)) ?
                      <div style={{display: "flex", flexDirection: "row"}}>
                        {!this.props.isPublic && !!getStoredUser() && getActiveChannel() && channelCanAddToFavorites() && (!!currentContentCreation.favorite ?
                          <StyledTooltip placement="top" title={localization.get("soundbar.remove_from_favorites")}>
                            <Star
                              className="creation-player-fav-icon"
                              style={{cursor: "pointer", height: 20, width: 20, marginRight: 5}} onClick={this.removeFromFavorites}
                            />
                          </StyledTooltip>
                          :
                          <StyledTooltip placement="top" title={localization.get("soundbar.add_to_favorites")}>
                            <Star
                              className="creation-player-unfav-icon"
                              style={{cursor: "pointer", height: 20,width: 20, marginRight: 5}} onClick={this.addToFavorites}
                            />
                          </StyledTooltip>
                        )}
                        {!this.props.isPublic && !!getStoredUser() && getActiveClient() && getUseTools() &&
                          <AddToPlaylist
                            item={currentContentCreation}
                            clientId={getActiveClient()}
                            content={"contentCreations"}
                            creationPlayer={window.innerWidth > 650}
                            icon={<StyledTooltip placement="top" title={localization.get("add_to_playlist")}>
                              <List className="creation-player-playlist" style={{cursor: "pointer", height: 20, width: 20, marginRight: 5, marginTop: 2}}/>
                            </StyledTooltip>}
                          />
                        }
                        {!this.props.isPublic && !!getStoredUser() && getActiveChannel() &&
                          <StyledTooltip placement="top" title={localization.get("share")}>
                            <Share
                              style={{fontSize: 20, cursor: "pointer", marginTop: 2, marginRight: 5}}
                              onClick={() => request.contentCreation.shareByChannel(currentContentCreation.id)
                                .then(response => this.setState({
                                  showShareDialog: true,
                                  shareUrl: response.url,
                                }))
                                .then(() => analyticsInstance.share({
                                  name: getMainTranslation(currentContentCreation, "title"),
                                  type: "Material",
                                }))
                              }
                            />
                          </StyledTooltip>
                        }
                        {!!userCanEditCreators() &&
                          <StyledTooltip title={localization.get('edit')} placement="top">
                            <Edit style={{fontSize: 20, cursor: "pointer", marginTop: 2, marginRight: 5}}
                            onClick={() => EventManager.getInstance().dispatch(events.OPEN_EDITING_MODAL, {
                              ...currentContentCreation,
                              typeSlug: get(currentContentCreation, "contentCreator.type") ? get(currentContentCreation, "contentCreator.type.slug") : this.props.typeSlug
                            })} />
                          </StyledTooltip>
                        }
                        {!!userCanEditCreators() && this.props.onDelete &&
                          <StyledTooltip title={localization.get('delete')} placement="top">
                            <Delete style={{fontSize: 20, cursor: "pointer", marginTop: 2, marginRight: 5}}
                              onClick={() =>
                                GlobalConfirmationDialog.showDelete({
                                  dialogTitle: `${localization.get('tooltip.delete')} ${currentContentCreation.title}`,
                                  content: localization.get('confirmation_dialog.delete_content_message'),
                                  doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
                                  elementName: localization.get("delete"),
                                  deleteRequest: () => request.contentCreation.delete(currentContentCreation.id)
                                    .then(() => {
                                      this.handleClose()
                                      this.props.onDelete(currentContentCreation.id)
                                    }),
                                  doubleConfirmation: true,
                                  auxiliaryInformation: "",
                                })
                              }
                            />
                          </StyledTooltip>
                        }
                        {!!currentContentCreation.allow_download &&
                          <>
                            <StyledTooltip placement="top" title={localization.get("download")}>
                              <CloudDownload
                                style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                                onClick={() => this.downloadCreation()}
                              />
                            </StyledTooltip>
                            {currentContentCreation.allow_download && !userHasRestrictedAccess() &&
                              <StyledTooltip
                                placement="top"
                                title={<div style={{ whiteSpace: 'pre-line' }}>{currentContentCreation.downloadHistories}</div>}
                              >
                                <Info
                                  style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                                />
                              </StyledTooltip>
                            }
                          </>
                        }

                        {currentContentCreation && currentContentCreation.comments &&
                          <StyledTooltip placement="top" title={`${get(currentContentCreation, 'comments.user', "")}: ${get(currentContentCreation, 'comments.comment', "")}`}>
                            <Info  style={{height: 22,width: 24, marginRight: 5, marginTop: 2}}/>
                          </StyledTooltip>
                        }
                        {currentContentCreation && currentContentCreation.payment_required &&
                          <StyledTooltip placement="top" title={localization.get("download")}>
                            <ShoppingCartOutlinedIcon
                              style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                              onClick={() => this.onBuyProduct()}
                            />
                          </StyledTooltip>
                        }
                      </div>
                      : (this.props.allowDownload && this.props.handleDownload) &&
                        <div style={{display: "flex", flexDirection: "row"}}>
                          <StyledTooltip placement="top" title={localization.get("download")}>
                            <CloudDownload
                              style={{fontSize: 24, cursor: "pointer", marginTop: 2}}
                              onClick={() => this.props.handleDownload()}
                            />
                          </StyledTooltip>
                        </div>
                      }
                      <div className={"creation-player-data"}>
                        {currentContentCreation.contentCreator && !this.props.hideAuthor &&
                          <div className={"creation-player-author"}>
                            {this.state.noAuthorLink ?
                              <a className="creation-player-author-name">{currentContentCreation.contentCreator.name}</a>
                            :
                              <Link
                                className={"creation-player-author-name"}
                                onClick={this.props.onCreatorClick ? () => this.props.onCreatorClick() : null}
                                to={this.state.noAuthorLink ? null :
                                  (getActiveChannel() ?
                                    channelPathGenerator(this.props.isPublic ? `public/content_creators/${currentContentCreation.contentCreator.hashcode}` : `contenido/${currentContentCreation.contentCreator.id}-${currentContentCreation.contentCreator.name}`)
                                    : `public/content_creators/${currentContentCreation.contentCreator.hashcode}`)}
                              >{currentContentCreation.contentCreator.name}</Link>
                            }
                          </div>
                        }
                        <h3 style={{color: "var(--main-font-color, black)", margin: 0}}>{currentContentCreation.title}</h3>
                        <h3 style={{color: "var(--main-font-color, black)", fontWeight: "300", margin: 0}}>{currentContentCreation.description && (currentContentCreation.description.length > 100 ? currentContentCreation.description.substring(0,100).concat("...") : currentContentCreation.description)}</h3>
                        {!this.props.isPublic && currentContentCreation.tags && currentContentCreation.tags.length > 0 &&
                          <div className={"creation-tags"}>
                            <h3 style={{textDecoration: "underline"}}>{localization.get("content_creation.tags")}</h3>
                            {currentContentCreation.tags.slice(0,12).map((t) => {
                              if (get(currentContentCreation, "contentCreator.type") || this.props.type) { 
                                const type = get(currentContentCreation, "contentCreator.type") ? currentContentCreation.contentCreator.type : this.props.type;

                                return <Link 
                                onClick={this.handleClose}
                                style={{textDecoration: "none", color: "var(--main-font-color, black)",}}
                                to={channelPathGenerator(`busqueda-inteligente/content_creations/${type.id}-${slugify(type.name)}?type=${type.slug}&tags_ids=${t.id}`)}>{t.name}</Link>;
                              }
                              return <div>{t.name}</div>
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  </>
                }
                {(currentContentCreation.inappropriate_content && this.state.showInappropriate) &&
                <div className="content-creation-player-overlay-container">
                  {!this.state.hideInappropriate && 
                    <div className="content-creation-player-inappropriate">
                      <p>{localization.get("creation.inappropriate_content.warning_text")}</p>
                      <div className="content-creation-player-inappropriate-buttons">
                        <Button className="content-creation-player-inappropriate-button" onClick={() => this.setState({showInappropriate: false})} color='default' style={{ backgroundColor: "var(--main-font-color, black)", color: "var(--main-color, white)" }}>
                          {localization.get("creation.inappropriate_content.of_age")}
                        </Button>
                        <Button className="content-creation-player-inappropriate-button" onClick={() => this.setState({hideInappropriate: true})} color='default' style={{ backgroundColor: "var(--main-font-color, black)", color: "var(--main-color, white)" }}>
                          {localization.get("creation.inappropriate_content.not_of_age")}
                        </Button>
                      </div>
                    </div>
                  }
                </div>
                }
              </DialogContent>
            </div>
          :
            null
        }
      </Dialog>
    )
  }
}

const AudioBar = styled.audio`
/*margin: 20px 2%;*/
@media (max-width: 440px) {
  width: 100%;
}
`;

export default withStyles(styles)(ContentCreationPlayer);