import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {rootPath, getStoredUser} from "../../../api-client/core/authentication/utils";
import queryString from "querystring";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import browserHistory from "../../../utils/browserHistory";

class ChannelUserActivate extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  componentDidMount() {
    if (getStoredUser()) {
      request.authentication.logout();
    }

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.activate()
  }

  activate = () => {
    request.authentication.activateChannelUser({
      url: queryString.parse(this.props.location.search)["?url"], 
      password: this.state.password
    })
      .then(() => {
        let redirectTo = queryString.parse(this.props.location.search)["return_url"];

        browserHistory.push(rootPath(`/login?activation_complete=true${redirectTo ? `&redirect_to=${redirectTo}` : ""}`))
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: localization.get("activate.activate_fail"),
          type: GlobalSnackbarTypes.ERROR
        });
        this.setState({requesting: false});
      });
  }

  render() {
    return('')
  }
}

export default withStyles(loginPageStyle)(ChannelUserActivate);
