import React from 'react'
import {PlaylistAdd} from '@material-ui/icons'
import SimpleButton from '../utils/SimpleButton';

const Playlist = ({ onClick, tooltip }) => {
  return (
    <SimpleButton onClick={onClick} tooltip={tooltip}><PlaylistAdd /></SimpleButton>
  )
}

export default Playlist