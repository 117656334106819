import React from "react";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {
  sadaicCategoryToDescriptionString,
  sadaicCategoryToString,
  sadaicMonthsToString,
  sadaicSecondsToString
} from "../utils/mappers";
import localization from '../../../../config/localization'
import {SadaicCategories, SadaicDurations, SadaicSeconds} from "../utils/constants";

const SadaicPlansScreen = ({ sadaicCategory, sadaicDuration, sadaicVersions, onChange }) => {
  const handleInputChange = property => e => onChange({ [property]: e.target.value });

  const selectDuration = () => (
    <FormControl style={{ marginTop: 10 }} fullWidth>
      <InputLabel>{localization.get('license_wizard.life')}</InputLabel>
      <Select value={sadaicDuration} onChange={handleInputChange("sadaicDuration")}>
        <MenuItem value={SadaicDurations.SIX_MONTHS}>{sadaicMonthsToString(SadaicDurations.SIX_MONTHS)}</MenuItem>
        <MenuItem value={SadaicDurations.TWELVE_MONTHS}>{sadaicMonthsToString(SadaicDurations.TWELVE_MONTHS)}</MenuItem>
      </Select>
      <FormHelperText>{`${localization.get('license_wizard.track_will_be_removed')} ${sadaicMonthsToString(sadaicDuration)}`}</FormHelperText>
    </FormControl>
  );

  const selectVersions = () => (
    <div style={{ margin: "10px 0" }}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>{localization.get('license_wizard.campaign_detail')}</th>
            <th>{localization.get('license_wizard.second')}</th>
            <th style={{ textAlign: "right" }}># {localization.get('license_wizard.reductions')}</th>
          </tr>
        </thead>
        <tbody>
          {sadaicVersions.map((version, i) => (
            <tr>
              <td>{version.label}</td>
              <td>
                <FormControl style={{ flex: 1, marginRight: 10 }}>
                  <Select
                    value={version.seconds}
                    onChange={e => {
                      const value = e.target.value;
                      if (value === SadaicSeconds.NONE) {
                        sadaicVersions[i].seconds = SadaicSeconds.NONE;
                        sadaicVersions[i].reductions = 0;
                      } else {
                        sadaicVersions[i].seconds = value;
                      }
                      onChange({ sadaicVersions });
                    }}
                  >
                    <MenuItem value={SadaicSeconds.NONE}>---</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_TEN}>{sadaicSecondsToString(SadaicSeconds.UP_TO_TEN)}</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_FIFTEEN}>{sadaicSecondsToString(SadaicSeconds.UP_TO_FIFTEEN)}</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_TWENTY}>{sadaicSecondsToString(SadaicSeconds.UP_TO_TWENTY)}</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_THIRTY}>{sadaicSecondsToString(SadaicSeconds.UP_TO_THIRTY)}</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_FORTY_FIVE}>{sadaicSecondsToString(SadaicSeconds.UP_TO_FORTY_FIVE)}</MenuItem>
                    <MenuItem value={SadaicSeconds.UP_TO_SIXTY}>{sadaicSecondsToString(SadaicSeconds.UP_TO_SIXTY)}</MenuItem>
                    <MenuItem value={SadaicSeconds.MORE_THAN_SIXTY}>{sadaicSecondsToString(SadaicSeconds.MORE_THAN_SIXTY)}</MenuItem>
                  </Select>
                </FormControl>
              </td>
              <td style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextField
                  style={{ width: 50 }}
                  value={version.reductions}
                  type="number"
                  onChange={e => {
                    let num = e.target.value;
                    if (num < 0) {
                      num = 0;
                    }
                    sadaicVersions[i].reductions = num;
                    onChange({ sadaicVersions });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ marginTop: 10 }}
      >{localization.get('license_wizard.more_than_6_reductions')}</div>
    </div>
  );

  return (
    <div style={{ margin: "0 20px", display: "flex", flexDirection: "column" }}>
      <h3>{localization.get('license_wizard.sadaic')}</h3>
      <FormControl>
        <InputLabel>{localization.get('license_wizard.medias')}</InputLabel>
        <Select value={sadaicCategory} onChange={handleInputChange("sadaicCategory")}>
          <MenuItem value={SadaicCategories.RESTRINGIDO_I}>
            <div>{sadaicCategoryToDescriptionString(SadaicCategories.RESTRINGIDO_I)}</div>
          </MenuItem>
          <MenuItem value={SadaicCategories.RESTRINGIDO_II_III}>{sadaicCategoryToDescriptionString(SadaicCategories.RESTRINGIDO_II_III)}</MenuItem>
          <MenuItem value={SadaicCategories.RESTRINGIDO_IV}>{sadaicCategoryToDescriptionString(SadaicCategories.RESTRINGIDO_IV)}</MenuItem>
          <MenuItem value={SadaicCategories.USO_TOTAL}>{sadaicCategoryToDescriptionString(SadaicCategories.USO_TOTAL)}</MenuItem>
          <MenuItem value={SadaicCategories.VPNT}>{sadaicCategoryToDescriptionString(SadaicCategories.VPNT)}</MenuItem>
          <MenuItem value={SadaicCategories.INCLUSION_EN_TV}>{sadaicCategoryToDescriptionString(SadaicCategories.INCLUSION_EN_TV)}</MenuItem>
        </Select>
        <FormHelperText>{sadaicCategoryToString(sadaicCategory)}</FormHelperText>
      </FormControl>
      {(sadaicCategory !== SadaicCategories.VPNT && sadaicCategory !== SadaicCategories.INCLUSION_EN_TV) && (
        <div style={{ width: "100%" }}>
          {selectDuration()}
          {selectVersions()}
        </div>
      )}
      {sadaicCategory === SadaicCategories.VPNT && (
        <div dangerouslySetInnerHTML={{ __html: localization.get('license_wizard.vpnt_description') }}/>
      )}
      {sadaicCategory === SadaicCategories.INCLUSION_EN_TV && (
        <div dangerouslySetInnerHTML={{ __html: localization.get('license_wizard.tv_inclusion_description') }}/>
      )}
    </div>
  );
};

export default SadaicPlansScreen;
