import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoImage from "../../../assets/img/Letflow/no-image.jpg";
import PlaylistImage from "../../../assets/img/Letflow/playlist.jpg";
import {Link} from "react-router-dom";
import {request} from "../../../api-client";
import {Cancel} from "@material-ui/icons"
import PrevArrow from "../../../components/Letflow/Arrows/PrevArrow";
import NextArrow from "../../../components/Letflow/Arrows/NextArrow";
import {userHasRestrictedAccess, homePathGenerator} from "../../../api-client/core/authentication/utils";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";

class FavoritesCarousel extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      elements: props.elements || [],
      showContentCreationDialog: false,
      currentPlayingContent: {}
    }
  }

  makeImage = (element, type) => {
    let image;
    if (type === 'playlists') {
      if (element.created_by_admin) {
        if (element.image) {
          image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
        } if (element.featuredImage && element.featuredImage.image) {
          image = request.file.image.makeCroppedUrl(element.featuredImage.image.id, 400, 400)
        } else {
          image = PlaylistImage
        }
      } else {
        if (element.featuredImage && element.featuredImage.image) {
          image = request.file.image.makeCroppedUrl(element.featuredImage.image.id, 400, 400)
        } else {
          image = PlaylistImage
        }
      }
    }  else if (element.image) {
      image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
    } else {
      image = NoImage
    }
    return image
  };

  removeFromFavorite = (id, type) => {
    this.setState({elements: this.state.elements.filter(element => element.id !== id)});
    switch (type) {
      case 'album':
        request.favorite.remove(id);
        break;
      case 'artist':
        request.artistFavorite.remove(id);
        break;
      case 'playlists':
        request.playlist.removeFavorite(id);
        break;
      case 'speaker':
        request.speakerFavorite.remove(id);
        break;
      case 'content_creator':
        request.contentCreatorFavorite.remove(id);
        break;
      case 'video_content_creation':
        request.contentCreationFavorite.remove(id);
        break;
    }
  };

  clearCurrentPlayingContent = () => {
    this.setState({showContentCreationDialog: false, hasPrev: false, hasNext: false, currentIndex: null, currentPlayingContent: {}})
  }

  setCurrentPlayingContent = (index) => {
    let hasPrev = index > 0 ;
    let hasNext = index < (this.state.elements.length - 1);
    this.setState({showContentCreationDialog: true, currentPlayingContent: this.state.elements[index], hasPrev: hasPrev, hasNext: hasNext, currentIndex: index},
      // () => GAevent('Contenido', "Play", this.state.currentPlayingContent && this.state.currentPlayingContent.name)
    );
  }

  render() {
    const {slidesToShow, type} = this.props;
    const {elements} = this.state;
    let settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      initialSlide: 0,
      prevArrow: <PrevArrow/>,
      nextArrow: <NextArrow/>,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow:slidesToShow === 6 ? 5 : 4,
            slidesToScroll: slidesToShow === 6 ? 5 : 4,
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };


    return (
      <section data-max-per-row="4" style={{ marginBottom: "0px", paddingBottom: "10px", paddingLeft: 0, paddingRight: 0 }} className="category">
        <ContentCreationPlayer
            show={this.state.showContentCreationDialog}
            onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(this.state.currentIndex+1) : null}
            onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(this.state.currentIndex-1) : null}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.clearCurrentPlayingContent()}
            noAuthorLink={userHasRestrictedAccess()}
          />
        <div className="row-category" style={{ position: "relative", float: "left", width: "100%" }}>
          <div style={{ width: "100%", float: "left", position: "relative" }}>
            <div className="carousel-wrapper">
              <Slider {...settings}>
                {elements.map((element,i) => {
                  return (
                    <article style={{ width: '100%' }} className="album-grid-cell is-collapsed opacity1">
                      <div className="album-grid-img" style={{ width: "100%" }}>
                        <Link
                          to={type !== 'video_content_creation' ? homePathGenerator(`${type}/${element.id}`) : "#"}
                          onClick={() => type == 'video_content_creation' && this.setCurrentPlayingContent(i)}>
                          <div className="category-item" style={{ width: "100%", cursor: 'pointer' }}>
                            <div style={{
                              position: "relative",
                              float: "left",
                              width: "100%",
                              padding: "50.0% 0"
                            }}>
                              <div style={{
                                backgroundImage: `url(${this.makeImage(element, type)})`,
                                position: "absolute",
                                top: "0",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover"
                              }} >
                                <Cancel
                                  style={{
                                    position: "absolute",
                                    fontSize: "30px",
                                    top: "-15px",
                                    right: "-15px",
                                    zIndex: 10000,
                                    color: "black",
                                    backgroundColor: "white",
                                    borderRadius: "50%"
                                  }}
                                  onClick={(e) => {
                                    this.removeFromFavorite(element.id, type);
                                    e.preventDefault()
                                  }}
                                />
                                {(type === 'playlists') &&
                                  <div className="featured-image-title"
                                    style={{
                                      color: element.featuredImage ? element.featuredImage.color : "black",
                                    }}
                                  >
                                    {element.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="category-info text-left album-info">
                              <h6 className="album-subtitle mt0">{element.subtitle || <span>&nbsp;</span>}</h6>
                              <h5 className="album-title">{element.title || element.name || <span>&nbsp;</span>}</h5>
                            </div>
                            <h7 className="album-description">
                              <small>{element.artist ?
                                <Link className="album-description-link" to={homePathGenerator(`artist/${element.artist.id}`)}>by {element.artist.name}</Link>
                                : element.shortDescription ? element.shortDescription : <span>&nbsp;</span>}</small>
                            </h7>
                          </div>
                        </Link>
                      </div>
                    </article>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FavoritesCarousel.defaultProps = {
  slidesToShow: 4,
  type: 'album'
};



export default FavoritesCarousel
