import React from "react";
import HomeItemDescription from "./HomeItemDescription";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Add} from "@material-ui/icons";
import EventManager, {events} from "../../../utils/EventManager";
import {
  getActiveChannel,
  getActiveClient, getStoredChannel,
  homePathGenerator,
  userIsReadOnly
} from "../../../api-client/core/authentication/utils";
import HomeElementCarouselItem from "./HomeElementCarouselItem";
import localization from "../../../config/localization"
import HomeElementCarouselItemOnlyImage from "./HomeElementCarouselItemOnlyImage";
import styled from "styled-components";
import browserHistory from "../../../utils/browserHistory";
import st from "../HomeNew.module.css"
import HomeElementFeaturedItem from "./HomeElementFeaturedItem";
import NextArrow from "../../../components/Letflow/Arrows/NextArrow";
import PrevArrow from "../../../components/Letflow/Arrows/PrevArrow";
import {get} from "lodash"
import HomeElementTitle from "./HomeElementTitle";

class HomeElementCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeElement: this.props.homeElement,
      requestedElement: null,
      showContentCreationDialog: false,
      mobileResize: (getActiveChannel() && getStoredChannel().preferences && getStoredChannel().preferences.mobile_font_resize) ? (Number(getStoredChannel().preferences.mobile_font_resize) / 100) : 1,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      get(nextProps, "homeElement.items.data", null) &&
      get(this.state.homeElement, "items.data", null) &&
      get(nextProps, "homeElement.items.data", []).length !== get(this.state.homeElement, "items.data", []).length) {
      this.setState({homeElement: nextProps.homeElement})
    }
  }

  handleElementClick = element => {
    EventManager.getInstance().dispatch(
      events.HOME_ELEMENT_ITEM_CLICKED,
      element
    );
    
    if(element.kind !== "video_content_creation") {
      this.setState({requestedElement: null, loading: true, selectedElement: element.id});
    this.props.requestElement(element.id,element.kind, getActiveClient(),element)
      .then(requestedElement => {this.setState({requestedElement, loading: false,selectedElement: null});})
      .catch(() => this.setState({ loading: false, selectedElement: null }));
    }else {
        EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  element.id, materialList: element.materialIds || []});
    }
  };

  makeItemDescription = () => {
    if (this.state.requestedElement) {
      return (
        <div
          className="home-element-carousel-item-description"
          style={{
            width: "100%",
            float: "left",
            position: "relative",
            marginTop: "25px",
            paddingTop: "0"
          }}
        >
          <HomeItemDescription
            {...this.props}
            loading={this.state.loading}
            data={
              this.state.requestedElement ||
              this.state.homeElement.items.data.find(
                x => x.id === this.state.selectedElement
              )
            }
            onClose={() => {
              this.setState({ requestedElement: null });
            }}
            trackType={this.props.trackType}
            dataType={this.props.dataType}
            favorites={
              this.state.requestedElement.client_type
                ? this.props.speakersFavorites
                : this.state.requestedElement.kind === "playlist"
                ? this.props.playlistFavorites
                : this.props.favorites
            }
            home={true}
          />
        </div>
      );
    }

    return null;
  };

  renderMoreItems = (title, onClick, quantity = 1) => {
    let elements = [];
    for (let i = 0; i < quantity; i++) {
      elements.push(
        <article
          style={{ width: "100%" }}
          className="album-grid-cell is-collapsed opacity1"
        >
          <div className="album-grid-img" onClick={onClick} style={{ width: "90%", padding: 0 }}>
            <div className="category-item" style={{ width: "100%",
              backgroundColor: getActiveChannel() ? getStoredChannel().preferences.element_background : "#F4F4F4"
            }}>
              <div style={{ cursor: "pointer" }}>
                <div
                  style={{
                    position: "relative",
                    float: "left",
                    width: "100%",
                    padding: "50.0% 0",
                    backgroundColor: "#F6F7EF"
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    border: "3px solid white",
                    borderRadius: "50%",
                    width: "120px",
                    height: "120px",
                    top: "calc(50% - 60px )",
                    left: "calc(50% - 60px)"
                  }}
                >
                  <Add
                    style={{
                      position: "absolute",
                      fontSize: "90px",
                      color: "white",
                      top: "calc(50% - 45px)",
                      left: "calc(50% - 45px)"
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    textAlign: "center",
                    height: "20px",
                    backgroundColor: "black",
                    color: "white",
                    zIndex: 10,
                    lineHeight: "20px",
                    fontSize: "13px",
                    borderTopLeftRadius: 14,
                    borderTopRightRadius: 14,
                    cursor: "pointer",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    width: "80%"
                  }}
                >{title}</div>
              </div>
            </div>
          </div>
        </article>
      );
    }
    return elements;
  };

  addStyleToCarouselWithDescription = () => {
    if ((this.props.shape === 'square' || this.props.shape === 'rectangle') && !this.props.onlyImage && window.innerWidth > 600) {
      return {width: 'calc(100% + 30px)', margin: "-15px"}
    } else if (this.props.shape === 'square' && this.props.onlyImage && window.innerWidth > 600) {
      return {width: 'calc(100% + 40px)'}
    } else {
      return {}
    }
  }

  addStyleToCarouselRightArrow = () => {
    if ((this.props.shape === 'square' || this.props.shape === 'rectangle' || this.props.shape === 'rectangle_event') && !this.props.onlyImage) {
      return "arrow-next"
    } else if (this.props.shape === 'square' && this.props.onlyImage) {
      return "arrow-next-square-carousel"
    } else {
      return ""
    }
  }

  slideNumber = (breakpoint) => {
    if (this.props.movie) return 1;
    switch (breakpoint) {
      case 'none':
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x2':
            return 2
          case 'x3':
            return 3
          case '16:9':
            return this.props.columns || 6
          case 'x4':
          case 'square':
          case 'rectangle':
          case 'round':
          case 'rectangle_event':
            return this.props.columns
        }
        break;
      case 1500:
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x2':
            return 2
          case 'x3':
            return 3
          case '16:9':
            return this.props.columns || 6
          case 'x4':
          case 'square':
          case 'rectangle':
          case 'round':
          case 'rectangle_event':
            return this.props.columns
        }
        break;
      case 1280:
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x2':
            return 2
          case 'x3':
            return 3
          case '16:9':
            return this.props.columns === 4 ? 4 : 5
          case 'x4':
            return 3
          case 'square':
          case 'rectangle':
          case 'rectangle_event':
            return 3
          case 'round':
            return this.props.columns === 6 ? 4 : 3
        }
        break;
      case 920:
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x4':
            return 2
          case 'x2':
            return 2
          case 'x3':
            return 2
          case '16:9':
            return this.props.columns === 4 ? 2 : 4
          case 'square':
          case 'rectangle':
          case 'rectangle_event':
            return 2
          case 'round':
            return this.props.columns === 6 ? 3 : 2
        }
        break;
      case 640:
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x4':
            return 1
          case 'x2':
            return 1
          case 'x3':
            return 1
          case '16:9':
            return 1
          case 'square':
          case 'rectangle':
          case 'rectangle_event':
            return 1
          case 'round':
            return this.props.columns === 6 ? 2 : 1
        }
        break;
      case 480:
        switch (this.props.shape) {
          case 'x1':
            return 1
          case 'x4':
            return 1
          case 'x2':
            return 1
          case 'x3':
            return 1
          case '16:9':
            return 1
          case 'square':
          case 'rectangle':
          case 'rectangle_event':
            return 1
          case 'round':
            return this.props.columns === 6 ? 2 : 1
        }
        break;
    }
  }

  PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <PrevArrow onClick={onClick}
           className={`${className} ${st[(this.props.shape === 'square' || this.props.shape === 'rectangle' || this.props.shape == 'rectangle_event') && !this.props.onlyImage ? "arrow-prev" : ""]}`}
           style={{...style}} />
    );
  }

  NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <NextArrow onClick={onClick}
           className={`${className} ${st[this.addStyleToCarouselRightArrow()]}`}
           style={{...style}}/>
    );
  }

  render() {
    let settings = {
      dots: get(this.props.homeElement, 'use_dots', false),
      dotsClass: 'slick-dots custom-dots',
      appendDots: dots => (
        <div style={{
          position: 'absolute',
          bottom: '-25px',
        }}>
          <ul style={{ margin: "0" }}> {dots} </ul>
        </div>
      ),
      infinite: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: this.slideNumber('none'),
      slidesToScroll: this.slideNumber('none'),
      initialSlide: 0,
      prevArrow: <this.PrevArrow/>,
      nextArrow: <this.NextArrow/>,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: this.slideNumber(1500),
            slidesToScroll: this.slideNumber(1500)
          }
        },
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: this.slideNumber(1280),
            slidesToScroll: this.slideNumber(1280)
          }
        },
        {
          breakpoint: 919,
          settings: {
            slidesToShow: this.slideNumber(920),
            slidesToScroll: this.slideNumber(920),
          }
        },
        {
          breakpoint: 639,
          settings: {
            slidesToShow: this.slideNumber(640),
            slidesToScroll: this.slideNumber(640),
            dots: false,
            infinite: true,
          }
        }
      ]
    };
    const mapItems = () => {
      return this.state.homeElement.items.data.map((element, key) => {
        if (this.props.homeElement.data_source === 'user_library' || this.props.homeElement.data_source === 'production' || this.props.onlyImage || this.props.shape === '16:9' || this.props.shape === 'round') {
          return (
            <HomeElementCarouselItemOnlyImage
              {...this.props}
              data={element}
              key={key}
              number={key}
              handleElementClick={() => {
                this.handleElementClick(element);
                this.setState({ active: key });
              }}
            />
          );
        }else if (this.props.homeElement.type === 'featured') {
          return (
            <HomeElementFeaturedItem
              {...this.props}
              data={element}
              key={key}
              number={key}
              handleElementClick={() => {
                this.handleElementClick(element);
                this.setState({ active: key });
              }}
            />
          );
        } else {
          return (
            <HomeElementCarouselItem
              {...this.props}
              data={element}
              key={key}
              handleElementClick={() => {
                this.handleElementClick(element);
                this.setState({ active: key });
              }}
            />
          );
        }

      })
    }
    
    let playlistMissingElement = (this.state.homeElement.items.data.length < 4) ?  (4 - this.state.homeElement.items.data.length) : 1
    let gridMobile = !!this.state.homeElement.mobile_grid && window.innerWidth <= 600;
    return (
      <section style={this.props.suggestedCarousel ? {padding: 0, position: "relative", float: "left"} : {position: "relative", float: "left"}}>
        <div style={{
          left: 0,
          width: "100%",
          position: "absolute",
          height: this.props.backgroundStyle === 'default' ? "0px" : `${this.props.backgroundStyle}%`,
          backgroundColor: this.props.backgroundColor
        }}/>
        <div
          className={st["row-category"]}
          style={{ position: "relative", float: "left", width: "100%", paddingBottom: this.props.bottomPadding }}
        >
          <HomeElementTitle {...this.props} />
          {gridMobile ? 
            <DivRR style={this.addStyleToCarouselWithDescription()}>
              {mapItems()}
            </DivRR> 
          :
          <DivRR style={this.addStyleToCarouselWithDescription()}>
            <Slider {...settings} >
              {(this.state.homeElement.data_source === "production" && !userIsReadOnly()) &&
                this.renderMoreItems('New Project', () => browserHistory.push(homePathGenerator(`projects/create`)))}
              {mapItems()}
              {(this.state.homeElement.data_source === "user_library" && !userIsReadOnly()) &&
                this.renderMoreItems(localization.get('playlist.create_playlist'),
                  () => this.props.history.push(homePathGenerator(`playlists`)),
                  playlistMissingElement
                )
              }
            </Slider>
          </DivRR>
        }
          {this.makeItemDescription()}
        </div>
      </section>
    );
  }
}

export default HomeElementCarousel;


const DivRR = styled.div`
  width: 100%;
  position: relative;
  float: left;
 //@media (max-width: 500px){
 //   display: flex; 
 //   justify-content: center;
 //}

  .custom-dots {
    li {
      button:before {
        color: var(--main-font-color) !important;
        opacity: 0.5;
        font-size: 8px;
      }
      
      &.slick-active button:before {
        color: var(--main-font-color) !important;
        opacity: 1;
      }
    }
  }
`;