import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {CloudDownload} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'

class TableDownloadButton extends Component {

  state = {
    downloading: false
  }

  render = () =>
    <TableButton title={localization.get('tooltip.download')} onClick={this.download} disabled={this.state.downloading}>
      <CloudDownload />
    </TableButton>

  download = () => {
    this.setState({ downloading: true })
    // const action = this.props.type === 'voice' ? analyticsInstance.downloadVoice : analyticsInstance.downloadMusic
    // action(this.props.title, this.props.duration)
    this.props.download().then(this.handleSuccess).catch(this.handleFail)
  }

  handleSuccess = () => this.setState({ downloading: false })

  handleFail = () => this.setState({ downloading: false })
}

TableDownloadButton.propTypes = {
  download: PropTypes.func.isRequired,
}

export default TableDownloadButton