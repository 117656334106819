import React, {Component} from "react";
import ReactTable from "react-table";
import localization from "../../../config/localization";
import PropTypes from "prop-types";
import deepEqual from "deep-equal";
import {makePageSizeFrom} from "./utils";
import {Add, Remove} from "@material-ui/icons";
import {Fade, IconButton} from "@material-ui/core";
import _ from "lodash";
import ErrorBoundary from "../ErrorBoundary";
import "./Pagination/style.css";
import browserHistory from "../../../utils/browserHistory";
import queryString from "querystring";
import Paginator from "../Paginator";

class ServerSidePaginationTable extends Component {
  state = {
    loading: false,
    data: [],
    pages: 0,
    filtered: [],
    sorted: [],
    page: 0,
  };

  // public

  removeById = id => this.setState(state => ({ data: state.data.filter(x => x.id !== id) }));

  changeActiveById = (id, active) => {
    this.state.data.find(vd => vd.id === id).active = active
    this.setState({});
  }

  forceUpdate = () => {
    const { filtered, sorted } = this.state;
    const { requestTableData, perPage } = this.props;
    this.fetchData(requestTableData, perPage)({ page: 0, filtered, sorted });
  };

  getData = () => this.state.data

  componentDidMount = () => {
    window.onpopstate = this.handlePopState
    this.handlePopState()
  };

  handlePopState = () => {
    let promise = this.props.readAndWriteUrl ? this.readUrlAndSetState() : Promise.resolve()
    promise.then(()=> {
      const { page, filtered, sorted } = this.state;

      const { requestTableData, perPage } = this.props;

      this.fetchData(requestTableData, perPage)({ page, filtered, sorted });

      this.fetchFilterIntervalId = this.initializeFetchFilterInterval();
    })
  }

  initializeIsWritingTimeout = () => {
    this.setState({ isWrittingFilter: true });
    clearTimeout(this.isWrittingTimeoutId);
    this.isWrittingTimeoutId = setTimeout(() => {
      this.setState({ isWrittingFilter: false });
    }, 500);
  };

  initializeFetchFilterInterval = () => {
    let previousFilter = this.state.filtered;

    return setInterval(() => {
      if (this.state.loading || this.state.isWrittingFilter) {
        return;
      }

      const toOrdered = array =>
        array
          .map(x => x.id)
          .sort()
          .map(x => ({
            id: x,
            value: array.find(y => y.id === x).value,
          }));
      const orderedPreviousFilter = toOrdered(previousFilter);
      const orderedFiltered = toOrdered(this.state.filtered);

      if (deepEqual(orderedPreviousFilter, orderedFiltered)) {
        return;
      }

      this.writeURLAndSetState({page: 0, filtered: this.state.filtered, sorted: this.state.sorted})

      this.fetchData(this.props.requestTableData, this.props.perPage)({
        page: 0,
        filtered: this.state.filtered,
        sorted: this.state.sorted,
      }).then(x => {
        previousFilter = this.state.filtered.slice();
        return x;
      });
    }, 500);
  };

  componentWillUnmount = () => {
    clearInterval(this.fetchFilterIntervalId);
    clearTimeout(this.isWrittingTimeoutId);
  };

  render = () => {
    //
    const { loading, data, pages, page, filtered, sorted } = this.state;

    const { columns, perPage, subComponent, getTrProps } = this.props;

    const expand = {
      Header: "",
      width: 50,
      filterable: false,
      sortable: false,
      expander: true,
      Expander: ({ isExpanded, ...rest }) => {
        if(typeof rest.original.expanded !== "undefined" && !rest.original.expanded) {
          return null;
        } else {
          if (isExpanded) {
            return (
              <IconButton style={{height: "35px", width: "35px"}}>
                <Remove/>
              </IconButton>
            );
          } else {
            return (
              <IconButton style={{height: "35px", width: "35px"}}>
                <Add/>
              </IconButton>
            );
          }
        }
      },
    };

    return (
      <ErrorBoundary>
        <ReactTable
          {...this.props}
          page={page}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          data={data}
          columns={subComponent ? _.concat(expand, columns) : columns}
          SubComponent={subComponent}
          onPageChange={this.handlePageChange}
          onFilteredChange={this.handleFiltered}
          onSortedChange={this.handleSorted}
          pages={pages}
          defaultPageSize={12}
          previousText={localization.get("table.previous_text")}
          nextText={localization.get("table.next_text")}
          noDataText={localization.get("table.no_data_text")}
          pageText={localization.get("table.page_text")}
          ofText={localization.get("table.of_text")}
          rowsText={localization.get("table.rowsText")}
          loadingText={localization.get("table.loading_text")}
          pageSize={makePageSizeFrom(perPage, data.length)}
          loading={loading}
          LoadingComponent={() => this.loadingComponent(loading)}
          showPageSizeOptions={false}
          className="-striped -highlight"
          resizable={false}
          filterable
          showPaginationTop={typeof this.props.showPaginationTop !== 'undefined' ? this.props.showPaginationTop : !this.props.showPaginationBottom}
          showPaginationBottom={!!this.props.showPaginationBottom}
          getTrProps={getTrProps}
          manual
          PaginationComponent={this.props.showCustomPagination && ((pagination) => <Paginator currentPage={pagination.page} onPageChange={pagination.onPageChange} pages={pagination.pages}/>)}
        />
      </ErrorBoundary>
    );
  };

  loadingComponent = loading =>
    loading ? (
      <Fade in={loading}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#f5f5f57a",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ScaleLoader /> */}
        </div>
      </Fade>
    ) : null;

  handlePageChange = page => {
    const { filtered, sorted } = this.state;
    const { requestTableData, perPage } = this.props;
    this.writeURLAndSetState({page, filtered, sorted})
    this.fetchData(requestTableData, perPage)({ page, filtered, sorted });
    return { page };
  };

  handleFiltered = filtered => {
    this.initializeIsWritingTimeout();
    this.setState({ filtered });
  };

  handleSorted = sorted => {
    const { filtered } = this.state;
    const { requestTableData, perPage } = this.props;
    this.writeURLAndSetState({page: 0, filtered, sorted})
    this.fetchData(requestTableData, perPage)({ page: 0, filtered, sorted });
    return { sorted };
  };

  writeURLAndSetState = ({page, filtered, sorted}) => {
    if (this.props.readAndWriteUrl) {
      browserHistory.push({
        pathname: window.location.pathname,
        search: `?table_params=${encodeURIComponent(JSON.stringify({page, filtered, sorted}))}`
      })
    }
    this.setState({page, filtered, sorted})
  }

  readUrlAndSetState = () =>
    new Promise( resolve => {
      let tableParams = queryString.parse(browserHistory.location.search)['?table_params']
      if (tableParams) {
        this.setState(JSON.parse(tableParams))
      }
      resolve()
    }
  )

  fetchData = (requestTableData, perPage) => status =>
    this.startLoading()
      .then(() => requestTableData(this.makeRequestTableDataParams(perPage, status)))
      // .then(() => new Promise(() => {}))
      .then(this.finishLoading);

  startLoading = () => Promise.resolve(this.setState({ loading: true }));

  makeRequestTableDataParams = (perPage, { page, filtered, sorted } = {}) => ({
    perPage,
    page,
    filterBy: this.mapFiltered(filtered),
    orderBy: this.reduceSorted(sorted),
  });

  mapFiltered = filtered => {
    if (!filtered) {
      return [];
    } else {
      return filtered.map(x => ({ column: x.id, filter: x.value }));
    }
  };

  reduceSorted = sorted => {
    if (!sorted || sorted.length === 0) {
      return undefined;
    } else {
      const { id, desc } = sorted[0];
      return {
        column: id,
        type: desc ? "desc" : "asc",
      };
    }
  };

  finishLoading = result => this.setState({ loading: false, data: result.data, pages: result.meta ? result.meta.last_page : 1 });
}

ServerSidePaginationTable.defaultProps = {
  perPage: 12,
  readAndWriteUrl: true
};

ServerSidePaginationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  requestTableData: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
};

export default ServerSidePaginationTable;
