import React, {Component} from 'react'
import localization from '../../../config/localization';
import {Add, ArrowDropDown, ArrowDropUp, Cancel} from '@material-ui/icons'
import {ClientSidePaginationTable, ServerSidePaginationTable, TableButton, TableButtonsBuilder,} from '../Table';
import {Tooltip} from '@material-ui/core';
import arrayMove from 'array-move'

class ItemSelectionTables extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
      removed: props.removed || []
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.requestPaginatedItems !== prevProps.requestPaginatedItems && !this.props.channelPlaylist) {
      this.refs.serverSideTable.forceUpdate()
    }
  }

  render = () => {

    const {
      selected,
      removed
    } = this.state

    const {
      requestPaginatedItems,
      columns,
      extraButtonFactories,
      selectedItemsChanged,
      removedItemsChanged,
      selectedTableTitle,
      toSelectTableTitle,
      hideOrderColumn,
      onOrderChange
    } = this.props

    const makeActionsColumn = (buttonTitle, ButtonIcon, onActionButtonClicked, renderActionButtonWhen = () => true) => {
      return {
        Header: localization.get('table.actions'),
        id: 'actions',
        filterable: false,
        sortable: false,
        accessor: item => {
          let tableButtonsBuilder = new TableButtonsBuilder()
          tableButtonsBuilder.withOther(<TableButton title={buttonTitle} onClick={() => onActionButtonClicked(item)} disabled={!renderActionButtonWhen(item)}><ButtonIcon /></TableButton>)
          extraButtonFactories.forEach(extraButtonFactory => tableButtonsBuilder.withOther(extraButtonFactory(item)))
          return tableButtonsBuilder.build()
        }
      }
    }

    const selectedActionsColumn = makeActionsColumn(localization.get('table.remove'), Cancel, item => {
      const newSelected = selected.filter(x => x !== item)
      this.setState({ selected: newSelected, removed: removed.concat([item]) })
      selectedItemsChanged(newSelected)
      removedItemsChanged && removedItemsChanged(removed.concat([item]))
    })

    const toSelectActionsColumn = makeActionsColumn(localization.get('table.add'), Add, item => {
      const newSelected = selected.concat([item])
      this.setState({ selected: newSelected, removed: removed.filter(music => music.id !== item.id ) })
      selectedItemsChanged(newSelected)
      removedItemsChanged && removedItemsChanged(removed.filter(music => music.id !== item.id ))
    }, item => !selected.find(s => s.id === item.id))

    const table = (title, component) => <div>
      {title}
      {component}
    </div>

    const reorderColumn = {
      id: 'order',
      Cell: ({ original }) => {
        const ReorderButton = makeReorderButtonComponent(
          selected,
          original,
          reorderedSelected => this.setState({ selected: reorderedSelected }, () => selectedItemsChanged(reorderedSelected)),
          onOrderChange
        )
        return (
          <div style={{ display: 'grid' }}>
            <ReorderButton Icon={ArrowDropUp} nextIndex={index => index - 1} tooltipKey='move_up'></ReorderButton>
            <ReorderButton Icon={ArrowDropDown} nextIndex={index => index + 1} tooltipKey='move_down'></ReorderButton>
          </div>
        )
      },
      sortable: false,
      filterable: false,
      width: 50,
      show: !hideOrderColumn
    }

    const selectedTable = table(selectedTableTitle, <ClientSidePaginationTable perPage={999} showPagination={false} data={selected} columns={[reorderColumn].concat(columns.map(column => ({ ...column, sortable: false, filterable: false }))).concat([selectedActionsColumn])}/>)
    const toSelectTable = table(toSelectTableTitle, <ServerSidePaginationTable ref='serverSideTable' perPage={5} readAndWriteUrl={false} requestTableData={requestPaginatedItems} columns={columns.concat([toSelectActionsColumn])}/>)

    return (
      <div>
        {selectedTable}
        <br />
        <hr />
        <br />
        {toSelectTable}
      </div>
    )
  }
}

const makeReorderButtonComponent = (selected, original, onReorder, onOrderChange) => ({ tooltipKey, Icon, nextIndex }) => (
  <Tooltip title={localization.get(tooltipKey)}>
    <Icon style={{ cursor: 'pointer' }} onClick={() => {
      const index = selected.indexOf(original)
      const reorderedSelected = arrayMove(selected, index, nextIndex(index))
      onReorder(reorderedSelected)
      onOrderChange()
    }} />
  </Tooltip>
)

ItemSelectionTables.defaultProps = {
  selected: [],
  extraButtonFactories: [],
}

export default ItemSelectionTables
