import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import Tags from "./All";
import Create from "./Create";
import Update from "./Update";
import DemandRanking from "./DemandRanking"

const TagsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/ranking/tags/demand" component={DemandRanking} />
        <Route path="/panel/music/tags/create" component={Create} />
        <Route path="/panel/music/tags/:id/edit" component={Update} />
        <Route path="/panel/talents/tags/create" component={Create} />
        <Route path="/panel/talents/tags/:id/edit" component={Update} />
        <Route path="/panel/audiovisual/tags/create" component={Create} />
        <Route path="/panel/audiovisual/tags/:id/edit" component={Update} />
        <Route path="/panel/editorial/tags/create" component={Create} />
        <Route path="/panel/editorial/tags/:id/edit" component={Update} />
        <Route
          render={props => (
            <Tags
              isMusic={window.location.pathname.startsWith("/panel/music")}
              isTalent={window.location.pathname.startsWith("/panel/talents")}
              isAudiovisual={window.location.pathname.startsWith("/panel/audiovisual")}
              isEditorial={window.location.pathname.startsWith("/panel/editorial")}
              {...props}
            />
          )}
        />
      </Switch>
    </Router>
  )
}

export default TagsRouting