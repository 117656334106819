import "layouts/Home/Home.css";
import _noImage from '../../../assets/img/Letflow/no-image.jpg'
import {request} from "../../../api-client";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {
  addHttpsToUrl,
  channelPathGenerator, getActiveChannel, getMainTranslation, getStoredChannel, rootPath,
  slugify,
  userCanMakeReservations
} from "../../../api-client/core/authentication/utils";
import {noop} from "../../../utils";
import EventManager, {events} from "../../../utils/EventManager";
import browserHistory from "../../../utils/browserHistory";
import localization from "../../../config/localization";
import {navigateToCurrentLangRoute} from "../../../routes/channel";
import {get} from "lodash";
import {getMonthName} from "../../../utils";
import moment from "moment";


export const emptyImage = _noImage

export const onImageError = e => {
    const currentTarget = e.currentTarget;
    currentTarget.onerror = null; // prevents looping
    currentTarget.src= emptyImage;
}

export const makeImageUrl = (element, size) => element.image ? request.file.image.makeCroppedUrl(element.image.id, size, size) : _noImage

export const makeImageUrlWithSize = (element, size, fit=false) => {
  let makeImage = fit ? (i, w, h) => request.file.image.makeFittedUrl(i, w, h) : (i, w, h) => request.file.image.makeCroppedUrl(i,w,h);
  if (element && element.image) {
    if (size == "xl") {
      return makeImage(element.image.id, 1920, 1080)
    }
    if (size === 'lg') {
      return makeImage(element.image.id, 1280 , 720)
    }
    if (size === 'md') {
      return makeImage(element.image.id, 400 , 400)
    }
    if (size === 'md-16:9') {
      return makeImage(element.image.id, 400 , 225)
    }
    if (size === 'sm') {
      return makeImage(element.image.id, 200, 200)
    }
    if (size === 'xs') {
      return makeImage(element.image.id, 50, 50)
    }
    if (size === 'reel-lg') {
      return makeImage(element.image.id, 1200 , 500)
    }
    if (size === 'reel-md') {
      return makeImage(element.image.id, 600 , 240)
    }
    if (size === 'reel-sm') {
      return makeImage(element.image.id, 180, 100)
    }
  }  else {
    return _noImage
  }
}

export const makeThumbnailUrlWithSize = (element, size, fit=false) => {
  if (element.thumbnail) {
    let makeImage = fit ? (i, w, h) => request.file.image.makeFittedUrl(i, w, h) : (i, w, h) => request.file.image.makeCroppedUrl(i,w,h);
    if (size === 'lg') {
      return makeImage(element.thumbnail.id, 1280 , 720)
    }
    if (size === 'md') {
      return makeImage(element.thumbnail.id, 400 , 400)
    }
    if (size === 'md-16:9') {
      return makeImage(element.thumbnail.id, 400 , 225)
    }
    if (size === 'sm') {
      return makeImage(element.thumbnail.id, 200, 200)
    }
    if (size === 'xs') {
      return makeImage(element.thumbnail.id, 50, 50)
    }
    if (size === 'reel-lg') {
      return makeImage(element.thumbnail.id, 1200 , 500)
    }
    if (size === 'reel-md') {
      return makeImage(element.thumbnail.id, 900 , 375)
    }
    if (size === 'reel-sm') {
      return makeImage(element.thumbnail.id, 180, 100)
    }
  }  else {
    return _noImage
  }
}

export const makeThumbnailUrl = (element, width, height) => element.thumbnail ? request.file.image.makeCroppedUrl(element.thumbnail.id, width, height) : _noImage

export const makeAlbumImageUrl = (album, size) => makeImageUrl(album, size)

export const makeSpeakerImageUrl = (speaker, size) => makeImageUrl(speaker, size)

export const makePlaylist = (element, tracksProperty, requestType, onClick = noop) => element[tracksProperty].map(track => ({
  fetchSrc: () => request[requestType].audioUrl(track.id),
  title: track.title,
  subtitle: element.title,
  image: makeImageUrlWithSize(element, 'xs'),
  onImageClick: () => onClick(element),
  onTitleClick: () => onClick(element),
  onSubtitleClick: () => onClick(element),
  type: requestType,
}))

export const makeAlbumPlaylist = (album, onClick) => makePlaylist(album, 'musics', 'music', onClick)

export const makeSpeakerPlaylist = (speaker, onClick) => makePlaylist(speaker, 'voices', 'voice', onClick)

export const makeActionButtons = (
  track,
  playlist,
  requestType,
  clientId,
  onAddToPlaylistClosed,
  history,
  match,
  showPlaylistButton = true
) => {

  const builder = new TableButtonsBuilder()

  const modelType = requestType + 's' // track type has to be plural

  builder.withDownload(() => request[requestType].download(track.id, {}), { title: track.title, duration: track.audio.duration, type: requestType })

  requestType === 'music' && userCanMakeReservations() && builder.withRequestReservation(track)

  showPlaylistButton && builder.withAddToPlaylist(clientId, track, modelType, onAddToPlaylistClosed, history, match)

  builder.withPlay({
    songs: playlist,
    playIndex: track.index,
  })

  return builder.build()
}

export const makeCustomLink = element => {
  let title = element.title;
  if (title === " ") title = "element";
  const metadata = get(element, "extra", element.metadata);

  switch (element.data_source) {
    case "link":
      const domain = window.location.hostname.replace('www.','');
      let isSameDomain = metadata.link.startsWith(`${window.location.protocol}//${window.location.hostname}`) || metadata.link.startsWith(`${window.location.protocol}//${domain}`);

      return isSameDomain ? channelPathGenerator(metadata.link.split('//')[1].split('/').slice(1).join('/')) : addHttpsToUrl(metadata.link);
    case "section":
      
      return rootPath(`/home/${metadata.target_section_id}-${slugify(metadata.target_section_name).replace(/-/g,'')}`)
    case "content_creator":

      return channelPathGenerator(`browse/${element.id}-${slugify(title)}`);
    case "catalog":

      return channelPathGenerator(`browse/${element.id}-${slugify(title)}`);
    case "video_content_creation":

      return channelPathGenerator(`browse/${element.id}-${slugify(title)}`);
    case "single_content_creator":

      return channelPathGenerator(`contenido/${metadata.content_creator_id}-${slugify(metadata.content_creator_name)}`);
    case "channel_playlist":

      return channelPathGenerator(`browse/${element.id}-${slugify(title)}`);
    case "smart_search":

      return channelPathGenerator(`busqueda-inteligente`);
    case "static":

      return "#";
    default:
      return "";
  }
}

export const makeCustomClick = (event, element) => {
  let title = element.title;
  if (title === " ") title = "element";

  const metadata = get(element, "extra", element.metadata);
  switch (element.data_source) {
    case "link":
      event.preventDefault()
      const domain = window.location.hostname.replace('www.','');
      let isSameDomain = metadata.link.startsWith(`${window.location.protocol}//${window.location.hostname}`) || metadata.link.startsWith(`${window.location.protocol}//${domain}`);
      const link = isSameDomain ? channelPathGenerator(metadata.link.split('//')[1].split('/').slice(1).join('/')) : addHttpsToUrl(metadata.link);

      if (metadata.new_tab) {
        window.open(link, '_blank');
      }else {
        isSameDomain ? browserHistory.push(link) : window.location.href = link;
      }
      break;
    case "section":
      event.preventDefault()
      browserHistory.push(rootPath(`/home/${metadata.target_section_id}-${slugify(metadata.target_section_name).replace(/-/g, '_')}`))
      break;
    case "content_creator":
      browserHistory.push(channelPathGenerator(`browse/${element.id}-${slugify(title)}`))
      break;
    case "catalog" :
      browserHistory.push(channelPathGenerator(`browse/${element.id}-${slugify(title)}`))
      break;
    case "single_content_creator":
      event.preventDefault()
      if (getActiveChannel() && getStoredChannel().use_canonicals == "1") {
        browserHistory.push(channelPathGenerator(`contenido/${metadata.content_creator_id}-${slugify(metadata.content_creator_name)}`));
      }else {
        EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
          id: metadata.content_creator_id,
          dataType: "contentCreator",
          dataName: slugify(metadata.content_creator_name),
          origin: getMainTranslation(element, "title"),
        })
      }
      break;
    case "channel_playlist":
      browserHistory.push(channelPathGenerator(`browse/${element.id}-${slugify(title)}`))
      break;
    case "change_language":
      navigateToCurrentLangRoute(metadata.lang);
      break;
    case "smart_search":
      browserHistory.push(channelPathGenerator(`busqueda-inteligente`))
      break;
    case "static":
      event.preventDefault()
      event.stopPropagation()
      break;
    default:
      return ;
  }
}

export const getNoteDate = (date) => {
  let event = new Date(date.replace(/-/g, "/"));
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return event.toLocaleDateString(mapLanguages(localization.getLanguage()), options)
}

export const mapLanguages = lang => {
  switch (lang) {
    case "en":
      return "en-US"
      break;
    case "pt":
      return "pt-BR"
      break;
    case "fr":
      return "fr-FR"
      break;
    case "de":
      return "de-DE"
    default:
    case "es":
      return "es-ES"

  }
}

export const getSplittedDate = date => {
  if (date) {
    const tzDate = getTZDate(date)
    const tzDateSplitted = tzDate.split(' ')
    let [year, month, day] = tzDateSplitted[0].split('-').map(Number);
    
    const [hour, mins, seconds] = tzDateSplitted[1].split(':');
    let time = `${hour}:${mins}`; 


    return {
      year: year,
      month: getMonthName(month),
      day: day,
      time: time
    };
  }

  return {
    year: "",
    month: "",
    day: "",
    time: ""
  };
}

export const getSameDaySchedule = (date, events = []) => {
  if (!date) return events;
  const day = getTZDate(date).substr(0,10)
   
  return events
    .filter(event => { 
      const eventDate = getTZDate(event.date);
      if (eventDate.includes(day)) {

        return {...event, date: eventDate}
      }
    });
}

export const getTZDate = (date) => { // date format: 2021-09-30 15:00:00
  let dateSplitted = date.split(' ');
  let timeZoneDate = moment(`${date.substr(0,10)} ${dateSplitted[1]} +0000`, "YYYY-MM-DD HH:mm Z")
  
  return timeZoneDate.format("YYYY-MM-DD HH:mm")
}