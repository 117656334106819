import React from "react";
import PropTypes from "prop-types";
import localization from "../../../config/localization";
import {FormControl, MenuItem, FormControlLabel, Checkbox} from "@material-ui/core";
import {SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {request} from "../../../api-client";
import AsyncSelect from "react-select/lib/Async";
import {userIsSupervisor} from "../../../api-client/core/authentication/utils";

const FormView = ({
  name,
  status,
  clients,
  onClientsChange,
  onChange,
  disabled,
  weight,
  createdBy,
  onCreatedByChange,
  content,
  personalizedContent,
  onPersonalizedContentChange,
  onCatalogContentChange,
  from,
  supervised,
  musicDealer,
}) => {
  return (
    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get("form.name")}
        name="name"
        value={name}
        onChange={onChange}
        disabled={disabled}
        validators={["required"]}
        errorMessages={[localization.get("validator.is_required")]}
      />

      <React.Fragment>
        <br />
        <br />
        <FormControl fullWidth>
          <SelectValidator
            label={localization.get("form.status")}
            id="status"
            name="status"
            value={status}
            onChange={onChange}
            validators={["required"]}
            errorMessages={[localization.get("validator.is_required")]}
          >
            <MenuItem value={'pending'}>{localization.get('catalog.inactive')}</MenuItem>
            <MenuItem value={'approved'}>{localization.get('catalog.active')}</MenuItem>
          </SelectValidator>
        </FormControl>
      </React.Fragment>

      {/*{!userisMultiClient() &&
      <React.Fragment>
        <br />
        <br />
        <FormControl fullWidth>
          <SelectValidator
            label={localization.get("form.visibility")}
            id="visibility"
            name="visibility"
            value={visibility}
            onChange={onChange}
            validators={["required"]}
            errorMessages={[localization.get("validator.is_required")]}
          >
            <MenuItem value={'public'}>{localization.get('public')}</MenuItem>
            <MenuItem value={'personalized'}>{localization.get('personalized')}</MenuItem>
          </SelectValidator>
        </FormControl>
      </React.Fragment>
      }*/}

      {from !== 'music' ?
      <React.Fragment>
        <br />
        <br />
        <FormControl fullWidth>
          <span>{localization.get('catalog.share_with')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onClientsChange}
            value={clients}
            isMulti
            defaultOptions
            loadOptions={inputValue =>{
              let filters = [{column: "name", filter: inputValue}]

              return request.client
                .getAll({filterBy: filters}, '')
                .then(res => res.map(client => ({value: client.id, label: client.name})))
            }}
          />
        </FormControl>
      </React.Fragment>
      :
        <div style={{ width: "100%", marginTop: 15}}>
          <span style={{textAlign: "left"}}>{localization.get('music_dealer')}</span>
          <AsyncSelect
            style={{width: "100%"}}
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            fullWidth
            placeholder={localization.get('music_dealer')}
            loadingMessage={() => localization.get('loading')}
            value={musicDealer}
            noOptionsMessage={() => localization.get('no_options')}
            defaultOptions
            onChange={(e) => onChange({target: {id: "musicDealer", value: e}})}
            loadOptions={inputValue => {
              let filters = [ {column: "name", filter: inputValue} ]

              return request.musicDealer.getAll({filterBy: filters})
                .then(musicDealers => musicDealers.map(musicDealer => ({
                  value: musicDealer.id,
                  label: musicDealer.name
                })))
                .then(musicDealers => musicDealers.sort((a, b) => a.label.localeCompare(b.label)))
            }}
          />
        </div>
      }

      <React.Fragment>
        <br />
        <br />
        <TextValidator
          style={{ width: "100%" }}
          id="weight"
          label={localization.get("form.weight")}
          name="weight"
          helperText={localization.get("form.weight_helper")}
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          type={"number"}

          value={weight}
          onChange={onChange}
        />
      </React.Fragment>
      
      
        <React.Fragment>
          <br />
          <br />
          {from !== 'music' ?
            <FormControl fullWidth>
              <span>{localization.get('catalog.owner')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onCreatedByChange}
                value={createdBy}
                defaultOptions
                loadOptions={inputValue => {
                  const filterBy = [{column: "name", filter: inputValue}];
                  if (from === 'music') {
                    filterBy.push({column: 'has_own_tags', filter: true})
                  }
                  return request.client.getAll({filterBy}, '')
                    .then(res => res.map(client => ({value: client.id, label: client.name})))
                }}
              />
            </FormControl>
          :
            userIsSupervisor() ? 
              <FormControl fullWidth>
              <FormControlLabel
                  control={
                    <Checkbox
                      checked={supervised}
                      onChange={() => onChange({target: {name: 'supervised', value: !supervised}})}
                    />
                  }
                  label={localization.get('catalog.supervised')}
                />
            </FormControl>
            : null
          }
        </React.Fragment>
      
      <br />
      <br />
      <FormControl fullWidth>
        <SelectValidator
          label={localization.get("form.content")}
          id="content"
          name="content"
          value={content}
          onChange={onCatalogContentChange}
          disabled={from === 'music'}
          validators={["required"]}
          errorMessages={[localization.get("validator.is_required")]}
        >
          <MenuItem style={{display: 'none'}} value={'all'}>{localization.get('catalog.all')}</MenuItem>
          <MenuItem style={{display: from === 'talents' && 'none'}} value={'music'}>{localization.get('music')}</MenuItem>
          <MenuItem style={{display: from === 'music' && 'none'}} value={'talents'}>{localization.get('talents_audiovisual')}</MenuItem>
          <MenuItem style={{display: from === 'music' && 'none'}} value={'personalized'}>{localization.get('catalog.personalized')}</MenuItem>
        </SelectValidator>
      </FormControl>

      {content === 'personalized' &&
      <>
        <br />
        <br />
        <FormControl fullWidth>
          <span>{localization.get('catalog.content_type')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onPersonalizedContentChange}
            value={personalizedContent}
            defaultOptions
            isMulti
            loadOptions={inputValue =>
              request.contentCreatorType.getAll({filterBy: [{column: "name", filter: inputValue}]})
                .then(res => res.map(contentCreatorType => ({value: contentCreatorType.slug, label: contentCreatorType.name})))
                .then(res => res.sort((a, b) => a.label.localeCompare(b.label)))
            }
          />
        </FormControl>
      </>
      }
    </div>
  );
};


FormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormView;
