import { getActiveChannel } from '../authentication/utils';
import {post} from '../utils'

const url = (host) => `${host}/channels/${getActiveChannel()}/contentCreations/deleteMany`;


export default host => (materialIds, contentCreatorType) => {
  return post(url(host), JSON.stringify({
    material_ids: materialIds,
    contentCreatorType: contentCreatorType,
  }))
}

