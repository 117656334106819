import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update";
import HomeEditor from "../HomeEditor";
import UsersToApprove from "./UsersToApprove"
import Preferences from "./Preferences"
import Preview from "./LiveStreams/Preview";

const ChannelRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/channels/:id/preferences" component={Preferences} />
        <Route path="/panel/channels/:id/edit" component={Update} />
        <Route path="/panel/channels/create" component={Create} />
        <Route path="/panel/channels/:id/editor" render={props => <HomeEditor {...props} channelId={props.match.params.id}/>} />
        <Route path="/panel/approved/channel_users" component={UsersToApprove} />
        <Route path="/panel/channels/:id/livestream_preview/:livestreamId" component={Preview} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default ChannelRouting