import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import localization from "../../config/localization";
import {Select} from "../../components/Letflow/Form";

class StateDialog extends React.Component {

  state = {
    state: this.props.state
  }

  handleClose = (state) => {
    this.props.onClose(state);
  };

  render() {
    const { state, ...other } = this.props;
    const items = [
      {id: 'requested', name: localization.get('original_music.state.requested')},
      {id: 'negotiating', name: localization.get('original_music.state.negotiating')},
      {id: 'producing', name: localization.get('original_music.state.producing')},
      {id: 'charged', name: localization.get('original_music.state.charged')},
      {id: 'canceled', name: localization.get('original_music.state.canceled')},
    ]

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">{localization.get('original_music.dialog.change_state')}</DialogTitle>
        <div style={{margin: "40px"}}>
          <Select
            id='state'
            name="state"
            current={state}
            title={localization.get('form.state')}
            onChange={(e) => {
              this.handleClose(e.target.value)
            }}
            items={items}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />
        </div>
      </Dialog>
    );
  }
}


export default (StateDialog);