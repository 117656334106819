import localization from '../../config/localization'

export const VIDEO_TYPES = {
  HOME_BANNER: "home_banner",
  toArray: () => [
    VIDEO_TYPES.HOME_BANNER,
  ],
  toString: type => {
    switch (type) {
      case VIDEO_TYPES.HOME_BANNER:
        return localization.get('video.home_banner')
    }
  },
};
