import React from 'react'
import {Close} from '@material-ui/icons'
import classNames from 'classnames'
import SimpleButton from '../utils/SimpleButton';
import zIndex from '../utils/zIndex';
import '../assets/style/SoundBar.css'
import Volume from './Volume';

const SoundBarView = ({ show, children, onClose, soundPlayer }) => {
  return (
    <div>
      {/* Fixed Soundbar */}
      <div id='fixed-soundbar' className={classNames('soundbar', { 'hide': !show })} style={{ position: 'fixed', zIndex: zIndex(), bottom: '0', width: '100%', backgroundColor: 'var(--secondary-color, white)', boxShadow: '0 0 5px 0 #999' }}>
        <div style={{ display: 'flex', justifyContent: window.innerWidth > 1070 ? 'space-between' : 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {children}
        </div>
        <Volume soundPlayer={soundPlayer} />
        <div style={{ position: 'absolute', top: '-15px', right: '15px' }}>
          <SimpleButton style={{ backgroundColor: 'var(--secondary-color, white)', boxShadow: '0 0 5px 0 lightgray' }} onClick={onClose}><Close fontSize={'inherit'} /></SimpleButton>
        </div>
      </div>
    </div>
  )
}

export default SoundBarView
