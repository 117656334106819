import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoImage from "../../../assets/img/Letflow/no-image.jpg";
import FolderImage from "../../../assets/img/Letflow/folder.png";
import {request} from "../../../api-client";
import {
  getMainTranslation,
  getStoredChannel,
  getActiveClient,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import HomeElementCarousel from "../../Home/HomeElements/HomeElementCarousel";
import styled from "styled-components";
import analyticsInstance from "../../../analytics/analyticsInstance";

class MyListCarousel extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      elements: props.elements || [],
      showContentCreationDialog: false,
      currentPlayingContent: {},
      channel: props.channel || false
    }
  }

  makeImage = (element, type) => {
    let image;
    if (type === 'playlists') {
      if (element.created_by_admin) {
        if (element.image) {
          image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
        } else {
          image = FolderImage
        }
      } else {
          image = FolderImage
      }
    }  else if (element.image) {
      image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
    } else {
      image = NoImage
    }
    return image
  };

  removeFromMyList = (id, type, element = null) => {
    let trackingName = getMainTranslation(element, "name")
    analyticsInstance.favorite({
      name: trackingName || getMainTranslation(element, "title"),
      type: type === "content_creator" ? "Content" : type === "video_content_creation" ? "Material" : type,
      content: "Remove"
    })
    this.setState({elements: this.state.elements.filter(element => element.id !== id)});
    switch (type) {
      case 'album':
        request.albumChannelFavorite.remove(id);
        break;
      case 'artist':
        request.artistChannelFavorite.remove(id);
        break;
      case 'playlist':
        request.playlistChannelFavorite.remove(id);
        break;
      case 'content_creator':
        request.contentCreatorChannelFavorite.remove(id);
        break;
      case 'video_content_creation':
        request.contentCreationChannelFavorite.remove(id);
        break;
    }
  };

  mobileFontSize = (fontSize) => {
    if (fontSize) {
      let size = fontSize.endsWith('px') ? fontSize.substr(0,fontSize.length-2) : null;
      if (size && size > 24) {
        return "24px";
      } else {
        return fontSize;
      }
    }
  }

  clearCurrentPlayingContent = () => {
    this.setState({showContentCreationDialog: false, hasPrev: false, hasNext: false, currentIndex: null, currentPlayingContent: {}})
  }

  setCurrentPlayingContent = (index) => {
    let hasPrev = index > 0 ;
    let hasNext = index < (this.state.elements.length - 1);
    this.setState({showContentCreationDialog: true, currentPlayingContent: this.state.elements[index], hasPrev: hasPrev, hasNext: hasNext, currentIndex: index},
      // () => GAevent('Contenido', "Play", this.state.currentPlayingContent && this.state.currentPlayingContent.name)
    );
  }

  makeTrackTypeProp = homeElement => 'music'

  makeDataTypeProp = () => {
    if(this.state.homeElement.data_source === "channel_playlist") {
      return 'content_creator'
    }else {
      return this.state.homeElement.data_source
    }
  }


  render() {
    const shape = getStoredChannel().preferences.favorite_template || "x4";
    const makeHomeElementProp = (contentCreators, isFeatured) => {
      return {
        ...contentCreators,
        type: isFeatured? "featured": "carousel",
        items: {
          data: contentCreators.map(item => ({
            ...item,
            title: item.name || item.title,
            shortDescription: item.description,
            kind: this.props.type,
          }))
        }
      }
    }

    const makeRequestElementProp = (homeElement) => {
      switch (homeElement.data_source) {
        case 'recommended':
          return async (id, itemKind) => {
            if (itemKind === 'playlist') {
              const playlist = await request.playlist.getGlobal(id)
    
              const selectedTags = playlist.tags.filter(tag => tag.wanted === 1).map(tag => tag.id)
              const unselectedTags = playlist.tags.filter(tag => tag.wanted === 0).map(tag => tag.id)
    
              const tracks = await request.music.getAllByPlaylistForClient(getActiveClient(), id, selectedTags, unselectedTags, playlist.query || '', "recentLicenses;audio:id,duration,url")
              return {
                ...playlist,
                tracks,
                kind: itemKind,
              }
            }
          }
    
        default:
          return albumId => request.album.getForClient(albumId, 'include=image;artist')
            .then(album =>
              request.music.getAllByAlbumForClient(getActiveClient(), albumId, 'include=recentLicenses.user;versions_count;audio:id,duration,url;tags;favorite;channelFavorite;audio.waveform;albums.image;artist', album.type === 'library' ? 'created_at' : null, album.type === 'library' ? 'desc' : null)
                .then(musics => ({
                  ...album,
                  tracks: musics.map(music => ({...music, type: 'music'})),
                  longDescription: album.long_description,
                  shortDescription: album.short_description,
                }))
            )
      }
    }
    

    return (
      <div className="category">
        <ContentCreationPlayer
          show={this.state.showContentCreationDialog}
          onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(this.state.currentIndex+1) : null}
          onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(this.state.currentIndex-1) : null}
          currentContentCreation={this.state.currentPlayingContent}
          handleClose={() => this.clearCurrentPlayingContent()}
          noAuthorLink={userHasRestrictedAccess()}
        />
        <RelatedContainer>
          <HomeElementCarousel
            {...this.props}
            homeElement={makeHomeElementProp(this.state.elements, ["featured2", "movie", 'movie_alternative', 'featured2_alternative'].includes(shape))}
            alternative={["movie_alternative", "featured2_alternative"].includes(shape)}
            maxPerRow={(shape === "featured2" || shape ===  "featured2_alternative") ? 2 : (["movie", "movie_alternative"].includes(shape))? 1 : 6}
            trackType={this.makeTrackTypeProp()}
            dataType={this.props.type}
            showSectionTitle={false}
            fixedTitle={true}
            showMore={true}
            handleNewSearch={this.handleNewSearch}
            editorial={shape === "featured2"}
            editorialInverted={shape === "featured2_alternative"}
            shape={["featured2", "featured2_alternative"].includes(shape) ? "x2" : ["movie", "movie_alternative"].includes(shape)? 'x1': shape}
            movie={["movie", "movie_alternative"].includes(shape)}
            search={true}
            columns={4}
            requestElement={makeRequestElementProp(this.state.elements)}
            onRemoveFavorites={(e, id) => {
              this.removeFromMyList(id, this.props.type, this.state.elements.find(element => element.id == id))
              this.setState({elements: this.state.elements.filter(element => element.id !== id)})
              e.stopPropagation()
              e.preventDefault()
            }}
          />
        </RelatedContainer>
      </div>
    )
  }
}

MyListCarousel.defaultProps = {
  slidesToShow: 4,
  type: 'album'
};


const RelatedContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
`


export default MyListCarousel
