import create from './create'
import del from './delete'
import getAll from './getAll'
import getAllPublic from './getAllPublic'
import get from './get'
import getPublic from './getPublic'
import update from './update'
import setImage from './setImage'
import getMusics from './getMusics'
import getMusic from './getMusic'
import getWithLicense from './getWithLicense'
import home from './home'
import searchFor from './searchFor'
import getCountries from './getCountries'
import updateCatalogs from './updateCatalogs'
import getQuota from './getQuota'
import getSocials from './getSocials'
import addSocials from './addSocials'
import publicHome from './getPublicHome'
import clientCatalogs from './clientCatalogs'
import exportCatalogAccess from './exportCatalogAccess'
import backup from './backup'

export default host => ({
  create: create(host),
  delete: del(host),
  getAll: getAll(host),
  getAllPublic: getAllPublic(host),
  get: get(host),
  getPublic: getPublic(host),
  update: update(host),
  setImage: setImage(host),
  getMusics: getMusics(host),
  getMusic: getMusic(host),
  getWithLicense: getWithLicense(host),
  home: home(host),
  searchFor: searchFor(host),
  getCountries: getCountries(host),
  updateCatalogs: updateCatalogs(host),
  getQuota: getQuota(host),
  getSocials: getSocials(host),
  addSocials: addSocials(host),
  publicHome: publicHome(host),
  clientCatalogs: clientCatalogs(host),
  exportCatalogAccess: exportCatalogAccess(host),
  backup: backup(host),
})
