import React, {Component} from "react";
import {Share, Visibility} from "@material-ui/icons";
import ClientSidePaginationTable from "../../../components/Letflow/Table/ClientSidePaginationTable";
import {TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import { channelPathGenerator, slugify } from "../../../api-client/core/authentication/utils";
import browserHistory from "../../../utils/browserHistory";
import localization from "../../../config/localization";
import { request } from "../../../api-client";
import moment from "moment-with-locales-es6";
import './PurchasesTable.css'
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import { Dialog } from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";

export default class ContentCreationTable extends Component{
  constructor(props) {
    super(props);
    this.state = {
      purchases: props.purchases,
      showShareGiftDialog: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.purchases) {
      this.setState({purchases: nextProps.purchases})
    }
  }

  goToProduct = (product) => {
    let productUrl;
    switch(product.content_type) {
      case "App\\ContentCreator":
        let contentCreator = product.content;
        if (contentCreator) {
          productUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
        }
        break;
      case "App\\ContentCreation":
        let contentCreation = product.content;
        if (contentCreation) {
          productUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
        }
        break;
      case "App\\ContentPack": 
        if (product.content && product.content.products) {
          let targetProduct = product.content.products[0];
          if (targetProduct.content_type == "App\\ContentCreator") {
            let contentCreator = targetProduct.content;
            if (contentCreator) {
              productUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
            }
          }
          if (targetProduct.content_type == "App\\ContentCreation") {
            let contentCreation = targetProduct.content;
            if (contentCreation) {
              productUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
            }
          }
        }
    }
    if (productUrl) {
      browserHistory.push(productUrl);
    } 
  }

  buttons = (purchase) => {
    let product = purchase.product;
    const builder = new TableButtonsBuilder();

    if (purchase.purpose == 'gift' && purchase.gift) {
      builder
        .withOther(
          <TableButton
            style={{color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}
            title={localization.get("table.purchase.share_link")}
            onClick={() => this.setState({
              showShareGiftDialog: true,
              gift: purchase.gift
            })}
          >
            <Share/>
          </TableButton>
        )
    }
    builder
      .withOther(
        <TableButton
          style={{color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}
          title={localization.get("product.go_to_content")}
          onClick={() => request.products.getForChannel(product.id).then((product) => this.goToProduct(product))}
        >
          <Visibility/>
        </TableButton>
      )

    return builder.build()
  };

  render() {
    const {purchases} = this.state
    return (          
      <React.Fragment>
        <div style={{border: "1px solid #ccc", borderRadius: "20px"}} className="purchase-table">
          <ShareGiftDialog
            show={this.state.showShareGiftDialog}
            handleClose={() => this.setState({showShareGiftDialog: false, shareUrl: null})}
            gift={this.state.gift}
          />
          <ClientSidePaginationTable
            style={{width: "100%", background: "var(--secondary-color, white)", color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))", borderRadius: "20px", padding: "10px 0"}}
            showPagination={false}
            noDataText={localization.get('table.no_results')}
            perPage={999}
            ref='table'
            columns={[
              {
                Header: () =>
                  <div
                    style={{
                      textAlign:"left",
                      paddingLeft: '20px'
                    }}
                  >{localization.get("product")}</div>,
                id: "product",
                accessor: purchase => purchase.product && purchase.product.content && (purchase.product.content.title || purchase.product.content.name),
                style: {
                  float: 'left',
                  textAlign: 'left',
                  paddingLeft: '20px'
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get("table.purchase_date"),
                id: 'purchase_date',
                accessor: purchase => moment(purchase.created_at).format("DD/MM/YY LT"),
                style: {
                  float: 'left',
                  textAlign: 'left'
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get("table.purchase_end_date"),
                id: 'end_date',
                accessor: purchase => purchase.product ? moment(purchase.created_at).add(purchase.product.days, 'day').format('DD/MM/YY') : '-',
                style: {
                  float: 'left',
                  textAlign: 'left'
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get("table.purchase_price"),
                id: 'price',
                accessor: purchase => `${purchase.currency} ${purchase.total}`,
                style: {
                  float: 'left',
                  textAlign: 'left'
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get("form.status"),
                id: 'status',
                accessor: purchase => purchase.status == 'authorized' ? (purchase.purpose == 'gift' ? localization.get("table.bought_for_gift") + (purchase.gift ? (purchase.gift.userReceiver ? `\n(${localization.get("table.gifts.accepted")})` : `\n(${localization.get("table.gifts.not_accepted")})`) : "") : (purchase.purpose == 'claim') ? localization.get("table.gifts.claimed_from_gift") : localization.get("table.bought")) : localization.get(`table.${purchase.status}`),
                style: {
                  float: 'left',
                  textAlign: 'left',
                  whiteSpace: 'break-spaces'
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                id: 'actions',
                Header: () =>
                  <div
                    style={{
                      textAlign:"right",
                      paddingRight: '20px'
                    }}
                  >{localization.get('table.actions')}</div>,
                sortable: false,
                filterable: false,
                style: {
                  paddingRight: '20px',
                },
                accessor: (purchase) => this.buttons(purchase),
              }
            ]}
            data={purchases}
          />
        </div>
      </React.Fragment>
    );
  }
}

class ShareGiftDialog extends Component {

  constructor (props) {
    super(props)
    this.state = {
      showShareDialog: false,
    }
  }
  
  handleClose = () => {
    this.props.handleClose && this.props.handleClose();
  }

  getShareUrl = () => {
    return `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`claim?token=${this.props.gift && this.props.gift.hashcode}`)}`;
  }

  shareLink = () => this.setState({showShareDialog: true});
  copyLink = () => copyStringToClipboard(this.getShareUrl());

  render = () => {
    let {gift} = this.props;
    return (
      <>
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={() => this.setState({showShareDialog: false})}
          url={this.getShareUrl()}
          darkMode={true}
          onClick={(e) => {e.stopPropagation(); e.preventDefault();}}
        />
        <Dialog open={this.props.show} onEscapeKeyDown={this.handleClose} onBackdropClick={this.handleClose}>
          <div className={"share-gift-dialog"}>
            <h3>{localization.get("gift.share_gift.title")}</h3>
            <p style={{margin: "20px 0"}} dangerouslySetInnerHTML={{__html: localization.get("gift.share_gift.message", gift ? gift.to : '')}}></p>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 30}}>
              <Button style={{ float: "right", width: 150, backgroundColor: "var(--navbar-text-color, black)", color: "var(--secondary-color, white)" }} color={"default"} onClick={this.copyLink}>{localization.get('copy_link')}</Button>
              <Button style={{ float: "right", width: 150, backgroundColor: "var(--navbar-text-color, black)", color: "var(--secondary-color, white)" }} color={"default"} onClick={this.shareLink}>{localization.get('share')}</Button>
            </div>
          </div>
        </Dialog>
      </>
    )
  }
}

const copyStringToClipboard = (text) => {
  const fallbackCopyTextToClipboard = (text) =>  {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(function() {
    GlobalSnackbar.show({
      message: localization.get('copy.success'),
      type: GlobalSnackbarTypes.SUCCESS
    })
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}