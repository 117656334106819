import React, {Component} from "react";
import CreativeTimSnackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import PropTypes from "prop-types"

class Snackbar extends Component {

  state = {
    showing: false,
  }

  componentDidMount = () => {
    if (this.props.startShowing) {
      this.show()
    }
  }

  componentWillUnmount = () => {
    this.clearTimeout()
  }

  // public
  
  show = () => {
    if (this.state.showing) {
      return
    }
    this.setState({ showing: true })
    this.timeOutId = setTimeout(() => {
      this.setState({ showing: false })
    }, 5000);
  }

  // private

  clearTimeout = () => {
    if (this.timeOutId) {
      clearTimeout(this.timeOutId)
    }
  }

  onClose = () => {
    this.clearTimeout()
    this.setState({ showing: false })
  }

  render = () =>
    <CreativeTimSnackbar
      message={this.props.message}
      close
      icon={AddAlert}
      color={this.props.color}
      place="tc"
      closeNotification={this.onClose}
      open={this.state.showing}
      snackbarStyle={{ top: this.props.top || "100px" }}
    />

}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  startShowing: PropTypes.bool
}

export default Snackbar