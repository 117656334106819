import React, {Component} from "react";
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import {TryAgainSnackbar} from "../../../components/Letflow/Snackbar";
import {ROLES} from "../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import {EditorState} from "draft-js";

export default class extends Component {

  state = {
    id: '',
    name: '',
    email: '',
    phone: '',
    type: '',
    image: { src: null, file: null },
    profile: { src: null, file: null },
    disabled: false,
    createUser: 1,
    userName: '',
    userEmail: '',
    selectedUsers: [],
    country: '',
    socialLinks: [],
    disableForm: false,
    unsplashCoverImage: '',
    unsplashProfileImage: '',
    coverImageRadio: 'filepond',
    profileImageRadio: 'filepond',
    translations: AvailableLanguages.map(language => ({
      language: language,
      description: "",
    })),
  }


  requestArtist= () => request.artist.get(this.props.match.params.id, 'include=image;profile;users;translations;country;socialLinks')
    .then(artist => {
    const translations = AvailableLanguages.map(language => {
      const translation = artist.translations.find(tr => tr.locale ===language)

      return {
        language: language,
        description: translation ? translation.description : "",
      };
    })

    this.setState({
      id: artist.id,
      name: artist.name,
      email: artist.email,
      phone: artist.phone,
      translations: translations,
      type: artist.type,
      selectedUsers: artist.users.map(user => ({value: user.id, label: user.name})),
      createUser: artist.users.length > 0 ? 3 : 1,
      image: artist.image
        ? { id: artist.image.id, src: getUpdateFormImageUrlFromItem(artist), filename: artist.image.original_name }
        : { src: null, file: null },
      profile: artist.profile
        ? { image: artist.profile }
        : { src: null, file: null },
      country: artist.country && {value: artist.country.id, label: artist.country.name},
      socialLinks: artist.socialLinks
    }, () => this.state.profile.image && this.setState({profile: { src: getUpdateFormImageUrlFromItem(this.state.profile), filename: artist.profile.original_name }}))
  })

  requestUpdate = () => {
    const {
      id,
      name,
      email,
      phone,
      translations,
      type,
      selectedUsers,
      userName,
      userEmail,
      createUser,
      socialLinks,
      country,
      imageId,
      profileId,
      unsplashCoverImage,
      unsplashProfileImage
    } = this.state

    const artistData = {
      id,
      name,
      email,
      phone,
      translations,
      imageId,
      profileId,
      countryId: country && country.value,
      socialLinks,
      type,
      selectedUsers: createUser === 3 && selectedUsers.map(x => x.value),
      unsplashCoverImage,
      unsplashProfileImage
    }

    const userData = {
      name: userName,
      email: userEmail,
      roleId: ROLES.artistId
    }

    if(createUser === 2) {
      return request.user.create(userData)
        .then((user) => {
          request.artist.update({...artistData, selectedUsers: [user.data.id]})
            .then(() => {
              GlobalConfirmationDialog.show({
                title: localization.get("user.created"),
                content: localization.get("user.email_sent", user.data.email),
                request: () => Promise.resolve(),
                confirmationLabel: localization.get("accept"),
                onClose: this.props.history.push(makeUrlWithTableParams('/panel/artists')),
              })
            })
        })
        .then(() => this.props.history.push(makeUrlWithTableParams('/panel/artists')))
    }

    return request.artist.update(artistData)
      .then(() => this.props.history.push(makeUrlWithTableParams('/panel/artists')))

  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })
  onAsyncUserChange = selectedUsers => this.setState({selectedUsers})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onProfileChange = profile => profile ? this.setState({profileId: profile.id, profile: {src: null, filename: null }, imagePreview: this.formNewUrl(profile.path)}) : this.setState({profileId: null, profile: { src: null, filename: null }})

  onSetCoverImageFromUnsplash = (url, name) => this.setState({unsplashCoverImage: {url, name}})
  onSetProfileImageFromUnsplash = (url, name) => this.setState({unsplashProfileImage: {url, name}})

  onImageRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'coverImageRadio') {
      if (e.target.value === 'unsplash'){
        this.setState({imageId: '', image: {src: null, filename: null }})
      } else {
        this.setState({unsplashCoverImage: ''})
      }
    }
    if (e.target.name === 'profileImageRadio') {
      if (e.target.value === 'unsplash'){
        this.setState({profileId: '', profile: {src: null, filename: null }})
      } else {
        this.setState({unsplashProfileImage: ''})
      }
    }
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('view.artist.update')}
          submitRequest={() => this.requestUpdate()}
          onBack={() => this.props.history.goBack()}
          initializationRequest={() => this.requestArtist()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          disabled={this.state.disableForm}
        >
          <FormContent
            name={this.state.name}
            email={this.state.email}
            phone={this.state.phone}
            descriptionEn={this.state.descriptionEn}
            descriptionEs={this.state.descriptionEs}
            type={this.state.type}
            image={this.state.image}
            profile={this.state.profile}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            onProfileChange={this.onProfileChange}
            artistId={this.props.match.params.id}
            onAsyncUserChange={this.onAsyncUserChange}
            createUser={this.state.createUser}
            userName={this.state.userName}
            userEmail={this.state.userEmail}
            onCreateUserChange={createUser => this.setState({ createUser })}
            selectedUsers={this.state.selectedUsers}
            socialLinks={this.state.socialLinks}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            country={this.state.country}
            onAsyncCountriesChange={country => this.setState({country})}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            coverImageRadio={this.state.coverImageRadio}
            profileImageRadio={this.state.profileImageRadio}
            onSetCoverImageFromUnsplash={this.onSetCoverImageFromUnsplash}
            onSetProfileImageFromUnsplash={this.onSetProfileImageFromUnsplash}
            onImageRadiosChange={this.onImageRadiosChange}
            translations={this.state.translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
          />
        </FormViewContainer>
        <TryAgainSnackbar ref='snackbar' />
      </div>
    )
  }
}
