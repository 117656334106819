import "layouts/Home/Home.css";
import React, {Component} from "react";
import Tab from "@material-ui/core/Tab";
import {List, CloudUploadOutlined} from "@material-ui/icons";
import localization from "../../../config/localization";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import UploadedTable from "./Uploaded";
import Upload from "./Upload";
import LogoBloom from "assets/img/logo-black.png";
import {request} from "../../../api-client";
import {Typography} from "@material-ui/core/index";
import styled from "styled-components";
import {makeFitImageUrl} from "../../../utils";
import { onImageError } from "../../../layouts/Home/HomeElements/utils";
import { get } from "lodash";

class PublicBatchUpload extends Component {

  state = {
    value: "upload",
    name: '',
    loaded: false,
    notFound: false,
    canSeeUploadedTab: false,
    client: null,
    hint: "",
    link: null,
    folder: "all",
  };

  componentDidMount = () => {
    let token = this.props.match.params.token
    let shouldSeeUploadedTab = false;
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.folder) {
      this.setState({ folder: params.folder, value: "uploaded" })
    }

    if (token.endsWith('-1') && token.startsWith('20')) {
      token = token.slice(2, -2).split("").reverse().join("")
      this.setState({ token })
      shouldSeeUploadedTab = true;
    }

    request.batchUpload.getPublic(token)
      .then((link) => {
        document.title = link.name + " - " + localization.get("batch_upload");
        if (shouldSeeUploadedTab) shouldSeeUploadedTab = !link.protected;
        this.setState({
          name: link.name,
          loaded: true,
          client: link.client,
          hint: link.hint,
          link,
          canSeeUploadedTab: shouldSeeUploadedTab,
        })
      })
      .catch(() => {
        document.title = "404 - Feater";
        this.setState({notFound: true})
      })
  };

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  setNavBarImage = () => {
    if (get(this.state, 'client.image')) {
      return makeFitImageUrl(this.state.client, 200, 50, '', 'png')
    }

    return LogoBloom
  }

  render = () => {

    return (
      <>
        {this.state.loaded &&
        <>
          <nav style={{backgroundColor: "white", position: "relative", boxShadow: "none", height: '50px', display: 'grid', gridTemplateColumns: 'auto auto'}}>
            <div style={{marginLeft: 40, alignSelf: 'center', maxHeight: 30}}>
              <img  src={this.setNavBarImage()} style={{maxWidth: 200}} alt="Logo Feater" onError={onImageError}/>
            </div>
            <DivStyled>{localization.get('batch_upload.environment') + this.state.name} </DivStyled>
          </nav>
          <Paper>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              fullWidth
              indicatorColor="secondary"
              textColor="#F2000000"
              centered
            >
              <Tab value="upload" icon={<CloudUploadOutlined />} label={localization.get('upload')}/>
              {this.state.canSeeUploadedTab && <Tab value="uploaded" icon={<List/>} label={localization.get('batch_upload.uploaded')}/>}
            </Tabs>

            <Upload show={this.state.value === 'upload'} {...this.props}  clientId={this.state.client && this.state.client.id} token={this.state.token} hint={this.state.hint}/>
            {this.state.value === 'uploaded' && <UploadedTable folder={this.state.folder} link={this.state.link} {...this.props} token={this.state.token}/>}

          </Paper>
        </>
        }
        {this.state.notFound &&
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 215px)",
          }}
        >
          <Typography variant="display2">{localization.get("batch_upload.non_existent")}</Typography>
          <Typography style={{ marginTop: "10px" }}>
            {localization.get("batch_upload.non_existent_extra")}
          </Typography>
        </div>
        }

      </>
    )
  }
}

export default PublicBatchUpload


const DivStyled = styled.div`
  width: fit-content;
  justify-self: end;
  padding: 0 10px;
  background-color: #e54755;
  margin-right: 40px;
  text-align: right;
  align-self: center;
  color: white;
  font-weight: bold;
`;