import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {makeWaveformUrl} from "../../../utils";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {userIsSupervisor, userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";

export default class extends Component {

  state = {
    clientCountries: [],
    country: ''
  }

  componentDidMount = () => {
    document.title = localization.get('title.musics_ranking') + ' - Feater'

    request.client.getCountries().then(clientCountries => this.setState({clientCountries}))
  }

  onChangeCountry = (country) => {
    this.setState({ country });
  }

  makePlayButtonTrack = music => ({
    title: music.title,
    subtitle: "",
    fetchAudioUrl: () => request.music.audioUrl(music.id),
    onAnyClick: () => this.props.history.push(`/panel/musics/${music.id}/edit`),
    waveform: makeWaveformUrl(music)
  });

  buttons = music => {
    const builder = new TableButtonsBuilder();

    builder.withNewPlay(this.makePlayButtonTrack(music));

    return builder.build();
  };

  columns = () => {
    const columns = [
      {
        Header: localization.get('table.position'),
        accessor: 'position',
        width: 100,
        style: {
          textAlign: 'center',
        },
        filterable: false,
      },
      {
        Header: localization.get("music.reference"),
        accessor: "code",
        Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
        width: 110
      },
      {
        Header: localization.get("music.title"),
        accessor: "title",
        Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
        maxWidth: 300
      },
      {
        Header: localization.get("music.owner"),
        id: "owner",
        accessor: music => music.owner === "internal" ? '100% Feater' : 'Revshare',
        Filter: ({filter, onChange}) =>
          <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected/>
            <option value={'artist'}>Revshare</option>
            <option value={'internal'}>100% Feater</option>
          </select>,
      },
      {
        id: 'image',
        accessor: music => <img alt="Artist" src={makeImageUrlWithSize(music.artist, 'xs')} width={50} height={50} onError={onImageError} />,
        style: {
          float: 'left',
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60
      },
      {
        id: 'artist[name]',
        Header: localization.get('artist'),
        accessor: "artist.name",
        sortable: false,
        Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
      },
      {
        Header: localization.get('table.points'),
        id: 'score',
        accessor: item => item.score || 0,
        filterable: false,
        width: 100,
        style: {
          textAlign: 'right',
        },
        headerStyle: {
          textAlign: 'center',
        },
      }
    ];

    if (userIsSysAdmin() || userIsSupervisor()) {
      columns.push(
        {
          Header: localization.get("music.actions"),
          id: "actions",
          sortable: false,
          filterable: false,
          accessor: this.buttons,
          width: 100,
        }
      );
    }

    return columns;
  }

  render = () => {
    const { country, clientCountries } = this.state;

    return (<AllViewContainer ref='viewContainer'>
      <div style={{textAlign: 'center', marginTop: '15px'}}>
        <InputLabel htmlFor="country">{localization.get('form.country')}</InputLabel>
        <Select
          id="country"
          name="country"
          value={country}
          onChange={event => {
            this.onChangeCountry(event.target.value)
            this.refs.table.forceUpdate();
          }}
          style={{ width: 300, marginLeft: '15px' }}
          displayEmpty
        >
          <MenuItem value='' defaultValue>{localization.get('all')}</MenuItem>
          {clientCountries.length > 0 && clientCountries.map(country => {
            return <MenuItem value={country.key}>{country.name}</MenuItem>;
          })}
        </Select>
      </div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ServerSidePaginationTable
                ref="table"
                requestTableData={paginationConfig => request.music.getRanking(this.state.country, paginationConfig)}
                columns={this.columns()}
                defaultPageSize={20}
                showPaginationTop
                showPaginationBottom={false}
                showPageSizeOptions={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>);
  }
}
