import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";
import artistRoutes from "routes/artist.jsx"

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import Logo from "assets/img/logo-white.png";
import LogoSmall from "assets/img/logo.png";
import {
  getStoredUser,
  userIsArtist, userIsContentSupervisor,
  userisMultiClient, userIsSupervisor,
  userIsSysAdmin
} from "../api-client/core/authentication/utils";
import browserHistory from "../utils/browserHistory";

const routes = () => {
  return userIsArtist() || getStoredUser().artist_view ? artistRoutes() : dashboardRoutes()
}

const switchRoutes = () => {
  return (
    <Switch>
      {routes().map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          });
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  )
}

var ps;

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      // document.body.style.overflow = "hidden";
    }

    document.documentElement.style.setProperty('--dashboard-section-header-background-color', "rgba(20, 20, 20, 0.5)");
    document.documentElement.style.setProperty('--dashboard-section-header-font-color', "rgba(255, 255, 255, 1)");
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  render() {
    if(!getStoredUser()) {
      window.location.href = `/login?redirect_to=${window.location.pathname}${window.location.search}`
      return ;
    }

    if(!userIsArtist() && !userIsSysAdmin() && !userisMultiClient() && !userIsSupervisor() && !userIsContentSupervisor()) {
      browserHistory.push('/')
      return ;
    }

    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes()}
          logoText={Logo}
          logo={LogoSmall}
          image={null}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="dark"
          bgColor="dark"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={routes()}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes()}</div>
            </div>
          ) : (
              <div className={classes.map}>{switchRoutes()}</div>
            )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
