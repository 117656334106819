import React from 'react'
import {ChevronRight} from "@material-ui/icons";

export default ({className, to, onClick, overrideStyle = {}, home=false, liveCarousel = false}) =>
  <ChevronRight
    onClick={onClick}
    className={`button button--text button--icon ${className} ${home && 'banner-arrow'} ${liveCarousel && 'live-carousel-arrow-right'}`}
    aria-label={to}
    style={Object.assign({
    }, overrideStyle)}
    icon={to}
  />


