import "../Home.css";
import "./index.css"
import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button.jsx";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx"
import _ from 'lodash'
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import 'react-input-range/lib/css/index.css'
import HomeElementGridContainer from "../HomeElements/HomeElementGridContainer";
import GridItem from "../HomeElements/GridItem";
import DeleteOutline from "../../../assets/icons/outline-delete.svg";
import {Style} from "@material-ui/icons";
import EventManager, {events} from "../../../utils/EventManager";
import qs from "qs";
import browserHistory from "../../../utils/browserHistory";
import AgeInputRange from "./AgeInputRange";
import {getActiveClient, homePathGenerator, setPageTitle} from "../../../api-client/core/authentication/utils";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import AsyncSelect from "react-select/lib/Async";

const makeSearchButton = (onClick) =>
  <Button className="smart-search-search-button" onClick={onClick}>
    {localization.get('home.smart_search.search')}
  </Button>

const hasAnySelectedTag = tagGroups =>
  _.some(_.flatMap(tagGroups, tagGroup => tagGroup.tags), tag => (tag.state === 'selected' || tag.state === 'unselected'));

const makeCleanTagsButton = (onClick) =>
  <Tooltip title={localization.get('smart_search.clean_search')} placement='top'>
    <IconButton onClick={onClick}>
      <img alt="delete-icon" src={DeleteOutline}/>
    </IconButton>
  </Tooltip>

const makeAddFiltersButton = (onClick) =>
  <Tooltip title={localization.get('smart_search.add_remove_tags')} placement='top'>
    <IconButton onClick={onClick} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
      <Style/>
    </IconButton>
  </Tooltip>

class SmartSearchSpeaker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [],
      searchResultsTags: [],
      searchResultsTitle: [],
      showTagSelection: true,
      showTagDialog: false,
      searchGroup: "speaker",
      favorites: [],
      age: null,
      selectedCountry: '',
      availableCountries: [],
      showTags: false,
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.requestHasQueryParams()
      .then(() => this.requestTagGroups())
      .then(() => this.requestAvailableTags())
      .then(() => this.clearUnusedTags())
      .then(() => this.setState({showTags: true}))
      .then(() => this.readQueryStringParams())
    request.speakerFavorite.getAll().then((favorites) => this.setState({favorites}))
    setPageTitle(localization.get('title.smart_search'))

    EventManager.getInstance().subscribe(events.SPEAKER_ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites);
    EventManager.getInstance().subscribe(events.SPEAKER_REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites);
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SPEAKER_ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites);
    EventManager.getInstance().unsubscribe(events.SPEAKER_REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites);
  }

  requestHasQueryParams = () =>
    new Promise(resolve => {
      let search = qs.parse(this.props.location.search.slice(1))
      let {tags_ids, not_tags_ids, age} = search
      if (tags_ids || not_tags_ids || age) {
        this.setState({showTagSelection: false})
      }
      resolve()
    })

  readQueryStringParams = () => {
    let search = qs.parse(this.props.location.search.slice(1))
    let {tags_ids, age, not_tags_ids, countryId} = search
    if (tags_ids || age || countryId || not_tags_ids) {
      if (tags_ids) {
        if (Array.isArray(tags_ids)) {
          tags_ids.forEach(tag => this.markTagAsSelected(tag))
        } else {
          this.markTagAsSelected(tags_ids)
        }
      }
      if (not_tags_ids) {
        if (Array.isArray(not_tags_ids)) {
          not_tags_ids.forEach(tag => this.markTagAsUnselected(tag))
        } else {
          this.markTagAsUnselected(not_tags_ids)
        }
      }
      if (age) {
        this.setState({age: JSON.parse(age)})
      }
      if (countryId) {
        this.setState({selectedCountry: {value: parseInt(countryId)}})
      }
      this.requestTracks()
    }
  }

  writeQueryStringParams = () => {
    let {age, selectedCountry} = this.state
    browserHistory.push({
      pathname: window.location.pathname,
      search: qs.stringify({
        tags_ids: this.getSelectedTags().map(tag => tag.id),
        not_tags_ids: this.getUnselectedTags().map(tag => tag.id),
        age: JSON.stringify(age),
        countryId: selectedCountry.value
      })
    })
  }

  markTagAsSelected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'selected'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsUnselected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'unselected'
    }
    this.forceUpdate(() => resolve({}))
  })


  addToFavorites = ({id, type}) => {
    request.speakerFavorite.add(id).then(() => this.setState({favorites: this.state.favorites.concat([id])}));
  };

  removeFromFavorites = ({id, type}) => {
    request.speakerFavorite.remove(id).then(() => this.setState({favorites: this.state.favorites.filter((favorite) => favorite !== id)}));
  };


  requestTagGroups = () => request.tagGroup.getAllOfTypeSpeaker().then(tagGroups => this.setState({tagGroups}))

  makeSmartSearchTagSelector = () => {
    return (
      <div>
        <section
          style={{backgroundColor: "var(--main-color, rgb(247, 247, 247))", display: this.state.showTagSelection ? 'block' : 'none'}}
          className="category">
          <div style={{
            float: "left",
            position: "relative",
            width: "100%",
            textAlign: "center",
            padding: "10px 50px",
            height: "auto",
            minHeight: "520px"
          }}>
            {!this.state.showTags ?
              <div style={{height: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}>
                <img style={{width: 150, height: 150, marginTop: "calc(43vh - 70px)"}} src={LoadingSpinner} alt="Loading logo"/>
              </div>
            :
              <TagSelection horizontal={true} onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged} data={this.state.tagGroups.filter(x => x.type === 'speaker')}/>
            }
          </div>
        </section>
      </div>
    )
  }

  getSelectedTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected')
  getUnselectedTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected')

  handleSmartSearchSelectedTagsChanged = (selectedTags, unselected, lastTagId) => {
    this.requestAvailableTags({lastTagId})
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : unselected.includes(tag.id) ? 'unselected' : tag.state
        }))
      }))
    }), () => this.writeQueryStringParams())
  }

  clearUnusedTags = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.filter(tag => tag.state !== 'disable')
      }))
    }))
  }

  requestAvailableTags = ({
    clientId = getActiveClient(),
    searchGroup = this.state.searchGroup,
    searchTags = this.getSearchTags(),
    unselectedSearchTags = this.getUnselectedSearchTags(),
    lastTagId = null
  } = {}) => {
    this.disableAllTagsForRequest()
    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId
    }).then(availableTags => {
      const tags = _.flatMap(this.state.tagGroups, x => x.tags)
      tags.forEach(x => x.state = x.state === 'selected' ? 'selected' : x.state === 'unselected' ? 'unselected' : availableTags.map(x => x.id).includes(x.id) ? 'available' : 'disable')
      this.setState({tagGroups: this.state.tagGroups})
      return availableTags
    })
  }

  disableAllTagsForRequest = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: 'disable'
        }))
      }))
    }))
  }

  getSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id)

  getUnselectedSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected').map(tag => tag.id)

  handleSmartSearch = () => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0) {
      this.requestTracks()
      this.setState({showTagDialog: false})
    } else {
      GlobalSnackbar.show({
        message: localization.get('home.smart_search.missing_tags'),
        type: GlobalSnackbarTypes.ERROR
      })
    }
  }

  removeSelectedTag = (id) => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length === 1) {
      this.cleanSearch()
    } else {
      this.setState({
        tagGroups: this.state.tagGroups.map(tagGroup => ({
          ...tagGroup,
          tags: tagGroup.tags.map(tag => {
            if (tag.id === id) {
              tag.state = 'available'
            }
            return tag
          })
        }))
      }, () => this.writeQueryStringParams())
      this.requestTracks()
    }
  }


  renderSearchResultsTableTags = () => {
    const {searchResultsTags} = this.state
    const Container = ({children}) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "var(--main-color, white)",
      marginTop: "80px"
    }}>{children}</div>
    let content
    if (this.state.showTagSelection) {
      content = null
    } else if (!this.state.showTagSelection && searchResultsTags.length === 0) {
      content =
        <div className="smart-search-no-result-container">
          <p>{localization.get('smart_search.no_result')}</p>
        </div>
    } else {
      content =
        <div className="smart-search-result-container">
          <HomeElementGridContainer
            {...this.props}
            favorites={this.state.favorites}
            maxPerRow={6}
            homeElement={this.makeHomeElementProp(this.state.searchResultsTags)}
            trackType={this.makeTrackTypeProp()}
            dataType={this.makeDataTypeProp()}
            requestElement={this.makeRequestElementProp()}
            showSectionTitle={false}
            sizes={{
              xs: 6,
              sm: 4,
              md: 3,
              lg: 2,
              xl: 2,
            }}
            showMore={true}
            rowsToShow={3}
            itemComponent={GridItem}
            kind={"speaker"}
          />
        </div>
    }

    return <Container>{content}</Container>
  }

  makeTrackTypeProp = () => {
    return () => 'voice'
  }

  makeDataTypeProp = () => {
    return 'speaker'
  }

  makeHomeElementProp = (speakers) => {
    return {
      ...speakers,
      items: {
        data: speakers.map(item => ({
          ...item,
          title: item.name,
          shortDescription: item.subtitle,
          kind: 'speaker'
        }))
      }
    }
  }

  makeRequestElementProp = () => {
    return id => request.speaker.get(id, 'include=image;voices;voices.audio:id,url,duration;voices.audio.waveform')
      .then(speaker => ({
        ...speaker,
        title: speaker.name,
        longDescription: speaker.description,
        tracks: speaker.voices ? speaker.voices.map(voice => ({...voice, type: 'voice'})) : []
      }))
  }

  cleanSearch = () => {
    this.setState( {
      tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
      searchResultsTags: [],
      showTagSelection: true,
      selectedCountry: '',
      age: null
    })
    browserHistory.push(homePathGenerator(`smart_search/speakers`))
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  requestTracks = ({
     searchTags = this.getSearchTags(),
     unselectedSearchTags = this.getUnselectedSearchTags(),
     age = this.state.age,
     countryId = this.state.selectedCountry.value
   } = {}) =>
    request.speaker.getAllWithTags(
      searchTags,
      unselectedSearchTags,
      age,
      countryId
    ).then(searchResults => {
      setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 500)
      this.setState({searchResultsTags: searchResults, showTagSelection: false})

      const searchTagsNames = this.state.tagGroups.flatMap(x => x.tags).filter(x => searchTags.includes(x.id)).map(x => x.name)
      // analyticsInstance.smartSearch(searchTagsNames)
    })
      .catch(() => {
        GlobalSnackbar.show({
          message: 'smart_search_request_failed',
          type: GlobalSnackbarTypes.ERROR,
        })
      })

  renderFilters = () => {
    let selectedTags = this.getSelectedTags();
    let unselectedTags = this.getUnselectedTags()

    if (!this.state.showTagSelection) {
      return (
        <div className="smart-search-filters-container">
          <div className="smart-search-filters-tag-container" style={{margin: 0}}>
            {(selectedTags.length > 0 || unselectedTags.length > 0) ? selectedTags.concat(unselectedTags).map(tag => {
              return (
                <Chip
                  style={{
                    backgroundColor: tag.state === 'unselected' ? '#e68484' : 'var(--secondary-color, lightgray)',
                    color: tag.state === 'unselected' ? 'white' : 'var(--secondary-font-color, black)'
                  }}
                  label={tag.name}
                  className="smart-search-filters-tag"
                  onDelete={() => this.removeSelectedTag(tag.id)}
                />
              )
            }) : <span>&nbsp;</span>}
          </div>

          <div className="smart-search-filters-buttons-container" style={{top: 0}}>
            <div className="smart-search-catalog-container" style={{width: 300, marginRight: 30}}>
              <FormControl fullWidth>
                <span>{localization.get('countries')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10,
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  name="catalogs"
                  placeholder={localization.get('countries')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={(e) => this.setState({selectedCountry: e},
                    () => this.requestTracks().then(() => this.writeQueryStringParams()))}
                  value={this.state.selectedCountry}
                  defaultOptions
                  loadOptions={inputValue =>
                    request.country.getForEntity(
                      'speakers',
                      false,
                      this.getSearchTags(),
                      this.getUnselectedSearchTags(),
                      {filterBy: [{column: "name", filter: inputValue}]})
                      .then(availableCountries => [{id: '', name: localization.get('all')}].concat(availableCountries)
                        .map(availableCountry =>
                          ({value: availableCountry.id, label: availableCountry.name})))
                  }
                />
              </FormControl>
            </div>
            <div style={{marginTop: "10px", float: "left", width: 120}}>
              <FormControl fullWidth>
                <InputLabel>{localization.get('smart_search.age')}</InputLabel>
                <Select
                  disableUnderline
                  value={this.state.age ? this.state.age.min + ' - ' + this.state.age.max : ''}
                >
                  <div style={{float: "left", position: "relative", width: "340px", padding: "50px"}}>
                    <AgeInputRange
                      onChangeAge={value => {
                        this.setState({age: value})
                      }}
                      onChangeCompleteAge={() => this.requestTracks().then(() => this.writeQueryStringParams())}
                      value={this.state.age}
                    />
                  </div>
                  <MenuItem style={{display: "none"}} selected
                            value={this.state.age ? this.state.age.min + ' - ' + this.state.age.max : null}>
                    {this.state.age ? this.state.age.min + ' - ' + this.state.age.max : null}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{marginTop: "20px", float: "left"}}>
              {makeAddFiltersButton(() => this.setState({showTagDialog: true}))}
              {hasAnySelectedTag(this.state.tagGroups) > 0 && makeCleanTagsButton(this.cleanSearch)}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  render = () => {
    return (
      <div id="innerinner" style={{marginBottom: '10px'}}>
        {this.makeSmartSearchTagSelector()}
        <div style={{display: this.state.showTagSelection ? 'block' : 'none'}} className="smart-search-buttons-container">
          {makeSearchButton(() => this.handleSmartSearch())}
          {hasAnySelectedTag(this.state.tagGroups) > 0 && makeCleanTagsButton(this.cleanSearch)}
        </div>
        {this.renderFilters()}
        {this.renderSearchResultsTableTags()}
        {this.state.showTagDialog && <Dialog
          open
          onClose={() => this.setState({showTagDialog: false})}
          PaperProps={{
            style: {
              backgroundColor: 'var(--secondary-color, white)', 
            },
          }}
        >
          <DialogContent style={{backgroundColor: "var(--secondary-color, inherit)", color: "var(--secondary-font-color, inherit)"}}>
            <TagSelection horizontal={true} onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged} data={this.state.tagGroups.filter(x => x.type === 'speaker')}/>
          </DialogContent>
          <DialogActions style={{backgroundColor: "var(--secondary-color, inherit)"}}>
            <div style={{float: "left", position: "relative", width: "100%", textAlign: "center"}}>
              {makeSearchButton(this.handleSmartSearch)}
            </div>
          </DialogActions>
        </Dialog>}
      </div>
    )
  }
}

export default SmartSearchSpeaker;
