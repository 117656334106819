import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import "./NavBar.css";
import Close from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import Search from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Badge from "@material-ui/core/Badge";
import PlayArrow from "@material-ui/icons/PlayArrow";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import Logo from "../../../assets/img/logo-black.png";
import LogoWhite from "../../../assets/img/logo-white.png";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField/TextField";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {
  addHttpToUrl,
  getMostImportantAlbum,
  makeFitImageUrl,
  makeUrlImageFromAlbums,
  makeWaveformUrl,
  pushIfDoesNotExist
} from "../../../utils";
import {makeImageUrlWithSize, emptyImage} from "./utils";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  catalogClientView,
  clientCanSeeMusics,
  clientIsTypeCatalog,
  getActiveClient, getSignatureTime,
  getStoredUser,
  homePathGenerator,
  userCanAddTracksToPlaylists,
  userCanDownloadTracks, userCanLicenseTracks, userIsClient,
  userIsClientAdmin,
  userisMultiClient,
  userIsReadOnly,
  userIsSupervisor,
  userIsSysAdmin,
  userIsTrial
} from "../../../api-client/core/authentication/utils";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import GlobalAddToPlaylistDialog from "../../../components/Letflow/AddToPlaylistDialog/GlobalAddToPlaylistDialog";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import GlobalLicenseWizardDialog from "../../../components/Letflow/Dialog/GlobalLicenseWizardDialog";
import EventManager from "../../../utils/EventManager/EventManager";
import {events} from "../../../utils/EventManager";
import analyticsInstance from "../../../analytics/analyticsInstance";
import LocalizedStrings from 'react-localization'
import localData from '../../../config/localization/localization.json'
import availableLanguages from '../../../config/localization/availableLanguages.json'
import ContentCreationPlayer from "../../../components/Letflow/ContentCreationPlayer";
const strings = new LocalizedStrings(localData);

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      search: false,
      showSearchItems: false,
      searchInput: null,
      open: false,
      openNotification: false,
      client: props.client,
      countPendingReceipt: 0,
      conversationsWithUnreadMessages: 0,
      language: (getStoredUser() && getStoredUser().language) ? getStoredUser().language : strings.getLanguage(),
      width: window.innerWidth,
      showCreationDialog: false
    };
  }

  componentDidMount = () => {
    this.updateNotifications();
    EventManager.getInstance().subscribe(events.UPDATE_HOME_NOTIFICATIONS, this.updateNotifications);
    window.addEventListener('resize', this.setWindowsWidth);
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.UPDATE_HOME_NOTIFICATIONS, this.updateNotifications);
    window.removeEventListener('resize', this.setWindowsWidth)
  };

  setWindowsWidth = () => this.setState({width: window.innerWidth});

  updateNotifications = () => {
    if (clientCanSeeMusics(this.state.client) && (userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin())) {
      request.license
        .countPendingReceipt(getActiveClient())
        .then(license => this.setState({ countPendingReceipt: license.count }));
    }
  };

  toggleSideBar = open => {
    this.setState({ sidebar: open });
  };

  toggleSearch = open => {
    if (this.state.currentPlayingContent && this.state.currentPlayingContent.id) {
      return;
    }
    this.setState({ search: open }, () => setTimeout(() => this.setState({showSearchItems: open}), 200));
    if (open) {
      setTimeout(() => this.searchInput.focus(), 300);
    } else {
      this.setState({
        searchInput: "",
        musics: [],
        voices: [],
        speakers: [],
        albums: [],
        playlists: [],
        savedQueries: [],
        searchResultComponents: [],
        showSearchItems: false,
      });
    }
  };

  checkPermissions = (entity) => this.state.client.permissions.some(permission => permission.entity === entity);

  handleSearchInputOnChange = e => this.setState({ searchInput: e.target.value });

  handleSearchInputKeyPress = e => {
    if (e.key === "Enter") {
      if (this.state.searchInput.length > 2) {
        this.handleSearch(this.state.searchInput);
        // analyticsInstance.navSearch(this.state.searchInput);
      } else {
        GlobalSnackbar.show({ message: localization.get("searchbar.min_letters"), type: GlobalSnackbarTypes.ERROR });
      }
    }
  };

  handleSearch = query => {
    request.user.globalSearch(getActiveClient(), query)
      .then((response) => {
        this.setState({
          playlists: response.playlists || [],
          albums: response.albums || [],
          artists: response.artists || [],
          musics: response.musics || [],
          voices: response.voices || [],
          speakers: response.speakers || [],
          contentCreators: response.contentCreators || [],
          contentCreations: response.contentCreations || [],
          reelFiles: response.reel_files || [],
          driveFiles: response.drive_files || [],
        })
      })
      .then(() => this.appendSearchResult())
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open, openNotification: false });
  };

  handleToggleNotification = () => {
    this.setState({ openNotification: !this.state.openNotification, open: false });
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handlePlayButtonPress = (track, tracks, type, trackContainer) => {
    const soundbarCompatibleTracks = tracks.map(track => {
      const onLicense = () => {
        GlobalLicenseWizardDialog.show({
          track: {
            id: track.id,
            title: track.title,
            image: this.makeSoundbarTrackImage(trackContainer),
            waveform: makeWaveformUrl(track),
            owner: track.owner
          },
          client: GlobalLicenseWizardDialog.mapApiClient(this.state.client)
        });
      };

      const onToggleFavorite = favorite => request.musicFavorite[favorite ? "add" : "remove"](track.id);

      return {
        fetchAudioUrl: () => new Promise(resolve => {
          let url = '';
          const date = new Date()
          const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

          if((now_utc - Date.parse(track.time)) < getSignatureTime()) {
            url = (!getActiveClient() && track.demo) ? track.demo.url : track.audio.url
          }else{
            url = (type === 'voice') ?
              request.voice.getForClient(track.id).then(track => track.audio.url) :
              request.music.getForClient(track.id).then(track => track.audio.url)
          }

          resolve(url);
        }),
        title: track.title,
        subtitle: trackContainer && (trackContainer.title ? trackContainer.title : trackContainer.name ? trackContainer.name : undefined),
        image: this.makeSoundbarTrackImage(trackContainer),
        waveform: makeWaveformUrl(track),
        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(track, trackContainer, type) : undefined,
        onAddToPlaylist: userCanAddTracksToPlaylists()
          ? () =>
            GlobalAddToPlaylistDialog.show({
              type: type,
              clientId: getActiveClient(),
              selectedTrack: track
            })
          : undefined,
        playlistButtonData: userCanAddTracksToPlaylists()
          ? 
          {
            type: type,
            clientId: getActiveClient(),
            selectedTrack: track,
          }
          :
          undefined,
        onTitleClick:
          type === "music"
            ? () => this.props.history.push(homePathGenerator(`music/${track.id}`))
            : undefined,
        onSubtitleClick: () => this.handleTrackContainerPressedInSoundbar(trackContainer, type),
        isFavorite: track.favorite,
        onToggleFavorite: type === "music" && onToggleFavorite,
        onLicense: type === "music" && onLicense
      };
    });
    const playableTrackIndex = tracks.indexOf(track);

    GlobalSoundBar.loadTracksAndPlay(soundbarCompatibleTracks, playableTrackIndex);
  };

  addTrackContainer = (track, tracks, type) => {
    const audioRequest = type === 'voice' ? request.voice.get(track.id) : request.music.getForClient(track.id)

    audioRequest.then(trackContainer =>
      this.handlePlayButtonPress(
        track,
        tracks,
        type,
        trackContainer.albums
          ? getMostImportantAlbum(trackContainer.albums)
          : trackContainer.speaker
          ? trackContainer.speaker
          : undefined
      )
    )
  };

  playCreation = creation => {
    if(creation.audio || creation.video) {
      this.setState({currentPlayingContent: creation, showContentCreationDialog: true})
    }
  }

  makeSoundbarTrackImage = trackContainer => {
    if (trackContainer.length > 0) {
      return this.makeUrlImageFromAlbums(trackContainer);
    } else {
      return makeImageUrlWithSize(trackContainer, 'xs');
    }
  };

  makeUrlImageFromAlbums = albums => makeUrlImageFromAlbums(albums);

  openDownloadDialog = (track, trackContainer, type) => {
    GlobalConfirmationDialog.show({
      title: localization.get("download.demo"),
      content: localization.get("download.demo.content"),
      request: () => this.handleDownload(track, trackContainer, type),
      confirmationLabel: localization.get("download")
    });
  };

  handleDownload = (track, trackContainer, type) => {
    this.setState({ downloading: true });
    let downloadType = type === "voice" ? "download" : "downloadDemo";
    return request[type][downloadType](track.id, {
      title: track.title,
      image: this.makeSoundbarTrackImage(track, trackContainer)
    })
      .then(() => {
        this.setState({ downloading: false });
      })
      .catch(() => {
        this.setState({ downloading: false });
      });
  };

  handleTrackContainerPressedInSoundbar = (trackContainer, type) => {
    let destinationPath = this.makeTrackContainerPathFrom(trackContainer, type);
    if (destinationPath) {
      this.props.history.push(destinationPath);
    }
  };

  makeTrackContainerPathFrom = (trackContainer, type) => {
    let destinationPath;
    if (trackContainer && type === "music") {
      destinationPath = homePathGenerator(`album/${trackContainer.id}`);
    } else if (trackContainer && type === "voice") {
      destinationPath = homePathGenerator(`speaker/${trackContainer.id}`);
    }
    return destinationPath;
  };

  appendSearchResult = () => {
    const { musics, voices, speakers, albums, playlists, savedQueries, contentCreators, reelFiles, driveFiles, contentCreations, artists  } = this.state;
    const searchResultComponents = [];
    if (
      musics.length > 0 ||
      voices.length > 0 ||
      speakers.length > 0 ||
      albums.length > 0 ||
      playlists.length > 0 ||
      savedQueries.length > 0 ||
      artists.length > 0 ||
      contentCreators.length > 0 ||
      contentCreations.length > 0 ||
      reelFiles.length > 0 ||
      driveFiles.length > 0
    ) {
      if (musics.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.musics")}</h4>
            {musics.map(music => (
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <Link
                  to={homePathGenerator(`music/${music.id}`)}
                  style={{
                    width: "calc(100% - 30px)",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "black"
                  }}
                >
                  {music.title}
                </Link>
                <IconButton
                  onClick={() => this.addTrackContainer(music, musics, "music")}
                  style={{ float: "left", position: "relative", width: "30px", height: "30px", top: "-6px" }}
                >
                  <PlayArrow />
                </IconButton>
              </div>
            ))}
          </div>
        );
      }
      if (voices.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.voices")}</h4>
            {voices.map(voice => (
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  style={{
                    width: "calc(100% - 30px)",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {voice.title}
                </div>
                <IconButton
                  onClick={() => this.addTrackContainer(voice, voices, "voice")}
                  style={{ float: "left", position: "relative", width: "30px", height: "30px", top: "-6px" }}
                >
                  <PlayArrow />
                </IconButton>
              </div>
            ))}
          </div>
        );
      }
      if (albums.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.albums")}</h4>
            {albums.map(album => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`album/${album.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(album, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {album.title}
                </div>
              </Link>
            ))}
          </div>
        );
      }

      if (artists.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.artists")}</h4>
            {artists.map(artist => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`artist/${artist.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(artist.profile ? {image: artist.profile} : artist, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {artist.name}
                </div>
              </Link>
            ))}
          </div>
        );
      }

      if (speakers.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.speakers")}</h4>
            {speakers.map(speaker => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`speaker/${speaker.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(speaker, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {speaker.name}
                </div>
              </Link>
            ))}
          </div>
        );
      }
      if (playlists.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("searchbar.playlists")}</h4>
            {playlists.map(playlist => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`playlist/${playlist.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(playlist, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {playlist.title}
                </div>
              </Link>
            ))}
          </div>
        );
      }
      if (contentCreators.length > 0) {
        const contentCreatorTypes = [];
        contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type));

        contentCreatorTypes.forEach(contentCreatorType => {
          searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <h4>{contentCreatorType.name}</h4>
              {contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id).map(contentCreator => (
                <Link
                  onClick={() => this.toggleSearch(false)}
                  to={homePathGenerator(`content_creator/${contentCreator.id}`)}
                  style={{
                    marginTop: "10px",
                    color: "black",
                    marginBottom: "10px",
                    width: "100%",
                    float: "left",
                    position: "relative",
                    height: "15px"
                  }}
                >
                  <div
                    className="searchbar-result-row-image"
                    style={{backgroundImage: `url(${makeImageUrlWithSize(contentCreator, 'xs')}), url(${emptyImage})`}}
                  />
                  <div
                    style={{
                      width: "70%",
                      float: "left",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {contentCreator.name}
                  </div>
                </Link>
              ))}
            </div>
          );
        })
      }
      if (contentCreations.length > 0) {
        const contentCreatorTypes = [];
        contentCreations.forEach(contentCreation => pushIfDoesNotExist(contentCreatorTypes, contentCreation.contentCreator.type));
        contentCreatorTypes.forEach(contentCreatorType => {
          searchResultComponents.push(
            <div className="searchbar-result-row-container">
              <h4>{contentCreatorType.name}</h4>
              {contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id).map(contentCreation => (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                    float: "left",
                    position: "relative",
                    height: "15px"
                  }}
                >
                  <div
                    style={{
                      width: "calc(100% - 30px)",
                      float: "left",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {contentCreation.title}
                  </div>
                  <IconButton
                    onClick={() => this.playCreation(contentCreation)}
                    style={{float: "left", position: "relative", width: "30px", height: "30px", top: "-6px"}}
                  >
                    <PlayArrow/>
                  </IconButton>
                </div>
              ))}
            </div>
          );
        })
      }
      if (reelFiles.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("navbar.reels")}</h4>
            {reelFiles.map(reelFile => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`reels?search=${reelFile.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(reelFile, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {reelFile.title}
                </div>
              </Link>
            ))}
          </div>
        );
      }
      if (driveFiles.length > 0) {
        searchResultComponents.push(
          <div className="searchbar-result-row-container">
            <h4>{localization.get("drive")}</h4>
            {driveFiles.map(driveFile => (
              <Link
                onClick={() => this.toggleSearch(false)}
                to={homePathGenerator(`drive?search=${driveFile.id}`)}
                style={{
                  marginTop: "10px",
                  color: "black",
                  marginBottom: "10px",
                  width: "100%",
                  float: "left",
                  position: "relative",
                  height: "15px"
                }}
              >
                <div
                  className="searchbar-result-row-image"
                  style={{ backgroundImage: `url(${makeImageUrlWithSize(driveFile, 'xs')}), url(${emptyImage})` }}
                />
                <div
                  style={{
                    width: "70%",
                    float: "left",
                    position: "relative",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {driveFile.title}
                </div>
              </Link>
            ))}
          </div>
        );
      }
    } else {
      searchResultComponents.push(
        <div>
          <p style={{paddingTop: 50, textAlign: "center"}}>{localization.get("searchbar.no_result")}</p>
        </div>
      );
    }
    this.setState({ searchResultComponents: searchResultComponents });
  };

  hasElevatedPrivileges = () => userIsSupervisor() || userIsSysAdmin() || userisMultiClient();

  isSelected = route => {
    if (route === "home") {
      if (window.location.pathname.split("/").length <= 3) {
        return true;
      }
    } else {
      return window.location.pathname.includes(route);
    }
  };

  render = () => {
    const { sidebar, search, showSearchItems, open, client } = this.state;
    let mobile = this.state.width <= 450;
    return (
      <div>
        <ContentCreationPlayer
         show={this.state.showContentCreationDialog}
         currentContentCreation={this.state.currentPlayingContent}
         handleClose={() => this.setState({showContentCreationDialog: false,currentPlayingContent: {}})}
        />
        { !this.props.transparentMode ?
          <nav className="navbar">
            {(!catalogClientView() || this.state.width < 1200) &&
              <div className="navbar-menu-container">
                <IconButton onClick={() => this.toggleSideBar(true)} className="navbar-menu-button">
                  <MenuIcon />
                </IconButton>
              </div>
            }
            <div className="navbar-image-container" style={{left: (catalogClientView() && this.state.width > 1200) && "66px"}}>
              <Link className="navbar-image-container-link" to={homePathGenerator(``)}>
                <img className="navbar-image" src={(client && client.use_logo && client.image) ? makeFitImageUrl(this.props.client, 300,35, '', 'png') :Logo} alt="Logo Feater" />
                {(client && !client.use_logo) &&
                  <>
                    <div className="navbar-client-name-pipe">|</div>
                    <div className="navbar-client-name">{client.name}</div>
                  </>
                }
              </Link>
            </div>

            <div className="navbar-links"  style={{marginLeft: catalogClientView() && "-15%"}}>
              {this.hasElevatedPrivileges() &&
                <Link className={`navbar-link ${this.isSelected("dashboard") ? "selected" : ""}`} to={`/dashboard`}>
                  {localization.get("navbar.dashboard")}
                </Link>
              }
              <Link
                className={`navbar-link ${this.isSelected("home") ? "selected" : ""}`}
                to={homePathGenerator(``)}
              >
                {localization.get("navbar.home")}
              </Link>
              {!catalogClientView() &&
                <Link
                  className={`navbar-link ${this.isSelected("playlists") ? "selected" : ""}`}
                  to={homePathGenerator(`playlists`)}
                >
                  {localization.get("navbar.playlists")}
                </Link>
              }
              {this.checkPermissions('pitch') &&
                <Link
                  className={`navbar-link ${this.isSelected("projects") ? "selected" : ""}`}
                  to={homePathGenerator(`projects`)}
                >
                  {localization.get("navbar.projects")}
                </Link>
              }
              <Link
                className={`navbar-link ${this.isSelected("smart_search") ? "selected" : ""}`}
                to={homePathGenerator(`smart_search`)}
              >
                {localization.get("navbar.smart_search")}
              </Link>
              {client.reel_url &&
              <Link
                className={"navbar-reel-link"}
                to={addHttpToUrl(client.reel_url)}
                target={"_blank"}
              >
                {localization.get("reel")}
              </Link>
              }
              {clientIsTypeCatalog() &&
              <>
              {client.translations && !client.show_about_in_home &&
                  <Link
                    className={`navbar-link ${this.isSelected("about") ? "selected" : ""}`}
                    to={homePathGenerator(`about`)}
                  >
                    {localization.get("navbar.about")}
                  </Link>
              }
              {client.email &&
                  <Link
                    className={`navbar-link ${this.isSelected("contact") ? "selected" : ""}`}
                    to={homePathGenerator(`contact`)}
                    style={{marginRight: 0}}
                  >
                    {localization.get("public_navbar.contact_us")}
                  </Link>
              }
              </>
              }
            </div>

            {/*Profile*/}
            {!catalogClientView() &&
              <div className="navbar-user-container">

                <IconButton
                  className="navbar-user-button"
                  buttonRef={node => {
                    this.anchorEl = node;
                  }}
                  aria-owns={open ? "menu-list-grow" : null}
                  aria-haspopup="true"
                  onClick={this.handleToggle}
                >
                  <div
                    className="navbar-user-button-image"
                    style={{ backgroundImage: `url(${makeImageUrlWithSize(getStoredUser(), 'xs')}), url(${emptyImage})` }}
                  />
                </IconButton>
                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList>
                            <MenuItem disabled style={{ cursor: "default" }} onClick={this.handleClose}>
                              {getStoredUser().name}
                            </MenuItem>
                            <Link
                              style={{ color: "black" }}
                              to={homePathGenerator(`account/profile`)}
                            >
                              <MenuItem onClick={this.handleClose} style={{ color: "black" }}>
                                {localization.get("navbar.my_profile")}
                              </MenuItem>
                            </Link>
                            <MenuItem onClick={() => request.authentication.logout()}>{localization.get("navbar.logout")}</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                {(userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin()) &&
                  <React.Fragment>
                    <IconButton
                      key={0}
                      color="inherit"
                      onClick={this.handleToggleNotification}
                      buttonRef={node => {
                        this.anchorNot = node;
                      }}
                      aria-owns={open ? "menu-list-grow" : null}
                      aria-haspopup="true"
                      style={{ top: "-5px", float: "right", right: 70}}
                    >
                      {!this.state.countPendingReceipt ? (
                        <NotificationsNoneIcon />
                      ) : (
                        <Badge
                          badgeContent={this.state.countPendingReceipt}
                          color="secondary"
                        >
                          <NotificationsIcon />
                        </Badge>
                      )}
                    </IconButton>
                    <Popper key={1} open={this.state.openNotification} anchorEl={this.anchorNot} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={this.handleToggleNotification}>
                              <MenuList>
                                {
                                  this.state.countPendingReceipt > 0
                                    ?
                                    <MenuItem onClick={this.handleToggleNotification}>
                                      <Link
                                        style={{ color: "black" }}
                                        to={homePathGenerator(`account/licenses?order_by=requested`)}
                                      >
                                        {localization.get("navbar.pending_receipt", this.state.countPendingReceipt)}
                                      </Link>
                                    </MenuItem>
                                    :
                                    <MenuItem onClick={this.handleToggleNotification}>
                                      {localization.get("navbar.no_notifications")}
                                    </MenuItem>

                                }
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </React.Fragment>
                  }

                <IconButton
                  style={{float: "right", top: -5, right: 65, color: 'inherit', zIndex: 1000}}
                  onClick={() => this.props.history.push(homePathGenerator(`favorites`))}
                >
                  {this.isSelected("favorites") ? <Star/> : <StarBorder/>}
                </IconButton>

              </div>
            }

            <div className="navbar-search-container" style={{right: catalogClientView() && (mobile ? "-70px" : "-40px")}}>
              <IconButton
                onClick={() => this.toggleSearch(true)}
                style={{float: "right", top: -5, right: catalogClientView() ? "105px" : "60px", color: 'inherit'}}
              >
                <Search />
              </IconButton>
            </div>

          </nav>
          :
          <nav className="navbar transparent">
            {!catalogClientView() &&
            <div className="navbar-menu-container">
              <IconButton onClick={() => this.toggleSideBar(true)} className="navbar-menu-button">
                <MenuIcon style={{color: "white"}}/>
              </IconButton>
            </div>
            }
            <div className="navbar-image-container" style={{left: catalogClientView() && "10%"}}>
              <Link className="navbar-image-container-link" to={homePathGenerator(``)}>
                <img className="navbar-image" style={{filter:  "grayscale(1)", marginTop: (!client || !(client.use_logo && client.image)) ? '5px' : 0}} src={(client && client.use_logo && client.image) ? makeFitImageUrl(this.props.client, 300,35, '', 'png') : (client && client.managedBy && client.managedBy.image) ? makeFitImageUrl(client.managedBy, 300,35, '', 'png') : LogoWhite} alt="Logo Feater" />
                {(client && !client.use_logo) &&
                  <div className="navbar-client-name" style={{color: "white"}}>
                    <div className="navbar-client-name-pipe" style={{color: "white"}}>|</div>
                    {client.name}
                  </div>
                }
              </Link>
            </div>
          </nav>
        }

        <ClickAwayListener onClickAway={() => this.toggleSearch(false)}>
          <div>
            <div style={{ height: search ? "70px" : 0 }} className="search-bar">
              <div style={{ display: showSearchItems ? "block" : "none" }} className="navbar-close-search-button-container">
                <IconButton onClick={() => this.toggleSearch(false)} className="navbar-menu-button">
                  <Close />
                </IconButton>
              </div>
              <div style={{ display: showSearchItems ? "inline-block" : "none" }} className="searchbar-input-container">
                <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false
                  }}
                  autoComplete={false}
                  className="searchbar-input"
                  onKeyPress={this.handleSearchInputKeyPress}
                  placeholder={localization.get("searchbar.input")}
                  value={this.state.searchInput}
                  onChange={this.handleSearchInputOnChange}
                />
              </div>
            </div>

            <div id="searchbar-results-container" className="searchbar-results-container" style={{paddingBottom: 60}}>
              {this.state.searchResultComponents}
            </div>
          </div>
        </ClickAwayListener>

        <Drawer
          id="navbar-drawer"
          PaperProps={{ style: { backgroundColor: "rgb(247, 247, 247)" } }}
          open={sidebar}
          onClose={() => this.toggleSideBar(false)}
        >
          <div className="sidebar">
            <div className="sidebar-close-container">
              <IconButton onClick={() => this.toggleSideBar(false)} className="sidebar-close-button">
                <Close />
              </IconButton>
            </div>

            {!clientIsTypeCatalog() &&
              <div className="sidebar-idiom-container">
                {availableLanguages.map(lang => {
                  return (
                    <div
                      style={{
                        fontWeight: this.state.language === lang ? 'bold' : 'normal',
                        cursor: 'pointer',
                        float: "left",
                        position: "relative",
                        marginRight: "20px"
                      }}
                      onClick={() => {
                        request.user.update({
                          userId: getStoredUser().id,
                          language: lang
                        }).then(() => window.location.reload())
                      }}
                    >
                      {lang.toUpperCase()}
                    </div>
                  )
                })}
              </div>
            }

            <div className="sidebar-container">
              <div className="sidebar-link-container">
                {this.hasElevatedPrivileges() ? (
                  <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                    <Link to={`/dashboard`}>{localization.get("navbar.dashboard")}</Link>
                  </div>
                ) : null}
                <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                  <Link onClick={() => this.toggleSideBar(false)} to={homePathGenerator(``)}>
                    {localization.get("navbar.home")}
                  </Link>
                </div>

                <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                  <Link
                    onClick={() => this.toggleSideBar(false)}
                    to={homePathGenerator(`smart_search`)}
                  >
                    {localization.get("navbar.smart_search")}
                  </Link>
                </div>

                {client.reel_url &&
                  <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                  <Link
                    onClick={() => this.toggleSideBar(false)}
                    to={addHttpToUrl(client.reel_url)}
                    target={"_blank"}
                  >
                    {localization.get("reel")}
                  </Link>
                </div>
                }

                {clientIsTypeCatalog() &&
                <>
                  {client.translations && !client.show_about_in_home &&
                  <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`about`)}
                    >
                      {localization.get("navbar.about")}
                    </Link>
                  </div>
                  }
                  {client.email &&
                  <div className={`sidebar-link show-link-mobile ${this.props.transparentMode ? 'transparent' : ''}`}>
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`contact`)}
                    >
                      {localization.get("public_navbar.contact_us")}
                    </Link>
                  </div>
                  }
                </>
                }

                {!catalogClientView() &&
                <>
                <div className="sidebar-link">
                  <Link
                    onClick={() => this.toggleSideBar(false)}
                    to={homePathGenerator(`favorites`)}
                  >
                    {localization.get("navbar.favorites")}
                  </Link>
                </div>

                {!userIsReadOnly() && !userIsTrial() && this.checkPermissions('pitch') &&
                    <div className="sidebar-link">
                      <Link
                          onClick={() => this.toggleSideBar(false)}
                          to={homePathGenerator(`projects`)}
                      >
                        {localization.get("navbar.projects.side")}
                      </Link>
                    </div>
                }

                <div className={`sidebar-link`}>
                  <Link
                    onClick={() => this.toggleSideBar(false)}
                    to={homePathGenerator(`playlists`)}
                  >
                    {localization.get("navbar.playlists.side")}
                  </Link>
                </div>

                {!userIsReadOnly() && !userIsClient() && !userIsTrial() ? (
                <div className={`sidebar-link`}>
                  <Link
                    onClick={() => this.toggleSideBar(false)}
                    to={homePathGenerator(`links`)}
                  >
                    {localization.get("navbar.links")}
                  </Link>
                </div>
                ) : null}

                </>}


                {!catalogClientView() && this.checkPermissions('music') && !userIsReadOnly() && (userIsClient() && userCanLicenseTracks() || userIsClientAdmin() || userIsSysAdmin()) && !userIsTrial() && this.checkPermissions('account') ? (
                  <div className="sidebar-link">
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`account/licenses`)}
                    >
                      {localization.get("navbar.licences")}
                    </Link>
                  </div>
                ) : null}

                {!catalogClientView() && !userIsReadOnly() && !userIsClient() && !userIsTrial() && this.checkPermissions('client_file') &&
                  <div className="sidebar-link">
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`drive`)}
                    >
                      {localization.get("drive")}
                    </Link>
                  </div>
                }

                {!catalogClientView() && !userIsReadOnly() && !userIsClient() && !userIsTrial() && this.checkPermissions('reel') &&
                  <div className="sidebar-link">
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`reels`)}
                    >
                      {localization.get("navbar.reels")}
                    </Link>
                  </div>
                }
              </div>

              <div className="sidebar-user-container">
                {this.checkPermissions('account') &&
                  <div className="sidebar-link-user">
                    <Link
                      onClick={() => this.toggleSideBar(false)}
                      to={homePathGenerator(`account/profile`)}
                    >
                      {localization.get("navbar.my_account")}
                    </Link>
                  </div>
                }
                {!catalogClientView() &&
                  <div className="sidebar-link-user">
                    <Link to={"#"} onClick={() => request.authentication.logout()}>
                      {localization.get("navbar.logout")}
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  };
}

NavBar.defaultProps = {
  transparentMode: false
};

export default NavBar;
