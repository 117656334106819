import {isNullOrUndefined} from "util";

export const makeFormData = ({
  name,
  phone,
  email,
  translations,
  imageFile,
  voices,
  selectedTags,
  isDemo,
  birthdate,
  active,
  socialLinks,
  countryId,
  observation,
  selectedCatalog
}) => {
  const form = new FormData();
  if (name) form.append("name", name);
  if (phone) form.append("phone", phone);
  if (email) form.append("email", email);
  if (birthdate) form.append("birthdate", birthdate);
  if (!isNullOrUndefined(active)) form.append("active", active ? 1 : 0);
  if (countryId) form.append("country_id", countryId);
  if (observation) form.append("observation", observation);
  if (selectedCatalog) form.append("catalog_id", selectedCatalog);
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
    });
  }
  if (socialLinks) {
    socialLinks.forEach(socialLink => {
      if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
    });
  }
  if (!isNullOrUndefined(isDemo)) form.append("is_demo", isDemo ? 1 : 0);
  if (imageFile) form.append("image", imageFile, imageFile.name);
  if (voices) voices.forEach(x => form.append("voices_ids[]", x));
  if (selectedTags) selectedTags.forEach(x => form.append("tags_ids[]", x));
  return form;
};

export const root = "/speakers";

export const includeQuery =
  "include=image;voices.audio;voices.audio.waveform;tags";
