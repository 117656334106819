import React, {Component} from "react";
import localization from '../../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../../utils";

export default class extends Component {
  state = {
    name: "",
    channel: null,
    products: [],
    image: { src: null, file: null },
    imageId: null,
    disableForm: false,
    mainProduct: null,
  }

  componentDidMount = () => {
    request.contentPacks.get(this.props.match.params.id)
      .then(contentPack => this.setState({
        id: contentPack.id,
        name: contentPack.name,
        channel: {value: contentPack.channel.id, label: contentPack.channel.name},
        products: contentPack.products.map(product => ({value: product.id, label: product.content.name || product.content.title})),
        mainProduct: contentPack.products.length > 0 ? {value: contentPack.products[0].id, label: contentPack.products[0].content.name || contentPack.products[0].content.title} : null,
        image: contentPack.image
          ? { id: contentPack.image.id, src: getUpdateFormImageUrlFromItem(contentPack), filename: contentPack.image.original_name }
          : { src: null, file: null },
      }))
  }

  createPack = () => {
    return request.contentPacks.update(this.props.match.params.id, {
      name: this.state.name,
      productIds: this.state.products.map(product => product.value),
      imageId: this.state.imageId,
    }) .then(() => GlobalSnackbar.show({
      message: localization.get('snackbar.entity_update_success'),
      type: GlobalSnackbarTypes.SUCCESS
    }))
      .catch(() => GlobalSnackbar.showGenericError)
      .finally(() => this.props.history.push(makeUrlWithTableParams('/panel/products')))
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onImageChange = image => image ? this.setState({imageId: image.id, image: { src:null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  onSubmitValidation = () => {
    if (this.state.products.length === 0) {
      GlobalSnackbar.show({message: localization.get('must_select_contents'), type: GlobalSnackbarTypes.ERROR});
      this.setState({countryIsCompleted: false})
      return false
    } else if (!this.state.name) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.must_complete_name')})
      return false
    } else if (this.state.synopsisEs && this.state.synopsisEs.length > 250) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.synopsisEs_validation')})
      return false;
    }else if (this.state.synopsisEn && this.state.synopsisEn.length > 250) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.synopsisEn_validation')})
      return false;
    }else {
      this.setState({countryIsCompleted: true})
      return true
    }
  }

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('content_pack.update')}
          submitRequest={this.createPack}
          onBack={this.props.history.goBack}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
          actionsExtraSpace={true}
        >
          <Form
            name={this.state.name}
            products={this.state.products}
            onProductsChange={products => this.setState({ products, mainProduct: products.length === 0 ? null : products[0]})}
            channel={this.state.channel}
            onChannelChange={channel => this.setState({ channel: null }, () => this.setState({ channel }))}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            image={this.state.image}
            updateDisableForm={value => this.setState({disableForm: value})}
            disableForm={this.state.disableForm}
            onMainProductChange={mainProduct => this.setState({
              products: this.state.products.sort((x, y) => x.value === mainProduct.value ? -1 : y.value === mainProduct.value ? 1 : 0),
              mainProduct: this.state.products[0]
            })}
            mainProduct={this.state.mainProduct}
          />
        </FormViewContainer>
      </div>
    )
  }
}
