import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, 
  TextField, } from "@material-ui/core";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import AsyncSelect from "react-select/lib/Async";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";

export default class ImportPurchaseModal extends React.Component {
  state = {
    product: null,
    fileId: null,
  }

  onClose = () => this.setState({product: null, file: null, fileId: null}, () => this.props.onClose())
  

  handleImport = () => request.products.importPurchases({productId: this.state.product.value, fileId: this.state.fileId})
    .then(() => GlobalSnackbar.show({message: localization.get('product.import_success'), type: "success"}))
    .then(this.onClose)
  
    render = () => {
      const canSubmit = this.state.product && this.state.fileId;
      console.log(this.props.channel)
      return (
        <Dialog open={this.props.open} onEscapeKeyDown={this.onClose}>
          <DialogTitle>Importar Compra</DialogTitle>
          <DialogContent style={{ width: 400 }}>
            <TextField
                style={{ width: "100%" }}
                label={localization.get('channel')}
                name="channelName"
                value={this.props.channel.label}
                disabled={true}
            />
            <br/>
            <span>{localization.get('products')}</span>
            <AsyncSelect
              placeholder={localization.get('products')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={(e) => this.setState({product: e})}
              value={this.state.product}
              isDisabled={this.state.disabled}
              loadOptions={inputValue => {
                let filters = [{ column: "name", filter: inputValue }]
                
                return request.products.getAll(this.props.channel.value, {filterBy: filters})
                  .then(response => response.data)
                  .then(products => products.map(product => ({value: product.id, label: product.content.name || ""})))
                  .then(contentCreations => contentCreations.sort((a, b) => a.label.localeCompare(b.label)))
              }}
            />        
            <br/>
            <label>CSV</label>
            <FilePondUploader
              disableForm={this.state.disableSubmit}
              updateDisableForm={value => this.setState({disableSubmit: value})}
              file={this.state.fileId}
              id={"other"}
              type={"other"}
              allowedTypes={["text/csv"]}
              onIdChange={csv => this.setState({fileId: csv.id})}
            />
            <br/>
            <span>{localization.get('purchase.import_helper')}</span>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} >
              {localization.get('cancel')}
            </Button>
            <Button onClick={this.handleImport}  disabled={this.state.disabled || !canSubmit}>
              {localization.get('import')}
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
}