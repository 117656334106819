import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {userCanCreateUser} from "../../../api-client/core/authentication/utils";
import {makeUrlWithTableParams} from "../../../utils";

class All extends Component {
  state = {
    isChannel: window.location.pathname.includes('channel')
  }

  componentDidMount = () => {
    document.title = localization.get(this.state.isChannel ? 'title.channels' : 'title.users') + ' - Feater'
  }

  onAdd = () => this.props.history.push(makeUrlWithTableParams(this.state.isChannel ? `/panel/channel_users/create` : `/panel/users/create`))

  render = () =>
    <AllViewContainer onAdd={userCanCreateUser() ? this.onAdd : null} >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props} isChannel={this.state.isChannel}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default All;
