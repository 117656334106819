import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    translations,
    imageId,
    visibility,
    selectedClients,
    hideInHome,
    featured,
    selectedMusics,
    artistId,
    type,
    createdAt,
    selectedCatalog,
    tagsIds,
    unsplashImage
  }) => post(`${host}/albums`, makeFormData({
    translations,
    imageId,
    visibility,
    selectedClients,
    hideInHome,
    featured,
    selectedMusics,
    artistId,
    type,
    createdAt,
    selectedCatalog,
    tagsIds,
    unsplashImage
  }))
  .then(response => response.json())
