import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TableSubcomponent from "./TableSubcomponent";
import {FilterNone, Person} from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {getActiveClient} from "../../../api-client/core/authentication/utils";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Tooltip} from "@material-ui/core";

class LinksTable extends Component {
  state = {
    total: "",
    showLinks: "all"
  };

  handleAlertClosed = artistId => status => {
    if (status === "passed") this.refs.table.removeById(artistId);
  };

  buttons = link => {
    const { url, user } = link;

    const buttonBuilder = new TableButtonsBuilder();

    buttonBuilder.withOther(
        <TableButton
            title={localization.get('copy')}
        >
          <Tooltip placement="top" title={this.state.copied}>
            <CopyToClipboard text={url}
                             style={{bottom: 5}} >
              <IconButton>
                <FilterNone />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        </TableButton>, url)

    buttonBuilder.withOther(
        <TableButton
            title={user ? user.name : '' }
        >
          <Tooltip placement="top">
            <CopyToClipboard text={url}
                             style={{bottom: 5}} >
              <IconButton>
                <Person />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        </TableButton>, user)

    return buttonBuilder.build();
  };

  columns = () => [
    {
      Header: localization.get("table.share_material"),
      accessor: "material",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          placeholder={localization.get('table.search')}
        />
      ),
      Cell: shortLink => <span>{shortLink.value !== "Content creator" ? shortLink.value : shortLink.original.material_description ? shortLink.original.material_description.name : '-'}</span>
    },
    {
      Header: localization.get("table.detail"),
      id: 'owner',
      accessor: shortLink =>  (shortLink.owner) ? shortLink.owner.name || shortLink.owner.title : '',
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get("table.client"),
      id: 'client',
      accessor: shortLink =>  (shortLink.client) ? shortLink.client.name : '',
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          placeholder={localization.get('table.search')}
        />
      ),
      sortable: false
    },
    {
      Header: localization.get("table.created_at"),
      id: "created_at",
      accessor: shortLink => localization.moment(shortLink.created_at).fromNow(),
      filterable: false,
      sortable: true
    },
    {
      Header: localization.get('table.views'),
      id: 'views',
      accessor: "views",
      filterable: false,
      sortable: true
    },
    {
      Header: localization.get("table.last_view"),
      id: "updated_at",
      accessor: shortLink => localization.moment(shortLink.updated_at).fromNow(),
      filterable: false,
      sortable: true
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145,
      Footer: (
        <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>Total: </strong>
          {this.state.total}
        </span>
      )
    }
  ];

  makeSubComponent = row => (
    <TableSubcomponent {...this.props} link={row.original} />
  );

  render = () => {
    return (
      <div>
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <RadioGroup
            name="showLinks"
            value={this.state.showLinks}
            onChange={event => {
              localStorage.setItem("showLinks", `"${event.target.value}"`)
              this.setState({ showLinks: event.target.value })
              this.refs.table.forceUpdate();
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('links.all')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="mine" control={<Radio color="default" />} label={localization.get('links.mine')} />
          </RadioGroup>
        </FormControl>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            subComponent={this.makeSubComponent}
            requestTableData={paginationConfig => {
              if(getActiveClient()){
                paginationConfig.client_id = getActiveClient()
              }
              if (this.state.showLinks === "mine") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'my_links', filter: true }]) }
              }
              return request.shareLink
                .getAll(paginationConfig)
                .then(response => {
                  this.setState({ total: response.meta.total });
                  return response;
                });
            }}
          />
        </div>
      </div>
    )
  };
}

export default LinksTable;
