import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {CloudDownload, Delete, Edit, PlayArrow} from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import moment from "moment";
import Card from "@material-ui/core/Card";
import ContentCreationPlayer from "../../../components/Letflow/ContentCreationPlayer";
import {
  Button, Checkbox,
  Dialog,
  DialogActions, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel, FormHelperText, Input,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";

class UploadedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlayDialog: false,
      link: props.link,
      folder: props.folder,
      unlimitedFolders: false,
      editingFile: null,
      newFilename: "",
      newFilenameExt: "",
      filesToDelete: [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.folder) {
      this.setState({folder: nextProps.folder})
    }
  }

  playContent = material => this.setState({ showPlayDialog: true, currentPlayingContent: material})

  buttons = batchUploadFile => {
    const builder = new TableButtonsBuilder()

    if (batchUploadFile.type !== 'other') {
      builder.withOther(<TableButton title={localization.get('tooltip.play')} onClick={() => this.playContent(batchUploadFile)}><PlayArrow/></TableButton>)
    }

    builder.withOther(
      <TableButton
        title={localization.get('download')}
        onClick={() => {
            const filename = batchUploadFile.type === 'other' ? batchUploadFile.binary.original_name :
              batchUploadFile[batchUploadFile.type].original_name;

            let url = batchUploadFile.type === 'other' ? batchUploadFile.binary.url : batchUploadFile[batchUploadFile.type].url

            window.open(`${url}?filename=${filename}`, '_blank')
        }}
      >
        <CloudDownload/>
      </TableButton>
    )

    builder.withOther(
      <TableButton
        title={localization.get('edit')}
        onClick={() => {
          const filename = batchUploadFile.filename.split('.');
          const ext = filename.pop()

          this.setState({editingFile: batchUploadFile, newFilename: filename.join('.'), newFilenameExt: ext})
        }}
      >
        <Edit />
      </TableButton>
    )

    builder.withDelete(batchUploadFile.filename,
      () => request.batchUpload.deleteFile(batchUploadFile.id),
        status => {
      if (status === 'passed') {
        this.refs.table.removeById(batchUploadFile.id)
      }
    })


    return builder.build();
  }

  onChangeFilesToDelete = id => {
    if (this.state.filesToDelete.includes(id)) {
    this.setState({filesToDelete: this.state.filesToDelete.filter(ccId => ccId !== id)})
    }else {
      this.state.filesToDelete.push(id)
      this.setState({filesToDelete: this.state.filesToDelete})
    }
  }

  deleteManyFiles = () => {
    if (this.state.filesToDelete.length > 1) {
      GlobalConfirmationDialog.showDelete({
        elementName: localization.get('form.double_confirmation_delete_many.label'),
        content: "confirmation_dialog.delete_many_materials",
        deleteRequest: () => new Promise(resolve => {
          Promise.all(this.state.filesToDelete.map(id => request.batchUpload.deleteFile(id)))
            .then(this.refs.table.forceUpdate)
            .then(() => this.setState({filesToDelete: []}))
            .then(resolve)
        }),
        doubleConfirmation: true,
        auxiliaryInformation: localization.get('form.double_confirmation_delete_many.aux', this.state.filesToDelete.length),
        doubleConfirmationText: "form.double_confirmation_delete_many",
      })
    }
  }

  columns = () => [
    {
      id: "check",
      Header: () => this.state.filesToDelete.length === 0 ? null :
        <TableButton
          title={localization.get("delete")}
          // style={{marginTop: 7, marginLeft: 7}}
          onClick={this.deleteManyFiles}
        >
          <Delete />
        </TableButton>,
      accessor: file =>
        <Checkbox
          style={{color: "#4cc2d5"}}
          checked={this.state.filesToDelete.includes(file.id)}
          onChange={() => this.onChangeFilesToDelete(file.id)}
        />
      ,
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get('table.name'),
      accessor: "filename",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    },
    {
      Header: localization.get("created_at"),
      accessor: "created_at",
      Cell: ({ value }) => <span>{moment(value).format("DD-MM-YYYY")}</span>,
      maxWidth: 175,
      filterable: false,
      sortable: true
    },
    {
      Header: localization.get("type"),
      accessor: "type",
      Cell: ({ value }) => <span>{localization.get(`${value}`)}</span>,
      maxWidth: 120,
      filterable: false,
      sortable: true
    },
    {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      maxWidth: 140,
      accessor: this.buttons,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ]

  onEdit = () => request.batchUpload.updateFile(this.state.editingFile.id, {name: `${this.state.newFilename}.${this.state.newFilenameExt}`})
    .then(() => this.setState({editingFile: null}))
    .then(this.refs.table.forceUpdate)

  render = () => {
    let folders = this.state.link.file_labels.filter(label => label !== "");
    if (!this.state.unlimitedFolders) {
      folders = folders.slice(0, 10)
    }

    return (
      <>
      <Card raised style={{height: "calc(100vh - 122px)", overflowY: 'scroll'}}>
        <Dialog open={this.state.editingFile} onEscapeKeyDown={() => this.setState({editingFile: null})}>
          <DialogTitle>{localization.get("batch_upload.file_edit")}</DialogTitle>
          <DialogContent style={{width: 400}}>
            <FormControl style={{width: "100%"}}>
              <Input
                id="filename"
                value={this.state.newFilename}
                onChange={e => this.setState({newFilename: e.target.value})}
                endAdornment={<InputAdornment position="end">{this.state.newFilenameExt}</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                style={{width: "100%"}}
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
              <FormHelperText id="standard-weight-helper-text">{localization.get('form.name')}</FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ editingFile: false })}>{localization.get('cancel')}</Button>
            <Button onClick={this.onEdit}>{localization.get('send')}</Button>
          </DialogActions>
        </Dialog>
        <div style={{ width: "100%", float: "left", position: "relative", padding: "20px 40px", display: "flex", flexDirection: "column"}}>
          <ContentCreationPlayer
            show={this.state.showPlayDialog}
            currentContentCreation={this.state.currentPlayingContent}
            handleClose={() => this.setState({showPlayDialog: false, currentPlayingContent: {}})}
            
          />
          {this.state.link.file_labels.filter(label => label !== "").length > 0 &&
            <>
              <h4>{localization.get('files.folders')}</h4>
            <FormControl style={{width: "100%", float: "left", position: "relative"}}>
              <RadioGroup
                name="showContent"
                value={this.state.folder}
                onChange={event => this.setState({folder: event.target.value}, () => this.refs.table.forceUpdate())}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="all" control={<Radio color="default"/>} label={localization.get('files.all')}/>
                <FormControlLabel value="empty_folder" control={<Radio color="default"/>} label={localization.get('files.no_folder')}/>
                {folders.map(label =>
                  <FormControlLabel value={label} control={<Radio color="default"/>} label={label}/>
                )}
              </RadioGroup>
            </FormControl>
              {this.state.link.file_labels.filter(label => label !== "").length > 6 &&
                <p
                  style={{cursor: "pointer", textDecoration: "underline"}}
                  onClick={() => this.setState({unlimitedFolders: !this.state.unlimitedFolders})}>
                  {localization.get(!this.state.unlimitedFolders ? 'form.show_more' : 'form.show_less')}
                </p>
              }
              <br/>
              <br/>
            </>
          }
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            perPage={12}
            requestTableData={paginationConfig => {
              if(!paginationConfig.orderBy) {
                paginationConfig.orderBy = {column: "created_at", type: "desc"}
              }

              if (this.state.folder !== "all") paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'label', filter: this.state.folder }]) };

              return request.batchUpload.getAllFilesPublic(paginationConfig, this.props.token).then(response => {
                this.setState({total: response.meta.total});
                return response;
              });
            }}
          />
        </div>
      </Card>
      </>
    )
  }
}


export default UploadedTable
