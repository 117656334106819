import React from "react";
import Button from "../../../components/CustomButtons/Button";
import localization from "../../../config/localization";
import browserHistory from "../../../utils/browserHistory";
import {isNullOrUndefined} from "util";
import Footer from "../../Home/HomeElements/Footer";
import ChannelPublicNavBar from "../ChannelPublicNavBar";
import {request} from "../../../api-client";
import {channelPathGenerator} from "../../../api-client/core/authentication/utils";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorText: props.errorText || localization.get('error.not_found'),
      showButton: !isNullOrUndefined(props.showButton) ? props.showButton : true,
      channel: null,
    }
  }

  componentDidMount = () => {
    if(this.props.channelId) request.channel.getPublic(this.props.channelId).then(channel => this.setState({channel}))
  }

  render = () => {
    return (
      <div style={{width: "100%", height:"100vh", display: "flex", flexDirection: "column"}}>
        {!!this.state.channel && <ChannelPublicNavBar channel={this.state.channel} hideImage={true}/>}
        <div style={{width: "100%", height: "calc(100vh - 100px)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <p style={{textAlign:"center", fontWeight: 800, fontSize: 150, padding: "15px", lineHeight: 1, color: "var(--main-font-color, inherit)"}}> 404 </p>
          <p style={{textAlign:"center",fontSize: 50, padding: "15px", lineHeight: 1, color: "var(--main-font-color, inherit)"}}>Oops!</p>
          <p style={{textAlign:"center",fontSize: 24, padding: "0 10%", lineHeight: 1, color: "var(--main-font-color, inherit)"}}>{this.state.errorText}</p>
          {!!this.state.showButton && <Button round size="sm" style={{textAlign: "center", margin: "3%"}} onClick={() => browserHistory.push(channelPathGenerator(''))}>{localization.get('go_home')} </Button>}
        </div>
        {!!this.state.channel && <Footer {...this.props} channel={this.state.channel}/>}
      </div>
    )}
}


export default NotFoundPage;