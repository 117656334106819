import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import ViewContainer from "../../../components/Letflow/ViewContainer/ViewContainer";
import localization from "../../../config/localization";
import {
  channelPathGenerator, 
  getActiveChannel,
  getActiveClient,
  homePathGenerator,
  setPageTitle,
  userCanCreatePlaylists,
  getStoredChannel
} from "../../../api-client/core/authentication/utils";
import HomePlaylistGridItem from "./HomePlaylistGridItem";
import HomeAddButton from "../../../components/Letflow/Button/HomeAddButton";
import FormView from "./Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {Dialog, Grid, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import browserHistory from "../../../utils/browserHistory";
import {getPropertySafe} from "../../../utils";
import TextField from "@material-ui/core/TextField/TextField";
import {Search} from "@material-ui/icons";
import qs from "qs";

class HomePlaylists extends React.Component {
  constructor(props) {
    super(props);
    let search = qs.parse(props.location.search.slice(1))
    let {own_playlist} = search

    this.state = {
      playlists: [],
      sharingPlaylist: false,
      playlistToShare: -1,
      playlistId: '',
      title: '',
      subtitle: '',
      userLibrary: '',
      visibility: '',
      allowDownload: false,
      disabled: false,
      showPlaylistCreationDialog: false,
      openUpdateDialog: false,
      searchInput: "",
      filterPlaylist: own_playlist ? "own": "all",
      brands: [],
      type: "client",
      tagGroups: [],
      selectedTags: [],
      useFilters: true,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.playlists'));
    document.getElementsByTagName('html')[0].scrollTop = 0;

    request.tagGroup.getAll({filterBy: [
        { column: 'content_creations', filter: true},
        {column: 'inheritable', filter: "true"},
        { column: 'show_on_smart_search', filter: '1'},
        {column: 'channel_id', filter: `${getActiveChannel()}`}
      ]}, 'material')
      .then(tagGroups => {
        let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
        if(getActiveChannel() && getStoredChannel().tagGroups.length) {
          getStoredChannel().tagGroups.filter(tg => tg.target === 'material').forEach(channelTg => {
            availableTagGroups.forEach(tg => {
              if (tg.id === channelTg.id) {
                tg.order = channelTg.order
              }
            })
          })
        }

      this.setState({ tagGroups: availableTagGroups.sort((a,b) => a.order - b.order)})
    })
  };

  requestPlaylistsForClient = (clientId = getActiveClient()) => {
    let qs = "include=brands;user;relatedTags"

    if (this.state.searchInput) {
      qs = qs.concat("&title="+this.state.searchInput)
    }

    if (this.state.filterPlaylist === "own") {
      qs = qs.concat("&own_playlists=true")
      browserHistory.replace(channelPathGenerator(`playlists?own_playlist=true`))
    }else {
      browserHistory.replace(channelPathGenerator(`playlists`))
    }

    return request.playlist.getAllForClient(clientId, qs)
      .then(playlists => this.setState({playlists}));
  }


  renderPlaylistCreationDialog = () => {
    const {title, subtitle, userLibrary, visibility, allowDownload, brands, type, tagGroups, selectedTags, useFilters} = this.state;

    return (
      <Dialog open={this.state.showPlaylistCreationDialog} onBackdropClick={this.onCloseForm} maxWidth="xl">
        <FormViewContainer
          title={localization.get('home_playlist.create')}
          onBack={this.onCloseForm}
          submitRequest={() => request.playlist.create({
            title,
            subtitle,
            clientId: getActiveClient(),
            userLibrary,
            visibility,
            allowDownload,
            brands,
            type,
            selectedTags,
            useFilters,
            channelId: getActiveChannel()
          })
            .then(this.onCloseForm)
            .then(() => this.requestPlaylistsForClient())
            .then(() => this.forceUpdate())
          }
          style={{maxWidth: "600px", width: "100%", display: "table"}}
          color="black"
        >
          <FormView
            title={title}
            subtitle={subtitle}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onUserLibraryChange={e => this.setState({ userLibrary: e })}
            onVisibilityLibraryChange={e => this.setState({ visibility: e })}
            userLibrary={userLibrary}
            visibility={visibility}
            allowDownload={allowDownload}
            onAllowDownloadChange={e => this.setState({ allowDownload: e })}
            brands={brands}
            onBrandsChange={e => this.setState({ brands: e })}
            type={type}
            tagGroups={tagGroups}
            selectedTags={selectedTags}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            useFilters={useFilters}
          />
        </FormViewContainer>
      </Dialog>
    )
  };

  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) { //Remove from selected / add to unselected tags
      this.setState({ selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag) }, 
        () => this.updateTagColor(selectedTag, "available"))
    }else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag);
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  }

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
      return {...tagGroup,
        tags: tagGroup.tags.map(tag => {
            if (tag.id === selectedTag) {
              return {...tag, state: value}
            }
          return tag
          })
        }
      })
    })

  onDelete = id => this.setState({playlists: this.state.playlists.filter(playlist => playlist.id !== id)});

  onEdit = playlist => {
    const selectedTags = playlist.relatedTags.map(tag => tag.id);
    this.setState({
      openUpdateDialog: true,
      playlistId: playlist.id,
      title: playlist.title,
      subtitle: playlist.subtitle,
      visibility: playlist.visibility === 'public',
      userLibrary: playlist.user_library,
      selectedClientIds: getPropertySafe(playlist, 'clients', []).map(x => x.id),
      allowDownload: playlist.allow_download,
      brands: playlist.brands.map(brand => ({value: brand.id, label: brand.name})),
      type: playlist.type,
      selectedTags: selectedTags,
      tagGroups: this.state.tagGroups.map(tagGroup => ({...tagGroup, tags: tagGroup.tags.map(tag => ({...tag, state: selectedTags.includes(tag.id) ? "selected" : "available"}))})),
      useFilters: playlist.use_filters
    });
  }


  openCreatePlaylist = () => this.setState({showPlaylistCreationDialog: true})

  onCloseForm = () => this.setState({
      showPlaylistCreationDialog: false,
      openUpdateDialog: false, 
      title: "", 
      subtitle: "",
      userLibrary: "",
      visibility: "",
      allowDownload: false,
      brands: [],
      type: "client",
      selectedTags: [],
      tagGroups: this.state.tagGroups.map(tagGroup => ({...tagGroup, tags: tagGroup.tags.map(tag => ({...tag, state: "available"}))})),
      useFilters: true,
    });

  makeUpdateDialog = () =>
    <Dialog open={this.state.openUpdateDialog} onBackdropClick={this.onCloseForm} maxWidth="xl">
      <FormViewContainer
        title={localization.get('home_playlist.update')}
        onBack={this.onCloseForm}
        submitRequest={this.requestUpdate}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
        style={{maxWidth: "600px", width: "100%", display: "table"}}
        color="black"
        smallExtraSpace={true}
      >
        <FormView
          title={this.state.title}
          subtitle={this.state.subtitle}
          onChange={e => this.setState({ [e.target.id]: e.target.value })}
          disabled={this.state.disabled}
          userLibrary={this.state.userLibrary}
          onUserLibraryChange={e => this.setState({ userLibrary: e })}
          visibility={this.state.visibility}
          onVisibilityLibraryChange={e => this.setState({ visibility: e })}
          allowDownload={this.state.allowDownload}
          onAllowDownloadChange={e => this.setState({ allowDownload: e })}
          brands={this.state.brands}
          onBrandsChange={e => this.setState({ brands: e })}
          updating={true}
          type={this.state.type}
          tagGroups={this.state.tagGroups}
          selectedTags={this.state.selectedTags}
          onSelectedTagsChanged={this.onSelectedTagsChanged}
          useFilters={this.state.useFilters}
        />
      </FormViewContainer>
    </Dialog>

  requestUpdate = () => {
    const { title, subtitle, userLibrary, visibility, playlistId, allowDownload, brands, selectedTags, useFilters } = this.state;
    return request.playlist.update({
      playlistId: playlistId,
      title,
      subtitle,
      userLibrary,
      clientId: getActiveClient(),
      visibility,
      allowDownload,
      brands,
      selectedTags,
      useFilters,
    })
      .then(() => this.requestPlaylistsForClient())
      .then(this.onCloseForm)
  };

  render() {
    const {playlists} = this.state;
    return (
      <ViewContainer
        ref="viewContainer"
        initializationRequest={() => this.requestPlaylistsForClient(getActiveClient())}
      >
        <div id="innerinner" className="home-playlists">
          {userCanCreatePlaylists() && <HomeAddButton onClick={this.openCreatePlaylist}/>}
            <div data-max-per-row={4} className="category">
              <div style={{marginLeft: window.innerWidth < 600 ? 20 : 30,marginBottom: 10, marginTop: 40, marginRight: 10, position: "relative", float: "left", display: "flex"}}>
                <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false,
                    style:{
                      color: "var(--main-font-color, black)",
                      borderBottom: `2px solid var(--main-font-color, black)`,
                      width: 250,
                      maxWidth: 250,
                    },
                  }}
                  InputProps={{disableUnderline: true}}
                  autoComplete={false}
                  className="smart-search-input-search"
                  placeholder={localization.get("home.smart_search.search_playlist")}
                  value={this.state.searchInput}
                  onChange={e => this.setState({searchInput: e.target.value})}
                  onKeyPress={e => {
                    if (e.key === 'Enter')  {
                      this.requestPlaylistsForClient()
                    }}}
                  style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
                />
                {window.innerWidth < 600 &&
                  <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}} onClick={this.requestPlaylistsForClient}/>
                }
              <RadioGroup
                name="filterPlaylist"
                value={this.state.filterPlaylist}
                onChange={e => this.setState({filterPlaylist: e.target.value}, this.requestPlaylistsForClient)}
                style={{ position: "relative", flexDirection: "row", marginLeft: 20, color: "var(--main-font-color, black)"}}
              >
                <FormControlLabel value="all" control={<Radio sx={{color:'white'}} />} style={{color: "var(--main-font-color, black)"}} label={<span style={{color: "var(--main-font-color, black)"}}>{localization.get("playlists.all")}</span>}/>
                <FormControlLabel value="own" control={<Radio sx={{color:'white'}} />} style={{color: "var(--main-font-color, black)"}}  label={<span style={{color: "var(--main-font-color, black)"}}>{localization.get("playlists.own")}</span>}/>
              </RadioGroup>
              </div>
              {playlists.length > 0 ?
                <Grid  container>
                  {playlists.map((playlist, key) => {
                    return (
                      <Grid key={key} xs={6} sm={6} md={4} lg={3} xl={3} item>
                        <HomePlaylistGridItem
                          playlist={playlist}
                          onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`playlist/${playlist.id}`) : homePathGenerator(`playlist/${playlist.id}`))}
                          onDelete={this.onDelete}
                          onEdit={this.onEdit}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
                :
                <div
                  style={{
                    float: "left",
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "50px",
                    fontSize: "20px",
                    padding: "40px"
                  }}
                >{localization.get('playlists.empty')}</div>
              }
            </div>
        </div>
        {this.renderPlaylistCreationDialog()}
        {this.makeUpdateDialog()}
      </ViewContainer>
    )
  }
}

export default HomePlaylists;
