import {isNullOrUndefined} from "util";
import {slugify} from "../authentication/utils";

export const makeFormData = ({
  translations,
  image,
  allowAudio,
  allowImage,
  allowVideo,
  allowBinary,
  allowLink,
  allowEmbed,
  creating,
  limit,
  isTalent,
  visibility,
  clientId,
  type,
  synonyms,
                               downloadable,
}) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name);
    })
  }

  if (creating) form.append('slug', slugify(translations.filter(translation => translation.language == 'en')[0].name));
  if (image) form.append('image', image, image.name);
  if (!isNullOrUndefined(allowAudio)) form.append('allow_audio', allowAudio ? 1 : 0)
  if (!isNullOrUndefined(allowImage)) form.append('allow_image', allowImage ? 1 : 0)
  if (!isNullOrUndefined(allowVideo)) form.append('allow_video', allowVideo ? 1 : 0)
  if (!isNullOrUndefined(allowBinary)) form.append('allow_binary', allowBinary ? 1 : 0)
  if (!isNullOrUndefined(allowLink))  form.append('allow_link', allowLink ? 1 : 0)
  if (!isNullOrUndefined(allowEmbed))  form.append('allow_embed', allowEmbed ? 1 : 0)
  isNullOrUndefined(limit) ? form.append('limit', '') : form.append('limit', limit)
  if (!isNullOrUndefined(isTalent)) form.append('is_talent', isTalent ? 1 : 0)
  if (visibility) form.append('visibility', visibility)
  if (clientId) form.append('client_id', clientId)
  if (type) form.append('type', type)
  if (!isNullOrUndefined(synonyms)) form.append('synonyms', synonyms)
  if (!isNullOrUndefined(downloadable)) form.append('downloadable', downloadable ? 1: 0)

  return form
};

export const root = '/contentCreatorTypes';

export const includeQuery = 'include=translations;image;client';
