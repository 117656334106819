import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import Thread from "./Thread";
import Create from "./Create";
import Online from "./Online";
import ProjectsListing from "../../layouts/Home/Projects/ProjectsListing";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveSection, getIsDomainChannel,
  slugify
} from "../../api-client/core/authentication/utils";

const Routes = props => {
  const clientId = props.clientId;
  const parseRouting = path => {
    let pathname = path
    if(getActiveSection()) pathname = `/home/${getActiveSection().id}-${getActiveSection().name}` + pathname
    pathname = getIsDomainChannel() ? pathname : '/channel/:channelId-:channelName' + pathname;

    return pathname;
  }
  return (
    <Router {...props}>
      <Switch>
        <Route
          path={getActiveChannel() ? parseRouting("/projects/online") : "/home/:clientId-:clientName/projects/online"}
          render={() => (
            <Online
              hideClientSelection
              threadUrl={pitchId =>
                getActiveChannel() ? channelPathGenerator(`/projects/${pitchId}/thread`) :`/home/${clientId}/projects/${pitchId}/thread`
              }
            />
          )}
        />
        <Route
          path={getActiveChannel() ? parseRouting("/projects/create") : "/home/:clientId-:clientName/projects/create"}
          render={() => (
            <Create
              onlyForActiveClient
              makeCreatedPitchPath={projectId =>
                getActiveChannel() ? channelPathGenerator(`/projects/${projectId}/thread`) : `/home/${clientId}-${slugify(props.client.name)}/projects/${projectId}/thread`
              }
              client={props.client}
            />
          )}
        />
        <Route
          path={getActiveChannel() ? parseRouting("/projects/:projectId/thread") : "/home/:clientId-:clientName/projects/:projectId/thread"}
          render={props => (
            <Thread
              pitchId={props.match.params.projectId}
              onlineVideosPath={({ link, pitchId }) => {
                const url = getActiveChannel() ? channelPathGenerator(`/projects/online?`) : `/home/${clientId}/projects/online?`;
                const qps = [];

                if (link) {
                  const splitedLink = link.split("/");
                  const linkPath = splitedLink[splitedLink.length - 1];
                  qps.push(`link=${linkPath}`);
                }

                if (pitchId) {
                  qps.push(`pitch_id=${pitchId}`);
                }

                qps.push(`client_id=${clientId}`);

                return url + qps.join("&");
              }}
            />
          )}
        />
        <Route render={() => <ProjectsListing/>} />

        {/*<All
          onlyForActiveClient
          onlineVideosUrl={`/home/${clientId}/projects/online?client_id=${clientId}`}
          threadUrl={projectId =>
            `/home/${clientId}/projects/${projectId}/thread`
          }
          createUrl={`/home/${clientId}/projects/create`}
        />*/}

      </Switch>
    </Router>
  );
};

export default Routes;
