import React, {Component} from "react";
import {getContentFromMaterial, getMaterialType, messageIsMaterial} from ".";
import {CloudDownload, Delete, Done, Share} from "@material-ui/icons";
import {IconButton, Tooltip} from "@material-ui/core";
import ShareDialog from "../../../../components/Letflow/ShareDialog";
import {SetOnlineDialog} from "./SetOnlineDialog";
import {request} from "../../../../api-client";
import GlobalSnackbar from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import {getStoredUser} from "../../../../api-client/core/authentication/utils";
import localization from "../../../../config/localization";
import GlobalConfirmationDialog from "../../../../components/Letflow/Dialog/GlobalConfirmationDialog";

class MessageOptions extends Component {
  state = {
    setOnlineDialog: null,
    shareDialog: null
  };

  onSetOnline = () =>
    this.setState({
      setOnlineDialog: (
        <SetOnlineDialog
          onlineVideosPath={this.props.onlineVideosPath({
            link: getContentFromMaterial(this.props.message),

          })}
          type={getMaterialType(this.props.message)}
          pitchId={this.props.pitchId}
          clients={this.props.clients}
          message={this.props.message}
          onClose={() => this.setState({ setOnlineDialog: null })}
        />
      )
    });

  onShare = () =>
    this.setState({
      shareDialog: (
        <div style={{ display: "inline-block" }}>
          <ShareDialog
            handleClose={() => this.setState({ shareDialog: null })}
            pitchId={this.props.pitchId}
            id={this.props.message.id}
            entity={"material"}
            show={true}
          />
        </div>
      )
    });

  onDelete = () => {
    GlobalConfirmationDialog.show({
      title: localization.get("message.delete_confirmation_title"),
      content: localization.get("message.delete_confirmation_content"),
      request: () =>
        request.pitch
          .deleteMessage(this.props.message.id)
          .then(() => this.props.onDelete(this.props.message))
          .catch(() => GlobalSnackbar.showGenericError())
    });
  };

  getButtons = () => {
    const { message, onlineVideos, isAdministrator } = this.props;

    const buttons = [];

    const showApproveButton =
      isAdministrator &&
      messageIsMaterial(message) &&
      (getMaterialType(message) === "video" ||
        getMaterialType(message) === "audio") &&
      !onlineVideos.some(x => getContentFromMaterial(message) === x);

    const showShareButton =
      messageIsMaterial(message) &&
      ["audio", "video", "other", "image"].includes(getMaterialType(message));

    const showDownloadButton = showShareButton;

    const showDeleteButton =
      message.user && message.user.id === getStoredUser().id;

    if (showApproveButton) {
      buttons.push(
        <Tooltip title={localization.get("approve")}>
          <IconButton
            style={{ width: 25, height: 25 }}
            onClick={this.onSetOnline}
          >
            <Done style={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      );
    }

    if (showShareButton) {
      buttons.push(
        <Tooltip title={localization.get("share")}>
          <IconButton style={{ width: 25, height: 25 }} onClick={this.onShare}>
            <Share style={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      );
    }

    if (showDownloadButton) {
      buttons.push(
        <Tooltip title={localization.get("download")}>
          <a
            href={request.pitch.downloadMaterialLink(
              getContentFromMaterial(message)
            )}
            download
          >
            <IconButton style={{ width: 25, height: 25 }}>
              <CloudDownload style={{ fontSize: 16 }} />
            </IconButton>
          </a>
        </Tooltip>
      );
    }

    if (showDeleteButton) {
      buttons.push(
        <Tooltip title={localization.get("delete")}>
          <IconButton style={{ width: 25, height: 25 }} onClick={this.onDelete}>
            <Delete style={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      );
    }

    return buttons;
  };

  render = () => {
    const buttons = this.getButtons();

    if (buttons.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          style={{
            padding: 3,
            borderRadius: 10,
            borderStyle: "solid",
            borderWidth: 1,
            position: "absolute",
            right: 0,
            top: -7,
            display: !this.props.show ? "none" : "inline-block"
          }}
        >
          {buttons}
        </div>
        {this.state.setOnlineDialog}
        {this.state.shareDialog}
      </React.Fragment>
    );
  };
}

export default MessageOptions;
