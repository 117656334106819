import React, {Component} from "react";
import {request} from "../../../api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from '../../../config/localization'
import {getUpdateFormImageUrlFromItem,  makeUrlWithTableParams} from "../../../utils";
import CustomizationForm from "./form"
import {IconButton, Tooltip} from "@material-ui/core";
import {addHttpsToUrl,  slugify} from "../../../api-client/core/authentication/utils";
import { Home, Settings } from "@material-ui/icons";
import browserHistory from "../../../utils/browserHistory";
import {Edit, LiveTv} from "@material-ui/icons";
import {isNullOrUndefined} from "util";
import {get} from "lodash"
import LiveStreams from "../LiveStreams";

const fonts = [
  { value: 'avenir-font', label: "Avenir" },
  { value: 'baskerville-font', label: "Baskerville" },
  { value: 'bodoni-font', label: "Bodoni" },
  { value: 'boing-font', label: "Boing regular" },
  { value: 'calluna-font', label: "Calluna regular" },
  { value: 'encode-font', label: "Encode Sans" },
  { value: 'futura_pt-font', label: "Futura PT" },
  { value: 'futura_pt_book-font', label: "Futura PT Book" },
  { value: 'gotham_medium-font', label: "Gotham Regular" },
  { value: 'gotham-font', label: "Gotham Rounded" },
  { value: 'helvetica-font', label: "Helvetica neue" },
  { value: 'knockout-sumo-font', label: "Knockout Sumo" },
  { value: 'malba-font', label: "Malba" },
  { value: 'playfair-font', label: "Playfair" },
  { value: 'playfair_bold-font', label: "Playfair Bold" },
  { value: 'plex-font', label: "Plex" },
  { value: 'roboto-font', label: "Roboto" },
  { value: 'syne-font', label: "Syne regular" },
  { value: 'syne-bold-font', label: "Syne Bold" },
  { value: 'syne-extra-bold-font', label: "Syne Extra Bold" },
  { value: 'morganite-medium-font', label: "Morganite Medium" },
  { value: 'monument-extended-ultrabold-font', label: "Monument Extended Ultrabold" },
  { value: 'monument-extended-regular-font', label: "Monument Extended Regular" },
  { value: 'heebo-font', label: "Heebo Latin" },
  { value: 'merriweather-font', label: "Merriweather" },
  { value: 'exo-font', label: "Exo" },
  { value: 'bungee-font', label: "Bungee" },
  { value: 'galano-grotesque-bold-font', label: "Galano Grotesque Bold" },
  { value: 'galano_grotesque-font', label: "Galano Grotesque Regular" },
  { value: 'unbounded-font', label: "Unbounded" },
  { value: 'league_spartan-font', label: "League Spartan" },
  { value: "lust_regular-font", label: "Lust Regular" },
  { value: "dinn_next_lt_bold-font", label: "Dinn next LT Bold" },
  { value: "dinn_next_lt_regular-font", label: "Dinn next LT Regular" },
  { value: "ralaway_bold-font", label: "Ralaway Bold" },
  { value: "ralaway_italic-font", label: "Ralaway Italic" },
  { value: "sf-font", label: "SF Pro" },
  { value: "sf-rounded-font", label: "SF Pro Rounded" },
  { value: "inter-font", label: "Inter" },
  { value: "soho-heavy-font", label: "Soho heavy" },
  { value: "collegiate-font", label: "Collegiate" },
  { value: "akzidenz-grotesk-font", label: "Akzidenz Grotesk" },
];

const icons = [
  {value: "star", label: "Estrella"},
  {value: "heart", label: "Corazon"},
  {value: "list", label: "Lista"}
]


const favoriteTemplates = [
  {value: "x4", label: "X4"},
  {value: "featured2", label: localization.get('form.featured_up')},
  {value: "featured2_alternative", label: localization.get('form.featured_down')},
  {value: "movie", label: localization.get('form.movieView')},
  {value: "movie_alternative", label: localization.get('form.movieView.alternative')}
]

export default class extends Component {
  state = {
    id: "",
    channel: null,
    titleColor: "rgba(0, 0, 0, 1)",
    titleSize: 27,
    titleFont:  {value: 'roboto-font', label: "Roboto"},
    titleWeight: {value: 'default', label: localization.get("form.font_weight.default")},
    containerTitleColor: "rgba(0, 0, 0, 1)",
    containerTitleColorHover: "rgba(255, 255, 255, 1)",
    containerTitleSize: 20,
    containerTitleWeight: {value: 'default', label: localization.get("form.font_weight.default")},
    containerDescriptionColor: "rgba(0, 0, 0, 1)",
    containerDescriptionColorHover: "rgba(255, 255, 255, 1)",
    containerDescriptionSize: 16,
    containerDescriptionWeight: {value: 'default', label: localization.get("form.font_weight.default")},
    liveButtonColor: "rgba(0, 0, 0, 1)",
    liveButtonIcon: true,
    liveButtonShape: "rounded",
    radioButtonColor: "rgba(0, 0, 0, 1)",
    radioButtonIcon: true,
    radioButtonShape: "rounded",
    textFont:  {value: 'roboto-font', label: "Roboto"},
    favIcon: {value: "star", label: "Estrella"},
    interactionColor: 'rgba(0, 0, 0, 1)',
    elementBackground: 'rgba(244, 244, 244, 1)',
    showMoreButton: 'rounded',
    mainFont: {value: 'roboto-font', label: "Roboto"},
    mainColor: 'rgba(255, 255, 255, 1)',
    mainFontColor: "rgba(0, 0, 0, 1)",
    secondaryColor: 'rgba(255, 255, 255, 1)',
    secondaryFontColor:  'rgba(0, 0, 0, 1)',
    footerColor:  'rgba(30, 30, 30, 1)',
    footerFontColor:  'rgba(255, 255, 255, 1)',
    navbarButtonSize:  16,
    contentCreatorTitleColor: "rgba(255, 255, 255, 1)",
    contentCreatorDescriptionColor: "rgba(255, 255, 255, 1)",
    contentCreatorCreationColor: "rgb(0,0,0, 1)",
    contentCreatorCardColor: "rgba(255, 255, 255, 0.4)",
    contentCreatorCardShape: {value: "rounded", label: localization.get('rounded')},
    contentCreatorCreationInverted: "down",
    contentCreatorShowBox: "show",
    contentCreatorShowCreationBorder: "show",
    contentCreatorCreationCarouselBackgroundColor: 'rgba(171, 171, 171, 1)',
    contentCreatorBackgroundColor: "none",
    useContentCreatorBackgroundColor: false,
    useContentCreatorBackgroundGradient: 1,
    contentCreatorCreationSubtitleMargin: 10,
    contentCreatorCreationSubtitleFontSize: 20,
    navbarFontColor: "rgb(0,0,0, 1)",
    editorialBackgroundColor: "rgb(255,255,255, 1)",
    editorialTitleColor: "rgb(0,0,0, 1)",
    contentCreatorEventsPosition: "top",
    contentCreatorBuyButtonPosition: {value: "both", label: localization.get('content_creator.buy_button.both')},
    contentCreatorBuyButtonBackgroundColor: "rgb(0,0,0,1)", 
    contentCreatorBuyButtonFontColor: "rgb(255,255,255,1)",
    useCanonicals: "0",
    useBlurOnLogin: false,
    mobileFontResize: 100,
    editorialHideCoverImage: false,
    favoriteTemplate: {value: "x4", label: localization.get('X4')},
    navbarCenterMode: "0",
    subNavbarFontColor: "rgb(255,255,255,1)",
    subNavbarBackgroundColor: "rgb(0,0,0,1)",
    showNavbarMenu: false,
    sharePosition: "top",
    socialFill: false,
    contentCreatorSocialPosition: "default",
    contentCreatorShowMoreButton: "use",
    homePadding: "70",
    sidebarFontColor: "rgba(0,0,0,1)",
    sidebarBackgroundColor: "rgb(255,255,255,1)",
    sidebarButtonFontColor: "rgb(255,255,255,1)",
    sidebarButtonBackgroundColor: "rgba(0,0,0,1)",
    sidebarSelectorFontColor: "rgba(0,0,0,1)",
    sidebarSelectorBackgroundColor: "rgb(255,255,255,1)",
    contentCreatorEventButtonColor: "rgba(0,0,0,1)",
    contentCreatorEventButtonBackgroundColor: "rgba(255,255,255,1)",
    contentCreatorEventButtonText: "eventos",
    contentCreatorEditorialUseSynopsis: false, 
    floatingButtonBackgroundColor: "rgb(255,255,255,1)",
    floatingButtonBorderColor: "rgba(0,0,0,1)",
    creationHideCreator: false,
    navbarShadow: "0 1px 10px 0 rgba(0,0,0,.1)"
  };

  getWeights = () => {
    return [
      {value: 'default', label: localization.get('form.font_weight.default')},
      {value: 'bold', label: localization.get('form.font_weight.bold')},
      {value: 'normal', label: localization.get('form.font_weight.normal')},
    ]
  }
  requestChannel = () =>
    request.channel.getForDashboard(this.props.match.params.id, "include=domain;client")
      .then(channel => {

        const hasContentCreatorBackgroundColor = channel.preferences.content_creator && channel.preferences.content_creator.background_color && channel.preferences.content_creator.background_color != 'none';
        this.setState({
          id: channel.id,
          channel: channel,
          titleColor: channel.preferences.title.color,
          titleSize: channel.preferences.title.size.match(/\d+/).toString(),
          titleFont: fonts.find(font => font.value  == channel.preferences.title.font),
          titleWeight: this.getWeights().find(weight => weight.value  == channel.preferences.title.weight) || this.state.titleWeight,
          containerTitleColor: channel.preferences.container_title.color,
          containerTitleColorHover: channel.preferences.container_title.color_hover,
          containerTitleSize: channel.preferences.container_title.size.match(/\d+/).toString(),
          containerDescriptionColor: channel.preferences.container_description.color,
          containerTitleWeight: this.getWeights().find(weight => weight.value  == channel.preferences.container_title.weight) || this.state.containerTitleWeight,
          containerDescriptionColorHover: channel.preferences.container_description.color_hover,
          containerDescriptionSize: channel.preferences.container_description.size.match(/\d+/).toString(),
          containerDescriptionWeight: this.getWeights().find(weight => weight.value  == channel.preferences.container_description.weight) || this.state.containerDescriptionWeight,
          liveButtonColor: channel.preferences.live_button.color,
          liveButtonIcon: channel.preferences.live_button.icon,
          liveButtonShape: channel.preferences.live_button.shape,
          radioButtonColor: channel.preferences.radio_button ? channel.preferences.radio_button.color : "rgba(0, 0, 0, 1)",
          radioButtonIcon: channel.preferences.radio_button ? channel.preferences.radio_button.icon : true,
          radioButtonShape: channel.preferences.radio_button ? channel.preferences.radio_button.shape : "rounded",
          textFont: fonts.find(font => font.value  == channel.preferences.text_font),
          favIcon: icons.find(icon => icon.value  == channel.preferences.fav_icon),
          interactionColor: channel.preferences.interaction_color,
          elementBackground: channel.preferences.element_background,
          showMoreButton: channel.preferences.show_more_button,
          mainFont: fonts.find(font => font.value  == channel.preferences.main_font) || {value: 'roboto-font', label: "Roboto"},
          mainColor: channel.preferences.main_color || 'rgba(255, 255, 255, 1)',
          mainFontColor: channel.preferences.main_font_color || 'rgba(0, 0, 0, 1)',
          secondaryColor: channel.preferences.secondary_color || 'rgba(255, 255, 255, 1)',
          secondaryFontColor: channel.preferences.secondary_font_color || 'rgba(0, 0, 0, 1)',
          footerColor: channel.preferences.footer_color || 'rgba(30, 30, 30, 1)',
          footerFontColor: channel.preferences.footer_font_color || 'rgba(255, 255, 255, 1)',
          navbarButtonSize: channel.preferences.navbar_button_size ? channel.preferences.navbar_button_size.match(/\d+/).toString() : '16',
          useContentCreatorBackgroundColor: hasContentCreatorBackgroundColor,
          contentCreatorBackgroundColor: hasContentCreatorBackgroundColor ? channel.preferences.content_creator.background_color : "none",
          useContentCreatorBackgroundGradient: (channel.preferences && channel.preferences.content_creator && channel.preferences.content_creator.background_gradient == 0) ? 0 : 1,
          contentCreatorCreationSubtitleFontSize: (channel.preferences && channel.preferences.content_creator && channel.preferences.content_creator.creation && channel.preferences.content_creator.creation.subtitles) ? channel.preferences.content_creator.creation.subtitles.font_size : 20,
          contentCreatorCreationSubtitleMargin: (channel.preferences && channel.preferences.content_creator && channel.preferences.content_creator.creation && channel.preferences.content_creator.creation.subtitles) ? channel.preferences.content_creator.creation.subtitles.margin : 10,
          contentCreatorTitleColor: channel.preferences.content_creator ? channel.preferences.content_creator.title.color : "rgba(255, 255, 255, 1)",
          contentCreatorDescriptionColor: channel.preferences.content_creator ? channel.preferences.content_creator.description.color : "rgba(255, 255, 255, 1)",
          contentCreatorCreationColor: channel.preferences.content_creator ? channel.preferences.content_creator.creation.color : "rgb(0,0,0, 1)",
          contentCreatorCardColor: channel.preferences.content_creator ? channel.preferences.content_creator.card.color : "rgba(255, 255, 255, 0.4)",
          contentCreatorCardShape: channel.preferences.content_creator ?
            {value: channel.preferences.content_creator.card.shape , label: localization.get(channel.preferences.content_creator.card.shape )} :
            {value: "rounded", label: localization.get('rounded')},
          contentCreatorCreationInverted: channel.preferences.content_creator ? (channel.preferences.content_creator.creation.inverted ? "up" : "down") : "down",
          contentCreatorShowBox: channel.preferences.content_creator && channel.preferences.content_creator ?
            (channel.preferences.content_creator.card.color == "rgba(255, 255, 255, 0)"? "hide" : "show") : "show",
          contentCreatorShowCreationBorder: (channel.preferences.content_creator && channel.preferences.content_creator.creation) ? (channel.preferences.content_creator.creation.carousel_background_color == 'transparent' ? 'hide' : 'show') : 'show',
          contentCreatorCreationCarouselBackgroundColor: (channel.preferences.content_creator && channel.preferences.content_creator.creation && channel.preferences.content_creator.creation.carousel_background_color && channel.preferences.content_creator.creation.carousel_background_color != 'transparent') ? channel.preferences.content_creator.creation.carousel_background_color : 'rgba(171, 171, 171, 1)',
          navbarFontColor: channel.preferences.navbar ? channel.preferences.navbar.font_color : "rgb(0,0,0, 1)",
          editorialBackgroundColor: (channel.preferences.content_creator && channel.preferences.content_creator.editorial && channel.preferences.content_creator.editorial.background_color)? channel.preferences.content_creator.editorial.background_color:"rgb(255,255,255, 1)",
          editorialTitleColor: (channel.preferences.content_creator && channel.preferences.content_creator.editorial && channel.preferences.content_creator.editorial.title && channel.preferences.content_creator.editorial.title.color)? channel.preferences.content_creator.editorial.title.color:"rgb(0,0,0, 1)",
          editorialHideCoverImage: get(channel, "preferences.content_creator.editorial.hide_cover_image", false),
          contentCreatorEventsPosition: get(channel, "preferences.content_creator.editorial.events.position", "top"),
          contentCreatorBuyButtonPosition: channel.preferences.content_creator && channel.preferences.content_creator.buy_button ?
            {value: channel.preferences.content_creator.buy_button.position, label: localization.get(`content_creator.buy_button.${channel.preferences.content_creator.buy_button.position}`)} :
            {value: "both", label: localization.get('content_creator.buy_button.both')},
          contentCreatorBuyButtonBackgroundColor: (channel.preferences.content_creator && channel.preferences.content_creator.buy_button) ? channel.preferences.content_creator.buy_button.bg_color : "rgb(0,0,0,1)",
          contentCreatorBuyButtonFontColor: (channel.preferences.content_creator && channel.preferences.content_creator.buy_button) ? channel.preferences.content_creator.buy_button.font_color : "rgb(255,255,255,1)",
          useCanonicals: channel.use_canonicals == "1" ? "1" : "0",
          useBlurOnLogin: !isNullOrUndefined(channel.preferences.use_blur_on_login) ? channel.preferences.use_blur_on_login : true,
          mobileFontResize: channel.preferences.mobile_font_resize ? channel.preferences.mobile_font_resize : 100,
          favoriteTemplate: channel.preferences.favorite_template ? favoriteTemplates.find(template => template.value == channel.preferences.favorite_template) : {value: "x4", label: localization.get('X4')},
          navbarCenterMode: get(channel, "preferences.navbar.center_mode", false) ? "1" : "0",
          subNavbarFontColor: get(channel, "preferences.sub_navbar.font_color", "rgba(0,0,0,1)"),
          subNavbarBackgroundColor:  get(channel, "preferences.sub_navbar.background_color", "rgba(255,255,255,1)"),
          showNavbarMenu: get(channel, "preferences.navbar.show_menu", true),
          sharePosition: get(channel, "preferences.content_creator.editorial.share_position", "top"),
          socialFill: get(channel, "preferences.content_creator.editorial.social_fill", false),
          contentCreatorSocialPosition: get(channel, "preferences.content_creator.social_position", "default"),
          contentCreatorShowMoreButton: get(channel, "preferences.content_creator.show_more_button", "use"),
          homePadding: get(channel, "preferences.home_padding", "70"),
          sidebarFontColor: get(channel, "preferences.navbar.sidebar.font_color", "rgba(0,0,0,1)"),
          sidebarBackgroundColor: get(channel, "preferences.navbar.sidebar.background_color", "rgb(255,255,255,1)"),
          sidebarButtonFontColor: get(channel, "preferences.navbar.sidebar.buttons.font_color", "rgb(255,255,255,1)"),
          sidebarButtonBackgroundColor: get(channel, "preferences.navbar.sidebar.buttons.background_color", "rgba(0,0,0,1)"),
          sidebarSelectorFontColor: get(channel, "preferences.navbar.sidebar.selectors.font_color", "rgba(0,0,0,1)"),
          sidebarSelectorBackgroundColor: get(channel, "preferences.navbar.sidebar.selectors.background_color", "rgb(255,255,255,1)"),
          contentCreatorEventButtonColor: get(channel, "preferences.content_creator.editorial.events.button.color", "rgb(0,0,0,1)"),
          contentCreatorEventButtonBackgroundColor:  get(channel, "preferences.content_creator.editorial.events.button.background_color", "rgb(255,255,255,1)"),
          contentCreatorEventButtonText:  get(channel, "preferences.content_creator.editorial.events.button.text", "eventos"),
          contentCreatorEditorialUseSynopsis: get(channel, "preferences.content_creator.editorial.use_synopsis", false),
          floatingButtonBackgroundColor: get(channel, "preferences.floating_button.background_color", "rgb(255,255,255,1)"),
          floatingButtonBorderColor: get(channel, "preferences.floating_button.border_color", "rgba(0,0,0,1)"),
          creationHideCreator: get(channel, "preferences.hide_creator", false),
          navbarShadow: get(channel, "preferences.navbar.shadow", "0 1px 10px 0 rgba(0,0,0,.1)")
        });
      })

  requestChannelCreation = () => {
    return request.channel.updatePreferences(this.props.match.params.id, {
      titleColor: this.state.titleColor,
      titleSize: this.state.titleSize,
      titleFont: this.state.titleFont.value,
      titleWeight: this.state.titleWeight.value,
      containerTitleColor: this.state.containerTitleColor,
      containerTitleColorHover: this.state.containerTitleColorHover,
      containerTitleSize: this.state.containerTitleSize,
      containerTitleWeight: this.state.containerTitleWeight.value,
      containerDescriptionColor: this.state.containerDescriptionColor,
      containerDescriptionColorHover: this.state.containerDescriptionColorHover,
      containerDescriptionSize: this.state.containerDescriptionSize,
      containerDescriptionWeight: this.state.containerDescriptionWeight.value,
      liveButtonColor: this.state.liveButtonColor,
      liveButtonIcon: this.state.liveButtonIcon,
      liveButtonShape: this.state.liveButtonShape,
      radioButtonColor: this.state.radioButtonColor,
      radioButtonIcon: this.state.radioButtonIcon,
      radioButtonShape: this.state.radioButtonShape,
      textFont: this.state.textFont.value,
      favIcon: this.state.favIcon.value,
      interactionColor: this.state.interactionColor,
      elementBackground: this.state.elementBackground,
      showMoreButton: this.state.showMoreButton,
      mainFont: this.state.mainFont.value,
      mainColor: this.state.mainColor,
      mainFontColor: this.state.mainFontColor,
      secondaryColor: this.state.secondaryColor,
      secondaryFontColor: this.state.secondaryFontColor,
      footerColor: this.state.footerColor,
      footerFontColor: this.state.footerFontColor,
      navbarButtonSize: this.state.navbarButtonSize,
      contentCreatorTitleColor: this.state.contentCreatorTitleColor,
      contentCreatorDescriptionColor: this.state.contentCreatorDescriptionColor,
      contentCreatorCreationColor: this.state.contentCreatorCreationColor,
      contentCreatorCardColor: this.state.contentCreatorCardColor,
      contentCreatorCardShape: this.state.contentCreatorCardShape.value,
      contentCreatorCreationInverted: this.state.contentCreatorCreationInverted !== "down",
      contentCreatorShowBox: this.state.contentCreatorShowBox,
      contentCreatorCreationCarouselBackgroundColor: this.state.contentCreatorShowCreationBorder == 'hide' ? 'transparent' : this.state.contentCreatorCreationCarouselBackgroundColor,
      navbarFontColor: this.state.navbarFontColor,
      editorialBackgroundColor: this.state.editorialBackgroundColor,
      editorialTitleColor: this.state.editorialTitleColor,
      contentCreatorEventsPosition: this.state.contentCreatorEventsPosition,
      contentCreatorBackgroundColor: this.state.contentCreatorBackgroundColor,
      useContentCreatorBackgroundColor: this.state.useContentCreatorBackgroundColor,
      useContentCreatorBackgroundGradient: this.state.useContentCreatorBackgroundGradient,
      contentCreatorCreationSubtitleFontSize: this.state.contentCreatorCreationSubtitleFontSize,
      contentCreatorCreationSubtitleMargin: this.state.contentCreatorCreationSubtitleMargin,
      contentCreatorBuyButtonPosition: this.state.contentCreatorBuyButtonPosition.value,
      contentCreatorBuyButtonBackgroundColor: this.state.contentCreatorBuyButtonBackgroundColor,
      contentCreatorBuyButtonFontColor: this.state.contentCreatorBuyButtonFontColor,
      useCanonicals: this.state.useCanonicals,
      useBlurOnLogin: this.state.useBlurOnLogin,
      mobileFontResize: this.state.mobileFontResize,
      editorialHideCoverImage: this.state.editorialHideCoverImage,
      favoriteTemplate: this.state.favoriteTemplate.value,
      navbarCenterMode: this.state.navbarCenterMode === "1",
      subNavbarFontColor: this.state.subNavbarFontColor,
      subNavbarBackgroundColor: this.state.subNavbarBackgroundColor,
      showNavbarMenu: this.state.showNavbarMenu,
      sharePosition: this.state.sharePosition,
      socialFill: this.state.socialFill,
      contentCreatorSocialPosition: this.state.contentCreatorSocialPosition,
      contentCreatorShowMoreButton: this.state.contentCreatorShowMoreButton,
      homePadding: this.state.homePadding,
      sidebarFontColor: this.state.sidebarFontColor,
      sidebarBackgroundColor: this.state.sidebarBackgroundColor,
      sidebarButtonFontColor: this.state.sidebarButtonFontColor,
      sidebarButtonBackgroundColor: this.state.sidebarButtonBackgroundColor,
      sidebarSelectorFontColor: this.state.sidebarSelectorFontColor,
      sidebarSelectorBackgroundColor: this.state.sidebarSelectorBackgroundColor,
      contentCreatorEventButtonColor: this.state.contentCreatorEventButtonColor,
      contentCreatorEventButtonBackgroundColor: this.state.contentCreatorEventButtonBackgroundColor,
      contentCreatorEventButtonText: this.state.contentCreatorEventButtonText,
      contentCreatorEditorialUseSynopsis: this.state.contentCreatorEditorialUseSynopsis, 
      floatingButtonBackgroundColor: this.state.floatingButtonBackgroundColor,
      floatingButtonBorderColor: this.state.floatingButtonBorderColor,
      creationHideCreator: this.state.creationHideCreator,
      navbarShadow: this.state.navbarShadow
    })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/channels")));
  }

  onChange = e => {
    let {target} = e;
    this.setState({[target.id]: target.type === 'checkbox' ? target.checked : target.value});
  };


  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  render = () => {
    const {
      channel,
      titleColor,
      titleSize,
      titleFont,
      titleWeight,
      containerTitleColor,
      containerTitleColorHover,
      containerTitleSize,
      containerTitleWeight,
      containerDescriptionColor,
      containerDescriptionColorHover,
      containerDescriptionSize,
      containerDescriptionWeight,
      liveButtonColor,
      liveButtonIcon,
      liveButtonShape,
      radioButtonColor,
      radioButtonIcon,
      radioButtonShape,
      textFont,
      favIcon,
      interactionColor,
      elementBackground,
      showMoreButton,
      mainFont,
      mainColor,
      mainFontColor,
      secondaryColor,
      secondaryFontColor,
      footerColor,
      footerFontColor,
      navbarButtonSize,
      contentCreatorTitleColor,
      contentCreatorDescriptionColor,
      contentCreatorCreationColor,
      contentCreatorCardColor,
      contentCreatorCardShape,
      contentCreatorCreationInverted,
      contentCreatorShowBox,
      contentCreatorShowCreationBorder,
      contentCreatorCreationCarouselBackgroundColor,
      navbarFontColor,
      editorialBackgroundColor,
      editorialTitleColor,
      contentCreatorEventsPosition,
      contentCreatorBackgroundColor,
      useContentCreatorBackgroundColor,
      useContentCreatorBackgroundGradient,
      contentCreatorCreationSubtitleFontSize,
      contentCreatorCreationSubtitleMargin,
      contentCreatorBuyButtonPosition,
      contentCreatorBuyButtonBackgroundColor,
      contentCreatorBuyButtonFontColor,
      useCanonicals,
      useBlurOnLogin,
      mobileFontResize,
      editorialHideCoverImage,
      favoriteTemplate,
      navbarCenterMode,
      subNavbarFontColor,
      subNavbarBackgroundColor,
      showNavbarMenu,
      sharePosition,
      socialFill,
      contentCreatorSocialPosition,
      contentCreatorShowMoreButton,
      homePadding,
      sidebarFontColor,
      sidebarBackgroundColor,
      sidebarButtonFontColor,
      sidebarButtonBackgroundColor,
      sidebarSelectorFontColor,
      sidebarSelectorBackgroundColor,
      contentCreatorEventButtonColor,
      contentCreatorEventButtonBackgroundColor,
      contentCreatorEventButtonText,
      contentCreatorEditorialUseSynopsis,
      floatingButtonBackgroundColor,
      floatingButtonBorderColor,
      creationHideCreator,
      navbarShadow
    } = this.state;

    return (
      <div>
        <LiveStreams showDialog={this.state.showLiveStreamSettings} channelId={get(this.state, "channel.id")} allowFakeLivestreams={get(this.state, "channel.client.allow_fake_livestreams")} channel={this.state.activeChannelComplete} handleCloseDialog={() => this.setState({showLiveStreamSettings: false})}/>
        <FormViewContainer
          title={localization.get("channel.update_preferences", channel ? channel.name : "")}
          submitRequest={this.requestChannelCreation}
          initializationRequest={() => this.requestChannel()}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
        >
          <div style={{position: "absolute", right: 10, top: -40}}>
            <IconButton
              onClick={() => {
                let url = !!this.state.channel.domain ? addHttpsToUrl(this.state.channel.domain.url) : `/channel/${this.state.channel.id}-${slugify(this.state.channel.name)}`;

                if(this.state.channel.language !== "es") url+=`/${this.state.channel.language}`

                return window.open(url, "_blank")
              }}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('channel.visit')}>
                <Home/>
              </Tooltip>
            </IconButton>
            <IconButton
              title={localization.get('livestreams.schedule')}
              onClick={() => this.setState({showLiveStreamSettings: true, activeChannel: this.state.channel.id, allowFakeLivestreams: get(this.state, "channel.client.allow_fake_livestreams"), activeChannelComplete: this.state.channel})}
            >
              <LiveTv />
            </IconButton>
            <IconButton
              onClick={() => browserHistory.push(`/panel/channels/${this.state.channel.id}/edit`)}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get(localization.get('edit'))}>
                <Edit />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => browserHistory.push(`/panel/channels/${this.state.channel.id}/editor`)}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('channel.editor')}>
                <Settings />
              </Tooltip>
            </IconButton>
          </div>
          <CustomizationForm
            onChange={this.onChange}
            channel={channel}
            titleColor={titleColor}
            titleSize={titleSize}
            titleFont={titleFont}
            titleWeight={titleWeight}
            containerTitleColor={containerTitleColor}
            containerTitleColorHover={containerTitleColorHover}
            containerTitleSize={containerTitleSize}
            containerTitleWeight={containerTitleWeight}
            containerDescriptionColor={containerDescriptionColor}
            containerDescriptionColorHover={containerDescriptionColorHover}
            containerDescriptionSize={containerDescriptionSize}
            containerDescriptionWeight={containerDescriptionWeight}
            liveButtonColor={liveButtonColor}
            liveButtonIcon={liveButtonIcon}
            liveButtonShape={liveButtonShape}
            radioButtonColor={radioButtonColor}
            radioButtonIcon={radioButtonIcon}
            radioButtonShape={radioButtonShape}
            textFont={textFont}
            favIcon={favIcon}
            interactionColor={interactionColor}
            elementBackground={elementBackground}
            showMoreButton={showMoreButton}
            fonts={fonts}
            icons={icons}
            weights={this.getWeights()}
            mainFont={mainFont}
            mainColor={mainColor}
            mainFontColor={mainFontColor}
            secondaryColor={secondaryColor}
            secondaryFontColor={secondaryFontColor}
            footerColor={footerColor}
            footerFontColor={footerFontColor}
            navbarButtonSize={navbarButtonSize}
            contentCreatorTitleColor={contentCreatorTitleColor}
            contentCreatorDescriptionColor={contentCreatorDescriptionColor}
            contentCreatorCreationColor={contentCreatorCreationColor}
            contentCreatorCardColor={contentCreatorCardColor}
            contentCreatorCardShape={contentCreatorCardShape}
            contentCreatorCreationInverted={contentCreatorCreationInverted}
            contentCreatorShowBox={contentCreatorShowBox}
            contentCreatorShowCreationBorder={contentCreatorShowCreationBorder}
            contentCreatorCreationCarouselBackgroundColor={contentCreatorCreationCarouselBackgroundColor}
            navbarFontColor={navbarFontColor}
            editorialBackgroundColor={editorialBackgroundColor}
            editorialTitleColor={editorialTitleColor}
            contentCreatorEventsPosition={contentCreatorEventsPosition}
            contentCreatorBackgroundColor={contentCreatorBackgroundColor}
            useContentCreatorBackgroundColor={useContentCreatorBackgroundColor}
            onUseContentCreatorBackgroundColorChange={(value) => this.setState({useContentCreatorBackgroundColor: value})}
            useContentCreatorBackgroundGradient={useContentCreatorBackgroundGradient}
            onUseContentCreatorBackgroundGradientChange={(value) => this.setState({useContentCreatorBackgroundGradient: value})}
            contentCreatorCreationSubtitleFontSize={contentCreatorCreationSubtitleFontSize}
            contentCreatorCreationSubtitleMargin={contentCreatorCreationSubtitleMargin}
            contentCreatorBuyButtonPosition={contentCreatorBuyButtonPosition}
            contentCreatorBuyButtonBackgroundColor={contentCreatorBuyButtonBackgroundColor}
            contentCreatorBuyButtonFontColor={contentCreatorBuyButtonFontColor}
            useCanonicals={useCanonicals}
            useBlurOnLogin={useBlurOnLogin}
            onChangeUseBlurOnLogin={val => this.setState({useBlurOnLogin: val})}
            mobileFontResize={mobileFontResize}
            editorialHideCoverImage={editorialHideCoverImage}
            favoriteTemplate={favoriteTemplate}
            favoriteTemplates={favoriteTemplates}
            navbarCenterMode={navbarCenterMode}
            subNavbarFontColor={subNavbarFontColor}
            subNavbarBackgroundColor={subNavbarBackgroundColor}
            showNavbarMenu={showNavbarMenu}
            sharePosition={sharePosition}
            socialFill={socialFill}
            contentCreatorSocialPosition={contentCreatorSocialPosition}
            contentCreatorShowMoreButton={contentCreatorShowMoreButton}
            homePadding={homePadding}
            sidebarFontColor={sidebarFontColor}
            sidebarBackgroundColor={sidebarBackgroundColor}
            sidebarButtonFontColor={sidebarButtonFontColor}
            sidebarButtonBackgroundColor={sidebarButtonBackgroundColor}
            sidebarSelectorFontColor={sidebarSelectorFontColor}
            sidebarSelectorBackgroundColor={sidebarSelectorBackgroundColor}
            contentCreatorEventButtonColor={contentCreatorEventButtonColor}
            contentCreatorEventButtonBackgroundColor={contentCreatorEventButtonBackgroundColor}
            contentCreatorEventButtonText={contentCreatorEventButtonText}
            contentCreatorEditorialUseSynopsis={contentCreatorEditorialUseSynopsis}
            floatingButtonBackgroundColor={floatingButtonBackgroundColor}
            floatingButtonBorderColor={floatingButtonBorderColor}
            creationHideCreator={creationHideCreator}
            navbarShadow={navbarShadow}
          />
        </FormViewContainer>
      </div>
    );
  };
}
