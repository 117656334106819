import {appendPaginationQueryParamsToUrl, get} from "../utils";

const getMusics = host => (clientId, paginationConfig) => {

  const resource = `/clients/${clientId}/musics`
  const url = appendPaginationQueryParamsToUrl(`${host}${resource}?&include=audio.waveform;tags`, {...paginationConfig, alreadyHasQueryParams: true})
  
  return get(url).then(res => res.json())
}


export default getMusics