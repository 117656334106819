import React, {Component} from "react";
import {request} from "api-client";
import UsersFormView from "./UsersFormView";
import FormViewContainer from "../../../../../components/Letflow/ViewContainer/FormViewContainer";
import {homePathGenerator, ROLES,} from "../../../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import localization from "../../../../../config/localization";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      email: props.email,
      clientId: props.match.params.clientId,
      image: { src: props.image, file: null },
      roles: [],
      roleId: props.roleId || '',
      canLicense: props.canLicense,
      language: props.language || ''
    };
  }

  requestRoles = () =>
    request.role
      .getAll()
      .then(roles => roles.filter(role => role.id != ROLES.trialId))
      .then(roles => roles.filter(role => role.type === "client"))
      .then(roles => this.setState({ roles }));

  handleSubmitRequest = () =>
    this.props.submitRequest(this.state).then(() => {
      if (this.props.type === "create") {
        GlobalConfirmationDialog.show({
          title: localization.get("user.created"),
          content: localization.get("user.email_sent", this.state.email),
          request: () => Promise.resolve(),
          confirmationLabel: localization.get("accept"),
          onClose: this.props.history.push(homePathGenerator(`account/users`)),
        });
      } else {
        this.props.history.push(homePathGenerator(`account/users`));
      }
    });
    
  onImageChange = image => this.setState({ image });

  render = () => {
    const { disabled, name, email, image, roles, roleId, canLicense, language } = this.state;

    return (
      <div>
        <FormViewContainer
          title={this.props.formTitle}
          initializationRequest={() => this.requestRoles()}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.handleSubmitRequest}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          style={{ maxWidth: "600px", width: "100%", display: "table", margin: "30px auto" }}
          color="black"
        >
          <UsersFormView
            name={name}
            email={email}
            roleId={roleId}
            roles={roles}
            image={image}
            language={language}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onImageChange={image => this.setState({ image })}
            disabled={disabled}
            canLicense={canLicense}
            onCanLicenseChange={canLicense => this.setState({ canLicense })}
          />
        </FormViewContainer>
      </div>
    );
  };
}

export default FormContainer;
