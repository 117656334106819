import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {MoreVert} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import React from "react";

class FloatingMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      tooltipTitle: this.props.tooltipTitle,
      tooltipPlacement: this.props.tooltipPlacement || 'bottom',
    }
  }

  handleToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({open: !this.state.open})
  }

  render() {
    const { open, tooltipTitle, tooltipPlacement} = this.state
    const { icon, extraClass, extraStyle, items, id} = this.props;

    const showTooltip = tooltipTitle && tooltipTitle.length > 0;
    
    const renderButton = () => (
      <div
        aria-describedby={id}
        ref={node => this.anchorEl = node}
        aria-owns={open ? "menu-list-grow" : null}
        aria-haspopup="true"
        onClick={this.handleToggle}
        className={extraClass || ''}
        style={extraStyle || {}}
      >
        {icon || <MoreVert/>}
      </div>
    );


    return (
      <>
        {showTooltip ? 
          <Tooltip title={tooltipTitle} placement={tooltipPlacement} PopperProps={{ style: { zIndex: 11000 } }}>
            {renderButton()}
          </Tooltip>
          :
          renderButton()
        }
        <Popper
          open={open}
          id={id}
          anchorEl={this.anchorEl}
          transition
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{zIndex:11000}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{transformOrigin: "center right", zIndex:10}}
            >
              <Paper style={this.props.creationPlayer ? {zIndex:10, marginLeft: 74} : {zIndex:10}}>
                <ClickAwayListener onClickAway={() => this.setState({open: false})}>
                    <MenuList style={this.props.creationPlayer ? {paddingBottom: 0, paddingTop: 0, minWidth: 224, width: 224} : {paddingBottom: 0, paddingTop: 0, minWidth: 250}}>
                      {items.map(item => 
                        <MenuItem
                            style={{cursor: "default"}}
                            onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.setState({open: false})
                        }}>
                            {item.component}
                        </MenuItem>
                      )}
                    </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }
}

export default FloatingMenu
