import React, {Component} from 'react'
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import {request} from '../../../api-client';
import localization from '../../../config/localization';

export default class ReceiveEmailsFromPitch extends Component {

  state = {
    value: null,
    subscribedToAllPitches: null
  }

  componentDidMount = () => {
    request.pitch.isSubscribedToPitch(this.props.pitchId)
      .then(data => this.setState({
        value: data.value,
        subscribedToAllPitches: data.subscribed_to_all
      }))
  }

  render = () => {

    if (this.state.value === null) {
      return null
    }

    if (this.state.subscribedToAllPitches === false) {
      return <Typography style={{paddingTop: 10, color: 'dimgray'}}>{localization.get('receive_emails_from_pitches_disabled')}</Typography>
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.value}
            onChange={() => {
              this.setState({ value: !this.state.value }, () => {
                request.pitch.subscribeToPitch(this.props.pitchId, this.state.value)
              })
            }}
            color='default'
          />
        }
        label={localization.get('receive_emails_from_pitch')}
      />
    )
  }
}