import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../../../config/localization";
import {homePathGenerator, setPageTitle} from "../../../../../api-client/core/authentication/utils";

class All extends Component {

  componentDidMount = () => {
    setPageTitle(localization.get('title.users'))
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  onAdd = () => this.props.history.push(homePathGenerator(`account/users/create`))

  render = () =>
    <AllViewContainer onAdd={this.onAdd}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default All;
