import React, {Component} from "react";
import {request} from "../../../api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import {TryAgainSnackbar} from "../../../components/Letflow/Snackbar";
import qs from "qs";
import {stateToHTML} from "draft-js-export-html";
import {EditorState, convertFromRaw, convertToRaw} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import moment from "moment-with-locales-es6";
import CreateMaterialsByLink from "./CreateMaterialsByLink";
import {getStoredUser, userIsContentSupervisor} from "../../../api-client/core/authentication/utils";
import { clearUnwantedStateStyles } from "../../../components/Letflow/RichTextEditor/utils";
import {
  Button,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton, MenuItem,
  Radio,
  RadioGroup, Select,
  Tooltip
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CustomShareContent from "../All/CustomShareContent";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelection";
import {get} from "lodash";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {
  state = {
    id: "",
    email: "",
    phone: "",
    image: { id: null, src: null, file: null },
    coverImage: { id: null, src: null, file: null },
    featuredImage: { id: null, src: null, file: null },
    mobileCoverImage: { id: null, src: null, file: null },
    disabled: false,
    selectedTags: [],
    tagGroups: [],
    country: "",
    observation: "",
    active: "1",
    contentCreations: [],
    contentCreationsOrders: [],
    selectedCatalogs: [],
    socialLinks: [],
    isDemo: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).is_demo,
    contentCreatorType: "",
    selectedContentCreatorType: "",
    weight: 0,
    unsplashCoverImage: '',
    unsplashFeaturedImage: '',
    unsplashProfileImage: '',
    unsplashMobileCoverImage: '',
    coverImageRadio: 'filepond',
    featuredImageRadio: 'filepond',
    profileImageRadio: 'filepond',
    mobileCoverImageRadio: 'filepond',
    startActivity: null,
    countryIsCompleted: true,
    loaded: false,
    selectedBatchProfileImageFile: '',
    selectedBatchCoverImageFile: '',
    selectedBatchFeaturedImageFile: '',
    selectedBatchProfileLink: '',
    selectedBatchMobileCoverImageFile: '',
    selectedBatchCoverLink: '',
    selectedBatchFeaturedLink: '',
    selectedBatchMobileCoverLink: '',
    downloadableMaterialFileType: '',
    downloadableMaterialId: '',
    downloadableMaterialText: '',
    downloadableMaterialFilename: '',
    contentSupervisor: {value: 0, label: localization.get('unsupervised')},
    releaseDate: null,
    expireDate: null,
    hasExpiredDate: false,
    isPermanentContent: "1",
    deleteDownloadableMaterial: false,
    downloadableMaterialRadio: 'filepond',
    selectedBatchDMaterialLink: '',
    selectedBatchDMaterialFile: '',
    limited: "1",
    mainMaterial: null,
    viewLimit: 0,
    timeToTrack: 1,
    requireLogin: "0",
    downloadableMaterialLink: '',
    showHelper: false,
    hasSuggestedCarousel: false,
    suggestedCarouselRadio: null,
    suggestedCarouselPlaylist: null,
    showDateOnHome: false,
    showTags: false,
    suggestedTagGroup: null,
    suggestedView: "16:9",
    hasDownloadableMaterial: "0",
    hasBuyButton: "0",
    buyButtonText: "",
    buyButtonShowInSocials: false,
    buyButtonProduct: null,
    selectedBuyButtonProduct: null,
    channel: null,
    selectedCloud: "",
    access: "sysadmin",
    clouds: [],
    openCreationDialog: false,
    hasPreferences: false,
    useBackgroundColor: false,
    backgroundColor: "rgba(255, 255, 255, 1)",
    useBackgroundGradient: true,
    titleColor: "rgba(255, 255, 255, 1)",
    descriptionColor: "rgba(255, 255, 255, 1)",
    creationColor: "rgb(0,0,0, 1)",
    cardColor: "rgba(255, 255, 255, 0.4)",
    cardShape: {value: "rounded", label: localization.get('rounded')},
    creationInverted: "down",
    subtitleFontSize: 20,
    subtitleMargin: 10,
    showBox: "show",
    showCreationBorder: "show",
    creationCarouselBackgroundColor: 'rgba(171, 171, 171, 1)',
    eventsPosition: "top",
    isEvent: "0",
    buyButtonPosition: {value: "both", label: localization.get('content_creator.buy_button.both')},
    buyButtonBackgroundColor: "rgb(0,0,0,1)", 
    buyButtonFontColor: "rgb(255,255,255,1)",
    products: [],
    hashcode: "",
    hasRegionBlock: "false",
    allowedRegions: [],
    hasEditorialPreferences: false,
    editorialBgColor: "rgba(255, 255, 255, 1)",
    editorialTitleColor: "rgb(0,0,0, 1)",
    editorialHideCoverImage: false,
    contentCreationsToDelete: [],
    eventsUpdated: false,
    viewType: "inherit",
    viewTypeMobile: "inherit",
    showRenameDialog: false,
    namePrefix: "",
    openShareDialog: false,
    suggestedViewMultipleRow: false,
    showReTagDialog: false,
    selectedRetags: [],
    updateVisible: "keep",
    updateDownloadable: "keep",
    updateAccessLevel: "keep",
    retagMethod: "add",
    driveProfileImage: null,
    driveCoverImage: null,
    driveFeaturedImage: null,
    driveMobileCoverImage: null,
    canonicalChannel: null,
    domainChannels: [],
    canonicalIsCompleted: true,
    translations: [],
    suggestedContentCreators: [],
    logs: null,
    reorderingCreations: false,
    lastPage: 0,
    currentPage: 0,
    requestingMaterials: true,
    uploadingMaterials: false,
    accessLevel: "0",
    additionInfoSize: "md",
    eventButtonColor: "rgba(0,0,0,1)",
    eventButtonBackgroundColor: "rgba(255,255,255,1)",
    eventButtonText: "eventos",
    editorialUseSynopsis: false,
    adsUsage: "auto",
    ad: null,
  };

  setJobDoneChecker = () => {
    if (this.state.uploadingMaterials) {
      this.interval = setInterval(() => {
        return request.contentCreator.get(this.props.match.params.id, "")
          .then(creators => {
            if (creators.job_done) {
              this.setState({uploadingMaterials: false})
              this.requestContentCreations({})
              clearInterval(this.interval)
            }
          });
      }, 30000);
    }
  }

  requestContentCreator = () =>
    request.contentCreator
      .get(this.props.match.params.id, "include=ad;relatedContents;products;eventDates;image;coverImage;mobileCoverImage;featuredImage;translations;tags;catalogs;country;type;type.client;socialLinks;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.audio;downloadableMaterial.binary;user;mainMaterial;suggestedPlaylist;cloud;eventDates;buyButtonProduct;buyButtonProduct.content;allowedRegions;mainChannel.domain")
      .then(contentCreator => {
        const contentCreatorTypeIsEditorial = contentCreator.type && contentCreator.type.type === 'editorial';

        const translations = AvailableLanguages.map(language => {
          const translation = contentCreator.translations.find(tr => tr.locale ===language)

          if (translation) {
            const description = translation.description ? (contentCreatorTypeIsEditorial ? convertFromRaw(JSON.parse(translation.description)) : stateFromHTML(translation.description)) : new EditorState.createEmpty();

            return {
              language: translation.locale,
              name: translation.name,
              description: translation.description ? EditorState.createWithContent(description) : description,
              author: translation.additions && translation.additions.split(';')[0] || "",
              category: translation.additions && translation.additions.split(';')[1] || "",
              synopsis: translation.synopsis,
              when: translation.when,
              entrance: translation.entrance,
            };
          }

          return  {
            language: language,
            name: "",
            description: new EditorState.createEmpty(),
            author: "",
            category: "",
            synopsis: "",
            when: "",
            entrance: "",
          }
        })

        const selectedTags = contentCreator.tags.map(tag => tag.id);
        const hasBackgroundColor = contentCreator.preferences && contentCreator.preferences.background_color && contentCreator.preferences.background_color != "none"

        this.setState({
          id: contentCreator.id,
          email: contentCreator.email,
          phone: contentCreator.phone,
          image: contentCreator.image && {
            id: contentCreator.image.id,
            src: getUpdateFormImageUrlFromItem(contentCreator),
            file: null
          },
          coverImage: contentCreator.coverImage && {
            id: contentCreator.coverImage.id,
            image: contentCreator.coverImage
          },
          featuredImage: contentCreator.featuredImage && {
            id: contentCreator.featuredImage.id,
            image: contentCreator.featuredImage
          },
          mobileCoverImage: contentCreator.mobileCoverImage && {
            id: contentCreator.mobileCoverImage.id,
            image: contentCreator.mobileCoverImage
          },
          selectedCloud: contentCreator.cloud && {
            value: contentCreator.cloud.id,
            label: contentCreator.cloud.name
          },
          access: !contentCreator.cloud ? "sysadmin" : "clouds",
          selectedTags,
          country: contentCreator.country ? {value: contentCreator.country.id, label: contentCreator.country.name} : '',
          observation: contentCreator.observation,
          active: contentCreator.active ? "1" : (!!contentCreator.expire_date || !!contentCreator.release_date ? "1" : "0"),
          selectedCatalogs: contentCreator.catalogs.length > 0 ? contentCreator.catalogs.map(catalog => ({value: catalog.id, label: catalog.name})) : [],
          socialLinks: contentCreator.socialLinks,
          selectedContentCreatorType: {...contentCreator.type, value: contentCreator.type.id, label: contentCreator.type.name, client: get(contentCreator, 'type.client', null), client_id: get(contentCreator, 'type.client.id', null)},
          availableContentCreatorTypes: [],
          weight: contentCreator.weight,
          startActivity: contentCreator.activity_start && moment(contentCreator.activity_start),
          disableForm: false,
          downloadableMaterialText: contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.name,
          downloadableMaterialLink: contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.link,
          downloadableMaterialRadio: contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.link ? 'link' : 'filepond',
          downloadableMaterialFilename: contentCreator.downloadableMaterial && contentCreator.downloadableMaterial[this.getDownloadableMaterialFileType(contentCreator.downloadableMaterial)] && contentCreator.downloadableMaterial[this.getDownloadableMaterialFileType(contentCreator.downloadableMaterial)].original_name,
          hasDownloadableMaterial: contentCreator.downloadableMaterial ? "1" : "0",
          hasBuyButton: contentCreator.buy_button_product_id ? "1" : "0",
          buyButtonProduct: contentCreator.buyButtonProduct,
          buyButtonText: contentCreator.buy_button_product_text,
          buyButtonShowInSocials: contentCreator.buy_button_show_in_socials == "1",
          contentSupervisor: contentCreator.user ? {value: contentCreator.user.id, label: contentCreator.user.name} :  {value: 0, label: localization.get('unsupervised')},
          releaseDate: !!contentCreator.release_date ?  moment.utc(contentCreator.release_date).local() : null,
          expireDate: !!contentCreator.expire_date ? moment.utc(contentCreator.expire_date).local() : null,
          hasExpiredDate: !!contentCreator.expire_date,
          isPermanentContent: contentCreator.active && !contentCreator.expire_date && !contentCreator.release_date ? "1" : "0",
          mainMaterial: contentCreator.mainMaterial ? {value: contentCreator.mainMaterial.id, label: contentCreator.mainMaterial.title} : null,
          viewLimit: contentCreator.view_limit,
          timeToTrack: contentCreator.time_to_track,
          limited: contentCreator.mainMaterial ? "1" : "0",
          requireLogin: contentCreator.require_login ? "1" : "0",
          contentCreatorTypeIsEditorial: contentCreatorTypeIsEditorial,
          hasSuggestedCarousel: contentCreator.has_suggested,
          suggestedCarouselPlaylist: contentCreator.suggestedPlaylist ? {value: contentCreator.suggestedPlaylist.id, label: contentCreator.suggestedPlaylist.title} : null,
          suggestedCarouselRadio: contentCreator.has_suggested ? contentCreator.relatedContents.length ? 'suggestedContentCreators' : contentCreator.suggestedPlaylist ? 'list' : contentCreator.suggested_tag_group ? "suggestedTag" : 'suggested' : null,
          showDateOnHome: !!contentCreator.show_date_on_home,
          showTags: contentCreator.show_tags || false,
          suggested_tag_group: contentCreator.suggested_tag_group || null,
          suggestedView: contentCreator.suggested_view || "16:9",
          hasPreferences: !!(contentCreator.type && contentCreator.type.type != 'editorial' && contentCreator.preferences),
          useBackgroundColor: hasBackgroundColor, 
          backgroundColor: hasBackgroundColor ? contentCreator.preferences.background_color : "rgba(255, 255, 255, 1)",
          useBackgroundGradient: (contentCreator.preferences && contentCreator.preferences.background_gradient == 0) ? false : true,
          titleColor: contentCreator.preferences && contentCreator.preferences.title ? contentCreator.preferences.title.color : "rgba(255, 255, 255, 1)",
          descriptionColor: contentCreator.preferences  && contentCreator.preferences.description ? contentCreator.preferences.description.color : "rgba(255, 255, 255, 1)",
          creationColor: contentCreator.preferences  && contentCreator.preferences.creation ? contentCreator.preferences.creation.color : "rgb(0,0,0, 1)",
          subtitleFontSize: contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.subtitles ? contentCreator.preferences.creation.subtitles.font_size : 20,
          subtitleMargin: contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.subtitles ? contentCreator.preferences.creation.subtitles.margin : 10,
          cardColor: contentCreator.preferences  && contentCreator.preferences.card ? contentCreator.preferences.card.color : "rgba(255, 255, 255, 0.4)",
          cardShape: contentCreator.preferences  && contentCreator.preferences.card?
            {value: contentCreator.preferences.card.shape, label: localization.get(contentCreator.preferences.card.shape)} :
            {value: "rounded", label: localization.get('rounded')},
          creationInverted: contentCreator.preferences  && contentCreator.preferences.creation ? (contentCreator.preferences.creation.inverted ? "up" : "down") : "down",
          showBox: contentCreator.preferences && contentCreator.preferences.card ? (contentCreator.preferences.card.color === "rgba(255, 255, 255, 0)"? "hide" : "show") : "show",
          showCreationBorder: (contentCreator.preferences && contentCreator.preferences.creation) ? (contentCreator.preferences.creation.carousel_background_color == 'transparent' ? 'hide' : 'show') : 'show',
          creationCarouselBackgroundColor: (contentCreator.preferences && contentCreator.preferences.creation && contentCreator.preferences.creation.carousel_background_color && contentCreator.preferences.creation.carousel_background_color != 'transparent') ? contentCreator.preferences.creation.carousel_background_color : 'rgba(171, 171, 171, 1)',
          eventsPosition: contentCreator.preferences && contentCreator.preferences.events ? contentCreator.preferences.events.position : "top",
          eventDates: contentCreator.eventDates ? this.mapEventDates(contentCreator.eventDates) : [],
          isEvent: contentCreator.is_event ? "1" : "0",
          buyButtonPosition: contentCreator.preferences && contentCreator.preferences.buy_button ?
            {value: contentCreator.preferences.buy_button.position, label: localization.get(`content_creator.buy_button.${contentCreator.preferences.buy_button.position}`)} :
            {value: "both", label: localization.get('content_creator.buy_button.both')},
          buyButtonBackgroundColor: (contentCreator.preferences && contentCreator.preferences.buy_button) ? contentCreator.preferences.buy_button.bg_color : "rgb(0,0,0,1)",
          buyButtonFontColor: (contentCreator.preferences && contentCreator.preferences.buy_button) ? contentCreator.preferences.buy_button.font_color : "rgb(255,255,255,1)",
          hashcode: contentCreator.hashcode,
          hasRegionBlock: contentCreator.region_restricted ? "true" : "false",
          allowedRegions: contentCreator.allowedRegions || [],
          hasEditorialPreferences: !!(contentCreator.type && contentCreator.type.type === 'editorial' && contentCreator.preferences),
          editorialBgColor: get(contentCreator, "preferences.background_color", "rgba(255, 255, 255, 1)"),
          editorialTitleColor: get(contentCreator, "preferences.title.color", "rgba(0,0,0, 1)"),
          editorialHideCoverImage: !!get(contentCreator, "preferences.hide_cover_image", false),
          viewType: contentCreator.view_type,
          viewTypeMobile: contentCreator.view_type_mobile,
          suggestedViewMultipleRow: contentCreator.suggested_view_multiple_row,
          canonicalChannel: get(contentCreator, "mainChannel.domain.url", null) ? {
            label: get(contentCreator, "mainChannel.domain.url", null) ,
            value: get(contentCreator, "mainChannel.id", null),
          } : null,
          translations: translations,
          suggestedContentCreators: contentCreator.relatedContents.map(contentCreator => ({value: contentCreator.id, label: contentCreator.name})),
          uploadingMaterials: !contentCreator.job_done,
          accessLevel: get(contentCreator, "access_level", get(contentCreator, 'type.client.default_access_level', 0), 0).toString(),
          ...(contentCreator.random_profile_image ? {profileImageRadio: "random"} : {}),
          ...(contentCreator.random_cover_image ? {coverImageRadio: "random"} : {}),
          additionInfoSize: contentCreator.additional_info_size || "md",
          eventButtonColor: get(contentCreator, "preferences.events.button.color","rgba(0,0,0,1)"),
          eventButtonBackgroundColor: get(contentCreator, "preferences.events.button.background_color","rgba(0,0,0,1)"),
          eventButtonText: get(contentCreator, "preferences.events.button.text","Eventos"),
          editorialUseSynopsis: get(contentCreator, "preferences.use_synopsis", false),
          adsUsage: get(contentCreator, "ads_usage", "auto"),
          ad: get(contentCreator, "ad") ? {value: get(contentCreator, "ad.id"), label: get(contentCreator, "ad.name")} : null,
        });
      })
      .then(() => {
        this.setJobDoneChecker();
        if (this.state.coverImage) this.setState({ coverImage: { id: this.state.coverImage.id, src: getUpdateFormImageUrlFromItem(this.state.coverImage), file: null }})
        if (this.state.mobileCoverImage) this.setState({ mobileCoverImage: { id: this.state.mobileCoverImage.id, src: getUpdateFormImageUrlFromItem(this.state.mobileCoverImage), file: null }})
        if (this.state.featuredImage) this.setState({ featuredImage: { id: this.state.featuredImage.id, src: getUpdateFormImageUrlFromItem(this.state.featuredImage), file: null }})
        this.setState({loaded: true})
      })
      .then(() => this.requestContentCreations({}))
      .then(this.getClouds)
      .then(() => this.getDomainChannels())
      .then(() => request.contentCreator.getAllProducts(this.props.match.params.id)
        .then(response => this.setState({products: response, hasBuyButton: response.length > 0 && this.state.hasBuyButton == "0" ? "2" : this.state.hasBuyButton })))

  mapEventDates = (dates) => {
    if (!dates || dates.length == 0) {
      return [];
    }
    return [dates.map(d => {
      let date = `${d.date.substring(0,10)} ${d.time} GMT`;
      date = new Date(Date.parse(date));


      let activationDate = d.preferences.button.start_date;
      return {
        date: date,
        duration: d.duration,
        dateLabel: moment(`${d.date.substring(0,10)} ${d.time}  +0000`, "YYYY-MM-DD HH:mm Z").format('lll'),
        link: d.preferences.button.link,
        linkText: d.preferences.button.text,
        activationDate: activationDate ? activationDate.concat(" GMT") : null,
        clarification: d.preferences.clarification,
      }
    })]
  }
  getDownloadableMaterialFileType = (material) => {
    if (material.video) {
      return 'video'
    }
    if (material.image) {
      return 'image'
    }
    if (material.audio) {
      return 'audio'
    }
    if (material.binary) {
      return 'binary'
    }
  }

  requestUpdate = (alternativeSubmit = false) => {
    const {
      id,
      email,
      phone,
      imageId,
      coverImageId,
      featuredImageId,
      mobileCoverImageId,
      selectedTags,
      country,
      observation,
      active,
      contentCreations,
      selectedCatalogs,
      socialLinks,
      selectedContentCreatorType,
      weight,
      unsplashCoverImage,
      unsplashFeaturedImage,
      unsplashProfileImage,
      unsplashMobileCoverImage,
      startActivity,
      selectedBatchProfileImageFile,
      selectedBatchCoverImageFile,
      selectedBatchFeaturedImageFile,
      selectedBatchMobileCoverImageFile,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      downloadableMaterialLink,
      buyButtonText,
      buyButtonShowInSocials,
      buyButtonProduct,
      selectedBuyButtonProduct,
      contentSupervisor,
      releaseDate,
      expireDate,
      deleteDownloadableMaterial,
      selectedBatchDMaterialFile,
      mainMaterial,
      viewLimit,
      timeToTrack,
      requireLogin,
      contentCreatorTypeIsEditorial,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist,
      showDateOnHome,
      showTags,
      suggestedTagGroup,
      suggestedView,
      selectedCloud,
      hasPreferences,
      useBackgroundColor,
      backgroundColor,
      useBackgroundGradient,
      subtitleFontSize,
      subtitleMargin,
      titleColor,
      descriptionColor,
      creationColor,
      cardColor,
      cardShape,
      creationInverted,
      isEvent,
      eventDates,
      eventsPosition,
      buyButtonPosition,
      buyButtonBackgroundColor,
      buyButtonFontColor,
      allowedRegions,
      hasRegionBlock,
      hasEditorialPreferences,
      editorialBgColor,
      editorialTitleColor,
      editorialHideCoverImage,
      eventsUpdated,
      viewType,
      viewTypeMobile,
      suggestedViewMultipleRow,
      driveProfileImage,
      driveCoverImage,
      driveFeaturedImage,
      driveMobileCoverImage,
      canonicalChannel,
      translations,
      suggestedContentCreators,
      contentCreationsOrders,
      accessLevel,
      additionInfoSize,
      eventButtonText,
      editorialUseSynopsis,
      eventButtonColor,
      eventButtonBackgroundColor,
      adsUsage,
      ad,
    } = this.state;

    const contentCreatorData = {
      id,
      email,
      phone,
      translations: translations.map(tr => ({
        language: tr.language,
        description: tr.description && (contentCreatorTypeIsEditorial ?
          (stateToHTML(tr.description.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.description.getCurrentContent())))) :
          stateToHTML(tr.description.getCurrentContent())),
        name: tr.name,
        additions: `${tr.author};${tr.category}`,
        synopsis: tr.synopsis,
        when: tr.when,
        entrance: tr.entrance,
      })),
      imageId,
      coverImageId,
      featuredImageId,
      mobileCoverImageId,
      selectedTags,
      countryId: country.value,
      observation,
      active,
      contentCreations,
      selectedContentCreations: contentCreationsOrders.sort((a,b) => a.order - b.order).map(c => c.id),
      selectedCatalogs: selectedCatalogs.map(catalog => catalog.value),
      socialLinks,
      contentCreatorTypeId: selectedContentCreatorType.value,
      weight,
      unsplashCoverImage,
      unsplashFeaturedImage,
      unsplashProfileImage,
      unsplashMobileCoverImage,
      startActivity,
      batchProfileImageId: selectedBatchProfileImageFile.value,
      batchCoverImageId: selectedBatchCoverImageFile.value,
      batchFeaturedImageId: selectedBatchFeaturedImageFile.value,
      batchMobileCoverImageId: selectedBatchMobileCoverImageFile.value,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      downloadableMaterialLink,
      buyButtonProductId: selectedBuyButtonProduct ? selectedBuyButtonProduct.id : buyButtonProduct ? buyButtonProduct.id : null,
      buyButtonText,
      buyButtonShowInSocials,
      contentSupervisorId: contentSupervisor.value,
      releaseDate,
      expireDate,
      deleteDownloadableMaterial,
      batchDownloadableMaterialId: selectedBatchDMaterialFile.value,
      mainMaterial,
      viewLimit,
      timeToTrack,
      requireLogin,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist: suggestedCarouselPlaylist && suggestedCarouselPlaylist.value,
      showDateOnHome,
      showTags,
      suggestedTagGroup: suggestedTagGroup && suggestedTagGroup.value,
      suggestedView,
      selectedCloud: selectedCloud && selectedCloud.value,
      hasPreferences,
      useBackgroundColor,
      backgroundColor,
      useBackgroundGradient,
      subtitleFontSize,
      subtitleMargin,
      titleColor,
      descriptionColor,
      creationColor,
      cardColor,
      cardShape: cardShape.value,
      creationInverted: this.state.creationInverted === "down",
      isEvent,
      eventsPosition,
      buyButtonPosition: buyButtonPosition.value,
      buyButtonBackgroundColor,
      buyButtonFontColor,
      allowedRegions,
      hasRegionBlock,
      editorialBgColor,
      editorialTitleColor,
      editorialHideCoverImage,
      hasEditorialPreferences: contentCreatorTypeIsEditorial ? hasEditorialPreferences : null,
      creationCarouselBackgroundColor: this.state.showCreationBorder == 'hide' ? 'transparent' : this.state.creationCarouselBackgroundColor,
      viewType,
      viewTypeMobile,
      suggestedViewMultipleRow,
      driveProfileImage,
      driveCoverImage,
      driveFeaturedImage,
      driveMobileCoverImage,
      suggestedContentCreators: suggestedContentCreators.map(c => c.value),
      canonicalChannel: canonicalChannel && canonicalChannel.value,
      accessLevel,
      additionInfoSize,
      randomProfileImage : this.state.profileImageRadio === "random",
      randomCoverImage : this.state.coverImageRadio === "random",
      eventButtonText,
      editorialUseSynopsis,
      eventButtonColor,
      eventButtonBackgroundColor,
      adsUsage: (adsUsage == "custom" && !ad) ? "auto" : adsUsage,
      ad: ad && ad.value,
    };

    if (isEvent == '1' && eventsUpdated) {
      let eventDatesData = {
        id,
        dates: eventDates.flat()
      }
      return request.contentCreator
        .update(contentCreatorData)
        .then(() => request.contentCreatorEventDates.update(eventDatesData))
        .then(() => !alternativeSubmit && this.props.history.goBack());
    }

    return request.contentCreator
      .update(contentCreatorData)
      .then(() => !alternativeSubmit && this.props.history.goBack());
  };

  getSynopsis = description => {
    const blocks = convertToRaw(description.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

    if (value.length < 247) return value+ "...";
    const shortDescription = value.substring(0, 300)
    const textInChars = shortDescription.split("")
    let text = "";
    let spaceText = "";

    for (let index = textInChars.length; index > 0; index--) {
      if(textInChars[index] === ".") {
        text = shortDescription.substring(0, index+1)
        break;
      }
      if(textInChars[index] === ",") {
        if (shortDescription.substring(0, index).length < 300) {
          text = shortDescription.substring(0, index) + "..."
          break;
        }
      }
      if(!spaceText && textInChars[index] === " " && shortDescription.substring(0, index).length < 300) {
        spaceText = shortDescription.substring(0, index) + "..."
      }
    }

   if (text.length < 100) text = spaceText;

   return text;
  }

  requestContentCreations = ({ page = 0, clearResults = true }) => {
    const options = { perPage: 35, page: page };

    return this.setState({requestingMaterials: true},
    () => request.contentCreation.getAllByCreator(this.state.id,  "include=video.thumbnail;image;audio;binary;embededContent&allCreations=true", options)
      .then(res => {
        let creations = res.data;
        if (!clearResults) {
          creations = this.state.contentCreations.concat(res.data)
        }

        return this.setState({
          contentCreations: creations,
          contentCreationsOrders: creations.map((c, index) => ({id: c.id, order: index+1})),
          lastPage: res.meta.last_page,
          page: res.meta.current_page,
          total: res.meta.total,
          requestingMaterials: false,
        })
      })
  )};

  requestTags = (contentCreatorType) =>
    request.tagGroup.getAll({filterBy: [{ column: 'type', filter: contentCreatorType},{ column: 'creation_group', filter: '0'}, { column: 'dashboard', filter: '1'}]})
    .then((tagGroups) => this.setState({tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}))

  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onCoverImageChange = coverImage => coverImage ? this.setState({coverImageId: coverImage.id, coverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(coverImage.path)}) : this.setState({coverImageId: null, coverImage: { src: null, filename: null }})
  onFeaturedImageChange = featuredImage => featuredImage ? this.setState({featuredImageId: featuredImage.id, featuredImage: {src: null, filename: null }, imagePreview: this.formNewUrl(featuredImage.path)}) : this.setState({featuredImageId: null, featuredImage: { src: null, filename: null }})
  onMobileCoverImageChange = mobileCoverImage => mobileCoverImage ? this.setState({mobileCoverImageId: mobileCoverImage.id, mobileCoverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(mobileCoverImage.path)}) : this.setState({mobileCoverImageId: null, mobileCoverImage: { src: null, filename: null }})
  onSetCoverImageFromUnsplash = (url, name) => this.setState({unsplashCoverImage: {url, name}})
  onSetFeaturedImageFromUnsplash = (url, name) => this.setState({unsplashFeaturedImage: {url, name}})
  onSetProfileImageFromUnsplash = (url, name) => this.setState({unsplashProfileImage: {url, name}})
  onSetMobileCoverImageFromUnsplash = (url, name) => this.setState({unsplashMobileCoverImage: {url, name}})

  onBatchProfileLinkChange = (selectedBatchProfileLink) => {
    this.setState({selectedBatchProfileLink: '', selectedBatchProfileImageFile: ''}, () => this.setState({selectedBatchProfileLink}))
  }
  onBatchCoverLinkChange = (selectedBatchCoverLink) => {
    this.setState({selectedBatchCoverLink: '', selectedBatchCoverImageFile: ''}, () => this.setState({selectedBatchCoverLink}))
  }
  onBatchFeaturedLinkChange = (selectedBatchFeaturedLink) => {
    this.setState({selectedBatchFeaturedLink: '', selectedBatchFeaturedImageFile: ''}, () => this.setState({selectedBatchFeaturedLink}))
  }
  onBatchDMaterialLinkChange = (selectedBatchDMaterialLink) => {
    this.setState({selectedBatchDMaterialLink: '', selectedBatchDMaterialFile: ''}, () => this.setState({selectedBatchDMaterialLink}))
  }
  onBatchMobileCoverLinkChange = (selectedBatchMobileCoverLink) => {
    this.setState({selectedBatchMobileCoverLink: '', selectedBatchMobileCoverImageFile: ''}, () => this.setState({selectedBatchMobileCoverLink}))
  }

  onDownloadableMaterialChange = downloadableMaterial => this.setState({downloadableMaterialId: downloadableMaterial ? downloadableMaterial.id : null, downloadableMaterialFilename: ''})

  onHasDMaterialChange = hasDMaterial => { 
    if (hasDMaterial == "0") {
      this.setState({
        selectedBatchDMaterialLink: '',
        selectedBatchDMaterialFile: '',
        downloadableMaterialId: '',
        downloadableMaterialLink: '',
        downloadableMaterialFilename: '',
        deleteDownloadableMaterial: true,
        hasDownloadableMaterial: "0"
      })
    } else {
      this.setState({hasDownloadableMaterial: hasDMaterial});
    }
  }

  onDeleteManyMaterials = () => {
    if (this.state.contentCreationsToDelete.length > 1) {
      GlobalConfirmationDialog.showDelete({
        elementName: localization.get('form.double_confirmation_delete_many.label'),
        content: "confirmation_dialog.delete_many_materials",
        deleteRequest: () => new Promise(resolve => {
          Promise.all(this.state.contentCreationsToDelete.map(id => request.contentCreation.delete(id)))
            .then(() => this.requestContentCreations({}))
            .then(() => this.setState({contentCreationsToDelete: []}))
            .then(resolve)
        }),
        doubleConfirmation: true,
        doubleConfirmationText: "form.double_confirmation_delete_many",
      })
    }
  }

  makeRenameDialog = () =>
    <Dialog open={this.state.showRenameDialog} onClose={() => this.setState({showRenameDialog: false, namePrefix: ""})} onBackdropClick={() => this.setState({showRenameDialog: false, namePrefix: ""})}>
      <DialogTitle>{localization.get('form.batch_rename_alert')}</DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <span style={{float: "left"}}>{localization.get('content_creations.prefix_name')}</span>
        <TextField
          placeholder={localization.get('content_creations.prefix_name')}
          value={this.state.namePrefix}
          onChange={e => this.setState({namePrefix: e.target.value})}
          helperText={localization.get('content_creations.prefix_name_helper')}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          GlobalConfirmationDialog.showDoubleConfirmation({
            dialogTitle: localization.get('form.rename'),
            elementName: localization.get('form.double_confirmation_rename_many.label'),
            content: "confirmation_dialog.rename_many_materials",
            updateRequest: () => request.contentCreation.renameManyById(this.state.namePrefix, this.state.contentCreationsToDelete)
              .then(() => this.requestContentCreations({}))
              .then(() => this.setState({contentCreationsToDelete: [], showRenameDialog: false})),
            auxiliaryInformation: localization.get('form.double_confirmation_rename_many.aux', this.state.contentCreationsToDelete.length),
            doubleConfirmation: true,
            doubleConfirmationText: "form.double_confirmation_rename_many",
            confirmationLabel: "form.rename",
            onSuccessText: localization.get('form.rename.success'),
            onFailText: localization.get('form.dialog.fail'),
          })
        }}>
          {localization.get('form.rename')}
        </Button>
      </DialogActions>
    </Dialog>

  onHasBuyButtonChange = hasBuyButton => {
    if (hasBuyButton == "0") {
      this.setState({
        hasBuyButton: "0",
        buyButtonProduct: null,
        selectedBuyButtonProduct: null,
        buyButtonText: '',
        buyButtonShowInSocials: false,
        channel: null
      })
    } else {
      this.setState({hasBuyButton: hasBuyButton});
    }
  }

  onImageRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'coverImageRadio') {
      this.setState({
        coverImageId: '',
        coverImage: {src: null, filename: null },
        unsplashCoverImage: '',
        selectedBatchCoverLink: '',
        selectedBatchCoverImageFile: '',
        driveCoverImage: null,
      })
    }
    if (e.target.name === 'featuredImageRadio') {
      this.setState({
        featuredImageId: '',
        featuredImage: {src: null, filename: null },
        unsplashFeaturedImage: '',
        selectedBatchFeaturedLink: '',
        selectedBatchFeaturedImageFile: '',
        driveFeaturedImage: null,
      })
    }
    if (e.target.name === 'profileImageRadio') {
      this.setState({
        imageId: '',
        image: {src: null, filename: null },
        unsplashProfileImage: '',
        selectedBatchProfileImageFile: '',
        selectedBatchProfileLink: '',
        driveProfileImage: null,
      })
    }
    if (e.target.name === 'downloadableMaterialRadio') {
      this.setState({
        selectedBatchDMaterialLink: '',
        selectedBatchDMaterialFile: '',
        downloadableMaterialId: '',
        downloadableMaterialLink: '',
        downloadableMaterialFilename: '',
        deleteDownloadableMaterial: true,
      })
    }
    if (e.target.name === 'suggestedCarouselRadio') {
      this.setState({suggestedCarouselPlaylist: null})
    }
    if (e.target.name === 'mobileCoverImageRadio') {
      this.setState({
        mobileCoverImageId: '',
        mobileCoverImage: {src: null, filename: null },
        unsplashMobileCoverImage: '',
        selectedBatchMobileCoverLink: '',
        selectedBatchMobileCoverImageFile: '',
        driveMobileCoverImage: null,
      })
    }
  }

  onAsyncCountriesChange = country => this.setState({ country , countryIsCompleted: true});

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  onSelectedContentCreatorTypeChange = (selectedContentCreatorType, isCloud = false) => {
    this.setState({contentCreatorType: '', selectedCatalogs: isCloud ? this.state.selectedCatalogs : [], selectedTags: []},
      () => this.setState({selectedContentCreatorType, contentCreatorType: selectedContentCreatorType, accessLevel: `${get(selectedContentCreatorType, "client.default_access_level", 0)}`},
        () => this.requestTags(selectedContentCreatorType && selectedContentCreatorType.slug)
          .then(this.getDomainChannels)))
  }

  getAvailableContentCreatorTypes = () => {
    let filter = {
      orderBy: {column: "name", type: "ASC"},
      filterBy: [
        {column: "is_talent", filter: this.props.location.pathname.includes('talents')},
        {column: "for_create", filter: 1},
      ]
    }

    if (this.props.isEditorial) filter = { ...filter, filterBy: filter.filterBy.concat([{ column: "is_editorial", filter: true }]) }
    if (userIsContentSupervisor()) {
      filter = { ...filter, filterBy: filter.filterBy.concat([{ column: "client_ids", filter: getStoredUser().clouds.map(c => c.catalog.client_id )}])}
    }

    return request.contentCreatorType.getAll(filter)
      .then(contentCreatorTypes => contentCreatorTypes.filter(contentCreatorType => (
        contentCreatorType.allow_audio === this.state.selectedContentCreatorType.allow_audio &&
        contentCreatorType.allow_video === this.state.selectedContentCreatorType.allow_video &&
        contentCreatorType.allow_image === this.state.selectedContentCreatorType.allow_image)))
      .then(contentCreatorTypes => contentCreatorTypes.map(type => ({
        value: type.id,
        label: type.name,
        slug: type.slug,
        type: type.type,
        client: type.client,
        client_id: type.client_id
      })))
      .then(contentCreatorTypes => this.setState({availableContentCreatorTypes: contentCreatorTypes}))
  }

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      this.setState({image : {src: this.state.imagePreview}})
    }
  }

  setCoverPreview = (type) =>  {
    if (type === 'image') {
      this.setState({coverImage : {src: this.state.imagePreview}})
    }
  }
  setFeaturedPreview = (type) =>  {
    if (type === 'image') {
      this.setState({featuredImage : {src: this.state.imagePreview}})
    }
  }

  getClouds = () => request.clouds.getAll({filterBy: [
      {column: "catalog_ids", filter: this.state.selectedCatalogs.map(c => c.value)}]})
    .then(res => this.setState({
      clouds: res.map(cloud => ({
        value: cloud.id,
        label: cloud.name,
        catalog: cloud.catalog,
        contentCreatorTypes: cloud.contentCreatorTypes.filter(c => {
          if (this.props.location.pathname.includes('talents')) {
            return c.is_talent === 1 && c.type === "audiovisual"
          }else if (this.props.location.pathname.includes('editorial')) {
            return c.is_talent === 0 && c.type === "editorial"
          }
          return c.is_talent === 0 && c.type === "audiovisual"
        }).map(c => ({...c, value: c.id, label: c.name, client: c.client_id})),
      })
    )}
  ))

  inputRef = (ref) => this.itemRefs.push(ref)

  onSubmitValidation = () => {
    if(!!this.state.releaseDate && !!this.state.expireDate && this.state.releaseDate > this.state.expireDate) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.dates_validation')})
      return false
    }else if (this.state.selectedCatalogs && this.state.selectedCatalogs.length === 0) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('content_creators.must_select_catalog')})
      return false;
    }else if (this.state.selectedContentCreatorType.type !== 'editorial' &&
      this.state.hasSuggestedCarousel &&
      !(this.state.suggestedTagGroup || this.state.suggestedCarouselPlaylist || this.state.suggestedContentCreators.length !== 0)
    ) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.must_select_related')})
      return false;
    }else if(!this.state.canonicalChannel && this.state.domainChannels.length) {
      let x = document.getElementById("content-creator-seo-configuration");
      let up = document.getElementById(`arrow-seo-up`);
      let down = document.getElementById(`arrow-seo-down`);

      if (x.style.display === "none") {
        x.style.display = "inline-block";
        down.style.display = "none";
        up.style.display = "block";
      }

      const element = document.getElementById('canonicalChannel');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

      this.setState({canonicalIsCompleted: false})
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('form.content_creator.must_select_canonicals')})
      return false;
    }

    let synopsisIsLong, additionsIsLong = "";

    this.state.translations.forEach(translation => {
      if (!synopsisIsLong && !additionsIsLong && translation.synopsis && translation.synopsis.length > 600) {
        synopsisIsLong = translation.language;
      }

      if (!synopsisIsLong && !additionsIsLong && `${translation.author};${translation.category}` && `${translation.author};${translation.category}`.length > 400) {
        additionsIsLong = translation.language;
      }
    })

    if (!!synopsisIsLong) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get(`form.content_creator.synopsis_validation_${synopsisIsLong}`)})
      return false;
    }
    if (!!additionsIsLong) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get(`form.content_creator.additions_validation_${additionsIsLong}`)})
      return false;
    }

    this.setState({canonicalIsCompleted: true})
    return true;
  }

  openCloseReTagMaterialModal = () => {
    request.tagGroup.getAll({filterBy: [
        { column: 'type', filter: this.state.selectedContentCreatorType.slug },
        { column: 'inheritable', filter: true }
      ]})
      .then(tags => this.setState({ tagGroupsAll: tags, showReTagDialog: true}))
  }

  onSelectedRetagsChanged = selectedTag => {
    if (this.state.selectedRetags.includes(selectedTag)) {
      this.setState({
        selectedRetags: this.state.selectedRetags.filter(tags => tags !== selectedTag),
      }, () => this.updateRetagColor(selectedTag, "available"))
    }else {
      this.state.selectedRetags.push(selectedTag);
      this.setState({selectedRetags: this.state.selectedRetags}, () => this.updateRetagColor(selectedTag, "selected"))
    }
  };

  getDomainChannels = () => {
    let filterBy = [{column: "has_domain", filter: true}];

    if (!!this.state.selectedContentCreatorType.client) {
      filterBy = filterBy.concat([{column: "client_id", filter: get(this.state, "selectedContentCreatorType.client.id")}])
    }

    return request.channel.getAll({filterBy})
      .then(res => {
        let results = res['data'] ? res['data'] : res;

        this.setState({
          domainChannels: results.map(content => ({
            ...content,
            value: content.id,
            label: `${content.domain.url}`,
            useAds: content.ads.length > 0
          }))
        })
      })
      .then(() => {
        if (!this.state.canonicalChannel && this.state.domainChannels.length === 1) {
          this.setState({ canonicalChannel: this.state.domainChannels[0] })
        }
      })
  }

  updateRetagColor = (selectedTag, value) =>
    this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              if (tag.id === selectedTag) {
                return {...tag, state: value}
              }
              return tag
            })
          }
        }
      )
    })

  clearAllRetags = () =>
    new Promise(resolve => {
      this.setState( {tagGroupsAll: this.state.tagGroupsAll.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedRetags: []}))


  makeUpdateTagSelectionDialog = () =>
    <Dialog open={this.state.showReTagDialog} onClose={() => this.setState({showReTagDialog: false, retagMethod: "add"}, this.clearAllRetags)} onBackdropClick={() => this.setState({showReTagDialog: false, retagMethod: "add", }, this.clearAllRetags)}>
      <DialogTitle>{localization.get('form.batch_retag_alert')}</DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        <h4 style={{textAlign: "left", color: "red"}}>{localization.get('form.retag.method')}</h4>
        <Select
          style={{width: "100%"}}
          onChange={event => this.setState({retagMethod: event.target.value})}
          value={this.state.retagMethod}
        >
          <MenuItem value="add">{localization.get('form.retag.add')}</MenuItem>
          <MenuItem value="remove">{localization.get('form.retag.remove')}</MenuItem>
          <MenuItem value="mirror">{localization.get('form.retag.mirror')}</MenuItem>
        </Select>
        <h4 style={{textAlign: "left", marginTop: 20, position: "relative", float: "left"}}>{localization.get('form.tags')}</h4>
        <TagSelection
          homeElementTagSelection={true}
          allowUnselected={false}
          data={this.state.tagGroupsAll}
          onSelectedTagsChanged={tagId => this.onSelectedRetagsChanged(tagId)}
          clearAllTags={this.clearAllRetags}
        />
        <br/>
        <br/>
        <FormControl style={{float: "left", position: "relative", width: "100%"}}>
          <h4 style={{textAlign: "left"}} >{localization.get('form.downloadable')}</h4>
          <RadioGroup
            name="updateDownloadable"
            value={this.state.updateDownloadable}
            onChange={event => this.setState({ updateDownloadable: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.downloadable')} />
            <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.downloadable.not')} />
          </RadioGroup>
          <h4 style={{textAlign: "left"}} >{localization.get('form.visibility')}</h4>
          <RadioGroup
            name="updateVisible"
            value={this.state.updateVisible}
            onChange={event => this.setState({ updateVisible: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value="1" control={<Radio color="default" />} label={localization.get('form.visible')} />
            <FormControlLabel value="0" control={<Radio color="default" />} label={localization.get('form.visible_not')} />
          </RadioGroup>
        </FormControl>
        {get(this.state.selectedContentCreatorType, 'client.has_access_level') &&   
        <>
          <h4 style={{textAlign: "left"}} >{localization.get('form.update_access_level')}</h4>
          <RadioGroup
            name="updateAccessLevel"
            value={this.state.updateAccessLevel}
            onChange={event => this.setState({ updateAccessLevel: event.target.value })}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="keep" control={<Radio color="default" />} label={localization.get('form.keep')} />
            <FormControlLabel value={"0"} control={<Radio color="default"/>} label={localization.get('smart_search.none')} />
            {["1","2","3","4","5"].map(level =>
              <FormControlLabel
                value={level}
                control={<Radio color="default"/>}
                label={level}
              />
            )}
          </RadioGroup>
        </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (this.state.selectedRetags.length > 0) {
            GlobalConfirmationDialog.showDoubleConfirmation({
              dialogTitle: localization.get('form.retag'),
              elementName: localization.get('form.double_confirmation_retag_many.label'),
              content: "confirmation_dialog.delete_many_materials",
              updateRequest: () => request.contentCreation.retagManyByCreator(this.props.match.params.id, {
                reTags: this.state.selectedRetags,
                retagMethod: this.state.retagMethod,
                updateVisible: this.state.updateVisible,
                updateAccessLevel: this.state.updateAccessLevel,
                updateDownloadable: this.state.updateDownloadable,
                contentCreatorTypeId: this.state.selectedContentCreatorType.value,
                contentCreationsIds: this.state.contentCreationsToDelete.length? this.state.contentCreationsToDelete: this.state.contentCreations.map(c => c.id)
              })
                .then(this.clearAllRetags)
                .then(() => this.setState({showReTagDialog: false, retagMethod: "add", contentCreationsToDelete: [] })),
              auxiliaryInformation: localization.get('form.double_confirmation_retag_many.aux.creator', this.state.contentCreationsToDelete.length || this.state.contentCreations.length),
              doubleConfirmation: true,
              doubleConfirmationText: "form.double_confirmation_retag_many",
              confirmationLabel: "form.retag",
              onSuccessText: localization.get('form.retag.success'),
              onFailText: localization.get('form.dialog.fail'),
            })
          }
        }}>
          {localization.get('form.retag')}
        </Button>
      </DialogActions>
    </Dialog>

  render = () => {
    const isEditorial = get(this.state, "selectedContentCreatorType.type") == "editorial";

    return (
      <div>
        {this.makeUpdateTagSelectionDialog()}
        {this.makeRenameDialog()}
        <CustomShareContent open={this.state.openShareDialog} sharingCreator={this.state.sharingCreator} onClose={() => this.setState({openShareDialog: false, sharingCreator: null})} />
        <CreateMaterialsByLink
          open={this.state.openCreationDialog}
          onClose={(submited = false) => this.setState({openCreationDialog: false, uploadingMaterials: !submited ? true : this.state.uploadingMaterials }, 
              () => {
                this.requestContentCreations({})
                this.setJobDoneChecker();
              })}
          contentCreatorType={this.state.selectedContentCreatorType}
          isTalent={this.props.location.pathname.includes('talents')}
          creatorId={this.state.id}
          cloudId={this.state.selectedCloud && this.state.selectedCloud.value}
          clientId={get(this.state, "selectedContentCreatorType.client.id")}
        />
        <FormViewContainer
          title={localization.get("view.content_creator.update")}
          submitRequest={() => this.requestUpdate()}
          onBack={() => this.props.history.goBack()}
          initializationRequest={() =>
            this.requestContentCreator()
              .then(() => this.requestTags(this.state.selectedContentCreatorType && this.state.selectedContentCreatorType.slug))
              .then(() => this.getAvailableContentCreatorTypes())
              .then(() => this.state.suggested_tag_group && this.state.tagGroups.find(tagGroup => tagGroup.id === this.state.suggested_tag_group) &&
                this.setState({suggestedTagGroup: {
                value: this.state.suggested_tag_group,
                label: this.state.tagGroups.find(tagGroup => tagGroup.id ===this.state.suggested_tag_group).name
              }}))}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          validation={this.onSubmitValidation}
          disabled={this.state.disableForm}
          actionsExtraSpace={true}
          altSubmit={isEditorial}
          altSubmitRequest={() => this.requestUpdate(true)}
          altSubmitValidation={this.onSubmitValidation}
        >
          <div style={{position: "absolute", right: 10, top: -40}}>
            <IconButton
              onClick={() => this.setState({
                openShareDialog: true,
                sharingCreator: {id: this.state.id, hashcode: this.state.hashcode, type: {...this.state.selectedContentCreatorType, id: this.state.selectedContentCreatorType.value}},
              })}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('button.see_home')}>
                <Visibility />
              </Tooltip>
            </IconButton>
          </div>
          {this.state.loaded &&
          <FormContent
            isDemo={this.state.isDemo}
            id={this.state.id}
            email={this.state.email}
            phone={this.state.phone}
            image={this.state.image}
            coverImage={this.state.coverImage}
            featuredImage={this.state.featuredImage}
            mobileCoverImage={this.state.mobileCoverImage}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            onCoverImageChange={this.onCoverImageChange}
            onFeaturedImageChange={this.onFeaturedImageChange}
            onMobileCoverImageChange={this.onMobileCoverImageChange}
            contentCreatorId={this.props.match.params.id}
            tagGroups={this.state.tagGroups}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            country={this.state.country}
            onAsyncCountriesChange={this.onAsyncCountriesChange}
            observation={this.state.observation}
            active={this.state.active}
            onActiveChange={(active) => this.setState({active}, () => this.state.active && this.setState({releaseDate: null, expireDate: null}))}
            contentCreations={this.state.contentCreations}
            onContentCreationsOrderChanged={contentCreations => this.setState({contentCreations, contentCreationsOrders: contentCreations.map((c, i) => ({id: c.id, order: i+1}))})}
            handleContentCreationRemoved={id => this.setState({
              contentCreations: this.state.contentCreations.filter(contentCreation => contentCreation.id !== id), 
              contentCreationsOrders: this.state.contentCreationsOrders.filter(c => c.id !== id)
            })}
            selectedCatalogs={this.state.selectedCatalogs}
            onCatalogChange={selectedCatalogs => this.setState({selectedCatalogs, hasSuggestedCarousel: false, suggestedCarouselPlaylist: null, suggestedTagGroup: null}, () => this.setState({hasSuggestedCarousel: true}, this.getClouds))}
            socialLinks={this.state.socialLinks}
            selectedContentCreatorType={this.state.selectedContentCreatorType}
            onSelectedContentCreatorTypeChange={this.onSelectedContentCreatorTypeChange}
            onSocialLinksChange={(socialLinks) => this.setState({socialLinks})}
            availableContentCreatorTypes={this.state.availableContentCreatorTypes}
            weight={this.state.weight}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            onSetCoverPreview={this.setCoverPreview}
            onSetFeaturedPreview={this.setFeaturedPreview}
            disableForm={this.state.disableForm}
            coverImageRadio={this.state.coverImageRadio}
            featuredImageRadio={this.state.featuredImageRadio}
            profileImageRadio={this.state.profileImageRadio}
            mobileCoverImageRadio={this.state.mobileCoverImageRadio}
            onSetCoverImageFromUnsplash={this.onSetCoverImageFromUnsplash}
            onSetFeaturedImageFromUnsplash={this.onSetFeaturedImageFromUnsplash}
            onSetProfileImageFromUnsplash={this.onSetProfileImageFromUnsplash}
            onSetMobileCoverImageFromUnsplash={this.onSetMobileCoverImageFromUnsplash}
            onImageRadiosChange={this.onImageRadiosChange}
            startActivity={this.state.startActivity}
            handleStartActivityChange={date => this.setState({startActivity: date})}
            countryIsCompleted={this.state.countryIsCompleted}
            isTalent={this.props.location.pathname.includes('talents')}
            onContentChange={(e, field) => this.setState({[field]: e})}
            onBatchProfileLinkChange={this.onBatchProfileLinkChange}
            selectedBatchProfileLink={this.state.selectedBatchProfileLink}
            selectedBatchProfileImageFile={this.state.selectedBatchProfileImageFile}
            onBatchProfileFileChange={selectedBatchProfileImageFile => this.setState({selectedBatchProfileImageFile})}
            onBatchCoverLinkChange={this.onBatchCoverLinkChange}
            onBatchFeaturedLinkChange={this.onBatchFeaturedLinkChange}
            selectedBatchCoverLink={this.state.selectedBatchCoverLink}
            selectedBatchFeaturedLink={this.state.selectedBatchFeaturedLink}
            selectedBatchCoverImageFile={this.state.selectedBatchCoverImageFile}
            selectedBatchFeaturedImageFile={this.state.selectedBatchFeaturedImageFile}
            onBatchCoverFileChange={selectedBatchCoverImageFile => this.setState({selectedBatchCoverImageFile})}
            onBatchFeaturedFileChange={selectedBatchFeaturedImageFile => this.setState({selectedBatchFeaturedImageFile})}
            onDownloadableMaterialChange={this.onDownloadableMaterialChange}
            setDownloadableMaterialFileType={downloadableMaterialFileType => this.setState({downloadableMaterialFileType})}
            downloadableMaterialText={this.state.downloadableMaterialText}
            downloadableMaterialFilename={this.state.downloadableMaterialFilename}
            onDownloadableMaterialDelete={() => this.setState({downloadableMaterialFilename: '', deleteDownloadableMaterial: true, downloadableMaterialText: ''})}
            onContentSupervisorChange={contentSupervisor => this.setState({contentSupervisor})}
            contentSupervisor={this.state.contentSupervisor}
            releaseDate={this.state.releaseDate}
            expireDate={this.state.expireDate}
            hasExpiredDate={this.state.hasExpiredDate}
            onHasExpiredDateChange={hasExpiredDate => this.setState({hasExpiredDate}, () => !this.state.hasExpiredDate && this.setState({expireDate: null}))}
            isPermanentContent={this.state.isPermanentContent}
            onIsPermanentContentChange={isPermanentContent => this.setState({isPermanentContent}, () => this.state.isPermanentContent && this.setState({expireDate: null, releaseDate: null}))}
            downloadableMaterialRadio={this.state.downloadableMaterialRadio}
            onBatchDMaterialLinkChange={this.onBatchDMaterialLinkChange}
            selectedBatchDMaterialLink={this.state.selectedBatchDMaterialLink}
            onBatchDMaterialFileChange={selectedBatchDMaterialFile => this.setState({selectedBatchDMaterialFile})}
            selectedBatchDMaterialFile={this.state.selectedBatchDMaterialFile}
            onLimitedChange={limited => this.setState({limited: limited}, () => this.setState({mainMaterial: 0, viewLimit: 0, timeToTrack: 1}))}
            limited={this.state.limited}
            onMainMaterialChange={mainMaterial => this.setState({mainMaterial: mainMaterial})}
            mainMaterial={this.state.mainMaterial}
            viewLimit={this.state.viewLimit}
            timeToTrack={this.state.timeToTrack}
            requireLogin={this.state.requireLogin}
            onRequireLoginChange={requireLogin => this.setState({requireLogin: requireLogin})}
            downloadableMaterialLink={this.state.downloadableMaterialLink}
            showHelper={this.state.showHelper}
            updateHelper={() => this.setState({showHelper: !this.state.showHelper})}
            hasSuggestedCarousel={this.state.hasSuggestedCarousel}
            onSuggestedCarouselChange={hasSuggestedCarousel => this.setState({hasSuggestedCarousel, suggestedCarouselPlaylist: null, suggestedTagGroup: null})}
            suggestedCarouselRadio={this.state.suggestedCarouselRadio}
            onSuggestedCarouselRadioChange={suggestedCarouselRadio => this.setState({suggestedCarouselRadio,  suggestedCarouselPlaylist: null, suggestedTagGroup: null})}
            onSuggestedCarouselPlaylistChange={suggestedCarouselPlaylist => this.setState({suggestedCarouselPlaylist})}
            suggestedCarouselPlaylist={this.state.suggestedCarouselPlaylist}
            showDateOnHome={this.state.showDateOnHome}
            showTags={this.state.showTags}
            suggestedTagGroup={this.state.suggestedTagGroup}
            suggestedView={this.state.suggestedView}
            onShowTagsChange={showTags =>  this.setState({ showTags })}
            suggestedTagGroupChange={suggestedTagGroup =>  this.setState({ suggestedTagGroup })}
            hasDownloadableMaterial={this.state.hasDownloadableMaterial}
            onHasDMaterialChange={this.onHasDMaterialChange}
            hasBuyButton={this.state.hasBuyButton}
            onHasBuyButtonChange={this.onHasBuyButtonChange}
            buyButtonProduct={this.state.buyButtonProduct}
            selectedBuyButtonProduct={this.state.selectedBuyButtonProduct}
            channel={this.state.channel}
            buyButtonText={this.state.buyButtonText}
            buyButtonShowInSocials={this.state.buyButtonShowInSocials}
            getSynopsis={description => this.getSynopsis(description)}
            onAccessChange={value => {
              this.setState({access: value.target.value})
              if (value.target.value === 'sysadmin') {
                this.setState({selectedCloud: null})
              }
            }}
            access={this.state.access}
            selectedCloud={this.state.selectedCloud}
            onSelectedCloudsChange={value => this.setState({ selectedCloud: value})}
            clouds={this.state.clouds}
            openDialog={() => this.setState({openCreationDialog: true})}
            useBackgroundColor={this.state.useBackgroundColor}
            backgroundColor={this.state.backgroundColor}
            useBackgroundGradient={this.state.useBackgroundGradient}
            onUseBackgroundGradientChange={value => this.setState({useBackgroundGradient: value})}
            subtitleFontSize={this.state.subtitleFontSize}
            subtitleMargin={this.state.subtitleMargin}
            titleColor={this.state.titleColor}
            descriptionColor={this.state.descriptionColor}
            creationColor={this.state.creationColor}
            cardColor={this.state.cardColor}
            cardShape={this.state.cardShape}
            hasPreferences={this.state.hasPreferences}
            onUseBackgroundColorChange={value => this.setState({useBackgroundColor: value})}
            onHasPreferencesChange={value => this.setState({hasPreferences: value})}
            setDefaultStyles={() => this.setState({
              backgroundColor: "rgba(255, 255, 255, 1)",
              useBackgroundGradient: true,
              useBackgroundColor: false,
              subtitleFontSize: 20,
              subtitleMargin: 10,
              titleColor: "rgba(255, 255, 255, 1)",
              descriptionColor: "rgba(255, 255, 255, 1)",
              creationColor: "rgb(0,0,0, 1)",
              cardColor: "rgba(255, 255, 255, 0.4)",
              cardShape: {value: "rounded", label: localization.get('rounded')},
              creationInverted: "down",
              showBox: "show",
              showCreationBorder: "show",
              creationCarouselBackgroundColor: "rgba(171, 171, 171, 1)",
              eventsPosition: "top",
              buyButtonPosition: {value: "both", label: localization.get('content_creator.buy_button.both')},
              buyButtonBackgroundColor: "rgb(0,0,0,1)", 
              buyButtonFontColor: "rgb(255,255,255,1)",
            })}
            creationInverted={this.state.creationInverted}
            showBox={this.state.showBox}
            showCreationBorder={this.state.showCreationBorder}
            creationCarouselBackgroundColor={this.state.creationCarouselBackgroundColor}
            isEvent={this.state.isEvent}
            eventDates={this.state.eventDates}
            onEventDatesChange={eventDates => this.setState({eventDates: eventDates, eventsUpdated: true})}
            eventsPosition={this.state.eventsPosition}
            buyButtonPosition={this.state.buyButtonPosition}
            buyButtonBackgroundColor={this.state.buyButtonBackgroundColor}
            buyButtonFontColor={this.state.buyButtonFontColor}
            products={this.state.products}
            hasRegionBlock={this.state.hasRegionBlock}
            onHasRegionBlockChange={hasRegionBlock => {
              if(hasRegionBlock == "false") {
                this.setState({ allowedRegions: [] })
              }
              this.setState({hasRegionBlock})
            }}
            allowedRegions={this.state.allowedRegions}
            onChangeAllowedRegions={allowedRegions => this.setState({allowedRegions})}
            hasEditorialPreferences={this.state.hasEditorialPreferences}
            onHasEditorialPreferencesChange={value => this.setState({hasEditorialPreferences: value})}
            editorialBgColor={this.state.editorialBgColor}
            editorialTitleColor={this.state.editorialTitleColor}
            editorialHideCoverImage={this.state.editorialHideCoverImage}
            contentCreationsToDelete={this.state.contentCreationsToDelete}
            onChangeContentCreationsToDelete={id => {
              if (this.state.contentCreationsToDelete.includes(id)) {
                this.setState({contentCreationsToDelete: this.state.contentCreationsToDelete.filter(ccId => ccId !== id)})
              }else {
                this.state.contentCreationsToDelete.push(id)
                this.setState({contentCreationsToDelete: this.state.contentCreationsToDelete})
              }
            }}
            onDeleteManyMaterials={this.onDeleteManyMaterials}
            viewType={this.state.viewType}
            viewTypeMobile={this.state.viewTypeMobile}
            onChangeViewType={e => this.setState({viewType: e.target.value})}
            onChangeViewTypeMobile={e => this.setState({viewTypeMobile: e.target.value})}
            openRenameDialog={() => this.setState({showRenameDialog: true})}
            suggestedViewMultipleRow={this.state.suggestedViewMultipleRow}
            openCloseReTagMaterialModal={this.openCloseReTagMaterialModal}
            onDriveImageChange={(target, id) => this.setState({ [target]: id })}
            canonicalChannel={this.state.canonicalChannel}
            domainChannels={this.state.domainChannels}
            getDomainChannels={this.getDomainChannels}
            canonicalIsCompleted={this.state.canonicalIsCompleted}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });


              this.setState({ translations });
            }}
            translations={this.state.translations}
            suggestedContentCreators={this.state.suggestedContentCreators}
            suggestedContentCreatorsChange={creators => this.setState({suggestedContentCreators: creators})}
            logs={this.state.logs}
            contentCreationsOrders={this.state.contentCreationsOrders}
            reorderingCreations={this.state.reorderingCreations}
            requestContentCreations={this.requestContentCreations}
            requestingMaterials={this.state.requestingMaterials}
            page={this.state.page}
            lastPage={this.state.lastPage}
            uploadingMaterials={this.state.uploadingMaterials}
            accessLevel={this.state.accessLevel}
            additionInfoSize={this.state.additionInfoSize}
            eventButtonText={this.state.eventButtonText}
            eventButtonColor={this.state.eventButtonColor}
            eventButtonBackgroundColor={this.state.eventButtonBackgroundColor}
            editorialUseSynopsis={this.state.editorialUseSynopsis}
            adsUsage={this.state.adsUsage}
            ad={this.state.ad}
            isEditorial={isEditorial}
          />
          }
        </FormViewContainer>
        <TryAgainSnackbar ref="snackbar" />
      </div>
    );
  };
}
