import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../api-client";
import localization from "../../config/localization";
import {getStoredUser, userIsTrial} from "../../api-client/core/authentication/utils";
import logout from "../../api-client/core/authentication/logout";
import queryString from 'querystring';
import Button from "../../components/CustomButtons/Button";
import browserHistory from "utils/browserHistory";
import {Typography} from "@material-ui/core";

class TrialLogin extends React.Component {

  state = {};

  componentDidMount() {

    if (getStoredUser()) {
      if (userIsTrial()) {
        browserHistory.push('/')
        return
      }
      request.authentication.logout()
    }

    request.authentication.trialLogin({ trialToken: queryString.parse(this.props.location.search)['?token'] })
      .then(() => {
        window.location.reload();
      })
      .catch(_ => this.setState({ invalidToken: true }))
  }

  makeContactUsButton() {
    let url = process.env.REACT_APP_WEB_HOST + '?section=contact_us';
    return (
      <div>
        <Button
          style={{ backgroundColor: "black", marginTop: '40px' }}
          color="default"
          onClick={() => window.location.href = url }
          simple
          size="lg"
          block
        >
        {localization.get("trial_login.contact")}
        </Button>
      </div>
    );
  }

  render() {
    if (this.state.invalidToken) {
      return (
        <div
          style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          }}
        >
          <Typography variant="display2">{localization.get("trial_login.invalid_token")}</Typography>
          <Typography style={{ marginTop: "10px" }}>
            {localization.get("trial_login.invalid_token_extra")}
          </Typography>
          {this.makeContactUsButton()}
        </div>
      );
    }

    return null;
  }
}

export default withStyles(loginPageStyle)(TrialLogin);
