import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery, root} from './utils'
import {getActiveChannel, isPublicChannel} from "../authentication/utils";

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}${isPublicChannel() ? "/public": ""}${getActiveChannel() ? `/channels/${getActiveChannel()}` : ''}${root}?${includeQuery}`, { ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
