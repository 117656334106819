import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {AccessTime, Done, GetApp, ShoppingCart, Redeem, Block, Publish} from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import {IconButton, Tooltip, Grid, Button} from "@material-ui/core";
import moment from "moment";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import ImportPurchaseModal from "./ImportPurchaseModal";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';

let table

class PurchaseTable extends Component {
  state = {
    channel: '',
    containerType: 'all',
    copied: localization.get('copy_link'),
    loaded: true,
    product: "",
    status: "authorized",
    purpose: "self",
    paginationConfig: null,
    openImportPurchaseModal: false,
    startDate: null,
    endDate: null,
  };

  componentDidMount() {
    let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));

    if (selectedChannelFromLocalStorage) {
      this.setState({channel: selectedChannelFromLocalStorage});
    }

    if (localStorage.getItem("selectedPurchaseStatus")) {
      this.setState({status: localStorage.getItem("selectedPurchaseStatus")});
    }

    if (localStorage.getItem("selectedPurposeStatus")) {
      this.setState({purpose: localStorage.getItem("selectedPurposeStatus")});
    }
  }

  handleDateChange = (date, type) => {
    this.setState({ [type]: date }, () => (this.state.startDate && this.state.endDate) && table.forceUpdate());
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.channel !== this.state.channel) {
      this.setState({channel: nextProps.channel});
      table.forceUpdate();
    }
  }

  columns = () => {
    const nameColumn =
      [
        {
          Header: localization.get("table.created_at"),
          id: 'created_at',
          accessor: purchase => localization.moment(purchase.created_at).fromNow(),
          filterable: false,
          sortable: false,
        },
        {
          Header: localization.get("table.user"),
          id: "user[name]",
          accessor: 'user.name',
          sortable: false,
          filterable: true,
        },
        {
          Header: localization.get("table.channel"),
          id: "channel",
          accessor: 'channel.name',
          sortable: false,
          filterable: false
        },
      /*  {
          Header: localization.get("Gateway"),
          id: "gateway",
          accessor: 'gateway',
          sortable: false,
          filterable: true
        },*/
        {
          Header: localization.get("music.license_table.product"),
          id: "product",
          accessor: purchase => purchase.product.content ? (purchase.product.content.name || purchase.product.content.title ) : localization.get('deleted'),
          sortable: false,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <input
              style={{width: "100%"}}
              onChange={event => {
                this.setState({product: event.target.value});
                onChange(event.target.value)
              }}
              value={filter ? filter.value : ""}
            />
          ),
        },
        {
          Header: localization.get("music.license_dialog.discount"),
          id: "code[code]",
          accessor: 'code.code',
          sortable: false,
          filterable: true,
        },
        {
          Header: localization.get("form.price"),
          id: "price",
          accessor: purchase => `${purchase.price} ${purchase.currency}`,
          sortable: false,
          filterable: false,
        },
        {
          Header: localization.get("table.total"),
          id: "total",
          accessor: "total",
          sortable: false,
          filterable: false,
          width: 85,
        },
        {
          Header: localization.get("table.purpose"),
          id: "purpose",
          accessor: purchase => purchase.purpose === 'self' ?  <ShoppingCart/> : <Redeem />,
          sortable: false,
          filterable: true,
          width: 85,
          Filter: ({filter, onChange}) => (
            <select
              style={{width: "100%"}}
              onChange={event =>  {
                this.setState({purpose: event.target.value})
                localStorage.setItem("selectedPurposeStatus", event.target.value)
                onChange(event.target.value)
              }}
              value={this.state.purpose}
            >
              <option selected value={"self"}>{localization.get('table.purpose.self')}</option>
              <option value={"gift"}>{localization.get('table.purpose.gift')}</option>
            </select>
          ),
        },
        {
          Header: localization.get("table.status"),
          id: "puchaseStatus",
          accessor: purchase => {
            const createDate = moment(purchase.created_at);

            return purchase.status === 'authorized' ? <Done style={{color: (moment().diff(createDate, 'days') > purchase.product.days ? "rgb(0, 170, 255)" : "green")}}/> : purchase.status === 'pending' ? <AccessTime /> : <Close style={{color: "red"}}/>
          },
          sortable: false,
          filterable: true,
          Filter: ({filter, onChange}) => (
            <select
              style={{width: "100%"}}
              onChange={event =>  {
                this.setState({status: event.target.value})
                localStorage.setItem("selectedPurchaseStatus", event.target.value)
                onChange(event.target.value)
              }}
              value={this.state.status}
            >
              <option selected value={"authorized"}>{localization.get('table.authorized')}</option>
              <option value={"pending"}>{localization.get('table.pending')}</option>
              <option value={"cancelled"}>{localization.get('table.cancelled')}</option>
            </select>
          ),
          width: 60,
        },
        userIsSysAdmin() && {
          Header: localization.get("table.actions"),
          id: "actions",
          sortable: false,
          filterable: false,
          width: 60,
          accessor: purchase => {
            const buttonBuilder = new TableButtonsBuilder()
            purchase.status !== "cancelled"  && purchase.purpose === 'self' && buttonBuilder.withOther(
              <TableButton title={localization.get('table.purchase.cancel')}
                onClick={() =>
                  GlobalConfirmationDialog.show({
                    title: localization.get("table.purchase.cancel"),
                    content: localization.get("table.purchase.cancel_text"),
                    request: () => request.products.cancelPurchase(purchase.id),
                    onSuccess: () => this.refs.table.forceUpdate()
                })
              }
              >
                <Block />
              </TableButton>)
            return buttonBuilder.build()
          }
        }
      ]


    return nameColumn;
  }
  render = () => {
    const { startDate, endDate, loaded } = this.state;

    return !loaded ? <div></div> :
      (
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          
          {this.state.channel &&
            <>
              <ImportPurchaseModal
                open={this.state.openImportPurchaseModal}
                channel={this.state.channel}
                onClose={() => this.setState({openImportPurchaseModal: false})}
              />          
              <IconButton
                onClick={() => window.open(request.products.purchasesExport(this.state.channel.value, this.state.paginationConfig))}
                style={{height: 40, width: 40,position: "absolute", top: -135, right: 50}}
              >
                <Tooltip placement="bottom" title={localization.get('download_csv')}>
                  <GetApp />
                </Tooltip>
              </IconButton>
              <IconButton
              onClick={() => this.setState({openImportPurchaseModal: true})}
              style={{height: 40, width: 40,position: "absolute", top: -135, right: 10}}
              >
                <Tooltip placement="bottom" title={localization.get('Importar Compra')}>
                  <Publish />
                </Tooltip>
              </IconButton>
            </>
          }
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-from"
                    label="Desde"
                    value={startDate}
                    onChange={(date) => this.handleDateChange(date, 'startDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-to"
                    label="Hasta"
                    value={endDate}
                    onChange={(date) => this.handleDateChange(date, 'endDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
            </MuiPickersUtilsProvider>
          </div>
          <ServerSidePaginationTable
            ref={r => table = r}
            columns={this.columns()}
            subComponent={subComponent}
            requestTableData={paginationConfig => {
              if (this.state.status) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'status', filter: this.state.status}])
                }
              }

              if (this.state.purpose) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'purpose', filter: this.state.purpose}])
                }
              }

              if (this.state.product) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'productName', filter: this.state.product}])
                }
              }

              if (this.state.channel) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'channel_id', filter: this.state.channel.value}])
                }
              }

              if (this.state.startDate && this.state.endDate) {
                const formattedStartDate = moment(this.state.startDate).format('YYYY-MM-DD');
                const formattedEndDate = moment(this.state.endDate).format('YYYY-MM-DD');
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([
                    {column: 'start_date', filter: formattedStartDate},
                    {column: 'end_date', filter: formattedEndDate},
                  ])
                }
              }

              this.setState({ paginationConfig })

              return request.products.purchases('include=purchases.product.content;product.content;channel;channel.channelShipments;channel.addresses;code;user;user.address', paginationConfig)
            }}
          />
        </div>
      )
  }
}


export default PurchaseTable

const addressInfo = (a) => a ? `${a.address}, ${a.city} (CP: ${a.zip_code})` : localization.get("table.purchase_no_address")
const subComponent = (row) => {
  let purchase = row.original;
  const createDate = moment(purchase.created_at);
  if (purchase.shipping_method && purchase.shipping_method_id) {
    if (purchase.shipping_method == "delivery" && purchase.channel && purchase.channel.channelShipments) {
      purchase.channelShipment = purchase.channel.channelShipments.find(cs => cs.id == purchase.shipping_method_id);
    }
    if (purchase.shipping_method == "withdrawal" && purchase.channel && purchase.channel.addresses) {
      purchase.address = purchase.channel.addresses.find(a => a.id == purchase.shipping_method_id);
    }
  }
  return (

       <React.Fragment>
         <div style={{ width: "100%", float: "left", position: "relative", padding: "20px" }}>
           <p>{localization.get("table.status")}: {purchase.status === 'authorized' ? (moment().diff(createDate, 'days') > purchase.product.days ? localization.get("account.license.expired") : localization.get("table.current")) : purchase.status === 'pending' ? localization.get("account.license.pending") :  localization.get("right_management.state.canceled")}</p>
           {(purchase.status === 'authorized' && moment().diff(createDate, 'days') > purchase.product.days) && <p> {localization.get('table.expired_at', moment().diff(createDate, 'days') - purchase.product.days)} </p> }
           {purchase.purchases.length > 0 && purchase.purchases.map(purchase =>
            <div style={{ float: "left", position: "relative", fontWeight: "bold", width: "100%", margin: "10px 0px" }}>{localization.get('product')}: {!purchase.product.content ? "" : purchase.product.content.name || purchase.product.content.title}</div>
           )}
           {(purchase.channelShipment || purchase.address) &&
            <>
              <p>
                <span style={{fontWeight: "bold", textDecoration: "underline"}}>{localization.get("table.purchase_with_delivery")}</span>
              </p>
              {purchase.channelShipment &&
                <>
                  <p>
                    <span>{localization.get("product.delivery_label", `${purchase.channelShipment.location} (${purchase.shipping_price})`)}</span><br/>
                  </p>
                  <p>
                    <span>{`${localization.get("table.purchase_delivery_address")}: ${addressInfo(purchase.user.address)} `} {purchase.user.address && purchase.user.address.google_maps_link && <a target="_blank" href={purchase.user.address.google_maps_link}>{localization.get("product.see_in_google_maps")}</a>}</span>
                  </p>
                </>
              }
              {purchase.address && <span>{localization.get("table.purchase_withdrawal_label", `${purchase.address.name} - ${addressInfo(purchase.address)}`)}</span>}
              {purchase.user.email && <p><span>{`${localization.get("email")}: ${purchase.user.email}`}</span></p>}
            </>
           }
         </div>
       </React.Fragment>
  )
}
