export const makeFormData = ({
 name,
 email,
 description,
}) => {
  const form = new FormData();
  if (name) form.append("name", name);
  if (email) form.append("email", email);
  if (description) form.append("description", description);
  return form;
};

