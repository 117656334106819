import React from "react";
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types'

class HomeItemDescriptionTags extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags,
      outlined: this.props.outlined || false
    }
  }

  componentDidMount = () => {
    if (!this.props.isClickable) {
      this.state.tags.forEach((tag) => {
        tag.state = 'disable'
      });
      this.setState(this.state)
    }
  }

  componentWillReceiveProps = (nextProps)  =>{
    if (this.state.tags !== nextProps.tags) {
      this.setState({tags: nextProps.tags})
    }
  };

  handleClickTag = (e, tagId) => {
    let tag = this.state.tags.find((tag) => tag.id === tagId);
    if (tag.state === 'available' && this.props.singleTagSelection) {
        this.state.tags.forEach(tag => {
          tag.state = 'available'
      });
      tag.state = 'selected'
      this.setState(this.state)
    } else if (tag.state === 'available' || !tag.state) {
      tag.state = 'selected'
      this.setState(this.state)
    } else if (tag.state === 'selected') {
      tag.state = 'available'
      this.setState(this.state)
    }
    if (this.props.onSelectedTagChanged) {
      this.props.onSelectedTagChanged(tagId);
    }
  }

  handleChange = () => {
    let selected = []
    this.state.tags.forEach(tag=> {
      if (tag.state === "selected") {
        selected.push(tag.id)
      }
    });
    if (this.props.onSelectedTagsChanged) {
      this.props.onSelectedTagsChanged(selected);
    }
  }

  makeChipStyle = (tag) =>  this.props.outlined ?
    {
      margin: "0 4px",
      border: "1px solid #0000003d",
      backgroundColor: tag.state === "selected" ? 'darkgray' : 'white',
      color: tag.state === "selected" ? 'white' : tag.state === "disable" ? 'grey' : 'black',
      cursor: tag.state === "disable" ? 'auto' : 'pointer',
    } :
    {
      width: "auto",
      maxWidth: "200px", fontWeight: "400",
      backgroundColor: tag.state === "selected" ? 'darkgray' : 'transparent',
      color: tag.state === "selected" ? 'white' : tag.state === "disable" ? 'grey' : 'darkgray',
      cursor: tag.state === "disable" ? 'auto' : 'pointer',
      height: "20px",
      fontSize: "12px",
      margin: "3px 4px 3px 4px",

    }

  handleAction = tag => this.props.handleAction ? this.props.handleAction(tag) : false

  actionIcon = () => this.props.actionIcon ? this.props.actionIcon : false

  render() {
    return (
      <div style={{display: "inline-block", overflow: "hidden", height: this.props.outlined ? 'auto' : 55}}>
        {this.state.tags.map((tag, j) => {
          return (
            <div className={!this.props.displayOnMobile && "home-item-description-tag-container"} style={{width: "auto",position: "relative", float: "left", marginTop: this.props.outlined ? 5 : 0}}>
              {this.props.handleAction ?
                <Chip
                  variant="outlined"
                  key={j}
                  style={this.makeChipStyle(tag)}
                  label={tag.name}
                  deleteIcon={this.actionIcon()}
                  onDelete={() => this.handleAction(tag)}
                  onClick={(e) => {
                    if (this.props.disable || tag.state === 'disable') return
                    this.handleClickTag(e, tag.id);
                    this.handleChange()
                  }}
                />
                :
                <Chip
                  variant="outlined"
                  key={j}
                  style={this.makeChipStyle(tag)}
                  label={tag.name}
                  onClick={(e) => {
                    if (this.props.disable || tag.state === 'disable') return
                    this.handleClickTag(e, tag.id);
                    this.handleChange()
                  }}
                />
              }
            </div>
          )
        })}
      </div>
    )
  }

}

HomeItemDescriptionTags.propTypes = {
  tags: PropTypes.array.isRequired,
  onSelectedTagsChanged: PropTypes.func,
  onSelectedTagChanged: PropTypes.func
}

HomeItemDescriptionTags.defaultProps = {
  isClickable: true
}

export default HomeItemDescriptionTags;
