import React, {Component} from "react";
import Card from "../../../components/Card/Card";
import {request} from "../../../api-client";
import moment from "moment";
import {makeFitImageUrlWithSize, setStatePromise} from "../../../utils";
import {Fullscreen, VolumeOff} from "@material-ui/icons";
import {Button, Dialog, IconButton, TextField} from "@material-ui/core";
import './Livestream.css'
import styled from "styled-components";
import Vimeo from "@vimeo/player"
import browserHistory from "../../../utils/browserHistory";
import {
  channelPathGenerator,
  getMainTranslation,
  slugify
} from "../../../api-client/core/authentication/utils";
import HomeElementCarousel from "../../Home/HomeElements/HomeElementCarousel";
import localization from "../../../config/localization";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {onImageError} from "../../Home/HomeElements/utils";


export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      livestream: null,
      showOpeningImage: false,
      showEndImage: false,
      timeDiff: 0,
      muted: false,
      fullscreen: false,
      loaded: false,
      playlist: null,
      showTokenModal: false,
      tokenNotValid: false,
      validatingToken: false,
      ticket: "",
      trackingTimes: 0,
      liveRestricted: false
    };

    this.refVimeoPlayer = null;
    this.setVimeoPlayer = element => {
      this.refVimeoPlayer = element
    }
}

  componentDidMount = () => {
    this.interval = setInterval(this.sendTracking, 30000);
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.setLivestream()
      .then((livestream) => {
        const live  = livestream ? livestream : this.props.location.state.livestream;
      
        if (live.region_restricted) {
          request.liveStream.canAccess(live.id)
              .catch(() => this.setState({liveRestricted: true}))
        }
        
        this.setState({
          livestream: live,
          showTokenModal: live.is_monetized,
        }, () => {
          analyticsInstance.visitContent({
            name: getMainTranslation(live, "name"),
            content_type: live.fake ? "Premiere" : "Live",
          })
          if (localStorage.getItem(`live-${this.props.match.params.livestreamId}`)) {
            this.checkCode(localStorage.getItem(`live-${this.props.match.params.livestreamId}`))
          }
          if (this.state.livestream && this.state.livestream.has_suggested && this.state.livestream.suggested_playlist) {
            request.playlist.getForChannel(this.state.livestream.suggested_playlist, 'include=contentCreators;channel')
              .then(playlist => this.setState({playlist}))
          }
        })
      })
      .then(() => {
        // GAevent('Contenido', "Play", this.state.livestream.fake ? `Estreno: ${this.state.livestream.name}` : `Vivo: ${this.state.livestream.name}`)
        analyticsInstance.playContent({
          name: getMainTranslation(this.state.livestream, "title"),
          type: this.state.livestream.fake ? "Premiere" : "Live",
        })
      })
      .then(() => this.processLivestreamStartTime(this.state.livestream))
      .then(() => this.setState({loaded: true}))


    document.addEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  };

  componentWillUnmount = () => {
    document.removeEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
    document.removeEventListener('mouseover', this.forceAutoplay)
    clearInterval(this.interval);
  }

  setLivestream = () => {
    if (!this.props.location.state) {
      return request.liveStream.get(this.props.channel.id, this.props.match.params.livestreamId)
    } else {
      return new Promise(resolve => {
        resolve()
      })
    }
  }

  sendTracking = () => {
    if(!this.state.showOpeningImage && !this.state.showEndImage) {
      this.setState(prevState => ({
        trackingTimes: prevState.trackingTimes + 1
      }), () => analyticsInstance.videoTracking({
        name: getMainTranslation(this.state.livestream, "name"),
        type: this.state.livestream.fake ? "Premiere" : "Live",
        seconds_viewed: this.state.trackingTimes * 30,
      }));
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.location.pathname !== this.props.location.pathname && !this.props.preview) {
  //     this.state.livestream && GAevent('Contenido', "Play", this.state.livestream.fake ? `Estreno: ${this.state.livestream.name}` : `Vivo: ${this.state.livestream.name}`)
  //     this.processLivestreamStartTime(this.state.livestream)
  //       .then(() => this.setState({loaded: true}))
  //   }
  // }

  processLivestreamStartTime = (livestream) => {
    if (!this.props.preview) {
      let timeDiff = moment().diff(moment.utc(livestream.start_time).local(), "seconds")
      let timeDiffToEnd = moment().diff(moment.utc(livestream.end_time).local(), "seconds")

      if (timeDiff < 0 ) {
        this.setState({showOpeningImage: true},
          () => {
            setTimeout(() => {
              clearInterval(this.countdown);
              this.setState({showOpeningImage: false})
            }, Math.abs(timeDiff * 1000))

            if (!livestream.startImage || livestream.show_countdown ) {
              this.setCountdown(livestream)
              this.countdown = setInterval(() => this.setCountdown(livestream), 10000)
            }
          })
      } else {
        this.setState({timeDiff})
      }

      if (livestream.contentCreator && timeDiffToEnd > 0) {
        browserHistory.push(channelPathGenerator(`contenido/${livestream.contentCreator.id}-${slugify(livestream.contentCreator.name)}`))
      }

    if (livestream.source === 'youtube' || livestream.source === 'vimeoLive') {
      if (timeDiffToEnd < 0) {
        setTimeout(() => this.handleEndVideoEvents(),
          Math.abs(timeDiffToEnd*1000))
      } else {
        this.handleEndVideoEvents()
      }
    }

    } else {
      this.setState({})
    }

    return new Promise(resolve => {
      resolve()
    })
  }

  forceAutoplay = () => {
    this.videoRef.play()
      .catch(() => this.setState({muted: true}))
  }

  setCountdown = (livestream) => {
    let difference = moment.utc(livestream.start_time).local().diff(moment(), "minutes");
    // Output the result
    let countdownElement = document.getElementById("countdown")

    if (countdownElement)  {
      countdownElement.innerHTML = difference + 1;
    }
    return difference
  }

  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  unmute = () => {
    this.videoRef.muted = false
    this.setState({muted: false})
  }

  fullScreen = () => {
    if (!/iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        document.getElementById("live-container").requestFullscreen()
          .then(() => this.setState({fullscreen: true}))
      } else {
        document.exitFullscreen()
          .then(() => this.setState({fullscreen: false}))
      }

    }
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.setState({fullscreen: false})
    }
  }

  toggleVideoState = (toPlayingState, action, event) => {
    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
  }

  playOrPause = () => {
    if (document.getElementById("video").paused) {
      this.play()
    } else {
      this.pause()
    }
  }

  resetState = () => {
    return setStatePromise(this, {
      livestream: null,
      showOpeningImage: false,
      showEndImage: false,
      timeDiff: 0,
      muted: false,
      fullscreen: false,
      loaded: false,
    })
  }

  fetchNextLivestream = () => {
    return request.liveStream.checkIfActive(this.props.channel.id)
      .then(livestreams => {
        if (livestreams.length > 1 || (livestreams.length === 1 && livestreams[0].id !== this.state.livestream.id)) {
          browserHistory.push(channelPathGenerator(`vivos`))
        }
      })
  }


  handleEndVideoEvents = () => {
    this.setVimeoPlayer(null)
    this.setState({showEndImage: true}, () => {
      if (!this.props.preview) {
        this.fetchNextLivestream()
      }
    })
  }

  renderPlaylistElements = () => {
    const Container = ({ children }) => <div style={{
      float: 'left',
      position: 'relative',
      width: '100%'
    }}>{children}</div>
    let content = <HomeElementCarousel
      {...this.props}
      homeElement={this.makeHomeElementProp( this.state.playlist.contentCreators)}
      dataType={'content_creator'}
      onlyImage={this.state.livestream.suggested_view == "16:9"}
      numbered={false}
      shape={this.state.livestream.suggested_view}
      columns={3}
      suggestedCarousel
      realated={true}
    />

    return <Container>{content}</Container>
  }

  makeHomeElementProp = (contentCreators) => {
    return {
      ...contentCreators,
      items: {
        data: contentCreators.map(item => ({
          ...item,
          title: item.name,
          shortDescription: item.description,
          kind: "content_creator",
        }))
      }
    }
  }

  makeUrl = () => `https://www.youtube.com/embed/${this.state.livestream.stream_id}?autoplay=1&modestbranding=1&rel=0`

  makeFakeUrl = (livestream) => livestream.video.url + `#t=${this.state.timeDiff}`

  renderVimeo = () => {
    let videoId = this.state.livestream.stream_id.split('/')
    let options = {
      id: videoId[videoId.length - 1],
      width: window.innerWidth*0.799,
      height: window.innerHeight*0.8,
      loop: false,
      autoplay: true,
    }

    const player = new Vimeo('vimeo-player', options);

    player.on('ended', () => {
      console.log('Ended the video');
      this.handleEndVideoEvents()
    });

    if ((this.state.livestream.fake || this.state.livestream.source === 'vimeoLive') && !this.props.preview) {
      player.getDuration().then(duration => {
        if (this.state.timeDiff < duration) {
          player.setCurrentTime(this.state.timeDiff)
        } else {
          player.setCurrentTime(duration)
        } 
      })
    }
  }

  checkCode = (ticket = this.state.ticket) => {
    if (!this.state.validatingToken && ticket) {
      this.setState({validatingToken: true},
        () => request.liveStream.checkToken(this.props.channel.id, this.props.match.params.livestreamId, ticket)
          .then((res) => {
            if (res && res.is_valid) {
              localStorage.setItem(`live-${this.props.match.params.livestreamId}`, ticket)
              this.setState({showTokenModal: false})
            } else {
              if (localStorage.getItem(`live-${this.props.match.params.livestreamId}`)) {
                localStorage.removeItem(`live-${this.props.match.params.livestreamId}`)
              } else {
                this.setState({tokenNotValid: true})
              }
            }
          })
          .catch((e) => {
            console.log(e)
            this.setState({tokenNotValid: true})
          })
          .finally(() => this.setState({validatingToken: false}))
      )
    }
  }


  useTokenDialog = () =>
    <Dialog open={this.state.showTokenModal}>
      <Card style={{
        display: "flex",
        flexDirection: "column",
        height: 350,
        margin: 0,
        padding: 30,
        justifyContent: "space-around",
        position: "relative",
        background: "var(--main-color, #FFF)",
        border: "1px solid var(--main-font-color, #000)",
      }}>
        <h3 style={{textAlign: "center", color: "var(--main-font-color, #000)", fontSize: 18}}>{localization.get('livestream.code.message')}</h3>
        <br/>
        <br/>
        <TextField
          autofocus
          inputProps={{
            style: {
              color: "var(--main-font-color, #000)",
              borderBottom: `2px solid var(--main-font-color, #000)`,
              "&:before,&:after": {
                borderBottom: `2px solid var(--main-font-color, #000)`,
              },
          }}}
          margin="dense"
          fullWidth
          type={"text"}
          name={"ticket"}
          onKeyPress={(event) => event.key === 'Enter' && this.checkCode() }
          onChange={event => this.setState({ticket: event.target.value})}
          value={this.state.ticket}
          placeholder={localization.get('livestream.code')}
        />
        <br/>
        <br/>
        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
          <Button
            style={{color: "var(--main-font-color, #000)", flex: "end"}}
            onClick={() => browserHistory.push(channelPathGenerator('vivos'))}>
            {localization.get('go_back')}
          </Button>
        <Button
          style={{color: "var(--main-font-color, #000)", flex: "end"}}
          onClick={() => this.checkCode()}>
          {this.state.validatingToken ? <img style={{width: 25, height: 25}} src={LoadingSpinner} alt="Loading logo"/>
            : this.state.tokenNotValid ? localization.get('livestream.access.fail')
            : localization.get('livestream.access')
          }
        </Button>
        </div>
      </Card>
    </Dialog>

  render = () => {
    const {loaded, livestream, showOpeningImage, showEndImage, showTokenModal, liveRestricted} = this.state

    if (liveRestricted) {
      return(
      <div style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
          <p style={{textAlign: 'center', fontSize: 30, fontWeight: "bold"}}>{localization.get('no_content.region_blocked')}</p>
      </div>)
    }


    if (loaded) {
      const isVimeoLiveEvent = livestream && (livestream.source === 'vimeo' || livestream.source === 'vimeoLive') && livestream.stream_id.includes('/event/');
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {this.useTokenDialog()}
          <Card style={{alignItems: "center", boxShadow: 'none', backgroundColor: "var(--main-color, white)", marginTop: 20}}>
            <h3 style={{width: window.innerWidth*0.8, color: "var(--main-font-color, black)", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: !showOpeningImage ? "left" : "center"}}>
              {livestream.name}
            </h3>
            {showTokenModal ?
              <Img
                alt="opening_image"
                style={{height: "100%"}}
                src={makeFitImageUrlWithSize({image: livestream.startImage}, 'lg')}
                onError={onImageError}
              />
              :
              <LiveContainer id="live-container" className="live-container" style={!isVimeoLiveEvent ? {
                width: window.innerWidth * 0.8,
                height: window.innerHeight * 0.8
              } : window.innerWidth > 650 ? {width: "80vw", height: "45vw"} : {
                width: "100vw",
                height: "calc(100vw / 16 * 9)"
              }}>
                {!showOpeningImage && !showEndImage &&
                  ((livestream.source === 'vimeo' || livestream.source === 'vimeoLive') ?
                    <>
                      {isVimeoLiveEvent ?
                        <>
                          <div id='vimeo-player' style={{padding: "56.5% 0 0 0", position: "relative"}}>
                            <iframe src={`${livestream.stream_id}/embed`} frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%'
                            }}></iframe>
                          </div>
                        </>
                        :
                        <>
                          <div id='vimeo-player' ref={this.setVimeoPlayer}></div>
                          {this.refVimeoPlayer && this.renderVimeo()}
                        </>
                      }
                    </>
                    : livestream.source === 'youtube' ?
                      <iframe width={window.innerWidth * 0.8} height={window.innerHeight * 0.8} src={this.makeUrl()}
                              allow="autoplay" frameBorder="0" allowFullScreen/>
                      :
                      <>
                        <div className="live-controls" style={{display: 'none'}}>
                          <span style={{
                            position: 'absolute',
                            color: 'white',
                            zIndex: 2,
                            left: 10,
                            top: 5
                          }}>{livestream.name}</span>
                          {this.state.muted &&
                            <IconButton onClick={() => this.unmute()}
                                        style={{height: 40, width: 30, right: 35, position: 'absolute', zIndex: 2}}>
                              <VolumeOff style={{fontSize: "25px", color: "white"}}/>
                            </IconButton>
                          }
                          <IconButton onClick={() => this.fullScreen()}
                                      style={{height: 40, width: 30, right: 5, position: 'absolute', zIndex: 2}}>
                            <Fullscreen style={{fontSize: "25px", color: "white"}}/>
                          </IconButton>
                        </div>
                        <video
                          id="video"
                          ref={ref => this.videoRef = ref}
                          style={{
                            width: "99.9%",
                            height: "100%",
                          }}
                          src={this.makeFakeUrl(livestream)}
                          autoPlay
                          onCanPlay={() => document.addEventListener('mouseover', this.forceAutoplay)}
                          onEnded={() => {
                            this.handleEndVideoEvents();
                            document.removeEventListener('mouseover', this.forceAutoplay)
                          }}
                          onPlaying={() => document.removeEventListener('mouseover', this.forceAutoplay)}
                          onDoubleClick={() => this.fullScreen()}
                          onClick={() => this.playOrPause()}
                          muted={this.state.muted}
                          onContextMenuCapture={(event) => {
                            event.preventDefault();
                            return false
                          }}
                        />
                      </>
                  )}
                {showOpeningImage && livestream.startImage ?
                  <>

                    {livestream.show_countdown && moment.utc(livestream.start_time).local().diff(moment(), "minutes") < 30 &&
                      <h3 style={{color: 'white'}}>{localization.get(`livestream.starting`)} <span id="countdown"></span> {localization.get(`livestream.starting.minutes`)}</h3>}
                    <Img
                      alt="opening_image"
                      style={{height: livestream.show_countdown ? "calc(100% - 90px)" : "100%"}}
                      src={makeFitImageUrlWithSize({image: livestream.startImage}, 'lg')}
                      onError={onImageError}  
                    />
                  </>
                  : (showOpeningImage && !livestream.startImage && livestream.show_countdown && moment.utc(livestream.start_time).local().diff(moment(), "minutes") < 30) &&
                  <h3 style={{color: 'white'}}>{localization.get(`livestream.starting`)} <span id="countdown"></span> {localization.get(`livestream.starting.minutes`)}</h3>
                }
                {showEndImage && livestream.endImage &&
                  <Img
                    alt="end_image"
                    src={makeFitImageUrlWithSize({image: livestream.endImage}, 'lg')}
                    onError={onImageError}    
                  />
                }
              </LiveContainer>
            }
            {livestream.has_suggested && this.state.playlist &&
              <RelatedContainer >
                {this.renderPlaylistElements()}
              </RelatedContainer>
            }

          </Card>
        </div>
      )
    } else {
      return <div></div>
    }

  }
}

const LiveContainer = styled.div`
  background-color: black;
  text-align: center;
@media (max-width: 500px) {
   width: 100vw!important;
}
`;

const Img = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
@media (max-width: 500px) {
    object-fit: contain;
    width: 100%;
}
`;
const RelatedContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
  margin-top: 30px;
  padding: 20px 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`