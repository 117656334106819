import React from "react";
import {TextValidator} from "react-material-ui-form-validator";
import localization from '../../../config/localization';
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import {Checkbox, FormControl, FormControlLabel} from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import {Radio, RadioGroup} from "@material-ui/core/index";
import {request} from "../../../api-client";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import {get} from "lodash";

class FormView extends React.Component {
  

  findMissingFields = () => this.child.findMissingFields()

  render = () => {
    const {
      disabled,
      translations,
      onChangeTranslation,
      order,
      types,
      groupType,
      onChange,
      onTypeChange,
      selectedTypes,
      isMusic,
      parent,
      isEditorial,
      isAudiovisual,
      isTalent,
      isAdmin,
      childrens,
      id,
    } = this.props

    return (
      <div>

        {!isMusic && !isEditorial &&
        <div>
          <span>{localization.get("tag_groups.type")}</span>
          <RadioGroup
            id="groupType"
            value={groupType}
            onChange={e => {
              e.target.id = "groupType";
              onChange(e)
            }}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="both" control={<Radio color="default"/>} label={localization.get('tag_groups.type.both')}/>
            <FormControlLabel value="content" control={<Radio color="default"/>} label={localization.get('tag_groups.type.content')}/>
            <FormControlLabel value="material" control={<Radio color="default"/>} label={localization.get('tag_groups.type.material')}/>
          </RadioGroup>
        </div>
        }
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={isAdmin}
              onChange={() => onChange({target: {id: "isAdmin", value: !isAdmin}})}
            />
          }
          label={localization.get('tag_group.for_admins')}
        />

        <FormControl fullWidth>
          <span>{localization.get('form.select_content_creator_type')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('content_creator_types')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onTypeChange}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
            isMulti={groupType !== 'both'}
            value={selectedTypes}
            loadOptions={(inputValue, callback) => {
              callback(types.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              ))
            }}
            defaultOptions={types}
          />
        </FormControl>
        <TextValidator
          style={{ width: "100%" }}
          id="order"
          label={localization.get('form.order')}
          name="order"
          value={order}
          onChange={onChange}
          disabled={disabled}
          validators={['isNumber']}
          errorMessages={[localization.get('validator.is_number')]}
        />
        <TranslatableInputs
          ref={instance => this.child = instance}
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  id={`name${language}`}
                  style={{width: "100%"}}
                  label={localization.get('form.name')}
                  name={`name${language}`}
                  value={currentLangTranslation.name}
                  onChange={e => onChangeTranslation(e.target.value, "name", language)}
                />
              ]
            }
          })}
        />

        {!isMusic && selectedTypes.length > 0 &&
          <FormControl fullWidth>
            <span>{localization.get('tag_group.parent')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('tag_group.parent')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={(parent) => onChange({target: {id: 'parent', value: parent}})}
              value={parent}
              isDisabled
            />
            <br/>
            <span>{localization.get('tag_group.childrens')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('tag_group.childrens')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={(childrens) => onChange({target: {id: 'childrens', value: childrens}})}
              value={childrens}
              isMulti
              defaultOptions
              loadOptions={inputValue => {
                const filters = [{ column: "name", filter: inputValue }];
                
                filters.push({column: "types", filter: selectedTypes.map(type => type.value)});
                

                if (isEditorial) {
                  filters.push({column: "content_type", filter: 'audiovisual'});
                  filters.push({column: "is_editorial", filter: '1'});
                }else if (isAudiovisual) {
                  filters.push({column: "content_type", filter: 'audiovisual'});
                }else if (isTalent) {
                  filters.push({column: "content_type", filter: 'talent'});
                }
                
                return request.tagGroup.getAllPrivate({
                  filterBy: filters,
                 })
                 .then(res => res.filter(tg => tg.id !== id)
                  .map(tg => ({ value: tg.id, label: `${tg.name} (${get(tg.types[0],"contentCreatorType.name")})`})))
              }}
            />
            <span style={{color: "#ccc", fontSize: 12}}>{localization.get('tag_group.childrens_helper')}</span>
          </FormControl>
        }
      </div>
    )
  }
}

export default FormView
