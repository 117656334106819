import {post} from "../utils";
import {storeTokens} from "./utils";

const form = (username, password) => {
  const f = new FormData()
  f.append('username', username)
  f.append('password', password)
  return f
}

export default host => ({
    username,
    password
  }) => post(`${host}/login`, form(username, password), {"Access-Control-Allow-Origin": "true"}, () => {}, false, false)
  .then(result => result.json())
  .then(storeTokens)