import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {getActiveChannel, getStoredUser} from "../../../api-client/core/authentication/utils";
import logout from "../../../api-client/core/authentication/logout";
import Email from "@material-ui/icons/Email";
import {Link} from "react-router-dom";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import ChannelPublicNavBar from "../../../layouts/Channel/ChannelPublicNavBar";
import Footer from "../../../layouts/Home/HomeElements/Footer";
import {channelPathGenerator} from "../../../api-client/core/authentication/utils";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      channel: ""
    };
  }
  componentDidMount() {

    if (getStoredUser()) {
      request.authentication.logout()
    }
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY, this.reCaptchaLoaded);
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    request.channel.getPublic(getActiveChannel() ? getActiveChannel() : window.location.pathname.split('/')[2])
      .then(channel => {
        document.title = localization.get('title.forgot') + ' - ' + channel.name
        this.setState({channel, requesting: false})
      })
  }

  reCaptchaLoaded = callback => console.log("loaded")

  verifyCallback = token => this.setState({token: token})

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  // <TryAgainSnackbar ref='snackbar' />
  // <Snackbar
  //     ref='successSnackbar'
  //     message={localization.get('forgot.success')}
  //     color={'success'}
  // />

  activate = () => {
    this.setState({ requesting: true })

    this.updateToken()
    request.authentication.forgot({ username: this.state.username, channelId: this.state.channel.id }, this.state.token)
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('forgot.success'),
          type: GlobalSnackbarTypes.SUCCESS,
        })
        setTimeout(() => {
          this.props.history.push(channelPathGenerator('login', this.state.channel))
        }, 3000);
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: localization.get('forgot.fail'),
          type: GlobalSnackbarTypes.ERROR,
        })
        this.setState({ requesting: false })
      })
  }

  render() {
    const { classes } = this.props;
    const { channel } = this.state;

    return (
      <React.Fragment>
        <ChannelPublicNavBar channel={channel} />
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>{localization.get('recover_password')}</h4>
                    </CardHeader>
                    <CardBody>
                      <p style={{ margin: "5px", fontSize: "0.875rem" }}>{localization.get('recover_password.auxiliary')}</p>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ username: e.target.value }),
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.activate()
                            }
                          }
                        }}
                      />
                      <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        action='reset_password'
                        verifyCallback={this.verifyCallback}
                      />
                      {this.state.channel &&
                        <Link to={getActiveChannel() ? (this.props.publicChannel ? channelPathGenerator(`login`) : `/login`) : `channel/${this.state.channel.id}/login`}>
                          <p style={{ textAlign: "center", color: "black", fontSize: "0.875rem" }}>{localization.get('already_remember')}</p>
                        </Link>
                      }
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.activate} color="default" style={{ backgroundColor: "black" }} simple size="lg" block disabled={this.state.requesting}>{localization.get('send')}</Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer channel={this.state.channel}/>
      </React.Fragment>
    );
  }
}

export default withStyles(loginPageStyle)(ForgotPassword);
