import React from "react";
import {request} from "../../../api-client";
import {getActiveClient} from "../../../api-client/core/authentication/utils";
import {Dialog} from "@material-ui/core";
import SocialLinks from "../../../components/Letflow/SocialLinks";
import localization from '../../../config/localization';
import FormViewContainer from '../../../components/Letflow/ViewContainer/FormViewContainer';
import {TextValidator} from "react-material-ui-form-validator";
import {ImagePicker} from "../../../components/Letflow/Form";
import {makeFitImageUrl} from "../../../utils";

class ClientSocialLinksDialog extends React.Component {

  state = {
    socialLinks: [],
    email: '',
    phone: '',
    address: '',
    reelsLogo: { src: null, file: null },
  };

  componentDidMount = () => {
    request.client.get(getActiveClient(), "include=socialLinks;reelsLogo")
      .then(client => this.setState({
        socialLinks: client.socialLinks,
        email: client.email,
        phone: client.phone,
        address: client.address,
        image: {image: client.reelsLogo},
      }))
      .then(() => this.setState({
        reelsLogo: {src: makeFitImageUrl(this.state.image), file: null},
      }))
  };

  handleSubmit = () => request.client.update(getActiveClient(), {
    socialLinks: this.state.socialLinks,
    phone: this.state.phone,
    email: this.state.email,
    reelsLogo: true,
    image: this.state.reelsLogo.file
  }).then(() => this.props.handleClose());

  render = () => {
    let isMobile = window.innerWidth < 550;

    return (
      <Dialog style={{margin: "-35px"}} open={this.props.open} onBackdropClick={this.props.handleClose} onEscapeKeyDown={this.props.handleClose}>
        <FormViewContainer
          title={localization.get('reel.settings')}
          submitRequest={this.handleSubmit}
          onBack={this.props.handleClose}
          style={!isMobile ? {minWidth: "600px", width: "100%", display: "table"} : {width: "100%", display: "table"}}
          color="black"
          smallExtraSpace={true}
        >
          <div style={{maxHeight: "60vh", overflowY: "auto"}}>
            <TextValidator
              style={!isMobile ? {width: "80%", margin: "15px 30px 0"} : {width: "90%", marginTop: "15px"}}
              label={localization.get('form.email')}
              value={this.state.email}
              onChange={e => {this.setState({email: e.target.value})}}
              name="email"
            />
            <TextValidator
              style={!isMobile ? {width: "80%", margin: "15px 30px 0"} : {width: "90%", marginTop: "15px"}}
              label={localization.get('form.phone')}
              value={this.state.phone}
              onChange={e => {this.setState({phone: e.target.value})}}
              name="phone"
            />
            <TextValidator
              style={!isMobile ? {width: "80%", margin: "15px 30px 0"} : {width: "90%", marginTop: "15px"}}
              label={localization.get('form.address')}
              value={this.state.address}
              onChange={e => {this.setState({address: e.target.value})}}
              name="address"
            />
            <div style={{ padding: !isMobile ? "30px" : "30px 0"}}>
              <SocialLinks
                socialLinks={this.state.socialLinks}
                onSocialLinksChange={(socialLinks) => this.setState({socialLinks})}
                homeView={true}
              />
            </div>
            <p>{localization.get('reel.edit_reels_logo_text')}</p>
            <br/>
            <ImagePicker
              buttonText={localization.get('form.select_image')}
              image={this.state.reelsLogo.src}
              onImageChange={ reelsLogo => this.setState({ reelsLogo })}
              color={'default'}
              style={{backgroundColor: "black"}}
              accept="image/png"
            />
          </div>
        </FormViewContainer>
      </Dialog>

    )
  }
}

export default ClientSocialLinksDialog