import React, {Component} from 'react'
import ViewContainer from '../ViewContainer';
import PropTypes from 'prop-types'
import Form from '../../Form';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../Snackbar/GlobalSnackbar';
import localization from '../../../../config/localization';
import EventManager from "../../../../utils/EventManager";
import events from "../../../../utils/EventManager/events";
import _ from 'lodash'

class FormViewContainer extends Component {

  state = {
    requesting: false,
    disabled: this.props.disabled || false
  }

  componentDidMount = () =>
    EventManager.getInstance().subscribe(events.MISSING_FIELDS_TRANSLATED, this.onSubmit);


  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.disabled !== this.state.disabled) {
      this.setState({disabled: nextProps.disabled})
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.MISSING_FIELDS_TRANSLATED, this.onSubmit);
  }

  onSubmit = (data) => {
    let request = (data && data.altSubmit) ? this.props.altSubmitRequest : this.props.submitRequest;
    this.setState({requesting: true})
    request()
      .then(r => {
        this.setState({requesting: false})
        if (!this.props.errors || _.isEmpty(this.props.errors)) {
          GlobalSnackbar.show({
            message: this.props.sucessMessage || localization.get('snackbar.entity_update_success'),
            type: GlobalSnackbarTypes.SUCCESS
          })
        }
        return r
      })
      .catch(() => {
        this.setState({requesting: false})
        GlobalSnackbar.show({
          message: this.props.errorMessage || localization.get('snackbar.entity_update_fail'),
          type: GlobalSnackbarTypes.ERROR
        })
      })
  }

  setRequestingState = value => {
    this.setState({ requesting: value })
    if (this.props.onRequestingStateChanged) {
      this.props.onRequestingStateChanged(value)
    }
  }

  render = () =>
    <ViewContainer
      ref='viewContainer'
      onRequestingStateChanged={this.setRequestingState}
      initializationRequest={this.props.initializationRequest}>
      <Form style={this.props.style || {}}
        title={this.props.title}
        color={this.props.color}
        onBack={this.props.onBack}
        extraSpace={this.props.extraSpace}
        smallExtraSpace={this.props.smallExtraSpace}
        actionsExtraSpace={this.props.actionsExtraSpace}
        hasNextStep={this.props.hasNextStep}
        onNextStep={this.props.onNextStep}
        altSubmit={this.props.altSubmit}
        onAltSubmit={() => {
          if (this.props.altSubmitValidation && this.props.validation) {
            if (this.props.altSubmitValidation() && this.props.validation()) this.onSubmit({altSubmit: true})
          } else if (this.props.altSubmitValidation) {
            if (this.props.altSubmitValidation()) this.onSubmit({altSubmit: true})
          } else if (this.props.validation) {
            if (this.props.validation()) this.onSubmit({altSubmit: true})
          } else {
            this.onSubmit({altSubmit: true})
          }
        }}
        onSubmit={() => {
          if (this.props.validation || this.props.hasTranslatableInputs) {
            if (!this.props.hasTranslatableInputs && this.props.validation()){
              this.onSubmit()
            } else if (!this.props.validation && this.props.hasTranslatableInputs) {
              EventManager.getInstance().dispatch(events.CHECK_MISSING_TRANSLATABLE_INPUTS)
            } else {
              if (this.props.validation()) {
                this.setState({requesting: true})
                EventManager.getInstance().dispatch(events.CHECK_MISSING_TRANSLATABLE_INPUTS)
              }
            }
          } else {
            this.onSubmit()
          }
        }}
        disabled={this.state.disabled ? this.state.disabled : this.state.requesting}
      >
        {this.props.children}
      </Form>
    </ViewContainer>

}

FormViewContainer.propTypes = {
  initializationRequest: PropTypes.func,
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  submitRequest: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onRequestingStateChanged: PropTypes.func,
}

export default FormViewContainer
