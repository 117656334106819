
export const makeFormData = ({
  image,
  second
}) => {
  const form = new FormData();
  if (image) form.append('image', image, image.name);
  if (second) form.append('second', second);
  return form
};

