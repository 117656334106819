import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    title,
    reference,
    url,
    phone,
    description,
    duration,
    territory,
    budget,
    clientId
  }) =>
  post(`${host}/rightsProcedures`, makeFormData({
    title,
    reference,
    url,
    phone,
    description,
    duration,
    territory,
    budget,
    clientId
  }))
  .then(response => response.json())