import React from "react"
import RankingChart from './RankingChart'
import {resolveKey} from "./utils";

const getReference = (data, labelKey, valueKey, formatter = (value => value)) =>
  <div style={{ display: 'inline-grid', gridTemplateColumns: 'auto auto', padding: '10px', width: "300px" }}>
    {data.map((value, i) => [
      <div key={`key_${i}`} style={{ fontWeight: 'bold', fontSize: 'small' }}>{resolveKey(labelKey, value)}:</div>,
      <div key={`value_${i}`} style={{ textAlign: 'right' }}>{formatter(resolveKey(valueKey, value))}</div>
    ])}
  </div>

const TotalizedRankingChart = ({title, data, idKey, labelKey, valueKey, name, formatter, colors}) =>
  <RankingChart
    title={title}
    data={data}
    idKey={idKey}
    labelKey={labelKey}
    valueKey={valueKey}
    name={name}
    formatter={formatter}
    colors={colors}
    reference={getReference(data, labelKey, valueKey, formatter)}
  />

export default TotalizedRankingChart