import React from 'react'
import {Star, StarBorder} from '@material-ui/icons'
import SimpleButton from '../utils/SimpleButton';

const Favorite = ({ onClick, isFavorite, addToFavoritesTooltip, removeFromFavoritesTooltip }) => {

  const icon = isFavorite ? <Star /> : <StarBorder />

  return (
    <SimpleButton onClick={onClick} tooltip={isFavorite ? removeFromFavoritesTooltip : addToFavoritesTooltip}>{icon}</SimpleButton>
  )
}

export default Favorite