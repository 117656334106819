import React, {Component} from "react";
import {ClientSidePaginationTable} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {CloudDownload, Delete, PlayArrow} from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import ContentCreationPlayer from "../../../components/Letflow/ContentCreationPlayer";
import {Checkbox, IconButton, Tooltip} from "@material-ui/core";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";

class TableSubcomponent extends Component {
  state = {
    files: [],
    showPlayDialog: false,
    selectedFiles: [],
  };

  componentDidMount = () => {
    request.batchUpload
      .get(this.props.link.id, 'include=files.video;files.audio;files.image;files.binary')
      .then(link => this.setState({ files: link.files }));
  };

  buttons = batchUploadFile => {
    const builder = new TableButtonsBuilder()

    if (batchUploadFile.type !== 'other') {
      builder.withOther(<TableButton title={localization.get('tooltip.play')} onClick={() => this.playContent(batchUploadFile)}><PlayArrow/></TableButton>)
    }

    builder.withOther(
          <TableButton
            title={localization.get('download')}
            onClick={() => window.open(
              batchUploadFile.type === 'image' ? request.batchUpload.downloadImage(batchUploadFile.id)
                : batchUploadFile.type === 'other' ? batchUploadFile.binary.url
                : batchUploadFile[batchUploadFile.type].url
              , "_blank")}
          >
            <CloudDownload/>
          </TableButton>
    )

    builder.withDelete(batchUploadFile.filename, () => request.batchUpload.deleteFile(batchUploadFile.id), status => {
      if (status === 'passed') {
        this.refs.tableSubcomponent.removeById(batchUploadFile.id)
      }
    })
    return builder.build();
  }

  playContent = material => this.setState({ showPlayDialog: true, currentPlayingContent: material})

  onChangeFilesToDelete = id => {
    if (this.state.selectedFiles.includes(id)) {
      this.setState({selectedFiles:  this.state.selectedFiles.filter(val => val ==id)});
    } else{
      this.state.selectedFiles.push(id)
      this.setState({ selectedFiles: this.state.selectedFiles });
    }
  }

  render = () => {

    return (
      <div style={{ margin: "20px", display: "flex", flexDirection: "column" }}>
        {this.state.selectedFiles.length > 0 &&
          <div style={{ float: "left", position: "relative" }}>
            <h4 style={{
              float: "right",
              position: "relative",
              width: "auto",
              marginLeft: 15,
            }}>
              {localization.get("view.content_creation.delete_many")}
              <Tooltip placement="top" style={{ float: "right", position: "relative" }} title={localization.get('view.content_creation.delete_many')}>
                <IconButton onClick={() =>
                  GlobalConfirmationDialog.showDelete({
                    elementName: localization.get('form.double_confirmation_delete_many.label'),
                    content: "confirmation_dialog.delete_many_materials",
                    deleteRequest: () => new Promise(resolve => {
                      Promise.all(this.state.selectedFiles.map(id => request.batchUpload.deleteFile(id)))
                        .then(() => this.setState({
                          files: this.state.files.filter(file => !this.state.selectedFiles.includes(file.id)),
                          selectedFiles: []
                        }))
                        .then(resolve)
                    }),
                    doubleConfirmation: true,
                    doubleConfirmationText: "form.double_confirmation_delete_many",
                  })
                }>
                  <Delete/>
                </IconButton>
              </Tooltip>
            </h4>
          </div>
        }
        <ContentCreationPlayer
          show={this.state.showPlayDialog}
          currentContentCreation={this.state.currentPlayingContent}
          handleClose={() => this.setState({showPlayDialog: false, currentPlayingContent: {}})}
        />
        {this.state.files.length > 0 && (
          <ClientSidePaginationTable
          ref="tableSubcomponent"
            data={this.state.files.map((file, index) => ({
              ...file,
              index
            }))}
            columns={[
              {
                id: "check",
                accessor: file =>
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={!!this.state.selectedFiles.includes(file.id)}
                    onChange={() => this.onChangeFilesToDelete(file.id)}
                  />
                ,
                resizeable: false,
                sortable: false,
                filterable: false,
                width: 60
              },
              {
                accessor: "filename",
              },
              {
                id: 'actions',
                sortable: false,
                filterable: false,
                accessor: this.buttons
              }
            ]}
            filterable={false}
            sortable={false}
            showPagination={false}
            zebraStyle={false}
            perPage={999}
          />
        )}

      </div>
    );
  };
}

export default TableSubcomponent;
