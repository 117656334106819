import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  name,
  email,
  phone,
  country,
  state,
  description,
  instagramUrl,
  facebookUrl,
  spotifyUrl,
  soundCloudUrl,
  audios,
  createdIn,
}, onProgress) => post(`${host}/artists`, makeFormData({
  name,
  email,
  phone,
  country,
  state,
  description,
  instagramUrl,
  facebookUrl,
  spotifyUrl,
  soundCloudUrl,
  audios,
  createdIn
  }), null, onProgress)
.then(response => response.json())
