import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

export default ({ id, title, current, items, onChange, disabled = false, style, disableUnderline = false }) =>
  <FormControl fullWidth>
    <InputLabel>{title}</InputLabel>
    <Select
      disableUnderline={disableUnderline}
      style={style}
      value={current}
      onChange={e => {
        e.target.id = id
        onChange(e)
      }}
      inputProps={{
        disabled
      }}
    >
      {items.map(type => <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>)}
    </Select>
  </FormControl>
