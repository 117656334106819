import {post} from '../utils'
import {getActiveClient} from '../authentication/utils';

export default host => ( id ) => {
  const form = new FormData()
  let clientId = getActiveClient()
  if (clientId) {
    form.append('client_id', clientId)
  }
  return post(`${host}/clients/${getActiveClient()}/musics/${id}/favorite`, form)
}
