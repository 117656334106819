function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgLinktree = function SvgLinktree(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    viewBox: "0 0 48 48",
    width: "48px",
    height: "48px",
    ref: svgRef
  }, props), React.createElement("g", {
    id: "surface182654840"
  }, React.createElement("path", {
    style: {
      stroke: "none",
      fillRule: "nonzero",
      fill: "rgb(0%,0%,0%)",
      fillOpacity: 1
    },
    d: "M 18.890625 32 L 21 32 L 21 40 C 21 41.101562 20.101562 42 19 42 L 15 42 C 13.898438 42 13 41.101562 13 40 L 13 32 L 4.890625 32 C 3.371094 32 2.410156 30.359375 3.140625 29.03125 L 15.25 7.160156 C 16.011719 5.789062 17.988281 5.789062 18.75 7.160156 L 23.601562 16.898438 L 17.140625 29.03125 C 16.410156 30.359375 17.371094 32 18.890625 32 Z M 18.890625 32 "
  }), React.createElement("path", {
    style: {
      stroke: "none",
      fillRule: "nonzero",
      fill: "rgb(0%,0%,0%)",
      fillOpacity: 1
    },
    d: "M 43.109375 32 L 35 32 L 35 40 C 35 41.101562 34.101562 42 33 42 L 29 42 C 27.898438 42 27 41.101562 27 40 L 27 32 L 29.109375 32 C 30.628906 32 31.589844 30.359375 30.859375 29.03125 L 24.300781 16.898438 L 29.25 7.160156 C 30.011719 5.789062 31.988281 5.789062 32.75 7.160156 L 44.859375 29.03125 C 45.589844 30.359375 44.628906 32 43.109375 32 Z M 43.109375 32 "
  })));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgLinktree, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/linktree.cc7b6ce5.svg";
export { ForwardRef as ReactComponent };