import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeImageUrl, makeWaveformUrl} from '../../../utils'
import {Typography} from "@material-ui/core";
import localization from "../../../config/localization";
import {userCanDownloadUnMarkedTracksWithoutLicence} from "../../../api-client/core/authentication/utils";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {Visibility} from "@material-ui/icons";


class PlaylistSubcomponent extends Component {

  state = {
    playlist: null
  }

  componentDidMount = () => {
    request.playlist.get(this.props.playlist.id).then(playlist => this.setState({playlist}))
  }

  render = () => {

    const {playlist} = this.state
    const playlistImage = makeImageUrl(this.props.playlist, 50)
    const gotoPlaylist = () => this.props.history.push(this.props.playlistRoute || `/panel/playlists/${this.props.playlist.id}/edit`)

    const makeSoundplayerSongs = type => this.state.playlist[type + 's'].map(track => ({
      fetchAudioUrl: () => request[type].audioUrl(track.id),
      image: playlistImage,
      title: track.title,
      subtitle: this.props.playlist.title,
      onAnyClick: gotoPlaylist,
      waveform: makeWaveformUrl(track)
    }))

    let soundplayerMusicSongs = []
    if(playlist){
      soundplayerMusicSongs = makeSoundplayerSongs('music')
    }

    const makeTable = (type, soundplayerSongs) => this.state.playlist[type].length > 0 && <div>
      <Typography style={{fontSize: "20px", fontWeight: "bold"}}>{localization.get(type)}</Typography>
      <ClientSidePaginationTable
        data={this.state.playlist[type].map((track, index) => ({ ...track, index, belongsTo: type }))}
        columns={[
          {
            id: 'title', accessor: track => track.title || track.name
          },
          {
            id: 'actions', accessor: track => {
              const builder = new TableButtonsBuilder()
              track.audio && builder.withDownload(this.makeDownloadRequest(track), { title: track.title, duration: track.audio.duration, type: 'music' })
              track.audio && builder.withNewPlay(soundplayerSongs, track.index)
              type === 'contentCreators' && builder.withOther(<TableButton title={localization.get('button.see_home')} onClick={() => window.open(`${window.location.origin}/public/content_creators/${track.id}` , "_blank")}><Visibility/></TableButton>)

              return builder.build()
            }
          },
        ]}
        filterable={false}
        sortable={false}
        showPagination={false}
        zebraStyle={false}
      />
    </div>


    return (
      <div style={{ ...this.props.style, margin: '20px' }}>
        {playlist &&
          <React.Fragment>
            <div style={{marginBottom: "10px"}}>
              <b>{localization.get('created_by')}: </b>
              {playlist.user.name}
            </div>
            <div style={{marginBottom: "20px"}}>
              <b>{localization.get('created_date')}: </b>
              {localization.moment(playlist.created_at).format('LL')}
            </div>
            {playlist.musics.length === 0 && (!playlist.contentCreators || playlist.contentCreators.length === 0) &&
              <Typography>{localization.get('subcomponent.playlist_has_no_tracks')}</Typography>
            }
            {makeTable('musics', soundplayerMusicSongs)}
            {playlist.contentCreators && makeTable('contentCreators')}
            <div style={{height: '20px'}} />
          </React.Fragment>
        }
      </div>
    )

  }

  makeDownloadRequest = track => () => {
    if (track.belongsTo === 'musics') {
      if (userCanDownloadUnMarkedTracksWithoutLicence()) {
        return request.music.download(track.id)
      }
      return request.music.downloadDemo(track.id)
    }
  }
}

PlaylistSubcomponent.defaultProps = {
  style: {}
}

export default PlaylistSubcomponent