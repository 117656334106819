import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => (channelId, {owner, productType,  days, active, translations, prices, shipments, addresses, channelShipments, giftable}) =>
  post(`${host}/channels/${channelId}/products`, makeFormData({
    owner,
    productType,
    days,
    active,
    translations,
    prices,
    shipments,
    addresses,
    channelShipments,
    giftable,
}))
  .then(response => response.json())
  .then(json => json.data)
