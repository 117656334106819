import {appendPaginationQueryParamsToUrl, get} from '../utils'


const url = (host, albumId, artistId, type, catalogId, removed, options) => appendPaginationQueryParamsToUrl(`${host}/musics/candidates?${ albumId ? `&album_id=${albumId}` : '' }${ artistId ? `&artist_id=${artistId}` : '' }${ type ? `&type=${type}` : ''}${ catalogId ? `&catalog_id=${catalogId}` : ''}${ removed && removed.length > 0 ? `&removed_musics_ids=${removed.map(music => music.id).join(',')}` : '' }&include=tags;audio:url,duration;audio.waveform;artist;albums`, {...options, alreadyHasQueryParams: true})

export default host => (albumId, artistId, type, catalogId, removed, options) =>
  get(url(host, albumId, artistId, type, catalogId, removed, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


