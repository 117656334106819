import React from 'react'
import localization from '../../../../config/localization';
import {Home} from '@material-ui/icons'
import TableButton from './TableButton';
import {Link} from "react-router-dom";

const TableEditButton = ({path}) =>
  <Link to={path}>
    <TableButton title={localization.get('tooltip.home')}>
      <Home/>
    </TableButton>
  </Link>
export default TableEditButton