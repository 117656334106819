import {addPutMethodToForm, post, put,} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => ({
  clientTagId,
  name,
  level
  }) =>
  put(`${host}/clients/${getActiveClient()}/tags/${clientTagId}`, JSON.stringify({
    "name": name,
    "level": !isNaN(level) ? level : null
  }))
  .then(response => response.json())