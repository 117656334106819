import React from 'react'

import PropTypes from 'prop-types'
import localization from '../../../config/localization';
import {FormControl, MenuItem} from "@material-ui/core";
import {SelectValidator, TextValidator} from "react-material-ui-form-validator";

const states = [
  {id: 'trying', name: localization.get('trial.state.trying')},
  {id: 'dealing', name: localization.get('trial.state.dealing')},
  {id: 'dealt', name: localization.get('trial.state.dealt')},
  {id: 'not_dealt', name: localization.get('trial.state.not_dealt')},
]

const TrialsFormView = ({
  name,
  email,
  state,
  comments,
  onChange,
  disabled = false,
}) => {
  return (
    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get('form.name')}
        name="name"
        value={name}
        disabled={true}
      />
      <br/>
      <br/>
      <TextValidator
        style={{ width: "100%" }}
        id="email"
        label={localization.get('form.email')}
        name="email"
        value={email}
        disabled={true}
      />
      <br/>
      <br/>
      <FormControl fullWidth>
        <SelectValidator
          label={localization.get('form.state')}
          id="state"
          name='state'
          value={state}
          onChange={e => {
            e.target.id = 'state'
            onChange(e)
          }}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        >
          {states.map(st => <MenuItem value={st.id} key={st.id}>{st.name}</MenuItem>)}
        </SelectValidator>
      </FormControl>
      <br/>
      <br/>
      <TextValidator
        style={{ width: "100%" }}
        id="comments"
        label={localization.get('form.comments')}
        name="comments"
        value={comments}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

TrialsFormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  comments: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default TrialsFormView
