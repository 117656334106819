import React from 'react';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import localization from '../../../config/localization';
import Pause from '@material-ui/icons/Pause';
import { Radio } from '@material-ui/icons';
import analyticsInstance from "../../../analytics/analyticsInstance";
import { get } from "lodash"
import {getStoredChannel} from "../../../api-client/core/authentication/utils";

class RadioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      volume: 1,
      mute: false,
      url: props.url
    };
  }

  handlePlay = () => {
    if (!this.state.play) {
      analyticsInstance.playContent({
        name: "Radio",
        type: "radio",
        content: "Radio"
      })
    }
    this.setState({ play: !this.state.play });
  }
  
  handleVolume = (event, newValue) => {
    this.setState({ volume: newValue });
  };

  handleMute = () => {
    this.setState({ mute: !this.state.mute });
  }

  render = () => {
    
    const radio = this.state;

    return (
      <div>
        <Link 
          to="#" 
          className={`${this.props.preferences && this.props.preferences.shape === 'square' ? 'livestream-link livestream-link-square' : 'livestream-link'}`} 
          style={this.props.iconOnly ? {margin: "5px 5px 5px 0px", width: "30px", height: "30px", backgroundColor: this.props.preferences && this.props.preferences.color} : {backgroundColor: this.props.preferences && this.props.preferences.color, width: "105px"}}
          onClick={() => this.handlePlay()}
        >
          {radio.play ? 
            <Pause style={this.props.iconOnly ? {background: this.props.preferences && this.props.preferences.color, color: 'white'} : {color: this.props.preferences && this.props.preferences.color}}/> 
          : 
            <Radio style={this.props.iconOnly ? {background: this.props.preferences && this.props.preferences.color, color: 'white', padding: 2} : {color: this.props.preferences && this.props.preferences.color, padding: 2}}/>
          }
          {!this.props.iconOnly && 
            <span
              className={this.props.fontClass}
              style={{marginLeft: 24, top: 5, position: "absolute", left: 8}}
            >
              {get(getStoredChannel(), "radio_text", localization.get('on_live'))}
            </span>
          }
        </Link>

        <ReactPlayer
          url={radio.url}
          playing={radio.play}
          volume={radio.volume}
          muted={radio.mute}
          width={0}
          height={0}
          playsinline={true}
        />
      </div>
    )
      
  }
}

export default RadioPlayer;