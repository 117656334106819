import React, {Component} from "react";
import ReactAutocomplete from 'react-autocomplete'

/**
 * Filter for react tables.
 * Will suggest values while typing.
 */

class TableAutocompleteFilter extends Component {

  state = {
    items: [],
    filter: ''
  }

  previousSearchFilter = ''

  fetchItemsIntervalId = null

  componentDidMount = () => {
    this.fetchItemsIntervalId = this.startFetchItemsInterval()
    this.setState({ filter: this.props.filter || '' })
  }

  componentWillUnmount = () => {
    clearInterval(this.fetchItemsIntervalId)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.filter !== this.props.filter) {
      this.setState({ filter: this.props.filter })
    }
  }

  startFetchItemsInterval = () => setInterval(() => {

    const filter = this.state.filter

    if (filter !== this.previousSearchFilter) {
      this.fetchItemsByFilter(filter)
      this.previousSearchFilter = filter
    }
  }, 1000);

  fetchItemsByFilter = async filter => {
    const items = await this.props.fetchItems(filter);
    this.setState({ items })
  }

  render = () => {

    const { items, filter } = this.state
    const { onChange } = this.props

    return (
      <ReactAutocomplete
        items={items}
        getItemValue={item => item}
        value={filter}
        wrapperStyle={{ width: '100%' }}
        onSelect={newFilter => {
          this.setState({ filter: newFilter })
          onChange(newFilter)
        }}
        onChange={e => {
          const newFilter = e.target.value
          this.setState({ filter: newFilter })
          if (!newFilter) {
            onChange(newFilter)
          }
        }}
        id={this.props.id}
        name={this.props.id}
        renderItem={(item, isHighlighted) =>
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            <div style={{ padding: '0 10px' }}>{item}</div>
          </div>
        }
      />
    )
  }
}

export default TableAutocompleteFilter;