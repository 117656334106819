import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import AdTable from "./Table";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

class Ad extends Component {

  state = {
    ads: [],
    perPage: 20,
    page: 0,
  };

  componentDidMount = () => {
    document.title = localization.get('ads') + ' - Feater'
  };

  onAdd = () => {
    this.props.history.push(makeUrlWithTableParams(`/panel/ads/create`))
  };


  render = () =>
    <div>
      <AllViewContainer
        onAdd={this.onAdd}
        ref='viewContainer'>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <AdTable ref="AdTable" {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    </div>
}

export default Ad;
