import {isNullOrUndefined} from "util";

export const makeFormData = ({
  translations,
  imageId,
  visibility,
  selectedClients,
  hideInHome,
  featured,
  selectedMusics,
  artistId,
  type,
  createdAt,
  selectedCatalog,
  tagsIds,
  unsplashImage,
  mirrorTags
}) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`translations[${translation.language}][title]`, translation.title)
      if (!isNullOrUndefined(translation.subtitle)) form.append(`translations[${translation.language}][subtitle]`, translation.subtitle)
      if (!isNullOrUndefined(translation.shortDescription)) form.append(`translations[${translation.language}][short_description]`, translation.shortDescription)
    })
  }
  if (imageId) form.append('imageId', imageId)
  if (visibility) form.append('visibility', visibility)
  if (selectedClients) selectedClients.forEach(x => form.append('clients_ids[]', x))
  form.append('hide_in_home', hideInHome ? 1 : 0)
  form.append('featured', featured ? 1 : 0)
  if (selectedMusics) selectedMusics.forEach(x => form.append('musics_ids[]', x))
  if (artistId) form.append('artist_id', artistId)
  if (type) form.append('type', type)
  if (createdAt) form.append('created_at', createdAt)
  if (selectedCatalog) form.append('catalog_id', selectedCatalog)
  if (tagsIds) tagsIds.forEach(tid => form.append('tags_ids[]', tid))
  if (unsplashImage) {
    form.append('image_url', unsplashImage.url)
    form.append('image_name', unsplashImage.name)
  }
  if (mirrorTags) form.append('mirror_tags', 1)

  return form
}

export const makeJsonData = ({
 selectedMusics,
}) => {
  let object = {};
  selectedMusics && selectedMusics.length > 0 ? object.musics_ids = selectedMusics : object.musics_ids = []
  return JSON.stringify(object)
}

export const includeQuery = 'include=image;musics.tags:name;musics.audio:url,duration.waveform:id'
