import getAll from './getAll'
import getAllForClient from './getAllForClient'
import create from './create'
import del from './delete'
import update from './update'
import get from './get'
import getForCatalogClient from './getForCatalogClient'
import getByClient from './getByClient'
import searchFor from './searchFor'
import searchForClient from './searchForClient'
import addToPlaylist from './addToPlaylist'
import removeFromPlaylist from './removeFromPlaylist'
import share from './share'
import unshare from './unshare'
import duplicate from './duplicate'
import downloadDemo from './downloadDemo'
import addComment from './addComment'


import {appendPaginationQueryParamsToUrl, del as _del, get as _get, post as _post} from '../utils'

import {makeGlobalFormData, makeOrder} from './utils';
import {getActiveClient} from "../authentication/utils";
import getForChannel from "./getForChannel";
import shareByChannel from "./shareByChannel";
import download from './download';
import addManyToPlaylist from './addManyToPlaylist'

export default host => ({

  getAll: getAll(host),
  getAllForClient: getAllForClient(host),
  create: create(host),
  delete: del(host),
  update: update(host),
  get: get(host),
  getForChannel: getForChannel(host),
  getForCatalogClient: getForCatalogClient(host),
  getByClient: getByClient(host),
  searchFor: searchFor(host),
  searchForClient: searchForClient(host),
  addToPlaylist: addToPlaylist(host),
  addManyToPlaylist: addManyToPlaylist(host),
  removeFromPlaylist: removeFromPlaylist(host),
  share: share(host),
  unshare: unshare(host),
  duplicate: duplicate(host),
  downloadDemo: downloadDemo(host),
  shareByChannel: shareByChannel(host),
  download: download(host),
  addComment: addComment(host),

  getAllGlobal: options =>
    _get(appendPaginationQueryParamsToUrl(`${host}/playlists/global?include=category;image;clients;tags;musics`, { ...options, alreadyHasQueryParams: true }))
      .then(res => res.json()),

  getGlobal: (id, include = 'include=category;image;translations;clients;tags') =>
    _get(appendPaginationQueryParamsToUrl(`${host}/playlists/global/${id}?${include}`))
      .then(res => res.json())
      .then(json => json.data),

  createGlobal: data =>
    _post(`${host}/playlists/global`, makeGlobalFormData(data))
      .then(res => res.json()),

  updateGlobal: (id, data) => {
    data = makeGlobalFormData(data)
    data.append('_method', 'put')
    return _post(`${host}/playlists/global/${id}`, data)
      .then(res => res.json())
      .then(json => json.data)
  },

  updateOrder: (id, data) => {
    data = makeOrder(data)
    data.append('_method', 'put')
    return _post(`${host}/playlists/${id}/order`, data)
      .then(res => res.json())
      .then(json => json.data)
  },

  deleteGlobal: id =>
    _del(`${host}/playlists/global/${id}`),

  getFavorites: () =>
    _get(`${host}/clients/${getActiveClient()}/playlists/favorites`)
      .then(res => res.json())
      .then(json => json.data),

  addFavorite: id =>
    _post(`${host}/clients/${getActiveClient()}/playlists/${id}/addFavorite`),

  removeFavorite: id =>
    _post(`${host}/clients/${getActiveClient()}/playlists/${id}/removeFavorite`)
})
