import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import Thread from "./Thread";
import All from "./All";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import Footer from "../../layouts/Home/HomeElements/Footer";

const Routes = props => (
  <div style={{ height: "100vh" }}>
    <PublicNavBar showPanel={false} />
    <div style={{ height: 80 }} />
    <div
      style={{ width: "100%", padding: "0 20px", height: "calc(100% - 95px)" }}
    >
      <Router {...props}>
        <Switch>
          <Route
            path="/project_invitee/projects/:projectId/thread"
            render={props => <Thread pitchId={props.match.params.projectId} />}
          />
          <Route
            render={() => (
              <All
                threadUrl={projectId =>
                  `/project_invitee/projects/${projectId}/thread`
                }
              />
            )}
          />
        </Switch>
      </Router>
    </div>
    <Footer {...props} />
  </div>
);

export default Routes;
