import create from './create'
import del from './delete'
import getAll from './getAll'
import get from './get'
import update from './update'
import getAllForClient from './getAllForClient'
import getReport from "./getReport";
import getRanking from './getRanking'
import getAllForSmartSearch from './getAllForSmartSearch'

export default host => ({
  create: create(host),
  delete: del(host),
  getAll: getAll(host),
  getAllForClient: getAllForClient(host),
  get: get(host),
  update: update(host),
  getReport: getReport(host),
  getRanking: getRanking(host),
  getAllForSmartSearch: getAllForSmartSearch(host),
})
