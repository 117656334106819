import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery} from './utils';
import {getActiveClient} from "../authentication/utils";

const url = (host, tags, notTagsIds, options) =>
  appendPaginationQueryParamsToUrl(
    `${host}/clients/${getActiveClient()}/smartSearchContentCreators?tags_ids=${tags.join(',')}&not_tags_ids=${notTagsIds.join(',')}`,
    {...options, alreadyHasQueryParams: true})

export default host => (tags, notTagsIds, options = includeQuery) =>
  get(url(host, tags, notTagsIds, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
