import React, {Component} from "react";
import PropTypes from "prop-types";
import localization from "../../../config/localization";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import EventManager, {events} from "../../../utils/EventManager";
import {Clear} from "@material-ui/icons";
import Lock from "../../../assets/img/Letflow/lock.png";

class GoToLoginDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      doubleConfirmationText: ''
    };
  }

  handleClose = state => {
    const { onClose } = this.props;
    this.setState({doubleConfirmationText: ''})
    EventManager.getInstance().dispatch(
      events.LOGIN_DIALOG_CLOSED,
      this
    );
    onClose && onClose(state);
  };

  handleSubmit = () => {
    const { request } = this.props;

    this.setState({ submitting: true });
    request()
      .then(() => {
        this.setState({ submitting: false });
        this.handleClose("success");
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  render = () => {
    const { submitting } = this.state;
    const {
      open,
      title,
      content,
      confirmationLabel,
      disableSubmit,
      hideCancel,
      doubleConfirmation,
      elementName,
      auxiliaryInformation
    } = this.props;

    const confirmationButtonText =
      confirmationLabel || localization.get("confirmation_dialog.accept");

    return (
      <Dialog open={open} onClose={this.handleClose} disableBackdropClick>
        {!hideCancel &&
          <Button disabled={submitting} style={{position: "absolute", right: 5, top: 5, color: "#6b6b6b"}}
                  onClick={this.handleClose}>
            <Clear/>
          </Button>
        }
        <DialogContent>
          <div style={{listStyle: "none", maxWidth: 300, padding: "50px 0px 24px 0px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
            <div style={{width: "80%", textAlign: "center", fontSize: 30, color: "#303030", lineHeight: 1, fontWeight: 700}}>{content}</div>
            <img src={Lock} style={{width:100, marginTop: 30}} alt="lock"/>
            <Button
              disabled={submitting || disableSubmit || (doubleConfirmation && this.state.doubleConfirmationText !== elementName)}
              onClick={this.handleSubmit}
              style={{backgroundColor: "black", color: "white", width: "50%", marginTop: 30}}
            >
              {confirmationButtonText}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
}

GoToLoginDialog.propTypes = {
  open: PropTypes.bool,
  confirmationLabel: PropTypes.string,
  request: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disableSubmit: PropTypes.bool,
  hideCancel: PropTypes.bool
};

export default GoToLoginDialog;
