import React, {Component} from "react";
import {request} from "../../api-client";
import localization from "../../config/localization";
import Button from "../../components/CustomButtons/Button";
import {Typography} from "@material-ui/core";
import Footer from "../../layouts/Home/HomeElements/Footer";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import {DownloadButton} from "../Pitches/Thread/Message/VideoMessage";
import {getMaterialOriginalFileName} from "../Pitches/Thread/Message";
import qs from "qs";

class PublicPitchMaterial extends Component {
  state = {
    message: null,
    pitch: null,
    error: null,
    onlineMaterial: null
  };

  componentDidMount = () => {
    let search = qs.parse(this.props.location.search.slice(1));
    let { client_id, online_video } = search;
    if (client_id) {
      request.client
        .getPublic(client_id)
        .then(client => this.setState({ client }));
    }
    if(online_video){
      this.requestApprovedMaterial()
    } else {
      this.requestMaterial();
    }
  };

  requestMaterial = () => {
    request.pitch.publicMaterial(this.props.pitchId, this.props.messageId)
      .then(({ message, pitch }) => this.setState({ message, pitch }))
      .catch(error => this.setState({ error }));
  };

  requestApprovedMaterial = () => {
    request.pitch.publicApprovedMaterial(this.props.pitchId, this.props.messageId)
      .then(({ online_material }) => this.setState({ onlineMaterial: online_material }))
      .catch(error => this.setState({ error }));
  };

  render = () => {
    if (this.state.error) {
      return (
        <React.Fragment>
          <PublicNavBar
            client={this.state.client && this.state.client}
            showLinks={false}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 215px)",
              margin: 100
            }}
          >
            <Typography variant="display2">
              {localization.get("public_material.error")}
            </Typography>
            <Typography style={{marginTop: "10px"}}>
              {localization.get("public_material.error_extra")}
            </Typography>
            <Button
              style={{
                backgroundColor: "black",
                marginTop: "40px",
                maxWidth: "140px"
              }}
              color="default"
              onClick={() =>
                (window.location.href =
                  process.env.REACT_APP_WEB_HOST + "?section=contact_us")
              }
              simple
              size="lg"
              block
            >
              {localization.get("trial_login.contact")}
            </Button>
          </div>
          <Footer {...this.props} />
        </React.Fragment>
      );
    }


    if (this.state.message){
      const { type: messageType, content: messageContent } = this.state.message;

      const [, materialType] = messageType.split(":");

      const { message: materialMessage, content: materialContent } = JSON.parse(
        messageContent
      );

      let materialContentDisplay;

      switch (materialType) {
        case "audio":
          materialContentDisplay = <audio src={materialContent} controls />;
          break;
        case "video":
          materialContentDisplay = (
            <video style={{ width: "100%" }} src={materialContent} controls />
          );
          break;
        case "image":
          materialContentDisplay = (
            <img style={{ width: "100%" }} src={materialContent}  alt={getMaterialOriginalFileName(materialContent)}/>
          );
          break;
        case "other":
          materialContentDisplay = null;
          break;
        default:
          this.setState({ message: null, error: {} });
          return;
      }

      return (
        <React.Fragment>
          <PublicNavBar client={this.state.client && this.state.client} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "100px 0",
              minHeight: "calc(100vh - 347px)"
            }}
          >
          <h1>{this.state.pitch.project_name}</h1>
          {materialMessage && (
            <React.Fragment>
              <p
                style={{
                  padding: "0 10px 20px 10px",
                  maxWidth: 700,
                  textAlign: "justify"
                }}
              >
                {materialMessage}
              </p>
            </React.Fragment>
          )}
          <div style={{ maxWidth: 700 }}>{materialContentDisplay}</div>
          <h4>{getMaterialOriginalFileName(materialContent)}</h4>
          <div style={{ marginTop: 20 }}>
            <DownloadButton link={materialContent} />
          </div>
          </div>
          <Footer {...this.props} />
        </React.Fragment>
      )
    }



    return (
      <React.Fragment>
        <PublicNavBar client={this.state.client && this.state.client} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "100px 0"
          }}
        >
          { this.state.onlineMaterial &&
            <React.Fragment>
              <h1>{this.state.onlineMaterial.title}</h1>
              <div style={{ maxWidth: 700 }}>
                <video style={{ width: "100%" }} src={this.state.onlineMaterial.link} controls />
              </div>
              <h4>{getMaterialOriginalFileName(this.state.onlineMaterial.link)}</h4>
              <div style={{ marginTop: 20 }}>
                <DownloadButton link={this.state.onlineMaterial.link} />
              </div>
            </React.Fragment>
          }
        </div>
        <Footer {...this.props} />
      </React.Fragment>
    );
  };
}

export default PublicPitchMaterial;
