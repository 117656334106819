import React, {Component} from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {Checkbox, Dialog, FormControlLabel} from "@material-ui/core";
import {TextValidator} from "react-material-ui-form-validator";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {userIsTrial} from "../../../api-client/core/authentication/utils";
import analyticsInstance from "../../../analytics/analyticsInstance";

class StoreSearchDialogForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      subtitle: props.subtitle,
      description: props.description,
      image: {
        src: props.image,
        file: null,
      },
      catalogId: props.catalogId,
      disabled: false,
      userLibrary: props.userLibrary || false,
      visibility: props.visibility && props.visibility === 'public' ? 1 : 0,
    }
  }

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  onSubmit = () => {

    const { title, subtitle, description, image, userLibrary, visibility, catalogId } = this.state
    const { type, tags, clientId, submitType, storedSearchId, duration, tempo, volume } = this.props

    const requestData = {
      title,
      subtitle,
      description,
      image: image.file,
      type,
      clientId,
      tagIds: tags.map(tag => tag.id),
      userLibrary,
      visibility,
      catalogId: null,
      query: `${tempo ? `&${tempo.value}` : ''}${volume ? `&${volume.value}` : ''}${duration ? `&audio[duration][]=ge:${duration.min}&audio[duration][]=lt:${duration.max}&${catalogId.length && `catalog[id][]=in:${catalogId.map(catalog => catalog.value)}`}` : `${catalogId.length && `catalog[id][]=in:${catalogId.map(catalog => catalog.value)}`}`}`,
    }

    const properRequest = submitType === 'create'
      ? request.savedQuery.create(requestData).then(data => {
        // analyticsInstance.newSavedQuery(title)
        return data
      })
      : request.savedQuery.update(storedSearchId, requestData)

    this.setState({ disabled: true })

    return (


      properRequest
        .then(() => {
          this.setState({ disabled: false })
          GlobalSnackbar.show({
            message: localization.get('home.smart_search.store_search_success'),
            type: GlobalSnackbarTypes.SUCCESS,
            topDistance: 150,
          })
          this.props.handleClose('success')
        })
        .catch(() => {
          this.setState({ disabled: false })
          GlobalSnackbar.show({
            message: localization.get('home.smart_search.store_search_fail'),
            type: GlobalSnackbarTypes.ERROR,
            topDistance: 150,
          })
        })
    )
  }

  render = () => {
    return (
      <Dialog fullWidth open={true} onClose={this.props.handleClose}>
        <FormViewContainer
          title={localization.get('store_search')}
          onBack={() => this.props.handleClose()}
          submitRequest={() => this.onSubmit()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          style={{maxWidth: "600px", marginBottom: "0", }}
          color="black"
          smallExtraSpace={true}
        >
          <div style={{padding: "0 50px"}}>
            {/*<Grid spacing={16} container>
              {this.props.tags.map(t => <Grid item><Chip label={t.name} /></Grid>)}
            </Grid>*/}
            <TextValidator
              style={{ width: "100%", margin: "5px 0" }}
              id="title"
              label={localization.get('form.title')}
              name="title"
              value={this.state.title}
              onChange={this.onChange}
              disabled={this.state.disabled}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />

            <TextValidator
              style={{ width: "100%", margin: "5px 0 30px 0" }}
              id="subtitle"
              label={localization.get('subtitle')}
              name="subtitle"
              value={this.state.subtitle}
              onChange={this.onChange}
              disabled={this.state.disabled}
            />

           {/* <TextValidator
              style={{ width: "100%", margin: "5px 0" }}
              id="description"
              label={localization.get('description')}
              name="description"
              value={this.state.description}
              onChange={this.onChange}
              disabled={this.state.disabled}
            />*/}

            {!userIsTrial() ?
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "black" }}
                    checked={this.state.visibility}
                    onChange={() => {
                      this.setState({ visibility: !this.state.visibility })
                    }}
                  />
                }
                style={{ width: "100%", height: "35px" }}
                label={localization.get('form.public_savedQuery')}
              />
            : null}

            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "black" }}
                  checked={this.state.userLibrary}
                  onChange={() => {
                    this.setState({ userLibrary: !this.state.userLibrary })
                  }}
                />
              }
              style={{ width: "100%", height: "35px" }}
              label={localization.get('form.show_in_home')}
            />
            {/*<ImagePicker style={{ backgroundColor: "black" }} color={'default'} buttonText={localization.get('form.select_image')} image={this.state.image.src} onImageChange={image => this.setState({ image })} disabled={this.state.disabled} />*/}
          </div>
        </FormViewContainer>
      </Dialog>
    )
  }
}

StoreSearchDialogForm.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  image: null,
  type: 'music',
  submitType: 'create',
}

export default StoreSearchDialogForm
