import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import localization from "../../../config/localization";

class ContentCreators extends Component {
  state = {
    artists: [],
    perPage: 20,
    page: 0
  };

  componentDidMount = () => {
    document.title = localization.get("channel.community") + " - Feater";
  };

  render = () => (
    <AllViewContainer ref="viewContainer">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default ContentCreators;
