import {makeRoutes, redirectTo, route} from "./utils";
import LoginChannel from "../../views/ChannelLogin/Login";
import Register from "../../views/ChannelLogin/Register";
import SetPassword from "../../views/ChannelLogin/Activate/SetPassword";
import ForgotPassword from "../../views/ChannelLogin/ForgotPassword/ForgotPassword";
import CompleteRegister from "../../views/ChannelLogin/Activate/CompleteRegister";
import EmailActivate from "../../views/ChannelLogin/Activate/EmailActivate";
import ErrorPage from "../../layouts/Channel/ErrorPage";
import AuthorizationCode from "../../layouts/Channel/Authorization/Code.jsx";
import PublicContentCreation from "../../layouts/Channel/PublicContentCreation";
import React from "react";
import PublicContentCreator from "../../views/PublicContentCreator";
import PublicPlaylist from "../../views/PublicPlaylist";
import PublicReel from "../../views/PublicReel";
import PublicAlbum from "../../views/PublicAlbum";


const allowed = () => [
  route(`/login`, LoginChannel),
  route(`/register`, Register),
  route(`/password/forgot`, ForgotPassword),
  route(`/set-password`, SetPassword),
  route(`/user/activation`, CompleteRegister),
  route(`/activacion`, EmailActivate),
  route(`/error-page`, ErrorPage),
  route(`/panel/login-code`, AuthorizationCode),
  route(`/panel/gcba/login-code`, AuthorizationCode),
  route(`/panel/miba/login-code`, AuthorizationCode),
  route(`/login-code`, AuthorizationCode),
  route(`/miba/login-code`, AuthorizationCode),
  route(`/gcba/login-code`, AuthorizationCode),
  route(`/public/playlists/:token-:playlistName`, props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route(`/public/playlists/:token`, props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route(`/public/albums/:token`, props => <PublicAlbum {...props} token={props.match.params.token} />),
  route(`/public/content_creators/:contentCreatorId`, props => <PublicContentCreator {...props} contentCreatorId={props.match.params.contentCreatorId} />),
  route(`/public/content_creations/:contentCreationId`, props => <PublicContentCreation {...props} contentCreationId={props.match.params.contentCreationId} />),
  route(`/public/reel/:reelId`, PublicReel),
];


const homeRedirect = () => redirectTo(`/login?redirect_to=${window.location.pathname}${window.location.search}`);

const excluded = channel => [];

const def = () => route("/", homeRedirect());

export default () => makeRoutes(allowed(), excluded(), def());