import React, {Component} from 'react';
import FormView from './FormView'
import FormViewContainer from '../../../components/Letflow/ViewContainer/FormViewContainer';
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

class FormContainer extends Component {

  state = {
    translations: AvailableLanguages.map(language => ({
      language: language,
      name: "",
    })),
    slug: '',
    image: {src: null, file: null},
    disabled: false,
    allowImage: false,
    allowAudio: false,
    allowVideo: false,
    allowBinary: false,
    allowLink: false,
    allowEmbed: false,
    limit: null,
    isTalent: this.props.isTalent,
    visibility: 'private',
    client: '',
    type: this.props.isEditorial ? 'editorial' : 'audiovisual',
    synonyms: "",
    downloadable: false,
  };

  setContentCreatorType = contentCreatorType => {
    const translations = AvailableLanguages.map(language => {
      const translation = contentCreatorType.translations.find(tr => tr.locale ===language)

      return {
        language: language,
        name: translation ? translation.name : "",
      };
    })

    this.setState({
      translations: translations,
      slug: contentCreatorType.slug,
      allowImage: contentCreatorType.allow_image,
      allowAudio: contentCreatorType.allow_audio,
      allowVideo: contentCreatorType.allow_video,
      allowBinary: contentCreatorType.allow_binary,
      allowLink: contentCreatorType.allow_link,
      allowEmbed: contentCreatorType.allow_embed,
      limit: contentCreatorType.limit,
      image: {
        src: getUpdateFormImageUrlFromItem(contentCreatorType),
        file: null
      },
      isTalent: !!contentCreatorType.is_talent,
      visibility: contentCreatorType.visibility,
      client: contentCreatorType.client && {value: contentCreatorType.client.id, label: contentCreatorType.client.name},
      type: contentCreatorType.type,
      synonyms: contentCreatorType.synonyms,
      downloadable: contentCreatorType.downloadable,
    })
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value })
    if (e.target.id === 'type') {
      this.setState({
        allowImage: false,
        allowAudio: false,
        allowVideo: false,
        allowBinary: false,
        allowLink: false,
        allowEmbed: false,
      })
    }
  }

  onImageChange = image => this.setState({ image })

  submitRequest = () => this.props.submitRequest(this.state)

  onSubmitValidation = () => {
    if (this.state.visibility === 'private' && !this.state.client) {
      GlobalSnackbar.show({message: localization.get('form.content_creator_types.validate_client'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }

  render = () => {

    const { translations, slug, disabled, image, allowImage, allowAudio, allowVideo, allowEmbed, limit, isTalent, visibility, client, allowBinary, allowLink, type, synonyms, downloadable} = this.state

    return (
      <FormViewContainer
        title={this.props.title}
        submitRequest={this.submitRequest}
        onBack={() => this.props.history.goBack()}
        initializationRequest={this.props.initializationRequest}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
        hasTranslatableInputs
        validation={this.onSubmitValidation}
      >
        <FormView
          ref={instance => this.child = instance}
          disabled={disabled}
          translations={translations}
          onChangeTranslation={(value, target, language) => {
            const translations = this.state.translations.map(tr => {
              if (tr.language === language) {
                return {...tr, [target]: value}
              }
              return tr
            });

            this.setState({ translations });
          }}
          slug={slug}
          allowImage={allowImage}
          allowAudio={allowAudio}
          allowVideo={allowVideo}
          allowBinary={allowBinary}
          allowLink={allowLink}
          allowEmbed={allowEmbed}
          onAllowImageChange={allowImage => this.setState({allowImage})}
          onAllowAudioChange={allowAudio => this.setState({allowAudio})}
          onAllowVideoChange={allowVideo => this.setState({allowVideo})}
          onAllowBinaryChange={allowBinary => this.setState({allowBinary})}
          onAllowLinkChange={allowLink => this.setState({allowLink})}
          onAllowEmbedChange={allowEmbed => this.setState({allowEmbed})}
          onChange={this.onChange}
          image={image}
          onImageChange={this.onImageChange}
          isUpdating={this.props.isUpdating}
          limit={limit}
          isTalent={isTalent}
          visibility={visibility}
          onClientChange={client => this.setState({client})}
          client={client}
          type={type}
          creationForm={this.props.creationForm}
          synonyms={synonyms}
          isEditorial={this.props.isEditorial}
          downloadable={downloadable}
        />
      </FormViewContainer>
    )
  }
}

export default FormContainer
