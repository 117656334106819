import React, {Component} from "react";
import SoundBar from "./SoundBar";

let soundbarInstance = null

const getInstance = () => {
  if (soundbarInstance === null) {
    throw new Error('GlobalSoundbar component was never mounted')
  }
  return soundbarInstance
}
class GlobalSoundBar extends Component {

  static closeButtonPressed$ = () => getInstance().closeButtonPressed$

  static nextButtonPressed$ = () => getInstance().nextButtonPressed$

  static previousButtonPressed$ = () => getInstance().previousButtonPressed$

  static trackEnded$ = () => getInstance().trackEnded$

  static trackPaused$ = () => getInstance().trackPaused$

  static trackStartedPlaying$ = () => getInstance().trackStartedPlaying$

  static loadSingleTrackAndPlay = track => GlobalSoundBar.loadTracksAndPlay([track])

  static loadTracksAndPlay = (tracks, index = 0) => getInstance().setTracks(tracks).then(() => getInstance().playTrack(index))

  static currentTrackIsTitled = title => getInstance().currentTrackIsTitled(title)

  static currentTrackHasProperties = properties => getInstance().currentTrackHasProperties(properties)

  static setIsFavoriteFromTrackWithTitle = (title, value) => getInstance().setIsFavoriteFromTrackWithTitle(title, value)

  static isPlaying = () => getInstance().isPlaying()

  static pause = () => getInstance().pause()

  static currentPlaylingTime = () => getInstance().currentPlaylingTime()

  static totalSeconds = () => getInstance().totalSeconds()

  static handleProgressManualChange = (currentSeconds, totalSeconds) => getInstance().handleProgressManualChange(currentSeconds, totalSeconds)

  render = () => {

    const { texts } = this.props
    
    return <SoundBar ref={soundbarRef => soundbarInstance = soundbarRef} texts={texts} />
  }
}

GlobalSoundBar.propTypes = SoundBar.propTypes

export default GlobalSoundBar