import {post} from "../utils";

export default host => ({
  translate: translate(host)
})

const translate = host => (text, from = 'es-MX', to = 'en') => {
  const form = new FormData();
  form.append('text', text)
  form.append('from', from)
  form.append('to', to)

  return post(`${host}/translate`, form)
    .then(response => response.json())
}

