import React, {Component} from "react";
import localization from "../../../config/localization";
import {Formik, Form} from "formik";
import {validateEmail} from "../../../utils";
import {request} from "../../../api-client";
import * as WLFH from "../../../components/Letflow/Form/WebLikeFormHelpers";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import qs from "qs";
import StepWizard from 'react-step-wizard';
import {Step1} from "./Step1";
import {Step2} from "./Step2";
import {Step3} from "./Step3";
import StepProgressBar from "../../../components/Letflow/StepProgressBar";
import {SuccessMessage} from "./SuccessMessage";
import getAllPublic from "../../../api-client/core/contentCreatorType/getAllPublic";

class PublicCreateContentCreator extends Component {

  state = {
    contentCreatorType: null
  }

  componentDidMount = () => {
    let search = qs.parse(this.props.location.search.slice(1))
    let {type} = search
    request.contentCreatorType.getAllPublic()
      .then(contentCreatorTypes => this.setState({contentCreatorType: contentCreatorTypes.find(contentCreatorType => contentCreatorType.slug === type)}))
  }

  render = () => {
    const {contentCreatorType} = this.state

    return (
      <React.Fragment>
        <div
          style={{
            minHeight: "100vh",
            width: "100%",
            overflow: "hidden"
          }}
        >
          <FormController
            contentCreatorType={contentCreatorType}
          />
        </div>
      </React.Fragment>
    );
  };
}

class FormController extends Component {
  state = {
    tagGroups: [],
    sendingCurrentStep: 0,
    sendingSteps: 0,
    submitted: false,
    contentCreatorType: this.props.contentCreatorType
  };

  componentDidMount = () => {
    if (this.state.contentCreatorType) {
      this.requestTagGroups()
    }
  };

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (this.props.contentCreatorType !== nextProps.contentCreatorType) {
      this.setState({contentCreatorType: nextProps.contentCreatorType}, () => this.requestTagGroups())
    }
  }

  requestTagGroups = () =>
    request.tagGroup.getAll({filterBy: [{ column: 'type', filter: `${this.state.contentCreatorType.slug}`}, { column: 'creation_group', filter: '0'}]}).then(res =>
      this.setState({
        tagGroups: res.map(tg => ({
          label: tg.name,
          tags: tg.tags.map(t => ({
            value: t.id,
            label: t.name,
            tagGroupId: tg.id
          }))
        }))
      })
    );

  validate = values => {
    const errors = {};
    const {contentCreatorType} = this.state;

    if (!values.name) {
      errors.name = localization.get("form.validation.required");
    }
    if (!validateEmail(values.email)) {
      errors.email = localization.get("form.validation.invalid_email");
    }
    if (!values.countryId) {
      errors.countryId = localization.get("form.validation.required");
    }
    if (!values.startActivity) {
      errors.startActivity = localization.get("form.validation.required");
    }
    if (!values.description) {
      errors.description = localization.get("form.validation.required");
    }
    if (!values.profileImageId && !values.unsplashProfileImage) {
      errors.profileImageId = localization.get("form.validation.required");
    }
    if (!values.coverImageId && !values.unsplashCoverImage) {
      errors.coverImageId = localization.get("form.validation.required");
    }
    if (contentCreatorType && contentCreatorType.allow_video && (values.videoProductions.length === 0)) {
      errors.videoProductions = localization.get(
        "form.validation.files_required_range",
        1,
        100
      );
    }
    if (contentCreatorType && contentCreatorType.allow_audio && (values.audioProductions.length === 0)) {
      errors.audioProductions = localization.get(
        "form.validation.files_required_range",
        1,
        100
      );
    }

    if (contentCreatorType && contentCreatorType.allow_image && (values.showcase.length === 0)) {
      errors.showcase = localization.get(
        "form.validation.files_required_range",
        1,
        100
      );
    }

    return errors;
  };

  createContentCreator = values => {
    this.setState({
      sendingCurrentStep: 0,
      sendingSteps: 1 + values.videoProductions.length + values.audioProductions.length + values.showcase.length
    });
    return request.contentCreator.createDemo(
      {
        email: values.email,
        countryId: values.countryId,
        phone: values.phone,
        imageId: values.profileImageId,
        coverImageId: values.coverImageId,
        unsplashCoverImage: values.unsplashCoverImage,
        unsplashProfileImage: values.unsplashProfileImage,
        selectedTags: values.selectedTags.map(x => x.value),
        contentCreatorTypeId: this.state.contentCreatorType.id,
        translations: [
          {
            language: localization.getLanguage(),
            description: values.description,
            name: values.name
          }
        ],
        socialLinks: values.socialLinks,
        startActivity: values.startActivity,
        active: false,
      }
    );
  };

  createContentCreations = (createdContentCreator, files) => {
    return files.reduce((acc, next) => {
      return acc.then(() => {
        this.setState({
          sendingCurrentStep: this.state.sendingCurrentStep + 1
        });
        return request.contentCreation.create({
          contentCreatorId: createdContentCreator.data.id,
          active: 1,
          videoId: next.type === 'video' && next.id,
          translations: [
            {
              language: localization.getLanguage(),
              title: '-', //next.name,
            }
          ],
          demo: true,
          imageId: next.type === 'image' && next.id,
          audioId: next.type === 'audio' && next.id
        });
      });
    }, Promise.resolve());
  };

  initialValues = () => ({
    name: "",
    email: "",
    phone: "",
    countryId: '',
    countries: [], 
    description: "",
    videoProductions: [],
    audioProductions: [],
    showcase: [],
    photos: [],
    selectedTags: [],
    profileImageRadio: 'filepond',
    profileImageId: '',
    unsplashProfileImage: '',
    coverImageRadio: 'filepond',
    coverImageId: '',
    unsplashCoverImage: '',
    audioId: '',
    startActivity: '',
    lastStep: null,
    socialLinks: [],
    disableForm: false
  });

  render = () => {
    return (
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialErrors={true}
        isInitialValid={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          this.createContentCreator(values)
            .then(createdContentCreator =>
              this.createContentCreations(
                createdContentCreator,
                [...values.videoProductions].concat([...values.audioProductions]).concat([...values.showcase])
              )
            )
            .then(() => {
              values.lastStep()
              this.setState({ submitted: true });
            })
            .catch(() => GlobalSnackbar.showGenericError())
            .finally(() => setTimeout(() => actions.setSubmitting(false), 3000));
        }}
      >
        {formikProps => (
          <React.Fragment>
            <Form className="demo-form">
              <WLFH.Title>{localization.get("demo.upload_title", this.state.contentCreatorType && this.state.contentCreatorType.name)}</WLFH.Title>
              <StepWizard nav={<StepProgressBar/>}>
                <Step1 formikProps={formikProps}/>
                <Step2 formikProps={formikProps} contentCreatorType={this.state.contentCreatorType}/>
                <Step3 formikProps={formikProps} onSubmitButtonAction={() => this.props.nextStep} tagGroups={this.state.tagGroups}/>
                <SuccessMessage formikProps={formikProps} />
              </StepWizard>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  };
}

export default PublicCreateContentCreator;
