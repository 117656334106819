import React from 'react'
import {request} from '../../../api-client';
import {
  Checkbox,
  FormControl, FormControlLabel, Radio, RadioGroup,
} from "@material-ui/core";
import localization from "../../../config/localization"
import {TextValidator} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";


const FormContent = ({
   id,
   code,
   discount,
   quantity,
   expiresAt,
   active,
   description,
   uses,
   channel,
   onChannelChange,
   onChange,
   products,
   onProductsChange,
   includeAll,
}) => {
  return (
    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="code"
        label={localization.get("form.code")}
        name="code"
        value={code}
        onChange={onChange}
      />
      <br />
      <br />
      {!id ?
        <FormControl fullWidth>
          <span>{localization.get('channel')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('channel')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onChannelChange}
            value={channel}
            loadOptions={inputValue => {
              let filterBy = [{column: "name", filter: inputValue}];
              return request.channel.getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                .then(res => res.map(content => ({value: content.id, label: `${content.name}`})))
            }}
            defaultOptions
          />
        </FormControl>
        :
        <TextValidator
          style={{ width: "100%" }}
          label={localization.get('channel')}
          name="channelName"
          value={channel.name}
          disabled={true}
        />
      }
      <br />
      <br />
      {id &&
        <TextValidator
          style={{width: "100%"}}
          id="uses"
          label={localization.get("form.uses")}
          name="uses"
          value={uses}
          disabled={true}
        />
      }
      <TextValidator
        style={{ width: "100%" }}
        id="quantity"
        label={localization.get("form.quantity")}
        name="quantity"
        value={quantity}
        onChange={onChange}
        validators={['minNumber:1']}
        type="number"
      />
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="discount"
        validators={['minNumber:0', 'maxNumber:100']}
        type="number"
        label={`${localization.get("form.discount")} %`}
        name="discount"
        value={discount}
        onChange={onChange}
      />

      <br />
      <br />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          keyboard
          onlyCalendar
          id={"expiresAt"}
          disablePast
          defaultValue={expiresAt}
          format={"dd/MM/yyyy"}
          label={localization.get('form.expire_date')}
          value={expiresAt}
          required
          onChange={e => {
            onChange({
              target: {
                id: "expiresAt",
                value: e,
              }
            })
          }}
        />
      </MuiPickersUtilsProvider>
      <br/><br/>
      <FormControl style={{width: "100%", float: "left", position: "relative"}}>
        <span>{localization.get('form.include_type')}</span>
        <RadioGroup
          name="includeAll"
          value={includeAll}
          onChange={(event) => {onChange({
              target: {
                id: "includeAll",
                value: event.target.value,
              }
            })
          }}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('form.include_all')}/>
          <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('form.include_some')}/>
        </RadioGroup>
      </FormControl>

      {channel && includeAll === "0" &&
        <FormControl fullWidth>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('products')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onProductsChange}
            value={products}
            isMulti
            loadOptions={inputValue => {
              let filterBy = [
                { column: "name", filter: inputValue},
              ];

              return request.products.getAll(channel.value, {filterBy, orderBy: {column: "name", type: "ASC"}})
                .then(json => json.data)
                .then(res => res.map(content => ({value: content.id, label: `${content.content.name || content.content.title}`})))

            }}
            defaultOptions
          />
        </FormControl>
      }
    </div>
  )
}

export default FormContent
