import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'

export default class extends Component {

  requestCreation = (state) => {

    const {
      translations,
      selectedTagGroup,
      order,
      altSubmit,
      showOnSmartSearch,
      childrens
    } = state

    return request.tag.create({
      translations,
      tagGroupId: selectedTagGroup.value,
      order,
      showOnSmartSearch,
      childrens: childrens ? childrens.map(t => t.value) : [],
    }).then(() => {
      if (!altSubmit) {
        this.props.history.goBack()
      }
    })
  }

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('tag.create_tag')}
        submitRequest={this.requestCreation}
        formType="create"
        isMusic={this.props.location.pathname.includes('music')}
        isTalent={this.props.location.pathname.includes('talents')}
        isAudiovisual={this.props.location.pathname.includes('audiovisual')}
        isEditorial={this.props.location.pathname.includes('editorial')}
        altSubmit={true}
      />
    )
  }
}
