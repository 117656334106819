import React, {Component} from 'react';
import "./FileStorage.css";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import AudioImage from "../../../assets/img/Letflow/audio-image.png";
import Doc from "../../../assets/img/Letflow/FileExtentions/doc.png";
import Pdf from "../../../assets/img/Letflow/FileExtentions/pdf.png";
import Ppt from "../../../assets/img/Letflow/FileExtentions/ppt.png";
import Zip from "../../../assets/img/Letflow/FileExtentions/zip.png";
import Xls from "../../../assets/img/Letflow/FileExtentions/xls.png";
import {makeImageUrl, makeImageUrlWithSize, emptyImage} from "../../../layouts/Home/HomeElements/utils";
import {request} from "../../../api-client";
import { Dialog } from "@material-ui/core";
import {Button, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {TableButton, TableButtonsBuilder} from "../Table";
import {getActiveClient, userIsReadOnly} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import {Attachment, CloudDownload, Edit, GroupAdd, PlayCircleOutline, PhotoLibrary} from "@material-ui/icons";
import {makeFitImageUrlWithSize, makeWaveformUrl} from "../../../utils";
import AsyncSelect from "react-select/lib/Async";
import {ValidatorForm} from "react-material-ui-form-validator";
import { onImageError } from '../../../layouts/Home/HomeElements/utils';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || [],
      showDialog: false,
      selected: false,
      publicView: this.props.publicView ||false,
      openPermissionDialog: false,
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.item !== this.props.item) {
      this.setState({item: nextProps.item})
    }

    if (nextProps.publicView !== this.props.publicView) {
      this.setState({publicView: nextProps.publicView})
    }
  }

  componentDidMount = () => {
    document.addEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }

  componentWillUnmount = () => {
    document.removeEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }

  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
    this.fullScreen()
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  fullScreen = () => {
    this.videoRef.requestFullscreen()
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()
    }
  }

  handleClosePermissionDialog = () => this.setState({ openPermissionDialog: false})

  handlePermissions = file =>
    request.clientFile.get(file.id)
      .then(response => this.setState({
        filePermissions: response.data.allowedUsers ? response.data.allowedUsers.map(user => ({value: user.id, label: user.name})) : [],
        openPermissionDialog: true
      }))


  getFileExtentionLogo = () => {
    const pdf = ["pdf"]
    const doc = ["doc", "docx", "txt"]
    const xls = ["xls","xlsx","xlsm","xltx","csv"]
    const zip = ["rar", "zip"]
    const ppt = ["ppt", "pptx"]

    if(pdf.includes(this.state.item.binary.mime_type)) {
      return Pdf
    }else if(doc.includes(this.state.item.binary.mime_type)) {
      return Doc
    }else if(xls.includes(this.state.item.binary.mime_type)) {
      return Xls
    }else if(zip.includes(this.state.item.binary.mime_type)) {
      return Zip
    }else if(ppt.includes(this.state.item.binary.mime_type)) {
      return Ppt
    }
    //default
    return Zip
  }

  buildButtons = (file) => {
    let builder = new TableButtonsBuilder()
    if (!userIsReadOnly()) {

      if (file.type !== 'other'){
        builder
          .withOther(
            <TableButton
              title={localization.get('download')}
              onClick={() => window.open(
                file.type === 'image' ? request.clientFile.downloadFile(getActiveClient(), file.id)
                  : file[file.type].url
                , "_blank")}
            >
              <CloudDownload/>
            </TableButton>
          )
      }

      builder
        .withOther(
          <TableButton
            title={localization.get('edit')}
            onClick={() => this.props.onEdit(file)}
          >
            <Edit/>
          </TableButton>
        )

      builder
        .withDelete(
          file.title,
          () => request.clientFile.delete(file.id),
          closeState => {
            if (closeState === 'passed') {
              this.props.onDelete && this.props.onDelete(file.id)
              this.props.updateGrid({})
            }
          }
        )

      if(!file.public) {
        builder
          .withOther(
            <TableButton
              title={localization.get('invite')}
              onClick={() => this.handlePermissions(file)}
            >
              <GroupAdd/>
            </TableButton>
          )
      }
    }
    return builder.build()
  }

  buildPin = (file) => {
    let builder = new TableButtonsBuilder()
    if (!userIsReadOnly()) {
      builder
        .withOther(
          <TableButton
            title={localization.get('select')}
            onClick={() =>
              this.setState({selected: !this.props.selected },
                () => this.props.changeFileSelected(file))
            }
          >
            <Attachment/>
          </TableButton>
        )
    }
    return builder.build()
  }

  toggleVideoState = (toPlayingState, action, event) => {

    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
  }

  handleCloseDialog = () => this.setState({showDialog: false})

  showDialogPhoto = (item) =>
    <Dialog
      open={this.state.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth={false}
    >
      <DialogContent style={{maxWidth: '1200px'}}>
        <img alt="photo" src={makeFitImageUrlWithSize(item, 'lg')} onError={onImageError}/>
      </DialogContent>
    </Dialog>

  onSubmitPermissions = () =>
    request.clientFile.updatePermissions(this.state.item, this.state.filePermissions).then(() => this.handleClosePermissionDialog())

  showAddPermissionsDialog = () =>
    <Dialog
      open={this.state.openPermissionDialog}
      onBackdropClick={this.handleClosePermissionDialog}
      onEscapeKeyDown={this.handleClosePermissionDialog}
    >
      <DialogTitle>
        {localization.get("pitch.upload_media_title")}
      </DialogTitle>
      <DialogContent>
        <div>{localization.get('files.permission_instructions')}</div>
        <ValidatorForm
          onSubmit={this.onSubmitPermissions}
          style={{marginTop: 30, width: 600, maxWidth: "100%", minHeight:330}}
        >
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10,
              }),
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('user')}
            value={this.state.filePermissions}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={filePermissions => this.setState({filePermissions})}
            loadOptions={inputValue => request.user.getAllForClient(getActiveClient())
              .then(res => res.filter(user => user.name.indexOf(inputValue) !== -1))
              .then(res => res.map(client => ({value: client.id, label: client.name})))
            }
            defaultOptions
            isMulti
          />
        </ValidatorForm>
      </DialogContent>
      <DialogActions>
      <Button onClick={this.handleClosePermissionDialog} disabled={this.state.sending}>
        {localization.get("cancel")}
      </Button>
      <Button onClick={this.onSubmitPermissions} disabled={this.state.sending}>
        {localization.get("send")}
      </Button>
    </DialogActions>
    </Dialog>

  itemContent = () => {
    switch (this.state.item.type) {
      case "audio":
        return (
          <div
            className="file-item over-item"
          >
            <div
              className="file-background-image"
              style={{backgroundImage: `url(${AudioImage})`}}>
            </div>
            {!this.state.publicView &&
            <>
              <div className="over-buttons" style={{display: this.props.selected ? "block" : "none", left: 20,backgroundColor: this.props.selected ? "rgba(110, 110, 110, 0.7)" : "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildPin(this.state.item)}</div>
              <div className="over-buttons" style={{display: "none", right: 20, backgroundColor: "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildButtons(this.state.item)}</div>
            </>
            }
            <div
              style={{background: this.props.selected ? "rgba(0, 0, 0, 0.3)" : "none"}}
              onClick={() => GlobalSoundBar.loadSingleTrackAndPlay(
                 {
                   fetchAudioUrl: () => request.clientFile.audioUrl(this.state.item.id, this.state.item.client_id),
                   title:  this.state.item.title,
                   subtitle:  this.state.item.subtitle &&  this.state.item.subtitle,
                   image: makeImageUrl(AudioImage),
                   waveform: makeWaveformUrl(this.state.item),
                 })
              }
            >
              <div className="over-action-reel">
                <PlayCircleOutline/>
              </div>
              <div className="over-background" style={{background: this.props.secondColor ? `linear-gradient(rgba(255, 255, 0, 0), ${this.props.secondColor})` : "linear-gradient(rgba(255, 255, 0, 0), #727272)"}}/>
              <div className="item-text">
                <h3 className="item-title">{this.state.item.title}</h3>
                <p className="item-subtitle">{this.state.item.subtitle}</p>
              </div>
            </div>
          </div>
        )
      case "video":
        return (
          <div className="file-item over-item" style={{overflow: "hidden", cursor: "pointer", background: "black"}}>
            <video 
              ref={ref => this.videoRef = ref}
              loop={false}
              controls={false}
              preload="none"
              poster={this.state.item.thumbnail}
              className={'cover-video'}
              style={{overflow: "hidden", width : "100%", minHeight: "100%"}}>
              <source src={this.state.item.video.url} />
            </video>
            {!this.state.publicView &&
              <>
                <div className="over-buttons" style={{display: this.props.selected ? "block" : "none",left: 20, backgroundColor: this.props.selected ? "rgba(110, 110, 110, 0.7)" : "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildPin(this.state.item)}</div>
                <div className="over-buttons" style={{display: "none", right: 20, backgroundColor: "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildButtons(this.state.item)}</div>
              </>
            }
            <div style={{background: this.props.selected ? "rgba(0, 0, 0, 0.3)" : "none", zIndex: 10}} onClick={() => (this.state.playing) ? this.pause() : this.play()}>
              <div className="over-action-reel" >
                {!this.state.publicView &&
                  <PlayCircleOutline/>
                }
              </div>
              <div className="over-background" style={{background: this.props.secondColor ? `linear-gradient(rgba(255, 255, 0, 0), ${this.props.secondColor})` : "linear-gradient(rgba(255, 255, 0, 0), #727272)"}} />
              <div className="item-text" style={{color: this.props.thirdColor ? this.props.thirdColor : "rgba(255,255,255,1) !important", opacity: 1}}>
                <h3 className="item-title">{this.state.item.title}</h3>
                <p className="item-subtitle">{this.state.item.subtitle}</p>
              </div>
            </div>
          </div>
        )
      case "image":
        return (
          <div
            className="file-item over-item"
            style={{overflow: "hidden", cursor: "pointer", backgroundImage: `url(${makeImageUrlWithSize(this.state.item, 'md')}), url(${emptyImage})`}}
          >
            {!this.state.publicView &&
              <>
                <div className="over-buttons" style={{display: this.props.selected ? "block" : "none", left: 20, backgroundColor: this.props.selected ? "rgba(110, 110, 110, 0.7)" : "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildPin(this.state.item)}</div>
                <div className="over-buttons" style={{display: "none", right: 20, backgroundColor: "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildButtons(this.state.item)}</div>
              </>
            }
            <div style={{background: this.props.selected ? "rgba(0, 0, 0, 0.3)" : "none"}} onClick={() => this.setState({showDialog: true})}>
              <div className="over-action-reel">
                <PhotoLibrary/>
              </div>
              <div className="over-background" style={{background: this.props.secondColor ? `linear-gradient(rgba(255, 255, 0, 0), ${this.props.secondColor})` : "linear-gradient(rgba(255, 255, 0, 0), #727272)"}} />
              <div className="item-text"
                   style={{color: "white", zIndex:  1000 }}>
                <h3 className="item-title">{this.state.item.title}</h3>
                <p className="item-subtitle">{this.state.item.subtitle}</p>
              </div>
            </div>
            {this.showDialogPhoto(this.state.item)}
          </div>
        )
      case "other":
        return (
          <div
            className="file-item over-item"
            style={{overflow: "hidden"}}
          >
            <div
              className="file-background-logo"
              style={{backgroundImage: `url(${this.getFileExtentionLogo()})`}}>
            </div>
            {!this.state.publicView &&
              <>
                <div className="over-buttons" style={{display: this.props.selected ? "block" : "none", left: 20, backgroundColor: this.props.selected ? "rgba(110, 110, 110, 0.9)" : "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildPin(this.state.item)}</div>
                <div className="over-buttons" style={{display: "none", right: 20, backgroundColor: "rgba(255, 255, 255, 0.7)",zIndex: 101}}>{this.buildButtons(this.state.item)}</div>
              </>
            }
            <div style={{background: this.props.selected ? "rgba(0, 0, 0, 0.3)" : "none"}} onClick={() => window.open(this.state.item.binary.url, "_blank")}>
              <div className="over-action-reel">
                <CloudDownload/>
              </div>
              <div className="over-background" style={{background: this.props.secondColor ? `linear-gradient(rgba(255, 255, 0, 0), ${this.props.secondColor})` : "linear-gradient(rgba(255, 255, 0, 0), #727272)"}} />
              <div className="item-text">
                <h3 className="item-title">{this.state.item.title}</h3>
                <p className="item-subtitle">{this.state.item.subtitle}</p>
              </div>
            </div>
          </div>
        )
      default:
    }
  }

  render() {
    return (
      <>
      {this.showAddPermissionsDialog()}
      <div
        className="item"
        onClick={e => e.preventDefault()}
      >
        {this.itemContent()}


      </div>
    </>
      );
  }
}
