import React, {Component} from 'react';
import localization from "../../../config/localization";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio, RadioGroup, FormControlLabel, FormControl
} from "@material-ui/core";
import {request} from "../../../api-client";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import TagSelection from "../../../components/Letflow/TagSelection";
import TextField from "@material-ui/core/TextField/TextField";
import AsyncSelect from "react-select/lib/Async";


class ConfigAdsDialog extends Component{
  state = {
    tagGroups: [],
    selectedTags: [],
    selectedSections: [],
    disabled: false,
    weight: 1,
    adTarget: "both"
  }

  componentDidMount = () => {
    request.tagGroup.getAll({filterBy: [
      { column: 'channel_id', filter: this.props.channelId},
      { column: 'target', filter: 'content'},
    ]}, 'include=tagGroups')
    .then(tagGroups => this.setState({ tagGroups: tagGroups.filter(tagGroup => tagGroup.tags.length) }))
  }

  componentDidUpdate  = (prevProps, prevState) => {
    if(this.props.open && this.props.open !== prevProps.open) {
      this.setState({
        weight: this.props.ad.weight,
        selectedTags: this.props.ad.tagIds,
        tagGroups: this.updateTagGroups(this.state.tagGroups, this.props.ad.tagIds),
        selectedSections: this.props.sections.filter(section => this.props.ad.sectionIds.includes(section.value.toString())),
        adTarget: (this.props.ad.tagIds.length > 0 && this.props.ad.sectionIds.length == 0) ? "only_content" : (this.props.ad.sectionIds.length > 0 && this.props.ad.tagIds.length == 0) ? "only_sections" : "both"
      })
    }
  }


  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

  onSelectedTagsChanged = selectedTag => {
    let selectedTags = this.state.selectedTags

    if (selectedTags.includes(selectedTag.toString())) {
      selectedTags = selectedTags.filter(tags => tags != selectedTag.toString());
    }else { 
      selectedTags.push(selectedTag.toString())
    }

    this.setState({ 
      selectedTags: selectedTags,
      tagGroups: this.state.tagGroups.map(tg => ({
        ...tg,
        tags: tg.tags.map(t => ({
            ...t,
            state: selectedTags.includes(t.id.toString()) ? "selected" : "available" 
        }))
      }))
    });
  }
  

  handleClose = (submited) =>
    this.setState({
        tagGroups: [],
        selectedTags: [],
        selectedSections: [],
        disabled: false,
        weight: "",
    }, () => this.props.onClose(submited))


  handleSubmit = () => {
    this.props.onSubmit({
      ...this.props.ad,
      tagIds: this.state.selectedTags.map(tag => tag.toString()),
      weight: this.state.weight,
      sectionIds: this.state.selectedSections.map(section => section.value.toString())
    })
  }

  render() {
    const {tagGroups} = this.state;

    return(
      <Dialog open={this.props.open} >
        <DialogTitle>
          {localization.get('channels.ads_config')}
        </DialogTitle>
        <DialogContent style={{width: window.innerWidth > 600 ? 600 : "100%", minHeight: 480}}>
        <FormControl>
            <span>{localization.get('channel.ad_target')}</span>
            <RadioGroup
              name="adTarget"
              value={this.state.adTarget}
              onChange={event => {
                if (event.target.value === "both") {
                  this.setState({adTarget: "both"})
                } else if (event.target.value === "only_sections") {
                  this.setState({
                    adTarget: event.target.value,
                    selectedTags: [],
                    tagGroups: this.state.tagGroups.map(tg => ({
                      ...tg,
                      tags: tg.tags.map(t => ({
                          ...t,
                          state: "available" 
                      }))
                    }))
                  })
                }else {
                  this.setState({adTarget: event.target.value, selectedSections: []})
                }
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="both" control={<Radio color="default"/>}
                                label={localization.get('channel.ad_target.all')}/>
              <FormControlLabel value="only_sections" control={<Radio color="default"/>}
                                label={localization.get('channel.ad_target.sections')}/>
              <FormControlLabel value="only_content" control={<Radio color="default"/>}
                                label={localization.get('channel.ad_target.content')}/>
            </RadioGroup>
          </FormControl>
          {(this.state.adTarget !== "only_content") && 
            <FormControl fullWidth>
              <br/>
              <h4>{localization.get('form.section')}</h4>
              <AsyncSelect
                styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('form.section')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={sections => this.setState({selectedSections: sections})}
              isMulti
              value={this.state.selectedSections}
              loadOptions={(inputValue, callback) => {
                callback(this.props.sections
                  .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
              }}
              defaultOptions
            />
            </FormControl>
          }
          {(this.state.adTarget !== "only_sections") && 
            <div style={{width: "100%", position: "relative", float: "left", marginTop: 15}}>
              <h4>{localization.get('content')}</h4>
              <label>{localization.get("blog.tags")}</label>
              <TagSelection
                homeElementTagSelection 
                data={tagGroups}
                onSelectedTagsChanged={this.onSelectedTagsChanged}
                cardView
                colapsable
                tabsView
              />
              <br />
              <br />
            </div>
          }
          <TextField
            style={{marginBottom: 15}}
              placeholder={localization.get('channels.ads_weight')}
              value={this.state.weight}
              onChange={e => this.setState({weight: e.target.value})}
              helperText={localization.get('channels.ads_weight.helper')}
              fullWidth
              type="number"
              min={1}
              max={10}
            />
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.disabled} onClick={this.props.onClose}>{localization.get('cancel')}</Button>
          <Button disabled={this.state.disabled} onClick={this.handleSubmit}>
              {localization.get('save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfigAdsDialog;