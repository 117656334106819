import getAll from './getAll'
import create from './create'
import del from './delete'
import get from './get'
import update from './update'
import searchFor from './searchFor';
import searchForClient from './searchForClient';
import getAllWithTags from './getAllWithTags';
import integrateToPlatform from './integrateToPlatform';
import exportAll from "./exportAll";
import getCountries from "./getCountries";
import getAllForClient from "./getAllForClient";
import getForClient from "./getForClient";

export default host => ({
  getAll: getAll(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  update: update(host),
  searchFor: searchFor(host),
  searchForClient: searchForClient(host),
  getAllWithTags: getAllWithTags(host),
  integrateToPlatform: integrateToPlatform(host),
  exportAll: exportAll(host),
  getCountries: getCountries(host),
  getAllForClient: getAllForClient(host),
  getForClient: getForClient(host),
})
