import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {Share} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import ShareDialog from '../../ShareDialog';
import {request} from "../../../../api-client";
import {getActiveClient, getUseTools, slugify} from "../../../../api-client/core/authentication/utils";
import ShareOnSocials from "../../ShareOnSocials";
import GlobalSnackbar from "../../Snackbar/GlobalSnackbar";

class TableShareButton extends Component {

  state = {
    showDialog: false,
    sharingPlaylistToken: false,
    shareUrl: false,
    sharingPlaylistName: "",
  }

  handleButtonClick = () => {
    this.props.entity === "playlist" ?
      this.sharePlaylist()
      :
      this.props.entity === "album" ? 
        this.shareAlbum()
        :
        this.setState({ showDialog: true })
  }

  sharePlaylist = () => {
    return request.playlist.shareByChannel(this.props.id)
      .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
      .catch(GlobalSnackbar.showGenericError)
  }

  shareAlbum = () => {
    return request.album.shareByChannel(this.props.id)
      .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
      .catch(GlobalSnackbar.showGenericError)
  }

  makeShareDialog = () =>
    <div style={{ display: 'inline-block' }}>
      {this.props.entity === "playlist" ?
        <ShareOnSocials
          show={this.state.showDialog}
          handleClose={() => this.setState({showDialog: false, sharingPlaylistToken: null, sharingPlaylistName: null,shareUrl: false})}
          url={this.state.shareUrl ? this.state.shareUrl :
            `${window.location.protocol}//${window.location.hostname}/public/playlists/${this.state.sharingPlaylistToken}-${this.state.sharingPlaylistName}`}
          darkMode={true}
        />
        :
        (this.props.entity === "album" && !getUseTools())?
          <ShareOnSocials
            show={this.state.showDialog}
            handleClose={() => this.setState({showDialog: false, sharingAlbum: null, shareUrl: false})}
            url={this.state.shareUrl ? this.state.shareUrl :
              `${window.location.protocol}//${window.location.hostname}/public/albums/${this.state.sharingAlbum}`}
            darkMode={true}
          />
          :
          <ShareDialog
            show={this.state.showDialog}
            handleClose={this.handleDialogClose}
            entity={this.props.entity}
            id={this.props.id}
          />
      }
    </div>

  handleDialogClose = () => this.setState({ showDialog: false })

  render = () => {
    return (
      <span>
        <TableButton onClick={this.handleButtonClick}>
          <Share style={this.props.color ? {color: this.props.color} : {}} />
        </TableButton>
        {this.makeShareDialog()}
      </span>
    )
  }
}

TableShareButton.propTypes = {
  playlistId: PropTypes.number.isRequired,
}

export default TableShareButton