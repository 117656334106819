import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {PlaylistAdd as Icon} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import AddToPlaylistDialog from '../../../../layouts/Home/HomeElements/AddToPlaylistDialog';
import {TryAgainSnackbar} from '../../Snackbar'

class TableAddToPlaylistButton extends Component {

  state = {
    showAlert: false,
  }

  handleOnClose = status => {
    this.setState({ showAlert: false })
    this.props.alertClosed(status)
  }

  addToPlaylistDialog = () => {

    const { showAlert } = this.state

    const dialog = showAlert ? <AddToPlaylistDialog {...this.props} handleClose={state => {
      this.setState({ showAlert: false })
      this.props.handleClose(state)
    }} /> : null

    return (
      <div style={{ position: 'absolute' }}>
        {dialog}
        <TryAgainSnackbar ref='failSnackbar' />
      </div>
    )
  }

  handleOnClick = () => this.setState({ showAlert: true })

  render = () => {
    return (
      <span>
        <TableButton title={localization.get('tooltip.add_to_playlist')} onClick={this.handleOnClick}>
          <Icon />
        </TableButton>
        {this.addToPlaylistDialog()}
      </span>
    )
  }
}

TableAddToPlaylistButton.propTypes = {
  clientId: PropTypes.string,
  selectedTrack: PropTypes.object,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default TableAddToPlaylistButton
