import "../Home.css";
import "./index.css"
import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button.jsx";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx"
import _ from 'lodash'
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import StoreSearchDialogForm from "./StoreSearchDialog";
import StoredSearchesDrawer from "./StoredSearchesDrawer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {
  catalogClientView, channelPathGenerator,
  getActiveClient,
  homePathGenerator,
  setPageTitle,
  userCanSaveQueries,
  userCanSeeLicenses,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import qs from 'qs'
import TrackResultWithPagination from "../../../components/Letflow/TrackResultWithPagination";
import TrackTable from "../../../components/Letflow/TrackTable";
import EventManager, {events} from "../../../utils/EventManager";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from "@material-ui/core";
import {DeleteOutline, List, Save, Style} from "@material-ui/icons";
import {getParameterByNameSavedQuery} from "../../../utils";
import 'react-input-range/lib/css/index.css'
import tempos from "../../../config/tempos"
import volumes from "../../../config/volumes"
import browserHistory from "../../../utils/browserHistory";
import {Link} from "react-router-dom";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import AsyncSelect from "react-select/lib/Async";
import {isNullOrUndefined} from "util";

const makeStoreSearchButton = (onClick) => userCanSaveQueries() && window.innerWidth > 600 &&
  <Tooltip title={localization.get('store_search')} placement='top' style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
    <IconButton onClick={onClick}>
      <Save/>
    </IconButton>
  </Tooltip>

const makeViewStoredSearchedButton = (onClick) =>
  <Tooltip title={localization.get('view_stored_searches')} placement='top'>
    <IconButton onClick={onClick} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
      <List/>
    </IconButton>
  </Tooltip>

const makeCleanTagsButton = (onClick) =>
  <Tooltip title={localization.get('smart_search.clean_search')} placement='top'>
    <IconButton onClick={onClick} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
      <DeleteOutline/>
    </IconButton>
  </Tooltip>

const makeAddFiltersButton = (onClick) =>
  <Tooltip title={localization.get('smart_search.add_remove_tags')} placement='top'>
    <IconButton onClick={onClick} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
      <Style/>
    </IconButton>
  </Tooltip>

const makeSearchButton = (onClick) =>
  <Button className="smart-search-search-button" onClick={onClick} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
    {localization.get('home.smart_search.search')}
  </Button>

const hasAnySelectedTag = tagGroups =>
  _.some(_.flatMap(tagGroups, tagGroup => tagGroup.tags), tag => (tag.state === 'selected' || tag.state === 'unselected'));

class SmartSearchMusic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [], // contains all tags and their correspondings availability state
      searchResultsTags: [],
      searchResultsTitle: [],
      searchGroup: 'music',
      order: 'default',
      orderBy: 'default',
      sortType: 'desc',
      lastTagId: null,
      duration: null,
      tempo: '',
      volume: '',
      showTagSelection: true,
      showTagDialog: false,
      onlyGold: false,
      catalogs: [],
      catalogId: [],
      showTags: false,
      channel: props.channel || null,
      tagSystemId: null,
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0

    this.requestHasQueryParams()
      .then(() => this.requestTagGroups())
      .then(() => this.requestAvailableTags())
      .then(() => this.clearUnusedTags())
      .then(() => this.setState({showTags: true}))
      .then(() => this.readQueryStringParams())
    setPageTitle(localization.get('title.smart_search'))

    const savedQueryId = qs.parse(this.props.location.search).savedQuery
    if (savedQueryId) {
      request.savedQuery.get(savedQueryId)
        .then(savedQuery => this.fillTagsFromSavedQueryAndSearch(savedQuery))
    }

    EventManager.getInstance().subscribe(events.TAG_PRESSED_IN_TRACK_TABLE, this.handleTagPressedInTrackTable)
    EventManager.getInstance().subscribe(events.TAG_SELECTED_PRESSED_IN_TRACK_TABLE, this.handleTagSelectedPressedInTrackTable)
  }

  componentDidUpdate = () => {
    window.onpopstate = () => {
      this.requestTagGroups()
        .then(() => this.readQueryStringParams())
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.TAG_PRESSED_IN_TRACK_TABLE, this.handleTagPressedInTrackTable)
    EventManager.getInstance().unsubscribe(events.TAG_SELECTED_PRESSED_IN_TRACK_TABLE, this.handleTagSelectedPressedInTrackTable)

  }

  requestHasQueryParams = () =>
    new Promise(resolve => {
      let search = qs.parse(this.props.location.search.slice(1))
      let {duration, tempo, order, orderBy, sortType, volume, tags_ids, not_tags_ids} = search
      if (tags_ids || not_tags_ids || duration || tempo || order || orderBy || sortType || volume) {
        this.setState({showTagSelection: false})
      }
      resolve()
    })

  readQueryStringParams = () =>
    new Promise(resolve => {
      let search = qs.parse(this.props.location.search.slice(1))
      let {duration, tempo, order, orderBy, sortType, volume, tags_ids, not_tags_ids} = search
      
      if (tags_ids || not_tags_ids || duration || tempo || order || orderBy || sortType || volume) {
        if (tags_ids) {
          if (Array.isArray(tags_ids)) {
            tags_ids.forEach(tag => this.markTagAsSelected(tag))
          } else {
            this.markTagAsSelected(tags_ids)
          }
        }
        if (not_tags_ids) {
          if (Array.isArray(not_tags_ids)) {
            not_tags_ids.forEach(tag => this.markTagAsUnselected(tag))
          } else {
            this.markTagAsUnselected(not_tags_ids)
          }
        }
        if (duration || tempo || order || orderBy || sortType || volume) {
          this.setState({
            duration: JSON.parse(duration),
            tempo: JSON.parse(tempo),
            volume: JSON.parse(volume),
            order: order,
            orderBy: orderBy,
            sortType: sortType,
          })
        }
        this.requestTracks()
      }
      resolve()
    })


  writeQueryStringParams = () => {
    let {duration, tempo, order, orderBy, sortType, volume} = this.state
    browserHistory.push({
      pathname: window.location.pathname,
      search: qs.stringify({
        tags_ids: this.getSelectedTags().map(tag => tag.id),
        not_tags_ids: this.getUnselectedTags().map(tag => tag.id),
        duration: JSON.stringify(duration),
        tempo: JSON.stringify(tempo),
        volume: JSON.stringify(volume),
        order: order || 'default',
        orderBy: orderBy || 'default',
        sortType: sortType || 'desc',
        tag_system_id: this.state.tagSystemId,
      })
    })
  }

  cleanSearch = () => {
    this.setState({
      tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
      searchResultsTags: [],
      showTagSelection: true,
      duration: null,
      tempo: '',
      catalogId: [],
      volume: '',
    })
    if(!this.state.channel) {
      browserHistory.push(homePathGenerator(`smart_search/musics`))
    }else {
      browserHistory.push(channelPathGenerator(`busqueda-inteligente/musica`))
    }

    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  handleTagPressedInTrackTable = tag => this.markTagAsSelectedAndSearch(tag.id)

  handleTagSelectedPressedInTrackTable = tag => this.markTagAsAvailableAndSearch(tag.id)

  requestTagGroups = () => {
    let search = qs.parse(this.props.location.search.slice(1))
    let {tag_system_id} = search

    request.tagGroup.getAllOfTypeMusic(tag_system_id||null, true)
    .then(tagGroups => this.setState({ tagSystemId: tag_system_id, tagGroups: tagGroups.filter(tagGroup => tagGroup.tags.length) }))
  }

  renderFilters = () => {
    if (this.state.searchResultsTags.data) {
      return (
        <div className="smart-search-filters-container">
          <div className="smart-search-tempo-container">
            <FormControl fullWidth>
              <InputLabel style={{color: 'var(--main-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get('smart_search.tempo')}</InputLabel>
              <Select
                style={{color: "var(--secondary-font-color, black)"}}
                MenuProps={{
                  MenuListProps: { 
                    style: {
                      backgroundColor: 'var(--main-color, white)',
                    },
                  }
                }}
                disableUnderline
                value={this.state.tempo}
                onChange={(e) => this.setState({tempo: e.target.value}, () => this.requestTracks().then(() => this.writeQueryStringParams()))}
              >
                {tempos.map(tempo => {
                  return (
                    <MenuItem style={{color: "var(--secondary-font-color, black)"}} value={tempo}>{localization.get(`tempo.${tempo.name}`)}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div className="smart-search-volume-container">
            <FormControl fullWidth>
              <InputLabel style={{color: 'var(--main-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get('smart_search.volume')}</InputLabel>
              <Select
                style={{color: "var(--secondary-font-color, black)"}}
                MenuProps={{
                  MenuListProps: { 
                    style: {
                      backgroundColor: 'var(--main-color, white)',
                    },
                  }
                }}
                disableUnderline
                value={this.state.volume}
                onChange={(e) => this.setState({volume: e.target.value}, () => this.requestTracks().then(() => this.writeQueryStringParams()))}
              >
                {volumes.map(volume => {
                  return (
                    <MenuItem style={{color: "var(--secondary-font-color, black)"}} value={volume}>{localization.get(`volume.${volume.name}`)}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>

          <div className="smart-search-sort">
            <FormControl fullWidth>
              <InputLabel style={{color: 'var(--main-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get('smart_search.order')}</InputLabel>
              <Select
                style={{color: "var(--secondary-font-color, black)"}}
                MenuProps={{
                  MenuListProps: { 
                    style: {
                      backgroundColor: 'var(--main-color, white)',
                    },
                  }
                }}
                disableUnderline
                value={this.state.order}
                onChange={e => {
                  let order = e.target.value
                  switch (order) {
                    case 'alphabetic' :
                      this.setState({order, orderBy: 'title', sortType: "asc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                      break
                    case 'newer' :
                      this.setState({order, orderBy: 'updated_at', sortType: "desc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                      break
                    case 'older' :
                      this.setState({order, orderBy: 'updated_at', sortType: "asc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                      break
                    case 'longer' :
                      this.setState({order, orderBy: 'duration', sortType: "desc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                      break
                    case 'shorter' :
                      this.setState({order, orderBy: 'duration', sortType: "asc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                      break
                    default :
                      this.setState({order: "default", orderBy: 'default', sortType: "asc"}, () => {
                        this.checkTableEmpty()
                        this.writeQueryStringParams()
                      })
                  }
                }}
              >
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="default">{localization.get('smart_search.order.default')}</MenuItem>
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="alphabetic">{localization.get('smart_search.order.alphabetic')}</MenuItem>
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="newer">{localization.get('smart_search.order.newer')}</MenuItem>
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="older">{localization.get('smart_search.order.older')}</MenuItem>
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="longer">{localization.get('smart_search.order.longer')}</MenuItem>
                <MenuItem style={{color: "var(--secondary-font-color, black)"}} value="shorter">{localization.get('smart_search.order.shorter')}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="smart-search-filters-buttons-container">
            {makeAddFiltersButton(() => this.setState({showTagDialog: true}))}
            {hasAnySelectedTag(this.state.tagGroups) > 0 && makeCleanTagsButton(this.cleanSearch)}
            {!this.state.channel && !catalogClientView() && hasAnySelectedTag(this.state.tagGroups) > 0 && makeStoreSearchButton(() => this.setState({ storingSearch: true }))}
            {!this.state.channel && !catalogClientView() && makeViewStoredSearchedButton(() => this.setState({ viewingStoredSearches: true }))}
          </div>

          {!this.state.channel && (userIsSysAdmin() || userIsSupervisor() || userCanSeeLicenses()) && (this.props.client.category_id === 4) &&
            <div className="smart-search-filters-only-gold">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "darkgray"}}
                    checked={this.state.onlyGold}
                    onChange={() => this.setState({onlyGold: !this.state.onlyGold}, () => this.requestTracks())}
                  />
                }
                labelPlacement={'start'}
                label={localization.get("smart_search.only_gold")}
              />
            </div>
          }
        </div>
      )
    } else {
      return null
    }
  }


  clearUnusedTags = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.filter(tag => tag.state !== 'disable')
      }))
    }))
  }

  makeStoreSearchDialogForm = () => this.state.storingSearch && <StoreSearchDialogForm
    handleClose={() => this.setState({ storingSearch: false })}
    type={this.state.searchGroup}
    clientId={getActiveClient()}
    tags={_.flatMap(this.state.tagGroups, (tg => tg.tags.filter(t => t.state === 'selected')))}
    duration={this.state.duration}
    tempo={this.state.tempo}
    volume={this.state.volume}
    catalogId={this.state.catalogId}
  />

  fillTagsFromSavedQueryAndSearch = savedQuery => {
    this.requestAvailableTags()
    this.setState({
      viewingStoredSearches: false,
      tagGroups: this.state.tagGroups.map(tagGroup => ({
        ...tagGroup,
        tags: tagGroup.tags.map(tag => ({
          ...tag,
          state: savedQuery.tags.find(savedQueryTag => savedQueryTag.id === tag.id) ? 'selected' : 'available'
        }))
      })),
      catalogId: !isNullOrUndefined(savedQuery.catalog) && savedQuery.catalog.id,
      duration: {
        min: getParameterByNameSavedQuery('audio[duration][]=ge', savedQuery.query) || 0,
        max: getParameterByNameSavedQuery('audio[duration][]=lt', savedQuery.query) || 480
      },
      tempo: tempos.find(tempo => tempo.min == getParameterByNameSavedQuery('midLevelFeature[tempo][]=ge', savedQuery.query) && (tempo.max ? tempo.max == getParameterByNameSavedQuery('midLevelFeature[tempo][]=lt', savedQuery.query) : true)) || '',
      volume: volumes.find(volume => volume.min == getParameterByNameSavedQuery('lowLevelFeature[rms][]=ge', savedQuery.query) && (volume.max ? volume.max == getParameterByNameSavedQuery('lowLevelFeature[rms][]=lt', savedQuery.query) : true)) || ''
    }, () => {
      this.requestTracks()
    })
  }

  makeStoredSearchDrawer = () => {
    return this.state.viewingStoredSearches && <StoredSearchesDrawer
      handleClose={() => this.setState({ viewingStoredSearches: false })}
      onStoredSearchClick={this.fillTagsFromSavedQueryAndSearch}
      clientId={getActiveClient()}
    />
  }

  makeSmartSearchTagSelector = () => {
    return (
      <div>
        <div style={{ backgroundColor: "var(--main-color, rgb(247, 247, 247))", display: this.state.showTagSelection ? 'block' : 'none'}} className="category">
          <div style={{ float: "left", position: "relative", width: "100%", textAlign: "center", padding: "10px 50px", height: "auto" , minHeight: "520px"}}>
            {!this.state.showTags ?
              <div style={{ height: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                <img style={{width: 150, height: 150, marginTop: "calc(43vh - 70px)"}} src={LoadingSpinner} alt="Loading logo"/>
              </div>
            :
              <TagSelection onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged} data={this.state.tagGroups} />
            }
          </div>
        </div>
      </div>
    )
  }

  getSelectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected')
  getUnselectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected')

  checkTableEmpty = () => {
    if (this.state.searchResultsTags.data) {
      this.requestTracks()
    }
  }

  markTagAsSelected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'selected'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsUnselected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'unselected'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsAvailable = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'available'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsSelectedAndSearch = tagId => {
    this.markTagAsSelected(tagId)
      .then(() => {
        this.requestTracks()
        this.handleSmartSearchSelectedTagsChanged(this.getSearchTags(), this.getUnselectedTags(), tagId)
      })
  }

  handleSmartSearchSelectedTagsChanged = (selectedTags, unselected, lastTagId) => {
    this.requestAvailableTags({lastTagId})
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : unselected.includes(tag.id) ? 'unselected' : tag.state
        }))
      }))
    }), () => this.writeQueryStringParams())
  }

  markTagAsAvailableAndSearch = tagId => {
    this.markTagAsAvailable(tagId)
      .then(() => {
        this.requestTracks()
        this.handleSmartSearchSelectedTagsChanged(this.getSearchTags(), this.getUnselectedTags(), tagId)
      })
  }


  disableAllTagsForRequest = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: 'disable'
        }))
      }))
    }))
  }

  getSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id)

  getUnselectedSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected').map(tag => tag.id)

  handleSmartSearch = () => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0) {
      this.requestTracks()
      this.setState({showTagDialog: false})
    } else {
      GlobalSnackbar.show({message: localization.get('home.smart_search.missing_tags'), type: GlobalSnackbarTypes.ERROR})
    }
  }

  renderSearchResultsTableTags = () => {

    const { searchResultsTags } = this.state

    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "var(--main-color, white)",
      marginTop: "120px"
    }}>{children}</div>

    let content

    if (this.state.showTagSelection || (!this.state.showTagSelection && searchResultsTags.length === 0)) {
      content = null
    } else if (searchResultsTags.data && searchResultsTags.data.length === 0 && !this.state.showTagSelection) {
      content =
        <div className="smart-search-no-result-container">
          <p>{localization.get('smart_search.no_result_music')}</p>
          <Link onClick={() => this.cleanSearch()} to={(!this.state.channel) ?
            homePathGenerator(`smart_search/musics`) :
            channelPathGenerator(`busqueda-inteligente/musica`)
          }>{localization.get('smart_search.try_again')}</Link>
        </div>
    } else {
      content =
        <div className="smart-search-result-container" style={{padding: "0 50px"}}>
          <TrackResultWithPagination
            rows={<TrackTable {...this.props} useAlbum channel={this.state.channel} type={'music'} selectedTags={this.getSearchTags()} tracks={searchResultsTags} fromHome={true}/>}
            onPageChange={page => this.requestTracks({page: page})}
            pagination={searchResultsTags.meta}
          />
        </div>
    }

    return <Container>{content}</Container>
  }

  renderTagSelectionDialog = () => {
    return (
      <Dialog
        open={this.state.showTagDialog}
        onClose={() => this.setState({showTagDialog: false})}
        PaperProps={{
          style: {
            backgroundColor: 'var(--main-color, white)',
          },
        }}
      >
        <DialogContent style={{backgroundColor: "var(--main-color, inherit)", color: "var(--secondary-font-color, inherit)"}}>
          <TagSelection onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged} data={this.state.tagGroups.filter(x => x.types[0].type === 'music')} />
        </DialogContent>
        <DialogActions style={{backgroundColor: "var(--main-color, inherit)"}}>
          <div style={{float: "left", position: "relative", width: "100%", textAlign: "center"}}>
            {makeSearchButton(this.handleSmartSearch)}
          </div>
        </DialogActions>
      </Dialog>
    )
  }


  requestAvailableTags = ({
    clientId = getActiveClient(),
    searchGroup = this.state.searchGroup,
    searchTags = this.getSearchTags(),
    unselectedSearchTags = this.getUnselectedSearchTags(),
    lastTagId = null,
    channelId = this.state.channel ? this.state.channel.id : null
  } = {}) => {
    this.disableAllTagsForRequest()
    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId,
      channelId
    }).then(availableTags => {
      const tags = _.flatMap(this.state.tagGroups, x => x.tags)
      tags.forEach(x => x.state = x.state === 'selected' ? 'selected' : x.state === 'unselected' ? 'unselected' : availableTags.map(x => x.id).includes(x.id) ? 'available' : 'disable')
      this.setState({ tagGroups: this.state.tagGroups })
      return availableTags
    })
  }

  requestTracks = ({
   searchGroup = this.state.searchGroup,
   clientId = getActiveClient(),
   searchTags = this.getSearchTags(),
   unselectedSearchTags = this.getUnselectedSearchTags(),
   volume = this.state.volume ? this.state.volume.value : null,
   tempo = this.state.tempo ? this.state.tempo.value : null,
   duration = this.state.duration ? `audio[duration][]=ge:${this.state.duration.min}&audio[duration][]=lt:${this.state.duration.max}` : null,
   page = 0,
   perPage = 12,
   orderBy = this.state.orderBy,
   sortType = this.state.sortType,
   onlyGold = this.state.onlyGold,
   catalogId = null,
   catalogIds = this.state.catalogId.length ? `catalog[id][]=in:${this.state.catalogId.map(catalog => catalog.value)}` : null,
   channelId = this.state.channel ? this.state.channel.id : null,
  } = {}) =>
    request[searchGroup].forClientWithTags(
      clientId,
      searchTags,
      unselectedSearchTags,
      volume,
      tempo,
      duration,
      orderBy,
      sortType,
      onlyGold,
      catalogId,
      catalogIds,
      {
        page,
        perPage
      },
      channelId
    ).then(searchResults => {
      setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 500)

      searchResults.data.map(track => track.date = new Date)

      this.setState({ searchResultsTags: searchResults, showTagSelection: false })
      const searchTagsNames = this.state.tagGroups.flatMap(x => x.tags).filter(x => searchTags.includes(x.id)).map(x => x.name)

      // analyticsInstance.smartSearch(searchTagsNames)
    })
      .catch(() => {
        GlobalSnackbar.show({
          message: 'smart_search_request_failed',
          type: GlobalSnackbarTypes.ERROR,
        })
      })

  render = () => {
    return (
      <div id="innerinner" style={{ marginBottom: '10px' }}>
        {this.makeStoreSearchDialogForm()}
        {this.makeStoredSearchDrawer()}
        {this.makeSmartSearchTagSelector()}
        <div style={{ display: this.state.showTagSelection ? 'block' : 'none' }} className="smart-search-buttons-container">
          {makeSearchButton(() => this.handleSmartSearch())}
          {!this.state.channel && !catalogClientView() && makeViewStoredSearchedButton(() => this.setState({ viewingStoredSearches: true }))}
          {hasAnySelectedTag(this.state.tagGroups) > 0 && makeCleanTagsButton(this.cleanSearch)}
        </div>
        {this.renderFilters()}
        {this.renderSearchResultsTableTags()}
        {this.renderTagSelectionDialog()}

      </div>
    )
  }
}

export default SmartSearchMusic;
