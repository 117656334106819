import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from "@material-ui/core";
import {
  Assignment,
  Close,
  ExpandLess,
  ExpandMore,
  Forward,
  HighlightOff,
  Search,
  Share,
  Warning
} from "@material-ui/icons";
import localization from "../../../config/localization";
import {getPropertySafe, getValueFromQueryParam, parseDate, setStatePromise} from "../../../utils";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {request} from "../../../api-client";
import AsyncSelect from "react-select/lib/Async";
import browserHistory from "../../../utils/browserHistory";
import InfiniteScroll from "react-infinite-scroll-component";
import {licenseTypeIdToString} from "../../../components/Letflow/LicenseWizard/utils/mappers";
import classNames from "classnames";
import "./index.css";
import {getActiveClient, userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";

export default class extends Component {
  state = {
    fetchData: null,
    onlineVideos: [],
    videoDetailsDialog: null,
    shareDialog: null,
    setOfflineDialog: null,
    client: null,
    tag: null,
    pitch: null,
    withLicense: null,
    fetching: true,
    materialName: "",
    minDate: null,
    maxDate: localization.moment()
  };

  componentDidMount = async () => {
    await this.fetchApiElementFromQueryParamAndSetInitialFilterOption(
      "client_id",
      v => request.client.get(v),
      "client",
      v => ({
        value: v.id,
        label: v.name
      })
    );
    await this.fetchApiElementFromQueryParamAndSetInitialFilterOption(
      "pitch_id",
      v => request.pitch.show(v),
      "pitch",
      v => ({
        value: v.id,
        label: v.project_name
      })
    );
    await this.fetchOnlineVideos();
  };

  fetchApiElementFromQueryParamAndSetInitialFilterOption = async (
    queryParamName,
    fetchApiElement,
    stateName,
    mapApiElementToOption
  ) => {
    const queryParamValue = getValueFromQueryParam(queryParamName);
    if (queryParamValue) {
      try {
        const apiElement = await fetchApiElement(queryParamValue);
        await setStatePromise(this, {
          [stateName]: mapApiElementToOption(apiElement)
        });
      } catch (e) {
        // Dont do anything because the api element could not be fetched
      }
    }
  };

  fetchOnlineVideos = () => {
    this.setState({ fetching: true });

    const fetchOptions = {
      filterBy: [
        {
          column: "client_id",
          filter: getPropertySafe(this.state.client, "value")
        },
        { column: "tag", filter: getPropertySafe(this.state.tag, "value") },
        {
          column: "pitch_id",
          filter: getPropertySafe(this.state.pitch, "value")
        },
        // Not a normal filter, but works for getting online videos
        // that have or not a license assigned to them.
        {
          column: "with_licenses",
          filter: getPropertySafe(this.state.withLicense, "value", null)
        },
        {
          column: "q",
          filter: this.state.materialName
        },
        {
          column: "min_date",
          filter: parseDate(this.state.minDate)
        },
        {
          column: "max_date",
          filter: parseDate(this.state.maxDate)
        }
      ],
      perPage: 10,
      orderBy: { column: "created_at", type: "desc" }
    };

    if (!this.state.fetchData) {
      fetchOptions.page = 0;
    } else {
      fetchOptions.page = this.state.fetchData.current_page;
    }

    return request.onlineVideos.getAll(fetchOptions).then(res =>
      setStatePromise(this, {
        fetchData: res,
        onlineVideos: this.state.onlineVideos.concat(res.data),
        fetching: false
      })
    );
  };

  onShowDetails = data =>
    this.setState({
      videoDetailsDialog: (
        <VideoDetailsDialog
          onClose={() => {
            this.setState({
              videoDetailsDialog: null
            });
          }}
          onSubmitted={() => {
            this.setState({
              videoDetailsDialog: null,
              fetchData: undefined,
              onlineVideos: []
            });
            this.fetchOnlineVideos();
          }}
          id={data.id}
          title={data.title}
          producer={data.producer}
          creativity={data.creativity}
          direction={data.direction}
          date={data.date}
          description={data.description}
          license={
            data.license
              ? {
                  value: data.license.id,
                  label: `${data.license.music.title} | ${
                    data.license.client.name
                  } | ${licenseTypeIdToString(data.license.type.id)}`
                }
              : null
          }
          tags={data.tags.map(x => ({
            value: x.name,
            label: localization.get(x.name)
          }))}
        />
      )
    });

  onShare = data =>
    this.setState({
      shareDialog: (
        <div style={{ display: "inline-block" }}>
          <ShareDialog
            handleClose={() => this.setState({ shareDialog: null })}
            id={data.id}
            pitchId={data.pitch_id}
            entity={"onlineMaterial"}
            show={true}
          />
        </div>
      )
    });

  onGoToPitch = data => {
    const url = this.props.threadUrl(data.pitch_id);
    browserHistory.push(url);
  };

  onSetOffline = data =>
    this.setState({
      setOfflineDialog: (
        <SetOfflineDialog
          onClose={() => this.setState({ setOfflineDialog: null })}
          title={data.title}
          setOfflinePromise={() =>
            request.onlineVideos.offline(data.id).then(() =>
              this.setState({
                onlineVideos: this.state.onlineVideos.filter(
                  onlineVideo => onlineVideo.id !== data.id
                )
              })
            )
          }
        />
      )
    });

  renderOnlineVideos = () => {
    if (!this.state.fetching && this.state.onlineVideos.length === 0) {
      if (this.state.client) {
        return (
          <h3>
            {localization.get(
              "online_videos.client_does_not_have_online_videos",
              this.state.client.label
            )}
          </h3>
        );
      } else {
        return (
          <h3>
            {localization.get("online_videos.no_online_videos_available")}
          </h3>
        );
      }
    }

    return (
      <InfiniteScroll
        dataLength={this.state.onlineVideos.length}
        next={this.fetchOnlineVideos}
        hasMore={
          this.state.fetchData &&
          this.state.fetchData.current_page < this.state.fetchData.last_page
        }
        loader={<h4>{localization.get("loading")}</h4>}
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <Grid  container>
          {this.state.onlineVideos.map((onlineVideo, key) => {
            const clientName = () => {
              if (onlineVideo.client) {
                return onlineVideo.client.name;
              } else {
                return "Flowlike";
              }
            };
            return (
              <Grid key={key} xs={12} sm={6} md={6} lg={4} xl={4} item style={{padding: 20}}>
                <Video
                  src={onlineVideo.link}
                  title={onlineVideo.title + ` (${clientName()})`}
                  needsLicense={!onlineVideo.license}
                  onShowDetails={() =>
                    this.onShowDetails({
                      id: onlineVideo.id,
                      title: onlineVideo.title,
                      producer: onlineVideo.producer,
                      creativity: onlineVideo.creativity,
                      direction: onlineVideo.direction,
                      date: localization
                        .moment(onlineVideo.created_at)
                        .format("DD-MM-YYYY"),
                      description: onlineVideo.description,
                      tags: onlineVideo.tags,
                      license: onlineVideo.license
                    })
                  }
                  onSetOffline={() =>
                    this.onSetOffline({
                      id: onlineVideo.id,
                      title: onlineVideo.title
                    })
                  }
                  onShare={() =>
                    this.onShare({
                      title: onlineVideo.title,
                      id: onlineVideo.id,
                      pitch_id: onlineVideo.pitch_id
                    })
                  }
                  onGoToPitch={() =>
                    this.onGoToPitch({
                      pitch_id: onlineVideo.pitch_id
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    );
  };

  handleSearch = () => {
      this.setState({ onlineVideos: [], fetchData: null }, () =>  this.fetchOnlineVideos());
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({ onlineVideos: [], fetchData: null }, () =>  this.fetchOnlineVideos());
    }
  };

  render = () => {
    return (
      <div className="highest-wrapper" style={{textAlign: "center", background: "white", minHeight: "100vh"}}>
        <h1 style={{textAlign: "center", fontSize: 30}}>{localization.get("online_videos.page_title")}</h1>

        <div style={{textAlign: "center"}}>
          <TextField
            label={localization.get("search")}
            onChange={e => this.setState({ materialName: e.target.value, pitch: null })}
            onKeyDown={this.handleKeyDown}
          />
          <IconButton onClick={this.handleSearch} >
            <Search />
          </IconButton>
        </div>

        <ApplyFilters>
          {!this.props.hideClientSelection && (
            <FilterByClient
              value={this.state.client}
              onChange={client => {
                this.setState(
                  {
                    client,
                    fetchData: null,
                    onlineVideos: [],
                    pitch: null
                  },
                  () => this.fetchOnlineVideos()
                );
              }}
            />
          )}
          {this.state.client && (
            <React.Fragment>
              <FilterByPitch
                value={this.state.pitch}
                clientId={getPropertySafe(this.state.client, "value")}
                onChange={pitch => {
                  this.setState(
                    { pitch, fetchData: null, onlineVideos: [] },
                    () => this.fetchOnlineVideos()
                  );
                }}
              />
            </React.Fragment>
          )}
          <FilterByTag
            value={this.state.tag}
            onChange={tag => {
              this.setState({ tag, fetchData: null, onlineVideos: [] }, () =>
                this.fetchOnlineVideos()
              );
            }}
          />
          {userIsSysAdmin() && (
            <FilterByWithLicense
              value={this.state.withLicense}
              onChange={withLicense => {
                this.setState(
                  { withLicense, fetchData: null, onlineVideos: [] },
                  () => this.fetchOnlineVideos()
                );
              }}
            />
          )}
          <FilterByDate
            title={localization.get("projects.approved_material.date_filter")}
            minDate={this.state.minDate}
            maxDate={this.state.maxDate}
            onChangeMin={date => this.setState({minDate: date, fetchData: null, onlineVideos: []}, () => this.fetchOnlineVideos())}
            onChangeMax={date => this.setState({maxDate: date, fetchData: null, onlineVideos: []}, () => this.fetchOnlineVideos())}
          />
        </ApplyFilters>

        {this.renderOnlineVideos()}
        {this.state.isShowMoreVideosButtonEnabled && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={this.fetchNextVideos}>
              {localization.get("load_more")}
            </Button>
          </div>
        )}
        {this.state.videoDetailsDialog}
        {this.state.shareDialog}
        {this.state.setOfflineDialog}
      </div>
    );
  };
}

class ApplyFilters extends Component {
  state = {
    open: false
  };

  render = () => {
    return (
      <div className="approved-materials-apply-filters">
        <div
          className="approved-materials-apply-filters-button"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <span>{localization.get("approved_materials.apply_filters")}</span>
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </div>
        <div
          className={classNames("approved-materials-apply-filters-expansor", {
            open: this.state.open
          })}
        >
          {this.state.open && (
            <div className="approved-materials-apply-filters-expansor-content">
              {this.props.children}
            </div>
          )}
        </div>
      </div>
    );
  };
}

const FilterByClient = ({ value, onChange }) => {
  const loadOptions = val =>
    request.client
      .getAll({
        page: 0,
        perPage: 20,
        filterBy: [{ column: "name", filter: val || "" }]
      })
      .then(res =>
        res.data.map(client => ({ value: client.id, label: client.name }))
      );

  return (
    <FilterByBase
      title={localization.get("select_client")}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
    />
  );
};

const _tags = [
  { value: "radio", label: localization.get("radio") },
  { value: "tv", label: localization.get("tv") },
  {
    value: "internet_cctv",
    label: localization.get("internet_cctv")
  },
  { value: "cine", label: localization.get("cine") }
];

const FilterByTag = ({ value, onChange }) => {
  const loadOptions = val =>
    Promise.resolve(_tags.filter(x => x.label.includes(val)));

  return (
    <FilterByBase
      title={localization.get("approved_materials.select_media")}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
    />
  );
};

const FilterByPitch = ({ value, clientId, onChange }) => {
  const loadOptions = val =>
    request.pitch
      .list(1, 20, { projectName: val || "", clientId: clientId || "" })
      .then(res =>
        res.data.map(pitch => ({ value: pitch.id, label: pitch.project_name }))
      );

  return (
    <FilterByBase
      key={clientId}
      title={localization.get("select_pitch")}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
    />
  );
};

const FilterByWithLicense = ({ value, onChange }) => {
  const loadOptions = () =>
    Promise.resolve([
      {
        value: "1",
        label: localization.get("approved_material.select_license.set")
      },
      {
        value: "0",
        label: localization.get("approved_material.select_license.pending")
      }
    ]);

  return (
    <FilterByBase
      title={localization.get("select_licenced")}
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
    />
  );
};

const FilterByBase = ({ title, value, loadOptions, onChange }) => {
  return (
    <div style={{ marginBottom: "0.5rem", maxWidth: "100%"}}>
      <p>{title}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 300 }}>
          <AsyncSelect
            value={value}
            loadOptions={loadOptions}
            onChange={onChange}
            defaultOptions
          />
        </div>
        <IconButton onClick={() => onChange(null)} disabled={!value}>
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

const FilterByDate = ({ title, minDate, maxDate, onChangeMin, onChangeMax }) => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: "0.5rem" }}>
        <p>{title}</p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          style={{marginBottom: 15, width: 300, maxWidth: "84%"}}
          label={localization.get('projects.approved_material.min_date')}
          value={minDate}
          onChange={onChangeMin}
        />
      </MuiPickersUtilsProvider>
      <IconButton onClick={() => onChangeMin(null)} disabled={!minDate}>
        <Close />
      </IconButton>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          style={{marginBottom: 15, width: 300, maxWidth: "84%"}}
          label={localization.get('projects.approved_material.max_date')}
          value={maxDate}
          onChange={onChangeMax}
        />
      </MuiPickersUtilsProvider>
      <IconButton onClick={() => onChangeMax(localization.moment())} disabled={!maxDate}>
        <Close />
      </IconButton>
      </div>
    </React.Fragment>
  )
};

const Video = ({
  src,
  title,
  onShowDetails,
  onSetOffline,
  onGoToPitch,
  needsLicense,
  onShare
}) => (
  <div
    style={{
      padding: 10,
      backgroundColor: "white",
      width: "100%",
      borderRadius: 10,
      boxShadow: "0 0 10px 0 #c1c1c1",
      float: "left"
    }}
  >
    <div
      style={{
        float: "left",
        paddingTop: "56%",
        position: "relative",
        overflow: "hidden",
        width: "100%",

      }}
    >
      <video
        style={{
          float: "left",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
        }}
        src={src}
        controls
      />
    </div>
    <div
      style={{
        float: "left",
        position: "relative",
        paddingTop: "20px",
        width: "100%"
      }}
    >
      <span style={{ flex: 2, fontSize: 16 }}>{title}</span>
      <div style={{ float: "right" }}>
        {(userIsSysAdmin() && needsLicense && getActiveClient()) &&
        <Tooltip title={localization.get("pending_license")}>
          <IconButton onClick={onShowDetails}>
            <Warning style={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        }
        <Tooltip title={localization.get("show_details")}>
          <IconButton onClick={onShowDetails}>
            <Assignment />
          </IconButton>
        </Tooltip>
        <Tooltip title={localization.get("set_offline")}>
          <IconButton onClick={onSetOffline}>
            <HighlightOff />
          </IconButton>
        </Tooltip>
        <Tooltip title={localization.get("share")}>
          <IconButton onClick={onShare}>
            <Share />
          </IconButton>
        </Tooltip>
        <Tooltip title={localization.get("online_videos.go_to_pitch")}>
          <IconButton onClick={onGoToPitch}>
            <Forward />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  </div>
);

class VideoDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags || [],
      license: props.license
    };
  }

  onUpdate = () => {
    request.onlineVideos
      .update(this.props.id, {
        tags: this.state.tags.map(x => x.value),
        license_id: getPropertySafe(this.state.license, "value")
      })
      .then(this.props.onSubmitted);
  };

  render = () => {
    const {
      title,
      producer,
      creativity,
      direction,
      date,
      description,
      onClose,
      open = true
    } = this.props;

    const { tags, license } = this.state;

    return (
      <Dialog
        PaperProps={{ style: { maxWidth: 500, width: "100%" } }}
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <table style={{ width: "100%", marginBottom: 10 }}>
            <tbody>
              <tr>
                <td>{localization.get("producer")}</td>
                <td align="right">{producer}</td>
              </tr>
              <tr>
                <td>{localization.get("creativity")}</td>
                <td align="right">{creativity}</td>
              </tr>
              <tr>
                <td>{localization.get("director")}</td>
                <td align="right">{direction}</td>
              </tr>
              <tr>
                <td>{localization.get("online_videos.date")}</td>
                <td align="right">{date}</td>
              </tr>
            </tbody>
          </table>
          {/** Change Tags */}
          <p>{localization.get("approve_material.tags")}</p>
          <AsyncSelect
            isDisabled={!userIsSysAdmin()}
            loadOptions={() => Promise.resolve(_tags)}
            value={tags}
            defaultOptions
            isMulti
            onChange={tags => this.setState({ tags })}
          />
          {userIsSysAdmin() && (
            <React.Fragment>
              <br />
              {/** Change License */}
              <p>{localization.get("approve_material.license")}</p>
              <AsyncSelect
                loadOptions={value => {
                  return request.license
                    .getAll({
                      page: 0,
                      perPage: 20,
                      filterBy: [
                        { column: "music[title]", filter: value || "" }
                      ]
                    })
                    .then(res =>
                      res.data.map(x => ({
                        value: x.id,
                        label: `${x.music.title} | ${
                          x.client.name
                        } | ${licenseTypeIdToString(x.type.id)}`
                      }))
                    );
                }}
                value={license}
                defaultOptions
                onChange={license => this.setState({ license })}
              />
            </React.Fragment>
          )}
          <hr />
          {/** Description */}
          <p style={{ textAlign: "justify" }}>{description}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{localization.get("close")}</Button>
          {(this.props.tags !== this.state.tags ||
            this.props.license !== this.state.license) && (
            <Button onClick={this.onUpdate}>
              {localization.get("update")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
}

class SetOfflineDialog extends Component {
  state = {
    requesting: false
  };

  onShare = () => {
    setStatePromise(this, { requesting: true })
      .then(this.props.setOfflinePromise)
      .then(() => {
        GlobalSnackbar.showGenericSuccess();
        this.props.onClose();
      })
      .catch(() => {
        GlobalSnackbar.showGenericError();
        this.setState({ requesting: false });
      });
  };

  onClose = () => {
    if (!this.state.requesting) {
      this.props.onClose();
    }
  };

  render = () => (
    <Dialog open={true} onClose={this.onClose}>
      <DialogTitle>{this.props.title}</DialogTitle>
      <DialogContent>
        {localization.get("setting_video_to_offline")}
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onClose} disabled={this.state.requesting}>
          {localization.get("close")}
        </Button>
        <Button onClick={this.onShare} disabled={this.state.requesting}>
          {localization.get("send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
