import * as WLFH from "../../../components/Letflow/Form/WebLikeFormHelpers";
import localization from "../../../config/localization";
import React, {Component} from "react";
import {ErrorMessage, Field} from "formik";
import classNames from "classnames";
import {Button} from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import {NavigateNext} from "@material-ui/icons";


export class Step1 extends Component {

  state = {
    isSubmitting: false,
    hide:false
  }

  onNextStep = () => {
    let setFieldTouched = this.props.formikProps.setFieldTouched

    this.props.formikProps.validateForm().then(errors => {
      if (errors.name || errors.email || errors.countryId || errors.startActivity || errors.description) {
        setFieldTouched('countryId', true)
        setFieldTouched('name', true)
        setFieldTouched('email', true)
        setFieldTouched('startActivity', true)
        setFieldTouched('description', true)
      } else {
        this.props.nextStep()
      }
    })
  }

  render = () => {
  const {touched, errors, setFieldValue, setFieldTouched} = this.props.formikProps
  return (
    <>
      <div style={{ width: "100%", maxWidth: 500, padding: '0px 20px 70px', margin: "auto"}}>
        <h4 className="web-like-h4">{localization.get("form.info")}</h4>
        {/** Name */}
        <Field
          name="name"
          type="text"
          className={classNames("web-like-input", {
          "web-like-error": Boolean(touched["name"] && errors["name"])
        })}
          placeholder={localization.get("form.name") + " *"}
        />
        <ErrorMessage name="name" className="web-like-error">
          {msg => <div className="web-like-error">{msg}</div>}
        </ErrorMessage>

        {/** Email */}
        <Field
          name="email"
          type="email"
          className={classNames("web-like-input", {
            "web-like-error": Boolean(touched["email"] && errors["email"])
          })}
          placeholder={localization.get("form.email") + " *"}
        />
        <ErrorMessage name="email" className="web-like-error">
          {msg => <div className="web-like-error">{msg}</div>}
        </ErrorMessage>

        {/** Phone */}
        <Field
          name="phone"
          type="text"
          onChange={e => {
            if (isNaN(e.target.value)) {
              e.preventDefault()
            } else {
              setFieldValue('phone', e.target.value)
            }
          }}
          className={classNames("web-like-input", {
            "web-like-error": Boolean(touched["phone"] && errors["phone"])
          })}
          placeholder={`${localization.get(
            "form.phone"
          )} (${localization.get("form.optional")})`}
        />

        {/** Activity start */}
        <div style={{ color: "#cccccc", margin: "16px 0 5px", lineHeight: 0.5 }}>
          {localization.get("start_activity") + " *"}
        </div>
        <WLFH.DatePicker
          field="startActivity"
          errors={errors}
          touched={touched}
          onChange={(field, date) => setFieldValue(field, date)}
          onBlur={() => setFieldTouched('startActivity', true)}
          type="date"
        />

        {/** Country */}
        <div style={{ color: "#cccccc", margin: "16px 0 5px", lineHeight: 0.5 }}>
          {localization.get("form.country_of_origin") + " *"}
        </div>
        <Field name="countryId">
          {({field, form}) => (
            <AsyncSelect
              onBlur={() => setFieldTouched('countryId', true)}
              styles={{
                control: base => ({
                  ...base,
                  color: touched["countryId"] && errors["countryId"] && 'red',
                  borderColor: (touched["countryId"] && errors["countryId"]) ? 'red' : "#ccc",
                  '&:hover': {
                    cursor: 'text'
                  }
                }),
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 4,
              })}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              defaultOptions
              onChange={(country) => setFieldValue('countryId', country.value)}
              loadOptions={inputValue =>
                request.country
                  .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                  .then(res => res.map(country => ({ value: country.id, label: `${country.name}` })))
              }
            />


          )}
        </Field>
        <ErrorMessage name="countryId" className="web-like-error">
          {msg => <div className="web-like-error">{msg}</div>}
        </ErrorMessage>

        {/** Description */}
        <Field name="description">
          {({field, form}) => (
              <textarea
                {...field}
                className={classNames("web-like-textarea", {
                  "web-like-error": Boolean(touched["description"] && errors["description"])
                })}
                placeholder={localization.get("form.bio") + " *"}
                rows={5}
              />
          )}
        </Field>
        <ErrorMessage name="description" className="web-like-error">
          {msg => <div className="web-like-error">{msg}</div>}
        </ErrorMessage>

        <Button
          style={{float: 'right', marginTop: 10, backgroundColor: "rgba(26 , 26, 26, 1)", color: "white"}}
          variant='raised'
          onClick={() => this.onNextStep()}
        >
          {localization.get('license_wizard.next')}
          <NavigateNext className={"material-icons md-24"} style={{color: "white", marginRight: "-12px"}}/>
        </Button>

      </div>
    </>
  )}
}

