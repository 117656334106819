import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  requestCreation = state => {

    this.setState({ disabled: true })

    const {
      translations,
      slug,
      image,
      allowImage,
      allowAudio,
      allowVideo,
      allowBinary,
      allowLink,
      allowEmbed,
      limit,
      isTalent,
      visibility,
      client,
      type,
      synonyms,
      downloadable,
    } = state

    return request.contentCreatorType.create({
      translations,
      slug,
      image: image.file,
      allowImage,
      allowAudio,
      allowVideo,
      allowBinary,
      allowLink,
      allowEmbed,
      limit,
      isTalent,
      visibility,
      type,
      synonyms,
      downloadable,
      clientId: client && visibility === 'private' && client.value
    }).then(() => this.props.history.push(makeUrlWithTableParams(this.props.location.pathname.includes('talents') ? '/panel/talents/content_creator_types' : '/panel/audiovisual/content_creator_types')))
  }

  render = () => {
    return (
      <Form
        {...this.props}
        title={localization.get('content_creator_type.create_content_creator_type')}
        submitRequest={this.requestCreation}
        isTalent={this.props.location.pathname.includes('talents')}
        isEditorial={this.props.location.pathname.includes('editorial')}
        creationForm
      />
    )
  }
}
