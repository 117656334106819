import React, {Component} from "react";
import localization from "../../config/localization";
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import {Formik} from "formik";
import {validateEmail} from "../../utils";
import {request} from "../../api-client";
import * as WLFH from "../../components/Letflow/Form/WebLikeFormHelpers";
import GlobalSnackbar from "../../components/Letflow/Snackbar/GlobalSnackbar";
import GlobalConfirmationDialog from "../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {Dialog, DialogContent} from "@material-ui/core";
import {BounceLoader} from "react-spinners";
import SocialLinks from "../../components/Letflow/SocialLinks";

class PublicCreateArtist extends Component {
  render = () => {
    return (
      <React.Fragment>
        <PublicNavBar publicLogo={"FlowlikeMusic"} showLinks={false} showPanel={false} />
        <div
          style={{
            minHeight: "100vh",
            width: "100%"
          }}
        >
          <FormController />
        </div>
        <PublicFooter {...this.props} />
      </React.Fragment>
    );
  };
}

class FormController extends Component {
  state = {
    sendingCurrentStep: 0,
    sendingSteps: 0,
    submitted: false,
    countries: [],
  };

  componentDidMount = () => {
    request.country.getAll()
      .then(countries => this.setState({countries}))
  };

  validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = localization.get("form.validation.required");
    }
    if (!values.privacy) {
      errors.privacy = localization.get("form.validation.required");
    }
    if (!validateEmail(values.email)) {
      errors.email = localization.get("form.validation.invalid_email");
    }
    if (!values.countryId) {
      errors.countryId = localization.get("form.validation.required");
    }

    if (values.demos.length === 0 || values.demos.length > 3) {
      errors.demos = localization.get(
        "form.validation.files_required_range",
        1,
        3
      );
    }
    if (values.photos.length === 0 || values.photos.length > 1) {
      errors.photos = localization.get(
        "form.validation.files_required_range",
        1,
        1
      );
    }
    return errors;
  };

  initialValues = () => ({
    name: "",
    email: "",
    countryId: '',
    countries: [],
    birthDate: undefined,
    description: "",
    demos: [],
    photos: [],
    phone: "",
    date: "",
  });

  createArtist = values => {
    this.setState({
      sendingCurrentStep: 0,
      sendingSteps: 1 + values.demos.length
    });
    return request.artist.createDemo(
      {
        name: values.name,
        email: values.email,
        countryId: values.countryId,
        translations: [
          {language: 'es', description: values.description},
          {language: 'en', description: values.description},
        ],
        birthdate: values.birthDate,
        image: values.photos[0],
        phone: values.phone,
        audios: this.appendDemos(values.demos),
        isDemo: true,
        socialLinks: values.socialLinks
      }
    );
  };

  appendDemos = (files) => {
    let audios = [];
    Array.from(files).forEach(file => {
      let src = URL.createObjectURL(file)
      audios.push({src, file})
    })
    return audios
  };

  showUpdateSuccessful = () => {
    const returnToWeb = () =>
      (window.location.href = process.env.REACT_APP_WEB_HOST);
    GlobalConfirmationDialog.show({
      title: localization.get("demo.uploaded_dialog_title"),
      content: localization.get("demo.uploaded_dialog_content"),
      onClose: returnToWeb,
      hideCancel: true,
      request: () => Promise.resolve().then(returnToWeb)
    });
  };

  render = () => {
    return (
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          this.createArtist(values)
            .then(() => {
              this.showUpdateSuccessful();
              this.setState({ submitted: true });
            })
            .catch(() => GlobalSnackbar.showGenericError())
            .finally(() => actions.setSubmitting(false));
        }}
      >
        {formikProps => (
          <React.Fragment>
            <FormView
              errors={formikProps.errors}
              touched={formikProps.touched}
              onChange={(field, value) => {
                formikProps.setFieldValue(field, value);
              }}
              countries={this.state.countries}
              onSubmit={formikProps.handleSubmit}
              submitted={this.state.submitted}
            />
            <Dialog open={formikProps.isSubmitting}>
              <DialogContent>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ paddingRight: "1rem" }}>{`${localization.get(
                    "demo.uploading"
                  )} ${this.state.sendingCurrentStep} / ${
                    this.state.sendingSteps
                    }`}</div>
                  <BounceLoader size={26} />
                </div>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </Formik>
    );
  };
}

const FormView = ({
    errors,
    touched,
    submitted,
    onChange,
    onSubmit,
    countries
  }) => {
  return (
    <WLFH.FormContainer errors={errors} touched={touched} onChange={onChange}>
      <WLFH.Title>{localization.get("artist.upload_title")}</WLFH.Title>
      <WLFH.SectionTitle>{localization.get("form.info")}</WLFH.SectionTitle>
      {/** Name */}
      <WLFH.Input
        field="name"
        type="text"
        placeholder={localization.get("form.name") + " *"}
      />
      {/** Email */}
      <WLFH.Input
        field="email"
        type="text"
        placeholder={localization.get("form.email") + " *"}
      />
      {/** Country */}
      <WLFH.FieldTitle>{localization.get("form.country_of_origin")}</WLFH.FieldTitle>
      <WLFH.Select
        field="countryId"
        options={countries.map(x => ({ value: x.id, label: x.name }))}
      />

      {/** Birthdate */}
      <WLFH.FieldTitle>{localization.get("form.birth_date")}</WLFH.FieldTitle>
      <WLFH.DatePicker
        field="birthDate"
        type="date"
      />

      {/** Phone */}
      <WLFH.Input
        field="phone"
        type="text"
        placeholder={`${localization.get(
          "form.phone"
        )} (${localization.get("form.optional")})`}
      />
      {/** Description */}
      <WLFH.TextArea
        field="description"
        type="text"
        placeholder={`${localization.get(
          "form.description"
        )} (${localization.get("form.optional")})`}
      />

      <SocialLinks
        onSocialLinksChange={(socialLinks) => onChange('socialLinks', socialLinks)}
        publicView={true}
      />

      {/** Photos */}
      <WLFH.MediaPicker
        field="photos"
        title={localization.get("form.pick_high_quality_pictures_range")}
        accept="image/*"
      />
      <WLFH.SectionTitle>{localization.get("form.media")}</WLFH.SectionTitle>
      {/** Demos */}
      <WLFH.MediaPicker
        field="demos"
        title={localization.get("voice_talent.demo_picker_title", 3)}
        accept=".mp3"
      />
      {/** Privacy */}
      <WLFH.Checkbox
        field="privacy"
        label={`${localization.get("form.privacy_agreement")}`}
        style={{paddingTop: 20}}
      />
      <div style={{marginTop: 10}}>
        <p style={{color: "#AAAAAA", fontSize: 14}}>{localization.get('public_speaker.first_line')}</p>
        <p style={{color: "#AAAAAA", fontSize: 14}}>{localization.get('public_speaker.second_line')}</p>
        <p style={{color: "#AAAAAA", fontSize: 14}}>{localization.get('public_speaker.third_line')}</p>
      </div>

      <WLFH.SubmitButton
        onClick={onSubmit}
        disabled={submitted}
        hasErrors={Object.keys(errors).length > 0}
      />
    </WLFH.FormContainer>
  );
};

export default PublicCreateArtist;
