import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => (channelId, { fileId }) =>
  post(`${host}/channels/${channelId}/codes`, makeStoreManyFormData({ fileId }))
    .then(response => response.json())

const makeStoreManyFormData = ({fileId}) => {
  const form = new FormData();
  if (fileId) form.append("file_id", fileId);

  return form;
}