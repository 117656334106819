import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery} from "./utils";

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/tags?${includeQuery}`, { ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)