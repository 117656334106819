import Home from "../../layouts/Home";
import {makeRoutes, publicRoutes, redirectTo, route} from "./utils";
import {homePathGenerator} from "../../api-client/core/authentication/utils";

const homeRedirect = client => redirectTo(homePathGenerator('', client));

const allowed = () => publicRoutes.concat([route(`/home/:clientId-:clientName`, Home)]);

const def = client => route("/", homeRedirect(client));

export default client => makeRoutes(allowed(client), [], def(client));
