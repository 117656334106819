import {post} from "../utils";
import {getActiveClient} from "../authentication/utils";

export default host => (reelId, emails, clientId) =>
  post(
    `${host}/clients/${getActiveClient()}/reels/${reelId}/share`,
    JSON.stringify({emails, client_id: clientId,})
  )
    .then(response => response.json())
    .then(json => json.data);
