import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    phone,
    description,
    duration,
    territory,
    budget,
    clientId,
  }) =>
  post(`${host}/originalMusic`, makeFormData({
    phone,
    description,
    duration,
    territory,
    budget,
    clientId,
  }))
  .then(response => response.json())