import {
  CountriesForRegion,
  InternetBudgets,
  LicenseTypeIds,
  MediaTypeIds,
  RegionIds,
  SadaicCategories,
  SadaicDurations,
  SadaicSeconds
} from "./constants";
import localization from '../../../../config/localization'

export const licenseTypeIdToString = licenseTypeId => {
  switch (licenseTypeId) {
    case LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS:
      return localization.get('license_wizard.type.corporate');
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL:
      return localization.get('license_wizard.type.publicity');
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA:
      return localization.get('license_wizard.type.publicity_argentine');
  }
};

export const mediaIdToString = mediaId => {
  switch (mediaId) {
    case MediaTypeIds.INTERNET:
      return localization.get('license_wizard.media.internet');
    case MediaTypeIds.RADIO:
      return localization.get('license_wizard.media.radio');
    case MediaTypeIds.TELEVISION:
      return localization.get('license_wizard.media.television');
    case MediaTypeIds.ALL_MEDIA:
      return localization.get('license_wizard.media.all_media');
  }
};

export const regionIdToString = regionId => {
  switch (regionId) {
    case RegionIds.ARGENTINA:
      return localization.get('license_wizard.region.argentine');
    case RegionIds.ASIA:
      return localization.get('license_wizard.region.asia');
    case RegionIds.EUROPE:
      return localization.get('license_wizard.region.europe');
    case RegionIds.NORTH_AMERICA:
      return localization.get('license_wizard.region.north_america');
    case RegionIds.PACIFIC:
      return localization.get('license_wizard.region.pacific');
    case RegionIds.LATAM:
      return localization.get('license_wizard.region.latin_america');
    case RegionIds.GLOBAL:
      return localization.get('license_wizard.region.all_regions');
  }
};

export const extentIdToRegion = extentId => {
  switch (extentId) {
    case 1:
      return RegionIds.GLOBAL;
    case 2:
      return RegionIds.NORTH_AMERICA;
    case 3:
    case 4:
    case 5:
    case 6:
      return RegionIds.LATAM;
    case 7:
    case 8:
    case 9:
    case 10:
      return RegionIds.EUROPE;
    case 11:
    case 12:
    case 13:
    case 14:
      return RegionIds.ASIA;
    case 15:
    case 16:
    case 17:
    case 18:
      return RegionIds.PACIFIC;
  }
};

export const regionIdToExtents = regionId => {
  switch (regionId) {
    case RegionIds.ARGENTINA:
      return [];
    case RegionIds.ASIA:
      return [11, 12, 13, 14];
    case RegionIds.EUROPE:
      return [7, 8, 9, 10];
    case RegionIds.NORTH_AMERICA:
      return [2];
    case RegionIds.PACIFIC:
      return [15, 16, 17, 18];
    case RegionIds.LATAM:
      return [3, 4, 5, 6];
    case RegionIds.GLOBAL:
      return [1];
  }
};

export const regionIdAndCountriesForRegionToExtent = (regionId, countriesForRegion) => {
  switch (regionId) {
    case RegionIds.GLOBAL:
    case RegionIds.NORTH_AMERICA:
      return regionIdToExtents(regionId)[0];
    default:
      return regionIdToExtents(regionId)[countriesForRegion - 1];
  }
};

export const extentIdToRegionIdAndCountriesForRegion = extentId => {
  const region = extentIdToRegion(extentId);
  const extents = regionIdToExtents(region);
  return {
    id: region,
    countriesForRegion: extents.indexOf(extentId) + 1
  };
};

export const countriesForRegionToString = countriesForRegion => {
  switch (countriesForRegion) {
    case CountriesForRegion.ALL:
      return localization.get('license_wizard.country.all_countries');
    case CountriesForRegion.ONE:
      return localization.get('license_wizard.country.one_country');
    case CountriesForRegion.TWO:
      return localization.get('license_wizard.country.two_countries');
    case CountriesForRegion.THREE:
      return localization.get('license_wizard.country.three_countries');
  }
};

export const sadaicCategoryToString = category => {
  switch (category) {
    case SadaicCategories.USO_TOTAL:
      return localization.get('license_wizard.sadaic.category.total_use');
    case SadaicCategories.RESTRINGIDO_I:
      return localization.get('license_wizard.sadaic.category.restricted_1');
    case SadaicCategories.RESTRINGIDO_II_III:
      return localization.get('license_wizard.sadaic.category.restricted_2_3');
    case SadaicCategories.RESTRINGIDO_IV:
      return localization.get('license_wizard.sadaic.category.restricted_4');
    case SadaicCategories.VPNT:
      return localization.get('license_wizard.sadaic.category.vpnt');
    case SadaicCategories.INCLUSION_EN_TV:
      return localization.get('license_wizard.sadaic.category.tv_inclusion');
  }
};

export const sadaicCategoryToDescriptionString = category => {
  switch (category) {
    case SadaicCategories.USO_TOTAL:
      return localization.get('license_wizard.sadaic.category.description.total_use');
    case SadaicCategories.RESTRINGIDO_I:
      return localization.get('license_wizard.sadaic.category.description.restricted_1');
    case SadaicCategories.RESTRINGIDO_II_III:
      return localization.get('license_wizard.sadaic.category.description.restricted_2_3');
    case SadaicCategories.RESTRINGIDO_IV:
      return localization.get('license_wizard.sadaic.category.description.restricted_4');
    case SadaicCategories.VPNT:
      return localization.get('license_wizard.sadaic.category.description.vpnt');
    case SadaicCategories.INCLUSION_EN_TV:
      return localization.get('license_wizard.sadaic.category.description.tv_inclusion');
  }
};

export const sadaicMonthsToString = months => {
  switch (months) {
    case SadaicDurations.SIX_MONTHS:
      return `6 ${localization.get('license_wizard.months')}`;
    case SadaicDurations.TWELVE_MONTHS:
      return `12 ${localization.get('license_wizard.months')}`;
  }
};

export const sadaicSecondsToString = seconds => {
  switch (seconds) {
    case SadaicSeconds.UP_TO_TEN:
      return `${localization.get('license_wizard.until')} 10''`;
    case SadaicSeconds.UP_TO_TWENTY:
      return `${localization.get('license_wizard.until')} 20''`;
    case SadaicSeconds.UP_TO_THIRTY:
      return `${localization.get('license_wizard.until')} 30''`;
    case SadaicSeconds.UP_TO_FORTY_FIVE:
      return `${localization.get('license_wizard.until')} 45''`;
    case SadaicSeconds.UP_TO_SIXTY:
      return `${localization.get('license_wizard.until')} 60''`;
    case SadaicSeconds.MORE_THAN_SIXTY:
      return `${localization.get('license_wizard.more_than')} 60''`;
    case SadaicSeconds.UP_TO_FIFTEEN:
      return `${localization.get('license_wizard.until')} 15''`;
  }
};

export const internetBudgetToString = budget => {
  switch (budget) {
    case InternetBudgets.UP_TO_2000:
      return `${localization.get('license_wizard.until')} 2000`;
    case InternetBudgets.UP_TO_5000:
      return `${localization.get('license_wizard.until')} 5000`;
    case InternetBudgets.MORE_THAN_5000:
      return `${localization.get('license_wizard.more_than')} 5000`;
  }
};
