import React, {Component} from "react";
import {ServerSidePaginationTable} from "../../../components/Letflow/Table";
import {request} from '../../../api-client';
import localization from "../../../config/localization";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {parseDate} from "../../../utils";

class CatalogsReportTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      minDate: null,
      maxDate: null
    }
  }

  onChangeFrom = (minDate) => this.setState({ minDate }, this.refs.table.forceUpdate)
  onChangeTo = (maxDate) => this.setState({ maxDate }, this.refs.table.forceUpdate)

  columns = () => [
    {
      id: "name",
      Header: localization.get("catalogs"),
      accessor: 'name',
      sortable: false,
      filterable: true,
    },
    {
      id: "musicDealer",
      Header: "Music Dealer",
      accessor: catalog => catalog.musicDealer ? catalog.musicDealer.name : "Feater",
      sortable: false,
      filterable: false
    },
    {
      id: "clients_count",
      Header: localization.get("clients"),
      accessor: 'clients_count',
      sortable: true,
      filterable: false
    },
    {
      id: "musics_count",
      Header: localization.get("musics.active"),
      accessor: 'musics_count',
      filterable: false,
      sortable: true,
    },
    {
      id: "licenses_count",
      Header: localization.get("licenses"),
      accessor: 'licenses_count',
      sortable: true,
      filterable: false,
      headerStyle: {
        textAlign: "left"
      }
    },
  ];

  render = () => {
    return (
      <div>
        <div style={{width: "100%", float: "left", position: "relative"}}>
          <div style={{ textAlign: 'center', marginBottom: '15px', padding: '10px' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                style={{ width: 150, marginLeft: 20, marginRight: 20 }}
                label={localization.get('form.from')}
                autoOk
                keyboard
                clearable
                onlyCalendar
                disableFuture
                value={this.state.minDate}
                format={"dd/MM/yyyy"}
                onChange={this.onChangeFrom}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                style={{ width: 150 }}
                label={localization.get('form.to')}
                autoOk
                keyboard
                clearable
                onlyCalendar
                disableFuture
                value={this.state.maxDate}
                format={"dd/MM/yyyy"}
                onChange={this.onChangeTo}
              />
            </MuiPickersUtilsProvider>
          </div>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            requestTableData={paginationConfig => {

              if (this.state.minDate) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "min_date", filter: parseDate(this.state.minDate)}])
                }
              }
              if (this.state.maxDate) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "max_date", filter: parseDate(this.state.maxDate)}])
                }
              }

              return request.catalog.getReport(paginationConfig).then((response) => {
                this.setState({total: response.meta.total})
                return response
              })
            }}
          />
        </div>
      </div>
    )
  }

}

export default CatalogsReportTable
