import {post} from '../utils'
import { getActiveChannel, getContentCreatorFavorites, setContentCreatorFavorites } from "../authentication/utils";

export default host => ( id ) =>
  post(`${host}/channels/${getActiveChannel()}/contentCreators/${id}/favorite`)
    .then(() => {
      const favs = getContentCreatorFavorites();
      favs.push(id);

      setContentCreatorFavorites(favs);
    })
