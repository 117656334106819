import React, {Component} from "react";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import ContentCreator from "../../layouts/Home/ContentCreator";
import {getActiveChannel} from "../../api-client/core/authentication/utils";
import Footer from "../../layouts/Home/HomeElements/Footer";
import {request} from "../../api-client";
import {makeFitImageUrl} from "../../utils"

class PublicContentCreator extends Component {
  state = {
    producer: null,
    channel: false,
  };

  componentDidMount = () => {
    getActiveChannel() && request.channel.get(getActiveChannel()).then(channel => {
      this.setState({channel, hasDomain: true, client: channel.client});
    })
  }

  render = () => {
    return (
      <div>
        {!this.props.channel && !getActiveChannel() && <PublicNavBar client={this.state.client && this.state.client} />}
        {!!this.state.channel && !!this.state.channel.image &&
          <a style={{position: "absolute",zIndex: 1,left: 65,top: 10}} href={"/"}>
            <img className="navbar-image" src={makeFitImageUrl(this.state.channel, 400,50, '', 'png')} alt={`Logo - ${this.state.channel.name}`} />
          </a>
        }
        <div id={"inner"}>
          <ContentCreator contentCreatorId={this.props.match.params.contentCreatorId} isPublic={true}/>
        </div>
        {!getActiveChannel() && <PublicFooter {...this.props} />}
        {!!this.state.channel && <Footer {...this.props} channel={this.state.channel}/>}
      </div>
    );
  };
}

export default PublicContentCreator;
