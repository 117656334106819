import "layouts/Home/Home.css";
import React from "react";
import HomeElementGrid from "./HomeElementGrid";
import HomeItemDescription from "./HomeItemDescription";
import {getActiveChannel, getStoredChannel, getActiveClient} from "../../../api-client/core/authentication/utils";
import st from "../HomeNew.module.css"
import styled from "styled-components";
import HomeElementTitle from "./HomeElementTitle";
import EventManager, {events} from "../../../utils/EventManager";

class HomeElementGridContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileResize: (getActiveChannel() && getStoredChannel().preferences && getStoredChannel().preferences.mobile_font_resize) ? (Number(getStoredChannel().preferences.mobile_font_resize) / 100) : 1,
    }
  }

  handleDeletePlaylist = (id) => {
    this.props.homeElement.items.data = this.props.homeElement.items.data.filter(playlist => playlist.id !== id)
    this.setState({homeElement: this.props.homeElement})
  }

  handleElementClick = element => {
    EventManager.getInstance().dispatch(
      events.HOME_ELEMENT_ITEM_CLICKED,
      element
    );
    
    if(element.kind !== "video_content_creation") {
      this.setState({requestedElement: null, loading: true, selectedElement: element.id});
      this.props.requestElement(element.id,element.kind, getActiveClient(),element)
      .then(requestedElement => {this.setState({requestedElement, loading: false,selectedElement: null});})
      .catch(() => this.setState({ loading: false, selectedElement: null }));
    }else {
        EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  element.id, materialList: element.materialIds || []});
    }
  };

  render() {
    const sectionStyle = {
      ...(this.props.hidePadding && { padding: "0px !important" }),
      ...(this.props.scrollable && { 
        overflowY: "auto",
        maxHeight: "60vh"
      })
    };

    return (
      <section style={sectionStyle}>
        {/* Divisor */}
        <div style={{
          left: 0,
          width: "100%",
          position: "absolute",
          height: this.props.backgroundStyle === 'default' ? "0px" : `${this.props.backgroundStyle}%`,
          backgroundColor: this.props.backgroundColor
        }}/>
        <div className={st["row-category"]} style={{ width: "100%", position: "relative", float: "left", paddingBottom: this.props.bottomPadding }}>
          <HomeElementTitle {...this.props} />
          <HomeElementGrid
            {...this.props}
            ref='elementDescription'
            sizes={this.props.sizes}
            requestElement={this.props.requestElement}
            showMore={this.props.showMore}
            rowsToShow={this.props.rowsToShow}
            maxPerRow={this.props.maxPerRow}
            shape={this.props.shape}
            fixedTitle={this.props.fixedTitle}
            withBackground={this.props.withBackground}
            withText={this.props.withText}
            homeElement={this.props.homeElement}
            requestTracks={this.props.requestTracks}
            renderGridElement={(element, onClick) => <this.props.itemComponent 
                {...this.props}
                data={element}
                onClick={onClick}
                onDelete={(id) => this.handleDeletePlaylist(id)}
                handleElementClick={() => this.handleElementClick(element)}
              />
            }
            renderElementDescription={(element, onClose) => <HomeItemDescription
              {...this.props}
              data={element}
              onClose={() => {
                this.refs.elementDescription.resetRequestedElement();
                onClose()
              }}
              favorites={this.props.favorites}
              speakersFavorites={this.props.speakersFavorites}
              trackType={this.props.trackType()}
              dataType={this.props.dataType}
              home={this.props.home}
            />}
          />
        </div>
      </section>
    )
  }

}

HomeElementGridContainer.defaultProps = {
  showSectionTitle: true
}

export default HomeElementGridContainer


const EditorContainer = styled.div`
  .rdw-link-decorator-icon {
    display: none;
  }
  padding: 50px 0px 0;
  overflow-wrap: break-word;
  @media (max-width: 650px) {
    font-size: ${props => props.mobileDefaultFontSize ? `${props.mobileDefaultFontSize}px` : "14px"};
  }
`;