import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import { FilterNone, Launch, Edit } from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {CopyToClipboard} from "react-copy-to-clipboard";
import moment from "moment";
import TableSubcomponent from "./TableSubcomponent";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";

let table

class Table extends Component {
  state = {
    copied1: localization.get('copy_link.without_files'),
    copied2: localization.get('copy_link.with_files'),
    sharingLink: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname || prevProps.update !== this.props.update) {
      table.forceUpdate()
    }
  }

  buttons = batchUploadLink => {
    const builder = new TableButtonsBuilder()
    let adminToken = `20${batchUploadLink.token.split("").reverse().join("")}-1`

    builder.withOther(
      <TableButton
        title={localization.get('edit')}
        onClick={() => this.props.onUpdate(batchUploadLink.id)}
      >
        <Edit />
      </TableButton>
    )

    builder.withOther(
      <TableButton
        title={localization.get('links.go_to')}
        onClick={() => window.open(`${window.location.origin}/public/batch_upload/${batchUploadLink.protected ? batchUploadLink.token :adminToken}` , "_blank")}
      >
        <Launch/>
      </TableButton>
    )


    builder.withOther(
      <CopyToClipboard
        onCopy={() => this.setState({copied1: localization.get('copied')},
          () => setTimeout(() => this.setState({copied1: localization.get('copy_link.without_files')}), 1000))}
        text={`${process.env.REACT_APP_FRONT_HOST}/public/batch_upload/${batchUploadLink.token}`}
      >
        <TableButton
          title={localization.get(this.state.copied1)}
        >
          <FilterNone/>
        </TableButton>
      </CopyToClipboard>
    )

    builder.withOther(
        <TableButton
          disabled={batchUploadLink.protected}
          title={localization.get(this.state.copied2)}
          onClick={() => this.setState({sharingLink: batchUploadLink})}
        >
          <FilterNone/>
        </TableButton>
    )

    builder.withDelete(batchUploadLink.name, () => request.batchUpload.deleteLink(batchUploadLink.id), status => {
      if (status === 'passed') {
        table.removeById(batchUploadLink.id)
      }
    }, true)
    return builder.build();
  }

  columns = () => [
    {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    },
    {
      Header: localization.get("table.date"),
      accessor: "created_at",
      Cell: ({ value }) => <span>{moment(value).format("DD-MM-YYYY")}</span>,
      minWidth: 100,
      filterable: false,
      sortable: true
    },
    {
      Header: localization.get("client"),
      accessor: "client[name]",
      minWidth: 112,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.client
            .getAll({
              filterBy: [{
                column: 'name', filter
              }, {
                column: 'has_batch_upload_links', filter: true
              }]
            })
            .then(clients => clients.map(client => client.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />,
      sortable: false
    },
    {
      Header: localization.get("batch_upload.last_content"),
      accessor: "files[0].filename",
      minWidth: 112,
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get("batch_upload.content_amount"),
      accessor: "files_count",
      Cell: ({ value }) => <div style={{textAlign: "center"}}>{value}</div>,
      width: 115,
      filterable: false,
      sortable: true,
    },
    {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
    }
  ]


  copyLinkDialog = () =>
    <Dialog open={this.state.sharingLink}  onBackdropClick={() => this.setState({sharingLink: null})} onEscapeKeyDown={() => this.setState({sharingLink: null})}>
      {this.state.sharingLink &&
        <>
          <DialogTitle>{localization.get('share')} {this.state.sharingLink.name}</DialogTitle>
          <DialogContent>
            <div style={{display: "flex", flexDirection: "column", minWidth: 500}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "5px 0"}}>
                <p>{localization.get('share.main_link')}</p>
                <CopyToClipboard
                  onCopy={() => this.setState({copied1: localization.get('copied')},
                    () => setTimeout(() => this.setState({
                      sharingLink: null,
                      copied1: localization.get('copy_link')
                    }), 1000))}
                  text={`${process.env.REACT_APP_FRONT_HOST}/public/batch_upload/20${this.state.sharingLink.token.split("").reverse().join("")}-1`}
                >
                  <TableButton
                    title={localization.get(this.state.copied1)}
                  >
                    <FilterNone/>
                  </TableButton>
                </CopyToClipboard>
              </div>
              {this.state.sharingLink.file_labels.filter(label => label !== "").length > 0 && <h3>{localization.get('share.custom_folder')}</h3>}
              {this.state.sharingLink.file_labels.filter(label => label !== "").map(label =>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "5px 0"}}>
                  <p>{localization.get('folder')}: {label}</p>
                  <CopyToClipboard
                    onCopy={() => this.setState({copied1: localization.get('copied')},
                      () => setTimeout(() => this.setState({
                        sharingLink: null,
                        copied1: localization.get('copy_link')
                      }), 1000))}
                    text={`${process.env.REACT_APP_FRONT_HOST}/public/batch_upload/20${this.state.sharingLink.token.split("").reverse().join("")}-1?folder=${label}`}
                  >
                    <TableButton
                      title={localization.get(this.state.copied1)}
                    >
                      <FilterNone/>
                    </TableButton>
                  </CopyToClipboard>
                </div>
              )}
            </div>
          </DialogContent>
        </>
      }
    </Dialog>


  render = () => {
    const {isTalent} = this.props;

    return (
      <>
        {this.copyLinkDialog()}
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref={r => table = r}
            columns={this.columns()}
            subComponent={row => <TableSubcomponent {...this.props} link={row.original}/>}
            requestTableData={paginationConfig => {
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'is_talent', filter: isTalent ? 1 : 0  }])}

              return request.batchUpload.getAll(paginationConfig)
            }}
          />
        </div>
      </>
    )
  }
}


export default Table
