import React, {Component} from "react";
import {request} from "../../../api-client";
import './Livestream.css'
import styled from "styled-components";
import browserHistory from "../../../utils/browserHistory";
import {channelPathGenerator, slugify} from "../../../api-client/core/authentication/utils";
import Slider from "react-slick";
import PrevArrow from "../../../components/Letflow/Arrows/PrevArrow";
import NextArrow from "../../../components/Letflow/Arrows/NextArrow";
import HomeElementCarouselItem from "../../Home/HomeElements/HomeElementCarouselItem";
import localization from "../../../config/localization"
import {Link} from "react-router-dom";

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeDiff: 0,
      loaded: false,
      allCurrentLivestreams: [],
    };

  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.fetchAllLivestreams()
      .then(() => this.setState({loaded: true}))
  };

  fetchAllLivestreams = () => {
    return request.liveStream.checkIfActive(this.props.channel.id, 'include=video;startImage;endImage;contentCreator.translations;translations')
      .then(livestreams => this.setState({allCurrentLivestreams: livestreams}))
  }

  render = () => {
    const {loaded, allCurrentLivestreams} = this.state

    const sliderSettings = {
      infinite: false,
      lazyLoad: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      prevArrow: <PrevArrow liveCarousel overrideStyle={{transform: `translate(0, -${window.innerWidth < 1300 ? 155 : 130}%)`, color: "var(--main-font-color, #00000099)"}}/>,
      nextArrow: <NextArrow liveCarousel overrideStyle={{transform: `translate(0, -${window.innerWidth < 1300 ? 155 : 130}%)`, color: "var(--main-font-color, #00000099)"}}/>,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }
    if (loaded) {
      return (
        <>
        <h1 style={{textAlign: 'center', fontSize: 30, marginTop: 40, color: "var(--main-font-color, inherit)"}}>{localization.get('livestreams')}</h1>
        <div style={{margin: window.innerWidth > 600 ? "0 50px" : "0 25px"}}>
          {allCurrentLivestreams.length === 0 ?
            <div style={{textAlign: 'center'}}>
              <h3>{localization.get('livestreams.empty')}</h3>
              <Link style={{color: 'inherit'}} to={channelPathGenerator(``)}>{localization.get('go_to.home')}</Link>
            </div>
          :
            <Slider style={{padding: 50}} {...sliderSettings} >
              {allCurrentLivestreams.map(livestream =>
                <div style={{alignItems: "center"}}>
                  {livestream.startImage ?
                    <HomeElementCarouselItem
                      {...this.props}
                      shape='square'
                      data={{...livestream, image: livestream.startImage, shortDescription: livestream.description}}
                      handleElementClick={() => {
                        browserHistory.push(channelPathGenerator(`vivo/${livestream.id}-${slugify(livestream.name)}`), {livestream})
                      }}
                      livestream
                    />
                    :
                    <LiveContainer
                      onClick={() => {
                        browserHistory.push(channelPathGenerator(`vivo/${livestream.id}-${slugify(livestream.name)}`), {livestream})
                      }}
                    >
                      {livestream.name}
                    </LiveContainer>
                  }
                </div>
              )}
            </Slider>
          }
        </div>
        </>
      )
    } else {
      return <div></div>
    }

  }
}

const LiveContainer = styled.h3`
  background-color: black;
  color: white;
  text-align: center;
  margin: 30px 20px 0;
  min-height: 300px;
  padding-top: 25%;
@media (max-width: 500px) {
   width: 100vw!important;
}
`;
