import {post} from "../utils";


const form = (name, email, password) => {
  const f = new FormData()
  f.append('name', name)
  f.append('username', email)
  f.append('password', password)
  return f
}

export default ({url, name, email, password}) =>
  post(`${url}`, form(name, email, password))
  .then(result => result.json())