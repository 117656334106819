import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {Typography} from "@material-ui/core";
import {userCanDeleteLicensePlans, userCanEditLicensePlans} from "../../../api-client/core/authentication/utils";
import {isNullOrUndefined} from "util";
import {feeCurrencies, feePaymentTypes} from "../Form";
import localization from '../../../config/localization'

export const types = [
  {id: "no_licenses", name: localization.get('table.no_licenses')},
  {id: "only_other_purpose", name: localization.get('table.only_other_purpose')},
  {id: "only_own_channels", name: localization.get('table.only_own_channels')},
  {id: "both", name: localization.get('license_plans.both')},
];

class Table extends Component {
  state = { total: "", type:"both" };

  reloadTable = () => this.refs.table.forceUpdate();

  handleAlertClosed = targetId => status => {
    if (status === "passed") this.refs.table.removeById(targetId);
  };

  buttons = licensePlan => {
    const builder = new TableButtonsBuilder();
    userCanEditLicensePlans() && builder.withEdit(`/panel/licensePlans/${licensePlan.id}/edit`);
    userCanDeleteLicensePlans() &&
      builder.withDelete(
        licensePlan.name,
        () => request.licensePlan.delete(licensePlan.id),
        this.handleAlertClosed(licensePlan.id)
      );
    return builder.build();
  };

  columns = () => [
    {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          placeholder={localization.get('table.search')}
        />
      ),
    },
    {
      Header: localization.get('table.type'),
      id: "type",
      sortable: false,
      accessor: licensePlan => licensePlan.only_other_purpose ? licensePlan.only_own_channels ? localization.get('license_plans.both'): localization.get('table.only_other_purpose'): licensePlan.only_own_channels ? localization.get('table.only_own_channels') : localization.get('table.no_licenses'),
      Filter: ({ filter, onChange }) => (
        <select
          style={{ width: "100%" }}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
        >
          <option value="" selected />
          {types.map(type => <option value={type.id}>{type.name}</option>)}
        </select>
      ),
    },
    {
      Header: localization.get('table.actions'),
      id: "actions",
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 170,
      Footer: (
        <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>{localization.get('table.total')}: </strong>
          {this.state.total}
        </span>
      ),
    },
  ];

  render = () => {
    return (
      <div>
        <ServerSidePaginationTable
          ref="table"
          columns={this.columns()}
          subComponent={row => <LicensePlanDetails licensePlan={row.original} />}
          requestTableData={paginationConfig => {
            if (this.state.type !== "both") {
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'type', filter: this.state.type}])}
            }
            return request.licensePlan.getAll(paginationConfig).then(response => {
              this.setState({ total: response.meta.total });
              return response;
            });
          }}
        />
      </div>
    );
  };
}

export const LicensePlanDetails = ({ licensePlan }) => (
  <div>
    <br />
    {!isNullOrUndefined(licensePlan.fee_amount) && (
      <React.Fragment>
        <Typography variant="subheading">{localization.get('license_plans.monthly_fee')}</Typography>
        <br />
        <table style={{ marginLeft: -3 }}>
          <tbody>
            {makeRow(localization.get('license_plans.currency'), feeCurrencies.toString(licensePlan.fee_currency))}
            {makeRow(localization.get('license_plans.amount'), licensePlan.fee_amount)}
            {makeRow(localization.get('license_plans.way_to_pay'), feePaymentTypes.toString(licensePlan.fee_payment_type))}
          </tbody>
        </table>
        <br />
      </React.Fragment>
    )}
    <Typography variant="subheading">{localization.get('license_plans.discount')}</Typography>
    <br />
    <div style={{ display: "flex" }}>
      <table style={{ marginLeft: -3, marginRight: 50 }}>
        <tbody>
          {makePercentageRow(localization.get('license_plans.bichannel'), licensePlan.bichannel_discount)}
          {makePercentageRow(localization.get('license_plans.second_edition'), licensePlan.edition_discount)}
          {makePercentageRow(localization.get('license_plans.publicity_reductions'), licensePlan.reductions_discount)}
          {makePercentageRow(localization.get('license_plans.corporates_reductions'), licensePlan.own_channels_reductions_discount)}
          {makePercentageRow(localization.get('license_plans.validity_radio'), licensePlan.duration_radio_discount)}
          {makePercentageRow(localization.get('license_plans.validity_tv_all'), licensePlan.duration_tv_all_discount)}
          {makePercentageRow(localization.get('license_plans.validity_internet'), licensePlan.duration_internet_discount)}
        </tbody>
      </table>
      <table style={{ marginLeft: -3 }}>
        <tbody>
          {makePercentageRow(localization.get('license_plans.own_channels_discount'), licensePlan.own_channels_discount)}
          {makePercentageRow(localization.get('license_plans.publicity_internet_discount'), licensePlan.publicity_internet_discount)}
          {makePercentageRow(localization.get('license_plans.publicity_radio_discount'), licensePlan.publicity_radio_discount)}
          {makePercentageRow(localization.get('license_plans.publicity_tv_discount'), licensePlan.publicity_tv_discount)}
          {makePercentageRow(localization.get('license_plans.publicity_all_discount'), licensePlan.publicity_all_discount)}
        </tbody>
      </table>
    </div>
    <br />
  </div>
);

const makeRow = (key, value) => (
  <tr>
    <td style={{ paddingRight: 20 }}>
      <Typography>{key}</Typography>
    </td>
    <td>
      <Typography>
        <i>{value}</i>
      </Typography>
    </td>
  </tr>
);

const makePercentageRow = (key, value) => makeRow(key, value * 100 + "%");

export default Table;
