import {addPutMethodToForm, post} from "../utils";
import {makeFormData} from "./utils";

export default host => (id, {
  name,
  isTalent,
  clientId,
  cloudId,
  emails,
  hint,
  isProtected,
}) => {
  return post(`${host}/batchUploadLinks/${id}`,
    addPutMethodToForm(makeFormData({
      name,
      isTalent,
      clientId,
      cloudId,
      emails,
      hint,
      isProtected,
    })))
    .then(response => response.json())
    .then(response => response.data)
}
