import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import {TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {userIsReadOnly} from "../../../api-client/core/authentication/utils";
import ProjectsImage from "../../../assets/img/Letflow/projects.jpg";
import localization from "../../../config/localization";
import {Edit} from "@material-ui/icons";
import ParticipantsButton from "../../../views/Pitches/Thread/ParticipantsButton";
import {makeImageUrlWithSize, emptyImage} from "../HomeElements/utils";

class ProjectItem extends React.Component {

  buildButtons = (project) => {
    let builder = new TableButtonsBuilder();
    if (!userIsReadOnly()) {

        builder
          .withOther(
            <ParticipantsButton
              small={true}
              tooltip="top"
              //clientsIds={this.state.clients.map(client => client.id)}
              pitchId={project.id}
              //participants={this.state.participants}
              //clients={this.state.clients}
            />
          );

      builder
        .withOther(
          <TableButton
            title={localization.get('edit')}
            onClick={() => this.props.onEdit(project)}
          >
            <Edit/>
          </TableButton>
        );

      builder
        .withDelete(
          project.project_name,
          () => request.pitch.delete(project.id),
          closeState => {
            if (closeState === 'passed') {
              this.props.onDelete && this.props.onDelete(project.id)
            }
          }
        )
    }
    return builder.build()
  }

  render = () => {

    const { project, onClick } = this.props;

    return (
      <article onMouseEnter={() => this.setState({ over: true })} onMouseLeave={() => this.setState({ over: false })}>
        <div style={{ width: "100%" }} className="album-grid-img">
          <div style={{ width: "100%", border: this.props.unreadMessages && "1px solid red" }} className="category-item">
            <div>
              <div style={{
                position: "relative",
                float: "left",
                width: "100%",
                padding: "50.0% 0"
              }}>
                <div style={{
                  backgroundImage: `url(${project.featured_image ? makeImageUrlWithSize(project.featured_image, 'md'): ProjectsImage}), url(${emptyImage})`,
                  position: "absolute",
                  top: "0",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  cursor: 'pointer'
                }}
                    onClick={onClick}
                >
                  <div
                    style={{color: (project.status != 'closed') ? "#2929ffc4" : "grey", fontWeight:600, fontSize: 16, marginTop: 10, marginLeft: 10, textAlign: "left", visibility:  (project.status == 'open') && "hidden"}}>
                   { localization.get(`project.${project.status}`).toUpperCase() }
                  </div>
                  <div
                    className="featured-image-title"
                    style={{
                      color: project.featured_image ? project.featured_image.color : "rgb(128, 144, 180)"
                    }}
                  >
                    {project.project_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                  </div>
                </div>
              </div>
              <div style={{position: "absolute", top: 10, right: 10, borderRadius: 50, backgroundColor: "rgba(255, 255, 255, 0.7)"}}>{this.buildButtons(project)}</div>
            </div>
          </div>
        </div>
      </article>
    )
  }
}
export default ProjectItem
