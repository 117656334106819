import {del} from '../utils'
import {getActiveChannel, getContentCreationFavorites, setContentCreationFavorites} from "../authentication/utils";

export default host => id => del(`${host}/channels/${getActiveChannel()}/contentCreations/${id}/favorite`)
  .then(() => {
    const favs = getContentCreationFavorites();
    
    if (favs.indexOf(id) > -1) {
      favs.splice(favs.indexOf(id), 1)
      setContentCreationFavorites(favs)
    }
  })