import * as WLFH from "../../../components/Letflow/Form/WebLikeFormHelpers";
import localization from "../../../config/localization";
import React, {Component} from "react";
import {ErrorMessage, Field} from "formik";
import {Button, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import SocialLinks from "../../../components/Letflow/SocialLinks";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import Unsplash from "../../../components/Letflow/Unsplash";
import {NavigateNext, NavigateBefore} from "@material-ui/icons";


export class Step2 extends Component {

  onNextStep = () => {
    let setFieldTouched = this.props.formikProps.setFieldTouched

    this.props.formikProps.validateForm().then(errors => {
      if (errors.profileImageId || errors.coverImageId || errors.videoProductions || errors.audioProductions || errors.showcase) {
        setFieldTouched('profileImageId', true)
        setFieldTouched('coverImageId', true)
        setFieldTouched('videoProductions', true)
        setFieldTouched('audioProductions', true)
        setFieldTouched('showcase', true)
      } else {
        this.props.nextStep()
      }
    })
  }

  render = () => {
    const {values, setFieldValue, setFieldTouched} = this.props.formikProps
    return (
      <>
        <div style={{ width: "100%", maxWidth: 500, padding: '0px 20px 20px', margin: "auto" }}>
          <SocialLinks
            onSocialLinksChange={(socialLinks) => setFieldValue('socialLinks', socialLinks)}
            publicView={true}
            onBlur={() => setFieldTouched('socialLinks', true)}
          />
          <div style={{display: 'flex', alignItems: 'flex-end'}}>
            <WLFH.SectionTitle>{localization.get("form.select_image_profile")}</WLFH.SectionTitle>
            <ErrorMessage name="profileImageId" className="web-like-error">
              {msg => <div className="web-like-error image-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <FormControl>
            <RadioGroup
              name="profileImageRadio"
              value={values.profileImageRadio}
              onChange={(e) => {
                setFieldValue('profileImageRadio', e.target.value)
                if (e.target.value === 'unsplash') {
                  setFieldValue('profileImageId', '')
                }
                if (e.target.value === 'filepond') {
                  setFieldValue('unsplashProfileImage', '')
                }
              }}
              onBlur={() => setFieldTouched('profileImageRadio', true)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
              <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
            </RadioGroup>
          </FormControl>
          {values.profileImageRadio === 'filepond' ?
            <>
              <FilePondUploader
                id={"image"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={(image) => setFieldValue('profileImageId', image ? image.id : '')}
                disableForm={values.disableForm}
                updateDisableForm={value => setFieldValue('disableForm', value)}
              />
              <div style={{color: "#757575", textAlign: 'center'}}>{localization.get('helper_text.images_width_2048')}</div>
            </>
            :
            <Unsplash imageAmount={4} public={true} onSelectImage={(url, name) => setFieldValue('unsplashProfileImage', {url, name})}/>
          }

          <div style={{ display: 'flex', alignItems: 'flex-end'}}>
            <WLFH.SectionTitle>{localization.get("form.select_image_cover")}</WLFH.SectionTitle>
            <ErrorMessage name="coverImageId" className="web-like-error">
              {msg => <div className="web-like-error image-error">{msg}</div>}
            </ErrorMessage>
          </div>
          <FormControl>
            <RadioGroup
              name="coverImageRadio"
              value={values.coverImageRadio}
              onChange={(e) => {
                setFieldValue('coverImageRadio', e.target.value)
                if (e.target.value === 'unsplash') {
                  setFieldValue('coverImageId', '')
                }
                if (e.target.value === 'filepond') {
                  setFieldValue('unsplashCoverImage', '')
                }
              }}
              onBlur={() => setFieldTouched('coverImageRadio', true)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
              <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
            </RadioGroup>
          </FormControl>
          {values.coverImageRadio === 'filepond' ?
            <>
              <FilePondUploader
                id={"image"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={(image) => setFieldValue('coverImageId', image ? image.id : '')}
                disableForm={values.disableForm}
                updateDisableForm={value => setFieldValue('disableForm', value)}
              />
              <div style={{color: "#757575", textAlign: 'center'}}>{localization.get('helper_text.images_width_2048')}</div>
            </>
            :
            <Unsplash imageAmount={4} public={true} onSelectImage={(url, name) => setFieldValue('unsplashCoverImage', {url, name})}/>
          }


          {(this.props.contentCreatorType && this.props.contentCreatorType.allow_video) &&
          <>
            <WLFH.SectionTitle>{localization.get("producer.video_productions_picker_title", 4)}</WLFH.SectionTitle>
            <ErrorMessage name="videoProductions" className="web-like-error">
              {msg => <div className="web-like-error">{msg}</div>}
            </ErrorMessage>
            <FilePondUploader
              id={'video'}
              type={"video"}
              allowedTypes={["video/mp4","video/x-m4v","video/*"]}
              onIdChange={(videoProductions) => setFieldValue('videoProductions', videoProductions)}
              isMultiple={true}
              maxFiles={100}
              disableForm={values.disableForm}
              updateDisableForm={value => setFieldValue('disableForm', value)}
            />
          </>
          }

          {(this.props.contentCreatorType && this.props.contentCreatorType.allow_audio) &&
            <>
              <WLFH.SectionTitle>{localization.get("producer.audio_productions_picker_title", 4)}</WLFH.SectionTitle>
              <ErrorMessage name="audioProductions" className="web-like-error">
                {msg => <div className="web-like-error">{msg}</div>}
              </ErrorMessage>
              <FilePondUploader
                id={'audio'}
                type={"audio"}
                allowedTypes={["audio/*"]}
                onIdChange={(audioProductions) => setFieldValue('audioProductions', audioProductions)}
                isMultiple={true}
                maxFiles={100}
                disableForm={values.disableForm}
                updateDisableForm={value => setFieldValue('disableForm', value)}
              />
            </>
          }

          {(this.props.contentCreatorType && this.props.contentCreatorType.allow_image) &&
          <>
            <WLFH.SectionTitle>{localization.get("photographer.showcase_picker_title", 4)}</WLFH.SectionTitle>
            <ErrorMessage name="showcase" className="web-like-error">
              {msg => <div className="web-like-error">{msg}</div>}
            </ErrorMessage>
            <FilePondUploader
              id={'image'}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={(photos) => setFieldValue('showcase', photos)}
              isMultiple={true}
              maxFiles={100}
              disableForm={values.disableForm}
              updateDisableForm={value => setFieldValue('disableForm', value)}
            />
          </>
          }

          <div>
            <Button style={{marginTop: 10}} variant="outlined" onClick={this.props.previousStep}>
              <NavigateBefore style={{marginLeft: "-12px"}}/>
              {localization.get('form.back')}
            </Button>
            <Button
              style={{float: 'right', marginTop: 10, backgroundColor: !values.disableForm ? "rgba(26 , 26, 26, 1)" : "#aaaaaa", color: "white"}}
              variant="raised"
              onClick={this.onNextStep}
              disabled={values.disableForm}
            >
              {localization.get('license_wizard.next')}
              <NavigateNext style={{color: "white", marginRight: "-12px"}}/>
            </Button>
          </div>
        </div>
      </>
    )}
}

