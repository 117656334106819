import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import {Clear} from '@material-ui/icons'
import {withStyles} from '@material-ui/core';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const Failed = ({ title, message, onConfirm, classes }) => <SweetAlert
  danger
  style={{ display: "block" }}
  title={title}
  onConfirm={onConfirm}
  confirmBtnCssClass={classes.button + " " + classes.success}
  confirmBtnText={<Clear/>}>
  {message}
</SweetAlert>

export default withStyles(sweetAlertStyle)(Failed)