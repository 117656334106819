import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  id,
  name,
  email,
  phone,
  translations,
  type,
  imageId,
  profileId,
  selectedMusics,
  selectedUsers,
  socialLinks,
  countryId,
  unsplashCoverImage,
  unsplashProfileImage
}) => post(`${host}/artists/${id}`, addPutMethodToForm(makeFormData({
  name,
  email,
  phone,
  translations,
  type,
  imageId,
  profileId,
  selectedMusics,
  selectedUsers,
  socialLinks,
  countryId,
  unsplashCoverImage,
  unsplashProfileImage
})))
  .then(response => response.json())
