import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import UserProfile from "./UserProfile";

const MusicsRouting = (props) => {
    return (
        <Router {...props}>
            <Switch>
                <Route component={UserProfile} />
            </Switch>
        </Router>
    )
}

export default MusicsRouting