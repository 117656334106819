import React, {Component} from "react";
import {Editor, EditorState, RichUtils} from 'draft-js';
import './RichEditor.css'
import localization from "../../../config/localization";

export default class RichEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createEmpty()};
    this.onChange = (editorState) => this.props.onChange(editorState);
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
  }

  handleKeyCommand = command => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState)
      return true
    }
    return false
  }

  onTab = e => {
    const maxDepth = 4
    this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth))
  }
  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType))
  }
  toggleInlineStyle = inlineStyle => {
    this.onChange(RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle))
  }

  render() {
    const { editorState } = this.props
    let className = 'RichEditor-editor'
    return (
      <div style={{margin: '2rem 0'}}>
        <div className={!this.props.readOnly ? "RichEditor-root" : ''}>
          {!this.props.readOnly &&
          <>
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          </>
          }
          <div className={className} onClick={this.focusEditor}>
            <Editor
              readOnly={this.props.readOnly || false}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              handleKeyCommand={this.handleKeyCommand}
              onTab={this.onTab}
              ref={this.setEditor}
              editorState={editorState}
              onChange={this.onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: 16,
    padding: 2,
  },
  FONT12: {
    fontSize: 12,
  },
  FONT14: {
    fontSize: 14,
  },
  FONT16: {
    fontSize: 16,
  },
  FONT18: {
    fontSize: 18,
  },
  FONT20: {
    fontSize: 20,
  },
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton'
    if (this.props.active) {
      className += ' RichEditor-activeButton'
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    )
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: localization.get('rich_text_editor.list'), style: 'unordered-list-item' },
  { label: localization.get('rich_text_editor.list.ordered'), style: 'ordered-list-item' },
]

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  return (
    <div className={"RichEditor-controls"} >
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}

let INLINE_STYLES = [
  { label: localization.get('rich_text_editor.bold'), style: 'BOLD' },
  { label: localization.get('rich_text_editor.italic'), style: 'ITALIC' },
  { label: localization.get('rich_text_editor.underlined'), style: 'UNDERLINE' },
  { label: 'Mono', style: 'CODE' },
  // { label: '12', style: 'FONT12' },
  // { label: '14', style: 'FONT14' },
  // { label: '16', style: 'FONT16' },
  // { label: '18', style: 'FONT18' },
  // { label: '20', style: 'FONT20' },
];
const InlineStyleControls = props => {
  let currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  )
}
