import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client';
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import {userIsSupervisor, userisMultiClient} from "../../../api-client/core/authentication/utils";
import {FormControl} from "@material-ui/core";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core/index";

class UsersTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      availablePerClient: 0,
      total: '',
      catalogCategories: [],
      catalogContent: this.props.location.pathname.includes('music') ? 'music' : 'all',
      from: this.props.location.pathname.includes('music') ? 'music' : 'talents',
    }
  }

  componentDidMount = () => {
    request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({catalogContent: this.props.location.pathname.includes('music') ?  'music' : 'all' }, () => this.refs.table.forceUpdate())
    }
  }

  render = () => {
    return (
      <div>
        {(!this.state.catalogContent || this.state.catalogContent !== 'music') &&
          <FormControl style={{width: "100%", float: "left", position: "relative"}}>
            <RadioGroup
              name="catalogContent"
              value={this.state.catalogContent}
              onChange={e => this.setState({catalogContent: e.target.value}, () => this.refs.table.forceUpdate())}
              style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('all')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="talents" control={<Radio color="default" />} label={localization.get('catalog.talents')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="personalized" control={<Radio color="default" />} label={localization.get('catalog.personalized')} />
            </RadioGroup>
          </FormControl>
        }
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref='table'
            columns={this.columns()}
            subComponent={this.subComponent}
            requestTableData={paginationConfig => {
              if (!userisMultiClient() && this.state.showCatalogs === "createdByAdmin") {
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "created_by_admin", filter: true}])}
              }
              paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: 'filter_content', filter: this.state.catalogContent}])}

              return request.catalog.getAll(paginationConfig).then((response) => {
                this.setState({total: response.meta.total})
                return response
              })
            }}
          />
        </div>
      </div>
    )
  }

  handleAlertClosed = id => status => {
    if (status === "passed") this.refs.table.removeById(id);
  };

  columns = () => {
    let columns = [];

    const nameColumn =
      {
        Header: localization.get('table.name'),
        accessor: "name",
        Filter: ({ filter, onChange }) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            placeholder={localization.get('table.search')}
          />
        ),
      };

    const statusColumn =
      {
        Header: localization.get('table.status'),
        id: 'status',
        accessor: catalog => catalog.status === "pending" ? localization.get('catalog.inactive') : localization.get('catalog.active') ,
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: "100%" }}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            <option value={'pending'}>{localization.get('catalog.inactive')}</option>
            <option value={'approved'}>{localization.get('catalog.active')}</option>
          </select>
        ),
      };

    const createdByColumn =
      {
        Header: localization.get('catalog.owner'),
        id: "createdBy[name]",
        accessor: catalog => catalog.createdBy ? catalog.createdBy.name : 'Flowlike',
        Filter: ({ filter, onChange }) =>
          <TableAutocompleteFilter
            fetchItems={filter => {
              return request.client.getAll({
                  filterBy: [{column: 'name', filter}, {column: 'has_catalogs_created', filter: true}]
                })
                .then(clients => clients.map(client => client.name))
            }}
            onChange={onChange}
            filter={filter ? filter.value : ''}
          />
      };


    const supervisedColumn =
      {
        Header: localization.get('catalog.supervised'),
        id: 'supervised',
        accessor: catalog => localization.get(catalog.supervised ? 'catalog.supervised' : 'catalog.not_supervised'),
        Filter: ({filter, onChange}) =>
          <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected/>
            <option value={'0'}>{localization.get('catalog.not_supervised')}</option>
            <option value={'1'}>{localization.get('catalog.supervised')}</option>
          </select>,
        width: 150,
      };

    const weightColumn =
    {
      Header: localization.get("table.weight"),
      id: "weight",
      filterable: false,
      accessor: catalog => catalog.weight,
      sortable: true,
      style: { textAlign: 'center' },
      width: 120
    }

    const musicDealerColumn =
    {
      id: "musicDealer[name]",
      Header: localization.get("music_dealer"),
      accessor: "musicDealer.name",
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.musicDealer.getAll({
              filterBy: [{ column: 'name',filter }]
            })
            .then(musicDealers => musicDealers.map(musicDealer => musicDealer.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    }

    const actionColumn =
      {
        Header: localization.get('table.actions'),
        id: 'actions',
        sortable: false,
        filterable: false,
        width: 80,
        accessor: catalog => {
          const builder = new TableButtonsBuilder();
          if (catalog.id !== 1 && (!catalog.supervised || (catalog.supervised && userIsSupervisor())))  {
            let editUrl = '';
            if(this.props.location.pathname.includes('music')) {
              editUrl = makeUrlWithTableParams(`music_catalogs/${catalog.id}/edit`);
            } else if (this.props.location.pathname.includes('talents')) {
              editUrl = makeUrlWithTableParams(`talents_catalogs/${catalog.id}/edit`);
            } else {
              editUrl = makeUrlWithTableParams(`audiovisual_catalogs/${catalog.id}/edit`);
            }

            builder.withEdit(editUrl)
        
            builder.withDelete(catalog.name, () => request.catalog.delete(catalog.id),
              this.handleAlertClosed(catalog.id)
              , true)
          }
          return builder.build()
        },
        Footer: <span
          style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
      };

    columns.push(nameColumn);
    columns.push(statusColumn);
    if(this.props.location.pathname.includes('music')) {
      columns.push(musicDealerColumn);
      columns.push(supervisedColumn);
    }else {
      columns.push(createdByColumn);
    }
    columns.push(weightColumn)
    columns.push(actionColumn);

    return columns
  }

  subComponent = (row) => {
    const catalog = row.original

    return (
      <React.Fragment>
        {catalog.created_at &&
        <div style={{ width: "100%", float: "left", position: "relative", padding: "10px 20px" }}>
          <div style={{ float: "left", position: "relative", fontWeight: "bold", marginRight: "30px",}}>{localization.get('table.created_at')}:</div>
          {localization.moment(catalog.created_at).fromNow()}
        </div>
        }
        {catalog.clients.length > 0 &&
          <div style={{ width: "100%", float: "left", position: "relative", padding: "10px 20px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('clients')}:</div>
            {catalog.clients.map(client => <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "auto" }}>{client.name}</div>)}
          </div>
        }
        {catalog.countries.length > 0 &&
          <div style={{ width: "100%", float: "left", position: "relative", padding: "10px 20px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('excluded_countries')}:</div>
            {catalog.countries.map(country => <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "auto" }}>{country.name}</div>)}
          </div>
        }
      </React.Fragment>
    )
  }
}

export default UsersTable
