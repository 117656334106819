import React, {Component} from 'react'
import localization from '../../../../config/localization';
import { FilterNone } from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import DuplicatePlaylistDialog from '../../DuplicatePlaylistDialog';

class TableDuplicatePlaylistButton extends Component {

  state = {
    showDialog: false
  }

  handleButtonClick = () => this.setState({ showDialog: true })

  makeShareDialog = () =>
    <div style={{ display: 'inline-block' }}>
      <DuplicatePlaylistDialog
        show={this.state.showDialog}
        handleClose={this.handleDialogClose}
        playlistId={this.props.playlistId}
        clients={this.props.clients}
      />
    </div>

  handleDialogClose = () => this.setState({ showDialog: false })

  render = () => {
    return (
      <span>
        <TableButton title={localization.get('tooltip.duplicate')} onClick={this.handleButtonClick}>
          <FilterNone />
        </TableButton>
        {this.makeShareDialog()}
      </span>
    )
  }
}

TableDuplicatePlaylistButton.propTypes = {
  playlistId: PropTypes.number.isRequired,
}

export default TableDuplicatePlaylistButton