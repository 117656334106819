import {isNullOrUndefined} from "util";

export const makeJsonData = ({
 name,
 type,
 categoryId,
 colorId,
 cuit,
 address,
 countryId,
 showBanner,
 licensePlan,
 excludeFromReports,
 demo,
 permissions,
 managedBy,
 prefix,
 reelUrl,
 useLogo,
 catalogsIds,
 phone,
 email,
 reelsLogo,
 keywordsSEO,
 titleSEO,
 descriptionSEO,
 showAbout,
 contentCreatorTypes,
 canManageClients,
 searchPreferences,
 blacklistTypeContentCreatorTypes,
 allowFakeLivestreams,
 secure,
 hasOwnTags,
 channelId,
 transcodeVideos,
 useAccessLevel,
 accessLevel,
 useRekognition
}) => {
  const form = {};
  if (name) form.name = name
  if (type) form.type = type
  if (categoryId) form.client_category_id = categoryId
  if (colorId) form.color_id = colorId
  if (cuit) form.cuit = cuit
  if (!isNullOrUndefined(address)) form.address = address
  if (countryId) form.country_id = countryId
  if (!isNullOrUndefined(prefix)) form.domain = prefix;
  if (reelUrl) form.reel_url = reelUrl
  if (showBanner) form.show_banner = showBanner
  if (licensePlan) form.license_plan_id = licensePlan;
  if (!isNullOrUndefined(excludeFromReports)) form.exclude_from_reports = excludeFromReports ? 1 : 0;
  if (!isNullOrUndefined(demo)) form.demo = demo ? 1 : 0;
  if (!isNullOrUndefined(useLogo)) form.use_logo = useLogo ? 1 : 0;
  if (permissions) permissions.length > 0 ? form.permissions = permissions : form.permissions = []

  if (!isNullOrUndefined(phone)) form.phone = phone;
  if (!isNullOrUndefined(email)) form.email = email;
  if (catalogsIds) catalogsIds.length > 0 ? form.catalogs_ids = catalogsIds : form.catalogs_ids = []
  if (reelsLogo) form.reels_logo = reelsLogo;
  if (keywordsSEO) form.seo_keywords = keywordsSEO;
  if (titleSEO) form.seo_title = titleSEO;
  if (descriptionSEO) form.seo_description = descriptionSEO;
  if (!isNullOrUndefined(canManageClients)) form.can_manage_clients =  canManageClients ? 1 : 0;
  if (!isNullOrUndefined(showAbout)) form.show_about_in_home = showAbout ? 1 : 0;
  if (!isNullOrUndefined(transcodeVideos)) form.transcode_videos = transcodeVideos;
  if (!isNullOrUndefined(useAccessLevel)) form.has_access_level = useAccessLevel;
  if (!isNullOrUndefined(secure)) form.secure = 0;
  if (!isNullOrUndefined(hasOwnTags)) form.has_own_tags = hasOwnTags;
  if (searchPreferences) form.content_creator_search_preference = searchPreferences;
  if (contentCreatorTypes) (contentCreatorTypes.length > 0 && blacklistTypeContentCreatorTypes != 2) ? form.content_creator_types_ids = contentCreatorTypes.map(contentCreatorType => contentCreatorType.value) : form.content_creator_types_ids = []
  if (blacklistTypeContentCreatorTypes) blacklistTypeContentCreatorTypes == 2 ? form.blacklist_content_creator_types = 1 : form.blacklist_content_creator_types = blacklistTypeContentCreatorTypes
  if (!isNullOrUndefined(allowFakeLivestreams)) form.allow_fake_livestreams = allowFakeLivestreams ? 1 : 0;
  if (channelId || channelId===null) form.default_channel_id = channelId;
  form.default_access_level = accessLevel;
  if (!isNullOrUndefined(useRekognition)) form.use_rekognition = useRekognition ? 1 : 0;

  return JSON.stringify(form)
};

export const makeFormData =
  ({
     image,
     socialLinks,
     translations
 }) => {
  const form = new FormData()
  if (image) form.append('image', image, image.name)
  if (socialLinks) {
    socialLinks.forEach(socialLink => {
      if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
    });
  }
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.about)) form.append(`translations[${translation.language}][about]`, translation.about);
    });
  }
  return form
}


export const includeQuery = "include=category;country;image;blogLocations;contentCreatorTypesBlacklist;licensePlan;color;permissions;managedBy.image;socialLinks;translations;domain";
