import React from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {channelPathGenerator, getActiveChannel} from "../../../api-client/core/authentication/utils";
import queryString from "querystring";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import analyticsInstance from "../../../analytics/analyticsInstance";

class AuthorizationCode extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loginWithCode()
  }

  loginWithCode = () => {
    let query = queryString.parse(this.props.location.search.slice(1));

    if(!query["code"] || !query["state"]) {
      window.location.href = getActiveChannel() ? channelPathGenerator('login') : '/login';
    }else {
      request.authentication.LoginWithCode({ code: query["code"], state: query["state"]}, window.location.href.includes('/gcba') ? "gcba" : "miba")
        .then(() => {
          analyticsInstance.login({type: window.location.href.includes('/gcba') ? "GCBA" : "MIBA", from: "ok"})
          const returnUrl = sessionStorage.getItem("returnUrl")
          if (returnUrl) {
            sessionStorage.removeItem("returnUrl")
            window.location.href = returnUrl;
          }else {
            window.location.href = getActiveChannel() ? channelPathGenerator('') : '/';
          }
        })
        .catch(() => {
          analyticsInstance.login({type: window.location.href.includes('/gcba') ? "GCBA" : "MIBA", from: "failed"})
          const returnUrl = sessionStorage.getItem("returnUrl")
          if (returnUrl) {
            sessionStorage.removeItem("returnUrl")
          }

          GlobalSnackbar.show({
            message: localization.get("login fail"),
            type: GlobalSnackbarTypes.ERROR
          });
          window.location.href = getActiveChannel() ? channelPathGenerator('') : '/';
          this.setState({requesting: false});
        });
    }
  }

  render() {
    return('')
  }
}

export default AuthorizationCode;
