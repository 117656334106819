import {appendPaginationQueryParamsToUrl, del, get, post, put} from "../utils";
import {toDateString} from "../../../utils";

/**
 * @typedef {Object} CreateLicenseParamsVersions
 * @property {any} length_id
 * @property {any} reductions
 */

/**
 * @typedef {Object} CreateLicenseParamsProject
 * @property {any} title
 * @property {any} customer
 * @property {any} product
 * @property {any} brand
 * @property {any} allow_spotlight
 */

/**
 * @typedef {Object} CreateLicenseParams
 * @property {any} music_id
 * @property {any} license_type_id
 * @property {any} reductions
 * @property {any} unlimited_reductions
 * @property {any} medias_ids
 * @property {any} budget_id
 * @property {any} exclusivity
 * @property {any} extents_ids
 * @property {any} regions_ids
 * @property {any} extension
 * @property {any} bichannel
 * @property {any} edition
 * @property {any} modification
 * @property {any} category_id
 * @property {any} duration
 * @property {any} client_id
 * @property {CreateLicenseParamsVersions} versions
 * @property {CreateLicenseParamsProject} project
 */

/**
 * @param {CreateLicenseParams} data
 */
const makeQueryStringWithLicenseData = data => {
  let queryString = "";
  queryString += `?music_id=${data.music_id}`;
  queryString += `&license_type_id=${data.license_type_id}`;
  queryString += `&bichannel=${data.bichannel}`;
  queryString += `&budget_id=${data.budget_id}`;
  queryString += `&category_id=${data.category_id}`;
  queryString += `&client_id=${data.client_id}`;
  queryString += `&duration=${data.duration}`;
  queryString += `&edition=${data.edition}`;
  queryString += `&exclusivity=${data.exclusivity}`;
  queryString += `&extension=${data.extension}`;
  queryString += `&modification=${data.modification}`;
  queryString += `&project[allow_spotlight]=${data.project.allow_spotlight}`;
  queryString += `&project[customer]=${data.project.customer}`;
  queryString += `&project[product]=${data.project.product}`;
  queryString += `&project[title]=${data.project.title}`;
  queryString += `&project[brand]=${data.project.brand}`;
  queryString += `&reductions=${data.reductions}`;
  queryString += `&unlimited_reductions=${data.unlimited_reductions}`;
  queryString += "&" + data.extents_ids.map((x, i) => `extents_ids[${i}]=${x}`).join("&");
  queryString += "&" + data.medias_ids.map((x, i) => `medias_ids[${i}]=${x}`).join("&");
  queryString +=
    "&" +
    data.versions
      .map((x, i) => `versions[${i}][length_id]=${x.length_id}&versions[${i}][reductions]=${x.reductions}`)
      .join("&");
  return queryString;
};

const getAll = host => (pagination, addToQPs = "") =>
  get(
    appendPaginationQueryParamsToUrl(
      `${host}/licenses?include=brand;client;client.licensePlan;client.country;category;project.videos:url;project.audios:url;medias;type;music.catalog.musicDealer;music.artist;music.audio.waveform;music.albums.image;user;sheet:url;receipts:url;contract:url;extents.region;versions.length${addToQPs}`,
      { ...pagination, alreadyHasQueryParams: true },
    ),
  ).then(res => res.json());

const license = host => ({

  getAll: getAll(host),

  getAllOnGoing: pagination => getAll(host)(pagination, "&state=accepted"),

  getAllOnGoingWithNewMaterials: pagination => getAll(host)(pagination, "&state=accepted&project[has_new_material]=1"),

  getAllForClient: (clientId, pagination) =>
    get(
      appendPaginationQueryParamsToUrl(
        `${host}/clients/${clientId}/licenses?include=client.country;client.licensePlan;versions.length;regions;extents;medias;category;project;budgets;music.artist;music.audio.waveform;music.albums.image;user;sheet:url;receipts:url;contract:url;type;music.audioAiff;music.versions_count`,
        { ...pagination, alreadyHasQueryParams: true },
      ),
    )
    .then(res => res.json()),

  get: licenseId =>
    get(
      `${host}/licenses/${licenseId}?include=client.country;client;client.licensePlan;category;project.videos:url;project.audios:url;medias;type;regions;budgets;music.artist;music.audio.waveform;music.audioAiff;music.albums.image;user;sheet:url;receipts:url;contract:url;extents.region;versions.length`,
    )
      .then(res => res.json())
      .then(res => res.data),

  getAllPending: () =>
    get(
      `${host}/licenses?state=requested&include=client;music.audio.waveform;music.albums.image;user;sheet:url;receipts:url;contract:url;project.videos:url;project.audios:url;type;category;extents;versions.length&order_by=created_at&sort_type=desc`,
    )
      .then(res => res.json())
      .then(res => res.data),

  countPending: () =>
    get(`${host}/licenses/pending/count`)
      .then(response => response.json())
      .then(json => json.data),

  countPendingReceipt: clientId =>
    get(`${host}/clients/${clientId}/licenses/pendingReceipt/count`)
      .then(response => response.json())
      .then(json => json.data),

  getAllAcceptedSadaic: () =>
    get(
      `${host}/licenses?license_type_id=3&state=accepted;client;music.audio.waveform;music.albums.image;user;sheet:url;receipts:url;contract:url`,
    )
      .then(res => res.json())
      .then(res => res.data),

  getPrices: () =>
    get(`${host}/licenses/prices`)
      .then(res => res.json())
      .then(res => res.data),

  updatePrices: prices => put(`${host}/licenses/prices`, JSON.stringify(prices)),

  accept: licenseId => post(`${host}/licenses/${licenseId}/accept`),

  /**
   * @param {CreateLicenseParams} data
   */
  create: data =>
    post(`${host}/licenses`, JSON.stringify(data))
      .then(response => response.json())
      .then(response => response.data),


  update: (id, data) =>
    put(`${host}/licenses/${id}`, JSON.stringify(data))
      .then(response => response.json())
      .then(response => response.data),

  delete: (id) =>
    del(`${host}/licenses/${id}`),


  /**
   * @param {CreateLicenseParams} data
   */
  getLicensePreviewUrl: data => {
    let queryString = makeQueryStringWithLicenseData(data);
    return `${host}/licenses/preview${queryString}&stream=true`;
  },

  /**
   * @param {CreateLicenseParams} data
   */
  getPriceForLicense: data => {
    let queryString = makeQueryStringWithLicenseData(data);
    return get(`${host}/licenses/price${queryString}`)
      .then(response => response.json())
      .then(response => response.data);
  },

  uploadSheet: (licenseId, file) => {
    const form = new FormData();
    form.append("file", file, file.name);
    return post(`${host}/licenses/${licenseId}/sheet`, form);
  },

  getLicenseReceiptsZipUrl: licenseId => `${host}/licenses/${licenseId}/receipts`,

  uploadReceipt: (licenseId, file) => {
    const form = new FormData();
    form.append("image", file, file.name);
    return post(`${host}/licenses/${licenseId}/receipts`, form);
  },

  uploadProjectElement: (projectId, item, type) => {
    return post(
      `${host}/projects/${projectId}`,
      (() => {
        const form = new FormData();
        switch (type) {
          case "url":
            form.append("urls[0]", item);
            break;
          case "audio":
            form.append("audios[0]", item, item.name);
            break;
          case "video":
            form.append("videos[0]", item, item.name);
            break;
        }
        form.append("_method", "PUT");
        return form;
      })(),
    );
  },

  deleteProjectElement: (projectId, itemId, type) => {
    let url;

    switch (type) {
      case "url":
        url = `${host}/projects/${projectId}/urls/${itemId}`;
        break;
      case "video":
        url = `${host}/projects/${projectId}/videos/${itemId}`;
        break;
      case "audio":
        url = `${host}/projects/${projectId}/audios/${itemId}`;
        break;
    }

    return del(url);
  },

  projectMaterialsViewed: projectId => post(`${host}/projects/${projectId}/materialsViewed`),

  countProjectsWithNewMaterials: () =>
    get(`${host}/projects/countProjectsWithNewMaterials`)
      .then(res => res.json())
      .then(res => res.data),

  exportLicenses: (client) =>
    `${host}/licenses/export?${client ? `client_id=${client}` : '' }`,


  getDownloadLink: (params) =>
    get(`${host}/licenses/downloadLink${params ? `${params}` : '' }`)
      .then(response => response.text()),

  getLicensesReport: (pagination, clientId, from, to) => {
    let url = appendPaginationQueryParamsToUrl(
      `${host}/licenses/report?include=user:role_id,name,last_login_at.role:description;client:name`,
      { ...pagination, alreadyHasQueryParams: true }
    );
    if (clientId) {
        url += `&client_id=${clientId}`;
    }
    if (from) {
        url += `&starts_at[]=ge:${encodeURIComponent(toDateString(from))}`;
    }
    if (to) {
        url += `&starts_at[]=le:${encodeURIComponent(toDateString(to))}`;
    }
    return get(url)
      .then(response => response.json());
  },

  downloadZip: (id) => `${host}/licenses/${id}/downloadMusicVersionZip`,

  approve: (id, data) =>
    put(`${host}/licenses/${id}/approve`, JSON.stringify(data))
      .then(response => response.json())
      .then(response => response.data),

  decline: (id, data) =>
    put(`${host}/licenses/${id}/decline`, JSON.stringify(data))
});

export default license;
