import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {IconButton, Tooltip} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FilterNone} from "@material-ui/icons";

class TableSubcomponent extends Component {
  state = {
    link_targets: []
  };

  componentDidMount = () => {
    request.shareLink
      .get(this.props.link.id)
      .then(link =>
          this.setState({ link_targets: link.shortLinkTargets })
      );
  };

  buttons = short_link => {
    const { name } = short_link;

    const buttonBuilder = new TableButtonsBuilder();

    buttonBuilder.withOther(
        <TableButton
            title={localization.get('copy')}
        >
          <Tooltip placement="top" >
            <CopyToClipboard text={`${this.props.link.url}:${name}`}
                             style={{bottom: 5}} >
              <IconButton>
                <FilterNone />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        </TableButton>)

    return buttonBuilder.build();
  };
  render = () => {
    return (
      <div style={{ margin: "20px" }}>
        {this.state.link_targets.length > 0 && (
            <div>
              <ClientSidePaginationTable
                  data={this.state.link_targets.map((link_target, index) => ({ ...link_target, index }))}
                  columns={[
                    {
                      Header: localization.get("table.to"),
                      id: 'to', accessor: link_target => link_target.to
                    },
                    {
                      Header: localization.get("table.views"),
                      id: 'views',
                      accessor: 'views',
                    },
                    {
                      Header: localization.get("table.last_view"),
                      id: 'updated_at',
                      accessor: link_target => localization.moment(link_target.updated_at).fromNow(),
                    },
                    {
                      id: 'actions',
                      accessor: this.buttons,
                    },
                  ]}
                  filterable={false}
                  sortable={false}
                  showPagination={false}
                  zebraStyle={false}
              />
            </div>
        )}
      </div>
    );
  };
}

export default TableSubcomponent;
