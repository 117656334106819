import React from 'react'
import {Pause, PlayArrow, SkipNext, SkipPrevious} from '@material-ui/icons'
import SimpleButton from '../utils/SimpleButton';

const playButtonStyle = {
  color: 'white',
  backgroundColor: 'black',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'dimgrey',
}

const Controll = ({ onPreviousClick, onPlayClick, onNextClick, isPlaying, isDisabled }) => {

  const middleIcon = isPlaying ? <Pause /> : <PlayArrow />

  return (
    <div style={{ display: 'flex' }}>
      <SimpleButton onClick={onPreviousClick} isDisabled={isDisabled}><SkipPrevious /></SimpleButton>
      <SimpleButton onClick={onPlayClick} style={playButtonStyle} isDisabled={isDisabled}>{middleIcon}</SimpleButton>
      <SimpleButton onClick={onNextClick} isDisabled={isDisabled}><SkipNext /></SimpleButton>
    </div>
  )
}

export default Controll