import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import Brands from "./All";

const BrandRouting = props => {
  return (
    <Router {...props}>
      <Switch>
        <Route
          render={props => (
            <Brands {...props} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default BrandRouting;
