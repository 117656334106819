import {appendPaginationQueryParamsToUrl, del, get, post, put} from "../utils";

const messaging = host => ({

  usersForNewConversation: (type, name, clientsIds, usersIds) => {

    const nameQS = name ? `&name=${name}` : ''
    const clientsIdsQS = clientsIds && clientsIds.length > 0 ? clientsIds.map(clientId => `&clients_ids[]=${clientId}`).join('') : ''
    const usersIdsQS = usersIds ? usersIds.map(userId => `&user_id[]=${userId}`).join('') : ''

    return get(`${host}/messaging/users/${type}?${nameQS}${clientsIdsQS}${usersIdsQS}`)
      .then(res => res.json())
      .then(json => json.data)
  },

  clientsForNewConversation: (name, clientId) => {

    const nameQS = name ? `&name=${name}` : ''
    const clientIdQS = clientId ? `&client_id=${clientId}` : ''

    return get(`${host}/messaging/clients?${nameQS}${clientIdQS}`)
      .then(res => res.json())
      .then(json => json.data)
  },

  writter: () => get(`${host}/messaging/writter`).then(res => res.json()),

  updateWritter: ({ receive_emails, email }) =>
    put(`${host}/messaging/writter`, JSON.stringify({ receive_emails, email })).then(res => res.json()),

  conversations: pagination =>
    get(
      appendPaginationQueryParamsToUrl(`${host}/messaging/conversations`, {
        alreadyHasQueryParams: false,
        ...pagination
      })
    )
      .then(res => res.json())
      .then(res => ({
        meta: res,
        data: res.data
      }))
      .then(res => {
        delete res.meta.data;
        return res;
      }),

  conversation: id => get(`${host}/messaging/conversations/${id}`).then(res => res.json()),

  messages: (conversationId, pagination) =>
    get(
      appendPaginationQueryParamsToUrl(`${host}/messaging/conversations/${conversationId}/messages`, {
        alreadyHasQueryParams: false,
        ...pagination
      })
    )
      .then(res => res.json())
      .then(res => ({
        meta: res,
        data: res.data
      }))
      .then(res => {
        delete res.meta.data;
        return res;
      }),

  messagesLastPage: ({ conversationId, perPage }) =>
    get(`${host}/messaging/conversations/${conversationId}/messages/last_page?page=1&per_page=${perPage}`)
      .then(res => res.json())
      .then(({ last_page }) => parseInt(last_page, 10) - 1),

  createConversation: ({ users, subject, initialMessage }) =>
    post(
      `${host}/messaging/conversations/start`,
      JSON.stringify({ user_ids: users.map(user_id => "" + user_id), subject })
    )
      .then(res => res.json())
      .then(conversation =>
        messaging(host).sendMessage({
          conversation: conversation.id,
          content: initialMessage
        })
      ),

  sendMessage: ({ conversation, content }) =>
    post(`${host}/messaging/conversations/${conversation}/messages`, JSON.stringify({ content })),

  markMessagesAsSeen: ({ message_ids }) => post(`${host}/messaging/messages/seen`, JSON.stringify({ message_ids })),

  countConversationsWithUnseenMessages: () =>
    get(`${host}/messaging/conversations/new_messages/count`)
      .then(res => res.json())
      .then(res => res.count),

  deleteConversation: ({ conversationId }) =>
    del(`${host}/messaging/conversations/${conversationId}`).then(res => res.json())
});

export default messaging;
