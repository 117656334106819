import {del} from '../utils'
import {getActiveClient} from '../authentication/utils';

export default host => id => {
  let url = `${host}/clients/${getActiveClient()}/musics/${id}/favorite`
  let clientId = getActiveClient()
  if (clientId) {
    url += `?client_id=${clientId}`
  }
  return del(url)
}
