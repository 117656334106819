import {put,} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => (material) => {
  const object = {}

  if (material.tags) {
    object.tags = material.tags
  }

  object.title = material.title
  object.subtitle = material.subtitle
  object.visibility = material.visibility
  object.public = material.public ? 1 : 0

  return put(`${host}/clients/${getActiveClient()}/files/${material.id}`, JSON.stringify(object))
    .then(response => response.json())
}