import "layouts/Home/Home.css";
import React, {Component} from "react";
import {Dialog, IconButton} from "@material-ui/core";
import {Close, Star, StarBorder} from "@material-ui/icons";
import PropTypes from "prop-types";
import EventManager, {events} from "../../../utils/EventManager";
import {ScaleLoader as Loader} from "react-spinners";
import ErrorBoundary from "../../../components/Letflow/ErrorBoundary";
import TrackTable from "../../../components/Letflow/TrackTable";
import TrackResult from "../../../components/Letflow/TrackResult";
import SavedQueryImage from "../../../assets/img/Letflow/savedQuery.jpg";
import PlaylistImage from "../../../assets/img/Letflow/playlist.jpg";
import FavoritesImage from "../../../assets/img/Letflow/favorite.jpg";
import {request} from "../../../api-client";
import NoImage from "../../../assets/img/Letflow/no-image.jpg";
import RecentsImage from "../../../assets/img/Letflow/recents.jpg";
import {compareValues} from "../../../utils";
import HomeItemDescriptionTags from "./HomeItemDescriptionTags";
import {Link} from 'react-router-dom'
import analyticsInstance from "../../../analytics/analyticsInstance";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {
  channelCanAddToFavorites, getActiveChannel, getStoredUser, getStoredChannel,
  homePathGenerator, getMainTranslation, channelPathGenerator
} from "../../../api-client/core/authentication/utils";

class HomeItemDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: this.props.favorites || (this.state && this.state.speakersFavorites) || [],
      data: this.props.data,
      favorite: false,
      tags: [],
      selectedTags: [],
    };
    this.state.favorite = this.state.favorites.includes(this.state.data.id);
  }

  componentDidMount = () => {
    this.scrollToTarget.scrollIntoView({ behavior: "smooth", block: "start" });
    EventManager.getInstance().subscribe(events.NAVBAR_HOME_BUTTON_PRESSED, this.handleClose);
    EventManager.getInstance().subscribe(events.HOME_ELEMENT_ITEM_CLICKED, this.handleClose);
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.NAVBAR_HOME_BUTTON_PRESSED, this.handleClose);
    EventManager.getInstance().unsubscribe(events.HOME_ELEMENT_ITEM_CLICKED, this.handleClose);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.id !== this.props.data.id) this.handleAnalytics(nextProps);
    this.setState({ tags: nextProps.data.tracks ? this.getTagsFromTracks(nextProps.data.tracks) : [] });
  }

  isAlbum = (props = this.props) => props.dataType === "album";

  isPlaylist = (props = this.props) => props.data.kind === "playlist";

  handleClose = () => {
    this.props.onClose();
  };

  onSelectedTagsChanged = selectedTags =>
    this.setState({ selectedTags, tags: this.updateTags(this.state.tags, selectedTags) });

  updateTags = (tags, selectedTags) => {
    return tags.map(tag => ({
      ...tag,
      state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? "selected" : "available",
    }));
  };

  getTagsFromTracks = tracks => {
    let tags = [];
    if (tracks && tracks.length > 0) {
      tracks.forEach(track => {
        track.tags &&
          track.tags.forEach(tag => {
            if (!tags.find(t => t.id === tag.id)) {
              tags.push(tag);
            }
          });
      });
    }
    tags = tags.sort(compareValues("name", "asc"));
    return tags;
  };

  makeImage = (element) => {
    let image
    if (element.kind === 'playlist') {
      if (element.created_by_admin) {
        if (element.image) {
          image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
        } else if (element.featuredImage && element.featuredImage.image) {
          image = request.file.image.makeCroppedUrl(element.featuredImage.image.id, 400, 400)
        } else {
          image = PlaylistImage
        }
      } else {
        if (element.featuredImage && element.featuredImage.image) {
          image = request.file.image.makeCroppedUrl(element.featuredImage.image.id, 400, 400)
        } else {
          image = PlaylistImage
        }
      }
    } else if (element.kind === 'savedQuery') {
      if (element.featuredImage && element.featuredImage.image) {
        image = request.file.image.makeCroppedUrl(element.featuredImage.image.id, 400, 400)
      } else {
        image = SavedQueryImage
      }
    } else if (element.kind === 'favorites') {
      if (element.image) {
        image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
      } else {
        image = FavoritesImage
      }
    } else if (element.kind === 'recents') {
      if (element.image) {
        image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
      } else {
        image = RecentsImage
      }
    } else if (element.image) {
        image = request.file.image.makeCroppedUrl(element.image.id, 400, 400)
    } else {
      image = NoImage
    }
    return image
  }

  openContentCreatorModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL,
      {
        id: this.props.dataType === 'album' ? this.props.data.id : this.props.data.artist.id,
        dataType: this.props.dataType === 'album' ? "album" : "artist",
        dataName: this.props.dataType === 'album' ? this.props.data.title : this.props.data.artist.name,
        origin: this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "",
      });
  }

  render = () => {
    const { data, homeElement } = this.props;
    return (
      <ErrorBoundary>
        <div
          ref={r => (this.scrollToTarget = r)}
          className="home-item-description-container"
        >
          <div style={{ display: "flex", marginBottom: "25px" }}>
            <div
              style={{
                position: "relative",
                float: "left",
                width: "150px",
                maxWidth: "35%"
              }}
            >
              <img
                alt="album"
                src={this.makeImage(data)}
                style={{
                  float: "left",
                  position: "relative",
                  width: "100%",
                  height: 150
                }}
              />
              {((data.kind === "playlist" && (!data.created_by_admin || !data.image)) || data.kind === "savedQuery") &&
                <div
                  style={{
                  color: data.featuredImage ? data.featuredImage.color : this.props.color ? this.props.color :"black",
                  fontSize: 22,
                  position: "absolute",
                  width: "100%",
                  lineHeight: "25px"
                }}
                  className="featured-image-title"
                >{data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}</div>
              }
            </div>
            <div
              style={{
                marginLeft: "10px",
                float: "left",
                position: "relative",
                textAlign: "left",
                width: "calc(100% - 170px)",
              }}
            >
              {data.subtitle && window.innerWidth > 600 &&
                <div style={{ float: "left", position: "relative", width: "100%", left: "7px", marginBottom: 5, color: this.props.color? this.props.color : this.props.transparentBackground && '#2b2b2b'}}>{data.subtitle}</div>
              }
              <div style={{ float: "left", position: "relative", width: "100%", margin: "10px 0" }}>
                <div
                  style={{
                    float: "left",
                    position: "relative",
                    width: "auto",
                    maxWidth: "300px",
                    fontSize: "18px",
                    left: "7px",
                    margin: "0 20px 10px 0",
                  }}
                >
                  <Link
                    to={this.props.home ? '#' : channelPathGenerator(`${this.props.dataType}/${data.id}-${data.title}`)}
                    onClick={(e) => {
                      if (this.props.home) e.preventDefault()
                      if (data.kind !== 'savedQuery'){
                        if (e.ctrlKey) {
                          window.open(channelPathGenerator(`${this.props.dataType}/${data.id}-${data.title}`))
                        } else {
                          this.openContentCreatorModal()
                        }
                      }
                    }}
                  >
                    <b className="home-item-description-album-name" style={{color: this.props.color ? this.props.color : (this.props.transparentBackground && 'black')}}>{data ? data.title : "-"}</b>
                  </Link>
                  {data.artist &&
                    <div style={{float: "left", position: "relative", fontSize: "13px", color: this.props.color? this.props.color :this.props.transparentBackground ? '#2b2b2b' : "darkgray"}}>by
                      <Link
                        style={{fontSize: "13px", color: this.props.color? `${this.props.color}` : this.props.transparentBackground ? '#2b2b2b' : "darkgray", marginLeft: 10}}
                        to={'#'}
                        className="home-artist-link"
                        onClick={(e) => {
                          e.preventDefault()
                          if (e.ctrlKey) {
                            window.open(homePathGenerator(`artist/${data.artist.id}`))
                          } else {
                            this.openContentCreatorModal()
                          }
                        }}
                      >
                        {data.artist.name}
                      </Link>
                    </div>
                  }
                </div>
                {(!getActiveChannel()) || (getActiveChannel() && !!getStoredUser() && channelCanAddToFavorites())  &&
                  <React.Fragment>
                    {(this.props.dataType === "album" || this.props.data.kind === 'playlist' || this.props.data.client_type) && data.kind !== 'savedQuery' && (
                      <div
                        className="album-fav"
                        style={{ float: "left", position: "relatives", marginTop: -8 }}
                      >
                        { this.state.favorite ? (
                          <IconButton
                            style={{ height: 35, width: 35 }}
                            onClick={() => this.removeFromFavorites(data.id)}
                          >
                            <Star style={{color: getStoredChannel().preferences.title.color}} />
                          </IconButton>
                        ) : (
                          <IconButton
                            style={{ height: 35, width: 35}}
                            onClick={() => this.addToFavorites(data.id)}
                          >
                            <StarBorder style={{color: getStoredChannel().preferences.title.color}} />
                          </IconButton>
                        )}
                        {new TableButtonsBuilder()
                          .withShare(this.isPlaylist() ? 'playlist' : 'album',
                            data.id, getActiveChannel() ? getStoredChannel().preferences.title.color:"rgb(0, 0, 0)",
                          () => analyticsInstance.share({
                              name: getMainTranslation(data, "title"),
                              type: this.isPlaylist() ? 'Playlist' : 'Album',
                            }))
                          .build()}
                      </div>
                    )}
                  </React.Fragment>
                }
              </div>
              {/*<div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: data ? data.longDescription : '-' }} />*/}
              {this.state.tags && this.state.tags.length > 0 ? (
                <HomeItemDescriptionTags tags={this.state.tags} onSelectedTagsChanged={this.onSelectedTagsChanged} singleTagSelection={true}/>
              ) : null}
            </div>
            <div style={{ position: "absolute", right: "60px"}}>
              <IconButton onClick={this.handleClose}>
                <Close style={getActiveChannel() ? {color: getStoredChannel().preferences.title.color} :{}}/>
              </IconButton>
            </div>
          </div>
          {this.makeTable(data)}
        </div>
      </ErrorBoundary>
    );
  };

  addToFavorites = id => {
    !!getStoredUser() && this.setState({ favorite: true });
    const type = this.isAlbum() ? 'album' : 'playlist';

    EventManager.getInstance().dispatch(events.ADD_TO_FAVORITE_BUTTON_PRESS, {id, type});
  };

  removeFromFavorites = id => {
    !!getStoredUser() && this.setState({ favorite: false });
    const type = this.isAlbum() ? 'album' : 'playlist';

    EventManager.getInstance().dispatch(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, {id, type});
  };

  makeTable = data => {
    if (!data || !data.tracks) {
      return (
        <div style={{ height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader color={"darkgray"} />
        </div>
      );
    }
    return <TrackResult rows={this.makeTrackTable(data)} />;
  };

  makeTrackTable = data => {
    const { id, title, image, tracks } = data;
    const { selectedTags } = this.state;

    const tracksFiltered = tracks.filter(track =>
      selectedTags.every(selectedTag => track.tags.map(tag => tag.id).includes(selectedTag))
    );

    return (
      <TrackTable
        {...this.props}
        hideImage={(data.kind && ['playlist', 'savedQuery', 'favorites', 'recents'].includes(data.kind)) ? false : true}
        trackContainer={{ id, title, image }}
        tracks={selectedTags.length > 0 ? tracksFiltered : tracks}
        fromHome={this.props.home}
        transparentBackground={this.props.transparentBackground}
      />
    );
  };
}

HomeItemDescription.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      tracks: PropTypes.array,
    })
  ).isRequired,
  trackType: PropTypes.oneOf(["music", "voice"]).isRequired,
  dataType: PropTypes.oneOf(["album", "speaker"]).isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default HomeItemDescription;
