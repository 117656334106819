import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import { Delete,  Edit, FilterNone } from "@material-ui/icons";
import {request} from "../../../api-client";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton
} from "@material-ui/core";
import TextField from "../../../components/Letflow/Form/TextField";
import AsyncSelect from "react-select/lib/Async";
import {CopyToClipboard} from "react-copy-to-clipboard";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar.jsx";

class TagSystems extends Component {
  state = {
    perPage: 20,
    page: 0,
    openFormDialog: false,
    editingTagSystem: null,
    name: "",
    selectedTagGroups: [],
  };

  componentDidMount = () => {
    document.title = localization.get("tag_system") + " - Feater";
  };

  buttons = tagSystem => {
    const {id, name} = tagSystem;

    const buttonBuilder = new TableButtonsBuilder();

    if (id != 1) {
      buttonBuilder.withOther(
        <TableButton  title={localization.get('copy')}>
          <CopyToClipboard text={`?tag_system_id=${id}`} >
            <IconButton>
              <FilterNone />
            </IconButton>
          </CopyToClipboard>
        </TableButton>
      )

      buttonBuilder.withOther(
        <TableButton
          title={localization.get('edit')}
          onClick={() =>
            this.setState({
              openFormDialog: true,
              editingTagSystem: tagSystem,
              name: tagSystem.name,
              selectedTagGroups: tagSystem.tagGroups.map(tg => ({value: tg.id, label: tg.name})),
            })
          }
        >
          <Edit/>
        </TableButton>
      )

      buttonBuilder.withOther(
        <TableButton
          title={localization.get('delete')}
          onClick={() =>
            GlobalConfirmationDialog.showDelete({
              dialogTitle: `${localization.get('tooltip.delete')} ${name}`,
              content: localization.get('confirmation_dialog.delete_content_message'),
              doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
              elementName: localization.get("delete"),
              deleteRequest: () => request.tagSystem.delete(id)
                .then(this.refs.table.forceUpdate),
              doubleConfirmation: true,
              auxiliaryInformation: "",
            })
          }
        >
          <Delete />
        </TableButton>
      )
    }

    return buttonBuilder.build();
  }

  onCloseDialog= () => this.setState({openFormDialog: false, name: "", editingTagSystem: null,selectedTagGroups: []})

  makeFormDialog = () => 
  <Dialog 
      open={this.state.openFormDialog} 
      onBackdropClick={this.onCloseDialog} 
      onEscapeKeyDown={this.onCloseDialog}
    >
      <DialogTitle>{localization.get(this.state.editingTagSystem ? "table.edit_tag_system" : 'table.create_tag_system')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", minWidth: 400, minHeight: 300}}>
          <TextField
            id="name"
            name="name"
            onChange={e => this.setState({name: e.target.value})} 
            value={this.state.name}
            label={localization.get("name")}
          />
          <br/>
          <div style={{ width: "100%"}}>
            <span style={{textAlign: "left"}}>{localization.get('tag_groups')}</span>
            <AsyncSelect
              style={{width: "100%"}}
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              fullWidth
              placeholder={localization.get('tag_groups')}
              loadingMessage={() => localization.get('loading')}
              value={this.state.selectedTagGroups}
              noOptionsMessage={() => localization.get('no_options')}
              defaultOptions
              onChange={(e) => this.setState({selectedTagGroups: e})}
              isClearable={true}
              isMulti
              loadOptions={inputValue => {
                let filters = [
                  {column: "name", filter: inputValue},
                  {column: "content_type", filter: 'music'}
                ]

                return request.tagGroup.getAllPrivate({filterBy: filters})
                  .then(tagGroups => tagGroups.map(tagGroup => ({
                    value: tagGroup.id,
                    label: tagGroup.name
                  })))
                  .then(tagGroups => tagGroups.sort((a, b) => a.label.localeCompare(b.label)))
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCloseDialog}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.handleSubmit()}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>


  handleSubmit = () => {
    if (this.state.selectedTagGroups.length !== 4) {
      GlobalSnackbar.show({
        message: localization.get('tag_systems.tag_groups_error'),
        type: GlobalSnackbarTypes.ERROR
      })
      return;
    }

    if(this.state.editingTagSystem) {
      return request.tagSystem.update(this.state.editingTagSystem.id, {name: this.state.name, tagGroupIds: this.state.selectedTagGroups.map(tagGroup => tagGroup.value)})
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
    } 

    return request.tagSystem.create({name: this.state.name, tagGroupIds: this.state.selectedTagGroups.map(tagGroup => tagGroup.value)})
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
  }

  columns = () => [
    {
      Header: localization.get("table.name"),
      accessor: tagSystem =>  <Tooltip title={tagSystem.name}><p>{tagSystem.name}</p></Tooltip>,
      id: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            if(event.keyCode == 13) {
              onChange(this.state.title)
            }else {
              this.setState({title: event.target.value || ""})
            }
          }}
          value={this.state.title ? this.state.title : ""}
          placeholder={localization.get("table.search")}
        />
      ),
    },
    {
      Header: localization.get("tag_groups"),
      accessor: tagSystem => <span>{tagSystem.tagGroups.map(tg=> tg.name).toString()}</span>,
      id: "tagGroups",
      filterable:false,
      sortable:false,
    },
    
    
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  render = () => (
    <AllViewContainer ref="viewContainer" onAdd={() => this.setState({openFormDialog: true})}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
                {this.makeFormDialog()}
                <div style={{ width: "100%", float: "left", position: "relative" }}>
                <ServerSidePaginationTable
                    ref="table"
                    columns={this.columns()}
                    requestTableData={async paginationConfig => {
                    paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'dashboard', filter: "true" }])};

                    this.setState({ paginationConfig })

                    return request.tagSystem.getAll(paginationConfig, "")
                        .then(response => {
                          this.setState({total: response.meta.total});
                          return response;
                      });
                    }}
                />
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default TagSystems;
