import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import wrapPageWithAnalytics from "../../analytics/src/wrapPage";
import analyticsInstance from "../../analytics/analyticsInstance";
import browserHistory from "utils/browserHistory";

const renderRoutes = routes => {
  if (routes == null) {
    return null;
  }

  const excluded = routes.excluded || [];
  const allowed = routes.allowed || [];
  const def = (routes.default && [routes.default]) || [];
  const flattened = excluded.concat(allowed).concat(def);

  return (
    <Router history={browserHistory}>
      <Switch>
        {flattened.map((route, i) => (
          <Route
            key={i}
            exact={route.exact}
            path={route.path}
            component={wrapPageWithAnalytics(route.component)}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default renderRoutes;
