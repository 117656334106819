import React, { Component } from "react";
import localization from '../../../config/localization';
import { request } from "api-client";
import Form from './Form';

export default class CreateAd extends Component {

  requestCreation = (state) => {
    const { name, link, duration, type, fileId, client, mobileImageId} = state;

    return request.ads.create({
        name,
        link,
        duration,
        type,
        fileId,
        client,
        mobileImageId
      })
      .then(() => {
        this.props.history.goBack();
      })
  }

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('ad.create_ad')}
        submitRequest={this.requestCreation}
        formType="create"
        altSubmit={true}
      />
    );
  }
}

