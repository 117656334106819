import localization from "../../../config/localization";
import React, {Component} from "react";
import Success from "../../../assets/img/success-check.gif";
import {checkIfTwins} from "../../../api-client/core/authentication/utils";


export class SuccessMessage extends Component {

  render = () => {
    return (
      <div style={{ width: "100%", maxWidth: 500, padding: '1px 20px 20px', margin: "auto", textAlign: "center", marginTop: 20, minHeight: "510px"}}>
        <h3 style={{fontWeight: "bold"}}>{localization.get("demo.uploaded_dialog_title_for_talents")}</h3>
        <p style={{marginTop: 20}}>{localization.get('demo.uploaded_dialog_content', (checkIfTwins() ? "Twins Music" : "Feater"))}</p>
        <img style={{width: 500, float: "right", margin: "0 -20px 6px 0"}} src={Success} alt="Loading logo"/>
      </div>
    )}
}

