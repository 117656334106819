import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import './favorites.css'
import TrackResult from "../../../components/Letflow/TrackResult";
import TrackTable from "../../../components/Letflow/TrackTable";
import {makeRequestElementProp} from "../Home";
import FavoritesCarousel from "./FavoritesCarousel";
import {setPageTitle} from "../../../api-client/core/authentication/utils";
import {pushIfDoesNotExist} from "../../../utils";

class Favorites extends React.Component {

  state = {
    albums: [],
    speakers: [],
    playlists: [],
    artists: [],
    musics: [],
    contentCreators: [],
    contentCreations: [],
    message: "loading",
  };

  componentDidMount = () => {
    setPageTitle(localization.get('title.favorites'))
    document.getElementsByTagName('html')[0].scrollTop = 0
    request.user.favorites()
      .then((user) => {
        if (
          !(
            user.musicsFavorites.length === 0 &&
            user.favorites.length === 0 &&
            user.playlistsFavorites.length === 0 &&
            user.artistsFavorites.length === 0 &&
            user.contentCreatorsFavorites.length === 0 &&
            user.contentCreationsFavorites.length === 0
          )
        ) {
          this.setState({
            albums: user.favorites,
            playlists: user.playlistsFavorites,
            artists: user.artistsFavorites,
            musics: user.musicsFavorites,
            contentCreators: user.contentCreatorsFavorites,
            contentCreations: user.contentCreationsFavorites
          })
        } else {
          this.setState({message: "favorites.no_result"})
        }
      })
  }


  render() {
    const {albums, speakers, playlists, artists, musics, contentCreators, contentCreations} = this.state;
    const contentCreatorTypes = [];
    contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type));
    const contentCreationTypes = [];
    contentCreations.forEach(contentCreations => pushIfDoesNotExist(contentCreationTypes, contentCreations.contentCreator.type));

    return (
      <div className="favorites-container">

        {musics.length > 0 &&
          <div style={{marginBottom: "20px", float: "left", width: "100%"}}>
            <h3>{localization.get('musics')}</h3>
            <TrackResult
              rows={<TrackTable {...this.props} type={'music'} hideSuggestions={true} tracks={musics} />}
            />
          </div>
        }

        {albums.length > 0 &&
          <React.Fragment>
            <h3>{localization.get('albums')}</h3>
            <FavoritesCarousel {...this.props} elements={albums} type={'album'}/>
          </React.Fragment>
        }

        {playlists.length > 0 &&
          <React.Fragment>
            <h3>{localization.get('playlists')}</h3>
            <FavoritesCarousel {...this.props} elements={playlists} type={'playlist'}/>
          </React.Fragment>
        }

        {artists.length > 0 &&
          <React.Fragment>
            <h3>{localization.get('artists')}</h3>
            <FavoritesCarousel {...this.props} elements={artists} type={'artist'}/>
          </React.Fragment>
        }

        {speakers.length > 0 &&
          <React.Fragment>
            <h3>{localization.get('favorite.speakers')}</h3>
            <FavoritesCarousel {...this.props} elements={speakers} type={'speaker'}/>
          </React.Fragment>
        }

        {contentCreatorTypes.map(contentCreatorType =>
          <React.Fragment>
            <h3>{contentCreatorType.name}</h3>
            <FavoritesCarousel {...this.props} elements={contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id)} type={'content_creator'}/>
          </React.Fragment>
        )}

        {contentCreationTypes.map(contentCreatorType =>
          <React.Fragment>
            <h3>{contentCreatorType.name}</h3>
            <FavoritesCarousel {...this.props} elements={contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id)} type={'video_content_creation'}/>
          </React.Fragment>
        )}

        {(musics.length === 0 && albums.length === 0 && playlists.length === 0 && artists.length === 0 && speakers.length === 0 && contentCreators.length === 0 && contentCreations.length === 0) &&
          <div
            style={{
              float: "left",
              position: "relative",
              width: "100%",
              textAlign: "center",
              marginTop: "30px",
              fontSize: "20px",
              padding: "40px"
            }}>{localization.get(this.state.message)}</div>
        }

      </div>
    )
  }
}

export default Favorites;
