import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import {Clear, Done} from '@material-ui/icons'
import {withStyles} from '@material-ui/core';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import {SmallSpinner} from '../Loading';

const Loading = () => <div>
  <br />
  <SmallSpinner />
</div>

const handleMessage = (showLoading, message) => <div>
  {message}
  {showLoading ? <Loading /> : null}
</div>

const Opened = ({ title, message, showButtons, onConfirm, onCancel, classes }) => <SweetAlert
  warning
  style={{ display: "block" }}
  title={title}
  onConfirm={onConfirm}
  onCancel={onCancel}
  confirmBtnCssClass={classes.button + " " + classes.success}
  cancelBtnCssClass={classes.button + " " + classes.danger}
  confirmBtnText={<Done style={{height:"30px", width: "30px"}} />}
  cancelBtnText={<Clear style={{height:"30px", width: "30px"}} />}
  showCancel={showButtons}
  showConfirm={showButtons}>
  {handleMessage(!showButtons, message)}
</SweetAlert>

export default withStyles(sweetAlertStyle)(Opened)