import {get} from '../utils'
import {includeQuery} from './utils';
import {getActiveChannel} from "../authentication/utils";
import localization from '../../../config/localization';

export default host => (id, isPublic = false, removeCache = false) => {
  let url = `${host}${isPublic ? '/public' : '' }/channels/${id}?${includeQuery}${getActiveChannel() ? `&cLang=${localization.getLanguage()}` : ""}`
  
  if (removeCache) {
    url += "&new_cache=" + Date.now();
  }

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
}