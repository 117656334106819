import {post} from "../utils";
import {getActiveChannel} from "../authentication/utils";

export default host => (contentId) =>
  post(`${host}/channels/${getActiveChannel()}/playlists/${contentId}/share`,
    JSON.stringify({
      viewType: localStorage.getItem('playlistViewType') ? localStorage.getItem('playlistViewType') : "grid"
    }))
    .then(response => response.json())
    .then(json => json.data);
