import {appendPaginationQueryParamsToUrl, del, get, post, put} from "../utils";

const mapData = data => {

  if (!data.hasFee) {

    delete data.fee_currency;
    delete data.fee_payment_type;

    data.fee_amount = null;
  }

  data.own_channels_discount /= 100;
  data.publicity_internet_discount /= 100;
  data.publicity_radio_discount /= 100;
  data.publicity_tv_discount /= 100;
  data.publicity_all_discount /= 100;
  data.bichannel_discount /= 100;
  data.duration_radio_discount /= 100;
  data.duration_tv_all_discount /= 100;
  data.duration_internet_discount /= 100;
  data.edition_discount /= 100;
  data.reductions_discount /= 100;
  data.own_channels_reductions_discount /= 100;

  switch (data.license_type) {
    case "no_licenses":
      data.only_own_channels = 0
      data.only_other_purpose =  0
      break
    case "other_purpose":
      data.only_own_channels = 0
      data.only_other_purpose =  1
      break
    case "own_channels":
      data.only_own_channels = 1
      data.only_other_purpose =  0
      break
    case "both":
      data.only_own_channels = 1
      data.only_other_purpose =  1
      break
  }

  return JSON.stringify(data);
};

export default host => ({

  getAll: options =>
    get(appendPaginationQueryParamsToUrl(`${host}/licensePlans`, { ...options, alreadyHasQueryParams: false })).then(
      res => res.json()
    ),

  get: id =>
    get(`${host}/licensePlans/${id}`)
      .then(res => res.json())
      .then(res => res.data),

  create: data =>
    post(`${host}/licensePlans`, mapData(data), { "Content-Type": "application/json" })
      .then(res => res.json())
      .then(res => res.data),

  update: (id, data) =>
    put(`${host}/licensePlans/${id}`, mapData(data), { "Content-Type": "application/json" })
      .then(res => res.json())
      .then(res => res.data),
      
  delete: (id) => del(`${host}/licensePlans/${id}`)
});
