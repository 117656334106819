import React, {Component} from "react";
import PropTypes from 'prop-types'
import {Subject} from "rxjs";
import _ from 'lodash'
import SoundBarView from "./components/SoundBarView";
import Playlist from "./components/Playlist";
import Favorite from "./components/Favorite";
import Information from "./components/Information";
import Controll from "./components/Controll";
import Progress from "./components/Progress";
import Options from "./components/Options";
import Download from "./components/Download";
import License from "./components/License";
import SoundPlayer from "./utils/SoundPlayer";
import {
  clientCanLicense, getActiveChannel, getActiveClient, getChannelCanLicense,
  getUseTools, getStoredUser, userIsSysAdmin
} from "../api-client/core/authentication/utils";
import AddToPlaylist from "../components/Letflow/AddToPlaylist";
import { PlaylistAdd } from "@material-ui/icons";
import { MenuItem } from "@material-ui/core";

/**
 * IMPORTANT:
 * The SoundBar updates its own state every 32 milliseconds.
 */
class SoundBar extends Component {

  // Events

  closeButtonPressed$ = new Subject()

  nextButtonPressed$ = new Subject()

  previousButtonPressed$ = new Subject()

  trackEnded$ = new Subject()

  trackPaused$ = new Subject()

  trackStartedPlaying$ = new Subject()

  trackCurrentPlayingSeconds$ = new Subject()

  // ---

  isFetchingTrackAudionUrl = false

  constructor(props) {
    super(props)

    this.state = {
      tracks: [],
      show: false,
      playingTrackIndex: 0,
      playingTrack: null,
      isFetchingTrackAudionUrl: false,
    }

    this.soundPlayer = this.makeSoundPlayerAndSubscribeToEvents()

  }

  makeSoundPlayerAndSubscribeToEvents = () => {
    const soundPlayer = new SoundPlayer()
    soundPlayer.trackEnded$.subscribe(this.handleSongEnd)
    soundPlayer.trackPaused$.subscribe(this.handleSongPaused)
    soundPlayer.trackStartedPlaying$.subscribe(this.handleSongStartedPlaying)
    
    return soundPlayer
  }

  handleSongEnd = () => {
    this.trackEnded$.next(this.makeEventData())
    if (this.state.tracks.length > 1) {
      this.playNextSong()
    }
  }

  handleSongChangedCurrentSeconds = () => {
    this.trackCurrentTimeInSeconds$.next(this.makeEventData())
  }

  makeEventData = () => ({
    title: this.state.playingTrack.title,
    track: this.state.playingTrack,
    seconds: this.soundPlayer.getCurrentTrackPlaytimeInSeconds(),
    total: this.soundPlayer.getCurrentTrackDurationInSeconds(),
  })

  handleSongPaused = () => this.trackPaused$.next(this.makeEventData())

  handleSongStartedPlaying = () => this.trackStartedPlaying$.next(this.makeEventData())

  componentDidMount = () => {
    this.refreshInterval = setInterval(() => this.forceUpdate(), 200)
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshInterval)
  }

  /**
   * Returns a Promise because initially, calling setTracks and playTrack together would cause playTrack to fail
   * because setTracks did not set the state immediatelly. The promise will resolve when the state is set correctly.
   */

  setTracks = tracks => new Promise(resolve => {
    this.setState({ tracks }, resolve(tracks))
  })

  
  playTrack = index => {
    const track = this.state.tracks[index]

    if (!track) {
      throw new Error('Track index is invalid')
    }

    this.handlePlayTrack({...track, index})
  }

  handlePlayTrack = (track, time = 0) => {
    this.setState({
      playingTrack: track,
      playingTrackIndex: track.index,
      show: true
    })
    
    this.fetchTrackAudioUrl(track)
      .then(audio => this.soundPlayer.play(audio, time))
  }

  fetchTrackAudioUrl = track => {
    this.isFetchingTrackAudionUrl = true
    return track.fetchAudioUrl()
      .finally(() => this.isFetchingTrackAudionUrl = false)
  }

  currentTrackHasId = id => {
    if (this.state.playingTrack) {
      return this.state.playingTrack.id === id
    }
    return false
  }

  currentTrackIsTitled = title => {
    if (this.state.playingTrack) {
      return this.state.playingTrack.title === title
    }
    return false
  }

  currentTrackHasProperties = properties => {
    if (this.state.playingTrack) {
      const entries = _.entries(properties)
      const playingTrackHasPropertyWithValue = ([key, value]) => this.state.playingTrack[key] === value
      return _.every(entries, playingTrackHasPropertyWithValue)
    }
    return false
  }

  setIsFavoriteFromTrackWithTitle = (title, value) => {
    const trackWithProvidedId = this.state.tracks.find(track => track.title === title)
    if (trackWithProvidedId) {
      trackWithProvidedId.isFavorite = value
      this.forceUpdate()
    }
  }

  pause = () => {
    this.soundPlayer.pause()
  }

  currentPlaylingTime = () => this.soundPlayer.getCurrentTrackPlaytimeInSeconds()

  totalSeconds = () => this.soundPlayer.getCurrentTrackDurationInSeconds()

  isPlaying = () => {
    return this.soundPlayer.isPlaying()
  }

  render = () => {

    const { show } = this.state

    return <SoundBarView show={show} onClose={this.handleCloseButtonClick} soundPlayer={this.soundPlayer}>{this.makeSoundBarContent()}</SoundBarView>
  }

  handleCloseButtonClick = () => {
    this.setState({ show: false })
    this.closeButtonPressed$.next(this.makeEventData())
    this.soundPlayer.pause()
  }

  makeSoundBarContent = () => {

    const { playingTrack } = this.state

    if (playingTrack === null) {
      return null
    }

    return this.makeSoundBarContentAccordingToDeviceSize()
  }

  makeSoundBarContentAccordingToDeviceSize = () => {

    let index = 0
    let getIndex = () => index++

    /**
     * An index is provided to each component so React does not 
     * cry over collection components without a key
     */

    return window.innerWidth > 1070
      ? [ // content for large devices
        <div style={{display: 'flex', maxWidth: "25%"}}>
          {!!getStoredUser() && this.makeFavoriteButton(getIndex())}
          {this.makeTrackInformation(getIndex())}
        </div>,
        <div style={{display: 'flex'}}>
          {this.makeControlls(getIndex())}
          {this.makeProgress(getIndex())}
        </div>,
        <div style={{display: 'flex', justifyContent: "flex-end"}}>
          {this.makeDowloadButton(getIndex())}
          {!!getStoredUser() && !!getActiveClient() && (!getActiveChannel() || getUseTools()) && this.makePlaylistButton(getIndex())}
          {getChannelCanLicense() && getActiveClient() && (clientCanLicense() || userIsSysAdmin()) && this.makeLicenseButton(getIndex())}
        </div>,
      ]
      : [ // content for small devices
        <div style={{display: 'flex'}}>
          {!!getStoredUser() && !!getActiveClient() && (!getActiveChannel() || getUseTools()) && this.makeFavoriteButton(getIndex())}
          {this.makeTrackInformation(getIndex())}
          {this.makeControlls(getIndex())}
          {!getStoredUser() && this.makeOptionsButton(getIndex())}
        </div>,
        this.makeProgress(getIndex()),
      ]
  }

  makePlaylistButton = index => {

    const { texts } = this.props
    const { addToPlaylist } = texts
    const { onAddToPlaylist, playlistButtonData } = this.state.playingTrack

    return onAddToPlaylist && (
      playlistButtonData 
      ? 
      <AddToPlaylist
        id={index}
        extraClass="soundbar-button"
        extraStyle={{
          color: 'var(--secondary-font-color, dimgrey)',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
        }}
        content={playlistButtonData.type ? playlistButtonData.type + 's' : undefined}
        item={playlistButtonData.selectedTrack}
        clientId={playlistButtonData.clientId}
        tooltipTitle={addToPlaylist}
        icon={<PlaylistAdd/>}
      />
      :
      <Playlist
        key={index}
        onClick={onAddToPlaylist}
        tooltip={addToPlaylist}
      />
    )
  }

  makeFavoriteButton = index => {

    const { texts } = this.props
    const { addToFavorites, removeFromFavorites } = texts
    const { playingTrack, playingTrackIndex } = this.state
    const { onToggleFavorite, isFavorite } = playingTrack

    return onToggleFavorite && <Favorite
      addToFavoritesTooltip={addToFavorites}
      removeFromFavoritesTooltip={removeFromFavorites}
      key={index}
      isFavorite={isFavorite}
      onClick={this.handleFavoriteButtonClick(isFavorite, playingTrackIndex, onToggleFavorite)}
    />
  }

  handleFavoriteButtonClick = (isFavorite, playingTrackIndex, onToggleFavorite) => () => {

    const nextFavoriteState = isFavorite ? false : true
    const tracks = this.state.tracks
    tracks[playingTrackIndex].isFavorite = nextFavoriteState
    this.forceUpdate()
    onToggleFavorite(nextFavoriteState)
  }

  makeTrackInformation = index => {
    const { image, title, subtitle, onImageClick, onTitleClick, onSubtitleClick, onAnyClick } = this.state.playingTrack
    return <Information
      key={index}
      imageSrc={image}
      title={title}
      subtitle={subtitle}
      onImageClick={onImageClick}
      onTitleClick={onTitleClick}
      onSubtitleClick={onSubtitleClick}
      onAnyClick={onAnyClick}
    />
  }

  makeControlls = index => {
    return <Controll key={index}
      onPlayClick={this.handleControllPlayClick}
      isPlaying={this.soundPlayer.isPlaying()}
      onNextClick={this.handleControllNextClick}
      onPreviousClick={this.handleControllPreviousClick}
      isDisabled={this.isPreparingSoundbar()}
    />
  }

  isPreparingSoundbar = () => this.soundPlayer.isUnavailable() || this.isFetchingTrackAudionUrl

  handleControllPlayClick = () => {
    if (this.soundPlayer.isPlaying()) {
      this.soundPlayer.pause()
    } else {
      this.soundPlayer.resume()
    }
  }

  handleControllNextClick = () => {
    this.nextButtonPressed$.next(this.makeEventData())
    this.playNextSong()
  }

  handleControllPreviousClick = () => {
    this.previousButtonPressed$.next(this.makeEventData())
    this.playPreviusSong()
  }

  playNextSong = () => {

    const { playingTrackIndex, tracks } = this.state
    const nextIndex = playingTrackIndex === tracks.length - 1 ? 0 : playingTrackIndex + 1

    this.setState({ playingTrackIndex: nextIndex })
    this.playTrack(nextIndex)
  }

  playPreviusSong = () => {

    const { playingTrackIndex, tracks } = this.state
    const nextIndex = playingTrackIndex === 0 ? tracks.length : playingTrackIndex - 1

    this.setState({ playingTrackIndex: nextIndex })
    this.playTrack(nextIndex)
  }

  makeProgress = index => {
    const { waveform, id } = this.state.playingTrack
    const playingTrackCurrentSeconds = this.soundPlayer.getCurrentTrackPlaytimeInSeconds()
    const playingTrackTotalSeconds = this.soundPlayer.getCurrentTrackDurationInSeconds()

    return <Progress
      trackId={id}
      key={index}
      imageSrc={waveform}
      currentSeconds={playingTrackCurrentSeconds}
      totalSeconds={playingTrackTotalSeconds}
      onManualChange={this.handleProgressManualChange(playingTrackTotalSeconds)}
      isLoading={this.isPreparingSoundbar()}
      
    />
  }

  handleProgressManualChange = (playingTrackTotalSeconds, otherTrackClicked = null) => sliderValue => {
    const second = playingTrackTotalSeconds * sliderValue

    if (!otherTrackClicked) { 
      this.soundPlayer.playCurrentTrackAtSecond(second) 
    }else {
      this.handlePlayTrack(otherTrackClicked, second)
    }
  }

  makeDowloadButton = index => {

    const { texts } = this.props
    const { download } = texts
    const { onDownload } = this.state.playingTrack

    return onDownload && <Download key={index} onClick={onDownload} tooltip={download} />
  }

  makeLicenseButton = index => {

    const { texts } = this.props
    const { license } = texts
    const { onLicense } = this.state.playingTrack

    return onLicense && <License key={index} onClick={onLicense} label={license} />
  }

  makeOptionsButton = index => {

    const { texts } = this.props

    const {
      license,
      download,
      addToPlaylist,
    } = texts

    const {
      onLicense,
      onDownload,
      onAddToPlaylist,
      playlistButtonData
    } = this.state.playingTrack

    const options = []

    const pushOptionWhenActionIsDefined = (action, optionLabel) => action && options.push({ label: optionLabel, onClick: action })

    pushOptionWhenActionIsDefined(onLicense, license)
    pushOptionWhenActionIsDefined(onDownload, download)
    if (playlistButtonData) {
      options.push({builder: (key) => <AddToPlaylist
        key={key}
        content={playlistButtonData.type ? playlistButtonData.type + 's' : undefined}
        item={playlistButtonData.selectedTrack}
        clientId={playlistButtonData.clientId}
        icon={<MenuItem>{addToPlaylist}</MenuItem>}
      />})
    } else {
      pushOptionWhenActionIsDefined(onAddToPlaylist, addToPlaylist)
    }

    return options.length > 0 ? <Options key={index} options={options} /> : null
  }
}

export const propTypes = {
  texts: PropTypes.shape({
    license: PropTypes.string.isRequired,
    download: PropTypes.string.isRequired,
    addToPlaylist: PropTypes.string.isRequired,
    addToFavorites: PropTypes.string.isRequired,
    removeFromFavorites: PropTypes.string.isRequired,
  }).isRequired
}

SoundBar.propTypes = propTypes

export default SoundBar