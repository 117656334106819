import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  channelId,
  livestreamId,
  translations,
  streamId,
  startTime,
  endTime,
  videoId,
  startImageId,
  endImageId,
  fake,
  source,
  contentCreatorId,
  timer,
  showCountdown,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  suggestedView,
  allowedTokens,
  allowedRegions,
}) =>
  post(`${host}/channels/${channelId}/livestreams/${livestreamId}`, addPutMethodToForm(makeFormData({
    translations,
    streamId,
    startTime,
    endTime,
    videoId,
    startImageId,
    endImageId,
    fake,
    source,
    contentCreatorId,
    timer,
    showCountdown,
    hasSuggestedCarousel,
    suggestedCarouselPlaylist,
    suggestedView,
    allowedTokens,
    allowedRegions,
  })))
    .then(response => response.json())