import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  rightManagementId,
  state,
  }) =>
  post(`${host}/rightsProcedures/${rightManagementId}`, addPutMethodToForm(makeFormData({
    state
  })))
  .then(response => response)