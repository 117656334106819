import React from "react";
import {FormControl, MenuItem} from "@material-ui/core";
import {SelectValidator} from 'react-material-ui-form-validator'

export default ({ id, name, title, current, items, onChange, disabled = false, style, validators, errorMessages, }) =>
  <FormControl fullWidth>
    <SelectValidator
      label={title}
      style={style}
      name={name}
      value={current}
      onChange={e => {
        e.target.id = id
        onChange(e)
      }}
      disabled={disabled}
      validators={validators}
      errorMessages={errorMessages}
    >
      {items.map(type => <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>)}
    </SelectValidator>
  </FormControl>
