import login from './login'
import activate from './activate'
import logout from './logout'
import refreshAccessToken from './refreshAccessToken'
import trialLogin from './trialLogin'
import getUser from './getUser'
import forgot from './forgot'
import reset from './reset'
import register from './register'
import activateChannelUser from './activateChannelUser'
import loginMiBA from './loginMiBA'
import profileMiba from './profileMiba'
import LoginWithCode from './loginWithCode'
import emailVerification from './emailVerification'
import talentUserRegister from './talentUserRegister'

export default host => ({
    login: login(host),
    forgot: forgot(host),
    register: register(host),
    reset,
    activate,
    logout: logout(host),
    refreshAccessToken: refreshAccessToken(host),
    trialLogin: trialLogin(host),
    getUser: getUser(host),
    profileMiba: profileMiba(host),
    loginMiBA: loginMiBA(host),
    LoginWithCode: LoginWithCode(host),
    emailVerification: emailVerification(host),
    activateChannelUser,
    talentUserRegister,
})