import React, {Component} from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select,
  TextField, Tooltip
} from "@material-ui/core";
import localization from "../../../config/localization";
import {request} from "../../../api-client";
import {setStatePromise} from "../../../utils";
import HomeItemDescriptionTags from "../../../layouts/Home/HomeElements/HomeItemDescriptionTags";
import {Add, Close, Edit} from "@material-ui/icons";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import AsyncSelect from "react-select/lib/Async";
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import FilePondUploader from "../FilePondUploader";
import {ImagePicker} from "../Form";
import {getActiveClient} from "../../../api-client/core/authentication/utils";

export default class ClientFileUploader extends Component {

  state = {
    open: false,
    sending: false,
    tags: [],
    searchTags: [],
    selectedTags: [],
    showProgress: false,
    progress: 0,
    editing: false,
    id: null,
    public: false,
    isReel: false,
    materialSelection: '',
    uploadRadio: 'pc',
    contentCreator: {},
    selectedContentCreation: '',
    visibility: '',
    editingTag: false,
    tagLevel: null,
    file: null,
    type: 'video',
    thumbnailImage: { src: null, file: null },
  };

  componentDidMount() {
    this.setState({open: true, isReel: this.props.isReel});

    if (this.props.file) {
      this.setState({
        id: this.props.file.id,
        title: this.props.file.title,
        subtitle: this.props.file.subtitle ? this.props.file.subtitle : '',
        visibility: this.props.visibility
      })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.creatingFile) {
      this.setState({
        selectedTags: [],
        showProgress: false,
        progress: 0,
        search: '',
        title: '',
        subtitle: '',
        materialSelection: '',
        public: false,
        isReel: nextProps.isReel,
        visibility: nextProps.isReel ? 'reel' : 'drive'
      });
      this.requestTags()
    } else if (this.state.id !== nextProps.file.id && typeof nextProps.file.title !== 'undefined') {
      this.requestTags().then(() =>
        this.setState({
          searchTags: this.updateTag(nextProps.file.tags),
          tags: this.updateTag(nextProps.file.tags),
          editing: true,
          title: nextProps.file.title,
          subtitle: nextProps.file.subtitle ? nextProps.file.subtitle : '',
          selectedTags: nextProps.file.tags.map(tag => tag.id),
          public: nextProps.file.public,
          isReel: nextProps.isReel,
          visibility: nextProps.file.visibility
        })
      )
    }


  }

  handleTagSearch = () =>
    this.setState({
      searchTags: this.state.tags.filter(tag => {
        return tag.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
      })
    });

  requestTags = () =>
    !this.state.isReel ?
      request.clientTags.getAll()
        .then(tags => this.setState({tags}, () => this.addSelectedToTagList()))
      :
      request.clientTags.getAll({
        filterBy: [{column: "is_reel", filter: true}]
      }).then(tags => this.setState({tags}, () => this.addSelectedToTagList()));


  addSelectedToTagList = () =>
    this.setState({
      searchTags:
        this.state.tags.map(tag => ({
          ...tag,
          state: this.state.selectedTags.find(selectedTag => selectedTag == tag.id)
            ? "selected" : "available"
        }))
    });


  onSelectedTagChanged = selectedTag => {
    let index = this.state.selectedTags.indexOf(selectedTag)
    if (index === -1) {
      this.setState({
        selectedTags: this.state.selectedTags.concat(selectedTag)
      })
    } else {
      this.state.selectedTags.splice(index, 1)
      this.setState({
        selectedTags: this.state.selectedTags
      })
    }
  };

  updateTag = (selectedTags) => {
    return this.state.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.id == tag.id
        )
          ? "selected"
          : "available"
      })
    );
  };

  handleCloseDialog = () => {
    if (!this.state.sending) {
      this.setState({
        id: null,
        selectedTags: [],
        showProgress: false,
        editing: false,
        progress: 0,
        sending: false,
        search: '',
        public: false,
        title: '',
        subtitle: '',
        materialSelection: '',
        open: false,
        searchTags: this.state.tags,
        tagLevel: 0,
        editingTag: false,
        fileId: null,
        selectedBatchFile: '',
        selectedBatchLink: '',
      });

      this.props.requestFiles({});
      this.props.handleClose()
    }
  };

  openEditTag = tag => this.setState({editingTag: tag.id ,openTagDialog: true, newTag: tag.name, tagLevel: tag.level, allowDeleteTag: true})
  handleTagDelete = ({tagId, tagName}) => GlobalConfirmationDialog.showDelete({
    elementName: tagName,
    deleteRequest: () =>  request.clientTags.delete(tagId).then(() => this.handleCloseTagDialogWhenDeleteTag(tagId))
  })

  handleCloseTagDialog = () => this.setState({newTag: '', openTagDialog: false, editingTag: false, tagLevel: null},
    () => this.requestTags())

  handleCloseTagDialogWhenDeleteTag = deletedTag => this.setState({newTag: '', openTagDialog: false, editingTag: false, tagLevel: null},
     () => this.requestTags().then(() => this.props.onSelectedTagDeleted(deletedTag)))

  onTagSave = () => {
    if (this.state.editingTag) {
      return request.clientTags.update({
        clientTagId: this.state.editingTag,
        name:this.state.newTag,
        level:this.state.tagLevel
      }).then(() => this.handleCloseTagDialog());
    } else {
      return request.clientTags.create({name: this.state.newTag, isReel: this.state.isReel, level: this.state.tagLevel})
        .then(() => this.setState({newTag: '', openTagDialog: false, tagLevel: null},
          () => this.handleCloseTagDialog()));
    }
  }

  onSubmit = () =>
    ((this.state.uploadRadio === 'pc' && !this.state.fileId && this.props.creatingFile)
    || (this.state.uploadRadio === 'platform' && !this.state.selectedContentCreation)
    || (this.state.uploadRadio === 'batch' && !this.state.selectedBatchFile)
    || this.state.title === '') ?
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('client_file.upload_error')})
      :
      setStatePromise(this, {sending: true, progress: 0})
        .then(() =>
          this.props.creatingFile ?
            request.clientFile.create({
                files: this.state.materialSelection && this.state.materialSelection,
                contentCreationId: this.state.selectedContentCreation && this.state.selectedContentCreation.value,
                title: this.state.title,
                subtitle: this.state.subtitle,
                tags: this.state.selectedTags,
                public: this.state.public,
                visibility: this.state.visibility,
                fileId: this.state.fileId,
                type: this.state.type,
                batchFileId: this.state.selectedBatchFile && this.state.selectedBatchFile.value,
              },
              (cur, tot) =>
                this.setState({progress: Math.floor((cur / tot) * 100)}))
              .then(file => {
                if (this.state.thumbnailImage.file && file.data) {
                  return request.clientFile.updateThumbnail({
                    fileId: file.data.id,
                    image: this.state.thumbnailImage.file
                  })
                }
              })
            :
            request.clientFile.update({
              id: this.props.file.id,
              title: this.state.title,
              subtitle: this.state.subtitle,
              tags: this.state.selectedTags,
              public: this.state.public,
              visibility: this.state.visibility
            })
        )
        .then(() => {
          GlobalSnackbar.show({
            type: GlobalSnackbarTypes.SUCCESS,
            message: localization.get('client_file.upload_success')
          })
          this.setState({sending: false}, () => this.handleCloseDialog())
        });

  addTagDialog = () => {
    const options = []

    for (let  i = 1; i < 11; i++) {
      options.push(<MenuItem value={i}>{i}</MenuItem>)
    }

    return(
    <Dialog open={this.state.openTagDialog} onBackdropClick={() => this.setState({openTagDialog: false, newTag: '', tagLevel: null, editingTag: false})}
            style={{padding: 10, margin: "-30px"}}>
      <DialogTitle>
        {this.state.editingTag ?  localization.get("tag.update_tag") : localization.get("tag.create_tag")}
      </DialogTitle>
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={() => this.setState({openTagDialog: false, newTag: '', tagLevel: null, editingTag: false})}>
        <Close />
      </IconButton>
      <DialogContent>
        <ValidatorForm onSubmit={this.onTagSave}>
          <TextValidator
            style={window.innerWidth > 500 ? {width: "80%", margin: "15px 50px 0"} : {width: "100%", marginTop: 15}}
            label={localization.get('form.name')}
            value={this.state.newTag}
            onChange={e => {
              this.setState({newTag: e.target.value})
            }}
            validators={['required']}
            name="name"
            errorMessages={[localization.get('validator.is_required')]}
          />
          <FormControl style={window.innerWidth > 500 ? {width: "80%", margin: "15px 50px 0"} : {width: "100%", marginTop: 15}}>
            <InputLabel>{localization.get('level')}</InputLabel>
            <Select
              value={this.state.tagLevel}
              onChange={e => this.setState({tagLevel: e.target.value})}
            >
              <MenuItem value={localization.get("no_level")}>{localization.get("no_level")}</MenuItem>
              {options}
            </Select>
          </FormControl>
        </ValidatorForm>
      </DialogContent>
        <DialogActions style={{marginBottom: 10}}>
          {this.state.editingTag &&
            <Button onClick={() => this.handleTagDelete({tagId: this.state.editingTag, tagName: this.state.newTag})} disabled={this.state.sending}>
              {localization.get("delete")}
            </Button>
          }
          <Button onClick={this.onTagSave} disabled={this.state.sending}>
            {localization.get("send")}
          </Button>
        </DialogActions>
    </Dialog>
    )}

  showTagDialog = () => this.setState({openTagDialog: true});

  render = () => {
    const {type} = this.state;

    return (
      <Dialog
        style={{margin: "-30px"}}
        open={this.props.show}
        onBackdropClick={this.handleCloseDialog}
        onEscapeKeyDown={this.handleCloseDialog}
      >

        {this.addTagDialog()}
        <DialogTitle>
          {localization.get("file.upload")}
        </DialogTitle>
        <DialogContent>
          <ValidatorForm
            onSubmit={this.onSubmit}>
            <React.Fragment>
              <TextValidator
                style={{marginBottom: 20}}
                value={this.state.title || ''}
                label={localization.get("file.title")}
                onChange={e => this.setState({title: e.target.value})}
                fullWidth
                id="filename"
                name="filename"
                validators={["required"]}
                errorMessages={[localization.get('validator.is_required')]}
              />
              <TextField
                style={{marginBottom: 20}}
                value={this.state.subtitle || ''}
                label={localization.get("file.subtitle")}
                onChange={e => this.setState({subtitle: e.target.value})}
                fullWidth
              />
              {!this.state.isReel &&
              <FormControlLabel
                control={
                  <Checkbox
                    id="public"
                    style={{color: "black"}}
                    checked={this.state.public}
                    onChange={() => this.setState({public: !this.state.public})}
                  />
                }
                style={{position: "relative", float: "left",}}
                label={localization.get('form.public')}
              />
              }
              <FormControlLabel
                control={
                  <Checkbox
                    id="visibility"
                    style={{color: "black"}}
                    checked={this.state.visibility === 'both'}
                    onChange={() => {
                      if (this.state.visibility === 'both') {
                        if (this.state.isReel) {
                          this.setState({visibility: 'reel'})
                        } else {
                          this.setState({visibility: 'drive'})
                        }
                      } else {
                        this.setState({visibility: 'both'})
                      }
                    }
                    }
                  />
                }
                style={{position: "relative", float: "left",}}
                label={localization.get(this.state.isReel ? 'form.show_on_drive' : 'form.show_on_reels')}
              />
              {this.props.creatingFile &&
              <>

                <FormControl>
                  <RadioGroup
                    name="uploadRadio"
                    value={this.state.uploadRadio}
                    onChange={event => this.setState({
                      uploadRadio: event.target.value,
                      materialSelection: '',
                      contentCreator: {},
                      selectedContentCreation: '',
                      selectedBatchFile: '',
                      selectedBatchLink: '',
                    })}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="pc" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
                    <FormControlLabel value="platform" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_platform')}/>
                    <FormControlLabel value="batch" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>

                  </RadioGroup>
                </FormControl>

                {!this.state.isReel && this.state.uploadRadio !== 'platform' &&
                  <>
                    <h3 className="mt-5" style={{textAlign: "left", fontSize: 18, marginTop: 20}}>{localization.get('file.upload')}</h3>
                    <Select
                      style={{width: "100%", marginBottom: 15}}
                      onChange={event => this.setState({type: event.target.value, selectedBatchLink: '', selectedBatchFile: ''})}
                      value={this.state.type}
                    >
                      <MenuItem value="video">{localization.get("video")}</MenuItem>
                      <MenuItem value="image">{localization.get("image")}</MenuItem>
                      <MenuItem value="audio">{localization.get("audio")}</MenuItem>
                      <MenuItem value="other">{localization.get("other")}</MenuItem>
                    </Select>
                </>
                }

                {this.state.uploadRadio === 'pc' ?
                  <>
                    <div style={{position: "relative", float: "left", width: "100%"}}>

                      <FilePondUploader
                        disableForm={this.state.sending}
                        updateDisableForm={value => this.setState({sending: value})}
                        file={this.state.file}
                        id={"materialSelection"}
                        type={this.state.type}
                        clientId={getActiveClient()}
                        allowedTypes={this.state.type === 'video' ? ["video/mp4","video/x-m4v","video/*"] : (this.state.type === 'image' ? ["image/*"] : (this.state.type === 'audio' ? ["audio/*"] : ["application/pdf","application/doc","application/docx","application/zip","application/rar","application/xls","application/xlsx","application/xlsm","application/xltx","application/csv","text/txt","application/ppt","application/pptx","application/postscript","application/ai"]))}
                        onIdChange={file => file ? this.setState({fileId: file.id, file: {src: null}}) : this.setState({fileId: null, file: {src: null}})}
                      />
                    </div>
                  </>
                  : this.state.uploadRadio === 'platform' ?
                  <div>
                    <div style={{marginBottom: "10px", color: "#757575"}}>{this.state.isReel ? localization.get('helper_text.upload_from_drive_to_reel') : localization.get('helper_text.upload_from_reel_to_drive')}</div>
                    <span>{localization.get('content_creators')}</span>
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(e) => this.setState({contentCreator: '', selectedContentCreation: ''}, () => this.setState({contentCreator: e}))}
                      value={this.state.contentCreator}
                      defaultOptions
                      loadOptions={inputValue => {
                        const fileTypeFilter = this.state.isReel ? "only_video" : "for_drive";
                        let filters = [{column: "name", filter: inputValue}, {column: fileTypeFilter, filter: true}, {column: "client_id", filter: getActiveClient()}]

                        return request.contentCreator
                          .getAll({filterBy: filters})
                          .then(contentCreators => contentCreators.map(contentCreator => ({
                            value: contentCreator.id,
                            label: contentCreator.name
                          })))
                          .then(contentCreators => contentCreators.sort((a, b) => a.label.localeCompare(b.label)))
                      }
                      }
                    />
                    {this.state.contentCreator &&
                      <div style={{marginTop: '5px'}}>
                        <span>{localization.get('content_creations')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          value={this.state.selectedContentCreation}
                          noOptionsMessage={() => localization.get('no_options')}
                          defaultOptions
                          onChange={(e) => this.setState({selectedContentCreation: e})}
                          isClearable={true}
                          loadOptions={inputValue => {
                            let filters = [
                              {column: "title", filter: inputValue},
                              {column: "client_id", filter: getActiveClient()}
                            ]

                            if (this.state.isReel) {
                              filters.push({column: "has_video", filter: true})
                            }

                            this.state.contentCreator && filters.push({
                              column: "content_creator_id",
                              filter: this.state.contentCreator.value
                            })

                            return request.contentCreation
                              .getAll({filterBy: filters})
                              .then(contentCreations => contentCreations.map(contentCreation => ({
                                value: contentCreation.id,
                                label: contentCreation.title
                              })))
                              .then(contentCreations => contentCreations.sort((a, b) => a.label.localeCompare(b.label)))
                          }
                          }
                        />
                      </div>
                    }
                  </div>
                :
                    <>
                      <FormControl fullWidth>
                        <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          noOptionsMessage={() => localization.get('no_options')}
                          onChange={(selectedBatchLink) => {
                            this.setState({selectedBatchLink: '', selectedBatchFile: ''}, () => this.setState({selectedBatchLink}))
                          }}
                          value={this.state.selectedBatchLink}
                          loadOptions={inputValue => {
                            let filterBy = [{column: "name", filter: inputValue}, {column: "client_id", filter: getActiveClient()}];

                            return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                              .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                          }}
                          defaultOptions
                        />
                      </FormControl>

                      {this.state.selectedBatchLink &&
                      <FormControl style={{marginTop: 20}} fullWidth>
                        <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          noOptionsMessage={() => localization.get('no_options')}
                          onChange={selectedBatchFile => this.setState({selectedBatchFile})}
                          value={this.state.selectedBatchFile}
                          loadOptions={inputValue => {
                            let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: this.state.type}];

                            return request.batchUpload.getAllFiles({orderBy: {column: "created_at", type: "DESC"}, filterBy}, this.state.selectedBatchLink.token)
                              .then(files => files.map(file => ({value: file.id, label: file.filename})))
                          }}
                          defaultOptions
                        />
                      </FormControl>
                      }
                    </>
                }

                {this.state.isReel &&
                  <>
                    <h3 className="mt-5" style={{textAlign: "left", fontSize: 18, marginTop: 20}}>{localization.get('reel.edit_thumbnail_tooltip')}</h3>
                    <ImagePicker
                      buttonText={localization.get('form.select_image')}
                      image={ this.state.thumbnailImage.src }
                      onImageChange={ thumbnailImage => this.setState({ thumbnailImage })}
                      color={'default'}
                      style={{backgroundColor: "black"}}
                      accept="image/png|jpg|jpeg"
                    />
                    <p style={{textAlign: "center"}}>{localization.get('reel.edit_thumbnail_dialog_description_alt')}</p>
                  </>
                }
              </>
              }

              <div className="file-uploader-tags-container" style={{width: "100%", minHeight: "30vh"}}>
                <h3 className="mt-5" style={{textAlign: "left"}}>{localization.get('tags')}
                  <Button
                    onClick={() => this.showTagDialog()}
                    style={{height: 35, width: 35, backgroundColor: "black", left: 15}}
                    variant="fab" color="primary" aria-label="Add"
                  >
                    <Tooltip
                      title={localization.get('file.tag_create')}
                      placement='top'>
                      <Add/>
                    </Tooltip>
                  </Button>
                </h3>
                {this.state.tags && this.state.tags.length > 0 &&
                <>
                  <FormControl style={{width: "100%", padding: "0 7px"}}>
                    <TextField
                      style={{marginBottom: 20}}
                      value={this.state.search}
                      label={localization.get("search")}
                      onChange={e => this.setState({search: e.target.value}, () => setTimeout(this.handleTagSearch(), 200))}
                      fullWidth
                    />
                  </FormControl>
                  <div className="tags-container">
                    {this.state.searchTags.length > 0 ?
                    [1,2,3,4,5,6,7,8,9,10,null].map(element => {
                      const tags = this.state.searchTags.filter(tag => tag.level === element)
                      return (
                      <>
                      {tags.length > 0 &&
                        <div style={{width: "100%", marginBottom: 10}}>
                          <HomeItemDescriptionTags
                            displayOnMobile={true}
                            outlined
                            actionIcon={<Edit style={{fontSize: 15}}/>}
                            handleAction={this.openEditTag}
                            tags={tags}
                            onSelectedTagChanged={this.onSelectedTagChanged}/>
                        </div>
                      }
                      </>)
                    })
                      :
                      <div style={{width: "100%", height: "75%",  marginBottom: 10, display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                        {localization.get('searchbar.no_result')}
                      </div>
                    }
                  </div>
                </>
                }
              </div>
            </React.Fragment>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} disabled={this.state.sending}>
            {localization.get("cancel")}
          </Button>
          <Button onClick={this.onSubmit} disabled={this.state.sending}>
            {this.state.sending ? this.state.progress + "%" : localization.get("send")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}
