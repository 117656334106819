import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update";

const AlbumsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/albums/:id/edit" component={Update} />
        <Route path="/panel/albums/create" component={Create} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default AlbumsRouting
