import {post} from "./utils";

export default host => (message, level) =>
  post(
    `${host}/log/${level}`,
    JSON.stringify({
      message
    }),
    false
  );
