import {get} from "../utils";
import {storeUser} from "./utils";

export default host => () => get(`${host}/user?include=address;image;role;clientRoles.role;clientRoles.client;artists;channels.channel;providers;contentCreators.image;contentCreators.coverImage;contentCreators.translations;contentCreators.tags;contentCreators.country;contentCreators.type;contentCreators.socialLinks;clouds.catalog.clients;brand`)
  .then(response => response.json())
  .then(json => {
    const user = json.data
    storeUser(user)
    return user
  })
