import React, {Component} from "react";
import localization from "../../../../config/localization";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import {Formik, ErrorMessage} from "formik";
import styled from "styled-components";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { Event } from "@material-ui/icons";
import DateFnsUtils from '@date-io/date-fns';

class EventDateEditionDialog extends Component {

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
  }

  validate = values => {
    const errors = {};

    if (values.activationRadio == 'programmed' && !values.activationDate) {
      errors.activationDate = localization.get("validator.is_required");
    }
    return errors;
  };

  initialValues = (eventDate) => ({
    date: eventDate.date,
    dateLabel: eventDate.dateLabel,
    duration: eventDate.duration,
    link: eventDate.link,
    linkText: eventDate.linkText,
    clarification: eventDate.clarification,
    activationDate: eventDate.activationDate ? new Date(Date.parse(eventDate.activationDate)) : null,
    activationRadio: eventDate.activationDate ? 'programmed' : 'notProgrammed'
  });

  onSubmit= (values) => {
    this.props.onSubmit(values);
  }

  getMaxDate = () => {
    return this.props.eventDate.date.toLocaleString();
  }

  render = () => (
    <Dialog
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>
        <span>
          {this.props.eventDate.dateLabel}
        </span>
      </DialogTitle>
      <DialogContent>
        
        <Formik
          initialValues={this.initialValues(this.props.eventDate)}
          validate={this.validate}
          onSubmit={(values) => {
            this.onSubmit(values)
          }}
        >
          {formikProps => (
            <div>
              <h4>{localization.get("recurrent_events.button_label")}</h4>
              <div style={{float: "left", position: "relative", width: "100%", marginBottom: 10, display: 'flex'}}>
                <div style={{float: "left", position: "relative", width: "200px"}}>
                  <TextField
                    style={{marginBottom: 20}}
                    value={formikProps.values.link}
                    label={localization.get("recurrent_events.button_url")}
                    onChange={e => formikProps.setFieldValue('link', e.target.value)}
                  />
                  <ErrorMessage name="link" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: "200px"}}>
                  <TextField
                    style={{marginBottom: 20}}
                    value={formikProps.values.linkText}
                    label={localization.get("recurrent_events.button_text")}
                    onChange={e => formikProps.setFieldValue('linkText', e.target.value)}
                  />
                  <ErrorMessage name="linkText" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div style={{float: "left", position: "relative", width: "100%", marginBottom: 10, display: 'flex'}}>
                <div style={{float: "left", position: "relative", width: "300px"}}>
                  <RadioGroup
                    name="activationRadio"
                    value={formikProps.values.activationRadio}
                    onChange={e => {
                      formikProps.setFieldValue("activationRadio", e.target.value); 
                      if (e.target.value == "notProgrammed") {
                        formikProps.setFieldValue("activationDate", null);
                      }
                    }}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="notProgrammed" control={<Radio color="default"/>} label={localization.get('recurrent_events.not_programmed_button')}/>
                    <FormControlLabel value="programmed" control={<Radio color="default"/>} label={localization.get('recurrent_events.programmed_button')}/>
                  </RadioGroup>
                  <ErrorMessage name="activationRadio" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                {formikProps.values.activationRadio == 'programmed' && 
                  <div style={{float: "left", position: "relative", width: "150px"}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateDiv>
                        <DateTimePicker
                          autoOk
                          autoSubmit
                          disablePast
                          ampm={false}
                          format={"dd/MM/yyyy, HH:mm a"}
                          dateRangeIcon={<Event/>}
                          maxDatetime={this.getMaxDate()}
                          label={localization.get("recurrent_events.button_activation_date")}
                          value={formikProps.values.activationDate}
                          onChange={value => formikProps.setFieldValue('activationDate', value)}
                        />
                        <ErrorMessage name="activationDate" className="web-like-error">
                          {msg => <div className="web-like-error font12">{msg}</div>}
                        </ErrorMessage>
                      </DateDiv>
                    </MuiPickersUtilsProvider>
                  </div>
                }
              </div>
              <h4>{localization.get("recurrent_events.clarification")}</h4>
              <div style={{position: "relative", width: "100%", marginBottom: 10, display: 'flex'}}>
                <div style={{float: "left", position: "relative", width: "200px"}}>
                  <TextField
                    style={{marginBottom: 20}}
                    value={formikProps.values.clarification}
                    label={localization.get("recurrent_events.clarification_label")}
                    onChange={e => formikProps.setFieldValue('clarification', e.target.value)}
                  />
                  <ErrorMessage name="clarification" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div style={{margin: '20px 0 5px', float: 'right'}}>
                <Button onClick={() => this.handleCloseDialog()} color='default'>{localization.get("cancel")}</Button>
                <Button onClick={() => formikProps.handleSubmit()} color='primary'>{localization.get("form.save")}</Button>
              </div>
            </div>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default EventDateEditionDialog;

const DateDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
`