import {get} from '../utils'

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/musics?approved=true&include=licenses(10).client;licenses.project;licenses.type;catalog;reservedBy;tags;albums.artist;audio:url,duration;audio.waveform;audioAiff;midLevelFeature;lowLevelFeature;artist&${appendHasArtist(options)}`, {...options, alreadyHasQueryParams: true})

const appendPaginationQueryParamsToUrl = (
  url,
  { alreadyHasQueryParams, perPage, page, filterBy, orderBy } = {}
) => {
  const qps = [];
  if (typeof perPage === "number") qps.push(`per_page=${perPage}`);
  if (typeof page === "number") qps.push(`page=${page + 1}`);
  if (orderBy) qps.push(`order_by=${orderBy.column}`, `sort_type=${orderBy.type}`);
  if (filterBy) filterBy.forEach(filter => {
    if(!(filter.column === 'artist_id' && filter.filter === 'false')) {
      qps.push(`${filter.column}=${filter.filter}`)
    }
  });
  if (qps.length === 0) {
    return url;
  }
  const qpsInitialOperator = alreadyHasQueryParams ? "&" : "?";
  return url + qpsInitialOperator + qps.join("&");
};

const appendHasArtist = (options) => {
  if (options.filterBy && options.filterBy.find(filter => filter.column === 'artist_id' && filter.filter === 'false')) {
    return 'has_artist=false'
  }
  return ''
}

export default host => options =>
  get(url(host, options))
  .then(response => response.json())
  .then(json => json.meta ? json : json.data)
