import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import { Delete,  Edit } from "@material-ui/icons";
import {request} from "../../../api-client";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import TextField from "../../../components/Letflow/Form/TextField";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar.jsx";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {ImagePicker} from '../../../components/Letflow/Form';
import {getUpdateFormImageUrlFromItem,} from "../../../utils";

class Brands extends Component {
  state = {
    perPage: 20,
    page: 0,
    openFormDialog: false,
    editingBrand: null,
    name: "",
    buttonName: "",
    link: "",
    image: { id: null, src: null, file: null },
    imageId: null,
    disableForm: false,
  };

  componentDidMount = () => {
    document.title = localization.get("channel.community") + " - Feater";
  };

  buttons = brand => {
    const {id, name} = brand;

    const buttonBuilder = new TableButtonsBuilder();
  

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('edit')}
        onClick={() =>
          this.setState({
            openFormDialog: true,
            editingBrand: brand,
            name: brand.name,
            buttonName: brand.button_name,
            link: brand.link,
            hasButton: brand.link && brand.button_name,
            image: brand.image
            ? { id: brand.image.id, src: getUpdateFormImageUrlFromItem(brand), filename: brand.image.original_name }
            : { src: null, file: null },
          })
        }
      >
        <Edit/>
      </TableButton>
    )

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('delete')}
        onClick={() =>
          GlobalConfirmationDialog.showDelete({
            dialogTitle: `${localization.get('tooltip.delete')} ${name}`,
            content: localization.get('confirmation_dialog.delete_content_message'),
            doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
            elementName: localization.get("delete"),
            deleteRequest: () => request.brands.delete(id)
              .then(this.refs.table.forceUpdate),
            doubleConfirmation: true,
            auxiliaryInformation: "",
          })
        }
      >
        <Delete />
      </TableButton>
    )

    return buttonBuilder.build();
  }

  onCloseDialog= () => this.setState({openFormDialog: false, name: "", editingBrand: null, link: "", buttonName: "", hasButton: false, imageId: null, image: { id: null, src: null, file: null }, disableForm: false})

  makeFormDialog = () => 
  <Dialog 
      open={this.state.openFormDialog} 
      onBackdropClick={this.onCloseDialog} 
      onEscapeKeyDown={this.onCloseDialog}
    >
      <DialogTitle>{localization.get(this.state.editingBrand ? "table.edit_brand" : 'table.create_brand')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: 400}}>
          <TextField
            id="name"
            name="name"
            onChange={e => this.setState({name: e.target.value})} 
            value={this.state.name}
            label={localization.get("name")}
          />
          <br/>
          <FormControlLabel
          control={
            <Checkbox
              id="hasButton"
              style={{ color: "#4cc2d5" }}
              checked={this.state.hasButton}
              onChange={() => {
                if (this.state.hasButton) {
                  this.setState({ hasButton: !this.state.hasButton, link: "", buttonName: "" })
                } else {
                  this.setState({ hasButton: !this.state.hasButton })
                }
              }}
            />
          }
          label={localization.get('form.use_brand_button')}
        />
          {this.state.hasButton && 
          <>
            <TextField
              id="link"
              name="link"
              onChange={e => this.setState({link: e.target.value})} 
              value={this.state.link}
              label={localization.get("link")}
            />
            <TextField
              id="buttonName"
              name="buttonName"
              onChange={e => this.setState({buttonName: e.target.value})} 
              value={this.state.buttonName}
              label={localization.get("recurrent_events.button_text")}
            />
          </>
        }
        {(this.state.image && this.state.image.src) && <ImagePicker image={this.state.image.src} previewOnly={true} id={this.state.image.id} />}
          <FilePondUploader
            disableForm={this.state.disableForm}
            updateDisableForm={value => this.setState({disableForm: value})}
            file={this.state.image}
            id={"image"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={this.onImageChange}
            setPreview={this.onSetPreview}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCloseDialog}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.handleSubmit()}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>


  handleSubmit = () => {
    if ((!this.state.buttonName && this.state.link) || (this.state.buttonName && !this.state.link))  {
      GlobalSnackbar.show({message: localization.get('form.brand_validation'), type: GlobalSnackbarTypes.ERROR});

      return;
    }

    if(this.state.editingBrand) {
      return request.brands.update(this.state.editingBrand.id,
         {
          name: this.state.name,
          link: this.state.link,
          button_name: this.state.buttonName,
          ...(this.state.imageId ? {imageId: this.state.imageId} : {})
        })
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
    } 

    return request.brands.create(
      {
        name: this.state.name,
        link: this.state.link,
        button_name: this.state.buttonName,
        ...(this.state.imageId ? {imageId: this.state.imageId} : {})
      }
      )
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
  }

  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)

  setPreview = (type) => {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
  }
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})

  columns = () => [
    {
      id: "image",
      accessor: brand => (
        <Tooltip title={brand.name}>
          <img
            alt="brand"
            src={makeImageUrlWithSize(brand, 'xs')}
            width={50}
            height={50}
            onError={onImageError}
          />
        </Tooltip>
      ),
      style: {
        float: "left"
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get("table.name"),
      accessor: creator =>  <Tooltip title={creator.name}><p>{creator.name}</p></Tooltip>,
      id: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            if(event.keyCode == 13) {
              onChange(this.state.title)
            }else {
              this.setState({title: event.target.value || ""})
            }
          }}
          value={this.state.title ? this.state.title : ""}
          placeholder={localization.get("table.search")}
        />
      ),
    },
    
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  render = () => (
    <AllViewContainer ref="viewContainer" onAdd={() => this.setState({openFormDialog: true})}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
                {this.makeFormDialog()}
                <div style={{ width: "100%", float: "left", position: "relative" }}>
                <ServerSidePaginationTable
                    ref="table"
                    columns={this.columns()}
                    requestTableData={async paginationConfig => {
                    paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'dashboard', filter: "true" }])};

                    if(!paginationConfig.orderBy) {
                        paginationConfig.orderBy = {column: "updated_at", type: "desc"};
                    }

                    this.setState({ paginationConfig })

                    return request.brands.getAll(paginationConfig, "")
                        .then(response => {
                          this.setState({total: response.meta.total});
                          return response;
                      });
                    }}
                />
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default Brands;
