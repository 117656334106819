import React from "react";
import NoImage from "../../../assets/img/no_image.png"
import NoProfile from "../../../assets/img/default-avatar.png";
import AudioImage from "../../../assets/img/Letflow/SmartSearch/audio.png";
import Pdf from "../../../assets/img/Letflow/SmartSearch/pdf.jpeg";
import Video from "../../../assets/img/Letflow/SmartSearch/video.jpeg";
import "./index.css"
import {makeImageCarousel} from "../../../layouts/Home/HomeElements/HomeElementCarouselItem";
import {Image, PlayCircleFilled, Headset, Description, Share, RemoveCircle, QuestionAnswer, Delete, Edit, CheckCircle} from "@material-ui/icons";
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import {channelCanAddToFavorites, getActiveChannel, getActiveClient, getUseTools, getStoredUser, homePathGenerator, getStoredChannel, userCanEditCreators} from "../../../api-client/core/authentication/utils";
import Link from "react-router-dom/Link";
import EventManager, {events} from "../../../utils/EventManager";
import AddToPlaylist from "../AddToPlaylist";
import localization from "../../../config/localization";
import {Tooltip} from "@material-ui/core";
import {get} from "lodash";
import {makeThumbnailUrlWithSize} from "../../../layouts/Home/HomeElements/utils";

const getIcon = (creation) => {
    if (!!creation.video) {
      return <PlayCircleFilled style={{color: "var(--main-font-color, #727272)"}} />;
    }else if (!!creation.audio) {
      return <Headset style={{color: "var(--main-font-color, #727272)"}} />;
    }else if (!!creation.binary) {
      return <Description style={{color: "var(--main-font-color, #727272)"}} />;
    }else if (!!creation.embededContent) {
      if(creation.embededContent.source === "spotify") {
        return <Headset style={{color: "var(--main-font-color, #727272)"}} />;
      }
      return <PlayCircleFilled style={{color: "var(--main-font-color, #727272)"}} />;
    }else {
      return <Image style={{color: "var(--main-font-color, #727272)"}} />;
    }
}

const getNoImage = (creation) => {
  if (!!creation.video) {
    return Video;
  }else if (!!creation.audio) {
    return AudioImage;
  }else if (!!creation.binary) {
    return Pdf;
  }else {
    return NoImage;
  }
}

const openContentCreatorModal = (creator) => {
  EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {id: creator.id, dataType: "contentCreator", dataName: creator.name, onTagChange: false});
}

const getImage = creation => {
  if (creation.image) {
    return makeImageCarousel(creation);
  } else if (creation.video && creation.video) {
    return makeThumbnailUrlWithSize(creation.video, "md");
  }

  return getNoImage(creation)
}


const CreationItem = ({
    contentCreation,
    onFavorite,
    onPlay,
    onShare,
    isPublic = false,
    extraHeight = false,
    showDescription = false,
    hideAuthor = false,
    removeFromPlaylist = false,
    onAddComment= false,
    noAuthorLink = false,
    onDelete = undefined,
    onEdit = undefined,
    selectMode= false,
    isSelected= false,
  }) => {
    hideAuthor = hideAuthor || get(getStoredChannel(), "preferences.hide_creator", false);

    return(
      <div className={"creation-card"}>
        {isSelected && <CheckCircle style={{position: "absolute", top: 10, right: 10, zIndex: 10}}/>}
        <div className={extraHeight ? "creation-img-extra-height-container" : "creation-img-container"}>
          <div
            className={"creation-img"}
            onClick={() => !selectMode && onPlay()}
            style={{
              opacity: isSelected? 0.7 : 1,
              backgroundImage: `url(${getImage(contentCreation)}), url(${NoImage})`,
              border: isSelected? "2px solid var(--main-font-color, black)" : "none"  
            }}/>
          {!isPublic && !!getStoredUser() && 
            <div className={"creation-over-actions"}>
              {!!getStoredUser() && getActiveChannel() && channelCanAddToFavorites() &&
                <Tooltip placement="bottom"
                        title={localization.get(contentCreation.favorite ? "soundbar.remove_from_favorites" : "soundbar.add_to_favorites")}>
                  <Star
                    className={`creation-over-actions-item ${contentCreation.favorite ? "creation-over-actions-item-active" : ""}`}
                    style={{fontSize: 30,}} onClick={onFavorite}
                  />
                </Tooltip>
              }
              {!!getStoredUser() && getActiveClient() && getUseTools() && !removeFromPlaylist &&
                <AddToPlaylist
                  clientId={getActiveClient()}
                  content={"contentCreations"}
                  item={contentCreation}
                  icon={<Tooltip placement="bottom" title={localization.get("add_to_playlist")}>
                    <List className={"creation-over-actions-item"}
                          style={{fontSize: 33, right: 10, cursor: "pointer", marginTop: 3}}/>
                  </Tooltip>}
                />
              }
              {!!getStoredUser() && getActiveClient() && getUseTools() && removeFromPlaylist &&
                <Tooltip placement="bottom"
                         title={localization.get("remove_from_playlist")}>
                  <RemoveCircle className={`creation-over-actions-item`} style={{ fontSize: 30 }} onClick={removeFromPlaylist} />
                </Tooltip>
              }
              {onShare && !!getStoredUser() && getActiveChannel() &&
                <Tooltip placement="bottom" title={localization.get("share")}>
                  <Share
                    className={"creation-over-actions-item"}
                    style={{fontSize: 2, marginTop: 2, cursor: "pointer", height: 26,width: 24}}
                    onClick={() => onShare(contentCreation)}
                  />
                </Tooltip>
              }
              {!!getStoredUser() && getActiveClient() && getUseTools() && onAddComment &&
                <Tooltip placement="bottom" title={localization.get("comment")}>
                  <QuestionAnswer
                    className={"creation-over-actions-item"}
                    style={{fontSize: 2, marginTop: 2, cursor: "pointer", height: 26,width: 24}}
                    onClick={() => onAddComment(contentCreation)}
                  />
                </Tooltip>
              }
              {!!getStoredUser() && getActiveClient() && getUseTools() && onEdit && userCanEditCreators() &&
                <Tooltip placement="bottom" title={localization.get("edit")}>
                  <Edit
                    className={"creation-over-actions-item"}
                    style={{fontSize: 2, marginTop: 2, cursor: "pointer", height: 26,width: 24}}
                    onClick={() => onEdit(contentCreation)}
                  />
                </Tooltip>
              }
              {!!getStoredUser() && getActiveClient() && getUseTools() && onDelete && userCanEditCreators() && 
                <Tooltip placement="bottom" title={localization.get("delete")}>
                  <Delete
                    className={"creation-over-actions-item"}
                    style={{fontSize: 2, marginTop: 2, cursor: "pointer", height: 26,width: 24}}
                    onClick={() => onDelete(contentCreation)}
                  />
                </Tooltip>
              }
            </div>
          }
          {!!contentCreation.link &&
            <div className="download-button-container creation-over-visit " onClick={onPlay} >
              <span className="download-button">{localization.get('visit')}</span>
            </div>
          }
        </div>
        <div className={"creation-info"}>
          <div className={"creation-type"}>
            {getIcon(contentCreation)}
          </div>
          <div className={"creation-data"}>
            <Tooltip placement="bottom" title={contentCreation.title}>
              <h3 onClick={onPlay} style={(hideAuthor && !showDescription) ? {cursor: "pointer", color: "var(--main-font-color, black)", maxHeight: "42px", whiteSpace: "break-spaces"} : {cursor: "pointer", color: "var(--main-font-color, black)"}}>{contentCreation.title}</h3>
            </Tooltip>
            {showDescription && contentCreation.description && <h3 onClick={onPlay} style={{cursor: "pointer", fontWeight: "normal", color: "#838383", marginTop: 0}}>{contentCreation.description}</h3>}
            {!hideAuthor && contentCreation.contentCreator && 
              <div className={"creation-author"}>
                <div
                  className={"creation-author-image"}
                  style={{backgroundImage: `url(${!!contentCreation.contentCreator.image ? makeImageCarousel(contentCreation.contentCreator, {width: 15, height: 15, fit: false}) : NoProfile}), url(${NoProfile})`}}
                />
                {noAuthorLink ?
                  <a className="creation-author-name">{contentCreation.contentCreator.name}</a>
                :
                  <Link
                    className={"creation-author-name"}
                    to={homePathGenerator(isPublic ? `public/content_creators/${contentCreation.contentCreator.hashcode}` : `contenido/${contentCreation.contentCreator.id}-${contentCreation.contentCreator.name}`)}
                    onClick={e => {
                      if(!isPublic) {
                        e.preventDefault();
                        openContentCreatorModal(contentCreation.contentCreator)
                      }
                    }}
                  >{contentCreation.contentCreator.name}</Link>
                }
              </div>
            }
        </div>
      </div>
    </div>
    );
}

export default CreationItem
