import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {Done} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import GlobalConfirmationDialog from "../../Dialog/GlobalConfirmationDialog";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../Snackbar/GlobalSnackbar";

class TableApprovedButton extends Component {



    handleOnClick = () => {
      if (this.props.albums === 0) {
        GlobalSnackbar.show({
          message: localization.get('music.must_have_library_album'),
          type: GlobalSnackbarTypes.ERROR,
        })
      } else {
        GlobalConfirmationDialog.show({
          title:localization.get('alert.approving_a_music') ,
          content: localization.get('alert.approving_a_music') ,
          request: () => this.props.requestApproved().then(() => this.props.alertClosed())
        })
      }
    }


    render = () => {
      return (
        <TableButton
          title={this.props.disabled ? localization.get('tooltip.must_be_supervisor') : localization.get('tooltip.approved')}
          disabled={this.props.disabled}
          onClick={this.handleOnClick}
        >
          <Done style={{color: this.props.albums !== 0 ? "lime" : "red"}} />
        </TableButton>
      )
    }
}

TableApprovedButton.propTypes = {
    itemInformation: PropTypes.string.isRequired,
    requestApproved: PropTypes.func.isRequired,
    alertClosed: PropTypes.func.isRequired,
}

export default TableApprovedButton