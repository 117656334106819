import React from 'react'
import {TextField} from '@material-ui/core'
import localization from '../../../../config/localization'

const REDUCTIONS_LIMIT = 20

export default ({ value, onChange, title = localization.get('license_wizard.reductions'), disabled = false }) =>
  <div style={{ margin: '10px 20px' }} >
    <TextField
      label={title}
      value={value}
      onKeyDown={e => e.key === '.' && e.preventDefault() }
      onChange={e => {
        let value = e.target.value.replace(/^0+/, "")
        if (value < 0 || isNaN(value)) {
          value = 0
        }
        if (value > REDUCTIONS_LIMIT) {
          value = REDUCTIONS_LIMIT
        }
        onChange(value)
      }}
      type='number'
      disabled={disabled}
      fullWidth
    />
  </div>