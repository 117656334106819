import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery} from './utils'
import {isPublicChannel} from "../authentication/utils";

const url = (host, options, target) => appendPaginationQueryParamsToUrl(`${host}${isPublicChannel() ? "/public": ""}/tagGroups?${target ? `target=${target}&`: ''}${includeQuery}`, { ...options,
  alreadyHasQueryParams: true
})

export default host => (options, target=null) =>
  get(url(host, options, target))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)