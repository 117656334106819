import {addPutMethodToForm, put, post} from '../utils'

export default host => ({
  id,
  name,
  catalog_id,
  content_creator_type_ids,
}) => {
  let formData = new FormData();

  if (name) formData.append("name", name);
  if (catalog_id) formData.append("catalog_id", catalog_id);
  if (content_creator_type_ids) {
    content_creator_type_ids.forEach(cId => formData.append("content_creator_type_ids[]", cId))
  }

  formData = addPutMethodToForm(formData);

  return post(`${host}/clouds/${id}`, formData)
    .then(response => response.json())
}