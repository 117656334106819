import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import AlbumsTable from "./Table";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {
  getStoredUser,
  userIsArtist,
  userisMultiClient
} from "../../../api-client/core/authentication/utils";
import {makeUrlWithTableParams} from "../../../utils";
import {request} from "../../../api-client";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

class Albums extends Component {

  state = {
    albums: [],
    tagGroups: [],
    perPage: 20,
    page: 0,
    openCatalogDialog: false,
  };

  componentDidMount = () => {
    document.title = localization.get('title.albums') + ' - Feater'
  };

  onAdd = () => {
    if(userisMultiClient()){
      request.catalog.getAll()
        .then(res => {
          if (res.length === 0){
            this.setState({openCatalogDialog: true})
          } else {
            this.props.history.push(makeUrlWithTableParams(`/panel/albums/create`))
          }
        })
    } else {
      this.props.history.push(makeUrlWithTableParams(`/panel/albums/create`))
    }
  };

  makeCatalogCreateDialog = () =>
    <Dialog open={this.state.openCatalogDialog} onBackdropClick={() => this.setState({openCatalogDialog: false})} onEscapeKeyDown={() => this.setState({openCatalogDialog: false})}>
      <DialogTitle>{localization.get('catalog.is_required_dialog.title')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('catalog.is_required_dialog.instructions')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openCatalogDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.props.history.push(makeUrlWithTableParams(`/panel/catalogs/create`))}>{localization.get('catalog.is_required_dialog.create_catalog')}</Button>
      </DialogActions>
    </Dialog>


  render = () =>
    <div>
      {this.makeCatalogCreateDialog()}
      <AllViewContainer
        onAdd={(!userIsArtist() && !getStoredUser().artist_view) && this.onAdd}
        ref='viewContainer'>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <AlbumsTable ref="albumsTable" {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {userIsArtist() &&
          <div style={{
            float: "left",
            position: "relative",
            width: "100%",
            backgroundColor: "gray",
            height: "50px",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            borderRadius: "6px",
            color: "white",
            textAlign: "center",
            paddingTop: "10px"
          }}>
            {localization.get('albums.disable_add')}
          </div>
        }
      </AllViewContainer>
    </div>
}

export default Albums;
