import { post } from "../utils";

export default host => {
  /**
   * @param {RequestData} data
   */
  const request = data => {
    const form = new FormData();

    if(data.name) form.append(`name`, data.name);
    if(data.address) form.append(`address`, data.address);
    if(data.city) form.append(`city`, data.city);
    if(data.zip_code) form.append(`zip_code`, data.zip_code);
    if(data.google_maps_link) form.append(`google_maps_link`, data.google_maps_link || "");
    if(data.phone) form.append(`phone`, data.phone || "");
    if(data.description) form.append(`description`, data.description || "");

    return post(`${host}/user/address`, form);
  };
  return request;
};