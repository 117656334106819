import { getActiveChannel } from '../authentication/utils'
import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  id,
  name,
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashProfileImage,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  deleteDownloadableMaterial,
  batchDownloadableMaterialId,
  mainMaterial,
  viewLimit,
  timeToTrack,
  requireLogin,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  viewType,
  viewTypeMobile='grid',
}) => post(`${host}/channels/${getActiveChannel()}/contentCreators/${id}`, addPutMethodToForm(makeFormData({
    name,
    email,
    phone,
    translations,
    imageId,
    coverImageId,
    selectedTags,
    selectedContentCreations,
    socialLinks,
    isDemo,
    countryId,
    observation,
    active,
    selectedCatalogs,
    contentCreatorTypeId,
    weight,
    unsplashCoverImage,
    unsplashProfileImage,
    startActivity,
    batchProfileImageId,
    batchCoverImageId,
    downloadableMaterialId,
    downloadableMaterialFileType,
    downloadableMaterialText,
    downloadableMaterialLink,
    contentSupervisorId,
    onlyArgentina,
    releaseDate,
    expireDate,
    deleteDownloadableMaterial,
    batchDownloadableMaterialId,
    mainMaterial,
    viewLimit,
    timeToTrack,
    requireLogin,
    hasSuggestedCarousel,
    suggestedCarouselPlaylist,
    showDateOnHome,
    viewType,
    viewTypeMobile,
})))
  .then(response => response.json())
  .then(json => json.data)
