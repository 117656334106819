import React from "react";
import { Dialog, DialogTitle, DialogContent, Card,  } from "@material-ui/core";
import localization from "../../../config/localization";
import { Add, Delete, Edit } from "@material-ui/icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import { IconButton } from "@material-ui/core/index";

const HomeElementMenuDialog = ({open, onClose, elements, setElements, parentElement, removeElement, onEdit, onAddElement}) => 
      <Dialog
        open={open}
        onBackdropClick={() => onClose()}
        onEscapeKeyDown={() => onClose()}
      >
        <DialogTitle>{localization.get("navbar_drop_down_buttons")}</DialogTitle>
        <DialogContent>
        {open && elements.filter(element => !element.parent).length > 0 &&
          <DragDropContext
            onDragEnd={result => {
                if (result.destination) {
                const destination = result.destination.index;
                const source = result.source.index;

                if (destination !== source) {
                    const notElement = elements.filter(element => !!element.parent && element.parent !== parentElement.id).concat(elements.filter(element => !element.parent))           
                    const currentElements = elements.filter(element => !!element.parent && element.parent == parentElement.id).map((element, index) => ({...element, order: index}));
                    const direction = destination < source ? -1 : 1;
                    const involved = direction === -1 ? currentElements.slice(destination, source) : currentElements.slice(source + 1, destination + 1);
                    currentElements[source].order = currentElements[destination].order;
                    involved.forEach(x => (x.order -= direction));
                    
                    setElements(notElement.concat(currentElements), parentElement.type==="footer")
                }
              }
            }}
          >
            <Droppable
                droppableId="navbar-elements-menu"
                direction="vertical"
            >
              {provided => (
                <div
                    ref={provided.innerRef}
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    }}
                    {...provided.droppableProps}
                >
                  {elements.sort((a, b) => a.order - b.order)
                    .filter(element => !!element.parent && element.parent == parentElement.id)
                    .map((element, index) =>
                    <Draggable key={element.id.toString()} draggableId={element.id.toString()} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"100%"}>
                            <Card style={{height: "auto", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <h3 style={{
                                fontSize: 13,
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis" ,
                                lineHeight: "1.5em",
                                height: "3em",
                            }}>{element.title}</h3>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Edit
                                style={{
                                    padding: 8,
                                    cursor: "pointer",
                                    fontSize: 35,
                                    bottom: 5
                                }}
                                color="dark"
                                onClick={() => onEdit(element)}
                                />
                                <Delete
                                style={{
                                    padding: 8,
                                    cursor: "pointer",
                                    fontSize: 35,
                                    bottom: 5
                                }}
                                color="dark"
                                onClick={() => removeElement(element.id)}
                                />
                            </div>
                            </Card>
                        </div>
                        )}
                    </Draggable>
                    )}
                </div>
                )}
            </Droppable>
            </DragDropContext>
        }

        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <IconButton onClick={() => onAddElement()}>
            <Add
              style={{
                padding: 8,
                textAlign: "center",
                fontSize: 40,
                cursor: "pointer"
                }}
              color="dark"
            />
          </IconButton>
        </div>
        </DialogContent>
      </Dialog>

export default HomeElementMenuDialog;