import React, {Component} from "react";

import {request} from "api-client";
import Form, {feeCurrencies, feePaymentTypes} from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from '../../../config/localization'

export default class extends Component {
  state = {
    name: "",
    hasFee: false,
    fee_amount: 0,
    fee_currency: feeCurrencies.ARS,
    fee_payment_type: feePaymentTypes.CARD,
    own_channels_discount: 0,
    publicity_internet_discount: 0,
    publicity_radio_discount: 0,
    publicity_tv_discount: 0,
    publicity_all_discount: 0,
    bichannel_discount: 0,
    duration_radio_discount: 0,
    duration_tv_all_discount: 0,
    duration_internet_discount: 0,
    edition_discount: 0,
    reductions_discount: 0,
    own_channels_reductions_discount: 0,
    only_own_channels: false,
    only_other_purpose: false,
    license_quantity: 0,
    license_type: 'no_licenses',
  };

  requestCreation = () => request.licensePlan.create(this.state).then(() => this.props.history.push("/panel/licensePlans"));

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  render = () => {
    const {
      disabled,
      name,
      hasFee,
      fee_currency,
      fee_amount,
      fee_payment_type,
      own_channels_discount,
      publicity_internet_discount,
      publicity_radio_discount,
      publicity_tv_discount,
      publicity_all_discount,
      bichannel_discount,
      duration_radio_discount,
      duration_tv_all_discount,
      duration_internet_discount,
      edition_discount,
      reductions_discount,
      own_channels_reductions_discount,
      only_own_channels,
      only_other_purpose,
      license_type,
      license_quantity
    } = this.state;
    return (
      <div>
        <FormViewContainer
          title={localization.get('license_plans.create')}
          submitRequest={this.requestCreation}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <Form
            disabled={disabled}
            onChange={this.onChange}
            name={name}
            hasFee={hasFee}
            allowFee={() => this.setState({hasFee: true})}
            disallowFee={() => this.setState({hasFee: false})}
            feeCurrency={fee_currency}
            feeAmount={fee_amount}
            feePaymentType={fee_payment_type}
            ownChannelsDiscount={own_channels_discount}
            publicityInternetDiscount={publicity_internet_discount}
            publicityRadioDiscount={publicity_radio_discount}
            publicityTvDiscount={publicity_tv_discount}
            publicityAllDiscount={publicity_all_discount}
            bichannelDiscount={bichannel_discount}
            durationRadioDiscount={duration_radio_discount}
            durationTvAllDiscount={duration_tv_all_discount}
            durationInternetDiscount={duration_internet_discount}
            editionDiscount={edition_discount}
            reductionsDiscount={reductions_discount}
            ownChannelsReductionsDiscount={own_channels_reductions_discount}
            onlyOwnChannels={only_own_channels}
            onlyOtherPurpose={only_other_purpose}
            licenseType={license_type}
            licenseQuantity={license_quantity}
          />
        </FormViewContainer>
      </div>
    );
  };
}
