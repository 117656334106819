import React, {Component} from 'react'


class Sendy extends Component {

  componentDidMount = () => {
    window.open('https://sendy.flowlikemusic.com/app?i=1', '_blank');
    this.props.history.goBack()
  }

  render = () => <div/>
}

export default Sendy
