import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  requestTagGroup = () => request.tagGroup.get(this.props.match.params.id, 'include=translations;types;parent;childrens')
    .then(tagGroup => this.refs.form.setTagGroup(tagGroup))

  requestUpdate = state => {

    this.setState({ disabled: true })

    const {
      translations,
      type,
      order,
      selectedTypes,
      groupType,
      isAdmin,
      childrens
    } = state

    return request.tagGroup.update({
      id: this.props.match.params.id,
      translations,
      type,
      order,
      inheritable: groupType === 'both' || groupType === 'material',
      creation_group: groupType === 'material',
      selectedTypes,
      isAdmin,
      childrensIds: childrens.map(t => t.value)
    }).then(() => {
      let url =  ''
      if (this.props.location.pathname.includes('music')) {
        url = makeUrlWithTableParams(`/panel/music/tag_groups`);
      } else if (this.props.location.pathname.includes('talents')) {
        url = makeUrlWithTableParams(`/panel/talents/tag_groups`);
      }  else if (this.props.location.pathname.includes('audiovisual')) {
        url = makeUrlWithTableParams(`/panel/audiovisual/tag_groups`);
      } else {
        url = makeUrlWithTableParams(`/panel/editorial/tag_groups`);
      }
      this.props.history.push(url)
    })
      .catch(e => this.setState({ disabled: false }))
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => this.setState({ image })

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('tag_group.update_tag_group')}
        initializationRequest={this.requestTagGroup}
        submitRequest={this.requestUpdate}
        disableTypes
        isMusic={this.props.location.pathname.includes('music')}
        isTalent={this.props.location.pathname.includes('talents')}
        isAudiovisual={this.props.location.pathname.includes('audiovisual')}
        isEditorial={this.props.location.pathname.includes('editorial')}
      />
    )
  }
}
