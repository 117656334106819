import React from 'react'
import "../../Home/Music/Music.css"
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {getMostImportantAlbum, getRangeOfNumber, makeWaveformUrl} from "../../../utils";
import {Link} from 'react-router-dom'
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";
import CloudDownload from "@material-ui/icons/CloudDownload";
import {
  catalogClientView, channelCanAddToFavorites, channelPathGenerator,
  getActiveClient, getChannelCanLicense,  getStoredUser, getStoredChannel,
  setPageTitle, slugify,
  userCanAddTracksToPlaylists,
  userCanDownloadTracks, userIsSupervisor, userIsSysAdmin,
} from "../../../api-client/core/authentication/utils";
import GlobalAddToPlaylistDialog from "../../../components/Letflow/AddToPlaylistDialog/GlobalAddToPlaylistDialog";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {Dialog, DialogContent, IconButton, Tooltip} from "@material-ui/core";
import EventManager from "../../../utils/EventManager";
import events from "../../../utils/EventManager/events";
import TrackTable from "../../../components/Letflow/TrackTable";
import TrackResult from "../../../components/Letflow/TrackResult";
import tempos from "../../../config/tempos";
import volumes from "../../../config/volumes";
import {Close, ShoppingCart, Videocam} from "@material-ui/icons";
import browserHistory from "../../../utils/browserHistory";
import {makeImageUrlWithSize, emptyImage, onImageError} from "../../Home/HomeElements/utils";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import moment from "moment";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import GlobalLicenseWizardDialog from "../../../components/Letflow/Dialog/GlobalLicenseWizardDialog";

class Music extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      music: null,
      channel: props.channel,
      favorite: false,
      playing: false,
      suggestions: [],
      groupedTags: [],
      openLicenseDialog: false,
      licenses: [],
      showVideoDialog: false
    }
  }

  componentDidMount = () => {
    this.requestInitialization(!this.props.modal ? this.props.match.params.musicId : this.props.musicId)

    !this.props.modal && window.scrollTo(0, 0);
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (this.props.match.params.musicId === nextProps.match.params.musicId && !this.props.modal) {
      this.requestInitialization(nextProps.match.params.musicId)
      !this.props.modal && window.scrollTo(0, 0);
    }
  }

  componentWillUnmount = () => {
    this.trackPausedSubscription.unsubscribe();
    this.trackStartedPlayingSubscription.unsubscribe();
  };

  requestInitialization = musicId => {
    request.music.getForClient(musicId)
      .then(music => {
        this.setState({ music, favorite: music.channelFavorite })
        this.groupTags(music)
        setPageTitle(`${music.title}`)
      })

    request.music.suggestions([musicId], getActiveClient())
      .then(suggestions => {
        this.setState({ suggestions })
      });

    this.trackPausedSubscription = GlobalSoundBar.trackPaused$().subscribe(this.handleTrackPausedViaSoundbar);
    this.trackStartedPlayingSubscription = GlobalSoundBar.trackStartedPlaying$().subscribe(this.handleTrackStartedPlayingInSoundbar);
  }

  handleTrackPausedViaSoundbar = () => {
    if (GlobalSoundBar.currentTrackIsTitled(this.state.music.title)) {
      this.setState({ playing: null });
    }
  }

  handleTrackStartedPlayingInSoundbar = () => {
    if (GlobalSoundBar.currentTrackIsTitled(this.state.music.title)) {
      this.setState({ playing: true });
    }
  }

  groupTags = (music) => {
    let groupedTags = []
    music.tags.forEach(tag => {
      let groupedTag = groupedTags.find(groupedTag => groupedTag.name === tag.group.name)
      if (groupedTag) {
        groupedTag.tags.push(tag)
      } else {
        groupedTags.push({ id: tag.group.order, name: tag.group.name, tags: [tag] })
      }
    })
    
    this.setState({ groupedTags: groupedTags.sort((a, b) => a.id - b.id) })
  }


  onLicense = (track, trackContainer) => {
    GlobalLicenseWizardDialog.show({
      track: {
        id: track.id,
        title: track.title,
        image: makeImageUrlWithSize(trackContainer, 'sm'),
        waveform: makeWaveformUrl(track),
        owner: track.owner,
      },
      client: GlobalLicenseWizardDialog.mapApiClient(getStoredChannel().client),
    });
  };

  handlePlayButtonPress = (track, tracks, type, trackContainer) => {
    this.setState({ playing: true })
    const soundbarCompatibleTracks = tracks.map(track => {

      const onToggleFavorite = favorite => request.musicChannelFavorite[favorite ? "add" : "remove"](track.id);

      return {
        fetchAudioUrl: () => track.type === 'voice' ? request.voice.get(track.id).then(track => track.audio.url) : request.music.getForClient(track.id).then(track => track.audio.url),
        title: track.title,
        subtitle: trackContainer.title ? trackContainer.title : trackContainer.name ? trackContainer.name : undefined,
        image: makeImageUrlWithSize(trackContainer, 'sm'),
        waveform: makeWaveformUrl(track),
        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(track, trackContainer, type) : undefined,
        onAddToPlaylist: userCanAddTracksToPlaylists()
          ? () =>
            GlobalAddToPlaylistDialog.show({
              type: type,
              clientId: getActiveClient(),
              selectedTrack: track,
            })
          : undefined,
        playlistButtonData: userCanAddTracksToPlaylists()
          ? 
          {
            type: type,
            clientId: getActiveClient(),
            selectedTrack: track,
          }
          :
          undefined,
        onTitleClick: () => this.props.history.push(channelPathGenerator(`musica/${track.id}-${slugify(track.title)}`)),
        onSubtitleClick: () => this.props.history.push(channelPathGenerator(`album/${trackContainer.id}`)),
        isFavorite: track.channelFavorite,
        onToggleFavorite,
        onLicense: () => this.onLicense(track, trackContainer),
      };
    });
    const playableTrackIndex = tracks.indexOf(track);

    GlobalSoundBar.loadTracksAndPlay(soundbarCompatibleTracks, playableTrackIndex);
  };

  handlePauseButtonPress = () => {
    this.setState({ playing: null });
    GlobalSoundBar.pause();
  };

  makeLicenseDialog = () =>
    <Dialog open={this.state.openLicenseDialog} onBackdropClick={() => this.setState({openLicenseDialog: false})} onClose={() => this.setState({openLicenseDialog: false})}>
      {this.state.licenses.length > 0 ?
        <div style={{float: "left", position: "relative", width: "100%"}}>
          <table id="music-table">
            <tr>
              <th>{localization.get('music.license_table.date')}</th>
              <th>{localization.get('music.license_table.client')}</th>
              <th>{localization.get('music.license_table.type')}</th>
              <th>{localization.get('music.license_table.brand')}</th>
              <th>{localization.get('music.license_table.product')}</th>
              <th>{localization.get('music.license_table.campaign')}</th>
            </tr>
            {this.state.licenses.map(license => {
              return (
                <tr>
                  <td>{moment(license.starts_at).format('DD-MM-YYYY')}</td>
                  <td>{license.client.name}</td>
                  <td>{license.type.name}</td>
                  <td>{license.project.brand}</td>
                  <td>{license.project.product}</td>
                  <td>{license.project.title}</td>
                </tr>
              )
            })}
          </table>
        </div>
        :
        <DialogContent style={{
          width: "400px",
          textAlign: "center",
          fontSize: "22px",
          height: "100px",
          lineHeight: "50px"
        }}>
          {localization.get('track_is_not_licensed')}
        </DialogContent>
      }
    </Dialog>

  openLicensesDialog = track => {
    request.music.get(track.id, 'include=licenses(10).client;licenses.project;licenses.type;reservedBy;catalog')
      .then(track => this.setState({
        openLicenseDialog: true,
        licenses: track.licenses
      }))
  }

  openDownloadDialog = (track, trackContainer) => {
    GlobalConfirmationDialog.show({
      title: localization.get("download.demo"),
      content: localization.get("download.demo.content"),
      request: () => {
        return (
          request.music.downloadDemo(track.id, {
            title: track.title,
            image: makeImageUrlWithSize(trackContainer, 'lg'),
          })
            .then(e => {
              this.setState({ downloading: false });
            })
            .catch(() => {
              this.setState({ downloading: false });
            })
        )
      },
      confirmationLabel: localization.get("download"),
    });
  };

  makeVideoclipDialog = () =>
    <Dialog open={this.state.showVideoDialog} onBackdropClick={() => this.setState({showVideoDialog: false})} onClose={() => this.setState({showVideoDialog: false})}>
      <div style={{padding: "15px", maxWidth: "55vw"}}>
        <video
          style={{ width: '100%' }}
          src={this.state.music.video && this.state.music.video.url}
          controls
          autoplay={"autoplay"}
        />
      </div>
    </Dialog>


  addToFavorites = track => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      EventManager.getInstance().dispatch(events.ADD_TRACK_TO_FAVORITES, track);
      request.musicChannelFavorite.add(track.id)
        .then(() => this.setState({favorite: true}))
    }
  };

  removeFromFavorites = track => {
    EventManager.getInstance().dispatch(events.REMOVE_TRACK_FROM_FAVORITES, track);
    request.musicChannelFavorite.remove(track.id)
      .then(() => this.setState({ favorite: false }))
  };

  render = () => {
    const { music, suggestions, groupedTags } = this.state

    if (music) {
      return (
        <div id="innerinner">
          <div className="music-background" style={{ backgroundImage: `url(${makeImageUrlWithSize(getMostImportantAlbum(music.albums), 'lg')}), url(${emptyImage})` }}/>
          <div className="music-container">
            <div className="music">
              <div className="music-image-container">
                <img alt="album" src={makeImageUrlWithSize(getMostImportantAlbum(music.albums), 'sm')} onError={onImageError}/>
                {!this.state.playing ?
                  <IconButton
                    onClick={() => this.handlePlayButtonPress(music, [music], 'music', getMostImportantAlbum(music.albums))}
                    className="music-action-button"
                  >
                    <PlayArrow className="music-action-icon" />
                  </IconButton>
                  :
                  <IconButton
                    onClick={() => this.handlePauseButtonPress()}
                    className="music-action-button"
                  >
                    <Pause className="music-action-icon" />
                  </IconButton>
                }
              </div>
              <div className="music-details">
                <div style={{ float: "left", position: "relative", width: "100%" }}>
                  <div className="music-title">{music.title}</div>
                  <div className="music-buttons" style={{width: (catalogClientView() && window.innerWidth > 800) && "50px"}}>
                    
                    {!!channelCanAddToFavorites() &&
                    (this.state.favorite ?
                      <Tooltip placement="top" title={localization.get('button.remove_from_favorites')}>
                        <IconButton onClick={() => this.removeFromFavorites(music)} className="music-button" style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
                          <Star />
                        </IconButton>
                      </Tooltip>
                      :
                      <Tooltip placement="top" title={localization.get('button.add_to_favorites')}>
                        <IconButton className="music-button" onClick={() => this.addToFavorites(music)} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
                          <StarBorder />
                        </IconButton>
                      </Tooltip>
                    )
                    }

                    <Tooltip placement="top" title={localization.get('download.demo')}>
                      <IconButton className="music-button" onClick={() => this.openDownloadDialog(music, getMostImportantAlbum(music.albums))} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
                        <CloudDownload />
                      </IconButton>
                    </Tooltip>

                    {getChannelCanLicense() &&
                      <Tooltip placement="top" title={localization.get("license")}>
                        <IconButton className="music-button" onClick={() => this.onLicense(music, getMostImportantAlbum(music.albums))} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
                          <AddShoppingCart />
                        </IconButton>
                      </Tooltip>
                    }

                    {getChannelCanLicense() && (userIsSupervisor() || userIsSysAdmin()) &&
                      <Tooltip placement="top" title={localization.get("see_licenses")}>
                        <IconButton className="music-button" onClick={() => this.openLicensesDialog(music)} style={{color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}}>
                          <ShoppingCart />
                        </IconButton>
                      </Tooltip>
                    }

                  </div>
                </div>
                {music.artist &&
                <div style={{ float: "left", position: "relative", width: "100%" }}>
                  <Link to={{pathname: channelPathGenerator(`artista/${music.artist.id}-${slugify(music.artist.name)}`), state: {insidePlatform: true}}} className="music-artist">by {music.artist.name}</Link>
                </div>
                }
                {music.albums.length > 0 &&
                <div style={{ float: "left", position: "relative", width: "100%" }}>
                  <Link to={channelPathGenerator(`album/${getMostImportantAlbum(music.albums).id}`)} className="music-album">in {getMostImportantAlbum(music.albums).title}</Link>
                </div>
                }
                {music.audio && music.audio.waveform &&
                <div className="music-waveform">
                  <img alt="waveform" src={`${process.env.REACT_APP_CDN_HOST}/files/images/${music.audio.waveform.id}/crop/1000x200/waveform.png`} />
                </div>
                }
              </div>
              <IconButton style={{ position: "absolute", top: 7, right: 10, color: "var(--main-font-color, rgba(0, 0, 0, 0.54))"}} onClick={() => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose()}><Close /></IconButton>
            </div>

            <div className="music-tags-container">

              {music.video &&
              <>
                <div className="music-section-title" style={{paddingBottom: 0}}>{localization.get('videoclip')}</div>
                <div className="music-tag-row">
                  <div className="music-tag-group">
                    <IconButton onClick={() => this.setState({showVideoDialog: true})}>
                      <Tooltip placement="right" title={localization.get('button.watch')}>
                        <Videocam/>
                      </Tooltip>
                    </IconButton>
                  </div>
                </div>
              </>
              }

              <div className="music-section-title">{localization.get('catalog')}</div>
              {music.catalog &&
              <div className="music-tag-row">
                <div className="music-tag-group">{music.catalog.name}</div>
              </div>
              }

              <div className="music-section-title" style={{ marginTop: "20px" }}>{localization.get('tags')}</div>
              {
                groupedTags.map((groupTag) => {
                  return (
                    <div className="music-tag-row">
                      <div className="music-tag-group">{groupTag.name}</div>
                      {groupTag.tags.map(tag => {
                        return <Link className="music-tag" to={channelPathGenerator(`busqueda-inteligente/musica?tags_ids[]=${tag.id}`)}>{tag.name}</Link>
                      })}
                    </div>
                  )
                })
              }
              <div className="music-section-title" style={{ marginTop: "20px" }}>{localization.get('features')}</div>
              {(music.midLevelFeature && music.midLevelFeature.tempo) &&
              <div className="music-tag-row">
                <div className="music-tag-group">Tempo</div>
                <div className="music-tag">{`${music.midLevelFeature.tempo} (${getRangeOfNumber(music.midLevelFeature.tempo, tempos)})`}</div>
              </div>
              }

              {(music.lowLevelFeature && music.lowLevelFeature.rms) &&
              <div className="music-tag-row">
                <div className="music-tag-group">Volumen</div>
                <div className="music-tag">{`${parseFloat(music.lowLevelFeature.rms).toFixed(2)} (${getRangeOfNumber(music.lowLevelFeature.rms, volumes)})`}</div>
              </div>
              }
            </div>

            <div className="music-similar-container">
              <div className="music-section-title">{localization.get('suggestions')}</div>
              <TrackResult
                rows={<TrackTable {...this.props} type="music" hideSuggestions={true} tracks={suggestions} fromHome={false} />}
              />
            </div>
          </div>
          {this.makeLicenseDialog()}
          {this.makeVideoclipDialog()}
        </div>
      )
    }
    return null
  }
}

export default Music
