import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update"
import CreatePack from "./ContentPacks/Create";
import UpdatePack from "./ContentPacks/Update"
import CreateCode from "./ProductCodes/Create";
import UpdateCode from "./ProductCodes/Update"

const ContentCreatorTypesRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/products/codes/create" component={CreateCode}/>
        <Route path="/panel/products/codes/:id/edit" component={UpdateCode}/>
        <Route path="/panel/products/contentPacks/create" component={CreatePack}/>
        <Route path="/panel/products/contentPacks/:id/edit" component={UpdatePack}/>
        <Route path="/panel/products/create" component={Create}/>
        <Route path="/panel/products/:id/edit" component={Update}/>
        <Route render={props => (<All {...props}/>)} />
      </Switch>
    </Router>
  )
}

export default ContentCreatorTypesRouting
