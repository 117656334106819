import React, {Component} from "react";
import {request} from "../../../../api-client";
import {ImagePicker, TextField} from '../../../../components/Letflow/Form'
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from "../../../../config/localization";
import {getUpdateFormImageUrlFromItem} from "../../../../utils";
import noImage from "../../../../assets/img/Letflow/no-image.jpg";
import {getStoredUser, setPageTitle} from "../../../../api-client/core/authentication/utils";
import Button from "../../../../components/CustomButtons/Button.jsx";
import {FormControl, MenuItem} from "@material-ui/core";
import {SelectValidator} from "react-material-ui-form-validator";

export default class extends Component {

  state = {
    name: '',
    email: '',
    image: { src: null, file: null },
    disabled: false,
    oldPassword: '',
    password: '',
    language: '',
    showPassword: false,
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.profile'))
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  requestUser = () => new Promise((resolve, reject) => {
    const user = getStoredUser()
    if (!user) {
      reject()
    } else {
      this.setState(state => ({
        id: user.id, name: user.name, email: user.email, language: user.language,
        image: {
          ...state.image,
          src: getUpdateFormImageUrlFromItem(user)
        }
      }))
      resolve()
    }
  })

  requestUpdate = () => {
    const { id, name, email, image: { file }, oldPassword, password, language } = this.state
    const data = { name, email, userId: id, imageFile: file, oldPassword, password, language }
    return request.user.update(data).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value })

  handleImageChange = image => this.setState({ image })

  imageProvider = () => this.state.image.src || noImage

  handleRequestingStateChanged = disabled => this.setState({ disabled })
  render = () => {
    const {
      name,
      email,
      disabled,
      password,
      oldPassword,
      language,
    } = this.state

    return (
      <div>

        <FormViewContainer
          onBack={() => this.props.history.goBack()}
          title={localization.get('form.update_profile')}
          initializationRequest={this.requestUser}
          submitRequest={this.requestUpdate}
          onRequestingStateChange={this.handleRequestingStateChanged}
          style={{maxWidth: "600px", width: "100%", display: "table", margin: "30px auto"}}
          color="black"
        >
          <TextField
            label={localization.get('form.email')}
            id="email"
            value={email}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <TextField
            label={localization.get('form.name')}
            id="name"
            value={name}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <br />
          <br />
          <FormControl fullWidth>
            <SelectValidator
              label={localization.get("form.language")}
              id="language"
              name="language"
              value={language}
              onChange={e => {
                e.target.id = "language";
                this.handleChange(e);
              }}
              validators={["required"]}
              errorMessages={[localization.get("validator.is_required")]}
            >
              <MenuItem value="es">{localization.get('form.spanish')}</MenuItem>
              <MenuItem value="en">{localization.get('form.english')}</MenuItem>
            </SelectValidator>
          </FormControl>
          <br />
          <br />
          <ImagePicker
            buttonText={localization.get('form.select_image')}
            onImageChange={this.handleImageChange}
            image={this.imageProvider()}
            disabled={disabled}
            style={{backgroundColor: "black"}}
            color={'default'}
          />
          <div style={{textAlign: "center"}}>
            <Button style={{backgroundColor: "black", textAlign: "center", marginTop: "30px", marginBottom: "30px"}}
                    onClick={() => this.setState({showPassword: !this.state.showPassword})}
            >
              {localization.get('account.profile.change_password')}
            </Button>
          </div>
          <TextField
            style={{display: this.state.showPassword ? 'block' : 'none'}}
            label={localization.get('form.old_password')}
            id="oldPassword"
            value={oldPassword}
            type="password"
            onChange={this.handleChange}
          />
          <TextField
            style={{display: this.state.showPassword ? 'block' : 'none'}}
            label={localization.get('form.password')}
            id="password"
            value={password}
            type="password"
            onChange={this.handleChange}
          />
        </FormViewContainer>
      </div >
    )
  }
}
