import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {Visibility} from "@material-ui/icons";
import matchSorter from "match-sorter";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";

export default class extends Component {
  state = {
    clientCountries: [],
    country: ""
  };

  componentDidMount = () => {
    document.title = localization.get("title.artists_ranking") + " - Feater";

    request.client.getCountries().then(clientCountries => this.setState({clientCountries}))
  };

  onChangeCountry = country => {
    this.setState({ country });
  };

  buttons = artist => {
    const buttonBuilder = new TableButtonsBuilder();
    buttonBuilder.withOther(
      <a href={`/public/artist/${artist.id}`} target="_blank">
        <TableButton title={localization.get("button.see_home")}>
          <Visibility />
        </TableButton>
      </a>
    );
    return buttonBuilder.build();
  };

  render = () => {
    const { country, clientCountries } = this.state;

    return (
      <AllViewContainer ref="viewContainer">
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <InputLabel htmlFor="country">
            {localization.get("form.country")}
          </InputLabel>
          <Select
            id="country"
            name="country"
            value={country}
            onChange={event => {
              this.onChangeCountry(event.target.value);
              this.refs.table.forceUpdate();
            }}
            style={{ width: 300, marginLeft: "15px" }}
            displayEmpty
          >
            <MenuItem value="" defaultValue>
              {localization.get("all")}
            </MenuItem>
            {clientCountries.length > 0 &&
              clientCountries.map(country => {
                return <MenuItem value={country.key}>{country.name}</MenuItem>;
              })}
          </Select>
        </div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ServerSidePaginationTable
                  ref="table"
                  requestTableData={paginationConfig =>
                    request.artist.getRanking(
                      this.state.country,
                      paginationConfig
                    )
                  }
                  columns={[
                    {
                      Header: localization.get("table.position"),
                      accessor: "position",
                      width: 100,
                      style: {
                        textAlign: "center"
                      },
                      filterable: false
                    },
                    {
                      id: "image",
                      accessor: artist => (
                        <img
                          alt="Artist"
                          src={makeImageUrlWithSize(artist, 'xs')}
                          width={50}
                          height={50}
                          onError={onImageError}
                        />
                      ),
                      style: {
                        float: "left"
                      },
                      resizeable: false,
                      sortable: false,
                      filterable: false,
                      width: 60
                    },
                    {
                      Header: localization.get("table.name"),
                      accessor: "name",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                      filterAll: true,
                      Filter: ({ filter, onChange }) => (
                        <input
                          onChange={event => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          placeholder={localization.get("table.search")}
                        />
                      )
                    },
                    {
                      Header: localization.get("table.type"),
                      id: "type",
                      accessor: "type"
                    },
                    {
                      Header: localization.get("table.tracks_amount"),
                      id: "musics_count",
                      accessor: "musics_count",
                      sortable: false,
                      filterable: false,
                      width: 100,
                      style: {
                        textAlign: "right"
                      },
                      headerStyle: {
                        textAlign: "center"
                      }
                    },
                    {
                      Header: localization.get("table.points"),
                      id: "score",
                      accessor: item => item.score || 0,
                      filterable: false,
                      width: 100,
                      style: {
                        textAlign: "right"
                      },
                      headerStyle: {
                        textAlign: "center"
                      }
                    },
                    {
                      id: "actions",
                      Header: localization.get("table.actions"),
                      sortable: false,
                      filterable: false,
                      accessor: this.buttons,
                      width: 100
                    }
                  ]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  showPageSizeOptions={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    );
  };
}
