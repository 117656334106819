import {post} from "../utils";

export default host => (playlistId, emails, clientId, downloadWithOutWatermark = false) =>
  post(
    `${host}/playlists/${playlistId}/share`,
    JSON.stringify({
      emails,
      client_id: clientId,
      download_demo: downloadWithOutWatermark ? 0 : 1
    })
  )
    .then(response => response.json())
    .then(json => json.data);
