import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {Done} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import GlobalConfirmationDialog from "../../Dialog/GlobalConfirmationDialog";

class TableReviewButton extends Component {

  handleOnClick = () =>
    GlobalConfirmationDialog.show({
      title: localization.get('alert.review_an_album') ,
      content: localization.get('alert.reviewing_an_album') ,
      request: () => this.props.requestReview().then(this.props.alertClosed)
    })

  render = () => {
    return (
      <TableButton title={localization.get('tooltip.review')} onClick={this.handleOnClick}>
        <Done />
      </TableButton>
    )
  }
}

TableReviewButton.propTypes = {
    itemInformation: PropTypes.string.isRequired,
    requestReview: PropTypes.func.isRequired,
    alertClosed: PropTypes.func.isRequired,
}

export default TableReviewButton