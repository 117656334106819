import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeImageUrl, makeWaveformUrl} from '../../../utils'
import {Typography} from "@material-ui/core";
import localization from "../../../config/localization";
import {userCanDownloadUnMarkedTracksWithoutLicence} from "../../../api-client/core/authentication/utils";

class PlaylistSubcomponent extends Component {

  state = {
    musics: [],
    tags: [],
    loaded: false,
  }

  componentDidMount = () => {
    request.playlist.get(this.props.playlist.id, 'include=tags;musics;musics.albums;musics.audio;musics.audio.waveform').then(playlist => this.setState({
      musics: playlist.musics,
      tags: playlist.tags,
      loaded: true
    }))
  }

  render = () => {

    const playlistImage = makeImageUrl(this.props.playlist, 50)
    const gotoPlaylist = () => this.props.history.push(this.props.playlistRoute || `/panel/playlists/${this.props.playlist.id}/edit`)

    const makeSoundplayerSongs = type => this.state[type + 's'].map(track => ({
      fetchAudioUrl: () => request[type].audioUrl(track.id),
      image: playlistImage,
      title: track.title,
      subtitle: this.props.playlist.title,
      onAnyClick: gotoPlaylist,
      waveform: makeWaveformUrl(track)
    }))

    const soundplayerMusicSongs = makeSoundplayerSongs('music')

    const makeTable = (type, soundplayerSongs) => this.state[type].length > 0 && <div>
      <Typography>{localization.get(type)}</Typography>
      <ClientSidePaginationTable
        data={this.state[type].map((track, index) => ({ ...track, index, belongsTo: type }))}
        columns={[
          {
            id: 'title', accessor: track => track.title
          },
          {
            id: 'actions', accessor: track => {
              const builder = new TableButtonsBuilder()
              track.audio && builder.withDownload(this.makeDownloadRequest(track), { title: track.title, duration: track.audio.duration, type: 'music' })
              track.audio && builder.withNewPlay(soundplayerSongs, track.index)
              return builder.build()
            }
          },
        ]}
        filterable={false}
        sortable={false}
        showPagination={false}
        zebraStyle={false}
      />
    </div>


    return (
    <>
    {this.state.loaded &&
      <div style={{ ...this.props.style, margin: '20px' }}>
      {this.state.musics.length === 0 && this.state.tags.length === 0 && <Typography>{localization.get('subcomponent.playlist_has_no_tracks')}</Typography>}
      {this.state.tags.length > 0 &&
        <div style={{ width: "100%", float: "left", position: "relative", padding: "10px 20px" }}>
          <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('tags')}:</div>
          {this.state.tags.map(tag => {
            if (tag.wanted) {
              return <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "auto"}}>{tag.name}</div>
            }
          })}
          {this.state.tags.map(tag => {
            if (!tag.wanted) {
              return <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "auto", color: "#717171" }}>{tag.name}</div>
            }
          })}
        </div>
      }
        {this.state.musics.length > 0 && makeTable('musics', soundplayerMusicSongs)}
        <div style={{ height: '20px' }} />
      </div>
    }
    </>
    )
  }

  makeDownloadRequest = track => () => {
    if (track.belongsTo === 'musics') {
      if (userCanDownloadUnMarkedTracksWithoutLicence()) {
        return request.music.download(track.id)
      }
      return request.music.downloadDemo(track.id)
    }
  }
}

PlaylistSubcomponent.defaultProps = {
  style: {}
}

export default PlaylistSubcomponent