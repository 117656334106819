import React from "react";
import _ from "lodash";
import Checkbox, {SecondaryCheckbox} from "../components/Checkbox";
import {internetBudgetToString, mediaIdToString} from "../utils/mappers";
import {InternetBudgets, MediaTypeIds} from "../utils/constants";
import localization from "../../../../config/localization"

const MediaSelectionScreen = ({ selectedMedias, internetBudget, onChange }) => {
  const makeCheckbox = (media, disabled) => {
    const checked =
      selectedMedias.includes(MediaTypeIds.ALL_MEDIA) || // all media is selected
      (media === MediaTypeIds.INTERNET && selectedMedias.includes(MediaTypeIds.TELEVISION)) || // current is internet and television is selected
      selectedMedias.includes(media);
    return (
      <Checkbox
        checked={checked}
        content={
          <div className="license-wizard-main-checkbox-label-with-description">
            <div className="description" />
            <div className="label">{mediaIdToString(media)}</div>
          </div>
        }
        onClick={() => toggleClickedMedia(media)}
        disabled={disabled}
      />
    );
  };

  const makeInternetBudgetCheckbox = budget => (
    <SecondaryCheckbox
      checked={internetBudget === budget}
      label={internetBudgetToString(budget)}
      onClick={() => onChange({ internetBudget: budget })}
    />
  );

  const toggleClickedMedia = media => {
    if (!selectedMedias.includes(media)) {
      switch (media) {
        case MediaTypeIds.ALL_MEDIA:
          onChange({ medias: [MediaTypeIds.ALL_MEDIA] });
          return;
        case MediaTypeIds.TELEVISION:
          onChange({ medias: [MediaTypeIds.TELEVISION] });
          return;
        case MediaTypeIds.RADIO:
          if (selectedMedias.includes(MediaTypeIds.INTERNET)) {
            onChange({ medias: [media, MediaTypeIds.INTERNET] });
          } else {
            onChange({ medias: [media] });
          }
          return;
      }
      onChange({ medias: _.concat(selectedMedias, media) });
    } else {
      switch (media) {
        case MediaTypeIds.ALL_MEDIA:
        case MediaTypeIds.TELEVISION:
          onChange({ medias: [] });
          return;
      }
      onChange({ medias: _.filter(selectedMedias, selectedMedia => selectedMedia !== media) });
    }
  };

  const disabledWhenSelected = (...medias) => medias.some(x => selectedMedias.includes(x));

  const makeInternetBudgetSelection = () => {
    if (
      selectedMedias.some(selectedMedia => selectedMedia === MediaTypeIds.INTERNET) &&
      selectedMedias.every(
        selectedMedia => selectedMedia !== MediaTypeIds.ALL_MEDIA && selectedMedia !== MediaTypeIds.TELEVISION
      )
    ) {
      return (
        <div>
          <div className={"license-wizard-main-description"}>
            {localization.get('license_wizard.choose_budget_internet_campaign')}
          </div>
          <div className="license-wizard-countries-per-region-checkbox-container">
            {makeInternetBudgetCheckbox(InternetBudgets.UP_TO_5000)}
            {makeInternetBudgetCheckbox(InternetBudgets.MORE_THAN_5000)}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <h3 style={{ marginLeft: 20 }}>{localization.get('license_wizard.medias')}</h3>
      <div className={"license-wizard-main-description"}>{localization.get('license_wizard.medias_description')}</div>
      {makeCheckbox(MediaTypeIds.INTERNET, disabledWhenSelected(MediaTypeIds.ALL_MEDIA, MediaTypeIds.TELEVISION))}
      {makeInternetBudgetSelection()}
      {makeCheckbox(MediaTypeIds.RADIO, disabledWhenSelected(MediaTypeIds.ALL_MEDIA))}
      {makeCheckbox(MediaTypeIds.TELEVISION, disabledWhenSelected(MediaTypeIds.ALL_MEDIA))}
      {makeCheckbox(MediaTypeIds.ALL_MEDIA, false)}
    </div>
  );
};

export default MediaSelectionScreen;
