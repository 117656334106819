import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {Done} from "@material-ui/icons";
import VideoMessage from "./VideoMessage";
import {getContentFromMaterial, getMaterialType, getMessageFromMaterial} from ".";
import AudioMessage from "./AudioMessage";
import OtherMessage from "./OtherMessage";
import MessageOptions from "./MessageOptions";
import localization from "../../../../config/localization";
import ImageMessage from "./ImageMessage";
import {makeImageUrlWithSize, onImageError} from "../../../../layouts/Home/HomeElements/utils";

const portraitStyle = {
  width: 50,
  minWidth: 50,
  height: 50,
  minHeight: 50,
  borderRadius: "10px",
  objectFit: "cover",
  marginRight: 10
};

class Message extends Component {
  state = {
    // messageOptionsAnchor: null,
    isHover: false
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextProps.message.id !== this.props.message.id ||
      nextProps.isSearching !== this.props.isSearching
    ) {
      return true;
    }
    return this.state !== nextState;
  };

  showApprovedMessage = () => {
    const { message, onlineVideos } = this.props;

    if (!message.type.startsWith("material")) {
      return false;
    }

    const materialType = getMaterialType(message);

    if (["video", "audio"].includes(materialType)) {
      return onlineVideos.some(x => getContentFromMaterial(message) === x);
    }

    return false;
  };

  render = () => {
    const {
      message,
      onlineVideos,
      onlineVideosPath,
      clients,
      pitchId,
      isSearching,
      classes,
      onClick
    } = this.props;

    return (
      <div
        id={`pitch-message-${message.id}`}
        onMouseOver={() => this.setState({ isHover: true })}
        onMouseOut={() => this.setState({ isHover: false })}
        style={{ display: "flex", position: "relative" }}
        className={classNames(classes.message, {
          [classes.messageIsSearching]: isSearching
        })}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            pointerEvents: isSearching ? "none" : undefined,
            flex: 1
          }}
        >
          <img
            style={portraitStyle}
            src={
              makeImageUrlWithSize(message.user, 'xs') ||
              "https://digitalartsny.com/digitalartsny/wp-content/uploads/2013/05/generic-face.png"
            }
            onError={onImageError}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: 10 }}>
              <span style={{ marginRight: 10 }}>
                <b>
                  {message.user
                    ? message.user.name
                    : `[${localization.get("deleted")}]`}
                </b>
              </span>
              <span style={{ fontSize: 12, color: "#868686", marginRight: 10 }}>
                {message.created_at.format("h:mm A")}
              </span>
              {this.showApprovedMessage() && (
                <span
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: "4px 12px 4px 4px"
                  }}
                >
                  <Done
                    style={{ position: "relative", color: "green", top: 7 }}
                  />
                  {localization.get("online_videos.approved")}
                </span>
              )}
            </div>
            <MessageContent message={message} />
          </div>
        </div>
        <MessageOptions
          show={this.state.isHover}
          onlineVideosPath={onlineVideosPath}
          onlineVideos={onlineVideos}
          clients={clients}
          message={message}
          pitchId={pitchId}
          onDelete={this.props.onDelete}
          isAdministrator={this.props.isAdministrator}
        />
      </div>
    );
  };
}

const urlify = (text) => {
  let urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return `<a href="${url}" target="_blank">${url}</a>`;
  })
}

const MessageContent = ({ message }) => {
  if (message.type.startsWith("material")) {
    const materialMessage = getMessageFromMaterial(message);
    const materialContent = getContentFromMaterial(message);
    const materialType = getMaterialType(message);

    let material;

    switch (materialType) {
      case "video":
        material = <VideoMessage message={message} />;
        break;
      case "image":
        material = <ImageMessage message={message} />;
        break;
      case "audio":
        material = <AudioMessage audioSrc={materialContent} />;
        break;
      case "other":
        material = <OtherMessage src={materialContent} />;
        break;
      case "url":
        let url = materialContent;
        if (!url.startsWith("http")) {
          url = "http://" + url;
        }
        material = (
          <a href={url} target="_blank">
            {materialContent}
          </a>
        );
        break;
      default:
        material = null;
    }

    if (materialMessage) {
      return (
        <React.Fragment>
          <div>{materialMessage}</div>
          {material}
        </React.Fragment>
      );
    } else {
      return material;
    }
  }

  return (
    <div>
      {message.content.split("\n").map((line, i) => (
        <div style={{ wordBreak: "break-all" }} key={i} dangerouslySetInnerHTML={{ __html: urlify(line) }}/>
      ))}
    </div>
  );
};

export default withStyles({
  message: {
    width: "100%",
    marginBottom: 20
  },
  messageIsSearching: {
    borderRadius: 10,
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white"
    }
  }
})(Message);
