import {
  Button, Dialog,
  DialogTitle, TextField
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React from 'react';
import localization from "../../../config/localization";

class AddressDialog extends React.Component {

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
  }

  validate = values => {
    const errors = {};

    if (!values.location) {
      errors.location = localization.get("validator.is_required");
    }
    if (!values.priceUsd || values.priceUsd < 0) {
      errors.priceUsd = localization.get("validator.is_required");
    }
    if (!values.priceArs || values.priceUsd < 0) {
      errors.priceArs = localization.get("validator.is_required");
    }
    return errors;
  };

  initialValues = () => {
    let shipment = this.props.shipment;
    let initialValues = {
      id: null,
      location: '',
      priceUsd: '',
      priceArs: '',
      temporaryId: null,
    }
    if (shipment) {
      initialValues = {
        id: shipment.id,
        temporaryId: shipment.temporaryId,
        location: shipment.location,
        priceUsd: shipment.prices.find(prices => prices.currency === "USD").price,
        priceArs: shipment.prices.find(prices => prices.currency === "ARS").price,
      };
    }
    return initialValues;
  }
  onSubmit= (values) => {
    this.props.onSubmit(values);
  }

  render = () => (
    <Dialog
      disableEnforceFocus
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth='sm'
    >
      <DialogTitle>
        <span>
          {this.props.shipment ? localization.get("form.shipment.update") : localization.get("form.shipment.new")}
        </span>
      </DialogTitle>
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          this.onSubmit(values)
          actions.resetForm();
        }}
      >
        {formikProps => (
          <div>
            <div style={{float: "left", position: "relative", width: "100%", display: 'flex', flexDirection: "column", padding: "0 40px", }}>
              <div style={{position: "relative", width: "100%", display: 'flex', flexDirection: "column"}}>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 20, width: "100%"}}
                    value={formikProps.values.location}
                    label={localization.get("form.shipment.name")}
                    onChange={e => formikProps.setFieldValue('location', e.target.value)}
                  />
                  <ErrorMessage name="name" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 20, width: "100%"}}
                    value={formikProps.values.priceUsd}
                    label={localization.get("form.shipment.priceUsd")}
                    type={"number"}
                    onChange={e => formikProps.setFieldValue('priceUsd', e.target.value)}
                  />
                  <ErrorMessage name="shipment" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 20, width: "100%"}}
                    value={formikProps.values.priceArs}
                    type={"number"}
                    label={localization.get("form.shipment.priceArs")}
                    onChange={e => formikProps.setFieldValue('priceArs', e.target.value)}
                  />
                  <ErrorMessage name="google_maps_link" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <div style={{margin: '10px 25px 20px', float: 'right'}}>
              <Button onClick={() => this.handleCloseDialog()} color='default'>{localization.get("cancel")}</Button>
              <Button onClick={() => formikProps.handleSubmit()} color='primary'>{localization.get("form.confirm")}</Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddressDialog;
