import React, {Component} from 'react';
import FormView from './FormView'
import {FormViewContainer} from '../../../components/Letflow/ViewContainer';
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {userisMultiClient} from "../../../api-client/core/authentication/utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

class FormContainer extends Component {

  state = {
    translations: AvailableLanguages.map(language => ({
      language: language,
      name: "",
    })),
    tagGroupType: '',
    tagGroupId: '',
    selectedTagGroup: "",
    order: '',
    showOnSmartSearch: true,
    types: [
      {value: 'music', label: localization.get('music')},
    ],
    childrens: [], 
    parents: [],
  }

  componentDidMount = () => {
      if (this.props.formType === 'create') {
        this.requestContentCreatorTypes()
      }
  }

  requestContentCreatorTypes = () => {
    if (!this.props.isMusic) {
      let  pagination = {orderBy: {column: "name", type: "ASC"},
        filterBy: [
          userisMultiClient() && {column: "visibility", filter: 'private'},
          {column: "is_talent", filter: this.props.isTalent ? 1 : 0},
          {column: "for_create", filter: true },
          {column: "dashboard_combos", filter: true }
        ]
      }

      if (this.props.isEditorial) pagination = { ...pagination, filterBy: pagination.filterBy.concat([{ column: 'type', filter: this.props.isEditorial ? "editorial" : "audiovisual"}])}

      return request.contentCreatorType.getAll(pagination)
        .then(contentCreatorTypes => {
          let types = contentCreatorTypes.map(contentCreatorType => ({value: contentCreatorType.slug, label: contentCreatorType.name}))
          this.setState({types})
        })
    } else {
      return new Promise(resolve => {
        resolve()
      })
    }
  }

  setTag = tag => {
    const translations = AvailableLanguages.map(language => {
      const translation = tag.translations.find(tr => tr.locale ===language)

      return {
        language: language,
        name: translation ? translation.name : "",
      };
    })

    let tagGroupType = ''

    this.requestContentCreatorTypes()
      .then(() => {
        this.state.types.forEach(type => {
          if (type.value === tag.group.types[0].type){
            tagGroupType = type
          }
        })
      })
      .then(() => {
        this.setState({
          translations: translations,
          selectedTagGroup: {value: tag.group.id, label: tag.group.name, types: tag.group.types, hasChildrens: tag.group.childrens.length > 0},
          tagGroupType: tagGroupType,
          order: tag.order,
          showOnSmartSearch: tag.show_on_smart_search,
          childrens: tag.childrens.map(t => ({value: t.id, label: t.name})),
          parents: tag.parents.map(t => ({value: t.id, label: t.name})),
        })
      })
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  submitRequest = () => this.props.submitRequest(this.state)

  altSubmitRequest = () => {
    return this.props.submitRequest({...this.state, altSubmit: true})
      .then(() => this.setState({
        ...this.state,
        translations: AvailableLanguages.map(language => ({
          language: language,
          name: "",
        })),
        order:'',
        showOnSmartSearch: true,
      }))
  }

  altSubmitValidation = () => {
    if (!this.state.translations.every(tr => tr.name)) {
      GlobalSnackbar.show({message: localization.get('form.missing_required_translation'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }

  render = () => {

    const { translations, tagGroupId, tagGroupType, order, types, showOnSmartSearch, childrens, parents } = this.state

    const { disabled, disableTagGroups } = this.props

    return (
      <FormViewContainer
        title={this.props.title}
        submitRequest={this.submitRequest}
        altSubmitRequest={this.altSubmitRequest}
        altSubmitValidation={this.altSubmitValidation}
        validation={this.altSubmitValidation}
        altSubmit={this.props.altSubmit}
        onBack={() => this.props.history.goBack()}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
        hasTranslatableInputs
      >
        <FormView
          ref={instance => this.child = instance}
          disabled={disabled}
          translations={translations}
          onChangeTranslation={(value, target, language) => {
            const translations = this.state.translations.map(tr => {
              if (tr.language === language) {
                return {...tr, [target]: value}
              }
              return tr
            });

            this.setState({ translations });
          }}
          tagGroupType={tagGroupType}
          tagGroupId={tagGroupId}
          onChange={this.onChange}
          selectedTagGroup={this.state.selectedTagGroup}
          disableTagGroups={disableTagGroups}
          onTagGroupChange={selectedTagGroup => this.setState({selectedTagGroup})}
          onTagCategoryChange={tagGroupType => this.setState({tagGroupType: '', selectedTagGroup: ''}, () => this.setState({tagGroupType}))}
          order={order}
          types={types}
          showOnSmartSearch={showOnSmartSearch}
          onShowOnSmartSearchChange={() => this.setState({showOnSmartSearch: !showOnSmartSearch})}
          childrens={childrens}
          parents={parents}
        />
      </FormViewContainer>
    )
  }
}

export default FormContainer
