import {get} from '../utils'
import {includeQuery} from './utils';
import {isNullOrUndefined} from "util";
import {getActiveChannel} from "../authentication/utils";
import { getDateInt } from '../../../utils';

export default host => (clientId, albumId, include = includeQuery, orderBy = 'order', sortType = 'asc') => {
  if (isNullOrUndefined(orderBy)) orderBy = 'order'
  if (isNullOrUndefined(sortType)) sortType = 'asc'

  let order = `&order_by=${orderBy}&sort_type=${sortType}`
  if(isNullOrUndefined(orderBy) || isNullOrUndefined(sortType)) {
    order = `&seed=${getDateInt()}`;
  }

  return get(`${host}/clients/${clientId}/${getActiveChannel() ? `channels/${getActiveChannel()}/` : ``}musics?album_id=${albumId}&${include};favorite${order}`)
    .then(response => response.json())
    .then(json => json.data)
}

