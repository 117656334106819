import React, {Component} from 'react'
import {MoreHoriz} from '@material-ui/icons'
import {Menu, MenuItem} from '@material-ui/core'
import SimpleButton from '../utils/SimpleButton';
import zIndex from '../utils/zIndex';

class Options extends Component {

  state = {
    showMenu: false,
    menuAnchor: null,
  }

  render = () => {

    const { options, color } = this.props
    const { showMenu, menuAnchor } = this.state

    return (
      <div style={{ alignSelf: 'center', display:'inline-block' }}>
        <SimpleButton onClick={this.handleClick}><MoreHoriz style={{color: color || "black"}} /></SimpleButton>
        <CustomMenu
          showMenu={showMenu}
          options={options}
          onClose={() => this.setState({ showMenu: false })}
          menuAnchor={menuAnchor}
          onOptionClick={this.handleOptionClick}
        />
      </div>
    )
  }

  handleClick = e => this.setState({ showMenu: true, menuAnchor: e.target })

  closeMenu = () => this.setState({ showMenu: false })

  handleOptionClick = option => {
    option.onClick()
    this.setState({ showMenu: false })
  }
}

const CustomMenu = ({ showMenu, onClose, menuAnchor, options, onOptionClick }) => {
  return (
    <Menu style={{ zIndex: zIndex(2) }} open={showMenu} onClose={onClose} anchorEl={menuAnchor} disableEnforceFocus>
      {options.map(
        (option, i) => {
          if (option.builder) {
            return option.builder(i);
          }
          return <MenuItem key={i} onClick={() => onOptionClick(option)}>{option.label}</MenuItem>
        }
      )}
    </Menu>
  )
}

export default Options
