import React, { Component } from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import { AllViewContainer } from '../../../components/Letflow/ViewContainer';
import { request } from "../../../api-client";
import localization from "../../../config/localization";
import ClientSidePaginationTable from "../../../components/Letflow/Table/ClientSidePaginationTable";
import {setPageTitle} from "../../../api-client/core/authentication/utils";

export default class extends Component {

  state = {
    catalogs: []
  };

  componentDidMount = () => {
    setPageTitle(localization.get('title.catalogs_ranking'));
    this.requestCatalogs()
  };

  requestCatalogs = () => {
    return request.catalog.getRanking()
      .then(catalogs => catalogs.filter(catalog => catalog.score > 0))
      .then((catalogs) => {
         catalogs.forEach((catalog, index) => {
          catalog.position = index + 1
        })
        return catalogs
      })
      .then(catalogs => this.setState({catalogs}))
  };

  columns = () => [
    {
      Header: localization.get('table.position'),
      accessor: 'position',
      width: 100,
      style: {
        textAlign: 'center',
      },
      filterable: false,
    },
    {
      id: "name",
      Header: localization.get("catalogs"),
      accessor: 'name',
      sortable: false,
      filterable: true,
    },
    {
      Header: localization.get("table.visibility"),
      accessor: "visibility",
      sortable: false,
      filterable: false,
    },
    {
      Header: localization.get('table.points'),
      id: 'score',
      accessor: item => item.score || 0,
      filterable: false,
      width: 150,
      style: {
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
    }
    ];


  render = () => {

    return (<AllViewContainer ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ClientSidePaginationTable
                style={{width: "100%"}}
                perPage={12}
                data={this.state.catalogs}
                columns={this.columns()}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>);
  }
}
