import {addPutMethodToForm, post} from '../utils'

let makeViewTypeFormData = ({viewType, viewTypeMobile='grid'}) => {
  const form = new FormData();
  if(viewType) form.append('view_type', viewType);
  if(viewTypeMobile) form.append('view_type_mobile', viewTypeMobile);
  return form;
} 

export default host => ({
  id,
  viewType,
  viewTypeMobile='grid',
}) => post(`${host}/contentCreators/${id}`, addPutMethodToForm(makeViewTypeFormData({
    viewType,
    viewTypeMobile,
})))
  .then(response => response.json())
  .then(json => json.data)
