import React, {Component} from "react";
import {get} from 'lodash';
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

const updateTagGroups = (tagGroups, selectedTags) => {
  return tagGroups.map(tagGroup => ({
    ...tagGroup,
    tags: tagGroup.tags.map(tag => ({
      ...tag,
      state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
    }))
  }))
}

export default class extends Component {

  state = {
    image: { src: null, file: null },
    clients: [],
    selectedClients: [],
    hideInHome: false,
    featured: false,
    musics: [],
    selectedMusics: [],
    removedMusics: [],
    disabled: false,
    artist: '',
    type: '',
    imageId: null,
    selectedCatalog: '',
    showTagSelection: false,
    hasNextStep: true,
    selectedTags: [],
    tagGroups: [],
    disableForm: false,
    unsplashImage: '',
    imageRadio: 'filepond',
    loaded: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: '',
      subtitle: '',
      shortDescription: '',
    })),
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevState.selectedCatalog !== this.state.selectedCatalog) {
      this.requestTagGroups(get(this.state.selectedCatalog, 'createdBy.id', null))
    }
  }

  requestTagGroups = (clientId) => request.tagGroup.getAllOfTypeMusic()
    .then(tags => this.setTagGroups(tags))

  setTagGroups = tagGroups => this.setState(state => ({...state, tagGroups}), () => this.setState({loaded: true}))

  requestAlbumCreation = () => {

    let {
      translations,
      selectedClients,
      hideInHome,
      featured,
      selectedMusics,
      artist,
      type,
      selectedCatalog,
      selectedTags,
      imageId,
      unsplashImage,
    } = this.state


    const data = {
      translations,
      imageId: imageId,
      selectedClients: selectedClients.map(x => x.value),
      hideInHome,
      featured,
      selectedMusics: selectedMusics.map(x => x.id),
      artistId: artist ? artist.value : '',
      type,
      selectedCatalog: selectedCatalog && selectedCatalog.value,
      tagsIds: selectedTags,
      unsplashImage,
    }

    return request.album.create(data)
      .then(_ => this.props.history.push(makeUrlWithTableParams('/panel/albums')))
  }

  checkTagGroupsSelectedTags = (tagGroups) => {
    let count = 0;
    tagGroups.forEach(tagGroup => {
      if (tagGroup.tags.find(tag => tag.state === 'selected'))
        count++
    });
    return count === tagGroups.length
  };

  onSubmitValidation = () => {
    if (!this.state.selectedCatalog) {
      GlobalSnackbar.show({message: localization.get('album.catalog_required'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (!this.checkTagGroupsSelectedTags(this.state.tagGroups) && this.state.selectedMusics.length !== 0) {
      GlobalSnackbar.show({message: localization.get('validation.must_have_tags'), type: GlobalSnackbarTypes.ERROR})
      return false
    } else {
      return true
    }
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})

  onSetImageFromUnsplash = (url, name) => this.setState({unsplashImage: {url, name}})

  onImageRadioChange = (e) => {
    this.setState({imageRadio: e.target.value})
      if (e.target.value === 'unsplash') {
        this.setState({imageId: '', image: {src: null, filename: null }})
      } else {
        this.setState({unsplashImage: ''})
      }
  }

  onSelectedTagsChanged = selectedTags => this.setState({
    selectedTags,
    tagGroups: updateTagGroups(this.state.tagGroups, selectedTags)
  })

  countSelectedMusicsTags = () => {
    let tags = []
    this.state.selectedMusics.forEach(selectedMusic => {
      selectedMusic.tags.forEach(selectedMusicTag => {
        let index = tags.findIndex(tag => tag.id.toString() === selectedMusicTag.id.toString())
        if (index !== -1){
          tags[index].count++
        } else {
          tags.push({...selectedMusicTag, count: 1})
        }
      })
    })
    return tags
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  mapMusicTagsToSelectedTags = () => {
    let countedTags = this.countSelectedMusicsTags()
    let tags = []

    this.state.tagGroups.forEach(tagGroup => {
      let selectedTags = []
      let fallbackSelectedTags = []
      tagGroup.tags.forEach(tag => {
        countedTags.forEach(countedTag => {
          if (countedTag.id === tag.id && countedTag.count > 2) {
            selectedTags.push(countedTag.id)
          } else if (countedTag.id === tag.id && countedTag.count === 2) {
            fallbackSelectedTags.push(countedTag.id)
          }
        })
      })
      if (selectedTags.length !== 0) {
        tags.push(...selectedTags)
      } else {
        tags.push(...fallbackSelectedTags)
      }

    })
    return tags
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  render = () => {

    const hideInHomeProps = {
      hideInHome: this.state.hideInHome,
      onHideInHomeChange: hideInHome => {
        this.setState({ hideInHome })
      }
    }

    const featuredProps = {
      featured: this.state.featured,
      onFeaturedChange: featured => {
        this.setState({ featured })
      }
    }

    const musicSelectionProps = {
      selectedMusics: this.state.selectedMusics,
      onSelectedItemsChanged: items => this.setState({ selectedMusics: items }),
      removedMusics: this.state.removedMusics,
      onRemovedItemsChanged: items => this.setState({ removedMusics: items }),
    }

    return (
      <div>
        <FormViewContainer
          title={localization.get('view.album.create')}
          submitRequest={this.requestAlbumCreation}
          onBack={() => this.state.hasNextStep ? this.props.history.goBack() : this.setState({showTagSelection: false, hasNextStep:true})}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          initializationRequest={() => this.requestTagGroups()}
          validation={this.onSubmitValidation}
          disabled={this.state.disableForm}
          hasNextStep={this.state.hasNextStep}
          onNextStep={() => this.setState({showTagSelection: true, hasNextStep: false, selectedTags: this.mapMusicTagsToSelectedTags()},
            () => {
              if (this.state.selectedTags.length === 0) {
                GlobalSnackbar.show({message: localization.get('tags.suggestions_not_found'), type: GlobalSnackbarTypes.ERROR})
              } else {
                this.setState({tagGroups: updateTagGroups(this.state.tagGroups, this.state.selectedTags)})
              }
            })}
        >
          {this.state.loaded &&
          <FormContent
            {...hideInHomeProps}
            {...featuredProps}
            {...musicSelectionProps}
            formType="create"
            disabled={this.state.disabled}
            translations={this.state.translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({translations});
            }}
            image={this.state.image}
            onChange={this.onChange}
            onArtistChange={(artist) => this.setState({ artist })}
            onImageChange={this.onImageChange}
            artist={this.state.artist}
            type={this.state.type}
            onTypeChange={type => this.setState({type: type.target.value, artist: null})}
            selectedCatalog={this.state.selectedCatalog}
            onCatalogChange={selectedCatalog => this.setState({selectedCatalog})}
            showTagSelection={this.state.showTagSelection}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            tagGroups={this.state.tagGroups}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            imageRadio={this.state.imageRadio}
            onSetImageFromUnsplash={this.onSetImageFromUnsplash}
            onImageRadioChange={this.onImageRadioChange}
          />
          }
        </FormViewContainer>
      </div>
    )
  }
}
