import create from './create'
import del from './delete'
import get from './get'
import download from './download'
import update from './update'
import downloadDemo from './downloadDemo'
import downloadAiff from "./downloadAiff";
import audioUrl from './audioUrl'

export default host => ({
  audioUrl: audioUrl(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  download: download(host),
  downloadAiff: downloadAiff(host),
  downloadDemo: downloadDemo(host),
  update: update(host),
})
