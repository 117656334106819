import React from 'react'
import {ScaleLoader} from "react-spinners";

const smallSpinnerStyle = {
  display: 'flex',
  justifyContent: 'center'
}

const bigSpinnerStyle = {
  minHeight: 'calc(100vh - 300px)',
  display: 'flex',
  justifyContent: 'center'
}

const Spinner = ({ size }) => <div
  className='sweet-loading'
  style={size === 'small' ? smallSpinnerStyle : bigSpinnerStyle}>
  <div style={{ margin: 'auto' }}>
    <ScaleLoader
      color={'darkgray'}
      loading={true}
    />
  </div>
</div>

export const SmallSpinner = () => <Spinner size='small' />

export const BigSpinner = () => <Spinner />
