import React, {Component} from "react";
import HomeElementCarousel from "../../../layouts/Home/HomeElements/HomeElementCarousel";
import localization from "../../../config/localization";
import styled from "styled-components";
import {get} from "lodash";
import {getStoredChannel} from "../../../api-client/core/authentication/utils"

export default class RelatedContent extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  render = () => {
    let related = this.props.related;
    let relatedData = [related];

    if (related.length > 10 && !!this.props.contentCreator.suggestedMultipleRows) {
      const middleIndex = Math.ceil(related.length / 2)
      const part1 = related.slice(0, middleIndex);
      const part2 = related.slice(-middleIndex);
      relatedData = [part1, part2]
    }

    return (
      <RelatedContainer
        color={get(this.props.contentCreator,'preferences.card.color', "rgba(255, 255, 255, 0.4)")}
        shape={get(this.props.contentCreator, 'preferences.card.shape', "rounded")}>
        <h4 style={{
          fontWeight: 'bold',
          color: get(this.props.contentCreator, 'preferences.creation.color', "#000")
        }}>{get(getStoredChannel(), "suggested_text",localization.get('related'))}</h4>
        <React.Fragment>
          {relatedData.map((col, index) =>
            <span key={index}>
              <HomeElementCarousel
                {...this.props}
                homeElement={makeHomeElementProp(col, ["news_up", "news_down"].includes(this.props.contentCreator.suggestedView))}
                dataType={'content_creator'}
                onlyImage={this.props.contentCreator.suggestedView === "16:9"}
                numbered={false}
                useFeaturedFormat={["news_up", "news_down"].includes(this.props.contentCreator.suggestedView)}
                news={["news_up", "news_down"].includes(this.props.contentCreator.suggestedView)}
                textPosition={["news_up", "news_down"].includes(this.props.contentCreator.suggestedView) ? (this.props.contentCreator.suggestedView === 'news_down'?  'inverted' : 'default') : null}
                shape={["news_up", "news_down"].includes(this.props.contentCreator.suggestedView) ? "x4" : this.props.contentCreator.suggestedView}
                columns={3}
                suggestedCarousel
                realated={true}
                hasMargin={["news_up", "news_down"].includes(this.props.contentCreator.suggestedView)}
              />
            </span>
          )}
            </React.Fragment>
      </RelatedContainer>)
  }
}

const makeHomeElementProp = (contentCreators, isFeatured) => {
  return {
    ...contentCreators,
    type: isFeatured? "featured": "carousel",
    items: {
      data: contentCreators.map(item => ({
        ...item,
        title: item.name,
        shortDescription: item.description,
        kind: "content_creator",
      }))
    }
  }
}
const Container = ({ children }) => <div style={{float: 'left', position: 'relative', width: '100%', backgroundColor: "rgba(255,255,255, 0)",}}>{children}</div>

const RelatedContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
  background-color: ${props => props.color || "rgba(255, 255, 255, 0.4)" };
  margin-top: 30px;
  padding: 20px 40px;
  border-radius: ${ props => props.shape === 'square' ? "0px" : "30px" };
  @media (max-width: 600px) {
    padding: 20px;
  }
`