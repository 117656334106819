import {post} from "../utils";
import {getActiveChannel} from "../authentication/utils";

export default host => (
  name,
  email,
  description,
) =>
  post(
    `${host}/channels/${getActiveChannel()}/contact`,
    makeContactForm({
      name,
      email,
      description,
    })
  ).then(response => response.json());

export const makeContactForm =
  ({
   name,
   email,
   description,
 }) => {
  const form = new FormData();
  if (name) form.append("name", name);
  if (email) form.append("email", email);
  if (description) form.append("description", description);
  return form;
};