import {del, get, post} from '../utils'
import {includeQuery} from "../album/utils";

export default host => {

  const makeForm = data => {

    const {
      title,
      subtitle,
      description,
      image,
      type,
      clientId,
      tagIds,
      visibility,
      userLibrary,
      query,
      catalogId
    } = data

    const form = new FormData()

    if (title) form.append('title', title);
    if (subtitle) form.append('subtitle', subtitle);
    if (description) form.append('description', description);
    if (image) form.append('image', image, image.title);
    if (type) form.append('type', type);
    if (clientId) form.append('client_id', clientId);
    if (Array.isArray(tagIds)) tagIds.forEach(tid => form.append('tags_ids[]', tid));
    form.append('visibility', visibility ? 'public' : 'private');
    form.append('user_library', userLibrary ? 1 : 0);
    if (query) form.append('query', query);
    if (catalogId) form.append('catalog_id', catalogId);

    return form
  }

  const makePutForm = data => {
    const form = makeForm(data)
    form.append('_method', 'PUT')
    return form
  }

  return {

    getAll: () => get(`${host}/savedQueries?include=image`).then(result => result.json()).then(json => json.data),

    getAllForClient: clientId => get(`${host}/clients/${clientId}/savedQueries?include=tags;image;catalog`).then(result => result.json()).then(json => json.data),

    get: savedQueryId => get(`${host}/savedQueries/${savedQueryId}?include=tags;image;featuredImage.image;catalog`).then(result => result.json()).then(json => json.data),

    create: data => post(`${host}/savedQueries`, makeForm(data)),

    update: (savedQueryId, data) => post(`${host}/savedQueries/${savedQueryId}`, makePutForm(data)),

    delete: savedQueryId => del(`${host}/savedQueries/${savedQueryId}`),

    searchFor: query => get(`${host}/savedQueries?q=${query}`).then(response => response.json()).then(json => json.data),

    searchForClient: (clientId, query, include = includeQuery) => get(`${host}/clients/${clientId}/savedQueries?${include}&q=${query}`).then(response => response.json()).then(json => json.data)
  }
}
