import {addPutMethodToForm, post, put} from '../utils'
import {makeFormData, makeJsonData} from './utils'

export default host => ({
    id,
    translations,
    imageId,
    visibility,
    selectedClients,
    hideInHome,
    featured,
    selectedMusics,
    artistId,
    type,
    createdAt,
    selectedCatalog,
    tagsIds,
    unsplashImage,
    mirrorTags
  }) => {
  return put(`${host}/albums/${id}`, makeJsonData({selectedMusics}))
    .then(() => post(`${host}/albums/${id}`, addPutMethodToForm(makeFormData({
    translations,
    imageId,
    visibility,
    selectedClients,
    hideInHome,
    featured,
    artistId,
    type,
    createdAt,
    selectedCatalog,
    tagsIds,
    unsplashImage,
    mirrorTags
  }))))
    .then(response => response.json())
}


