export const makeFormData = ({
  title,
  reference,
  url,
  phone,
  description,
  duration,
  territory,
  budget,
  state,
  clientId,
}) => {
  const form = new FormData()
  if (title) form.append('title', title)
  if (reference) form.append('reference', reference)
  if (url) form.append('url', url)
  if (phone) form.append('phone', phone)
  if (description) form.append('description', description)
  if (duration) form.append('duration', duration)
  if (territory) form.append('territory', territory)
  if (budget) form.append('budget', budget)
  if (state) form.append('state', state)
  if (clientId) form.append('client_id', clientId)
  return form
}