import {post} from "../utils";
import {makeFormData, root} from "./utils";

export default host => (
  {
    name,
    email,
    phone,
    translations,
    imageFile,
    selectedTags,
    isDemo,
    birthdate,
    active,
    socialLinks,
    countryId,
    observation,
    selectedCatalog
  },
  {path = root} = {}
) => {
  return post(
    `${host}${path}`,
    makeFormData({
      name,
      email,
      phone,
      translations,
      imageFile,
      selectedTags,
      isDemo,
      birthdate,
      active,
      socialLinks,
      countryId,
      observation,
      selectedCatalog
    })
  ).then(response => response.json());
};
