import React, {Component} from "react";
import {request} from "api-client";
import localization from '../../../../config/localization'
import FormHandler from "../Form/FormHandler";
import analyticsInstance from "../../../../analytics/analyticsInstance";
import {makeUrlWithTableParams} from "../../../../utils";


export default class extends Component {

  requestCreation = (data) => {
    return request.playlist.create(data)
      .then(() => {
        // analyticsInstance.createPlaylist(data.titleEs)
        this.props.history.push(makeUrlWithTableParams('/panel/lists/channels'))
      })
  }

  render = () => {
    return <FormHandler {...this.props} submitRequest={this.requestCreation} formTitle={localization.get('home_playlist.create')} />;
  };
}
