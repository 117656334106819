import React, {Component} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@material-ui/core'
import {Add, ArrowDownward, ArrowUpward, Delete, People} from '@material-ui/icons'
import _ from 'lodash'
import localization from '../../../config/localization';
import {getActiveClient, getStoredUser, ROLES} from '../../../api-client/core/authentication/utils';
import AsyncSelect from 'react-select/lib/Async'
import {request} from '../../../api-client';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../../components/Letflow/Snackbar/GlobalSnackbar';
import Logo from '../../../assets/img/logo.png'
import {validateEmail} from '../../../utils';
import Tooltip from "@material-ui/core/Tooltip";

class ParticipantsButton extends Component {

  constructor(props) {
    super(props);

    this.state = {

      // add participant
      showDialog: false,
      addingParticipant: false,
      participantToBeAdded: null,
      participantToBeAddedIsAdmin: false,
      addParticipantByEmail: false,
      emailOfParticipantTobeAdded: '',
      clients: [],
      users: [],
      selectedClients: this.props.clients ? this.props.clients.map(client => ({value: client.id, label: client.name})) : [],
      selectedUsers: [],
      participants: this.props.participants || [],

      // delete participant
      showDeleteDialog: false,
      participantToBeRemoved: null,

      // promote participant
      showPromotionDialog: false,
      participantToBePromoted: null,
      promotionType: 'promote', // or 'demote'

      // component
      submitting: false,
    };

    if (!props.clientId) {
      this.state.participantToBeAddedIsAdmin = true
    }
  }

  requestPitch = () =>
     request.pitch.show(this.props.pitchId)
      .then(res => this.setState({
        participants: res.participants,
        selectedClients: res.clients.map(client => ({value: client.id, label: client.name}))
      }));

  flowlikeUsers = () => this.state.participants.filter(this.participantIsFlowlikeAdmin);

  adminUsers = () => this.state.participants.filter(x => !this.participantIsFlowlikeAdmin(x) && x.pivot.is_admin === 1);

  commonUsers = () => this.state.participants.filter(x => x.pivot.is_admin === 0);

  participantIsFlowlikeAdmin = participant => participant.role_id === ROLES.SUPERVISOR || participant.role_id === ROLES.SYS_ADMIN;

  myUserIsAdminInPitch = () => this.participantIsAdmin(getStoredUser());

  participantIsAdmin = participant => {
    const user = this.state.participants.find(x => x.id === participant.id);
    return user !== undefined && user.pivot.is_admin === 1
  };

  renderParticipantGroup = (groupName, users) =>
    <div style={{ marginBottom: 10 }}>
      <div><b>{groupName}</b></div>
      {users.length === 0 && '-'}
      {users.map(this.renderParticipant)}
    </div>

  renderParticipant = user => {

    const isSelf = user.id === getStoredUser().id;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={user.id}>
        <span>{user.name}</span>
        <div>
          {!this.participantIsFlowlikeAdmin(user) &&
            <IconButton onClick={() => {
              this.setState({
                showPromotionDialog: true,
                participantToBePromoted: user,
                promotionType: this.participantIsAdmin(user) ? 'demote' : 'promote'
              })
            }} disabled={isSelf || !this.myUserIsAdminInPitch} >
              {this.participantIsAdmin(user) ? <ArrowDownward /> : <ArrowUpward />}
            </IconButton>}
          <IconButton
            onClick={() => this.setState({ showDeleteDialog: true, participantToBeRemoved: user })}
            disabled={isSelf || !this.myUserIsAdminInPitch}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    )
  };

  render = () =>
    <React.Fragment>
      {/** Participants Button | Opens the participants dialog*/}
        <IconButton
          style={ this.props.small && {width: "35px", height: "35px"}}
          onClick={(e) => {
            e.stopPropagation();
            if (!this.props.participants) {
              this.requestPitch()
                .then(() => this.setState({showDialog: true}))
            } else {
              this.setState({showDialog: true})
            }
          }}
        >
          <Tooltip placement={this.props.tooltip} title={localization.get('participants')}>
            <People />
          </Tooltip>
        </IconButton>
      <this.ParticipantsDialog />
      <this.RemoveParticipantDialog />
      <this.PromoteParticipantDialog />
    </React.Fragment>

  ParticipantsDialog = () =>
    <Dialog open={this.state.showDialog} onBackdropClick={() => this.setState({ showDialog: false })} onEscapeKeyDown={() => this.setState({ showDialog: false })}>
      <DialogTitle style={{ width: 500, maxWidth: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ marginRight: 10 }}>{localization.get('participants')}</span>
          {!this.state.addingParticipant && <IconButton onClick={() => this.setState({ addingParticipant: true })} disabled={this.state.addingParticipant}><Add /></IconButton>}
        </div>
      </DialogTitle>
      <DialogContent style={{height: "67vh"}}>
        {this.state.addingParticipant ? <this.ParticipantsDialogAddingParticipantContent /> : <this.ParticipantsDialogContent />}
      </DialogContent>
      <DialogActions>
        {this.state.addingParticipant ? <this.ParticipantsDialogAddingParticipantActions /> : <this.ParticipantsDialogActions />}
      </DialogActions>

    </Dialog>

  ParticipantsDialogContent = () =>
    <React.Fragment>
      {this.renderParticipantGroup('Flowlike', this.flowlikeUsers())}
      {this.renderParticipantGroup('Administrators', this.adminUsers())}
      {this.renderParticipantGroup('Users', this.commonUsers())}
    </React.Fragment>

  ParticipantsDialogAddingParticipantContent = () =>
    <React.Fragment>
      {this.state.addParticipantByEmail
        ? <p>{localization.get('pitch.add_new_user_by_email_instructions')}</p>
        : <p>{localization.get('pitch.add_new_user_instructions')}</p>}
      <br />
      {/** Add user via email checkbox */}
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', right: 13 }}>
        <Checkbox
          checked={this.state.addParticipantByEmail}
          style={{ color: 'black' }}
          onChange={() => this.setState({ addParticipantByEmail: !this.state.addParticipantByEmail })}
        />
        <span>{localization.get('pitches.add_user_via_email')}</span>
      </div>
      <div style={{ height: 250, display: 'flex', marginTop: 10 }}>
        {/** Select New Participant */}
        <div style={{ flex: 9 }}>
          {this.state.addParticipantByEmail ?
            <this.NewParticipantEmailInput />
            : <this.NewParticipantSelectionInput />
          }
        </div>
        {/** Check if new Participant is Admin */}
        {this.state.clientsIds && !this.state.addParticipantByEmail && (
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', top: 16 }}>
              <Checkbox
                checked={this.state.participantToBeAddedIsAdmin}
                style={{ color: 'black' }}
                onChange={() => this.setState({ participantToBeAddedIsAdmin: !this.state.participantToBeAddedIsAdmin })}
              />
              <span>Admin</span>
            </div>
          </div>)}
      </div>
    </React.Fragment>

  NewParticipantEmailInput = () =>
    <TextField
      fullWidth
      label={localization.get('email')}
      value={this.state.emailOfParticipantTobeAdded}
      onChange={e => this.setState({ emailOfParticipantTobeAdded: e.target.value })}
    />

  NewParticipantSelectionInput = () =>
    <React.Fragment>
      <p style={{ fontSize: 12 }}>{localization.get('clients')}</p>
      <AsyncSelect
        styles={{ menuList: base => ({ ...base, maxHeight: 200 }), singleValue: base => ({ ...base, width: '100%', paddingRight: 10 }) }}
        onChange={selectedClients => this.setState({selectedClients})}
        isMulti
        value={this.state.selectedClients}
        defaultOptions={this.state.clients}
        onMenuOpen={() => this.requestClients().then(clients => this.setState({clients}))}
        loadOptions={this.requestClients}
      />
      <br/>
      <p style={{ fontSize: 12 }}>{localization.get('pitches.add_users')}</p>
      <AsyncSelect
        styles={{ menuList: base => ({ ...base, maxHeight: 200 }), singleValue: base => ({ ...base, width: '100%', paddingRight: 10 }) }}
        defaultOptions={this.state.users}
        onMenuOpen={() => this.requestUsers().then(users => this.setState({users}))}
        isMulti
        value={this.state.selectedUsers}
        onChange={selectedUsers => this.setState({selectedUsers})}
        loadOptions={this.requestUsers}
      />
    </React.Fragment>


  requestClients = (inputValue = '') =>
    request.messaging
      .clientsForNewConversation(inputValue, getActiveClient())
      .then(clients => clients.map(client => ({value: client.id, label: client.name})))


  requestUsers = (inputValue = '') => {
    const requests = []
    const clientsIds = this.state.selectedClients.map(client => client.value)

    if (clientsIds && clientsIds.length > 0) {
      requests.push(request.user.listClientUsers(clientsIds, 1, 30, ['name:' + inputValue]).then(res => res.data))
    }
    requests.push(request.user.listFlowlikeAdmins(1, 30, ['name:' + inputValue]).then(res => res.data))
    return Promise.all(requests)
      .then(userArrays => _.concat(...userArrays).filter(user => !this.state.participants.map(participant => participant.id).concat(this.state.selectedUsers.map(user => user.id)).includes(user.id)))
      .then(users => users.map(user => ({
        value: user.id,
        label: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>{`${user.name} ${(user.clientRoles && user.clientRoles.length > 0) ? `| ${user.clientRoles[0].client.name}` : ''}`}</div>
          {(user.role && [ROLES.SYS_ADMIN, ROLES.SUPERVISOR].includes(user.role.id)) && <img style={{ width: 25, height: 25 }} src={Logo} />}
        </div>
      })))
  }


  ParticipantsDialogActions = () =>
    <React.Fragment>
      <Button onClick={() => this.setState({ showDialog: false })}>
        {localization.get('close')}
      </Button>
    </React.Fragment>

  ParticipantsDialogAddingParticipantActions = () => {

    let sendButtonDisabled;

    if (this.state.submitting) {
      sendButtonDisabled = true
    } else if (this.state.addParticipantByEmail) {
      sendButtonDisabled = !this.state.emailOfParticipantTobeAdded || !validateEmail(this.state.emailOfParticipantTobeAdded)
    } else {
      sendButtonDisabled = !(this.state.selectedUsers && this.state.selectedUsers.length > 0) && !(this.state.selectedClients && this.state.selectedClients.length > 0)
    }

    return (
      <React.Fragment>
        <Button disabled={this.state.submitting} onClick={() =>  this.setState({ addingParticipant: false })}>{localization.get('back')}</Button>
        <Button disabled={sendButtonDisabled} onClick={this.requestAddParticipant}>{localization.get('send')}</Button>
      </React.Fragment>
    )
  };

  RemoveParticipantDialog = () =>
    <Dialog open={this.state.showDeleteDialog} onBackdropClick={() => this.setState({ showDeleteDialog: false })} onEscapeKeyDown={() => this.setState({ showDeleteDialog: false })}>
      <DialogTitle>{localization.get('pitch.remove_participant_title')}</DialogTitle>
      <DialogContent>
        {this.state.participantToBeRemoved && localization.get('pitch.remove_participant_content', this.state.participantToBeRemoved.name)}
      </DialogContent>
      <DialogActions>
        <Button disabled={this.state.submitting} onClick={() => this.setState({ showDeleteDialog: false })}>{localization.get('close')}</Button>
        <Button disabled={this.state.submitting} onClick={this.requestRemoveParticipant}>{localization.get('send')}</Button>
      </DialogActions>
    </Dialog>

  PromoteParticipantDialog = () =>
    <Dialog open={this.state.showPromotionDialog} onBackdropClick={() => this.setState({ showPromotionDialog: false })} onEscapeKeyDown={() => this.setState({ showPromotionDialog: false })}>
      <DialogTitle>
        {localization.get(this.state.promotionType === 'promote' ? 'pitch.promote_participant_title' : 'pitch.demote_participant_title')}
      </DialogTitle>
      <DialogContent>
        {this.state.participantToBePromoted &&
          localization.get(
            this.state.promotionType === 'promote' ? 'pitch.promote_participant_content' : 'pitch.demote_participant_content',
            this.state.participantToBePromoted.name)}
      </DialogContent>
      <DialogActions>
        <Button disabled={this.state.submitting} onClick={() => this.setState({ showPromotionDialog: false })}>
          {localization.get('close')}
        </Button>
        <Button disabled={this.state.submitting} onClick={this.requestPromoteParticipant}>{localization.get('send')}</Button>
      </DialogActions>
    </Dialog>

  requestAddParticipant = () => this.executeRequest(this.getAddParticipantRequest());

  getAddParticipantRequest = () => {
    return this.state.addParticipantByEmail
      ? () => request.user.createProjectInvitee(
        this.state.emailOfParticipantTobeAdded,
        this.props.pitchId,
      )
      : () => {
        const requests = this.state.selectedUsers.map(selectedUser => request.pitch.addParticipant(
          this.props.pitchId,
          selectedUser.value
        )).concat([request.pitch.update({id: this.props.pitchId, clientsIds: this.state.selectedClients.map(client => client.value)})])
        return Promise.all(requests)
      }

  }

  requestRemoveParticipant = () => this.executeRequest(() =>
    request.pitch.removeParticipant(
      this.props.pitchId,
      this.state.participantToBeRemoved.id
    ));

  requestPromoteParticipant = () => this.executeRequest(() => {
    const req = this.state.promotionType === 'promote'
      ? request.pitch.promoteParticipant
      : request.pitch.demoteParticipant
    return req(this.props.pitchId, this.state.participantToBePromoted.id)
  });

  executeRequest = request => {
    this.setState({ submitting: true });
    request()
      .then(this.handleSuccessfulRequest)
      .catch(this.handleFailedRequest)
  };

  handleSuccessfulRequest = () => window.location.reload();

  handleFailedRequest = e => {
    if (e.status === 403) {
      GlobalSnackbar.show({ message: localization.get('pitches.action_for_admin_users'), type: GlobalSnackbarTypes.ERROR })
    } else {
      GlobalSnackbar.showGenericError()
    }
    this.setState({ submitting: false })
  }
}

export default ParticipantsButton
