import {isNullOrUndefined} from "util";
import moment from "moment";

export const makeFormData = ({
  creating = false,
  isEditorial = false,
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  featuredImageId,
  mobileCoverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashFeaturedImage,
  unsplashProfileImage,
  unsplashMobileCoverImage,
  image,
  contactName,
  contactEmail,
  contactDescription,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  batchFeaturedImageId,
  batchMobileCoverImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  deleteDownloadableMaterial,
  batchDownloadableMaterialId,
  mainMaterial,
  viewLimit,
  timeToTrack,
  requireLogin,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  showTags,
  suggestedTagGroup,
  suggestedView,
  selectedCloud,
  hasPreferences,
  useBackgroundColor,
  backgroundColor,
  useBackgroundGradient,
  subtitleFontSize,
  subtitleMargin,
  titleColor,
  descriptionColor,
  creationColor,
  cardColor,
  cardShape,
  creationInverted,
  isEvent,
  eventsPosition,
  buyButtonText,
  buyButtonShowInSocials,
  buyButtonProductId,
  buyButtonPosition,
  buyButtonBackgroundColor,
  buyButtonFontColor,
  allowedRegions,
  hasRegionBlock,
  editorialBgColor,
  editorialTitleColor,
  editorialHideCoverImage,
  hasEditorialPreferences,
  creationCarouselBackgroundColor,
  viewType,
  viewTypeMobile,
  suggestedViewMultipleRow,
  driveProfileImage,
  driveCoverImage,
  driveFeaturedImage,
  driveMobileCoverImage,
  canonicalChannel,
  suggestedContentCreators,
  accessLevel,
  randomProfileImage,
  randomCoverImage,
  additionInfoSize,
  eventButtonText,
  eventButtonColor,
  eventButtonBackgroundColor,
  editorialUseSynopsis,
  ad,
  adsUsage
}) => {
  const form = new FormData();
  if (phone) form.append("phone", phone);
  if (email) form.append("email", email);
  if (countryId) form.append("country_id", countryId);
  if (contentCreatorTypeId) form.append("content_creator_type_id", contentCreatorTypeId);
  if (observation) form.append("observation", observation);
  if (selectedCatalogs) selectedCatalogs.forEach(x => form.append("catalogs_ids[]", x))
  if (weight) form.append("weight", weight);
  if (!isNullOrUndefined(active)) form.append("active", active);
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name);
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
      if (!isNullOrUndefined(translation.additions)) form.append(`translations[${translation.language}][additions]`, translation.additions.substring(0, 400));
      if (!isNullOrUndefined(translation.synopsis)) form.append(`translations[${translation.language}][synopsis]`, translation.synopsis.substring(0, 600));
      if (!isNullOrUndefined(translation.when)) form.append(`translations[${translation.language}][when]`, translation.when.substring(0, 250));
      if (!isNullOrUndefined(translation.entrance)) form.append(`translations[${translation.language}][entrance]`, translation.entrance.substring(0, 250));
    });
  }

  if (socialLinks) {
    socialLinks.forEach(socialLink => {
      if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
    });

    if(socialLinks.length === 0 && !creating) {
      form.append('social_links', "clear_all")
    }
  }

  if (!isNullOrUndefined(selectedTags)) {
    if(selectedTags.length > 0) {
      selectedTags.forEach(x => form.append("tags_ids[]", x));
    }else {
      form.append("clear_all", 1);
    }
  }
  if (image) form.append('image', image, image.name);
  if (selectedContentCreations)
    selectedContentCreations.forEach(x => form.append("content_creations_ids[]", x));
  if (imageId) form.append("imageId", imageId);
  if (coverImageId) form.append("coverImageId", coverImageId);
  if (featuredImageId) form.append("featuredImageId", featuredImageId);
  if (mobileCoverImageId) form.append("mobileCoverImageId", mobileCoverImageId);
  if (!isNullOrUndefined(isDemo)) form.append("is_demo", isDemo ? 1 : 0);
  if (unsplashCoverImage) {
    form.append('cover_image_url', unsplashCoverImage.url)
    form.append('cover_image_name', unsplashCoverImage.name)
  }
  if (unsplashFeaturedImage) {
    form.append('featured_image_url', unsplashFeaturedImage.url)
    form.append('featured_image_name', unsplashFeaturedImage.name)
  }
  if (unsplashProfileImage) {
    form.append('profile_image_url', unsplashProfileImage.url)
    form.append('profile_image_name', unsplashProfileImage.name)
  }
  if (unsplashMobileCoverImage) {
    form.append('mobile_cover_image_url', unsplashMobileCoverImage.url)
    form.append('mobile_cover_image_name', unsplashMobileCoverImage.name)
  }
  if (contactName) form.append("name", contactName);
  if (contactEmail) form.append("email", contactEmail);
  if (contactDescription) form.append("description", contactDescription);
  if (startActivity) form.append("activity_start", moment(startActivity).format(isEditorial ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'));
  if (releaseDate) form.append("release_date", moment.utc(releaseDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (expireDate) form.append("expire_date", moment.utc(expireDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (batchProfileImageId) form.append("batchProfileImageId", batchProfileImageId);
  if (driveProfileImage) form.append("drive_profile_file_id", driveProfileImage);
  if (driveCoverImage) form.append("drive_cover_file_id", driveCoverImage);
  if (driveFeaturedImage) form.append("drive_featured_file_id", driveFeaturedImage);
  if (driveMobileCoverImage) form.append("drive_mobile_cover_file_id", driveMobileCoverImage);
  if (batchCoverImageId) form.append("batchCoverImageId", batchCoverImageId);
  if (batchFeaturedImageId) form.append("batchFeaturedImageId", batchFeaturedImageId);
  if (batchMobileCoverImageId) form.append("batchMobileCoverImageId", batchMobileCoverImageId);
  if (downloadableMaterialId) form.append ("downloadableMaterialId", downloadableMaterialId);
  if (downloadableMaterialFileType) form.append ("downloadableMaterialFileType", downloadableMaterialFileType);
  downloadableMaterialText ? form.append ("downloadableMaterialText", downloadableMaterialText) : form.append ("downloadableMaterialText", "");
  downloadableMaterialLink ? form.append ("downloadableMaterialLink", downloadableMaterialLink) : form.append ("downloadableMaterialLink", "");
  if (!isNullOrUndefined(contentSupervisorId)) form.append("content_supervisor_id", contentSupervisorId);
  if (onlyArgentina) form.append("only_argentina", onlyArgentina === 'true' ? 1 : 0);
  if (deleteDownloadableMaterial) form.append('deleteDownloadableMaterial', deleteDownloadableMaterial)
  if (batchDownloadableMaterialId) form.append("batchDownloadableMaterialId", batchDownloadableMaterialId);
  if(!isNullOrUndefined(mainMaterial)) form.append("main_material", mainMaterial.value || mainMaterial);
  if(!isNullOrUndefined(viewLimit)) form.append("view_limit", viewLimit);
  if(!isNullOrUndefined(timeToTrack)) form.append("time_to_track", timeToTrack);
  if(!isNullOrUndefined(requireLogin)) form.append("require_login", requireLogin);
  if (!isNullOrUndefined(hasSuggestedCarousel)) form.append("has_suggested", hasSuggestedCarousel ? 1 : 0);
  if (!isNullOrUndefined(showDateOnHome)) form.append("show_date_on_home", showDateOnHome ? 1 : 0);
  suggestedCarouselPlaylist ? form.append ("suggested_playlist", suggestedCarouselPlaylist) : form.append ("suggested_playlist", "");
  suggestedTagGroup ? form.append("suggested_tag_group", suggestedTagGroup) : form.append ("suggested_tag_group", "");
  suggestedContentCreators && suggestedContentCreators.length ? form.append("suggested_content_creator", suggestedContentCreators) : form.append ("suggested_content_creator", "");
  if(!isNullOrUndefined(suggestedViewMultipleRow)) form.append('suggested_view_multiple_row', suggestedViewMultipleRow ? 1 : 0);
  if (!isNullOrUndefined(showTags)) form.append("show_tags", showTags ? 1 : 0);
  if (suggestedView) form.append("suggested_view", suggestedView);
  if (selectedCloud) form.append("cloud_id", selectedCloud);
  if(!isNullOrUndefined(hasPreferences)) form.append('has_preferences', hasPreferences ? 1 : 0);
  if (useBackgroundColor && backgroundColor) {
    form.append('background_color', backgroundColor);
  } else {
    form.append('background_color', "none")
  }
  if(!isNullOrUndefined(useBackgroundGradient)) form.append('background_gradient', useBackgroundGradient ? 1 : 0);
  if(subtitleFontSize) form.append('subtitle_font_size', subtitleFontSize);
  if(subtitleMargin) form.append('subtitle_margin', subtitleMargin);
  if(titleColor) form.append('title_color', titleColor);
  if(descriptionColor) form.append('description_color', descriptionColor);
  if(creationColor) form.append('creation_color', creationColor);
  if(cardColor) form.append('card_color', cardColor);
  if(cardShape) form.append('card_shape', cardShape);
  if(creationInverted) form.append('creation_inverted', creationInverted);
  if (!isNullOrUndefined(isEvent)) form.append("is_event", isEvent == "1" ? 1 : 0);
  if (eventsPosition) form.append("event_position", eventsPosition);

  if(!isNullOrUndefined(buyButtonText)) form.append('buy_button_product_text', buyButtonText);
  form.append('buy_button_product_id', buyButtonProductId ? buyButtonProductId : 0);
  if(!isNullOrUndefined(buyButtonShowInSocials)) form.append('buy_button_show_in_socials', buyButtonShowInSocials ? 1 : 0);
  
  if(buyButtonPosition) form.append('buy_button_position', buyButtonPosition);
  if(buyButtonBackgroundColor) form.append('buy_button_bg_color', buyButtonBackgroundColor);
  if(buyButtonFontColor) form.append('buy_button_font_color', buyButtonFontColor);
  if (!isNullOrUndefined(hasRegionBlock)) form.append("region_restricted", hasRegionBlock == "true" ? 1 : 0);
  if(allowedRegions.length > 0) allowedRegions.forEach(x => form.append("available_countries[]", x))

  form.append('editorial_background_color', editorialBgColor || 'rgba(255,255,255,1)');
  form.append('editorial_title_color', editorialTitleColor || 'rgba(0,0,0,1)');
  form.append('editorial_hide_cover_image', editorialHideCoverImage ? "1" : "0");

  if(creationCarouselBackgroundColor) form.append('creation_carousel_background_color', creationCarouselBackgroundColor);
  
  if(!isNullOrUndefined(hasEditorialPreferences)) form.append('has_editorial_preferences', hasEditorialPreferences ? 1 : 0);
  console.log(hasEditorialPreferences, hasPreferences)

  if(viewType) form.append('view_type', viewType);
  if(viewTypeMobile) form.append('view_type_mobile', viewTypeMobile);
  if (canonicalChannel) form.append('main_channel_id', canonicalChannel)
  if (!isNullOrUndefined(accessLevel)) form.append('access_level', accessLevel)
  if (!isNullOrUndefined(randomProfileImage)) form.append('random_profile_image', randomProfileImage ? 1 : 0)
  if (!isNullOrUndefined(randomCoverImage)) form.append('random_cover_image', randomCoverImage ? 1 : 0)
  if (!isNullOrUndefined(additionInfoSize)) form.append('additional_info_size', additionInfoSize)

  if (!isNullOrUndefined(eventButtonText)) form.append('event_button_text', eventButtonText)
  if (!isNullOrUndefined(eventButtonColor)) form.append('event_button_color', eventButtonColor)
  if (!isNullOrUndefined(eventButtonBackgroundColor)) form.append('event_button_background_color', eventButtonBackgroundColor)
  if (!isNullOrUndefined(editorialUseSynopsis)) form.append('editorial_use_synopsis', editorialUseSynopsis ? 1 : 0)
  if (!isNullOrUndefined(ad)) form.append('ad_id', ad)
  if (!isNullOrUndefined(adsUsage)) form.append('ads_usage', adsUsage)

  return form;
};

export const includeQuery = "include=image;contentCreations;type";
