import React, {Component} from 'react'
import {Pause, PlayArrow} from "@material-ui/icons";
import PropTypes from 'prop-types'
import './OriginalMusic.css'

class FeaturedVideo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      hover: false,
    }
  }

  componentDidMount = () => {
    document.addEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }

  componentWillUnmount = () => {
    document.removeEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }


  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
    this.fullScreen()
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  fullScreen = () => {
    this.videoRef.webkitEnterFullScreen()
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()
    }
  }

  toggleVideoState = (toPlayingState, action, event) => {

    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
    this.props[event]()
  }

  render = () => {

    const { videoSrc, title, subtitle, style } = this.props

    return (
      <div className="original-music-video-wrapper" style={style || {}}>
        <div className="original-music-video" onMouseOver={() => this.setState({ hover: true })} onMouseOut={() => this.setState({ hover: false })} onClick={() => {
          if (this.state.playing) {
            this.pause()
          } else {
            this.play()
          }
        }}>
          <video ref={ref => this.videoRef = ref} loop={false} autoPlay={false} controls={false}>
            <source src={videoSrc} />
          </video>
          {this.state.playing ? <div style={{ display: this.state.hover ? 'block' : 'none' }} className="original-music-play-pause-button"><Pause className="original-music-play-pause-icon" /></div> : <div className="original-music-play-pause-button"><PlayArrow className="original-music-play-pause-icon" /></div>}
          <div style={{ display: this.state.playing ? 'none' : 'block' }} className="original-music-video-description">
            <h4>{title}</h4>
            <h6>{subtitle}</h6>
          </div>
        </div>
      </div>
    )
  }
}

FeaturedVideo.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
}

FeaturedVideo.defaultProps = {
  onPause: () => { },
  onPlay: () => { },
}

export default FeaturedVideo
