import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {ServerSidePaginationTable} from "components/Letflow/Table";
import {request} from "api-client";
import localization from "config/localization";
import {FormControl} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';

export default class extends Component {

  state = {
    clients: [],
    clientId: null,
    from: null,
    to: null
  }

  componentDidMount = () => {
    document.title = localization.get('title.licenses_report') + ' - Feater'

    request.client.getWithLicense().then(clients => this.setState({ clients }));
  }

  onChangeClient = (clientId) => this.setState({ clientId }, this.refs.table.forceUpdate)

  onChangeFrom = (from) => this.setState({ from }, this.refs.table.forceUpdate)

  onChangeTo = (to) => this.setState({ to }, this.refs.table.forceUpdate)

  render = () => {
    const { clients, clientId, from, to } = this.state;

    return (<AllViewContainer ref='viewContainer'>
      <div style={{ textAlign: 'center', marginTop: '15px', padding: '10px' }}>
        <FormControl>
          <InputLabel htmlFor="client">{localization.get('form.client')}</InputLabel>
          <Select
            id="client"
            name="client"
            value={clientId}
            onChange={event => this.onChangeClient(event.target.value)}
            style={{ width: 250 }}
            displayEmpty
          >
            <MenuItem value='' defaultValue>{localization.get('all')}</MenuItem>
            {clients.length > 0 && clients.map(client => {
              return <MenuItem value={client.id}>{client.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{ width: 150, marginLeft: 20, marginRight: 20 }}
            label={localization.get('form.from')}
            autoOk
            keyboard
            clearable
            onlyCalendar
            disableFuture
            value={from}
            format={"dd/MM/yyyy"}
            onChange={this.onChangeFrom}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{ width: 150 }}
            label={localization.get('form.to')}
            autoOk
            keyboard
            clearable
            onlyCalendar
            disableFuture
            value={to}
            format={"dd/MM/yyyy"}
            onChange={this.onChangeTo}
          />
        </MuiPickersUtilsProvider>
      </div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ServerSidePaginationTable
                ref="table"
                requestTableData={paginationConfig => request.license.getLicensesReport(paginationConfig, clientId, from, to)}
                columns={[
                  {
                    Header: localization.get('table.client'),
                    accessor: 'client.name',
                    filterable: false,
                    sortable: false
                  },
                  {
                    Header: localization.get('table.user'),
                    accessor: "user.name",
                    Cell: ({ value }) => value || '-',
                    filterable: false,
                    sortable: false
                  },
                  {
                    Header: localization.get('table.role'),
                    accessor: "user.role.description",
                    Cell: ({ value }) => value || '-',
                    filterable: false,
                    sortable: false
                  },
                  {
                    Header: localization.get('table.last_login'),
                    accessor: "user.last_login_at",
                    Cell: ({ value }) => value ? localization.moment(value).fromNow() : localization.get('never'),
                    filterable: false,
                    sortable: false
                  },
                  {
                    Header: localization.get('table.amount'),
                    id: "count",
                    accessor: 'count',
                    filterable: false,
                    sortable: true,
                    width: 100,
                    style: {
                      textAlign: 'right',
                    },
                    headerStyle: {
                      textAlign: 'right',
                    }
                  }
                ]}
                defaultPageSize={20}
                showPaginationTop
                showPaginationBottom={false}
                showPageSizeOptions={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>)
  }
}