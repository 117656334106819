import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TextField from "@material-ui/core/TextField/TextField";


class Upload extends Component {
  state= {
    label: ""
  }
  onFileAdd = (files) => {
    let fileToSave = files[files.length - 1]
    let videoId = null
    let imageId = null
    let audioId = null
    let fileId = null

    switch (fileToSave.type) {
      case 'video':
        videoId = fileToSave.id
        break;
      case 'image':
        imageId = fileToSave.id
        break;
      case 'audio':
        audioId = fileToSave.id
        break;
      case 'other':
        fileId = fileToSave.id
        break;
    }

    const data = {
      filename: fileToSave.name,
      type: fileToSave.type,
      label: this.state.label,
      token: this.props.token || this.props.match.params.token,
      videoId,
      imageId,
      audioId,
      fileId,
    }

    return request.batchUpload.addFile(data)
  }

  onFileDelete = (fileId) => request.batchUpload.deleteFile(fileId)

  render = () => {

    return (
      <Card raised style={{height: "calc(100vh - 122px)", overflow: "scroll", display: !this.props.show && 'none'}}>
        <div style={{ width: "100%", maxWidth: 800, margin: "auto", paddingTop: 30}}>
          <TextField
            autoComplete={false}
            value={this.state.label}
            onChange={e => this.setState({label: e.target.value})}
            placeholder={localization.get('upload.label')}
            helperText={(this.props.hint && this.props.hint.length > 0) ? this.props.hint : localization.get('upload.label_helper')}
            style={{ margin: "20px 0"}}
          />
          <FilePondUploader
            onIdChange={(batchFiles) => this.onFileAdd(batchFiles)}
            isMultiple={true}
            maxFiles={1000}
            batchUpload={true}
            onBatchFileDelete={(fileId) => this.onFileDelete(fileId)}
            clientId={this.props.clientId}
          />
          <p style={{opacity: 0.8, textAlign: "center"}}>{localization.get('batch_upload.delete_disclaimer')}</p>
        </div>
      </Card>
    )
  }
}


export default Upload
