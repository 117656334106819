import React from 'react'
import PropTypes from 'prop-types'
import {ImagePicker} from 'components/Letflow/Form'
import localization from '../../../config/localization';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@material-ui/core";
import {ItemSelectionTables} from "../../../components/Letflow/Table";
import {makeWaveformUrl, secondsToHHMMSS, secondsToMMSS} from "../../../utils";
import {request} from "../../../api-client";
import {TextValidator} from "react-material-ui-form-validator";
import TablePlayButton from '../../../components/Letflow/Table/Buttons/TablePlayButton';
import {SelectValidator} from "../../../components/Letflow/Form";
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import AsyncSelect from "react-select/lib/Async";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelection";
import tempos from "../../../config/tempos";
import volumes from "../../../config/volumes";
import DurationInputRange from "../../../layouts/Home/SmartSearch/DurationInputRange";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

const MusicSelectionTables = ({ onSelectedItemsChanged, selectedMusics, onRemovedItemsChanged }) =>
  <ItemSelectionTables
    columns={[
      { Header: localization.get('table.code'), accessor: 'code' },
      { Header: localization.get('table.title'), accessor: 'title' },
      {
        Header: localization.get('table.catalog'),
        id: 'catalog[name]',
        accessor: music => music.catalog ? music.catalog.name : '',
        Filter: ({ filter, onChange }) =>
          <TableAutocompleteFilter
            fetchItems={filter => request.catalog.getAll({
                filterBy: [
                  {
                    column: 'name',
                    filter
                  },
                ]
              })
              .then(clients => clients.map(client => client.name))
            }
            onChange={onChange}
            filter={filter ? filter.value : ''}
          />
      },
      {
        Header: localization.get('table.duration'),
        id: 'duration',
        accessor: music => secondsToHHMMSS((music.audio && music.audio.duration) || 0)
      },
    ]}
    extraButtonFactories={[music => <TablePlayButton tracks={{
      fetchAudioUrl: () => request.music.get(music.id).then(music => music.audio.url),
      title: music.title,
      waveform: makeWaveformUrl(music)
    }} />]}
    requestPaginatedItems={paginatedConfig => {
      return request.music.getAll(paginatedConfig)
    }}
    selectedItemsChanged={onSelectedItemsChanged}
    removedItemsChanged={onRemovedItemsChanged}
    selectedTableTitle={<h4>{localization.get('form.selected_musics')}</h4>}
    toSelectTableTitle={<h4>{localization.get('form.select_music')}</h4>}
    selected={selectedMusics}
  />

const FormView = ({
  type,
  catalogCategories,
  catalogCategoryId,
  image,
  onChange,
  onImageChange,
  userLibrary,
  onUserLibraryChange,
  selectedMusics,
  onSelectedMusicsChanged,
  disabled = false,
  visibility,
  onVisibilityChange,
  contentType,
  onContentTypeChange,
  onAsyncClientsChange,
  selectedClients,
  onSelectedTagChanged,
  tagGroups,
  duration,
  volume,
  tempo,
  onVolumeChange,
  onTempoChange,
  onDurationChange,
  onRemovedItemsChanged,
  translations,
  onChangeTranslation,
  onBrandsChange,
  brands,
}) => {

  return (
    <div>
      <TranslatableInputs
        children={AvailableLanguages.map(language => {
          const currentLangTranslation = translations.find(tr => tr.language === language);

          return {
            language: language,
            content: [
              <TextValidator
                style={{ width: "100%", marginBottom: 20 }}
                id={`title${language}`}
                label={localization.get('form.title')}
                name={`title${language}`}
                value={currentLangTranslation.title}
                onChange={e => onChangeTranslation(e.target.value, "title", language)}
                disabled={disabled}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />,
              <TextValidator
                style={{ width: "100%" }}
                id={`subtitle${language}`}
                label={localization.get('form.subtitle')}
                name={`subtitle${language}`}
                value={currentLangTranslation.subtitle}
                onChange={e => onChangeTranslation(e.target.value, "subtitle", language)}
                disabled={disabled}
              />
            ]
          }
        })}
      />

      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "#4cc2d5" }}
            checked={userLibrary}
            onChange={() => {
              onUserLibraryChange(!userLibrary)
            }}
          />
        }
        label={localization.get('form.active')}
      />

      <ImagePicker
        buttonText={localization.get('form.select_image')}
        image={image.src}
        onImageChange={onImageChange}
        disabled={disabled}
      />
      <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div>

      { type === 'global' &&
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <FormLabel>{localization.get('form.visibility')}</FormLabel>
          <RadioGroup
            name="visibility"
            value={visibility}
            onChange={onVisibilityChange}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="private" control={<Radio color="default" />}
              label={localization.get('form.visibility.private')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="public"
              control={<Radio color="default" />}
              label={localization.get('form.visibility.public')}
            />
          </RadioGroup>
        </FormControl>
      }


      {type === "global" && visibility === 'private' &&
        <div style={{float: "left", position: "relative", width: "100%", margin: "10px 0px 30px"}}>
          <span>{localization.get('clients')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('clients')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onAsyncClientsChange}
            value={selectedClients}
            isMulti
            defaultOptions
            loadOptions={inputValue =>
              request.client
                .getAll({filterBy: [{column: "name", filter: inputValue}]})
                .then(res => res.map(client => ({value: client.id, label: `${client.name}`})))
            }
          />
          <br/>
          <span>{localization.get('brands')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('brands')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onBrandsChange}
            value={brands}
            isMulti
            defaultOptions
            loadOptions={inputValue =>
              request.brands.getAll({filterBy: [{column: "name", filter: inputValue}]})
                .then(res => res.map(brand => ({value: brand.id, label: brand.name})))
            }
          />
        </div>
      }

      {(type === 'global' && visibility === 'public') &&
        <SelectValidator
          style={{ width: "100%", marginBottom: "30px" }}
          id='catalogCategoryId'
          name="catalogCategoryId"
          current={catalogCategoryId}
          title={localization.get('form.category')}
          onChange={onChange}
          items={catalogCategories}
          disabled={true}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
      }

      {type === "global" &&
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <FormLabel>{localization.get('global_playlist.content_type')}</FormLabel>
          <RadioGroup
            name="contentType"
            value={contentType}
            onChange={onContentTypeChange}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="musics" control={<Radio color="default" />}
              label={localization.get('global_playlist.musics')}
            />
            <FormControlLabel
              style={{ width: "fit-content", float: "left", position: "relative" }}
              value="tags"
              control={<Radio color="default" />}
              label={localization.get('global_playlist.tags')}
            />
            {type === "global" &&
              <FormControlLabel
                style={{width: "fit-content", float: "left", position: "relative"}}
                value="recent"
                control={<Radio color="default"/>}
                label={localization.get('form.recent')}
              />
            }
          </RadioGroup>
        </FormControl>
      }
      {contentType !== "recent" &&
        <>

        {contentType === 'musics' && type === "global" &&
          <MusicSelectionTables selectedMusics={selectedMusics} onRemovedItemsChanged={onRemovedItemsChanged} onSelectedItemsChanged={onSelectedMusicsChanged}/>
        }

        {(contentType === 'tags' || type === "library") &&
          <React.Fragment>
            <div style={{ float: "left", position: "relative", width: "100%", textAlign: "center"}}>
              <TagSelection onSelectedTagsChanged={onSelectedTagChanged} allowUnselected={true} data={tagGroups} />
            </div>

            {type === "global" &&
              <React.Fragment>
                <FormControl fullWidth style={{margin: "10px 0"}}>
                  <InputLabel>{localization.get('smart_search.tempo')}</InputLabel>
                  <Select
                    value={tempo}
                    onChange={onTempoChange}
                  >
                    <MenuItem value={false}>{localization.get(`options.none`)}</MenuItem>
                    {tempos.map(tempo => {
                      return (
                        <MenuItem value={tempo}>{localization.get(`tempo.${tempo.name}`)}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{margin: "10px 0"}}>
                  <InputLabel>{localization.get('smart_search.volume')}</InputLabel>
                  <Select
                    value={volume}
                    onChange={onVolumeChange}
                  >
                    <MenuItem value={false}>{localization.get(`options.none`)}</MenuItem>
                    {volumes.map(volume => {
                      return (
                        <MenuItem value={volume}>{localization.get(`volume.${volume.name}`)}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth style={{margin: "10px 0"}}>
                  <InputLabel>{localization.get('smart_search.duration')}</InputLabel>
                  <Select
                    value={duration ? secondsToMMSS(duration.min) + ' - ' + secondsToMMSS(duration.max) : ''}
                  >
                    <div style={{float: "left", position: "relative", width: "340px", padding: "50px"}}>
                      <DurationInputRange
                        onChangeDuration={onDurationChange}
                        onChangeCompleteDuration={() => {}}
                        value={duration}
                      />
                    </div>
                    <MenuItem style={{display: "none"}} selected value={duration ? secondsToMMSS(duration.min) + ' - ' + secondsToMMSS(duration.max) : null}>
                      {duration ? secondsToMMSS(duration.min) + ' - ' + secondsToMMSS(duration.max) : null}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
            }
          </React.Fragment>
        }
      </>
      }
    </div>
  )
}

FormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disableClientInput: PropTypes.bool,
}

export default FormView
