import React from "react";
import {CardActions, Divider} from "@material-ui/core";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

import _Select from "./Select";
import _SelectValidator from "./SelectValidator";
import _TextField from "./TextField";
import _ImagePicker from "./ImagePicker";
import _VideoPicker from "./VideoPicker";
import _LargeImagePicker from "./LargeImagePicker";
import _FormTagSelection from "./FormTagSelection";
import _SoundFilePicker from "./SoundFilePicker";
import _MultiItemSelection from "./MultiItemSelection";
import localization from "../../../config/localization";
import {ValidatorForm} from 'react-material-ui-form-validator'
import {getActiveClient} from "../../../api-client/core/authentication/utils";

export const Select = _Select
export const SelectValidator = _SelectValidator
export const TextField = _TextField
export const ImagePicker = _ImagePicker
export const VideoPicker = _VideoPicker
export const LargeImagePicker = _LargeImagePicker
export const FormTagSelection = _FormTagSelection
export const SoundFilePicker = _SoundFilePicker
export const MultiItemSelection = _MultiItemSelection


export default ({ title, onSubmit, altSubmit, onAltSubmit, children, onBack, disabled = false, style = {}, color = '#1f1f1f', extraSpace = false, smallExtraSpace = false, hasNextStep = false, onNextStep, actionsExtraSpace = false }) =>
<div style={style}>
  <div style={{textAlign: "center"}}>
    <Card style={{marginBottom: "0px", maxWidth: "800px", display: 'inline-block', textAlign: "left", padding: extraSpace ? "70px 50px 50px 50px" : smallExtraSpace ? "10px 5px 5px" : 0}}>
      <ValidatorForm
        onSubmit={onSubmit}
      >
        <CardHeader color={color} text>
          <CardText style={{ backgroundColor: color }} >
            <h4 style={{color:"white"}}>{title}</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
        <Divider />
        <CardActions style={{ display: "block", padding: extraSpace ? "35px 12px" : smallExtraSpace ? "15px 12px" : "none" }}>
          <Button style={{ float: "left", top: "-4px", margin: actionsExtraSpace && "20px 10px" }} color="default" onClick={onBack} disabled={disabled}>{getActiveClient() ? localization.get('form.close') : localization.get('form.back') }</Button>
          {!hasNextStep && <Button type="submit" style={{ float: "right", top: "-4px", backgroundColor: color !== 'dark' && color, margin: actionsExtraSpace && "20px 10px" }} color={color == 'info' ? "info" : color == 'dark' ? "dark" : "default"} disabled={disabled}>{localization.get('form.submit')}</Button>}
          {hasNextStep && <Button onClick={onNextStep} style={{ float: "right", top: "-4px", backgroundColor: color !== 'dark' && color, margin: actionsExtraSpace && "20px 10px" }} color={color == 'info' ? "info" : color == 'dark' ? "dark" : "default"} disabled={disabled}>{localization.get('license_wizard.next')}</Button>}
          {altSubmit && <Button onClick={onAltSubmit} link style={{ float: "right", top: "-4px", height: "41px", border: `1px solid #999999`, color: "black", margin: actionsExtraSpace && "20px 10px" }} size='sm' disabled={disabled}>{localization.get('form.submit_and_continue')}</Button>}
        </CardActions>
      </ValidatorForm>
    </Card >
  </div>
</div>
