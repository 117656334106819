import React, {Component} from "react";
import EventManager, {events} from "../../../utils/EventManager";
import LicenseWizard from "../LicenseWizard/LicenseWizard";

class GlobalLicenseWizardDialog extends Component {
  
  static mapApiClient = client => ({
    id: client.id,
    name: client.name,
    country: client.country,
    ...client,
  });

  static _ref = null;

  static show = ({ track, client, initializeWithValues, toApprove = null }) =>
    EventManager.getInstance().dispatch(events.SHOW_GLOBAL_LICENSE_WIZARD_DIALOG, {
      track,
      client,
      initializeWithValues,
      toApprove
    });

  static hide = () => GlobalLicenseWizardDialog._ref.hide();

  constructor(props) {
    super(props);
    GlobalLicenseWizardDialog._ref = this;
    this.state = {
      open: false,
      track: null,
      client: null,
      initializeWithValues: null,
      toApprove: null
    };
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.SHOW_GLOBAL_LICENSE_WIZARD_DIALOG, this.show);
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SHOW_GLOBAL_LICENSE_WIZARD_DIALOG, this.show);
  };

  show = ({ track, client, initializeWithValues, toApprove}) =>
    this.setState({ open: true, track, client, initializeWithValues, toApprove });

  hide = () => this.setState({ open: false });

  render = () => {
    return (
      <LicenseWizard
        show={this.state.open}
        onClose={() => this.setState({ open: false })}
        track={this.state.track}
        client={this.state.client}
        toApprove={this.state.toApprove}
        initializeWithValues={this.state.initializeWithValues}
      />
    );
  };
}

export default GlobalLicenseWizardDialog;
