import React from "react";

import {ImagePicker} from "components/Letflow/Form";
import PropTypes from "prop-types";
import localization from "../../../../../config/localization";
import {FormControl, MenuItem} from "@material-ui/core";
import {SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {ROLES} from "../../../../../api-client/core/authentication/utils";
import {CanLicenseCheckbox} from "../../../../../views/Users/Form/UsersFormView";

const UsersFormView = ({
  name,
  email,
  image,
  onChange,
  onImageChange,
  roles,
  roleId,
  disabled = false,
  canLicense,
  onCanLicenseChange,
  language,
}) => {
  return (
    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get("form.name")}
        name="name"
        value={name}
        onChange={onChange}
        disabled={disabled}
        validators={["required"]}
        errorMessages={[localization.get("validator.is_required")]}
      />
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="email"
        label={localization.get("form.email")}
        name="email"
        value={email}
        onChange={onChange}
        disabled={disabled}
        validators={["required", "isEmail"]}
        errorMessages={[localization.get("validator.is_required"), localization.get("validator.is_email")]}
      />
      <br />
      <br />
      <FormControl fullWidth>
        <SelectValidator
          label={localization.get("form.language")}
          id="language"
          name="language"
          value={language}
          onChange={e => {
            e.target.id = "language";
            onChange(e);
          }}
          validators={["required"]}
          errorMessages={[localization.get("validator.is_required")]}
        >
          <MenuItem value="es">Español</MenuItem>
          <MenuItem value="en">Ingles</MenuItem>
        </SelectValidator>
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <SelectValidator
          label={localization.get("form.role")}
          id="roleId"
          name="roleId"
          value={roleId}
          onChange={e => {
            e.target.id = "roleId";
            onChange(e);
          }}
          validators={["required"]}
          errorMessages={[localization.get("validator.is_required")]}
        >
          {roles.map(role => (
            <MenuItem value={role.id} key={role.id}>
              {role.description}
            </MenuItem>
          ))}
        </SelectValidator>
      </FormControl>
      <br />
      <br />

      {roleId === ROLES.userId && (
        <React.Fragment>
          <CanLicenseCheckbox
            checkboxStyle={{ color: "black" }}
            canLicense={canLicense}
            onCanLicenseChange={onCanLicenseChange}
            roleId={roleId}
          />
          <br />
          <br />
        </React.Fragment>
      )}

      <ImagePicker
        style={{ backgroundColor: "black" }}
        color={"default"}
        buttonText={localization.get("form.select_image")}
        image={image != null ? image.src : null}
        onImageChange={onImageChange}
        disabled={disabled}
      />
    </div>
  );
};

UsersFormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default UsersFormView;
