import React from "react";
import localization from "../../../config/localization";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Theaters from "@material-ui/icons/Theaters";
import Group from "@material-ui/icons/Group";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Hidden from "@material-ui/core/Hidden";
import {
  catalogClientView, channelPathGenerator, getActiveChannel, getActiveSection, getIsDomainChannel,
  homePathGenerator,
  setPageTitle, userCanLicenseTracks,
  userIsClient,
  userIsClientAdmin, userIsReadOnly,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import "./Account.css";
import withStyles from "@material-ui/core/styles/withStyles";
import Profile from "./Profile";
import Client from "./Client";
import Licenses from "./Licenses";
import Users from "./Users";
import Banners from "./Banners";
import All from "../../../views/Clients/All";
import {Link, Redirect, Route, Router, Switch} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import LicensePage from "./Licenses/LicensePage/LicensePage";
import {isNull} from "util";

const styles = theme => ({
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      client: props.client || null,
      contentHeight: "100vh"
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("title.my_account"));
    document.getElementsByTagName("html")[0].scrollTop = 0;

    this.setState({ contentHeight: this.accountContent.offsetHeight });
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  isSelected = route => window.location.pathname.includes(route);

  checkPermissions = (entity) => this.props.client.permissions.some(permission => permission.entity === entity)

  render = () => {
    const { classes } = this.props;

    const sidebar = (() => {
      const makeSidebarLink = (canRender, isSelectedLabel, Logo, to, label) =>
        canRender && (
          <Link
            onClick={e => {
              if (isNull(to)) {
                e.preventDefault();
              } else {
                this.handleDrawerToggle();
              }
            }}
            to={to || "#"}
          >
            <div
              className={`account-link-container ${
                this.isSelected(isSelectedLabel) ? "selected" : ""
              }`}
            >
              <div className="account-link-logo">
                <Logo style={{ fontSize: "30px" }} />
              </div>
              {(() => {
                const content = (
                  <div className="account-link">{label}</div>
                );
                if (isNull(to)) {
                  return (
                    <Tooltip
                      title={localization.get("sidebar.soon")}
                      placement="right"
                    >
                      {content}
                    </Tooltip>
                  );
                }
                return content;
              })()}
            </div>
          </Link>
        );

      return (
        <div className="account-drawer-container">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleDrawerToggle}
            className={classes.navIconHide}
            style={{
              position: "absolute",
              right: "20px",
              top: "15px",
              zIndex: "1000"
            }}
          >
            <Close />
          </IconButton>
          {!getActiveChannel() && makeSidebarLink(
            true,
            "profile",
            AccountCircle,
            homePathGenerator(`account/profile`),
            localization.get("account.profile")
          )}
          {this.checkPermissions('account') &&
            makeSidebarLink(
              !getActiveChannel() && (userIsClientAdmin() || userIsSysAdmin() || userIsSupervisor()),
              "client",
              AccountBalance,
              homePathGenerator(`account/client`),
              localization.get("account.client")
            )
          }
          {this.checkPermissions('account') &&
            makeSidebarLink(
              !getActiveChannel() && (userIsClientAdmin() || userIsSysAdmin() || userIsSupervisor()),
            "users",
            Group,
            homePathGenerator(`account/users`),
            localization.get("account.users")
            )
          }
          {this.checkPermissions('account') &&
            makeSidebarLink(
              !getActiveChannel() && (userIsClientAdmin() || userIsSysAdmin() || userIsSupervisor()),
              "banners",
              Theaters,
              homePathGenerator(`account/banners`),
              localization.get("account.banners")
            )
          }
          {!catalogClientView() && (userIsClient() && userCanLicenseTracks() || userIsClientAdmin() || userIsSysAdmin()) && !userIsReadOnly() && this.checkPermissions('music') &&
            makeSidebarLink(
            userIsClientAdmin() ||
              userIsSysAdmin() ||
              userIsSupervisor() ||
              userIsClient(),
            "licenses",
            ShoppingCart,
            getActiveChannel() ? channelPathGenerator(`account/licenses`) : homePathGenerator(`account/licenses`),
            localization.get("account.licenses")
          )}
        </div>
      );
    })();
    
    const parseRouting = path => {
      let pathname = path
      if(getActiveSection()) pathname = `/home/${getActiveSection().id}-${getActiveSection().name}` + pathname
      pathname = getIsDomainChannel() ? pathname : '/channel/:channelId-:channelName' + pathname;

      return pathname;
    }
    return (
      <div id="innerinner" className="home-playlists" style={{backgroundColor: "white"}}>
        {!getActiveChannel() &&
          <>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
              style={{
                zIndex: 100
              }}
            >
              <MenuIcon/>
            </IconButton>

            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {sidebar}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                open
                PaperProps={{
                  style: {
                    position: "relative",
                    float: "left",
                    width: "250px",
                    boxShadow: "0 1px 10px 0 rgba(0,0,0,.1)",
                    borderRight: "none",
                    backgroundColor: "rgb(247,247,247)",
                    zIndex: "2",
                    minHeight: "100vh",
                    height: this.state.contentHeight
                  }
                }}
              >
                {sidebar}
              </Drawer>
            </Hidden>
          </>
        }
        {console.log(userIsClient(), userCanLicenseTracks())}
        <div className="account-content" style={getActiveChannel() ? {width: "100%"} : {}} ref={el => (this.accountContent = el)}>
          <Router {...this.props}>
            <Switch>
              <Route
                path={"/home/:clientId-:clientName/account/profile"}
                component={Profile}
              />
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor()) && (
                <Route
                  path="/home/:clientId-:clientName/account/client"
                  component={Client}
                />
              )}
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor() ||
                (userIsClient() && userCanLicenseTracks())) && (
                <Route
                  path={ getActiveChannel() ? parseRouting("/account/licenses/:licenseId") : "/home/:clientId-:clientName/account/licenses/:licenseId"}
                  component={LicensePage}
                />
              )}
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor() ||
                (userIsClient() && userCanLicenseTracks())) && (
                <Route
                  path={getActiveChannel() ? parseRouting("/account/licenses") : "/home/:clientId-:clientName/account/licenses"}
                  component={Licenses}
                />
              )}
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor()) && (
                <Route path="/home/:clientId-:clientName/account/users" component={Users} />
              )}
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor()) && (
                <Route
                  path="/home/:clientId-:clientName/account/banners"
                  render={props => (
                    <Banners
                      {...props}
                      client={this.state.client}
                      clientId={props.match.params.clientId}
                    />
                  )}
                />
              )}
              {(userIsClientAdmin() ||
                userIsSysAdmin() ||
                userIsSupervisor()) && (
                <Route path="/home/:clientId-:clientName/account/credits" component={All} />
              )}
              <Route
                render={() => (
                  <Redirect
                    to={getActiveChannel() ? channelPathGenerator('account/licenses') : homePathGenerator(`account/profile`)}
                  />
                )}
              />
            </Switch>
          </Router>
        </div>
      </div>
    );
  };
}

export default withStyles(styles, { withTheme: true })(Account);
