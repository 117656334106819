import React, {Component} from 'react'
import localization from '../../../config/localization';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import ClientsTable from "./Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {userCanCreateClient} from '../../../api-client/core/authentication/utils';
import {makeUrlWithTableParams} from "../../../utils";

class Clients extends Component {

  componentDidMount = () => {
    document.title = localization.get('title.clients') + ' - Feater'
  }

  onAdd = () => this.props.history.push(makeUrlWithTableParams(`/panel/clients/create`))

  render = () =>
    <AllViewContainer
      onAdd={userCanCreateClient() ? this.onAdd : null}
      ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ClientsTable {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default Clients