import localization from "../../../../config/localization";
import IconButton from "@material-ui/core/IconButton";
import {Close, Settings} from "@material-ui/icons";
import React from "react";
import HomeItemDescriptionTags from "../../HomeElements/HomeItemDescriptionTags";
import StorageChart from "../../../../components/Letflow/StorageChart";
import {Checkbox, FormControl, FormControlLabel, TextField} from "@material-ui/core";

const Sidebar = ({
     handleDrawerToggle,
     classes,
     tags,
     onSelectedTagChanged,
     search,
     handleTagSearch,
     handleViewChange,
     sharedWithMe
   }) =>
    <div className="account-drawer-container" style={{paddingTop: "35px"}}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerToggle}
        className={classes.navIconHide}
        style={{
          position: "absolute",
          left: "5px",
          top: "5px",
          zIndex: "1000"
        }}
      >
        <Close/>
      </IconButton>
      <div className="drive-sidebar-title">
        <h1 style={{textAlign: "center", color: "black", fontSize: "1.825em", lineHeight: "1.4em", margin: "0 0 10px"}}>{localization.get("drive")}</h1>
      </div>
      <div className="tags-section-container">
        <FormControlLabel
          control={
            <Checkbox
              id="public"
              style={{ color: "black"}}
              checked={sharedWithMe}
              onChange={handleViewChange}
            />
          }
          style={{ position: "relative", float: "left",marginLeft: -10}}
          label={localization.get('files.just_mine')}
        />
          <>
            <FormControl style={{ width: "100%", padding: "0 7px"}}>
              <TextField
                style={{ marginBottom: 20 }}
                value={search}
                label={localization.get("search.tags")}
                onChange={handleTagSearch}
                fullWidth
              />
            </FormControl>

            {tags && tags.length > 0 ?
            <div className="tags-container">
              {tags.length > 0 &&
              [1,2,3,4,5,6,7,8,9,10,null].map(element => {
                const filteredTags = tags.filter(tag => tag.level === element)
                return (
                  <>
                    {filteredTags.length > 0 &&
                    <div style={{width: "100%", marginBottom: 10}}>
                      <HomeItemDescriptionTags
                        displayOnMobile={true}
                        outlined
                        tags={filteredTags}
                        onSelectedTagChanged={onSelectedTagChanged}/>
                    </div>
                    }
                  </>)
              })}
            </div>
              :
              <div className="tags-container" >
                <p style={{width: "100%",textAlign: "center"}}>{localization.get('unavailable_tags')}</p>
              </div>
            }
          </>

      </div>
    </div>

export default Sidebar