import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import browserHistory from "../../../utils/browserHistory";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import { Delete, Done, Clear } from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@material-ui/core";
  import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class TableSubcomponent extends Component {
  state = {
    contentCreations: [],
    contentCreator: null
  };

  componentDidMount = () => {
    this.getCreator();
  };

  getCreator = () => request.contentCreator
    .get(this.props.contentCreator.id, 'include=allContentCreations')
    .then(contentCreator => this.setState({ contentCreator, contentCreations: contentCreator.allContentCreations }));

  makeEditUrl = (v) => {
    if (this.props.isTalent) {
      return "/talents/content_creations/" + v.original.id + "/edit"
    } else {
     return "/audiovisual/content_creations/" + v.original.id + "/edit"
    }
  }

 
  makeDeclineDialog = () =>
    <Dialog 
      open={this.state.openDeclineDialog} 
      onBackdropClick={() => this.setState({openDeclineDialog: false})} 
      onEscapeKeyDown={() => this.setState({openDeclineDialog: false})}
    >
      <DialogTitle>{localization.get('table.decline')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('table.decline.text')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openDeclineDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.manageContentCreator('declined').then(() => this.setState({openDeclineDialog: false}))}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>

    makeIntegrateDialog = () =>
    <Dialog open={this.state.openIntegrateDialog} onBackdropClick={() => this.setState({openIntegrateDialog: false})} onEscapeKeyDown={() => this.setState({openIntegrateDialog: false})}>
      <DialogTitle>{localization.get('demo.integrate_to_platform')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('table.approve.text')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openIntegrateDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.manageContentCreator('approved').then(() => this.setState({openIntegrateDialog: false}))}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>

    manageContentCreator = status => 
        request.contentCreation.moderate(this.state.contentCreationId, {status})
            .then(() => GlobalSnackbar.show({
                type: GlobalSnackbarTypes.SUCCESS,
                message: localization.get(status == "approved" ? 'table.approved' : 'table.declined'),
            }))
            .then(this.getCreator)


    buttons = contentCreation => {
        const {id, name, status} = contentCreation;
    
        let editUrl = '';
        
        if (this.props.isTalent) {
          editUrl = makeUrlWithTableParams(`/panel/talents/content_creations/${contentCreation.id}/edit`);
        } else {
          editUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creations/${contentCreation.id}/edit`);
        }

        const buttonBuilder = new TableButtonsBuilder();
    
        if (status == "pending" || status == "declined") {
            buttonBuilder.withOther(
              <TableButton
                title={localization.get("table.approve")}
                onClick={() => {
                this.setState({openIntegrateDialog: true, contentCreationId: id})
                }}
              >
                <Done/>
            </TableButton>
            );
        }
            
        if (status == "pending" || status == "approved") {
            buttonBuilder.withOther(
              <TableButton
                title={localization.get("table.decline")}
                onClick={() => this.setState({openDeclineDialog: true, contentCreationId: id})}
              >
                <Clear />
              </TableButton>
            );
        }
  
        buttonBuilder.withEdit(editUrl);
    
        buttonBuilder.withOther(
          <TableButton
            title={localization.get('delete')}
            onClick={() =>
              GlobalConfirmationDialog.showDelete({
                dialogTitle: `${localization.get('tooltip.delete')} ${name}`,
                content: localization.get('confirmation_dialog.delete_content_message'),
                doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
                elementName: localization.get("delete"),
                deleteRequest: () => request.contentCreation.delete(id)
                  .then(this.refs.table.forceUpdate),
                doubleConfirmation: true,
                auxiliaryInformation: "",
              })
            }
          >
            <Delete />
          </TableButton>
        )
    
        return buttonBuilder.build();
      };
    

  render = () => {
    const { contentCreator } = this.state;
    
    return (
      <div style={{ margin: "20px" }}>
        {this.makeDeclineDialog()}
        {this.makeIntegrateDialog()}
        <h4>{contentCreator && contentCreator.name}</h4>
        {this.state.contentCreations.length > 0 && (
          <ClientSidePaginationTable
            data={this.state.contentCreations.map((contentCreation, index) => ({
              ...contentCreation,
              index
            }))}
            columns={[
              {
                accessor: "title",
                Cell: v => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => browserHistory.push(this.makeEditUrl(v))}
                  >
                    {v.value}
                  </div>
                )
              },
              {
              id: "actions",
              sortable: false,
              filterable: false,
              accessor: this.buttons,
              width: 145,
              }
            ]}
            filterable={false}
            sortable={false}
            showPagination={false}
            zebraStyle={false}
          />
        )}
        {this.props.contentCreator.observation &&
        <div>
          <b style={{width: "100%", position: "relative", float: "left"}}>{localization.get('form.observations')}</b>
          {this.props.contentCreator.observation }
        </div>
        }
      </div>
    );
  };
}

export default TableSubcomponent;
