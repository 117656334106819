import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Analytics from './Analytics'

class Wrapper extends Component {

  componentDidMount = () => {
    this.trackInitialization()
  }

  componentDidUpdate = () => {
    this.trackInitialization()
  }

  getPath = () => window.location.pathname

  trackInitialization = () => {
    // this.props.analytics.pageView(this.getPath())
  }

  render = () => <this.props.component {...this.props} />
}

Wrapper.propTypes = {
  // analytics: PropTypes.instanceOf(Analytics).isRequired,
  component: PropTypes.func.isRequired,
}

const wrapPage = (component) => (props) => <Wrapper {...props} component={component} />

export default wrapPage