import {addHttpsToUrl, clearTokens, getActiveChannel, channelPathGenerator, getStoredUser, rootPath} from "./utils";
import {post} from "../utils";
import browserHistory from "../../../utils/browserHistory";

export default host => () => {
  if (!!getStoredUser()) {
    if (typeof getStoredUser().providers !== 'undefined' && getStoredUser().providers.find(p => p.provider === 'gcba')) {
      post(process.env.REACT_APP_LOGOUT_GCBA_URL, null, false, false, false, false)
        .finally(() => makeLogout(host))
    }else {
      makeLogout(host)
    }
  }
}

const makeLogout = (host) => {
  const form = new FormData()
  if(getActiveChannel()) {
    form.append('redirect_url', `${window.location.protocol}//${window.location.hostname}${rootPath()}`)
  }else {
    form.append('redirect_url', window.location.protocol + '//' + window.location.hostname)
  }

  return post(`${host}/logout`, form, null, () => {}, true, true, false)
      .then(result => result.json())
      .then(response => {
        if (!!response.redirectUrl) {
          window.location.href= addHttpsToUrl(response.redirectUrl)
        }else {
          if(getActiveChannel()) {
            browserHistory.push(rootPath())

            window.location.reload()
          }else {
            window.location.reload()
          }
        }
        // reloading will cause the router to redirect to login page.
      })
      .catch(response => {
        if(response.status === 429) {
          getActiveChannel() ? browserHistory.push(channelPathGenerator('error-page')) : browserHistory.push('error-page')
        }else {
          window.location.reload()
        }
      })
      .finally(clearTokens)
      
}