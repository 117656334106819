import React from 'react'
import PropTypes from 'prop-types'
import localization from '../../../config/localization';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ItemSelectionTables} from "../../../components/Letflow/Table";
import {makeWaveformUrl, secondsToHHMMSS} from "../../../utils";
import {request} from "../../../api-client";
import {TextValidator} from "react-material-ui-form-validator";
import TablePlayButton from '../../../components/Letflow/Table/Buttons/TablePlayButton';
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import AutoCompleteSelect from "react-select";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

const MusicSelectionTables = ({ clientId, onSelectedItemsChanged, selectedMusics }) =>
  <ItemSelectionTables
    columns={[
      {
        Header: localization.get('table.code'),
        accessor: 'code',
        Filter: ({ filter, onChange }) => (
          <input
            onKeyPress={e => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder={localization.get('table.search')} />
        )
      },
      {
        Header: localization.get('table.title'),
        accessor: 'title',
        Filter: ({ filter, onChange }) => (
        <input
          onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
          placeholder={localization.get('table.search')} />
        )
      },
      { Header: localization.get('table.tags'), id: 'tags', accessor: music => music.tags.map(t => t.name).join(', '), filterable: false },
      {
        Header: localization.get('table.duration'),
        id: 'duration',
        accessor: music => secondsToHHMMSS((music.audio && music.audio.duration) || 0),
        filterable: false
      },
    ]}
    extraButtonFactories={[music => <TablePlayButton tracks={{
      fetchAudioUrl: () => request.music.get(music.id).then(music => music.audio.url),
      title: music.title,
      waveform: makeWaveformUrl(music)
    }} />]}
    requestPaginatedItems={paginatedConfig => {
      return request.client.getMusics(clientId, paginatedConfig)
    }}
    selectedItemsChanged={onSelectedItemsChanged}
    selectedTableTitle={<h4>{localization.get('form.selected_musics')}</h4>}
    toSelectTableTitle={<h4>{localization.get('form.select_music')}</h4>}
    selected={selectedMusics}
  />

const FormView = ({
  translations,
  onChangeTranslation,
  clients,
  client,
  onClientChange,
  onChange,
  userLibrary,
  onUserLibraryChange,
  onSelectedItemsChanged,
  selectedMusics,
  onSelectedMusicsChanged,
  disabled = false
}) => {

  return (
    <div>
      <TranslatableInputs
        children={AvailableLanguages.map(language => {
          const currentLangTranslation = translations.find(tr => tr.language === language);

          return {
            language,
            content: [
              <TextValidator
                style={{ width: "100%", marginBottom: 20 }}
                id={`title${language}`}
                label={localization.get('form.title')}
                name={`title${language}`}
                value={currentLangTranslation.title}
                onChange={e => onChangeTranslation(e.target.value, "title", language)}
                disabled={disabled}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />,
              <TextValidator
                style={{ width: "100%" }}
                id={`subtitle${language}`}
                label={localization.get('form.subtitle')}
                name={`subtitle${language}`}
                value={currentLangTranslation.subtitle}
                onChange={e => onChangeTranslation(e.target.value, "subtitle", language)}
                disabled={disabled}
              />
            ]
          }
        })}
      />


      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "#4cc2d5" }}
            checked={userLibrary}
            onChange={() => {
              onUserLibraryChange(!userLibrary)
            }}
          />
        }
        label={localization.get('form.show_in_home')}
      />

      <div style={{marginTop: 30}}>
        <AutoCompleteSelect
          styles={{
            menu: base => ({
              ...base,
              zIndex: 10
            })
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 0
          })}
          placeholder={localization.get('user.add_client')}
          value={client}
          noOptionsMessage={() => localization.get('no_options')}
          onChange={e => onClientChange(e)}
          options={clients.map(client => ({value: client.id, label: client.name}))}
        />

      </div>

      {client && <MusicSelectionTables clientId={client.value} selectedMusics={selectedMusics} onSelectedItemsChanged={onSelectedMusicsChanged} />}
    </div>
  )
}

FormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disableClientInput: PropTypes.bool,
}

export default FormView
