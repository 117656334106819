import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {Email} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import GlobalConfirmationDialog from "../../Dialog/GlobalConfirmationDialog";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../Snackbar/GlobalSnackbar";

class TableNotifyButton extends Component {

  handleOnClick = (e) => {
    e.stopPropagation();
    this.props.requestNotify()
      .then(() => GlobalSnackbar.show({
      message: localization.get("alert.sending_activation_email_passed"),
      type: GlobalSnackbarTypes.SUCCESS
    }))
  }

  render = () => {
    return (
      <span>
        <TableButton title={localization.get('tooltip.notify')} onClick={this.handleOnClick}>
          <Email />
        </TableButton>
      </span>
    )
  }
}

TableNotifyButton.propTypes = {
  itemInformation: PropTypes.string.isRequired,
  requestNotify: PropTypes.func.isRequired,
  alertClosed: PropTypes.func.isRequired,
}

export default TableNotifyButton