import {post} from "../utils";
import {storeTokens} from "./utils";

const form = (code, state) => {
  const f = new FormData()
  f.append('code', code)
  f.append('state', state)
  return f
}

export default host => ({code, state}, provider = 'miba') =>
    post(`${host}/login/provider/${provider}/code`, form(code, state), {}, () => {}, false)
    .then(result => result.json())
    .then(storeTokens)