import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import {LibraryMusic} from "@material-ui/icons";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import {userCanCreateClient} from "../../../api-client/core/authentication/utils";

class All extends Component {
  state = {
    channels: [],
    clients: []
  }

  componentDidMount = () => {
    document.title = localization.get("title.channels") + " - Flowlike";
  };

  onAdd = () => this.props.history.push(makeUrlWithTableParams(`/panel/channels/create`));

  render = () => (
    <AllViewContainer
      requestDeletion={this.requestDelete}
      onAdd={userCanCreateClient() && this.onAdd}
      viewIcon={<LibraryMusic />}
      ref="viewContainer"
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table
                {...this.props}
                onDelete={this.onDelete}
                clients={this.state.clients}
                channels={this.state.channels}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default All;
