import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Edit from "./Update";

const PlaylistsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/playlists/:id/edit" component={Edit} />
        <Route path="/panel/playlists/create" component={Create} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default PlaylistsRouting
