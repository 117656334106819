import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'
import {getStoredUser, userIsArtist} from "../authentication/utils";

export default host => ({ id, code, title, tagIds, audioId, audioAiffId, videoId, albumIds, active, showInRecents, bichannel, artistId, inactiveReason, owner, catalogId, batchAudioId , deleteAiff}, onProgress) => {
  if(userIsArtist()) {
    if(getStoredUser().artists.length > 1) {
      code = getStoredUser().artists.find(artist => artist.id === artistId).name
    } else {
      code = getStoredUser().artists[0].name + ' - ' + title
      artistId = getStoredUser().artists[0].id
    }
  }
  return (
    post(`${host}/musics/${id}`, addPutMethodToForm(makeFormData(code, title, tagIds, audioId, audioAiffId, videoId, albumIds, active, showInRecents, bichannel, artistId, inactiveReason, owner, catalogId, batchAudioId, deleteAiff)), {}, onProgress)
      .then(response => response.json())
  )
}

