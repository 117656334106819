import React, {Component} from "react";
import localization from "../../config/localization";

class ErrorBoundary extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch = () => {
    this.setState({ hasError: true })
  }

  render = () =>
    this.state.hasError ?
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{localization.get('error.refresh_page')}</div> :
      this.props.children
}

export default ErrorBoundary