import React, {Component} from "react";
import localization from '../../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import { makeUrlWithTableParams} from "../../../../utils";

export default class extends Component {
  state = {
    channel: null,
    code: "",
    discount: 0,
    uses: 0,
    quantity: 0,
    expiresAt: null,
    active: true,
    description: "",
    products: [],
    includeAll: "1",
  }

  updatePack = () => {
    return request.productCodes.update(this.props.match.params.id, {
      code: this.state.code,
      discount: this.state.discount,
      uses: this.state.uses,
      quantity: this.state.quantity,
      expiresAt: this.state.expiresAt,
      active: this.state.active,
      description: this.state.description,
      products: this.state.products,
      includeAll: this.state.includeAll ===  "1",
    })
      .then(() => this.props.history.push(makeUrlWithTableParams('/panel/products')))
  }

  onSubmitValidation = () => {
      return true
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('form.update_code')}
          submitRequest={this.updatePack}
          onBack={this.props.history.goBack}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
          actionsExtraSpace={true}
          errorMessage={localization.get('snackbar.code_error_created')}
          initializationRequest={() => request.productCodes.get(this.props.match.params.id)
            .then(code => this.setState({
              channel: {value: code.channel.id, label: code.channel.name } ,
              code: code.code,
              discount: code.discount,
              uses: code.uses,
              quantity: code.quantity,
              expiresAt: code.expires_at,
              active: true,
              description: "",
              products: code.products.map(product => ({value: product.id, label: product.content.name || product.content.title || "" })),
              includeAll: code.include_all ? "1" : "0",
            })
          )}
        >
          <Form
            code={this.state.code}
            discount={this.state.discount}
            uses={this.state.uses}
            quantity={this.state.quantity}
            expiresAt={this.state.expiresAt}
            active={this.state.active}
            description={this.state.description}
            channel={this.state.channel}
            onChannelChange={channel => this.setState({ channel: null }, () => this.setState({ channel }))}
            onChange={this.onChange}
            products={this.state.products}
            onProductsChange={products => this.setState({ products })}
            includeAll={this.state.includeAll}
          />
        </FormViewContainer>
      </div>
    )
  }
}
