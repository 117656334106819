import "layouts/Home/Home.css";
import React, {Component} from "react";
import Button from "components/CustomButtons/Button.jsx";
import {request} from "../../api-client";

import localization from "../../config/localization";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {SelectValidator} from "./Form";
import {ValidatorForm} from "react-material-ui-form-validator";
import GlobalSnackbar, {GlobalSnackbarTypes} from "./Snackbar/GlobalSnackbar";
import browserHistory from "utils/browserHistory";

class DuplicatePlaylistDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      clients: this.props.clients,
      client: '',
      submiting: false,
      errorMessage: '',

    }
  }

  handleClose = () => {
    if (!this.state.submiting) {
      this.props.handleClose()
      this.setState({
        submiting: false,
      })
    }
  }

  submit = () => {

    request.playlist.duplicate({playlistId: this.props.playlistId, clientId: this.state.client})
      .then((playlist) => {
        GlobalSnackbar.show({message: localization.get('dialog.playlist_duplicate_success'), type: GlobalSnackbarTypes.SUCCESS})
        this.setState({submiting: false})
        browserHistory.push(`/playlists/${playlist.id}/edit`)
      })
      .catch(() => {
        GlobalSnackbar.show({message: localization.get('dialog.playlist_duplicate_failed'), type: GlobalSnackbarTypes.ERROR})
        this.setState({submiting: false, errorMessage: localization.get('dialog.playlist_duplicate_failed')})
      })
  }

  render = () =>
    <Dialog open={this.props.show} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
      <ValidatorForm
        onSubmit={this.submit}
      >
        <DialogTitle>{localization.get('dialog.duplicate_playlist')}</DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>{localization.get('dialog.duplicate_playlist_instructions')}</div>
            <div style={{float: "left", position: "relative", width: "100%", marginTop: "30px", marginBottom: "20px" }}>
              <SelectValidator
                id='client'
                name="client"
                current={this.state.client}
                title={localization.get('form.client')}
                onChange={(e) => this.setState({ [e.target.id]: e.target.value })}
                items={this.state.clients}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color='default' style={{backgroundColor: "black"}} disabled={this.state.submiting}>{localization.get('send')}</Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
}

export default DuplicatePlaylistDialog