import React from 'react'
import CustomTooltip from './CustomTooltip';
import '../assets/style/SoundBar.css'

const buttonStyle = {
  color: 'var(--secondary-font-color, dimgrey)',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
}

const SimpleButton = ({ onClick = () => { }, onMouseEnter = () => { }, tooltip, style = {}, children, isDisabled }) => {

  const button = <div
    className={'soundbar-button'}
    style={{ ...buttonStyle, ...style, cursor: isDisabled ? 'auto' : 'pointer' }}
    onClick={e => {
      if (!isDisabled) {
        onClick(e)
      }
    }}
    onMouseEnter={onMouseEnter}>{children}</div>

  if (tooltip) {
    return <CustomTooltip title={tooltip}>{button}</CustomTooltip>
  }
  return button
}

export default SimpleButton