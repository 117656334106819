import {isNullOrUndefined} from "util";

export const makeFormData = ({
  translations,
}) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
    })
  }
  return form
}