import getAll from './getAll'
import create from './create'
import del from './delete'
import update from './update'
import get from './get'
import audioUrl from './audioUrl'
import createReel from './createReel'
import share from './share'
import getFilesByReel from './getFilesByReel'
import getMany from './getMany'
import createCustomReel from './createCustomReel'
import downloadReel from './downloadReel'
import updatePermissions from './updatePermissions'
import getAllForReels from './getAllForReels'
import updateThumbnail from "./updateThumbnail";
import downloadFile from "./downloadFile";
import getForDashboard from './getForDashboard'
import storeMany from './storeMany'

export default host => ({
  getAll: getAll(host),
  create: create(host),
  delete: del(host),
  update: update(host),
  get: get(host),
  audioUrl: audioUrl(host),
  createReel: createReel(host),
  share: share(host),
  getFilesByReel: getFilesByReel(host),
  getMany: getMany(host),
  createCustomReel: createCustomReel(host),
  downloadReel: downloadReel(host),
  updatePermissions: updatePermissions(host),
  getAllForReels: getAllForReels(host),
  updateThumbnail: updateThumbnail(host),
  downloadFile: downloadFile(host),
  getForDashboard: getForDashboard(host),
  storeMany: storeMany(host),
})