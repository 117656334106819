import {post} from '../utils'

export default host => ({
  playlistId,
  musicId,
  type,
  tagsIds
}) =>
post(`${host}/playlists/${playlistId}/${type}/${musicId}`, makeFormData({
  tagsIds,
}))

const makeFormData = ({tagsIds }) => {
  const form = new FormData()
  if (tagsIds) tagsIds.forEach(x => form.append('tags_ids[]', x))
  return form
}
