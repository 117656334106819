import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host, options, include="?include=tagGroups") => appendPaginationQueryParamsToUrl(`${host}/tagSystems${include}`, {
  ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)