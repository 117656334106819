import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {
  channelPathGenerator,
  getActiveChannel,
  getStoredUser, rootPath
} from "../../../api-client/core/authentication/utils";
import queryString from 'querystring'
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import ChannelPublicNavBar from "../../../layouts/Channel/ChannelPublicNavBar";
import Footer from "../../../layouts/Home/HomeElements/Footer";
import analyticsInstance from "../../../analytics/analyticsInstance";

class SetPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      channel: {},
    };
  }
  componentDidMount() {
    if (getStoredUser()) {
      request.authentication.logout()
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    request.channel.getPublic(getActiveChannel() ? getActiveChannel() : window.location.pathname.split('/')[2])
      .then(channel => {
        document.title = localization.get('title.reset') + ' - ' + channel.name
        this.setState({channel, requesting: false})
      })
  }

  activate = () => {
    if (this.state.password && this.state.password === this.state.confirmPassword && this.state.password.length > 5) {
      this.setState({ requesting: true })
      return request.authentication.activate({ url: queryString.parse(this.props.location.search)['?url'], password: this.state.password })
        .then(user =>
          request.authentication.login({ username: user.data.email, password: this.state.password })
            .then(() => {
              analyticsInstance.login({type: "Feater", from: "ok"})

              window.location.href = window.location.origin
            })
        )
        .catch(() => {
          this.setState({ requesting: false })
          analyticsInstance.login({type: "Feater", from: "failed"})
          GlobalSnackbar.show({
            message: localization.get('activate.activate_fail'),
            type: GlobalSnackbarTypes.ERROR,
          })
        })
    } else {
      GlobalSnackbar.show({
        message: (this.state.password && this.state.password.length < 5) ? localization.get( 'activate.passwords_too_short', 6 ) : localization.get('activate.passwords_not_match'),
        type: GlobalSnackbarTypes.ERROR,
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { channel } = this.state
    return (
      <React.Fragment>
        <ChannelPublicNavBar channel={channel} />
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>{localization.get('reset_password')}</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText={localization.get('new_password')}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ password: e.target.value }),
                          type: 'password',
                        }}
                      />
                      <CustomInput
                        labelText={localization.get('repeat_password')}
                        id="confirmPassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ confirmPassword: e.target.value }),
                          type: 'password',
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.activate()
                            }
                          }
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.activate} style={{ backgroundColor: "black" }} color="default" simple size="lg" block disabled={this.state.requesting}>{localization.get('send')}</Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer channel={this.state.channel}/>
      </React.Fragment>
    );
  }
}

export default withStyles(loginPageStyle)(SetPassword);
