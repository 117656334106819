import getAll from './getAll'
import create from './create'
import update from './update'
import get from './get'
import share from './share'
import downloadReel from './downloadReel'
import showPublic from "./showPublic"
import del from "./delete"
import getForAllClients from "./getForAllClients";
import shareByChannel from './shareByChannel'
import createByFilters from './createByFilters'


export default host => ({
  getAll: getAll(host),
  create: create(host),
  createByFilters: createByFilters(host),
  update: update(host),
  get: get(host),
  share: share(host),
  downloadReel: downloadReel(host),
  showPublic: showPublic(host),
  delete: del(host),
  getForAllClients: getForAllClients(host),
  shareByChannel: shareByChannel(host),
})