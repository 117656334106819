import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Dialog, IconButton, TextField, Tooltip} from "@material-ui/core";
import {CheckCircle, Edit, Search} from "@material-ui/icons";
import {request} from "../../../api-client";
import {setStatePromise} from "../../../utils";
import MessagesContainer from "./MessagesContainer";
import MessageSendInput from "./MessageSendInput";
import ParticipantsButton from "./ParticipantsButton";
import localization from "../../../config/localization";
import {getStoredUser, userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import ReceiveEmailsFromPitch from "./ReceiveEmailsFromPitch";
import browserHistory from "../../../utils/browserHistory";
import styled from "styled-components";
import {FormViewContainer} from "../../../components/Letflow/ViewContainer";
import {TextValidator} from "react-material-ui-form-validator";

const fetchPitchLastMessages = (pitchId, { filterByMessage } = {}) =>
  request.pitch.showLastMessages(pitchId, { filterByMessage });

const fetchPitchFirstMessages = (pitchId, { filterByMessage } = {}) =>
  request.pitch.showFirstMessages(pitchId, { filterByMessage });

const fetchPitchPreviousMessages = (
  pitchId,
  messageId,
  { filterByMessage, amount } = {}
) =>
  request.pitch.showPreviousMessages(pitchId, messageId, {
    filterByMessage,
    amount
  });

const fetchPitchNextMessages = (
  pitchId,
  messageId,
  { filterByMessage, amount } = {}
) =>
  request.pitch.showNextMessages(pitchId, messageId, {
    filterByMessage,
    amount
  });

export const fetchPitch = pitchId => request.pitch.show(pitchId);

export const ThreadContext = React.createContext({
  pitch: null,
  onlineVideosPath: null
});

export default class Thread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pitch: null,
      pitchMessages: [],
      searchValue: "",
      isSearching: false,
      hasPrevious: false,
      hasNext: false,
      hideMessages: false
    };

    this.messageContainerRef = React.createRef();
  }

  componentDidMount = () => {
    this.fetchPitch()
      .then(this.fetchPitchLastMessages)
      .then(
        () =>
          (this.updateInterval = setInterval(
            () => this.fetchPitchNextMessages(),
            10000
          ))
      );
  };

  componentWillUnmount = () => clearInterval(this.updateInterval);

  fetchPitch = async () => {
    const pitch = await fetchPitch(this.props.pitchId);

    if (!pitch.user_belongs_to_pitch && !userIsSysAdmin()) {
      browserHistory.goBack();
    }

    await setStatePromise(this, { pitch });
  };

  fetchPitchLastMessages = async ({ scrollToBottom = true } = {}) => {
    const { has_more, data: pitchMessages } = await fetchPitchLastMessages(
      this.state.pitch.id,
      { filterByMessage: this.state.searchValue }
    );

    await setStatePromise(this, { pitchMessages, hasPrevious: has_more });

    if (scrollToBottom) {
      this.scrollToBottomOfMessageContainer();
    }
  };

  fetchPitchFirstMessages = async () => {
    let { has_more, data: pitchMessages } = await fetchPitchFirstMessages(
      this.state.pitch.id,
      { filterByMessage: this.state.searchValue }
    );

    pitchMessages = pitchMessages.slice(0).reverse();

    await setStatePromise(this, { pitchMessages, hasNext: has_more });
  };

  fetchPitchPreviousMessages = async ({
    scroll = true,
    messageId,
    amount
  } = {}) => {
    if (!messageId) {
      const initialId = this.state.pitchMessages[0]
        ? this.state.pitchMessages[0].id
        : 0;

      const lowestMessageId = this.state.pitchMessages.reduce(
        (acc, next) => (acc < next.id ? acc : next.id),
        initialId
      );

      messageId = this.state.pitchMessages.length === 0 ? 0 : lowestMessageId;
    }

    let { has_more, data: pitchMessages } = await fetchPitchPreviousMessages(
      this.state.pitch.id,
      messageId,
      { filterByMessage: this.state.searchValue, amount }
    );

    pitchMessages = this.state.pitchMessages.concat(pitchMessages);

    await setStatePromise(this, { pitchMessages, hasPrevious: has_more });

    if (scroll) {
      this.messageContainerRef.current.scrollToMessage(messageId);
    }
  };

  fetchPitchNextMessages = async ({
    scrollToBottom,
    messageId,
    amount
  } = {}) => {
    if (!messageId) {
      const initialId = this.state.pitchMessages[0]
        ? this.state.pitchMessages[0].id
        : 0;

      const lowestMessageId = this.state.pitchMessages.reduce(
        (acc, next) => (acc > next.id ? acc : next.id),
        initialId
      );

      messageId = this.state.pitchMessages.length === 0 ? 0 : lowestMessageId;
    }

    let { has_more, data: pitchMessages } = await fetchPitchNextMessages(
      this.state.pitch.id,
      messageId,
      { filterByMessage: this.state.searchValue, amount }
    );

    pitchMessages = pitchMessages.slice(0).reverse();

    pitchMessages = pitchMessages.concat(this.state.pitchMessages);

    await setStatePromise(this, { pitchMessages, hasNext: has_more });

    if (scrollToBottom) {
      this.scrollToBottomOfMessageContainer();
    }
  };

  scrollToBottomOfMessageContainer = () =>
    this.messageContainerRef.current.scrollToBottom();

  currentUserIsPitchAdministrator = () =>
    this.state.pitch.participants.some(
      participant =>
        participant.id === getStoredUser().id &&
        participant.pivot.is_admin === 1
    );

  onSearch = searchValue => {
    setStatePromise(this, {
      searchValue,
      isSearching: searchValue.length > 0,
      pitchMessages: [],
      hasNext: false,
      hasPrevious: false
    }).then(() => {
      if (searchValue.length > 0) {
        this.fetchPitchFirstMessages();
      } else {
        this.fetchPitchLastMessages({ scrollToBottom: true });
      }
    });
  };

  onMessageClick = message => {
    if (this.state.isSearching) {
      setStatePromise(this, {
        pitchMessages: [],
        isSearching: false,
        searchValue: "",
        hideMessages: true
      })
        .then(() =>
          this.fetchPitchNextMessages({
            scrollToBottom: false,
            messageId: message.id - 1,
            amount: 20
          })
        )
        .then(() =>
          this.fetchPitchPreviousMessages({
            scroll: false,
            messageId: message.id,
            amount: 3
          })
        )
        .then(() => setStatePromise(this, { hideMessages: false }));
    }
  };

  render = () => {
    const { onlineVideosPath } = this.props;

    const {
      pitch,
      pitchMessages,
      hasNext,
      hasPrevious,
      isSearching,
      hideMessages
    } = this.state;

    if (pitch === null) return null;

    return (
      <ThreadContext.Provider
        value={{
          pitch: this.state.pitch,
          onlineVideosPath
        }}
      >
        <RespDiv>
          <Top pitch={pitch} updatePitch={ () => this.fetchPitch() } onSearch={this.onSearch} />
          <MessagesContainer
            ref={this.messageContainerRef}
            hasNext={hasNext}
            onNext={() =>
              this.fetchPitchNextMessages({ scrollToBottom: false })
            }
            hasPrevious={hasPrevious}
            onPrevious={() => this.fetchPitchPreviousMessages({ scroll: true })}
            pitch={pitch}
            clients={pitch.clients}
            pitchMessages={hideMessages ? [] : pitchMessages.slice(0).reverse()}
            pitchId={pitch.id}
            onlineVideos={pitch.online_videos.map(x => x.link)}
            onlineVideosPath={onlineVideosPath}
            isSearching={isSearching}
            isAdministrator={this.currentUserIsPitchAdministrator()}
            onMessageClick={this.onMessageClick}
            onDelete={message => {
              this.setState({
                pitchMessages: this.state.pitchMessages.filter(
                  x => x.id !== message.id
                )
              });
            }}
          />
          <MessageSendInput
            onSend={message =>
              request.pitch
                .sendMessage(this.state.pitch.id, message)
                .then(() =>
                  this.fetchPitchNextMessages({ scrollToBottom: true })
                )
            }
            onSendMaterial={(material, onProgress) =>
              request.pitch
                .sendMaterial(this.state.pitch.id, material, onProgress)
                .then(() =>
                  this.fetchPitchNextMessages({ scrollToBottom: true })
                )
                .catch((e) => request.log(JSON.stringify(e), 'error'))
            }
            disabled={this.state.isSearching}
          />
        </RespDiv>
      </ThreadContext.Provider>
    );
  };
}

const Top = ({ pitch, onSearch, updatePitch }) => {
  const belongsToConversation = pitch.user_belongs_to_pitch;

  return (
    <ThreadContext.Consumer>
      {({ pitch, onlineVideosPath }) => {
        return (
          <>
            <div style={{float: "left", position: "relative", width: "100%"}}>
              <Title>{pitch.project_name}</Title>
              <EditProjectButton updatePitch={updatePitch} project={pitch} />
            </div>
            <p>{pitch.project_details}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                float: "left",
                width: "100%",
                marginBottom: 20
              }}
            >
              <div>
                {belongsToConversation && (
                  <SearchMessageButton onSearch={onSearch} />
                )}
                <ParticipantsButton
                  clients={pitch.clients}
                  clientsIds={pitch.clients.map(client => client.id)}
                  pitchId={pitch.id}
                  participants={pitch.participants}
                  tooltip="bottom"
                />
                {!!onlineVideosPath && (
                  <Tooltip
                    title={localization.get("pitches.view_online_videos")}
                  >
                    <Link
                      to={onlineVideosPath({
                        clientsIds: pitch.clients.map(client => client.id),
                        pitchId: pitch.id
                      })}
                    >
                      <IconButton>
                        <CheckCircle />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            </div>
            {belongsToConversation && (
              <ReceiveEmailsFromPitch pitchId={pitch.id} />
            )}
          </>
        );
      }}
    </ThreadContext.Consumer>
  );
};

class SearchMessageButton extends Component {
  state = { search: "" };

  onKeyPress = e => {
    if (e.key === "Enter") this.props.onSearch(this.state.search);
  };

  onClick = () => this.props.onSearch(this.state.search);

  render = () => {
    return (
      <React.Fragment>
        <TextField
          style={{float: "left", position: "relative", maxWidth: "50%", width: 300}}
          onKeyPress={this.onKeyPress}
          label={localization.get("search_message")}
          onChange={e => this.setState({ search: e.target.value })}
        />
        <IconButton onClick={this.onClick}>
          <Search />
        </IconButton>
      </React.Fragment>
    );
  };
}

class EditProjectButton extends Component {
  state = {
    openUpdateDialog: false,
    editingProject: '',
    name: '',
    description: '',
    project: this.props.project
  };

  componentWillReceiveProps =(nextProps) => {
    if (this.state.project !== nextProps.project){
      this.setState({project: nextProps.project})
    }
  };

  submitRequest = (id) =>
    request.pitch.update({id, name: this.state.name, description: this.state.description})
      .then(() => this.setState({openUpdateDialog: false}))
      .then(()=> this.props.updatePitch());

  onEdit = project => this.setState({openUpdateDialog: true, editingProject: project.id, name: project.project_name, description: project.project_details});

  onChange = e => this.setState({[e.target.id]: e.target.value})

  makeUpdateDialog = () =>
    <Dialog open={this.state.openUpdateDialog} onBackdropClick={() => this.setState({openUpdateDialog: false})}>
      <FormViewContainer
        title={localization.get('project.edit')}
        submitRequest={() => this.submitRequest(this.state.editingProject)}
        onBack={() => this.setState({openUpdateDialog: false})}
        style={{maxWidth: "100%", width: "600px"}}
        color="black"
        smallExtraSpace={true}
      >
        <TextValidator
          style={{width: "100%"}}
          id="name"
          label={localization.get('form.name')}
          name="name"
          value={this.state.name}
          onChange={this.onChange}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
        <br/>
        <br/>
        <TextValidator
          style={{width: "100%"}}
          id="description"
          label={localization.get('form.description')}
          name="description"
          value={this.state.description}
          onChange={this.onChange}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
      </FormViewContainer>
    </Dialog>

  render = () => {
    return (
      <React.Fragment>
        {this.makeUpdateDialog()}
        <IconButton style={{ marginLeft: "15px"}}  onClick={() => this.onEdit(this.state.project)}>
          <Edit/>
        </IconButton>
      </React.Fragment>
    );
  };

}

const Title = ({ children }) => <ProjTitle>{children}</ProjTitle>;

const ProjTitle = styled.h3`
  float: left;
  position: relative;
  width: auto;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  margin: 10px 10px 10px 0;
`;


const RespDiv = styled.div`
  padding: 2rem 12.5rem;
  float: left;
  width:100%;
  background: white;
  min-height: 100vh;
 @media (max-width: 1500px) {
    padding: 2rem 10rem;
}
 @media (max-width: 1150px) {
    padding: 2rem 6rem;
}
 @media (max-width: 850px) {
    padding: 2rem 3rem;
}
 @media (max-width: 650px) {
    padding: 1rem 1.5rem;
}
 @media (max-width: 470px) {
     padding: 1rem 0.5rem;
}
`;
