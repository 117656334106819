import availableLanguages from '../../../config/localization/availableLanguages.json'

export const makeFormData = ({
  name,
  email,
  phone,
  country,
  state,
  description,
  instagramUrl,
  facebookUrl,
  spotifyUrl,
  audios,
  content,
  language,
  soundCloudUrl,
}) => {
  const form = new FormData()
  if (name) form.append('name', name)
  if (email) form.append('email', email)
  if (phone) form.append('phone', phone)
  if (country) form.append('country', country)
  if (description) {
    availableLanguages.forEach(language => {
      form.append(`translations[${language}][description]`, description)
    })
  }
  if (instagramUrl) form.append('instagram_url', instagramUrl)
  if (soundCloudUrl) form.append('soundcloud_url', soundCloudUrl)
  if (facebookUrl) form.append('facebook_url', facebookUrl)
  if (spotifyUrl) form.append('spotify_url', spotifyUrl)
  if (audios) audios.forEach(audio => audio.file && form.append('audios[]', audio.file, audio.name))
  if (content) form.append('content', content)
  if (language) form.append('language', language)
  form.append('type', 'external')
  form.append('is_demo', '1')
  return form
}

export const includeQuery = 'include=comments;audios'
