import React from "react"
import {Route, Router, Switch} from "react-router-dom"

import All from './All'
import Create from './Create'
import Update from './Update'

const ClientsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/licensePlans/create" component={Create} />
        <Route path="/panel/licensePlans/:id/edit" component={Update} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default ClientsRouting
