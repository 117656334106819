import React, {Component} from "react";
import {getStoredUser, onUserChanged, updateStoredUser} from "api-client/core/authentication/utils";
import {request} from "api-client";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";

class TermsOfUseDialog extends Component {
  state = {
    accepts: false,
    accepting: false,
    user: getStoredUser(),
  };

  componentDidMount = () => {
    onUserChanged.subscribe(user => {
      this.setState({ user });
    });
  };

  canShow = () => this.state.user && !this.state.user.is_trial && !this.state.user.accepted_terms_of_use;

  render = () => {
    return (
      <Dialog open={this.canShow()}>
        <DialogTitle>Importante</DialogTitle>
        <DialogContent>
          <span>
            Para continuar debe aceptar los <b>Términos de Uso</b>
          </span>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.accepts}
                onChange={e => this.setState({ accepts: e.target.checked })}
                color="default"
              />
            }
            label={
              <span>
                He leído y acepto los{" "}
                <a href={request.document.getTermsOfUseDownloadUrl} onClick={e => e.stopPropagation()}>
                  términos y condiciones de uso
                </a>
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ accepting: true }, () =>
                request.user
                  .acceptTermsOfUse()
                  .then(() => updateStoredUser({ accepted_terms_of_use: true }))
                  .finally(() => this.setState({ accepting: false })),
              );
            }}
            disabled={!this.state.accepts || this.state.accepting}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default TermsOfUseDialog;
