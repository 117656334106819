import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons'
import {Tooltip} from '@material-ui/core';
import arrayMove from 'array-move'
import {secondsToHHMMSS} from "../../../../utils";
import ClientSidePaginationTable from "../../../../components/Letflow/Table/ClientSidePaginationTable";

class Table extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected
    }
  }

  render = () => {
    const {
      selectedItemsChanged,
      onOrderChange,
      selected,
    } = this.props

    const table = (title, component) => <div>
      {title}
      {component}
    </div>


      const columns = [
        {
          id: 'order',
          Cell: ({original}) => {
            const ReorderButton = makeReorderButtonComponent(
              selected,
              original,
              reorderedSelected => this.setState({selected: reorderedSelected}, () => selectedItemsChanged(reorderedSelected)),
              onOrderChange
            )
            return (
              <div style={{display: 'grid'}}>
                <ReorderButton Icon={ArrowDropUp} nextIndex={index => index - 1} tooltipKey='move_up'></ReorderButton>
                <ReorderButton Icon={ArrowDropDown} nextIndex={index => index + 1}
                               tooltipKey='move_down'></ReorderButton>
              </div>
            )
          },
          sortable: false,
          filterable: false,
          width: 50
        },
        {
          Header: localization.get('table.title'),
          accessor: this.props.contents ? 'name' : 'title',
          sortable: false,
          filterable: false,
        },
      ]
    if (! this.props.contents) {
      columns.push({
        Header: localization.get(this.props.contents ? 'title.content_creators' : 'table.tags'),
        id: this.props.contents ? 'table.creations' : 'tags',
        accessor: music => this.props.contents ? music.contentCreations.length : music.tags.map(t => t.name).join(', '),
        filterable: false
      })
    }

    columns.push({
      Header: localization.get(this.props.contents ? 'category' : 'table.duration'),
      id: this.props.contents ? 'type' : 'duration',
      accessor: music => this.props.contents ? music.type.name : secondsToHHMMSS((music.audio && music.audio.duration) || 0),
      filterable: false,
    })

    return (
      <div>
        <ClientSidePaginationTable
          perPage={999}
          showPagination={false}
          data={selected}
          columns={columns.map(column => ({ ...column, sortable: false, filterable: false }))}/>
      </div>
    )
  }
}

const makeReorderButtonComponent = (selected, original, onReorder, onOrderChange) => ({ tooltipKey, Icon, nextIndex }) => (
  <Tooltip title={localization.get(tooltipKey)}>
    <Icon style={{ cursor: 'pointer' }} onClick={() => {
      const index = selected.indexOf(original)
      const reorderedSelected = arrayMove(selected, index, nextIndex(index))
      onReorder(reorderedSelected)
      //onOrderChange()
    }} />
  </Tooltip>
)

Table.defaultProps = {
  selected: [],
  extraButtonFactories: [],
}

export default Table
