import {request} from "../api-client";

import noImage from "../assets/img/Letflow/no-image.jpg";
import {LicenseTypeIds, MediaTypeIds, SadaicCategories} from "../components/Letflow/LicenseWizard/utils/constants";
import {Component} from "react";
import localization from "../config/localization";
import queryString from "querystring";
import browserHistory from "./browserHistory";
import download from "downloadjs";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../components/Letflow/Snackbar/GlobalSnackbar";

export const addImageNameToImageURL = imageURL => imageURL + "/image.jpg";

/**
 * find a property within an item without errors,
 * usage: getPropertySafe(item, 'client.fullname.name')
 * if not found, returns defaultValue
 * @param {object} item
 * @param {string} pathToProperty
 * @param {any} defaultValue
 */
export const getPropertySafe = (item, pathToProperty, defaultValue) => {
  try {
    const split = pathToProperty.split(".");
    let result;
    switch (split.length) {
      case 1:
        result = item[split[0]];
        break;
      case 2:
        result = item[split[0]][split[1]];
        break;
      case 3:
        result = item[split[0]][split[1]][split[2]];
        break;
      case 4:
        result = item[split[0]][split[1]][split[2]][split[3]];
        break;
      default:
        return defaultValue;
    }
    return result || defaultValue;
  } catch (_) {
    return defaultValue;
  }
};
/**
 * return in hh:mm:ss format the amount of seconds provided
 * @param {number} seconds
 */
export const secondsToHHMMSS = seconds => {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(seconds);
  const prepend0To1DigitNumber = number =>
    number < 10 ? "0" + number : number.toString();
  return `${prepend0To1DigitNumber(hh)}:${prepend0To1DigitNumber(
    mm - hh * 60
  )}:${prepend0To1DigitNumber(ss - mm * 60)}`;
};

export const secondsToMMSS = seconds => {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor(seconds / 60);
  const ss = Math.floor(seconds);
  const prepend0To1DigitNumber = number =>
    number < 10 ? "0" + number : number.toString();
  return `${mm - hh * 60}:${prepend0To1DigitNumber(ss - mm * 60)}`;
};

export const compareValues = (key, order = "asc") => {
  return function(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const wrapArray = value => (Array.isArray(value) ? value : [value]);

const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
];

export const getMonthName = month => localization.get(MONTHS[month - 1]);

export const monthDiff = (d1, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months + 1;
};

export const makeImageUrl = (item, width, height, filter, extension = 'jpg') => {
  if (item && item.image) {
    if (width) {
      height = height || width;
      if (filter) {
        return request.file.image.makeCroppedUrl(item.image.id, width, height, 'file', extension, filter);
      }
      return request.file.image.makeCroppedUrl(item.image.id, width, height, 'file', extension);
    }
    if (filter) {
      return request.file.image.makeCroppedUrl(item.image.id, 'file', extension, filter);
    }
    return request.file.image.makeUrl(item.image.id, "file", extension);
  }
  return noImage;
};

export const makeFitImageUrlWithSize = (item, size, extension = 'jpg') => {
  if (item.image) {
    if (size === 'xl') {
      return request.file.image.makeFittedUrl(item.image.id, 1920 , 1080, 'file', extension)
    }
    if (size === 'lg') {
      return request.file.image.makeFittedUrl(item.image.id, 1280 , 720, 'file', extension)
    }
    if (size === 'md') {
      return request.file.image.makeFittedUrl(item.image.id, 400 , 400, 'file', extension)
    }
    if (size === 'sm') {
      return request.file.image.makeFittedUrl(item.image.id, 200, 200, 'file', extension)
    }
    if (size === 'xs') {
      return request.file.image.makeFittedUrl(item.image.id, 50, 50, 'file', extension)
    }
    if (!size) {
    return request.file.image.makeUrl(item.image.id, "file", "png");
    }
  }
  return noImage;
};

export const makeFitImageUrl = (item, width, height, filter, extension = 'jpg') => {
  if (item && item.image) {
    if (width) {
      height = height || width;
      if (filter) {
        return request.file.image.makeFittedUrl(item.image.id, width, height, 'file', extension, filter);
      }
      return request.file.image.makeFittedUrl(item.image.id, width, height, 'file', extension);
    }
    if (filter) {
      return request.file.image.makeFittedUrl(item.image.id, 'file', extension, filter);
    }
    return request.file.image.makeUrl(item.image.id, "file", extension);
  }
  return noImage;
};

export const makeCropImageUrlWithSize = (item, size, extension = 'jpg') => {
  if (item.image) {
    if (size === 'lg') {
      return request.file.image.makeCroppedUrl(item.image.id, 1280 , 720, 'file', extension)
    }
    if (size === 'md') {
      return request.file.image.makeCroppedUrl(item.image.id, 400 , 400, 'file', extension)
    }
    if (size === 'sm') {
      return request.file.image.makeCroppedUrl(item.image.id, 200, 200, 'file', extension)
    }
    if (size === 'xs') {
      return request.file.image.makeCroppedUrl(item.image.id, 50, 50, 'file', extension)
    }
    if (!size) {
      return request.file.image.makeUrl(item.image.id, "file", "png");
    }
  }
  return noImage;
};

export const makeWaveformUrl = item => {
  return item.audio && item.audio.waveform && item.audio.waveform.id
    ? request.file.image.makeUrl(item.audio.waveform.id, "file", "png") :
    item.publicAudio && item.publicAudio.waveform && item.publicAudio.waveform.id ?
      request.file.image.makeUrl(item.publicAudio.waveform.id, "file", "png") :
      null;
};

export const getMostImportantAlbum = albums => {
  if (!albums) {
    return null;
  }
  if (albums.find(album => album.visibility === "protected")) {
    return albums.find(album => album.visibility === "protected");
  } else {
    if (albums.find(album => album.type === "artist")) {
      return albums.find(album => album.type === "artist");
    } else if (albums.find(album => album.type === "compiled")) {
      return albums.find(album => album.type === "compiled");
    } else if (albums.find(album => album.type === "library")) {
      return albums.find(album => album.type === "library");
    } else {
      return albums[0];
    }
  }
};

export const makeCustomImageUrl = (image, width, height, filter) => {
  if (image) {
    if (width) {
      height = height || width;
      if (filter) {
        return request.file.image.makeCroppedUrl(image.id, width, height, 'file', 'jpg', filter);
      }
      return request.file.image.makeCroppedUrl(image.id, width, height);
    }
    if (filter) {
      return request.file.image.makeCroppedUrl(image.id, 'file', 'jpg', filter);
    }
    return request.file.image.makeUrl(image.id, "file", "png");
  }
  return noImage;
};


export const makeUrlImageFromAlbums = (albums, size = 100) =>
  makeImageUrl(getMostImportantAlbum(albums), size);

export const getUpdateFormImageUrlFromItem = item => makeImageUrl(item, 300);

export const noop = () => {};

export const validateEmail = email => {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateUrl = (url, requireProtocol=false) => {
  var pattern = new RegExp(
    "^" +
    // protocol identifier (optional)
    // short syntax // still required
    // "(?:(?:(?:https?|ftp):)?\\/\\/)" +
    "(?:(?:(?:https?):)?\\/\\/)" + (!requireProtocol ? "?": "") +

    // user:pass BasicAuth (optional)
    // "(?:\\S+(?::\\S*)?@)?" +
    "(?:" +

    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
    "|" +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    "(?:" +
    "(?:" +
    "[a-z0-9\\u00a1-\\uffff]" +
    "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
    ")?" +
    "[a-z0-9\\u00a1-\\uffff]\\." +
    ")+" +
    // TLD identifier name, may end with dot
    "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
    ")" +
    
    // port number (optional)
    // "(?::\\d{2,5})?" +
    // resource path (optional)
    "(?:[/?#]\\S*)?" +

    "$", "i"
  );
  return !!pattern.test(url);
};

export const getValueFromQueryParam = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const makeBlobFromImage = image =>
  new Promise(resolve => {
    const img = new Image();
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");

    img.onload = function() {
      c.width = this.naturalWidth;
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0);
      c.toBlob(resolve, "image/jpeg", 0.75);
    };
    img.crossOrigin = "";
    img.src = image;
  });

const OWN_MEDIA_TYPES = {
  TELEVISION: "Television",
  RADIO: "Radio",
  INTERNET: "Internet",
  RADIO_INTERNET: "Radio e Internet",
  VPNT: "VPNT",
  TV_INCLUSION: "Inclusión en TV",
  ALL: "Todos los Medios",
  toArray: () => [
    OWN_MEDIA_TYPES.TELEVISION,
    OWN_MEDIA_TYPES.RADIO,
    OWN_MEDIA_TYPES.RADIO_INTERNET,
    OWN_MEDIA_TYPES.INTERNET,
    OWN_MEDIA_TYPES.ALL
  ]
};

export const mapMediaFromApiLicense = license => {
  switch (license.type.id) {
    case LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS:
      return OWN_MEDIA_TYPES.INTERNET;
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA:
      switch (license.category.id) {
        case SadaicCategories.RESTRINGIDO_I:
          return OWN_MEDIA_TYPES.TELEVISION;
        case SadaicCategories.RESTRINGIDO_II_III:
          return OWN_MEDIA_TYPES.RADIO;
        case SadaicCategories.RESTRINGIDO_IV:
          return OWN_MEDIA_TYPES.INTERNET;
        case SadaicCategories.USO_TOTAL:
          return OWN_MEDIA_TYPES.ALL;
        case SadaicCategories.VPNT:
          return OWN_MEDIA_TYPES.VPNT;
        case SadaicCategories.INCLUSION_EN_TV:
          return OWN_MEDIA_TYPES.TV_INCLUSION;
      }
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL:
      if (license.medias.length === 1) {
        switch (license.medias[0].id) {
          case MediaTypeIds.INTERNET:
            return OWN_MEDIA_TYPES.INTERNET;
          case MediaTypeIds.RADIO:
            return OWN_MEDIA_TYPES.RADIO;
          case MediaTypeIds.TELEVISION:
            return OWN_MEDIA_TYPES.TELEVISION;
          case MediaTypeIds.ALL_MEDIA:
            return OWN_MEDIA_TYPES.ALL;
        }
      } else {
        return OWN_MEDIA_TYPES.RADIO_INTERNET;
      }
  }
  return "";
};

export const parseDate = stringDate => {
  let date = new Date(stringDate);
  let year = "" + date.getFullYear();
  let month = "" + (date.getMonth() + 1);
  if (month.length == 1) {
    month = "0" + month;
  }
  let day = "" + date.getDate();
  if (day.length == 1) {
    day = "0" + day;
  }
  let hour = "" + date.getHours();
  if (hour.length == 1) {
    hour = "0" + hour;
  }
  let minute = "" + date.getMinutes();
  if (minute.length == 1) {
    minute = "0" + minute;
  }
  let second = "" + date.getSeconds();
  if (second.length == 1) {
    second = "0" + second;
  }
  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
};

export const toDateString = date => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}`;
};

export const getRangeOfNumber = (number, ranges) => {
  let rangeName = "";
  ranges.forEach(range => {
    if (range.min && range.max && number >= range.min && number <= range.max) {
      rangeName = range.name;
    } else if ((range.min && !range.max) || (!range.min && range.max)) {
      if (!range.min && number <= range.max) {
        rangeName = range.name;
      } else if (!range.max && number >= range.min) {
        rangeName = range.name;
      }
    }
  });
  return rangeName;
};

export const addHttpToUrl = url => {
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const getParameterByNameSavedQuery = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(:([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * As Component.setState does not execute immediately, executing stuff imperativelly might not always work.
 * This method will resolve with a promise so a .then will be available, ensuring whatever is called next will
 * have the changes of the setState.
 * @param {Component} ctx
 * @param {*} state
 */
export const setStatePromise = (ctx, state) =>
  new Promise(resolve => ctx.setState(state, resolve));

export const timeoutPromise = ({ time = 2000, fail = false } = {}) =>
  new Promise((resolve, reject) =>
    setTimeout(() => (fail ? reject() : resolve()), time)
  );

export const makeUrlWithTableParams = destinationPath => {
  let tableParams = queryString.parse(browserHistory.location.search)[
    "?table_params"
  ];
  if (tableParams) {
    return `${destinationPath}?table_params=${tableParams}`;
  } else {
    return destinationPath;
  }
};

export const pushIfDoesNotExist = (arr, obj, property = 'id') => {
  const index = arr.findIndex((e) => e[property] === obj[property]);
  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = obj;
  }
}

export const oneToX = (x) => {
  let array = []
  for(let i=1; i<(x+1); i++){
    array.push(i)
  }
  return array
}

export const oneToTen = () =>{
  let array = []
  for(let i=1; i<11; i++){
    array.push(i)
  }
  return array
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
};

export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
    return 'iPadOS'
  }
  return false;
}

export const isMobileDevice = () => !!getMobileOperatingSystem();

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isIOSDevice = () => getMobileOperatingSystem() === 'iOS';

export const isAndroidDevice = () => getMobileOperatingSystem() === 'Android';

export const isIpadOSDevice = () => getMobileOperatingSystem() === 'iPadOS';

export const makeSubtitlesLabel = (language) => {
  switch (language) {
    case 'es':
      return 'Español'
    case 'en':
      return 'Inglés'
    case 'it':
      return 'Italiano'
    case 'de':
      return 'Alemán'
    case 'fr':
      return 'Francés'
  }
}

export const downloadFromUrl = (url, name, extension, mimetype) =>
  fetch(url, { headers: { 'Accept': mimetype } })
    .then(response => response.arrayBuffer())
    .then(file => download(file, `${name}.${extension}`, mimetype))

export const copyStringToClipboard = (text) => {
  if (!navigator.clipboard) {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand('copy');
      let msg = successful ? 'copy.success' : 'copy.unsuccessful';
      let type = successful ? GlobalSnackbarTypes.SUCCESS : GlobalSnackbarTypes.ERROR;
      console.log(msg)
      GlobalSnackbar.show({
        message: localization.get(msg),
        type: type,
      })
    } catch (err) {
      GlobalSnackbar.show({
        message: localization.get('copy.unsuccessful'),
        type: GlobalSnackbarTypes.ERROR,
      })
    }

    document.body.removeChild(textArea);
    return;
  }

  navigator.clipboard.writeText(text).then(function() {
    GlobalSnackbar.show({
      message: localization.get('copy.success'),
      type: GlobalSnackbarTypes.SUCCESS,
    })
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
    GlobalSnackbar.show({
      message: localization.get('copy.unsuccessful'),
      type: GlobalSnackbarTypes.ERROR,
    })
  });
};

export const getDateInt = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();

  return year * 10000 + month * 100 + day;
}