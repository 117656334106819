import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";

const BatchUploadRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route
          render={props => (
            <All
              isTalent={window.location.pathname.startsWith("/panel/talents")}
              isAudiovisual={window.location.pathname.startsWith("/panel/audiovisual")}
              {...props}
            />
          )}
        />
      </Switch>
    </Router>
  )
}

export default BatchUploadRouting
