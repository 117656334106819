import React, {Component} from "react";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import localization from "../../../config/localization";

export default class extends Component {

  state = {
    users: []
  }

  componentWillMount = () => {
    request.messaging
      .usersForNewConversation('target', null, null, this.props.usersIds)
      .then(res => res.map(user => ({ value: user.id, label: user.name })))
      .then(users => this.setState({ users }))
  }

  getUsers = () => this.state.users.map(user => user.value)

  render = () => {
    return (
      <div>
        <span>{localization.get('users')}</span>
        <AsyncSelect
          styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
          value={this.state.users}
          isDisabled={true}
          defaultOptions
          isMulti
        />
      </div>
    );
  }
}