import React from 'react'
import {Checkbox as MaterialCheckbox} from '@material-ui/core'
import classNames from 'classnames'

export const SecondaryCheckbox = ({ checked, onClick, label, style = {} }) =>
  <div className='license-wizard-countries-per-region-checkbox' style={style}>
    <MaterialCheckbox color='default' checked={checked} onChange={onClick} />
    <div>{label}</div>
  </div>

const Checkbox = ({ content, checked, onClick, disabled = false, style = {} }) => {
  return (
    <div className={classNames('license-wizard-main-checkbox', { 'disabled': disabled })} style={style} onClick={() => {
      if (disabled) {
        return
      }
      onClick()
    }}>
      <MaterialCheckbox color='default' style={{ width: 10, height: 0 }} checked={checked} />{content}
    </div>
  )
}

export default Checkbox