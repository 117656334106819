import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {getActiveChannel, getStoredUser} from "../../../api-client/core/authentication/utils";
import Email from "@material-ui/icons/Email";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import ChannelPublicNavBar from "../../../layouts/Channel/ChannelPublicNavBar";
import Footer from "../../../layouts/Home/HomeElements/Footer";
import {channelPathGenerator} from "../../../api-client/core/authentication/utils";

class EmailActivate extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      channel: "",
      username: "",
    };
  }
  componentDidMount() {

    if (getStoredUser()) {
      request.authentication.logout()
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    request.channel.getPublic(getActiveChannel() ? getActiveChannel() : window.location.pathname.split('/')[2])
      .then(channel => {
        document.title = localization.get('email_activate') + ' - ' + channel.name
        this.setState({channel, requesting: false})
      })
  }
  validateEmail = (email) => {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email)
  }

  activate = () => {
    console.log(this.validateEmail(this.state.username))
    if(this.validateEmail(this.state.username)) {
      this.setState({ requesting: true })
      request.authentication.emailVerification({username: this.state.username, channelId: this.state.channel.id})
        .then(response => {
          if (response.status === "unapproved") {
            setTimeout(() => {
              window.location.href = response.url
            }, 100);
          } else {
            GlobalSnackbar.show({
              message: localization.get('activate.done'),
              type: GlobalSnackbarTypes.SUCCESS,
            })

            setTimeout(() => {
              window.location.href = channelPathGenerator('', this.state.channel)
            }, 100);
          }
        })
        .catch(() => {
          GlobalSnackbar.show({
            message: localization.get('activate.fail'),
            type: GlobalSnackbarTypes.ERROR,
          })

          this.setState({requesting: false})
        })
    }else {
      GlobalSnackbar.show({
        message: localization.get('forgot.fail'),
        type: GlobalSnackbarTypes.ERROR,
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { channel } = this.state;

    return (
      <React.Fragment>
        <ChannelPublicNavBar channel={channel} />
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>{localization.get('email_activate')}</h4>
                    </CardHeader>
                    <CardBody>
                      <p style={{ margin: "5px", fontSize: "0.875rem" }}>{localization.get('email_activate.aux')}</p>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ username: e.target.value }),
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.activate()
                            }
                          }
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.activate} color="default" style={{ backgroundColor: "black" }} simple size="lg" block disabled={this.state.requesting}>{localization.get('send')}</Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer channel={this.state.channel}/>
      </React.Fragment>
    );
  }
}

export default withStyles(loginPageStyle)(EmailActivate);
