import React, {Component} from "react";
import {getMaterialOriginalFileName} from ".";
import styled from "styled-components";

class AudioMessage extends Component {
  render = () => {
    const { audioSrc } = this.props;

    return (
      <React.Fragment>
        <div style={{ marginTop: 10 }}>
          <AudioR src={audioSrc} controls />
          <p>{getMaterialOriginalFileName(audioSrc)}</p>
        </div>
      </React.Fragment>
    );
  };
}

export default AudioMessage;

const AudioR = styled.audio`
margin-right: 10px;
@media (max-width: 450px) {
  width: 100%;
  
}
`;