import Login from "../../views/Login";
import {makeRoutes, publicRoutes, redirectTo, route} from "./utils";
import ForgotPassword from "../../views/ForgotPassword";
import ResetPassword from "../../views/ResetPassword";
import ErrorPage from "../../layouts/Channel/ErrorPage";
import Code from "../../layouts/Channel/Authorization/Code";
import Register from "../../views/Register";

const defaultComponent = Login;

const allowed = publicRoutes.concat([
  route("/login", defaultComponent),
  route("/password/forgot", ForgotPassword),
  route("/password/reset", ResetPassword),
  route(`/error-page`, ErrorPage),
  route(`/login-code`, Code),
  route(`/gcba/login-code`, Code),
  route(`/register`, Register),
]);

export default makeRoutes(allowed, [], route("/", redirectTo(`/login?redirect_to=${window.location.pathname}${window.location.search}`)));
