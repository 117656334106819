import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {unregister} from "./registerServiceWorker";

import "index.css";

const renderReactDom = () => {
    ReactDOM.render(<App />, document.getElementById("root"));
}

if (window.cordova) {
    document.addEventListener('deviceready', () => {
      onDeviceReady();
    }, false);
} else {
    renderReactDom();
}

function onDeviceReady() {
    console.log("DEVICE READY");
    renderReactDom();
    window.universalLinks && window.universalLinks.subscribe(null, handleDeepLink);
}

function handleDeepLink(eventData) {
    console.log('Showing page: ' + eventData.path);
    window.location.href = eventData.path;
}

unregister();
