import React, {Component} from "react";
import localization from '../../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../../utils";

export default class extends Component {
  state = {
    channel: null,
    code: "",
    discount: null,
    uses: null,
    quantity: null,
    expiresAt: null,
    active: true,
    description: "",
    products: [],
    includeAll: "1",
  }

  componentDidMount() {
    let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));

    if (selectedChannelFromLocalStorage) {
      this.setState({channel: selectedChannelFromLocalStorage});
    }
  }

  createPack = () => request.productCodes.create(this.state.channel.value, {
    code: this.state.code,
    discount: this.state.discount,
    uses: this.state.uses,
    quantity: this.state.quantity,
    expiresAt: this.state.expiresAt,
    active: this.state.active,
    description: this.state.description,
    products: this.state.products,
    includeAll: this.state.includeAll ===  "1",
  }).then(response => {
      GlobalSnackbar.show({
        message: localization.get('snackbar.entity_update_success'),
        type: GlobalSnackbarTypes.SUCCESS
      })
      this.props.history.push(makeUrlWithTableParams('/panel/products'))
  })
    .catch(response => {
      if (response.status === 400) {
        GlobalSnackbar.show({
          message: localization.get('snackbar.code_error_created'),
          type: GlobalSnackbarTypes.ERROR
        })
      }else {
        GlobalSnackbar.showGenericError()
      }
    })


  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onImageChange = image => image ? this.setState({imageId: image.id, image: { src:null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  onSubmitValidation = () => {
    if(!this.state.channel) {
      GlobalSnackbar.show({message: localization.get('must_complete_client'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }

  render = () => {

    return (
      <div>
        <FormViewContainer
          title={localization.get('form.create_code')}
          submitRequest={this.createPack}
          onBack={this.props.history.goBack}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
          errors={'error'}
          actionsExtraSpace={true}
        >
          <Form
            code={this.state.code}
            discount={this.state.discount}
            uses={this.state.uses}
            quantity={this.state.quantity}
            expiresAt={this.state.expiresAt}
            active={this.state.active}
            description={this.state.description}
            channel={this.state.channel}
            onChannelChange={channel => this.setState({ channel: null }, () => this.setState({ channel }))}
            onChange={this.onChange}
            products={this.state.products}
            onProductsChange={products => this.setState({ products })}
            includeAll={this.state.includeAll}
          />
        </FormViewContainer>
      </div>
    )
  }
}
