import React, {Component} from 'react';
import { getActiveChannel, getStoredChannel } from '../../../api-client/core/authentication/utils';
import "./GoogleAd.css"

export default class GoogleAdsenseAd extends Component {

  componentDidMount() {
    const installGoogleAds = (publisherID) => {
      const elem = document.createElement("script");
      elem.src =
        `//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-${publisherID}`;
      elem.crossOrigin = "anonymous";
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
  if (getActiveChannel() && getStoredChannel().google_publisher_id && this.props.data) {
      installGoogleAds(getStoredChannel().google_publisher_id);
      window.adsbygoogle && window.adsbygoogle.push({});
    }
  }

  render() {
    let {data} = this.props;
    return (
      <div style={{width: "100%"}} dangerouslySetInnerHTML={{__html: data}}>
      </div>
    );
  }
}