import React, {Component} from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip,
} from "@material-ui/core";
import HomeItemDescriptionTags from "../HomeElements/HomeItemDescriptionTags";
import {Add, Close, Edit} from "@material-ui/icons";
import {checkIfMobile, getActiveClient} from "../../../api-client/core/authentication/utils";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import {Link} from "react-router-dom";

const fileTypes = [ 'video', 'binary', 'image', 'audio',];

export class UploadMany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      public: true,
      isReel: false,
      showUploadDnD: false,
      sending: false,
      files: [],
      tags: props.tags,
      searchTags: props.tags,
      selectedTags: [],
      search: "",
      openTagDialog: false,
      uploadFrom: "pc",
      selectedContentCreations: [],
      contentCreator: null,
      namePrefix: "",
      selectedLabel: {value: "all", label: "All"},
      selectedFileTypes: fileTypes,
      filename: "",
      disableForm: false,
      link: null,
      links: [],
    }
  }

  componentWillMount() {
    let paginationConfig = {filterBy: [
        { column: 'for_creations', filter: 1 },
        { column: 'client_id', filter: getActiveClient() },
    ]}

    request.batchUpload.getAll(paginationConfig)
      .then(links => this.setState({
        links: links.map(link => ({
          value: link.id, label: `${link.name} (${link.files_count})`,
          count: link.files_count,
          fileLabels: (link.file_labels && link.file_labels.length > 0) ? link.file_labels.filter(label => label !== "").map(label => {
            return {value: label, label: label}
          }) : [],
          token: link.token,
        }))
      }))
      .then(this.requestTags)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.tags) {
      this.setState({searchTags: nextProps.tags, tags: nextProps.tags}, this.handleTagSearch)
    }
  }

  onSelectedTagChanged = selectedTag => {
    let index = this.state.selectedTags.indexOf(selectedTag)
    if (index === -1) {
      this.setState({
        selectedTags: this.state.selectedTags.concat(selectedTag)
      })
    } else {
      this.state.selectedTags.splice(index, 1)
      this.setState({
        selectedTags: this.state.selectedTags
      })
    }
  };

  onSubmit = () =>
    this.setState({disableForm: true},
      () => request.clientFile.storeMany({
    clientFiles: this.state.files,
    tagsIds: this.state.selectedTags,
    public: this.props.forReels ? true: this.state.public,
    isReel: this.props.forReels ? true: this.state.visibility === 'both',
    contentCreations: this.state.selectedContentCreations.map(c => c.value),
    linkId: this.state.link && this.state.link.value,
    namePrefix: this.state.namePrefix,
    selectedLabel: this.state.selectedLabel && this.state.selectedLabel.value,
    filename: this.state.filename,
    fileTypes: this.state.selectedFileTypes
  })
    .then(GlobalSnackbar.showGenericSuccess)
    .then(this.onClose))

  onFileAdd = (files) => this.setState({ files })
  onFileDelete = (fileId) => this.setState({ files: this.state.files.filter(file => file.id !== fileId) })
  onClose = () => this.setState({
      public: true,
      isReel: false,
      showUploadDnD: false,
      sending: false,
      files: [],
      searchTags: this.props.tags,
      tags: this.props.tags,
      selectedTags: [],
      search: "",
      selectedContentCreations: [],
      contentCreator: null,
      namePrefix: "",
      selectedLabel: {value: "all", label: "All"},
      selectedFileTypes: fileTypes,
      filename: "",
      disableForm: false,
      link: null,
      links: [],
      uploadFrom: "pc",
    }, this.props.handleClose);


  handleCloseDialog = () => {
    if (this.state.disableForm || this.state.files.length > 0) {
      GlobalConfirmationDialog.show({
        title: localization.get("drive.upload_many_exit"),
        content: localization.get("drive.upload_many_exit_confirmation"),
        request: () => new Promise(resolve => {
          this.setState({
            public: true,
            isReel: false,
            showUploadDnD: false,
            sending: false,
            files: [],
            searchTags: this.props.tags,
            tags: this.props.tags,
            selectedTags: [],
            search: "",
            selectedContentCreations: [],
            contentCreator: null,
            namePrefix: "",
            selectedLabel: {value: "all", label: "All"},
            selectedFileTypes: fileTypes,
            filename: "",
            disableForm: false,
            link: null,
            links: [],
            uploadFrom: "pc",
          }, () => {
            this.props.handleClose()
            resolve()
          });
        }),
        confirmationLabel: localization.get("drive.upload_many_exit_confirm"),
        exitButtonText: localization.get("drive.upload_many_exit_keep"),
      });
    }else {
      this.onClose();
    }
  }

  addTagDialog = () => {
    const options = []

    for (let  i = 1; i < 11; i++) {
      options.push(<MenuItem value={i}>{i}</MenuItem>)
    }

    return(
      <Dialog open={this.state.openTagDialog} onBackdropClick={() => this.setState({openTagDialog: false, newTag: '', tagLevel: null, editingTag: false})}
              style={{padding: 10, margin: "-30px"}}>
        <DialogTitle>
          {this.state.editingTag ?  localization.get("tag.update_tag") : localization.get("tag.create_tag")}
        </DialogTitle>
        <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={() => this.setState({openTagDialog: false, newTag: '', tagLevel: null, editingTag: false})}>
          <Close />
        </IconButton>
        <DialogContent>
          <ValidatorForm onSubmit={this.onTagSave}>
            <TextValidator
              style={window.innerWidth > 500 ? {width: "80%", margin: "15px 50px 0"} : {width: "100%", marginTop: 15}}
              label={localization.get('form.name')}
              value={this.state.newTag}
              onChange={e => {
                this.setState({newTag: e.target.value})
              }}
              validators={['required']}
              name="name"
              errorMessages={[localization.get('validator.is_required')]}
            />
            <FormControl style={window.innerWidth > 500 ? {width: "80%", margin: "15px 50px 0"} : {width: "100%", marginTop: 15}}>
              <InputLabel>{localization.get('level')}</InputLabel>
              <Select
                value={this.state.tagLevel}
                onChange={e => this.setState({tagLevel: e.target.value})}
              >
                <MenuItem value={localization.get("no_level")}>{localization.get("no_level")}</MenuItem>
                {options}
              </Select>
            </FormControl>
          </ValidatorForm>
        </DialogContent>
        <DialogActions style={{marginBottom: 10}}>
          {this.state.editingTag &&
            <Button onClick={() => this.handleTagDelete({tagId: this.state.editingTag, tagName: this.state.newTag})} disabled={this.state.sending}>
              {localization.get("delete")}
            </Button>
          }
          <Button onClick={this.onTagSave} disabled={this.state.sending}>
            {localization.get("send")}
          </Button>
        </DialogActions>
      </Dialog>
    )}

  handleTagSearch = () =>
    this.setState({
      searchTags: this.state.tags.filter(tag => {
        return tag.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
      })
    });

  showTagDialog = () => this.setState({openTagDialog: true});
  handleCloseTagDialog = () => this.setState(
    {newTag: '', openTagDialog: false, editingTag: false, tagLevel: null},
    () => this.props.requestTags())

  onTagSave = () => {
    if (this.state.editingTag) {
      return request.clientTags.update({
        clientTagId: this.state.editingTag,
        name:this.state.newTag,
        level:this.state.tagLevel
      }).then(() => this.handleCloseTagDialog());
    } else {
      return request.clientTags.create({name: this.state.newTag, isReel: true, level: this.state.tagLevel})
        .then(() => this.setState({newTag: '', openTagDialog: false, tagLevel: null, selectedTags: []}))
        .then(() => this.handleCloseTagDialog())
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onBackdropClick={this.handleCloseDialog}
        onEscapeKeyDown={this.handleCloseDialog}
      >
        {this.addTagDialog()}
        <DialogTitle>{localization.get('drive.upload_many')}</DialogTitle>
        <DialogContent style={{width: checkIfMobile() ? 290 : 600}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{width: "100%", maxWidth: 800, margin: "auto", paddingTop: 30}}>
              <FormControl>
                <RadioGroup
                  name="uploadRadio"
                  value={this.state.uploadFrom}
                  onChange={event => this.setState({
                    uploadFrom: event.target.value,
                    materialSelection: '',
                    contentCreator: null,
                    selectedContentCreations: [],
                    selectedBatchFile: '',
                    selectedBatchLink: '',
                    selectedLabel: {value: "all", label: "All"},
                    selectedFileTypes: fileTypes,
                    filename: "",
                    link: null,
                    disableForm: false,
                  })}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="pc" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
                  <FormControlLabel value="platform" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_platform')}/>
                  <FormControlLabel value="link" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
                </RadioGroup>
              </FormControl>
              {this.state.uploadFrom === 'pc' ?
                <>
                  {this.props.forReels ?
                    <FilePondUploader
                      disableForm={this.state.disableForm}
                      updateDisableForm={(val) => this.setState({disableForm: val})}
                      onIdChange={(batchFiles) => this.onFileAdd(batchFiles)}
                      isMultiple={true}
                      maxFiles={1000}
                      batchUpload={true}
                      allowedTypes={["video/mp4","video/x-m4v","video/*"]}
                      onBatchFileDelete={(fileId) => this.onFileDelete(fileId)}
                      clientId={getActiveClient()}
                    />:
                    <FilePondUploader
                      disableForm={this.state.disableForm}
                      updateDisableForm={(val) => this.setState({disableForm: val})}
                      onIdChange={(batchFiles) => this.onFileAdd(batchFiles)}
                      isMultiple={true}
                      maxFiles={1000}
                      batchUpload={true}
                      onBatchFileDelete={(fileId) => this.onFileDelete(fileId)}
                      clientId={getActiveClient()}
                    />
                  }
                </>
                : this.state.uploadFrom === 'link' ?
                  <>
                    <label>{localization.get("links")}</label>
                    <AsyncSelect
                      ref="select"
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(link) => this.setState({
                        link,
                        selectedLabel: {value: "all", label: localization.get("label.not_use")}
                      })}
                      value={this.state.link}
                      defaultOptions={this.state.links}
                      isClearable={true}
                      loadOptions={(inputValue, callback) => {
                        callback(this.state.links.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                    />
                    {!!this.state.link && !!this.state.link.fileLabels && this.state.link.fileLabels.length > 0 &&
                      <>
                        <FormControl fullWidth>
                          <label>{localization.get("label")}</label>
                          <AsyncSelect
                            ref="select"
                            styles={{
                              menu: base => ({
                                ...base,
                                zIndex: 10
                              })
                            }}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 5,
                            })}
                            placeholder={localization.get('conversation.filter_users')}
                            loadingMessage={() => localization.get('loading')}
                            noOptionsMessage={() => localization.get('no_options')}
                            onChange={(selectedLabel) => this.setState({selectedLabel, namePrefix: `${selectedLabel.value !== "all" ? selectedLabel.label: ""}${this.state.namePrefix}`})}
                            value={this.state.selectedLabel}
                            defaultOptions={[{
                              value: "all",
                              label: localization.get("label.not_use")
                            }].concat(this.state.link.fileLabels)}
                            isClearable={true}
                            loadOptions={(inputValue, callback) => {
                              callback(this.state.link.fileLabels.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                            }}
                          />
                          {this.state.selectedLabel && this.state.selectedLabel.value !== 'all' &&
                            <Link
                              to={`${process.env.REACT_APP_FRONT_HOST}/public/batch_upload/20${this.state.link.token.split("").reverse().join("")}-1?folder=${this.state.selectedLabel.value}`}
                              target={"_blank"}
                            >
                              {localization.get('label.go_to')}
                            </Link>
                          }
                        </FormControl>
                      </>
                    }

                    <div style={{width: "100%", position: "relative", float: "left", marginTop: 15}}>
                      <TextField
                        style={{marginBottom: 15}}
                        placeholder={localization.get('search.by_filename')}
                        value={this.state.filename}
                        onChange={e => this.setState({filename: e.target.value})}
                        helperText={localization.get('search.by_filename_helper')}
                        fullWidth
                      />
                      <TextField
                        style={{marginBottom: 15}}
                        placeholder={localization.get('content_creations.prefix_name')}
                        value={this.state.namePrefix}
                        onChange={e => this.setState({namePrefix: e.target.value})}
                        helperText={localization.get('content_creations.prefix_name_helper')}
                        fullWidth
                      />
                      <br/>
                      {!this.state.forReels &&
                        <>
                          <label>{localization.get("catalog.content_type")}</label>
                          <div>
                            {fileTypes.map(type =>
                              <FormControlLabel
                                style={{position: "relative", float: "left"}}
                                control={
                                  <Checkbox
                                    style={{color: "#4cc2d5"}}
                                    checked={this.state.selectedFileTypes.includes(type)}
                                    onChange={() => {
                                      if (this.state.selectedFileTypes.includes(type)) {
                                        this.setState({selectedFileTypes: this.state.selectedFileTypes.filter(t => type !== t)})
                                      } else {
                                        this.state.selectedFileTypes.push(type);
                                        this.setState({selectedFileTypes: this.state.selectedFileTypes})
                                      }
                                    }}
                                  />
                                }
                                label={localization.get(type)}
                              />
                            )}
                          </div>
                        </>
                      }
                    </div>
                  </>
                  :
                  <div>
                    <div style={{marginBottom: "10px", color: "#757575"}}>{this.state.isReel ? localization.get('helper_text.upload_from_drive_to_reel') : localization.get('helper_text.upload_from_reel_to_drive')}</div>
                    <span>{localization.get('content_creators')}</span>
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('conversation.filter_users')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(e) => this.setState({contentCreator: null, selectedContentCreations: []}, () => this.setState({contentCreator: e}))}
                      value={this.state.contentCreator}
                      defaultOptions
                      loadOptions={inputValue => {
                        const fileTypeFilter = this.state.isReel ? "only_video" : "for_drive";
                        let filters = [{column: "name", filter: inputValue}, {column: fileTypeFilter, filter: true}, {column: "client_id", filter: getActiveClient()}]

                        return request.contentCreator
                          .getAll({filterBy: filters})
                          .then(contentCreators => contentCreators.map(contentCreator => ({
                            value: contentCreator.id,
                            label: contentCreator.name
                          })))
                          .then(contentCreators => contentCreators.sort((a, b) => a.label.localeCompare(b.label)))
                      }
                      }
                    />
                    {this.state.contentCreator &&
                      <div style={{marginTop: '5px'}}>
                        <span>{localization.get('content_creations')}</span>
                        <AsyncSelect
                          styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 10
                            })
                          }}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0
                          })}
                          placeholder={localization.get('conversation.filter_users')}
                          loadingMessage={() => localization.get('loading')}
                          value={this.state.selectedContentCreations}
                          noOptionsMessage={() => localization.get('no_options')}
                          defaultOptions
                          onChange={(e) => this.setState({selectedContentCreations: e})}
                          isClearable={true}
                          isMulti
                          loadOptions={inputValue => {
                            let filters = [{column: "title", filter: inputValue}]

                            if (this.state.isReel) {
                              filters.push({column: "has_video", filter: true})
                            }

                            this.state.contentCreator && filters.push({
                              column: "content_creator_id",
                              filter: this.state.contentCreator.value
                            })

                            return request.contentCreation
                              .getAll({filterBy: filters})
                              .then(contentCreations => contentCreations.map(contentCreation => ({
                                value: contentCreation.id,
                                label: contentCreation.title
                              })))
                              .then(contentCreations => contentCreations.sort((a, b) => a.label.localeCompare(b.label)))
                          }
                          }
                        />
                      </div>
                    }
                  </div>
              }
            </div>
            {!this.props.forReels &&
              <div>
                <h4>{localization.get('form.visibility')}</h4>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="public"
                      style={{color: "black"}}
                      checked={this.state.public}
                      onChange={() => this.setState({public: !this.state.public})}
                    />
                  }
                  style={{position: "relative", float: "left",}}
                  label={localization.get('form.public')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="visibility"
                      style={{color: "black"}}
                      checked={this.state.visibility === 'both'}
                      onChange={() => this.setState({visibility: this.state.visibility === 'both' ? 'drive' : 'both'})}
                    />
                  }
                  style={{position: "relative", float: "left",}}
                  label={localization.get(this.state.isReel ? 'form.show_on_drive' : 'form.show_on_reels')}
                />
              </div>
            }
            <h3 className="mt-5" style={{textAlign: "left"}}>{localization.get('tags')}
              <Button
                onClick={() => this.showTagDialog()}
                style={{height: 35, width: 35, backgroundColor: "black", left: 15}}
                variant="fab" color="primary" aria-label="Add"
              >
                <Tooltip
                  title={localization.get('file.tag_create')}
                  placement='top'>
                  <Add/>
                </Tooltip>
              </Button>
            </h3>
          {this.props.tags && this.props.tags.length > 0 &&
            <>
              <FormControl style={{width: "100%", padding: "0 7px"}}>
                <TextField
                  style={{marginBottom: 20}}
                  value={this.state.search}
                  label={localization.get("search")}
                  onChange={e => this.setState({search: e.target.value}, () => setTimeout(this.handleTagSearch(), 200))}
                  fullWidth
                />
              </FormControl>
              <div className="tags-container">
                <h4>{localization.get('tags')}</h4>
                {(this.state.searchTags && this.state.searchTags.length > 0) ?
                  [1,2,3,4,5,6,7,8,9,10,null].map(element => {
                    const tags = this.state.searchTags.filter(tag => tag.level === element)
                    return (
                      <>
                        {tags.length > 0 &&
                          <div style={{width: "100%", marginBottom: 10}}>
                            <HomeItemDescriptionTags
                              displayOnMobile={true}
                              outlined
                              actionIcon={<Edit style={{fontSize: 15}}/>}
                              tags={tags}
                              onSelectedTagChanged={this.onSelectedTagChanged}/>
                          </div>
                        }
                      </>)
                  })
                :
                  <div style={{width: "100%", height: "75%",  marginBottom: 10, display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                    {localization.get('searchbar.no_result')}
                  </div>
                }
              </div>
            </>
          }
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} disabled={this.state.disableForm}>
            {localization.get("cancel")}
          </Button>
          <Button onClick={this.onSubmit} disabled={this.state.disableForm || (this.state.files.length === 0 && this.state.selectedContentCreations.length === 0 && !this.state.link)} >
            {localization.get("drive.upload", this.state.link ? "" : (this.state.files.length || this.state.selectedContentCreations.length))}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}