import React, {Component} from "react";
import localization from "../../config/localization";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SelectMaterial from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import {request} from 'api-client';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AsyncSelect from "react-select/lib/Async";

class ExportEntities extends Component {

  state = {
    selectedEntity: false,
    clients: [],
    brands: [],
    contentCreatorTypes: [],
    filterBy: "clients"
  }

  generateDownloadLink = () =>
    request.authentication.refreshAccessToken()
      .then(() => {
        if (this.state.selectedEntity == "user") {
          return request.user.exportUsers(this.state.clients, this.state.brands);
        }

        return request.authentication.refreshAccessToken().then(() => request.contentCreator.exportAll(this.state.contentCreatorTypes));
      })


  componentDidMount = () => {
    document.title = localization.get('comercial.export_entities') + ' - Feater';
  }

  render = () => {
    const {brands, clients, contentCreatorTypes} = this.state;

    const entities = [
      {value: 'user', label: localization.get('comercial.user')},
      {value: 'content_creator', label: localization.get('content_creators')},

    ];

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <FormControl fullWidth>
                <InputLabel>{localization.get("comercial.entity")}</InputLabel>
                <SelectMaterial
                  value={this.state.selectedEntity}
                  onChange={(e) => {
                    this.setState({
                      selectedEntity: e.target.value,
                    })
                  }}
                  style={{marginBottom: "20px"}}
                >
                  <MenuItem value={false} selected>{localization.get('select_an_option')}</MenuItem>
                  {entities.map(entity => <MenuItem value={entity.value}>{entity.label}</MenuItem>)}
                </SelectMaterial>
              </FormControl>
              <br/>
              {this.state.selectedEntity === 'user' &&
                <>
                <FormControl fullWidth>
                  <InputLabel>{localization.get("comercial.filter_by")}</InputLabel>
                  <SelectMaterial
                    value={this.state.filterBy}
                    onChange={event => this.setState({filterBy: event.target.value, clients: [], brands: []})}
                    style={{marginBottom: "20px"}}
                  >
                    <MenuItem value={"clients"} selected>{localization.get('clients')}</MenuItem>
                    <MenuItem value={"brands"} selected>{localization.get('brands')}</MenuItem>
                  </SelectMaterial>
                </FormControl>
                  {this.state.filterBy === "clients" ?
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('client')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(clients) => this.setState({clients})}
                      value={clients}
                      isMulti
                      isClearable={true}
                      loadOptions={inputValue => {
                        let filters = [{column: "name", filter: inputValue}]

                        return request.client.getAll({filterBy: filters})
                          .then(clients => clients.map(client => ({
                            value: client.id,
                            label: client.name
                          })))
                          .then(clients => clients.sort((a, b) => a.label.localeCompare(b.label)))
                      }}
                    />
                  :
                    <AsyncSelect
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 10
                        })
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                      })}
                      placeholder={localization.get('brand')}
                      loadingMessage={() => localization.get('loading')}
                      noOptionsMessage={() => localization.get('no_options')}
                      onChange={(brands) => this.setState({ brands })}
                      value={brands}
                      isMulti
                      isClearable={true}
                      loadOptions={inputValue =>
                        request.brands.getAll({filterBy: [{column: "name", filter: inputValue}]})
                          .then(res => res.map(brand => ({value: brand.id, label: brand.name})))
                      }
                    />
                  }
                  <br/><br/>
                </>
              }
              {this.state.selectedEntity === 'content_creator' &&
              <>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                  })}
                  placeholder={localization.get('container_type')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={(contentCreatorTypes) => this.setState({contentCreatorTypes})}
                  value={contentCreatorTypes}
                  defaultOptions
                  isMulti
                  isClearable={true}
                  loadOptions={inputValue => {
                    let filters = [{column: "name", filter: inputValue}]

                    return request.contentCreatorType.getAll({filterBy: filters})
                      .then(res => res.map(contentCreatorType => ({
                        value: contentCreatorType.slug,
                        label: contentCreatorType.name
                      })))
                      .then(contentCreatorTypes => contentCreatorTypes.sort((a, b) => a.label.localeCompare(b.label)))
                  }}
                />
                <br/><br/>
              </>
              }
              <Link
                to="#"
                onClick={e =>{
                  e.preventDefault();
                  this.generateDownloadLink()
                    .then(url => window.open(url, "_blank"))
                }}>
                <Button style={{float: "right", top: "-4px"}} disabled={!this.state.selectedEntity}  color={'info'}>{localization.get('download')}</Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default ExportEntities
