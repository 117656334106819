import React, {Component} from 'react'


class Gdrive extends Component {

  componentDidMount = () => {
    window.open('https://drive.google.com/drive/u/0/folders/1Mei_MPJ0j46nnPJQYpB423Gajk2wFf4H', '_blank');
    this.props.history.goBack()
  }

  render = () => <div/>
}

export default Gdrive
