import React, {Component} from "react";
import {Button, Checkbox, FormControlLabel, IconButton, Input, TextField} from "@material-ui/core";
import {Add, Delete, Done} from "@material-ui/icons";
import localization from "../../config/localization";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import {request} from "../../api-client";
import browserHistory from "../../utils/browserHistory";
import GlobalSnackbar from "../../components/Letflow/Snackbar/GlobalSnackbar";
import {
  getActiveClient,
  userisMultiClient
} from "../../api-client/core/authentication/utils";
import {validateEmail} from "../../utils";

class InviteExternalUsers extends Component {
  state = {
    current: ""
  };

  isValid = () => validateEmail(this.state.current) && !this.props.emails.includes(this.state.current);

  onAddClick = () => {
    this.props.onEmailsChange(this.props.emails.concat([this.state.current]));
    this.setState({ current: "" });
  };

  onRemoveClick = email => {
    this.props.onEmailsChange(this.props.emails.filter(x => x !== email));
  };

  render = () => (
    <InviteExternalUsersView
      current={this.state.current}
      onCurrentChange={current => this.setState({ current })}
      emails={this.props.emails}
      addButtonDisabled={!this.isValid()}
      onAddClick={this.onAddClick}
      onRemoveClick={this.onRemoveClick}
    />
  );
}

const InviteExternalUsersView = ({
  current,
  onCurrentChange,
  emails,
  onAddClick,
  onRemoveClick,
  addButtonDisabled
}) => {
  return (
    <section style={{ marginBottom: 30 }}>
      <h4>{localization.get("create_pitch.invite_external_users")}</h4>
      <p>
        {localization.get("create_pitch.invite_external_users_explanation")}
      </p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          style={{ flexBasis: "calc(100% - 50px)", marginRight: 10 }}
          value={current}
          onChange={e => onCurrentChange(e.target.value)}
        />
        <IconButton
          style={{ width: 24, height: 24 }}
          onClick={onAddClick}
          disabled={addButtonDisabled}
        >
          <Add onClick={onAddClick} />
        </IconButton>
      </div>
      <div style={{ height: 10 }} />
      <h4>{localization.get("create_pitch.added_external_users")}</h4>
      {emails.map((x, i) => (
        <SelectedUserView key={i} name={x} onRemove={() => onRemoveClick(x)} />
      ))}
      {emails.length === 0 && (
        <p>{localization.get("create_pitch.no_external_users_added")}</p>
      )}
    </section>
  );
};

const SelectedUserView = ({ name, isAdmin, onAdminChange, onRemove }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <Done style={{ marginRight: 10, color: "green" }} />
      <span>{name}</span>
    </div>
    <div style={{ display: "flex", alignItems: "center" }}>
      {onAdminChange && (
        <React.Fragment>
          <span>{localization.get("pitches.pitch_admin")}</span>
          <input
            type="checkbox"
            value={isAdmin}
            onChange={() => onAdminChange(!isAdmin)}
          />
        </React.Fragment>
      )}
      <IconButton style={{ width: 24, height: 24 }} onClick={onRemove}>
        <Delete style={{ fontSize: 17 }} />
      </IconButton>
    </div>
  </div>
);

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFlowlikePrivateProject: false,
      withFlowlikeAdmins: false,
      flowlikeAdminUsers: [],
      clients: [],
      clientUsers: [],
      projectName: "",
      projectDetails: "",
      firstMessage: "",
      submitting: false,
      externalUsers: [],
      usersToSelect: []
    };
  }

  componentDidMount = () => {
    document.getElementsByTagName("html")[0].scrollTop = 0;
    if (this.props.onlyForActiveClient) {
      request.client.get(getActiveClient(), '')
        .then(client => {
          this.setState({
            clients: [{value: client.id, label: client.name}],
            isFlowlikePrivateProject: false
          });
        })
    }
  }

  render = () => (
    <div style={{ display: "flex", justifyContent: "center", padding:"2% 10%", background: "white", minHeight: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 700
        }}
      >
        <this.Title />

        {!this.props.onlyForActiveClient && !(userisMultiClient()) && (
          <this.FlowlikePrivateProjectCheckbox />
        )}

        <this.Subtitle localizationKey="select_pitch_users" />

        {this.state.isFlowlikePrivateProject ? (
          <this.FlowlikePrivateProject />
        ) : (
          <this.ClientProject />
        )}

        <InviteExternalUsers
          emails={this.state.externalUsers}
          onEmailsChange={x => this.setState({ externalUsers: x })}
        />

        <this.Subtitle localizationKey="fill_pitch_details" />

        <this.ProjectName />

        <this.ProjectDetails />

        <this.Subtitle localizationKey="pitch_initial_message_title" />

        <this.InitialMessage />

        <this.SubmitButton />
      </div>
    </div>
  );

  Title = () => <h1>{localization.get("pitches.create_pitch")}</h1>;

  FlowlikePrivateProjectCheckbox = () => {
    const onChange = () => {
      const newValue = !this.state.isFlowlikePrivateProject;
      this.setState({
        isFlowlikePrivateProject: newValue,
        flowlikeAdminUsers: [],
        clientUsers: [],
        client: null
      });
    };

    return (
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={this.state.isFlowlikePrivateProject}
            onChange={onChange}
          />
        }
        label={localization.get("is_flowlike_private_project")}
      />
    );
  };

  Subtitle = ({ localizationKey }) => (
    <h4>{localization.get(localizationKey)}</h4>
  );

  SelectFlowlikeAdminsCheckbox = () => {
    const onChange = () => {
      const newValue = !this.state.withFlowlikeAdmins;
      this.setState({
        withFlowlikeAdmins: newValue,
        flowlikeAdminUsers: !newValue ? [] : this.state.flowlikeAdminUsers
      });
    };

    return (
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={this.state.withFlowlikeAdmins}
            onChange={onChange}
          />
        }
        label={localization.get("include_flowlike_admins", 'Flowlike')}
      />
    );
  };

  FlowlikePrivateProject = () => (
    <React.Fragment>
      <this.SelectFlowlikeAdmins />
      <this.SelectedFlowlikeAdmins />
    </React.Fragment>
  );

  ClientProject = () => (
    <React.Fragment>
      <this.SelectFlowlikeAdminsCheckbox />
      {this.state.withFlowlikeAdmins && <this.WithFlowlikeAdmins />}
      <this.SelectClient />
      {this.state.clients.length > 0 && <this.SelectClientUsers />}
      <this.SelectedClientUsers />
    </React.Fragment>
  );

  ProjectName = () => (
    <this.TextFieldWrapper id="projectName" labelKey="pitches.project_name" />
  );

  ProjectDetails = () => (
    <this.TextFieldWrapper
      id="projectDetails"
      labelKey="pitches.project_details"
      multiline
    />
  );

  InitialMessage = () => (
    <this.TextFieldWrapper
      id="firstMessage"
      labelKey="pitches.first_message"
      multiline
    />
  );

  SubmitButton = () => {
    const { submitting, projectName, projectDetails } = this.state;

    let disabled = submitting || projectName.length === 0 || projectDetails.length === 0;

    return (
      <div style={{ textAlign: "right" }}>
        <Button variant="contained" onClick={this.onSubmit} disabled={disabled}>{localization.get("send")}</Button>
      </div>
    );
  };

  WithFlowlikeAdmins = () => (
    <React.Fragment>
      <this.SelectFlowlikeAdmins />
      <this.SelectedFlowlikeAdmins />
      <div
        style={{
          marginBottom: 10,
          height: 1,
          width: "100%",
          backgroundColor: "#8a8a8a"
        }}
      />
    </React.Fragment>
  );

  SelectFlowlikeAdmins = () => {
    const loadOptions = val =>
      request.messaging
        .usersForNewConversation( "administrator", val)
        .then(res =>
          res.map(user => {
            let label;
            if (user.clientRoles && user.clientRoles.length > 0) {
              label = `${user.name} | (${user.clientRoles[0].client.name})`
            } else {
              label = `${user.name} | (${user.role.description})`
            }
            return ({
              value: user.id,
              label
            })
          })
        );

    const onChange = user => {
      const userIsAlreadySelected = this.state.flowlikeAdminUsers
        .map(x => x.value)
        .includes(user.value);
      if (userIsAlreadySelected) return;
      const addableUser = { ...user, is_admin: true };
      this.setState({
        flowlikeAdminUsers: this.state.flowlikeAdminUsers.concat([addableUser])
      });
    };

    return (
      <this.AsyncSelectWrapper
        value={null}
        titleKey="select_participating_flowlike_admin_users"
        loadOptions={loadOptions}
        onChange={onChange}
        defaultOptions
      />
    );
  };

  SelectClient = () => {
    const loadOptions = val =>
      request.messaging
        .clientsForNewConversation(val, getActiveClient())
        .then(res =>
          res.map(client => ({ value: client.id, label: client.name }))
        );

    return (
      <this.AsyncSelectWrapper
        titleKey="for_client"
        value={this.state.clients}
        loadOptions={loadOptions}
        isMulti
        onChange={clients => this.setState({ clients })}
        defaultOptions
      />
    );
  };

  SelectClientUsers = () => {
    const loadOptions = val =>
      request.messaging
        .usersForNewConversation("user", val, this.state.clients.map(client => client.value))
        .then(res => this.setState({
          usersToSelect: res.map(user => {
            let label;
            if (user.clientRoles && user.clientRoles.length > 0) {
              label = `${user.name} | (${user.clientRoles[0].client.name})`
            } else {
              label = user.name
            }
            return ({
              value: user.id,
              label
            })
          })
        }));

    const onChange = user => {
      const userIsAlreadySelected = this.state.clientUsers
        .map(x => x.value)
        .includes(user.value);
      if (userIsAlreadySelected) return;
      const addableUser = { ...user, is_admin: false };
      this.setState({
        clientUsers: this.state.clientUsers.concat([addableUser])
      });
    };

    return (
      <this.SelectWrapper
        value={null}
        onMenuOpen={loadOptions}
        titleKey="select_participating_client_users"
        options={this.state.usersToSelect}
        onChange={onChange}
      />
    );
  };

  SelectedClientUsers = () =>
    this.state.clientUsers.map(user => (
      <SelectedUserView
        key={user.value}
        name={user.label}
        isAdmin={user.is_admin}
        onAdminChange={() => (user.is_admin = !user.is_admin)}
        onRemove={() =>
          this.setState({
            clientUsers: this.state.clientUsers.filter(x => x !== user)
          })
        }
      />
    ));

  SelectedFlowlikeAdmins = () =>
    this.state.flowlikeAdminUsers.map(user => (
      <SelectedUserView
        key={user.value}
        name={user.label}
        onRemove={() =>
          this.setState({
            flowlikeAdminUsers: this.state.flowlikeAdminUsers.filter(
              x => x !== user
            )
          })
        }
      />
    ));

  AsyncSelectWrapper = ({ titleKey, ...props }) => (
    <React.Fragment>
      <p style={{ fontSize: 12 }}>{localization.get(titleKey)}</p>
      <AsyncSelect
        styles={{ container: base => ({ ...base, marginBottom: 20 }) }}
        {...props}
      />
    </React.Fragment>
  );

  SelectWrapper = ({ titleKey, ...props }) => (
    <React.Fragment>
      <p style={{ fontSize: 12 }}>{localization.get(titleKey)}</p>
      <Select
        styles={{ container: base => ({ ...base, marginBottom: 20 }) }}
        {...props}
      />
    </React.Fragment>
  );

  TextFieldWrapper = ({ id, labelKey, multiline }) => (
    <TextField
      style={{ marginBottom: 20 }}
      id={id}
      label={localization.get(labelKey)}
      onChange={e => this.setState({ [e.target.id]: e.target.value })}
      multiline={multiline}
    />
  );

  onSubmit = async () => {
    this.setState({ submitting: true });

    const {
      flowlikeAdminUsers,
      clientUsers,
      projectDetails,
      projectName,
      firstMessage,
      clients,
      externalUsers
    } = this.state;

    const participants = flowlikeAdminUsers
      .concat(clientUsers)
      .map(x => ({ id: x.value, is_admin: x.is_admin }));

    try {
      const createdPitch = await request.pitch.create({
        project_name: projectName,
        project_details: projectDetails,
        participants,
        first_message: firstMessage,
        clients_ids: clients.map(client => client.value)
      });

      await externalUsers.reduce(
        (acc, next) =>
          acc.then(() =>
            request.user.createProjectInvitee(next, createdPitch.id)
          ),
        Promise.resolve()
      );

      GlobalSnackbar.showGenericSuccess();

      browserHistory.push(this.props.makeCreatedPitchPath(createdPitch.id));
    } catch (e) {
      GlobalSnackbar.showGenericError();
      this.setState({ submitting: false });
    }
  };
}
