import create from './create'
import getAll from './getAll'
import update from './update'
import countPending from './countPending'

export default host => ({
  create: create(host),
  getAll: getAll(host),
  update: update(host),
  countPending: countPending(host),
})