import React, {Component} from "react";
import TrialsFormView from "./TrialsFormView";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {makeUrlWithTableParams} from "../../../utils";

class FormContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      state: props.state,
      comments: props.comments
    }
  }

  handleSubmitRequest = () => this.props.submitRequest(this.state)
    .then(() => { this.props.history.push(makeUrlWithTableParams('/trials')) })

  render = () => {

    const {
      disabled,
      state,
      comments
    } = this.state

    const {
      name,
      email
    } = this.props;

    return (
      <div>
        <FormViewContainer
          title={this.props.formTitle}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.handleSubmitRequest}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <TrialsFormView
            name={name}
            email={email}
            state={state}
            comments={comments}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            disabled={disabled}
          />
        </FormViewContainer>
      </div>
    )
  }
}


export default FormContainer