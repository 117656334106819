import React, {Component} from "react";
import {IconButton} from "@material-ui/core";
import {GetApp} from "@material-ui/icons";
import {request} from "../../../../api-client";
import {getContentFromMaterial, getMaterialOriginalFileName} from ".";
import styled from "styled-components";

const buttonStyle = {
  height: 40,
  width: 40,
  backgroundColor: "#e0e0e0",
  marginBottom: 10,
  boxShadow: `0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)`
};

export const DownloadButton = ({ style = {}, link }) => (
  <a href={request.pitch.downloadMaterialLink(link)} download>
    <IconButton style={{ ...buttonStyle, ...style }}>
      <GetApp />
    </IconButton>
  </a>
);

class VideoMessage extends Component {
  render = () => {
    const { message } = this.props;

    const videoSrc = getContentFromMaterial(message)

    return (
      <div style={{ marginTop: 10 }}>
        <VideoR
          src={videoSrc}
          controls
        />
        <p>{getMaterialOriginalFileName(videoSrc)}</p>
      </div>
    );
  };
}

export default VideoMessage;

const VideoR = styled.video`
height: 250px;
@media (max-width: 600px) {
  height: 150px;
}
@media (max-width: 370px) {
  height: 140px;
}
@media (max-width: 350px) {
  height: 120px;
}
`;
