import React, {Component} from 'react'
import './index.css'
import MusicsImage from "../../../assets/img/Letflow/SmartSearch/musics.jpg"
import localization from "../../../config/localization"
import {Link} from 'react-router-dom'
import {
  clientCanSeeContentCreators, clientIsTypeCatalog,
  homePathGenerator,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import {makeImageUrlWithSize, emptyImage} from "../HomeElements/utils";
import {request} from "../../../api-client";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";

class SmartSearch extends Component {

  state = {
    width: window.innerWidth,
    contentCreatorTypes: [],
    gettingCreators: true,
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.smart_search'))
    document.getElementsByTagName("html")[0].scrollTop = 0;
    window.addEventListener('resize', this.setWindowsWidth)
    if (clientCanSeeContentCreators(this.props.client)) {
      if(clientIsTypeCatalog()) {
        request.contentCreatorType.getAll({filterBy:
            [
              {column: "client_id", filter: this.props.client.id},
              {column: "content_creations", filter: "true"},
            ]})
          .then(contentCreatorTypes => this.setState({contentCreatorTypes, gettingCreators: false}))
      }else {
        request.contentCreatorType.getAll({filterBy: [{column: "client_id", filter: this.props.client.id}]})
          .then(contentCreatorTypes => this.setState({contentCreatorTypes, gettingCreators: false}))
      }

    }else {
      this.setState({gettingCreators: false})
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setWindowsWidth)
  }

  setWindowsWidth = () => this.setState({width: window.innerWidth})

  checkPermissions = (entity) => this.props.client.permissions.some(permission => permission.entity === entity)

  render = () => {
    if(!this.state.gettingCreators) {
      if (this.checkPermissions('music') && this.state.contentCreatorTypes.length === 0) {
        this.props.history.push(homePathGenerator(`smart_search/musics`))
      }else if (!this.checkPermissions('music') && this.state.contentCreatorTypes.length === 1) {
        this.props.history.push(homePathGenerator(`smart_search/content_creators?type=${this.state.contentCreatorTypes[0].slug}`))
      }
    }

    if (this.state.gettingCreators){
      return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
          <img style={{width: 150, height: 150, marginTop: "calc(43vh - 70px)"}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
      )
    } else {
      return (
        <div id="innerinner" style={{ marginBottom: '10px' }}>
          <div className="smart-search-category-container" style={{textAlign: this.state.contentCreatorTypes.length > 2 && "left"}}>

            {this.checkPermissions('music') &&
            <Link className="smart-search-item" to={homePathGenerator(`smart_search/musics`)}>
              <div className="smart-search-category">
                <div className="smart-search-category-title"
                     style={{color: "black"}}>{localization.get('smart_search.musics')}</div>
                <div className="smart-search-category-image" style={{backgroundImage: `url(${MusicsImage})`}}/>
              </div>
            </Link>
            }
            {this.state.contentCreatorTypes.sort((a, b) => a.name.localeCompare(b.name)).map(contentCreatorType =>
              <Link className="smart-search-item" to={homePathGenerator(`smart_search/content_creators?type=${contentCreatorType.slug}`)}>
                <div className="smart-search-category">
                  <div className="smart-search-category-title" style={{color: this.state.width > 900 ? "black" : "white"}}>{contentCreatorType.name}</div>
                  <div className="smart-search-category-image" style={{backgroundImage: `url(${makeImageUrlWithSize(contentCreatorType, 'md')}), url(${emptyImage})` }} />
                </div>
              </Link>
            )}
          </div>
        </div>
      )
    }
  }


}

export default SmartSearch
