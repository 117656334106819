import {addPutMethodToForm, del, get, post, put} from "../utils";
import {isNullOrUndefined} from "util";
/**
 * @typedef {Object} InputData
 * @property {string} type
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {file} video
 * @property {file} image
 * @property {number} order
 */

/**
 * @param {InputData} data
 */
const makeJsonData = (data) => {
  const form = {};
  if(data.title) form.title = data.title
  if (data.type) form.type = data.type
  if(data.order) form.order = data.order
  if(!isNullOrUndefined(data.active))  form.active = data.active ? 1 : 0
  if(!isNullOrUndefined(data.clients))  form.platform_banner = data.clients.filter(client => client.value == 0 ).length > 0 ? 1 : 0
  if (data.clients) data.clients.length > 0 ? form.clients_ids = data.clients.filter(client => client.value !== 0 ).map(clients => clients.value) : form.clients_ids = []
  if (data.videoId) form.videoId = data.videoId
  if (data.previewId) form.previewId = data.previewId

  return JSON.stringify(form)
};

export const makeFormData = data => {
    const form = new FormData()
    if (data.image) form.append('image', data.image, data.image.name)
    if (data.audio) form.append('audio', data.audio, data.audio.name)
    data.translations &&
      data.translations.forEach(translation => {
        if (!isNullOrUndefined(translation.subtitle)) form.append(`translations[${translation.language}][subtitle]`, translation.subtitle)
        if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description)
      });

    return form
  }

export default host => {
  const all = () =>
    get(`${host}/featuredVideos?include=video:url;image:url;clients`)
      .then(response => response.json())
      .then(response => response.data);


  const getForClient = clientId =>
    get(`${host}/clients/${clientId}/featuredVideos?include=video:url;image:url;clients`)
      .then(response => response.json())
      .then(response => response.data);

  const allOfType = type =>
    get(`${host}/featuredVideos?include=video:url;clients;image:url&type=${type}`)
      .then(response => response.json())
      .then(response => response.data);

  const allActiveOfType = (type, client) =>
    get(`${host}/featuredVideos?include=video:url;preview;clients&type=${type}&active=1&client_id=${client}`)
      .then(response => response.json())
      .then(response => response.data);

  const allSpotlights = page =>
    get(`${host}/featuredVideos?include=clients;video:url;preview&type=spotlight&active=1&per_page=5&page=${page}`)
      .then(response => response.json())

  const find = id =>
    get(`${host}/featuredVideos/${id}?include=video:url;audio;image:url;translations;preview;clients`)
      .then(response => response.json())
      .then(response => response.data);

  /**
   * @param {InputData} data
   */
  const create = (data, onProgress) =>
    post(`${host}/featuredVideos`, makeJsonData(data), null, onProgress)
      .then(response => response.json())
      .then(response => response.data)
      .then(video =>
        post(`${host}/featuredVideos/${video.id}`, addPutMethodToForm(makeFormData(data)))
      );

  /**
   * @param {InputData} data
   */
  const update = (id, data, onProgress) =>
    put(`${host}/featuredVideos/${id}`, makeJsonData(data), null, onProgress)
      .then(response => response.json())
      .then(response => response.data)
      .then(video =>
        post(`${host}/featuredVideos/${video.id}`, addPutMethodToForm(makeFormData(data)))
      );

  const setOrder = (id, order) => update(id, { order });

  const _delete = id => del(`${host}/featuredVideos/${id}`);

  return {
    all,
    allOfType,
    allSpotlights,
    allActiveOfType,
    find,
    create,
    update,
    setOrder,
    getForClient,
    delete: _delete
  };
};
