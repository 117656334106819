import getAll from './getAll'
import create from './create'
import del from './delete'
import get from './get'
import update from './update'
import searchFor from './searchFor';
import getAvailableTagsForClient from './getAvailableTagsForClient';
import getSupplyRanking from './getSupplyRanking';
import getDemandRanking from './getDemandRanking';
import generateDownloadLink from "./generateDownloadLink";
import getAvailableTagsForPlaylist from './getAvailableTagsForPlaylist'

export default host => ({
  getAll: getAll(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  update: update(host),
  searchFor: searchFor(host),
  getAvailableTagsForClient: getAvailableTagsForClient(host),
  getSupplyRanking: getSupplyRanking(host),
  getDemandRanking: getDemandRanking(host),
  generateDownloadLink: generateDownloadLink(host),
  getAvailableTagsForPlaylist: getAvailableTagsForPlaylist(host)
})