import React from "react";
import {
  ArrowBackIos,
  Chat,
  Close,
  GetApp,
  People,
  PlaylistAdd,
  Redeem,
  Share,
  ShoppingCartOutlined,
  Star,
  StarBorder
} from "@material-ui/icons";
import {
  Behance,
  Facebook,
  Instagram,
  LinkedIn,
  SoundCloud,
  Spotify,
  Twitter,
  Vimeo,
  Web,
  Youtube,
  Email,
  Twitch,
  Tiktok,
  Linktree,
  Android,
  Apple,
  Telegram
} from "../SocialIcons";
import styled from "styled-components";
import {ClickAwayListener, Grow, IconButton, Paper, Popper, Tooltip} from "@material-ui/core";
import localization from "../../../config/localization";
import {addHttpToUrl} from "../../../utils";
import {getActiveClient} from "../../../api-client/core/authentication/utils";

export default class SocialBannerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openSocials: false,
    };
  }

  render = () => {
    const {
      name,
      picture,
      blur,
      socialLinks,
      onClose,
      onToggleFavorite,
      favorite,
      showFavoriteButton = true,
      showShareButton = false,
      showAddToPlaylistButton = false,
      onAddToPlaylist,
      playlistButton,
      onShare,
      showContact,
      onContact,
      showBuyButton,
      showGiftButton,
      onBuyProduct,
      onGiftProduct,
      backgroundColor,
      color,
      shape,
      buyButtonPreferences,
      isModal,
      isEditorial = false,
      showDownload = false,
      onDownload,
      onClickSocial = null
    } = this.props;
    const {openSocials} = this.state;
    const isMobile = window.innerWidth <= 600;
    return (
      <SocialBanner sizeXl={!!getActiveClient()} backgroundColor={backgroundColor} shape={shape} borderColor={color}
                    isModal={isModal}>
        {!!getActiveClient() &&
          <>
            <BackButton>
              <IconButton onClick={onClose} style={{width: 35}}>
                <ArrowBackIos style={{fontSize: "35px", color: color}}/>
              </IconButton>
            </BackButton>
            <CloseButton>
              <IconButton onClick={onClose}>
                <Close style={{fontSize: "35px", color: color}}/>
              </IconButton>
            </CloseButton>
          </>
        }
        <ProfileContainer>
          <Profile picture={picture} blur={blur}/>
        </ProfileContainer>
        <div style={{
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: isMobile ? "flex-end" : "center",
        }}>
          <SocialButtons showOnMobile={isEditorial}>
            {renderSocialLinksButtons(socialLinks, true, onClickSocial )}
          </SocialButtons>
          {!isEditorial &&
            <MobileButtonsWrapper>
              {showBuyButton &&
                <>
                  <ContactMobile>
                    <IconButton onClick={onBuyProduct} style={{height: 33, width: 33}}>
                      <ShoppingCartOutlined style={{fontSize: 25, color: 'white'}}/>
                    </IconButton>
                  </ContactMobile>
                  {(buyButtonPreferences ?
                      <Contact backgroundColor={buyButtonPreferences.bg_color} fontColor={buyButtonPreferences.font_color}
                              onClick={onBuyProduct}>{localization.get('buy')}<ShoppingCartOutlined
                        style={{marginLeft: 5, fontSize: 15}}/></Contact>
                      :
                      <Contact color={color} onClick={onBuyProduct}>{localization.get('buy')}<ShoppingCartOutlined
                        style={{marginLeft: 5, fontSize: 15}}/></Contact>
                  )}
                </>
              }
              {showGiftButton && !showBuyButton &&
                <>
                  <ContactMobile>
                    <IconButton onClick={onGiftProduct} style={{height: 33, width: 33}}>
                      <Redeem style={{fontSize: 25, color: 'white'}}/>
                    </IconButton>
                  </ContactMobile>
                  {(buyButtonPreferences ?
                      <Contact backgroundColor={buyButtonPreferences.bg_color} fontColor={buyButtonPreferences.font_color}
                              onClick={onGiftProduct}>{localization.get('gift')}<Redeem
                        style={{marginLeft: 5, fontSize: 15}}/></Contact>
                      :
                      <Contact color={color} onClick={onGiftProduct}>{localization.get('gift')}<Redeem
                        style={{marginLeft: 5, fontSize: 15}}/></Contact>
                  )}
                </>
              }
              {showContact && !showBuyButton && !showGiftButton &&
                <>
                  <ContactMobile>
                    <IconButton onClick={onContact} style={{height: 33, width: 33}}>
                      <Chat style={{fontSize: 25, color: 'white'}}/>
                    </IconButton>
                  </ContactMobile>
                  <Contact color={color} onClick={onContact}>{localization.get('contact')}</Contact>
                </>
              }
              {showDownload && onDownload &&
                <ContactMobile>
                  <IconButton onClick={onDownload} style={{height: 33, width: 33}}>
                    <GetApp style={{fontSize: 25, color: 'white'}}/>
                  </IconButton>
                </ContactMobile>
              }
              <Separator color={color}/>
              {name && (name.length > 0) && <Name className="multiline-ellipsis" color={color}>{name}</Name>}
              <ActionsContainer>
                {showFavoriteButton &&
                  <Tooltip placement="bottom" title={localization.get("button.add_to_favorites")}>
                    <FavoriteButton>
                      <IconButton onClick={onToggleFavorite}
                                  style={isMobile ? {height: 33, width: 33} : {height: 40, width: 40}}>
                        {favorite ? <Star style={{fontSize: "25px", color: "white"}}/> :
                          <StarBorder style={{fontSize: "25px", color: "white"}}/>}
                      </IconButton>
                    </FavoriteButton>
                  </Tooltip>
                }
                {showAddToPlaylistButton &&
                  (playlistButton ?
                      <AddToPlaylistButton>
                        {playlistButton}
                      </AddToPlaylistButton>
                      :
                      <Tooltip placement="bottom" title={localization.get("add_to_playlist")}>
                        <AddToPlaylistButton>
                          <IconButton onClick={onAddToPlaylist}
                                      style={isMobile ? {height: 33, width: 33} : {height: 40, width: 40}}>
                            <PlaylistAdd style={{fontSize: "25px", color: "white", marginLeft: 4}}/>
                          </IconButton>
                        </AddToPlaylistButton>
                      </Tooltip>
                  )
                }
                {showShareButton &&
                  <Tooltip placement="bottom" title={localization.get("share")}>
                    <ShareButton>
                      <IconButton onClick={onShare} style={isMobile ? {height: 33, width: 33} : {height: 40, width: 40}}>
                        <Share style={{fontSize: "25px", color: "white"}}/>
                      </IconButton>
                    </ShareButton>
                  </Tooltip>
                }
                {socialLinks && socialLinks.length > 0 &&
                  <>
                    <ContactMobile>
                      <IconButton
                        buttonRef={node => {
                          this.anchorEl = node;
                        }}
                        aria-owns={openSocials ? "menu-list-grow" : null}
                        aria-haspopup="true"
                        onClick={() => this.setState({openSocials: !this.state.openSocials})}
                        style={{height: 33, width: 33}}
                      >
                        <People style={{fontSize: 25, color: 'white'}}/>
                      </IconButton>
                    </ContactMobile>
                    <Popper
                      open={openSocials}
                      anchorEl={this.anchorEl}
                      transition
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      style={{zIndex: 11000, top: 2}}
                    >
                      {({TransitionProps, placement}) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{transformOrigin: "center right", zIndex: 10}}
                        >
                          <Paper style={{
                            zIndex: 10,
                            backgroundColor: "transparent",
                            boxShadow: 'none',
                            marginRight: isEditorial ? 'calc(10vw - 3px)' : 10
                          }}>
                            <ClickAwayListener onClickAway={() => this.setState({openSocials: false})}>
                              <MobileSocialButtons>
                                {renderSocialLinksButtons(socialLinks, isEditorial, onClickSocial)}
                              </MobileSocialButtons>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                }
              </ActionsContainer>
            </MobileButtonsWrapper>
          }
        </div>
      </SocialBanner>
    );
  };
}

export const renderSocialLinksButtons = (socialLinks, whiteBackgroundSocials = false, onClickSocial = () => null, color="#000") =>
  socialLinks.map(socialLink =>
    <Tooltip title={socialLink.type.capitalize()} placement={"top"}>
      <a href={socialLink.type === "email" ? `mailto:${socialLink.url}` : addHttpToUrl(socialLink.url)}  onClick={onClickSocial} target="_blank"
         rel="nofollow">
        <SocialButton whiteBackgroundSocials={whiteBackgroundSocials}>
          {renderIcon(socialLink.type, color)}
        </SocialButton>
      </a>
    </Tooltip>
  ).slice(0, 4)

const renderIcon = (type, color = "#000") => {
  switch (type) {
    case "facebook":
      return <Facebook/>;
    case "twitter":
      return <Twitter/>;
    case "instagram":
      return <Instagram/>;
    case "linkedin":
      return <LinkedIn/>;
    case "vimeo":
      return <Vimeo/>;
    case "spotify":
      return <Spotify/>;
    case "soundcloud":
      return <SoundCloud/>;
    case "youtube":
      return <Youtube/>;
    case "web":
      return <Web/>;
    case "behance":
      return <Behance/>;
    case "email":
      return <Email/>
    case "twitch":
      return <Twitch/>
    case "tiktok":
      return <Tiktok/>
    case "linktree":
      return <Linktree/>
    case "apple":
      return <Apple/>
    case "android":
      return <Android/>
    case "telegram":
      return <Telegram/>
  }
}

const SocialBanner = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom-left-radius: ${props => props.shape === "square" ? "0px" : "10px"};
  border-bottom-right-radius: ${props => props.shape === "square" ? "0px" : "10px"};
  max-height: ${props => props.sizeXl ? "385px" : "285px"};
  padding: 3rem 15px 3rem 15px;
  background: ${props => props.backgroundColor ? props.backgroundColor : "linear-gradient(to bottom, #918e8e40, darkgray)"};
  border: ${props => props.borderColor ? `2px solid ${props.borderColor}` : 'none'};
  border-top: none;
  @media (min-width: 479px) {
    width: 160px;
  }
  @media (max-width: 600px) {
    width: calc(100% + 1.6rem);
    flex-direction: row;
    min-height: ${props => props.isModal ? '150px' : '120px'};
    padding: 15px;
    // margin-top: 50px;
    margin-left: -0.8rem;
    border: none;
      // border-top: ${props => props.borderColor ? `2px solid ${props.borderColor}` : 'none'};
    padding-top: ${props => props.isModal ? '50px' : '15px'};
  }
`;

const CloseButton = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 150;
  top: -5px;
  display: flex;
  @media (max-width: 600px) {
    top: 0;
    display: none;
  }
`;

const BackButton = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 150;
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`;

const FavoriteButton = styled.div`
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  background-color: black;
  top: 15px;
  margin: 0 5px;
  @media (max-width: 600px) {
    top: 0;
    margin: 0 2px;
  }
`;

const AddToPlaylistButton = styled.div`
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  background-color: black;
  top: 15px;
  margin: 0 5px;
  @media (max-width: 600px) {
    top: 0;
    margin: 0 2px;
  }
`;

const ShareButton = styled.div`
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  background-color: black;
  top: 15px;
  margin: 0 5px;
  @media (max-width: 600px) {
    top: 0;
    margin: 0 2px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  position: relative;
`;

const SocialButtons = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  @media (max-width: 600px) {
    margin-bottom: 0;
    justify-content: flex-end;
    width: 100%;
    display: ${props => props.isEditorial ? 'none' : 'flex'};
  }
`;

const MobileSocialButtons = styled.div`
  margin-bottom: 0;
  justify-content: flex-end;
  width: 100%;
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`;

const SocialButton = styled.div`
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  padding: 2px;
  border-color: black;
  cursor: pointer;
  margin: 2px;
  background-color: ${props => props.whiteBackgroundSocials ? 'white' : 'transparent'};
`;

const Separator = styled.div`
  height: 2px;
  background-color: ${props => props.color ? props.color : 'black'};
  width: 70%;
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 600px) {
    width: 2px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1px;
    margin-right: 1px;
    display: none;
  }
`;

const Name = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.color ? props.color : "#000"};
  -webkit-line-clamp: 2;
  min-height: 40px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Profile = styled.div`
  background: url(${props => props.picture}) no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border: ${props => props.picture ? "1px solid black" : "none"};
  background-position-x: 50%;
  filter: blur(${props => props.blur ? "8px" : "0px"});
  -webkit-filter: blur(${props => props.blur ? "8px" : "0px"});
  top: 0;
  border-radius: 50%;
  @media (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 10;
  margin-top: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const Contact = styled.div`
  cursor: pointer;
  color: ${props => props.fontColor ? props.fontColor : props.color ? props.color : "#000"};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
  margin-bottom: 5px;
  box-shadow: ${props => props.backgroundColor ? 'none' : "inset 1px 0 5px 1px #c3c3c3"};
  padding: 0 10px;
  border-radius: 5px;

  &:hover {
    font-weight: bold;
  }

  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 600px) {
    display: none;
  }
`;

const ContactMobile = styled.div`
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  background-color: black;
  margin: 0 5px;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    margin: 0 2px;
  }
`;

const MobileButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;