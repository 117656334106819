import {addPutMethodToForm, post} from "../utils";
import {makeFormData, makeJsonData} from "./utils";

export default host => ({
  name,
  visibility,
  status,
  createdByAdmin,
  catalogCategoryId,
  createdBy,
  clientsIds,
  countriesIds,
  weight,
  content,
  personalizedContent,
  supervised,
  musicDealer,
}) => {
  return post(`${host}/catalogs`,
    makeJsonData({
      name,
      visibility,
      status,
      createdByAdmin,
      catalogCategoryId,
      createdBy,
      clientsIds,
      countriesIds,
      weight,
      content,
      personalizedContent,
      supervised,
      musicDealer,
    })
  )
    .then(response => response.json())
    .then(response => response.data)
}
