import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import FolderImage from "../../../assets/img/Letflow/folder.png";
import {TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {
  getMainTranslation,
  userIsReadOnly
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization"
import {Edit} from "@material-ui/icons";
import analyticsInstance from "../../../analytics/analyticsInstance";

class HomePlaylistGridItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playlist.id
    }
  }

  buildButtons = (playlist) => {
    let builder = new TableButtonsBuilder();
    if (!userIsReadOnly()) {

      builder
        .withDelete(
          playlist.title,
          () => request.playlist.delete(playlist.id),
          closeState => {
            if (closeState === 'passed') {
              this.props.onDelete && this.props.onDelete(playlist.id)
            }
          }, false, "", "var(--main-font-color, black)"
        )
        .withOther(
        <TableButton
          title={localization.get('edit')}
          onClick={() => this.props.onEdit(playlist)}
        >
          <Edit style={{color: "var(--main-font-color, black)"}}/>
        </TableButton>
      )
        .withShare('playlist',
          playlist.id,
          "var(--main-font-color, black)",
          () => analyticsInstance.share({
          name: getMainTranslation(playlist, "title"),
          type: "Playlist",
        }))
    }
    return builder.build()
  };

  render = () => {

    const { playlist, onClick } = this.props;

    return (
      <article onMouseEnter={() => this.setState({ over: true })} onMouseLeave={() => this.setState({ over: false })}>
        <div style={{ width: "100%" }} className="album-grid-img">
          <div style={{ width: "100%", backgroundColor: "rgba(0,0,0,0)", boxShadow: "none"}} className="category-item">
            <div>
              <div style={{
                position: "relative",
                float: "left",
                width: "100%",
                padding: "50.0% 0"
              }}>
                <div style={{
                    backgroundImage: `url(${FolderImage})`,
                    position: "absolute",
                    backgroundSize: "contain",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    cursor: 'pointer',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: window.innerWidth < 600 ? "center" : "space-evenly",
                    padding: window.innerWidth < 600 ? "10px 0" : "30px 0",
                  }}
                  onClick={onClick}
                >
                  <div
                    className="featured-image-title"
                    style={{
                      color: "white",
                      fontSize: window.innerWidth < 600 ? "14px" : "18px",
                      lineHeight: window.innerWidth < 600 ? "18px" : "24px",
                    }}
                  >
                    {playlist.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                  </div>
                  {playlist.user &&
                    <div
                      className="featured-image-title"
                      style={{
                        color: "white",
                        fontSize: window.innerWidth < 600 ? 8 : 12,
                      }}
                    >
                      By {playlist.user.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                    </div>
                  }
                </div>
              </div>
              <div style={window.innerWidth < 600 ? 
                {position: "absolute", bottom: 25, display: "flex",justifyContent: "center",width: "100%", alignItems: "center", borderRadius: 50 } : {position: "absolute", top: 25, right: 30, borderRadius: 50 }}>{this.buildButtons(playlist)}</div>
            </div>
          </div>
        </div>
      </article>
    )
  }
}
export default HomePlaylistGridItem
