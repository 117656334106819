import React from "react";
import localization from "../../../../config/localization";
import {
  getActiveChannel,
  getStoredChannel,
  getStoredUser,
  setPageTitle,
  getActiveClient
} from "../../../../api-client/core/authentication/utils";
import { AddPhotoAlternateOutlined, Work } from "@material-ui/icons";
import VideocamIcon from '@material-ui/icons/Videocam';
import Audiotrack from '@material-ui/icons/Audiotrack';
import { Formik } from "formik";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import * as BFH from "../../../../components/Letflow/Form/BloomcloudsFormHelpers";
import {request} from "../../../../api-client";
import FilePondUploader from "../../../../components/Letflow/FilePondUploader";
import Unsplash from "../../../../components/Letflow/Unsplash";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Pdf from "../../../../assets/img/Letflow/FileExtentions/pdf.png";
import TagSelection from "../../../../components/Letflow/TagSelection/TagSelection";
import BloomcloudsToggleSwitch from "../../../../components/Letflow/BloomcloudsToggleSwitch";
import { getUpdateFormImageUrlFromItem } from "../../../../utils";
import browserHistory from "../../../../utils/browserHistory";
import { ImagePicker, VideoPicker } from "../../../../components/Letflow/Form";
import FormikTranslatableInputs from "../../../../components/Letflow/TranslatableInputs/FormikTranslatableInputs";
import { withRouter } from "react-router";
import analyticsInstance from "../../../../analytics/analyticsInstance";
import moment from "moment";
import {get} from "lodash";

class ContentCreationForm extends React.Component {
  constructor(props) {
    super(props);
    let currentUser = getStoredUser();
    let currentChannel = getStoredChannel();
    let creator = currentUser.contentCreators.find(c => c.channel_id == currentChannel.id);

    let validations = get(currentChannel, 'community.preferences.validations', null)

    this.state = {
      loaded: false,
      disabled: false,
      tagGroups: [],
      selectedTags: [],
      contentCreationId: props.match && props.match.params && props.match.params.contentCreationId,
      contentCreatorId: creator ? creator.id : null,
      contentCreation: undefined,
      contentCreator: creator,
      showErrors: false,
      disableForm: false,
      allowAudio: creator ? creator.type.allow_audio : false,
      allowBinary: creator ? creator.type.allow_binary : false,
      allowEmbed: creator ? creator.type.allow_embed : false,
      allowImage: creator ? creator.type.allow_image : false,
      allowVideo: creator ? creator.type.allow_video : false,
      translations: getStoredChannel().languages.map(lang => lang.language).map(language => {
        return  {
          language: language,
          title: "",
          description: "",
        }
      }),
      isPermanentContent: "1",
      expireDate: null,
      createdWhere: "",
      validations: validations,
    }
  }

  requestContentCreation = () => request.contentCreation.get(this.state.contentCreationId,"include=originalVideo.thumbnail;contentCreator.type;translations;image;tags;audio;binary;closedCaptions.binary;embededContent", true)
    .then(response => {
      const translations = getStoredChannel().languages.map(lang => lang.language).map(language => {
        const translation = response.translations.find(tr => tr.locale ===language)

        return  {
          language: language,
          title: translation ? translation.title: "",
          description: translation ? translation.description: "",
        }
      })

      this.setState({
        translations: translations,
        contentCreation: response,
        selectedTags: response.tags.map(t => t.id)},
      () => this.requestTags(response.contentCreator.type.slug));
  });

  componentDidMount = () => {
    setPageTitle(localization.get('title.talent_contents'));
    document.getElementsByTagName('html')[0].scrollTop = 0;
    this.state.contentCreationId ? this.requestContentCreation() : this.requestTags(this.state.contentCreator.type.slug);
  }

  initialValues = (contentCreation) => {
    let values = {
      id: '',
      active: "1",
      video: { src: null, file: null },
      image: { src: null, file: null },
      audio: { src: null, file: null },
      binary: null,
      fileSelector: this.state.allowVideo ? 'video' : this.state.allowImage ? 'image' : this.state.allowAudio ? 'audio' : this.state.allowBinary ? 'binary' : this.state.allowEmbed ? 'embed' : 'default',
      embedSource: "youtube",
      embedUrl: "",
      videoId: null,
      imageId: null,
      audioId: null,
      binaryId: null,
      unsplashImage: '',
      imageRadio: 'filepond',
      disableFileSelector: false,
      allowVideo: this.state.allowVideo,
      allowImage: this.state.allowImage,
      allowAudio: this.state.allowAudio,
      allowBinary: this.state.allowBinary,
      allowEmbed: this.state.allowEmbed,
      expireDate: !!get(contentCreation, "expire_date") ? moment.utc(contentCreation.expire_date).format("YYYY-MM-DD") : null,
      createdWhere: get(contentCreation, "created_where"),
    };

    if (contentCreation) {
      let fileSelector = "image";
      if(contentCreation.originalVideo) {
        fileSelector= "video"
      } else if (contentCreation.audio) {
        fileSelector= "audio"
      } else if (contentCreation.binary) {
        fileSelector= "binary"
      } else if (contentCreation.embededContent) {
        fileSelector= "embed"
      }
      values.fileSelector = fileSelector;
      values.disableFileSelector = true;
      values.id = contentCreation.id;
      values.active = contentCreation.active;
      if (contentCreation.originalVideo) {
        values.video = { src: contentCreation.originalVideo.url, file: null, filename: contentCreation.originalVideo.original_name}
        values.videoId = contentCreation.originalVideo.id;
      }
      if (contentCreation.image) {
        values.image = { id: contentCreation.image.id, src: getUpdateFormImageUrlFromItem(contentCreation), filename: contentCreation.image.original_name }
        values.imageId = contentCreation.image.id;
      }
      if (contentCreation.audio) {
        values.audio = { src: contentCreation.audio.url, file: null, filename: contentCreation.audio.original_name }
        values.audioId = contentCreation.audio.id;
      }
      values.binary = contentCreation.binary;
      values.binaryId = contentCreation.binary && contentCreation.binary.id;
      values.hasBinary = contentCreation.binary ? true : false;
      if (contentCreation.embededContent) {
        values.embedSource = contentCreation.embededContent.source;
        values.embedUrl = contentCreation.embededContent.embed_url;
      }
    }
    
    return values;
  }

  validate = values => {
    const errors = {};

    return errors;
  };

  checkBeforeSubmit= values => {
    const validations = this.state.validations;
    if (!values.imageId && !values.unsplashImage && !values.audioId && !values.videoId && !values.binaryId && !values.embedUrl) {
      GlobalSnackbar.show({message: localization.get('form.validate_files'), type: GlobalSnackbarTypes.ERROR});
      return false;
    } else if (values.fileSelector === "embed" && values.embedUrl) {
      if (values.embedSource === 'youtube' && !values.embedUrl.includes('youtu.be/')) {
        GlobalSnackbar.show({message: localization.get('error_youtube'), type: GlobalSnackbarTypes.ERROR});
        return false;
      } else if (values.embedSource === 'vimeo' && !values.embedUrl.includes('vimeo.com/')) {
        GlobalSnackbar.show({message: localization.get('error_vimeo'), type: GlobalSnackbarTypes.ERROR});
        return false;
      } else if (values.embedSource === 'spotify' && !values.embedUrl.includes('open.spotify.com/')) {
        GlobalSnackbar.show({message: localization.get('error_spotify'), type: GlobalSnackbarTypes.ERROR});
        return false;
      }
      if (!values.imageId && !values.unsplashImage) {
        GlobalSnackbar.show({message: localization.get('error_required_embed_preview'), type: GlobalSnackbarTypes.ERROR});
        return false;
      }
    }else if (!this.state.translations.some(tr => tr.title)) {
      GlobalSnackbar.show({message: localization.get('error.must_complete_title'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (get(validations, 'creationDescription', "false") == "true" && !this.state.translations.some(tr => tr.description)) {
      GlobalSnackbar.show({message: localization.get('form.validate_bio'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (get(validations, 'creationYear', "false") == "true" && !values.createdWhere) {
      GlobalSnackbar.show({message: localization.get('form.validate_created_where'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (get(validations, 'creationDate', "false") == "true" && !values.expireDate) {
      GlobalSnackbar.show({message: localization.get('form.validate_expire_date'), type: GlobalSnackbarTypes.ERROR});
      return false
    }

    for (let tg of this.state.tagGroups) {
      if (tg.tags.every(t => t.state == 'available')) {
        GlobalSnackbar.show({message: localization.get('form.no_tags_selected_for_group', tg.name), type: GlobalSnackbarTypes.ERROR});   
        return false;
      }
    }
    return true;
  }

  async submit(values) {
    let contentCreation = this.state.contentCreation;
    let canUploadVideo = values.fileSelector === "video";
    let canUploadAudio = values.fileSelector === "audio";
    let canUploadBinary = values.fileSelector === "binary";
    let canUploadEmbed = values.fileSelector === "embed" && values.embedSource && values.embedUrl;
    let canUploadImage = true;
    if (contentCreation) {
      canUploadVideo = canUploadVideo && !(contentCreation.originalVideo && (contentCreation.originalVideo.id == values.videoId));
      canUploadAudio = canUploadAudio && !(contentCreation.audio && (contentCreation.audio.id == values.audioId));
      canUploadBinary = canUploadBinary && !(contentCreation.binary && (contentCreation.binary.id == values.binaryId));
      canUploadImage = canUploadImage && !(contentCreation.image && (contentCreation.image.id == values.imageId));
    }

    const esTranslations = this.state.translations.find(tr => tr.language === "es");

    let translatedTranslations = await Promise.all(this.state.translations.map(async tr => {
      if (tr.language !== "es") {
        if (!tr.title && esTranslations.title) {
          tr.title = await request.translate.translate(esTranslations.title, "es", tr.language)
            .then(res => res.text)
            .catch(() => esTranslations.title);
        }
        if (!tr.description && esTranslations.description) {
          tr.description = await request.translate.translate(esTranslations.description, "es", tr.language)
            .then(res => res.text)
            .catch(() => esTranslations.description);
        }
      }

      return tr;
    }))

    const contentCreationData = {
      active: values.active ? "1" : "0",
      translations: translatedTranslations,
      video: canUploadVideo ? values.video && values.video.file : null,
      image: canUploadImage ? values.image && values.image.file : null,
      audio: canUploadAudio ? values.audio && values.audio.file : null,
      selectedTags: this.state.selectedTags,
      videoId: canUploadVideo ? values.videoId : null,
      imageId: canUploadImage ? values.imageId : null,
      audioId: canUploadAudio ? values.audioId : null,
      binaryId: canUploadBinary ? values.binaryId : null,
      embedSource: canUploadEmbed ? values.embedSource: null,
      embedUrl: canUploadEmbed ? values.embedUrl: null,
      unsplashImage: values.unsplashImage,
      requireDrm: false,
      expireDate: values.expireDate,
      createdWhere: values.createdWhere,
      channelId: getActiveChannel(),
    };
    let response;
    if (!contentCreation) {
      contentCreationData.contentCreatorId = this.state.contentCreatorId;
      response = await request.contentCreation.create(contentCreationData).then(() => browserHistory.goBack());
    } else {
      contentCreationData.id = contentCreation.id;
      response = await request.contentCreation.update(contentCreationData).then(() => browserHistory.goBack());
    }
    analyticsInstance.community({name: this.state.contentCreator.name, action: "Update_Contents"})

    return response;
  }

  requestTags = (contentCreatorType) => request.tagGroup.getAll({filterBy: [{ column: 'type', filter: contentCreatorType}, {column: 'inheritable', filter: true}]})
    .then((tagGroups) => this.setState({loaded: true, tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}))

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

  render = () => {
    return (
      <div className="talent-profile-section">
        <div className="talent-profile-section-title">
          <div className="talent-profile-section-title-logo">
            <Work style={{ fontSize: "16px", color: "#676767" }} />
          </div>
          <div className="talent-profile-section-title-label">{localization.get("talent_profile.contents")}</div>
        </div>
        <div style={{marginTop: '20px'}}>
          <div style={{marginBottom: "-30px", paddingLeft: "30px", display: 'flex', whiteSpace: 'break-spaces', fontWeight: 'bold'}}>{localization.get("content_creations.upload_material")} <div style={{fontSize: '0.8em'}}>{localization.get("content_creations.upload_material.types")}</div></div>
          {this.state.loaded && (
            <Formik
              initialValues={this.initialValues(this.state.contentCreation)}
              validate={this.validate}
              onSubmit={(values, actions) => {
                // actions.setSubmitting(true);
                this.setState({disableForm: true});
                if (this.checkBeforeSubmit(values)) {
                  this.submit(values)
                    .then(() => {
                      this.setState({ disableForm: false, submitted: true });
                    })
                    .catch((e) => {
                      console.log(e)
                      GlobalSnackbar.showGenericError();
                      this.setState({disableForm: false});
                    })
                    // .finally(() => actions.setSubmitting(false));
                } else {
                  this.setState({disableForm: false});
                }
              }}
            >
              {formikProps =>
                <React.Fragment>
                  <FormView
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                    onChange={(field, value) => {
                      formikProps.setFieldValue(field, value);
                    }}
                    onSubmit={(e) => {
                      this.setState({showErrors: true});
                      formikProps.handleSubmit(e);
                    }}
                    translations={this.state.translations}
                    onContentChange={(e, field) => this.setState({[field]: e})}
                    submitted={this.state.submitted}
                    formikProps={formikProps}
                    tagGroups={this.state.tagGroups}
                    onSelectedTagsChanged={this.onSelectedTagsChanged}
                    showErrors={this.state.showErrors}
                    disableForm={this.state.disableForm}
                    updateDisableForm={value => this.setState({disableForm: value})}
                    onCancel={() => browserHistory.goBack()}
                    validations={this.state.validations}
                  />
                </React.Fragment>
              }
            </Formik>
          )}
        </div> 
      </div>
    );
  }
}

const FormView = ({
  errors,
  touched,
  submitted,
  onChange,
  onSubmit,
  translations,
  onContentChange,
  formikProps,
  tagGroups,
  onSelectedTagsChanged,
  showErrors,
  disableForm,
  updateDisableForm,
  onCancel,
  validations
}) => {
  let formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path);
  let onVideoChange = video => {
    if (video) {
      formikProps.setFieldValue('videoId', video.id);
      formikProps.setFieldValue('video', {src: null});
      formikProps.setFieldValue('videoPreview', formNewUrl(video.path));
    } else {
      formikProps.setFieldValue('videoId', null);
      formikProps.setFieldValue('video', {src: null});
    }
  }
  let onImageChange = image => {
    if (image) {
      formikProps.setFieldValue('imageId', image.id);
      formikProps.setFieldValue('image', {src: null, filename: null});
      formikProps.setFieldValue('imagePreview', formNewUrl(image.path));
    } else {
      formikProps.setFieldValue('imageId', null);
      formikProps.setFieldValue('image', {src: null, filename: null});
    }
  }
  let onAudioChange = audio => {
    if (audio) {
      formikProps.setFieldValue('audioId', audio.id);
      formikProps.setFieldValue('audio', {src: null});
    } else {
      formikProps.setFieldValue('audioId', null);
      formikProps.setFieldValue('audio', {src: null});
    }
  }
  let onBinaryChange = binary => {
    if (binary) {
      formikProps.setFieldValue('binaryId', binary.id);
      formikProps.setFieldValue('binary', {src: null, filename: null});
      formikProps.setFieldValue('hasBinary', true);
    } else {
      formikProps.setFieldValue('binaryId', null);
      formikProps.setFieldValue('binary', {src: null, filename: null});
    }
  }

  let onSetPreview = (type) => {
    if (type === 'video') {
      formikProps.setFieldValue('video', { src: formikProps.values.videoPreview, filename: null});
    } else if (type === 'image') {
      let item = {image: {id: formikProps.values.imageId, src: formikProps.values.imagePreview}}
      formikProps.setFieldValue('image', {src: getUpdateFormImageUrlFromItem(item)});
    }
  }

  let makeContentCreationInput = () => (
    <>
      <FormControl style={{width: '100%'}}>
        <RadioGroup
          name="fileSelector"
          value={formikProps.values.fileSelector}
          onChange={e => {
            formikProps.setFieldValue("fileSelector", e.target.value);
          }}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          {formikProps.values.allowVideo && <FormControlLabel value="video" disabled={formikProps.values.disableFileSelector} control={<Radio color="default"/>} label={localization.get('creation.videos_file')}/>}
          {formikProps.values.allowImage && <FormControlLabel value="image" disabled={formikProps.values.disableFileSelector} control={<Radio color="default"/>} label={localization.get('creation.photos_file')}/>}
          {formikProps.values.allowAudio && <FormControlLabel value="audio" disabled={formikProps.values.disableFileSelector} control={<Radio color="default"/>} label={localization.get('creation.audios_file')}/>}
          {formikProps.values.allowBinary && <FormControlLabel value="binary" disabled={formikProps.values.disableFileSelector} control={<Radio color="default"/>} label={localization.get('creation.pdf_file')}/>}
          {formikProps.values.allowEmbed && <FormControlLabel value="embed" disabled={formikProps.values.disableFileSelector} control={<Radio color="default"/>} label={localization.get('creation.embeds_file')}/>}
        </RadioGroup>
      </FormControl>
      {formikProps.values.fileSelector !== 'image' &&
      <div className="media-input-container">
        {formikProps.values.fileSelector === 'video' &&
          <>
            {!formikProps.values['videoId'] &&
              <div className="filepond-img-container">
                <VideocamIcon style={{ fontSize: "100px" }}></VideocamIcon>
              </div>
            }
            <div className="filepond-container">
              {formikProps.values.videoId && formikProps.values.video.src && <VideoPicker video={formikProps.values.video.src} previewOnly={true}/>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={formikProps.values.video}
                id={"video"}
                type={"video"}
                allowedTypes={["video/mp4", "video/x-m4v", "video/*"]}
                onIdChange={onVideoChange}
                setPreview={onSetPreview}
                clientId={getActiveClient()}
              />
              <div className="filepond-clarification">{localization.get('creation.filepond_clarification.video_file')}</div>
            </div>
          </>
        }
        {formikProps.values.fileSelector === 'audio' &&
          <>
            {!formikProps.values['audioId'] &&
              <div className="filepond-img-container">
                <Audiotrack style={{ fontSize: "100px" }}></Audiotrack>
              </div>
            }
            {formikProps.values.audio.src && <audio style={{marginBottom: '20px'}} src={formikProps.values.audio.src} controls/>}
            <div className="filepond-container">
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={formikProps.values.audio}
                id={'audio'}
                type={"audio"}
                allowedTypes={["audio/*"]}
                onIdChange={onAudioChange}
                setPreview={onSetPreview}
                clientId={getActiveClient()}
              />
              <div className="filepond-clarification">{localization.get('creation.filepond_clarification.audio_file')}</div>
            </div>
          </>
        }

        {formikProps.values.fileSelector === 'binary' &&
          <>
            {!formikProps.values['audioId'] &&
              <div className="filepond-img-container">
                <img
                  style={{borderRadius: 6, maxWidth: '100px'}}
                  src={Pdf}
                />
              </div>
            }
            <div className="filepond-container">
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={formikProps.values.binary}
                id={'binary'}
                allowedTypes={["application/pdf"]}
                onIdChange={onBinaryChange}
                clientId={getActiveClient()}
              />
              <div className="filepond-clarification">{localization.get('creation.filepond_clarification.binary_file')}</div>
            </div>
          </>
        }

        {formikProps.values.fileSelector === "embed" &&
          <>
            <RadioGroup
              name="embedSource"
              value={formikProps.values.embedSource}
              onChange={e => {
                formikProps.setFieldValue("embedSource", e.target.value);
                formikProps.setFieldValue("embedUrl", '');
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="youtube" control={<Radio color="default"/>}
                                label={localization.get('form.youtube')}/>
              <FormControlLabel value="vimeo" control={<Radio color="default"/>} label={localization.get('form.vimeo')}/>
              <FormControlLabel value="spotify" control={<Radio color="default"/>}
                                label={localization.get('form.spotify')}/>
            </RadioGroup>
            
            <BFH.InputContainer errors={errors} touched={touched} onChange={onChange}>
              <BFH.FieldTitle>{`${localization.get('form.embed_url')} ${formikProps.values.embedSource === 'spotify' ? localization.get('talent_profile.contents.embed_spotify') : formikProps.values.embedSource === 'vimeo' ? localization.get('talent_profile.contents.embed_vimeo') : localization.get('talent_profile.contents.embed_youtube')}`}</BFH.FieldTitle>
              <BFH.Input
                field="embedUrl"
                type="text"
                placeholder={formikProps.values.embedSource === 'spotify' ? localization.get('talent_profile.contents.embed_spotify.example') : formikProps.values.embedSource === 'vimeo' ? localization.get('talent_profile.contents.embed_vimeo.example') : localization.get('talent_profile.contents.embed_youtube.example')}
                value={formikProps.values.embedUrl}
              />
            </BFH.InputContainer>
          </>
        }
      </div>
      }
    </>
  );

  let makeTags = () => (
    <>
      <BFH.SectionTitle>{localization.get('tags')}</BFH.SectionTitle>
      <div style={{marginTop: '10px', display: 'flex', flexDirection: 'column'}}>
        <TagSelection bloomCloudsView={true} data={tagGroups} onSelectedTagsChanged={onSelectedTagsChanged} disabled={false} />
      </div>
    </>
  );

  return (
    <BFH.FormContainer errors={errors} touched={touched} onChange={onChange}>
      {/** upload creation */}
      <BFH.SectionTitle>{localization.get("creation.content_type")}</BFH.SectionTitle>
      {makeContentCreationInput()}
      
      {/* thumbnail */}
      <BFH.SectionTitle>{localization.get('creation.thumbnail')}</BFH.SectionTitle>
      <BFH.InputContainer>
        <FormControl>
          <RadioGroup
            name="imageRadio"
            value={formikProps.values.imageRadio}
            onChange={(e) => {
              formikProps.setFieldValue("imageRadio", e.target.value);
              formikProps.setFieldValue("imageId", '');
              formikProps.setFieldValue("unsplashImage", '');
            }}
            onBlur={() => formikProps.setFieldTouched("imageRadio", true)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('file.upload')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
          </RadioGroup>
        </FormControl>
        <div className="media-input-container">
          {!formikProps.values.imageId && formikProps.values.imageRadio === 'filepond' && 
            <div className="filepond-img-container">
              <AddPhotoAlternateOutlined style={{ fontSize: "100px" }}></AddPhotoAlternateOutlined>
            </div>
          }
          <div className="filepond-container">
            {formikProps.values.imageRadio === 'filepond' ?
              <>
                {formikProps.values.imageId && formikProps.values.image && <div style={{paddingBottom: '20px'}}><ImagePicker image={formikProps.values.image.src} previewOnly={true}/></div>}
                <FilePondUploader
                  file={formikProps.values.image}
                  id={"image"}
                  type={"image"}
                  allowedTypes={["image/*"]}
                  onIdChange={onImageChange}
                  setPreview={onSetPreview}
                  disableForm={disableForm}
                  updateDisableForm={updateDisableForm}
                  clientId={getActiveClient()}
                />
                <div className="filepond-clarification">{localization.get('helper_text.images_width_2048')}</div>
              </>
              :
              <Unsplash imageAmount={6} profile={true} onSelectImage={(url, name) => formikProps.setFieldValue("unsplashImage", {url, name})}/>
            }
          </div>
        </div>
      </BFH.InputContainer>

      <BFH.InputContainer>
        <FormikTranslatableInputs
          bloomCloudsView={true}
          children={getStoredChannel().languages.map(lang => lang.language).map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <BFH.TranslateInput
                  errors={errors}
                  touched={touched}
                  onChange={(field, value) => {
                    currentLangTranslation.title = value;
                    onContentChange(translations, 'translations')
                  }}
                  label={localization.get("content_creation.title")+" *"}
                  field={`title${language}`}
                  type="text"
                  value={currentLangTranslation.title}
                />
                ,
                <BFH.TranslateInput
                  errors={errors}
                  touched={touched}
                  onChange={(field, value) => {
                    currentLangTranslation.description = value;
                    onContentChange(translations, 'translations')
                  }}
                  label={localization.get("content_creation.description") + (get(validations, 'creationDescription', "false") == "true" ? " *" : "")}
                  field={`description${language}`}
                  type="text"
                  value={currentLangTranslation.description}
                />
              ]
            }
          })}
        />
      </BFH.InputContainer>
      <BFH.InputContainer>
        <div style={{ color: "#808080", lineHeight: 0.5 }}>{localization.get("content_creation.created_where") + (get(validations, 'creationYear', "false") == "true" ? " *" : "")}</div>
        <BFH.Input
          errors={errors}
          touched={touched}
          onChange={(e) => formikProps.setFieldValue('createdWhere', e.target.checked)}
          field={`createdWhere`}
          type="text"
          value={formikProps.values.createdWhere}
        />
      </BFH.InputContainer>

      {/* tags */}
      {tagGroups && tagGroups.length > 0 && makeTags()}

      {/* visibility */}
      {get(getStoredChannel(), 'community.requires_approval', "not_required") != "on_update" && get(formikProps, "values.status", "pending") == "approved" &&
        <>
          <BFH.SectionTitle>{localization.get('form.visibility')}</BFH.SectionTitle>
          
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: '20px 0'}}>
            <div style={{marginRight: '20px'}}>
              <BloomcloudsToggleSwitch
                id="active-toggle-switch"
                Name="active"
                Text={[localization.get('creation.active'), localization.get('creation.inactive')]}
                onChange={(e) => formikProps.setFieldValue('active', e.target.checked)}
                currentValue={formikProps.values.active}
                disabled={false}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: localization.get("creation.active_clarification") }}/>
          </div>
        </> 
      }
      <BFH.SectionTitle>{localization.get('form.disponibility')}</BFH.SectionTitle>
      <BFH.InputContainer>
        <div style={{ color: "#808080", lineHeight: 0.5 }}>{localization.get("form.content_expire") + (get(validations, 'creationDate', "false") == "true" ? " *" : "")}</div>
        <BFH.Input
          errors={errors}
          touched={touched}
          onChange={(e) => formikProps.setFieldValue('expireDate', e.target.value)}
          field={`expireDate`}
          type="date"
          value={formikProps.values.expireDate}
        />
      </BFH.InputContainer>
      
      <BFH.SubmitButton
        onClick={onSubmit}
        disabled={submitted || disableForm}
        onCancel={onCancel}
        hasErrors={showErrors && Object.keys(errors).length > 0}
      />
    </BFH.FormContainer>
  );
};

export default withRouter(ContentCreationForm);