import {appendPaginationQueryParamsToUrl, del, get, post, put} from "../utils";

/**
 * @typedef OnlineData
 * @property link
 * @property title
 * @property producer
 * @property creativity
 * @property direction
 * @property description
 */

export default host => ({
  getAll: options => {
    let url = `${host}/online_videos?include=tags;license.music;license.client;license.type;client;user`;
    if (options) {
      url = appendPaginationQueryParamsToUrl(url, {
        ...options,
        alreadyHasQueryParams: true
      });
    }
    return get(url).then(res => res.json());
  },

  /**
   * @param {OnlineData} data
   */
  online: (pitchId, data, sendEmail = false) => {
    let url = `${host}/online_videos/online/pitches/${pitchId}`;
    if (sendEmail) {
      url += "?with_email=1";
    }
    return post(url, JSON.stringify(data));
  },

  update: (id, data) => {
    let url = `${host}/online_videos/${id}`;
    return put(url, JSON.stringify(data));
  },

  offline: id => del(`${host}/online_videos/offline/${id}`)
});
