import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveChannel} from "../authentication/utils";
import { getDateInt } from '../../../utils';

const url = (host,clientId, artistId, options) =>
  appendPaginationQueryParamsToUrl(`${host}/clients/${clientId}/${ getActiveChannel() ? `channels/${getActiveChannel()}/` : ""}musics?artist_id=${artistId}&include=recentLicenses;tags;audio;audio.waveform;${getActiveChannel() ? "channelFavorite" : "favorite"};albums.image&seed=${getDateInt()}`, {...options, alreadyHasQueryParams: true})

export default host => (clientId, artistId, options) =>
  get(url(host, clientId, artistId, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


