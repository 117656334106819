import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery, root} from './utils'
import {getActiveChannel} from "../authentication/utils";

const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/public${root}?${includeQuery}`, { ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  get(url(host, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
