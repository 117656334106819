import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {makeUrlWithTableParams, makeWaveformUrl} from "../../../utils";
import localization from "../../../config/localization";
import {Close, Done, LibraryAdd, Clear} from "@material-ui/icons";
import {deepOrange} from "@material-ui/core/colors";
import LicenseDetailsDialog from './LicenseDetailsDialog'
import {getStoredUser, userIsArtist} from "../../../api-client/core/authentication/utils";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import CatalogSelection from "../../../components/Letflow/CatalogSelection";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import MusicVersionDialog from "./MusicVersionDialog";
import SubComponent from "./SubComponent";
import AsyncSelect from "react-select/lib/Async";
import { Tooltip } from "@material-ui/core";

class MusicsTable extends Component {

  state = {
    total: '',
    showDialog: false,
    showMusicVersionDialog: false,
    active: {value:"all", label: localization.get('all')},
    inactiveReason: ""
  }

  handleAlertClosed = musicId => status => {
    if (status === "passed") this.refs.table.removeById(musicId);
  };

  buttons = music => {

    const { id, title, exclusive } = music;

    const builder = new TableButtonsBuilder();

    builder.withDownload(() => request.music.download(id), { title: title, duration: music.audio.duration, type: "music" });

    const tableMusics = this.refs.table.getData()

    const buttonMusics = tableMusics.map(tableMusic => ({
      title: tableMusic.title,
      subtitle: '',
      fetchAudioUrl: () => request.music.audioUrl(tableMusic.id),
      onAnyClick: () => this.props.history.push(this.makeEditUrl(tableMusic)),
      waveform: makeWaveformUrl(tableMusic)
    }))

    const providedMusicIndex = tableMusics.findIndex(x => x.id === music.id)

    builder.withNewPlay(buttonMusics, providedMusicIndex);

    if (!userIsArtist() && !getStoredUser().artist_view) {
      builder.withOther(
        <TableButton
          title={localization.get("music.add_version")}
          onClick={() => this.setState({showMusicVersionDialog: true, musicId: id})}
        >
          <LibraryAdd />
        </TableButton>
      )
      builder.withEdit(this.makeEditUrl(music));
      !exclusive && builder.withDelete(title, () => request.music.delete(id), this.handleAlertClosed(id));
    }

    return builder.build();
  };

  makeEditUrl = music => makeUrlWithTableParams(`/panel/musics/${music.id}/edit`);

  columns = () => {
    let firstColumns = [
      {
        Header: localization.get("music.reference"),
        accessor: "code",
        Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
        width: 110
      },
      {
        Header: localization.get("music.title"),
        accessor: "title",
        Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
        maxWidth: 300,
        minWidth: 100,
        Cell: cell => (
          <span style={{ color: cell.original.exclusive ? deepOrange['600'] : undefined }}>
            {cell.original.exclusive && <div style={{ fontSize: 10, lineHeight: "normal" }}>{localization.get('music.exclusive')}</div>}
            {cell.value}
          </span>
        )
      },
      {
        Header: localization.get("music.owner"),
        id: "owner",
        accessor: music => music.owner === "internal" ? '100% Feater' :  music.owner === "artist" ? 'Revshare' : "TMH",
        minWidth: 95,
        Filter: ({ filter, onChange }) =>
          <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected />
            <option value={'artist'}>Revshare</option>
            <option value={'internal'}>100% Feater</option>
            <option value={'tmh'}>TMH</option>
          </select>,
      },
      

      
    ];

    const middleColumns = [
      {
        Header: localization.get(this.state.active.value ? "table.active" : "music.inactive_motive"),
        id: this.state.active.value ? "active" : "inactiveReason",
        accessor: music => <div>
          {!this.state.active.value ? 
            <Tooltip title={music.inactive_reason}>
              <span>{["En Uso", "Sotano", "Exclusividad", "Observada"].includes(music.inactive_reason) ? music.inactive_reason : "Otro"}</span>
            </Tooltip> :
          (music.active ? <Done /> : <Close />)}
         </div>,
        minWidth: this.state.active.value ? 72 :180,
        filterable: !this.state.active.value,
        Filter: ({ filter, onChange }) =>
          <select style={{ width: "100%" }} onChange={event => {
            onChange(event.target.value)
            this.setState({inactiveReason: event.target.value})
            }} 
            value={this.state.inactiveReason}>
            <option value="" selected />
            <option value={'Exclusividad'}>Exclusividad</option>
            <option value={'En Uso'}>En Uso</option>
            <option value={'Sotano'}>Sotano</option>
            <option value={'Observada'}>Observada</option>
            <option value={'Otros'}>Otros</option>
          </select>,
      },
    ]

    if (this.state.active.value) {
      middleColumns.push({
        Header: localization.get("table.exclusive"),
        id: "is_exclusive",
        accessor: music => (music.exclusive ? <Done /> : <Close />),
        minWidth: 50,
        sortable: false,
        Filter: ({ filter, onChange }) =>
          <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected />
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
      })
    }

    const lastColumns = [
      {
        Header: localization.get('table.artist'),
        id: 'artist[name]',
        accessor: 'artist.name',
        Cell: ({ value }) => value || '-',
        minWidth: 90,
        sortable: false,
        Filter: ({ filter, onChange }) =>
          <TableAutocompleteFilter
            fetchItems={filter => request.artist
              .getAll({
                filterBy: [{
                  column: 'name',
                  filter
                }]
              })
              .then(artists => artists.map(artist => artist.name))
            }
            filter={filter ? filter.value : ''}
            onChange={onChange}
          />,
      },
      {
        Header: localization.get('table.with_album'),
        id: 'no_album',
        accessor: music => music.albums.length === 0 ? <Clear/> : <Done/>,
        minWidth: 90,
        sortable: false,
        filterable: false,
        style: {textAlign: "center"}
      },
      {
        Header: localization.get('table.actions'),
        id: "actions",
        sortable: false,
        filterable: false,
        accessor: this.buttons,
        width: 180,
        Footer: <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>{localization.get('table.total')}:  </strong>{this.state.total}</span>
      }
    ];

    const columns = firstColumns.concat(middleColumns).concat(lastColumns)

    if (userIsArtist() || getStoredUser().artist_view) {
      columns = columns.filter((column) => !(['owner'].includes(column.id)))
    }
    return columns
  }

  subComponent = (row) =>
    <SubComponent
      row={row}
      openLicenseDetailsDialog={(id) => this.setState({ licenseId: id, showDialog: true })}
    />

  render = () => {
    return (
      <React.Fragment>
        {this.state.showDialog && <LicenseDetailsDialog hideDialog={() => this.setState({ showDialog: false })} licenseId={this.state.licenseId} />}

        <MusicVersionDialog
          show={this.state.showMusicVersionDialog}
          musicId={this.state.musicId}
          handleClose={() => {
            this.refs.table.forceUpdate()
            this.setState({showMusicVersionDialog: false})
          }}
        />
        <div style={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
          <CatalogSelection
            catalog={this.state.catalog}
            onChange={(catalog) => this.setState({catalog}, () => this.refs.table.forceUpdate())}
            hasRelation={'has_musics'}
          />
          <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
            <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
              <span>{localization.get('table.active')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={(active) => {
                  this.setState({active}, () => this.refs.table.forceUpdate())
                  if (active.value === 'all') this.setState({inactiveReason: ""})
                }}
                value={this.state.active}
                isClearable={true}
                defaultOptions={[
                  {value: "all", label: localization.get('all')},
                  {value: true, label: localization.get('table.yes')}, 
                  {value: false, label: localization.get('table.no')}
                ]}
              />
            </div>
          </div>
        </div>

        <div style={{width: "100%", float: "left", position: "relative"}}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            subComponent={this.subComponent}
            requestTableData={paginationConfig => {
              if (this.state.catalog) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'catalog_id', filter: this.state.catalog.value }]) };
              }
              if (this.props.showNoAlbumTracks) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'no_album', filter: "true" }]) };
              }
              if (this.props.showNoAiffTracks) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'has_audio_aiff', filter: false }]) };
              }

              if (this.state.active !== 'all') {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'active', filter: this.state.active.value ? 1: 0 }]) };
              }

              if (this.state.inactiveReason !== '') {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'inactive_reason', filter: this.state.inactiveReason }]) };
              }

              return request.music.getAll(paginationConfig).then((response) => {
                this.setState({ total: response.meta.total })
                return response
              })
            }}
          />
        </div>
      </React.Fragment>
    );
  };
}

export default MusicsTable;
