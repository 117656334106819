import React from "react";
import {makeFitImageUrl, makeCustomImageUrl} from "../../../utils";
import Login1 from "../../../assets/img/login1.jpg";
import {channelPathGenerator, getStoredUser} from "../../../api-client/core/authentication/utils";
import {Link} from "react-router-dom";
import localization from "../../../config/localization";
import {request} from "../../../api-client";

class ChannelPublicNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: props.channel
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.channel) {
      this.setState({channel: nextProps.channel})
    }
  }

  render() {
    const {channel} = this.state
    return (
      <>
      {channel.id &&
        <nav className="navbar" style={{position: "relative", boxShadow: "none", zIndex: 0, height: 77, display: "flex", justifyContent: "space-between"}}>
          {!this.props.hideImage &&
            <div
              style={{
                height: "100vh",
                width: "100%", transform: "scale(1.1)",
                overflow: "hidden",
                position: " fixed",
                filter: (channel.preferences && channel.preferences.use_blur_on_login) ? "blur(5px)" : "blur(0)",
                background: `url(${channel.loginImage ? makeCustomImageUrl(channel.loginImage, 1024, 576) : Login1}) no-repeat center center fixed`,
                backgroundSize: "cover"
              }}
            />
          }
          <div className="navbar-image-container" style={{marginLeft: "40px"}}>
            <div className="navbar-image-container">
              <a className="navbar-image-container-link" href={channelPathGenerator("", channel)}>
                {channel.image &&
                <React.Fragment>
                  <img className="navbar-image" style={{filter: "grayscale(1)"}}
                       src={(makeFitImageUrl(this.state.channel, 400, 50, '', 'png'))}
                       alt={`Logo - ${this.state.channel.name}`}/>
                </React.Fragment>
                }
              </a>
            </div>
          </div>
          {getStoredUser() &&
            <Link
              to={'#'}
              style={{
                fontWeight:'normal',
                cursor: 'pointer',
                display: "flex",
                marginRight: 30,
                color: "black",
                textDecoration: "none",
                fontWeight: "bold",
                alignItems: "center",
              }}
              onClick={() => request.authentication.logout()}
            >
              <span style={{color: "black"}}>{localization.get("navbar.logout")}</span>
            </Link>
          }
        </nav>
      }
      </>
    )
  }
}

export default ChannelPublicNavBar
