import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../config/localization";
import {request} from "../../api-client";
import {ServerSidePaginationTable} from "../../components/Letflow/Table";
import {Edit} from "@material-ui/icons";
import TableButton from "../../components/Letflow/Table/Buttons/TableButton";
import StateDialog from "./StateDialog"
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import './RightManagement.css'
import EventManager, {events} from "../../utils/EventManager";

class All extends Component {

  state = {
    open: false
  }

  componentDidMount = () => {
    document.title = localization.get('right_management') + ' - Feater'
  }

  handleEdit = (rightManagement) => {
    this.setState({open: true, id: rightManagement.id, state: rightManagement.state});
  }

  handleClose = state => {
    this.setState({ open: false });
    if(state !== this.state.state) {
      request.rightManagement.update({rightManagementId: this.state.id, state:state})
        .then(() => {
          GlobalSnackbar.show({message: localization.get('right_management.snackbar.success'), type: GlobalSnackbarTypes.SUCCESS})
          this.refs.table.forceUpdate()
          EventManager.getInstance().dispatch(events.UPDATE_DASHBOARD_NOTIFICATIONS)
        })
    }
  };


  columns = () => [
    {
      Header: localization.get('right_management.date'),
      accessor: "created_at",
      width: 200,
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get('right_management.client'),
      accessor: "client.name",
      maxWidth: 200,
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get('right_management.state'),
      id: "state",
      accessor: rightManagement => localization.get(`right_management.state.${rightManagement.state}`),
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: rightManagement =>
        <div style={{float: 'right'}}>
          <TableButton onClick={() => this.handleEdit(rightManagement)} title={localization.get('tooltip.edit')}>
            <Edit/>
          </TableButton>
        </div>,
      width: 100
    }
  ]

  subComponent = (row) => {
    return (
      <div className="right-management-subcomponent">

        <h4>{localization.get('subcomponent.song_data')}</h4>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.title')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.title}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.reference')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.reference}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.url')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.url}</div>
        </div>

        <h4>{localization.get('subcomponent.contact_data')}</h4>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.name')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.user.name}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.email')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.user.email}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.client')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.client.name}</div>
        </div>

        <h4>{localization.get('subcomponent.project_data')}</h4>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.description')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.description}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.duration')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.duration}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.territory')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.territory}</div>
        </div>
        <div className="right-management-subcomponent-row">
          <div className="right-management-subcomponent-row-key">{localization.get('subcomponent.budget')}:</div>
          <div className="right-management-subcomponent-row-value">{row.original.budget}</div>
        </div>

      </div>
    )
  }


  render = () =>
    <div>
      <AllViewContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ServerSidePaginationTable
                  ref='table'
                  columns={this.columns()}
                  subComponent={this.subComponent}
                  requestTableData={paginationConfig => request.rightManagement.getAll(paginationConfig)}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
      <StateDialog
        open={this.state.open}
        onClose={this.handleClose}
        state={this.state.state}
      />
    </div>
}

export default All;
