import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    id,
    name,
    email,
    phone,
    country,
    state,
    description,
    instagramUrl,
    facebookUrl,
    spotifyUrl,
    soundCloudUrl,
    audios,
    language
}, onProgress) => post(`${host}/artists/${id}`, addPutMethodToForm(makeFormData({
    name,
    email,
    phone,
    country,
    state,
    description,
    instagramUrl,
    facebookUrl,
    spotifyUrl,
    soundCloudUrl,
    audios,
    language
})), null, onProgress)
  .then(response => response.json())
