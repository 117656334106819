import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {root} from './utils'

const url = (host, country, options) => {
  let url = `${host}${root}/ranking/demand?include=group`
  if (country) {
    url += `&country=${country}`
  }

  return appendPaginationQueryParamsToUrl(url, {...options, alreadyHasQueryParams: true})
}

export default host => (country, options) =>
  get(url(host, country, options))
  .then(response => response.json())
  .then(json => json.meta ? json : json.data)
