import {isNullOrUndefined} from "util";

export const makeFormData = (translations, clientCategories, review, order) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
    })
  }
  if (clientCategories) {
    clientCategories.forEach(clientCategoryId => form.append('client_categories_ids[]', clientCategoryId))
  }
  if (!isNullOrUndefined(review)) {
    form.append('review', review ? 1 : 0)
  }
  if (!isNullOrUndefined(order)) {
    form.append('order', order)
  }

  return form
}

export const root = '/catalogCategories'

export const includeQuery = 'include=clientCategories'
