import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Edit from "./Update";

const UsersRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/home/:clientId-:clientName/account/users/:id/edit" component={Edit} />
        <Route path="/home/:clientId-:clientName/account/users/create" component={Create} />
        <Route path="/home/:clientId-:clientName/account/users" component={All} />
      </Switch>
    </Router>
  )
}

export default UsersRouting
