import React from "react";
import {Route, Router, Switch} from "react-router-dom";

export default ({
  createPath,
  createComponent,
  threadPath,
  threadComponent,
  conversationsComponent,
  ...rest
}) => (
  <Router {...rest}>
    <Switch>
      {createComponent && (
        <Route path={createPath} component={createComponent} />
      )}
      <Route path={threadPath} component={threadComponent} />
      <Route component={conversationsComponent} />
    </Switch>
  </Router>
);
