import React, {Component} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import localization from "../../config/localization";
import Dropzone from "react-dropzone";
import {CloudUpload} from "@material-ui/icons";

export default class MaterialSelection extends Component {
  static URL = "url";
  static VIDEO = "video";
  static IMAGE = "image";
  static PLAYLIST = "playlist";
  static AUDIO = "audio";
  static OTHER = "other";

  state = {
    type: MaterialSelection.OTHER,
    url: "",
    video_file: null,
    displayed_video: null,
    image_file: null,
    displayed_image: null,
    audio_file: null,
    displayed_audio: null,
    other_files: [],
    other_files_name: ""
  };

  componentDidUpdate = (_, prevState) =>
    prevState !== this.state && this.props.onChange(this.state);

  renderSelectedMaterialTypeInput = () => {
    const { type, url, displayed_video, displayed_audio, displayed_image } = this.state;

    switch (type) {
      case MaterialSelection.URL:
        return (
          <TextField
            label={localization.get("pitch.write_material_url")}
            value={url}
            onChange={e => this.setState({ url: e.target.value })}
            fullWidth
          />
        );
      case MaterialSelection.VIDEO:
        return (
          <React.Fragment>
            <div
              style={{
                borderRadius: 5,
                borderStyle: "solid",
                borderWidth: 1,
                padding: "2rem 3rem 2.7rem"
              }}
            >
              <Dropzone
                onDrop={acceptedFiles => {
                  const file = acceptedFiles[0];
                  this.setState({ video_file: file, displayed_video: null });
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () =>
                      this.setState({ displayed_video: reader.result });
                    reader.readAsDataURL(file);
                  }
                }}
                accept="video/mp4,video/x-m4v,video/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 50,
                          height: 50,
                          padding: 12,
                          background: "black",
                          color: "white",
                          textAlign:"center",
                          cursor: "pointer",
                        }}
                      >
                        <CloudUpload/>
                      </div>
                      <div style={{marginLeft: "60px", marginTop: "-32px"}}>
                        <p>
                        {localization.get(
                          "send_material.drag_and_drop_to_select_single"
                        )}
                      </p>
                      </div>
                      {/** Separator */}
                      {this.state.video_file && (
                        <>
                          <div style={{ height: "1rem" }} />
                          <div style={{ color: "dimgray", fontSize: 12 }}>
                            {this.state.video_file.name}
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {displayed_video && (
              <video
                style={{ width: "100%", marginTop: 20 }}
                src={displayed_video}
                controls
              />
            )}
          </React.Fragment>
        );
      case MaterialSelection.IMAGE:
        return (
          <React.Fragment>
            <div
              style={{
                borderRadius: 5,
                borderStyle: "solid",
                borderWidth: 1,
                padding: "2rem 3rem 2.7rem"
              }}
            >
              <Dropzone
                onDrop={acceptedFiles => {
                  const file = acceptedFiles[0];
                  this.setState({ image_file: file, displayed_image: null });
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () =>
                      this.setState({ displayed_image: reader.result });
                    reader.readAsDataURL(file);
                  }
                }}
                accept="image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 50,
                          height: 50,
                          padding: 12,
                          background: "black",
                          color: "white",
                          textAlign:"center",
                          cursor: "pointer",
                        }}
                      >
                        <CloudUpload/>
                      </div>
                      <div style={{marginLeft: "60px", marginTop: "-32px"}}>
                        <p>
                          {localization.get(
                            "send_material.drag_and_drop_to_select_single"
                          )}
                        </p>
                      </div>
                      {/** Separator */}
                      {this.state.image_file && (
                        <>
                          <div style={{ height: "1rem" }} />
                          <div style={{ color: "dimgray", fontSize: 12 }}>
                            {this.state.image_file.name}
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {displayed_image && (
              <img
                style={{ width: "100%", marginTop: 20 }}
                src={displayed_image}
              />
            )}
          </React.Fragment>
        );
      case MaterialSelection.AUDIO:
        return (
          <React.Fragment>
            <div
              style={{
                borderRadius: 5,
                borderStyle: "solid",
                borderWidth: 1,
                padding: "2rem 3rem 2.7rem"
              }}
            >
              <Dropzone
                onDrop={acceptedFiles => {
                  const file = acceptedFiles[0];
                  this.setState({ audio_file: file, displayed_audio: null });
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () =>
                      this.setState({ displayed_audio: reader.result });
                    reader.readAsDataURL(file);
                  }
                }}
                accept="audio/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 50,
                          height: 50,
                          padding: 12,
                          background: "black",
                          color: "white",
                          textAlign:"center",
                          cursor: "pointer",
                        }}
                      >
                        <CloudUpload/>
                      </div>
                      <div style={{marginLeft: "60px", marginTop: "-32px"}}>
                        <p>
                          {localization.get(
                            "send_material.drag_and_drop_to_select_single"
                          )}
                        </p>
                      </div>
                      {/** Separator */}
                      {this.state.audio_file && (
                        <>
                          <div style={{ height: "1rem" }} />
                          <div style={{ color: "dimgray", fontSize: 12 }}>
                            {this.state.audio_file.name}
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {displayed_audio && (
              <div style={{ marginTop: 20 }}>
                <audio src={displayed_audio} controls />
              </div>
            )}
          </React.Fragment>
        );
      case MaterialSelection.OTHER:
        const onDrop = acceptedFiles =>
          this.setState({ other_files: acceptedFiles });
        return (
          <div
            style={{
              borderRadius: 5,
              borderStyle: "solid",
              borderWidth: 1,
              padding: "2rem 3rem 2.7rem"
            }}
          >
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        borderRadius: "50%",
                        width: 50,
                        height: 50,
                        padding: 12,
                        background: "black",
                        color: "white",
                        textAlign:"center",
                        cursor: "pointer",
                      }}
                    >
                      <CloudUpload/>
                    </div>
                    <div style={{marginLeft: "60px", marginTop: "-32px"}}>
                      <p>
                        {localization.get(
                          "send_material.drag_and_drop_to_select"
                        )}
                      </p>
                    </div>
                    {/** Separator */}
                    {this.state.other_files.length > 0 && (
                      <div style={{ height: "1rem" }} />
                    )}
                    {/** Selected Files */}
                    {this.state.other_files.map((file, key) => (
                      <div style={{ color: "dimgray", fontSize: 12 }} key={key}>
                        {file.name}
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </Dropzone>
            {this.state.other_files.length > 1 && (
              <TextField
                style={{ marginTop: "1rem" }}
                label={localization.get(
                  "send_material.drag_and_drop_to_select_zip_name"
                )}
                value={this.state.other_files_name}
                onChange={e =>
                  this.setState({ other_files_name: e.target.value })
                }
                fullWidth
              />
            )}
          </div>
        );
      case MaterialSelection.PLAYLIST:
        return null;
    }
  };

  render = () => {
    const { type } = this.state;

    return (
      <div {...this.props}>
        <FormControl style={{ marginBottom: 20 }} fullWidth>
          <InputLabel>{localization.get("select_pitch_material")}</InputLabel>
          <Select
            value={type}
            onChange={e => this.setState({ type: e.target.value })}
          >
            <MenuItem value={MaterialSelection.VIDEO}>
              {localization.get("video")}
            </MenuItem>
            <MenuItem value={MaterialSelection.IMAGE}>
              {localization.get("image")}
            </MenuItem>
            <MenuItem value={MaterialSelection.AUDIO}>
              {localization.get("audio")}
            </MenuItem>
            <MenuItem value={MaterialSelection.OTHER}>
              {localization.get("other")}
            </MenuItem>
          </Select>
        </FormControl>
        {this.renderSelectedMaterialTypeInput()}
      </div>
    );
  };
}
