import getAllForChannel from './getAllForChannel'
import create from "./create";
import checkIfActive from "./checkIfActive";
import get from "./get";
import del from "./delete";
import update from "./update";
import checkToken from "./checkToken";
import canAccess from "./canAccess";


export default host => ({
  getAllForChannel: getAllForChannel(host),
  create: create(host),
  checkIfActive: checkIfActive(host),
  get: get(host),
  delete: del(host),
  update: update(host),
  checkToken: checkToken(host),
  canAccess: canAccess(host),
})
