import {isNullOrUndefined} from "util";
import moment from "moment";

export const makeFormData = ({
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashProfileImage,
  image,
  contactName,
  contactEmail,
  contactDescription,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  deleteDownloadableMaterial,
  batchDownloadableMaterialId,
  mainMaterial,
  viewLimit,
  timeToTrack,
  requireLogin,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  viewType,
  viewTypeMobile,
}) => {
  const form = new FormData();
  if (phone) form.append("phone", phone);
  if (email) form.append("email", email);
  if (countryId) form.append("country_id", countryId);
  if (contentCreatorTypeId) form.append("content_creator_type_id", contentCreatorTypeId);
  if (observation) form.append("observation", observation);
  if (selectedCatalogs) selectedCatalogs.forEach(x => form.append("catalogs_ids[]", x))
  if (weight) form.append("weight", weight);
  if (!isNullOrUndefined(active)) form.append("active", active);
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name);
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
      if (!isNullOrUndefined(translation.additions)) form.append(`translations[${translation.language}][additions]`, translation.additions.substring(0, 254));
      if (!isNullOrUndefined(translation.synopsis)) form.append(`translations[${translation.language}][synopsis]`, translation.synopsis.substring(0, 250));
    });
  }
  if (socialLinks) {
    socialLinks.forEach(socialLink => {
      if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
    });
  }

  if (!isNullOrUndefined(selectedTags)) {
    if(selectedTags.length > 0) {
      selectedTags.forEach(x => form.append("tags_ids[]", x));
    }else {
      form.append("clear_all", 1);
    }
  }
  if (image) form.append('image', image, image.name);
  if (selectedContentCreations)
    selectedContentCreations.forEach(x => form.append("content_creations_ids[]", x));
  if (imageId) form.append("imageId", imageId);
  if (coverImageId) form.append("coverImageId", coverImageId);
  if (!isNullOrUndefined(isDemo)) form.append("is_demo", isDemo ? 1 : 0);
  if (unsplashCoverImage) {
    form.append('cover_image_url', unsplashCoverImage.url)
    form.append('cover_image_name', unsplashCoverImage.name)
  }
  if (unsplashProfileImage) {
    form.append('profile_image_url', unsplashProfileImage.url)
    form.append('profile_image_name', unsplashProfileImage.name)
  }
  if (contactName) form.append("name", contactName);
  if (contactEmail) form.append("email", contactEmail);
  if (contactDescription) form.append("description", contactDescription);
  if (startActivity) form.append("activity_start", moment(startActivity).format('YYYY-MM-DD'));
  if (releaseDate) form.append("release_date", moment.utc(releaseDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (expireDate) form.append("expire_date", moment.utc(expireDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (batchProfileImageId) form.append("batchProfileImageId", batchProfileImageId);
  if (batchCoverImageId) form.append("batchCoverImageId", batchCoverImageId);
  if (downloadableMaterialId) form.append ("downloadableMaterialId", downloadableMaterialId);
  if (downloadableMaterialFileType) form.append ("downloadableMaterialFileType", downloadableMaterialFileType);
  downloadableMaterialText ? form.append ("downloadableMaterialText", downloadableMaterialText) : form.append ("downloadableMaterialText", "");
  downloadableMaterialLink ? form.append ("downloadableMaterialLink", downloadableMaterialLink) : form.append ("downloadableMaterialLink", "");
  if (!isNullOrUndefined(contentSupervisorId)) form.append("content_supervisor_id", contentSupervisorId);
  if (onlyArgentina) form.append("only_argentina", onlyArgentina === 'true' ? 1 : 0);
  if (deleteDownloadableMaterial) form.append('deleteDownloadableMaterial', deleteDownloadableMaterial)
  if (batchDownloadableMaterialId) form.append("batchDownloadableMaterialId", batchDownloadableMaterialId);
  if(!isNullOrUndefined(mainMaterial)) form.append("main_material", mainMaterial.value || mainMaterial);
  if(!isNullOrUndefined(viewLimit)) form.append("view_limit", viewLimit);
  if(!isNullOrUndefined(timeToTrack)) form.append("time_to_track", timeToTrack);
  if(!isNullOrUndefined(requireLogin)) form.append("require_login", requireLogin);
  if (!isNullOrUndefined(hasSuggestedCarousel)) form.append("has_suggested", hasSuggestedCarousel ? 1 : 0);
  if (!isNullOrUndefined(showDateOnHome)) form.append("show_date_on_home", showDateOnHome ? 1 : 0);
  suggestedCarouselPlaylist ? form.append ("suggested_playlist", suggestedCarouselPlaylist) : form.append ("suggested_playlist", "");

  if(viewType) form.append('view_type', viewType);
  if(viewTypeMobile) form.append('view_type_mobile', viewTypeMobile);

  return form;
};

export const includeQuery = "include=image;contentCreations;type";
