import {post} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => ({
    name,
    isReel,
    level
  }) => {

  return post(`${host}/clients/${getActiveClient()}/tags`,JSON.stringify({
    "name": name,
    "is_reel": isReel ? 1 : 0,
    "level": !isNaN(level) ? level : null
  }))
    .then(response => response.json())
}