import {get} from "../utils";
import {getActiveChannel, isPublicChannel, userHasRestrictedAccess} from "../authentication/utils";
import localization from "../../../config/localization";

export default host => (channelId, sectionId = null, removeCache = false) => {

  let url = `${host}${isPublicChannel() ? "/public": ""}/channels/${channelId}/elements/metadata?include=translations${sectionId ? `&section_id=${sectionId}` : ""}${getActiveChannel() ? `&cLang=${localization.getLanguage()}` : ""}`;

  if (removeCache || userHasRestrictedAccess()) {
    url += "&new_cache=" + Date.now();
  }

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
}
