import React, {Component} from "react";
import {Button} from "@material-ui/core";
import PriceCalculator from "../../components/Letflow/LicenseWizard/utils/PriceCalculator";
import {request} from "../../api-client";
import {
  CountriesForRegion,
  LicenseTypeIds,
  MediaTypeIds,
  RegionIds
} from "../../components/Letflow/LicenseWizard/utils/constants";
import {
  countriesForRegionToString,
  mediaIdToString,
  regionIdToExtents,
  regionIdToString
} from "../../components/Letflow/LicenseWizard/utils/mappers";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import localization from '../../config/localization'

class PricingConfiguration extends Component {
  state = {
    editing: false,
    apiPrices: null
  };

  componentDidMount = () => {
    document.title = localization.get("prices") + " - Feater";
    request.license
      .getPrices()
      .then(apiPrices => this.setState({ apiPrices }))
      .catch(e => {
        throw new Error(e);
      });
  };

  onUpdateButtonClick = () => {
    const { apiPrices, editing } = this.state;
    if (editing) {
      request.license
        .updatePrices(apiPrices)
        .then(() => {
          GlobalSnackbar.show({ message: localization.get('pricing.prices_successfully_updated'), type: GlobalSnackbarTypes.SUCCESS });
        })
        .catch(e => {
          GlobalSnackbar.show({ message: e.message, type: GlobalSnackbarTypes.SUCCESS });
        });
    }
    this.setState({ editing: !editing });
  };

  makePositivePriceInput = (key, value, onChange, { step = 1, extraValidation = v => v } = {}) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ marginRight: 10 }}>{key}</div>
      <div>
        <input
          step={step}
          type="number"
          value={value}
          disabled={!this.state.editing}
          onChange={e => {
            let val = e.target.value;
            if (val < 0) val = 0;
            val = extraValidation(val);
            onChange(val);
          }}
        />
      </div>
    </div>
  );

  makeMultiplierInput = (key, value, onChange) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ marginRight: 10 }}>{key}</div>
      <div>
        <input
          step={0.1}
          type="number"
          value={value}
          disabled={!this.state.editing}
          onChange={e => {
            let val = e.target.value;
            if (val < 0) val = 0;
            if (val > 1) val = 1;
            onChange(val);
          }}
        />
      </div>
    </div>
  );

  makeInternetBudget = (budgetLabel, budgetId) => {
    const internet = this.state.apiPrices.license_type[2].media[1];
    return (
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        {this.makePositivePriceInput(budgetLabel, internet.budget[budgetId].credits, value => {
          internet.budget[budgetId].credits = value;
          this.forceUpdate();
        })}
        {this.makePositivePriceInput(
          localization.get('pricing.3_months_exclusivity'),
          internet.budget[budgetId].exclusivity.credits,
          value => {
            internet.budget[budgetId].exclusivity.credits = value;
            this.forceUpdate();
          }
        )}
      </div>
    );
  };

  render = () => {
    const { apiPrices } = this.state;

    if (apiPrices === null) return null;

    return (
      <div>
        {/* Boton para actualizar como el de la pagina de precios de sadaic */}
        <Button variant="contained" onClick={this.onUpdateButtonClick}>
          {this.state.editing ? localization.get('pricing.save_changes') : localization.get('pricing.update')}
        </Button>
        {this.makeOwnChannelPrices()}
        <h3>{localization.get('pricing.publicity')}</h3>
        {this.makeMultiplierInput(
          localization.get('pricing.artist_fee'),
          apiPrices.artist.fee.surcharge,
          value => {
            apiPrices.artist.fee.surcharge = value;
            this.forceUpdate();
          }
        )}
        {this.makeInternetPrices()}
        {this.makeMediaPrices(MediaTypeIds.RADIO)}
        {this.makeMediaPrices(MediaTypeIds.TELEVISION)}
        {this.makeMediaPrices(MediaTypeIds.ALL_MEDIA)}
      </div>
    );
  };

  makeOwnChannelPrices = () => {
    const category = this.state.apiPrices.license_type[LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS];
    return (
      <div>
        <h3>{localization.get('pricing.own_channels')}</h3>
        {this.makePositivePriceInput(localization.get('pricing.base'), category.credits, value => {
          category.credits = value;
          this.forceUpdate();
        })}
        {this.makePositivePriceInput(localization.get('pricing.unlimited_reductions'), category.unlimited_reductions.credits, value => {
          category.unlimited_reductions.credits = value;
          this.forceUpdate();
        })}
        <div>{this.makeReductions(category.reductions)}</div>
      </div>
    );
  };

  makeInternetPrices = () => {
    const media = this.state.apiPrices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].media[
      MediaTypeIds.INTERNET
    ];
    return (
      <ElementContainer title={mediaIdToString(MediaTypeIds.INTERNET)}>
        {this.makeInternetBudget(`${localization.get('pricing.budget')} < 5000`, 2)}
        {this.makeInternetBudget(`${localization.get('pricing.budget')} > 5000`, 3)}
        <br />
        {this.makePositivePriceInput(localization.get('pricing.bichannel'), media.bichannel.credits, value => {
          media.bichannel.credits = value;
          this.forceUpdate();
        })}
        <br />
        {this.makePositivePriceInput(localization.get('pricing.second_edition'), media.edition.credits, value => {
          media.edition.credits = value;
          this.forceUpdate();
        })}
        <br />
        {this.makeReductions(media.reductions)}
      </ElementContainer>
    );
  };

  makeMediaPrices = mediaId => {
    const media = this.state.apiPrices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].media[mediaId];

    const makeRegionWithOneExtent = regionId => (
      <div style={{ width: 400, margin: "0 10px 10px 0" }}>
        {this.makePositivePriceInput(
          <b>{regionIdToString(regionId)}</b>,
          media.extent[regionIdToExtents(regionId)[0]].credits,
          value => {
            media.extent[regionIdToExtents(regionId)[0]].credits = value;
            this.forceUpdate();
          }
        )}
      </div>
    );

    const makeRegionWith4Extents = regionId => (
      <div style={{ width: 400, margin: "0 10px 10px 0" }}>
        <div>
          <b>{regionIdToString(regionId)}</b>
        </div>
        <div>
          {regionIdToExtents(regionId).map((extentId, i) => {
            let msg;
            switch (i + 1) {
              case CountriesForRegion.ALL:
                msg = countriesForRegionToString(CountriesForRegion.ALL);
                break;
              case CountriesForRegion.ONE:
                msg = countriesForRegionToString(CountriesForRegion.ONE);
                break;
              case CountriesForRegion.THREE:
                msg = countriesForRegionToString(CountriesForRegion.THREE);
                break;
              case CountriesForRegion.TWO:
                msg = countriesForRegionToString(CountriesForRegion.TWO);
                break;
            }
            return this.makePositivePriceInput(msg, media.extent[extentId].credits, value => {
              media.extent[extentId].credits = value;
              this.forceUpdate();
            });
          })}
        </div>
      </div>
    );

    return (
      <ElementContainer title={mediaIdToString(mediaId)}>
        <h5>{localization.get('pricing.regions')}</h5>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {makeRegionWithOneExtent(RegionIds.GLOBAL)}
          {makeRegionWithOneExtent(RegionIds.NORTH_AMERICA)}
          {makeRegionWith4Extents(RegionIds.ASIA)}
          {makeRegionWith4Extents(RegionIds.EUROPE)}
          {makeRegionWith4Extents(RegionIds.LATAM)}
          {makeRegionWith4Extents(RegionIds.PACIFIC)}
        </div>
        <h5>{localization.get('pricing.adds_on')}</h5>
        <br />
        {this.makePositivePriceInput(localization.get('pricing.bichannel'), media.bichannel.credits, value => {
          media.bichannel.credits = value;
          this.forceUpdate();
        })}
        <br />
        {this.makeReductions(media.reductions)}
      </ElementContainer>
    );
  };

  makeReductions = reductionsObject => {
    return PriceCalculator.makeReductionRangesFrom(reductionsObject).map(({ from, to, surcharge }) => {
      return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <div>
            {`${localization.get('pricing.reductions_from')} ${from} `}
            {!isNaN(to) && `${localization.get('pricing.to')} ${to}`}
          </div>
          {this.makeMultiplierInput("", surcharge, value => {
            const key = from + "-" + (isNaN(to) ? "" : to);
            reductionsObject[key] = { surcharge: value };
            this.forceUpdate();
          })}
        </div>
      );
    });
  };
}

const ElementContainer = ({ title, children }) => (
  <div style={{ padding: 10, margin: "10px 0", boxShadow: "0 0 5px 0 dimgray", borderRadius: "5px" }}>
    <h4 style={{ textAlign: "center" }}>{title}</h4>
    <div style={{ height: "1px", width: "80%", backgroundColor: "dimgray", margin: "auto", marginBottom: 10 }} />
    {children}
  </div>
);

export default PricingConfiguration;
