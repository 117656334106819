import React from 'react';
import {request} from "../../../api-client";
import TextField from "@material-ui/core/TextField/TextField";
import localization from "../../../config/localization";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from "@material-ui/core";
import {Close, Search as SearchIcon, Style} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import HomeItemDescriptionTags from "../../../layouts/Home/HomeElements/HomeItemDescriptionTags";
import Paginator from "../Paginator";
import FileItem from "../FileItem";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";


export default class DriveFileSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      searchInput: "",
      loading: true,
      page: 0,
      tags: [],
      searchTags: [],
      selectedTags: [],
      showTagDialog: false,
      selectedFiles: [],
      selectedFile: null,
    }
  }


  componentDidMount = () => {
    this.getFiles({});

    this.requestTags()
  };

  getFiles = ({page = 0, perPage = 12}) => {
    return this.setState({ loading: true }, () => {
      const filter = [];
      filter.push({column: "tags_ids", filter: this.state.selectedTags || ""});
      this.state.searchInput !== "" && filter.push({ column: "q", filter: this.state.searchInput });
      filter.push({column: "type", filter: this.props.fileType});

      return request.clientFile.getForDashboard(
        this.props.clientId,
        {
          page,
          perPage,
          filterBy: filter
        }
      ).then(files => {this.setState({ files, loading: false })})
    })
  }


  handleKeyPress = e => {
    if(e.key === 'Enter'){
      this.getFiles({})

      e.preventDefault();
      e.stopPropagation();
    }
  }

  requestTags = () =>
    request.clientTags.getAllForDashboard(this.props.clientId, {
      filterBy: [{column: 'order_by', filter: "name"}, {column: 'sort_type', filter: "asc"}]
    })
      .then(tags => this.setState({
        tags,
        searchTags: tags
      },  () => this.addSelectedToTagList()
    ));

  addSelectedToTagList = () =>
    this.setState({
      searchTags:
        this.state.tags.map(tag => ({
          ...tag,
          state: this.state.selectedTags.find(selectedTag => selectedTag == tag.id)
            ? "selected" : "available"
        }))
    });

  onSelectedTagChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) {
      this.setState({ selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag) },
        () => {
          this.getFiles({})
          this.updateTagColor(selectedTag, "available")
        })
    }else {
      this.state.selectedTags.push(selectedTag);

      this.setState({selectedTags: this.state.selectedTags},
        () => {
          this.getFiles({})
          this.updateTagColor(selectedTag, "selected")
        })
    }
  };

  updateTagColor = (selectedTag, value) => {
    this.setState({searchTags: this.state.searchTags.map(tag => {
        if(tag.id === selectedTag) {
          return {...tag, state: value}
        }
        return tag
      })
    })
  }

  handleCloseTagDialog = () => this.setState({showTagDialog: false, searchTagByText: ""});

  onSelectedFilesChanged = selectedFile => {
    if(this.props.isMulti) {
      if (this.state.selectedFiles.includes(selectedFile.id)) {
        this.setState({selectedFiles: this.state.selectedFiles.filter(files => files !== selectedFile.id)},
          () => this.props.onSelectFile(this.state.selectedFiles))
      } else {
        this.setState({selectedFiles: this.state.selectedFiles.concat([selectedFile.id])},
          () => this.props.onSelectFile(this.state.selectedFiles))
      }
    }else {
      this.setState({ selectedFile: this.state.selectedFile === selectedFile.id ? null : selectedFile.id},
        () => this.props.onSelectFile(this.state.selectedFile))
    }
  }

  tagDialog = () =>
    <Dialog open={this.state.showTagDialog} onEscapeKeyDown={() => this.handleCloseTagDialog()} onBackdropClick={() => this.handleCloseTagDialog()} style={{padding: 10, margin: "-30px"}}>
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={() => this.handleCloseTagDialog()}>
        <Close />
      </IconButton>
      <DialogTitle>{localization.get('tags')}</DialogTitle>
      <DialogContent style={{minHeight: 300, minWidth: 300}}>
        {this.state.searchTags.length > 0 &&
          [1,2,3,4,5,6,7,8,9,10,null].map(element => {
            const tags = this.state.searchTags.filter(tag => tag.level === element)
            return (
              tags.length > 0 &&
              <div style={{width: "100%", marginBottom: 10}}>
                <HomeItemDescriptionTags
                  displayOnMobile={true}
                  outlined
                  tags={tags}
                  onSelectedTagChanged={this.onSelectedTagChanged}/>
              </div>
            )
        })}
      </DialogContent>
      <DialogActions>
        <div style={{float: "left", position: "relative", width: "100%", textAlign: "center",}}>
          <Button className="reels-button" onClick={() => this.handleCloseTagDialog()} style={{background: "black", color: "white"}}>
            {localization.get('home.smart_search.search').toUpperCase()}
          </Button>
        </div>
      </DialogActions>
    </Dialog>

  render = () => {
    const {loading, files, searchInput} = this.state;

    return (
      <div style={{ height: 500, display: "flex", flexDirection: "column"}}>
        {this.tagDialog()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField id='search' onKeyPress={this.handleKeyPress} placeholder={localization.get('search.files')} value={searchInput} onChange={(e) => this.setState({searchInput: e.target.value})} />
            <IconButton onClick={() => this.getFiles({})} disabled={searchInput.length === 0} disableRipple ><SearchIcon /></IconButton>
          </div>
          <div style={{marginTop: 10, position: "relative", float:"left"}} onClick={() =>  this.setState({showTagDialog: true})}>
            <IconButton >
              <Tooltip  title={localization.get('reel.filter_tag')} leaveDelay={1} placement='top'>
                <Style/>
              </Tooltip>
            </IconButton>
          </div>
        </div>
        {loading ?
        <div style={{ height: 300, display: "flex", justifyContent: "center", alignContent: "center" }}>
          <img style={{width: 120, height: 120, marginTop: "20%"}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
        : files.data && files.data.length > 0 ?
          <React.Fragment>
            <div className="file-container" style={{ overflowY: "scroll", height: 500 }}>
              {this.state.files.data.map(item => (
                <FileItem
                  item={item}
                  selected={this.props.isMulti ? this.state.selectedFiles.includes(item.id) : this.state.selectedFile === item.id}
                  onClick={this.onSelectedFilesChanged}
                />
              ))}
            </div>
            {files && files.meta && files.meta.last_page !== 1 &&
              <Paginator
                style={{marginTop: 10}}
                pages={files.meta.last_page}
                onPageChange={page => this.getFiles({page: page})}
                pagination={files.meta}
                currentPage={files.meta.current_page-1}
              />
            }
          </React.Fragment>
            :
            <div style={{ height: 300, display: "flex", justifyContent: "center", alignContent: "center" }}>
              <p style={{fontWeight: "bold", fontSize: 18, textAlign: "center", padding: "20px 10px"}} >No hay archivos</p>
            </div>
        }
      </div>
    );
  }
}