import React, {Component} from 'react'
import {TryAgainSnackbar} from '../Snackbar';
import PropTypes from 'prop-types'
import {BigSpinner} from '../Loading';
import Alert from '../Alert'
import localization from '../../../config/localization';
import ErrorBoundary from '../ErrorBoundary';

class ViewContainer extends Component {

  state = {
    showRefreshPage: false,
    showBigSpinner: false,
    showAsyncCommandAlert: false,
    asyncCommandAlert: null,
    requesting: false,
  }

  componentDidMount = () => {
    this._initialize()
  }

  componentWillUnmount = () => {
    this._setRequestingState = () => { }
  }

  // public

  reInitialize = () => this._initialize()

  executePromise = (promiseFactory, onError) => {
    this._setRequestingState(true)
    return promiseFactory()
      .then(result => {
        this._setRequestingState(false)
        return result
      })
      .catch(e => {
        this._setRequestingState(false)
        if (this.props.onRequestingStateChanged) {
          this.props.onRequestingStateChanged(false)
        }
        console.error(e)
        onError()
      })
  }

  executePromiseWithLoadingScreen = promiseFactory => {
    this.setState({ showBigSpinner: true })
    return promiseFactory()
      .then(result => {
        this.setState({ showBigSpinner: false })
        return result
      })
  }

  executePromiseWithRefreshFallback = promiseFactory => this.executePromise(promiseFactory, this._showRefreshPage)

  executePromiseWithTryAgainFallback = promiseFactory => this.executePromise(promiseFactory, this._showTryAgainSnackbar)

  openAsyncCommandAlert = (promiseFactory, onClose = () => { }, message = '', failedMessage = '', successMessage = '') => {

    const asyncCommandAlert = (
      <Alert
        openedTitle={localization.get('alert.atention')}
        failedTitle={localization.get('alert.failed')}
        passedTitle={localization.get('alert.success')}
        openedMessage={message}
        failedMessage={failedMessage}
        passedMessage={successMessage}
        onClose={state => {
          this.setState({ asyncCommandAlert: null })
          onClose(state)
        }}
        promiseFactory={promiseFactory}
      />
    )

    this.setState({ asyncCommandAlert })

  }

  // private

  _initialize = () => {
    if (this.props.initializationRequest) {
      const promiseFactory = () => this.props.initializationRequest()
      const withRefreshFallback = () => this.executePromiseWithRefreshFallback(promiseFactory)
      return this.executePromiseWithLoadingScreen(withRefreshFallback)
    } else {
      return Promise.resolve()
    }
  }

  _showTryAgainSnackbar = () => {
    this.refs.tryAgainSnackbar.show()
  }

  _showRefreshPage = () => {
    this.setState({ showRefreshPage: true })
  }

  _setRequestingState = value => {
    this.setState({ requesting: value })
    if (this.props.onRequestingStateChanged) {
      this.props.onRequestingStateChanged(value)
    }
  }

  _renderRefreshPage = () =>
    <div>
      <div>{localization.get('error.refresh_page')}</div>
    </div>

  _renderBigSpinner = () =>
    <BigSpinner />

  _renderView = () =>
    <div>
      <TryAgainSnackbar ref='tryAgainSnackbar' />
      {this.state.asyncCommandAlert}
      {this.props.children}
    </div>

  render = () => {

    const wrapInErrorBoundary = element => <ErrorBoundary>
      {element}
    </ErrorBoundary>

    const { showRefreshPage, showBigSpinner } = this.state

    let element

    if (showRefreshPage) {
      element = this._renderRefreshPage()
    } else if (showBigSpinner) {
      element = this._renderBigSpinner()
    } else {
      element = this._renderView()
    }

    return wrapInErrorBoundary(element)
  }
}

ViewContainer.propTypes = {
  children: PropTypes.any.isRequired,
  initializationRequest: PropTypes.func,
}

export default ViewContainer
