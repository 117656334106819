import {post} from "../utils";

export default host => (contentCreatorId, channelId, allCreation = false) =>
  post(
    `${host}/contentCreators/${contentCreatorId}/customShare`,
    JSON.stringify({
      channel_id: channelId,
      all_creations: allCreation ? 1:0,
    })
  )
    .then(response => response.json())
    .then(json => json.data);
