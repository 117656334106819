import React, {Component} from 'react'
import {Grid, Tooltip} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types'
import localization from '../../../../config/localization'

class Top extends Component {

  render = () => {
    const { onAdd } = this.props
    return (
      <Grid container>
        <Grid xs={6} item>
            {onAdd ? <Tooltip title={localization.get('tooltip.add')} placement='top'><Button onClick={onAdd} style={{ position: "fixed", bottom: '20px', right: "30px", zIndex: "1000", height: "60px", width: "60px", backgroundColor: "#1f1f1f" }} variant="fab" color="primary" aria-label="Add" ><AddIcon /></Button></Tooltip> : null}
        </Grid>
      </Grid>
    )
  }
}

Top.propTypes = {
  onAdd: PropTypes.func,
}

export default (Top)
