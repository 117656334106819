import React from 'react'
import {CloudDownload} from '@material-ui/icons'
import CustomTooltip from '../utils/CustomTooltip';

const Download = ({ onClick, tooltip }) => {
  return (
    <CustomTooltip title={tooltip}>
      <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
        <div className='soundbar-button' onClick={onClick} style={{ display: 'flex', alignItems: 'center', color: 'var(--secondary-font-color, dimgrey)' }}>
          <div style={{ lineHeight: '0' }}><CloudDownload /></div>
        </div>
      </div>
    </CustomTooltip>
  )
}

export default Download