export const makeFormData = ({title, musicId, audioFile, audioAiffFile}) => {
  const form = new FormData()
  if (title) form.append('title', title)
  if (musicId) form.append('music_id', musicId)
  if (audioFile instanceof Blob) form.append('audio', audioFile, audioFile.name)
  if (audioAiffFile instanceof Blob) form.append('audio_aiff', audioAiffFile, audioAiffFile.name)
  return form
}

export const includeQuery = 'include=audio:original_name,url,duration;audioAiff'
