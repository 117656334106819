import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeImageUrl, makeWaveformUrl} from '../../../utils'
import {Typography} from "@material-ui/core";
import localization from "../../../config/localization";
import {userCanDownloadUnMarkedTracksWithoutLicence} from "../../../api-client/core/authentication/utils";

class ArtistSubcomponent extends Component {

  state = {
    musics: [],
  }

  componentDidMount = () => {
    request.artist.get(this.props.artist.id).then(artist => this.setState({
      musics: artist.recentMusics,
    }))
  }

  render = () => {

    const gotoArtist = () => this.props.history.push(`/artists/${this.props.artist.id}/edit`)

    const makeSoundplayerSongs = () => this.state.musics.map(track => ({
      fetchAudioUrl: () => request.music.audioUrl(track.id),
      image: makeImageUrl(this.props.artist, 50),
      title: track.title,
      subtitle: this.props.artist.name,
      onAnyClick: gotoArtist,
      waveform: makeWaveformUrl(track)
    }))

    const soundplayerMusicSongs = makeSoundplayerSongs()

    const makeTable = (soundplayerSongs) => this.state.musics.length > 0 && <div>
      <h4 style={{marginLeft: "25px"}}>{localization.get('musics')}</h4>
      <ClientSidePaginationTable
        data={this.state.musics.map((track, index) => ({ ...track, index, belongsTo: 'music' }))}
        columns={[
          {
            id: 'title', accessor: track => track.title
          },
          {
            id: 'actions', accessor: track => {
              const builder = new TableButtonsBuilder()
              track.audio && builder.withDownload(this.makeDownloadRequest(track), { title: track.title, duration: track.audio.duration, type: 'music' })
              track.audio && builder.withNewPlay(soundplayerSongs, track.index)
              return builder.build()
            }
          },
        ]}
        filterable={false}
        sortable={false}
        showPagination={false}
        zebraStyle={false}
      />
    </div>


    return <div style={{ ...this.props.style, margin: '20px' }}>
      {this.state.musics.length === 0 && <Typography>{localization.get('subcomponent.artist_has_no_tracks')}</Typography>}
      {makeTable(soundplayerMusicSongs)}
    </div>
  }

  makeDownloadRequest = track => () => {
    if (userCanDownloadUnMarkedTracksWithoutLicence()) {
      return request.music.download(track.id)
    }
    return request.music.downloadDemo(track.id)
  }
}

export default ArtistSubcomponent