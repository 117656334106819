import React, {Component} from "react";
import {secondsToMMSS} from "../../../utils";
import InputRange from 'react-input-range'

class DurationInputRange extends Component {

  constructor(props){
    super(props);
    this.state = {
      value: props.value || {min: 0, max: 480}
    }
  }

  render = () => {
    return (
      <InputRange
        step={10}
        minValue={0}
        maxValue={480}
        formatLabel={value => secondsToMMSS(value)}
        onChange={value => {
          this.setState({value})
          this.props.onChangeDuration(value)
        }}
        onChangeComplete={() => this.props.onChangeCompleteDuration()}
        value={this.state.value}
      />
    )
  }
}

export default DurationInputRange