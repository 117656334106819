import * as WLFH from "../../../components/Letflow/Form/WebLikeFormHelpers";
import localization from "../../../config/localization";
import React, {Component} from "react";
import {Field} from "formik";
import {Button} from "@material-ui/core";
import {NavigateBefore} from "@material-ui/icons";
import {BounceLoader} from "react-spinners";


export class Step3 extends Component {
  state = {
    tagGroups: [],
    submitting: false
  }

  render = () => {
    const {values, setFieldValue, handleBlur, isSubmitting, handleSubmit} = this.props.formikProps

    return (
      <>
        <div style={{ width: "100%", maxWidth: 500, padding: '0px 20px 20px', margin: "auto"}}>

          <WLFH.SectionTitle>{localization.get("form.tags")}</WLFH.SectionTitle>
          {/** Tags */}
          <Field name="selectedTags">
          {({field, form}) =>
            this.props.tagGroups.map((tg, key) => (
              <WLFH.TagGroup
                key={key}
                field="selectedTags"
                label={tg.label}
                tags={tg.tags}
                selectedTags={values.selectedTags}
                onChange={setFieldValue}
                onBlur={handleBlur}
              />
            ))
          }
          </Field>

          <div style={{marginTop: 30}}>
          <Button style={{marginTop: 10}} variant="outlined" onClick={this.props.previousStep}>
            <NavigateBefore style={{marginLeft: "-12px"}}/>
            {localization.get('form.back')}
          </Button>
          <Button
            className={this.state.submitting && "save-button"}
            style={{float: 'right', marginTop: 10, backgroundColor: "rgba(26 , 26, 26, 1)", color: "white", display: isSubmitting && 'none'}}
            variant="raised"
            onClick={() => {
              this.setState({submitting: true})
              setFieldValue('lastStep', this.props.lastStep)
              setTimeout(() => handleSubmit(), 500)
            }}
          >
            {localization.get('license_wizard.confirm')}
          </Button>

          {isSubmitting &&
          <div className="save-loader" style={{float: 'right', marginTop: 15, marginRight: 30}}>
            <BounceLoader size={35}/>
          </div>
          }
          </div>
        </div>
      </>
    )}
}

