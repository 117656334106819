import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import TagSelection from "./TagSelection.jsx";
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

class ExpandableTagSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagGroups: props.tagGroups,
    }
  }
  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ExpansionPanel >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ width: "50%" }}>
              <Typography style={{ fontSize: "24px" }} className={classes.heading}>Busqueda Inteligente</Typography>
            </div>
            <div style={{ width: "50%" }}>
              <Typography style={{ marginTop: "9px" }} className={classes.secondaryHeading}>Filtre de manera mas eficiente por Etiquetas</Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: "block", textAlign: "center" }}>
            <div style={{ display: 'inline-block' }}><TagSelection data={this.state.tagGroups} /></div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

ExpandableTagSelection.propTypes = {
  tagGroups: PropTypes.array.isRequired,
}

export default withStyles(styles)(ExpandableTagSelection);