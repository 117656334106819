import React, {Component} from 'react'
import localization from "../../config/localization";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  MenuItem, Paper,
  Select, Tab, Tabs,
} from "@material-ui/core";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Add, Clear, Close, Done, Reorder} from "@material-ui/icons";


class ConfigTagGroupDialog extends Component {
  state = {
    tagGroups: [],
    selectedTab: "content",
    contentTagGroups: [],
    materialTagGroups: [],
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tagGroups) {
      this.setState({tagGroups: nextProps.tagGroups})
    }

    if (nextProps.channelTagGroups) {
      this.setState({
        contentTagGroups: nextProps.channelTagGroups.filter(tg => tg.target === 'content'),
        materialTagGroups: nextProps.channelTagGroups.filter(tg => tg.target === 'material'),
      })
    }
  }

  addTagGroup = tagGroup => {
    const target = this.state.selectedTab === "content" ? "contentTagGroups" : "materialTagGroups"
    let availableTagGroups =  this.state[target];

    availableTagGroups.push(tagGroup)
    this.setState({[target]: availableTagGroups.map((element, index) => ({
          ...element,
          order: index,
          target: this.state.selectedTab,
      }))
    })
  }

  removeFromTagGroup= tagGroup => {
    const target = this.state.selectedTab === "content" ? "contentTagGroups" : "materialTagGroups"
    let availableTagGroups = this.state[target].filter(element => element.id !== tagGroup.id)
    this.setState({[target]: availableTagGroups.map((element, index) => ({
        ...element,
        order: index,
      }))
    })
  }

  setTagGroups = tagGroups => {
    const target = this.state.selectedTab === "content" ? "contentTagGroups" : "materialTagGroups"

    this.setState({[target]: tagGroups})
  }

  closeWithSave = () => {
    const {contentTagGroups, materialTagGroups} = this.state;

    this.props.setTagGroups(contentTagGroups.concat(materialTagGroups));
    this.setState({selectedTab: "content"})

    return this.props.openCloseDialog();
  }
  handleClose = () => {
    this.setState({
      selectedTab: "content",
      contentTagGroups: this.props.channelTagGroups.filter(tg => tg.target === 'content'),
      materialTagGroups: this.props.channelTagGroups.filter(tg => tg.target === 'material'),
    })

    return this.props.openCloseDialog();
  }

  render = () => {
    const {
      channelTagGroups,
      tagGroups,
      open,
      openCloseDialog,
      editingCategory
    } = this.props;
    const {selectedTab} = this.state;
    const target = this.state.selectedTab === "content" ? "contentTagGroups" : "materialTagGroups"

    const freeTagGroups = tagGroups.filter(tg => {
      if(selectedTab === "content") {
        return !this.state[target].some(tagGroup => tagGroup.id == tg.id) && !tg.creation_group
      }else {
        return !this.state[target].some(tagGroup => tagGroup.id == tg.id) && (tg.creation_group || tg.inheritable)
      }
    })

    const getTagCount = (count) => count == 1 ? localization.get("tag_group.single_tag") : localization.get("tag_group.multiple_tags", count);

    if (!open) return null;

    return (
      <Dialog open={open} onEscapeKeyDown={openCloseDialog}>
        <DialogTitle
          style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",}}>{localization.get('tag_group.customize_title')}{editingCategory}</DialogTitle>
        <Paper square style={{width: '100%', maxWidth: "100%"}}>
          <Tabs
            value={selectedTab}
            onChange={ (event, value) => this.setState({selectedTab: value})}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab wrapped value={"content"} label={"content"} style={{minWidth: 0}}/>
            <Tab wrapped value={"material"} label={"material"} style={{minWidth: 0}}/>
          </Tabs>
        </Paper>
        <DialogContent style={window.innerWidth > 600 ? {width: "600px", height: "70vh"} : {}}>
          <h5 style={{
            background: "lightgrey",
            padding: "4px 24px",
            width: "calc(100% + 48px)",
            marginLeft: "-24px",
            marginRight: "-24px"
          }}>{localization.get('tag_group.allowed')}</h5>
          {(!this.state[target] || this.state[target].length === 0) &&
            <div style={{width: "100%", padding: 10}}>
              {localization.get("tag_group.empty_allowed")}
            </div>
          }
          {this.state[target].length > 0 &&
            <DragDropContext
              onDragEnd={result => {
                if (result.destination) {
                  const destination = result.destination.index;
                  const source = result.source.index;

                  if (destination !== source) {
                    const currentTagGroup = this.state[target];
                    const direction = destination < source ? -1 : 1;
                    const involved = direction === -1 ? currentTagGroup.slice(destination, source) : currentTagGroup.slice(source + 1, destination + 1);

                    currentTagGroup[source].order = currentTagGroup[destination].order;
                    involved.forEach(x => (x.order -= direction));
                    this.setTagGroups(this.state[target].sort((a, b) => a.order - b.order))
                  }
                }
              }}
            >
              <Droppable
                droppableId="navbar-elements"
                direction="vertical"
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 10,
                      overflow: "auto",
                    }}
                    {...provided.droppableProps}
                  >
                    {this.state[target].sort((a, b) => a.order - b.order).map((tagGroup, index) =>
                      <Draggable key={tagGroup.id.toString()} draggableId={tagGroup.id.toString()} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} width={"100%"}>
                            <div style={{
                              height: "45px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center"
                            }}>
                              <Reorder/>
                              <div style={{
                                fontSize: 13,
                                width: "45%",
                                textAlign: "left",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}>{tagGroup.name} {tagGroup.tags && `(${getTagCount(tagGroup.tags.length)})`}</div>
                              <div style={{
                                fontSize: 13,
                                width: "45%",
                                textAlign: "left",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}>
                                <Select
                                  value={tagGroup.view_type}
                                  onClick={event => {
                                    tagGroup.view_type = event.target.value;
                                    this.setTagGroups(this.state[target])
                                  }}
                                  fullWidth
                                >
                                  <MenuItem value={"pill"}>{localization.get('pill')}</MenuItem>
                                  <MenuItem value={"drop_down"}>{localization.get('drop_down')}</MenuItem>
                                  <MenuItem value={"carousel"}>{localization.get('carousel')}</MenuItem>
                                </Select>
                              </div>
                              <IconButton style={{float: "right"}} onClick={() => this.removeFromTagGroup(tagGroup)}>
                                <Clear/>
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          }
          <div>
            <h5 style={{
              background: "lightgrey",
              padding: "4px 24px",
              width: "calc(100% + 48px)",
              marginLeft: "-24px",
              marginRight: "-24px"
            }}>{localization.get('tag_group.not_allowed')}</h5>
            {freeTagGroups.length > 0 && freeTagGroups.map(tagGroupAvailable => {
                return (
                  <div style={{
                    height: "45px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: 'center',
                    padding: 10
                  }}>
                    <div style={{
                      fontSize: 13,
                      width: "90%",
                      textAlign: "left",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>{tagGroupAvailable.name} {tagGroupAvailable.tags && `(${getTagCount(tagGroupAvailable.tags.length)})`}</div>
                    <IconButton style={{float: "right"}} onClick={() => this.addTagGroup(tagGroupAvailable)}>
                      <Add/>
                    </IconButton>
                  </div>
                )
              }
            )}
            {freeTagGroups.length === 0 &&
              <div style={{width: "100%", padding: 10}}>
                {localization.get("tag_group.empty_not_allowed")}
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} ><Close /></Button>
          <Button onClick={() => this.closeWithSave()}><Done /></Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfigTagGroupDialog;