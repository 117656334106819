import "layouts/Home/Home.css";
import React from "react";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";
import Logo from "../../../assets/img/logo-white.png";
import styled from "styled-components";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: "close",
      height: "0%",
    };
  }

  render() {
    return (
      <footer style={{padding: 0}}>
        <div style={{
          padding: "0.55rem 0 0.625rem",
          backgroundColor: "#1E1E1E",
          position: "relative",
          float: "left",
          width: "100%"}}
        >
          <div style={{position: "relative",float: "left", padding: "0px 70px", width: "100%"}}>
            <MobileDiv>
               <span style={{ marginRight: 13, marginTop: -5}}>
                  <a style={{color: 'inherit'}} href="http://feater.me" target="_blank">
                    <img style={{ width: 60, filter: 'brightness(0.5) opacity(0.5)' }} src={Logo} alt="Logo" />
                  </a>
               </span>
            </MobileDiv>
            <div style={{position: "relative", float: "right", marginTop: 8}}/>
          </div>
        </div>
      </footer>
    );
  }
}

const FooterDocumentLink = withStyles({
  title: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
})(({ classes, requestRoute, title }) => {
  return (
    <Link to={requestRoute} target="_blank" rel="nofollow">
      <h6 style={{ marginRight: 20 }}>
        <small className={classes.title}>
          {title}
        </small>
      </h6>
    </Link>
  );
});

export default withStyles(pagesStyle)(Footer);

const MobileDiv = styled.div`
display: inline-flex;
align-self: center;
  @media(max-width: 500px){
  justify-self: center;
  }
`;

const MobileUl = styled.ul`
margin: -5px;
place-self: end;
  @media(max-width: 500px){
  place-self: center;
  }
`;
