import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    name,
    email,
    phone,
    translations,
    type,
    imageId,
    profileId,
    selectedMusics,
    selectedUsers,
    isDemo,
    birthdate,
    countryId,
    socialLinks,
    unsplashCoverImage,
    unsplashProfileImage
  }) => post(`${host}/artists`, makeFormData({
    name,
    email,
    phone,
    translations,
    type,
    imageId,
    profileId,
    selectedMusics,
    selectedUsers,
    isDemo,
    birthdate,
    countryId,
    socialLinks,
    unsplashCoverImage,
    unsplashProfileImage
}))
  .then(response => response.json())
