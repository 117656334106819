import {del} from '../utils'
import {getActiveChannel, getContentCreatorFavorites, setContentCreatorFavorites} from "../authentication/utils";

export default host => id => del(`${host}/channels/${getActiveChannel()}/contentCreators/${id}/favorite`)
  .then(() => {
    const favs = getContentCreatorFavorites();
    
    if (favs.indexOf(id) > -1) {
      favs.splice(favs.indexOf(id), 1)
      setContentCreatorFavorites(favs)
    }
  })
