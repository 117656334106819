import React, {Component} from 'react'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import localization from '../../config/localization'
import {request} from '../../api-client'

const getActionName = actionId => localization.get(`ranking_config.${actionId}`)

const getModelName = modelType => {
  var i = modelType.lastIndexOf("\\");
  var name = modelType.substring(i + 1);
  var key = `ranking_config.${name.toLowerCase()}`;

  return localization.has(key) ? localization.get(key) : name;
}

class RankingConfigs extends Component {

  state = {
    configs: [],
    newValues: []
  }

  componentDidMount = () => this.getConfigs()

  getConfigs = () => request.rankingConfig
    .getAll()
    .then(configs => this.setState({ configs }))
    .catch(e => {
      throw new Error(e);
    });

  saveConfigs = () => request.rankingConfig
    .updateAll(this.state.newValues)
    .then(() => GlobalSnackbar.show({ message: localization.get('ranking_config.updated'), type: GlobalSnackbarTypes.SUCCESS }))
    .catch(e => GlobalSnackbar.show({ message: e.message, type: GlobalSnackbarTypes.ERROR }));

  onChange = (id, points) => {
    let { newValues } = this.state;
    let config = newValues.find(value => value.id === id);

    if (config) {
      config.points = points;
    } else {
      newValues.push({ id, points });
    }
  }

  render = () =>
    <div style={{textAlign: 'center'}}>
      <div>
        <div style={{ display: 'inline-grid', gridTemplateColumns: '140px 80px 140px', gridGap: '20px 50px', alignItems: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>{localization.get('table.action')}</div>
          <div style={{ fontWeight: 'bold' }}>{localization.get('table.model')}</div>
          <div style={{ fontWeight: 'bold' }}>{localization.get('table.points')}</div>
          { this.state.configs.map(config => {
            return [
              <p>{getActionName(config.action)}</p>,
              <p>{getModelName(config.target)}</p>,
              <Input
                key={config.id}
                type='number'
                defaultValue={config.points}
                inputProps={{ style: { textAlign: "right" } }}
                onChange={e => this.onChange(config.id, e.target.value)} />
              ];
            })
          }
        </div>
      </div>
      <Button variant='contained' style={{marginTop: '30px'}} onClick={this.saveConfigs}>{ localization.get('store') }</Button>
    </div>
}

export default RankingConfigs