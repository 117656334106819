import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {request} from "api-client";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {LocalOffer} from '@material-ui/icons'
import localization from "../../../config/localization";

export default class extends Component {

  state = { artists: [] }

  componentDidMount = () => {
      document.title = localization.get('title.artists_new_musics') + ' - Feater'
  }

  requestArtists = () => request.artist.getNewMusics().then(artists => this.setState({ artists }))

  render = () =>
    <AllViewContainer
      initializationRequest={this.requestArtists}
      viewIcon={<LocalOffer />}
      ref='viewContainer'>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Table items={this.state.artists} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </AllViewContainer>

}