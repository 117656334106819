import React, {Component} from "react";
import {get} from 'lodash';
import {request} from "api-client";
import Form from '../Form'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {
  requestTagGroups = (tagSystemId) => request.tagGroup.getAllOfTypeMusic(tagSystemId)
    .then(tgs => {
      this.refs.form.setTagGroups(tgs, !tagSystemId);
      return tgs
    })

  requestMusic = () => request.music.get(this.props.match.params.id);

  requestData = () => this.requestMusic()
    .then(music => {
      //request catalogs tgs
      const tagSystemId = get(music, 'catalog.musicDealer.tagSystem.id');
      this.requestTagGroups(tagSystemId)
        .then(() => this.refs.form.setMusic(music))
        //request main tgs
        .then(() => this.requestTagGroups())
    })

  requestUpdate = (state) => {
    this.setState({ disabled: true })

    const {
      active,
      showInRecents,
      code,
      title,
      selectedTags,
      audioId,
      audioAiffId,
      videoId,
      selectedAlbums,
      bichannel,
      artist,
      inactiveReason,
      owner,
      addAudioAiff,
      selectedBatchAudioFile,
    } = state

    return request.music.update(
      {
        id: this.props.match.params.id,
        active,
        showInRecents,
        code,
        title,
        tagIds: selectedTags,
        audioId: audioId,
        audioAiffId: audioAiffId,
        videoId: videoId,
        albumIds: selectedAlbums && [selectedAlbums.value.albumId],
        bichannel,
        artistId: artist.value,
        inactiveReason,
        owner,
        catalogId: selectedAlbums && selectedAlbums.value.catalogId,
        deleteAiff: !addAudioAiff,
        batchAudioId: selectedBatchAudioFile && selectedBatchAudioFile.value
      })
      .then(() => this.props.history.push(makeUrlWithTableParams('/panel/musics')))
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => this.setState({ image })

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('music.update_music')}
        submitRequest={this.requestUpdate}
        initializationRequest={this.requestData}
        requestTagGroups={this.requestTagGroups}
      />
    )
  }
}
