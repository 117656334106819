import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./Table";
import CreateUser from "../../Users/Create"
import UpdateUser from "../../Users/Update"

const ChannelRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/channel_users/create" render={props => <CreateUser isChannel {...props}/>} />
        <Route path="/panel/channel_users/:id/edit" render={props => <UpdateUser isChannel {...props}/>} />
        <Route
          render={props => (
            <All {...props}/>
          )}
        />
      </Switch>
    </Router>
  )
}

export default ChannelRouting