import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {makeWaveformUrl} from "../../../utils";
import {Visibility} from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import localization from "../../../config/localization";
import moment from 'moment';
import InlineInput from "../../../components/Letflow/Form/InlineInput";

class MusicsTable extends Component {

  state = {
    artists: []
  }

  componentDidMount = () => {
    request.artist.getAll().then(artists => this.setState({ artists }))
  }

  reloadTable = () => this.refs.table.forceUpdate();

  buttons = music => {
    const builder = new TableButtonsBuilder();

    builder.withNewPlay(this.makePlayButtonTrack(music));
    builder.withEdit(this.makeEditUrl(music));
    builder.withOther(this.makeSeeArtistButton(music));
    if (music.artist.users && music.artist.users.length > 0) {
        builder.withSendMessage(music.artist.users.map(user => user.id));
    }

    return builder.build();
  };

  makeEditUrl = music => `/panel/musics/${music.id}/edit`;

  makePlayButtonTrack = music => ({
    title: music.title,
    subtitle: "",
    fetchAudioUrl: () => request.music.audioUrl(music.id),
    onAnyClick: () => this.props.history.push(this.makeEditUrl(music)),
    waveform: makeWaveformUrl(music)
  });

  makeSeeArtistButton = music => <TableButton
      title={localization.get('button.see_home')}
      onClick={() => this.props.history.push(`/public/artist/${music.artist_id}`)}>
      <Visibility/>
    </TableButton>

  onSaveCode = (id, code) => request.music.update({ id, code }).then(this.reloadTable)

  columns = () => [
    {
      Header: localization.get("music.code"),
      accessor: "code",
      width: 250,
      Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
      Cell: cell => <InlineInput defaultValue={cell.value} onSave={value => this.onSaveCode(cell.original.id, value)} />
    },
    {
      Header: localization.get("music.title"),
      accessor: "title",
      Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />,
      maxWidth: 300
    },
    {
      Header: localization.get('table.artist'),
      id: 'artist_id',
      accessor: music => music.artist ? music.artist.name : '-',
      Filter: ({filter, onChange}) =>
        <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected/>
          {this.state.artists.map(artist => {
            return <option key={artist.id} value={artist.id}>{artist.name}</option>
          })}
        </select>,
    },
    {
      Header: localization.get('table.upload_date'),
      id: 'audio[created_at]',
      accessor: music => music.audio && music.audio.created_at,
      Cell: cell => <span>{moment(cell.value).format("DD-MM-YYYY")}</span>,
      sortable: false,
      filterable: false,
    },
    {
      Header: localization.get('table.actions'),
      id: "actions",
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145
    }
  ];

  render = () => {
    return (
      <React.Fragment>
        <ServerSidePaginationTable
          ref="table"
          columns={this.columns()}
          requestTableData={paginationConfig => request.music.getLogicProjects(paginationConfig)}
        />
      </React.Fragment>
    );
  };
}

export default MusicsTable;
