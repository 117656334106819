import React, {Component} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {request} from "../../../../api-client";
import "./HomeLicenses.css";
import LicenseListing from "./LicenseListing/LicenseListing";
import {STATE_COLOR_OPACITY, StateColors} from "./utils";
import {getValueFromQueryParam} from "../../../../utils";
import Paginator from "../../../../components/Letflow/Paginator/Paginator";
import EventManager from "../../../../utils/EventManager";
import events from "../../../../utils/EventManager/events";
import queryString from "querystring";
import localization from '../../../../config/localization'
import {getActiveClient} from "../../../../api-client/core/authentication/utils";


const OWN_MEDIA_TYPES = {
  TELEVISION: localization.get('license.television'),
  RADIO: localization.get('license.radio'),
  INTERNET: localization.get('license.internet'),
  VPNT: localization.get('license.vpnt'),
  TV_INCLUSION: localization.get('license.tv_inclusion'),
  ALL: localization.get('license.all'),
  toArray: () => [
    OWN_MEDIA_TYPES.TELEVISION,
    OWN_MEDIA_TYPES.RADIO,
    OWN_MEDIA_TYPES.INTERNET,
    OWN_MEDIA_TYPES.ALL,
    OWN_MEDIA_TYPES.VPNT,
    OWN_MEDIA_TYPES.TV_INCLUSION,
  ],
  toDisplayString: media => {
    switch (media) {
      case OWN_MEDIA_TYPES.TELEVISION:
        return localization.get('license.television');
      case OWN_MEDIA_TYPES.RADIO:
        return localization.get('license.radio');
      case OWN_MEDIA_TYPES.INTERNET:
        return localization.get('license.internet');
      case OWN_MEDIA_TYPES.ALL:
        return localization.get('license.all');
      case OWN_MEDIA_TYPES.VPNT:
        return localization.get('license.vpnt');
      case OWN_MEDIA_TYPES.TV_INCLUSION:
        return localization.get('license.tv_inclusion');
    }
  },
};

class HomeLicenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenses: [],
      isFetching: false,
      isEmptyLicensesResponse: false,
      filterByTitleOrType: "",
      filterByQuery: "",
      filterByMedia: "all",
      orderBy: "created_at",
      downloadMenuAnchor: null,
      currentPage: 0,
      meta: '',
      newLicense: null,
      renewLicense: null,
    };
  }

  clientId = () => getActiveClient();

  componentDidMount = () => {
    this.providedRenewLicense();
    this.fetchLicensesForClientAndSetThemToState({});
    EventManager.getInstance().subscribe(events.LICENSED_MUSIC, this.fetchLicensesForClientAndSetThemToState);
    let orderBy = queryString.parse(this.props.location.search)['?order_by']
    if (orderBy) {
      this.setState({orderBy})
    }
    this.providedLicenseInQueryString();
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.LICENSED_MUSIC, this.fetchLicensesForClientAndSetThemToState);
  };

  fetchLicensesForClientAndSetThemToState = ({page = 0}) => {
    this.setState({
      isFetching: true,
      isEmptyLicensesResponse: false
    });

    return this.fetchLicensesForClient({page})
      .then(licenses => {
          this.setState({
            licenses: licenses.data,
            isEmptyLicensesResponse: licenses.length === 0,
            meta: licenses.meta
          })
        }
      )
      .finally(() =>
        this.setState({
          isFetching: false
        })
      );
  };

  fetchLicensesForClient = ({page = 0}) => {
   let pagination = {
     page: page,
     perPage: 12,
     filterBy: [],
     orderBy: this.state.orderBy === "created_at" ? { column: "created_at", type: "desc" } : { column: "state", type: this.state.orderBy }
   };

   if(this.state.filterByTitleOrType !== "") {
     pagination.filterBy.push({
       column: "q",
       filter: this.state.filterByTitleOrType
     })
   }
    if(this.state.filterByQuery !== "") {
      pagination.filterBy.push({
        column: "query_filter",
        filter: this.state.filterByQuery
      })
    }
    if(this.state.filterByMedia !== "all") {
      pagination.filterBy.push({
        column: "medias",
        filter: this.state.filterByMedia
      })
    }

   return request.license.getAllForClient(this.clientId(), pagination)
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchLicensesForClientAndSetThemToState({});
    }
  };

  render = () => {
    return (
      <div style={{ margin: "0 10px", minHeight: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%", maxWidth: "1000px", whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', marginTop: 20}}>
            <h1 style={{ textAlign: "left",color:"black", fontSize: "1.825em", lineHeight: "1.4em", margin: "20px 0 10px"}}>{localization.get('account.license.title')}</h1>
            {this.renderLicenseProvidedInQueryString()}
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <TextField
                style={{ position: "relative", width: "250px" }}
                value={this.state.filterByTitleOrType}
                onChange={e => this.setState({ filterByTitleOrType: e.target.value, currentPage: 0 })}
                label={localization.get('account.license.search')}
                onKeyDown={this.handleKeyDown}
              />
              <TextField
                style={{ position: "relative", width: "250px" }}
                value={this.state.filterByQuery}
                onChange={e => this.setState({ filterByQuery: e.target.value, currentPage: 0 })}
                label={localization.get('account.license.search_brand')}
                onKeyDown={this.handleKeyDown}
              />
              <FormControl>
                <InputLabel style={{fontSize: "11px", color: "black"}}>Medias: </InputLabel>
                <Select
                  style={{ width: 250 }}
                  value={this.state.filterByMedia}
                  onChange={e =>
                    this.setState({ filterByMedia: e.target.value, currentPage: 0 },
                      () => this.fetchLicensesForClientAndSetThemToState({})
                    )}
                >
                  <MenuItem value={"all"}> {localization.get('all')}</MenuItem>
                  {OWN_MEDIA_TYPES.toArray().map(x =>
                    <MenuItem value={x}> {OWN_MEDIA_TYPES.toDisplayString(x)}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            {this.renderLicenseStateColorKeys()}

            {this.renderLicenses()}
          </div>
        </div>
      </div>
    );
  };

  renderLicenseProvidedInQueryString = () => {
    return (
      this.state.newLicense && (
        <div>
          <Typography style={{ marginLeft: 10 }}>Nueva Licencia</Typography>
          <LicenseListing licenses={[this.state.newLicense]} requestLicenses={this.fetchLicensesForClientAndSetThemToState} />
        </div>
      )
    );
  };

  renderLicenseStateColorKeys = () => {
    const nonSelectedStyle = {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginRight: "5px",
      padding: "0 7px"
    };

    const selectedStyle = {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginRight: "5px",
      backgroundColor: "#e5e5e5",
      borderRadius: 7,
      padding: "0 7px"
    };

    const makeKey = (color, label, orderBy) => {
      const selected = this.state.orderBy === orderBy;
      return (
        <div style={selected ? selectedStyle : nonSelectedStyle} onClick={() => this.setState({ orderBy }, () => this.fetchLicensesForClientAndSetThemToState({}))}>
          <div style={{ height: "10px", width: "10px", marginRight: "5px", backgroundColor: color, borderRadius: "50%", opacity: STATE_COLOR_OPACITY }} />
          <div style={{ fontSize: "10px", position: "relative", top: "2px", color: selected ? "black" : "dimgray" }}>{label}</div>
        </div>
      );
    };

    return (
      <div style={{ margin: "20px 10px 0 0px", display: "flex"}}>
        <div
          style={{
            fontSize: "11px",
            marginRight: "10px",
            position: "relative",
            top: "2px",
            color: "black"
          }}
        >
          {localization.get('account.license.order_by')}
        </div>
        {makeKey("grey", localization.get('created_at'), "created_at")}
        {makeKey(StateColors.ON_GOING, localization.get('account.license.on_going'), "asc")}
        {makeKey(StateColors.EXPIRED, localization.get('account.license.expired'), "desc")}

      </div>
    );
  };

  providedRenewLicense = () => {
    let renewLicenseId = getValueFromQueryParam("license_id", this.props.location.path);

    if (renewLicenseId){
      request.license.get(renewLicenseId).then(res => this.setState({renewLicense: res}));
    }
  };

  providedLicenseInQueryString = () => {
    let newLicenseId = getValueFromQueryParam("new_license_id", this.props.location.path);

    if (newLicenseId){
      request.license.get(newLicenseId).then(res => this.setState({newLicense: res}));
    }
  };

  renderLicenses = () => {
    const { isFetching, isEmptyLicensesResponse, licenses } = this.state;

    if (isFetching) {
      return <div style={{ textAlign: "center", marginTop: "50px" }}>{localization.get('account.license.getting_licenses')}</div>;
    }

    if (isEmptyLicensesResponse) {
      return <div style={{ textAlign: "center", marginTop: "50px" }}>{localization.get('account.license.no_licenses')}</div>;
    }

    return (
      <div>
        <Paginator pages={this.state.meta.last_page} currentPage={this.state.meta.current_page -1} onPageChange={page => this.fetchLicensesForClientAndSetThemToState({page: page})} />
            <LicenseListing
              licenses={this.state.licenses}
              licenseToRenew={this.state.renewLicense}
              requestLicenses={this.fetchLicensesForClientAndSetThemToState}
            />
      </div>
    );
  };
}

export default HomeLicenses;
