import React from "react"
import {Route, Router, Switch} from "react-router-dom"

import All from './All'

const CloudsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default CloudsRouting
