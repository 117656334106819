import {makeRoutes, redirectTo, route} from "./utils";
import LoginChannel from "../../views/ChannelLogin/Login";
import Register from "../../views/ChannelLogin/Register";
import SetPassword from "../../views/ChannelLogin/Activate/SetPassword";
import ForgotPassword from "../../views/ChannelLogin/ForgotPassword/ForgotPassword";
import CompleteRegister from "../../views/ChannelLogin/Activate/CompleteRegister";
import EmailActivate from "../../views/ChannelLogin/Activate/EmailActivate";
import ErrorPage from "../../layouts/Channel/ErrorPage";
import PublicCreateContentCreator from "../../views/PublicContentCreator";
import PublicContentCreation from "../../layouts/Channel/PublicContentCreation";
import React from "react";
import PublicPlaylist from "../../views/PublicPlaylist";
import PublicReel from "../../views/PublicReel";
import PublicAlbum from "../../views/PublicAlbum";

const allowed = channel => [
  route(`/channel/${channel.id}/login`, LoginChannel),
  route(`/channel/${channel.id}/register`, Register),
  route(`/channel/${channel.id}/password/forgot`, ForgotPassword),
  route(`/channel/${channel.id}/set-password`, SetPassword),
  route(`/channel/${channel.id}/user/activation`, CompleteRegister),
  route(`/channel/${channel.id}/activacion`, EmailActivate),
  route(`/channel/${channel.id}/error-page`, ErrorPage),
  route(`/channel/${channel.id}-:channelName/public/playlists/:token-:playlistName`, props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route(`/channel/${channel.id}-:channelName/public/playlists/:token`, props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route(`/channel/${channel.id}-:channelName/public/content_creators/:contentCreatorId`, PublicCreateContentCreator),
  route(`/channel/${channel.id}-:channelName/public/content_creations/:contentCreationId`, props => < PublicContentCreation {...props} contentCreationId={props.match.params.contentCreationId} />),
  route(`/channel/${channel.id}-:channelName/public/reel/:reelId`, PublicReel),
  route(`/channel/${channel.id}-:channelName/public/albums/:token`, props => <PublicAlbum {...props} token={props.match.params.token} />),
];


const homeRedirect = channel => redirectTo(`/channel/${channel.id}/login?redirect_to=${window.location.pathname}${window.location.search}`);

const excluded = channel => [];

const def = channel => route("/", homeRedirect(channel));

export default channel => makeRoutes(allowed(channel), excluded(channel), def(channel));