import { getActiveChannel } from "../authentication/utils";
import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashProfileImage,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  batchDownloadableMaterialId,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  viewType,
  viewTypeMobile='grid',
}) =>
  post(
    `${host}/channels/${getActiveChannel()}/contentCreators`,
    makeFormData({
      email,
      phone,
      translations,
      imageId,
      coverImageId,
      selectedTags,
      selectedContentCreations,
      socialLinks,
      isDemo,
      countryId,
      observation,
      active,
      selectedCatalogs,
      contentCreatorTypeId,
      weight,
      unsplashCoverImage,
      unsplashProfileImage,
      startActivity,
      batchProfileImageId,
      batchCoverImageId,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      downloadableMaterialLink,
      contentSupervisorId,
      onlyArgentina,
      releaseDate,
      expireDate,
      batchDownloadableMaterialId,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist,
      showDateOnHome,
      viewType,
      viewTypeMobile,
    })
  ).then(response => response.json());
