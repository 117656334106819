import upload from './upload'
import del from './delete'
import get from './get'
import getCropped from './getCropped'
import makeUrl from './makeUrl'
import makeCroppedUrl from './makeCroppedUrl'
import makeFittedUrl from './makeFittedUrl'
import getDownloadLink from "./getDownloadLink";

export default host => ({
  upload: upload(host),
  delete: del(host),
  get: get(host),
  getCropped: getCropped(host),
  makeUrl: makeUrl(host),
  makeCroppedUrl: makeCroppedUrl(host),
  makeFittedUrl: makeFittedUrl(host),
  getDownloadLink: getDownloadLink(host),
})
