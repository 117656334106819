import getAll from './getAll'
import del from './delete'
import create from './create'
import get from './get'
import update from './update'
import getAllWithTags from './getAllWithTags'
import searchFor from "./searchFor";
import integrateToPlatform from "./integrateToPlatform";
import exportAll from './exportAll';
import exportByDate from './exportByDate';
import removeTagFromPlaylist from './removeTagFromPlaylist';
import share from './share';
import createDemo from "./createDemo";
import getForClient from './getForClient';
import getAllForClient from './getAllForClient';
import getAllForSmartSearch from './getAllForSmartSearch';
import contact from "./contact";
import getCandidates from "./getCandidates";
import downloadMaterial from "./downloadMaterial";
import preview from "./preview"
import getHashcode from "./getHashcode"
import getSuggested from "./getSuggested"
import getDownloadLink from "./getDownloadLink"
import duplicate from "./duplicate"
import shareByChannel from "./shareByChannel"
import getAllProducts from "./getAllProducts"
import updateMany from "./updateMany"
import updateViewType from './updateViewType'
import customShare from './customShare'
import canAccess from './canAccess'
import getForClientV2 from './getForClientV2'
import approve from './approve'
import getForChannelUser from './getForChannelUser'
import exportForCommunity from './exportForCommunity'
import updateInClient from './updateInClient'
import isPurchased from './isPurchased'

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  get: get(host),
  update: update(host),
  getAllWithTags: getAllWithTags(host),
  searchFor: searchFor(host),
  integrateToPlatform: integrateToPlatform(host),
  exportAll: exportAll(host),
  exportByDate: exportByDate(host),
  removeTagFromPlaylist: removeTagFromPlaylist(host),
  share: share(host),
  createDemo: createDemo(host),
  getForClient: getForClient(host),
  getAllForClient: getAllForClient(host),
  getAllForSmartSearch: getAllForSmartSearch(host),
  contact: contact(host),
  getCandidates: getCandidates(host),
  downloadMaterial: downloadMaterial(host),
  preview: preview(host),
  getHashcode: getHashcode(host),
  getSuggested: getSuggested(host),
  getDownloadLink: getDownloadLink(host),
  duplicate: duplicate(host),
  shareByChannel: shareByChannel(host),
  getAllProducts: getAllProducts(host),
  updateMany: updateMany(host),
  updateViewType: updateViewType(host),
  customShare: customShare(host),
  canAccess: canAccess(host),
  getForClientV2: getForClientV2(host),
  approve: approve(host),
  getForChannelUser: getForChannelUser(host),
  exportForCommunity: exportForCommunity(host),
  updateInClient: updateInClient(host),
  isPurchased: isPurchased(host)
})
