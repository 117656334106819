import "layouts/Home/Home.css";
import React from "react";
import NoImage from "../../../assets/img/Letflow/no-image.jpg";
import SavedQueryImage from "../../../assets/img/Letflow/savedQuery.jpg";
import PlaylistImage from "../../../assets/img/Letflow/playlist.jpg";
import FavoriteImage from "../../../assets/img/Letflow/favorite.jpg";
import {Tooltip} from "@material-ui/core";
import {request} from "../../../api-client";
import EventManager, {events} from "../../../utils/EventManager";
import {
  channelCanAddToFavorites, generateLink, getActiveChannel,
  getActiveClient, getUseTools, getStoredChannel, getStoredUser, getMainTranslation,
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import ShareDialog from "../../../components/Letflow/ShareDialog";
import Link from "react-router-dom/Link";
import styleFixed from "./Styles/Grid/directors.module.css"
import hoverStyleX6 from "./Styles/Grid/musicRecommended.module.css";
import styleX4 from "./Styles/Grid/musicLibrary.module.css";
import styleX4Round from "./Styles/Grid/gridX4Round.module.css";
import styleRectangle from "./Styles/Grid/books.module.css";
import styleRectangleX4 from "./Styles/Grid/booksX4.module.css";
import style16_9 from "./Styles/Grid/grid16_9.module.css";
import style16_9X4 from "./Styles/Grid/grid16_9X4.module.css";
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import {ReactComponent as List} from "../../../components/Letflow/SvgIcons/list.svg"
import {ReactComponent as Heart} from "../../../components/Letflow/SvgIcons/heart.svg"
import {ReactComponent as Share} from "../../../components/Letflow/SvgIcons/share.svg"
import {ReactComponent as Person} from "../../../components/Letflow/SvgIcons/person.svg"
import font from "./Styles/Fonts/fonts.module.css";
import {ReactComponent as Group} from "../../../components/Letflow/SvgIcons/groups.svg";
import {makeCustomClick, makeCustomLink} from "./utils";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import EventDatesInfo from "../../../components/Letflow/EventDatesInfo";
import st from "../HomeNew.module.css";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { YouTubeEmbed } from 'react-social-media-embed';
import {get} from "lodash"

class GridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favorite: props.data.favorite,
      id: this.props.data.id,
      showShareDialog: false,
      playlistToShareId: null,
      limitReached: false,
      shareUrl: null
    };
    this.catchedClick = false;
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
    this.checkViewsLimit()
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_ITEM_DESCRIPTION, this.addToFavoriteItemDescription)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION, this.removeFromFavoritesItemDescription)
  }

  checkViewsLimit = () => {
    if (this.props.data.view_limit > 0 && this.props.data.views >= this.props.data.view_limit) {
      this.setState({limitReached: true})
    }
  }

  addToFavoriteItemDescription = ({id, type}) => {
    this.addToFavorites({id, type})
  }

  removeFromFavoritesItemDescription = ({id, type}) => {
    this.removeFromFavorites({id, type})
  }

  addToFavorites = ({id, type}) => {
    if (id === this.state.id && this.props.data.kind === type) {
      !!getStoredUser() && this.setState({favorite: true})
      EventManager.getInstance().dispatch(events.ADD_TO_FAVORITE_BUTTON_PRESS, {id, type});
    }
  }

  removeFromFavorites = ({id, type}) => {
    if (id === this.state.id && this.props.data.kind === type) {
      this.setState({favorite: false})
      EventManager.getInstance().dispatch(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, {id, type});
    }
  }

  renderShareDialog = () => {
    if (this.state.shareUrl) {
      return (<ShareOnSocials
        show={this.state.showDialog}
        handleClose={() => this.setState({showDialog: false, shareUrl: null})}
        url={this.state.shareUrl}
        darkMode={true}
      />);
    }
    return (<ShareDialog
      show={this.state.showShareDialog}
      handleClose={() => this.setState({showShareDialog: false})}
      entity={'playlist'}
      id={this.state.playlistToShareId}
    />);
  }

  sharePlaylist = (playlistId) => {
    if (getUseTools()) {
      return request.playlist.shareByChannel(playlistId)
        .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    }else {
      request.playlist.share(playlistId, null, getActiveClient())
        .then(response => this.setState({
          showDialog: true, 
          shareUrl: `${window.location.protocol}//${window.location.hostname}/public/playlists/${response.share_token}`
        }))
        .catch(GlobalSnackbar.showGenericError)
    }
  }
  shareAlbum = (albumId) => {
    if (getUseTools()) {
      return request.album.shareByChannel(albumId)
        .then(rest => this.setState({showDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    }else {
      request.album.share(albumId, null, getActiveClient())
        .then(response => this.setState({
          showDialog: true, 
          shareUrl: `${window.location.protocol}//${window.location.hostname}/public/albums/${response.share_token}`
        }))
        .catch(GlobalSnackbar.showGenericError)
    }
  }

  toggleModalFavorite = () => this.setState({favorite: !this.state.favorite});

  openContentCreatorModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.kind === 'video_content_creation' ? this.props.data.content_creator_id : this.props.data.id,
      dataType: "contentCreator",
      dataName: this.props.data.kind === 'video_content_creation' ? '' : this.props.data.name,
      onTagChange: this.props.handleNewSearch || false,
      origin: this.props.home ? (this.props.homeElement ? getMainTranslation(this.props.homeElement, "title", true): "") : this.props.search ? 'Search' : this.props.realated ? 'Related' : '',
    });
  }

  openPlaylistModal = () => {
    EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
      id: this.props.data.id,
      dataType: "playlist",
      dataName: this.props.data.name || this.props.data.title,
    });
  }

  makeFavoriteButton = (data, favorite, icon) => {
    if (!this.state.limitReached) {
      let onClick = ''
      const trackingName = getMainTranslation(data, "name")


      if (favorite) {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation()

          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Remove",
          });
          this.removeFromFavorites({id: data.id, type: this.props.data.kind})
        }
      } else {
        onClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          // GAevent('Contenido', "Favorite", data.name || data.title)
          analyticsInstance.favorite({
            name: trackingName || getMainTranslation(data, "title"),
            type: this.props.data.kind === "content_creator" ? "Content" : this.props.data.kind === "video_content_creation" ? "Material" : this.props.data.kind,
            content: "Add"
          });
          this.addToFavorites({id: data.id, type: this.props.data.kind})
        }
      }

      switch (icon) {
        case 'star':
          return <Star onClick={onClick}/>
        case 'heart':
          return <Heart onClick={onClick}/>
        case 'list':
          return <List onClick={onClick}/>
      }
    }
  }

  getStoredChannelContainerTitleWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_title) {
      switch(channel.preferences.container_title.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default-text";
  }

  getStoredChannelContainerDescriptionWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.container_description) {
      switch(channel.preferences.container_description.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "default-text";
      }
    }
    return "default_text";
  }

  handleClick = event => {
    const {data, onClick} = this.props;
    const mustCatchClick = window.innerWidth < 650 && data && data.kind == 'content_creator';

    if (!this.catchedClick && mustCatchClick) {
      this.catchedClick = true;
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    if (!['artist', 'production', 'content_creator', 'playlist', 'video_content_creation', 'custom'].includes(data.kind)) {
      event.preventDefault();
      onClick();
    } else if (data.type === 'custom') {
      makeCustomClick(event, data)
    }else if (data.kind === 'content_creator') {
      if (this.state.limitReached) {
        event.preventDefault();
        EventManager.getInstance().dispatch(events.OPEN_VIEWS_LIMIT_MODAL);
      } else if (!getActiveChannel() || getStoredChannel().use_canonicals != "1") {
        event.preventDefault();
        this.openContentCreatorModal();
      }
    } else if (data.kind === 'playlist') {
      event.preventDefault();
      this.openPlaylistModal();
    } else if (data.kind === 'video_content_creation'){
      event.preventDefault();
      EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  this.props.data.id, materialList: this.props.data.materialIds || []});
    }
  }

  render = () => {
    const {data} = this.props;
    const style = this.props.fixedTitle ? styleFixed : this.props.shape === 'rectangle' ? (this.props.maxPerRow === 6 ? styleRectangle : styleRectangleX4) : this.props.shape === '16:9' ? (this.props.maxPerRow === 6 ? style16_9 : style16_9X4) : this.props.maxPerRow === 6 ? hoverStyleX6 : this.props.shape === 'round' ? styleX4Round : styleX4;
    const isX4 = !this.props.fixedTitle && this.props.maxPerRow != 6 && this.props.shape != 'round';

    const eventDates = data.eventDates ? data.eventDates.filter(ed => ed.preferences && !(ed.preferences instanceof Array && ed.preferences.length == 0)) : null;

    if(this.props.data.data_source == 'embed') {
      return (
       <div
           className={`${style['photo-container', 'embed-container']}`}
         >
           <YouTubeEmbed
             placeholderImageUrl={data.image ? request.file.image.makeCroppedUrl(data.image.id, 400, this.props.shape === 'rectangle' ? 600 : this.props.shape === '16:9' ? 225 : 400, 'file', 'jpg') : null}
             url={get(this.props.data, "extra.link", "")}
             width={"100%"}
             height={"100%"}
           />
       </div>
      );
   }
    return (
      <>
        {this.state.limitReached &&
        <Tooltip placement={"top"} title={localization.get('content.views_limit_reached')}>
          <Group className={style['limit-reached']}/>
        </Tooltip>
        }
        <Link
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          className={`${style['photo-container']} ${this.props.withBackground && style['background']}`}
          style={this.props.search ? {backgroundColor: "var(--secondary-color, #f2f2f2)"} : {}}
          to={data.type === 'custom' ? makeCustomLink(data) : generateLink(data.kind, data.id, data.title || data.name, false)}
          onClick={this.handleClick}
        >
          <div
            className={style['photo']}
            style={{
              cursor: "pointer",
              backgroundImage: `url(${
                (data.image && data.kind === 'speaker')
                  ? request.file.image.makeCroppedUrl(data.image.id, 400, this.props.shape === 'rectangle' ? 600 : this.props.shape === '16:9' ? 225 : 400, 'speaker', 'jpg', 'grayscale')
                  : data.image
                  ? request.file.image.makeCroppedUrl(data.image.id, 400, this.props.shape === 'rectangle' ? 600 : this.props.shape === '16:9' ? 225 : 400, 'file', 'jpg')
                  : data.kind && data.kind === "savedQuery"
                    ? SavedQueryImage
                    : data.kind && data.kind === "playlist"
                      ? PlaylistImage
                      : data.kind && data.kind === "favorite"
                        ? FavoriteImage
                        : NoImage
              }), url(${NoImage})`,
            }}
          >
            {!this.props.rounded && ((['rectangle', '16:9'].includes(this.props.shape) && !this.props.onlyImage) || (this.props.shape === 'square' && this.props.withText)) && data.additions && data.additions !== ";" && !!data.additions.split(';')[1] &&
              <div
                className={`${style["section"]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
              >
                {data.additions.split(';')[1]}
              </div>
            }
          </div>
        </Link>

        {(['album', 'content_creator', 'speaker', 'artist', 'video_content_creation', 'playlist', 'custom'].includes(data.kind) || data.client_type) && !(['rectangle', '16:9'].includes(this.props.shape) && !this.props.onlyImage) && !(this.props.shape === 'square' && this.props.withText) &&
        <Link
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          className={style['info']}
          to={data.type === 'custom' ? makeCustomLink(data) : generateLink(data.kind, data.id, data.title || data.name, false)}
          style={this.props.search && this.props.shape != "round" ? {backgroundColor: "var(--secondary-color, #f2f2f2)"} : {}}
          onClick={this.handleClick}
        >
          <div
            className={`${style["title"]} ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
            style={getActiveChannel() ? {
              fontSize: this.props.fixedTitle ? Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 0.7) : this.props.maxPerRow === 6 ? getStoredChannel().preferences.container_title.size : Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 1.1),
            } : {}}
          >
            {data.title || data.name}
          </div>
          {!this.props.fixedTitle && (data.kind === 'album' || isX4) &&
          <div
            className={`${style["text"]} ${st[this.getStoredChannelContainerDescriptionWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
            style={getActiveChannel() ? {
              fontSize: this.props.maxPerRow !== 6 ? getStoredChannel().preferences.container_description.size : Math.round(parseInt(getStoredChannel().preferences.container_description.size) * 0.7),
            } : {}}
          >
            {data.artist ? localization.get('compiled') : data.subtitle}
          </div>
          }
        </Link>
        }
        {((['rectangle', '16:9'].includes(this.props.shape) && !this.props.onlyImage) || (this.props.shape === 'square' && this.props.withText)) &&
          <div
            className={`${style["bottom-title"]} ${st[this.getStoredChannelContainerTitleWeight()]} ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}
            onClick={this.handleClick}
            style={getActiveChannel() ? {
              fontSize: this.props.maxPerRow === 6 ? getStoredChannel().preferences.container_title.size : Math.round(parseInt(getStoredChannel().preferences.container_title.size) * 1.1),
              backgroundColor: getActiveChannel() ? getStoredChannel().preferences.element_background : "transparent",
            } : {}}
          >
            {data.title || data.name}
          </div>
        }

        {['album', 'speaker', 'content_creator', 'artist', 'playlist', `${getActiveChannel() ? "" : 'video_content_creation'}`].includes(this.props.data.kind) && data.type !== 'custom' &&
        <Link
          rel={(data.type === 'custom' && data.data_source == 'link') ? "nofollow" : "canonical"}
          className={(this.props.shape === 'square' && this.props.withText) ? `${style['icons']} ${style['iconsWithText']}` : style['icons']}
          to={data.type === 'custom' ? makeCustomLink (data) : generateLink(data.kind, data.id, data.title || data.name, false)}
          onClick={this.handleClick}
        >
          {(!getActiveChannel() || getUseTools())  || (getActiveChannel() && channelCanAddToFavorites()) ?
            <>
              {(!getActiveChannel() || getUseTools())  &&
              <>
                {data.kind === "content_creator" &&
                  <AddToPlaylist
                    clientId={getActiveClient()}
                    content='contentCreators'
                    item={data}
                    contentCreatorType={data.type.slug}
                    extraClass={style['list']}
                    tooltipTitle={localization.get("add_to_playlist")}
                    tooltipPlacement='top'
                  />
                }
                {data.kind === "video_content_creation" &&
                  <>
                    {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) &&
                      <AddToPlaylist 
                        clientId={getActiveClient()}
                        content='contentCreations'
                        item={data}
                        extraClass={style['list']}
                        tooltipTitle={localization.get("ranking_config.playlist_add")}
                      />
                    }
                    <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                      <div className={style['list']}>
                        <Person onClick={(e) => {
                          e.stopPropagation();
                          this.openContentCreatorModal()
                        }}/>
                      </div>
                    </Tooltip>
                  </>
                }
              </>
              }

              {data.kind === "playlist" &&
              <Tooltip placement="bottom" title={localization.get("share")}>
                <div className={style['star']}>
                  <Share onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.sharePlaylist(data.id);
                  }}/>
                </div>
              </Tooltip>
              }
              {data.kind === "album" && getStoredChannel() && 
                <Tooltip placement="bottom" title={localization.get("share")}>
                  <div className={style['list']} style={{width: 24}}>
                    <Share onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.shareAlbum(data.id);
                    }}/>
                  </div>
                </Tooltip>
              }

              <div className={`${style['star']} ${this.state.favorite && style['active']}`}>
                {this.makeFavoriteButton(data, this.state.favorite, !getActiveChannel() ? 'star' : getStoredChannel().preferences.fav_icon)}
              </div>

            </>
            :
            <>
              {data.kind === "video_content_creation" &&
              <Tooltip placement="bottom" title={localization.get("go_to_content_creator")}>
                <div className={style['list']}>
                  <Person onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.openContentCreatorModal()
                  }}/>
                </div>
              </Tooltip>
              }
            </>
          }
        </Link>
        }
        {this.props.showEvents && eventDates && eventDates.length > 0 && 
          <div className={`${style["event-dates"]}`} style={{position: 'absolute', left: 20, top: 20, height: 35, zIndex: 4}}>
            <EventDatesInfo eventDates={eventDates} chipColor={"var(--secondary-color, #f2f2f2)"} chipFontColor={"var(--title-normal, #f2f2f2)"} tooltipPlacement="bottom-start"/>
          </div>
        }

        {/*<div className="arrow-wrap">
            <div className="arrow-down" />
          </div>*/}

        <div className="album-grid-expand album-info"/>
        {this.renderShareDialog()}
      </>
    );
  };
}

export default GridItem;
