import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  blogLocationId,
  name,
  code,
  }) =>
  post(`${host}/blogLocations/${blogLocationId}`, addPutMethodToForm(makeFormData({
    name,
    code,
  })))
  .then(response => response.json())