import React from "react";
import TagSelection from '../../../components/Letflow/TagSelection/TagSelection'
import localization from '../../../config/localization'
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

const FormTagSelection = ({
  tagGroups,
  onSelectedTagsChanged,
  disabled = false,
  hideTitle = false,
  showClientSelector = false,
  client,
  availableClients,
  onClientChange
}) =>
  <div>
    <h4 style={{display: hideTitle && 'none'}}>{localization.get('tags')}</h4>
    {showClientSelector &&
      <FormControl fullWidth>
        <span>{localization.get('music.tag_group_client_owner')}</span>
        <RadioGroup
          id="client"
          name="client"
          value={client || 0}
          onChange={e => onClientChange(e)}
          style={{ width: "100%", float: "left", position: "relative", flexDirection: "row", marginTop: 15}}
        >
          {availableClients.map(availableClient =>
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value={availableClient.value} control={<Radio color="default" />} label={availableClient.label} />
          )}
        </RadioGroup>
      </FormControl>
    }

    <div style={{ textAlign: 'center' }}>
      <TagSelection data={tagGroups} onSelectedTagsChanged={onSelectedTagsChanged} disabled={disabled} />
    </div>
  </div>

export default FormTagSelection
