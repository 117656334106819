import React, {Component} from 'react'
import localization from '../../../config/localization';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Form from "../Form";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {IconButton} from "@material-ui/core";
import {request} from "../../../api-client";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import Edit from "@material-ui/icons/Edit";
import CloudSubcomponent from "../../ContentCreations/All/Subcomponent";

class Clouds extends Component {
  state = {
    openDialog: false,
    editingCloud: null,
  }

  componentDidMount = () => {
    document.title = localization.get('title.clouds') + ' - Feater'
  }

  onAdd = () => this.setState({ openDialog: true })
  reloadTable = () => this.refs.table.forceUpdate();

  handleAlertClosed = musicId => status => {
    if (status === "passed") this.refs.table.removeById(musicId);
  };

  buttons = cloud => {
    const builder = new TableButtonsBuilder();

    builder.withOther(
      <TableButton
        title={localization.get('edit')}
      >
        <IconButton onClick={() => this.setState({openDialog: true, editingCloud: cloud })}>
          <Edit />
        </IconButton>
      </TableButton>)

    builder.withDelete(cloud.name, () => request.clouds.delete(cloud.id), this.reloadTable)

    return builder.build();
  };

  columns = () => {
    let columns = []

    const nameColumn =
      {
        Header: localization.get("client.name"),
        accessor: "name",
        Filter: ({ filter, onChange }) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder={localization.get('table.search')}
          />
        ),
      };

    const usersColumn =
      {
        Header: localization.get("table.users_count"),
        id: "userCount",
        accessor: 'userCount',
        filterable: false,
        sortable: false,
      };

    const creatorCount =
      {
        Header: localization.get("table.content_creator_count"),
        id: "contentCreatorCount",
        accessor: 'contentCreatorCount',
        filterable: false,
      }

    const catalogColumn =
      {
        Header: localization.get("table.catalog"),
        id: "catalog",
        accessor: cloud => cloud.catalog.name,
        filterable: false,
        sortable: false,
      };

    const actionColumn =
      {
        Header: localization.get('table.actions'),
        id: "actions",
        sortable: false,
        filterable: false,
        accessor: this.buttons,
        width: 190,
        Footer: (
          <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>Total: </strong>
            {this.state.total}
        </span>
        ),
      };

    columns.push(nameColumn);
    columns.push(usersColumn);
    columns.push(creatorCount);
    columns.push(catalogColumn);
    columns.push(actionColumn);

    return columns
  }

  render = () =>
    <AllViewContainer
      onAdd={this.onAdd}
      ref='viewContainer'>
      <Form
        show={this.state.openDialog}
        editingCloud={this.state.editingCloud}
        handleClose={() => this.setState({ openDialog: false, editingCloud: null }, this.reloadTable)}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <div>
                <div style={{ width: "100%", float: "left", position: "relative" }}>
                  <ServerSidePaginationTable
                    ref="table"
                    columns={this.columns()}
                    subComponent={row => <CloudSubcomponent {...this.props} cloud={row.original} />}
                    requestTableData={paginationConfig => {
                      return request.clouds.getAll(paginationConfig).then(response => {
                        this.setState({ total: response.meta.total});
                        return response;
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default Clouds