import {isNullOrUndefined} from "util";

export const makeFormData = ({name, imageId, hasBackgroundImage, active}) => {
  const form = {}
  if (name) form.name = name
  if (imageId) form.imageId = imageId
  if (!isNullOrUndefined(hasBackgroundImage)) form.has_background_image = hasBackgroundImage
  if (!isNullOrUndefined(active)) form.active = active

  return form
}