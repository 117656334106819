import React, {Component} from "react";
import {request} from "../../../../api-client";
import {ImagePicker, TextField} from '../../../../components/Letflow/Form'
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from "../../../../config/localization";
import noImage from "../../../../assets/img/Letflow/no-image.jpg";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import {getUpdateFormImageUrlFromItem} from "../../../../utils";
import {getActiveClient, setPageTitle} from "../../../../api-client/core/authentication/utils";

export default class extends Component {

  state = {
    name: '',
    email: '',
    image: { src: null, file: null },
    user: undefined,
    disabled: false,
    reelUrl: '',
  };

  componentDidMount = () => {
    setPageTitle(localization.get('title.my_company'))
    document.getElementsByTagName('html')[0].scrollTop = 0
  };

  requestClient = () => request.client.get(getActiveClient()).then(client => this.setState({
    name: client.name,
    cuit: client.cuit,
    address: client.address,
    image: {
      src: getUpdateFormImageUrlFromItem(client),
      file: null,
    },
    reelUrl: client.reel_url
  }));

  requestUpdate = () => request.client.update(getActiveClient(),{ name: this.state.name, image: this.state.image.file, cuit: this.state.cuit, address: this.state.address, reelUrl: this.state.reelUrl })
      .then(() => GlobalSnackbar.show({type: GlobalSnackbarTypes.SUCCESS, message: localization.get('account.client_updated')}));

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  handleImageChange = image => this.setState({ image });

  imageProvider = () => this.state.image.src || noImage;

  handleRequestingStateChanged = disabled => this.setState({ disabled });
  render = () => {
    const {
      name,
      cuit,
      address,
      disabled,
      reelUrl
    } = this.state;

    return (
      <div>

        <FormViewContainer
          onBack={() => this.props.history.goBack()}
          title={localization.get('form.update_client')}
          initializationRequest={this.requestClient}
          submitRequest={this.requestUpdate}
          onRequestingStateChange={this.handleRequestingStateChanged}
          style={{maxWidth: "600px", width: "100%", display: "table", margin: "30px auto"}}
          color="black"
        >
          <TextField
            label={localization.get('form.name')}
            id="name"
            value={name}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <TextField
            label={localization.get('form.cuit')}
            id="cuit"
            value={cuit}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <TextField
            label={localization.get('form.address')}
            id="address"
            value={address}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <TextField
            label={localization.get('form.reel_url')}
            id="reelUrl"
            value={reelUrl}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <ImagePicker
            buttonText={localization.get('form.select_image')}
            onImageChange={this.handleImageChange}
            image={this.imageProvider()}
            disabled={disabled}
            style={{backgroundColor: "black"}}
            color={'default'}
          />
        </FormViewContainer>
      </div >
    )
  }
}
