import {isNullOrUndefined} from "util";

export const makeFormData = ({
  translations,
  code,
  visibility,
  image,
  selectedClients,
  selectedClient,
  selectedBlogTags,
  selectedBlogLocations,
}) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
      if (!isNullOrUndefined(translation.content)) form.append(`translations[${translation.language}][content]`, translation.content)
    })
  }
  if (code) form.append('code', code)
  if (visibility) form.append('visibility', visibility)
  if (image) form.append('image', image, image.name)
  if (selectedClient) form.append('clients_ids[]', selectedClient)
  if (selectedClients) selectedClients.forEach(x => form.append('clients_ids[]', x))
  if (selectedBlogTags) selectedBlogTags.forEach(x => form.append('blog_tags_ids[]', x))
  if (selectedBlogLocations) selectedBlogLocations.forEach(x => form.append('blog_locations_ids[]', x))
  return form
}