import React, {Component} from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import {Add, Delete, Edit} from '@material-ui/icons';
import localization from '../../../config/localization';
import {request} from '../../../api-client';
import GlobalConfirmationDialog from '../../../components/Letflow/Dialog/GlobalConfirmationDialog';
import FormControl from "@material-ui/core/FormControl";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import HomeGridAndCarouselBase from "../HomeGridAndCarouselBase";
import HomeElementCreateDialog from "../HomeElementCreateDialog";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class CustomElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customElements: props.customElements || [],
      customElementsToDelete: [],
      showForm: false,
      openDropDownDialog: false,
      dropDownElement: null,
      openCloseNavbarDialog: false,
      openNavbarDialog: false,
      elementId: null,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({customElements: nextProps.customElements})
  }

  addNavbarElement = element => {
    this.state.customElements.push(element)
    this.setState({customElements: this.state.customElements})
  }

  updateNavbarElement = element => {
    this.setState({
      customElements: this.state.customElements.map(nElement => {
        if(nElement.id === element.id) {
          return element;
        }
        return nElement
      })
    }, () => this.props.onUpdateSuccess(this.state.customElements))
  }

  requestDelete = id => request.client.home.elements.delete(this.props.clientId, id)
    .then(() => this.setState({customElements: this.state.customElements.filter(element => element.id !== id)}))
    .catch(this.props.onUpdateFailed)
  handleDelete = id => GlobalConfirmationDialog.showDelete({ elementName: 'custom', deleteRequest: () => this.requestDelete(id) })
  openCloseEditDropDownDialog = (dropDownElement= null) => this.setState({openDropDownDialog: !this.state.openDropDownDialog, dropDownElement: dropDownElement})
  openCloseNavbarDialog = () => this.setState({openNavbarDialog: !this.state.openNavbarDialog})
  openCloseEditDialog = element => this.setState({editingElement: element, openEditDialog: !this.state.openEditDialog})

  onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(this.state.customElements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    const orderData = items.map((element, index) => {
      return ({...element, order: index+10})
    })

    this.updateOrder(orderData)
    return this.setState({customElements: orderData})
  };

  updateOrder = async (items) => {
    return request.client.home.elements.updateOrder(this.props.clientId, items)
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.order_updated'),
          type: GlobalSnackbarTypes.SUCCESS,
        })
      })
      .catch(error => GlobalSnackbar.showGenericError())
  }

  render = () => {
    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: 5 * 2,
      margin: `0 ${5}px 0 0`,
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    return (
      <React.Fragment>
        <Dialog open={this.state.openNavbarDialog} onBackdropClick={this.openCloseNavbarDialog}
                onEscapeKeyDown={this.openCloseNavbarDialog}>
          <HomeElementCreateDialog
            contentCreatorTypes={this.props.contentCreatorTypes.length ? this.props.contentCreatorTypes : []}
            type={'custom'}
            isNavbar={true}
            isCreating={true}
            addElement={this.addNavbarElement}
            onClose={() => this.setState({openNavbarDialog: false, editingElement: false})}
            channel={{...this.props.channel, creatingElements: true, isEmbed: this.props.isEmbed}}
            channelId={this.props.channelId}
            clientId={this.props.clientId}
            custom={true}
            permissions={this.props.permissions}
            parent={this.props.parent}
            availableChannelPlaylists={this.props.availableChannelPlaylists}
            section={this.props.section}
            sections={this.props.sections}
          />
        </Dialog>
        <Dialog
          open={this.state.openDropDownDialog}
          onBackdropClick={this.openCloseEditDropDownDialog}
          onEscapeKeyDown={this.openCloseEditDropDownDialog}
        >
          <DialogTitle>{localization.get("navbar_drop_down_buttons")}</DialogTitle>
          <div style={{width: "100%", display: "flex", flexDirection: "column", minWidth: 300}}>
            {this.state.elementId &&
            <>
              {this.state.customElements.sort((a,b) => a.order - b.order).filter(element => !!element.parent && element.parent == this.state.elementId.id).map(element =>
                <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                  <span style={{
                    position: "relative",
                    width: "65%",
                    padding: "5px 25px"
                  }}>{element.reference || localization.get(element.data_source)}</span>
                  <div style={{position: "relative", width: "35%"}}>
                    <IconButton onClick={() => this.openCloseEditDialog(element)}>
                      <Edit
                        style={{
                          padding: 8,
                          cursor: "pointer",
                          fontSize: 35,
                          bottom: 5
                        }}
                        color="info"
                      />
                    </IconButton>
                    <IconButton onClick={() => this.handleDelete(element.id)}>
                      <Delete
                        style={{
                          padding: 8,
                          cursor: "pointer",
                          fontSize: 35,
                          bottom: 5
                        }}
                        color="info"
                      />
                    </IconButton>
                  </div>
                </div>
              )}
            </>
            }
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <IconButton onClick={this.openCloseNavbarDialog}>
                <Add
                  style={{
                    padding: 8,
                    textAlign: "center",
                    fontSize: 40,
                    cursor: "pointer"
                  }}
                  color="info"
                />
              </IconButton>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={this.state.openEditDialog}
          onBackdropClick={() => this.setState({openEditDialog: false, editingElement: false})}
          onEscapeKeyDown={() => this.setState({openEditDialog: false, editingElement: false})}
        >
          <HomeGridAndCarouselBase
            {...this.state.editingElement}
            contentCreatorTypes={this.props.contentCreatorTypes.length ? this.props.contentCreatorTypes : []}
            type={'custom'}
            isNavbar={true}
            isCreating={true}
            isCustom={true}
            permissions={this.props.permissions}
            onClose={element => this.setState({
              openEditDialog: false,
              editingElement: false
            }, () => element && this.updateNavbarElement(element))}
            isModal={true}
            parent={this.props.parent}
            channelId={this.props.channelId}
            clientId={this.props.clientId}
            channel={{...this.props.channel, creatingElements: true}}
            custom={true}
            availableChannelPlaylists={this.props.availableChannelPlaylists}
            sections={this.props.sections}
          />
        </Dialog>
        <FormControl fullWidth style={{maxWidth: 1000}}>
          <Card style={{width: '100%', maxWidth: 1000}}>
            <CardContent>
              <Typography variant='display1'>{localization.get('elements')}</Typography>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                  droppableId={`drop-${this.props.parent.id}`}
                  direction="horizontal"
                  type={this.props.parent.id}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        padding: 10,
                        overflow: "auto",
                      }}
                      {...provided.droppableProps}
                    >
                      {(this.state.customElements && this.state.customElements.length > 0) && 
                        this.state.customElements.sort((a, b) => a.order - b.order)
                          .map((element, index) =>
                          <Draggable key={element.id.toString()} draggableId={element.id.toString()} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                width={"16.66%"}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Card style={{height: "170px", minWidth: 100, maxWidth: 130}}>
                                  <h3 style={{
                                    fontSize: 13,
                                    textAlign: "center",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    height: 36
                                  }}>{localization.get(element.data_source)}</h3>
                                  <h3 style={{
                                    fontSize: 13,
                                    textAlign: "center",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    lineHeight: "1.5em",
                                    height: "3em",
                                  }}>{element.reference}</h3>
                                  <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                                    <IconButton style={{margin: "-4px 0px"}}
                                                onClick={() => this.openCloseEditDialog(element)}><Edit/></IconButton>
                                    <IconButton style={{margin: "-4px 0px"}}
                                                onClick={() => this.handleDelete(element.id)}><Delete/></IconButton>
                                  </CardActions>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardContent>
            <CardActions style={{float: 'right'}}>
              <IconButton onClick={this.openCloseNavbarDialog}><Add/></IconButton>
            </CardActions>
          </Card>
        </FormControl>
      </React.Fragment>)
  }
}

export default CustomElement