import React from 'react'
import localization from '../../../../config/localization';
import {Edit} from '@material-ui/icons'
import TableButton from './TableButton';
import {Link} from "react-router-dom";

const TableEditButton = ({path, color}) =>
  <Link to={path}>
    <TableButton title={localization.get('tooltip.edit')}>
      <Edit style={color ? {color} : {}}/>
    </TableButton>
  </Link>
export default TableEditButton