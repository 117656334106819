import {get} from '../utils'
import {isPublicChannel} from "../authentication/utils";

export default host => ({
  clientId,
  tagGroupType,
  contentCreatorType = null,
  tags,
  notTags,
  lastTagId,
  channelId,
  contentCreatorId=null, 
  startDate = null,
  endDate = null,
  publishStartDate = null,
  publishEndDate = null
}) =>
  get(`${host}${isPublicChannel() ? "/public": ""}/clients/${clientId}${!!channelId ? `/channels/${channelId}` : ""}/tags/${tagGroupType}${contentCreatorType ? `/${contentCreatorType}` : ''}?tags_ids=${tags.join(',')}&not_tags_ids=${notTags.join(',')}${lastTagId ? `&last_tag_id=${lastTagId}` : ''}${contentCreatorId ? `&for_creator=${contentCreatorId}` : ''}${startDate && endDate ? `&date_start=${startDate}&date_end=${endDate}`: ''}${publishStartDate && publishEndDate ? `&publish_date_start=${publishStartDate}&publish_date_end=${publishEndDate}`: ''}`)
    .then(response => response.json())
    .then(json => json.data)
