import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeImageUrl, makeUrlWithTableParams} from '../../../utils'
import localization from '../../../config/localization'
import ArtistSubcomponent from "./TableSubComponent";
import {
  getStoredUserRoleId,
  ROLES,
  userisMultiClient
} from "../../../api-client/core/authentication/utils";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {Visibility} from "@material-ui/icons";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import { onImageError } from "../../../layouts/Home/HomeElements/utils";

class ArtistsTable extends Component {

  state = {
    total: '',
    showArtistsWithoutTracks: false,
  }

  handleAlertClosed = artistId => status => {
    if (status === 'passed') this.refs.table.removeById(artistId)
  }

  buttons = artist => {

    const {
      id,
      name,
    } = artist

    const editUrl = makeUrlWithTableParams(`/panel/artists/${id}/edit`)

    const buttonBuilder = new TableButtonsBuilder()

    buttonBuilder.withOther(<TableButton title={localization.get('button.see_home')} onClick={() => this.props.history.push(`/public/artist/${id}`)}><Visibility/></TableButton>)
    buttonBuilder.withEdit(editUrl)
    getStoredUserRoleId() !== ROLES.artistId && buttonBuilder.withDelete(name, () => request.artist.delete(id), this.handleAlertClosed(id))

    return buttonBuilder.build()
  }

  columns = () => {
    let columns = []

    const imageColumn =
    {
      id: 'image',
      accessor: artist => <img alt="Artist" src={makeImageUrl((artist.profile ? {image: artist.profile} : artist), 50)} width={50} height={50} onError={onImageError}/>,
      style: {
        float: 'left',
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    };

    const nameColumn =
    {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    };

    const typeColumns =
    {
      Header: localization.get('table.type'),
      id: "type",
      Filter: ({filter, onChange}) =>
        <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected/>
          <option value="internal">{localization.get('internal')}</option>
          <option value="external">{localization.get('external')}</option>
        </select>,
      accessor: artist => localization.get(artist.type)
    };

    const actionColumn =
    {
      id: 'actions',
      Header: localization.get('table.actions'),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total:  </strong>{this.state.total}</span>
    }

    columns.push(imageColumn);
    columns.push(nameColumn);
    !userisMultiClient() && columns.push(typeColumns);
    columns.push(actionColumn);

    return columns
  }

  makeSubComponent = row => <ArtistSubcomponent {...this.props} artist={row.original} />

  render = () => {
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox
              id="showArtistsWithoutTracks"
              style={{ color: "#4cc2d5" }}
              checked={this.state.showArtistsWithoutTracks}
              onChange={event => {
                this.setState({ showArtistsWithoutTracks: !this.state.showArtistsWithoutTracks })
                this.refs.table.forceUpdate();
              }}
            />
          }
          label={localization.get('form.show_artists_without_tracks')}
        />
        <div style={{width: "100%", float: "left", position: "relative"}}>
          <ServerSidePaginationTable
            ref='table'
            columns={this.columns()}
            subComponent={this.makeSubComponent}
            requestTableData={paginationConfig => {
              if (this.state.showArtistsWithoutTracks) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'has_musics', filter: "false" }]) };
              }
              return request.artist.getAll(paginationConfig).then((response) => {
                this.setState({total: response.meta.total})
                return response
              })
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default ArtistsTable