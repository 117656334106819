import { getActiveClient } from '../authentication/utils';
import {addPutMethodToForm, post} from '../utils'
import { isNullOrUndefined } from 'util';

export default host => ({
    id,
    email,
    phone,
    imageId,
    coverImageId,
    selectedTags,
    country,
    active,
    socialLinks,
    requireLogin,
    allowedRegions,
    hasRegionBlock,
    translations,
}) => 
post(`${host}/clients/${getActiveClient()}/contentCreators/${id}`, addPutMethodToForm(makeFormData({
        email,
        phone,
        imageId,
        coverImageId,
        selectedTags,
        country,
        active,
        socialLinks,
        requireLogin,
        allowedRegions,
        hasRegionBlock,
        translations,
})))
  .then(response => response.json())
  .then(json => json.data)


export const makeFormData = ({
    email,
    phone,
    imageId,
    coverImageId,
    selectedTags,
    country,
    active,
    socialLinks,
    requireLogin,
    allowedRegions,
    hasRegionBlock,
    translations,
  }) => {
    const form = new FormData();
    if (phone) form.append("phone", phone);
    if (email) form.append("email", email);
    if (country) form.append("country_id", country);
    if (!isNullOrUndefined(active)) form.append("active", active);
    if (translations) {
      translations.forEach(translation => {
        if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name);
        if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
        if (!isNullOrUndefined(translation.additions)) form.append(`translations[${translation.language}][additions]`, translation.additions.substring(0, 400));
        if (!isNullOrUndefined(translation.synopsis)) form.append(`translations[${translation.language}][synopsis]`, translation.synopsis.substring(0, 250));
      });
    }
    if (socialLinks) {
      socialLinks.forEach(socialLink => {
        if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
      });
  
      if(socialLinks.length === 0) {
        form.append('social_links', "clear_all")
      }
    }
  
    if (!isNullOrUndefined(selectedTags)) {
      if(selectedTags.length > 0) {
        selectedTags.forEach(x => form.append("tags_ids[]", x));
      }else {
        form.append("clear_all", 1);
      }
    }
    if (imageId) form.append("imageId", imageId);
    if (coverImageId) form.append("coverImageId", coverImageId);
    if(!isNullOrUndefined(requireLogin)) form.append("require_login", requireLogin);
    if (!isNullOrUndefined(hasRegionBlock)) form.append("region_restricted", hasRegionBlock == "true" ? 1 : 0);
    if(allowedRegions.length > 0) allowedRegions.forEach(x => form.append("available_countries[]", x))

    return form;
  };