import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {
  channelPathGenerator, getActiveChannel,
  getActiveClient,
  getStoredUser,
  homePathGenerator,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import HomeAddButton from "../../../components/Letflow/Button/HomeAddButton";
import {ReceiveEmailsFromPitches, ShowAllPitches} from "../../../views/Pitches/All";
import {Dialog, DialogTitle, FormControl, Grid, IconButton, MenuItem, Select, TextField,} from "@material-ui/core";
import ProjectItem from "./ProjectItem";
import browserHistory from "../../../utils/browserHistory";
import {ArrowForward, Cancel, CheckCircle, Edit, Reorder, Save, Settings} from "@material-ui/icons";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import {FormViewContainer} from "../../../components/Letflow/ViewContainer";
import {SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";
import ClientSidePaginationTable from "../../../components/Letflow/Table/ClientSidePaginationTable";
import moment from "moment";
import TableButtonsBuilder from "../../../components/Letflow/Table/Buttons/TableButtonsBuilder";
import {TableButton} from "../../../components/Letflow/Table";
import ParticipantsButton from "../../../views/Pitches/Thread/ParticipantsButton";
import styled from "styled-components";
import red from "@material-ui/core/es/colors/red";
import Tooltip from "@material-ui/core/Tooltip";
import {ScaleLoader} from "react-spinners";

class Projects extends React.Component {
  state = {
    projects: [],
    projectsFiltered: [],
    openSettingsDialog: false,
    showNonParticipatingConversations: false,
    openUpdateDialog: false,
    openOrderDialog: false,
    editingProject: '',
    status: 'open',
    name: '',
    description: '',
    requestingProjects: false,
    filterProjectStatus: "all",
    projectsNewOrder: [],
    orderingProjects: false,
    width: window.innerWidth,
    viewAsList: false,
    unreadProjects: [],
    projectsLoaded: false
  };


  componentDidMount = () => {
    setPageTitle(localization.get('title.projects'));
    document.getElementsByTagName('html')[0].scrollTop = 0;
    window.addEventListener('resize', this.setWindowsWidth);
    if (eval(localStorage.getItem("viewAsList"))) {
      this.setState({viewAsList: eval(localStorage.getItem("viewAsList")) })
    }
    if (eval(localStorage.getItem("filterProjectStatus"))) {
      this.setState({filterProjectStatus: eval(localStorage.getItem("filterProjectStatus")) }, () => this.requestPitches())
    } else {
      this.requestPitches()
    }
    this.requestPitchesUnreadMessages()
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setWindowsWidth)
  };

  setWindowsWidth = () => this.setState({width: window.innerWidth});

  requestPitches = (includeAll = false) => {
    let paginationConfig = {
      filterBy: [{column: 'status', filter: this.state.filterProjectStatus}]
    };

    const sort = localStorage.getItem("sortProjectBy");

    if(sort) {
      paginationConfig = {...paginationConfig, orderBy: {column: sort.split(":")[0], type: sort.split(":")[1]}}
    }

    request.pitch.getAllForClient(getActiveClient(), includeAll, paginationConfig)
      .then((projects) => this.setState({projects, projectsFiltered: projects, projectsLoaded: true}));
  };

  requestPitchesUnreadMessages = () => {
    request.pitch.getUnreadMessages(getStoredUser().id)
      .then(res => this.setState({unreadProjects: res}))
  };

  onDelete = (id) => this.setState({projectsFiltered: this.state.projectsFiltered.filter(project => project.id !== id)});

  onEdit = (project) => this.setState({openUpdateDialog: true, editingProject: project.id, status: project.status, name: project.project_name, description: project.project_details});

  onChange = e => this.setState({[e.target.id]: e.target.value});

  onChangeSelector = e => {
    localStorage.setItem("filterProjectStatus", `"${e.target.value}"`);
    const sort = localStorage.getItem("sortProjectBy");

    this.setState({[e.target.id]: e.target.value});

    let paginationConfig = {
      filterBy: [{column: 'status', filter: e.target.value}]
    };

    if(sort) {
      paginationConfig = {...paginationConfig, orderBy: {column: sort.split(":")[0], type: sort.split(":")[1]}}
    }


    request.pitch.getAllForClient(getActiveClient(), false, paginationConfig)
      .then((projects) => this.setState({projects, projectsFiltered: projects}))
  };


  submitRequest = (id) =>
    request.pitch.update({id, name: this.state.name, description: this.state.description, status: this.state.status})
      .then(this.requestPitches())
      .then(this.setState({openUpdateDialog: false}));

  onProjectNameChange = () => {
    if (this.state.projectName) {
      let searchWords = this.state.projectName.toLowerCase().split(" ");

      let filtered = this.projectSearch(searchWords, this.state.projects, "project_name");
      let descriptions = this.projectSearch(searchWords, this.state.projects, "project_details");

      for(let i=0; i < descriptions.length; i++){
        let ids = [];
        for (let j=0; j < filtered.length; j++){
          ids.push(filtered[j].id)
        }
        if (!ids.includes(descriptions[i].id)){
          filtered.push(descriptions[i])
        }
      }

      this.setState({
        projectsFiltered: filtered
      })
    } else {
      this.setState({projectsFiltered: this.state.projects})
    }
  };

  projectSearch = (input, itemsSearched, property) => {
    return (
        itemsSearched.filter(item => {
        let included = [];
        input.forEach(word => {
          if (word) included.push(item[property].toLowerCase().indexOf(word) > -1)
        });
        return included.every((c) => !!c )
      })
    )
  };

  hasUnreadMessages = projectId => {
    return this.state.unreadProjects.includes(projectId)
  };

  makeSettingsDialog = () =>
      <Dialog open={this.state.openSettingsDialog} onBackdropClick={() => this.setState({openSettingsDialog: false})}>
        <DialogTitle>{localization.get('settings')}</DialogTitle>
        <div style={{margin: "10px 30px 40px 30px"}}>
          <ShowAllPitches
            isChecked={this.state.showNonParticipatingConversations}
            onChange={v => this.setState({ showNonParticipatingConversations: v }, () => this.requestPitches(v))}
          />
          <ReceiveEmailsFromPitches/>
        </div>
      </Dialog>

  makeUpdateDialog = (mobile) =>
      <Dialog style={{margin: "-35px"}} open={this.state.openUpdateDialog} onBackdropClick={() => this.setState({openUpdateDialog: false})}>
        <FormViewContainer
          title={localization.get('project.edit')}
          submitRequest={() => this.submitRequest(this.state.editingProject)}
          onBack={() => this.setState({openUpdateDialog: false})}
          color="black"
          style={{maxWidth: "100%", width: "600px"}}
          smallExtraSpace={true}
        >
          <TextValidator
            style={!mobile ? {width: "80%", margin: "15px 50px 0"} : {width: "100%"}}
            id="name"
            label={localization.get('form.name')}
            name="name"
            value={this.state.name}
            onChange={this.onChange}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />
          <br/>
          <br/>
          <TextValidator
            style={!mobile ? {width: "80%", margin: "15px 50px 0"} : {width: "100%"}}
            id="description"
            label={localization.get('form.description')}
            name="description"
            value={this.state.description}
            onChange={this.onChange}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />
          <br/>
          <br/>
          <FormControl fullWidth>
            <SelectValidator
              style={!mobile ? {width: "80%", margin: "15px 50px 0"} : {width: "100%"}}
              label={localization.get('form.status')}
              name={"status"}
              value={this.state.status}
              onChange={e => {
                e.target.id = "status";
                this.onChange(e)
              }}
            >
              <MenuItem value={'open'} key={1}>{localization.get('project.open')}</MenuItem>
              <MenuItem value={'closed'} key={2}>{localization.get('project.closed')}</MenuItem>
              <MenuItem value={'archived'} key={3}>{localization.get('project.archived')}</MenuItem>
            </SelectValidator>
          </FormControl>
        </FormViewContainer>
      </Dialog>

  makeHeaderBar = (mobile) =>
      <div style={!mobile ? {display: "inline-block", marginTop: 10} : {display: "inline-block", marginTop: 10, maxWidth: "85%", marginLeft: "20%"}}>
        <Select
          style={{position: "relative", float: "left", marginTop: 16, marginRight: 19}}
          label={localization.get('form.status')}
          name={"filterProjectStatus"}
          value={this.state.filterProjectStatus}
          onChange={e => {
            e.target.id = "filterProjectStatus";
            this.onChangeSelector(e)
          }}
        >
          <MenuItem value={'all'} key={1}>{localization.get('all')}</MenuItem>
          <MenuItem value={'open'} key={1}>{localization.get('project.open')}</MenuItem>
          <MenuItem value={'closed'} key={2}>{localization.get('project.closed')}</MenuItem>
          <MenuItem value={'archived'} key={3}>{localization.get('project.archived')}</MenuItem>
        </Select>
        <TextField
          style={{position: "relative", float: "left", maxWidth: "50%"}}
          label={localization.get("search_projects")}
          onChange={e => this.setState({ projectName: e.target.value }, () => this.onProjectNameChange())}
        />
        <div style={!mobile ? {position: "relative", float: "left"} : {position: "relative", float: "left", marginLeft: "15%"}}>
          <IconButton
            onClick={() => {
              localStorage.setItem("viewAsList", !this.state.viewAsList );
              this.setState({viewAsList: !this.state.viewAsList, orderingProjects: false})
            }}>
            <Tooltip placement={"bottom"} title={localization.get('projects.switch_view')}>
              <SwapHoriz />
            </Tooltip>
          </IconButton>
        </div>
        {(this.state.projects && this.state.projects.length > 1 && this.state.filterProjectStatus === 'all' && !this.state.orderingProjects && this.state.width > 700) &&
          <div style={{position: "relative", float: "left"}}>
            <IconButton onClick={() => this.setState({orderingProjects: true, projectsNewOrder: this.state.projectsFiltered, viewAsList: false})}>
              <Tooltip placement={"bottom"} title={localization.get('reorder')}>
                <Reorder/>
              </Tooltip>
            </IconButton>
          </div>
        }
        {(this.state.orderingProjects && !this.state.viewAsList) &&
        <div style={{position: "relative", float: "left"}}>
          <IconButton onClick={() => {
            let order = this.state.projectsNewOrder.map(project => project.id);
            let clientId = getActiveClient();
            request.pitch.updateOrder(clientId, order)
              .then(() => this.requestPitches())
              .then(() => this.setState({orderingProjects: false}))
          }}>
            <Tooltip placement={"bottom"} title={localization.get('store')}>
              <Save/>
            </Tooltip>
          </IconButton>
        </div>
        }
        {(this.state.orderingProjects && !this.state.viewAsList) &&
        <div style={{position: "relative", float: "left"}}>
          <IconButton onClick={() => this.setState({orderingProjects: false})}>
            <Tooltip placement={"bottom"} title={localization.get('cancel')}>
              <Cancel/>
            </Tooltip>
          </IconButton>
        </div>
        }
        <div style={{position: "relative", float: "left"}}>
          <IconButton>
            <Tooltip placement={"bottom"} title={localization.get('pitches.view_online_videos')}>
              <CheckCircle onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/online?client_id=${getActiveClient()}`) : homePathGenerator(`projects/online?client_id=${getActiveClient()}`))} />
            </Tooltip>
          </IconButton>
        </div>
        <div style={{position: "relative", float: "left"}}>
          <IconButton onClick={() => this.setState({openSettingsDialog: true})}>
            <Tooltip placement={"bottom"} title={localization.get('settings')}>
              <Settings />
            </Tooltip>
          </IconButton>
        </div>
      </div>

  /*getTrProps = () => (_, row, column) => ({
    onClick: () => {
      if (row) {
        browserHistory.push(`/home/${getActiveClient()}/projects/${row.original.id}/thread`)
      }
    },
    style: {
      cursor: row ? "pointer" : undefined
    }
  });*/

  // target id will only be set if dragging from one dropzone to another.
  onChangeOrder = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(this.state.projectsNewOrder, sourceIndex, targetIndex);
    this.setState({projectsNewOrder: nextState})
  };

  render() {
    const {projectsFiltered} = this.state;
    let mobile = this.state.width <= 550;

    let onOrderBoxes;
    if (this.state.width > 1279) {
      onOrderBoxes = 4
    } else if (this.state.width > 950) {
      onOrderBoxes = 3
    } else if (this.state.width > 600) {
      onOrderBoxes = 2
    } else {
      onOrderBoxes = 2
    }

    return (
      <div style={{textAlign: "center", background: "white", minHeight: "100vh"}}>

        {this.makeUpdateDialog(mobile)}
        {this.makeSettingsDialog()}
        {this.makeHeaderBar(mobile)}

        <HomeAddButton onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/create`) : homePathGenerator(`projects/create`))}/>

        {!this.state.projectsLoaded ?
          <div style={{marginTop: "150px"}}>
            <ScaleLoader color="darkgray" />
          </div>
        :

        !this.state.viewAsList ?

        this.state.orderingProjects ?

        <GridContextProvider
          onChange={this.onChangeOrder}
          style={{overflow: "hidden", paddingRight: 10, marginBottom: 15, touchAction: 'none'}}
        >
          <GridDropZone
            id="projects"
            boxesPerRow={onOrderBoxes}
            rowHeight={this.state.width > 680 ? 300 : 200}
            style={{
              height: 50000,
            }}
          >
            {this.state.projectsNewOrder.map((project, key) => (
                <GridItem key={key} style={{height: 300, margin: 15, boxSizing: 'border-box', /*border: "3px solid green"*/}}>
                  <OrderableDiv>
                    <ProjectItem
                      style={{boxSizing: 'border-box'}}
                      project={project}
                      onDelete={this.onDelete}
                      onEdit={this.onEdit}
                    />
                  </OrderableDiv>
                </GridItem>
              ))
            }
          </GridDropZone>
        </GridContextProvider>
          :
          <section data-max-per-row={4} className="category">
            <Grid container>
              { projectsFiltered.length > 0 ?
                projectsFiltered.map((project, key) => {
                  return (
                    <Grid key={key} xs={6} sm={6} md={4} lg={3} xl={3} item>
                      <ProjectItem
                        unreadMessages={this.hasUnreadMessages(project.conversation_id)}
                        project={project}
                        onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/${project.id}/thread`) : homePathGenerator(`projects/${project.id}/thread`))}
                        onDelete={this.onDelete}
                        onEdit={this.onEdit}
                      />
                    </Grid>
                  )
                }) : <div
                  style={{
                    float: "left",
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    margin: "50px 0px",
                    fontSize: "20px",
                    padding: "40px"
                  }}>{localization.get('projects.no_result')}</div>}
            </Grid>
          </section>
        :
        <RespDiv>
          <ClientSidePaginationTable
            style={{width: "100%"}}
            perPage={999}
            showPagination={false}
            data={projectsFiltered}
            handleSort={e => localStorage.setItem("sortProjectBy",  `${e[0].id}:${e[0].desc ? "desc" : "asc"}`)}
            columns={[
              {
                Header: localization.get("project"),
                accessor: "project_name",
                sortable: true,
                filterable: false,
                maxWidth: this.state.width < 630 ? 140 : 400,
                Cell: row => <span onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/${row.original.id}/thread`) : homePathGenerator(`projects/${row.original.id}/thread`))}  style={{ cursor: "pointer", color: this.hasUnreadMessages(row.original.conversation_id) && red[500] }}>{row.value}</span>
              },
              {
                Header: <div style={{display: "inline-table", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{localization.get("created_at")}</div>,
                accessor: "created_at",
                Cell: row => <span onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/${row.original.id}/thread`) : homePathGenerator(`projects/${row.original.id}/thread`))}  style={{ cursor: "pointer", color: this.hasUnreadMessages(row.original.conversation_id) && red[500] }}>{moment(row.value).format("DD-MM-YYYY")}</span>,
                filterable: false,
                sortable: true,
                onSortedChange:  () => localStorage.setItem("sortProjectBy",  "created_at")
              },
              {
                Header: localization.get("table.status"),
                accessor: "status",
                Cell: row => <span onClick={() => browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/${row.original.id}/thread`) : homePathGenerator(`projects/${row.original.id}/thread`))} style={{ cursor: "pointer", color: this.hasUnreadMessages(row.original.conversation_id) && red[500] }}>{localization.get(`project.${row.value}`)}</span>,
                filterable: false,
                sortable: false,
                show: this.state.width > 475,
                maxWidth: this.state.width < 740 ? 100 : 300,
              },
              {
                id: "actions",
                Header: localization.get("table.actions"),
                sortable: false,
                filterable: false,
                width: 145,
                Cell: (project) => {
                  const builder = new TableButtonsBuilder();

                  builder
                    .withOther(
                      <ParticipantsButton
                        small={true}
                        tooltip="top"
                        pitchId={project.original.id}
                      />
                    );

                  builder
                    .withOther(
                      <TableButton
                        title={localization.get('edit')}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.onEdit(project.original)
                        }}
                      >
                        <Edit/>
                      </TableButton>
                    );

                  builder
                    .withDelete(
                      project.original.project_name,
                      () => request.pitch.delete(project.original.id),
                      closeState => {
                        if (closeState === 'passed') {
                          this.onDelete(project.original.id)
                        }
                      }
                    );

                  builder
                    .withOther(
                      <TableButton
                        title={localization.get('projects.go_to')}
                        onClick={(e) => {
                          e.stopPropagation();
                          browserHistory.push(getActiveChannel() ? channelPathGenerator(`projects/${project.original.id}/thread`) : homePathGenerator(`projects/${project.original.id}/thread`))
                        }}
                      >
                        <ArrowForward/>
                      </TableButton>
                    );

                  return builder.build()
                },
              }
            ]}
          />
        </RespDiv>
        }
      </div>
    )
  }
}

export default Projects;

const RespDiv = styled.div`
  background-color: #ffffff;
  text-align: left;
  margin: 10px 100px;
 @media (max-width: 830px) {
    margin: 10px 50px;
}
 @media (max-width: 670px) {
    margin: 10px 25px;
}
 @media (max-width: 410px) {
    margin: 10px 10px;
}
`;

const OrderableDiv = styled.div`
  box-sizing: border-box;
  height: 300px; 
  width: 300px;
  margin-left: 0;
 @media (max-width: 1279px) {
    margin-left: 5%;
}
 @media (max-width: 950px) {
    margin-left: 10%;
 @media (max-width: 680px) {
    margin-left: 10%;
    height: 200px; 
    width: 200px;
}
`;
