import {del} from '../utils'
import {getActiveChannel} from '../authentication/utils';

export default host => id => {
  let url = `${host}/channels/${getActiveChannel()}/playlist/${id}/favorite`
  let channelId = getActiveChannel()
  if (channelId) {
    url += `?channel_id=${channelId}`
  }
  return del(url)
}
