import React, {Component} from "react";
import {request} from "../../../api-client";
import {ImagePicker, TextField} from '../../../components/Letflow/Form'
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import AddressInput from "../../../components/Letflow/AddressInput";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import noImage from "../../../assets/img/Letflow/no-image.jpg";
import {
  channelPathGenerator,
  getStoredChannel,
  getStoredUser,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import Button from "../../../components/CustomButtons/Button.jsx";
import {get} from "lodash"
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";

export default class extends Component {

  state = {
    name: '',
    email: '',
    image: { src: null, file: null },
    disabled: false,
    oldPassword: '',
    password: '',
    language: '',
    showPassword: false,
    identityNumber: null,
    address: null,
    birthdate: null,
    requesting: true
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.profile'))
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.requestUser();
  }

  requestUser = () => {
    const user = getStoredUser()
    let currentUrl = `${window.location.search}`;
    console.log(currentUrl)


      if (getStoredChannel().login_type === "MiBA" && window.location.search === "?sho=true") {
        
        window.location.href = request.authentication.profileMiba(window.location.protocol + '//' + window.location.hostname)
      } else {
        this.setState(state => ({
          id: user.id,
          name: user.name, 
          email: user.email, 
          language: user.language, 
          identityNumber: user.identity_number,
          address: user.address,
          birthdate: user.birth_date,
          requesting: false,
          image: {
            ...state.image,
            src: getUpdateFormImageUrlFromItem(user)
          }
        }))
      }
  }

  requestUpdate = () => {
    const { id, name, image: { file }, oldPassword, password, language, birthdate, address, identityNumber } = this.state
    const data = { name, userId: id, imageFile: file, oldPassword, password, language, birthdate, address, identityNumber }
    return request.user.update(data)
      .then(() => window.location.href = channelPathGenerator(''));
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value })

  handleImageChange = image => this.setState({ image })

  imageProvider = () => this.state.image.src || noImage

  handleRequestingStateChanged = disabled => this.setState({ disabled })
  render = () => {
    const {
      name,
      disabled,
      password,
      oldPassword,
      requesting
    } = this.state
    const extendRegistration = JSON.parse(get(getStoredChannel(), "extend_registration", null));

    return (
      <div >
        {!requesting &&
          <FormViewContainer
            onBack={() => this.props.onClose? this.props.onClose() : this.props.history.goBack()}
            title={localization.get('form.update_profile')}
            submitRequest={this.requestUpdate}
            onRequestingStateChange={this.handleRequestingStateChanged}
            style={{maxWidth: "600px", width: "100%", display: "table", minWidth: 330, backgroundColor: "var(--main-color, #FFF)"}}
            color="black"
          >
            <div >
              <TextField
                label={localization.get('form.name')}
                id="name"
                value={name}
                onChange={this.handleChange}
                disabled={disabled}
              />
              {get(extendRegistration, 'identity_number', 'none') != "none" &&
                <>
                  <TextField
                    label={localization.get('DNI')}
                    id="identityNumber"
                    value={this.state.identityNumber}
                    onChange={this.handleChange}
                    disabled={disabled}
                  />
                </>
              }
              {get(extendRegistration, 'birthdate', 'none') != "none" &&
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      fullWidth
                      autoOk
                      keyboard
                      clearable
                      onlyCalendar
                      disableFuture
                      defaultValue={null}
                      format={"dd/MM/yyyy"}
                      label={localization.get("birthdate")}
                      value={this.state.birthdate}
                      onChange={(e) => {
                        this.setState({birthdate: e})}
                      }
                    />
                  </MuiPickersUtilsProvider>
                </>
              }

              {get(extendRegistration, "address", "none") != "none" &&
                <AddressInput
                  ref={ref => this.addressRef = ref}
                  address={this.state.address}
                  onAddressChange={(address) => this.setState({address: address})}
                  buttonColor="black"
                  noAddressText={localization.get("")}
                />
              }
              <br/>
              <br/>
              <ImagePicker
                buttonText={localization.get('form.select_image')}
                onImageChange={this.handleImageChange}
                image={this.imageProvider()}
                disabled={disabled}
                style={{backgroundColor: "black"}}
                color={'default'}
              />
              {getStoredChannel().login_type === "default" &&
                <div style={{textAlign: "center"}}>
                  <Button style={{backgroundColor: "black", textAlign: "center", marginTop: "30px", marginBottom: "30px"}}
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}
                  >
                    {localization.get('account.profile.change_password')}
                  </Button>
                </div>
              }
              <TextField
                style={{display: this.state.showPassword ? 'block' : 'none'}}
                label={localization.get('form.old_password')}
                id="oldPassword"
                value={oldPassword}
                type="password"
                onChange={this.handleChange}
              />
              <TextField
                style={{display: this.state.showPassword ? 'block' : 'none'}}
                label={localization.get('form.password')}
                id="password"
                value={password}
                type="password"
                onChange={this.handleChange}
              />
            </div>

          </FormViewContainer>
        }
      </div >
    )
  }
}
