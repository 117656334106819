import playlist from './playlist'
import playlistAudio from './playlistAudio'
import album from './album'
import albumAudio from './albumAudio'
import downloadZip from './downloadZip'
import contentCreator from "./contentCreator";

export default host => ({
  playlist: playlist(host),
  playlistAudio: playlistAudio(host),
  album: album(host),
  albumAudio: albumAudio(host),
  downloadZip: downloadZip(host),
  contentCreator: contentCreator(host)
})
