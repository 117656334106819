import {addPutMethodToForm, post,} from '../utils'
import {getActiveClient} from "../authentication/utils";
import {makeFormData} from "./utils";

export default host => ({
  fileId,
  image,
  second,
}) => post(`${host}/clients/${getActiveClient()}/files/${fileId}`, addPutMethodToForm(makeFormData({
  image,
  second,
})))
  .then(response => response.json())