import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {Style} from '@material-ui/icons'
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {
  state = {
    currentTable: 'products',
  }
  componentDidMount = () => {
    document.title = localization.get('products') + ' - Feater'
  }

  onAdd = (table) =>
    this.props.history.push(makeUrlWithTableParams(
      table === "products" ? `/panel/products/create`
        : table  === 'contentPacks' ? `/panel/products/contentPacks/create`
          : `/panel/products/codes/create`
    ))

  render = () => {
    let currentTable = 'products';
    let selectedViewTypeFromLocalStorage = JSON.parse(localStorage.getItem("monetizedViewType"));
    if (selectedViewTypeFromLocalStorage) {
      currentTable = selectedViewTypeFromLocalStorage;
    }

  return (
    <AllViewContainer
      onAdd={currentTable !== "purchases" ? () => this.onAdd(currentTable) : null}
      viewIcon={<Style />}
      ref='viewContainer'
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props} onCurrentTableChange={table => this.setState({ currentTable: table })}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  )}
}
