import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {userisMultiClient} from "../../../api-client/core/authentication/utils";

class TagGroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [
        {id: 'music', label: localization.get('music')},
      ],
      showContent: "false",
      loaded: false
    };
  }

  componentDidMount = () => {
    this.requestContentCreatorTypes()
      .then(() => this.setState({loaded: true}))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({loaded: false}, () => this.requestContentCreatorTypes().then(() => this.setState({loaded: true})))
    }
  }

  requestContentCreatorTypes = () => {
    if (!this.props.isMusic) {

      let filter = { filterBy: [
          {column: "is_talent", filter: this.props.isTalent ? 1 : 0},
          {column: "dashboard_combos", filter: true},
          {column: "for_create", filter: true},
        ]}

      if (this.props.isEditorial) filter = { ...filter, filterBy: filter.filterBy.concat([{ column: "type", filter: "editorial" }]) }

      return request.contentCreatorType.getAll(filter)
        .then(contentCreatorTypes => {
          let types = contentCreatorTypes.map(contentCreatorType => ({
            id: contentCreatorType.slug,
            label: contentCreatorType.name
          }))
          this.setState({types: types.sort((a, b) => a.label.localeCompare(b.label))})
        })
    } else {
      return new Promise(resolve => {
        resolve()
      })
    }
  }


  requestTagGroupDelete = id => request.tagGroup.delete(id)

  render = () => {
    const types = this.state.types

    const columns = [];
    const nameColumn = {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }
    const typeColumn = {
      id: "type",
      Header: localization.get('category'),
      accessor: tagGroup => tagGroup.types.map(gType => {
        if(types.find(type => type.id === gType.type)) {
          return(<p>{types.find(type => type.id === gType.type).label}</p>)
        }
      }),
      Filter: ({filter, onChange}) =>
        !this.props.isMusic && (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            {types.sort((a, b) => a.label.localeCompare(b.label)).map(type => {
              return <option value={type.id}>{localization.get(type.label)}</option>;
            })}
          </select>
        ),
      sortable: false
    }

    const orderColumn = {
      Header: localization.get('table.order'),
      accessor: "order",
      filterable: false,
      sorterable: false
    }

    const parentColumn = {
      Header: localization.get('tag_group.parent'),
      id: "parent",
      accessor: 'parent.name',
      sortable: false,
      filterable: false,
    }

    const actionColumn = {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: tagGroup => {
        let editUrl = ''
        if (this.props.isMusic) {
          editUrl = makeUrlWithTableParams(`/panel/music/tag_groups/${tagGroup.id}/edit`);
        } else if (this.props.isTalent) {
          editUrl = makeUrlWithTableParams(`/panel/talents/tag_groups/${tagGroup.id}/edit`);
        } else if (this.props.isEditorial) {
          editUrl = makeUrlWithTableParams(`/panel/editorial/tag_groups/${tagGroup.id}/edit`);
        }else {
          editUrl = makeUrlWithTableParams(`/panel/audiovisual/tag_groups/${tagGroup.id}/edit`);
        }

        const builder = new TableButtonsBuilder()
        return builder.withEdit(editUrl)
          .withDelete(tagGroup.name, () => request.tagGroup.delete(tagGroup.id), status => {
            if (status === 'passed') {
              this.refs.table.removeById(tagGroup.id)
            }
          })
          .build()
      }
    }

    columns.push(nameColumn)
    columns.push(typeColumn)
    columns.push(orderColumn)
    columns.push(parentColumn)
    columns.push(actionColumn)


    return (
      <div>
        {!this.props.isMusic && !this.props.isEditorial &&
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <RadioGroup
            name="showContent"
            value={this.state.showContent}
            onChange={event => this.setState({showContent: event.target.value}, () => this.refs.table.forceUpdate())}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="false" control={<Radio color="default"/>}
                              label={localization.get(this.props.isTalent ? 'tags.talents' : 'tags.audiovisual')}/>
            <FormControlLabel value="true" control={<Radio color="default"/>}
                              label={localization.get('tags.material')}/>
          </RadioGroup>
        </FormControl>
        }

        <div style={{width: "100%", float: "left", position: "relative"}}>
          {this.state.loaded &&
          <ServerSidePaginationTable
            ref="table"
            columns={columns}
            requestTableData={paginationConfig => {
              if (this.props.isMusic) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'music'}])
                }
              }
              if (this.props.isTalent) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'talent'}])
                }
              }
              if (this.props.isAudiovisual) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'audiovisual'}])
                }
              }

              if (this.props.isEditorial) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'audiovisual'}, {column: "is_editorial", filter: true }])
                }
              }

              if (userisMultiClient()) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "showForMulticlient", filter: true}])
                }
              }
              paginationConfig = {
                ...paginationConfig,
                filterBy: paginationConfig.filterBy.concat([
                  {
                    column: "creation",
                    filter: this.state.showContent === 'true' ? 1 : 0
                  },
                  {
                    column: "dashboard",
                    filter: 1
                  }
                ])
              }

              return request.tagGroup.getAllPrivate(paginationConfig)
            }}
          />
          }
        </div>
      </div>
    )
  }
}

export default TagGroupTable;
