import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import {CheckCircle} from "@material-ui/icons"

export default class StepProgressBar extends React.Component {

  render = () =>
    <div style={{width: "100%", maxWidth: 500, margin: "auto", padding: "30px 80px 0"}}>
      <ProgressBar
        percent={(this.props.currentStep - 1) / (this.props.totalSteps - 1.99) * 100}
        filledBackground="linear-gradient(to right, #56e44df0, #14b704)"
        hasStepZero={true}
      >
        <Step transition="scale">
          {({ accomplished}) => (
            <div>
            <CheckCircle
              style={{
                filter: `grayscale(${accomplished ? 0 : 100}%)`,
                color: "green",
                backgroundColor: "#f7f7f7",
                height: "1.3em",
                width: "1.3em",
              }}
            />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <CheckCircle
              style={{
                filter: `grayscale(${accomplished ? 0 : 100}%)`,
                color: "green",
                backgroundColor: "#f7f7f7",
                height: "1.3em",
                width: "1.3em"
              }}
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <CheckCircle
              style={{
                filter: `grayscale(${accomplished ? 0 : 100}%)`,
                color: "green",
                backgroundColor: "#f7f7f7",
                height: "1.3em",
                width: "1.3em"
              }}
            />
          )}
        </Step>
      </ProgressBar>
    </div>
}