import React, {Component} from 'react'
import './Volume.css'
import Slider from '@material-ui/lab/Slider';
import {VolumeMute, VolumeUp} from '@material-ui/icons'
import SimpleButton from '../../utils/SimpleButton';

class Volume extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showController: false,
      hoveringControler: false,
    }
  }

  render = () => {

    const { soundPlayer } = this.props

    return (
      <div>
        {!this.state.showController && <div style={{ position: 'absolute', top: '-15px', right: '50px' }}>
          <SimpleButton style={{ backgroundColor: 'var(--secondary-color, white)', boxShadow: '0 0 5px 0 lightgray' }} onMouseEnter={() => this.setState({ showController: true })}><VolumeUp style={{fontSize:'18px'}} /></SimpleButton>
        </div>}
        {this.state.showController && <div className='soundbar-volume-controller' style={{backgroundColor: 'var(--secondary-color, white)', color: 'var(--secondary-font-color, inherit)'}} onMouseLeave={() => this.setState({ showController: false })}>
          <div style={{ position: 'relative', top: '6px' }}><VolumeMute style={{ fontSize: '18px' }} /></div>
          <div style={{ width: '150px' }}>
            <Slider
              id={'soundbar-volume-controller-slider'}
              value={soundPlayer.getVolume() * 100}
              aria-labelledby="label"
              onChange={(_, v) => soundPlayer.setVolume(v / 100)}
            />
          </div>
          <div style={{ position: 'relative', top: '6px' }}><VolumeUp style={{ fontSize: '18px' }} /></div>
        </div>}
      </div>
    )
  }
}

export default Volume