import React from 'react';
import { Dialog, DialogContent, Button, withStyles, Radio, Checkbox } from "@material-ui/core";
import { request } from '../../../../api-client';
import localization from '../../../../config/localization';
import AsyncSelect from "react-select/lib/Async";
import { channelPathGenerator, getStoredChannel, getStoredUser, getActiveChannel } from '../../../../api-client/core/authentication/utils';
import { makeFitImageUrl } from '../../../../utils';
import Logo from "../../../../assets/img/logo-black.png";
import { Link } from 'react-router-dom';
import TypeformPopup from "../../../../components/Letflow/TypeformPopup";
import {get} from 'lodash';

const styles = {
  dialogPaper: {
      minHeight: '80vh',
      maxHeight: '80vh',
      backgroundColor: '#EFEFEF',
      borderRadius: '10px',
      padding: '20px',
      height: '80vh'
  },
  dialogPaperMobile: {
    minHeight: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#EFEFEF',
    borderRadius: '10px',
    padding: '20px',
    height: '80vh',
    fontSize: '0.8em'
  }
};

const ModalButton = withStyles({
  root: {
    backgroundColor: 'var(--navbar-text-color, black)',
    color: 'var(--secondary-color, white)',
    minHeight: '30px',
    textTransform: 'none',
    padding: '5px 30px',
    '&:hover': {
      backgroundColor: 'var(--navbar-text-color, black)'
    },
    '&:disabled': {
      backgroundColor: 'grey',
      color: 'lightgrey'
    }
  },
})(Button);

class TalentProfileModal extends React.Component {
  constructor(props) {
    super(props);
    let currentChannel = getStoredChannel();
    let categoryIds = (currentChannel.community && currentChannel.community.contentCreatorTypes) ? currentChannel.community.contentCreatorTypes.map(ccType => ccType.id) : [];
    
    let channelUser = getStoredUser().channels.length > 0 ? getStoredUser().channels.find(cr => cr.channel && cr.channel.id == getActiveChannel()) : {accept_terms_of_use: true, completed_form: true};
    
    this.state = {
      open: true,
      // categories: categories,
      categoryIds: categoryIds,
      isEditing: this.props.isEditing,
      currentStep: this.props.isEditing ? 2 : 1,
      selectedContentCreatorType: '',
      acceptedTermsOfUse: currentChannel.community.terms_of_use ? !!get(channelUser, "accept_terms_of_use", false): true,
      completedForm: currentChannel.extra_registration ? get(channelUser, "completed_form", false) : true,
      openForm: false,
    };
  }
  

  renderStep = () => {
    switch (this.state.currentStep) {
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      default:
        return this.renderFirstStep();
    }
  }

  renderFirstStep = () => {
    const steps = [localization.get("talent_profile_modal.step_1.item_1"), localization.get("talent_profile_modal.step_1.item_2"), localization.get("talent_profile_modal.step_1.item_3")];
    let channel = getStoredChannel();
    let community = channel.community;

    return (
      <DialogContent style={{padding: '0', height: '100%'}}>
        <div className="modal-step-container">
          <div className="modal-step-content">
            <div className="modal-title">{getStoredChannel().community.title || localization.get('talent_profile_modal.step_1.title', getStoredChannel().name)}</div>

            <div className="modal-subtitle">{getStoredChannel().community.description || localization.get('talent_profile_modal.step_1.subtitle')}</div>
            <div style={{paddingBottom: '10px'}}>
              {steps.map((item, index) => (
                <div className="modal-subtitle-item" key={`modal-step-${index}`}>{`${index+1}. ${item}`}</div>
              ))}
            </div>

            <div className="modal-text" style={{fontSize: '1.1em'}}>{getStoredChannel().community.success_message || localization.get("talent_profile_modal.step_1.text")}</div>

            <div className="modal-text">{getStoredChannel().community.slogan || getStoredChannel().name}</div>
            
            {getStoredChannel().extra_registration && 
              <div className="modal-text" style={{display: "flex", alignItems: "center"}} onClick={()=> this.setState({openForm: false}, () =>this.setState({openForm: true}))}>
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={this.state.completedForm}
                />
                <p>{localization.get("talent_profile_modal.complete_form")}</p>
                <TypeformPopup
                  open={this.state.openForm && !this.state.completedForm}
                  name={getStoredUser().name}
                  email={getStoredUser().email}
                  userid={getStoredUser().id}
                  typeformUrl={getStoredChannel().extra_registration}
                  onSubmit={() => request.user.completeForm().then(() => this.setState({completedForm: true}))}
                  completeForm={this.state.completedForm}
                />
              </div>
            }

            {community.terms_of_use &&
              <div className="modal-text" style={{display: "flex", alignItems: "center"}} >
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={this.state.acceptedTermsOfUse}
                  onChange={() => this.setState({acceptedTermsOfUse: !this.state.acceptedTermsOfUse})}
                />
                <div dangerouslySetInnerHTML={{__html: community.terms_of_use.replace("<a", '<a target="_blank"')}} />
              </div>
            }
            <br/>
            <div>
              <img alt={`Logo - ${channel.name}`}
                src={(channel && channel.image) ?
                  makeFitImageUrl(channel, 250, 50, '', 'png')
                  :
                  (channel ?
                    makeFitImageUrl(channel.client, 250,50, '', 'png')
                  :Logo)}
              />
            </div>
          </div>
          <div className="modal-step-buttons" style={{justifyContent: 'space-between'}}>
            <Link to={channelPathGenerator("")}><Button style={{color: "var(--navbar-text-color, black)"}}>{localization.get("talent_profile_modal.step_1.cancel_button")}</Button></Link>
            <ModalButton
              disabled={!(this.state.completedForm && this.state.acceptedTermsOfUse)} 
              onClick={() => {
                request.user.acceptTermsOfUseForChannel();
                return this.state.categoryIds.length == 1 ?
                this.setState({selectedContentCreatorType: {id: this.state.categoryIds[0]}}, this.handleModalEnd) : 
                this.setState({currentStep: 2})
              }}
              >
                {localization.get("talent_profile_modal.step_1.button")}
            </ModalButton>
          </div>
        </div>
      </DialogContent>
    );
  }

  renderStep2 = () => {
    return (
      <DialogContent style={{padding: '0', height: '100%'}}>
        <div className="modal-step-container">
          <div className="modal-step-content">
            <div className="modal-title">{this.props.isEditing ? localization.get("talent_profile_modal.step_2.editing_title") : localization.get("talent_profile_modal.step_2.title")}</div>
            <div style={{alignSelf: 'center', width: '80%'}}>
              <div style={{display: 'flex', flexDirection: 'column', color: 'black'}}>
                {this.renderAsyncSelect()}
              </div>
            </div>
          </div>
          <div className="modal-step-buttons" style={this.props.isEditing ? {justifyContent: 'space-between'} : {justifyContent: 'flex-end'}}>
            {this.props.isEditing && <Button onClick={() => this.handleModalClose()} style={{color: "var(--navbar-text-color, black)"}}>{localization.get("talent_profile_modal.step_2.cancel_button")}</Button>}
            <ModalButton disabled={!this.state.selectedContentCreatorType} onClick={() => this.setState({currentStep: 3})}>{localization.get("talent_profile_modal.step_2.button")}</ModalButton>
          </div>
        </div>
      </DialogContent>
    );
  }

  renderAsyncSelect = () => (
    <AsyncSelect
      styles={{
        menu: base => ({
          ...base,
          zIndex: 10,
          height: '150px'
        }),
        menuList: base => ({
          ...base,
          height: '150px',
        }),
        menuPortal: base => ({
          ...base,
          height: '150px',
        })
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 5,
      })}
      placeholder={localization.get("talent_profile_modal.step_2.search_placeholder")}
      loadingMessage={() => localization.get('loading')}
      noOptionsMessage={() => localization.get('no_options')}
      onChange={(e) => {
        this.setState({selectedContentCreatorType: e});
      }}
      value={this.state.selectedContentCreatorType}
      defaultOptions
      isClearable={true}
      loadOptions={inputValue => {
        let filters = [{ column: "name", filter: inputValue }];
        return request.contentCreatorType
          .getAll({filterBy: filters})
          .then(cct => cct.filter(c => this.state.categoryIds.indexOf(c.id) != -1))
          .then(cct => cct.map(cct => ({...cct, value: cct.id, label: cct.name, order: this.state.categoryIds.indexOf(cct.id)})))
          .then(categories => categories.sort((a, b) => a.order > b.order ? 1 : -1))
        }
      }
    />
  );

  renderStep3 = () => {
    return (
      <DialogContent style={{padding: '0', height: '100%'}}>
        <div className="modal-step-container">
          <div className="modal-step-content">
            <div className="modal-title">{localization.get("talent_profile_modal.step_3.title", this.state.selectedContentCreatorType.label)}</div>
            {this.props.isEditing && <div className="modal-text editing-text"><b style={{color: 'red'}}>{localization.get("talent_profile_modal.step_3.editing_bold_text")}</b> {localization.get("talent_profile_modal.step_3.editing_text")}</div>}
            <div className="modal-text" dangerouslySetInnerHTML={{ __html: localization.get("talent_profile_modal.step_3.text_1") }}/>
            <div className="modal-text" dangerouslySetInnerHTML={{ __html: localization.get("talent_profile_modal.step_3.text_2") }}/>
          </div>
          <div className="modal-step-buttons" style={{justifyContent: 'space-between'}}>
            <Button onClick={() => this.setState({currentStep: 2})} style={{color: "var(--navbar-text-color, black)"}}>{localization.get("talent_profile_modal.step_3.back_button")}</Button>
            <ModalButton onClick={() => this.handleModalEnd()}>{localization.get("talent_profile_modal.step_3.continue_button")}</ModalButton>
          </div>
        </div>
      </DialogContent>
    );
  }

  handleModalEnd = () => {
    this.props.onModalEnd && this.props.onModalEnd(this.state.selectedContentCreatorType);
    this.setState({open: false});
  }

  handleModalClose = () => {
    this.props.onModalClose && this.props.onModalClose();
    this.setState({open: false});
  }

  render = () => {
    const { classes } = this.props;
    let isMobile = window.innerWidth < 450;
    return (
      <Dialog
        disableEnforceFocus
        style={{zIndex: 999}}
        open={this.state.open} // TODO: add condition to showModal
        fullWidth
        classes={{paper: isMobile ? classes.dialogPaperMobile : classes.dialogPaper}}
        maxWidth="md"
      >
        <div style={{
          // minHeight: "225px",
          // backgroundImage: `url(${modalBgImg})`,
          backgroundColor: 'var(--secondary-color, #A5E9D0)',
          backgroundSize: 'cover',
          padding: isMobile ? '20px 10px' : '80px 50px',
          paddingBottom: isMobile ? '10px' : '20px',
          borderRadius: '10px',
          color: "var(--navbar-text-color, black)",
          height: '100%',
          overflow: 'auto'
        }}>
          {this.renderStep()}
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TalentProfileModal);