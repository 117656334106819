import React from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {userisMultiClient} from "../../../api-client/core/authentication/utils";

class Table extends React.Component {

  state = {
    tagGroups: [],
    types: [
      {id: 'music', label: localization.get('music')},
    ],
    showContent: "false",
    loaded: false
  };

  componentDidMount = () => {
    this.requestGroups()
    this.requestContentCreatorTypes().then(() => this.setState({loaded: true}))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.requestGroups()
        .then(() => this.setState({loaded: false}, () => this.requestContentCreatorTypes().then(() => this.setState({loaded:true}))))
    }
  }


  requestGroups = () => {
    let content = ''
    if(this.props.isMusic) {
      content = 'music'
    } else if (this.props.isTalent) {
      content = 'talent'
    } else {
      content = 'audiovisual'
    }
    const filters = [
      {column: "content_type", filter: content},
      {column: "dashboard", filter: 1},
    ]
    if(this.props.isEditorial) {
      filters.push({column: "is_editorial", filter: "1"})
    }

    return request.tagGroup.getAllPrivate({filterBy: filters}).then((tagGroups) => this.setState({tagGroups}))
  }

  requestContentCreatorTypes = () => {
    if (!this.props.isMusic) {

      let filter = { filterBy: [
          {column: "is_talent", filter: this.props.isTalent ? 1 : 0},
          {column: "dashboard_combos", filter: true},
          {column: "for_create", filter: true},
        ]}
      filter = { ...filter, filterBy: filter.filterBy.concat([{ column: "type", filter: this.props.isEditorial ? "editorial" : "audiovisual" }]) }

      return request.contentCreatorType.getAll(filter)
        .then(contentCreatorTypes => {
          let types = contentCreatorTypes.map(contentCreatorType => ({id: contentCreatorType.slug, label: contentCreatorType.name}))
          this.setState({types: types.sort((a, b) => a.label.localeCompare(b.label))})
        })
    } else {
      return new Promise(resolve => {
        resolve()
      })
    }
  }


  setTagGroupTypes = tagGroup => {
    let tagGroupTypes = tagGroup.types.map(type => type.type)
    if (!this.state.selectedType || tagGroupTypes.includes(this.state.selectedType)) {
      return true
    }
  }

  handleAlertClosed = id => status => {
    if (status === "passed") this.refs.table.removeById(id);
  };

  render = () => {
    let table

    const {types} = this.state;

    return (
      <div>
        {!this.props.isMusic && !this.props.isEditorial &&
          <FormControl style={{width: "100%", float: "left", position: "relative"}}>
            <RadioGroup
              name="showContent"
              value={this.state.showContent}
              onChange={event => this.setState({showContent: event.target.value},
                () => this.requestGroups().then(() => this.refs.table.forceUpdate()))}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="false" control={<Radio color="default"/>} label={localization.get(this.props.isTalent ? 'tags.talents' : 'tags.audiovisual')}/>
              <FormControlLabel value="true" control={<Radio color="default"/>} label={localization.get('tags.material')}/>
            </RadioGroup>
          </FormControl>
        }
        <div style={{width: "100%", float: "left", position: "relative"}}>
          {this.state.loaded &&
          <ServerSidePaginationTable
            ref="table"
            columns={[
              {
                Header: localization.get('table.name'),
                accessor: "name",
                Filter: ({filter, onChange}) => (
                  <input
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    placeholder={localization.get('table.search')}
                  />
                ),
              },
              {
                Header: localization.get('category'),
                id: "type",
                accessor: tag => tag.group.types.map(gType => {
                  if(types.find(type => type.id === gType.type)) {
                    return(<p>{types.find(type => type.id === gType.type).label}</p>)
                  }
                }),
                Filter: ({filter, onChange}) =>
                  !this.props.isMusic && (
                    <select
                      style={{width: "100%"}}
                      onChange={event => {
                        this.setState({selectedType: event.target.value})
                        onChange(event.target.value)
                      }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="" selected/>
                      {types.map(type => {
                        return <option value={type.id}>{localization.get(type.label)}</option>;
                      })}
                    </select>
                  ),
                sortable: false,
              },

              {
                Header: localization.get('table.tag_group'),
                id: "tag_group_id",
                accessor: tag => tag.group.name,
                Filter: ({filter, onChange}) => (
                  <select
                    style={{width: "100%"}}
                    onChange={event => onChange(event.target.value)}
                    value={filter ? filter.value : ""}
                  >
                    <option value="" selected/>
                    {this.state.tagGroups.filter(this.setTagGroupTypes).sort((a, b) => a.name.localeCompare(b.name)).map(tagGroup => {
                      let tagTypes = []
                      tagGroup.types.forEach(tagGroupType => {
                        types.forEach(type => {
                          if (type.id === tagGroupType.type) {
                            tagTypes.push(type.label)
                          }
                        })
                      })

                      return <option value={tagGroup.id}>{tagGroup.name} -
                        ({tagTypes.map((type, index) => (index === tagTypes.length - 1) ? type : type + ', ')})</option>;
                    })}
                  </select>
                ),
                sortable: false,
              },
              {
                Header: localization.get('table.order'),
                accessor: "order",
                filterable: false,
                sorterable: true
              },
              {
                Header: localization.get('tag.parents'),
                id: "parent",
                accessor: tag => tag.parents.map(parent => parent.name).join(', '),
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get('table.actions'),
                id: 'actions',
                sortable: false,
                filterable: false,
                accessor: tag => {
                  let editUrl =  ''
                  if(this.props.isMusic) {
                    editUrl = makeUrlWithTableParams(`/panel/music/tags/${tag.id}/edit`)
                  } else if (this.props.isTalent) {
                    editUrl = makeUrlWithTableParams(`/panel/talents/tags/${tag.id}/edit`);
                  } else {
                    editUrl = makeUrlWithTableParams(`/panel/audiovisual/tags/${tag.id}/edit`);
                  }
                  const builder = new TableButtonsBuilder()
                  builder.withEdit(editUrl)
                  builder.withDelete(tag.name, () => request.tag.delete(tag.id),
                        this.handleAlertClosed(tag.id)
                    )
                  return builder.build()
                }
              }
            ]}
            requestTableData={paginationConfig => {
              if (this.props.isMusic) {
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'music'}])}
              }
              if (this.props.isTalent) {
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'talent'}])}
              }
              if (this.props.isAudiovisual) {
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'audiovisual'}])}
              }
              if (userisMultiClient()) {
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "showForMulticlient", filter: true}])}
              }

              if (this.props.isEditorial) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: "content_type", filter: 'audiovisual'}, {column: "is_editorial", filter: true }])
                }
              }

              paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "creation", filter: this.state.showContent === 'true' ? 1 : 0}])}

              return request.tag.getAll(paginationConfig)
            }}
          />
          }
        </div>
      </div>
    )
  }
}


export default Table
