import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {makeUrlWithTableParams} from "../../../utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    titleEs: '',
    subtitleEs: '',
    titleEn: '',
    subtitleEn: '',
    userLibrary: false,
    clients: [],
    client: '',
    selectedClientIds: [],
    disabled: false,
    selectedMusics: [],
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      subtitle: "",
    })),
  }

  requestClients = () => request.client.getAll().then(clients => this.setState({ clients }))

  requestCreation = () => {
    const { client, translations, userLibrary, selectedMusics } = this.state
    return request.playlist.create({
      translations: translations,
      userLibrary,
      clientId: client.value,
      musicIds: selectedMusics,
      visibility: 'public'
    })
      .then(() => {
        // analyticsInstance.createPlaylist(titleEs)
        this.props.history.push(makeUrlWithTableParams('/panel/playlists'))
      })
  }

  onSubmitValidation = () => {
      if(!this.state.client) {
        GlobalSnackbar.show({message: localization.get('must_complete_client'), type: GlobalSnackbarTypes.ERROR});
        return false
      }else {
        return true
      }
  }

  render = () => {
    const { disabled, clients, translations, userLibrary, selectedMusics, client } = this.state
    return (
      <div>
        <FormViewContainer
          title={localization.get('playlist.create_playlist')}
          initializationRequest={this.requestClients}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestCreation}
          validation={this.onSubmitValidation}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
        >
          <FormView
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            clients={clients}
            client={client}
            onClientChange={client => this.setState({client})}
            onSelectedItemsChanged={selectedClientIds => this.setState({ selectedClientIds })}
            onChange={e => this.setState({[e.target.id]: e.target.value})}
            onUserLibraryChange={e => this.setState({ userLibrary: e })}
            userLibrary={userLibrary}
            selectedMusics={selectedMusics}
            onSelectedMusicsChanged={selectedMusics => this.setState({ selectedMusics })}
            disabled={disabled}
          />
        </FormViewContainer>
      </div>
    )
  }
}
