import React, {Component} from 'react'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel, Radio, RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import {Close, Done} from '@material-ui/icons'
import localization from '../../../config/localization';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../../components/Letflow/Snackbar/GlobalSnackbar';
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {ImagePicker} from "../../../components/Letflow/Form";
import ColorPicker from '../../../components/Letflow/ColorPicker';
import TranslatableInputs from '../../../components/Letflow/TranslatableInputs';
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import { get } from 'lodash';


class HomeBannerElementForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      active: props.active,
      image: {
        src: props.image || null,
        file: null
      },
      title: props.title || '',
      subtitle: props.subtitle || '',
      link: props.link || '',
      newTab: props.newTab || false,
      videoId: null,
      video: { src: props.video || null, file: null },
      previewId: null,
      preview: { src: props.preview || null, file: null },
      submitting: false,
      bannerType: (props.video || !props.editing) ? "video": "image",
      imageId  : '',
      hasButton: !!props.hasButton,
      hasCustomButton: !!props.button,
      buttonPosition: (props.button && props.button.position) ? props.button.position : 'bottom-center',
      buttonBgColor: (props.button && props.button.background_color) ? props.button.background_color : 'rgba(255,255,255,1)',
      buttonFontColor: (props.button && props.button.font_color) ? props.button.font_color : 'rgba(0,0,0,1)',
      buttonTextEs: (props.button && props.button.textEs) ? props.button.textEs : '',
      buttonTextEn: (props.button && props.button.textEn) ? props.button.textEn : '',
      buttonShape: (props.button && props.button.shape) ? props.button.shape : 'rounded',
      buttonIcon: (props.button && props.button.icon) ? props.button.icon : 'show',
      buttonFontSize: (props.button && props.button.fontSize) ? props.button.fontSize : 14,
      buttonPadding: (props.button && props.button.padding) ? props.button.padding : 4,
      halfHeight: props.halfHeight || false,
      translations: this.props.translations || AvailableLanguages.map(language => {
        const translation = { 
          language, 
          title: "", 
          subtitle: "" 
        };
        return translation;
      }),
    }
  }

  componentWillRecieveProps = (nextProps) => {
    if(nextProps.image !== this.state.image.src) {
      this.setState({image: {src: nextProps.image, file: null}})
    }
  }

  onVideoChange = video => video ? this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)}) : this.setState({videoId: null, video: {src: null}})
  onPreviewChange = preview => preview ? this.setState({previewId: preview.id, preview: {src: null}, videoPreview: this.formNewUrl(preview.path)}) : this.setState({previewId: null, preview: {src: null}})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  handleChange = e => this.setState({ [e.target.id]: e.target.value })

  handleUploadProgress = (current, total) => this.setState({ uploadProgress: Math.floor(current / total * 100) })
  onChangeTranslation = (value, target, language) => {
    const translations = this.state.translations.map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }
      return tr
    });

    this.setState({ translations });
  }

  handleSubmit = () => {
    const { active, title, subtitle, link, newTab, image, imageId, video, videoId, previewId, hasButton, buttonTextEs, buttonTextEn, buttonShape, buttonBgColor, buttonFontColor, buttonIcon, buttonPosition, hasCustomButton, buttonFontSize, buttonPadding, halfHeight, translations } = this.state

    if (!imageId && !image.src && !video.src && !videoId) {
      GlobalSnackbar.show({
          message: localization.get('home_editor.must_upload_image_or_video'),
          type: GlobalSnackbarTypes.ERROR,
        })
      return null;
    }
    
    this.setState({ submitting: true })
    let button = hasCustomButton ? {textEs: buttonTextEs, textEn: buttonTextEn, shape: buttonShape, background_color: buttonBgColor, font_color: buttonFontColor, icon: buttonIcon, position: buttonPosition, fontSize: buttonFontSize, padding: buttonPadding} : null;

    this.props.submitRequest(active, title, subtitle, link, newTab, imageId, videoId, previewId, hasButton, button, halfHeight, translations, this.handleUploadProgress)
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.banner_updated'),
          type: GlobalSnackbarTypes.SUCCESS,
        })
        this.setState({ submitting: false })
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.banner_update_failed'),
          type: GlobalSnackbarTypes.ERROR,
        })
        this.setState({ submitting: false })
      })
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  render = () => {
    return (
      <Dialog open={true}>
        <DialogContent style={{ width: '500px', maxWidth: '100%' }}>
          <FormControl style={{ width: '100%' }}>
          <TranslatableInputs
              color="dark"
              isEditorialContent
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = this.state.translations.find(tr => tr.language === language);

                return {
                  language: language,
                  content: [
                    <TextField
                      style={{width: "100%", marginBotton: 15}}
                      id={`title${language}`}
                      label={localization.get('form.title')}
                      name={`title${language}`}
                      value={get(currentLangTranslation, "title", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                    />,
                    <TextField
                      style={{width: "100%", marginBotton: 15}}
                      id={`subtitle${language}`}
                      label={localization.get('form.subtitle')}
                      name={`subtitle${language}`}
                      value={get(currentLangTranslation, "subtitle", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "subtitle", language)}
                    />,
                  ]
                }
              })}
            />
            {/* <TextField id='title' label={localization.get('form.title')} value={this.state.title} onChange={this.handleChange} disabled={this.state.submitting} />
            <TextField style={{ marginTop: '10px' }} id='subtitle' label={localization.get('form.subtitle')} value={this.state.subtitle} onChange={this.handleChange} disabled={this.state.submitting} /> */}
            <TextField style={{ marginTop: '10px' }} id='link' label={localization.get('form.link')} value={this.state.link} onChange={this.handleChange} disabled={this.state.submitting} />
            <div style={{width: '100%', display: 'flex'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.active}
                    onChange={() => {
                      this.setState({ active: !this.state.active })
                    }}
                  />
                }
                label={localization.get('form.active')}
              />
              <div style={{width: '50%'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.newTab}
                      onChange={() => {
                        this.setState({ newTab: !this.state.newTab })
                      }}
                    />
                  }
                  label={localization.get('form.open_new_tab')}
                />
                 <FormControlLabel
                  control={
                    <Checkbox
                      disabled={this.state.bannerType === 'video'}
                      checked={this.state.halfHeight}
                      onChange={() => {
                        this.setState({ halfHeight: !this.state.halfHeight })
                      }}
                    />
                  }
                  label={localization.get('form.banner_half_height')}
                />
              </div>
              {this.state.link && 
                <div style={{width: '50%'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!this.state.link || this.state.link.length == 0}
                        checked={this.state.hasButton}
                        onChange={() => {
                          this.setState({ hasButton: !this.state.hasButton })
                        }}
                      />
                    }
                    label={localization.get("form.show_banner_button")}
                  />
                </div>
              }
            </div>

            {this.state.hasButton && this.state.link && 
              <Card style={{overflow: 'visible'}}>
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                  <h4 style={{marginTop: 0}}>{localization.get("form.banner_button_style")}</h4>
                  <div style={{width: '100%', display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!this.state.link || this.state.link.length == 0}
                          checked={this.state.hasCustomButton}
                          onChange={() => {
                            this.setState({ hasCustomButton: !this.state.hasCustomButton })
                          }}
                        />
                      }
                      label={localization.get("form.customize_link")}
                    />
                  </div>
                  {this.state.hasCustomButton &&
                    <>
                      <TextField id='buttonTextEs' style={{marginBottom: 10}} label={localization.get("form.button_text_es")} value={this.state.buttonTextEs} onChange={this.handleChange} disabled={this.state.submitting} />
                      <TextField id='buttonTextEn' style={{marginBottom: 10}} label={localization.get("form.button_text_en")} value={this.state.buttonTextEn} onChange={this.handleChange} disabled={this.state.submitting} />
                      <FormControl style={{float: "left", position: "relative", width: "100%", marginBottom: 10}}>
                        <label> {localization.get('live_button.shape')} </label>
                        <RadioGroup
                          name="buttonShape"
                          value={this.state.buttonShape}
                          onChange={e =>
                            this.handleChange({target: {
                                id: "buttonShape",
                                value: e.target.value
                              }})
                          }
                          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                        >
                          <FormControlLabel value="rounded" control={<Radio color="default"/>} label={localization.get("form.rounded")}/>
                          <FormControlLabel value="square" control={<Radio color="default"/>} label={localization.get("form.square")}/>
                        </RadioGroup>
                      </FormControl>
                      <FormControl style={{float: "left", position: "relative", width: "100%", marginBottom: 10}}>
                        <label> {localization.get('form.position')} </label>
                        <RadioGroup
                          name="buttonPosition"
                          value={this.state.buttonPosition}
                          onChange={e =>
                            this.handleChange({target: {
                                id: "buttonPosition",
                                value: e.target.value
                              }})
                          }
                          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                        >
                          <FormControlLabel value="top-center" control={<Radio color="default"/>} label={localization.get("form.position.top_center")}/>
                          {/* <FormControlLabel value="top-left" control={<Radio color="default"/>} label={localization.get("form.position.top_left")}/> */}
                          <FormControlLabel value="bottom-center" control={<Radio color="default"/>} label={localization.get("form.position.bottom_center")}/>
                        </RadioGroup>
                      </FormControl>
                      <div style={{width: "100%", float: "left", marginBottom: 10}}>
                        <div style={{float: "left", width: "50%"}}>
                          <TextField id='buttonFontSize' type='number' label={localization.get('form.button_font_size')+"(px)"} value={this.state.buttonFontSize} onChange={this.handleChange} disabled={this.state.submitting} />
                        </div>
                        <div style={{float: "left", width: "50%"}}>
                          <TextField id='buttonPadding' type='number' label={localization.get('form.button_padding')+"(px)"} value={this.state.buttonPadding} onChange={this.handleChange} disabled={this.state.submitting} />
                        </div>
                      </div>
                      <div style={{width: "100%", float: "left", marginBottom: 10}}>
                        <FormControl style={{ float:"left", width: "25%"}}>
                          <label style={{height: 40}}> {localization.get('form.color_background')} </label>
                          <ColorPicker
                            id={'buttonBgColor'}
                            handleColorChange={color =>
                              this.handleChange({target: {
                                  id: "buttonBgColor",
                                  value: color
                                }})
                            }
                            startColor={this.state.buttonBgColor}
                          />
                        </FormControl>
                        <FormControl style={{ float:"left", width: "25%"}}>
                          <label style={{height: 40}}> {localization.get('form.color_text')} </label>
                          <ColorPicker
                            id={'buttonFontColor'}
                            handleColorChange={color =>
                              this.handleChange({target: {
                                  id: "buttonFontColor",
                                  value: color
                                }})
                            }
                            startColor={this.state.buttonFontColor}
                          />
                        </FormControl>
                        <FormControl style={{float: "left", position: "relative", width: "50%"}}>
                          <label>{localization.get('live_button.show_button')}</label>
                          <RadioGroup
                            name="buttonIcon"
                            value={this.state.buttonIcon}
                            onChange={e =>
                              this.handleChange({target: {
                                  id: "buttonIcon",
                                  value: e.target.value
                                }})
                            }
                            style={{ position: "relative", flexDirection: "row"}}
                          >
                            <FormControlLabel value="show" control={<Radio color="default"/>} label={localization.get("show")}/>
                            <FormControlLabel value="hide" control={<Radio color="default"/>} label={localization.get("hide")}/>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </>
                  }
                </CardContent>
              </Card>
            }

            {!this.props.editing &&
              <FormControl style={{width: "100%", float: "left", position: "relative"}}>
                <RadioGroup
                  name="showSpeakers"
                  value={this.state.bannerType}
                  onChange={event => this.setState({ bannerType: event.target.value })}
                  style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
                >
                  <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="video" control={<Radio color="default" />} label={localization.get('banner.videos')} />
                  <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="image" control={<Radio color="default" />} label={localization.get('banner.images')} />
                </RadioGroup>
              </FormControl>
            }

            {this.state.bannerType === 'video' ?
                <div style={{ textAlign: 'center', margin: '10px 0' }}>
                  <h3>{localization.get('form.select_video')}</h3>
                  {this.state.video.src && <video width='100%' controls>
                    <source src={this.state.video.src} type='video/mp4' />
                    Your browser does not support HTML5 video.
                  </video>}
                  <FilePondUploader
                    file={this.state.video}
                    id={"video"}
                    type={"video"}
                    allowedTypes={["video/mp4","video/x-m4v","video/*"]}
                    onIdChange={this.onVideoChange}
                    setPreview={this.setPreview}
                    disableForm={this.state.submitting}
                    updateDisableForm={value => this.setState({submitting: value})}
                    clientId={this.props.clientId}
                  />
                  <h6 style={{textAlign: "center"}}>{localization.get('video_aspect_ratio', "16:9")}</h6>
                </div>
              :
              <div style={{ textAlign: 'center', margin: '10px 0' }}>
                {this.state.image && this.state.image.src && <ImagePicker image={this.state.image.src} previewOnly={true} id={this.state.image.id}/>}
                <FilePondUploader
                  disableForm={this.state.submitting}
                  updateDisableForm={value => this.setState({submitting: value})}
                  file={this.state.image}
                  id={"image"}
                  type={"image"}
                  allowedTypes={["image/*"]}
                  onIdChange={this.onImageChange}
                  setPreview={this.onSetPreview}
                  clientId={this.props.clientId}
                />
                <h6 style={{textAlign: "center"}}>{localization.get('helper_text.recommended_size', this.state.halfHeight ? "1500x420" : "1500x840")}</h6>
              </div>
            }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} disabled={this.state.submitting}><Close /></Button>
          <Button onClick={this.handleSubmit} disabled={this.state.submitting}><Done /></Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default HomeBannerElementForm