import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  name,
  isTalent,
  clientId,
  cloudId,
  emails,
  hint,
  isProtected,
}) => {
  return post(`${host}/batchUploadLinks`,
    makeFormData({
      name,
      isTalent,
      clientId,
      cloudId,
      emails,
      hint,
      isProtected,
    }))
    .then(response => response.json())
    .then(response => response.data)
}
