export const makeFormData = ({
  audioFile
}) => {
  const form = new FormData()
  if (audioFile instanceof Blob) form.append('audio', audioFile, audioFile.name)
  return form
}

export const makeJsonData = ({
  name,
  visibility,
  status,
  createdByAdmin,
  catalogCategoryId,
  createdBy,
  clientsIds,
  countriesIds,
  weight,
  content,
  personalizedContent,
  supervised,
  musicDealer,
}) => {
  let object = {};
  if (name) object.name = name;
  if (visibility) object.visibility = visibility;
  if (status) object.status = status;
  if (createdByAdmin) object.created_by_admin = createdByAdmin;
  if (catalogCategoryId) object.catalog_category_id = catalogCategoryId;
  if (createdBy) object.created_by = createdBy;
  if (weight) object.weight = weight;
  clientsIds && clientsIds.length > 0 ? object.clients_ids = clientsIds : object.clients_ids = []
  countriesIds && countriesIds.length > 0 ? object.countries_ids = countriesIds : object.countries_ids = []
  if (content) object.content = content
  personalizedContent && personalizedContent.length > 0 ? object.personalized_content = personalizedContent : object.personalized_content = []
  object.supervised = !!supervised
  console.log(musicDealer);
  if (musicDealer) object.music_dealer_id = musicDealer;

  return JSON.stringify(object)
}

export const includeQuery = "include=clients;createdBy;countries;catalogCategory;watermark;musicDealer.tagSystem";
