import {makeRoutes, publicRoutes, redirectTo, route} from "./utils";
import PitchesInviteeRoutes from '../../views/Pitches/PitchesInviteeRoutes'

const root = `/project_invitee/projects`

const allowed = publicRoutes.concat([
  route(root, PitchesInviteeRoutes)
]);

const excluded = [];

export default makeRoutes(
  allowed,
  excluded,
  route("/", redirectTo(root))
);
