import React from 'react'
import {request} from '../../../api-client';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import localization from "../../../config/localization"
import {PieChart, Pie, Sector, ResponsiveContainer, Cell} from 'recharts';
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import {get} from "lodash";


const initialState = {
  quota: [],
  loading: true,
  activeIndex: 0,
}


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={get(payload, 'color', fill)}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={get(payload, 'color', fill)}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={get(payload, 'color', fill)}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={get(payload, 'color', fill)} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={get(payload, 'color', fill)} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} GB`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class QuotaDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    this.getQuota(this.props.clientId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.clientId !== this.props.clientId) {
      this.getQuota(nextProps.clientId);
    }
  }
  
  convertToGigabytes = (bytes) => {
    const gigabytes = bytes / (1000000000);
    return Number(gigabytes.toFixed(2));
  }

  getQuota = (clientId) => request.client.getQuota(clientId)
      .then(quota => this.setState({ quota: [
          { name: localization.get('images'), value: this.convertToGigabytes(quota.images), color: '#FFBB28'},
          { name: localization.get('videos'), value: this.convertToGigabytes(quota.videos), color: '#FF8042'},
          { name: localization.get('binary'), value: this.convertToGigabytes(quota.binaries), color: '#00C49F'},
          { name: localization.get('audios'), value: this.convertToGigabytes(quota.audios), color: '#0088FE'},
        ], loading: false,
      }))
      .catch(error => {
        console.log(error)
        this.props.handleClose();
      });

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render = () => {
    const { loading } = this.state;
    const data = this.state.quota;
    const COLORS = ['#FFBB28', '#FF8042', '#00C49F', '#0088FE'];

    return (
      <Dialog open={this.props.show} onBackdropClick={this.props.handleClose} onEscapeKeyDown={this.props.handleClose}>
        <DialogTitle>{this.props.clientName+' '+localization.get('quota')}</DialogTitle>
        <DialogContent style={{width: 500, height: 300, maxWidth: "100%", justifyContent: "center",display: "flex",alignItems: "center"}}>
          {loading ? <div><img style={{width: 25, height: 25}} src={LoadingSpinner} alt="Loading logo"/></div> :
          data.every(item => item.value == 0) ?  
          <div style={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}>{localization.get('quota.empty')}</div> :  
          <ResponsiveContainer width="100%" height="100%">
              <PieChart width={300} height={300}>
              <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                dataKey="value"
                onMouseEnter={this.onPieEnter}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>              
          }
        </DialogContent>
      </Dialog>
    )
  }
}

QuotaDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clientId: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
};

export default QuotaDialog
