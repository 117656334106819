import React, {Component} from "react";
import ConfirmationDialog from "./ConfirmationDialog";
import EventManager, {events} from "../../../utils/EventManager";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";

class GlobalConfirmationDialog extends Component {
  static _ref = null;

  static show = ({ title, content, request, onClose, confirmationLabel, hideCancel, exitButtonText }) =>
    EventManager.getInstance().dispatch(events.SHOW_GLOBAL_CONFIRMATION_DIALOG, {
      title,
      content,
      request,
      onClose,
      confirmationLabel,
      hideCancel,
      exitButtonText,
      doubleConfirmation: false,
    });

  static showDelete = ({
   elementName,
   deleteRequest,
   onSuccess,
   onFail,
   doubleConfirmation = false,
   doubleConfirmationText = 'form.double_confirmation_text',
   auxiliaryInformation="",
   content= "confirmation_dialog.delete_message",
   confirmationLabel= "delete",
   dialogTitle = localization.get("confirmation_dialog.delete_title")
  }) =>
    EventManager.getInstance().dispatch(events.SHOW_GLOBAL_CONFIRMATION_DIALOG, {
      title: dialogTitle,
      content: <div dangerouslySetInnerHTML={{ __html: localization.get(content, elementName) }} />,
      confirmationLabel: localization.get(confirmationLabel),
      doubleConfirmation: doubleConfirmation,
      auxiliaryInformation: auxiliaryInformation,
      elementName: elementName,
      doubleConfirmationText: doubleConfirmationText,
      request: () => deleteRequest()
          .then(() => {
            GlobalSnackbar.show({
              message: localization.get("alert.deleting_an_item_passed"),
              type: GlobalSnackbarTypes.SUCCESS
            });
            onSuccess && onSuccess();
          })
          .catch(e => {
            console.log(e)
            GlobalSnackbar.show({
              message: localization.get("alert.deleting_an_item_failed"),
              type: GlobalSnackbarTypes.ERROR
            });
            onFail && onFail();
          })
    });

  static showDoubleConfirmation = ({
    dialogTitle,
    elementName,
    updateRequest,
    onSuccess,
    onFail,
    doubleConfirmation = false,
    doubleConfirmationText = 'form.double_confirmation_text',
    auxiliaryInformation="",
    content= "confirmation_dialog.message",
    confirmationLabel= "confirmation_dialog.accept",
    onSuccessText = localization.get('home_editor.element_updated'),
    onFailText = localization.get('form.dialog.fail')
  }) =>
    EventManager.getInstance().dispatch(events.SHOW_GLOBAL_CONFIRMATION_DIALOG, {
      title: dialogTitle,
      content: <div dangerouslySetInnerHTML={{ __html: localization.get(content, elementName) }} />,
      confirmationLabel: localization.get(confirmationLabel),
      doubleConfirmation: doubleConfirmation,
      auxiliaryInformation: auxiliaryInformation,
      elementName: elementName,
      doubleConfirmationText: doubleConfirmationText,
      request: () => updateRequest()
        .then(() => {
          GlobalSnackbar.show({
            message: onSuccessText,
            type: GlobalSnackbarTypes.SUCCESS
          });
          onSuccess && onSuccess();
        })
        .catch(e => {
          console.log(e)
          GlobalSnackbar.show({
            message: onFailText,
            type: GlobalSnackbarTypes.ERROR
          });
          onFail && onFail();
        })
    });

  static showFileUploader = (title, message, accept, uploadRequest) => {
    let file;
    GlobalConfirmationDialog.disableSubmit(true);
    GlobalConfirmationDialog.show({
      title,
      content: (
        <div>
          <div style={{ marginBottom: 10 }}>{message}</div>
          <input
            type="file"
            accept={accept}
            onChange={e => {
              file = e.target.files[0];
              if (file.size < 1000000) {
                GlobalConfirmationDialog.disableSubmit(false);
              } else {
                GlobalConfirmationDialog.disableSubmit(true);
                GlobalSnackbar.show({
                  message: "El archivo no debe superar los 1mb de peso",
                  type: GlobalSnackbarTypes.ERROR
                });
              }
            }}
          />
        </div>
      ),
      confirmationLabel: "Cargar",
      onClose: () => {
        GlobalConfirmationDialog.disableSubmit(false);
      },
      request: () =>
        uploadRequest(file)
          .then(() => GlobalSnackbar.show({ message: "Se subio el archivo con exito!", type: GlobalSnackbarTypes.SUCCESS }))
          .catch(e => GlobalSnackbar.show({ message: "No se pudo subir el archivo...", type: GlobalSnackbarTypes.ERROR }))
    });
  };

  static hide = () => EventManager.getInstance().dispatch(events.HIDE_GLOBAL_CONFIRMATION_DIALOG);

  static disableSubmit = value => GlobalConfirmationDialog._ref.disableSubmit(value);

  constructor(props) {
    super(props);
    GlobalConfirmationDialog._ref = this;
    this.state = {
      open: false,
      disableSubmit: false
    };
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.SHOW_GLOBAL_CONFIRMATION_DIALOG, this.show);
    EventManager.getInstance().subscribe(events.HIDE_GLOBAL_CONFIRMATION_DIALOG, this.hide);
    EventManager.getInstance().subscribe(events.CONFIRMATION_DIALOG_CLOSED, this.onInstanceClosed);
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SHOW_GLOBAL_CONFIRMATION_DIALOG, this.show);
    EventManager.getInstance().unsubscribe(events.HIDE_GLOBAL_CONFIRMATION_DIALOG, this.hide);
    EventManager.getInstance().unsubscribe(events.CONFIRMATION_DIALOG_CLOSED, this.onInstanceClosed);
  };

  show = data => this.setState({ open: true, ...data });

  hide = () => this.setState({ open: false });

  onInstanceClosed = confirmationDialog => {
    if (confirmationDialog === this.confirmationDialog) {
      GlobalConfirmationDialog.hide();
    }
  };

  disableSubmit = value => this.setState({ disableSubmit: value });

  render = () => {
    const { open, title, content, request, onClose, confirmationLabel, disableSubmit, hideCancel, doubleConfirmation, doubleConfirmationText, elementName, auxiliaryInformation, exitButtonText } = this.state;

    return (
      <ConfirmationDialog
        ref={r => (this.confirmationDialog = r)}
        open={open}
        title={title}
        content={content}
        request={request}
        onClose={onClose}
        hideCancel={hideCancel}
        confirmationLabel={confirmationLabel}
        disableSubmit={disableSubmit}
        auxiliaryInformation={auxiliaryInformation}
        doubleConfirmation={doubleConfirmation}
        doubleConfirmationText={doubleConfirmationText}
        elementName={elementName}
        exitButtonText={exitButtonText}
      />
    );
  };
}

export default GlobalConfirmationDialog;
