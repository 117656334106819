import {post} from "../utils";
import {getActiveChannel} from "../authentication/utils";

export default host => (
  id,
  contactName,
  contactEmail,
  contactDescription,
) => {
  let url = `${host}${getActiveChannel() ? `/channels/${getActiveChannel()}` : ""}/contentCreators/${id}/contact`
  const makeForm = ({ contactName, contactEmail, contactDescription }) => {
    const form = new FormData();

    if (contactName) form.append("name", contactName);
    if (contactEmail) form.append("email", contactEmail);
    if (contactDescription) form.append("description", contactDescription);
    return form;
  }
  return post(url, makeForm({
      contactName,
      contactEmail,
      contactDescription,
    })
  ).then(response => response.json());
}