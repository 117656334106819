import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import {request} from "../../../api-client";
import Button from "../../../components/CustomButtons/Button";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {TextValidator} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import {
  getStoredChannel,
  userIsContentSupervisor,
  userisMultiClient,
  userIsSupervisor
} from "../../../api-client/core/authentication/utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {Clear} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import {validateEmail} from "../../../utils";

class BatchUpload extends Component {
  state = {
    name: '',
    client: '',
    perPage: 20,
    page: 0,
    openDialog: false,
    updateTable: false,
    clouds: [],
    cloud: null,
    useEmailNotification: false,
    isProtected: false,
    emailInput: "",
    emails: [],
    editingDialog: false,
    hint: ""
  };

  componentDidMount = () => {
    document.title = localization.get("batch_upload") + " - Feater";

    return request.clouds.getAll()
      .then(clouds => clouds.map(cloud => ({value: cloud.id, label: cloud.name})))
      .then(clouds => clouds.sort((a, b) => a.label.localeCompare(b.label)))
      .then(clouds => this.setState({clouds}))
  };

  handleCloseDialog = () => this.setState({
    editingDialog: false,
    openDialog: false,
    cloud: null,
    useEmailNotification: false,
    isProtected: false,
    emailInput: "",
    emails: [],
    name: '',
    hint: '',
    client: '',
  })

  onUpdateLink = id => {
    request.batchUpload.get(id, 'include=cloud;client')
      .then(link => this.setState({
        editingDialog: true,
        editingLink: link,
        openDialog: true,
        useEmailNotification: !!link.notify_emails,
        isProtected: !!link.protected,
        emails: link.notify_emails ? link.notify_emails.split(',') : [],
        name: link.name,
        hint: link.hint,
      }))
  }

  updateLink = () => {
    const data = {
      name: this.state.name,
      clientId: this.state.client && this.state.client.value,
      cloudId: this.state.cloud && this.state.cloud.value,
      emails: this.state.emails.length > 0 ? this.state.emails.toString() : null,
      hint: this.state.hint,
      isProtected: this.state.isProtected,
    }

    if(validateEmail(this.state.emailInput)) {
      const emails = this.state.emails;
      emails.push(this.state.emailInput);

      data.emails = emails;
    }

    request.batchUpload.update(this.state.editingLink.id, data)
      .then(() => {
        this.setState({
          openDialog: false,
          editingDialog: false,
          editingLink: null,
          updateTable: !this.state.updateTable,
          name: '',
          client: '',
          cloud: null,
          useEmailNotification: false,
          emailInput: "",
          emails: [],
          hint: "",
          isProtected: false,
        })
      })
  }

  renderCreateLinkDialog = () => {
    return (
      <Dialog
        style={{margin: "-35px"}}
        open={this.state.openDialog}
        onBackdropClick={this.handleCloseDialog}
        onEscapeKeyDown={this.handleCloseDialog}
      >
        <DialogTitle>{localization.get('batch_upload.create_link')}</DialogTitle>
        <DialogContent style={{width: "460px", }}>
          <ValidatorForm>
          <TextValidator
            style={{ width: "100%", marginBottom: "20px" }}
            id="name"
            label={localization.get('form.name')}
            name="name"
            value={this.state.name}
            onChange={e => this.setState({name: e.target.value})}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />
            {!userIsContentSupervisor() && !(this.state.editingDialog && this.state.editingLink && this.state.editingLink.client) &&
              <>
                <br/>
                <FormControl fullWidth>
                  <span>{localization.get(!userisMultiClient() ? 'batch_upload.select_client' : 'batch_upload.select_client_multiclient')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('clients')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={client => this.setState({client})}
                    value={this.state.client}
                    loadOptions={inputValue =>
                      request.client
                        .getAll({filterBy: [{column: "name", filter: inputValue}]})
                        .then(res => res.map(client => ({value: client.id, label: client.name})))
                    }
                    defaultOptions
                  />
                </FormControl>
              </>
            }

            {!(this.state.editingDialog && this.state.editingLink && this.state.editingLink.cloud) &&
              <>
                <br/>
                <FormControl fullWidth>
                  <span>{localization.get('clouds')}</span>
                  <AsyncSelect
                    ref="select"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                    })}
                    placeholder={localization.get('conversation.filter_users')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={(cloud) => this.setState({cloud})}
                    value={this.state.cloud}
                    defaultOptions={this.state.clouds}
                    isClearable={true}
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.clouds.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                    }}
                  />
                </FormControl>
              </>
            }
            {this.state.editingDialog && this.state.editingLink &&
              <>
                <br/>
                {this.state.editingLink.cloud &&
                  <TextValidator
                    style={{width: "100%"}}
                    id="cloud"
                    label={localization.get('clouds')}
                    name="cloud"
                    value={this.state.editingLink.cloud.name}
                    disabled
                  />
                }
                {this.state.editingLink.client &&
                  <TextValidator
                    style={{width: "100%",}}
                    id="client"
                    label={localization.get('form.client')}
                    name="client"
                    value={this.state.editingLink.client.name}
                    disabled
                  />
                }
              </>
            }
            <TextValidator
              style={{ width: "100%", marginBottom: "20px" }}
              id="hint"
              label={localization.get('batch_upload.hint')}
              name="hint"
              value={this.state.hint}
              onChange={e => this.setState({hint: e.target.value})}
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={this.state.useEmailNotification}
                  onChange={() => {
                    if (this.state.useEmailNotification) {
                      this.setState({
                        useEmailNotification: !this.state.useEmailNotification,
                        emailInput: "",
                        emails: [],
                      })
                    }else {
                      this.setState({ useEmailNotification: !this.state.useEmailNotification })
                    }
                  }}
                />
              }
              label={localization.get("form.notify_email")}
            />
            {userIsSupervisor() &&
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={this.state.isProtected}
                      onChange={() => this.setState({isProtected: !this.state.isProtected})}
                    />
                  }
                  label={localization.get("form.is_protected")}
                />
                <br/>
                <span>{localization.get('form.is_protected_helper')}</span>
              </>
            }
            {this.state.useEmailNotification &&
              <>
                <TextValidator
                  style={{ width: "100%", marginBottom: "20px" }}
                  id="emailInput"
                  label={localization.get('form.emails_to_notify')}
                  name="emailInput"
                  value={this.state.emailInput}
                  onChange={e => this.setState({emailInput: e.target.value})}
                  helperText={localization.get('form.emails_to_notify_helper')}
                  onKeyDown={e => {
                    const splitKeyCodes = [32, 188, 9, 13];
                    if (splitKeyCodes.includes(e.keyCode)) {
                      e.preventDefault();
                      e.stopPropagation();
                      if(validateEmail(this.state.emailInput)) {
                        const emails = this.state.emails;
                        emails.push(this.state.emailInput);
                        this.setState({emails: emails, emailInput: ""})
                      }else {
                        GlobalSnackbar.show({
                          message: localization.get('form.email_not_valid'),
                          type: GlobalSnackbarTypes.ERROR
                        })
                      }
                    }
                  }}
                />
                {this.state.emails.length > 0 && this.state.emails.map((item, i) =>
                  <Chip
                    deleteIcon={<Clear />}
                    onDelete={() => this.setState({emails: this.state.emails.filter(email => item !== email)})}
                    variant="outlined"
                    style={{
                      margin: 2.5,
                      border: "1px solid #0000003d",
                      backgroundColor: 'darkgray',
                      color: 'white',
                    }}
                    label={item}
                  />
                )}
              </>
            }
            <br />
            <span style={{opacity: 0.8}}>{localization.get('batch_upload.delete_disclaimer')}</span>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color='default'>
            {localization.get("cancel")}
          </Button>
          <Button
            onClick={() => this.state.editingDialog ? this.updateLink() : this.onCreateLink()}
            color='dark'
          >
            {localization.get("form.submit")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  onCreateLink = () => {
    const data = {
      name: this.state.name,
      isTalent: this.props.isTalent ? 1 : 0,
      clientId: this.state.client && this.state.client.value,
      cloudId: this.state.cloud && this.state.cloud.value,
      emails: this.state.emails.length > 0 ? this.state.emails.toString() : null,
      hint: this.state.hint,
      isProtected: this.state.isProtected,
    }

    if (userisMultiClient() && !this.state.client) {
      GlobalSnackbar.show({message: localization.get('client.required'), type: GlobalSnackbarTypes.ERROR})
      return false
    } else if (userIsContentSupervisor() && !this.state.cloud) {
      GlobalSnackbar.show({message: localization.get('cloud.required'), type: GlobalSnackbarTypes.ERROR})
      return false
    } else {
      return request.batchUpload.create(data)
        .then(() => this.setState({
          openDialog: false,
          updateTable: !this.state.updateTable,
          name: '',
          client: '',
          cloud: null,
          useEmailNotification: false,
          emailInput: "",
          emails: [],
          hint: "",
          isProtected: false,
        }))
    }
  }

  onAdd = () =>  this.setState({openDialog: true});

  render = () => (
    <AllViewContainer onAdd={this.onAdd} ref="viewContainer">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              {this.renderCreateLinkDialog()}
              <Table {...this.props} update={this.state.updateTable} onUpdate={id => this.onUpdateLink(id)}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default BatchUpload;
