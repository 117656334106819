import React, {Component} from "react";

import {request} from "api-client";
import Form from "../Form";
import {ROLES} from "../../../api-client/core/authentication/utils";
import localization from '../../../config/localization'

export default class extends Component {
  submitRequest = data => {

    const requestData = {
      name: data.name,
      email: data.email,
      description: data.description,
      imageFile: data.image.file,
      roleId: data.roleId,
      language: data.language,
      selectedArtists: data.selectedArtists.map(x => x.value),
      selectedClouds: data.selectedClouds.map(x => x.value),
      canCreateClients: data.canCreateClients,
      canSeeLicenses: data.canSeeLicenses,
      canCreateContentCreators: data.canCreateContentCreators,
      selectedCatalogs: data.selectedCatalogs.map(x => x.value),
      canEditAdmins: data.canEditAdmins,
      selectedChannels: data.selectedChannels,
      clientForEditor: data.clientForEditor && data.clientForEditor.value,
      gcbaId: data.gcbaId,
      mibaId: data.mibaId,
      address: data.address,
      canAccessMusic: data.canAccessMusic,
      canAccessClients: data.canAccessClients,
      canAccessRanking: data.canAccessRanking,
      canAccessConfig: data.canAccessConfig,
      canAccessLicense: data.canAccessLicense,
      canAccessReports: data.canAccessReports,
      password: data.password,
      brand: data.selectedBrand && data.selectedBrand.value, 
      accessLevel: data.accessLevel,
      identityNumber: data.identityNumber,
    };

    const dataRepresentsToRequestClients = represents =>
      represents.map(represented => ({
        client_id: represented.client_id,
        role_id: represented.role_id,
        can_license: represented.can_license,
        access_level: 5,
        allow_edit: 1,
      }));

    switch (data.roleId) {
      case ROLES.MULTI_CLIENT:
        requestData.client_roles = dataRepresentsToRequestClients(data.represents);
        break;
      case ROLES.SINGLE_CLIENT:
        requestData.client_roles = [
          {
            client_id: data.client.value,
            role_id: data.roleInClient,
            can_license: data.roleInClient === ROLES.clientAdminId ? 1 : data.canLicense,
            access_level: data.roleInClient === ROLES.clientAdminId ? 5 : data.accessLevel,
            allow_edit:  data.roleInClient === ROLES.clientAdminId ? 1 : data.canEditContentCreators,
          },
        ];
        break;
      case ROLES.contentSupervisorId:
        requestData.client_roles = [
          {
            client_id: data.clientForEditor && data.clientForEditor.value,
            role_id: ROLES.USER,
            can_license: 0,
            access_level: data.accessLevel,
            allow_edit:  1,
          },
        ];
        break;
    }

    return request.user.create(requestData);
  };

  render = () => {
    return <Form {...this.props} creating={true} submitRequest={this.submitRequest} formTitle={localization.get('user.create_user')} type={"create"} />;
  };
}
