import React, {Component} from "react";
import {ImagePicker, SelectValidator} from "../../../components/Letflow/Form";
import {Checkbox, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import localization from "../../../config/localization";
import {request} from "../../../api-client";
import AsyncSelect from "react-select/lib/Async";
import {getStoredUser, userisMultiClient, userIsSupervisor} from "../../../api-client/core/authentication/utils";
import {types} from "../All/Table"
import SocialLinks from "../../../components/Letflow/SocialLinks";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton/IconButton";
import TranslatableInputs from "../../../components/Letflow/TranslatableInputs";
import RichTextEditor from "../../../components/Letflow/RichTextEditor";
import {stateToHTML} from "draft-js-export-html";

const canMakeManagerClients = () => {
  let whitelistedEmails = process.env.REACT_APP_WHITELIST_CAN_MANAGE_CLIENTS
  if (!whitelistedEmails) {
    return false
  }
  whitelistedEmails = whitelistedEmails.split(',')
  return whitelistedEmails.find(whitelistedEmail => whitelistedEmail === getStoredUser().email)
}

const ClientFormView = ({
  id,
  disabled,
  onChange,
  name,
  type,
  image,
  onImageChange,
  cuit,
  address,
  email,
  country,
  licensePlan,
  licensePlans,
  excludeFromReports,
  demo,
  onAsyncPermissionsChange,
  selectedPermissions,
  onAsyncCountriesChange,
  managedBy,
  prefix,
  reelUrl,
  onAsyncManagedBy,
  useLogo,
  socialLinks,
  onSocialLinksChange,
  catalogClient,
  expanded,
  expanderOnClick,
  aboutEs,
  aboutEn,
  onContentChange,
  keywordsSEO,
  titleSEO,
  descriptionSEO,
  showAbout,
  onAsyncContentCreatorTypesChange,
  contentCreatorTypes,
  canManageClients,
  searchPreferences,
  onChangeRadio,
  blacklistTypeContentCreatorTypes,
  onChangeBlacklistTypeContentCreatorTypes,
  allowFakeLivestreams,
  secure,
  hasOwnTags,
  onChangeChannel,
  channel,
  transcodeVideos,
  useAccessLevel,
  accessLevel,
  useRekognition
}) => {
  const musicPermission = {value: 'music', label: localization.get('permissions.music')};
  const contentCreatorPermission = {value: 'content_creator', label: localization.get('permissions.content_creator')};
  const reelPermission = {value: 'reel', label: localization.get('permissions.reel')};
  const clientFilePermission = {value: 'client_file', label: localization.get('permissions.client_file')};
  const pitchPermission = {value: 'pitch', label: localization.get('permissions.pitch')};
  const accountPermission = {value: 'account', label: localization.get('permissions.account')};
  const channelPermission = {value: 'channel', label: localization.get('permissions.channel')};

  let permissions = [];

  permissions.push(musicPermission);
  permissions.push(contentCreatorPermission);
  permissions.push(reelPermission);
  permissions.push(clientFilePermission);
  permissions.push(pitchPermission);
  permissions.push(accountPermission);
  permissions.push(channelPermission);

  ValidatorForm.addValidationRule('urlPrefix', () => {
    if (!prefix) {
      return false
    }
    return prefix.match(/^[0-9a-za]+$/)
  });

  return (
    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get("form.name")}
        name="name"
        value={name}
        onChange={onChange}
        disabled={disabled}
        validators={["required"]}
        errorMessages={[localization.get("validator.is_required")]}
      />

    
      <React.Fragment>
        <br />
        <br />
        <SelectValidator
          id="type"
          name="type"
          current={type}
          title={localization.get("catalog.owner")}
          onChange={onChange}
          items={types.filter(type => !type.disabled)}
          disabled={disabled}
          validators={["required"]}
          errorMessages={[localization.get("validator.is_required")]}
        />
      </React.Fragment>


      <br />
      <br />
      <FormControl fullWidth>
        <span>{localization.get('client.permissions')}</span>
        <AsyncSelect
          styles={{
            menu: base => ({
              ...base,
              zIndex: 10
            })
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 0
          })}
          placeholder={localization.get('form.permissions')}
          loadingMessage={() => localization.get('loading')}
          noOptionsMessage={() => localization.get('no_options')}
          onChange={onAsyncPermissionsChange}
          value={selectedPermissions}
          isMulti
          isDisabled={id && userisMultiClient()}
          defaultOptions={permissions}
        />
      </FormControl>

      {selectedPermissions.filter(selectedPerm => selectedPerm.value == 'content_creator').length > 0 &&
        <>
          <br />
          <br />
          <FormControl>
            <span>{localization.get('filter_type_content_creators_types')}</span>
            <RadioGroup
              name="blacklistTypeContentCreatorTypes"
              value={blacklistTypeContentCreatorTypes}
              onChange={onChangeBlacklistTypeContentCreatorTypes}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('select_allow_content_creator_types')}/>
              <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('select_disallow_content_creator_types')}/>
              <FormControlLabel value="2" control={<Radio color="default"/>} label={localization.get('see_all.private')}/>
            </RadioGroup>
          </FormControl>
          {blacklistTypeContentCreatorTypes != 2 &&
          <>
            <br/>
            <br/>
            <FormControl fullWidth>
              <span>{blacklistTypeContentCreatorTypes == 1 ? localization.get('client.content_creator_types_blacklist') : localization.get('client.content_creator_types_whitelist')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={blacklistTypeContentCreatorTypes == 1 ? localization.get('form.content_creator_types_blacklist') : localization.get('form.content_creator_types_allowed')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onAsyncContentCreatorTypesChange}
                value={contentCreatorTypes}
                loadOptions={inputValue => {
                  let filterBy = id ? [{column: "name", filter: inputValue}, {column: "dashboardClient", filter: id}] : [{column: "name", filter: inputValue}, {column: "visibility", filter: 'public'}];

                  return request.contentCreatorType.getAll({filterBy})
                    .then(res => res.map(contentCreatorType => ({
                      value: contentCreatorType.id,
                      label: `${contentCreatorType.name}`
                    })))
                    .then(contentCreatorTypes => contentCreatorTypes.sort((a, b) => a.label.localeCompare(b.label)))
                }}
                isMulti
                defaultOptions
              />
            </FormControl>
          </>
          }

         {/* <br />
          <br />
          <FormControl style={{width: "100%", float: "left", position: "relative"}}>
            <FormLabel>{localization.get('search_preferences')}</FormLabel>
            <RadioGroup
            name="searchPreferences"
            id="searchPreferences"
            value={searchPreferences}
            onChange={onChangeRadio}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="content_creator" control={<Radio color="default" />} label={localization.get('content_creator')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="content_creation" control={<Radio color="default" />} label={localization.get('content_creation')} />
              <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="both" control={<Radio color="default" />} label={localization.get('license_plans.both')} />
            </RadioGroup>
          </FormControl>*/}
        </>
      }

      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="cuit"
        label="CUIT"
        name="cuit"
        value={cuit}
        onChange={onChange}
        disabled={disabled}
      />
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="address"
        label={localization.get("form.address")}
        name="address"
        value={address}
        onChange={onChange}
        disabled={disabled}
      />
     {/* <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="prefix"
        label={localization.get('prefix')}
        name="prefix"
        value={prefix}
        onChange={onChange}
        disabled={disabled}
        errorMessages={[localization.get("validator.url_prefix")]}
      />*/}
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="reelUrl"
        label={localization.get('form.reel_url')}
        name="reelUrl"
        value={reelUrl}
        onChange={onChange}
        disabled={disabled}
      />
      <br />
      <br />

      <SocialLinks formView={true} socialLinks={socialLinks} onSocialLinksChange={onSocialLinksChange}/>
      <br/>
      <br/>

      <TextValidator
        style={{ width: "100%" }}
        id="email"
        label={localization.get("form.email")}
        name="email"
        value={email}
        onChange={onChange}
        disabled={disabled}
      />
      <br />
      <br />

      <FormControl fullWidth>
        <span>{localization.get('form.country')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onAsyncCountriesChange}
            value={country}
            defaultOptions
            loadOptions={inputValue =>
              request.country
                .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                .then(res => res.map(country => ({ value: country.id, label: country.name })))
            }
            validators={["required"]}
            errorMessages={[localization.get("validator.is_required")]}
          />
      </FormControl>


        <React.Fragment>
          <br/>
          <br/>
          <SelectValidator
            id="licensePlan"
            name="licensePlan"
            current={licensePlan}
            title={localization.get("form.licensePlan")}
            onChange={onChange}
            items={licensePlans}
            disabled={disabled}
          />
        </React.Fragment>

      { catalogClient &&
        <>
        <br/>
        <br/>
        <span>{localization.get('form.additional_information')}</span>
        <IconButton onClick={expanderOnClick}>
          {expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
        </IconButton>

      { expanded &&
      <>
        <TranslatableInputs
          children={[
            {
              language: 'es',
              content: [
                <FormControlLabel
                  control={
                    <Checkbox
                      id="showAbout"
                      style={{ color: "#4cc2d5" }}
                      checked={showAbout}
                      onChange={onChange}
                      disabled={disabled}
                    />
                  }
                  label={localization.get('form.show_in_home')}
                />,
                <TextValidator
                  style={{display: "none"}}
                  id="aboutEs"
                  label={localization.get('form.about')}
                  name="aboutEs"
                  value={stateToHTML(aboutEs.getCurrentContent())}
                  onChange={onChange}
                  disabled={disabled}
                  isHtml={true}
                />,
                <div style={{marginBottom: "-2rem"}}>{localization.get('form.about')}</div>,
                <RichTextEditor
                  editorState={aboutEs}
                  onChange={(e) => onContentChange(e, 'aboutEs')}
                />
              ]
            },
            {
              language: 'en',
              content: [
                <FormControlLabel
                  control={
                    <Checkbox
                      id="showAbout"
                      style={{ color: "#4cc2d5" }}
                      checked={showAbout}
                      onChange={onChange}
                      disabled={disabled}
                    />
                  }
                  label={localization.get('form.show_in_home')}
                />,
                <TextValidator
                  style={{display: "none"}}
                  id="aboutEn"
                  label={localization.get('form.about')}
                  name="aboutEn"
                  value={aboutEn}
                  onChange={onChange}
                  disabled={disabled}
                  isEditorState={true}
                />,
                <div style={{marginBottom: "-2rem"}}>{localization.get('form.about')}</div>,
                <RichTextEditor
                  editorState={aboutEn}
                  onChange={(e) => onContentChange(e, 'aboutEn')}
                />
              ]
            }
          ]}
        />
        <TextValidator
          style={{ width: "100%" }}
          id="keywordsSEO"
          label={localization.get("form.seo_keywords")}
          name="keywordsSEO"
          value={keywordsSEO}
          onChange={onChange}
          disabled={disabled}
        />
        <br />
        <br />
        <TextValidator
          style={{ width: "100%" }}
          id="titleSEO"
          label={localization.get("form.seo_title")}
          name="titleSEO"
          value={titleSEO}
          onChange={onChange}
          disabled={disabled}
        />
        <br />
        <br />
        <TextValidator
          style={{ width: "100%" }}
          id="descriptionSEO"
          label={localization.get("form.seo_description")}
          name="descriptionSEO"
          value={descriptionSEO}
          onChange={onChange}
          disabled={disabled}
        />
      </>
      }
        </>
      }
      <br />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            id="useAccessLevel"
            style={{ color: "#4cc2d5" }}
            checked={useAccessLevel}
            onChange={e => { 
              if (useAccessLevel) {
                onChange({target: {id: "accessLevel", value: "0"}})
              }

              onChange(e)
            }}
            disabled={disabled}
          />
        }
        label={localization.get('form.has_access_level')}
      />
      {useAccessLevel && 
        <FormControl fullWidth>
          <h4>{localization.get('form.access_level')}</h4>
          <RadioGroup
            name="accessLevel"
            id="accessLevel"
            value={accessLevel}
            onChange={e => onChange({target: {id: "accessLevel", value: e.target.value}})}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel
                value={"0"}
                control={<Radio color="default"/>}
                label={localization.get('smart_search.none')}
              />
            {["1","2","3","4","5"].map(level =>
              <FormControlLabel
                value={level}
                control={<Radio color="default"/>}
                label={level}
              />
            )}
          </RadioGroup>
        </FormControl>
      }
      <br/>
      {userIsSupervisor() &&
        <>
          <FormControlLabel
            control={
              <Checkbox
                id="secure"
                style={{ color: "#4cc2d5" }}
                checked={secure}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.secure')}
          />
          <br/>
        </>
      }
      {userIsSupervisor() &&
        <>
          <FormControlLabel
            control={
              <Checkbox
                id="transcodeVideos"
                style={{ color: "#4cc2d5" }}
                checked={transcodeVideos}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.client_transcode_videos')}
          />
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                id="useRekognition"
                style={{ color: "#4cc2d5" }}
                checked={useRekognition}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.use_rekognition')}
          />
          <br/>
        </>
      }
      {!userisMultiClient() ?
        <React.Fragment>
          <FormControlLabel
            control={
              <Checkbox
                id="demo"
                style={{ color: "#4cc2d5" }}
                checked={demo}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.demo')}
          />
          <br />
          { !demo &&
          <FormControlLabel
            control={
              <Checkbox
                id="excludeFromReports"
                style={{ color: "#4cc2d5" }}
                checked={excludeFromReports}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.exclude_from_reports')}
          />
          }
          <br />
          <FormControlLabel
            control={
              <Checkbox
                id="useLogo"
                style={{ color: "#4cc2d5" }}
                checked={useLogo}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('form.use_logo')}
          />
          {canMakeManagerClients() &&
           <>
             <br />
             <FormControlLabel
               control={
                 <Checkbox
                   id="canManageClients"
                   style={{ color: "#4cc2d5" }}
                   checked={canManageClients}
                   onChange={onChange}
                   disabled={disabled}
                 />
               }
               label={localization.get('form.can_manage_clients')}
             />
           </>
          }
          <br />
          <FormControlLabel
            control={
              <Checkbox
                id="allowFakeLivestreams"
                style={{ color: "#4cc2d5" }}
                checked={allowFakeLivestreams}
                onChange={onChange}
                disabled={disabled}
              />
            }
            label={localization.get('livestreams.allow_fake')}
          />

          <br/>
          {userIsSupervisor() &&
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    id="hasOwnTags"
                    style={{ color: "#4cc2d5" }}
                    checked={hasOwnTags}
                    onChange={onChange}
                    disabled={disabled}
                  />
                }
                label={localization.get('form.has_own_tags')}
              />
              <br/>
              
            </>
          }

          <br />
          <br />
          <ImagePicker
            buttonText={localization.get('form.select_image')}
            image={image != null ? image.src : null}
            onImageChange={onImageChange}
            disabled={disabled}
            accept="image/png"
          />
        </React.Fragment>
        :
        <>
          {useLogo &&
            <>
              <br/>
              <br />
              <ImagePicker
              buttonText={localization.get('form.select_image')}
              image={image != null ? image.src : null}
              onImageChange={onImageChange}
              disabled={disabled}
              accept="image/png"
              />
            </>
          }
        </>
      }
    </div>
  );
};

class Wrapper extends Component {
  state = {
    plans: [],
  };

  componentDidMount = () => {
    request.licensePlan
      .getAll()
      .then(res => res.data)
      .then(plans => this.setState({ plans }));
  };

  render = () => <ClientFormView {...this.props} licensePlans={this.state.plans} />;
}

export default Wrapper;
