import "./TrackTable.css";
import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {makeWaveformUrl, secondsToMMSS} from "../../../utils";
import {request} from "../../../api-client";
import SoundWave from "assets/img/Letflow/sound-wave.png";
import {CloudDownload, Pause, PlayArrow as Play} from "@material-ui/icons";
import localization from "../../../config/localization";
import {Tooltip} from "@material-ui/core";
import {homePathGenerator, userCanDownloadTracks,} from "../../../api-client/core/authentication/utils";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import GlobalConfirmationDialog from "../Dialog/GlobalConfirmationDialog";
import browserHistory from "../../../utils/browserHistory";
import {makeImageUrlWithSize} from "../../../layouts/Home/HomeElements/utils";

class TrackTableSubComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versions: []
    };
  }

  componentDidMount = () => {
    request.music.get(this.props.music.id, 'include=versions.audio.waveform')
      .then(music => this.setState({versions: music.versions}))

    this.setState({innerWidth: window.innerWidth})
    window.addEventListener('resize', () => this.setState({innerWidth: window.innerWidth}));

    this.trackPausedSubscription = GlobalSoundBar.trackPaused$().subscribe(this.handleTrackPausedViaSoundbar);
    this.trackStartedPlayingSubscription = GlobalSoundBar.trackStartedPlaying$().subscribe(this.handleTrackStartedPlayingInSoundbar);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', () => this.setState({innerWidth: window.innerWidth}));
    this.trackPausedSubscription.unsubscribe();
  };

  handleTrackStartedPlayingInSoundbar = () => {
    const { versions } = this.state;
    const playableTrackFromOwnTracks = versions.find(version => GlobalSoundBar.currentTrackIsTitled(version.title));

    if (playableTrackFromOwnTracks) {
      this.setState({ playing: playableTrackFromOwnTracks.id });
    } else {
      this.setState({ playing: null });
    }
  };

  handleTrackPausedViaSoundbar = () => {
    const { versions } = this.state;
    const pausableTrackFromOwnTracks = versions.find(version => GlobalSoundBar.currentTrackIsTitled(version.title));

    if (pausableTrackFromOwnTracks) {
      this.setState({ playing: null });
    }
  };

  handlePauseButtonPress = () => {
    this.setState({ playing: null });
    GlobalSoundBar.pause();
  };

  handlePlayButtonPress = (version, album, music) => () => {
    const { versions } = this.state;

    const soundbarCompatibleTracks = versions.map(version => {
      return {
        fetchAudioUrl: () => request.musicVersion.audioUrl(version.id),
        title: version.title,
        subtitle: album.title,
        image: makeImageUrlWithSize(album, 'xs'),
        waveform: makeWaveformUrl(version),

        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(version, album) : undefined,
        onTitleClick:  () => browserHistory.push(homePathGenerator(`music/${music.id}`)),
        onSubtitleClick: () => browserHistory.push(homePathGenerator(`album/${album.id}`)),
      };
    });

    const playableTrackIndex = versions.indexOf(version);

    this.setState({ playing: version.id });

    GlobalSoundBar.loadTracksAndPlay(soundbarCompatibleTracks, playableTrackIndex);
  };

  openDownloadDialog = (version, album) => {
    GlobalConfirmationDialog.show({
      title: localization.get("download.demo"),
      content: localization.get("download.demo.content"),
      request: () => this.handleDownload(version, album),
      confirmationLabel: localization.get("download"),
    });
  };

  handleDownload = (version, album) => {
    this.setState({ downloading: true });
    return request.musicVersion.downloadDemo(version.id, {
      title: version.title,
      image: makeImageUrlWithSize(album, 'xs'),
    })
      .then(() => {
        this.setState({ downloading: false });
      })
      .catch(() => {
        this.setState({ downloading: false });
      });
  };

  render = () => {
    const {album, music, musicIndex} = this.props;
    const {versions} = this.state;

    return (
      <div className="track-table-versions">
        {versions.map((version, index) => (
          <div className="track-table-row">
            <div className="track-table-favorite">
              <span>&nbsp;</span>
            </div>
            <div className="track-table-image-container">
              <div className="track-table-index">
                {`${musicIndex}.${index + 1}`}
                {this.state.playing === version.id ?
                  <IconButton onClick={this.handlePauseButtonPress} className="track-table-pause-button">
                    <Pause className="track-table-pause-icon"/>
                  </IconButton>
                 :
                  <IconButton
                    onClick={this.handlePlayButtonPress(
                      version,
                      album,
                      music
                    )}
                    className="track-table-play-button"
                  >
                    <Play className="track-table-play-icon"/>
                  </IconButton>
                }
              </div>
            </div>

            <div className="track-table-name-container" style={{top: 30}}>
              <div className="track-table-name" onClick={() => browserHistory.push(homePathGenerator(`music/${music.id}`))}>
                <div
                  style={{
                    float: "left",
                    position: "relative",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {version.title}
                </div>
              </div>
            </div>

            <div className="track-table-sound-wave-container">
              <div
                className="track-table-sound-wave"
                style={{
                  backgroundImage:
                    version.audio && version.audio.waveform
                      ? `url(${`${process.env.REACT_APP_CDN_HOST}/files/images/${
                        version.audio.waveform.id
                      }/crop/300x50/waveform.png`})`
                      : `url(${SoundWave})`,
                }}
              />
            </div>

            <div className="track-table-tags">
              {music.tags && music.tags.length > 0 &&
                <div className="track-table-tags-link-container">
                  {music.tags
                    .map(tag => <div className="track-table-tags-link">{tag.name}</div>)
                    .slice(0, 5)
                  }
                </div>
              }
            </div>

            <div className="track-table-duration">{version.audio ? secondsToMMSS(version.audio.duration) : "--:--"}</div>

            <div className="track-table-action-buttons">
              {this.state.innerWidth > 600 &&
                <Tooltip placement="top" title={localization.get('download.demo')}>
                  <CloudDownload
                    onClick={() => this.openDownloadDialog(version, album)}
                    className="track-table-action-icon"
                  />
                </Tooltip>
              }

              {this.state.innerWidth < 600 && this.state.playing === version.id &&
                <Pause onClick={() => this.handlePauseButtonPress()} className="track-table-action-icon"/>
              }

              {this.state.innerWidth < 600 && this.state.playing !== version.id &&
                <Play onClick={this.handlePlayButtonPress(version, album, music)} className="track-table-action-icon"/>
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default TrackTableSubComponent;
