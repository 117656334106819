import React, {Component} from "react";
import {Add} from "@material-ui/icons";
import localization from "../../../config/localization";
import {
  Button,
  Dialog,
  DialogTitle,
  Tooltip
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "./Table";
import FormController from "./Form/FormHandler";

class LiveStreams extends Component {
  state = {
    loaded: true,
    value: 'scheduled',
    showForm: false,
    create: '',
    livestreamId: '',
  }

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  showCreateLiveStreamForm = () => {
    return (
      <FormController {...this.props} livestreamId={this.state.livestreamId} create={this.state.create} exitForm={() => this.setState({showForm: false})}/>
    )
  }

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
    this.setState({showForm: false})
  }

  render = () => (
    <Dialog
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>
        <span>
          {localization.get('livestreams')}
        </span>
        {!this.state.showForm &&
        <Button
          onClick={() => this.setState({showForm: true, create: true})}
          style={{height: 35, width: 35, backgroundColor: "#9d4d5c", left: 15}}
          variant="fab" color="primary" aria-label="Add"
        >
          <Tooltip
            title={localization.get('livestreams.new')}
            placement='top'>
            <Add/>
          </Tooltip>
        </Button>
        }
      </DialogTitle>
      {!this.state.showForm ?
      <Paper>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          fullWidth
          indicatorColor="secondary"
          textColor="#F2000000"
          centered
        >
          <Tab value="scheduled" label={localization.get('livestreams.scheduled')}/>
          <Tab value="finished" label={localization.get('livestreams.finished')}/>
        </Tabs>
        <Table value={this.state.value} channelId={this.props.channelId} channel={this.props.channel}
               onEdit={(livestreamId) => this.setState({showForm: true, create: false, livestreamId})}/>

      </Paper>
        :
        this.showCreateLiveStreamForm()
      }
    </Dialog>
  );
}

export default LiveStreams;
