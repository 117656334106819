import React from "react";
import classNames from "classnames";
import localization from "../../../config/localization";
import "./BloomcloudsFormHelpers.css";
import EditorialEditor from "../RichTextEditor/EditorialEditor";

export const FormContainer = ({ children, errors, touched, onChange, noMargin}) => (
  <div style={{ width: "100%", margin: "auto", fontSize: '12px', marginTop: noMargin?'0px':'40px', display: 'flex', flexDirection: 'column', background: 'white', border: '1px solid #AAAAAA', borderRadius: '10px', padding: '20px 30px'}}>
    {React.Children.map(children, child =>
      child && React.cloneElement(child, { errors, touched, onChange })
    )}
  </div>
);

export const SectionTitle = ({ children }) => (
  <h4 className="bloomclouds-h4">{children}</h4>
);

export const FieldTitle = ({ children }) => (
  <div style={{ color: "#808080", marginTop: 16, lineHeight: 0.5 }}>
    {children}
  </div>
);

export const DoubleInputContainer = ({ children, errors, touched, onChange }) => (
  <div style={{ display: 'grid', gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)', gridColumnGap: "20px" }}>
    {React.Children.map(children, child =>
      React.cloneElement(child, { errors, touched, onChange })
    )}
  </div>
);

export const InputContainer = ({ children, errors, touched, onChange }) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    {React.Children.map(children, child =>
      React.cloneElement(child, { errors, touched, onChange })
    )}
  </div>
);

export const TranslateInput = ({
  field,
  errors,
  touched,
  type,
  placeholder,
  onChange,
  value,
  label
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <div style={{ color: "#808080", marginTop: 16, lineHeight: 0.5 }}>{label}</div>
    <p>
      <input
        className={classNames("bloomclouds-input", {
          "bloomclouds-error": Boolean(touched[field] && errors[field])
        })}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(field, e.target.value)}
      />
      {touched[field] && errors[field] && (
        <label className="bloomclouds-error">{errors[field]}</label>
      )}
    </p>
  </div>
);

export const TranslateEditor = ({
  field,
  errors,
  touched,
  onChange,
  value,
  label,
  isEditor=true
}) => (
  <div>
    <div style={{ color: "#808080", marginTop: 16, lineHeight: 0.5 }}>{label}</div>
    <EditorialEditor
      bloomCloudsView
      toolbarOptions={['inline', 'blockType', 'list', 'link', 'remove', 'history']}
      editorState={value}
      onChange={onChange}
    />
  </div>
);

export const Input = ({
  field,
  errors,
  touched,
  type,
  placeholder,
  onChange,
  value,
  autocomplete
}) => (
  <p>
    <input
      className={classNames("bloomclouds-input", {
        "bloomclouds-error": Boolean(touched[field] && errors[field])
      })}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(field, e.target.value)}
      autoComplete={autocomplete ? autocomplete : 'on'}
    />
    {touched[field] && errors[field] && (
      <label className="bloomclouds-error">{errors[field]}</label>
    )}
  </p>
);

export const Checkbox = ({
  field,
  errors,
  touched,
  onChange,
  label,
  style = {}
}) => (
  <p style={style}>
    <input
      className={classNames("bloomclouds-input", {
        "bloomclouds-error": Boolean(touched[field] && errors[field])
      })}
      type="checkbox"
      onChange={e => onChange(field, e.target.value)}
      style={{width: "auto"}}
    />
    {label && (
      <label>{label}</label>
    )}
    {touched[field] && errors[field] && (
      <label className="bloomclouds-error">{errors[field]}</label>
    )}
  </p>
);

export const Select = ({
  errors,
  touched,
  onChange,
  field,
  placeholder,
  options,
  value
}) => (
  <p>
    <select
      className={classNames("bloomclouds-select", {
        "bloomclouds-error": Boolean(touched[field] && errors[field])
      })}
      style={{
        backgroundColor: "#fff"
      }}
      value={value}
      onChange={e => onChange(field, e.target.value)}
    >
      <option value={""}>{placeholder}</option>
      {options.map(o => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
    {touched[field] && errors[field] && (
      <label className="bloomclouds-error">{errors[field]}</label>
    )}
  </p>
);

export const TextArea = ({
  errors,
  touched,
  onChange,
  field,
  type,
  placeholder
}) => (
  <p>
    <textarea
      className={classNames("bloomclouds-textarea", {
        "bloomclouds-error": Boolean(touched[field] && errors[field])
      })}
      type={type}
      placeholder={placeholder}
      rows={5}
      onChange={e => onChange(field, e.target.value)}
    />
    {touched[field] && errors[field] && (
      <label className="bloomclouds-error">{errors[field]}</label>
    )}
  </p>
);

export const SubmitButton = ({ onClick, disabled, hasErrors, text, onCancel }) => {
  return (
    <>
      {/** Button */}
      <p className="bloomclouds-submit-container">
        {onCancel && 
          <div
            className="bloomclouds-cancel"
            onClick={() => {
              if (!disabled) {
                onCancel();
              }
            }}
          >
            {localization.get("cancel")}
          </div>
        }
        <div
          className="bloomclouds-submit"
          onClick={() => {
            if (!disabled) {
              onClick();
            }
          }}
        >
          {text ? text : localization.get("form.save")}
        </div>
      </p>
      {/** Error Message */}
      {hasErrors && (
        <div style={{ marginTop: "1rem", color: "red", textAlign: 'center'}}>
          {localization.get("form.errors_present")}
        </div>
      )}
    </>
  );
};
