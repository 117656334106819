import React, {Component} from "react";
import CreativeTimSnackbar from "components/Snackbar/Snackbar.jsx";
import {AddAlert} from "@material-ui/icons"
import EventManager, {events} from "../../../utils/EventManager";
import localization from "../../../config/localization";

export const GlobalSnackbarTypes = {
  INFO: 0,
  SUCCESS: 1,
  ERROR: 2,
}

const defaultTopDistance = 90

class GlobalSnackbar extends Component {

  /**
   * @param {Object} data
   * @param {string} data.message
   * @param {number} data.type
   * @param {number} data.topDistance
   */
  static show = data => {
    EventManager.getInstance().dispatch(events.SHOW_SNACKBAR, data)
  }

  static showGenericSuccess = () => {
    EventManager.getInstance().dispatch(events.SHOW_SNACKBAR, {
      message: localization.get('snackbar.generic_success'),
      type: GlobalSnackbarTypes.SUCCESS,
    })
  }

  static showGenericError = () => {
    EventManager.getInstance().dispatch(events.SHOW_SNACKBAR, {
      message: localization.get('snackbar.generic_error'),
      type: GlobalSnackbarTypes.ERROR,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      message: '',
      type: GlobalSnackbarTypes.INFO,
      topDistance: defaultTopDistance,
      showing: false,
    }
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.SHOW_SNACKBAR, this.show)
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SHOW_SNACKBAR, this.show)
    clearTimeout(this.timeoutId)
  }

  show = data => {
    const { message, type, topDistance } = data
    this.setState({
      message,
      type: type || GlobalSnackbarTypes.INFO,
      topDistance: topDistance || defaultTopDistance,
      showing: true
    })
    clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(this.hide, 3000);
  }

  hide = () => {
    this.setState({ showing: false })
    clearTimeout(this.timeoutId)
  }

  render = () => {

    const {
      message,
      type,
      showing,
      topDistance,
    } = this.state

    const mapTypeToColor = (type) => {
      switch (type) {
        case GlobalSnackbarTypes.ERROR:
          return 'danger'
        case GlobalSnackbarTypes.SUCCESS:
          return 'success'
        default:
          return 'info'
      }
    }

    return <CreativeTimSnackbar
      message={message}
      icon={AddAlert}
      color={mapTypeToColor(type)}
      place="tr"
      closeNotification={this.hide}
      open={showing}
      snackbarStyle={{ top: topDistance + 'px', zIndex: '99999' }}
      close
    />
  }
}

export default GlobalSnackbar