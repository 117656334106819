import React from "react"
import AddToPlaylistDialog from "../../../layouts/Home/HomeElements/AddToPlaylistDialog";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import localization from "../../../config/localization";
import EventManager, {events} from "../../../utils/EventManager";

class GlobalAddToPlaylistDialog extends React.Component {

  static show = data => {
    EventManager.getInstance().dispatch(events.OPEN_ADD_TO_PLAYLIST_DIALOG, data)
  }

  constructor(props) {
    super(props)
    this.state = {
      type: null,
      clientId: null,
      selectedTrack: null,
      showAddToPlaylistDialog: false
    }
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.OPEN_ADD_TO_PLAYLIST_DIALOG, this.show)
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.OPEN_ADD_TO_PLAYLIST_DIALOG, this.show)
  }

  show = data => {
    const { type, clientId, selectedTrack, contentCreatorTagsIds, contentCreatorType } = data
    this.setState({
      type: type + 's',
      contentCreatorType,
      clientId,
      selectedTrack,
      showAddToPlaylistDialog: true,
      contentCreatorTagsIds,
    })
  }

  render() {

    const {type, contentCreatorType, clientId, selectedTrack, showAddToPlaylistDialog, contentCreatorTagsIds} = this.state
    return(
      showAddToPlaylistDialog &&
        <AddToPlaylistDialog
          {...this.props}
          type={type}
          contentCreatorType={contentCreatorType}
          clientId={clientId}
          selectedTrack={selectedTrack}
          handleClose={(state) => {
            this.setState({showAddToPlaylistDialog: false})
            if (state === 'success') {
              GlobalSnackbar.show({
                message: localization.get('snackbar.add_to_playlist_success'),
                type: GlobalSnackbarTypes.SUCCESS
              })
            }
          }}
          contentCreatorTagsIds={contentCreatorTagsIds}
        />
    )
  }
}

export default GlobalAddToPlaylistDialog
