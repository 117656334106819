import React from 'react'
import "./RightManagement.css"
import localization from "../../../config/localization";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Button from "components/CustomButtons/Button.jsx";
import {getActiveClient, getStoredUser, setPageTitle} from "../../../api-client/core/authentication/utils";
import {request} from "../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class RightManagement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      privacy: false,
      sent: false,
      client: props.client
    }
  }

  componentDidMount = () => {
    setPageTitle(localization.get('right_management'))
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  handleSubmit = () => {
    const {title, reference, url, phone, description, duration, territory, budget} = this.state
    request.rightManagement.create({
      title: title,
      reference: reference,
      url: url,
      phone: phone,
      description: description,
      duration: duration,
      territory: territory,
      budget: budget,
      clientId: getActiveClient()
    }).then(() => {
      GlobalSnackbar.show({message: localization.get('right_management.snackbar.sent'), type: GlobalSnackbarTypes.SUCCESS})
      this.setState({sent: true})
    })
  }

  render = () => {
    return (
      <div id="innerinner" style={{ marginBottom: '10px' }}>
        <div className="right-management-container">
          <h2 className="right-management-title">{localization.get('right_management.title')}</h2>
          <h3 className="right-management-subtitle">{localization.get('right_management.subtitle')}</h3>
          <div className="right-management-section">
            <h3>{localization.get('right_management.first_section_title')}</h3>
            <p dangerouslySetInnerHTML={{ __html:localization.get('right_management.first_section_content') }}/>
          </div>
          <div className="right-management-section">
            <h3>{localization.get('right_management.second_section_title')}</h3>
            <p dangerouslySetInnerHTML={{ __html:localization.get('right_management.second_section_content') }}/>
          </div>
          <div className="right-management-section">
            <h3>{localization.get('right_management.third_section_title')}</h3>
            <p dangerouslySetInnerHTML={{ __html:localization.get('right_management.third_section_content') }}/>
          </div>
        </div>
        <div className="right-management-form-container">
          <h2 className="right-management-form-title">{localization.get('right_management.form_title')}</h2>
          {this.state.sent ?
            <h3 style={{marginBottom: "60px"}}>{localization.get('right_management.form.sent')}</h3>
            :
            <ValidatorForm
              onSubmit={this.handleSubmit}
              style={{width: "100%", maxWidth: "600px", display: "inline-block"}}
            >
              <h3>{localization.get('right_management.form.first_title')}</h3>
              <TextValidator
                className="right-management-input"
                id="title"
                label={localization.get('right_management.form.title')}
                name="title"
                value={this.state.title}
                onChange={(e) => this.setState({title: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />

              <TextValidator
                className="right-management-input"
                id="reference"
                label={localization.get('right_management.form.author')}
                name="reference"
                value={this.state.reference}
                onChange={(e) => this.setState({reference: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
                style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}
              />
              <TextValidator
                className="right-management-input"
                id="url"
                label={localization.get('right_management.form.url')}
                name="url"
                value={this.state.url}
                onChange={(e) => this.setState({url: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />

              <h3>{localization.get('right_management.form.second_title')}</h3>
              <TextValidator
                className="original-music-input"
                id="name"
                label={localization.get('right_management.form.firstname') + ' y ' + localization.get('right_management.form.lastname')}
                name="name"
                value={getStoredUser().name}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="email"
                label={localization.get('right_management.form.email')}
                name="email"
                value={getStoredUser().email}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="client"
                label={localization.get('right_management.form.organization')}
                name="client"
                value={this.state.client.name}
                onChange={(e) => this.setState({phone: e.target.value})}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="phone"
                label={localization.get('right_management.form.phone')}
                name="phone"
                value={this.state.phone}
                onChange={(e) => this.setState({phone: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />

              <h3>{localization.get('right_management.form.third_title')}</h3>
              <TextValidator
                className="right-management-input"
                id="description"
                label={localization.get('right_management.form.details')}
                name="description"
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
              <TextValidator
                className="right-management-input"
                id="duration"
                label={localization.get('right_management.form.duration')}
                name="duration"
                value={this.state.duration}
                onChange={(e) => this.setState({duration: e.target.value})}
                validators={['required', 'isNumber']}
                errorMessages={[localization.get('validator.is_required'), localization.get('validator.is_number')]}
              />
              <TextValidator
                className="right-management-input"
                id="territory"
                label={localization.get('right_management.form.territory')}
                name="territory"
                value={this.state.territory}
                onChange={(e) => this.setState({territory: e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
              <TextValidator
                className="right-management-input"
                id="budget"
                label={localization.get('right_management.form.budget')}
                name="budget"
                value={this.state.budget}
                onChange={(e) => this.setState({budget: e.target.value})}
                validators={['required', 'isNumber']}
                errorMessages={[localization.get('validator.is_required'), localization.get('validator.is_number')]}
              />
              <div className="right-management-form-button-container">
                <Button className="right-management-form-button" type="submit">{localization.get('send')}</Button>
              </div>
            </ValidatorForm>
          }
        </div>
      </div>
    )
  }
}

export default RightManagement