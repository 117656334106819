import React, {Component} from 'react'

const RowView = ({ rows }) => {
  return (
    <div style={{ width: "100%", float: "left", position: "relative" }}>
      {rows}
    </div>
  )
}

export default class TrackResult extends Component {
  render = () => <RowView rows={this.props.rows} />
}