import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host, country, options) => {
  let url = `${host}/artists/ranking?include=image;musics_count`
  if (country) {
    url += `&country=${country}`
  }

  return appendPaginationQueryParamsToUrl(url, {...options, alreadyHasQueryParams: true})
}

export default host => (country, options) =>
  get(url(host, country, options))
  .then(response => response.json())
  .then(json => json.meta ? json : json.data)
