import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../api-client";
import localization from "../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import './register.css'
import queryString from 'querystring'
import Footer from "../../layouts/Home/HomeElements/Footer";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import browserHistory from "../../utils/browserHistory";
import {Person} from "@material-ui/icons";
import LoginPage from "../Login";
import {Checkbox, FormControlLabel} from "@material-ui/core";

class Register extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      keepLogged: "keep",
      username: "",
      inputType: "password"
    };
  }

  componentDidMount() {
    document.title = localization.get('title.register') + ' - Feater'
    this.setState({username: queryString.parse(queryString.parse(this.props.location.search)['?url'].split("?")[1])['email'] || ""})
  }

  register = () => {
    console.log(queryString.parse(this.props.location.search)['?url'])
    if(this.state.username && this.state.name && this.state.password && this.state.password === this.state.confirmPassword && this.state.password.length > 5) {
      this.setState({ requesting: true })
      request.authentication.talentUserRegister({url: queryString.parse(this.props.location.search)['?url'], name: this.state.name, email: this.state.username ,password: this.state.password })
        .then(() => {
          this.setState({ requesting: false })
          GlobalSnackbar.show({
            message: localization.get("login.register_complete_public"),
            type: GlobalSnackbarTypes.SUCCESS,
          })

          setTimeout(() => browserHistory.push(`/login`), 1000)
        })
        .catch(e => {
          this.setState({ requesting: false })
          GlobalSnackbar.show({
            message: e.status == 406  ? localization.get('login.channel_already_register') : localization.get('login.login_failed'),
            type: GlobalSnackbarTypes.ERROR,
          })
        })
    }else {
      GlobalSnackbar.show({
        message:  (!this.state.username) ? localization.get('login.error_username_required')
          : !this.state.name ? localization.get('login.error_name_required')
          : !this.state.password ? localization.get('login.error_password_required')
          : this.state.password !== this.state.confirmPassword ? localization.get('login.error_password_not_match')
          : this.state.password.length < 5 ? localization.get('login.error_password_too_short') : "error",
        type: GlobalSnackbarTypes.ERROR,
      })
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PublicNavBar/>
        <div className={classes.content} style={{minHeight: "calc(100vh - 147px)"}}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card register style={{opacity: "1!important"}}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>Activacion</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email"
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.username,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ username: e.target.value })
                        }}
                      />
                      <CustomInput
                        labelText="Name"
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Person className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ name: e.target.value })
                        }}
                      />
                      <CustomInput
                        labelText={localization.get('password')}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ password: e.target.value }),
                          type: this.state.inputType,
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.register()
                            }
                          }
                        }}
                      />
                      <CustomInput
                        labelText={localization.get('repeat_password')}
                        id="confirmPassword"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ confirmPassword: e.target.value }),
                          type: this.state.inputType,
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.register()
                            }
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="inputType"
                            checked={this.state.inputType === "text"}
                            onChange={() => {
                              this.setState({ inputType: this.state.inputType === "text" ? "password" : "text"})
                            }}
                          />
                        }
                        label={localization.get('show_password')}
                      />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.register} color="default" style={{ backgroundColor: "black" }} simple size="lg" block disabled={this.state.requesting}>{localization.get('register')}</Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer noSocials={true}/>
      </React.Fragment>
    )
      ;
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(Register);
