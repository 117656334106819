import React from "react";
import "./OriginalMusic.css";
import localization from "../../../config/localization";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Button from "components/CustomButtons/Button.jsx";

import CocaColaLogo from "assets/img/Letflow/Logos/CocaCola.png";
import JeepLogo from "assets/img/Letflow/Logos/Jeep.png";
import McDonaldsLogo from "assets/img/Letflow/Logos/McDonalds.png";
import ShellLogo from "assets/img/Letflow/Logos/Shell.png";
import MondelezLogo from "assets/img/Letflow/Logos/Mondelez.png";
import MovistarLogo from "assets/img/Letflow/Logos/Movistar.png";
import ToyotaLogo from "assets/img/Letflow/Logos/Toyota.png";
import SantanderLogo from "assets/img/Letflow/Logos/Santander.png";
import UnicefLogo from "assets/img/Letflow/Logos/Unicef.png";
import DirecTVLogo from "assets/img/Letflow/Logos/DirecTV.png";
import NissanLogo from "assets/img/Letflow/Logos/Nissan.png";
import LGLogo from "assets/img/Letflow/Logos/LG.png";
import VolkswagenLogo from "assets/img/Letflow/Logos/Volkswagen.png";
import FordLogo from "assets/img/Letflow/Logos/Ford.png";

import BilbaoSolLogo from "assets/img/Letflow/Awards/BilbaoSol.png";
import CannesLionLogo from "assets/img/Letflow/Awards/CannesLion.png";
import DienteCCALogo from "assets/img/Letflow/Awards/DienteCCA.png";
import FiapLogo from "assets/img/Letflow/Awards/Fiap.png";
import FipLogo from "assets/img/Letflow/Awards/Fip.png";
import LapizDePlatinoLogo from "assets/img/Letflow/Awards/LapizDePlatino.png";
import MartinFierroLogo from "assets/img/Letflow/Awards/MartinFierro.png";
import OjoDeIberoamericaLogo from "assets/img/Letflow/Awards/OjoDeIberoamerica.png";
import WaveLogo from "assets/img/Letflow/Awards/Wave.png";
import FeaturedVideo from "./FeaturedVideo";
import {getActiveClient, getStoredUser, setPageTitle} from "../../../api-client/core/authentication/utils";
import {request} from "../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {VIDEO_TYPES} from "../../../views/VideoConfig/constants";
import {makeImageUrl} from "../../../utils";

class OriginalMusic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: "",
      sent: false,
      videos: [],
      client: props.client
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("original_music"))
    document.getElementsByTagName("html")[0].scrollTop = 0;
    request.featuredVideo.allActiveOfType(VIDEO_TYPES.ORIGINAL_MUSIC).then(videos => this.setState({ videos }));
  };

  handleSubmit = () => {
    const { phone, description, duration, territory, budget } = this.state;
    request.originalMusic
      .create({
        phone: phone,
        description: description,
        duration: duration,
        territory: territory,
        budget: budget,
        clientId: getActiveClient()
      })
      .then(() => {
        GlobalSnackbar.show({ message: localization.get("original_music.snackbar.sent"), type: GlobalSnackbarTypes.SUCCESS });
        this.setState({ sent: true });
      });
  };

  render = () => {
    return (
      <div id="innerinner" style={{ marginBottom: "10px" }}>
        <div className="original-music-container">
          <h2 className="original-music-title">{localization.get("original_music.title")}</h2>
          <div className="original-music-section">
            <h3 dangerouslySetInnerHTML={{ __html: localization.get("original_music.first_section_title") }} />
            <p dangerouslySetInnerHTML={{ __html: localization.get("original_music.first_section_content") }} />
          </div>
          <div className="original-music-section">
            <h3>{localization.get("original_music.second_section_title")}</h3>
            <p dangerouslySetInnerHTML={{ __html: localization.get("original_music.second_section_content") }} />
          </div>
        </div>

        <div className="original-music-videos-container">
          <h3>{localization.get("original_music.videos.title")}</h3>
          {this.state.videos.map(video => (
            <FeaturedVideo key={video.id} videoSrc={video.video.url + '#t=2'} logoSrc={makeImageUrl(video)} title={video.title} subtitle={video.subtitle} />
          ))}
        </div>

        <div className="original-music-brands-container">
          <h3>{localization.get("original_music.brands.title")}</h3>
          <h5>{localization.get("original_music.brands.subtitle")}</h5>
          <div className="original-music-brands-logos-container">
            <div className="original-music-brands-logo">
              <img alt={"CocaColaLogo"} src={CocaColaLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"FordLogo"} src={FordLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"JeepLogo"} src={JeepLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"McDonaldsLogo"} src={McDonaldsLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"ShellLogo"} src={ShellLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"MondelezLogo"} src={MondelezLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"MovistarLogo"} src={MovistarLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"ToyotaLogo"} src={ToyotaLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"SantanderLogo"} src={SantanderLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"UnicefLogo"} src={UnicefLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"DirecTVLogo"} src={DirecTVLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"NissanLogo"} src={NissanLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"LGLogo"} src={LGLogo} />
            </div>
            <div className="original-music-brands-logo">
              <img alt={"VolkswagenLogo"} src={VolkswagenLogo} />
            </div>
          </div>
        </div>

        <div className="original-music-awards-container">
          <h3>{localization.get("original_music.awards.title")}</h3>

          <div className="original-music-awards-logos-container">
            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"CannesLionLogo"} src={CannesLionLogo} />
              </div>
              <h4>Cannes Lion</h4>
              <h6>Gold / Silver / Bronze</h6>
              <h2>8</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"LapizDePlatinoLogo"} src={LapizDePlatinoLogo} />
              </div>
              <h4>Lápiz de Platino</h4>
              <h6>Platinium</h6>
              <h2>2</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"LapizDePlatinoLogo"} src={LapizDePlatinoLogo} />
              </div>
              <h4>Lápiz de Platino</h4>
              <h6>Gold / Silver</h6>
              <h2>9</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"FiapLogo"} src={FiapLogo} />
              </div>
              <h4>FIAP</h4>
              <h6>Gold / Silver / Bronze</h6>
              <h2>8</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"MartinFierroLogo"} src={MartinFierroLogo} />
              </div>
              <h4>Martín Fierro</h4>
              <h6>Nominee for Best Ad</h6>
              <h2>2</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"BilbaoSolLogo"} src={BilbaoSolLogo} />
              </div>
              <h4>Bilbao Sol</h4>
              <h6>Gold / Silver</h6>
              <h2>6</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"DienteCCALogo"} src={DienteCCALogo} />
              </div>
              <h4>Diente CCA</h4>
              <h6>Gold / Silver / Bronze</h6>
              <h2>17</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"WaveLogo"} src={WaveLogo} />
              </div>
              <h4>Wave</h4>
              <h6>Silver</h6>
              <h2>1</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"OjoDeIberoamericaLogo"} src={OjoDeIberoamericaLogo} />
              </div>
              <h4>Ojo de Iberoamerica</h4>
              <h6>Gold / Silver / Bronze</h6>
              <h2>14</h2>
            </div>

            <div className="original-music-award">
              <div className="original-music-awards-logo">
                <img alt={"FipLogo"} src={FipLogo} />
              </div>
              <h4>FIP</h4>
              <h6>Gold / Bronze</h6>
              <h2>2</h2>
            </div>
          </div>
        </div>

        <div className="original-music-form-container">
          <h2 className="original-music-form-title">{localization.get("original_music.form_title")}</h2>

          {this.state.sent ? (
            <h3 style={{ marginBottom: "60px" }}>{localization.get("right_management.form.sent")}</h3>
          ) : (
            <ValidatorForm onSubmit={this.handleSubmit} style={{ width: "100%", maxWidth: "600px", display: "inline-block" }}>
              <h3>{localization.get("right_management.form.second_title")}</h3>
              <TextValidator
                className="original-music-input"
                id="name"
                label={localization.get("right_management.form.firstname") + " y " + localization.get("right_management.form.lastname")}
                name="name"
                value={getStoredUser().name}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="email"
                label={localization.get("right_management.form.email")}
                name="email"
                value={getStoredUser().email}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="client"
                label={localization.get("right_management.form.organization")}
                name="client"
                value={this.state.client.name}
                disabled={true}
              />
              <TextValidator
                className="original-music-input"
                id="phone"
                label={localization.get("right_management.form.phone")}
                name="phone"
                value={this.state.phone}
                onChange={e => this.setState({ phone: e.target.value })}
                validators={["required"]}
                errorMessages={[localization.get("validator.is_required")]}
              />

              <h3>{localization.get("right_management.form.third_title")}</h3>
              <TextValidator
                className="original-music-input"
                id="description"
                label={localization.get("right_management.form.details")}
                name="description"
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
                validators={["required"]}
                errorMessages={[localization.get("validator.is_required")]}
              />
              <TextValidator
                className="original-music-input"
                id="duration"
                label={localization.get("right_management.form.duration")}
                name="duration"
                value={this.state.duration}
                onChange={e => this.setState({ duration: e.target.value })}
                validators={["required", "isNumber"]}
                errorMessages={[localization.get("validator.is_required"), localization.get("validator.is_number")]}
              />
              <TextValidator
                className="original-music-input"
                id="territory"
                label={localization.get("right_management.form.territory")}
                name="territory"
                value={this.state.territory}
                onChange={e => this.setState({ territory: e.target.value })}
                validators={["required"]}
                errorMessages={[localization.get("validator.is_required")]}
              />
              <TextValidator
                className="original-music-input"
                id="budget"
                label={localization.get("right_management.form.budget")}
                name="budget"
                value={this.state.budget}
                onChange={e => this.setState({ budget: e.target.value })}
                validators={["required", "isNumber"]}
                errorMessages={[localization.get("validator.is_required"), localization.get("validator.is_number")]}
              />

              <div className="original-music-form-button-container">
                <Button className="original-music-form-button" type="submit">{localization.get('send')}</Button>
              </div>
            </ValidatorForm>
          )}
        </div>
      </div>
    );
  };
}

export default OriginalMusic;
