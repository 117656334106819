import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {makeUrlWithTableParams} from "../../../utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    id: false,
    type: 'global',
    userLibrary: true,
    image: { src: null, file: null },
    catalogCategories: [],
    catalogCategoryId: 1,
    disabled: false,
    selectedMusics: [],
    visibility: 'public',
    contentType: 'musics',
    selectedClients: [],
    tagGroups: [],
    selectedTags: [],
    unselectedTags: [],
    tempo: '',
    volume: '',
    duration: null,
    loaded: false,
    recent: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      subtitle: "",
    })),
    brands: [],
  }

  requestCategories = () => request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }))
  requestTagGroup = () => request.tagGroup.getAllOfTypeMusic().then((tagGroups) => this.setState({tagGroups}, () => this.setState({loaded: true})))

  requestCreation = () => {

    const { type, catalogCategoryId, image, userLibrary,
      selectedMusics, selectedClients, selectedTags, unselectedTags, tempo,
      duration, volume, translations, brands
    } = this.state

    return request.playlist.createGlobal({
      type,
      translations,
      userLibrary,
      imageFile: image.file,
      catalogCategoryId,
      musicIds: selectedMusics,
      selectedClients: selectedClients.map(client => client.value),
      selectedTags,
      unselectedTags,
      recent: this.state.contentType === "recent",
      query: `${tempo ? `&${tempo.value}` : ''}${volume ? `&${volume.value}` : ''}${duration ? `&audio[duration][]=ge:${duration.min}&audio[duration][]=lt:${duration.max}` : ''}`,
      brands: brands.map(brand => brand.value),
    })
      .then(() => {
        // analyticsInstance.createPlaylist(titleEs)
        this.props.history.push(makeUrlWithTableParams('/panel/global_playlists'))
      })
  }

  onImageChange = image => this.setState({ image })
  onRemovedItemsChanged = items => this.setState({ removedMusics: items })


  render = () => {
    const {
      type,
      disabled,
      catalogCategories,
      translations,
      image,
      userLibrary,
      selectedMusics,
      catalogCategoryId,
      visibility,
      contentType,
      selectedClients,
      tagGroups,
      tempo,
      volume,
      duration,
      id,
      brands,
    } = this.state

    return (
      <div>
        <FormViewContainer
          title={localization.get('playlist.create_playlist')}
          initializationRequest={() => this.requestCategories().then(() => this.requestTagGroup())}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestCreation}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
        >
        {this.state.loaded &&
        <FormView
          type={type}
          translations={this.state.translations}
          onChangeTranslation={(value, target, language) => {
            const translations = this.state.translations.map(tr => {
              if (tr.language === language) {
                return {...tr, [target]: value}
              }
              return tr
            });

            this.setState({ translations });
          }}
          catalogCategories={catalogCategories}
          catalogCategoryId={catalogCategoryId}
          image={image}
          onChange={e => this.setState({ [e.target.id]: e.target.value })}
          onUserLibraryChange={e => this.setState({ userLibrary: e })}
          userLibrary={userLibrary}
          selectedMusics={selectedMusics}
          onSelectedMusicsChanged={selectedMusics => this.setState({ selectedMusics })}
          onImageChange={image => this.setState({ image })}
          disabled={disabled}
          visibility={visibility}
          onVisibilityChange={e => this.setState({visibility: e.target.value, selectedClients: [], catalogCategoryId: 1, brands: []})}
          contentType={contentType}
          onContentTypeChange={e => this.setState({contentType: e.target.value})}
          onAsyncClientsChange={selectedClients => this.setState({selectedClients})}
          selectedClients={selectedClients}
          onSelectedTagChanged={(selectedTags, unselectedTags) => this.setState({selectedTags, unselectedTags})}
          tagGroups={tagGroups}
          tempo={tempo}
          onRemovedItemsChanged={this.onRemovedItemsChanged}
          onTempoChange={e => this.setState({tempo: e.target.value})}
          volume={volume}
          onVolumeChange={e => this.setState({volume: e.target.value})}
          duration={duration}
          onDurationChange={value => this.setState({duration: value})}
          id={id}
          brands={brands}
          onBrandsChange={brands => this.setState({ brands })}
        />
        }
        </FormViewContainer>
      </div>
    )
  }
}
