import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  channelId,
  translations,
  streamId,
  startTime,
  endTime,
  videoId,
  startImageId,
  endImageId,
  fake,
  source,
  contentCreatorId,
  timer,
  showCountdown,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  suggestedView,
  allowedTokens,
  allowedRegions,
}) => {
  return post(`${host}/channels/${channelId}/livestreams`,
    makeFormData({
      translations,
      streamId,
      startTime,
      endTime,
      videoId,
      startImageId,
      endImageId,
      fake,
      source,
      contentCreatorId,
      timer,
      showCountdown,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist,
      suggestedView,
      allowedTokens,
      allowedRegions,
    }))
    .then(response => response.json())
    .then(response => response.data)
}
