import React from "react"
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import Paper from "@material-ui/core/Paper";
import {getMonthName} from "../../utils";

const MonthlyChart = ({title, data, lines, reference, formatter}) =>
  <div className="dashboard-graph">
    {title && <h4 style={{ marginTop: '0px' }}>{title}</h4>}
    <LineChart data={data} width={500} height={250} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3"/>
      <XAxis dataKey="month" tickFormatter={tick => getMonthName(tick).substr(0, 3)} />
      <YAxis allowDecimals={false} />
      <Tooltip labelFormatter={label => getMonthName(label)} formatter={formatter} />
      <Legend wrapperStyle={{fontSize: 'small', marginLeft: '35px'}} />
      {lines.filter(({ hideInChart = false }) => !hideInChart)
        .map(({key, name, color, strokeDasharray}, i) => <Line key={`line-${i}`} type="monotone" dataKey={key} name={name} stroke={color} strokeDasharray={strokeDasharray} />)}
    </LineChart>
    {reference && <Paper className="recharts-legend" style={{ marginLeft: "calc(50% - 125px)", textAlign: 'left', float: "left" }}>{reference}</Paper>}
  </div>

export default MonthlyChart
