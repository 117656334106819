import React from 'react'
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {Close} from '@material-ui/icons'

const HomeBannerElementVideoDialog = ({ videoUrl, onClose }) => <Dialog open={true} maxWidth={false}>
  <DialogContent>
    <video width='100%' controls>
      <source src={videoUrl} type="video/mp4" />
      <source src={videoUrl} type="video/mov" />
      Your browser does not support HTML5 video.
    </video>
  </DialogContent>
  <DialogActions>
    <Button onClick={onClose}><Close /></Button>
  </DialogActions>
</Dialog>

export default HomeBannerElementVideoDialog