import React from "react";
import {TextField, VideoPicker} from "../../../components/Letflow/Form";
import FormTagSelection from "../../../components/Letflow/Form/FormTagSelection";
import localization from "../../../config/localization";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup, Tooltip,
  Typography,
  Tab, Tabs, Card, Paper,
  Select
} from "@material-ui/core";
import {SelectValidator as SelectValidatorOriginal, TextValidator} from 'react-material-ui-form-validator'
import {
  getStoredUser,
  userIsArtist,
} from "../../../api-client/core/authentication/utils";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import AlbumSelection from "./AlbumSelection";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {CloudDownload} from "@material-ui/icons";
import download from "downloadjs";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

const FormView = ({
  disabled,
  active,
  showInRecents,
  id,
  code,
  title,
  audio,
  tagGroups,
  selectedAlbums,
  onChange,
  onActiveChange,
  onAudioChanged,
  onSelectedTagsChanged,
  onMainSelectedTagsChanged,
  onSelectedItemsChanged,
  submitProgress,
  bichannel,
  onBichannelChange,
  onShowInRecentsChange,
  artist,
  owner,
  inactiveReason,
  inactiveReasonDropdown,
  onAsyncArtistChange,
  onAddAudioAiff,
  addAudioAiff,
  audioAiff,
  onAudioAiffChanged,
  waveformId,
  addVideo,
  onAddVideo,
  video,
  onVideoChange,
  albumsRadio,
  onAlbumsRadioChange,
  onSetPreview,
  updateDisableForm,
  disableForm,
  musicUploadRadio,
  onMusicUploadRadioChange,
  onBatchAudioLinkChange,
  selectedBatchAudioLink,
  onBatchAudioFileChange,
  selectedBatchAudioFile,
  client,
  mainTagGroups,
  taggingSystem,
  tagSystem,
}) => {

  return < div >
    {!userIsArtist() && !getStoredUser().artist_view &&
      <FormControlLabel
        control={
          <Checkbox
            style={{color: "#4cc2d5"}}
            checked={active}
            onChange={() => {
              if (active) {
                onChange({target: {id: "inactiveReasonDropdown", value: "Otros"}})
              }
              onActiveChange(!active)
            }}
          />
        }
        label={localization.get('form.active')}
      />
    }
    <br/>
    {!active &&
    <>
      <span>{localization.get('music.inactive_reason')}</span>
      <br/>
      <Select
      style={{position: "relative", float: "left", marginRight: 19}}
      label={localization.get('music.inactive_reason')}
      name={"inactiveReasonDropdown"}
      value={inactiveReasonDropdown}
      onChange={e => {
        onChange({target: {id: "inactiveReasonDropdown", value: e.target.value}})
      }}
    >
      <MenuItem value={'Exclusividad'} key={1}>Exclusividad</MenuItem>
      <MenuItem value={'En Uso'} key={2}>En Uso</MenuItem>
      <MenuItem value={'Sotano'} key={3}>Sotano</MenuItem>
      <MenuItem value={'Observada'} key={3}>Observada</MenuItem>
      <MenuItem value={'Otros'} key={1}>Otros</MenuItem>
    </Select>
    </>
    }
    {!active && inactiveReasonDropdown == 'Otros' &&
      <TextValidator
        style={{ width: "100%", marginBottom: "20px", marginTop: "15px"  }}
        id="inactiveReason"
        label={localization.get('music.inactive_reason')}
        name="inactiveReason"
        value={inactiveReason}
        onChange={onChange}
        disabled={disabled}
      />
    }

    {id && <TextField id="id" label={localization.get('form.code')} value={id} disabled={true} />}

    {!userIsArtist() && !getStoredUser().artist_view &&
      <React.Fragment>
        <TextValidator
          style={{width: "100%"}}
          id="code"
          label={localization.get('music.reference')}
          name="code"
          value={code}
          onChange={onChange}
          disabled={disabled}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
        <br/>
        <br/>
      </React.Fragment>
    }
    <TextValidator
      style={{ width: "100%" }}
      id="title"
      label={localization.get('form.title')}
      name="title"
      value={title}
      onChange={onChange}
      disabled={disabled}
      validators={['required']}
      errorMessages={[localization.get('validator.is_required')]}
    />
    <br />
    <br />

    {!userIsArtist() && !getStoredUser().artist_view &&
      <React.Fragment>
        <FormControl fullWidth>
          <SelectValidatorOriginal
            label={localization.get('form.owner')}
            name={"owner"}
            value={owner}
            onChange={e => {
              e.target.id = 'owner'
              onChange(e)
            }}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          >
            <MenuItem value={'internal'} key={1}>Feater 100%</MenuItem>
            <MenuItem value={'artist'} key={2}>Revshare</MenuItem>
            <MenuItem value={'tmh'} key={3}>TMH</MenuItem>
          </SelectValidatorOriginal>
        </FormControl>
        <br/>
        <br/>
      </React.Fragment>
    }

    {(!userIsArtist() || userIsArtist() && getStoredUser().artists.length > 1) &&
    <FormControl fullWidth>
      <span>{localization.get('form.artist')}</span>
      <AsyncSelect
        styles={{
          menu: base => ({
            ...base,
            zIndex: 10
          })
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0
        })}
        name={"artist"}
        placeholder={localization.get('form.artist')}
        loadingMessage={() => localization.get('loading')}
        noOptionsMessage={() => localization.get('no_options')}
        onChange={onAsyncArtistChange}
        value={artist}
        defaultOptions
        validators={['required']}
        errorMessages={[localization.get('validator.is_required')]}
        loadOptions={inputValue =>
          request.artist
            .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
            .then(res => res.map(artist => ({ value: artist.id, label: `${artist.name} - (${localization.get(artist.type)})` })))
        }
      />
      </FormControl>
    }


    <br/>
    <br/>
    {/* <FormControl fullWidth>
      <span>{localization.get('music.owner_select')}</span>
      <RadioGroup
        id="client"
        name="client"
        value={client || 0}
        onChange={e => onClientChange(e)}
        style={{ width: "100%", float: "left", position: "relative", flexDirection: "row", marginBottom: "-20px"}}
      >
        {availableClients.map(availableClient =>
          <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value={availableClient.value} control={<Radio color="default" />} label={availableClient.label} />
        )}
      </RadioGroup>
    </FormControl> */}



    {!userIsArtist() && !getStoredUser().artist_view &&
    <AlbumSelection
      onSelectedAlbumsChanged={onSelectedItemsChanged}
      artistId={artist && artist.value}
      selectedAlbums={selectedAlbums}
      albumsRadio={albumsRadio}
      onAlbumsRadioChange={onAlbumsRadioChange}
      client={client}
    />
    }

    {!userIsArtist() && !getStoredUser().artist_view &&
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={bichannel}
              onChange={() => {
                onBichannelChange(!bichannel)
              }}
            />
          }
          label={localization.get('form.bichannel')}
        />
      </React.Fragment>
    }

    {!userIsArtist() && !getStoredUser().artist_view &&
      <React.Fragment>
        <br/>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={showInRecents}
              onChange={() => {
                onShowInRecentsChange(!showInRecents)
              }}
            />
          }
          label={localization.get('form.show_in_recents')}
        />
      </React.Fragment>
    }

    <>
      <h4>{localization.get('audio')}</h4>

      <FormControl>
        <RadioGroup
          name="musicUploadRadio"
          value={musicUploadRadio}
          onChange={e => onMusicUploadRadioChange(e)}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_computer')}/>
          <FormControlLabel value="batchUpload" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_platform')}/>
        </RadioGroup>
      </FormControl>

      {musicUploadRadio === 'filepond' ?
      <>
        <div>{localization.get('form.audio_rules')}</div>
        <br />
        {audio.src &&
        <div style={{display: 'flex', marginBottom: 5}}>
          <audio src={audio.src} controls/>
          <span style={{marginLeft: 25, alignSelf: 'center'}}>{audio.file.name}</span>
        </div>
        }
        <FilePondUploader
          disableForm={disableForm}
          updateDisableForm={updateDisableForm}
          file={audio}
          id={'audio'}
          type={"audio"}
          allowedTypes={["audio/*"]}
          onIdChange={onAudioChanged}
          setPreview={onSetPreview}
        />
        <br/>
      </>
        :
        <>
          <FormControl fullWidth>
            <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onBatchAudioLinkChange}
              value={selectedBatchAudioLink}
              loadOptions={inputValue => {
                let filterBy = [{column: "name", filter: inputValue}];

                return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                  .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
              }}
              defaultOptions
            />
          </FormControl>

          {selectedBatchAudioLink &&
          <FormControl style={{marginTop: 20}} fullWidth>
            <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onBatchAudioFileChange}
              value={selectedBatchAudioFile}
              loadOptions={inputValue => {
                let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'audio'}];

                return request.batchUpload.getAllFiles({orderBy: {column: "created_at", type: "DESC"}, filterBy}, selectedBatchAudioLink.token)
                  .then(files => files.map(file => ({value: file.id, label: file.filename})))
              }}
              defaultOptions
            />
          </FormControl>
          }
          <br/>
        </>
      }

    </>

    {waveformId &&
      <div style={{width: "100%", textAlign: "center", margin: "20px 0"}}>
        <img
          style={{filter: "brightness(50%) sepia(100) saturate(00) hue-rotate(25deg)", display: "inlineBlock"}}
          alt="Waveform"
          src={`${process.env.REACT_APP_CDN_HOST}/files/images/${waveformId}/crop/300x50/waveform.png`}
        />
      </div>
    }


      <React.Fragment>
        <br/>
        <FormControlLabel
          control={
            <Checkbox
              style={{color: "#4cc2d5"}}
              checked={addAudioAiff}
              onChange={() => {
                onAddAudioAiff(!addAudioAiff)
              }}
            />
          }
          label={localization.get('form.aiff_checkbox')}
        />
      </React.Fragment>


    {addAudioAiff &&
      <>
        <br />
        <span>{localization.get('form.audio_rule_aiff')}</span>
        <br />
        {audioAiff.src &&
        <div style={{display: 'flex', marginBottom: 5}}>
          <span style={{marginLeft: 25, alignSelf: 'center'}}>{audioAiff.aiffFile.name}</span>
          <Tooltip title={localization.get('download')} placement="top">
            <IconButton
              title={localization.get('download')}
              style={{width: 30, height: 30, backgroundColor:  "#9d4d5c", marginLeft: 15}}
              onClick={() => {
                GlobalSnackbar.show({
                  message: localization.get('downloading'),
                  type: GlobalSnackbarTypes.SUCCESS,
                });

                return fetch(audioAiff.src, { headers: { 'Accept': 'audio/mpeg' } })
                  .then(response => response.arrayBuffer())
                  .then(audio => download(audio, `${audioAiff.aiffFile.name}${audioAiff.src.includes(".wav") ? '.wav' : audioAiff.src.includes(".aiff") ? '.aiff' : '.mp3'}`, 'audio/mpeg'))
              }}
            >
              <CloudDownload style={{color: "white"}}/>
            </IconButton>
          </Tooltip>
        </div>
        }
        <FilePondUploader
          disableForm={disableForm}
          updateDisableForm={updateDisableForm}
          file={audioAiff}
          id={'audioAiff'}
          type={"audio"}
          allowedTypes={["audio/x-aiff", "audio/aiff", ".aiff", "audio/x-wav", "audio/wav"]}
          onIdChange={onAudioAiffChanged}
          setPreview={onSetPreview}
        />
        <br/>
        <br/>
      </>
     }
    <React.Fragment>
      <br/>
      <FormControlLabel
        control={
          <Checkbox
            style={{color: "#4cc2d5"}}
            checked={addVideo}
            onChange={() => {
              onAddVideo(!addVideo)
            }}
          />
        }
        label={localization.get('form.add_videoclip')}
      />
    </React.Fragment>


    {addVideo &&
      <>
        <h4>{localization.get('form.select_video')}</h4>
        {video.src && <VideoPicker video={video.src} previewOnly={true}/>}
        <FilePondUploader
          disableForm={disableForm}
          updateDisableForm={updateDisableForm}
          file={video}
          type={"video"}
          allowedTypes={["video/mp4","video/x-m4v","video/*"]}
          onIdChange={onVideoChange}
          setPreview={onSetPreview}
        />
        <br/>
        <br/>
      </>
    }

    { !userIsArtist() && !getStoredUser().artist_view &&

      <Card style={{overflow: 'visible', marginTop: 10}}>
        <Paper square style={{width: '100%', maxWidth: "100%"}}>
          <Tabs
            value={taggingSystem}
            onChange={(event, value) => onChange({target: {id: "taggingSystem", value: value}})}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab wrapped value="main" label={"Generico"} />
            {tagSystem && <Tab wrapped value="tagSysyem" label={tagSystem.name} />}
          </Tabs>
        </Paper>
        <div style={{position: "relative", float: "left", width: "100%", padding: "0 15px", border: "1px solid #ccc"}}>
          {taggingSystem == "main" ?
            <FormTagSelection
              tagGroups={mainTagGroups}
              onSelectedTagsChanged={onMainSelectedTagsChanged}
              disabled={disabled}
            />
          :
          <FormTagSelection
            tagGroups={tagGroups}
            onSelectedTagsChanged={onSelectedTagsChanged}
            disabled={disabled}
          />
        }
        </div>
        </Card>
    }

    {disabled && <Typography>{localization.get('form.uploading')}: {submitProgress}%</Typography>}

  </div >
}

export default FormView
