import React from "react";

import {TextValidator} from "react-material-ui-form-validator";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@material-ui/core";
import localization from '../../../config/localization'

export const feeCurrencies = {
  ARS: "ARS",
  USD: "USD",
};

feeCurrencies.toString = feeCurrency => {
  switch (feeCurrency) {
    case feeCurrencies.ARS:
      return "ARS";
    case feeCurrencies.USD:
      return "USD";
  }
};

export const feePaymentTypes = {
  CARD: "card",
  TRANSFERENCE: "transference",
};

feePaymentTypes.toString = feePaymentType => {
  switch (feePaymentType) {
    case feePaymentTypes.CARD:
      return "Tarjeta";
    case feePaymentTypes.TRANSFERENCE:
      return "Transferencia";
  }
};

const Form = ({
  disabled,
  name,
  hasFee,
  feeCurrency,
  feeAmount,
  feePaymentType,
  ownChannelsDiscount,
  publicityInternetDiscount,
  publicityRadioDiscount,
  publicityTvDiscount,
  publicityAllDiscount,
  bichannelDiscount,
  durationRadioDiscount,
  durationTvAllDiscount,
  durationInternetDiscount,
  editionDiscount,
  reductionsDiscount,
  ownChannelsReductionsDiscount,
  licenseQuantity,
  onChange,
  licenseType,
  allowFee,
  disallowFee
}) => {
  return (
    <React.Fragment>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        name="name"
        label={localization.get('license_plans.name')}
        value={name}
        validators={["required"]}
        errorMessages={["Campo obligatorio"]}
        disabled={disabled}
        onChange={onChange}
      />
      <br />
      <RadioGroup
        name="licenseType"
        value={licenseType}
        onChange={event => onChange({
            target: {
              id: "license_type",
              value: event.target.value,
            }
          })
        }
        style={{ width: "100%", float: "left", position: "relative", flexDirection: "row", marginTop: 15}}
      >
        <FormControlLabel value="no_licenses" control={<Radio color="default" />} onClick={disallowFee} label={localization.get('table.no_licenses')} />
        <FormControlLabel value="own_channels" control={<Radio color="default" />} onClick={allowFee} label={localization.get('table.only_own_channels')} />
        <FormControlLabel value="other_purpose" control={<Radio color="default" />} onClick={disallowFee} label={localization.get('table.only_other_purpose')} />
        <FormControlLabel value="both" control={<Radio color="default" />} onClick={allowFee} label={localization.get('license_plans.both')} />
      </RadioGroup>
      <br />
      <br />
      {hasFee && (
        <React.Fragment>
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel>{localization.get('license_plans.currency')}</InputLabel>
            <Select
              value={feeCurrency}
              onChange={e => {
                e.target.id = "fee_currency";
                onChange(e);
              }}
            >
              <MenuItem value={feeCurrencies.ARS}>Pesos</MenuItem>
              <MenuItem value={feeCurrencies.USD}>Dolares</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <TextValidator
            style={{ width: "100%" }}
            id="fee_amount"
            name="fee_amount"
            label={localization.get('license_plans.amount')}
            value={feeAmount}
            validators={["minNumber:0"]}
            errorMessages={["Debe ser un numero positivo"]}
            disabled={disabled}
            type="number"
            onChange={onChange}
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel>{localization.get('license_plans.way_to_pay')}</InputLabel>
            <Select
              value={feePaymentType}
              onChange={e => {
                e.target.id = "fee_payment_type";
                onChange(e);
              }}
            >
              <MenuItem value={feePaymentTypes.CARD}>{localization.get('license_plans.card')}</MenuItem>
              <MenuItem value={feePaymentTypes.TRANSFERENCE}>{localization.get('license_plans.transfer')}</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <TextValidator
            style={{ width: "100%" }}
            id="license_quantity"
            name="license_quantity"
            label={localization.get('license_plans.quantity')}
            value={licenseQuantity}
            validators={["minNumber:0"]}
            errorMessages={["Debe ser un numero positivo"]}
            disabled={disabled}
            type="number"
            onChange={onChange}
          />
          <br />
          <br />
        </React.Fragment>
      )}
      {/*<Typography variant="subheading">Descuentos por tipo de licencia</Typography>
      <br />
      <br />
      <DiscountTextValidator
        id="own_channels_discount"
        value={ownChannelsDiscount}
        label={localization.get('license_plans.own_channels_discount')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="publicity_internet_discount"
        value={publicityInternetDiscount}
        label={localization.get('license_plans.publicity_internet_discount')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="publicity_radio_discount"
        value={publicityRadioDiscount}
        label={localization.get('license_plans.publicity_radio_discount')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="publicity_tv_discount"
        value={publicityTvDiscount}
        label={localization.get('license_plans.publicity_tv_discount')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="publicity_all_discount"
        value={publicityAllDiscount}
        label={localization.get('license_plans.publicity_all_discount')}
        disabled={disabled}
        onChange={onChange}
      />
      <br />
      <br />
      <Typography variant="subheading">{localization.get('license_plans.additional_discount')}</Typography>
      <br />
      <br />
      <DiscountTextValidator
        id="bichannel_discount"
        value={bichannelDiscount}
        label={localization.get('license_plans.bichannel')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="edition_discount"
        value={editionDiscount}
        label={localization.get('license_plans.second_edition')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="reductions_discount"
        value={reductionsDiscount}
        label={localization.get('license_plans.publicity_reductions')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="own_channels_reductions_discount"
        value={ownChannelsReductionsDiscount}
        label={localization.get('license_plans.corporates_reductions')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="duration_radio_discount"
        value={durationRadioDiscount}
        label={localization.get('license_plans.validity_radio')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="duration_tv_all_discount"
        value={durationTvAllDiscount}
        label={localization.get('license_plans.validity_tv_all')}
        disabled={disabled}
        onChange={onChange}
      />
      <DiscountTextValidator
        id="duration_internet_discount"
        value={durationInternetDiscount}
        label={localization.get('license_plans.validity_internet')}
        disabled={disabled}
        onChange={onChange}
      />
      <br />
      <br />*/}
    </React.Fragment>
  );
};

const DiscountTextValidator = ({ id, label, value, disabled, onChange }) => (
  <React.Fragment>
    <TextValidator
      style={{ width: "100%" }}
      id={id}
      name={id}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      validators={["minNumber:0", "maxNumber:100"]}
      errorMessages={["Debe ser un valor mayor a 0", "Debe ser un valor menor a 100"]}
      type="number"
    />
    <br />
    <br />
  </React.Fragment>
);

export default Form;
