import React from "react";
import styled from "styled-components";

export default ({ bgImage, bgColor, children, blur=false, gradient=false }) => {
  if (bgColor) {
    return (
      <React.Fragment>
        <ColorBackground bgColor={bgColor} />
        <Front gradient={gradient}>{children}</Front>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Background bgImage={bgImage} blur={blur} />
      <Front gradient>{children}</Front>
    </React.Fragment>
  );
};

const ColorBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: ${props => props.bgColor};
  background-size: cover;
  background-position-x: 50%;
  top: 0;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url(${props => props.bgImage}) no-repeat;
  background-size: cover;
  background-position-x: 50%;
  filter: blur(${props => props.blur ? "8px" : "0px"});
  -webkit-filter: blur(${props => props.blur ? "8px" : "0px"});
  top: 0;
  &::after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: inherit;
    height: inherit;
  }
`;

const Front = styled.div`
  position: relative;
  padding: 0 0rem 1.5rem;
  background: ${props => props.gradient ? "linear-gradient(to bottom, rgba(43,43,43,0.8) 0%, rgba(43, 43, 43, 0.13) 20vh, rgba(76,76,76,0) 100vh)" : ""};
  @media (min-width: 600px) {
    padding: 0 4rem 2rem;
  }
`;
