import React from 'react'
import {request} from '../../api-client';
import {
  Dialog,
  DialogContent,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";
import localization from "../../config/localization"
import {TextValidator} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import FormViewContainer from "../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      catalog: "",
      categories: [],
      contentCreatorTypes: [],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.editingCloud && nextProps.editingCloud.id) {
      this.setState({
        name: nextProps.editingCloud.name,
        catalog: { value: nextProps.editingCloud.catalog.id, label: nextProps.editingCloud.catalog.name, createdBy: nextProps.editingCloud.catalog.createdBy },
        categories: nextProps.editingCloud.contentCreatorTypes.map(c => ({value: c.id, label: c.name})),
      }, () => this.getContentCreatorTypes());
    }
  }


  handleChange = id => () => {
    let selectedCatalogs = this.state.selectedCatalogs
    if (id === 0) {
      if (selectedCatalogs.includes(id)) {
        selectedCatalogs = []
      } else {
        selectedCatalogs = this.state.catalogs.map(client => client.id).concat([0])
      }
    } else {
      if (selectedCatalogs.includes(id)) {
        selectedCatalogs = selectedCatalogs.filter(selectedClient => selectedClient !== id)
      } else {
        selectedCatalogs.push(id)
      }
      selectedCatalogs = selectedCatalogs.filter(catalog => catalog !== 0)
    }
    this.setState({selectedCatalogs})
  }

  handleSubmit = () => {
    const {catalog, name, categories} = this.state

    let req = request.clouds.create;
    const data = { name, catalog_id: catalog.value, content_creator_type_ids: categories.map(c => c.value) }

    if (this.props.editingCloud) {
      req = request.clouds.update;
      data.id = this.props.editingCloud.id;
    }

    return req(data).then(() => this.closeDialog())
  }

  getContentCreatorTypes = () => {

    let filterBy = [
      {column: "visibility", filter: "private"},
      this.state.catalog && {column: "private_client_id", filter: this.state.catalog.createdBy.id}
    ];

    return request.contentCreatorType
      .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
      .then(res => this.setState({
        contentCreatorTypes: res.map(ccType => ({
          value: ccType.id,
          label: `${ccType.name} (${localization.get(!ccType.is_talent ? (ccType.type === "editorial" ? 'editorial' : 'audiovisual') : 'talent')})`
        }))
      }))
  }

  closeDialog = () =>
    this.setState({
      name: "",
      catalog: "",
      categories: [],
    }, this.props.handleClose)


  render = () => {
    const {catalog, name, categories, contentCreatorTypes} = this.state

    return (
      <Dialog open={this.props.show} onBackdropClick={this.closeDialog} onEscapeKeyDown={this.closeDialog}>
        <DialogContent style={{width: 600, maxWidth: "100%", padding: 0}}>
          <FormViewContainer
            title={localization.get(this.props.editingCloud ? 'cloud.update' : 'cloud.create')}
            submitRequest={this.handleSubmit}
            onBack={() => this.closeDialog()}
            validation={() => {
              if (categories.length === 0) {
                GlobalSnackbar.show({
                  message: localization.get('category.required'),
                  type: GlobalSnackbarTypes.ERROR,
                })
                return false;
              }

              return true;
            }}
          >
            <TextValidator
              style={{ width: "100%" }}
              id="name"
              label={localization.get("form.name")}
              name="name"
              value={name}
              onChange={e => this.setState({ name: e.target.value})}
              validators={["required"]}
              errorMessages={[localization.get("validator.is_required")]}
            />
            <br />
            <br />
            <FormControl fullWidth>
              <span>{localization.get('form.select_catalog')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('form.catalogs')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={catalog => this.setState({ catalog, categories: [] }, () => this.getContentCreatorTypes())}
                value={catalog}
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}];
                  return request.catalog
                    .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                    .then(res => res.map(catalog => ({createdBy: catalog.createdBy, value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})` })))
                }}
                defaultOptions
              />
            </FormControl>
            <br />
            <br />
            {!!catalog &&
              <FormControl fullWidth>
                <span>{localization.get('form.select_cloud_categories')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('form.categories')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={categories => this.setState({categories})}
                  value={categories}
                  isMulti
                  loadOptions={(inputValue, callback) => {
                    callback(contentCreatorTypes.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
                  }}
                  defaultOptions={contentCreatorTypes}
                />
              </FormControl>
            }

            <br />
            <br />

            <br />
            <br />

            <br />
            <br />
          </FormViewContainer>
        </DialogContent>
      </Dialog>
    )
  }
}

Form.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default Form
