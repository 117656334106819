import React from 'react'
import {PlayArrow} from '@material-ui/icons'
import localization from '../../../../config/localization';
import GlobalSoundBar from '../../../../sound-bar/GlobalSoundBar';
import TableButton from './TableButton';

export default ({ tracks, playIndex = 0, color = undefined}) => {
  if (!Array.isArray(tracks)) {
    tracks = [tracks]
    playIndex = 0
  }

  return <TableButton title={localization.get('tooltip.play')} style={{color: color}} onClick={() => GlobalSoundBar.loadTracksAndPlay(tracks, playIndex)}><PlayArrow style={{color: color}} /></TableButton>
}