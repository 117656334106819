import React, {Component} from "react";
import Livestream from "../../../layouts/Channel/LiveStream";

export default class extends Component {

  state = {
  };

  render = () => {
    return (
      <div style={{textAlign: "center", alignSelf: "center"}}>
        <Livestream preview channel={{id: this.props.match.params.id}} {...this.props}/>
      </div>
    )
  }
}
