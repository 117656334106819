import Message from "./Message";

export const getMessageFromMaterial = message =>
  JSON.parse(message.content).message;
export const getContentFromMaterial = message =>
  JSON.parse(message.content).content;
export const messageIsMaterial = message => getMaterialType(message) != null;

export const getMaterialType = message => {
  if (!message.type.startsWith("material")) {
    return null;
  }
  return message.type.split(":")[1];
};

export const getMaterialOriginalFileName = materialSrc => {
  const splitSrc = materialSrc.split("/");
  const lastPartOfSource = splitSrc[splitSrc.length - 1];
  const splitLastPartOfSource = lastPartOfSource.split("-");
  return splitLastPartOfSource.slice(1).join("-");
};

export default Message;
