import React, {Component} from "react";
import {ClientSidePaginationTable} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from "../../../config/localization";


class CloudSubcomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    }
  }

  componentDidMount = () => {
    request.user.getAll({filterBy: [{column: "cloud_id", filter: this.props.cloud.id}]})
     .then(response => {
      this.setState({ users: response});
      return response;
    });
  }

  render = () => {
    return (
      <div style={{ margin: '20px' }}>
        {this.state.users.length > 0 &&
          <ClientSidePaginationTable
            data={this.state.users.map((track, index) => ({...track, index}))}
            columns={[
              {
                Header: localization.get("table.name"),
                id: 'name', accessor: "name"
              },
              {
                Header: localization.get("table.email"),
                id: 'email', accessor: "email"
              },
            ]}
            filterable={false}
            sortable={false}
            showPagination={false}
            zebraStyle={false}
          />
        }
      </div>
    )

  }

}

export default CloudSubcomponent