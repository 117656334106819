import React from 'react'
import {Link} from "react-router-dom";
import {Message} from '@material-ui/icons'
import TableButton from './TableButton';
import localization from '../../../../config/localization';

const TableSendMessageButton = ({ usersIds }) => {
  let path = '/conversations/create';

  if (usersIds && usersIds.length > 0) {
    path += `?` + usersIds.map(userId => `user_id=${userId}`).join('&');
  }

  return (
    <Link to={path}>
      <TableButton title={localization.get('tooltip.send_message')}>
        <Message/>
      </TableButton>
    </Link>
  );
}

export default TableSendMessageButton