import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host, id, options) => appendPaginationQueryParamsToUrl(`${host}/contentPacks/${id}?include=channel;image;products.content`, {
  ...options,
  alreadyHasQueryParams: true
})

export default host => (id, options) =>
  get(url(host, id, options))
    .then(response => response.json())
    .then(json => json.data)