import {
  post,
  appendPaginationQueryParamsToUrl
} from '../utils'
import {getActiveClient} from "../authentication/utils";

  const url = (host, options) => appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/reel?`, { ...options,
  alreadyHasQueryParams: true
})

export default host => options =>
  post(url(host, options))
  .then(response => response.json())
