import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import Create from "./Create";
import Update from "./Update";
import All from "./All";

const ProductionsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/demos/content_creations/:id/edit" component={Update} />
        <Route path="/panel/talents/content_creations/:id/edit" component={Update} />
        <Route path="/panel/talents/content_creations/create" component={Create} />
        <Route path="/panel/audiovisual/content_creations/:id/edit" component={Update} />
        <Route path="/panel/audiovisual/content_creations/create" component={Create} />
        <Route
          render={props => (
            <All
              isDemo={window.location.pathname.startsWith("/panel/demos")}
              isTalent={window.location.pathname.startsWith("/panel/talents")}
              isAudiovisual={window.location.pathname.startsWith("/panel/audiovisual")}
              {...props}
            />
          )}
        />
      </Switch>
    </Router>
  )
};

export default ProductionsRouting
