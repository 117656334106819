import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import moment from "moment-with-locales-es6";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  TextField,
  DialogTitle
} from "@material-ui/core";
import Publish from '@material-ui/icons/Publish';
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

let index

class ProductCodesTable extends Component {
  state = {
    channel: this.props.channel,
    copied: localization.get('copy_link'),
    loaded: true,
    openUploadDialog: false,
    disableSubmit: true,
    sending: false,
  };

  componentDidMount() {
    let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));

    if (selectedChannelFromLocalStorage) {
      this.setState({channel: selectedChannelFromLocalStorage});
    }
  }

  columns = () => {

    const columns =
      [
        {
          Header: localization.get("table.code"),
          id: 'code',
          accessor: 'code',
          filterable: true,
          sortable: false,
        },
        {
          Header: localization.get("table.discount"),
          id: 'discount',
          accessor: code => `${code.discount} %`,
          filterable: false,
          sortable: true,
        },
        {
          Header: localization.get("table.uses"),
          id: 'quantity',
          accessor: 'quantity',
          filterable: false,
          sortable: false
        },
        {
          Header: localization.get("table.uses_made"),
          id: 'uses',
          accessor: 'uses',
          filterable: false,
          sortable: false
        },
        {
          Header: localization.get("table.expires_at"),
          id: "expires_at",
          accessor: code => moment(code.expires_at).format('DD/MM/YYYY'),
          filterable: false,
          sortable: true
        },
        {
          Header: localization.get("product"),
          id: "channel",
          accessor: code => code.include_all ? localization.get("table.include_all") : localization.get("table.include_personalized"),
          sortable: false,
          filterable: false
        },
        {
          Header: localization.get('table.actions'),
          id: 'actions',
          sortable: false,
          filterable: false,
          accessor: code => {
            const builder = new TableButtonsBuilder()

            builder.withEdit(makeUrlWithTableParams(`/panel/products/codes/${code.id}/edit`))
            builder.withDelete(code.code, () => request.productCodes.delete(code.id).then(() => index.forceUpdate()))

            return builder.build();
          }
        }
      ]


    return columns;
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  handleCloseDialog = () => {
    if(!this.state.sending) this.setState({openUploadDialog: false, fileId: null, disableSubmit: true})
  }

  onSubmit = () => {
    this.setState({sending: true}, () => {
      request.productCodes.storeMany(this.props.channel.value, {fileId: this.state.fileId})
        .then(() => {
          GlobalSnackbar.show({
            type: GlobalSnackbarTypes.SUCCESS,
            message: localization.get('table.uploaded_codes')
          })
          index.forceUpdate()
          this.setState({sending: false}, this.handleCloseDialog)
      }).catch(() => GlobalSnackbar.showGenericError())
    })
  }

  render = () => {
    const { loaded } = this.state;
    const { channel } = this.props;

    return !loaded ? <div></div> :
      (
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <Dialog open={this.state.openUploadDialog} onBackdropClick={this.handleCloseDialog} onEscapeKeyDown={this.handleCloseDialog}>
            <DialogTitle>{localization.get('table.upload_codes')}</DialogTitle>
            <DialogContent style={{padding: 20, width: 350}}>
              <TextField
                style={{ width: "100%" }}
                label={localization.get('channel')}
                name="channelName"
                value={channel.label}
                disabled={true}
              />
              <br/>
              <br/>
              <label>CSV</label>
              <FilePondUploader
                disableForm={this.state.disableSubmit}
                updateDisableForm={value => this.setState({disableSubmit: value})}
                file={this.state.fileId}
                id={"other"}
                type={"other"}
                allowedTypes={["text/csv"]}
                onIdChange={csv => this.setState({fileId: csv.id})}
                channelId={channel && channel.value}
              />
              <div className="filepond-clarification">{localization.get('code.filepond_clarification')}</div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog} disabled={this.state.sending}>
                {localization.get("cancel")}
              </Button>
              <Button onClick={this.onSubmit} disabled={this.state.sending || this.state.disableSubmit}>
                {localization.get("send")}
              </Button>
            </DialogActions>
          </Dialog>
          {channel &&
            <IconButton
              onClick={() => this.setState({openUploadDialog: true})}
              style={{height: 40, width: 40,position: "absolute", top: -135, right: 10}}
            >
              <Tooltip placement="bottom" title={localization.get("form.code.store_many")}>
                <Publish />
              </Tooltip>
            </IconButton>
          }
          <ServerSidePaginationTable
            ref={r => index = r}
            columns={this.columns()}
            requestTableData={paginationConfig => request.productCodes.getAll(channel.value, paginationConfig)}
          />
        </div>
      )
  }
}


export default ProductCodesTable
