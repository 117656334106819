import React, {Component} from "react";
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem,} from "../../../utils";
import {TryAgainSnackbar} from "../../../components/Letflow/Snackbar";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import moment from "moment";
import {IconButton, Tooltip} from "@material-ui/core";
import browserHistory from "../../../utils/browserHistory";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import AvailableLanguages from "../../../config/localization/availableLanguages.json";


export default class extends Component {
  state = {
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      description: "",
    })),
    subtitle: "",
    active: "1",
    video: { src: null, file: null },
    preRollVideo: { src: null, file: null },
    image: { id: null, src: null, file: null },
    audio: { src: null, file: null },
    contentCreator: "",
    radio: null,
    tagGroups: [],
    videoId: null,
    imageId: null,
    audioId: null,
    binaryId: null,
    link: '',
    disableForm: false,
    videosRadio: 'filepond',
    imagesRadio: 'filepond',
    audiosRadio: 'filepond',
    selectedBatchVideoLink: '',
    selectedBatchVideoFile: '',
    selectedBatchImageLink: '',
    selectedBatchImageFile: '',
    selectedBatchAudioLink: '',
    selectedBatchAudioFile: '',
    subtitles: false,
    spanishSubtitlesId: null,
    englishSubtitlesId: null,
    italianSubtitlesId: null,
    germanSubtitlesId: null,
    frenchSubtitlesId: null,
    spanishSubtitles: false,
    englishSubtitles: false,
    italianSubtitles: false,
    germanSubtitles: false,
    frenchSubtitles: false,
    spanishSubtitlesFile: { src: null, filename: null },
    englishSubtitlesFile: { src: null, filename: null },
    italianSubtitlesFile: {src: null, filename: null},
    germanSubtitlesFile: {src: null, filename: null},
    frenchSubtitlesFile: {src: null, filename: null},
    releaseDate: null,
    expireDate: null,
    hasExpiredDate: false,
    isPermanentContent: "1",
    embedSource: "",
    embedUrl: "",
    downloadable: false,
    unsplashImage: '',
    requireDrm: false,
    requireLogin: false,
    showRequireLoginHelper: false,
    hasPreRoll: false,
    showDRMDialog: false,
    limited: "0",
    viewLimit: 0,
    views: 0,
    timeToTrack: 1,
    allowDownload: false,
    hasRegionBlock: "false",
    allowedRegions: [],
    inappropriateContent: false,
    driveImage: null,
    driveVideo: null,
    driveAudio: null,
    driveBinary: null,
    client: null,
    hasExtraInfo: false,
    extraInformation: "",
    createdAt: null,
    isProtected: false,
    accessLevel: "0",
  };

  requestContentCreation = () =>
    request.contentCreation
      .get(this.props.match.params.id, "include=originalVideo.thumbnail;contentCreator.type.client;translations;image;tags;audio;binary;closedCaptions.binary;embededContent;allowedRegions")
      .then(contentCreation => {
        const translations = AvailableLanguages.map(language => {
          const translation = contentCreation.translations.find(tr => tr.locale ===language)

          return  {
            language: language,
            title: translation ? translation.title: "",
            description: translation ? translation.description: "",
          }
        })

        let selectedTags = contentCreation.tags.map(tag => tag.id);
        let fileSelector = "image"
        if(contentCreation.originalVideo) {
          fileSelector= "video"
        }else if (contentCreation.audio) {
          fileSelector= "audio"
        }else if (contentCreation.binary) {
          fileSelector= "binary"
        }else if (contentCreation.link) {
          fileSelector= "link"
        } else if (contentCreation.embededContent) {
          fileSelector= "embed"
        }
        let spanishSubtitles = null
        let englishSubtitles = null
        let italianSubtitles = null
        let germanSubtitles = null
        let frenchSubtitles = null
        if (contentCreation.closedCaptions.length > 0) {
          spanishSubtitles = contentCreation.closedCaptions.find(subtitle => subtitle.language === 'es')
          englishSubtitles = contentCreation.closedCaptions.find(subtitle => subtitle.language === 'en')
          italianSubtitles = contentCreation.closedCaptions.find(subtitle => subtitle.language === 'it')
          germanSubtitles = contentCreation.closedCaptions.find(subtitle => subtitle.language === 'de')
          frenchSubtitles = contentCreation.closedCaptions.find(subtitle => subtitle.language === 'fr')
        }

        this.setState({
          id: contentCreation.id,
          translations: translations,
          subtitle: contentCreation.subtitle,
          active: contentCreation.active ? "1" : (!!contentCreation.expire_date || !!contentCreation.release_date ? "1" : "0") ,
          video: contentCreation.originalVideo
            ? { src: contentCreation.originalVideo.url, file: null, filename: contentCreation.originalVideo.original_name, hls: contentCreation.originalVideo.hls, dash: contentCreation.originalVideo.dash}
            : { src: null, file: null },
          image: contentCreation.image
            ? { id: contentCreation.image.id, src: getUpdateFormImageUrlFromItem(contentCreation), filename: contentCreation.image.original_name }
            : { src: null, file: null },
          audio: contentCreation.audio
            ? { src: contentCreation.audio.url, file: null, filename: contentCreation.audio.original_name }
            : { src: null, file: null },
          binary: contentCreation.binary,
          selectedTags,
          contentCreator: contentCreation.contentCreator,
          allowImage: contentCreation.contentCreator.type.allow_image,
          allowVideo: contentCreation.contentCreator.type.allow_video,
          allowAudio: contentCreation.contentCreator.type.allow_audio,
          allowBinary: contentCreation.contentCreator.type.allow_binary,
          allowLink: contentCreation.contentCreator.type.allow_link,
          allowEmbed: contentCreation.contentCreator.type.allow_embed,
          client: contentCreation.contentCreator.type.client,
          link: contentCreation.link,
          fileSelector: fileSelector,
          subtitles: contentCreation.closedCaptions.length > 0,
          spanishSubtitles: !!spanishSubtitles,
          englishSubtitles: !!englishSubtitles,
          italianSubtitles: !!italianSubtitles,
          germanSubtitles: !!germanSubtitles,
          frenchSubtitles: !!frenchSubtitles,
          spanishSubtitlesFile: spanishSubtitles
            ? { src: spanishSubtitles.binary.url, filename: spanishSubtitles.binary.original_name }
            : { src: null, filename: null },
          englishSubtitlesFile: englishSubtitles
            ? { src: englishSubtitles.binary.url, filename: englishSubtitles.binary.original_name }
            : { src: null, filename: null },
          italianSubtitlesFile: italianSubtitles
            ? { src: italianSubtitles.binary.url, filename: italianSubtitles.binary.original_name }
            : { src: null, filename: null },
          germanSubtitlesFile: germanSubtitles
            ? { src: germanSubtitles.binary.url, filename: germanSubtitles.binary.original_name }
            : { src: null, filename: null },
          frenchSubtitlesFile: frenchSubtitles
            ? { src: frenchSubtitles.binary.url, filename: frenchSubtitles.binary.original_name }
            : { src: null, filename: null },
          releaseDate: !!contentCreation.release_date ?  moment.utc(contentCreation.release_date).local() : null,
          expireDate: !!contentCreation.expire_date ? moment.utc(contentCreation.expire_date).local() : null,
          hasExpiredDate: !!contentCreation.expire_date,
          isPermanentContent: contentCreation.active && !contentCreation.expire_date && !contentCreation.release_date ? "1" : "0",
          embedSource: contentCreation.embededContent && contentCreation.embededContent.source,
          embedUrl: contentCreation.embededContent && contentCreation.embededContent.embed_url,
          downloadable: contentCreation.downloadable,
          requireDrm: contentCreation.require_drm,
          requireLogin: contentCreation.require_login,
          hasPreRoll: contentCreation.has_pre_roll,
          limited: contentCreation.limited ? "1" : "0",
          viewLimit: contentCreation.view_limit,
          timeToTrack: contentCreation.time_to_track,
          allowDownload: contentCreation.allow_download,
          views: contentCreation.views,
          hasRegionBlock: contentCreation.region_restricted ? "true" : "false",
          allowedRegions: contentCreation.allowedRegions || [],
          inappropriateContent: contentCreation.inappropriate_content,
          extraInformation: contentCreation.extra_information,
          hasExtraInfo: !!contentCreation.extra_information,
          createdAt: moment.utc(contentCreation.created_at).local(),
          isProtected: contentCreation.protected,
          accessLevel: `${contentCreation.access_level}`,
        });
      });

  requestUpdate = () => {
      const {
        id,
        translations,
        subtitle,
        video,
        active,
        image,
        audio,
        selectedTags,
        videoId,
        imageId,
        audioId,
        binaryId,
        link,
        selectedBatchVideoFile,
        selectedBatchImageFile,
        selectedBatchAudioFile,
        spanishSubtitlesId,
        englishSubtitlesId,
        italianSubtitlesId,
        germanSubtitlesId,
        frenchSubtitlesId,
        spanishSubtitles,
        englishSubtitles,
        italianSubtitles,
        germanSubtitles,
        frenchSubtitles,
        releaseDate,
        expireDate,
        embedSource,
        embedUrl,
        downloadable,
        onlyArgentina,
        unsplashImage,
        requireDrm,
        requireLogin,
        hasPreRoll,
        limited,
        viewLimit,
        timeToTrack,
        allowDownload,
        allowedRegions,
        hasRegionBlock,
        inappropriateContent,
        driveImage,
        driveVideo,
        driveAudio,
        driveBinary,
        extraInformation,
        createdAt,
        isProtected,
        accessLevel,
      } = this.state;

      const contentCreationData = {
        id,
        subtitle,
        active,
        translations,
        video: video && video.file,
        image: image && image.file,
        audio: audio && audio.file,
        selectedTags,
        videoId,
        imageId,
        audioId,
        binaryId,
        link,
        batchVideoId: selectedBatchVideoFile.value,
        batchImageId: selectedBatchImageFile.value,
        batchAudioId: selectedBatchAudioFile.value,
        spanishSubtitlesId,
        englishSubtitlesId,
        italianSubtitlesId,
        germanSubtitlesId,
        frenchSubtitlesId,
        spanishSubtitles,
        englishSubtitles,
        italianSubtitles,
        germanSubtitles,
        frenchSubtitles,
        releaseDate,
        expireDate,
        embedSource,
        embedUrl,
        downloadable,
        onlyArgentina,
        unsplashImage,
        requireDrm,
        requireLogin,
        hasPreRoll,
        limited,
        viewLimit,
        timeToTrack,
        allowDownload,
        allowedRegions,
        hasRegionBlock,
        inappropriateContent,
        driveImage,
        driveVideo,
        driveAudio,
        driveBinary,
        extraInformation,
        createdAt,
        isProtected,
        accessLevel,
      };

      return request.contentCreation.update(contentCreationData)
        .then(() => this.props.history.goBack())
  }

  requestTags = () => request.tagGroup.getAllPrivate({filterBy: [
    { column: 'type', filter: `${this.state.contentCreator.type.slug}`},
    { column: "creation", filter: 1 },
    { column: "dashboard", filter: 1 }
  ]})
    .then((tagGroups) => this.setState({tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}))

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onVideoChange = video => video ? this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)}) : this.setState({videoId: null, video: {src: null}})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onAudioChange = audio => audio ? this.setState({audioId: audio.id, audio: {src: null}}) : this.setState({audioId: null, audio: {src: null}})
  onBinaryChange = binary => binary ? this.setState({binaryId: binary.id, binary: {src: null, filename: null }, hasBinary: true}) : this.setState({binaryId: null, binary: { src: null, filename: null }})
  onSubtitlesChange = (name, subtitles) => subtitles ? this.setState({[name]: subtitles.id}) : this.setState({[name]: null})

  onSetImageFromUnsplash = (url, name) => this.setState({unsplashImage: {url, name}})

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      this.setState({image : {src: this.state.imagePreview}})
    }
  }

  /*onAudioChange = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let src = URL.createObjectURL(file);
    this.setState({audio: {src, file}})
  };*/

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

  onSubmitValidation = () => {
    if (!this.state.imageId && !this.state.image.src && !this.state.selectedBatchImageFile
      && !this.state.audioId && !this.state.audio.src && !this.state.selectedBatchAudioFile
      && !this.state.videoId && !this.state.video.src && !this.state.selectedBatchVideoFile
      && !this.state.driveAudio && !this.state.driveVideo && !this.state.driveImage
    ) {
      GlobalSnackbar.show({message: localization.get('form.validate_files'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'youtube' && !this.state.embedUrl.includes('youtu.be/')) {
      GlobalSnackbar.show({message: localization.get('error_youtube'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'vimeo' && !this.state.embedUrl.includes('vimeo.com/')) {
      GlobalSnackbar.show({message: localization.get('error_vimeo'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'spotify' && !this.state.embedUrl.includes('open.spotify.com/')) {
      GlobalSnackbar.show({message: localization.get('error_spotify'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }
  onBatchRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'videosRadio') {
      this.setState({selectedBatchVideoLink: '', selectedBatchVideoFile: '', videoId: null, video: {src: null}})
    }
    if (e.target.name === 'imagesRadio') {
      this.setState({selectedBatchImageLink: '', selectedBatchImageFile: '', imageId: null, image: {src: null}, requireLogin: false})
    }
    if (e.target.name === 'audiosRadio') {
      this.setState({selectedBatchAudioLink: '', selectedBatchAudioFile: '', audioId: null})
    }
  }

  onBatchVideoLinkChange = (selectedBatchVideoLink) => {
    this.setState({selectedBatchVideoLink: '', selectedBatchVideoFile: ''},
      () => {
      if(selectedBatchVideoLink.protected) {
        this.setState({
          selectedBatchVideoLink,
          isProtected: 1,
          allowDownload: 0,
        })
      }else {
        this.setState({ selectedBatchVideoLink })
      }
    })
  }

  onBatchImageLinkChange = (selectedBatchImageLink) => {
    this.setState({selectedBatchImageLink: '', selectedBatchImageFile: ''}, () => this.setState({selectedBatchImageLink}))
  }
  onBatchAudioLinkChange = (selectedBatchAudioLink) => {
    this.setState({selectedBatchAudioLink: '', selectedBatchAudioFile: ''}, () => this.setState({selectedBatchAudioLink}))
  }
  onBatchPreRollVideoLinkChange = (selectedBatchPreRollVideoLink) => {
    this.setState({selectedBatchPreRollVideoLink: '', selectedBatchPreRollVideoFile: ''}, () => this.setState({selectedBatchPreRollVideoLink}))
  }

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get("view.content_creation.update")}
          submitRequest={() => this.requestUpdate()}
          onBack={() => this.props.history.goBack()}
          initializationRequest={() => this.requestContentCreation().then(() => this.requestTags())}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
          actionsExtraSpace={true}
        >
          <div style={{position: "absolute", right: 10, top: -40}}>
            <IconButton
              onClick={() => browserHistory.push(`/panel/${this.state.contentCreator.type.is_talent ? 'talents': 'audiovisual'}/content_creators/${this.state.contentCreator.id}/edit`)}
              style={{position: 'relative'}}
            >
              <Tooltip title={localization.get('go_to.content')}>
                <TrendingFlatIcon />
              </Tooltip>
            </IconButton>
          </div>
          <FormContent
            id={this.state.id}
            translations={this.state.translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            subtitle={this.state.subtitle}
            active={this.state.active}
            onChange={this.onChange}
            contentCreator={this.state.contentCreator}
            onActiveChange={(active) => this.setState({active}, () => this.state.active && this.setState({releaseDate: null, expireDate: null}))}
            image={this.state.image}
            video={this.state.video}
            audio={this.state.audio}
            binary={this.state.binary}
            radio={this.state.radio}
            allowAudio={this.state.allowAudio}
            allowImage={this.state.allowImage}
            allowVideo={this.state.allowVideo}
            allowBinary={this.state.allowBinary}
            link={this.state.link}
            tagGroups={this.state.tagGroups}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            onVideoChange={this.onVideoChange}
            onImageChange={this.onImageChange}
            onAudioChange={this.onAudioChange}
            onBinaryChange={this.onBinaryChange}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            videosRadio={this.state.videosRadio}
            imagesRadio={this.state.imagesRadio}
            audiosRadio={this.state.audiosRadio}
            onBatchRadiosChange={this.onBatchRadiosChange}
            isTalent={this.props.location.pathname.includes('talents')}
            selectedBatchVideoLink={this.state.selectedBatchVideoLink}
            selectedBatchVideoFile={this.state.selectedBatchVideoFile}
            onBatchVideoLinkChange={this.onBatchVideoLinkChange}
            onBatchVideoFileChange={selectedBatchVideoFile => this.setState({selectedBatchVideoFile})}
            selectedBatchImageLink={this.state.selectedBatchImageLink}
            selectedBatchImageFile={this.state.selectedBatchImageFile}
            onBatchImageLinkChange={this.onBatchImageLinkChange}
            onBatchImageFileChange={selectedBatchImageFile => this.setState({selectedBatchImageFile})}
            selectedBatchAudioLink={this.state.selectedBatchAudioLink}
            selectedBatchAudioFile={this.state.selectedBatchAudioFile}
            onBatchAudioLinkChange={this.onBatchAudioLinkChange}
            onBatchAudioFileChange={selectedBatchAudioFile => this.setState({selectedBatchAudioFile})}
            fileSelector={this.state.fileSelector}
            onCheckBoxChange={(name, value) => this.setState({[name]: value})}
            onSubtitlesChange={this.onSubtitlesChange}
            subtitles={this.state.subtitles}
            spanishSubtitles={this.state.spanishSubtitles}
            englishSubtitles={this.state.englishSubtitles}
            italianSubtitles={this.state.italianSubtitles}
            germanSubtitles={this.state.germanSubtitles}
            frenchSubtitles={this.state.frenchSubtitles}
            spanishSubtitlesFile={this.state.spanishSubtitlesFile}
            englishSubtitlesFile={this.state.englishSubtitlesFile}
            italianSubtitlesFile={this.state.italianSubtitlesFile}
            germanSubtitlesFile={this.state.germanSubtitlesFile}
            frenchSubtitlesFile={this.state.frenchSubtitlesFile}
            releaseDate={this.state.releaseDate}
            expireDate={this.state.expireDate}
            hasExpiredDate={this.state.hasExpiredDate}
            onHasExpiredDateChange={hasExpiredDate => this.setState({hasExpiredDate}, () => !this.state.hasExpiredDate && this.setState({expireDate: null}))}
            isPermanentContent={this.state.isPermanentContent}
            onIsPermanentContentChange={isPermanentContent => this.setState({isPermanentContent}, () => this.state.isPermanentContent && this.setState({expireDate: null, releaseDate: null}))}
            embedSource={this.state.embedSource}
            embedUrl={this.state.embedUrl}
            downloadable={this.state.downloadable}
            onlyArgentina={this.state.onlyArgentina}
            onChangeOnlyArgentina={value => this.setState({onlyArgentina: value})}
            onSetImageFromUnsplash={this.onSetImageFromUnsplash}
            requireDrm={this.state.requireDrm}
            requireLogin={this.state.requireLogin}
            showRequireLoginHelper={this.state.showRequireLoginHelper}
            updateHelper={() => this.setState({showRequireLoginHelper: !this.state.showRequireLoginHelper})}
            showDRMDialog={this.state.showDRMDialog}
            updateDRMDialog={() => this.setState({showDRMDialog: !this.state.showDRMDialog})}
            unsplashImage={this.state.unsplashImage}
            hasPreRoll={this.state.hasPreRoll}
            limited={this.state.limited}
            viewLimit={this.state.viewLimit}
            views={this.state.views}
            timeToTrack={this.state.timeToTrack}
            onLimitedChange={limited => this.setState({limited: limited}, () => this.setState({mainMaterial: 0, viewLimit: 0, timeToTrack: 1}))}
            allowDownload={this.state.allowDownload}
            hasRegionBlock={this.state.hasRegionBlock}
            onHasRegionBlockChange={hasRegionBlock => {
              if(hasRegionBlock == "false") {
                this.setState({ allowedRegions: [] })
              }
              this.setState({hasRegionBlock})
            }}
            allowedRegions={this.state.allowedRegions}
            onChangeAllowedRegions={allowedRegions => this.setState({allowedRegions})}
            inappropriateContent={this.state.inappropriateContent}
            driveImage={this.state.driveImage}
            driveVideo={this.state.driveVideo}
            driveAudio={this.state.driveAudio}
            driveBinary={this.state.driveBinary}
            onDriveFileChange={(target, id) => this.setState({ [target]: id })}
            client={this.state.client}
            extraInformation={this.state.extraInformation}
            hasExtraInfo={this.state.hasExtraInfo}
            createdAt={this.state.createdAt}
            isProtected={this.state.isProtected}
            onChangeIsProtected={() => this.setState({
              isProtected: !this.state.isProtected,
              allowDownload: 0,
            })}
            accessLevel={this.state.accessLevel}
          />
        </FormViewContainer>
        <TryAgainSnackbar ref="snackbar" />
      </div>
    );
  };
}
