import getAll from './getAll'
import getAllWithoutUser from './getAllWithoutUser'
import del from './delete'
import create from './create'
import createDemo from './createDemo'
import get from './get'
import update from './update'
import getRanking from './getRanking'
import getNewMusics from './getNewMusics'
import searchForClient from './searchForClient'
import getByClient from './getByClient'

export default host => ({
  getAll: getAll(host),
  getAllWithoutUser: getAllWithoutUser(host),
  delete: del(host),
  create: create(host),
  createDemo: createDemo(host),
  get: get(host),
  update: update(host),
  getRanking: getRanking(host),
  getNewMusics: getNewMusics(host),
  searchForClient: searchForClient(host),
  getByClient: getByClient(host),
})
