import React, {Component} from "react";
import InputRange from 'react-input-range'

class AgeInputRange extends Component {

  constructor(props){
    super(props);
    this.state = {
      value: props.value || {min: 20, max: 80}
    }
  }

  render = () => {
    return (
      <InputRange
        step={5}
        minValue={0}
        maxValue={80}
        onChange={value => {
          this.setState({value})
          this.props.onChangeAge(value)
        }}
        onChangeComplete={() => this.props.onChangeCompleteAge()}
        value={this.state.value}
      />
    )
  }
}

export default AgeInputRange