import create from './create'
import getAll from './getAll'
import getAllFiles from './getAllFiles'
import addFile from "./addFile";
import deleteFile from "./deleteFile";
import getPublic from "./getPublic";
import deleteLink from "./deleteLink";
import downloadImage from "./downloadImage";
import get from "./get";
import update from './update';
import updateFile from './updateFile';


export default host => ({
  create: create(host),
  update: update(host),
  get: get(host),
  getPublic: getPublic(host),
  getAll: getAll(host),
  getAllFiles: getAllFiles(host),
  getAllFilesPublic: getAllFiles(host, true),
  addFile: addFile(host),
  deleteFile: deleteFile(host),
  deleteLink: deleteLink(host),
  downloadImage: downloadImage(host),
  updateFile: updateFile(host),
})
