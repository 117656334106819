import getAll from './getAll'
import approve from './approve'
import create from './create'
import del from './delete'
import get from './get'
import audioUrl from './audioUrl'
import download from './download'
import update from './update'
import searchFor from './searchFor'
import searchForClient from './searchForClient'
import forClientWithTags from './forClientWithTags'
import forClientWithTitle from './forClientWithTitle'
import countPendingApproval from './countPendingApproval'
import countPendingApprovalArtist from './countPendingApprovalArtist'
import getAllPendingApproval from './getAllPendingApproval'
import getAllPendingApprovalArtist from './getAllPendingApprovalArtist'
import getAllByAlbumForClient from './getAllByAlbumForClient'
import getAllByPlaylistForClient from './getAllByPlaylistForClient'
import getAllByArtist from './getAllByArtist'
import getAllByArtistPublic from './getAllByArtistPublic'
import getAllFromQuery from './getAllFromQuery'
import downloadDemo from './downloadDemo'
import favorites from './favorites'
import recents from './recents'
import candidates from './candidates'
import getAllWithoutArtist from './getAllWithoutArtist'
import suggestions from './suggestions'
import getRanking from './getRanking'
import getLogicProjects from './getLogicProjects'
import downloadAiff from "./downloadAiff";
import getForClient from "./getForClient";
import getPublic from "./getPublic"
import showByAlbumPublic from "./showByAlbumPublic"

export default host => ({
  approve: approve(host),
  getAll: getAll(host),
  getAllPendingApproval: getAllPendingApproval(host),
  getAllPendingApprovalArtist: getAllPendingApprovalArtist(host),
  getAllByArtist: getAllByArtist(host),
  getAllByArtistPublic: getAllByArtistPublic(host),
  getAllFromQuery: getAllFromQuery(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  audioUrl: audioUrl(host),
  download: download(host),
  downloadAiff: downloadAiff(host),
  downloadDemo: downloadDemo(host),
  update: update(host),
  searchFor: searchFor(host),
  searchForClient: searchForClient(host),
  forClientWithTags: forClientWithTags(host),
  forClientWithTitle: forClientWithTitle(host),
  countPendingApproval: countPendingApproval(host),
  countPendingApprovalArtist: countPendingApprovalArtist(host),
  getAllByAlbumForClient: getAllByAlbumForClient(host),
  getAllByPlaylistForClient: getAllByPlaylistForClient(host),
  favorites: favorites(host),
  recents: recents(host),
  candidates: candidates(host),
  getAllWithoutArtist: getAllWithoutArtist(host),
  suggestions: suggestions(host),
  getRanking: getRanking(host),
  getLogicProjects: getLogicProjects(host),
  getForClient: getForClient(host),
  getPublic: getPublic(host),
  showByAlbumPublic: showByAlbumPublic(host)
})
