import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  trialId,
  state,
  comments,
  }) =>
  post(`${host}/trials/${trialId}`, addPutMethodToForm(makeFormData({
    state,
    comments,
  })))
  .then(response => response.json())