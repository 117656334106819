import React, {Component} from "react";
import PricesByTimeForAllCategories from "./PricesByTimeForAllCategories";
import {Button} from "@material-ui/core";
import {request} from "../../../api-client";
import mapSadaicPricesFromApi, {mapDurationToApiDuration, mapSecondsToApiSeconds} from "./mapSadaicPricesFromApi";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import localization from '../../../config/localization'

class PricingConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: null,
      editing: false
    };
  }

  componentDidMount = () => {
    document.title = localization.get("sadaic_prices") + " - Feater";
    request.license
      .getPrices()
      .then(prices => this.setState({ prices }))
      .catch(e => {
        throw new Error(e);
      });
  };

  render = () => {
    const { editing, prices } = this.state;

    if (prices === null) {
      return null;
    }

    return (
      <div>
        {this.makeEditButton()}
        <SADAICFee
          percentage={this.sadaicFee().surcharge * 100}
          onChange={this.handleSADAICFeeChange}
          editable={editing}
        />
        <PricesByTimeForAllCategories
          prices={mapSadaicPricesFromApi(prices)}
          onChange={this.handlePriceByTimeChange}
          editable={editing}
        />
        <VPNTPricing
          price={parseInt(this.sadaicPriceConfig().category[5].money)}
          onChange={this.handleVpntPriceChange}
          editable={editing}
        />
        <TvInclusionPricing
          price={parseInt(this.sadaicPriceConfig().category[6].money)}
          onChange={this.handleTvInclusionPriceChange}
          editable={editing}
        />
        <ReductionsDiscount
          percentage={parseFloat(this.sadaicPriceConfig().reductions["1-"].surcharge) * 100}
          onChange={this.handleReductionsDiscountChange}
          editable={editing}
        />
        <ThreeOrMoreDiscount
          percentage={parseFloat(this.sadaicPriceConfig().versions["3-6"].surcharge) * 100}
          onChange={this.handleThreeOrMoreChange}
          editable={editing}
        />
      </div>
    );
  };

  makeEditButton = () => {
    const onClick = () => {
      const { prices, editing } = this.state;
      if (editing) {
        request.license
          .updatePrices(prices)
          .then(() => {
            GlobalSnackbar.show({ message: localization.get('pricing.prices_successfully_updated'), type: GlobalSnackbarTypes.SUCCESS });
          })
          .catch(e => {
            GlobalSnackbar.show({ message: e.message, type: GlobalSnackbarTypes.SUCCESS });
          });
      }
      this.setState({ editing: !editing });
    };

    return (
      <Button variant={"contained"} onClick={onClick}>
        {this.state.editing ? localization.get('pricing.save_changes') : localization.get('pricing.update')}
      </Button>
    );
  };

  handleSADAICFeeChange = newPercentage => {
    this.sadaicFee().surcharge = newPercentage / 100;
    this.forceUpdate();
  };

  handlePriceByTimeChange = ({ category, months, seconds, price }) => {
    this.sadaicPriceConfig().category[category].duration[mapDurationToApiDuration(months)].length[
      mapSecondsToApiSeconds(seconds)
    ].money = price;
    this.forceUpdate();
  };

  handleVpntPriceChange = newPrice => {
    this.sadaicPriceConfig().category[5].money = newPrice;
    this.forceUpdate();
  };

  handleTvInclusionPriceChange = newPrice => {
    this.sadaicPriceConfig().category[6].money = newPrice;
    this.forceUpdate();
  };

  handleReductionsDiscountChange = newPercentage => {
    this.sadaicPriceConfig().reductions["1-"].surcharge = newPercentage / 100;
    this.forceUpdate();
  };

  handleThreeOrMoreChange = newPercentage => {
    this.sadaicPriceConfig().versions["3-6"].surcharge = newPercentage / 100;
    this.forceUpdate();
  };

  sadaicPriceConfig = () => this.state.prices.license_type[3];

  sadaicFee = () => this.state.prices.sadaic.fee;
}

const SADAICFee = ({ percentage, onChange, editable }) => {
    return (
      <ElementValue
        style={{ marginTop: "20px" }}
        title={localization.get('sadaic_pricing.fee')}
        subtitle={localization.get('sadaic_pricing.fee_description')}
        value={percentage}
        onChange={v => onChange(keepPercentageInRange(v))}
        symbol={"%"}
        editable={editable}
      />
    );
  };

const VPNTPricing = ({ price, onChange, editable }) => {
  return (
    <ElementValue
      title={localization.get('sadaic_pricing.vpnt')}
      subtitle={localization.get('sadaic_pricing.vpnt_description')}
      value={price}
      onChange={onChange}
      symbol={"$"}
      editable={editable}
    />
  );
};

const TvInclusionPricing = ({ price, onChange, editable }) => {
  return (
    <ElementValue
      title={localization.get('sadaic_pricing.tv_inclusion')}
      subtitle={localization.get('sadaic_pricing.tv_inclusion_description')}
      value={price}
      onChange={onChange}
      symbol={"$"}
      editable={editable}
    />
  );
};

const ReductionsDiscount = ({ percentage, onChange, editable }) => {
  return (
    <ElementValue
      style={{ marginTop: "20px" }}
      title={localization.get('sadaic_pricing.reductions')}
      subtitle={localization.get('sadaic_pricing.reductions_description')}
      value={percentage}
      onChange={v => onChange(keepPercentageInRange(v))}
      symbol={"%"}
      editable={editable}
    />
  );
};

const ThreeOrMoreDiscount = ({ percentage, onChange, editable }) => {
  return (
    <ElementValue
      style={{ marginTop: "20px" }}
      title={localization.get('sadaic_pricing.3_or_more_plan')}
      subtitle={localization.get('sadaic_pricing.3_or_more_plan_description')}
      value={percentage}
      onChange={v => onChange(keepPercentageInRange(v))}
      symbol={"%"}
      editable={editable}
    />
  );
};

const ElementValue = ({ style, title, subtitle, value, onChange, symbol, editable }) => {
  return (
    <div style={style}>
      <div>{title}</div>
      <div style={{ fontSize: "12px" }}>{subtitle}</div>
      <div>
        {symbol} <input type={"number"} value={value} onChange={e => onChange(e.target.value)} disabled={!editable} />
      </div>
    </div>
  );
};

const keepPercentageInRange = percentage => {
  if (percentage < 0) {
    return 0;
  }
  if (percentage > 100) {
    return 100;
  }
  return percentage;
};

export default PricingConfiguration;
