import {post} from '../utils'

export default host => ({ creatorId, selectedTags, allowDownload, namePrefix, files }) =>
  post(`${host}/contentCreator/${creatorId}/contentCreations/storeMany`, makeFrom(
    {selectedTags, allowDownload, namePrefix, files}))
  .then(response => response.json())

const makeFrom = ({ selectedTags, allowDownload, namePrefix, files }) => {
  const form = new FormData();

  if (selectedTags) selectedTags.forEach(x => form.append("tagsIds[]", x));
  if (namePrefix) form.append("name_prefix", namePrefix);

  files.forEach((x, index) => {
    form.append(`filesToUpload[${index}][id]`, x.id)
    form.append(`filesToUpload[${index}][type]`, x.type)
    form.append(`filesToUpload[${index}][name]`, x.name)
  });

  form.append("allow_download", allowDownload ?  1: 0);

  return form;
}