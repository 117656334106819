import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    clientId,
    title,
    subtitle,
    translations,
    description,
    imageFile,
    musicIds,
    voiceIds,
    userLibrary,
    visibility,
    channelId,
    selectedContent,
    orderType,
    allowDownload,
    brands,
    type,
    selectedTags,
    useFilters
  }) =>
  post(`${host}/playlists`, makeFormData({
    clientId,
    title,
    subtitle,
    translations,
    description,
    imageFile,
    musicIds,
    voiceIds,
    userLibrary,
    visibility,
    channelId,
    selectedContent,
    orderType,
    allowDownload,
    brands,
    type,
    selectedTags,
    useFilters
  }))
  .then(response => response.json())