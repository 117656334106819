import {post} from '../utils'

export default host => (playlistId, contentId, comment) =>
  post(`${host}/playlists/${playlistId}/contentCreations/${contentId}/comments`, makeFormData(comment))

const makeFormData = (comment) => {
  const form = new FormData()
  form.append('comment', comment)
  return form
}
