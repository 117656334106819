import React, {Component} from "react";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";

export default class extends Component {

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0;
  };

  render = () => {
    const client = this.props.client;
    return (
      <div  id="innerinner" style={{textAlign: "center"}}>
        <Card className="catalog-client-info">
          {/*<CardHeader>
            {client.image ?
              <img alt="image" src={makeFitImageUrl(client, 133)} width={133} height={75} />
              :
              <h3 style={{marginTop: 10}}>{client.name}</h3>
            }
          </CardHeader>*/}
            <CardBody style={{height: "65vh"}}>
              <div
                dangerouslySetInnerHTML={{
                  __html: client.about
                }}
              />
            </CardBody>
        </Card >
      </div>
    )
  }
}
