import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update"

const TagsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/music/tag_groups/create" component={Create}/>
        <Route path="/panel/music/tag_groups/:id/edit" component={Update}/>
        <Route path="/panel/talents/tag_groups/create" component={Create}/>
        <Route path="/panel/talents/tag_groups/:id/edit" component={Update}/>
        <Route path="/panel/audiovisual/tag_groups/create" component={Create}/>
        <Route path="/panel/audiovisual/tag_groups/:id/edit" component={Update}/>
        <Route path="/panel/editorial/tag_groups/create" component={Create}/>
        <Route path="/panel/editorial/tag_groups/:id/edit" component={Update}/>
        <Route
          render={props => (
            <All
              isMusic={window.location.pathname.startsWith("/panel/music")}
              isTalent={window.location.pathname.startsWith("/panel/talents")}
              isAudiovisual={window.location.pathname.startsWith("/panel/audiovisual")}
              isEditorial={window.location.pathname.startsWith("/panel/editorial")}
              {...props}
            />
          )}
        />
      </Switch>
    </Router>
  )
}

export default TagsRouting