import {addPutMethodToForm, post} from "../utils";

export const makeFormData = ({name}) => {
  const form = new FormData();
  if (name) form.append('filename', name)
  return form
};

export default host => (id, {name}) => {
  return post(`${host}/public/batchUploadFile/${id}`, addPutMethodToForm(makeFormData({name})))
    .then(response => response.json())
    .then(response => response.data)
}
