import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import FilterByClient from "../../../components/Letflow/Table/FilterByClient";
import {request} from '../../../api-client'
import {
  getStoredUser,
  getStoredUserRoleId,
  ROLES,
  ROLES_IMPORTANCE, userCanCreateUser,
  userCanHandleMultipleClients,
  userisMultiClient,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

const requestUsers = paginationConfig => request.user.getAll(paginationConfig)
  .then(excludeCurrentUserFromResult)
  .then(excludeTrialUsersFromResult)
  .then(addCustomPropertiesToUsersInResult)

const excludeCurrentUserFromResult = result => {
  if (showStoreUserIfWhitelisted()) {
    return result
  }
  return ({ ...result, data: result.data.filter(user => user.id !== getStoredUser().id) })
}

const excludeTrialUsersFromResult = result => ({ ...result, data: result.data.filter(user => user.role && user.role.id !== ROLES.trialId) })

const showStoreUserIfWhitelisted = () => {
  let whitelistedEmails = process.env.REACT_APP_WHITELIST_PROFILE_EDITION
  if (!whitelistedEmails) {
    return false
  }
  whitelistedEmails = whitelistedEmails.split(',')
  return whitelistedEmails.find(whitelistedEmail => whitelistedEmail === getStoredUser().email)
}

const addCustomPropertiesToUsersInResult = result => ({
  ...result,
  data: result.data.map(user => ({
    ...user,
    canHandle: ROLES_IMPORTANCE[getStoredUserRoleId()] >= ROLES_IMPORTANCE[user.role.id],
    inactive: !user.active,
  }))
})

class UsersTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showUsers: "all_roles",
      provider: "all",
      requestTableData: this.makeInitialTableDataRequest(),
      availablePerClient: 0,
      total: '',
    }
  }
  makeInitialTableDataRequest = () => paginationConfig => {
    if(this.state.channel) {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "channel_id", filter: this.state.channel.value}])}
    }

    if(this.state.showActivated !== "all") {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "activated", filter: this.state.showActivated}])}
    }

    if (this.state.showUsers === 'channels') {
      paginationConfig = {
        ...paginationConfig,
        filterBy: paginationConfig.filterBy.concat([{column: "role", filter: 'channel_user'}])
      }
    }

    if (this.state.showUsers === 'no_client') {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "no_client", filter: true}])}
    }

    if (this.state.provider !== 'all') {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "provider", filter: this.state.provider}])}
    }

    return requestUsers(paginationConfig)
      .then(result => {
        this.setState({ availablePerClient: FilterByClient.makeAvailablePerClientFromPaginatedResult(result) })
        return result
      })
      .then((response) => {
        this.setState({ total: response.meta.total })
        return response
      })
  }

  componentDidUpdate = (_, prevState) => {
    if (prevState.requestTableData !== this.state.requestTableData) this.table && this.table.forceUpdate()
  }

  render = () => {

    const { requestTableData } = this.state

    return (
      <div>
        { !userisMultiClient() &&
          <>
            <FormControl style={{float: "left", position: "relative"}}>
              <RadioGroup
                  name="showUsers"
                  value={this.state.showUsers}
                  onChange={event => this.setState({ showUsers: event.target.value }, () => this.table.forceUpdate())}
                  style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
              >
                <FormControlLabel value="all_roles" control={<Radio color="default" />} label={localization.get('users.users')} />
                <FormControlLabel value="channels" control={<Radio color="default" />} label={localization.get('users.channels')} />
              </RadioGroup>
            </FormControl>
            <FormControl style={{float: "left", position: "relative", marginLeft: 80}}>
              <RadioGroup
                name="provider"
                value={this.state.provider}
                onChange={event => this.setState({ provider: event.target.value }, () => this.table.forceUpdate())}
                style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
              >
                <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('all')} />
                <FormControlLabel value="default" control={<Radio color="default" />} label={localization.get('form.default')} />
                <FormControlLabel value="miba" control={<Radio color="default" />} label={"MiBA"} />
              </RadioGroup>
            </FormControl>
          </>
        }

        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref={r => this.table = r}
            columns={this.makeTableColumns()}
            requestTableData={requestTableData}
            subComponent={this.subComponent}
          />
        </div>
      </div>
    )
  }

  makeTableColumns = () => {

    const columns = []

    const nameColumn = {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const emailColumn = {
      Header: localization.get('table.email'),
      accessor: "email",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const clientColumn = {
      Header: localization.get('table.client'),
      id: "client",
      accessor: 'clientRoles',
      Cell: ({ value }) => value.map(clientsRole => clientsRole.client && clientsRole.client.name).join(", "),
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.client
            .getAll({
              filterBy: [{
                column: 'name',
                filter
              }]
            })
            .then(clients => clients.map(client => client.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    }

    const roleColumn = {
      Header: localization.get('table.role'),
      id: "role",
      sortable: false,
      accessor: user => (user.role.name === 'singleclient') ? (user.clientRoles.length > 0 ? user.clientRoles[0].role.description : '-') : user.role.description,
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          {userIsSupervisor() && <option value="supervisor">{localization.get('role.supervisor')}</option>}
          {(userIsSupervisor() || userIsSysAdmin()) && <option value="sysadmin">{localization.get('role.sysadmin')}</option>}
          {(userIsSupervisor() || userIsSysAdmin()) && <option value="artist">{localization.get('role.artist')}</option>}
          {(userIsSupervisor() || userIsSysAdmin() || userisMultiClient()) && <option value="multiclient">{localization.get('role.multiclient')}</option>}
          {(userIsSupervisor() || userIsSysAdmin() || userisMultiClient()) && <option value="content_supervisor">{localization.get('role.content_supervisor')}</option>}
          <option value="music_supervisor">{localization.get('role.music_supervisor')}</option>
          <option value="admin">{localization.get('role.admin')}</option>
          <option value="user">{localization.get('role.user')}</option>
          <option value="readonly">{localization.get('role.readonly')}</option>
        </select>,
    }

    const lastLoginColumn = {
      Header: localization.get('table.last_login'),
      id: "last_login_at",
      accessor: user => user.last_login_at ? localization.moment(user.last_login_at).fromNow() : localization.get('never'),
      filterable: false,
      maxWidth: 150,
    }

    const actionsColumn = {
      Header: localization.get('table.actions'), id: 'actions', sortable: false, filterable: false, accessor: user => {
        const builder = new TableButtonsBuilder()
        user.inactive && builder
          .withNotify(user.name, () => request.user.notify(user.id))
        if (!getStoredUser().can_edit_admins && getStoredUserRoleId() === ROLES.multiClientId && user.role.id === ROLES.multiClientId) {
          return builder.build()
        }

        userCanCreateUser() && user.canHandle && builder
          .withEdit(makeUrlWithTableParams(!this.state.isChannel ? `/panel/users/${user.id}/edit` : `/panel/channel_users/${user.id}/edit`))
          .withDelete(user.name, () => request.user.delete(user.id), status => {
            if (status === 'passed') {
              this.table.removeById(user.id)
            }
          })
        return builder.build()
      },
      maxWidth: 150,
      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }


    columns.push(nameColumn);
    columns.push(emailColumn);
    (userCanHandleMultipleClients() && this.state.showUsers !== 'project_invitee') && !this.state.isChannel && columns.push(clientColumn);
    this.state.showUsers !== 'project_invitee' && !this.state.isChannel && columns.push(roleColumn);
    columns.push(lastLoginColumn);
    columns.push(actionsColumn)

    return columns
  }

  subComponent = (row) => {
    let userClientsIds = getStoredUser().clientRoles.map(clientRole => clientRole.client.id)
    return (
      <React.Fragment>
        {!this.state.isChannel ?
          <>
          {row.original.clientRoles.map(clientRole => {
              if (getStoredUserRoleId() === ROLES.supervisorId ||
                getStoredUserRoleId() === ROLES.sysAdminId ||
                userClientsIds.includes(clientRole.client.id)
              )
                return (
                  <div style={{ width: "100%", float: "left", position: "relative", padding: "20px", display:"grid", gridTemplateColumns: "repeat(6, auto)" }}>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>Cliente:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "130px" }}>{clientRole.client.name}</div>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold", marginLeft: "40px" }}>Role:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "200px" }}>{clientRole.role.description}</div>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold", marginLeft: "40px" }}>Puede Licenciar:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "50px" }}>{clientRole.can_license ? 'SI' : 'NO'}</div>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold", marginTop: "10px" }}>Descripción:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", marginTop: "10px", overflow: "hidden", gridColumn: "2 / span 5" }}>{row.original.description ? row.original.description : '-'}</div>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('created_at')}:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "200px" }}>{row.original.created_at.split(' ')[0]}</div>
                  </div>
                )
            })}
            </>
          :
          <>
            {row.original.channels.map(channel => {
              if (getStoredUserRoleId() === ROLES.supervisorId ||
                getStoredUserRoleId() === ROLES.sysAdminId
              )
                return (
                  <div style={{ width: "100%", float: "left", position: "relative", padding: "20px", display:"grid", gridTemplateColumns: "repeat(6, auto)" }}>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>Canal:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "130px" }}>{channel.channel.name}</div>
                    <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('created_at')}:</div>
                    <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "200px" }}>{row.original.created_at.split(' ')[0]}</div>
                  </div>
                )
            })}
          </>
        }
      </React.Fragment>
    )
  }
}

export default UsersTable
