import {request} from "../../../api-client";
import HomeElementGridContainer from "../../Home/HomeElements/HomeElementGridContainer";
import GridItem from "../../Home/HomeElements/GridItem";
import React, {Component} from "react";
import {
  channelCanAddToFavorites, channelPathGenerator,
  getActiveChannel,
  getStoredUser, getStoredChannel, getMainTranslation
} from "../../../api-client/core/authentication/utils";
import TrackTable from "../../../components/Letflow/TrackTable";
import EventManager, {events} from "../../../utils/EventManager";
import localization from "../../../config/localization";
import browserHistory from "../../../utils/browserHistory";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import HomeElementCarousel from "../../Home/HomeElements/HomeElementCarousel";
import HomeElementFeaturedItem from "../../Home/HomeElements/HomeElementFeaturedItem";
import {get} from "lodash";

class Browse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: props.channel,
      homeElement: null,
      favorites: [],
      requesting: false
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    if (channelCanAddToFavorites() && !!getStoredUser()) {
      this.requestBrowseElement(this.props.elementId).then(() => this.requestMyList())
    }else{
      this.requestBrowseElement(this.props.elementId)
    }

    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)

    if (window.location.href.indexOf('?contenido=') > 0 || window.location.href.indexOf('?album=') > 0 || window.location.href.indexOf('?artista=') > 0) {
      const urlParameter = window.location.href.split("?")[1].split('=');
      const content = urlParameter[1].split('-');
      const contentId = content[0]
      content.shift()

      EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
        id: contentId,
        dataType: urlParameter[0],
        dataName: content.toString(),
        origin: this.state.homeElement ? getMainTranslation(this.state.homeElement, "title", true): "",
      });
    }
  }

  requestMyList = () => new Promise(resolve => {
    request.albumChannelFavorite.getAll().then((albumFavorites) => this.setState({ favorites: albumFavorites }))
    request.artistChannelFavorite.getAll().then((artistsFavorites) => this.setState({ artistsFavorites }))
    request.contentCreatorChannelFavorite.getAll().then(contentCreatorsFavorites => this.setState({ contentCreatorsFavorites }))
    resolve()
  })

  addToMyList = ({id, type = 'album'}) => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      let {favorites, speakersFavorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites} = this.state
      switch (type) {
        case 'album':
          favorites.push(id)
          request.albumChannelFavorite.add(id)
          break
        case 'artist':
          artistsFavorites.push(id)
          request.artistChannelFavorite.add(id)
          break
        case 'content_creator':
          contentCreatorsFavorites.push(id)
          request.contentCreatorChannelFavorite.add(id)
          break
        case 'video_content_creation':
          contentCreationsFavorites.push(id)
          request.contentCreationFavorite.add(id)
          break
      }
      //this.setState({
      //  favorites,
      //  speakersFavorites,
      //  artistsFavorites,
      //  playlistFavorites,
      //  contentCreatorsFavorites,
      //  contentCreationsFavorites
      //})
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)
  }

  removeFromMyList = ({id, type = 'album'}) => {
    let {albumChannelFavorites, artistsFavorites, contentCreatorsFavorites, contentCreationsFavorites} = this.state
    switch (type) {
      case 'album':
        if (albumChannelFavorites.indexOf(id) > -1) {
          albumChannelFavorites.splice(albumChannelFavorites.indexOf(id), 1)
          request.albumChannelFavorite.remove(id)
        }
        break
      case 'artist':
        if (artistsFavorites.indexOf(id) > -1) {
          artistsFavorites.splice(artistsFavorites.indexOf(id), 1)
        }
        request.artistChannelFavorite.remove(id)
        break
      case 'content_creator':
        if (contentCreatorsFavorites.indexOf(id) > -1) {
          contentCreatorsFavorites.splice(contentCreatorsFavorites.indexOf(id), 1)
        }
        request.contentCreatorChannelFavorite.remove(id)
        break
      case 'video_content_creation':
        if (contentCreationsFavorites.indexOf(id) > -1) {
          contentCreationsFavorites.splice(contentCreationsFavorites.indexOf(id), 1)
        }
        request.contentCreationFavorite.remove(id)
        break
    }
    //this.setState({ albumChannelFavorites, artistsFavorites, contentCreatorsFavorites, contentCreationsFavorites})
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(!!this.state.homeElement && nextProps.elementId != this.state.homeElement.id && !this.state.requesting) {
      this.requestBrowseElement(nextProps.elementId)
    }
  }

  requestBrowseElement = id =>
    new Promise(resolve => {
      this.setState({requesting: true}, () =>
        request.channel.getElementById(getActiveChannel(), id)
          .then(response => this.setState({homeElement: response, requesting: false}))
      )

      document.getElementsByTagName('html')[0].scrollTop = 0
      resolve()
    })

  renderSearchResultsTableTags = () => {
    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "var(--main-color, white)",
    }}>{children}</div>
    let content;

    let maxPerRow = (this.state.homeElement.columns == 6 || (this.state.homeElement.shape === 'rectangle' && window.innerWidth < 600)) ? 6 : 4;

    switch (this.state.homeElement.view_type) {
      case "grid":
        content =
          <div className="smart-search-result-container">
            <HomeElementGridContainer
              {...this.props}
              alternative={["movie_alternative"].includes(this.state.homeElement.shape) }
              maxPerRow={["movie_alternative", "movie"].includes(this.state.homeElement.shape) ? 1 : this.state.homeElement.columns}
              homeElement={this.makeHomeElementProp(this.state.homeElement.items.data)}
              trackType={this.makeTrackTypeProp()}
              dataType={this.makeDataTypeProp()}
              showSectionTitle={false}
              shape={["movie_alternative", "movie"].includes(this.state.homeElement.shape) ? 'x1' : this.state.homeElement.shape}
              fixedTitle={this.state.homeElement.fixed_title}
              withBackground={this.state.homeElement.with_background}
              movie={["movie_alternative", "movie"].includes(this.state.homeElement.shape)}
              sizes={maxPerRow === 6 ? {
                xs: 6,
                sm: 4,
                md: 3,
                lg: 2,
                xl: 2,
              }
                :
              {
                xs: 12,
                sm: 6,
                md: 3,
                lg: 3,
                xl: 3,
              }}
              showMore={!!this.state.homeElement.rows_to_show}
              rowsToShow={this.state.homeElement.rows_to_show || 3}
              itemComponent={["movie_alternative", "movie"].includes(this.state.homeElement.shape) ? HomeElementFeaturedItem : GridItem}
              home
            />
          </div>
        break;
      case "carousel":
      case "featured":
        content = <HomeElementCarousel
          {...this.props}
          alternative={["movie_alternative"].includes(this.state.homeElement.shape)}
          homeElement={this.makeHomeElementProp(this.state.homeElement.items.data)}
          trackType={this.makeTrackTypeProp()}
          dataType={this.makeDataTypeProp()}
          onlyImage={this.state.homeElement.only_image}
          numbered={this.state.homeElement.numbered}
          shape={this.state.homeElement.shape}
          columns={this.state.homeElement.columns}
          inverted={this.state.homeElement.inverted}
          editorial={this.state.homeElement.editorial}
          editorialInverted={this.state.homeElement.editorial_inverted}
          square={this.state.homeElement.square}
          hasMargin={this.state.homeElement.has_margin}
          spacing={this.state.homeElement.spacing}
          rounded={this.state.homeElement.rounded}
          halfHeight={this.state.homeElement.half_height}
          mobileHalfHeight={this.state.homeElement.mobile_half_height}
          backgroundColor={this.state.homeElement.background_color}
          backgroundStyle={this.state.homeElement.background_style}
          home
        />
        break;
      default:
        content = <div className="smart-search-result-container">
          <HomeElementGridContainer
            {...this.props}
            maxPerRow={this.state.homeElement.shape === "movie" ? 1 : this.state.homeElement.columns}
            homeElement={this.makeHomeElementProp(this.state.homeElement.items.data)}
            trackType={this.makeTrackTypeProp()}
            dataType={this.makeDataTypeProp()}
            showSectionTitle={false}
            shape={this.state.homeElement.shape === "movie" ? 'x1' : this.state.homeElement.shape}
            fixedTitle={this.state.homeElement.fixed_title}
            withBackground={this.state.homeElement.with_background}
            movie={this.state.homeElement.shape === "movie"}
            sizes={maxPerRow === 6 ? {
                xs: 6,
                sm: 4,
                md: 3,
                lg: 2,
                xl: 2,
              }
              :
              {
                xs: 12,
                sm: 6,
                md: 3,
                lg: 3,
                xl: 3,
              }}
            showMore={!!this.state.homeElement.rows_to_show}
            rowsToShow={this.state.homeElement.rows_to_show || 3}
            itemComponent={this.state.homeElement.shape === "movie" ? HomeElementFeaturedItem : GridItem}
            home
          />
        </div>
    }

    return <Container>{content}</Container>
  }

  makeDataTypeProp = () => {
    if(this.state.homeElement.data_source === "channel_playlist") {
      return 'content_creator'
    }else {
      return this.state.homeElement.data_source
    }
  }

  makeTrackTypeProp = () => {
    switch (this.state.homeElement.data_source) {
      case 'user_library':
        return (itemType) => itemType
      default:
        return () => 'music'
    }
  }

  isFavorite = (item) => {
    switch (this.state.homeElement.data_source) {
      case 'artist':
        return this.state.artistsFavorites ? this.state.artistsFavorites.includes(item.id) : false
      case 'channel_playlist':
        return this.state.contentCreatorsFavorites ? this.state.contentCreatorsFavorites.includes(item.id) : false
      case 'content_creator':
        return this.state.contentCreatorsFavorites ? this.state.contentCreatorsFavorites.includes(item.id) : false
      default:
        return this.state.favorites ? this.state.favorites.includes(item.id) : false
    }
  }

  makeHomeElementProp = (contentCreations) => {
    return {
      ...this.state.homeElement,
      items: {
        data: contentCreations.map(item => ({
          ...item,
          title: item.title,
          shortDescription: item.description,
          kind: this.state.homeElement.data_source === "channel_playlist" ? "content_creator" : this.state.homeElement.data_source,
          favorite: this.isFavorite(item)
        }))
      }
    }
  }

  renderTrackTable = () => {
    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "white",
      marginTop: "30px"
    }}>{children}</div>

    let content =
      <div className="smart-search-result-container" style={{padding: "0 50px"}}>
        <TrackTable {...this.props} channel={this.state.channel} type={'music'} tracks={this.state.homeElement.items.data} fromHome={true}/>
      </div>

    return <Container>{content}</Container>
  }

  render = () => {
    return (
      <div id="innerinner" style={{marginBottom: '10px'}}>
        {!!this.state.homeElement &&
          <>
            <h1 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{this.state.homeElement.title}</h1>
            {this.state.homeElement.data_source !== "catalog" ?
              this.renderSearchResultsTableTags() :
              this.renderTrackTable()
            }
          </>
        }
      </div>
    )
  }
}

export default Browse;