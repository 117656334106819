import {
  get
} from "../utils";
import {
  getActiveClient
} from "../authentication/utils";

export default host => (musicId) => {
  let url = `${host}/musics/${musicId}?include=audio:url`;
  let clientId = getActiveClient();
  if (clientId != null) {
    url += `&client_id=${clientId}`
  }
  return get(url)
    .then(response => response.json())
    .then(json => json.data)
    .then(data => data.audio.url)
}