import React from 'react';
import styled from 'styled-components';
import { makeFitImageUrlWithSize } from '../../utils';
import { get } from 'lodash';

const PersonCard = styled.div`
	width: 200px;
	height: 250px;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	transition: transform 0.2s;
	cursor: pointer;
	&:hover {
		transform: scale(1.02);
	}
`;

const PersonImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: ${props => {
		if (props.boundingBox) {
			const x = props.boundingBox.Left * 100;
			const y = props.boundingBox.Top * 100;
			return `${x}% ${y}%`;
		}
		return 'center';
	}};
	background-color: #f0f0f0;
`;

const PersonName = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 8px;
	color: white;
	font-size: 20px;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
	background: linear-gradient(transparent, rgba(0,0,0,0.7));
    `;


const calculateImageStyle = (photo) => {
    const boundingBox = get(photo, 'face_metadata.boundingBox');
			
    if (!boundingBox) {
        return {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        };
    }

    // Obtenemos las dimensiones originales de la imagen
    const originalWidth = get(photo, 'width', 0);
    const originalHeight = get(photo, 'height', 0);

    if (!originalWidth || !originalHeight) {
        return {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        };
    }

    // Calculamos el área de la cara
    const faceWidth = boundingBox.Width * originalWidth;
    const faceHeight = boundingBox.Height * originalHeight;
    const faceLeft = boundingBox.Left * originalWidth;
    const faceTop = boundingBox.Top * originalHeight;

    // Calculamos el factor de escala para que la cara ocupe el 90% del contenedor
    const containerSize = 150;
    const scale = (containerSize * 0.75) / Math.max(faceWidth, faceHeight);

    // Calculamos las dimensiones finales de la imagen
    const scaledWidth = originalWidth * scale;
    const scaledHeight = originalHeight * scale;

    // Calculamos la posición para centrar la cara
    const left = -(faceLeft * scale) + (containerSize - faceWidth * scale) / 1;
    const top = -(faceTop * scale) + (containerSize - faceHeight * scale) / 1;

    return {
        width: `${scaledWidth}px`,
        height: `${scaledHeight}px`,
        transform: `translate(${left}px, ${top}px)`,
    };
};


const PersonItem = ({ person, onClick }) => {
	const boundingBox = get(person, 'mainImage.face_metadata.boundingBox');
	
  
	return (
		<PersonCard onClick={onClick}>
			<PersonImage 
				src={makeFitImageUrlWithSize({image: person.mainImage}, 'xl')} 
				alt={person.name || 'Sin nombre'}
				boundingBox={boundingBox}
                style={calculateImageStyle(person.mainImage)}
			/>
			{person.name && (
				<PersonName>
					{person.name}
				</PersonName>
			)}
		</PersonCard>
	);
};

export default PersonItem;
