import React from 'react'
import {ImagePicker, SelectValidator} from '../../components/Letflow/Form';
import localization from '../../config/localization';
import {TextValidator} from "react-material-ui-form-validator";
import {getStoredUser, userIsArtist} from "../../api-client/core/authentication/utils";
import {request} from "../../api-client";
import AsyncSelect from "react-select/lib/Async";
import {Checkbox, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import SocialLinks from "../../components/Letflow/SocialLinks";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import Unsplash from "../../components/Letflow/Unsplash";
import AvailableLanguages from "../../config/localization/availableLanguages.json";

const FormContent = ({
  name,
  email,
  phone,
  type,
  image,
  profile,
  onChange,
  onAsyncUserChange,
  onImageChange,
  onProfileChange,
  disabled,
  createUser,
  onCreateUserChange,
  userName,
  userEmail,
  selectedUsers,
  socialLinks,
  onSocialLinksChange,
  onAsyncCountriesChange,
  country,
  onSetPreview,
  updateDisableForm,
  disableForm,
  onSetCoverImageFromUnsplash,
  onSetProfileImageFromUnsplash,
  coverImageRadio,
  profileImageRadio,
  onImageRadiosChange,
  translations,
  onChangeTranslation,
}) => {
  const types = [{id: 'internal', name: localization.get('artist.internal_artist')}, {id: "external", name: localization.get('artist.external_artist')}]
  return (
    <div>
      <TextValidator
        style={{ width: "100%", marginBottom: "20px" }}
        id="name"
        label={localization.get('form.name')}
        name="name"
        value={name}
        onChange={onChange}
        disabled={disabled}
        validators={['required']}
        errorMessages={[localization.get('validator.is_required')]}
      />
      { !userIsArtist() && !getStoredUser().artist_view &&
        <SelectValidator
          style={{ width: "100%", marginBottom: "20px" }}
          id='type'
          name="type"
          current={type}
          title={localization.get('form.type')}
          onChange={onChange}
          items={types}
          disabled={disabled}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        />
      }
      <TextValidator
        style={{ width: "100%", marginBottom: "20px" }}
        id="email"
        label={localization.get('form.email')}
        name="email"
        value={email}
        onChange={onChange}
        disabled={disabled}
      />
      <TextValidator
        style={{ width: "100%", marginBottom: "20px" }}
        id="phone"
        label={localization.get('form.phone')}
        name="phone"
        value={phone}
        onChange={onChange}
        disabled={disabled}
      />

      <FormControl fullWidth style={{marginBottom: "10px"}}>
        <span>{localization.get('form.country')}</span>
        <AsyncSelect
          styles={{
            menu: base => ({
              ...base,
              zIndex: 10
            })
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
          })}
          placeholder={localization.get('conversation.filter_users')}
          loadingMessage={() => localization.get('loading')}
          noOptionsMessage={() => localization.get('no_options')}
          onChange={onAsyncCountriesChange}
          value={country}
          defaultOptions
          loadOptions={inputValue =>
            request.country
              .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
              .then(res => res.map(country => ({ value: country.id, label: `${country.name}` })))
          }
          errorMessages={[localization.get("validator.is_required")]}
        />
      </FormControl>

      <TranslatableInputs
        children={AvailableLanguages.map(language => {
          const currentLangTranslation = translations.find(tr => tr.language === language);

          return {
            language: language,
            content: [
              <TextValidator
                id={`description${language}`}
                style={{width: "100%"}}
                label={localization.get('form.description')}
                name={`description${language}`}
                value={currentLangTranslation.description}
                onChange={e => onChangeTranslation(e.target.value, "description", language)}
              />
            ]
          }
        })}
      />
      <SocialLinks socialLinks={socialLinks} onSocialLinksChange={onSocialLinksChange}/>
      <br/>
      <br/>

      <h4>{localization.get('form.select_image_cover')}</h4>
      <FormControl>
        <RadioGroup
          name="coverImageRadio"
          value={coverImageRadio}
          onChange={e => onImageRadiosChange(e)}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
          <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
        </RadioGroup>
      </FormControl>
      {coverImageRadio === 'filepond' ?
        <>
          {image.src && <ImagePicker image={image.src} previewOnly={true} id={image.id}/>}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={image}
            id={"image"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={onImageChange}
            setPreview={onSetPreview}
          />
          <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div>
        </>
        :
          <Unsplash imageAmount={9} onSelectImage={onSetCoverImageFromUnsplash}/>
      }

      <br/>
      <h4>{localization.get('form.select_image_profile')}</h4>
      <FormControl>
        <RadioGroup
          name="profileImageRadio"
          value={profileImageRadio}
          onChange={e => onImageRadiosChange(e)}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
          <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
        </RadioGroup>
      </FormControl>
      <br/>
      {profileImageRadio === 'filepond' ?
        <>
          {profile.src && <ImagePicker image={profile.src} previewOnly={true} id={profile.id}/>}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={profile}
            id={"profile"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={onProfileChange}
            setPreview={onSetPreview}
          />
          <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div>
        </>
        :
        <Unsplash imageAmount={9} onSelectImage={onSetProfileImageFromUnsplash}/>
      }


      <br/>
      <br/>
      {!userIsArtist() && !getStoredUser().artist_view &&
        <React.Fragment>
          <h4>{localization.get('user')}</h4>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={createUser === 1}
                onChange={() => {
                  onCreateUserChange(1);
                }}
              />
            }
            label={localization.get("artist.no_user")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={createUser === 2}
                onChange={() => {
                  onCreateUserChange(2);
                }}
              />
            }
            label={localization.get("user.create_user")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={createUser === 3}
                onChange={() => {
                  onCreateUserChange(3);
                }}
              />
            }
            label={localization.get("user.select_existing")}
          />
          {createUser === 2 ?
            <React.Fragment>
              <TextValidator
                style={{ width: "100%", marginBottom: "20px" }}
                id="userName"
                label={localization.get('form.name')}
                name="userName"
                value={userName}
                onChange={onChange}
                disabled={disabled}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
              <TextValidator
                style={{ width: "100%", marginBottom: "20px" }}
                id="userEmail"
                label={localization.get('form.email')}
                name="userEmail"
                value={userEmail}
                onChange={onChange}
                disabled={disabled}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
            </React.Fragment>
            : createUser === 3  ?
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onAsyncUserChange}
                value={selectedUsers}
                isMulti
                loadOptions={inputValue =>
                  request.user
                    .getArtistCandidates({ filterBy: [{ column: "name", filter: inputValue }] })
                    .then(res => res.map(user => ({ value: user.id, label: `${user.name} - ${user.email}` })))
                }
              />
              :
              <div style={{
                position: "relative",
                width: "100%",
                backgroundColor: "gray",
                boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
                borderRadius: "6px",
                color: "white",
                textAlign: "left",
                lineHeight: "50px",
                verticalAlign: "center",
                paddingLeft: 10
              }}>
                {localization.get('artist.no_user_description')}
              </div>
          }
        </React.Fragment>
      }
    </div>
  )
}

export default FormContent
