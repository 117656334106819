import {
  Button, Checkbox, Dialog,
  DialogTitle, FormControlLabel, TextField
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React from 'react';
import { request } from "../../../../api-client";
import { getStoredUser } from "../../../../api-client/core/authentication/utils";
import localization from "../../../../config/localization";
import { validateEmail } from "../../../../utils";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../Snackbar/GlobalSnackbar";

class AddressDialog extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      emailSubmitted: false
    }
  }

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
  }

  validate = values => {
    const errors = {};

    if (!values.name || values.name.length == 0) {
      errors.name = localization.get("validator.is_required");
    }
    if (!values.address || values.address.length == 0) {
      errors.address = localization.get("validator.is_required");
    }
    if (!values.city || values.city.length == 0) {
      errors.city = localization.get("validator.is_required");
    }
    if (!values.zip_code || values.zip_code.length == 0) {
      errors.zip_code = localization.get("validator.is_required");
    }
    if (!this.props.isDashboard && (!values.phone || values.phone.length == 0)) {
      errors.phone = localization.get("validator.is_required");
    }
    if (this.props.withEmail && (!values.email || values.email.length == 0)) {
      errors.email = localization.get("validator.is_required");
    } else if (this.props.withEmail && !validateEmail(values.email)) {
      errors.email = localization.get("validator.is_email");
    }
    return errors;
  };

  initialValues = () => {
    let address = this.props.address;
    let initialValues = {
      id: null,
      name: '',
      address: '',
      city: '',
      zip_code: '',
      active: true,
      google_maps_link: '',
      phone: '',
      description: '',
      temporaryId: null,
    }
    if (address) {
      initialValues = address;
    }
    if (this.props.withEmail) {
      initialValues['email'] = this.props.email || '';
    }
    return initialValues;
  }

  onSubmit= (values) => {
    this.props.onSubmit(values);
  }

  isMobile = () => window.innerWidth < 500;

  render = () => (
    <Dialog
      disableEnforceFocus
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth='sm'
    >
      <DialogTitle style={{background: this.props.isDashboard ? "white" : "var(--secondary-color, white)"}}>
        <span style={this.props.isDashboard ? {} : {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}>
          {this.props.address ? localization.get("form.address.update") : localization.get("form.address.new")}
        </span>
      </DialogTitle>
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          let submitForm = () => {
            this.onSubmit(values)
            actions.resetForm();
          }
          if (this.props.withEmail && !this.state.emailSubmitted) {
            request.user.update({userId: getStoredUser() && getStoredUser().id, email: values.email})
              .then(() => {
                this.setState({emailSubmitted: true});
                this.props.onEmailChange && this.props.onEmailChange(values.email);
                submitForm();
              }).catch((err) => {
                if (err.status == 422) {
                  GlobalSnackbar.show({message: localization.get("product.email_in_use_error"), type: GlobalSnackbarTypes.ERROR});
                } else {
                  GlobalSnackbar.show({message: localization.get("product.email_not_updated_error"), type: GlobalSnackbarTypes.ERROR});
                }
              })
          } else {
            submitForm();
          }
        }}
      >
        {formikProps => (
          <div style={this.props.isDashboard ? {} : {background: "var(--secondary-color, white)"}}>
            <div style={{float: "left", position: "relative", width: "100%", display: 'flex', justifyContent: 'space-between', flexDirection: "column", padding: "0 24px", }}>
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.name}
                    label={localization.get("form.address.name")+'*'}
                    onChange={e => formikProps.setFieldValue('name', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="name" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.address}
                    label={localization.get("form.address")+'*'}
                    onChange={e => formikProps.setFieldValue('address', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="address" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.city}
                    label={localization.get("form.address.city")+'*'}
                    onChange={e => formikProps.setFieldValue('city', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="city" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.zip_code}
                    label={localization.get("form.address.zip_code")+'*'}
                    onChange={e => formikProps.setFieldValue('zip_code', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="zip_code" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.google_maps_link}
                    label={localization.get("form.address.google_maps_link")}
                    onChange={e => formikProps.setFieldValue('google_maps_link', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="google_maps_link" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div style={{float: "left", position: "relative", width: this.isMobile() ? "100%" : "45%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.phone}
                    label={localization.get("form.address.phone") + (this.props.isDashboard ? '' : '*')}
                    onChange={e => formikProps.setFieldValue('phone', e.target.value)}
                    InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="phone" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              {this.props.withEmail ?
                <div style={{position: "relative", width: "100%", display: 'flex'}}>
                  <div style={{float: "left", position: "relative", width: "100%"}}>
                    <TextField
                      disabled={this.state.emailSubmitted}
                      style={{marginBottom: 10, width: "100%"}}
                      value={formikProps.values.email}
                      label={localization.get("form.email")+'*'}
                      onChange={e => formikProps.setFieldValue('email', e.target.value)}
                      InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                      inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    />
                    <ErrorMessage name="email" className="web-like-error">
                      {msg => <div className="web-like-error font12">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              :
                <div style={{position: "relative", width: "100%", display: 'flex'}}>
                  <div style={{float: "left", position: "relative", width: "100%"}}>
                    <TextField
                      style={{marginBottom: 10, width: "100%"}}
                      value={formikProps.values.description}
                      label={localization.get("form.address.description")}
                      onChange={e => formikProps.setFieldValue('description', e.target.value)}
                      InputLabelProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                      inputProps={this.props.isDashboard ? {} : {style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    />
                    <ErrorMessage name="description" className="web-like-error">
                      {msg => <div className="web-like-error font12">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              }
              {this.props.canDisable && 
                <div style={{float: "left", position: "relative", width: "150px"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={formikProps.values.active}
                        onChange={e => formikProps.setFieldValue('active', e.target.checked)}
                      />
                    }
                    label={localization.get('form.active')}
                  />
                </div>
              }
            </div>
            <div style={{margin: '10px 10px 20px', float: 'right'}}>
              <Button onClick={() => this.handleCloseDialog()} color='default'>{localization.get("cancel")}</Button>
              <Button onClick={() => formikProps.handleSubmit()} color='primary'>{localization.get("form.confirm")}</Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddressDialog;
