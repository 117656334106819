import {LicenseTypeIds, SadaicCategories} from "./constants";
import PriceCalculator from "./PriceCalculator";

class SadaicPriceCalculator {
  constructor(prices, sadaicVersions, sadaicCategory, sadaicDuration) {
    this.prices = prices;
    this.sadaicVersions = sadaicVersions;
    this.sadaicCategory = sadaicCategory;
    this.sadaicDuration = sadaicDuration;
  }

  isVpnt = () => this.sadaicCategory === SadaicCategories.VPNT;

  isTvInclusion = () => this.sadaicCategory === SadaicCategories.INCLUSION_EN_TV;

  getLicenseTypeConfig = () => this.prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA];

  getVpntPrice = () => this.getLicenseTypeConfig().category[SadaicCategories.VPNT].money;

  getTvInclusionPrice = () => this.getLicenseTypeConfig().category[SadaicCategories.INCLUSION_EN_TV].money;

  getPriceForVersion = version =>
    this.getLicenseTypeConfig().category[this.sadaicCategory].duration[this.sadaicDuration].length[version.seconds]
      .money;

  calculate = () => {
    if (this.isVpnt()) {
      return this.getVpntPrice();
    }
    if (this.isTvInclusion()) {
      return this.getTvInclusionPrice();
    }
    return this.calculateSadaicReductionsPrice() + this.calculateSadaicBasePriceWithDiscount();
  };

  getRanges = type => PriceCalculator.makeReductionRangesFrom(this.getLicenseTypeConfig()[type]);

  getReductionRanges = () => this.getRanges("reductions");

  getVersionRanges = () => this.getRanges("versions");

  calculateSadaicReductionsPrice = () =>
    this.sadaicVersions.reduce(
      (accPrice, version) =>
        accPrice +
        PriceCalculator.calculateReductionsPrice(
          this.getPriceForVersion(version),
          version.reductions,
          this.getReductionRanges()
        ),
      0
    );

  calculateSadaicBasePriceWithDiscount = () => {
    const matchRange = this.getVersionRanges().find(
      x => this.sadaicVersions.length >= x.from && this.sadaicVersions.length <= x.to
    );
    return this.calculateSadaicBasePrice() * (matchRange ? matchRange.surcharge : 1);
  };

  calculateSadaicDiscountedBasePriceFromVersions = () => {
    const matchRange = this.getVersionRanges().find(
      x => this.sadaicVersions.length >= x.from && this.sadaicVersions.length <= x.to
    );
    const basePrice = this.calculateSadaicBasePrice();
    return basePrice - basePrice * (matchRange ? matchRange.surcharge : 1);
  };

  calculateSadaicBasePrice = () =>
    this.isVpnt()
      ? this.getVpntPrice()
      : this.isTvInclusion() ? this.getTvInclusionPrice()
      : this.sadaicVersions.reduce(
          (acc, version) =>
            acc +
            this.getLicenseTypeConfig().category[this.sadaicCategory].duration[this.sadaicDuration].length[
              version.seconds
            ].money,
          0
        );
}

export default SadaicPriceCalculator;
