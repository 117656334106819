import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../config/localization";
import {request} from "../../api-client";
import {ServerSidePaginationTable} from "../../components/Letflow/Table";
import {Edit} from "@material-ui/icons";
import TableButton from "../../components/Letflow/Table/Buttons/TableButton";
import StateDialog from "./StateDialog"
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import countries from 'config/countries.json'
import {Link} from "react-router-dom";
import './ContactMessage.css'
import EventManager, {events} from "../../utils/EventManager";

class All extends Component {

  state = {
    open: false
  }

  componentDidMount = () => {
    document.title = localization.get('contact_messages') + ' - Feater'
  }

  handleEdit = (contactMessage) => {
    this.setState({open: true, id: contactMessage.id, state: contactMessage.state});
  }

  handleClose = state => {
    this.setState({ open: false });
    if(state !== this.state.state) {
      request.contactMessage.update({contactMessageId: this.state.id, state:state})
        .then(() => {
          GlobalSnackbar.show({message: localization.get('contact_message.snackbar.success'), type: GlobalSnackbarTypes.SUCCESS})
          this.refs.table.forceUpdate()
          EventManager.getInstance().dispatch(events.UPDATE_DASHBOARD_NOTIFICATIONS)
        })
    }
  };


  columns = () => [
    {
      Header: localization.get('table.name'),
      accessor: "name",
      width: 200,
    },
    {
      Header: localization.get('table.email'),
      accessor: "email",
      maxWidth: 200,
    },
    {
      Header: localization.get('table.country'),
      id: 'country',
      accessor: contactMessage => contactMessage.country ? countries.find(country => country.id == contactMessage.country).name : '',
      filterable: false,
      sortable: false
    },
    {
      Header: localization.get('table.company'),
      accessor: 'company'
    },
    {
      Header: localization.get('table.state'),
      id: 'state',
      accessor: contactMessage => localization.get(`contact_message.${contactMessage.state}`),
      Filter: ({filter, onChange}) =>
        <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected/>
          <option value={'pending'}>{localization.get('contact_message.pending')}</option>
          <option value={'contacted'}>{localization.get('contact_message.contacted')}</option>
          <option value={'not_interested'}>{localization.get('contact_message.not_interested')}</option>
          <option value={'client'}>{localization.get('contact_message.client')}</option>
        </select>,
    },
    {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: contactMessage =>
        <div style={{float: 'right'}}>
          <TableButton onClick={() => this.handleEdit(contactMessage)} title={localization.get('tooltip.edit')}>
            <Edit/>
          </TableButton>
        </div>,
      width: 100
    }
  ]

  subComponent = (row) => {
    return (
      <div className="contact-message-subcomponent">
        {row.original.description ?
          <div>
            <h4>{localization.get('subcomponent.description')}</h4>
            <div className="contact-message-subcomponent-row" >{row.original.description}</div>
          </div> : null }
        {row.original.url ?
          <div>
            <h4>{localization.get('subcomponent.url')}</h4>
            <Link className="contact-message-subcomponent-row" to={row.original.url} target={"_blank"}>{row.original.url}</Link>
          </div> : null}
      </div>
    )
  }


  render = () =>
    <div>
      <AllViewContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ServerSidePaginationTable
                  ref='table'
                  columns={this.columns()}
                  subComponent={this.subComponent}
                  requestTableData={paginationConfig => request.contactMessage.getAll(paginationConfig)}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
      <StateDialog
        open={this.state.open}
        onClose={this.handleClose}
        state={this.state.state}
      />
    </div>
}

export default All;
