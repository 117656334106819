import {post} from "../utils";

export default host => (producerId, emails, clientId) =>
  post(
    `${host}/contentCreators/${producerId}/share`,
    JSON.stringify({
      emails,
      client_id: clientId,
    })
  )
    .then(response => response.json())
    .then(json => json.data);
