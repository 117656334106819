import React from 'react'
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import localization from "../../config/localization";
import TagSelection from "../../components/Letflow/TagSelection/TagSelection";
import styled from "styled-components";

class HomeElementCreateDialogTagSelection extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      tagsRadio: this.props.radio || "all",
      count: null,
      error: false,
      selectedCreator: typeof this.props.contentCreators !== 'undefined' && this.props.contentCreators.length > 0 ? this.props.contentCreators[0].slug : false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.contentCreators && nextProps.contentCreators !== this.props.contentCreators) {
      this.setState({selectedCreator: typeof nextProps.contentCreators !== 'undefined' && nextProps.contentCreators.length > 0 ? nextProps.contentCreators[0].slug : false})
    }
  }

  onTest =  () => {
    this.setState({error: false})
    this.props.onTest()
      .then(res => this.setState({count: res.count}))
      .catch(() => this.setState({error: true, count: null}))
  }

  render = () => {
  const tagGroup = this.props.tagGroups
    return (
      <div style={{textAlign: "center", marginTop: "20px"}}>
        <FormControl style={{width: "100%", flexDirection: "row"}}>
          <FormLabel focused={false} style={{alignSelf: "center", color: "#3b4858", marginRight: 20}}>{localization.get('home_editor.tag_selection_title')}</FormLabel>
          <RadioGroup
            name="tagsRadio"
            value={this.state.tagsRadio}
            onChange={event => {
              if (event.target.value === 'all') {
                this.props.clearAllTags()
              }
              this.setState({ tagsRadio: event.target.value })
            }}
            style={{flexDirection: "row", display: "contents"}}
          >
            <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('home_editor.tag_selection.all')} />
            <FormControlLabel value="filter" control={<Radio color="default" />} label={localization.get('home_editor.tag_selection.filter')} />
          </RadioGroup>
          <Button disabled={this.state.tagsRadio === 'all'} onClick={() => this.onTest()}>{localization.get('home_editor.tag_selection_test.button')}</Button>
          {this.state.count !== null && this.state.tagsRadio === "filter" &&
            <span style={{alignSelf: "center", marginLeft: 5}}>{this.state.count + localization.get('home_editor.tag_selection_test.items')}</span>
          }
          {this.state.error &&
          <span style={{alignSelf: "center", marginLeft: 5}}>{localization.get('smart_search.try_again')}</span>
          }
        </FormControl>
        {this.state.tagsRadio === "filter" &&
          <>
            {typeof this.props.contentCreators === 'undefined' || this.props.contentCreators.length===0 ?
              <TagSelection
                homeElementTagSelection={true}
                data={this.props.tagGroups}
                allowUnselected={true}
                onSelectedTagsChanged={this.props.onSelectedTagsChanged}/>
            :
              <div>
                <div style={{ overflow: "hidden",border: "1px solid #ccc",backgroundColor: "#f1f1f1"}}>
                  {this.props.contentCreators.map(contentCreatorType => (
                        <ButtonTab style={{ backgroundColor: this.state.selectedCreator === contentCreatorType.slug ? "#ccc" : "#f1f1f1"}} onClick={() => this.setState({selectedCreator: contentCreatorType.slug})}>{contentCreatorType.label}</ButtonTab>
                      )
                  )}
                </div>
                {this.state.selectedCreator &&
                  <TagSelection
                    homeElementTagSelection={true}
                    data={tagGroup.filter(tagGroup => tagGroup.types.filter(type => type.type === this.state.selectedCreator).length > 0)}
                    allowUnselected={true}
                    onSelectedTagsChanged={this.props.onSelectedTagsChanged}
                  />
                }
              </div>
            }
            </>
        }
      </div>
    )
  }
}

export default HomeElementCreateDialogTagSelection

const ButtonTab = styled.div`
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
   &:hover {
   background-color: #ddd;
  }
`;