function EventManager() {

  const handlers = {}

  this.subscribe = (type, callback) => {
    if (!handlers[type]) {
      handlers[type] = []
    }
    handlers[type].push(callback)
    return handlers[type].length - 1
  }

  this.unsubscribe = (type, callback) => {
    if (handlers[type]) {
      const callbackIndex = handlers[type].indexOf(callback)
      if (callbackIndex !== -1) {
        handlers[type].splice(callbackIndex, 1)
      }
    }
  }

  this.dispatch = (type, data) => {
    if (handlers[type]) {
      handlers[type].forEach(callback => callback(data))
    }
  }

}

let instance = null

/** @returns {EventManager} */
EventManager.getInstance = () => {
  if (!instance) {
    instance = new EventManager()
  }
  return instance
}

export default EventManager