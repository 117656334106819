import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    blogPostId,
    translations,
    visibility,
    selectedClient,
    selectedClients,
    selectedBlogLocations,
    selectedBlogTags,
    image,
  }) =>
  post(`${host}/blogPosts/${blogPostId}`, addPutMethodToForm(makeFormData({
      translations,
      visibility,
      selectedClient,
      selectedClients,
      selectedBlogLocations,
      selectedBlogTags,
      image
  })))
  .then(response => response.json())
