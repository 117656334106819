import moment from "moment";
import localization from "../../../config/localization";
import {mapMediaFromApiLicense, monthDiff} from "../../../utils";
import React from "react";
import {LicenseTypeIds} from "../../../components/Letflow/LicenseWizard/utils/constants";
import {Dialog, DialogContent} from "@material-ui/core";
import {request} from "../../../api-client";

class LicenseDetailsDialog extends React.Component {
  state = {
    open: false,
    license: null
  }

  componentDidMount = () => {
    request.license.get(this.props.licenseId)
      .then(license => this.setState({license: license, open: true}))
  }

  handleClose = () => {
    this.setState({open: false})
    this.props.hideDialog()
  }

  render = () => {
    const {license} = this.state
    if (this.state.open) {
      return (
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogContent>
            <div style={{float: "left", position: "relative", width: "100%", border: "2px solid darkgray"}}>
              <div style={{
                float: "left",
                position: "relative",
                width: "100%",
                borderBottom: "2px solid darkgray",
                textAlign: "center",
                height: "35px",
                fontSize: "22px",
                paddingTop: "7px"
              }}
              >
                {localization.get('music.license_dialog.details')} {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ? 'SADAIC' : null}
              </div>
              <div style={{float: "left", position: "relative", width: "60%", padding: "20px"}}>

                {license.starts_at ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.starts_at')}:</b>{moment(license.starts_at).format("D-MM-YYYY")}
                  </div>
                  : null}

                {license.ends_at ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.ends_at')}:</b>{moment(license.ends_at).format("D-MM-YYYY")}
                  </div>
                  : null}

                {license.music ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.track')}:</b>{`${license.music.title} (${license.music.code})`}
                  </div>
                  : null}

                {license.music && license.music.artist ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.artist')}:</b>{`${license.music.artist.name} (${localization.get(license.music.artist.type)})`}
                  </div>
                  : null}

                {license.music && license.music.albums.length > 0 ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.albums')}:</b>{license.music.albums.map(album => album.title).join(", ")}
                  </div>
                  : null}

                {license.client ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left",
                      width: "200px"
                    }}>{localization.get('music.license_dialog.client')}:</b>{`${license.client.name} ${license.user ? `(${license.user.name})` : ''}`}
                  </div>
                  : null}

                {license.type ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.license_type')}:</b>{license.type.name}
                  </div>
                  : null}

                {license.medias.length > 0 ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.media')}:</b>{mapMediaFromApiLicense(license)}
                  </div>
                  : null}

                {license.extents.length > 0 ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.regions')}:</b>{license.extents.map(extent => (`${extent.region.name} (${extent.name})`)).join(", ")}
                  </div>
                  : null}

                {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.validity')}:</b>{monthDiff(license.starts_at, license.ends_at)} {localization.get('music.license_dialog.months')}
                  </div>
                  : null}

                {license.exclusivity ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px", float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.exclusivity')}:</b>{monthDiff(license.starts_at, license.exclusive_until)} {localization.get('music.license_dialog.months')}
                  </div>
                  : <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.exclusivity')}:</b> No exclusivo
                  </div>}

                {license.versions && license.versions.length > 0 ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.versions')}:</b>
                    <div style={{float: "left"}}>
                      {license.versions.map((version, key) =>
                        <div>{`${key === 0 ? localization.get('music.license_dialog.comercial')
                          : `${localization.get('music.license_dialog.version')} ${key}`} / ${localization.get('music.license_dialog.second')}: ${version.length.name} / ${localization.get('music.license_dialog.reductions')}: ${version.reductions}`}</div>)}
                    </div>
                  </div>
                  : null}

                {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.especial_campaign')}:</b>
                    {!license.versions || license.versions.length < 3 ? localization.get('music.license_dialog.none') : license.versions.length >= 6 ? localization.get('music.license_dialog.6_or_more') : localization.get('music.license_dialog.3_or_more')}
                  </div>
                  : null}

                {license.unlimited_reductions || license.reductions ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>{localization.get('music.license_dialog.reductions')}:</b>{license.unlimited_reductions ? localization.get('music.license_dialog.unlimited') : `${license.reductions} ${localization.get('music.license_dialog.reductions')}`}
                  </div>
                  : license.versions ? null :
                    <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                      <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.reductions')}:</b>{localization.get('music.license_dialog.no_reductions')}
                    </div>}

                {license.price ?
                  <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                    <b style={{
                      fontSize: "16px",
                      float: "left", width: "200px"
                    }}>Total:</b>{`USD ${license.price} ${license.discount ? `(${localization.get('music.license_dialog.discount')} USD ${license.discount})` : ''}`}
                  </div>
                  : null}

              </div>
              {license.project ?
                <div style={{float: "left", position: "relative", width: "40%", textAlign: "center"}}>
                  <div style={{display: "inline-block", border: "2px solid darkgray", height: "200px", width: "300px",marginTop: "55px", textAlign: "left", padding: "30px 0 0 30px",backgroundColor: "lightgray"}}>
                    <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                      <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.brand')}:</b>{license.project.brand}
                    </div>
                    <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                      <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.product')}:</b>{license.project.product}
                    </div>
                    <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
                      <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.campaign')}:</b>{license.project.title}
                    </div>
                  </div>
                </div>
                : null}

            </div>
          </DialogContent>
        </Dialog>
      )
    } else {
      return <div/>
    }
  }
}

export default LicenseDetailsDialog