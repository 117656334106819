import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import PublicPlaylist from "../../views/PublicPlaylist/index.jsx";
import TrialLogin from "../../views/TrialLogin";
import UserActivate from "../../views/UserActivate";
import PublicSpotlight from "../../layouts/Home/Spotlight/PublicSpotlight";
import PublicArtist from "../../views/Artists/PublicArtist"
import PublicAlbum from "../../views/PublicAlbum/index.jsx"
import PublicPitchMaterial from "../../views/PublicPitchMaterial";
import PublicCreateSpeaker from "../../views/Speakers/PublicCreateSpeaker";
import PublicCreateArtist from "../../views/Artists/PublicCreateArtist";
import PublicCreateContentCreator from "../../views/ContentCreators/DemoCreate/PublicCreateContentCreator";
import PublicContentCreator from "../../views/PublicContentCreator";
import PublicReel from "../../views/PublicReel";
import PublicBatchUpload from "../../views/BatchUpload/PublicBatchUpload"
import ChannelUserActivate from "../../views/ChannelLogin/Activate/CompleteRegister";
import PreviewContentCreator from "../../views/ContentCreators/Preview";
import PublicContentCreation from "../../layouts/Channel/PublicContentCreation";

export const makeRoutes = (allowed, excluded, defaultRoute) => ({
  allowed,
  excluded,
  default: defaultRoute,
});

export const route = (path = "/", component) => ({
  path,
  component,
});

export const redirectTo = to => () => <Redirect to={to} />;

export const publicRoutes = [
  route("/login/trial", TrialLogin),
  route("/public/playlists/:token-:playlistName", props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route("/public/playlists/:token", props => <PublicPlaylist {...props} token={props.match.params.token} />),
  route("/public/albums/:token", props => <PublicAlbum {...props} token={props.match.params.token} />),
  route("/public/pitches/:pitchId/materials/:messageId", props => <PublicPitchMaterial {...props} pitchId={props.match.params.pitchId} messageId={props.match.params.messageId} />),
  route("/channel/user/activation", ChannelUserActivate),
  route("/users/activate", UserActivate),
  route("/spotlights", PublicSpotlight),
  route("/public/artist/:artistId", PublicArtist),

  route("/public/speakers/create", PublicCreateSpeaker),
  route("/public/artists/create", PublicCreateArtist),

  route("/public/content_creator/create", PublicCreateContentCreator),
  route("/public/content_creators/:contentCreatorId", PublicContentCreator),
  route("/preview/content_creators/:contentCreatorId", PreviewContentCreator),
  route(`/public/content_creations/:contentCreationId`,props => <PublicContentCreation {...props} contentCreationId={props.match.params.contentCreationId} />),

  route("/public/reel/:reelId", PublicReel),
  route("/public/batch_upload/:token", PublicBatchUpload),
];
