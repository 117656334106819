import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveChannel, getActiveClient, isPublicChannel} from "../authentication/utils";
import localization from '../../../config/localization';

const url = (host, type, tags, notTags, age, countryId, options, channelId = null, search= "", startDate, endDate, schedule, publishStartDate, publishEndDate) =>
  appendPaginationQueryParamsToUrl(`${host}${isPublicChannel() ? "/public": ""}/clients/${getActiveClient()}${!!channelId ? `/channels/${channelId}`: ""}/contentCreators?type[slug]=${type}&tags_ids=${tags.join(',')}&not_tags_ids=${notTags.join(',')}${addFilters(age, countryId)}${search ? `&name=${search}` : ''}${schedule ? `&schedule=${schedule}`:''}${startDate && endDate ? `&date_start=${startDate}&date_end=${endDate}`: ''}${publishStartDate && publishEndDate ? `&publish_date_start=${publishStartDate}&publish_date_end=${publishEndDate}`: ''}&include=tags;image;type;contentCreations${getActiveChannel() ? `&cLang=${localization.getLanguage()}` : ""}`, {...options, alreadyHasQueryParams: true});


const addFilters = (age, countryId) => {
  let filters = '';
  if (age) {
    let currentAge = new Date().getFullYear()
    let min = `${currentAge - age.min}-00-00`
    let max = `${currentAge - age.max}-00-00`
    filters += `&birthdate[]=ge:${max}&birthdate[]=lt:${min}`
  }
  if (countryId) {
    filters += `&country[id]=${countryId}`
  }
  return filters
}

export default host => (type, tags, notTags, age, countryId, options, channelId = null, search= "", startDate, endDate, schedule, publishStartDate, publishEndDate) =>
  get(url(host, type, tags, notTags, age, countryId, options, channelId, search, startDate, endDate, schedule, publishStartDate, publishEndDate))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)