import React, {Component} from 'react'
import {Grid} from '@material-ui/core';
import ReactPaginate from 'react-paginate'
import '../CardGrid/index.css'

const Paginator = ({ pages, onPageChange, currentPage }) => {
  const makeClassName = item => `card-grid-pagination-${item}`
  return (
    <div className={makeClassName('root')}>
      <ReactPaginate
        pageCount={pages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        previousLabel={'<'}
        nextLabel={'>'}
        onPageChange={({selected}) => onPageChange(selected)}
        forcePage={currentPage}
        containerClassName={makeClassName('container')}
        pageClassName={makeClassName('page')}
        pageLinkClassName={makeClassName('page-link')}
        previousClassName={makeClassName('previous')}
        previousLinkClassName={makeClassName('previous-link')}
        nextClassName={makeClassName('next')}
        nextLinkClassName={makeClassName('next-link')}
        breakClassName={makeClassName('break')}
        activeClassName={makeClassName('active')}
        disabledClassName={makeClassName('disabled')}
        disableInitialCallback
      />
    </div>
  )
}

/**
 * @param {array} cards - an array of Card
 */
const RowView = ({ pages, currentPage, rows, onPageChange }) => {
  return (
    <div>
      <Grid className="track-table-rows-container" container>
        {rows}
      </Grid>
      {pages > 1 &&
        <Paginator pages={pages} onPageChange={onPageChange} currentPage={currentPage} />
      }
    </div>
  )
}

export default class TrackResult extends Component {
  render = () => {
    return (
      <RowView
        pages={this.props.pagination.last_page}
        currentPage={this.props.pagination.current_page - 1}
        rows={this.props.rows}
        onPageChange={this.props.onPageChange}
      />
    )
  }
}