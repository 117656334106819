import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import {Close, Done, FilterNone} from "@material-ui/icons";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {userisMultiClient} from "../../../api-client/core/authentication/utils";

let table

class Table extends Component {
  state = {
    visibility:'private',
    client: '',
    containerType: 'all',
    copied: localization.get('copy_link'),
    loaded: true
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({client: '', visibility: 'private', loaded: false},
        () => this.setState({loaded: true}))
    }
  }

  columns = () => {
    const {isTalent, isAudiovisual, isEditorial } = this.props;
    const nameColumn = {
        Header: localization.get('table.name'),
        accessor: "name",
        Filter: ({filter, onChange}) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            placeholder={localization.get('table.search')}
          />
        ),
      }
      const actionColumns = {
          Header: localization.get('table.actions'),
          id: 'actions',
          sortable: false,
          filterable: false,
          accessor: contentCreatorType => {
            const builder = new TableButtonsBuilder()

            if (this.props.isTalent && !userisMultiClient()) {
              builder.withOther(
                <CopyToClipboard
                  onCopy={() => this.setState({copied: localization.get('copied')},
                    () => setTimeout(() => this.setState({copied: localization.get('copy_link')}), 1000))}
                  text={`${process.env.REACT_APP_FRONT_HOST}/public/content_creator/create?type=${contentCreatorType.slug}`}
                >
                  <TableButton
                    title={localization.get(this.state.copied)}
                  >
                    <FilterNone/>
                  </TableButton>
                </CopyToClipboard>
              )
            }
            builder.withEdit(makeUrlWithTableParams(isTalent ? `/panel/talents/content_creator_types/${contentCreatorType.id}/edit` : `/panel/audiovisual/content_creator_types/${contentCreatorType.id}/edit`))
            builder.withDelete(contentCreatorType.name, () => request.contentCreatorType.delete(contentCreatorType.id), status => {
              if (status === 'passed') {
                table.removeById(contentCreatorType.id)
              }
            }, true, localization.get("table.delete.content_creator_type"))
            return builder.build();
          }
        }
    const infoColumns = [
      {
        Header: localization.get("type"),
        id: "type",
        accessor: contentCreatorType => localization.get(`content_creator_type.${contentCreatorType.type}`),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value="editorial">{localization.get('content_creator_type.editorial')}</option>
            <option value="audiovisual">{localization.get('production')}</option>
          </select>
        ),
        sortable: false,
        show: !this.props.isTalent,
      },
      {
        Header: localization.get("audio"),
        id: "allow_audio",
        accessor: catalogCategory => (catalogCategory.allow_audio ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
      {
        Header: localization.get("video"),
        id: "allow_video",
        accessor: catalogCategory => (catalogCategory.allow_video ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
      {
        Header: localization.get("image"),
        id: "allow_image",
        accessor: catalogCategory => (catalogCategory.allow_image ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
      {
        Header: localization.get("link"),
        id: "allow_link",
        accessor: catalogCategory => (catalogCategory.allow_link ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
      {
        Header: localization.get("pdf"),
        id: "allow_binary",
        accessor: catalogCategory => (catalogCategory.allow_binary ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
      {
        Header: localization.get("form.embed_short"),
        id: "allow_embed",
        accessor: catalogCategory => (catalogCategory.allow_embed ? <Done/> : <Close/>),
        Filter: ({filter, onChange}) => (
          <select
            style={{width: "100%"}}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected/>
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>
        ),
        sortable: false,
        style: {textAlign: 'center'},
        width: 100
      },
    ]

    let columns = [nameColumn];
    if (isAudiovisual || isTalent) columns.concat(infoColumns);

    return columns.concat([actionColumns])
  }
  render = () => {
    const { visibility, client, loaded } = this.state;
    const {isTalent} = this.props;

    return !loaded ? <div></div> :
      (
      <>
        <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
          <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
            <span>{localization.get('select_client')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={client => this.setState({client}, () => this.state.client && table.forceUpdate())}
              value={client}
              defaultOptions
              isClearable
              loadOptions={inputValue =>{
                let filters = [{column: "name", filter: inputValue}, {column: "has_content_creator_types", filter: true}, {column: "is_talent", filter: this.props.isTalent ? 1 : 0}]
                return request.client
                  .getAll({filterBy: filters}, '')
                  .then(res => res.map(client => ({value: client.id, label: client.name})))
              }}
            />
          </div>
        </div>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
         {client &&
          <ServerSidePaginationTable
            ref={r => table = r}
            columns={this.columns()}
            requestTableData={paginationConfig => {
              paginationConfig.filterBy.push({
                column: "visibility",
                filter: visibility
              });

              if (client) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'private_client_id', filter: client.value }]) };
              }

              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'is_talent', filter: isTalent ? 1 : 0  }])}
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'type', filter: this.props.isEditorial ? "editorial" : "audiovisual" }])}
              return request.contentCreatorType.getAll(paginationConfig)
            }}
          />
          }
        </div>
      </>
    )
  }
}


export default Table
