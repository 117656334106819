import React, {Component} from "react";
import {request} from "api-client";
import FormView from "../Form";
import FormViewContainer from "components/Letflow/ViewContainer/FormViewContainer";
import localization from "../../../../config/localization"
import {getPropertySafe} from "../../../../utils";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveClient,
  getStoredChannel
} from "../../../../api-client/core/authentication/utils";

export default class extends Component {

  state = {
    title: '',
    subtitle: '',
    image: { src: null, file: null },  
    disabled: false,
    userLibrary: false,
    visibility: false,
    allowDownload: false,
    brands: [],
    type: "client",
    tagGroups: [],
    selectedTags: [],
    useFilters: true
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
  }

  requestPlaylist = () => {
    const id = this.props.match.params.playlistId

    return request.playlist.get(id, "include=brands;user;relatedTags&ts="+ new Date())
      .then(playlist => {
        const selectedTags = playlist.relatedTags.map(tag => tag.id);

        this.setState({
        title: playlist.title,
        subtitle: playlist.subtitle,
        image: {
            src: playlist.image ? request.file.image.makeUrl(playlist.image.id) : null,
            file: null,
        },
        visibility: playlist.visibility === 'public',
        userLibrary: playlist.user_library,
        selectedClientIds: getPropertySafe(playlist, 'clients', []).map(x => x.id),
        allowDownload: playlist.allow_download,
        brands: playlist.brands.map(brand => ({value: brand.id, label: brand.name})),
        type: playlist.type,
        selectedTags: selectedTags,
        tagGroups: this.state.tagGroups.map(tagGroup => ({...tagGroup, tags: tagGroup.tags.map(tag => ({...tag, state: selectedTags.includes(tag.id) ? "selected" : "available"}))})),
        useFilters: playlist.use_filters
      })
    })
  }

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
      return {...tagGroup,
        tags: tagGroup.tags.map(tag => {
            if (tag.id === selectedTag) {
              return {...tag, state: value}
            }
          return tag
          })
        }
      })
    })

    
  initializationRequest = () => request.tagGroup.getAll({filterBy: [
      { column: 'content_creations', filter: true},
      { column: 'inheritable', filter: "true"},
      { column: 'show_on_smart_search', filter: '1'},
      { column: 'channel_id', filter: `${getActiveChannel()}`}
    ]}, 'material')
    .then(tagGroups => {
      let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
      if(getActiveChannel() && getStoredChannel().tagGroups.length) {
        getStoredChannel().tagGroups.filter(tg => tg.target === 'material').forEach(channelTg => {
          availableTagGroups.forEach(tg => {
            if (tg.id === channelTg.id) {
              tg.order = channelTg.order
            }
          })
        })
      }

    this.setState({ tagGroups: availableTagGroups.sort((a,b) => a.order - b.order)})
  }).then(() => this.requestPlaylist()) 


  requestUpdate = () => {
    const { title, subtitle, image, userLibrary, visibility, allowDownload, brands, type, selectedTags, useFilters } = this.state
    return request.playlist.update({
      playlistId: this.props.match.params.playlistId,
      title,
      subtitle,
      userLibrary,
      imageFile: image.file,
      clientId: getActiveClient(),
      visibility,
      allowDownload,
      brands,
      type,
      selectedTags,
      useFilters
    })
      .then(() => this.props.history.push(channelPathGenerator(`playlists`)))
  }


  onImageChange = image => this.setState({ image })
  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) { //Remove from selected / add to unselected tags
      this.setState({ selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag) }, 
        () => this.updateTagColor(selectedTag, "available"))
    }else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag);
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  }


  render = () => {
    const { disabled, title, subtitle, image, userLibrary, visibility, allowDownload, brands, type, selectedTags } = this.state
    return (
      <div id="innerinner">
        <FormViewContainer
          title={localization.get('home_playlist.update')}
          initializationRequest={this.initializationRequest}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestUpdate}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          style={{maxWidth: "600px", width: "100%", display: "table", margin: "30px auto"}}
          color="black"
        >
          <FormView
            title={title}
            subtitle={subtitle}
            image={image}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onImageChange={image => this.setState({ image })}
            disabled={disabled}
            userLibrary={userLibrary}
            onUserLibraryChange={e => this.setState({ userLibrary: e })}
            visibility={visibility}
            onVisibilityLibraryChange={e => this.setState({ visibility: e })}
            allowDownload={allowDownload}
            onAllowDownloadChange={e => this.setState({ allowDownload: e })}
            type={type}
            brands={brands}
            tagGroups={this.state.tagGroups}
            selectedTags={selectedTags}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            updating
            useFilters={this.state.useFilters}
          />
        </FormViewContainer>
      </div>
    )
  }
}
