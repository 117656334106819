import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import matchSorter from "match-sorter";
import {ServerSidePaginationTable} from "../../../components/Letflow/Table";

export default class extends Component {

  state = {
    clientCountries: [],
    country: ''
  }

  componentDidMount = () => {
    document.title = localization.get('title.tags_demand_ranking') + ' - Feater'

    request.client.getCountries().then(clientCountries => this.setState({clientCountries}));
  }

  onChangeCountry = (country) => {
    this.setState({ country });
  }

  render = () => {
    const { country, clientCountries } = this.state;

    return (<AllViewContainer ref='viewContainer'>
      <div style={{textAlign: 'center', marginTop: '15px'}}>
        <InputLabel htmlFor="country">{localization.get('form.country')}</InputLabel>
        <Select
          id="country"
          name="country"
          value={country}
          onChange={event => {
            this.onChangeCountry(event.target.value)
            this.refs.table.forceUpdate();
          }}
          style={{ width: 300, marginLeft: '15px' }}
          displayEmpty
        >
          <MenuItem value='' defaultValue>{localization.get('all')}</MenuItem>
          {clientCountries.length > 0 && clientCountries.map(country => {
            return <MenuItem value={country.key}>{country.name}</MenuItem>;
          })}
        </Select>
      </div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ServerSidePaginationTable
                ref="table"
                requestTableData={paginationConfig => request.tag.getDemandRanking(this.state.country, paginationConfig)}
                columns={[
                  {
                    Header: localization.get('table.position'),
                    accessor: 'position',
                    width: 100,
                    style: {
                      textAlign: 'center',
                    },
                    filterable: false,
                  },
                  {
                    Header: localization.get('table.name'),
                    accessor: 'name',
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    Filter: ({filter, onChange}) => (
                      <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        placeholder={localization.get('table.search')}
                      />
                    ),
                  },
                  {
                    Header: localization.get('table.tag_group'),
                    id: 'type',
                    filterable: false,
                    sortable: false,
                    accessor: item => item.group.name
                  },
                  {
                    Header: localization.get('table.points'),
                    id: 'score',
                    accessor: item => item.score || 0,
                    filterable: false,
                    width: 100,
                    style: {
                      textAlign: 'right',
                    },
                    headerStyle: {
                      textAlign: 'center',
                    },
                  },
                ]}
                defaultPageSize={20}
                showPaginationTop
                showPaginationBottom={false}
                showPageSizeOptions={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>)
  }
}
