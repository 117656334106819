import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridPeople from "./GridPeople.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import People from "../../../api-client/core/people";

export default class extends Component {

  componentDidMount = () => {
      document.title = localization.get('title.tags') + ' - Feater'
  }

  render = () =>
    <AllViewContainer
      viewIcon={<People />}
      ref='viewContainer'>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridPeople {...this.props}/>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </AllViewContainer>

}
