import React from 'react'
import localization from "../../../config/localization";
import Button from "../../../components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import { Card, CardContent, IconButton, Tooltip } from "@material-ui/core";
import RecurrentEventDialog from './RecurrentEventDialog';
import EventDateEditionDialog from './EventDateEditionDialog';
import moment from 'moment-with-locales-es6';
import { Close, Edit } from '@material-ui/icons';

class RecurrentEvents extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      events: (this.props.events && this.props.events.length > 0) ? this.props.events : []
    }
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (nextProps.events && nextProps.events.length > 0 && this.state.events !== nextProps.events) {
      this.setState({events: nextProps.events})
    }
  };
  
  addRecurrentEvent = (dates) => {
    let events = this.state.events;
    let eventDates = dates.dates.map((d) => {
      // {date: '1992-10-02', time: 'HH:mm', preferences: {button: {text: , link: , startTime: , endTime: }, modality: ''}}
      // {date: '1992-10-02', time: 'HH:mm', buttonText: , buttonLink: , buttonStartTime: datetime, buttonEndTime: datetime, modality: ''}
      return {date: d, duration: dates.duration, dateLabel: moment(d).format('lll'), link: '', linkText: '', clarification: '', activationDate: ''};
    })
    events.push(eventDates);
    this.setState({events: events, showDialog: false}, () => this.updateEvents());
  };

  removeRecurrentEvent = (index) => {
    const events = this.state.events.filter((event, eventIndex) => index !== eventIndex);
    this.setState({events}, () => this.updateEvents());
  };
  
  editDate = (dateGroupIndex, dateIndex) => {
    this.setState({editingDate: [dateGroupIndex, dateIndex]});
  }

  updateEditingDate = (date) => {
    if (this.state.editingDate) {
      let [dateGroupIndex, dateIndex] = this.state.editingDate;
      let event = this.state.events[dateGroupIndex];
      event[dateIndex] = date;
      const events = [
        ...this.state.events.slice(0,dateGroupIndex),
        event,
        ...this.state.events.slice(dateGroupIndex+1)
      ];
      this.setState({events: events, editingDate: null}, () => this.updateEvents());
    }
  }

  removeDate = (dateGroupIndex, dateIndex) => {
    let event = this.state.events[dateGroupIndex].filter((e, i) => dateIndex !== i);
    let events;
    if (event.length>0) {
      events = [
        ...this.state.events.slice(0,dateGroupIndex),
        event,
        ...this.state.events.slice(dateGroupIndex+1)
      ];
    } else {
      events = this.state.events.filter((e, index) => dateGroupIndex !== index);
    }
    this.setState({events}, () => this.updateEvents());
  }

  getDateCount = () => {
    return this.state.events.reduce((acc,element) => acc + element.length, 0);
  }

  updateEvents = () => {
    return this.props.onEventsChange(this.state.events);
  }

  getEditingDate = () => {
    if (this.state.editingDate) {
      let [dateGroupIndex, dateIndex] = this.state.editingDate;
      return this.state.events[dateGroupIndex][dateIndex];
    }
    return null
  }

  render = () => {
    const {events, daysOfWeek} = this.state;
    let isMobile = window.innerWidth < 450;

    return(
      <React.Fragment>
        <RecurrentEventDialog showDialog={this.state.showDialog} handleCloseDialog={() => this.setState({showDialog: false})} onSubmit={(dates) => this.addRecurrentEvent(dates)}/>
        {this.state.editingDate && <EventDateEditionDialog eventDate={this.getEditingDate()} showDialog={this.state.editingDate} handleCloseDialog={() => this.setState({editingDate: null})} onSubmit={(date) => this.updateEditingDate(date)}/>}
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <h4>{localization.get("recurrent_events.total_dates")}: {this.getDateCount()}</h4>
              <Button
                style={{
                  padding: 8,
                  textTransform: "capitalize"
                }}
                color={this.props.buttonColor ? this.props.buttonColor : "dark"}
                onClick={() => this.setState({showDialog: true})}
              >
                {localization.get('recurrent_events.create_date')}
              </Button>
            </div>
          </CardContent>
        </Card>
        <br/>
        {events.map((dateGroup, index) => {
          return (
            <>
              <Card key={index} style={{overflow: 'visible'}}>
                <CardContent>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <h4>{localization.get("recurrent_events.group_dates")}: {dateGroup.length}</h4>
                    <Button
                      style={{
                        float: 'right',
                        padding: 8,
                        textTransform: "capitalize"
                      }}
                      color="danger"
                      onClick={() => this.removeRecurrentEvent(index)}
                    >
                      {localization.get('recurrent_events.delete_date_group')}
                    </Button>
                  </div>
                  <table style={{width: '100%'}}>
                    <tbody>
                      {dateGroup.map((date, i) => (
                        <tr key={i}>
                          <td style={{minWidth: 140}}><b>{date.dateLabel} {date.duration > 0 ? localization.get('duration.hours', date.duration) : ""}</b></td>
                          <td style={{wordBreak: "break-all"}}>
                            {date.link ? 
                              <Tooltip placement='bottom' title={date.link}>
                                <label style={{cursor: 'pointer'}}>{date.link.length > 100 ? date.link.substring(0,40).concat("...") : date.link}</label>
                              </Tooltip> 
                            : 
                              <label>Sin link</label>
                            }
                          </td>
                          <td><label>{date.linkText ? date.linkText : 'Sin texto de link'}</label></td>
                          <td><label>{date.clarification ? date.clarification : 'Sin aclaración'}</label></td>
                          <td style={{width: 100}}>
                            <IconButton onClick={() => this.editDate(index, i)}><Edit/></IconButton>
                            <IconButton onClick={() => this.removeDate(index, i)}><Close/></IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
              <br/>
            </>
          )
        })}
      </React.Fragment>
    )
  }
}

RecurrentEvents.propTypes = {
  events: PropTypes.array,
  onEventsChange: PropTypes.func
};

export default RecurrentEvents