import {post} from '../utils'
import {isNullOrUndefined} from "util";

export default host =>
  async (material) => {
    const presignedUrlForm = new FormData()

    presignedUrlForm.append('filename', material.filename)
    presignedUrlForm.append('type', material.type)
    presignedUrlForm.append('duration', material.duration)
    if (!isNullOrUndefined(material.batchUpload)) presignedUrlForm.append('batch_upload', material.batchUpload)
    if (!isNullOrUndefined(material.isLicense)) presignedUrlForm.append('is_license', material.isLicense)
    if (material.size) presignedUrlForm.append('size', material.size)
    if (material.clientId) presignedUrlForm.append('client_id', material.clientId)
    if (material.channelId) presignedUrlForm.append('channel_id', material.channelId)
    

    return await post(
      `${host}/files/upload`,
      presignedUrlForm,
      null
    )
      .then(response => response.json())
  }