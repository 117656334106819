import React from "react";
import PropTypes from "prop-types";
import {SadaicCategories} from "../../../components/Letflow/LicenseWizard/utils/constants";
import localization from '../../../config/localization'

export const SADAIC_CATEGORIES = {
  FULL_USE: SadaicCategories.USO_TOTAL,
  RESTRICTED_I: SadaicCategories.RESTRINGIDO_I,
  RESTRICTED_II_AND_III: SadaicCategories.RESTRINGIDO_II_III,
  RESTRICTED_IV: SadaicCategories.RESTRINGIDO_IV,

  asArray: function() {
    return [this.FULL_USE, this.RESTRICTED_I, this.RESTRICTED_II_AND_III, this.RESTRICTED_IV].sort();
  }
};

const PricesByTimeForAllCategories = ({ prices, onChange, editable }) => {
  const _makeCategoryPricesByTime = (category, categoryTitle, categoryDescription) =>
    makeCategoryPricesByTime(prices, category, categoryTitle, categoryDescription, onChange, editable);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {_makeCategoryPricesByTime(SADAIC_CATEGORIES.FULL_USE, localization.get('license_wizard.sadaic.category.total_use'), localization.get('license_wizard.sadaic.category.description.total_use'))}
      {_makeCategoryPricesByTime(SADAIC_CATEGORIES.RESTRICTED_I, localization.get('license_wizard.sadaic.category.restricted_1'), localization.get('license_wizard.sadaic.category.description.restricted_1'))}
      {_makeCategoryPricesByTime(
        SADAIC_CATEGORIES.RESTRICTED_II_AND_III,
        localization.get('license_wizard.sadaic.category.restricted_2_3'),
        localization.get('license_wizard.sadaic.category.description.restricted_2_3')
      )}
      {_makeCategoryPricesByTime(SADAIC_CATEGORIES.RESTRICTED_IV, localization.get('license_wizard.sadaic.category.restricted_4'), localization.get('license_wizard.sadaic.category.description.restricted_4'))}
    </div>
  );
};

PricesByTimeForAllCategories.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.oneOf(SADAIC_CATEGORIES.asArray()).isRequired,
      months: PropTypes.oneOf([6, 12]).isRequired,
      seconds: PropTypes.oneOf([10, 15, 20, 30, 45, 50, 60, 70]).isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired
};

const makeCategoryPricesByTime = (prices, category, categoryTitle, categoryDescription, onChange, editable) => {
  const handleChange = ({ months, seconds, price }) => onChange({ category, months, seconds, price });

  return (
    <PricesByTimeForCategory
      categoryTitle={categoryTitle}
      categoryDescription={categoryDescription}
      sixMonthPrices={getSixMonthPricesForCategory(prices, category)}
      twelveMonthPrices={getTwelveMonthPricesForCategory(prices, category)}
      onChange={handleChange}
      editable={editable}
    />
  );
};

const getSixMonthPricesForCategory = (prices, category) => getSomeMonthPricesForCategory(prices, category, 6);

const getTwelveMonthPricesForCategory = (prices, category) => getSomeMonthPricesForCategory(prices, category, 12);

const getSomeMonthPricesForCategory = (prices, category, months) =>
  prices.filter(p => p.category === category && p.months === months).map(p => p.price);

const PricesByTimeForCategory = ({
  categoryTitle,
  categoryDescription,
  sixMonthPrices,
  twelveMonthPrices,
  onChange,
  editable
}) => {
  const handleChange = months => ({ seconds, price }) => onChange({ months, seconds, price });

  return (
    <div style={{ margin: "20px 20px 20px 0" }}>
      <div style={{ fontSize: "16px", marginBottom: "10px" }}>
        <div>
          <b>{categoryTitle}</b>
        </div>
        <div style={{ fontSize: "12px", textAlign: "justify" }}>{categoryDescription}</div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {makePriceForSecondsColumn(6, sixMonthPrices, handleChange(6), editable)}
        {makePriceForSecondsColumn(12, twelveMonthPrices, handleChange(12), editable)}
      </div>
    </div>
  );
};

const makePriceForSecondsColumn = (months, prices, onChange, editable) => {
  const handleChange = seconds => price => onChange({ seconds, price });
  console.log(prices)
  return (
    <div style={{ marginRight: "10px", marginBottom: "10px" }}>
      <div style={{ color: "dimgray" }}>{localization.get('sadaic_pricing.months',months)}:</div>
      <PriceForSeconds seconds={10} price={prices[0]} onChange={handleChange(10)} editable={editable} />
      <PriceForSeconds seconds={15} price={prices[6]} onChange={handleChange(15)} editable={editable} />
      <PriceForSeconds seconds={20} price={prices[1]} onChange={handleChange(20)} editable={editable} />
      <PriceForSeconds seconds={30} price={prices[2]} onChange={handleChange(30)} editable={editable} />
      <PriceForSeconds seconds={45} price={prices[3]} onChange={handleChange(45)} editable={editable} />
      <PriceForSeconds seconds={60} price={prices[4]} onChange={handleChange(60)} editable={editable} />
      <PriceForSeconds seconds={60} price={prices[5]} onChange={handleChange(70)} editable={editable} isHigher />
    </div>
  );
};

const PriceForSeconds = ({ isHigher, seconds, price, onChange, editable }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ marginRight: "10px" }}>
        {isHigher ? localization.get('sadaic_pricing.superior_to') : localization.get('sadaic_pricing.until')}
        {seconds}
        ''
      </div>
      <div>
        ${" "}
        <input
          style={{ width: "100px" }}
          type={"number"}
          value={price}
          onChange={e => onChange(e.target.value)}
          disabled={!editable}
        />
      </div>
    </div>
  );
};

export default PricesByTimeForAllCategories;
