import React, { Component } from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import { AllViewContainer } from '../../../components/Letflow/ViewContainer';
import { request } from "../../../api-client";
import localization from "../../../config/localization";
import ClientSidePaginationTable from "../../../components/Letflow/Table/ClientSidePaginationTable";
import {setPageTitle} from "../../../api-client/core/authentication/utils";
import moment from "moment";

const PROJECTS_WEIGHT = 10;
const PLAYLISTS_WEIGHT = 10;
const LINKS_WEIGHT = 5;
const LICENSES_WEIGHT = 50;
const USERS_WEIGHT = 100;

export default class extends Component {

  state = {
    data: [],
  };

  componentDidMount = () => {
    setPageTitle(localization.get('title.monthly_report'));
    this.requestData()
  };

  requestData = () => {
    return request.monthlyReport.getReport()
      .then(res => {
        this.setState({
          data: [...res[0], ...res[1], ...res[2], ...res[3], ...res[4]],
        })
      })
      .then(() => {
        let transData = [];
        this.state.data.forEach(obj => {
          let index = transData.findIndex(data => data.month === obj.month)
          if (index === -1) {
            transData.push(obj)
          } else {
            transData[index] = {...transData[index], ...obj}
          }
        });
        this.setState({data: transData})
      })
      .then(() => {
        let transData = [];
        this.state.data.forEach((obj, index) => {
          transData[index] = {...obj, score: this.calculateScore(obj)}
        });
        this.setState({data: transData.sort((a, b) => moment(a.month).format("YYYY MM").localeCompare(moment(b.month).format("YYYY MM"))).reverse()})
      })
  };

  calculateScore = (data) => {
    let score = 0;
    if (data.pitches) {
      score += data.pitches * PROJECTS_WEIGHT
    }
    if (data.playlists) {
      score += data.playlists * PLAYLISTS_WEIGHT
    }
    if (data.links) {
      score += data.links * LINKS_WEIGHT
    }
    if (data.licenses) {
      score += data.licenses * LICENSES_WEIGHT
    }
    if (data.users) {
      score += data.users * USERS_WEIGHT
    }
    return score
  };

  columns = () => [
    {
      Header: localization.get('table.month'),
      accessor: 'month',
      Cell: data => <span> {moment(data.value).format("MMMM YYYY")} </span>,
      filterable: true,
      sortMethod: (a, b) => {
        moment(a.month).format("YYYY MM").localeCompare(moment(b.month).format("YYYY MM"))
      },
    },
    {
      id: "projects",
      Header: localization.get("table.new_projects"),
      accessor: 'pitches',
      Cell: data => <span> {data.value ? data.value : '-'} </span>,
      sortable: true,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      id: 'playlists',
      Header: localization.get("table.new_playlists"),
      accessor: "playlists",
      Cell: data => <span> {data.value ? data.value : '-'} </span>,
      sortable: true,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      Header: localization.get('table.new_links'),
      id: 'links',
      accessor: "links",
      Cell: data => <span> {data.value ? data.value : '-'} </span>,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      id: 'licenses',
      Header: localization.get("table.new_licenses"),
      accessor: "licenses",
      Cell: data => <span> {data.value ? data.value : '-'} </span>,
      sortable: true,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      id: 'users',
      Header: localization.get("table.new_users"),
      accessor: "users",
      Cell: data => <span> {data.value ? data.value : '-'} </span>,
      sortable: true,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      id: 'score',
      Header: localization.get("table.points"),
      accessor: "score",
      sortable: true,
      filterable: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  render = () => {

    return (<AllViewContainer ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ClientSidePaginationTable
                style={{width: "100%"}}
                perPage={12}
                data={this.state.data}
                columns={this.columns()}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>);
  }
}
