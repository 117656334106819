import React, { Component } from "react";
import PropTypes from "prop-types";
import './BloomcloudsToggleSwitch.css';

class BloomcloudsToggleSwitch extends Component {
  render() {
    return (
      <div className="toggle-switch" style={{"--checked-color": this.props.checkedColor ? this.props.checkedColor : "black", "--unchecked-color": this.props.uncheckedColor ? this.props.uncheckedColor : "#ccc"}}>
        <input
          type="checkbox"
          name={this.props.Name}
          className="toggle-switch-checkbox"
          id={this.props.id}
          checked={this.props.currentValue}
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
        {this.props.id ? (
          <label className="toggle-switch-label" htmlFor={this.props.id}>
            <span
              className={
                this.props.disabled
                  ? "toggle-switch-inner toggle-switch-disabled"
                  : "toggle-switch-inner"
              }
              data-yes={this.props.Text[0]}
              data-no={this.props.Text[1]}
            />
            <span
              className={
                this.props.disabled
                  ? "toggle-switch-switch toggle-switch-disabled"
                  : "toggle-switch-switch"
              }
            />
          </label>
        ) : null}
      </div>
    );
  }
  // Set text for rendering.
  static defaultProps = {
    Text: ["Yes", "No"]
  };
}

BloomcloudsToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  Text: PropTypes.string.isRequired,
  Name: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  uncheckedColor: PropTypes.string,
  checkedColor: PropTypes.string
};

export default BloomcloudsToggleSwitch;