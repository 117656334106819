const resolveKey = (key, data) => {
  if (typeof key === 'function') {
    return key(data)
  } else if (key in data) {
    return data[key];
  } else {
    return null;
  }
};

export { resolveKey }