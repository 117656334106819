import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import Thread from "./Thread";
import Create from "./Create";
import All from "./All";
import Online from "./Online";

const Routes = props => {
  return (
    <Router {...props}>
      <Switch>
        <Route
          path="/panel/pitches/online"
          render={props => (
            <Online threadUrl={pitchId => `/panel/pitches/${pitchId}/thread`} />
          )}
        />
        <Route
          path="/panel/pitches/create"
          render={() => (
            <Create
              makeCreatedPitchPath={pitchId => `/panel/pitches/${pitchId}/thread`}
            />
          )}
        />
        <Route
          path="/panel/pitches/:pitchId/thread"
          render={props => (
            <Thread
              pitchId={props.match.params.pitchId}
              onlineVideosPath={({ link, clientId, pitchId }) => {
                const url = `/panel/pitches/online?`;
                const qps = [];

                if (link) {
                  const splitedLink = link.split("/");
                  const linkPath = splitedLink[splitedLink.length - 1];
                  qps.push(`link=${linkPath}`);
                }
                if (clientId) {
                  qps.push(`client_id=${clientId}`);
                }
                if (pitchId) {
                  qps.push(`pitch_id=${pitchId}`);
                }
                return url + qps.join("&");
              }}
            />
          )}
        />
        <Route
          render={() => (
            <All
              onlineVideosUrl="/panel/pitches/online"
              threadUrl={pitchId => `/panel/pitches/${pitchId}/thread`}
              createUrl="/panel/pitches/create"
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
