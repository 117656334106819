import React, {Component} from 'react'
import localization from '../../../config/localization';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import Table from "./Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

class Clients extends Component {

  componentDidMount = () => {
    document.title = localization.get('title.license_plans') + ' - Feater'
  }

  onAdd = () => this.props.history.push(`/panel/licensePlans/create`)

  render = () =>
    <AllViewContainer
      onAdd={this.onAdd}
      ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default Clients