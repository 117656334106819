import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {IconButton} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ArrowForward, Block, FilterNone} from "@material-ui/icons";
import {red} from "@material-ui/core/colors";
import {addHttpToUrl} from "../../../utils";
import {Link} from "react-router-dom";

class TableSubcomponent extends Component {
  state = {
    link_targets: []
  };

  componentDidMount = () => {
    request.shareLink
      .get(this.props.link.id)
      .then(link =>
          this.setState({ link_targets: link.shortLinkTargets })
      );
  };

  buttons = short_link => {
    const { name, id } = short_link;

    const buttonBuilder = new TableButtonsBuilder();

    buttonBuilder.withOther(
        <TableButton
            title={localization.get('copy')}
        >
          <CopyToClipboard text={`${this.props.link.url}:${name}`} style={{bottom: 5}} >
            <IconButton>
              <FilterNone />
            </IconButton>
          </CopyToClipboard>
        </TableButton>)

    buttonBuilder.withOther(
        <TableButton
          title={'expire'}
          onClick={() => {
            request.shareLink.expire(id)
              .then(() => request.shareLink.get(this.props.link.id))
              .then(link => this.setState({ link_targets: link.shortLinkTargets }))

          }}
        >
          <Block/>
        </TableButton>
    )

    buttonBuilder.withOther(
            <Link
              to={addHttpToUrl(this.props.link.url)}
              target={"_blank"}
            >
              <TableButton
                title={localization.get('links.go_to')}
              >
                <ArrowForward/>
              </TableButton>
            </Link>
            );

    return buttonBuilder.build();
  };
  render = () => {
    return (
      <React.Fragment>
        {this.state.link_targets.length > 1 && (
          <div style={{ margin: "0px 0px 25px 0px", borderBottom: "1px solid #80808085", borderLeft: "1px solid #80808085", borderRight: "1px solid #80808085" }}>
            <ClientSidePaginationTable
              data={this.state.link_targets.map((link_target, index) => ({ ...link_target, index }))}
              ref="subTable"
              style={{marginTop: "-9px"}}
              columns={[
                {
                  filterable: false,
                  width: 49
                },
                {
                  filterable: false,
                },
                {
                  filterable: false,
                  show: window.innerWidth > 635,
                },
                {
                  filterable: false,
                  show: window.innerWidth > 935,
                },
                {
                  id: 'to',
                  accessor: link_target => link_target.to,
                  Cell: link => <span style={{ color: link.original.expired ? red['500'] : '#3C4858' }}> {link.value} </span>,
                },
                {
                  id: 'views',
                  accessor: 'views',
                  minWidth: 70,
                  show: window.innerWidth > 715,
                  Cell: link => <span style={{ color: link.original.expired ? red['500'] : '#3C4858' }}> {link.value} </span>
                },
                {
                  id: 'updated_at',
                  accessor: link_target => localization.moment(link_target.updated_at).fromNow(),
                  show: window.innerWidth > 825,
                  Cell: link => <span style={{ color: link.original.expired ? red['500'] : '#3C4858', textAlign:"end" }}> {link.value} </span>
                },
                {
                  id: 'actions',
                  accessor: this.buttons,
                  style: {paddingRight: (window.innerWidth > 425 && "40px")},
                  maxWidth: 145,
                  minWidth: window.innerWidth > 425 ? 120 : 70,
                },
              ]}
              filterable={false}
              sortable={false}
              showPagination={false}
              zebraStyle={false}
            />
          </div>
        )}
      </React.Fragment>
    );
  };
}

export default TableSubcomponent;
