import getAll from './getAll'
import del from './delete'
import create from './create'
import get from './get'
import update from './update'
import download from './download'
import getAllWithTags from './getAllWithTags'
import trackView from './trackView'
import getDownloadLink from  './getDownloadLink'
import storeByLink from './storeByLink'
import getAllByCreator from './getAllByCreator'
import getExportLink from './getExportLink'
import getPublic from './getPublic'
import shareByChannel from './shareByChannel'
import retagMany from './retagMany'
import deleteMany from './deleteMany'
import renameMany from './renameMany'
import renameManyById from './renameManyById'
import retagManyByCreator from  './retagManyByCreator'
import storeMany from './storeMany'
import getIdsBySearch from './getIdsBySearch'
import showDownloadHistories from './showDownloadHistories'
import getAllByCreatorForChannel from './getAllByCreatorForChannel'
import exportForCommunity from './exportForCommunity'
import moderate from "./modarate"
import updateInClient from "./updateInClient"
import getAllForPlaylistTags from './getAllForPlaylistTags'
import retagManyInChannel from './retagManyInChannel'
import renameManyInChannel from './renameManyInChannel'
import deleteManyInChannel from './deleteManyInChannel'

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  get: get(host),
  update: update(host),
  download: download(host),
  getAllWithTags: getAllWithTags(host),
  trackView: trackView(host),
  getDownloadLink: getDownloadLink(host),
  storeByLink: storeByLink(host),
  getAllByCreator: getAllByCreator(host),
  getExportLink: getExportLink(host),
  getPublic: getPublic(host),
  shareByChannel: shareByChannel(host),
  retagMany: retagMany(host),
  deleteMany: deleteMany(host),
  renameMany: renameMany(host),
  renameManyById: renameManyById(host),
  retagManyByCreator: retagManyByCreator(host),
  storeMany: storeMany(host),
  getIdsBySearch: getIdsBySearch(host),
  showDownloadHistories: showDownloadHistories(host),
  getAllByCreatorForChannel: getAllByCreatorForChannel(host),
  exportForCommunity: exportForCommunity(host),
  moderate: moderate(host),
  updateInClient: updateInClient(host),
  getAllForPlaylistTags: getAllForPlaylistTags(host),
  retagManyInChannel: retagManyInChannel(host),
  renameManyInChannel: renameManyInChannel(host),
  deleteManyInChannel: deleteManyInChannel(host),
})
