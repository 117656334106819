import React from "react"
import localization from "../../../config/localization";
import {getRangeOfNumber, makeWaveformUrl} from "../../../utils";
import tempos from "../../../config/tempos";
import volumes from "../../../config/volumes";
import {Link} from "react-router-dom";
import moment from "moment";
import {IconButton} from "@material-ui/core";
import {Delete, Edit, MoreHoriz} from "@material-ui/icons";
import {request} from "../../../api-client";
import MusicVersionDialog from "./MusicVersionDialog";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";

class SubComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      versions: [],
      showMusicVersionDialog: false,
      musicId: props.row.original.id,
      selectedVersion: null
    }
  }

  componentDidMount = () => {
    this.requestMusicVersions()
  }

  requestMusicVersions = () =>
    request.music.get(this.props.row.original.id, 'include=versions.audio.waveform;versions.audioAiff')
      .then(music => this.setState({versions: music.versions}))


  updateMusicVersion = (selectedVersion) => this.setState({showMusicVersionDialog: true, selectedVersion})

  deleteMusicVersion = (selectedVersion) =>
    GlobalConfirmationDialog.showDelete({
      elementName: selectedVersion.title,
      deleteRequest: () => request.musicVersion.delete(selectedVersion.id),
      onSuccess: () => this.setState({versions: this.state.versions.filter(version => version.id !== selectedVersion.id)})
    })


  buttons = (version, versions) => {
    const {id, title} = version;

    const builder = new TableButtonsBuilder();

    builder.withDownload(() => request.musicVersion.download(id), { title: title, duration: version.audio.duration, type: "music" });

    const buttonMusics = versions.map(tableMusic => ({
      title: tableMusic.title,
      fetchAudioUrl: () => request.musicVersion.audioUrl(tableMusic.id),
      waveform: makeWaveformUrl(tableMusic)
    }));

    const providedMusicIndex = versions.findIndex(x => x.id === id);

    builder.withNewPlay(buttonMusics, providedMusicIndex);

    builder.withOther(
      <TableButton onClick={() => this.updateMusicVersion(version)} title={localization.get('edit')}>
        <Edit/>
      </TableButton>
    );

    builder.withOther(
      <TableButton onClick={() => this.deleteMusicVersion(version)} title={localization.get('delete')}>
        <Delete/>
      </TableButton>
    );

    return builder.build();
  }

  render = () => {

    const {row} = this.props;
    const {versions, showMusicVersionDialog, musicId, selectedVersion} = this.state;

    return (
      <React.Fragment>

        <MusicVersionDialog
          show={showMusicVersionDialog}
          musicId={musicId}
          version={selectedVersion}
          handleClose={() => {
            this.setState({showMusicVersionDialog: false})
          }}
        />

        {row.original.details &&
          <div style={{ width: "100%", float: "left", position: "relative", padding: "10px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('music.details')}:</div>
            <div style={{ float: "left", position: "relative", marginLeft: "30px" }}>{row.original.details}</div>
          </div>
        }

        {row.original.midLevelFeature && row.original.midLevelFeature.tempo &&
          <div style={{ width: "100%", float: "left", position: "relative", padding: "10px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('music.tempo')}:</div>
            <div style={{ float: "left", position: "relative", marginLeft: "30px" }}>{`${row.original.midLevelFeature.tempo} (${localization.get(`tempo.${getRangeOfNumber(row.original.midLevelFeature.tempo, tempos)}`)})`}</div>
          </div>
        }

        {row.original.lowLevelFeature && row.original.lowLevelFeature.rms &&
          <div style={{ width: "100%", float: "left", position: "relative", padding: "10px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('music.volume')}:</div>
            <div style={{ float: "left", position: "relative", marginLeft: "30px" }}>{`${row.original.lowLevelFeature.rms} (${localization.get(`volume.${getRangeOfNumber(row.original.lowLevelFeature.rms, volumes)}`)})`}</div>
          </div>
        }

        {row.original.albums.length > 0 && (
          <div style={{padding: "10px", float: "left", position: "relative", width: "100%"}}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold"}}>{localization.get('music.albums')}</div>
            {row.original.albums.map(album => (
              <Link
                style={{
                  width: "auto",
                  float: "left",
                  position: "relative",
                  marginLeft: "20px",
                  marginRight: "20px",
                  textDecoration: "underline",
                  color: "black"
                }}
                to={`/albums/${album.id}/edit`}
              >
                {album.title}
              </Link>
            ))}
          </div>
        )}

        {row.original.catalog && (
          <div style={{ float: "left", position: "relative", width: "100%", padding: "10px" }}>
            <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('table.catalog')}</div>
            <div style={{ float: "left", position: "relative", marginLeft: "30px" }}>{`${row.original.catalog.name} (${localization.get(row.original.catalog.visibility)})`}</div>
          </div>
        )}

        {row.original.artist &&
          <div style={{ float: "left", position: "relative", width: "100%", padding: "10px" }}>
            <div style={{ fontWeight: "bold", float: "left", position: "relative" }}>{localization.get('music.artist')}: </div>
            <div style={{ marginLeft: "15px", float: "left", position: "relative" }}>
              <Link
                style={{
                  width: "auto",
                  float: "left",
                  position: "relative",
                  marginLeft: "20px",
                  marginRight: "20px",
                  textDecoration: "underline",
                  color: "black"
                }}
                to={`/artists/${row.original.artist.id}/edit`}
              >
                {row.original.artist.name} ({localization.get(row.original.artist.type)})
              </Link>
            </div>
          </div>
        }

        {versions.length > 0 &&
          <div style={{ float: "left", position: "relative", width: "100%",  padding: "10px" }}>
            <div style={{ float: "left", position: "relative", width: "100%", fontWeight: "bold", marginBottom: "15px" }}>{localization.get('music.versions')}: </div>
            <table id="music-table">
              <tr>
                <th>{localization.get('music.title')}</th>
                <th style={{textAlign: "center"}}>{localization.get('table.actions')}</th>
              </tr>
              {versions.map(version =>
                <tr>
                  <td>{version.title}</td>
                  <td style={{width: 160}}>{this.buttons(version, versions)}</td>
                </tr>
              )}
            </table>
          </div>
        }

        {!row.original.active &&
          <div style={{ float: "left", position: "relative", width: "100%", padding: "10px" }}>
            <div style={{ fontWeight: "bold", float: "left", position: "relative" }}>{localization.get('music.inactive_motive')}: </div>
            <div style={{ marginLeft: "15px", float: "left", position: "relative" }}>{row.original.inactive_reason}</div>
          </div>
        }

        {row.original.reservedBy &&
          <div style={{ float: "left", position: "relative", width: "100%", padding: "10px" }}>
            <div style={{ fontWeight: "bold", float: "left", position: "relative" }}>{localization.get('music.exclusive_validity')}</div>
            <div style={{ marginLeft: "4px", float: "left", position: "relative" }}>({row.original.reservedBy.name})</div>
          </div>
        }

        {row.original.licenses.length > 0 &&
          <div style={{ float: "left", position: "relative", width: "100%", marginTop: "20px" }}>
            <div style={{ float: "left", position: "relative", width: "100%", fontWeight: "bold", marginBottom: "15px" }}>{localization.get('music.last_licenses')}: </div>
            <table id="music-table">
              <tr>
                <th>{localization.get('music.license_table.date')}</th>
                <th>{localization.get('music.license_table.client')}</th>
                <th>{localization.get('music.license_table.type')}</th>
                <th>{localization.get('music.license_table.brand')}</th>
                <th>{localization.get('music.license_table.product')}</th>
                <th>{localization.get('music.license_table.campaign')}</th>
                <th>{localization.get('music.license_table.details')}</th>
              </tr>
              {row.original.licenses.map(license =>
                <tr>
                  <td>{moment(license.starts_at).format('DD-MM-YYYY')}</td>
                  <td>{license.client ? license.client.name : ''}</td>
                  <td>{license.type.name}</td>
                  <td>{license.project.brand}</td>
                  <td>{license.project.product}</td>
                  <td>{license.project.title}</td>
                  <td>
                    <IconButton onClick={() => this.props.openLicenseDetailsDialog(license.id)}>
                      <MoreHoriz />
                    </IconButton>
                  </td>
                </tr>
              )}
            </table>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default SubComponent
