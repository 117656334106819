import React, {Component} from "react";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@material-ui/core";
import localization from "../../../../config/localization";
import {request} from "../../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import browserHistory from "../../../../utils/browserHistory";
import {getContentFromMaterial} from ".";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import {getPropertySafe} from "../../../../utils";
import {licenseTypeIdToString} from "../../../../components/Letflow/LicenseWizard/utils/mappers";
import {userIsSysAdmin} from "../../../../api-client/core/authentication/utils";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

const tags = [
  {
    value: "radio",
    label: localization.get("license_wizard.media.radio"),
    type: "audio"
  },
  {
    value: "tv",
    label: localization.get("license_wizard.media.television"),
    type: "video"
  },
  {
    value: "internet_cctv",
    label: localization.get("internet_cctv"),
    type: "video"
  },
  { value: "cine", label: localization.get("cine"), type: "video" }
];

export class SetOnlineDialog extends Component {
  state = {
    creativity: "",
    description: "",
    direction: "",
    producer: "",
    title: "",
    license: null,
    sendEmail: true,
    tags: [],
    client: null
  };

  onSetOnline = () => {
    request.onlineVideos
      .online(
        this.props.pitchId,
        {
          ...this.state,
          message_id: this.props.message.id,
          tags: this.state.tags.map(x => x.value),
          link: getContentFromMaterial(this.props.message),
          license_id: getPropertySafe(this.state.license, "value"),
          client_id: this.state.client && this.state.client.value
        },
        this.state.sendEmail
      )
      .then(() => {
        GlobalSnackbar.showGenericSuccess();
        // Online videos path is created in the parent component,
        // that is why it does not have to be called like a function like in other places
        browserHistory.push(this.props.onlineVideosPath);
      })
      .catch(e => {
        if (e.status === 403) {
          GlobalSnackbar.show({
            message: localization.get("pitches.action_for_admin_users"),
            type: GlobalSnackbarTypes.ERROR
          });
        } else {
          GlobalSnackbar.showGenericError();
        }
      });
  };
  render = () => {
    const {clients} = this.props;

    return (
      <Dialog
        PaperProps={{ style: { width: 500, maxWidth: "100%" } }}
        open={true}
        onClose={this.props.onClose}
      >
        <ValidatorForm
          ref="form"
          onSubmit={this.onSetOnline}
        >
          <DialogTitle>{localization.get("set_online_dialog_title")}</DialogTitle>
          <DialogContent>
            <p>{localization.get("set_online_dialog_content")}</p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendEmail}
                  color="default"
                  onChange={e => this.setState({ sendEmail: e.target.checked })}
                />
              }
              label={localization.get("approved_material.send_email")}
            />
            <TextValidator
              name="title"
              style={{ marginBottom: 15, width: "100%" }}
              value={this.state.title}
              onChange={e => this.setState({ title: e.target.value })}
              label={localization.get("title")}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />
            <TextValidator
              name="director"
              style={{ marginBottom: 15, width: "100%" }}
              value={this.state.direction}
              onChange={e => this.setState({ direction: e.target.value })}
              label={localization.get("director")}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />
            <TextValidator
              name="producer"
              style={{ marginBottom: 15, width: "100%" }}
              value={this.state.producer}
              onChange={e => this.setState({ producer: e.target.value })}
              label={localization.get("producer")}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />
            <TextValidator
              name="creativity"
              style={{ marginBottom: 15, width: "100%" }}
              value={this.state.creativity}
              onChange={e => this.setState({ creativity: e.target.value })}
              label={localization.get("creativity")}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />
            <TextValidator
              name="description"
              style={{ marginBottom: 15, width: "100%" }}
              value={this.state.description}
              onChange={e => this.setState({ description: e.target.value })}
              label={localization.get("description")}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
              multiline
            />
            <br />
            <p>{localization.get("approve_material.tags")}</p>
            <Select
              options={tags
                .filter(x => x.type === this.props.type)
                .map(x => ({ value: x.value, label: x.label }))}
              value={this.state.tags}
              onChange={tags => this.setState({ tags: tags })}
              isMulti
            />
            <br />
            <p>{localization.get("client")}</p>
            <Select
              options={clients.map(client => ({ value: client.id, label: client.name }))}
              value={this.state.client}
              onChange={client => this.setState({client})}
            />
            <br />
            {/** Only flowlike users can assign license to online video */}
            {(userIsSysAdmin() && this.state.client) && (
              <React.Fragment>
                <p>{localization.get("approve_material.license")}</p>
                <AsyncSelect
                  loadOptions={value => {
                    return request.license
                      .getAll({
                        page: 0,
                        perPage: 20,
                        filterBy: [
                          { column: "music[title]", filter: value || "" },
                          {
                            column: "client[id]",
                            filter: this.state.client.value
                          }
                        ]
                      })
                      .then(res =>
                        res.data.map(x => ({
                          value: x.id,
                          label: (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <span>
                                {x.music.title +
                                  (this.props.clientsIds
                                    ? ""
                                    : ` (${x.client.name}) `)}
                              </span>
                              <span>{licenseTypeIdToString(x.type.id)}</span>
                            </div>
                          )
                        }))
                      );
                  }}
                  value={this.state.license}
                  onChange={license => this.setState({ license })}
                  defaultOptions
                />
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>{localization.get("close")}</Button>
            <Button type="submit">{localization.get("send")}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  };
}
