import {request} from "../";
import _ from "lodash";
import {accessTokenRefreshFinished$, isRefreshingAccessToken} from "./authentication/refreshAccessToken";
import {shouldUseTokensInHeader} from "./authentication/utils"
import {isNullOrUndefined} from "util";
import {channelPathGenerator, getAccessToken, getActiveChannel, getActiveClient} from "./authentication/utils";
import localization from "../../config/localization";
import browserHistory from "../../utils/browserHistory";
import EventManager, {events} from "../../utils/EventManager";

export const addPutMethodToForm = form => {
  form.append("_method", "PUT");
  return form;
};


export const addPutMethodToJson = json => {
  json._method = "PUT"

  return json;
};

const httpMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  canHaveBody: function(method) {
    return method === this.POST || method === this.PUT;
  }
};

const bodyIsJson = (body, method) => {
  if (isNullOrUndefined(body)) {
    return false;
  }
  if (httpMethods.canHaveBody(method)) {
    try {
      JSON.parse(body);
    } catch (error) {
      return false;
    }
    return true;
  }
  return false;
};

const makeHttpRequest = (
  url,
  method,
  body,
  headers,
  onProgress,
  downloadOnProgress,
  refreshAccessToken = true,
  useAuth = true,
  shouldRefresh = true,
) => {
  const mainFetch = () =>
    new Promise(resolve => {
      const r = new XMLHttpRequest();

      r.open(method, url, true);
      r.setRequestHeader("Accept", "application/json");
      r.setRequestHeader("Content-Language", localization.getLanguage());

      if (useAuth && shouldUseTokensInHeader()) {
        r.setRequestHeader("Authorization", `Bearer ${getAccessToken()}`);
      }

      if (bodyIsJson(body, method)) {
        r.setRequestHeader("Content-type", "application/json");
      }

      _.entries(headers).forEach(h => r.setRequestHeader(h[0], h[1]));
      r.withCredentials = true;

      if (onProgress) {
        r.upload.onprogress = e => onProgress(e.loaded, e.total);
      }

      if (downloadOnProgress) {
        r.onprogress = e => downloadOnProgress(e.loaded, e.total);
        r.responseType = 'arraybuffer';
      }

      r.onreadystatechange = () => {
        if (r.readyState === 4) {
          r.status === 503 && EventManager.getInstance().dispatch(events.SET_MAINTENANCE_MODE)

          resolve({
            ok: r.status >= 200 && r.status < 300,
            status: r.status,
            statusText: r.statusText,
            json: () => Promise.resolve(JSON.parse(r.responseText)),
            blob: () => Promise.resolve(r.response),
            text: () => Promise.resolve(r.responseText),
            arrayBuffer: () => Promise.resolve(r.response)
          });
        }
      };
      r.send(body);
    });

  const waitUntilAccessTokenRefreshFinishes = () =>
    new Promise(resolve => {
      let subscription;
      subscription = accessTokenRefreshFinished$.subscribe(() => {
        subscription.unsubscribe();
        resolve();
      });
    });

  const fetchWithAuthenticationHandler = () =>
    mainFetch().then(response => {
      if (refreshAccessToken) {
        if (response.status === 401 && shouldRefresh) {
          if (isRefreshingAccessToken()) {
            return waitUntilAccessTokenRefreshFinishes().then(mainFetch);
          }
          return request.authentication.refreshAccessToken().then(mainFetch);
        }
      }
      if (!response.ok) {
        if(response.status === 429) {
          getActiveChannel() ? browserHistory.push(channelPathGenerator('error-page')) : browserHistory.push('error-page')
        }

        return response.text().then(text =>
          Promise.reject({
            status: response.status,
            error: text
          })
        );
      }
      return response;
    });

  return fetchWithAuthenticationHandler();
};

export const post = (url, body, headers, onProgress, refreshAccessToken, useAuth, shouldRefresh) =>
  makeHttpRequest(
    url,
    httpMethods.POST,
    body,
    headers,
    onProgress,
    null,
    refreshAccessToken,
    useAuth,    
    shouldRefresh,
  );

export const put = (url, body, headers, onProgress, refreshAccessToken, useAuth) =>
  makeHttpRequest(
    url,
    httpMethods.PUT,
    body,
    headers,
    onProgress,
    null,
    refreshAccessToken,
    useAuth,
  );

export const get = (url, headers, withTimestamp = false, downloadOnProgress = null, forceRemoveTS = false) => {
  if (!forceRemoveTS && (withTimestamp || !getActiveClient() && !getActiveChannel())) {
    url += (url.includes("?") ? "&" : "?") + "ts=" + Date.now();
  }
  return makeHttpRequest(url, httpMethods.GET, null, headers, null, downloadOnProgress);
};

export const del = (url, headers) =>
  makeHttpRequest(url, httpMethods.DELETE, null, headers);

export const makeFileUploadBody = (type, file, isEditorialImage) => {
  const formData = new FormData();
  formData.append(type, file, file.name);
  if (isEditorialImage) formData.append("isEditorialImage", isEditorialImage);
  return formData;
};

export const appendPaginationQueryParamsToUrl = (
  url,
  { alreadyHasQueryParams, perPage, page, filterBy, orderBy } = {}
) => {
  const qps = [];
  if (typeof perPage === "number") qps.push(`per_page=${perPage}`);
  if (typeof page === "number") qps.push(`page=${page + 1}`);
  if (orderBy)
    qps.push(`order_by=${orderBy.column}`, `sort_type=${orderBy.type}`);
  if (filterBy)
    filterBy
      .filter(x => !isNullOrUndefined(x.filter))
      .forEach(filter => qps.push(`${filter.column}=${filter.filter}`));
  if (qps.length === 0) {
    return url;
  }
  const qpsInitialOperator = alreadyHasQueryParams ? "&" : "?";
  return url + qpsInitialOperator + qps.join("&");
};
