import {post} from "../utils";
import {isNullOrUndefined} from 'util'

const form = (name, email, password, channel_id, return_url=null, identity_number, birth_date, address) => {
  const f = new FormData()
  f.append('name', name)
  f.append('email', email)
  f.append('password', password)
  f.append('channel_id', channel_id)
  if (!isNullOrUndefined(identity_number)) f.append('identity_number', identity_number)
  if (!isNullOrUndefined(birth_date)) f.append('birth_date', birth_date)
  if (!isNullOrUndefined(return_url)) f.append('return_url', return_url);

  if (!isNullOrUndefined(address)) {
    if(address.name) f.append(`address[name]`, address.name);
    if(address.address) f.append(`address[address]`, address.address);
    if(address.city) f.append(`address[city]`, address.city);
    if(address.zip_code) f.append(`address[zip_code]`, address.zip_code);
    if(address.google_maps_link) f.append(`address[google_maps_link]`, address.google_maps_link || "");
    if(address.phone) f.append(`address[phone]`, address.phone || "");
    if(address.description) f.append(`address[description]`, address.description || "");
  };

  return f
}

export default host =>
  ({
     name, email, password, channel_id, return_url=null, identity_number, birth_date, address
  }) => post(`${host}/register`, form(name, email, password, channel_id, return_url, identity_number, birth_date, address), {}, () => {}, false)
  .then(result => result.json())