import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import Footer from "../../../layouts/Home/HomeElements/Footer";
import AddressInput from "../../../components/Letflow/AddressInput";
import {
  channelPathGenerator,
  getActiveChannel,
} from "../../../api-client/core/authentication/utils";
import {Person} from "@material-ui/icons"
import ChannelPublicNavBar from "../../../layouts/Channel/ChannelPublicNavBar";
import TypeformPopup from "../../../components/Letflow/TypeformPopup";
import queryString from 'querystring'
import {Checkbox, FormControlLabel, IconButton} from "@material-ui/core";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {get} from "lodash"
import moment from "moment";

class Register extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimation: "cardHidden",
      channel: {},
      requesting: true,
      registered: false,
      inputType: "password"
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );

    request.channel.getPublic(getActiveChannel() ? getActiveChannel() : window.location.pathname.split('/')[2])
      .then(channel => {
        if (channel.visibility === "closed") window.location.href = getActiveChannel() ? `/login` : `/channel/${channel.id}/login`
        document.title = localization.get('title.login') + ' - ' + channel.name
        this.setState({channel, requesting: false, extendRegistration: JSON.parse(get(channel, "extend_registration", null))})
      })

  }

  register = () => {
    if (this.validate()) {
      this.setState({ requesting: true })
      let redirectTo = queryString.parse(this.props.location.search)['?redirect_to'];
      
      request.authentication.register({ 
        name: this.state.name,
        email: this.state.username,
        password: this.state.password,
        channel_id: this.state.channel.id,
        return_url: redirectTo,
        identity_number: this.state.identification,
        ...this.state.identification ? {identity_number: this.state.identification} : {},
        ...this.state.birthdate ? { birthdate: moment(this.state.birthdate).format('YYYY-MM-DD')}: {},
        address: this.state.address
      })
        .then(response => {
          // GAevent('Usuario', "Registro", "")
          analyticsInstance.register({type: "Feater"})
          this.setState({ requesting: false, registered: true, userId: response.id, name: response.name, email: response.email })
          GlobalSnackbar.show({
            message: localization.get(this.state.channel.visibility === "private" ? 'login.register_complete_private' : "login.register_complete_public"),
            type: GlobalSnackbarTypes.SUCCESS,
          })
        })
        .catch(e => {
          this.setState({ requesting: false })
          GlobalSnackbar.show({
            message: e.status == 406  ? localization.get('login.channel_already_register') : localization.get('login.login_failed'),
            type: GlobalSnackbarTypes.ERROR,
          })
        })
    }
  }

  validate = () => {
    if(!this.state.name) {
      GlobalSnackbar.show({
        message: localization.get('login.name_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }else if(!this.state.username) {
      GlobalSnackbar.show({
        message: localization.get('login.email_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }else if(!this.state.password) {
      GlobalSnackbar.show({
        message: localization.get('login.password_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }else if(!this.state.identification && get(this.state.extendRegistration, "identification_number", "none") == "required") {
      GlobalSnackbar.show({
        message: localization.get('login.identification_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }else if(!this.state.birthdat && get(this.state.extendRegistration, "birthdate", "none") == "required") {
      GlobalSnackbar.show({
        message: localization.get('login.birthdate_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }else if(!this.state.address && get(this.state.extendRegistration, "address", "none") == "required") {
      GlobalSnackbar.show({
        message: localization.get('login.address_required'),
        type: GlobalSnackbarTypes.WARNING,
      })
      return false
    }
    
    return true;
  }


  getLoginUrl = () => {
    let redirectTo = queryString.parse(this.props.location.search)['?redirect_to'];
    return redirectTo ? channelPathGenerator(`login?redirect_to=${redirectTo}`, this.state.channel) : channelPathGenerator('login', this.state.channel);
  }

  render() {
    const { classes } = this.props;
    const { channel, requesting, extendRegistration} = this.state;

    return (
      <React.Fragment>
        <ChannelPublicNavBar channel={channel} />
        {this.state.registered && this.state.channel.extra_registration &&
          <TypeformPopup
            name={this.state.name}
            email={this.state.email}
            userid={this.state.userId}
            closeTime={10000}
            typeformUrl={this.state.channel.extra_registration}
            onSubmit={() => request.user.completeFormUnlogged(this.state.channel.id, this.state.userId)}
          />
        }
        <div className={classes.content} style={{minHeight: "calc(100vh - 147px)"}}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimation]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>{localization.get(!this.state.registered ? 'register' : 'register.successfully')}</h4>
                    </CardHeader>
                    <CardBody>
                      {!this.state.registered ?
                        <>
                          <CustomInput
                            labelText={localization.get('form.name')}
                            id="name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <Person className={classes.inputAdornmentIcon}/>
                                  </IconButton>
                                </InputAdornment>
                              ),
                              onChange: e => this.setState({name: e.target.value})
                            }}
                          />
                            <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                            fullWidth: true
                          }}
                            inputProps={{
                              endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <Email className={classes.inputAdornmentIcon} />
                                </IconButton>
                              </InputAdornment>
                              ),
                              onChange: e => this.setState({username: e.target.value})
                            }}
                          />
                          <CustomInput
                            labelText={localization.get('password')}
                            id="password"
                            formControlProps={{fullWidth: true}}
                            inputProps={{
                              endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                              <LockOutline
                              className={classes.inputAdornmentIcon}
                              />
                              </IconButton>
                              </InputAdornment>
                              ),
                              onChange: e => this.setState({password: e.target.value}),
                              type: this.state.inputType,
                              onKeyPress: e => {
                                if (e.key === 'Enter') {
                                e.preventDefault()
                              }}
                            }}
                          />
                          {get(extendRegistration, "identity_number", "none") != "none" &&
                            <CustomInput
                              labelText={localization.get('DNI')}
                              id="ID"
                              formControlProps={{fullWidth: true}}
                              inputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                <LockOutline
                                className={classes.inputAdornmentIcon}
                                />
                                </IconButton>
                                </InputAdornment>
                                ),
                                onChange: e => this.setState({identification: e.target.value}),
                                onKeyPress: e => {
                                  if (e.key === 'Enter') {
                                  e.preventDefault()
                                }}
                              }}
                            />
                          }
                          {get(extendRegistration, "birthdate", "none") != "none" &&
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <br/>
                            <DatePicker
                                fullWidth
                                autoOk
                                keyboard
                                clearable
                                onlyCalendar
                                disableFuture
                                defaultValue={null}
                                format={"dd/MM/yyyy"}
                                label={localization.get("birthdate")}
                                value={this.state.birthdate}
                                onChange={(e) => {
                                  this.setState({birthdate: e})}
                                }
                              />
                            </MuiPickersUtilsProvider>
                          }
                          <br/>
                          {get(extendRegistration, "address", "none") != "none" &&
                            <AddressInput
                              ref={ref => this.addressRef = ref}
                              address={this.state.address}
                              onAddressChange={(address) => this.setState({address: address})}
                              buttonColor="black"
                              noAddressText={localization.get("form.address.load_address")}
                            />
                          }
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="inputType"
                                checked={this.state.inputType === "text"}
                                onChange={() => {
                                  this.setState({ inputType: this.state.inputType === "text" ? "password" : "text"})
                                }}
                              />
                            }
                            label={localization.get('show_password')}
                          />
                        </>
                        :
                        <p style={{
                          paddingTop: 15,
                          textAlign: "center",
                          color: "black",
                          fontSize: "0.875rem"
                        }}>{localization.get('login.register_complete_check_email')}</p>
                      }
                      {!requesting && !this.state.registered &&
                      <>
                        <Link to={this.getLoginUrl()}>
                          <p style={{
                            paddingTop: 15,
                            textAlign: "center",
                            color: "black",
                            fontSize: "0.875rem",
                            cursor: "pointer"
                          }}>{localization.get('register.return_login')}</p>
                        </Link>
                      </> 
                      }
                    </CardBody>
                    {!this.state.registered &&
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.register} color="default" style={{backgroundColor: "black"}} simple
                              size="lg" block disabled={this.state.requesting}>{localization.get('send')}</Button>
                    </CardFooter>
                    }
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer channel={this.state.channel}/>
      </React.Fragment>
    )
      ;
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(Register);
