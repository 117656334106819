import React from "react";
import {TextField} from "@material-ui/core";

export default ({ id, value, label, onChange, disabled = false, multiline = false, numeric = false, type = 'text', style }) =>
  <TextField
    style={style}
    id={id}
    label={label}
    value={value}
    onChange={e => {
      if (!disabled)
        onChange(e)
    }}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      disabled
    }}
    type={numeric ? 'number' : type }
    multiline={multiline}
    margin="normal"
    fullWidth
  />