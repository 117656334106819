import React, {Component} from "react";

import {request} from "../../../api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import tempos from "../../../config/tempos";
import volumes from "../../../config/volumes";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {
  state = {
    id: false,
    type: 'global',
    image: { src: null, file: null },
    catalogCategories: [],
    catalogCategoryId: '',
    disabled: false,
    userLibrary: true,
    selectedMusics: [],
    visibility: 'public',
    contentType: 'musics',
    selectedTags: [],
    unselectedTags: [],
    selectedClients: [],
    tagGroups: [],
    tempo: '',
    volume: '',
    duration: null,
    loaded: false,
    recent: false,
    brands: [],
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      subtitle: "",
    })),
  };

  requestPlaylist = () => {
    const id = this.props.match.params.id;
    return request.playlist.getGlobal(id, 'include=category;image;translations;clients;tags;musics.audio.waveform;musics.tags;brands').then(playlist => {
      const translations = AvailableLanguages.map(language => {
        const translation = playlist.translations.find(tr => tr.locale ===language)

        return {
          language: language,
          title: translation ? translation.title : "",
          subtitle: translation ? translation.subtitle : "",
        };
      })
      let selectedTags = playlist.tags.filter(tag => tag.wanted === 1).map(tag => tag.id)
      let unselectedTags = playlist.tags.filter(tag => tag.wanted === 0).map(tag => tag.id)

      this.setState({
        id: playlist.id,
        type: playlist.type,
        translations: translations,
        userLibrary: playlist.user_library,
        image: {
          src: getUpdateFormImageUrlFromItem(playlist),
          file: null,
        },
        catalogCategoryId: playlist.category ? playlist.category.id : null,
        selectedMusics: playlist.musics,
        selectedTags: selectedTags,
        unselectedTags: unselectedTags,
        selectedClients: playlist.clients.map(client => ({value: client.id, label: client.name})),
        visibility: playlist.clients.length > 0 ? 'private' : 'public',
        contentType: playlist.recent ? 'recent' : playlist.tags.length > 0 ? 'tags' : 'musics',
        tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags, unselectedTags),
        recent: playlist.recent,
        brands: playlist.brands.map(brand => ({ value: brand.id, label: brand.name })),
      })

      if (playlist.query) {
        this.setExtraParams(playlist.query)
      }
    });
  };

  setExtraParams = (query) => {
    let params = query.split('&')

    let tempo = tempos.find(tempo => tempo.value === params.filter(tempo => tempo.indexOf('[tempo]') > -1).join('&'))
    let volume = volumes.find(volume => volume.value === params.filter(volume => volume.indexOf('[rms]') > -1).join('&'))

    let minDuration = params.find(param => param.indexOf("audio[duration][]=ge:") > -1)
    let maxDuration = params.find(param => param.indexOf("audio[duration][]=lt:") > -1)

    let duration = (minDuration && maxDuration) && {
      min: minDuration.split("audio[duration][]=ge:")[1],
      max: maxDuration.split("audio[duration][]=lt:")[1]
    }

    this.setState({tempo, volume, duration})

  }

  requestCategories = () => request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }))
  requestTagGroup = () => request.tagGroup.getAllOfTypeMusic().then((tagGroups) => this.setState({tagGroups},() => this.setState({loaded: true})))

  updateTagGroups = (tagGroups, selectedTags, unselectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ?
          'selected'
          : unselectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'unselected'
            :'available'
      }))
    }))
  }

  initializationRequest = () => this.requestCategories().then(() => this.requestTagGroup()).then(() => this.requestPlaylist());

  requestUpdate = () => {
    const {
      catalogCategoryId,
      translations,
      image,
      userLibrary,
      selectedMusics,
      selectedClients,
      selectedTags,
      unselectedTags,
      tempo,
      volume,
      duration,
      brands,
    } = this.state;

    return request.playlist
      .updateGlobal(this.props.match.params.id, {
        translations,
        imageFile: image.file,
        catalogCategoryId,
        userLibrary,
        musicIds: selectedMusics,
        selectedClients: selectedClients.map(client => client.value),
        selectedTags,
        unselectedTags,
        recent: this.state.contentType === "recent",
        query: `${tempo ? `&${tempo.value}` : ''}${volume ? `&${volume.value}` : ''}${duration ? `&audio[duration][]=ge:${duration.min}&audio[duration][]=lt:${duration.max}` : ''}`,
        brands: brands.map(brand => brand.value),
      })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/global_playlists")));
  };

  onImageChange = image => this.setState({ image });
  onRemovedItemsChanged= items => this.setState({ removedMusics: items })

  render = () => {
    const {
      type,
      disabled,
      catalogCategories,
      translations,
      image,
      userLibrary,
      selectedMusics,
      catalogCategoryId,
      visibility,
      contentType,
      selectedClients,
      tagGroups,
      volume,
      tempo,
      duration,
      recent,
      id,
      brands,
    } = this.state;

    return (
      <div>
        <FormViewContainer
          title={localization.get('playlist.update_playlist')}
          initializationRequest={this.initializationRequest}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestUpdate}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
        >
          {this.state.loaded &&
          <FormView
            type={type}
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            catalogCategories={catalogCategories}
            catalogCategoryId={catalogCategoryId}
            image={image}
            onSelectedItemsChanged={selectedClientIds => this.setState({ selectedClientIds })}
            onUserLibraryChange={e => this.setState({ userLibrary: e })}
            userLibrary={userLibrary}
            onChange={e => this.setState({ [e.target.id]: e.target.value })}
            onImageChange={image => this.setState({ image })}
            disabled={disabled}
            selectedMusics={selectedMusics}
            onSelectedMusicsChanged={selectedMusics => this.setState({
              selectedMusics,
              selectedTags: [],
              unselectedTags: [],
              tagGroups:this.updateTagGroups(this.state.tagGroups, [], [])
            })}
            visibility={visibility}
            onVisibilityChange={e => this.setState({visibility: e.target.value, selectedClients: [], catalogCategoryId: 1, brands: []})}
            contentType={contentType}
            onContentTypeChange={e => this.setState({contentType: e.target.value})}
            onAsyncClientsChange={selectedClients => this.setState({selectedClients})}
            selectedClients={selectedClients}
            onSelectedTagChanged={(selectedTags, unselectedTags) => this.setState({selectedTags, unselectedTags, selectedMusics: []})}
            tagGroups={tagGroups}
            tempo={tempo}
            onRemovedItemsChanged={this.onRemovedItemsChanged}
            onTempoChange={e => this.setState({tempo: e.target.value})}
            volume={volume}
            onVolumeChange={e => this.setState({volume: e.target.value})}
            duration={duration}
            onDurationChange={value => this.setState({duration: value})}
            recent={recent}
            id={id}
            brands={brands}
            onBrandsChange={brands => this.setState({ brands })}
          />
          }
        </FormViewContainer>
      </div>
    );
  };
}
