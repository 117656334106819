import React from "react";
import localization from "../../../config/localization";
import {request} from "../../../api-client";
import FeaturedVideo from "../OriginalMusic/FeaturedVideo";
import './Spotlight.css';
import PublicNavBar from "../HomeElements/PublicNavBar";
import InfiniteScroll from "react-infinite-scroll-component";
import PublicFooter from "../HomeElements/PublicFooter";
import {setPageTitle} from "../../../api-client/core/authentication/utils";

class Spotlight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: "",
      sent: false,
      spotlights: [],
      page: 1,
      hasMore: true,
      lastPage: 1
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("spotlights"))
    document.getElementsByTagName("html")[0].scrollTop = 0;
    this.requestSpotlight(1)
  };

  requestSpotlight = () => {
    if (this.state.page <= this.state.lastPage) {
      request.featuredVideo.allSpotlights(this.state.page)
        .then(spotlights => {
          let hasMore = spotlights.meta.current_page !== spotlights.meta.last_page
          this.setState(state => ({lastPage: spotlights.meta.last_page, spotlights: state.spotlights.concat(spotlights.data), page: state.page+1, hasMore}))
        })
    }
  }

  render() {
    return (
      <React.Fragment>
        <PublicNavBar showLinks={false}/>
        <div id="innerinner" style={{backgroundColor: "transparent"}}>
          <div className="spotlight-container">
            <h2 className="spotlight-title">{localization.get("spotlights")}</h2>
            {this.state.spotlights.length > 0 ?
              <InfiniteScroll
                dataLength={this.state.spotlights.length}
                next={this.requestSpotlight}
                hasMore={this.state.hasMore}
                loader={<h4>{localization.get('spotlights.loading')}</h4>}
              >
                {this.state.spotlights.map((spotlight) => (
                  <div className="spotlight-video">
                    <FeaturedVideo style={{width: "100%"}} key={spotlight.id} videoSrc={spotlight.video.url + '#t=2'} title={spotlight.title} subtitle={spotlight.subtitle} />
                  </div>
                ))}
              </InfiniteScroll>
              :
              <h3>{localization.get('spotlight.no_result')}</h3>
            }
          </div>
        </div>
        <PublicFooter {...this.props}/>
      </React.Fragment>
    )
  }
}

export default Spotlight
