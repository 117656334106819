import React, {Component} from "react";
import {request} from "../../../api-client";
import ChannelForm from "../FormContent";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from '../../../config/localization'
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import {stateToHTML} from "draft-js-export-html";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {convertToRaw, EditorState} from "draft-js";
import AddressDialog from "../../../components/Letflow/AddressInput/AddressDialog";
import ShipmentDialog from "../../../components/Letflow/ShipmentDialog";
import {clearUnwantedStateStyles} from "../../../components/Letflow/RichTextEditor/utils";
import {paths} from "../../../config/channelPaths.json";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
import {addHttpsToUrl} from "../../../api-client/core/authentication/utils";
import {communityValidationOptions as validationOptions} from "../utils"

const options = {
  inlineStyles: {
    // Override default element (`strong`).
    BOLD: {element: 'b'},
    // Use a custom inline style. Default element is `span`.
    FONT12: {style: {fontSize: 12}},
    FONT14: {style: {fontSize: 14}},
    FONT16: {style: {fontSize: 16}},
    FONT18: {style: {fontSize: 18}},
    FONT20: {style: {fontSize: 20}},
  },
};

export default class extends Component {
  state = {
    name: "",
    email: "",
    imageId: "",
    image: { src: null, file: null },
    loginImageId: "",
    loginImage: { src: null, file: null },
    footerImageId: "",
    footerImage: { src: null, file: null },
    sidebarImageId: "",
    sidebarImage: { src: null, file: null },
    faviconId: "",
    favicon: { src: null, file: null },
    detail: "",
    type: "content",
    visibility: "public",
    client: "",
    googleAnalyticsId: "",
    googleTagManagerId: "",
    customUrl: "",
    channelContentCreatorTypes: [],
    socialLinks: [],
    catalogs: [],
    disableForm: false,
    clientIsCompleted: true,
    showHelper: false,
    showAdvancedSearch: "none",
    showSupport: false,
    showAbout: "none",
    tags: [],
    contentCreatorTypes: [],
    openNavbarDialog: false,
    navbarElements: [],
    binaryId: "",
    binary: {src: null, file: null},
    hasBinary: false,
    policyId: "",
    policy: {src: null, file: null},
    hasPolicy: false,
    bloomcloudChannel: true,
    loginType: 'default',
    language: 'es',
    languages: [{lang: "es", isMain: true}],
    canDownloadTracks: false,
    keywordsSEO: "",
    descriptionSEO: "",
    suggestedText: "",
    openDropDownDialog: false,
    dropDownElement: null,
    availableTagGroups: [],
    tagGroups: [],
    openTagGroupDialog: false,
    supportNegativeSearch: "0",
    trackingGroup: null,
    showLoginButton: false,
    availableChannelPlaylists: [],
    openTrackingGroupsDialog: false,
    preRollVideoId: "",
    preRollVideo: { src: null, file: null },
    hasFpsCertificate: false,
    hasExtraInformation: false,
    extraRegistration: "",
    showTagSelection: "0",
    showMercadoPagoForm: false,
    mercadoPagoClientId: "",
    mercadoPagoClientSecret: "",
    showPaypalForm: false,
    paypalClientId: "",
    paypalClientSecret: "",
    showGoogleAdsenseForm: false,
    googleAdsensePublisherId: "",
    showHomeAds: false,
    showContentAds: false,
    adUnitHomeDesktop: "",
    adUnitHomeMobile: "",
    adUnitContentDesktop: "",
    adUnitContentMobile: "",
    searchType: "single",
    hasEvents: "unactivated",
    showFilterOnSmartSearch: "active",
    selectedCCTTG: 0,
    radioEnabled: false,
    radioLink: "",
    contactMail: "",
    shipmentMethod: "addresses",
    addresses: [],
    channelShipments: [],
    showAddressDialog: false,
    editingAddress: null,
    showShippingDialog: false,
    editingShipping: null,
    showShipmentDialog: false,
    commercialMail: "",
    hasCommunity: false,
    communityCatalog: "",
    communityCCTypes: [],
    communityCanDeleteMaterials: "1",
    customShare: false,
    openEditCategoryDialog: false,
    editingCategory: null,
    useTools: false,
    useCustomRedirects: false,
    channelRedirects: [],
    showDate: false,
    noIndex: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      about: new EditorState.createEmpty(),
      welcome: new EditorState.createEmpty(),
      register: new EditorState.createEmpty(),
      registerConfirmed: new EditorState.createEmpty(),
      registerEvaluating: new EditorState.createEmpty(),
      evaluateApproved: new EditorState.createEmpty(),
      regionLimit: new EditorState.createEmpty(),
      loginRedirectText: "",
      globalSearchText: "",
      viewsLimit: "",
      restrictedContentText: '',
      fpsCertificateLimit: '',
      requireDrmText: '',
      loginText: "Login",
      supportText: "",
      boughtCompleteText: "",
      keywordsSEO: "",
      descriptionSEO: "",
      suggestedText: "",
      liveText: "",
      radioText: "",
    })),
    communityTranslations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      description: "",
      slogan: "",
      successMessage: "",
      pendingMessage: "",
      approvedMessage: "",
      declinedMessage: "",
    })),
    customScripts: "",
    isDemo: "1",
    redirectButton: null,
    skipTimePreRoll: null,
    subNavbarElement: null,
    subNavbarElements: [],
    parentElement: null,
    footerElements: [],
    openFooterDialog: false,
    footerElementTranslations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
    })),
    dropDownList: [],
    footerType: "simple",
    footerLogoSide: "left",
    footerTranslation: AvailableLanguages.map(language => ({
      language: language,
      title: new EditorState.createEmpty(),
    })),
    footerSocialPosition: "center",
    footerMargin: 'default',
    communityRequiresApproval: "not_required",
    communityEmail: "",
    communityPreferences: {
      redirectTo: "home",
      validations : validationOptions.map(val => ({[val]: false}))
    },
    tools: "none",
    hasExtendRegistration: false,
    extendRegistration: {
        birthdate: "none",
        address: "none",
        identityNumber: "none"
      },
    useBrandButton: false,
    editableFor: "none",
    navbarContentCreatorType: null,
    mobileNavbarType: "default",
    navbarSearchType: "content",
    useSidebarDesktop: "none",
    useFloatingContactButton: false,
    floatingButtonImage: {src: null, file: null},
    floatingButtonImageId: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.channelContentCreatorTypes.length !== this.state.channelContentCreatorTypes.length) {
      this.requestTagGroups()
    }
  }

  cleanAdUnit = (adunit) => {
    let cleanedAdUnit = "";
    if (adunit && adunit.length > 0) {
      let insStartIndex = adunit.indexOf('<ins ');
      cleanedAdUnit = adunit.slice(insStartIndex);
      let insEndIndex = cleanedAdUnit.indexOf('>');
      cleanedAdUnit = insEndIndex ? cleanedAdUnit.slice(0, insEndIndex + 1) : "";
      cleanedAdUnit = cleanedAdUnit.concat('<ins/>');
    }
    return cleanedAdUnit;
  }

  requestClientCreation = () => {
    let navbarElements = this.state.navbarElements;

    if (this.state.subNavbarElement && this.state.subNavbarElements.length) {
      //remove subnavbar elements for adding ordered later
      navbarElements = navbarElements.filter(e => e.parent !== this.state.subNavbarElement.id)
      navbarElements = navbarElements.concat(this.state.subNavbarElements);
      navbarElements.push(this.state.subNavbarElement);
    }

    return request.channel.create({
        ...this.state,
        name: this.state.name,
        email: this.state.email,
        imageId: this.state.imageId,
        loginImageId: this.state.loginImageId,
        footerImageId: this.state.footerImageId,
        sidebarImageId: this.state.sidebarImageId,
        faviconId: this.state.faviconId,
        detail: this.state.detail,
        type: this.state.type,
        visibility: this.state.visibility,
        client: this.state.client,
        googleAnalyticsId: this.state.googleAnalyticsId,
        googleTagManagerId: this.state.googleTagManagerId,
        customUrl: this.state.customUrl,
        socialLinks: this.state.socialLinks,
        catalogs: this.state.catalogs,
        channelContentCreatorTypes: this.state.channelContentCreatorTypes,
        showAdvancedSearch: this.state.showAdvancedSearch,
        tags: this.state.tags,
        showSupport: this.state.showSupport,
        showAbout: this.state.showAbout,
        navbarElements: navbarElements,
        binaryId: this.state.binaryId,
        policyId: this.state.policyId,
        bloomcloudChannel: this.state.bloomcloudChannel,
        loginType: this.state.loginType,
        languages: this.state.languages,
        canDownloadTracks: this.state.canDownloadTracks,
        supportNegativeSearch: this.state.supportNegativeSearch,
        tagGroups: this.state.tagGroups,
        trackingGroup: this.state.trackingGroup,
        showLoginButton: this.state.showLoginButton,
        preRollVideoId: this.state.preRollVideoId,
        extraRegistration: this.state.extraRegistration,
        showTagSelection: this.state.showTagSelection,
        mercadoPagoClientId: this.state.mercadoPagoClientId,
        mercadoPagoClientSecret: this.state.mercadoPagoClientSecret,
        paypalClientId: this.state.paypalClientId,
        paypalClientSecret: this.state.paypalClientSecret,
        googleAdsensePublisherId: this.state.googleAdsensePublisherId,
        adUnitHomeDesktop: this.cleanAdUnit(this.state.adUnitHomeDesktop),
        adUnitHomeMobile: this.cleanAdUnit(this.state.adUnitHomeMobile),
        adUnitContentDesktop: this.cleanAdUnit(this.state.adUnitContentDesktop),
        adUnitContentMobile: this.cleanAdUnit(this.state.adUnitContentMobile),
        searchType: this.state.searchType,
        hasEvents: this.state.hasEvents,
        showFilterOnSmartSearch: this.state.showFilterOnSmartSearch,
        radioEnabled: this.state.radioEnabled,
        radioLink: this.state.radioLink,
        commercialMail: this.state.commercialMail,
        customShare: this.state.customShare,
        useTools: this.state.useTools,
        channelRedirects: this.state.channelRedirects,
        showDate: this.state.showDate,
        noIndex: this.state.noIndex,
        useLicenses: this.state.useLicenses,
        usePlaylists: this.state.usePlaylists,
        translations: this.state.translations.map(translation => ({
          language: translation.language,
          about: translation.about && stateToHTML(translation.about.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(translation.about.getCurrentContent()))),
          welcome: translation.welcome && stateToHTML(translation.welcome.getCurrentContent(), options),
          register: translation.welcome && stateToHTML(translation.register.getCurrentContent(), options),
          registerConfirmed: translation.welcome && stateToHTML(translation.registerConfirmed.getCurrentContent(), options),
          registerEvaluating: translation.welcome && stateToHTML(translation.registerEvaluating.getCurrentContent(), options),
          evaluateApproved: translation.evaluateApproved && stateToHTML(translation.evaluateApproved.getCurrentContent(), options),
          regionLimit: translation.regionLimit && stateToHTML(translation.regionLimit.getCurrentContent(), options),
          loginRedirectText: translation.loginRedirectText,
          globalSearchText: translation.globalSearchText,
          login: translation.loginText,
          viewsLimit: translation.viewsLimit,
          restrictedContentText: translation.restrictedContentText,
          fpsCertificateLimit: translation.fpsCertificateLimit,
          requireDrmText: translation.requireDrmText,
          supportText: translation.supportText,
          boughtCompleteText: translation.boughtCompleteText,
          keywordsSEO: translation.keywordsSEO,
          descriptionSEO: translation.descriptionSEO,
          suggestedText: translation.suggestedText,
          liveText: translation.liveText,
          radioText: translation.radioText,
        })),
        communityTranslations: this.state.communityTranslations,
        communityCatalog: this.state.hasCommunity && this.state.communityCatalog,
        communityCCTypes: this.state.hasCommunity && this.state.communityCCTypes,
        communityCanDeleteMaterials: this.state.communityCanDeleteMaterials,
        hasCommunity: this.state.hasCommunity,
        customScripts: this.state.customScripts,
        isDemo: this.state.isDemo,
        footerType: "simple",
        footerLogoSide: "left",
        footerTranslation: [],
        footerSocialPosition: this.state.footerSocialPosition,
        footerMargin: this.state.footerMargin,
        communityRequiresApproval: this.state.communityRequiresApproval,
        communityPreferences: this.state.communityPreferences,
        communityEmail: this.state.communityEmail,
        useBrandButton: this.state.useBrandButton,
        editableFor: this.state.editableFor,
        purpose: this.state.purpose,
        navbarContentCreatorTypeId: (!this.state.hasCommunity && this.state.tools == "none" && this.state.navbarContentCreatorType && this.state.mobileNavbarType == "tabs") ? this.state.navbarContentCreatorType.value : null,
        navbarSearchType: this.state.navbarSearchType,
        mobileNavbarType: this.state.mobileNavbarType == "tabs",
        useSidebarDesktop: this.state.useSidebarDesktop == "sidebar",
        useFloatingContactButton: this.state.email && this.state.useFloatingContactButton,
        floatingButtonImageId: this.state.floatingButtonImageId,
      })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/channels")))
  }

  requestCCTypes = () => {
    request.contentCreatorType.getAll({
      filterBy: [
        {column: "private_client_id", filter: this.state.client.id},
        {column: "visibility", filter: 'private'}
      ]
    })
      .then(contentCreatorTypes => contentCreatorTypes.sort((a, b) => a.name.localeCompare(b.name)))
      .then(cCTypes => this.setState({contentCreatorTypes: cCTypes}))
  }

  onChange = e => {
    let { target } = e;
    this.setState({ [target.id]: target.type === 'checkbox' ? target.checked : target.value });
  };

  onPreRollVideoChange = video => video ? this.setState({preRollVideoId: video.id, preRollVideo: {src: null}, preRollVideoPreview: this.formNewUrl(video.path)}) : this.setState({preRollVideoId: null, preRollVideo: {src: null}})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onLoginImageChange = image => image ? this.setState({loginImageId: image.id, loginImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({loginImageId: null, loginImage: { src: null, filename: null }})
  onFooterImageChange = image => image ? this.setState({footerImageId: image.id, footerImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({footerImageId: null, footerImage: { src: null, filename: null }})
  onSidebarImageChange = image => image ? this.setState({sidebarImageId: image.id, sidebarImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({sidebarImageId: null, sidebarImage: { src: null, filename: null }})
  onFaviconChange = image => image ? this.setState({faviconId: image.id, favicon: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({faviconId: null, favicon: { src: null, filename: null }})
  onBinaryChange = binary => binary ? this.setState({binaryId: binary.id, binary: {src: null, filename: null }, hasBinary: true}) : this.setState({binaryId: null, binary: { src: null, filename: null }})
  onPolicyChange = policy => policy ? this.setState({policyId: policy.id, policy: {src: null, filename: null }, hasBinary: true}) : this.setState({policyId: null, policy: { src: null, filename: null }})
  onFloatingButtonImageChange = image => image ? this.setState({floatingButtonImageId: image.id, floatingButtonImage: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({floatingButtonImageId: null, floatingButtonImage: { src: null, filename: null }})

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  requestTagGroups = () =>  request.tagGroup.getAll({filterBy: [
      { column: 'types', filter: `${this.state.channelContentCreatorTypes.map(type => type.contentCreatorType.slug)}`},
      { column: 'creation_group', filter: '0'},
      { column: 'show_on_smart_search', filter: '1'},
    ]}, 'include=tagGroups')
    .then(tagGroups => this.setState({ availableTagGroups: tagGroups.filter(tagGroup => tagGroup.tags.length).map(tagGroup => ({...tagGroup, view_type: "pill"})) }))


  setPreview = (type) => {
    if (type === 'video') {
      this.setState({preRollVideo: { src: this.state.preRollVideoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }
  onAsyncContentCreatorTypesChange = selectedContentCreatorTypes => this.setState({ selectedContentCreatorTypes });

  onSubmitValidation = () => {
    if (!this.state.client) {
      GlobalSnackbar.show({message: localization.get('form.client_is_required'), type: GlobalSnackbarTypes.ERROR});
      this.setState({clientIsCompleted: false})
      return false
    }  else if (this.state.openNavbarDialog) {
      return false
    }else if(this.state.catalogs.length === 0) {
      GlobalSnackbar.show({message: localization.get('form.catalog_required'), type: GlobalSnackbarTypes.ERROR});
      return false
    }else if(this.state.channelRedirects.length > 0) {
      let allowed = true;

      this.state.channelRedirects.forEach(link => {
        paths.forEach(path => {
          if (!link.targetPath || link.targetPath.startsWith(path)) {
            allowed = false;
            link.error = true;
          }
          this.setState({channelRedirects: this.state.channelRedirects})
        })
      })
      if (!allowed) {
        GlobalSnackbar.show({
          message: localization.get('form.custom_url_check'),
          type: GlobalSnackbarTypes.ERROR
        });
      }else {
        const valueArr = this.state.channelRedirects.map(function(item){ return item.targetPath });
        const isDuplicate = valueArr.some(function(item, idx){
          return valueArr.indexOf(item) != idx
        });

        if (isDuplicate ){
          GlobalSnackbar.show({
            message: localization.get('form.custom_url_check_repeated'),
            type: GlobalSnackbarTypes.ERROR
          });

          return false;
        }
      }

      return allowed
    }else if(this.state.isDemo === "1" && this.state.customUrl && !this.state.customUrl.endsWith('feater.me')) {
      GlobalSnackbar.show({
        message: localization.get('form.validate_domain_demo'),
        type: GlobalSnackbarTypes.ERROR
      });

      return false;
    }else if(!this.state.communityEmail && this.state.hasCommunity && this.state.communityRequiresApproval !== "not_required") {
      GlobalSnackbar.show({
        message: localization.get('form.community_email_required'),
        type: GlobalSnackbarTypes.ERROR
      });

      return false
    }


    this.setState({clientIsCompleted: true})
    return true
  }

  addNavbarElement = element => {
    if(this.state.parentElement && this.state.parentElement.data_source === "sub_navbar") {
      this.state.subNavbarElements.push(element)
    }else {
      this.state.newElements.push(element)
      this.state.navbarElements.push(element)

      if (this.state.redirectButton) {
        return this.setState({
          navbarElements: this.state.navbarElements,
          newElements: this.state.newElements,
          channelRedirects: this.state.channelRedirects.map(redirectElement => {
            if (redirectElement.id === this.state.redirectButton) {
              return {
                ...redirectElement,
                home_element_id: element.id,
                redirectTo: `${addHttpsToUrl(this.state.customUrl)}/browse/${element.id}-search`
              }
            }

            return redirectElement;
          })
        })
      }
    }

    return this.setState({navbarElements: this.state.navbarElements, newElements: this.state.newElements, subNavbarElements: this.state.subNavbarElements, parentElement: null })
  }

  removeNavbarElement = elementId => {
    this.state.navbarElementsToDelete.push(elementId)
    this.setState({
      navbarElements: this.state.navbarElements.filter(element => element.id != elementId),
      subNavbarElements: this.state.subNavbarElements.filter(element => element.id != elementId),
      navbarElementsToDelete: this.state.navbarElementsToDelete
    })
  }

  openCloseEditDropDownDialog = (dropDownElement= null) => this.setState({openDropDownDialog: !this.state.openDropDownDialog, dropDownElement: dropDownElement, parentElement: dropDownElement})

  makeDownloadTagLink = () => {
    request.tag.generateDownloadLink(`?tag_group_id=${this.state.trackingGroup.value}`).then(url => {
      window.open(url, '_blank')
    })
  };

  updateAddress = (address) => {
    if (address.id || address.temporaryId) {
      const newAddresses = this.state.addresses.map(addr => {
        if (addr.id === address.id || (addr.temporaryId && addr.temporaryId === address.temporaryId)) {
          return ({...address})
        }

        return addr
      })

      this.setState({addresses: newAddresses})
    }else {
      this.state.addresses.push({...address, temporaryId: Math.random().toString(36).slice(-6)})
      this.setState({addresses: this.state.addresses})
    }
    this.setState({showAddressDialog: false, editingAddress: null})
  }

  updateShipment = (shipment) => {
    const newShipment = {
      id: shipment.id || null,
      temporaryId: shipment.temporaryId || null,
      location: shipment.location,
      prices: [
        {currency: "ARS", price: shipment.priceArs},
        {currency: "USD", price: shipment.priceUsd},
      ],
    }

    if (newShipment.id || newShipment.temporaryId) {
      const newChannelShipments = this.state.channelShipments.map(ship => {
        if (ship.id === newShipment.id || (ship.temporaryId && ship.temporaryId === newShipment.temporaryId)) return ({...newShipment})

        return ship
      })
      this.setState({channelShipments: newChannelShipments})
    }else {
      this.state.channelShipments.push({...newShipment, temporaryId: Math.random().toString(36).slice(-6)})
      this.setState({channelShipments: this.state.channelShipments})
    }

    this.setState({showShipmentDialog: false, editingShipping: null})
  }

  onAddSubNavbar = () => request.client.home.elements
    .create(this.state.client.id, {type: 'navbar', dataSource: 'sub_navbar'})
    .then(res => this.setState({subNavbarElement: res.data}))

  render = () => {
    const {
      name,
      email,
      image,
      imageId,
      detail,
      visibility,
      client,
      type,
      googleAnalyticsId,
      googleTagManagerId,
      customUrl,
      socialLinks,
      channelContentCreatorTypes,
      catalogs,
      clientIsCompleted,
      loginImage,
      loginImageId,
      footerImage,
      footerImageId,
      sidebarImage,
      sidebarImageId,
      favicon,
      faviconId,
      showAdvancedSearch,
      tags,
      showSupport,
      showAbout,
      contentCreatorTypes,
      navbarElements,
      binary,
      binaryId,
      hasBinary,
      policy,
      policyId,
      hasPolicy,
      bloomcloudChannel,
      loginType,
      languages,
      canDownloadTracks,
      openDropDownDialog,
      dropDownElement,
      tagGroups,
      availableTagGroups,
      openTagGroupDialog,
      supportNegativeSearch,
      trackingGroup,
      showLoginButton,
      availableChannelPlaylists,
      openTrackingGroupsDialog,
      preRollVideo,
      preRollVideoId,
      hasFpsCertificate,
      hasExtraInformation,
      extraRegistration,
      showTagSelection,
      hasEvents,
      showFilterOnSmartSearch,
      radioEnabled,
      radioLink,
      commercialMail,
      customShare,
      useTools,
      useCustomRedirects,
      channelRedirects,
      showDate,
      noIndex,
      translations,
      communityTranslations,
      skipTimePreRoll,
    } = this.state;

    return (
      <div>
        <FormViewContainer
          title={localization.get('channel.create')}
          submitRequest={this.requestClientCreation}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          validation={this.onSubmitValidation}
        >
          <AddressDialog
            showDialog={this.state.showAddressDialog}
            handleCloseDialog={() => this.setState({showAddressDialog: false, editingAddress: null})}
            onSubmit={(address) => this.updateAddress(address)}
            address={this.state.editingAddress}
            isDashboard
          />
          <ShipmentDialog
            showDialog={this.state.showShipmentDialog}
            handleCloseDialog={() => this.setState({showShipmentDialog: false, editingShipment: null})}
            onSubmit={(shipment) => this.updateShipment(shipment)}
            shipment={this.state.editingShipment}
          />
          <ChannelForm
            name={name}
            email={email}
            imageId={imageId}
            onImageChange={this.onImageChange}
            detail={detail}
            image={image}
            loginImage={loginImage}
            loginImageId={loginImageId}
            onLoginImageChange={this.onLoginImageChange}
            footerImage={footerImage}
            footerImageId={footerImageId}
            onFooterImageChange={this.onFooterImageChange}
            sidebarImage={sidebarImage}
            sidebarImageId={sidebarImageId}
            onSidebarImageChange={this.onSidebarImageChange}
            favicon={favicon}
            faviconId={faviconId}
            onFaviconChange={this.onFaviconChange}
            visibility={visibility}
            type={type}
            googleAnalyticsId={googleAnalyticsId}
            googleTagManagerId={googleTagManagerId}
            client={client}
            onChangeType={value => value.target.value === "mixed" ?
              this.setState({type: "", nextType: value.target.value}, () =>   this.setState({type: this.state.nextType})) :
              (value.target.value === "music" ?
                this.setState({type: "", nextType: value.target.value}, () =>  this.setState({type: this.state.nextType, channelContentCreatorTypes: [], catalogs: []})) :
                this.setState({type: "", nextType: value.target.value}, () =>  this.setState({type: this.state.nextType, catalogs: []})))
            }
            onClientChange={client => this.setState({client: '', type: "content", channelContentCreatorTypes: [], catalogs: [], countryIsCompleted: true, nextClient: client}, () => this.setState({client: this.state.nextClient}, () => this.requestCCTypes()))}
            channelContentCreatorTypes={channelContentCreatorTypes}
            onChangeAllowedTypeContentCreatorTypes={allowedTypeContentCreatorTypes => this.setState({allowedTypeContentCreatorTypes})}
            customUrl={customUrl}
            catalogs={catalogs}
            onCatalogsChange={catalogs => this.setState({catalogs})}
            socialLinks={socialLinks}
            onChange={this.onChange}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            updateDisableForm={value => this.setState({disableForm: value})}
            onContentChange={(e, field) => this.setState({[field]: e})}
            onChangeVisibility={value => this.setState({visibility: value.target.value})}
            clientIsCompleted={clientIsCompleted}
            showHelper={this.state.showHelper}
            updateHelper={() => this.setState({showHelper: !this.state.showHelper})}
            showAdvancedSearch={showAdvancedSearch}
            showSupport={showSupport}
            showAbout={showAbout}
            tags={tags}
            onTagsChange={tags => this.setState({ tags })}
            redirectButton={this.state.redirectButton}
            openNavbarDialog={this.state.openNavbarDialog}
            openCloseNavbarDialog={(redirectButton = null) => this.setState({openNavbarDialog: !this.state.openNavbarDialog, redirectButton})}
            contentCreatorTypes={contentCreatorTypes}
            navbarElements={navbarElements}
            addNavbarElement={element => this.addNavbarElement(element)}
            removeNavbarElement={element => this.removeNavbarElement(element)}
            binary={binary}
            binaryId={binaryId}
            onBinaryChange={this.onBinaryChange}
            hasBinary={hasBinary}
            policy={policy}
            policyId={policyId}
            onPolicyChange={this.onPolicyChange}
            hasPolicy={hasPolicy}
            setNavbarElements={elements => this.setState({navbarElements: elements})}
            bloomcloudChannel={bloomcloudChannel}
            loginType={loginType}
            languages={languages}
            canDownloadTracks={canDownloadTracks}
            openCloseEditDropDownDialog={this.openCloseEditDropDownDialog}
            openDropDownDialog={openDropDownDialog}
            dropDownElement={dropDownElement}
            tagGroups={tagGroups}
            availableTagGroups={availableTagGroups}
            openTagGroupDialog={openTagGroupDialog}
            openCloseTagGroupDialog={(tg) => {
              if(!!this.state.openTagGroupDialog) {
                return this.setState({openTagGroupDialog: false, selectedCCTTG: null})
              }else {
                return this.setState({openTagGroupDialog: true, selectedCCTTG: tg})
              }
            }}
            setTagGroup={(tagGroups, categoryId=null) => {
              if (categoryId) {
                let newTagGroups = this.state.tagGroups.filter(tagGroup => !tagGroup.types.some(t => t.contentCreatorType && t.contentCreatorType.id == categoryId));
                tagGroups.forEach(tg => newTagGroups.push(tg));
                this.setState({tagGroups: newTagGroups});
              } else {
                this.setState({tagGroups: tagGroups});
              }
            }}
            addTagGroup={tagGroup => {
              let availableTagGroups = this.state.tagGroups
              availableTagGroups.push(tagGroup)
              this.setState({tagGroups: availableTagGroups.map((element, index) => {
                  element.order = index
                  return element;
                })})
            }}
            removeFromTagGroup={tagGroup => {
              let availableTagGroups = this.state.tagGroups.filter(element => element.id !== tagGroup.id)

              this.setState({tagGroups: availableTagGroups.map((element, index) => {
                  element.order = index
                  return element;
                })
              })
            }}
            supportNegativeSearch={supportNegativeSearch}
            trackingGroup={trackingGroup}
            onTrackingGroupChange={tagGroup => this.setState({trackingGroup: tagGroup}) }
            downloadLink={this.makeDownloadTagLink}
            showLoginButton={showLoginButton}
            showDate={showDate}
            availableChannelPlaylists={availableChannelPlaylists}
            openTrackingGroupsDialog={openTrackingGroupsDialog}
            openCloseEditTrackingGroupsDialog={() => this.setState({openTrackingGroupsDialog: !this.state.openTrackingGroupsDialog})}
            preRollVideo={preRollVideo}
            preRollVideoId={preRollVideoId}
            onPreRollVideoChange={this.onPreRollVideoChange}
            hasFpsCertificate={hasFpsCertificate}
            hasExtraInformation={hasExtraInformation}
            extraRegistration={extraRegistration}
            showTagSelection={showTagSelection}
            dashboardLoginType={"default"}
            loginClientId={""}
            loginClientSecret={""}
            dashboardLoginClientId={""}
            dashboardLoginClientSecret={""}
            userCanSeeAuth={false}
            showMercadoPagoForm={this.state.showMercadoPagoForm}
            mercadoPagoClientId={this.state.mercadoPagoClientId}
            mercadoPagoClientSecret={this.state.mercadoPagoClientSecret}
            showPaypalForm={this.state.showPaypalForm}
            paypalClientId={this.state.paypalClientId}
            paypalClientSecret={this.state.paypalClientSecret}
            showGoogleAdsenseForm={this.state.showGoogleAdsenseForm}
            googleAdsensePublisherId={this.state.googleAdsensePublisherId}
            showHomeAds={this.state.showHomeAds}
            showContentAds={this.state.showContentAds}
            adUnitHomeDesktop={this.state.adUnitHomeDesktop}
            adUnitHomeMobile={this.state.adUnitHomeMobile}
            adUnitContentDesktop={this.state.adUnitContentDesktop}
            adUnitContentMobile={this.state.adUnitContentMobile}
            searchType={this.state.searchType}
            hasEvents={hasEvents}
            showFilterOnSmartSearch={showFilterOnSmartSearch}
            selectedCCTTG={this.state.selectedCCTTG}
            onSelectedCCTTGChange={(e, newValue) => {
              this.setState({selectedCCTTG: newValue})}
            }
            radioEnabled={this.state.radioEnabled}
            radioLink={this.state.radioLink}
            contactMail={this.state.contactMail}
            shipmentMethod={this.state.shipmentMethod}
            onShipmentMethodChange={(e, newValue) => {
              this.setState({shipmentMethod: newValue})}
            }
            addresses={this.state.addresses}
            channelShipments={this.state.channelShipments}
            handleAddressDialog={(editingAddress = null) => this.state.showAddressDialog ?
              this.setState({showAddressDialog: false, editingAddress: null}) :
              this.setState({showAddressDialog: true, editingAddress: editingAddress})
            }
            handleShipmentDialog={(editingShipping = null) => this.state.showShippingDialog ?
              this.setState({showShipmentDialog: false, editingShipping: null}) :
              this.setState({showShipmentDialog: true, editingShipping: editingShipping})
            }
            commercialMail={commercialMail}
            hasCommunity={this.state.hasCommunity}
            communityCatalog={this.state.communityCatalog}
            customScripts={this.state.customScripts}
            onCommunityCatalogChange={catalog => this.setState({communityCatalog: catalog})}
            communityCCTypes={this.state.communityCCTypes}
            communityCanDeleteMaterials={this.state.communityCanDeleteMaterials}
            onCommunityCCTypesChange={ccTypes => this.setState({communityCCTypes: ccTypes})}
            removeCommunityCCType={ccType => {
              let availableCCTypes = this.state.communityCCTypes.filter(element => element.content_creator_type_id !== ccType.content_creator_type_id)
              this.setState({communityCCTypes: availableCCTypes.map((element, index) => {
                  element.order = index
                  return element;
                })
              })
            }}
            customShare={customShare}
            useTools={useTools}
            openEditCategoryDialog={this.state.openEditCategoryDialog}
            editingCategory={this.state.editingCategory}
            onEditCategory={(category =null) =>
              this.setState({openEditCategoryDialog: !!category, editingCategory: category})}
            useCustomRedirects={useCustomRedirects}
            channelRedirects={channelRedirects}
            onChangeChannelRedirects={channelRedirects => this.setState({ channelRedirects })}
            noIndex={noIndex}
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            communityTranslations={communityTranslations}
            onChangeCommunityTranslations={(value, target, language) => {
              const communityTranslations = this.state.communityTranslations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ communityTranslations });
            }}
            isDemo={this.state.isDemo}
            skipTimePreRoll={skipTimePreRoll}
            setSubNavbarElements={subNavbarElements => this.setState({ subNavbarElements })}
            subNavbarElements={this.state.subNavbarElements}
            subNavbarElement={this.state.subNavbarElement}
            addSubNavbar={this.onAddSubNavbar}
            footerType={this.state.footerType}
            footerLogoSide={"left"}
            footerTranslation={this.state.footerTranslation}
            footerElementTranslations={this.state.footerElementTranslations}
            footerSocialPosition={this.state.footerSocialPosition}
            footerMargin={this.state.footerMargin}
            communityRequiresApproval={this.state.communityRequiresApproval}
            communityPreferences={this.state.communityPreferences}
            communityEmail={this.state.communityEmail}
            communityValidations={validationOptions}
            tools={this.state.tools}
            useBrandButton={this.state.useBrandButton}
            editableFor={this.state.editableFor}
            purpose={this.state.purpose}
            navbarContentCreatorType={this.state.navbarContentCreatorType}
            mobileNavbarType={this.state.mobileNavbarType}
            navbarSearchType={this.state.navbarSearchType}
            useSidebarDesktop={this.state.useSidebarDesktop}
            useFloatingContactButton={this.state.useFloatingContactButton}
            floatingButtonImage={this.state.floatingButtonImage}
            onFloatingButtonImageChange={this.onFloatingButtonImageChange}
          />
        </FormViewContainer>
      </div>
    );
  };
}
