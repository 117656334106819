import React, {Component} from "react";
import localization from "../../../config/localization";
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {request} from "../../../api-client";
import AsyncSelect from "react-select/lib/Async";
import Card from "../../../components/Card/Card";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class AlbumSelection extends Component {

  state = {
    storeAlbumId: null,
    artistId: this.props.artistId,
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (this.state.artistId !== nextProps.artistId) {
      this.setState({artistId: nextProps.artistId})
      if (this.props.albumsRadio === 'artist') {
        this.props.onSelectedAlbumsChanged('')
      }
    }
  }

  render = () => {

    const { selectedAlbums, onSelectedAlbumsChanged } = this.props

    return (
      <Card style={{padding: "5px 20px"}}>
        <h4>{localization.get('form.select_albums')}</h4>
        <p>{localization.get('form.select_albums_instructions')}</p>
        <FormControl>
          <RadioGroup
            name="albumsRadio"
            value={this.props.albumsRadio}
            onChange={this.props.onAlbumsRadioChange}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="artist" control={<Radio color="default"/>} label={localization.get('form.album_type.artist')}/>
            <FormControlLabel value="compiled" control={<Radio color="default"/>} label={localization.get('form.album_type.compiled')}/>
          </RadioGroup>
        </FormControl>
          <div style={{float: "left", position: "relative", width: "100%", marginBottom: 30}}>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              name={"artistAlbums"}
              placeholder={localization.get('form.type_to_search')}
              loadingMessage={() => localization.get(this.props.albumsRadio === 'artist' && !this.state.artistId ? 'no_options' : 'loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onSelectedAlbumsChanged}
              value={selectedAlbums}
              isClearable={true}
              isDisabled={!this.props.albumsRadio}
              loadOptions={inputValue => {
                if (this.props.albumsRadio === 'artist' && !this.state.artistId) {
                  GlobalSnackbar.show({message: localization.get('form.album_type.artist.helper_text'), type: GlobalSnackbarTypes.ERROR})
                } else {
                  return request.album
                    .getAll({ filterBy:
                        [
                          { column: "title", filter: inputValue },
                          this.props.albumsRadio === 'artist' && { column: "artist_id", filter: this.props.artistId },
                          this.props.client !== 0 && { column: "client", filter: this.props.client },
                          this.props.client !== 0 && { column: "musicFilter", filter: true },
                          { column: "type", filter: this.props.albumsRadio },
                        ]
                    })
                    .then(res => res.map(album => ({ value: {albumId: album.id, catalogId: album.catalog.id}, label: album.title, catalogId: album.catalog.id })))
                }
              }}
            />
          </div>
      </Card>
    )
  }
}

export default AlbumSelection
