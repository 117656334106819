import {post} from '../utils'

const makeGiftForm = (gateway, code, callback, from, to, dedication) => {
    const form = new FormData();
    if (gateway) form.append("gateway", gateway);
    if (code) form.append("code", code);
    if (callback) form.append("callback", callback);
    if (from) form.append("from", from);
    if (to) form.append("to", to);
    if (dedication) form.append("dedication", dedication);
    
    return form;
}

export default host => (product, {gateway = "mercadopago", code, callback, from, to, dedication}) =>
  post(`${host}/products/${product}/gift`, makeGiftForm(gateway, code, callback, from, to, dedication))
    .then(response => response.json())
    .then(json => json.data)
