import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import localization from "../../../config/localization";
import moment from "moment";
import 'moment/locale/es';
import { withStyles } from '@material-ui/core/styles';
import './index.css';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: props => ({
    backgroundColor: 'var(--secondary-color, #f5f5f9)',
    color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.87))',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    marginTop: props.marginTop, 
  }),
}))(Tooltip);

class AlternativeEventsInfo extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('es');
    let today = moment();
    let eventDates = this.props.eventDates.map((ed) => {
      if (ed.preferences && ed.preferences.button && ed.preferences.button.start_date) {
        ed.preferences.button.start_date = moment(`${ed.preferences.button.start_date} +0000`, "YYYY-MM-DD HH:mm Z");
      }
      let date = moment(`${ed.date.substr(0, 10)} ${ed.time} +0000`, "YYYY-MM-DD HH:mm Z");
      return {
        date: date,
        duration: ed.duration,
        button: ed.preferences.button,
        clarification: ed.preferences.clarification,
        hasStarted: date.isBefore(today),
      };
    }).sort((d1, d2) => d1.date.isBefore(d2.date) ? -1 : 1);
    
    this.state = {
      events: eventDates,
    };
  }

  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick && this.props.onClick(e);
    return false;
  }

  render() {
    const { events } = this.state;
    const chipFontColor = this.props.chipFontColor || "#4c4c4c";
    const chipColor = this.props.chipColor || "var(--title-hover, #e0e0e0)";

    return (
        <div style={{
          position: 'absolute',
          right: 0,
          width: this.props.show ? '120px' : 0,
          height: '100%',
          background: 'linear-gradient(to left, rgba(0, 0, 0, 0.6) 75%, transparent)',
          padding: this.props.show ? '20px' : "20px 0px",
          boxSizing: 'border-box',
          zIndex: 2,
          transition: "width 0.3s ease-in-out",
        }}>
          <span style={{
            color: chipFontColor,
            background: chipColor,
            padding: "5px 10px",
            borderRadius: 15,
            transition: "opacity 1s ease-in-out",
            opacity: this.props.show ? 1 : 0,
          }}>
            {localization.get('event_count.schedule', events.length)}
          </span>
          {this.props.show &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
              {events.slice(0, 7).map((event, index) => (
                <div key={index} style={{ 
                  color: 'white', 
                  borderBottom: '.5px solid white', 
                  padding: '0',
                  fontWeight: 'bold'
                }}>
                  {event.date.format("HH:mm")}
                </div>
              ))}
            </div>
          }
        </div>
    );
  }
}

export default AlternativeEventsInfo;