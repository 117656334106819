import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from '@material-ui/core/Chip';
import {Block} from '@material-ui/icons';
import PropTypes from 'prop-types'
import './TagSelectionSmartSearch.css'
import {Avatar, MenuItem} from "@material-ui/core";
import Select from "react-select";
import Slider from "react-slick";
import PrevArrow from "../Arrows/PrevArrow";
import NextArrow from "../Arrows/NextArrow";
import localization from "../../../config/localization";
import font from "../../../layouts/Home/HomeElements/Styles/Fonts/fonts.module.css"
import {getActiveChannel, getStoredChannel} from "../../../api-client/core/authentication/utils";

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const selectStyles = {
  control: (styles) => ({ ...styles, color: 'var(--secondary-font-color, black)', backgroundColor: 'transparent', boxShadow: 0, borderColor: 'var(--secondary-font-color, black)', ':hover': {borderColor: 'var(--secondary-font-color, black)'}, ":focus": {boxShadow: 0}}),
  menu: base => ({...base, zIndex: 10, backgroundColor: 'var(--secondary-color, white)'}),
  placeholder: (styles) => ({ ...styles, opacity: 0.6, fontStyle: 'italic', color: 'var(--secondary-font-color, black)'}),
  noOptionsMessage: (styles) => ({ ...styles, color: 'var(--secondary-font-color, black)'}),
  dropdownIndicator: (styles) => ({ ...styles, color: 'var(--secondary-font-color, black)'}), 
  clearIndicator: (styles) => ({ ...styles, color: 'var(--secondary-font-color, black)'}), 
  indicatorSeparator: (styles) => ({ ...styles, backgroundColor: 'var(--secondary-font-color, black)'}), 
  singleValue: (styles) => ({...styles, color: 'var(--secondary-font-color, black)'}),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    let color = "var(--secondary-color, white)";
    let fontColor = "var(--secondary-font-color, black)";
    return {
      ...styles,
      float: 'left',
      textAlign: 'left',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "rgba(0, 0, 0, 0.14)"
        : isFocused
        ? "rgba(0, 0, 0, 0.1)"
        : undefined,
      color: isDisabled
        ? fontColor
        : fontColor,
      fontWeight: isSelected ? 'bold' : 'normal',
      cursor: isDisabled ? 'not-allowed' : 'default',
      opacity: isDisabled ? 0.4 : 1,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? color
          : undefined,
      },
    };
  },
}

class TagSelection extends React.Component {

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.state = {
      amountBanners: 4,
      tagGroups: props.data || [],
    }
  }

  componentDidMount() {
    if (this.props.modal && this.slider) {
      let carouselTagGroup = this.state.tagGroups.find(tagGroup => tagGroup.view_type === 'carousel')
      if (carouselTagGroup) {
        let selectedTagIndex = carouselTagGroup.tags.findIndex(tag => tag.state === 'selected')
        if (selectedTagIndex !== -1) {
          this.slider.current.slickGoTo(selectedTagIndex + 1)
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ tagGroups: this.addAvailability(nextProps.data)})
    }
  }


  handleClickTag = (e, tagGroupId, tagId) => {
    let tagGroup = this.state.tagGroups.find((tagGroup) => tagGroup.id === tagGroupId);
    let tag = tagGroup.tags.find((tag) => tag.id === tagId);

    if (!tag) {
      tagGroup.tags.forEach(tag => {
        tag.state = 'available'
        tagGroup.selectedTag = null;
      })
    } else {
      if (this.props.audiovisual || tagGroup.view_type === "drop_down" || tagGroup.view_type === "carousel" ) {
        tagGroup.tags.forEach(tag => {
          if (tag.state == 'selected') {
            tag.state = 'available';
          } 
        })

        tagGroup.selectedTag = tag.id
        tag.state = 'selected'
      } else {
        if (tag.state === 'available' && this.props.singleTagSelection) {
          this.state.tagGroups.forEach(tagGroup => {
            tagGroup.tags.forEach(tag => {
              if((tag.state === 'unselected' || tag.state === 'selected' )&& tagGroupId === tagGroup.id) tag.state = 'available'
            })
          });
          tag.state = 'selected'
        } else if (tag.state === 'available') {
          tag.state = 'selected'
        } else if (tag.state === 'selected' && this.props.allowUnselected) {
          tag.state = 'unselected'
        } else if (tag.state === 'unselected' || (tag.state === 'selected' && !this.props.allowUnselected)) {
          tag.state = 'available'
        }
      }
    }

    this.setState(this.state)
  };

  handleChange = (lastTagId, checkAvailableTags = !!this.props.checkAvailableTags) => {
    let selected = [], unselected = [];
    this.state.tagGroups.forEach(tagGroup => {
      tagGroup.tags.forEach(tag => {
        if (tag.state === "selected") {
          selected.push(tag.id)
        }
        if (tag.state === 'unselected') {
          unselected.push(tag.id)
        }
      })
    });
    if (this.props.onSelectedTagsChanged) {
      this.props.onSelectedTagsChanged(selected, unselected, lastTagId, checkAvailableTags, this.props.notWriteQueryString && !this.props.notWriteQueryString)
    }
  };

  addAvailability = (tagGroups) => {
    tagGroups.forEach(tagGroup => {
      tagGroup.tags.forEach(tag => {
        if (!tag.state) {
          tag.state = "available"
        }
      })
    });
    return tagGroups
  };

  addMargin = (index) => {
    let margin = '5px'
    if (this.state.tagGroups[index + 1] && this.state.tagGroups[index].view_type !== this.state.tagGroups[index + 1].view_type) {
      margin = '30px'
    }
    return margin
  }


  render() {
    const {classes} = this.props;
    const isMobileModal = this.props.modal && window.innerWidth <= 600;
    const getSettings = (size) => ({
      className: isMobileModal ? "center mobile-modal-slider-with-background" : this.props.modal ? "center modal-slider-with-background" : "center slider-with-background",
      centerMode: true,
      infinite: true,
      centerPadding: "0",
      slidesToShow: this.props.modal ? 1 : size > 2 ? 3 : 1,
      speed: 500,
      beforeChange: (_currentSlide, nextSlide) => {
        if (this.slider.current) {
          const elementId = this.slider.current.innerSlider.props.children[nextSlide].props.children[0].props.children[0].props.id;
          let tagGroupId = parseInt(elementId.split('-')[0])
          let tagId = parseInt(elementId.split('-')[1])

          this.handleClickTag('', tagGroupId, tagId);
          this.handleChange(tagId)
        }
      },
      prevArrow: <PrevArrow/>,
      nextArrow: <NextArrow/>,
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            prevArrow: null,
            nextArrow: null,
          }
        }
      ]
    });
    if (this.props.audiovisual) {
      return (
        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: (this.state.tagGroups && this.state.tagGroups.length > 0) ? 30: 0}}>
          {this.state.tagGroups.map((tagGroup, i) =>
            <>
                <Select 
                  styles={selectStyles}
                  className="audiovisual-select-filter"
                  value={tagGroup.selectedTag ? {value: tagGroup.selectedTag, label: tagGroup._selectedLabel} : 0}
                  onChange={value => {
                    tagGroup._selectedLabel = value ? value.label : null;
                    this.handleClickTag(null, tagGroup.id, value ? value.value : null);
                    this.handleChange(value ? value.value : null);
                  }}
                  isDisabled={tagGroup.tags.every(t => t.state == "disable")}
                  isMulti={false}
                  isSearchable={true}
                  isClearable={true}
                  name={tagGroup.name}
                  placeholder={tagGroup.name}
                  options={
                    tagGroup.tags.map((tag) => ({
                      value: tag.id, 
                      label: tag.name, 
                      isDisabled: tag.state === 'disable' && !tagGroup.tags.some(t => t.state == 'selected')
                    }))
                  }
                />
            </>
          )}
        </div>
        )
    }
    return (
      <div style={{float: "left", position: "relative", width: "100%", paddingTop: 30}}>
        {this.state.tagGroups.map((tagGroup, i) =>
          <>
            {tagGroup.view_type === "drop_down" ?
              <div className={`smart-search-tag-column ${this.props.horizontal ? 'horizontal' : ''}`}
                   style={{marginBottom: this.addMargin(i), marginLeft: 20, maxWidth: 230, width: `${100 / this.state.tagGroups.length}%`, position: "relative", float: "left"}}
                   key={i}>
                <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{fontWeight: "600", textAlign: "left"}}>{tagGroup.name}</h4>
                <Select 
                  styles={selectStyles}
                  value={tagGroup.selectedTag ? {value: tagGroup.selectedTag, label: tagGroup._selectedLabel} : 0}
                  onChange={value => {
                    tagGroup._selectedLabel = value ? value.label : null;
                    this.handleClickTag(null, tagGroup.id, value ? value.value : null);
                    this.handleChange(value ? value.value : null);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  isClearable={true}
                  name="dataSource"
                  placeholder={tagGroup.name}
                  options={
                    tagGroup.tags.map((tag) => ({
                      value: tag.id, 
                      label: tag.name, 
                      isDisabled: tag.state === 'disable' && !tagGroup.tags.some(t => t.state == 'selected')
                    }))
                  }
                />
              </div>
              : tagGroup.view_type === "carousel" ?
                <div className={isMobileModal ? '' : `smart-search-tag-column ${this.props.horizontal ? 'horizontal' : ''}`}
                     style={{marginBottom: this.addMargin(i), marginLeft: isMobileModal ? "-30px" : 20, width: isMobileModal ? "calc(100% + 60px)" : `${100 / this.state.tagGroups.length}%`, position: "relative", float: "left", overflowX: isMobileModal ? 'hidden' : 'visible'}}
                     key={i}>
                  <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{fontWeight: "600", textAlign: "left", marginLeft: isMobileModal ? 50 : 0 }}>{tagGroup.name}</h4>
                  <div style={{
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    width: isMobileModal ? "calc(100% + 40px)" : this.props.modal ? 'calc(100% + 100px)' : '100vw',
                    marginLeft: window.innerWidth > 600 ? (this.props.modal ? '-70px' : '-140px') : isMobileModal ? '-20px' : '-70px',
                    padding: window.innerWidth > 600 ? (this.props.modal ? '5px 0 0 70px' : '5px 0 0 140px') : '5px 0 0 70px',
                    height: window.innerWidth > 600 ? '85px' : '105px'
                  }}>
                  <Slider ref={this.slider} {...getSettings(tagGroup.tags.length)} >
                      {[{id: 0, name: localization.get('tags.tag_selection')}].concat(tagGroup.tags).map((tag) =>
                        <div id={`${tagGroup.id}-${tag.id}`}>
                          <h3 className={`slide-h3 ${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{tag.name}</h3>
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>
                :
                <div className={`smart-search-tag-column ${this.props.horizontal ? 'horizontal' : ''}`}
                     style={{marginBottom: this.addMargin(i), width: `${100 / this.state.tagGroups.length}%`, position: "relative", float: "left"}}
                     key={i}>
                  <div style={{
                    width: "100%",
                    position: "relative",
                    float: "left",
                    margin: "10px 0 10px 20px",
                  }}>
                    <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{fontWeight: "600", textAlign: "left"}}>{tagGroup.name}</h4>
                  </div>
                  <div style={{width: "100%", position: "relative", textAlign: "left", display: 'flex', flexWrap: 'wrap'}}>
                    {
                      tagGroup.tags.map((tag, j) => {
                        return (
                          <div className={`smart-search-tag-containter ${this.props.horizontal ? 'horizontal' : ''}`}
                               style={{width: "100%", position: "relative", display: "inline-block",}}>
                            {tag.state !== 'disable' ?
                              <Chip
                                key={j}
                                avatar={tag.state === 'unselected' &&
                                <Avatar style={{width: 23, height: 23, backgroundColor: "rgb(208, 55, 55)"}}>
                                  <Block style={{color: "white"}}/>
                                </Avatar>
                                }
                                style={{
                                  width: "auto",
                                  fontWeight: "400",
                                  backgroundColor: tag.state === "selected" ? 'var(--main-font-color, black)' : tag.state === "unselected" ? '#e68484' : 'transparent',
                                  color: tag.state === "selected" ? 'var(--main-color, white)' :
                                     tag.state === 'unselected' ? 'var(--main-color, white)' :
                                      tag.state === "disable" ? 'grey' : 'var(--main-font-color, black)',
                                  cursor: 'pointer',
                                  height: "30px",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                  border: !['selected', 'unselected'].includes(tag.state) && '1px solid var(--main-font-color, #c0c0c0)',
                                }}
                                label={<span className={`${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{tag.name}</span>}
                                className={classes.chip}
                                onClick={(e) => {
                                  if (this.props.disabled) return
                                  this.handleClickTag(e, tagGroup.id, tag.id);
                                  this.handleChange(tag.id)
                                }}
                              /> :
                              <Chip key={j}
                                    style={{
                                      width: "auto",
                                      fontWeight: "400",
                                      backgroundColor: 'transparent',
                                      color: '#8080808c',
                                      cursor: 'default',
                                      height: "30px",
                                      marginTop: "3px",
                                      marginBottom: "3px",
                                      border: '1px solid #e9e9e9',
                                    }}
                                    label={<span className={`${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{tag.name}</span>}
                                    className={classes.chip}
                              />
                            }
                          </div>
                        )
                      })}
                  </div>
                </div>

            }
          </>
        )}
      </div>
    )
  }
}

TagSelection.propTypes = {
  data: PropTypes.array.isRequired,
};

TagSelection.defaultProps = {
  horizonal: false,
  allowUnselected: true,
  singleTagSelection: false,
  audiovisual: false,
};


export default withStyles(styles)(TagSelection);
