import {isNullOrUndefined} from "util";

export const makeFormData = ({
  name,
  email,
  phone,
  translations,
  type,
  imageId,
  profileId,
  selectedMusics,
  selectedUsers,
  isDemo,
  birthdate,
  countryId,
  audios,
  socialLinks,
  unsplashCoverImage,
  unsplashProfileImage
}) => {
  const form = new FormData()
  if (name) form.append('name', name)
  if (phone) form.append('phone', phone)
  if (email) form.append('email', email)
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description)
    })
  }
  if (audios) audios.forEach(audio => form.append('audios[]', audio.file, audio.name))
  if (type) form.append('type', type)
  if (selectedUsers) selectedUsers.forEach(x => form.append('users_ids[]', x))
  if (imageId) form.append('imageId', imageId)
  if (profileId) form.append('profileId', profileId)
  if (selectedMusics) selectedMusics.forEach(x => form.append('musics_ids[]', x))
  if (isDemo !== null && isDemo !== undefined) form.append("is_demo", isDemo ? 1 : 0);
  if (birthdate) form.append('birthdate', birthdate)
  if (countryId) form.append('country_id', countryId)
  if (socialLinks) {
    socialLinks.forEach(socialLink => {
      if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
    });
  }
  if (unsplashCoverImage) {
    form.append('cover_image_url', unsplashCoverImage.url)
    form.append('cover_image_name', unsplashCoverImage.name)
  }
  if (unsplashProfileImage) {
    form.append('profile_image_url', unsplashProfileImage.url)
    form.append('profile_image_name', unsplashProfileImage.name)
  }
  return form
}

export const includeQuery = 'include=image;profile;recentMusics.albums.image;recentMusics.audio.waveform;recentMusics.tags;albums.image;favorite;users;socialLinks;country'
