import React from "react";
import "../assets/style/SoundBar.css";
import {AddShoppingCart} from "@material-ui/icons";

import CustomTooltip from "../utils/CustomTooltip";

const License = ({ label, onClick }) => {
  return (
    <CustomTooltip title={label}>
      <div style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
        <div className="soundbar-button" onClick={onClick} style={{ display: "flex", alignItems: "center", color: "var(--secondary-font-color, dimgrey)" }}>
          <div style={{ lineHeight: "0" }}>
            <AddShoppingCart />
          </div>
        </div>
      </div>
    </CustomTooltip>
  );
};

export default License;
