import React from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import Home from "./Home";
import Album from "./Album";
import Spotlight from "./Spotlight";
import Favorites from "./Favorites";
import Speaker from "./Speaker";
import HomePlaylists from "./Playlists/HomePlaylists";
import HomePlaylist from "./Playlists/HomePlaylist";
import SmartSearchMusic from "./SmartSearch/SmartSearchMusic";
import SmartSearchSpeaker from "./SmartSearch/SmartSearchSpeaker";
import SmartSearchContentCreator from "./SmartSearch/SmartSearchContentCreator";
import SmartSearchContentCreation from "./SmartSearch/SmartSearchContentCreation";
import SmartSearch from "./SmartSearch/index";
import HomePlaylistUpdate from "./Playlists/HomePlaylistUpdate/index.jsx";
import RightManagement from "./RightManagement/index.jsx";
import OriginalMusic from "./OriginalMusic/index.jsx";
import Account from "./Account/index.jsx";
import Footer from "./HomeElements/Footer";
import NavBar from "./HomeElements/NavBar";
import {
  clientCanLicense,
  getStoredUser,
  homePathGenerator,
  ROLES,
  setActiveClient, slugify,
  storeClientLicencePlan,
  storeClientName,
  storeClientType,
  userIsArtist,
  userisMultiClient,
  userisSingleClient,
} from "../../api-client/core/authentication/utils";
import {request} from "../../api-client";
import Artist from "./Artist";
import Music from "./Music";
import ContentCreator from "./ContentCreator";
import Projects from "./Projects";
import Links from "./Links"
import Drive from "./Drive"
import Reels from "./Reels"
import Contact from "./Contact"
import About from "./About"
import EventManager, {events} from "../../utils/EventManager";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import localization from "../../config/localization";
import NotFoundPage from "../Channel/ErrorPage/NotFoundPage";

const transparentRoutes = ['/artist', '/playlist', '/content_creator', '/music', '/speaker', '/album'];
const transparentExcludeRoutes = ['smart_search', 'playlists', 'account', 'original_music', '/edit'];

const getIdFromUrl = () => {
  let clientData =  window.location.pathname.split('/')[2]

  return Number(clientData && clientData.split("-")[0])
}

class HomeRouting extends React.Component {
  state = {
    client: null,
    transparent: false,
    limitReached: false,
  };

  componentDidMount = () => {
    request.client.get(getIdFromUrl()).then(client => {
      if (!!client.defaultChannel) {
        window.location.href = client.defaultChannel.domain ? `https://${client.defaultChannel.domain.url}` : `${process.env.REACT_APP_FRONT_HOST}/channel/${client.defaultChannel.id}-${slugify(client.defaultChannel.name)}`;
      }
      storeClientType(client);
      storeClientLicencePlan(client)
      storeClientName(client)
      this.setState({client});
    });
    this.checkIfNavbarIsTransparent()

    EventManager.getInstance().subscribe(events.OPEN_CONTENT_MODAL, this.openModal)
    EventManager.getInstance().subscribe(events.OPEN_VIEWS_LIMIT_MODAL, this.openViewsLimitModal)
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.OPEN_CONTENT_MODAL, this.openModal)
    EventManager.getInstance().unsubscribe(events.OPEN_VIEWS_LIMIT_MODAL, this.openViewsLimitModal)
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({showModal: false})
      this.checkIfNavbarIsTransparent()
    }
  };

  checkIfNavbarIsTransparent = () =>
    this.setState({
      transparent: (
        transparentRoutes.some(transparentRoute => this.props.location.pathname.indexOf(transparentRoute) > -1) &&
        transparentExcludeRoutes.every(transparentExcludeRoute => this.props.location.pathname.indexOf(transparentExcludeRoute) === -1)
      )
    });

  RightManagement = props => <RightManagement client={this.state.client} {...props} />
  OriginalMusic = props => <OriginalMusic client={this.state.client} {...props} />
  Account = (props) => <Account client={this.state.client} {...props} />
  SmartSearch = (props) => <SmartSearch client={this.state.client} {...props}/>
  ContentCreator = (props) => <ContentCreator contentCreatorId={props.match.params.contentCreatorId} client={this.state.client} showAddToPlaylist {...props}/>
  Music = (props) => <Music client={this.state.client} {...props}/>
  Projects = (props) => <Projects client={this.state.client} {...props}/>
  Home = props => <Home client={this.state.client} {...props} />
  SmartSearchMusic = props => <SmartSearchMusic client={this.state.client} {...props}/>
  Links = props => <Links client={this.state.client} {...props}/>
  Favorites = props => <Favorites client={this.state.client} {...props}/>
  Drive = props => <Drive client={this.state.client} {...props}/>
  Reels = props => <Reels client={this.state.client} {...props}/>
  HomePlaylist = props => <HomePlaylist client={this.state.client} {...props}/>
  Contact = props => <Contact client={this.state.client} {...props}/>
  About = props => <About client={this.state.client} {...props}/>
  NotFoundPage = () => <NotFoundPage showButton={false} />

  openModal = data => this.setState({modalEntityId: data.id, entityType: data.dataType, showModal: true, onTagChange: data.onTagChange || false})

  renderShowModal = () =>
    <Dialog
      open={this.state.showModal}
      onEscapeKeyDown={() => this.setState({showModal: false})}
      fullScreen={true}
      disableEnforceFocus
    >
      {this.state.entityType === 'album' &&
      <Album albumId={this.state.modalEntityId} client={this.state.client} modal={true} onModalClose={() => this.setState({showModal: false})} {...this.props}/>
      }
      {this.state.entityType === "artist" &&
      <Artist artistId={this.state.modalEntityId} client={this.state.client} modal={true} onModalClose={() => this.setState({showModal: false})} {...this.props}/>
      }
      {this.state.entityType === "contentCreator" &&
      <ContentCreator contentCreatorId={this.state.modalEntityId} client={this.state.client} modal={true} onModalClose={() => this.setState({showModal: false})} onTagChange={this.state.onTagChange} {...this.props}/>
      }
    </Dialog>

  openViewsLimitModal = () => this.setState({limitReached: true})

  renderViewLimitReachedDialog = () =>
    <Dialog
      open={this.state.limitReached}
      onEscapeKeyDown={() => this.setState({limitReached: false})}
      onBackdropClick={() => this.setState({limitReached: false})}
    >
      <DialogTitle>{localization.get("content.views_limit_reached")}</DialogTitle>
      <DialogContent>{localization.get("content.views_limit_reached.description")}</DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({limitReached: false})}>{localization.get('form.close')}</Button>
      </DialogActions>
    </Dialog>


  render = () => {
    if (this.clientInUrlIsInvalid()) {
      return this.redirectToAValidClient()
    }
    if(!this.state.client) {
      return null
    }
    return (
      <div>
        <div style={{ backgroundColor: "white", paddingTop: this.state.transparent ? 0 : 50 }} id="wrapper">
          <div id="inner">
            <Router {...this.props}>
              <Switch>
                <Route path="/home/:clientId-:clientName" render={wrap(this.NotFoundPage)} />
              </Switch>
            </Router>
          </div>
        </div>
      </div>

    );
  };

  clientInUrlIsInvalid = () => {
    const clientIdInPath = this.props.match.params.clientId;
    const currentUser = getStoredUser();

    if (userisSingleClient() || userisMultiClient()) {
      if (!currentUser.clientRoles.some(cr => cr.client.id == clientIdInPath)) {
        return true;
      }
    }

    if (userIsArtist()) {
      return true
    }

    return false;
  };

  redirectToAValidClient = () => {
    const currentUser = getStoredUser();
    if(currentUser.role.id === ROLES.artistId) {
      return <Redirect to={`/musics`} />;
    }
    return <Redirect to={homePathGenerator('', currentUser.clientRoles[0].client)} />;
  };
}

const wrap = (Comp) => props => {
  setActiveClient(getIdFromUrl())
  return <Comp {...props}/>
}

export default HomeRouting;
