import {post} from '../utils'

export default host => ({
    creatorId, linkId, selectedTags, allowDownload, namePrefix, selectedLabel, filename, fileTypes
}) => post(`${host}/contentCreator/${creatorId}/contentCreations/storeMany/${linkId}`,
  makeFrom({selectedTags, allowDownload, namePrefix, selectedLabel, filename, fileTypes}))
  .then(response => response.json())

const makeFrom = ({ selectedTags, allowDownload, namePrefix, selectedLabel, filename, fileTypes }) => {
  const form = new FormData();

  if (selectedTags) selectedTags.forEach(x => form.append("tagsIds[]", x));
  if (filename) form.append("filename", filename );
  if (namePrefix) form.append("name_prefix", namePrefix);

  fileTypes.forEach(type => {
    form.append("file_types[]", type );
  })
  form.append("allow_download", allowDownload ?  1: 0);
  form.append("label", selectedLabel );

  return form;
}