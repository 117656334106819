import {appendPaginationQueryParamsToUrl, get} from '../utils'

const url = (host,id, tags, query, options) =>
  appendPaginationQueryParamsToUrl(`${host}/clients/${id}/musics?tags_ids=${tags.join(',')}&${query}&include=versions_count;tags;artist;audio;audio.waveform;favorite;albums.image`, {...options, alreadyHasQueryParams: true})

export default host => (id, tags, query, options) =>
  get(url(host, id, tags, query))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


