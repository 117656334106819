import React, { Component } from 'react';
import PropTypes from 'prop-types';
class TagLabel extends Component {
    render() {
        const { title, onClick, color, backgroundColor } = this.props;

        const tagStyle = {
            display: 'inline-block',
            padding: '0.3em 0.6em',
            margin: '0.2em',
            fontSize: '0.875em',
            fontWeight: '600',
            borderRadius: '1rem',
            cursor: 'pointer',
            position: 'relative',
            userSelect: 'none',
            color: color,
            backgroundColor: backgroundColor,
            border: `1px solid ${color}`,
        };
        return (
          <span style={tagStyle} onClick={onClick}>
            {title}
          </span>
        );
    }
}

TagLabel.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default TagLabel;