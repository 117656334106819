import React, {Component} from 'react'
import {request} from 'api-client';
import localization from '../../config/localization';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import {Link} from "react-router-dom"
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AsyncSelect from "react-select/lib/Async";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {userIsSysAdmin} from "../../api-client/core/authentication/utils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ExportLicenses extends Component {
    state = {
      clients: [],
      allowSadaic: true,
      allowAdvertising: true,
      allowCorporate: true,
      brand: null,
      year: null,
      downloadLink: '#',
      showPrice: false,
      thisYear: new Date().getFullYear(),
      year: "all",
    }

  componentDidMount = () => {
    document.title = localization.get('comercial.export_licenses') + ' - Feater'
    this.requestToken()
    this.interval = window.setInterval(this.requestToken, 300000) // refresco el token cada 5 minutos
  }

  componentWillUnmount = () => {
    window.clearInterval(this.interval)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.clients !== this.state.clients ||
      prevState.allowSadaic !== this.state.allowSadaic ||
      prevState.allowAdvertising !== this.state.allowAdvertising ||
      prevState.allowCorporate !== this.state.allowCorporate ||
      prevState.catalog !== this.state.catalog ||
      prevState.showPrice !== this.state.showPrice ||
      prevState.brand !== this.state.brand ||
      prevState.year !== this.state.year 
    ) {
      this.makeDownloadLink()
    }
  }

  makeDownloadLink = () => {
    let  downloadLink = `?client_id=${(this.state.clients.map((client) => client.value))}&licenses=`

    if (this.state.allowSadaic) downloadLink = downloadLink + '3,'
    if (this.state.allowAdvertising) downloadLink = downloadLink + '2,'
    if (this.state.allowCorporate) downloadLink = downloadLink + '1'

    if (this.state.catalog && this.state.catalog.value !== 0) downloadLink = downloadLink + '&catalog_id=' + this.state.catalog.value
    if (this.state.showPrice) downloadLink = downloadLink +  '&price=true'
    if (this.state.brand) downloadLink = downloadLink +  `&brand_id=${this.state.brand.value}`
    if (this.state.year !== "all") downloadLink = downloadLink + `&year=${this.state.year}`


    request.license.getDownloadLink(downloadLink).then(url => {
      this.setState({downloadLink: url})
    })
  };

  requestToken = () => request.authentication.refreshAccessToken()

  onChange = e => this.setState({[e.target.id]: e.target.value})

  render = () => {
    const {clients, allowCorporate, allowSadaic, allowAdvertising, downloadLink, showPrice} = this.state
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <FormControl fullWidth>
                <InputLabel>{localization.get("clients")}</InputLabel>

                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  placeholder={localization.get("clients")}
                  cacheOptions
                  onChange={(clients) => this.setState({clients: (clients.length > 1) ? clients.filter(client => client.value !== 0) : clients })}
                  value={clients}
                  defaultOptions
                  loadOptions={inputValue =>
                    request.client
                      .getAll({filterBy: [{column: "name", filter: inputValue}]})
                      .then(res => {
                        res = res.map(client => ({value: client.id, label: client.name}))
                        userIsSysAdmin() && res.unshift({value: 0, label: 'Todos'})
                        return res
                      })
                  }
                  isMulti
                />
                <br/>

                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                  })}
                  placeholder={localization.get('catalogs')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={catalog => this.setState({catalog})}
                  value={this.state.catalog}
                  defaultOptions
                  isClearable={true}
                  loadOptions={inputValue => {
                    let filters = [{ column: "name", filter: inputValue }]

                    filters.push({column: "has_licenses", filter: true })

                    return request.catalog
                      .getAll({filterBy: filters})
                      .then(catalogs => catalogs.map(catalog => ({value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})`})))
                      .then(catalogs => catalogs.sort((a, b) => a.label.localeCompare(b.label)))
                      .then(catalogs => {
                        catalogs.unshift({value: 0, label: 'Todos'})
                        return catalogs
                      })
                  }
                  }
                />
              </FormControl>
              
              <br/>
              <br/>
              
              <FormControl fullWidth>
                <InputLabel>{localization.get("brand")}</InputLabel>
                <AsyncSelect
                 styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                })}
                style={{ width: "100%" }}
                placeholder={localization.get('brands')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={brand => this.setState({brand})}
                value={this.state.brand}
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}];
                  
                  return request.brands.getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                    .then(res => res.map(brand => ({value: brand.id, label: brand.name})))
                }}
                defaultOptions
              />
                <br/>
              </FormControl>

              <FormControl style={{width: "100%"}}>
                <InputLabel>{localization.get('form.year')}</InputLabel>
                <Select
                  value={this.state.year}
                  onChange={e => this.setState({year: e.target.value})}
                >
                  <MenuItem value={"all"}>{localization.get("all")}</MenuItem>
                  {[0,1,2,3,4,5].map(index => 
                    <MenuItem value={this.state.thisYear - index}>{this.state.thisYear - index}</MenuItem>
                  )}
                </Select>
              </FormControl>


              <div style={{marginTop: 30}}>
                <InputLabel>{localization.get("license_wizard.licenses")}</InputLabel>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={allowAdvertising}
                        onChange={(e) => {
                          this.setState({allowAdvertising: !allowAdvertising})
                        }}
                      />
                    }
                    label={localization.get("license_wizard.type.publicity")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={allowCorporate}
                        onChange={(e) => {
                          this.setState({allowCorporate: !allowCorporate})
                        }}
                      />
                    }
                    label={localization.get("license_wizard.type.corporate")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={allowSadaic}
                        onChange={(e) => {
                          this.setState({allowSadaic: !allowSadaic})
                        }}
                      />
                    }
                    label={localization.get("SADAIC")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={showPrice}
                        onChange={(e) => {
                          this.setState({showPrice: !showPrice})
                        }}
                      />
                    }
                    label={localization.get("export_licenses.include_price")}
                  />
                </div>
              </div>
              <Link to={downloadLink} target="_blank">
                <Button style={{float: "right", marginTop: "20px"}}
                        color={'info'}>{localization.get('download')}</Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default ExportLicenses