import {addPutMethodToForm, post} from "../utils";
import {makeFormData, root} from "./utils";

export default host => ({
  id,
  name,
  email,
  phone,
  translations,
  imageFile,
  selectedTags,
  isDemo,
  birthdate,
  active,
  socialLinks,
  countryId,
  observation,
  selectedCatalog
}) =>
  post(
    `${host}${root}/${id}`,
    addPutMethodToForm(
      makeFormData({
        name,
        email,
        phone,
        translations,
        imageFile,
        selectedTags,
        isDemo,
        birthdate,
        active,
        socialLinks,
        countryId,
        observation,
        selectedCatalog
      })
    )
  ).then(response => response.json());
