import {get} from '../utils'
import {root} from './utils'

const url = (host, id, include) => {
    let url = `${host}${root}/${id}`;
    if (include) {
        url += `?${include}`;
    }
    return url;
}

export default host => (id, include) =>
  get(url(host,id, include))
    .then(response => response.json())
    .then(json => json.data)
