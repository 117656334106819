import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {AttachFile, Send} from "@material-ui/icons";
import localization from "../../../config/localization";
import MaterialSelection from "../MaterialSelection";
import {setStatePromise} from "../../../utils";
import {ThreadContext} from ".";
import styled from "styled-components";
import InputAdornment from "@material-ui/core/InputAdornment";

class MessageSendInput extends Component {
  state = {
    showDialog: false,
    message: ""
  };

  onSendWrapper = () => {
    this.props.onSend(this.state.message);
    this.setState({ message: "" });
  };

  onKeyDown = e => {
    const enterPressed = e.key === "Enter";
    const shiftNotPressed = !e.shiftKey;

    if (enterPressed && shiftNotPressed) {
      e.preventDefault();
      this.onSendWrapper();
    }
  };

  render = () => {
    const { onSendMaterial } = this.props;

    const { showDialog, message } = this.state;

    return (
      <ThreadContext.Consumer>
        {({ pitch }) => {
          const userDoesNotBelongToConversation = !pitch.user_belongs_to_pitch;
          return (
            <div style={{ display: "flex", marginTop: 20, flexWrap: "wrap" }}>

              {/** Message Input Field with Send Material Button */}

              <DivR>
                <TextField
                  value={message}
                  onChange={e => this.setState({ message: e.target.value })}
                  onKeyDown={this.onKeyDown}
                  label={localization.get("pitch.write_new_message")}

                  disabled={
                    this.props.disabled || userDoesNotBelongToConversation
                  }
                  multiline
                  fullWidth
                  InputProps={{
                    style: { border: "#999999de 1px solid", alignItems: "center"},
                    startAdornment: (
                      <InputAdornment position="start">
                          <Button
                            variant="text"
                            color="default"
                            size='small'
                            mini={true}
                            style={{ boxShadow: "none", minWidth:"50px!important"}}
                            onClick={() => this.setState({ showDialog: true })}
                            disabled={
                              this.props.disabled || userDoesNotBelongToConversation
                            }
                          >
                            <AttachFile style={{fontSize: "1.2rem"}} />
                          </Button>
                          <SendMaterialDialog
                            open={showDialog}
                            onCancel={() => this.setState({ showDialog: false })}
                            onSendMaterial={(material, onProgress) =>
                              onSendMaterial(material, onProgress).then(() =>
                                this.setState({ showDialog: false })
                              )
                            }
                          />
                      </InputAdornment>
                    ),
                  }}
                />
              </DivR>

              { window.innerWidth < 1024 &&
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end"
                }}
              >
                {/** Send Message Button */}

                  <div style={{marginLeft: 10}}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={this.onSendWrapper}
                      disabled={
                        message.length === 0 ||
                        this.props.disabled ||
                        userDoesNotBelongToConversation
                      }
                    >
                      <Send/>
                    </Button>
                  </div>

              </div>
              }
            </div>
          );
        }}
      </ThreadContext.Consumer>
    );
  };
}

class SendMaterialDialog extends Component {
  state = {
    sending: false,
    showProgress: false,
    progress: 0,
    message: ""
  };

  onSubmit = () =>
    setStatePromise(this, { sending: true, progress: 0 })
      .then(() =>
        this.props.onSendMaterial(
          { ...this.materialSelection, message: this.state.message },
          (cur, tot) =>
            this.setState({ progress: Math.floor((cur / tot) * 100) })
        )
      )
      .finally(() =>
        setStatePromise(this, {
          sending: false,
          message: "",
          showProgress: false,
          progress: 0
        })
      );

  onCancel = () => {
    this.setState({ message: "" }, () => {
      this.props.onCancel();
    });
  };

  render = () => {
    const { open } = this.props;

    const { sending, message } = this.state;

    return (
      <Dialog open={open}>
        <DialogTitle>
          {localization.get("pitch.upload_media_title")}
        </DialogTitle>
        <DialogContent>
          {open && (
            <React.Fragment>
              <TextField
                style={{ marginBottom: 20 }}
                value={message}
                label={localization.get("pitches.optional_message")}
                onChange={e => this.setState({ message: e.target.value })}
                fullWidth
              />
              <MaterialSelection
                onChange={materialSelection =>
                  (this.materialSelection = materialSelection)
                }
              />
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} disabled={sending}>
            {localization.get("cancel")}
          </Button>
          <Button onClick={this.onSubmit} disabled={sending}>
            {sending ? this.state.progress + "%" : localization.get("send")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default MessageSendInput;

const DivR = styled.div`
  flex: 9;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
@media (max-width: 470px) {
   margin-left: 5px;
}
`;
