import React, { Component } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { Radio, RadioGroup, FormControlLabel, FormLabel,  FormControl } from '@material-ui/core';
import localization from '../../../config/localization';
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import {FormViewContainer} from '../../../components/Letflow/ViewContainer';
import { makeImageUrl } from '../../../layouts/Home/HomeElements/utils';
import { VideoPicker } from "../../../components/Letflow/Form";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class AdForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || null,
      name: props.name || '',
      link: props.link || '',
      duration: props.duration || 10,
      type: props.type || 'image', 
      file: props.file || null, 
      fileId: null,
      disabled: false,
      client: props.client || null,
      mobileImage: props.mobileImage || null,
      mobileImageId: null,
    };
  }

  onFileChange = file => file ? this.setState({fileId: file.id, file: {src: null, filename: null }}) : this.setState({fileId: null, file: { src: null, filename: null }})
  onMobileImageChange = mobileImage =>  mobileImage ? this.setState({mobileImageId: mobileImage.id, mobileImage: {src: mobileImage.path, filename: null }}) : this.setState({mobileImageId: null, mobileImage: { src: null, filename: null }})


  handleTagChange = (channelId, tags) => {
    this.setState(prevState => ({
      selectedTags: {
        ...prevState.selectedTags,
        [channelId]: tags
      }
    }));
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  submitRequest = () => this.props.submitRequest(this.state);
  
  setAd = ad => {
    this.setState({
      id: ad.id,
      name: ad.name,
      link: ad.link,
      duration: ad.duration,
      type: ad.type, 
      file: ad.image || ad.video, 
      disabled: false,
      client: {value: ad.client.id, label: ad.client.name},
      mobileImage: ad.mobileImage,
    })
  }

  validate = () => {
    const { name, fileId, client, duration } = this.state;
    
    if (!name) {
      GlobalSnackbar.show({message: localization.get('ad.error.name_required'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }
    
    if (!fileId && this.props.formType == "create") {
      GlobalSnackbar.show({message: localization.get('ad.error.file_id_required'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (!client && this.props.formType == "create") {
      GlobalSnackbar.show({message: localization.get('ad.error.client_required'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    if (!duration || duration < 1 || duration > 30) {
      GlobalSnackbar.show({message: localization.get('ad.error.duration_required'), type: GlobalSnackbarTypes.ERROR});
      return false;
    }

    return true;
  }

  render() {
    const { id, name, link, duration, channels, type, file, disabled, client, mobileImage } = this.state;

    return (
    	<FormViewContainer
        title={id ? localization.get('ad.update_ad') : localization.get('ad.create_ad')}
        submitRequest={this.submitRequest}
        validation={this.validate}
        onBack={() => this.props.history.goBack()}
        onRequestingStateChanged={value => this.setState({ disabled: value })}
      >
        <TextValidator
          id="name"
          name="name"
          fullWidth
          label={localization.get('form.name')}
          value={name}
          onChange={this.onChange}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
          style={{ width: "100%", marginBottom: "20px" }}
        />

      {!this.state.id ?
          <>
          <FormControl fullWidth>
            <span>{localization.get('form.client')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                }),
                control: (base, state) => ({
                  ...base,
                  borderColor: '#ddd',
                  '&:hover': {
                    borderColor: '#ddd' 
                  }
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('form.client')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={client => this.setState({ client: null, channels: [] }, () => this.setState({ client })) }
              validators={['required']}
              value={client}
              loadOptions={inputValue => {
                let filterBy = [{column: "name", filter: inputValue}];
                return request.client
                  .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                  .then(res => res.map(client => ({...client, value: client.id, label: `${client.name}`})))
              }}
              defaultOptions
            />
          </FormControl>
          <br />
          <br />
          </>
          :
          <>
            <TextValidator
              fullWidth
              style={{ width: "100%", marginBottom: "20px" }}
              label={localization.get('form.client')}
              id="clientName"
              name="clientName"
              value={client.label}
              disabled={true}
            />
          </>
        }
        <TextValidator
          fullWidth
          id="link"
          name="link"
          label={localization.get('form.link')}
          value={link}
          onChange={this.onChange}
          style={{ width: "100%", marginBottom: "20px" }}
        />
        <TextValidator
          fullWidth
          id="duration"
          name="duration"
          label={localization.get('form.duration')}
          value={duration}
          onChange={this.onChange}
          style={{ width: "100%", marginBottom: "20px" }}
          validators={['required']}
          type="number"
          min={1}
          max={30}
          helperText={localization.get('form.duration.helper')}
          errorMessages={[localization.get('validator.is_required')]}
        />

        <FormLabel component="legend">{localization.get('type')}</FormLabel>
        <RadioGroup
          aria-label="type"
          name="type"
          id="type"
          value={type}
          fullWidth
          style={{ width: "100%", flexDirection: "row" }}
          onChange={(e) => this.setState({ type: e.target.value, file: null })}
        >
          <FormControlLabel value="image" control={<Radio />} label={localization.get('image')} />
          <FormControlLabel value="video" control={<Radio />} label={localization.get('video')} />
        </RadioGroup>
        
        {file &&
          <>
            {type === "image" ?
              <div style={{width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={makeImageUrl({image: file}, 400, 400)} style={{width: 200, height: 200}}/>
              </div>
            :
              <VideoPicker video={(file.hls || file.dash) ? file : file.src} previewOnly={true}/>
            }
          </>
        }
        <FilePondUploader
          disableForm={disabled}
          updateDisableForm={value => this.setState({ disabled: value })}
          file={file}
          id={"file"}
          type={type}
          allowedTypes={type == "image" ? ["image/*"] : ["video/*"]}
          onIdChange={this.onFileChange}
          clientId={client && client.id}
        />

        {type == "image" &&
          <>
            <br />
            <br />
            <span>{localization.get('mobile_image')}</span>
            <div style={{width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={mobileImage && mobileImage.src ? process.env.REACT_APP_CDN_HOST.concat('/' + mobileImage.src) : makeImageUrl({image: mobileImage}, 400, 400)} style={{width: 200, height: 200}}/>
            </div>
            <FilePondUploader
              disableForm={disabled}
              updateDisableForm={value => this.setState({ disabled: value })}
              file={mobileImage}
              id={"mobileImage"}
              type={type}
              allowedTypes={["image/*"]}
              onIdChange={this.onMobileImageChange}
              clientId={client && client.id}
            />
          </>
        }
      </FormViewContainer>
    );
  }
}

export default AdForm;
