import React from "react";
import localization from "../../../../config/localization";

import {
  getStoredChannel,
  getStoredUser,
  setPageTitle
} from "../../../../api-client/core/authentication/utils";
import { LocalOffer } from "@material-ui/icons";
import TagSelection from "../../../../components/Letflow/TagSelection/TagSelection";
import { request } from "../../../../api-client";
import EditIcon from '@material-ui/icons/Edit';
import * as BFH from "../../../../components/Letflow/Form/BloomcloudsFormHelpers";
import TalentProfileModal from "../TalentProfileModal";
import queryString from 'querystring'
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import browserHistory from "../../../../utils/browserHistory";
import {get} from 'lodash';
import analyticsInstance from "../../../../analytics/analyticsInstance";

export default class extends React.Component {

  constructor(props) {
    super(props);
    let creator = props.creator;
    this.state = {
      selectedTags: creator ? creator.tags.map(t => t.id): [],
      tagGroups: [],
      disabled: false,
      initialized: false,
      editingContentCreatorType: false,
      contentCreator: creator,
      wizardStep: null
    }
  }

    componentDidMount = () => {
      setPageTitle(localization.get('title.talent_tags'))
      document.getElementsByTagName('html')[0].scrollTop = 0;
      let wizardStep = queryString.parse(window.location.search)['?wizardStep'];
      this.setState({wizardStep: wizardStep});
      this.requestTags(this.state.contentCreator.type.slug);
    }

    componentWillReceiveProps = (nextProps, nextContext) => {
      if (nextProps.creator) {
        this.setState({creator: nextProps.creator})
      }
    };

    requestTags = (contentCreatorType) => request.tagGroup.getAll({filterBy: [{ column: 'type', filter: contentCreatorType},{ column: 'creation_group', filter: '0'}]})
    .then((tagGroups) => this.setState({initialized: true, tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}))

    onSelectedTagsChanged = selectedTags =>
      this.setState({
        selectedTags,
        tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
      });

    updateTagGroups = (tagGroups, selectedTags) => {
      return tagGroups.map(tagGroup => ({
        ...tagGroup,
        tags: tagGroup.tags.map(tag => ({
          ...tag,
          state: selectedTags.find(
            selectedTag => selectedTag.toString() === tag.id.toString()
          )
            ? "selected"
            : "available"
        }))
      }));
    };

    checkBeforeSubmit= () => {
      // for (let tg of this.state.tagGroups) {
      //   if (tg.tags.every(t => t.state == 'available')) {
      //     GlobalSnackbar.show({message: localization.get('form.no_tags_selected_for_group', tg.name), type: GlobalSnackbarTypes.ERROR});   
      //     return false;
      //   }
      // }
      return true;
    }

    saveData = () => {
      if (this.checkBeforeSubmit()) {
        request.channelContentCreator.update({id: this.state.contentCreator.id, selectedTags: this.state.selectedTags})
        .then(() => {
          GlobalSnackbar.show({message: localization.get("talent_profile.tags_updated"), type: GlobalSnackbarTypes.SUCCESS})
          analyticsInstance.community({name: this.state.contentCreator.name, action: "Update_Tags"})

          request.authentication.getUser()
            .then(() => this.state.wizardStep && browserHistory.push("contents?wizardStep=3"));
        })
      }
    }

    renderTagSelection = () => {
      return (
        this.state.tagGroups && this.state.tagGroups.length > 0 &&
          <div style={{marginTop: '40px', display: 'flex', flexDirection: 'column', background: 'white', border: '1px solid #AAAAAA', borderRadius: '10px', padding: '20px 30px'}}>
            <BFH.SectionTitle>{localization.get("talent_profile_creator_type", this.state.contentCreator.type.name)} {get(getStoredChannel(), 'community.contentCreatorTypes', []).length > 1 && !this.state.editingContentCreatorType && <EditIcon style={{fontSize: '16px', marginLeft: '10px', marginBottom: '2px'}} onClick={() => this.setState({editingContentCreatorType: true})}/>}</BFH.SectionTitle>
            <TagSelection bloomCloudsView={true} data={this.state.tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged} disabled={this.state.disabled} />
            {!this.state.disabled && (
              <BFH.SubmitButton
                onClick={this.saveData}
                disabled={this.state.disabled}
              />
            )}
          </div>
      )
    }

    renderShowModal = () => {
      return <TalentProfileModal isEditing={true} onModalEnd={this.modalEndHandler} onModalClose={this.cancelHandler}/>
    }

    cancelHandler = () => {
      this.setState({ editingContentCreatorType: false })
    }

    modalEndHandler = (cct) => {
      if (cct && this.state.contentCreator.type && cct.id != this.state.contentCreator.type.id) {
        request.channelContentCreator.update({id: this.state.contentCreator.id, contentCreatorTypeId: cct.id}).then(
          () => window.location.reload()
        );
      }
    }

    render = () => {
      return (
        <div className="talent-profile-section">
          <div className="talent-profile-section-title" style={ this.state.wizardStep ? {justifyContent: 'space-between', marginBottom: 0} : {marginBottom: 0}}>
            {this.state.wizardStep && <div className="talent-profile-section-title-label">{localization.get("talent_profile_wizard_step", this.state.wizardStep)}</div>}
            <div className="talent-profile-section-title">
              <div className="talent-profile-section-title-logo">
                <LocalOffer style={{ fontSize: "16px", color: "#676767" }}/>
              </div>
              <div className="talent-profile-section-title-label">{localization.get("talent_profile.tags")}</div>
            </div>
          </div>
          {this.state.editingContentCreatorType && this.renderShowModal()}
          {this.state.contentCreator && this.state.initialized && this.renderTagSelection()}
        </div>
      );
    }
}