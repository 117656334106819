import {get} from "../utils";
import {root} from "./utils";
import {getStoredUser} from "../authentication/utils";

export default host => () => {
  //
  let url = `${host}${root}`;

  return get(appendUserIdQueryStringToUrl(url), null, false, false, true)
    .then(response => response.json());
};

const appendUserIdQueryStringToUrl = url => url + `?uid=${getStoredUser().id}`;
