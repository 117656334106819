import {post} from "../utils";


const form = (username) => {
  const f = new FormData()

  f.append('username', username)
  return f
}

export default host => ({username, channelId}) =>
  post(`${host}/public/channel/${channelId}/verification`, form(username))
    .then(response => response.json())