import React from 'react'
import CustomTooltip from '../utils/CustomTooltip';
import defaultImage from '../assets/img/no-image.jpg'

const Information = ({ imageSrc, title, subtitle, onImageClick, onTitleClick, onSubtitleClick, onAnyClick = () => { } }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px', width: window.innerWidth > 1070 ? "250px" : "190px", color: 'var(--secondary-font-color, rgb(60, 72, 88))' }}>
      <div className='soundbar-button' style={{ marginRight: '10px' }}>
        <img alt="..." style={{ borderRadius: '10px' }} src={imageSrc || defaultImage} width={'60px'} height={'60px'} onClick={onImageClick || onAnyClick} />
      </div>
      <div style={{width: "100%"}}>
        <InformationText onClick={onTitleClick || onAnyClick} value={title} bold />
        <InformationText onClick={onSubtitleClick || onAnyClick} value={subtitle} fontSize={12} />
      </div>
    </div>
  )
}

const InformationText = ({ onClick, value, bold = false, fontSize }) => {
  return (
    <div className='soundbar-button' style={{ overflow:"hidden", textOverflow: "ellipsis", userSelect: 'none', whiteSpace: 'nowrap', maxWidth: window.innerWidth > 1070 ?  190: 130,fontSize }} onClick={onClick}>
      <p>{bold ? <b>{value}</b> : <span>{value}</span>}</p>
    </div>
  )
}

const cutText = text => {
  if (!text) {
    return ''
  }
  if (text.length > 12) {
    return text.substring(0, 10) + '...'

  }
  return text
}

export default Information