import React from "react";
import localization from "../../../config/localization";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import AccountBox from "@material-ui/icons/AccountBox";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Work from "@material-ui/icons/Work";
import Visibility from "@material-ui/icons/Visibility";
import Hidden from "@material-ui/core/Hidden";
import {
    setPageTitle,
    getStoredUser,
    slugify,
    channelPathGenerator,
    getStoredChannel,
    getActiveChannel
} from "../../../api-client/core/authentication/utils";
import "./TalentProfile.css";
import withStyles from "@material-ui/core/styles/withStyles";
import TalentInfo from "./TalentInfo";
import TalentTags  from "./TalentTags";
import TalentContents from "./TalentContents";
import {Link, Redirect, Route, Router, Switch} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {isNull} from "util";
import BloomcloudsToggleSwitch from "../../../components/Letflow/BloomcloudsToggleSwitch";
import ContentCreationForm from './TalentContents/ContentCreationForm';
import { request } from "../../../api-client";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import browserHistory from "../../../utils/browserHistory";
import { Dialog, DialogTitle, DialogContent} from "@material-ui/core";
import {get} from 'lodash';
import analyticsInstance from "../../../analytics/analyticsInstance";

const styles = theme => ({
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

class TalentProfile extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mobileOpen: false,
      visibility:  false,
      openContactDialog: false,
      creator: null,
      loaded: null,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("title.talent_profile"));
    document.getElementsByTagName("html")[0].scrollTop = 0;

    this.requestCreator();
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  isSelected = route => route && window.location.pathname.includes(route);


  userNeedsExtraRegistry = () => {
    const currentUser = getStoredUser();
    return true;
    
    if (currentUser) {
      const channel = currentUser.channels.find(cr => cr.channel.id == getActiveChannel().id);
      return channel && !channel.completed_form;
    }

    return false;
  }

  handleVisibilityChange = (visibility) => {
    let creator = this.state.creator;

    if (!creator) return;

    if (creator.status == "pending") {
      const pendingMessage = get(getStoredChannel(), 'community.pending_message', null);
        GlobalSnackbar.show({
          type: GlobalSnackbarTypes.INFO,
          message: pendingMessage ? pendingMessage : localization.get("talent_profile.visibility_check_pending")
        });

        return;
    }

    if (creator.status == "declined") {
      return this.setState({openContactDialog: true});
    }

    if (visibility) {
      let translationEs = creator.translations && creator.translations.find(t => t.locale === "es");
      let descriptionEs = translationEs && translationEs.description;
      var span = document.createElement('span');
      span.innerHTML = descriptionEs;
      let description = span.textContent || span.innerText;
      if (!creator.image || !(description && description.length > 0)) {
        GlobalSnackbar.show({message: localization.get("talent_profile.visibility_check"), type: GlobalSnackbarTypes.ERROR});
        return;
      }
    }

    request.channelContentCreator.update({id: creator.id, active: visibility ? 1 : 0})
      .then(response => this.requestCreator())
      .then(() => analyticsInstance.community({name: creator.name, action: visibility ? "Visible" : "Invisible"})); 
  }

  requestCreator = () => request.contentCreator.getForChannelUser()
    .then(creator => this.setState({ creator, loaded: true }))
    .catch(e => {
      if(e.status == 404) {
        this.setState({ creator: null, loaded: true })
      }
    })

  makeContactDialog = () => 
    <Dialog
      open={this.state.openContactDialog}
      onBackdropClick={() => this.setState({openContactDialog: false})}
      onEscapeKeyDown={() => this.setState({openContactDialog: false})}
    >
      <DialogTitle>{localization.get('talent_profile.decline_contact')}</DialogTitle>
      <DialogContent>
        {get(getStoredChannel(), 'community.declined_message', false) ? get(getStoredChannel(), 'community.declined_message', "") : localization.get('talent_profile.decline_description', get(getStoredChannel(), 'community.email', ''))}
      </DialogContent>
    </Dialog>

  render = () => {
    const { classes } = this.props;
    const currentUser = getStoredUser();
    const { creator, loaded } = this.state;

    if (!currentUser) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        hideCancel: true,
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
      return null;
    }

    if (!loaded) return null;

    const sidebar = (() => {
      const makeSidebarLink = (isSelectedLabel, Logo, to, label, disabled, tooltipText=localization.get("talent_profile.not_available")) =>
        (
          <Link
            onClick={e => {
              if (isNull(to) || disabled || this.isSelected(isSelectedLabel)) {
                e.preventDefault();
              } else {
                this.handleDrawerToggle();
              }
            }}
            to={to || "#"}
          >
            <div
              className={`talent-profile-link-container ${
                this.isSelected(isSelectedLabel) ? "selected" : ""
              }`}
            >
              <div className="talent-profile-link-logo">
                <Logo style={{ fontSize: "14px" }} />
              </div>
              {(() => {
                const content = (
                  <div className="talent-profile-link">{label}</div>
                );
                if (disabled) {
                  return (
                    <Tooltip
                      title={tooltipText}
                      placement="right"
                    >
                      {content}
                    </Tooltip>
                  );
                }
                if (isNull(to)) {
                  return (
                    <Tooltip
                      title={localization.get("sidebar.soon")}
                      placement="right"
                    >
                      {content}
                    </Tooltip>
                  );
                }
                return content;
              })()}
            </div>
          </Link>
        );
      const makeSidebarLinkWithCallback = (Logo, onClick, label) => 
        (
          <a onClick={onClick}>
            <div className="talent-profile-link-container">
              <div className="talent-profile-link-logo">
                <Logo style={{ fontSize: "14px" }} />
              </div>
              {(() => {
                const content = (
                  <div className="talent-profile-link">{label}</div>
                );
                if (isNull(onClick)) {
                  return (
                    <Tooltip
                      title={localization.get("sidebar.soon")}
                      placement="right"
                    >
                      {content}
                    </Tooltip>
                  );
                }
                return content;
              })()}
            </div>
          </a>
        );

        return (
          <div className="talent-profile-drawer-container">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                zIndex: "1000"
              }}
            >
              <Close />
            </IconButton>
            <div className="talent-profile-avatar-container" style={creator && creator.image && {backgroundImage: `url(${request.file.image.makeCroppedUrl(creator.image.id, 200, 200)})`}}></div>

            <div className="talent-profile-user-info-container">
              <div className="talent-profile-name">{creator ? creator.name : currentUser.name}</div>
              {creator && <div style={{color: creator.status === "approved" ? "green": creator.status === "declined" ? "red" : "black"}} className="talent-profile-status">{localization.get("channel.community."+creator.status)}</div>}
              <div style={{marginTop: '15px'}}>
                <BloomcloudsToggleSwitch
                  id="visible-toggle-switch"
                  Name="visible"
                  Text={[localization.get("form.visible"), localization.get("form.visible_not")]}
                  onChange={(e) => this.handleVisibilityChange(e.target.checked)}
                  currentValue={this.state.creator && this.state.creator.active}
                  disabled={!creator}
                  uncheckedColor="red"
                />
              </div>
            </div>

            <div className="talent-profile-link-set">
              {makeSidebarLink(
                "general-information",
                AccountBox,
                channelPathGenerator("profile/general-information"),
                localization.get("talent_profile.general_info"),
                false
              )}
              {makeSidebarLink(
                "tags",
                LocalOffer,
                channelPathGenerator("profile/tags"),
                localization.get("talent_profile.tags"),
                !creator,
                localization.get("talent_profile.first_upload_general_info")
              )}
              {makeSidebarLink(
                "contents",
                Work,
                channelPathGenerator("profile/contents"),
                localization.get("talent_profile.contents"),
                !creator,
                localization.get("talent_profile.first_upload_general_info")
              )}
            </div>
            <div className="talent-profile-link-set">
              {makeSidebarLink(
                null,
                Visibility,
                creator ? channelPathGenerator(`contenido/${creator.id}-${slugify(creator.name)}`) : null,
                localization.get("talent_profile.profile"),
                !creator || !this.state.visibility,
                !creator ? localization.get("talent_profile.profile_required") : localization.get("talent_profile.profile_not_available")
              )}
            </div>
          </div>
        );
      })();
      
    return (
      <div id="innerinner" className="home-playlists" style={{paddingTop: '20px', backgroundColor: 'rgb(247,247,247)'}}>
        {this.makeContactDialog()}
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={this.handleDrawerToggle}
          className={classes.navIconHide}
          style={{
              zIndex:100
          }}
        >
          <MenuIcon />
        </IconButton>
  
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
            keepMounted: true // Better open performance on mobile.
            }}
          >
            {sidebar}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            containerStyle={{
              height: '100%'
            }}
            PaperProps={{
              style: {
                float: "left",
                width: "250px",
                boxShadow: "0 1px 10px 0 rgba(0,0,0,.1)",
                borderRight: "none",
                backgroundColor: "white",
                zIndex: "0",
                minHeight: "100vh",
                marginTop: "70px"
              }
            }}
          >   
            {sidebar}
          </Drawer>
        </Hidden>
        <div className="talent-profile-content">
          <Router {...this.props}>
            <Switch>
              <Route
                exact
                path={channelPathGenerator("profile")}
                component={() => <Redirect to={channelPathGenerator("profile/general-information")}/>}
              />
              <Route path={channelPathGenerator("profile/general-information")}>
                <TalentInfo requestCreator={this.requestCreator} creator={this.state.creator} />
              </Route>
              <Route path={channelPathGenerator("profile/tags")}>
                {!creator ? <Redirect to="general-information"/> : <TalentTags requestCreator={this.requestCreator} creator={this.state.creator} />}
              </Route>
              <Route path={channelPathGenerator("profile/contents/new")}>
                {!creator ? <Redirect to="general-information"/> : <ContentCreationForm></ContentCreationForm>}
              </Route>
              <Route
                path={channelPathGenerator("profile/contents/:contentCreationId/edit")}>
                {!creator ? <Redirect to="general-information"/> : <ContentCreationForm></ContentCreationForm>}
              </Route>
              <Route path={channelPathGenerator("profile/contents")}>
                {!creator ? <Redirect to="general-information"/> : <TalentContents></TalentContents>}
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TalentProfile);