import React from "react";
import Footer from "../../../layouts/Home/HomeElements/Footer";
import PublicNavBar from "../../../layouts/Home/HomeElements/PublicNavBar";
import HomeArtist from "../../../layouts/Home/Artist";

class Artist extends React.Component {

  render() {
    return (
      <React.Fragment>
        <PublicNavBar showLinks={true}/>
        <div id={"inner"}>
            <HomeArtist {...this.props}/>
        </div>
        <Footer {...this.props}/>

      </React.Fragment>
    )
  }
}

export default Artist