import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveClient} from "../authentication/utils";

const url = (host, tags, notTags, age, countryId, options) =>
  appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/speakers?is_demo=0&tags_ids=${tags.join(',')}&not_tags_ids=${notTags.join(',')}${addFilters(age, countryId)}&include=image;tags&active=1`, {...options, alreadyHasQueryParams: true})

const addFilters = (age, countryId) => {
  let filters = '';
  if (age) {
    let currentAge = new Date().getFullYear()
    let min = `${currentAge - age.min}-00-00`
    let max = `${currentAge - age.max}-00-00`
    filters += `&birthdate[]=ge:${max}&birthdate[]=lt:${min}`
  }
  if (countryId) {
    filters += `&country_id=${countryId}`
  }
  return filters
}

export default host => (tags, notTags, age, countryId, options) =>
  get(url(host, tags, notTags, age, countryId, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


