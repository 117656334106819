import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Edit from "./Update";
import Ranking from "./Ranking";
import LogicProjects from './LogicProjects'

const MusicsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/musics/:id/edit" component={Edit} />
        <Route path="/panel/musics/create" component={Create} />
        <Route path="/panel/ranking/musics" component={Ranking} />
        <Route path="/panel/musics/logicProjects" component={LogicProjects} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default MusicsRouting