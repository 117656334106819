import getAll from './getAll'
import del from './delete'
import create from './create'
import get from './get'
import update from './update'
import searchFor from './searchFor'
import share from './share'
import unshare from './unshare'
import review from './review'
import countPendingReview from './countPendingReview'
import downloadDemo from './downloadDemo'
import getAllForClient from "./getAllForClient";
import getForClient from "./getForClient";
import downloadZip from "./downloadZip";
import getDownloadLink from "./getDownloadLink";
import shareByChannel from "./shareByChannel";

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  get: get(host),
  update: update(host),
  searchFor: searchFor(host),
  share: share(host),
  shareByChannel: shareByChannel(host),
  unshare: unshare(host),
  review: review(host),
  countPendingReview: countPendingReview(host),
  downloadDemo: downloadDemo(host),
  getAllForClient: getAllForClient(host),
  getForClient: getForClient(host),
  downloadZip: downloadZip(host),
  getDownloadLink: getDownloadLink(host),
})
