import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'

export default class extends Component {

  componentDidMount() {
    request.tag.get(this.props.match.params.id, 'include=group.types.contentCreatorType;group.childrens;translations;childrens;parents').then(tg => this.refs.form.setTag(tg))
  }

  requestUpdate = state => {

    this.setState({ disabled: true })

    const {
      translations,
      selectedTagGroup,
      order,
      showOnSmartSearch,
      childrens
    } = state

    return request.tag.update({
      id: this.props.match.params.id,
      translations,
      tagGroupId: selectedTagGroup.value,
      order,
      showOnSmartSearch,
      childrens: childrens.map(t => t.value),
    }).then(() => this.props.history.goBack())
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => this.setState({ image })

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        initializationRequest={this.requestTag}
        title={localization.get('tag.update_tag')}
        submitRequest={this.requestUpdate}
        isMusic={this.props.location.pathname.includes('music')}
        isTalent={this.props.location.pathname.includes('talents')}
        isAudiovisual={this.props.location.pathname.includes('audiovisual')}
        isEditorial={this.props.location.pathname.includes('editorial')}
      />
    )
  }
}
