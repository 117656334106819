import React, {Component} from 'react'
import Snackbar from './Snackbar'
import localization from '../../../config/localization';

class TryAgainSnackbar extends Component {

  state = {
    message: localization.get('snackbar.try_again_default_message')
  }

  show = message => {
    if (message) {
      this.setState({ message })
    }
    this.refs.snackbar.show()
  }
  render = () => <Snackbar ref='snackbar' color='danger' message={this.state.message} />
}

export default TryAgainSnackbar
