import React from "react";

import Button from "components/CustomButtons/Button.jsx";
import ShakaPlayer from "../ShakaPlayer";

export default ({ onVideoChange, buttonText, disabled = false, video, style = null, color = 'dark', previewOnly = false }) => {

  let imageInput

  const onChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => { onVideoChange({ src: reader.result, file }) }
    reader.readAsDataURL(file);
  }

  const button = () => <div style={{ textAlign: 'center', display: previewOnly && 'none' }}>
    <Button style={style} color={color} onClick={() => imageInput.click()} disabled={disabled}>{buttonText}</Button>
  </div>

  return (
    <div>
      <input ref={(r => imageInput = r)} style={{ display: 'none' }} type="file" accept="video/*,.mp4" onChange={onChange} />
      {button()}
      <br />
      {video && (video.hls || video.dash) ?
        <ShakaPlayer
          playOnHover={false}
          video={video}
        />
      :
        <video
          style={{ width: '100%', borderRadius: 6, maxWidth: '500px', marginLeft: 'calc(50% - 250px)' }}
          src={video}
          controls
        />
      }
    </div>
  )
}