import getAll from './getAll'
import del from './delete'
import create from './create'
import get from './get'
import update from './update'
import comment from './comment'
import countPending from './countPending'
import integrateToPlatform from './integrateToPlatform'
import audioUrl from './audioUrl'

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  get: get(host),
  update: update(host),
  comment: comment(host),
  countPending: countPending(host),
  integrateToPlatform: integrateToPlatform(host),
  audioUrl: audioUrl(host)
})
