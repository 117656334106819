import "layouts/Home/Home.css";
import React from "react";
import {getActiveChannel, getStoredChannel} from "../../../api-client/core/authentication/utils";
import st from "../HomeNew.module.css"
import font from "./Styles/Fonts/fonts.module.css"
import RichEditor from "../../../components/Letflow/RichTextEditor/EditorialEditor";
import styled from "styled-components";
import {convertFromRaw, EditorState} from "draft-js";

class HomeElementTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileResize: (getActiveChannel() && getStoredChannel().preferences && getStoredChannel().preferences.mobile_font_resize) ? (Number(getStoredChannel().preferences.mobile_font_resize) / 100) : 1,
    }
  }

  hasTitle = title => {
    if (!title || title.length < 2) {
      return false;
    }else if(title.startsWith("{")) {
      let state = EditorState.createWithContent(convertFromRaw(JSON.parse(title)));
      const content = state.getCurrentContent();
      const text = content.getPlainText();

      return !!text;
    }

    return true;
  }

  getStoredChannelTitleWeight = () => {
    let channel = getStoredChannel();
    if (channel && channel.preferences && channel.preferences.title) {
      switch(channel.preferences.title.weight) {
        case "bold": return "forced-bold-text";
        case "normal": return "forced-normal-text";
        default: return "bold";
      }
    }
    return "bold";
  }

  mobileFontSize = (fontSize) => {
    if (fontSize) {
      let size = fontSize.endsWith('px') ? fontSize.substr(0,fontSize.length-2) : null;
      if (size && size > 24) {
        return "24px";
      } else {
        return fontSize;
      }
    }
  }

  render = () => {
    if (this.props.showSectionTitle && this.hasTitle(this.props.homeElement.title)) {
      return (
        <div className={st['category-title']}>
          {this.props.homeElement.title.startsWith("{") ?
            <EditorContainer mobileDefaultFontSize={Math.round(14 * this.state.mobileResize)}>
              <RichEditor
                customStyles={{margin: 0}}
                editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.homeElement.title)))}
                readOnly={true}
                onChange={() => console.log('read only')}
              />
            </EditorContainer>
            :
            <h2
              className={`${st["title"]} ${st[this.getStoredChannelTitleWeight()]}
                     ${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`}
              style={getActiveChannel() ? {
                fontSize: window.innerWidth > 600 ? getStoredChannel().preferences.title.size : this.mobileFontSize(getStoredChannel().preferences.title.size),
                color: getStoredChannel().preferences.title.color,
              } : {}}>
              {this.props.homeElement.title}
            </h2>
          }
        </div>
      )
    } else if (this.props.spacing === "medium" || this.props.spacing === "small") {
      return (
        <div style={{
          width: "100%",
          position: "relative",
          float: "left",
          marginTop: this.props.spacing === "medium" ? 40 : 20
        }}
        />
      )
    } else {
      return null;
    }
  }
}

HomeElementTitle.defaultProps = {
  showSectionTitle: true
}

export default HomeElementTitle;


const EditorContainer = styled.div`
  .rdw-link-decorator-icon {
    display: none;
  }
  overflow-wrap: break-word;
  @media (max-width: 650px) {
    font-size: ${props => props.mobileDefaultFontSize ? `${props.mobileDefaultFontSize}px` : "14px"};
  }
`;