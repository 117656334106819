import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {
  componentDidMount = () => {
    document.title = localization.get('title.tag_groups') + ' - Feater'
  }

  onAdd = () => {
    let createUrl =  ''
    if(this.props.isMusic) {
      createUrl = makeUrlWithTableParams(`/panel/music/tag_groups/create`);
    } else if (this.props.isTalent) {
      createUrl = makeUrlWithTableParams(`/panel/talents/tag_groups/create`);
    } else if (this.props.isEditorial) {
      createUrl = makeUrlWithTableParams(`/panel/editorial/tag_groups/create`);
    } else {
      createUrl = makeUrlWithTableParams(`/panel/audiovisual/tag_groups/create`);
    }
    return this.props.history.push(createUrl)
  }
  onDelete = tagGroup => this.refs.viewContainer.requestDeletion(tagGroup.id)

  render = () =>
    <AllViewContainer
      requestDeletion={this.requestTagGroupDelete}
      onAdd={this.onAdd}
      ref='viewContainer'>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Table {...this.props}/>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </AllViewContainer>

}
