import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {Style} from '@material-ui/icons'
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  componentDidMount = () => {
      document.title = localization.get('title.content_creator_types') + ' - Feater'
  }

  onAdd = () => this.props.history.push(makeUrlWithTableParams(this.props.isTalent ? `/panel/talents/content_creator_types/create` : this.props.isAudiovisual ? `/panel/audiovisual/content_creator_types/create` : `/panel/editorial/content_creator_types/create`))

  render = () =>
    <AllViewContainer
      onAdd={this.onAdd}
      viewIcon={<Style />}
      ref='viewContainer'
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>

}
