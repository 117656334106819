import getAll from './getAll'
import update from './update'
import create from './create'
import get from './get'
import del from './delete'
import getAllForEntity from './getAllForEntity'
import makePurchase from './makePurchase'
import purchases from './purchases'
import getForChannel from './getForChannel'
import getPaypalWebhook from './getPaypalWebhook'
import getUserPurchasesForChannel from './getUserPurchasesForChannel'
import purchasesExport from './purchasesExport'
import makeGift from './makeGift'
import cancelPurchase from  './cancelPurchase'
import importPurchases from  './importPurchases'

export default host => ({
  getAll: getAll(host),
  update: update(host),
  get: get(host),
  getForChannel: getForChannel(host),
  create: create(host),
  delete: del(host),
  getAllForEntity: getAllForEntity(host),
  makePurchase: makePurchase(host),
  makeGift: makeGift(host),
  purchases: purchases(host),
  cancelPurchase: cancelPurchase(host),
  getPaypalWebhook: getPaypalWebhook(host),
  getUserPurchasesForChannel: getUserPurchasesForChannel(host),
  purchasesExport: purchasesExport(host),
  importPurchases: importPurchases(host)
})
