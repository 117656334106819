import {TableButton, TableDowloadButton} from '../../components/Letflow/Table'
import {Delete, Edit, PlayArrow} from '@material-ui/icons';
import {request} from 'api-client';
import React, {Component} from "react";
import ViewContainer from '../../components/Letflow/ViewContainer';
import {MultiItemSelection} from '../../components/Letflow/Form';
import SearchBar from './SearchBar';
import BaseTable from './BaseTable';
import {noop} from 'rxjs';
import localization from '../../config/localization';
import ErrorBoundary from '../../components/Letflow/ErrorBoundary';
import GlobalSoundBar from '../../sound-bar/GlobalSoundBar';
import {makeWaveformUrl} from '../../utils';

const editTitle = localization.get('tooltip.edit')
const deleteTitle = localization.get('tooltip.delete')
const playTitle = localization.get('tooltip.play')

const EditButton = ({ value, handleClick }) => <TableButton title={editTitle} onClick={() => handleClick(value)}><Edit /></TableButton>
const DeleteButton = ({ value, handleClick }) => <TableButton title={deleteTitle} onClick={() => handleClick(value)}><Delete /></TableButton>
const PlayButton = ({ value, handleClick }) => <TableButton title={playTitle} onClick={() => handleClick(value)}><PlayArrow /></TableButton>

const ClientsTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Clientes' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Tipo', id: 'type', accessor: v => v.type.name },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const UsersTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Usuarios' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Client', id: 'client', accessor: v => v.client ? v.client.name : '-' },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const AlbumsTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Albumes' data={data} columns={[
    { Header: 'Titulo', accessor: 'title' },
    { Header: 'Subtitulo', accessor: 'subtitle' },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const SpeakersTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Locutores' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Descripcion', accessor: 'description' },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const TagsTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Etiquetas' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Grupo', id: 'group', accessor: v => v.group.name },
    { Header: 'Tipo', id: 'type', accessor: v => v.group.type },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const TagGroupsTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Grupo de Etiquetas' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    { Header: 'Etiquetas', id: 'tags', accessor: v => v.tags.map(x => x.name).join(', ') },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: v => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={v} handleClick={handleEdit} />
          <DeleteButton value={v} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />

const MusicsTable = ({ data, handleEdit, handleDelete, handlePlay }) =>
  <BaseTable title='Temas' data={data} columns={[
    { Header: 'Codigo', accessor: 'code' },
    { Header: 'Titulo', accessor: 'title' },
    { Header: 'Etiquetas', id: 'tags', accessor: music => music.tags.map(tag => tag.name).join(', ') },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: music => (
        <div style={{ textAlign: 'right' }}>
          <TableDowloadButton download={() => request.music.download(music.id)} />
          <EditButton value={music} handleClick={handleEdit} />
          <DeleteButton value={music} handleClick={handleDelete} />
          <PlayButton value={music} handleClick={handlePlay} />
        </div>
      )
    },
  ]} />

const VoicesTable = ({ data, handleEdit, handleDelete, handlePlay }) =>
  <BaseTable title='Voces' data={data} columns={[
    { Header: 'Titulo', accessor: 'title' },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: voice => (
        <div style={{ textAlign: 'right' }}>
          <TableDowloadButton download={() => request.voice.download(voice.id)} />
          <EditButton value={voice} handleClick={handleEdit} />
          <DeleteButton value={voice} handleClick={handleDelete} />
          <PlayButton value={voice} handleClick={handlePlay} />
        </div>
      )
    },
  ]} />

const ContentCreatorsTable = ({ data, handleEdit, handleDelete }) =>
  <BaseTable title='Creadores de contenido' data={data} columns={[
    { Header: 'Nombre', accessor: 'name' },
    {
      Header: 'Acciones', id: 'actions', filterable: false, sortable: false, accessor: contentCreator => (
        <div style={{ textAlign: 'right' }}>
          <EditButton value={contentCreator} handleClick={handleEdit} />
          <DeleteButton value={contentCreator} handleClick={handleDelete} />
        </div>
      )
    },
  ]} />


const filterModules = [
  { id: 0, value: 'Clientes', ref: 'clients' },
  { id: 7, value: 'Usuarios', ref: 'users' },
  { id: 1, value: 'Albumes', ref: 'albums' },
  { id: 3, value: 'Temas', ref: 'musics' },
  { id: 5, value: 'Grupo de Etiquetas', ref: 'tagGroups' },
  { id: 6, value: 'Etiquetas', ref: 'tags' },
  { id: 8, value: 'Creadores de contenido', ref: 'content_creators' },
];

class Search extends Component {

  state = {
    clients: [],
    albums: [],
    users: [],
    tags: [],
    tagGroups: [],
    musics: [],
    selectedFilters: filterModules.map(fm => fm.id),
    content_creators: [],
  };

  componentDidMount = () => {
    document.title = localization.get('title.global_search') + ' - Feater'
  };

  render = () => {

    const multiItemSelectionItems = filterModules.map(fm => {
      const length = this.state[fm.ref].length
      return {
        id: fm.id,
        value: `${fm.value}${length > 0 ? ` (${length})` : ''}`
      }
    });

    return (
      <ViewContainer ref='viewContainer'>
        <SearchBar handleSearch={this.handleSearch} />
        <MultiItemSelection
          items={multiItemSelectionItems}
          selectedIds={this.state.selectedFilters}
          onSelectedItemsChanged={selectedFilters => this.setState({ selectedFilters })}
        />
        {this.makeClientsTable()}
        {this.makeUsersTable()}
        {this.makeAlbumsTable()}
        {this.makeMusicsTable()}
        {this.makeTagsTable()}
        {this.makeTagGroupsTable()}
        {this.makeContentCreatorsTable()}
      </ViewContainer>
    )
  }

  // Tables

  makeClientsTable = () => this.makeTable(0, 'clients', ClientsTable, 'client', this.searchClients, client => client.name)

  makeTable = (filterId, stateProperty, TableComponent, moduleName, searchMethod, elementNameAccessor, handlePlay = noop) => {

    const { selectedFilters } = this.state

    if ((selectedFilters.length > 0 && !this.state.selectedFilters.includes(filterId)) || this.state[stateProperty].length === 0) {
      return null
    }

    return (
      <ErrorBoundary>
        <TableComponent
          data={this.state[stateProperty]}
          handleEdit={element => this.props.history.push(`/${stateProperty}/${element.id}/edit`)}
          handleDelete={element => this.openDeleteDialog(moduleName, element.id, searchMethod, elementNameAccessor(element))}
          handlePlay={handlePlay}
        />
      </ErrorBoundary>
    )
  }

  makeUsersTable = () => this.makeTable(7, 'users', UsersTable, 'user', this.searchUsers, user => user.name);

  makeAlbumsTable = () => this.makeTable(1, 'albums', AlbumsTable, 'album', this.searchAlbums, album => album.title);

  makeMusicsTable = () => this.makeTable(3, 'musics', MusicsTable, 'music', this.searchMusics, music => music.title, this.playTrack('music'));

  playTrack = type => track => GlobalSoundBar.loadSingleTrackAndPlay({ fetchAudioUrl: () => request[type].audioUrl(track.id), title: track.title, waveform: makeWaveformUrl(track) });

  makeTagGroupsTable = () => this.makeTable(5, 'tagGroups', TagGroupsTable, 'tagGroup', this.searchTagGroups, tagGroup => tagGroup.name);

  makeTagsTable = () => this.makeTable(6, 'tags', TagsTable, 'tag', this.searchTags, tag => tag.name);

  makeContentCreatorsTable = () => this.makeTable(8, 'content_creators', ContentCreatorsTable, 'contentCreator', this.searchContentCreators, contentCreator => contentCreator.name);

  // Requests

  searchClients = query => this.searchRequestFactory('client', 'clients', query);

  searchRequestFactory = (clientModule, stateProperty, query) => request[clientModule].searchFor(query)
    .then(result => { this.setState({ [stateProperty]: result }) })
    .catch(console.error);

  searchUsers = query => this.searchRequestFactory('user', 'users', query);

  searchAlbums = query => this.searchRequestFactory('album', 'albums', query);

  searchMusics = query => this.searchRequestFactory('music', 'musics', query);

  searchTags = query => this.searchRequestFactory('tag', 'tags', query);

  searchTagGroups = query => this.searchRequestFactory('tagGroup', 'tagGroups', query);

  searchContentCreators = query => this.searchRequestFactory('contentCreator', 'content_creators', query);

  openDeleteDialog = (clientModule, elementId, searchRequest, elementName) =>
    this.refs.viewContainer.openAsyncCommandAlert(
      () => request[clientModule].delete(elementId),
      () => searchRequest(this.state.lastSearchedValue),
      `Se va a eliminar el elemento ${elementName}`,
      'No se pudo eliminar el elemento',
      'Se elimino el elemento con exito',
    )

  handleSearch = query => {
    this.setState({ lastSearchedValue: query })
    this.searchClients(query)
    this.searchUsers(query)
    this.searchAlbums(query)
    this.searchMusics(query)
    this.searchTags(query)
    this.searchTagGroups(query)
    this.searchContentCreators(query)
  }

}

export default Search
