import {
  Button, Dialog,
  DialogTitle, TextField
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React from 'react';
import localization from "../../../config/localization";
import { validateEmail } from "../../../utils";

class GiftDialog extends React.Component {

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
  }

  validate = values => {
    const errors = {};

    if (!values.email || values.email.length == 0) {
      errors.email = localization.get("validator.is_required");
    } else if (!validateEmail(values.email)) {
      errors.email = localization.get("validator.is_email");
    }
    // if (!values.repeatEmail || values.repeatEmail.length == 0) {
    //   errors.repeatEmail = localization.get("validator.is_required");
    // } else if (values.repeatEmail != values.email) {
    //   errors.repeatEmail = localization.get("validator.emails_not_match");
    // }
    return errors;
  };

  initialValues = () => {
    let initialValues = {
      email: this.props.email || '',
      // repeatEmail: '',
      name: this.props.name || '',
      message: this.props.message || ''
    }
    return initialValues;
  }

  onSubmit= (values) => {
    this.props.onSubmit({email: values.email, name: values.name, message: values.message});
  }

  isMobile = () => window.innerWidth < 500;

  render = () => (
    <Dialog
      disableEnforceFocus
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      PaperProps={{ style: window.innerWidth > 450 ? { maxWidth: (window.innerWidth < 600) ? "100vw" : "90vw", width: 750, minHeight: 350, background: "var(--secondary-color, white)", display: 'flex', flexDirection: 'column', justifyContent: "space-between"} : { maxWidth: "100vw", width: 750, margin: 5, minHeight: 350, background: "var(--secondary-color, white)", display: 'flex', flexDirection: 'column', justifyContent: "space-between"}}}
    >
      <DialogTitle style={{background: "var(--secondary-color, white)"}}>
        <span style={{color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}>
          {this.props.email ? localization.get("form.gift_data.update") : localization.get("form.gift_data.create")}
        </span>
      </DialogTitle>
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          this.onSubmit(values)
          actions.resetForm();
        }}
      >
        {formikProps => (
          <div style={{background: "var(--secondary-color, white)", display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: "space-between"}}>
            <div style={{float: "left", position: "relative", width: "100%", display: 'flex', justifyContent: 'space-between', flexDirection: "column", padding: "0 24px", }}>
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.email}
                    label={localization.get("form.gift_email")}
                    onChange={e => formikProps.setFieldValue('email', e.target.value)}
                    InputLabelProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="email" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              {/* <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.repeatEmail}
                    label={localization.get("repeat_email")}
                    onChange={e => formikProps.setFieldValue('repeatEmail', e.target.value)}
                    InputLabelProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="repeatEmail" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div> */}
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.name}
                    label={localization.get("form.gift_sender_name")}
                    onChange={e => formikProps.setFieldValue('name', e.target.value)}
                    InputLabelProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="name" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <div style={{position: "relative", width: "100%", display: 'flex', justifyContent: "space-between", flexDirection: this.isMobile() ? "column" : "row"}}>
                <div style={{float: "left", position: "relative", width: "100%"}}>
                  <TextField
                    style={{marginBottom: 10, width: "100%"}}
                    value={formikProps.values.message}
                    label={localization.get("form.gift_message")}
                    onChange={e => formikProps.setFieldValue('message', e.target.value)}
                    InputLabelProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                    inputProps={{style: {color: "var(--navbar-text-color, rgba(0, 0, 0, 0.54))"}}}
                  />
                  <ErrorMessage name="message" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <div style={{margin: '10px 10px 20px', alignSelf: 'flex-end'}}>
              <Button onClick={() => this.handleCloseDialog()} color='default'>{localization.get("cancel")}</Button>
              <Button onClick={() => formikProps.handleSubmit()} color='primary'>{localization.get("form.confirm")}</Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
}

export default GiftDialog;