import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import localization from "../../config/localization";
import {Select} from "../../components/Letflow/Form";

class StateDialog extends React.Component {

  state = {
    state: this.props.state
  }

  handleClose = (state) => {
    this.props.onClose(state);
  };

  render() {
    const { state, ...other } = this.props;
    const items = [
      {id: 'pending', name: localization.get('contact_message.pending')},
      {id: 'contacted', name: localization.get('contact_message.contacted')},
      {id: 'not_interested', name: localization.get('contact_message.not_interested')},
      {id: 'client', name: localization.get('contact_message.client')},
    ]

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">{localization.get('original_music.dialog.change_state')}</DialogTitle>
        <div style={{margin: "40px"}}>
          <Select
            id='state'
            name="state"
            current={state}
            title={localization.get('form.state')}
            onChange={(e) => {
              this.handleClose(e.target.value)
            }}
            items={items}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />
        </div>
      </Dialog>
    );
  }
}


export default (StateDialog);