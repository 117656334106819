import React, {Component} from "react";
import localization from "../../../../config/localization";
import {Formik} from "formik";
import GlobalSnackbar from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import FormViewContainer from "../../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "./FormView";


class FormController extends Component {
  state = {
    submitted: false,
  };

  validate = values => {
    const errors = {};

    if (!values.titleEs) {
      errors.titleEs = localization.get("validator.is_required");
    }
    if (!values.client) {
      errors.client = localization.get("validator.is_required");
    }
    if (!values.channel) {
      errors.channel = localization.get("validator.is_required");
    }

    return errors;
  };

  initialValues = () => ({
    titleEs: this.props.titleEs || "",
    client: this.props.client || '',
    channel: this.props.channel || '',
    userLibrary: true,
    selectedContent: this.props.selectedContent || [],
    removedContent: [],
    visibility: false,
    disableForm: false,
    orderType: this.props.orderType || "manual"
  });

  render = () => {
    return (
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialErrors={true}
        isInitialValid={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          const data = {
            ...values,
            clientId: values.client.value,
            channelId: values.channel.value,
            orderType: values.orderType,
            translations: [
              {language: 'es', title: values.titleEs},
              {language: 'en', title: values.titleEs},
            ],
          }

          this.props.submitRequest(data)
            .then(() => {
              this.setState({ submitted: true });
            })
            .catch(() => GlobalSnackbar.showGenericError())
            .finally(() => setTimeout(() => actions.setSubmitting(false), 3000));
        }}
      >
        {formikProps => (
          <React.Fragment>
              <FormViewContainer
                title={this.props.formTitle}
                submitRequest={() => formikProps.submitForm()}
                onBack={() => this.props.history.goBack()}
                onRequestingStateChanged={value => this.setState({ disabled: value })}
                errors={formikProps.errors}
              >
                {FormContent(formikProps)}
              </FormViewContainer>
          </React.Fragment>
        )}
      </Formik>
    );
  };
}

export default FormController;
