import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  requestTagGroup = () => request.contentCreatorType.get(this.props.match.params.id, 'include=translations;image;client')
    .then(tagGroup => this.refs.form.setContentCreatorType(tagGroup))

  requestUpdate = state => {

    this.setState({ disabled: true })

    const {
      translations,
      slug,
      image,
      allowImage,
      allowAudio,
      allowVideo,
      allowBinary,
      allowLink,
      allowEmbed,
      limit,
      isTalent,
      visibility,
      client,
      type,
      synonyms,
      downloadable,
    } = state

    return request.contentCreatorType.update({
      id: this.props.match.params.id,
      translations,
      slug,
      image: image.file,
      allowImage,
      allowAudio,
      allowVideo,
      allowBinary,
      allowLink,
      allowEmbed,
      limit,
      isTalent,
      visibility,
      type,
      synonyms,
      downloadable,
      clientId: client && visibility === 'private' && client.value,
    }).then(() => this.props.history.push(makeUrlWithTableParams(this.props.location.pathname.includes('talents') ? '/panel/talents/content_creator_types' : '/panel/audiovisual/content_creator_types')))
  }

  render = () => {
    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('content_creator_type.update_content_creator_type')}
        initializationRequest={this.requestTagGroup}
        submitRequest={this.requestUpdate}
        disableTypes
        isEditorial={this.props.location.pathname.includes('editorial')}
        isUpdating={true}
      />
    )
  }
}
