import React from 'react'
import ReactPaginate from 'react-paginate'
import './index.css'

const Paginator = ({ pages, onPageChange, currentPage }) => {

  const makeClassName = item => `letflow-paginator-${item}`
  
  return (
    <div className={makeClassName('root')}>
      <ReactPaginate
        pageCount={pages}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        previousLabel={'<'}
        nextLabel={'>'}
        onPageChange={({selected}) => onPageChange(selected)}
        forcePage={currentPage}
        containerClassName={makeClassName('container')}
        pageClassName={makeClassName('page')}
        pageLinkClassName={makeClassName('page-link')}
        previousClassName={makeClassName('previous')}
        previousLinkClassName={makeClassName('previous-link')}
        nextClassName={makeClassName('next')}
        nextLinkClassName={makeClassName('next-link')}
        breakClassName={makeClassName('break')}
        activeClassName={makeClassName('active')}
        disabledClassName={makeClassName('disabled')}
        disableInitialCallback
      />
    </div>
  )
}

export default Paginator