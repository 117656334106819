import {TextValidator} from "react-material-ui-form-validator";
import localization from "../../../../config/localization";
import React from "react";
import {ErrorMessage, FastField} from "formik";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../../api-client";
import {ContentSelectionTables} from "./ContentSelectionTables";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

const FormView = (formikProps) => {
  const {touched, errors, values, handleChange, setFieldValue, setFieldTouched} = formikProps
  return (
    <div>
      <TextValidator
        style={{ width: "100%"}}
        label={localization.get('form.name')}
        name={"titleEs"}
        value={values.titleEs}
        onChange={handleChange}
        validators={['required']}
        errorMessages={[localization.get('validator.is_required')]}
      />

      <div style={{marginTop: 30}}>
        {/** Clients */}
        <div style={{ color: "#777f9c", margin: "16px 0 5px", lineHeight: 0.5 }}>
          {localization.get("form.client")}
        </div>
        <AsyncSelect
          onBlur={() => setFieldTouched('client', true)}
          styles={{
            control: base => ({
              ...base,
              color: touched["client"] && errors["client"] && 'red',
              borderColor: (touched["client"] && errors["client"]) ? 'red' : "#ccc",
              borderWidth: (touched["client"] && errors["client"]) ? '2px' : "1px",
              '&:hover': {
                cursor: 'text'
              }
            }),
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 4,
          })}
          placeholder={localization.get('user.add_client')}
          loadingMessage={() => localization.get('loading')}
          noOptionsMessage={() => localization.get('no_options')}
          defaultOptions
          value={values.client}
          onChange={async (client) => {
            await setFieldValue('client', '')
            await setFieldValue('client', client)
            await setFieldValue('channel', '')
          }}
          loadOptions={inputValue =>
            request.client
              .getAll({ filterBy: [{ column: "name", filter: inputValue }, { column: "has_channels", filter: true }] })
              .then(res => res.map(client => ({ value: client.id, label: `${client.name}` })))
          }
        />
        <ErrorMessage name="client" className="web-like-error">
          {msg => <div className="web-like-error font12">{msg}</div>}
        </ErrorMessage>

        {values.client &&
        <>
          {/** Channels */}
          <div style={{ color: "#777f9c", margin: "16px 0 5px", lineHeight: 0.5 }}>
            {localization.get("channel")}
          </div>
          <AsyncSelect
            onBlur={() => setFieldTouched('channel', true)}
            styles={{
              control: base => ({
                ...base,
                color: touched["channel"] && errors["channel"] && 'red',
                borderColor: (touched["channel"] && errors["channel"]) ? 'red' : "#ccc",
                borderWidth: (touched["channel"] && errors["channel"]) ? '2px' : "1px",
                '&:hover': {
                  cursor: 'text'
                }
              }),
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 4,
            })}
            placeholder={localization.get('channel.add_channel')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            defaultOptions
            value={values.channel}
            onChange={async (channel) => {
              await setFieldValue('channel', '')
              await setFieldValue('selectedContent', [])
              await setFieldValue('removedContent', [])
              await setFieldValue('channel', channel)
            }}
            loadOptions={inputValue =>
              request.channel
                .getAll({ filterBy: [{ column: "name", filter: inputValue }, { column: "client_id", filter: values.client.value }] })
                .then(res => res.map(channel =>
                  ({ value: channel.id, label: `${channel.name}` })
                ))
            }
          />
          <ErrorMessage name="channel" className="web-like-error">
            {msg => <div className="web-like-error font12">{msg}</div>}
          </ErrorMessage>
        </>
        }
        <br/>
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <h4>{localization.get('order.type')}</h4>
          <RadioGroup
            name="orderType"
            value={values.orderType}
            onChange={async event =>
              await setFieldValue('orderType', event.target.value)
            }
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="manual" control={<Radio color="default" />} label={localization.get('order.manual')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="alpha-asc" control={<Radio color="default" />} label={localization.get('order.alpha_asc')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="alpha-desc" control={<Radio color="default" />} label={localization.get('order.alpha_desc')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="created-asc" control={<Radio color="default" />} label={localization.get('order.created_asc')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="created-desc" control={<Radio color="default" />} label={localization.get('order.created_desc')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="weight" control={<Radio color="default" />} label={localization.get('order.weight')} />
          </RadioGroup>
        </FormControl>
        <br/>
        <br/>

        {values.channel &&
          //Using <FastField/> so that formik doesn't re render this component each time a form update occurs
          <FastField name={'ignore'}>
            {({field, form}) => (
            <ContentSelectionTables
              channelId={values.channel.value}
              removedContent={values.removedContent}
              selectedContent={values.selectedContent}
              onRemovedItemsChanged={(removedContent) => setFieldValue('removedContent', removedContent)}
              onSelectedItemsChanged={(selectedContent) => setFieldValue('selectedContent', selectedContent)}
              onOrderChange={async () => await setFieldValue('orderType', "manual")}
            />
            )}
          </FastField>
        }

      </div>
    </div>
  )
}

export default FormView