import React from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import ItemView, {ItemInfo} from '../../../layouts/Home/HomeItemView'
import ViewContainer from "../../../components/Letflow/ViewContainer/ViewContainer";
import TrackResult from "../../../components/Letflow/TrackResult";
import TrackTable from "../../../components/Letflow/TrackTable";
import {compareValues, makeWaveformUrl, pushIfDoesNotExist} from "../../../utils";
import FolderImage from "../../../assets/img/Letflow/folder.png";
import Chip from "@material-ui/core/Chip";
import './HomePlaylist.css'
import * as VCS from "../../../components/Letflow/ContentCreatorViewComponents";
import {
  Button, Card,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";
import {
  GridOn,
  List,
  PlayArrow,
  PlaylistAdd,
  QuestionAnswer,
  RemoveCircle,
  Search,
  Share,
  Star,
  StarBorder
} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {
  addHttpsToUrl,
  channelPathGenerator,
  clientCanSeeMusics,
  getActiveChannel,
  getActiveClient, getMainTranslation, getSignatureTime, getStoredChannel, getStoredUser, getUseTools,
  homePathGenerator,
  setPageTitle,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import styled from "styled-components";
import Slider from "react-slick";
import PrevArrow from "../../../components/Letflow/Arrows/PrevArrow";
import NextArrow from "../../../components/Letflow/Arrows/NextArrow";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import AudioImage from "../../../assets/img/Letflow/audio-image.png";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize, onImageError} from "../HomeElements/utils";
import OrderUpdate from './OrderUpdate'
import browserHistory from "../../../utils/browserHistory";
import ContentCreationPlayer from "../../../components/Letflow/NewContentCreationPlayer";
import CreationItem from "../../../components/Letflow/CreationItem";
import TextField from "@material-ui/core/TextField/TextField";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import {get} from "lodash"
import analyticsInstance from "../../../analytics/analyticsInstance";
import MaterialGrid from "./MaterialGrid";
import InfiniteScroll from "react-infinite-scroll-component";

class HomePlaylist extends React.Component {

  state = {
    playlist: undefined,
    loading: true,
    loadingFailed: false,
    tags: [],
    musics: [],
    selectedTags: [],
    showContentCreationDialog: false,
    openOrderDialog: false,
    searchInput: "",
    typeRadio: "all",
    viewType: "grid",
    addingCommentMaterial: null,
    comment: "",
    currentList: [],
    currentPage: 0,
    itemsPerPage: 18, 
    hasMore: true,
  };

  componentDidMount = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const viewType = params.materialsViewType || localStorage.getItem('playlistViewType');
    if (viewType) this.setState({ viewType })

    this.requestData().then(() => {
      analyticsInstance.visitContent({
        name: getMainTranslation(this.state.playlist, "name"),
        content_type: "Playlist",
      })

      setPageTitle(this.state.playlist.title)
    })

  };

  getTagsFromTracks = (tracks) => {
    let tags = [];
    if (tracks && tracks.length > 0) {
      tracks.forEach((track) => {
        track.tags.forEach((tag) => {
          if (!tags.find(t => t.id === tag.id)) {
            tags.push(tag)
          }
        })
      })
    }
    tags = tags.sort(compareValues('name', 'asc'))
    return tags
  };

  onReorder = () => {
    this.setState({
      openOrderDialog: true,
    })
  }

  requestData = () => {
    let {playlistId} = this.props.match.params
    if (!playlistId) playlistId = this.props.playlistId

    this.setState({loading: true})
    const getPlaylist = 'get';

    return request.playlist[getPlaylist](playlistId, `include=image;tags${(getActiveClient() && !getActiveClient() )? "&client_id="+getActiveClient(): "" }`)
      .then(playlist => {
        this.setState({playlist, currentContentCreations: playlist.contentCreations})
        const selectedTags = playlist.tags.filter(tag => tag.wanted === 1).map(tag => tag.id)
        const unselectedTags = playlist.tags.filter(tag => tag.wanted === 0).map(tag => tag.id)
        if(this.props.client && clientCanSeeMusics(this.props.client) && playlist.type != "tags") {
          request.music.getAllByPlaylistForClient(getActiveClient(), playlistId, selectedTags, unselectedTags, playlist.query || '')
            .then((musics) => {
              this.setState({musics, tags: this.getTagsFromTracks(musics)})
            })
        }

        this.setState({loading: false, })
      }).catch((res) => {
          if (!this.props.modal) {
            browserHistory.push(getActiveChannel() ? channelPathGenerator('') : homePathGenerator(''))
          } else {
            this.props.onModalClose()
          }
      });
  };

  onPlayContentCreation = (creation, contentCreator) =>
    GlobalSoundBar.loadSingleTrackAndPlay({
      fetchAudioUrl: () => new Promise(resolve => {
        let url = '';
        const date = new Date()
        const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        if((now_utc - Date.parse(creation.time)) < getSignatureTime()) {
          url = creation.audio.url
        }else{
            request.contentCreation.get(creation.id, "include=audio:url").then(track => track.audio.url)
        }

        resolve(url);
      }),
        title:  creation.title,
        subtitle:  creation.contentCreator && creation.contentCreator.name,
        image: creation.image ? makeImageUrlWithSize(creation, 'xs') : makeImageUrlWithSize(contentCreator, 'xs'),
        waveform: makeWaveformUrl(creation),
      })

  getColumns = () => {
    const columns = [
      {
        id: "image",
        accessor: contentCreation => (
          <img
            alt="ContentCreator"
            src={!contentCreation.image ? makeImageUrlWithSize(contentCreation.contentCreator, 'xs') : makeImageUrlWithSize(contentCreation, 'xs')}
            width={50}
            height={50}
            onError={onImageError}
          />
        ),
        style: {
          float: "left"
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60
      },
      {
        Header: localization.get("table.name"),
        id: "title",
        accessor: contentCreation => <Tooltip placement="bottom" title={contentCreation.title}><span>{contentCreation.title}</span></Tooltip>,
        Filter: ({filter, onChange}) => (
          <input
            onChange={event => {
              onChange(event.target.value)
              this.setState({title: event.target.value || ""})
            }}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        )
      },
      {
        Header: localization.get("content_creator"),
        id: "contentCreator[name]",
        accessor: content => content.contentCreator ? content.contentCreator.name : "",
        Filter: ({filter, onChange}) => (
          <input
            onChange={event => {
              this.setState({content_creator_id: event.target.value || ""})
              onChange(event.target.value)
            }}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        ),
        sortable: false,
        filterable: false,
        width: 300,
      },

      {
        id: "actions",
        Header: localization.get("table.actions"),
        sortable: false,
        filterable: false,
        accessor: contentCreation => {
          const buttonBuilder = new TableButtonsBuilder();

          {!!getStoredUser() && getActiveClient() && getUseTools() &&
            buttonBuilder.withOther(
              <TableButton title={localization.get("comment")}>
                <QuestionAnswer
                  onClick={() => this.openCommentDialog(contentCreation)}
                />
              </TableButton>
            );
          }

          {!!getStoredUser() && getActiveClient() && getUseTools() &&
            buttonBuilder.withOther(
              <TableButton title={localization.get("add_to_playlist")}>
                <AddToPlaylist
                  clientId={getActiveClient()}
                  content={"contentCreations"}
                  item={contentCreation}
                  icon={<PlaylistAdd style={{marginTop: 8, marginLeft: 3}}/>}
                />
              </TableButton>
            )
          }

          buttonBuilder.withOther(
            <TableButton
              title={localization.get('tooltip.play')}
              onClick={() => this.setCurrentPlayingContent(contentCreation.index)}>
              <PlayArrow/>
            </TableButton>
          )

          buttonBuilder.withOther(
            <TableButton
              title={localization.get(contentCreation.favorite ? "soundbar.remove_from_favorites" : "soundbar.add_to_favorites")}
              onClick={() => this.onFavorite(contentCreation)}
            >
              {!contentCreation.favorite ? <StarBorder /> : <Star />}
            </TableButton>
          )

          buttonBuilder.withOther(
            <TableButton
              title={localization.get('reel.share')}
              onClick={() => request.contentCreation.shareByChannel(contentCreation.id)
                .then(response => this.setState({
                  showShareDialog: true,
                  shareUrl: response.url,
                  sharingCreation: contentCreation.id,
                  sharingCreationTitle: contentCreation.title,
                }))
                .then(() => analyticsInstance.share({
                  name: getMainTranslation(contentCreation, "title"),
                  type: "Material",
                }))
              }
            >
              <Share />
            </TableButton>
          )


          return buttonBuilder.build();
        },
        width: 180,
        Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.currentContentCreations.length}</span>
      }];

    return columns;
  }

  updateTags = (tags, selectedTags) => {
    return tags.map(tag => ({
      ...tag,
      state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
    }))
  };

  removeTagFromContentCreator = (type, contentCreatorId, tagId) => {
    request[type].removeTagFromPlaylist(this.props.match.params.playlistId || this.props.playlistId, contentCreatorId, tagId)
      .then(() => this.requestData())
  };

  onSelectedTagsChanged = selectedTags => {
    this.setState({ selectedTags, tags: this.updateTags(this.state.tags, selectedTags) })
  };

  renderItemInfo = () =>
    <ItemInfo
      image={this.state.playlist.image ?
        request.file.image.makeCroppedUrl(this.state.playlist.image.id, 400, 400, 'playlist', 'jpg')
        :  null}
      id={this.state.playlist.id}
      title={this.state.playlist.title}
      description={this.state.playlist.subtitle}
      tags={this.state.tags}
      isPlaylist={this.state.playlist.type !== 'global'}
      isAlbum={false}
      onSelectedTagsChanged={this.onSelectedTagsChanged}
      singleTagSelection={true}
      allowDownload={this.state.playlist.allow_download}
      onHandleClose={() => this.props.onModalClose ? this.props.onModalClose() : browserHistory.goBack()}
      onReorder={this.onReorder}
      useReorder={this.state.musics.length > 0}
      {...this.props}
    />

  getFilteredCreations = () => this.state.currentContentCreations
      .filter(creation => creation.title.toLowerCase().includes(this.state.searchInput.toLowerCase()) || creation.contentCreator.name.toLowerCase().includes(this.state.searchInput.toLowerCase()))
      .filter(creation => {
        switch (this.state.typeRadio) {
          case "video":
            return !!creation.video;
          case "audio":
            return !!creation.audio;
          case "binary":
            return !!creation.binary;
          case "image":
            return !!creation.image && !creation.video && !creation.audio && !creation.binary;
          default:
            return true;
        }
      })

  openCommentDialog = item => this.setState({ addingCommentMaterial: item, comment: get(item, 'comments.comment', "") })

  closeCommentDialog = () => this.setState({ addingCommentMaterial: null, comment: "" })

  renderCommentDialog = () =>
    <Dialog open={this.state.addingCommentMaterial} onBackdropClick={this.closeCommentDialog} onEscapeKeyDown={this.closeCommentDialog}>
      {this.state.addingCommentMaterial &&
        <Card style={{
          display: "flex",
          flexDirection: "column",
          width: 450,
          margin: 0,
          justifyContent: "space-around",
          position: "relative",
          background: "var(--main-color, #FFF)",
          border: "1px solid var(--main-font-color, #000)",
        }}>
          <DialogTitle>{localization.get('comment.add', this.state.addingCommentMaterial.title)}</DialogTitle>
          <DialogContent>
            <p>{localization.get('comment.add_helper')}</p>
            <TextField
              multiline
              rows={5}
              fullWidth
              id={"comment"}
              name={"comment"}
              placeholder={"Script"}
              value={this.state.comment}
              onChange={e => this.setState({comment: e.target.value})}
              label={localization.get('comment')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCommentDialog}>
              {localization.get("cancel")}
            </Button>
            <Button
              onClick={() =>
                request.playlist.addComment(this.state.playlist.id, this.state.addingCommentMaterial.id, this.state.comment)
                  .then(() => this.setState({
                    currentContentCreations: this.state.currentContentCreations.map(creation => {
                      if (creation.id === this.state.addingCommentMaterial.id) {
                        return {...creation, comments: {...creation.comments, comment: this.state.comment}}
                      }

                      return {...creation}
                    }),
                    playlist: {
                      ...this.state.playlist,
                      contentCreations: this.state.playlist.contentCreations.map(creation => {
                        if (creation.id === this.state.addingCommentMaterial.id) {
                          return {...creation, comments: {...creation.comments, comment: this.state.comment}}
                        }

                        return {...creation}
                      })
                  }}))
                  .then(this.closeCommentDialog)}
            >
              {localization.get("send")}
            </Button>
          </DialogActions>
        </Card>
      }
    </Dialog>

  onFavorite = (creation) => {
    analyticsInstance.favorite({
      name: getMainTranslation(creation, "title"),
      type: "Material",
      content: creation.favorite ? "Add" : "Remove"
    })
    if(!creation.favorite) {
      this.addToFavorites(creation.id)
    } else  {
      this.removeFromFavorites(creation.id)
    }
  }

  addToFavorites = id => {
    this.setState(state => ({
      ...state,
      currentContentCreations: state.currentContentCreations.map(creation => {
        if (creation.id === id) {
          return ({...creation, favorite: true})
        }
        return creation
      })
    }))

    return request.contentCreationChannelFavorite.add(id)
  }

  removeFromFavorites = id => {
      this.setState(state => ({
        ...state,
        currentContentCreations: state.currentContentCreations.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: false})
          }
          return creation
        })
      }))

    return request.contentCreationChannelFavorite.remove(id)
  }


  fetchMoreData = () => {
    const { currentPage, itemsPerPage } = this.state;
    const totalCreations = this.getFilteredCreations();

    if (currentPage * itemsPerPage >= totalCreations.length) {
      this.setState({ hasMore: false }); 
      return;
    }

    this.setState({ currentPage: currentPage + 1 }); 
  };


  renderItemTable = () => {
    const {playlist, musics} = this.state
    const {selectedTags} = this.state
    let contentCreationsFilters = [];

    const musicsFiltered = musics.filter(track => selectedTags.every(selectedTag => track.tags.map(tag => tag.id).includes(selectedTag)))
    const filters = ["video", "audio", "binary", "image"];

    if (playlist.contentCreations.length > 0) {
      // Generate array using contentCreationsFilters
      contentCreationsFilters = filters.filter(filter => {
        if (filter == "image") {
          return playlist.contentCreations.some(creation => creation.image && !creation.video && !creation.audio && !creation.binary)
        }

        return playlist.contentCreations.some(creation => creation[filter])
      });
    }

    return (
      <div>
        {playlist && (musics.length > 0) &&
        <React.Fragment>
          <h3 className="content-creator-title" style={{marginTop: 0}}>{localization.get('tracks')}</h3>
          <TrackResult
            rows={<TrackTable {...this.props} useAlbum hideSuggestions={true} type="music" tracks={selectedTags.length > 0 ? musicsFiltered : musics} playlistType={this.state.playlist.type} playlist={this.state.playlist}/>}
          />
        </React.Fragment>
        }
        {(playlist && playlist.contentCreators && playlist.contentCreators.length > 0) && this.renderContentCreatorView()}
        {playlist && playlist.contentCreations.length > 0 &&
          <div>
            <h3 className="content-creator-title">{playlist.contentCreations.every((material, index, arr) => get(material, "contentCreator.content_creator_type_id") == get(arr[0], "contentCreator.content_creator_type_id")) ? get(playlist.contentCreations[0], "contentCreator.type.name") : localization.get('content_creations')}</h3>
            <MaterialContainer>
              <div style={{ margin: 10, position: "relative", float: "left", display: "flex", maxWidth: 185}}>
                <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false,
                    style: {
                      color: "var(--main-font-color, black)",
                      borderBottom: `2px solid var(--main-font-color, rgba(0,0,0, .5))`,
                      width: 250,
                      maxWidth: 250,
                    },
                  }}
                  InputProps={{disableUnderline: true}}
                  autoComplete={false}
                  className="smart-search-input-search"
                  placeholder={localization.get("searchbar.content_creators")}
                  value={this.state.searchInput}
                  onChange={e => this.setState({searchInput: e.target.value})}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.writeQueryStringParams(true)
                    }
                  }}
                  style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
                />
                {window.innerWidth < 600 &&
                  <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}}
                          onClick={this.writeQueryStringParams}/>
                }
              </div>
              <TableButton title={localization.get("searchbar.change_view")} onClick={() => this.setState({viewType: this.state.viewType === "grid" ? "list" : "grid"}, () => localStorage.setItem('playlistViewType', this.state.viewType))}>
                {this.state.viewType === "grid" ?
                  <List  style={{color: "var(--main-font-color, black)"}} /> :
                  <GridOn  style={{color: "var(--main-font-color, black)"}} />
                }
              </TableButton>
              <RadioGroup
                name="typeRadio"
                value={this.state.typeRadio}
                onChange={event => this.setState({typeRadio: event.target.value})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row", marginLeft: 15}}
              >
                <FormControlLabel value="all" control={<Radio color="default"/>} label={localization.get('all')}/>
                {filters.map(filter => <FormControlLabel value={filter} disabled={!contentCreationsFilters.includes(filter)} control={<Radio color="default"/>} label={localization.get(filter)}/>)}
              </RadioGroup>
            </MaterialContainer>
            {this.state.viewType === 'grid' ?
              <Grid container fullWidth>
                {this.getFilteredCreations().length > 0 ?
                    <InfiniteScroll
                    dataLength={(this.state.currentPage + 1) * this.state.itemsPerPage} 
                    next={this.fetchMoreData} 
                    hasMore={this.state.hasMore}
                  >
                  <Grid container fullWidth>
                    {this.getFilteredCreations()
                      .slice(0, (this.state.currentPage + 1) * this.state.itemsPerPage)
                      .map((creation, key) =>
                        <Grid key={key} xs={6} sm={6} md={4} lg={4} xl={2} item style={{width: "100%", padding: 10}}>
                           <CreationItem
                            onAddComment={item => this.openCommentDialog(item)}
                            removeFromPlaylist={() => request.playlist.removeFromPlaylist({
                              playlistId: playlist.id,
                              musicId: creation.id,
                              type: 'contentCreations'
                            })
                              .then(() => {
                                analyticsInstance.playlist({
                                  playlist: playlist.title,
                                  type: "Material",
                                  content_name: getMainTranslation(creation, "title"),
                                  action: "Remove",
                                });
                                const filteredContentCreations = playlist.contentCreations.filter((newContentCreation) => creation.id !== newContentCreation.id)
                                this.setState(prevState => ({
                                  playlist: {
                                    ...prevState.playlist,
                                    contentCreations: filteredContentCreations,
                                  }
                                }))
                              })
                            }
                            contentCreation={creation}
                            onFavorite={() => this.onFavorite(creation)}
                            onPlay={() => this.setCurrentPlayingContent(key, playlist.contentCreations)}
                            onShare={creation => request.contentCreation.shareByChannel(creation.id)
                              .then(response => this.setState({
                                showShareDialog: true,
                                shareUrl: response.url,
                                sharingCreation: creation.id,
                                sharingCreationTitle: creation.title,
                              }))
                              .then(() => analyticsInstance.share({
                                name: getMainTranslation(creation, "title"),
                                type: "Material",
                              }))
                            }
                            noAuthorLink={userHasRestrictedAccess()}
                          />
                        </Grid>
                      )}
                  </Grid>
                </InfiniteScroll>
                  :
                  <div style={{
                    minHeight: 320,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <p>{localization.get('creation.not_found')}</p>
                  </div>
                }
              </Grid>
              :
              <div style={{float: "left", position: "relative", width: "100%", padding: "0px 5px 15px"}}>
                <ClientSidePaginationTable
                  data={this.getFilteredCreations().map((creation, index) => ({...creation, index}))}
                  columns={this.getColumns()}
                  filterable={false}
                  sortable={false}
                  showPagination={true}
                  zebraStyle={false}
                  perPage={18}
                  showPaginationBottom={true}
                />
              </div>
            }
          </div>
        }
        {(playlist && playlist.type == "tags") && 
          <MaterialGrid playlistId={playlist.id} useTagFilters={playlist.use_filters} />
        }
      </div>
    )
  }

  renderContentCreatorView = () => {
    const {playlist} = this.state
    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      lazyLoad: true,
      prevArrow: <PrevArrow overrideStyle={{transform: "translate(0, -110%)", left: "-35px"}}/>,
      nextArrow: <NextArrow overrideStyle={{transform: "translate(0, -110%)", right: "-35px"}}/>,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    }
    const contentCreatorTypes = []
    playlist.contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type))

    return (
      <React.Fragment>
        {contentCreatorTypes.map(contentCreatorType =>
          <React.Fragment>
            <h3 className="content-creator-title">{contentCreatorType.name}</h3>
            {playlist.contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id)
              .sort((a, b) => a.country && b.country && a.country.key.localeCompare(b.country.key)).map(contentCreator => {
              let tags = [];
              let hasAudio = false
              let hasVideo = false
              let hasImage = false
              contentCreator.contentCreations.forEach(contentCreation => {
                if (contentCreation.tags) contentCreation.tags.forEach(tag => pushIfDoesNotExist(tags, tag))
                if (contentCreation.audio) hasAudio = true
                if (contentCreation.video) hasVideo = true
                if (contentCreation.image) hasImage = true
              })
              return (
                <React.Fragment>
                  <RespD>
                    <div className="content-creator-container">
                      <div style={{width: 75, height: 75, margin: 5}} className="content-creator-image-container">
                        <img style={{width: "100%", borderRadius: "50%"}} alt="photographer" src={makeImageUrlWithSize(contentCreator, 'sm')} onError={onImageError}/>
                      </div>
                      <Tooltip placement="top" title={localization.get("remove_from_playlist")}>
                        <RemoveCircle
                          className="content-creator-remove-button"
                          onClick={() => request.playlist.removeFromPlaylist({
                              playlistId: playlist.id,
                              musicId: contentCreator.id,
                              type: 'contentCreators'
                            })
                              .then(() => {
                                analyticsInstance.playlist({
                                  playlist: playlist.title,
                                  type: "Content",
                                  content_name: getMainTranslation(contentCreator, "name"),
                                  action: "Remove",
                                });
                                const filteredContentCreators = playlist.contentCreators.filter((newContentCreator) => contentCreator.id !== newContentCreator.id)
                                this.setState( prevState => ({playlist: {...prevState.playlist, contentCreators: filteredContentCreators}}))
                              })
                          }
                        />
                      </Tooltip>
                      <div className="content-creator-info-container">
                        <div>
                          <Link to={getActiveChannel() ? channelPathGenerator(`contenido/${contentCreator.id}-${contentCreator.name}`) : homePathGenerator(`content_creator/${contentCreator.id}`)} className="content-creator-name" style={{lineHeight: "40px"}}>{contentCreator.name}</Link>
                          <div className="content-creator-tag-container">
                            {tags.map(tag =>
                              <Chip
                                className="content-creator-tag"
                                label={tag.name}
                                onDelete={() => this.removeTagFromContentCreator('contentCreator', contentCreator.id, tag.id)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="content-creator-country">{contentCreator.country && contentCreator.country.name}</span>
                    </div>
                    <div className="content-creator-content-container">
                      {(contentCreatorType.allow_audio) && hasAudio &&
                      <Slider
                        style={{width: "100%"}}
                        {...sliderSettings}>
                        {contentCreatorType.limit != null ?
                          contentCreator.contentCreations.slice(0,contentCreatorType.limit).map((item, key) => (
                          <div key={key}>
                            <div
                              style={{ padding: "5px", cursor: "pointer" }}
                              onClick={() => this.setCurrentPlayingContent(key, contentCreator.contentCreations.slice(0,contentCreatorType.limit))}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  background: 'var(--main-font-color, transparent)',
                                  backgroundImage: `url(${AudioImage})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  opacity: 0.3,
                                  filter: "grayscale(100%)",
                                  height: 150
                                }}
                              />
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  margin: "10px 0px",
                                  color: "var(--main-font-color, rgba(0, 0, 0, 0.87))"
                                }}
                              >
                                {item.title}
                              </div>
                              {this.state.playlist && this.state.playlist.allow_download &&
                                <div style={{height: 30}}>
                                  <p style={{textAlign: "center", color: "var(--navbar-text-color, #000)"}}>
                                      <span
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          let download = url => window.open(url, '_blank')
                                          if((/iPhone|iPad|iPod/i.test(navigator.userAgent) || window.safari !== undefined)) {
                                            // safari doesnt allow window.open inside async calls
                                            var windowRef=window.open();
                                            download = url => windowRef.location = addHttpsToUrl(url);
                                          }

                                          return request.contentCreation.getDownloadLink(item.id, this.state.playlist.id)
                                            .then(response => {
                                              analyticsInstance.download({
                                                name: getMainTranslation(item, "title"),
                                                type: "Channel",
                                                from: "Playlist",
                                              })

                                              return download(response.url)
                                            })
                                        }}
                                        style={{
                                          overflow: "hidden",
                                          height: 21,
                                          background: "var(--secondary-color, #ccc)",
                                          color: "var(--navbar-text-color, white)",
                                          width: 100,
                                          margin: "auto",
                                          borderRadius: 25,
                                          padding: 6,
                                        }}
                                      >
                                        {localization.get('download')}
                                      </span>
                                  </p>
                                </div>
                              }
                            </div>
                          </div>
                        )) :
                          contentCreator.contentCreations.map((item, key) => (
                            <div key={key}>
                              <div
                                style={{ padding: "5px", cursor: "pointer" }}
                                onClick={() => this.setCurrentPlayingContent(key, contentCreator.contentCreations)}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    backgroundImage: `url(${AudioImage})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 0.3,
                                    filter: "grayscale(100%)",
                                    height: 150
                                  }}
                                />
                                <div
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    margin: "10px 0px"
                                  }}
                                >
                                  {item.title}
                                </div>
                                {this.state.playlist && this.state.playlist.allow_download &&
                                  <div style={{height: 30}}>
                                    <p style={{textAlign: "center", color: "var(--navbar-text-color, #000)"}}>
                                      <span
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          let download = url => window.open(url, '_blank')
                                          if((/iPhone|iPad|iPod/i.test(navigator.userAgent) || window.safari !== undefined)) {
                                            // safari doesnt allow window.open inside async calls
                                            var windowRef=window.open();
                                            download = url => windowRef.location = addHttpsToUrl(url);
                                          }
                                          return request.contentCreation.getDownloadLink(item.id, this.state.playlist.id)
                                            .then(response => {
                                              analyticsInstance.download({
                                                name: getMainTranslation(item, "title"),
                                                type: "Channel",
                                                from: "Playlist",
                                              })
                                              return download(response.url)
                                            })
                                        }}
                                        style={{
                                          overflow: "hidden",
                                          height: 21,
                                          background: "var(--secondary-color, #ccc)",
                                          color: "var(--navbar-text-color, white)",
                                          width: 100,
                                          margin: "auto",
                                          borderRadius: 25,
                                          padding: 6,
                                        }}
                                      >
                                        {localization.get('download')}
                                      </span>
                                    </p>
                                  </div>
                                }
                              </div>
                            </div>
                          ))
                        }
                      </Slider>
                      }
                      {(contentCreatorType.allow_image || contentCreatorType.allow_video) && (hasVideo || hasImage) &&
                        <VCS.WorkContainerCarousel
                          shape={(getStoredChannel() && getStoredChannel().preferences && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.card.shape : "rounded"}
                          items={(contentCreator.type.limit == null) ?
                            contentCreator.contentCreations :
                            contentCreator.contentCreations.slice(0, contentCreator.type.limit)}
                          allowDownload={this.state.playlist.allow_download}
                          handleDownload={id => request.contentCreation.getDownloadLink(id, this.state.playlist.id)
                            .then(response => window.open(response.url, '_blank'))}
                          renderItem={contentCreation => (
                            (contentCreation.video && !contentCreation.image) ?
                              <video
                                style={{
                                  objectFit: "cover",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  padding: 5
                                }}
                                src={contentCreation.video.url}
                                poster={makeThumbnailUrlWithSize(contentCreation.video, 'md')}
                              />
                              :
                              <img alt="photo"
                                   style={{
                                     objectFit: "cover",
                                     position: "absolute",
                                     top: 0,
                                     left: 0,
                                     width: "100%",
                                     height: "100%",
                                     padding: 5
                                   }}
                                   src={makeImageUrlWithSize(contentCreation, 'md')}
                                   onError={onImageError}
                              />
                          )}
                          onItemClick={contentCreation => {
                            let creations = (contentCreator.type.limit == null) ?
                              contentCreator.contentCreations :
                              contentCreator.contentCreations.slice(0, contentCreator.type.limit);
                            this.setCurrentPlayingContent(creations.findIndex(i => i.id == contentCreation.id), creations);
                          }}
                        />
                      }
                    </div>
                  </RespD>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderTagPlaylists = () => {
    const {playlist} = this.state
    return (
       <div id="reference-scroll-position">
        
      </div>
    )
  }

  clearCurrentPlayingContent = () => {
    this.setState({showContentCreationDialog: false, hasPrev: false, hasNext: false, currentIndex: null, currentPlayingContent: {}, currentList: null})
  }

  setCurrentPlayingContent = (index, list = null) => {
    let currentList = list || this.state.playlist.contentCreations;
    let hasPrev = index > 0 ;
    let hasNext = index < (currentList.length - 1);
    this.setState({showContentCreationDialog: true, currentPlayingContent: currentList && currentList[index], hasPrev: hasPrev, hasNext: hasNext, currentIndex: index, currentList: currentList})
  }

  render = () => {
    const { playlist } = this.state;
    
    if (this.state.loading) {
      return null
    } else {
      return (
        <div style={{height: "100%"}}>
          {this.renderCommentDialog()}
          <ViewContainer
            ref="viewContainer"
          >
            <ItemView
              containerImage={
                playlist.image ?
                  request.file.image.makeCroppedUrl(playlist.image.id, 1000, 1000, 'playlist', 'jpg')
                    : FolderImage
              }
              renderItemInfo={this.renderItemInfo}
              renderItemTable={this.renderItemTable}
            />
          </ViewContainer>
          <ContentCreationPlayer
            show={this.state.showContentCreationDialog}
            onNextArrow={this.state.hasNext ? () => this.setCurrentPlayingContent(this.state.currentIndex+1) : null}
            onPrevArrow={this.state.hasPrev ? () => this.setCurrentPlayingContent(this.state.currentIndex-1) : null}
            currentContentCreation={this.state.currentPlayingContent}
            noAuthorLink={userHasRestrictedAccess()}
            handleClose={() => this.clearCurrentPlayingContent()}
          />
          <OrderUpdate
            playlistId={playlist.id}
            show={this.state.openOrderDialog}
            creators={playlist.contentCreators}
            tracks={this.state.musics}
            requestData={this.requestData}
            onClose={() => this.setState({openOrderDialog: false})}
          />
        </div>
      )
    }
  }
}

export default HomePlaylist;

const AudioBar = styled.audio`
/*margin: 20px 2%;*/
@media (max-width: 440px) {
  width: 100%;
}
`;

const RespD = styled.div`
float: left;
position: relative;
padding: 10px;
width: 100%;
@media (max-width: 460px) {
  padding: 8px;
}
`;

const MaterialContainer = styled.div`
  width: 100%;
  display: flex; 
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  @media (max-width: 460px) {
    flex-direction: column;
  }
`

