import {PlayCircleOutline} from "@material-ui/icons";
import React, {Component} from "react";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || [],
      showDialog: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.item) {
      this.setState({item: nextProps.item})
    }
  }

  componentDidMount() {
    document.addEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('MSFullscreenChange', this.handleEscapeKeyPress);
  }


  toggleVideoState = (toPlayingState, action, event) => {

    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
  }

  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
    this.fullScreen()
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  fullScreen = () => {
    this.videoRef.requestFullscreen()
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()
    }
  }

  render = () =>
      <div
        className="file-item-carousel"
        style={{overflow: "hidden", cursor: "pointer"}}
        onClick={() => {(this.state.playing) ? this.pause() : this.play()}}
      >
        <div className="banner-text">
          <div className="over-action-reel">
            <PlayCircleOutline/>
          </div>
          <div className="over-background"/>
          <div className="item-text" style={{color: this.props.thirdColor ? this.props.thirdColor : "white", opacity: 1, marginLeft: 50, marginBottom: 20, zIndex: 100}}>
            <h3 className="item-title" >{this.state.item.title}</h3>
            <p className="item-subtitle">{this.state.item.subtitle}</p>
          </div>
        </div>
        <video ref={ref => this.videoRef = ref} loop={false} controls={false}
               preload="none"
               poster={this.state.item.thumbnail}
               className={'banner-video'}>
          <source src={this.state.item.video.url} />
        </video>
      </div>
}