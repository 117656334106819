import React from 'react'
import {ImagePicker, SelectValidator} from '../../components/Letflow/Form';
import localization from '../../config/localization';
import {Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip} from '@material-ui/core';
import {ItemSelectionTables} from '../../components/Letflow/Table';
import {request} from '../../api-client';
import {TextValidator} from "react-material-ui-form-validator";
import TablePlayButton from '../../components/Letflow/Table/Buttons/TablePlayButton';
import TableEditButton from "../../components/Letflow/Table/Buttons/TableEditButton";
import {DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  getStoredUserRoleId,
  ROLES,
  userIsArtist,
  userIsSupervisor,
  userIsSysAdmin
} from "../../api-client/core/authentication/utils";
import AsyncSelect from "react-select/lib/Async";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import FormTagSelection from "../../components/Letflow/Form/FormTagSelection";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import Unsplash from "../../components/Letflow/Unsplash";
import {makeWaveformUrl} from "../../utils";
import AvailableLanguages from "../../config/localization/availableLanguages.json";


const Hint = ({ label, tip }) =>
  <Tooltip placement={"top"} title={tip}>
    <div
      style={{
        display: 'inline-block',
        fontSize: 'x-small',
        color: '#666666',
        backgroundColor: '#ffffcc',
        border: 'solid #cccccc 1px',
        borderRadius: '6px',
        margin: '3px',
        padding: '3px'
      }}>{label}</div>
  </Tooltip>


const Hints = ({ albumId, music, visibility }) => {
  let hints = [];

  if (music.visibility && visibility) {
    const visibilities = ['public', 'protected', 'hidden'];

    if (visibilities.indexOf(music.visibility) > visibilities.indexOf(visibility)) {
      const otherAlbums = music.albums
        .filter(album => album.id != albumId && visibilities.indexOf(album.visibility) > visibilities.indexOf(visibility))
        .map(album => album.title)
        .join(', ');

      if (otherAlbums) {
          hints.push({ label: localization.get(music.visibility), tip: otherAlbums });
      }
    }
  }

  return hints.map(hint => <Hint { ...hint } />);
}

const MusicSelectionTables = ({onSelectedItemsChanged, onRemovedItemsChanged, selectedMusics, removedMusics, albumId, artistId, type, catalogId}) =>
  <ItemSelectionTables
    columns={[
      {Header: localization.get('table.code'), accessor: 'code'},
      {Header: localization.get('table.title'), accessor: 'title'},
      {
        Header: localization.get('table.artist'),
        id: 'artist',
        accessor: music => music.artist ? music.artist.name : '-',
        sortable: false,
        filterable: false
      },
      {Cell: ({ original }) =>
          <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
            <Hints albumId={albumId} music={original} />
          </div>,
        sortable: false,
        filterable: false
      }
    ]}
    extraButtonFactories={[music => <TablePlayButton tracks={{
      fetchAudioUrl: () => request.music.get(music.id).then(music => music.audio.url),
      title: music.title,
      waveform: makeWaveformUrl(music)
    }}/>, music => <TableEditButton path={`/panel/musics/${music.id}/edit`}/>]}
    requestPaginatedItems={paginatedConfig => request.music.candidates(albumId, artistId, type, catalogId, removedMusics, paginatedConfig)}
    selectedItemsChanged={onSelectedItemsChanged}
    removedItemsChanged={onRemovedItemsChanged}
    selectedTableTitle={<h4>{localization.get('form.selected_musics')}</h4>}
    toSelectTableTitle={<h4>{localization.get('form.select_music')}</h4>}
    selected={selectedMusics}
    removed={removedMusics}
    hideOrderColumn={type === 'library'}
  />


const FormContent = ({
  hideInHome,
  onHideInHomeChange,
  translations,
  onChangeTranslation,
  image,
  onChange,
  onImageChange,
  selectedMusics,
  disabled,
  onSelectedItemsChanged,
  onRemovedItemsChanged,
  removedMusics,
  albumId,
  onArtistChange,
  artist,
  type,
  onTypeChange,
  createdAt,
  handleDateChange,
  formType,
  onCatalogChange,
  selectedCatalog,
  showTagSelection,
  tagGroups,
  onSelectedTagsChanged,
  onSuggestTags,
  onSetPreview,
  updateDisableForm,
  disableForm,
  imageRadio,
  onSetImageFromUnsplash,
  onImageRadioChange,
  mirrorTags,
}) => {
  const types = [
    {id: "artist", name: "Artista"},
    {id: "compiled", name: "Compilado"},
  ]

    return (
      <>
      <div style={{display: showTagSelection && 'none'}}>
        {getStoredUserRoleId() !== ROLES.artistId &&
        <React.Fragment>

          <SelectValidator
            style={{width: "100%", marginBottom: "20px"}}
            id='type'
            name="type"
            current={type}
            title={localization.get('form.type')}
            onChange={onTypeChange}
            items={types}
            disabled={formType === 'update' && type === 'compiled' ? true : disabled}
            validators={['required']}
            errorMessages={[localization.get('validator.is_required')]}
          />

          {type === 'artist' &&
          <div style={{marginBottom: 20}}>
            <label style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)'}}>{localization.get('artist')}</label>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              placeholder={localization.get('form.artist')}
              cacheOptions
              defaultOptions
              onChange={artist => {
                onArtistChange(artist)
              }}
              value={artist}
              loadOptions={inputValue =>
                request.artist
                  .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                  .then(artists => artists.map(artist => ({value: artist.id, label: artist.name})))
              }
            />
          </div>
          }

          <FormControlLabel
            control={
              <Checkbox
                id="hideInHome"
                style={{ color: "#4cc2d5" }}
                checked={hideInHome}
                onChange={() => onHideInHomeChange(!hideInHome)}
              />
            }
            label={localization.get('form.hide_in_home')}
          />

        </React.Fragment>
        }

        <br/>
        <FormControl fullWidth>
          <span>{localization.get('form.select_catalog')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('form.catalogs')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onCatalogChange}
            value={selectedCatalog}
            loadOptions={inputValue => {
              let filterBy = [{column: "name", filter: inputValue}];
              if (userIsSysAdmin() || userIsSupervisor()) {
                filterBy = filterBy.concat([{column: "created_by_admin", filter: true}, {column: 'content', filter: 'music'}])
              }
              return request.catalog
                .getAll({filterBy})
                .then(res => res.map(catalog => ({
                  value: catalog.id,
                  label: `${catalog.name}`,
                  createdBy: catalog.createdBy
                })))
            }}
            defaultOptions
          />
        </FormControl>
        <br/>
        <br/>

        <TranslatableInputs
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`title${language}`}
                  label={localization.get('form.title')}
                  name={`title${language}`}
                  value={currentLangTranslation.title}
                  onChange={e => onChangeTranslation(e.target.value, "title", language)}
                  disabled={disabled}
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                />,
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`subtitle${language}`}
                  label={localization.get('form.subtitle')}
                  name={`subtitle${language}`}
                  value={currentLangTranslation.subtitle}
                  onChange={e => onChangeTranslation(e.target.value, "subtitle", language)}
                  disabled={disabled}
                />,
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`shortDescription${language}`}
                  label={localization.get('form.short_description')}
                  name={`shortDescription${language}`}
                  value={currentLangTranslation.shortDescription}
                  onChange={e => onChangeTranslation(e.target.value, "shortDescription", language)}
                  disabled={disabled}
                  multiline
                />
              ]
            }
          })}
        />

        {formType === 'update' &&
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            fullWidth
            label="Fecha de Creacion"
            value={createdAt}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
        }

        <br/>

        <h4>{localization.get('form.select_image')}</h4>
        <FormControl>
          <RadioGroup
            name="imageRadio"
            value={imageRadio}
            onChange={e => onImageRadioChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            <FormControlLabel value="filepond" control={<Radio color="default"/>} label={localization.get('client_file.upload_from_computer')}/>
            <FormControlLabel value="unsplash" control={<Radio color="default"/>} label={localization.get('form.upload_from_unsplash')}/>
          </RadioGroup>
        </FormControl>
        {imageRadio === 'filepond' ?
        <>
          {image.src && <ImagePicker image={image.src} previewOnly={true} id={image.id}/>}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={image}
            id={'image'}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={onImageChange}
            setPreview={onSetPreview}
          />
          <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div>
        </>
          :
          <Unsplash imageAmount={9} onSelectImage={onSetImageFromUnsplash}/>
        }
          <br/>
          <br/>


        {!userIsArtist() &&
        <MusicSelectionTables
          albumId={albumId ? albumId : undefined}
          artistId={artist ? artist.value : undefined}
          catalogId={selectedCatalog ? selectedCatalog.value : undefined}
          type={type}
          onRemovedItemsChanged={onRemovedItemsChanged}
          removedMusics={removedMusics}
          selectedMusics={selectedMusics}
          onSelectedItemsChanged={onSelectedItemsChanged}
        />
        }

      </div>
      <div style={{display: !showTagSelection &&  'none'}}>
        <h3 style={{fontSize: '1.4rem'}}>{localization.get('Select Tags')}</h3>
        {formType === 'update' && <Button style={{backgroundColor: '#ddddddbd'}} disabled={disabled} onClick={onSuggestTags}>{localization.get('tags.suggest')}</Button>}
        <br/>
        {formType === 'update' &&
         <FormControlLabel
            control={
              <Checkbox
                id="mirrorTags"
                style={{ color: "#4cc2d5" }}
                checked={mirrorTags}
                onChange={() => onChange({target: {id: "mirrorTags", value: !mirrorTags}})}
              />
            }
            label={localization.get('form.mirror_tags')}
          />
        }
        <FormTagSelection hideTitle={true} tagGroups={tagGroups} onSelectedTagsChanged={onSelectedTagsChanged} disabled={disabled}/>
      </div>
    </>
    )

}

export default FormContent
