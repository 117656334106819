import { put } from '../utils'
import { makeJsonData } from './utils'

export default host => ({
    id,
    name,
    link,
    duration,
    type,
    fileId,
    client,
    mobileImageId,
  }) => {
  return put(`${host}/banners/${id}`, makeJsonData({
    name,
    link,
    duration,
    type,
    fileId,
    client,
    mobileImageId
  }))
    .then(response => response.json())
}


