import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ( channelId, id,
{
  ownerId,
  ownerType,
  days,
  active,
  translations,
  prices,
  shipments,
  addresses,
  channelShipments,
  giftable,
}) => post(`${host}/channels/${channelId}/products/${id}`, addPutMethodToForm(makeFormData({
  ownerId,
  ownerType,
  days,
  active,
  translations,
  prices,
  shipments,
  addresses,
  channelShipments,
  giftable,
})))
  .then(response => response.json())
  .then(json => json.data)
