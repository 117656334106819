import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  featuredImageId,
  mobileCoverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo,
  countryId,
  observation,
  active,
  selectedCatalogs,
  contentCreatorTypeId,
  weight,
  unsplashCoverImage,
  unsplashFeaturedImage,
  unsplashProfileImage,
  unsplashMobileCoverImage,
  startActivity,
  batchProfileImageId,
  batchCoverImageId,
  batchFeaturedImageId,
  batchMobileCoverImageId,
  downloadableMaterialId,
  downloadableMaterialFileType,
  downloadableMaterialText,
  downloadableMaterialLink,
  contentSupervisorId,
  onlyArgentina,
  releaseDate,
  expireDate,
  batchDownloadableMaterialId,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  showDateOnHome,
  showTags,
  suggestedTagGroup,
  suggestedView,
  selectedCloud,
  hasPreferences,
  useBackgroundColor,
  backgroundColor,
  useBackgroundGradient,
  subtitleFontSize,
  subtitleMargin,
  titleColor,
  descriptionColor,
  creationColor,
  cardColor,
  cardShape,
  creationInverted,
  isEvent,
  eventsPosition,
  buyButtonText,
  buyButtonShowInSocials,
  buyButtonProductId,
  buyButtonPosition,
  buyButtonBackgroundColor,
  buyButtonFontColor,
  hasRegionBlock,
  allowedRegions,
  editorialBgColor,
  editorialTitleColor,
  hasEditorialPreferences,
  editorialHideCoverImage,
  creationCarouselBackgroundColor,
  viewType,
  viewTypeMobile,
  suggestedViewMultipleRow,
  driveProfileImage,
  driveCoverImage,
  driveFeaturedImage,
  driveMobileCoverImage,
  canonicalChannel,
  suggestedContentCreators,
  accessLevel,
  additionInfoSize,
  eventButtonText,
  eventButtonColor,
  eventButtonBackgroundColor,
  editorialUseSynopsis
}) =>
  post(
    `${host}/contentCreators`,
    makeFormData({
      creating:true,
      email,
      phone,
      translations,
      imageId,
      coverImageId,
      mobileCoverImageId,
      featuredImageId,
      selectedTags,
      selectedContentCreations,
      socialLinks,
      isDemo,
      countryId,
      observation,
      active,
      selectedCatalogs,
      contentCreatorTypeId,
      weight,
      unsplashCoverImage,
      unsplashFeaturedImage,
      unsplashProfileImage,
      unsplashMobileCoverImage,
      startActivity,
      batchProfileImageId,
      batchCoverImageId,
      batchFeaturedImageId,
      batchMobileCoverImageId,
      downloadableMaterialId,
      downloadableMaterialFileType,
      downloadableMaterialText,
      downloadableMaterialLink,
      contentSupervisorId,
      onlyArgentina,
      releaseDate,
      expireDate,
      batchDownloadableMaterialId,
      hasSuggestedCarousel,
      suggestedCarouselPlaylist,
      showDateOnHome,
      showTags,
      suggestedTagGroup,
      suggestedView,
      selectedCloud,
      hasPreferences,
      useBackgroundColor,
      backgroundColor,
      useBackgroundGradient,
      subtitleFontSize,
      subtitleMargin,
      titleColor,
      descriptionColor,
      creationColor,
      cardColor,
      cardShape,
      creationInverted,
      isEvent,
      eventsPosition,
      buyButtonText,
      buyButtonShowInSocials,
      buyButtonProductId,
      buyButtonPosition,
      buyButtonBackgroundColor,
      buyButtonFontColor,
      hasRegionBlock,
      allowedRegions,
      editorialBgColor,
      editorialTitleColor,
      hasEditorialPreferences,
      editorialHideCoverImage,
      creationCarouselBackgroundColor,
      viewType,
      viewTypeMobile,
      suggestedViewMultipleRow,
      driveProfileImage,
      driveCoverImage,
      driveFeaturedImage,
      driveMobileCoverImage,
      canonicalChannel,
      suggestedContentCreators,
      accessLevel,
      additionInfoSize,
      eventButtonText,
      eventButtonColor,
      eventButtonBackgroundColor,
      editorialUseSynopsis
    })
  ).then(response => response.json());
