import {
  Album as AlbumIcon,
  LibraryMusic,
  Message,
  PieChart,
  RecentActors,
  ShoppingCart,
  Sort
} from "@material-ui/icons";
import localization from '../config/localization'
import Musics from "views/Musics";
import Albums from "views/Albums";
import Artists from "views/Artists";
import AllLicenseListing from "views/Licenses/LicenseListing";
import Messaging from "views/Messaging";
import UserProfile from "views/UserProfile";
import MusicRanking from "views/Musics/Ranking";
import ArtistRanking from "views/Artists/Ranking";

const makeRoutes = () => {

  return (
    [
      {
        hidden: true,
        path: "/panel/profile",
        component: UserProfile
      },
      {
        path: "/panel/musics",
        name: localization.get("musics"),
        icon: LibraryMusic,
        component: Musics,
        state: 'musics'
      },
      {
        path: "/panel/albums",
        name: localization.get("albums"),
        icon: AlbumIcon,
        component: Albums,
      },
      {
        path: "/panel/artists",
        name: localization.get("artists"),
        icon: RecentActors,
        component: Artists,
      },
      {
        path: "/panel/licenses",
        name: localization.get("licenses"),
        icon: ShoppingCart,
        component: AllLicenseListing,
      },
      {
        collapse: true,
        name: localization.get("reports"),
        icon: PieChart,
        state: "openReports",
        views: [

          {
            path: "/panel/ranking/musics",
            name: localization.get("ranking.musics"),
            icon: Sort,
            component: MusicRanking,
          },
          {
            path: "/panel/ranking/artists",
            name: localization.get("ranking.artists"),
            icon: Sort,
            component: ArtistRanking,
          },
        ],
      },
      {
        path: "/panel/conversations",
        name: localization.get("conversations"),
        icon: Message,
        component: Messaging,
      },
      {
        path: "/",
        redirect: true,
        pathTo: "/musics",
        name: localization.get('musics')
      },
    ]
  )
}

export default makeRoutes