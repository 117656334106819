import React from "react";
import {Button} from "@material-ui/core";
import {CloudUpload} from "@material-ui/icons";
import localization from "../../../config/localization";
import styled from "styled-components";

const SoundFilePicker = ({ audio, onAudioChanged, disabled = false, audioRulesLabel, format, showAudioBar = true }) => {

  let audioInput

  return (
    <div>
      <br />
      <h4>{localization.get('audio_file')}</h4>
      <span style={{color: "darkgray", margin: "15px 0", display: "inherit"}}>{localization.get(audioRulesLabel)}</span>
      <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: "500px", paddingLeft: "45px" }}>
        <input id='audio' ref={r => audioInput = r} accept={format} type='file' style={{ display: 'none' }} onChange={onAudioChanged} />
        <Button style={{ backgroundColor: '#00B9CC', color: 'white' }} variant='fab' onClick={() => audioInput.click()} disabled={disabled}>
          <CloudUpload />
        </Button>
        {(format === '.mp3' && showAudioBar) && <AudioBar src={audio.src} controls />}
      </div>
      <br />
      <span>{audio.file ? audio.file.name : audio.aiffFile ? audio.aiffFile.name : ''}</span>
    </div>
  )
}

export default SoundFilePicker


const AudioBar = styled.audio`
display: none;
margin-left: 10%;
@media (min-width: 545px) {
  display: block;
}
`
