import React, {Component} from 'react'
import {licenseTypeIdToString} from '../utils/mappers';
import {LicenseTypeIds} from '../utils/constants';
import Checkbox, {SecondaryCheckbox} from '../components/Checkbox'
import Reductions from '../components/Reductions';
import localization from '../../../../config/localization'
import {userIsSysAdmin} from "../../../../api-client/core/authentication/utils";

const LicenseTypeScreen = ({ licenseType, reductions, unlimitedReductions, client, onChange }) =>
  <div>
    <div className={'license-wizard-main-description'}>{localization.get('license_wizard.select_use')}</div>
    {(client.licensePlan.only_own_channels || userIsSysAdmin()) &&
      <OwnChannelsCheckbox
        licenseType={licenseType}
        reductions={reductions}
        unlimitedReductions={unlimitedReductions}
        onChange={onChange}
      />
    }
    {(client.licensePlan.only_other_purpose || userIsSysAdmin()) &&
      <CommercialCheckbox
        licenseType={licenseType}
        client={client}
        onChange={onChange}
      />
    }
    {userIsSysAdmin() &&
      <SadaicCheckbox
        licenseType={licenseType}
        client={client}
        onChange={onChange}
      />
    }
  </div>


class OwnChannelsCheckbox extends Component {
  MainCheckbox = () =>
    <LicenseTypeCheckbox
      licenseType={this.props.licenseType}
      checkboxLicenseType={LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS}
      onClick={v => this.props.onChange({ licenseType: v })}
    />

  ReductionsWrapper = () =>
    <div style={{ flex: 2 }}>
      <Reductions
        value={this.props.reductions}
        onChange={v => this.props.onChange({ reductions: v })}
        title={localization.get('license_wizard.versions')}
        disabled={this.props.unlimitedReductions}
      />
    </div>

  UnlimitedReductions = () =>
    <SecondaryCheckbox
      style={{ flex: 1 }}
      checked={this.props.unlimitedReductions}
      onClick={() => this.props.onChange({ unlimitedReductions: !this.props.unlimitedReductions, reductions: 0 })}
      label={localization.get('license_wizard.unlimited')}
    />

  render = () =>
    <React.Fragment>
      <this.MainCheckbox />
      {this.props.licenseType === LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS &&
        <div style={{ display: 'flex', margin: '-10px 10px 0 10px' }}>
          <this.ReductionsWrapper />
          {/*<this.UnlimitedReductions />*/}
        </div>}
      <div style={{ margin: '0 30px' }}>{localization.get('license_wizard.type.corporate.explanation')}</div>
    </React.Fragment>
}

const CommercialCheckbox = ({ licenseType, onChange }) =>
  <LicenseTypeCheckbox
    licenseType={licenseType}
    checkboxLicenseType={LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL}
    onClick={v => onChange({ licenseType: v, reductions: 0, unlimitedReductions: false })}
  />

const SadaicCheckbox = ({ licenseType, onChange }) =>
  <LicenseTypeCheckbox
    licenseType={licenseType}
    checkboxLicenseType={LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA}
    onClick={v => onChange({ licenseType: v, reductions: 0, unlimitedReductions: false })}
  />

const LicenseTypeCheckbox = ({ licenseType, checkboxLicenseType, onClick }) =>
  <Checkbox
    content={licenseTypeIdToString(checkboxLicenseType)}
    checked={licenseType === checkboxLicenseType}
    onClick={() => onClick(checkboxLicenseType)}
  />

export default LicenseTypeScreen
