import React, {Component} from "react";
import {request} from "../../api-client";
import "./PublicReel.css";
import ShareFooter from "../../components/Letflow/ShareFooter";
import qs from "qs";
import FileStorageItem from "../../components/Letflow/FileStorageItem";
import {Email, GetApp, Home, Phone} from "@material-ui/icons"
import localization from "../../config/localization";
import Slider from "react-slick";
import styled from "styled-components";
import Banner from "./Banner";
import {addHttpToUrl, makeFitImageUrl, makeFitImageUrlWithSize} from "../../utils";
import {Tooltip} from "@material-ui/core";
import {getSocialLinkIcon} from "../../components/Letflow/SocialLinks";
import {makeThumbnailUrlWithSize} from "../../layouts/Home/HomeElements/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import analyticsInstance from "../../analytics/analyticsInstance";
import {getMainTranslation} from "../../api-client/core/authentication/utils";

class PublicReel extends Component {
  state = {
    files: [],
    client: null,
    reel: {},
    secondColor: "rgba(0,0,0,1)",
    pageIsReady: false,
    logo: null,
  };

  componentDidMount = () => {
    let search = qs.parse(this.props.location.search.slice(1));
    let { client_id } = search;
    this.requestFiles({})
      .then(() => this.setState({pageIsReady: true}))
  };

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );


  requestFiles = ({page = 0, perPage = 18}) =>
    request.clientReels
      .showPublic({page, perPage}, this.props.match.params.reelId)
      .then(reel => this.setState({ files: reel.clientFiles, reel, client: reel.client }, () => {
          analyticsInstance.visitShareContent({
            type: "Reel",
            name: reel.title || ""
          })

          this.setState({
              files: this.state.files.map((file, index) => {
                  if (file.type === 'video') {
                    file = {
                      ...file,
                      thumbnail: index < 5 ? makeThumbnailUrlWithSize(file.video, 'reel-lg') : makeThumbnailUrlWithSize(file.video, 'reel-md')
                    }
                  }
                  return file
                }
              )
            }
          )
        })
      ).then(() => {
          let secondColor = this.state.reel.second_color.split(',')
          secondColor[secondColor.length -1 ] = ".8)"

          secondColor.toString()
          this.setState({secondColor: secondColor.toString()})
      }).then(() => {
        if (this.state.client.reelsLogo) {
          this.setState({logo: {image: this.state.client.reelsLogo}}, () => this.setState({logo: makeFitImageUrlWithSize(this.state.logo)}))
        } else if (this.state.client.image) {
          this.setState({logo: {image: this.state.client.image}}, () => this.setState({logo: makeFitImageUrlWithSize(this.state.logo)}))
        }


      document.title = `${this.state.client.name} - Reel`;
    });

  render = () => {
    let settings = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1500,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1280,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 960,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    };

  return (
     this.state.pageIsReady ?
        <div >
          {/*<PublicNavBar client={this.state.client && this.state.client} />*/}
          <div style={{minHeight: "95vh", width: "100%",position: "relative", float:"left", backgroundColor: this.state.reel.color || "#EEEEEE"}} >
            {this.state.reel.view_type == "grid_with_banner" &&
            <div style={{ width: "100%", float: "left", position: "relative", overflow: "hidden", linearGradient:"(rgba(255, 255, 0, 0), rgba(95, 179, 255, 0.8))}"}} className="banner-container">
              <DivR>
                <Slider {...settings} >
                  {this.state.files.slice(0, 5).map(file =>
                    <Banner
                      item={file}
                      thirdColor={this.state.reel && this.state.reel.third_color}
                    />
                  )}
                </Slider>
              </DivR>
            </div>
            }
            <div className={this.state.reel.view_type == "grid_with_banner" ? "reel-navbar" : "reel-navbar reel-navbar-no-banner"} >
              <img
                onClick={() => this.client.socialLinks && this.state.client.socialLinks.filter(social => social.type == "web") && window.open(addHttpToUrl( this.state.client.socialLinks.filter(social => social.type == "web").url), "_blank")}
                className="logo-navbar-reel"
                alt={this.state.client.name}
                src={this.state.client && makeFitImageUrl(this.state.client, 300, 200, null,'png')}
              />
              <div className="contact-section" >
                <div className="socials">
                  {this.state.client && this.state.client.socialLinks &&
                  this.state.client.socialLinks.map(social =>
                    <>
                      {social.url !== "" &&
                      <div className="social" style={{position: "relative",float: "right", cursor: "pointer", color: this.state.secondColor || "#000000"}} onClick={() => window.open(addHttpToUrl(social.url), "_blank")}>
                        {getSocialLinkIcon(social.type)}
                      </div>
                      }
                    </>
                  )}
                  {this.state.client && this.state.client.email && <div className="social" onClick={() => window.location = `mailto:${this.state.client && this.state.client.email}`} ><Email/></div>}
                  {this.state.client && this.state.client.address && <div className="social"><Tooltip title={this.state.client && this.state.client.address} placement='bottom'><Home/></Tooltip></div>}
                  {this.state.client && this.state.client.phone && <div className="social"><Tooltip title={this.state.client && this.state.client.phone} placement='bottom'><Phone/></Tooltip></div>}
                </div>
              </div>
            </div>
            <div className="subheader">
              <div className="subheader-title" style={{color: this.state.reel && this.state.reel.fourth_color || "black"}}>
                <h1 style={{margin: 0, width: "100%", overflow: "hidden", fontSize: window.innerWidth > 600 ? 50 : 32}}>{this.state.reel.title}</h1>
              </div>
              {window.innerWidth > 600 &&
                <div className="download">
                  <Tooltip title={localization.get('download.all')}>
                    <GetApp
                      style={{color: this.state.reel && this.state.reel.fourth_color || "black", fontSize: 40}}
                      onClick={() => window.open(request.clientFile.downloadReel(this.props.match.params.reelId), '_blank')}/>
                  </Tooltip>
                </div>
              }
            </div>
            {this.state.files.length > 0 &&
            <>
              <div className="file-container" style={{padding: 10, marginBottom: 30}}>
                {this.state.files.map(file =>
                  <FileStorageItem
                    item={file}
                    publicView={true}
                    secondColor={this.state.secondColor}
                    thirdColor={this.state.reel && this.state.reel.third_color}
                  />
                )}
              </div>
              {/*{this.state.files.meta.last_page !== 1 &&
                <Paginator
                  style={{marginTop: 10}}
                  pages={this.state.files.meta.last_page}
                  onPageChange={page => this.requestFiles({page: page})}
                  pagination={this.state.files.meta}
                />
              }*/}
            </>
            }
          </div>
          <ShareFooter {...this.props} socialLinks={this.state.client.socialLinks}/>
        </div>
        :
       <SkeletonTheme color={"#ddd"}>
        <Skeleton height={550} duration={1.5}/>
        <br/><br/>
        <br/><br/>
        <Skeleton height={75} duration={1.5}/>
        <br/>
        <br/>
        <Skeleton height={250} duration={1.5} width={"32%"}/>
        <span className="skeleton">
          <Skeleton height={250} duration={1.5} width={"32%"}/>
        </span>
        <Skeleton height={250} duration={1.5} width={"32%"}/>
       </SkeletonTheme>
  )}
}

export default PublicReel;

const DivR = styled.div`
  width: 100%;
  position: relative;
  float: left;
  overflow: hidden;
  
@media (max-width: 770px) {
  display: none;
}
`;
