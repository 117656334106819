import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  playlistId,
  clientId,
}) =>
  post(`${host}/playlists/${playlistId}/duplicate`, makeFormData({
    clientId,
  }))
  .then(response => response.json())
  .then(json => json.data)