import {appendPaginationQueryParamsToUrl, del, get, post} from "../utils";
/**
 * @typedef {Object} InputData
 * @property {string} type
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {file} video
 * @property {file} image
 * @property {number} order
 */

/**
 * @param {InputData} data
 */
const makeFormData = (data, isPut) => {
  const form = new FormData();
  data.type && form.append("type", data.type);
  data.color && form.append("color", data.color);
  data.image && form.append("image", data.image, data.image.name);
  isPut && form.append("_method", "PUT");
  return form;
};

export default host => {
  const getAll = (options) =>
    get(appendPaginationQueryParamsToUrl(`${host}/featuredImages?include=image`, { ...options,
      alreadyHasQueryParams: true
    }))
      .then(response => response.json())

  const _get = id =>
    get(`${host}/featuredImages/${id}?include=image`)
      .then(response => response.json())
      .then(response => response.data);

  /**
   * @param {InputData} data
   */
  const create = (data, onProgress) =>
    post(`${host}/featuredImages`, makeFormData(data), null, onProgress)
      .then(response => response.json())
      .then(response => response.data);

  /**
   * @param {InputData} data
   */
  const update = (id, data, onProgress) =>
    post(`${host}/featuredImages/${id}`, makeFormData(data, true), null, onProgress)
      .then(response => response.json())
      .then(response => response.data);

  const _delete = id => del(`${host}/featuredImages/${id}`);

  return {
    getAll,
    get: _get,
    create,
    update,
    delete: _delete
  };
};
