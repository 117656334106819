import getAll from './getAll'
import getAllOfTypeMusic from './getAllOfTypeMusic'
import getAllOfTypeSpeaker from './getAllOfTypeSpeaker'
import create from './create'
import del from './delete'
import get from './get'
import update from './update'
import searchFor from './searchFor';
import getAllPrivate from "./getAllPrivate";

export default host => ({
  getAll: getAll(host),
  getAllOfTypeMusic: getAllOfTypeMusic(host),
  getAllOfTypeSpeaker: getAllOfTypeSpeaker(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  update: update(host),
  searchFor: searchFor(host),
  getAllPrivate: getAllPrivate(host),
})
