import React, {Component} from "react";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {AllViewContainer} from '../../components/Letflow/ViewContainer';
import localization from "../../config/localization";
import {ServerSidePaginationTable} from "../../components/Letflow/Table";
import TableAutocompleteFilter from "../../components/Letflow/Table/TableAutocompleteFilter";
import {request} from '../../api-client';
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {parseDate} from "../../utils";

class AdsReport extends Component {
constructor(props) {
    super(props);
    this.state = {
        minDate: null,
        maxDate: null
    }
    }

  componentDidMount = () => {
      document.title = localization.get('catalogs.report') + ' - Feater'
  }


  onChangeFrom = (minDate) => this.setState({ minDate }, this.refs.table.forceUpdate)
  onChangeTo = (maxDate) => this.setState({ maxDate }, this.refs.table.forceUpdate)

  columns = () => [
		{
      id: "date",
      Header: localization.get("original_music.date"),
      accessor: 'date',
      filterable: false,
      sortable: false,
    },
		{
      id: "channel_name",
      Header: localization.get("channel"),
      accessor: 'channel_name',
      sortable: false,
      filterable: true,
			Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.channel
            .getAll({
              filterBy: [{ column: 'name', filter }]
            })
            .then(channels => channels.map(channel => channel.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    },	
    {
      id: "ad_name",
      Header: localization.get("ads"),
      accessor: 'ad_name',
      sortable: false,
      filterable: true,
			Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.ads.getAll({
              filterBy: [{ column: 'name', filter }]
            })
            .then(ads => ads.map(ad => ad.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    },
    
    {
      id: "views",
      Header: localization.get("table.views"),
      accessor: 'total_views',
      filterable: false,
      sortable: false,
			width: 80,
			style: {
				textAlign: "right",
			}
    },
		{
      id: "clicks",
      Header: localization.get("table.clicks"),
      accessor: 'total_clicks',
      sortable: false,
      filterable: false,
			width: 80,
			style: {
				textAlign: "right",
			}
    },
    // {
    //   id: "authenticated_views",
    //   Header: localization.get("authenticated_views"),
    //   accessor: 'total_views_authenticated',
    //   sortable: true,
    //   filterable: false,
    // },
		// {
    //   id: "anonymous_views",
    //   Header: localization.get("anonymous_views"),
    //   accessor: 'total_views_anonymous',
    //   sortable: true,
    //   filterable: false,
    // },
  ];

  render = () =>
    <AllViewContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
							<div style={{width: "100%", float: "left", position: "relative"}}>
									<div style={{ textAlign: 'center', marginBottom: '15px', padding: '10px' }}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<DatePicker
													style={{ width: 150, marginLeft: 20, marginRight: 20 }}
													label={localization.get('form.from')}
													autoOk
													keyboard
													clearable
													onlyCalendar
													disableFuture
													value={this.state.minDate}
													format={"dd/MM/yyyy"}
													onChange={this.onChangeFrom}
											/>
											</MuiPickersUtilsProvider>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<DatePicker
													style={{ width: 150 }}
													label={localization.get('form.to')}
													autoOk
													keyboard
													clearable
													onlyCalendar
													disableFuture
													value={this.state.maxDate}
													format={"dd/MM/yyyy"}
													onChange={this.onChangeTo}
											/>
											</MuiPickersUtilsProvider>
									</div>
									<ServerSidePaginationTable
											ref="table"
											columns={this.columns()}
											requestTableData={paginationConfig => {

											if (this.state.minDate) {
													paginationConfig = {
													...paginationConfig,
													filterBy: paginationConfig.filterBy.concat([{column: "min_date", filter: parseDate(this.state.minDate)}])
													}
											}
											if (this.state.maxDate) {
													paginationConfig = {
													...paginationConfig,
													filterBy: paginationConfig.filterBy.concat([{column: "max_date", filter: parseDate(this.state.maxDate)}])
													}
											}

											return request.ads.getReport(paginationConfig).then((response) => {
													this.setState({total: response.meta.total})
													return response
											})
											}}
									/>
							</div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default AdsReport;
