import React, {Component} from "react";
import {getMaterialOriginalFileName} from ".";
import {LibraryBooks} from "@material-ui/icons";

class OtherMessage extends Component {
  render = () => {
    const { src } = this.props;

    return (
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center", marginTop: 10, wordBreak:"break-word" }}>
          <span
            style={{ marginRight: 10, display: "flex", alignItems: "center" }}
          >
            {getMaterialOriginalFileName(src)}
            <LibraryBooks style={{ marginLeft: 10 }} />
          </span>
        </div>
      </React.Fragment>
    );
  };
}

export default OtherMessage;
