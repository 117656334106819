import React, {Component} from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {IconButton, Tooltip, Typography} from "@material-ui/core";
import {makeFitImageUrl, makeFitImageUrlWithSize} from "../../../utils";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import CloudDownload from "@material-ui/icons/CloudDownload";
import ShakaPlayer from "../../../components/Letflow/ShakaPlayer";
import {makeThumbnailUrlWithSize, onImageError} from "../../Home/HomeElements/utils";
import DownloadImage from "../../../assets/img/Letflow/download.png";
import Footer from "../../Home/HomeElements/Footer";
import {addHttpsToUrl, getActiveChannel, getMainTranslation} from "../../../api-client/core/authentication/utils";
import PublicNavBar from "../../Home/HomeElements/PublicNavBar";
import analyticsInstance from "../../../analytics/analyticsInstance";

class PublicContentCreation extends Component {
  state = {
    contentCreation: null,
    fetching: true,
    channel: null,
  };

  componentDidMount = () => {
      request.contentCreation.getPublic(this.props.contentCreationId)
        .then(contentCreation => {
          analyticsInstance.visitShareContent({
            type: "Material",
            name: getMainTranslation(contentCreation, "title")
          })
          this.setState({ contentCreation, fetching: false })
        });

      if(getActiveChannel()) {
        request.channel.getPublic(getActiveChannel()).then((channel) => this.setState({channel}));
      }
  }

  downloadCreation = contentCreation => {
    if(contentCreation.binary) {
      window.open(contentCreation.binary.url, '_blank')
    }else {
      let download = url => window.open(url, '_blank')
      if((/iPhone|iPad|iPod/i.test(navigator.userAgent) || window.safari !== undefined)) {
        // safari doesnt allow window.open inside async calls
        var windowRef=window.open();
        download = url => windowRef.location = addHttpsToUrl(url);
      }
      return request.contentCreation.getDownloadLink(contentCreation.id, undefined, true)
        .then(response => download(response.url))
        .catch(GlobalSnackbar.showGenericError)
    }
  }

  render = () => {
    const { contentCreation, fetching } = this.state;

    if (!contentCreation) {
      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 215px)",
              margin: 100
            }}
          >
            <Typography variant="display2">
              {localization.get(!fetching ? "public_material.error" : "public_material.loading")}
            </Typography>
            {!fetching &&
              <Typography style={{marginTop: "10px"}}>
                {localization.get("public_material.error_extra")}
              </Typography>
            }
          </div>
        </React.Fragment>
      );
    }


      if (contentCreation){
        let materialContentDisplay;

        if (contentCreation.audio) {
          materialContentDisplay = <audio src={contentCreation.audio.url} controls/>
        }else if (contentCreation.video) {
          materialContentDisplay = (
            <ShakaPlayer
              material={contentCreation}
              video={contentCreation.video}
              autoPlay={false}
              subtitles={contentCreation.closedCaptions}
              thumbnail={contentCreation.image ? makeFitImageUrlWithSize(contentCreation, 'lg') : makeThumbnailUrlWithSize(contentCreation.video)}
              videoTitle={contentCreation.title}
              contentCreationId={contentCreation.id}
            />
          );
        }else if(contentCreation.binary ) {
          materialContentDisplay = <img style={{ width: "100%" }} src={(contentCreation.image ? makeFitImageUrlWithSize(contentCreation, window.innerWidth > 600 ? 'lg' : 'md') : DownloadImage)} onError={onImageError} alt={contentCreation.title}/>
        }else {
          materialContentDisplay = <img style={{ width: "100%" }} src={makeFitImageUrlWithSize(contentCreation, window.innerWidth > 600 ? 'lg' : 'md')} onError={onImageError} alt={contentCreation.title}/>;
        }


      return (
        <div style={{display: "flex", flexDirection: "column"}}>
          {!!this.state.channel && !!this.state.channel.image ?
            <a style={{position: "relative",zIndex: 1,marginLeft: window.innerWidth > 600 ? 65 : 20, marginTop: 10, float:"left", height: 60}} href={"/"}>
              <img style={{width: 'auto', height: "100%"}} className="navbar-image" src={makeFitImageUrl(this.state.channel, 400,50, '', 'png')} alt={`Logo - ${this.state.channel.name}`} />
            </a>
            : <PublicNavBar/>
          }
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "calc(100vh - 132px)"
            }}
          >
            <h1 style={window.innerWidth > 600 ? {} : {fontSize: "2em", padding: "0 10px"}}>{contentCreation.title}</h1>
            <div style={{ maxWidth: 700, width: contentCreation.audio ? "auto" : "100%", height: "auto" }}>{materialContentDisplay}</div>
            <h4 style={{marginTop: 20}}>{contentCreation.contentCreator.name}</h4>
            {(contentCreation.allow_download || contentCreation.binary) &&
              <Tooltip placement="top" title={localization.get("download")}>
                <IconButton onClick={() => this.downloadCreation(contentCreation)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            }
          </div>
          <Footer noSocials />
        </div>
      )
    }
  };
}

export default PublicContentCreation;
