import moment from "moment";
import 'moment/locale/es';
import React from "react";
import styled from "styled-components";
import { addHttpsToUrl } from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import { AddAlertOutlined } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { mapLanguages} from "../../../layouts/Home/HomeElements/utils";
import {ReactComponent as Tickets} from "../../Letflow/SvgIcons/tickets.svg";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from "../../../components/CustomButtons/Button";
import "./EventSchedule.css";

export default class EventSchedule extends React.Component {
  
  constructor(props) {
    super(props);
    moment.locale(localization.getLanguage());
    this.state = {
      events: this.props.events.sort((d1,d2) => d1.date.isBefore(d2.date) ? -1 : 1),
      maxPrevDates: this.props.maxPrevDates ? this.props.maxPrevDates : 2,
      maxNextDates: this.props.maxNextDates ? this.props.maxNextDates : 2,
    }
  } 

  getNextEventIndex = () => {
    let today = moment();
    let index = this.state.events.findIndex(d => {
      let endDate = d.date.clone();
      return endDate.add(d.duration, 'hour').isAfter(today)
    });
    if (index == -1) {
      index = this.state.events.length;
    }
    return index;
  }

  renderItem = (eventDate, key, style) => {
    let button = eventDate.button;
    let hasButton = button && button.text && button.text.length && button.link && button.link.length;
    let today = moment();
    let endDate = eventDate.date.lang(localization.getLanguage()).clone().add(eventDate.duration, 'hour');

    let hasEventStarted = endDate.isBefore(today);

    const date = eventDate.date.format("YYYY-MM-DD HH:mm")
    let options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    let event = new Date(date.replace(/-/g, "/"));
    let formattedDate = event.toLocaleDateString(mapLanguages(localization.getLanguage()), options);
    formattedDate = formattedDate.replace(/,/g, " -").replace(/ de/g, "").concat('hs'); //Weekday: long adds 'de's for date

    let startedStyle = {...style, background: "#e3aaac"};
    let activeStyle = {...style, background: "#b4e8ba"};

    let isMobile = window.innerWidth < 650;
    return (
      <EventDate key={key} style={hasEventStarted ? startedStyle : activeStyle}>
        <div style={hasEventStarted ? {display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "100%", padding: isMobile ? 0 : "0 5px"}: {display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "100%", padding: isMobile ? 0 : "0 5px"}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {(!isMobile || !eventDate.clarification)?
              <div style={hasEventStarted ? {textDecoration: 'line-through', opacity: "0.5", textTransform: "capitalize", flexShrink: 0}: {textTransform: "capitalize", opacity: "0.6", flexShrink: 0}}>{formattedDate}</div>:
              <Tooltip title={eventDate.clarification} >
                <div style={hasEventStarted ? {textDecoration: 'line-through', opacity: "0.5", textTransform: "capitalize", flexShrink: 0}: {textTransform: "capitalize", opacity: "0.6", flexShrink: 0}}>{formattedDate}</div>
              </Tooltip>
            }
            {eventDate.clarification && !isMobile && <div style={hasEventStarted ? {textDecoration: 'line-through', opacity: "0.5", marginLeft: 20, flexShrink: 1}: {opacity: "0.6", marginLeft: 20, flexShrink: 1}}>{`(${eventDate.clarification})`}</div>}
          </div>
          <div style={{display: 'flex', alignItems: 'center', width: 75, justifyContent: 'flex-start'}}>
            {!hasEventStarted &&
              <CalendarSwitch
                id={`ticket-switch-${key}`}
                onChange={(e) => {
                  let value = e.target.checked;
                  eventDate._added = value;
                  if (value) setTimeout(() => window.open(this.getGoogleCalendarUrl(eventDate), "_blank"), 500);
                }}
                defaultChecked={false}
                currentValue={!hasEventStarted && eventDate._added}
                disabled={hasEventStarted}
              />
            }
            {!hasEventStarted && hasButton && 
              <Tooltip placement="top" title={button.text}>
                <IconButton target="_blank" href={addHttpsToUrl(button.link)} rel="nofollow" className="ticket-button">
                  <Tickets style={{width: 20, height: 20, marginLeft: 2}}/>
                </IconButton>
              </Tooltip>
            }
          </div>
        </div>
      </EventDate>
    )
  }
  
  formatTime(date) {
    let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
    return formattedDate.replace("+00:00", "Z");
  }

  getGoogleCalendarUrl = (eventDate) => {
    let endDate = eventDate.date.clone();
    endDate = endDate.add(1, 'hours');
    let calendarEvent = {
      title: eventDate.title,
      description: eventDate.description,
      startTime: eventDate.date.format(),
      endTime: endDate.format(),
    }
    
    let calendarUrl = "https://calendar.google.com/calendar/render";
    calendarUrl += "?action=TEMPLATE";
    calendarUrl += "&dates=" + this.formatTime(calendarEvent.startTime);
    calendarUrl += "/" + this.formatTime(calendarEvent.endTime);
    calendarUrl += "&text=" + encodeURIComponent(calendarEvent.title);
    calendarUrl += "&details=" + encodeURIComponent(calendarEvent.description);
    
    return calendarUrl;
  } 

  render = () => {
    let {events, maxNextDates, maxPrevDates} = this.state;
    let nextIndex = this.getNextEventIndex();
    let prevIndex = nextIndex-maxPrevDates;

    const eventComponent = <EventsContainer style={this.props.additionalStyle ? this.props.additionalStyle: {}}>
      <EventsHeader>
        {localization.get("content_creator.event_modal_title")}
      </EventsHeader>
      <EventsContent style={events.length > 0 ? {maxHeight: 'calc(1.2em + 159px + 10px)', overflowX: 'auto', width: "100%"} : {}}>
        {(events.length > 0) ?
          <>
            {events.slice(prevIndex >= 0 ? prevIndex : 0, nextIndex).map((d, index) => this.renderItem(d, index, {color:"black"}))}
            {events.slice(nextIndex, events.length).map((d, index) => this.renderItem(d, index, {color: 'black'}))}
          </>
          :
          (<div>{localization.get("content_creator.event_modal_empty")}</div>)
        }
      </EventsContent>
    </EventsContainer>

    if (window.innerWidth < 650) { 
      const fontColor = this.props.fontColor ? this.props.fontColor : "black"
      const backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : "white"
      const text = this.props.text ? this.props.text : localization.get("events")

      return <div style={{
        width: "100%",
         display: "flex",
          justifyContent: "center",
          maxWidth: "100%",
          flexDirection: "column",
          alignItems: "center",
          }}>
        <Dialog PaperProps={{ style: { margin: "30px 10px", width: "90vw"} }} maxWidth="xl" open={this.state.showEventsModal} onBackdropClick={() => this.setState({showEventsModal: false})} onEscapeKeyDown={() => this.setState({showEventsModal: false})}>
          <DialogContent style={{ padding: "24px 10px" }}>
            {eventComponent}
          </DialogContent>
        </Dialog>
        <Button style={{ backgroundColor, color: fontColor, borderRadius: 20 }} onClick={() => this.setState({showEventsModal: true})}>
          {text}
        </Button> 
      </div>
    }

    return eventComponent;
  };
}

const EventsContainer = styled.div`
  // background-image: linear-gradient(to bottom,#918e8e40,darkgray);
  // border: 1px solid grey;
  border-radius: 5px;
  color: black;
  margin-right: 20px;
  max-width: 100%;
  width: fit-content;
  min-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 650px) {
    min-width: 100%;
  }
`;
const EventsHeader = styled.div`
  background-color: #ececec;
  width: fit-content;
  min-width: 70%;
  font-size: 1.1em;
  text-align: center;
  padding: 6px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
  box-shadow: 0 7px 7px #ccc inset;
  font-weight: bold;
`;
const EventsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const EventDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 4px -2px;
  // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5)
  border-radius: 10px;
  min-height: 40px;
`

class CalendarSwitch extends React.Component {
  render() {
    return (
      <div className="calendar-toggle-switch" style={{paddingLeft: 5, paddingRight: 5}}>
        <input
          type="checkbox"
          name={"calendar-switch"}
          className="calendar-toggle-switch-checkbox"
          id={this.props.id}
          checked={this.props.currentValue}
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
        {this.props.id ? (
          <Tooltip placement="top" title={localization.get("button.add_to_google_calendar")}>
            <label className="calendar-toggle-switch-label" htmlFor={this.props.id}>
              <span
                className={
                  this.props.disabled
                    ? "calendar-toggle-switch-inner calendar-toggle-switch-disabled"
                    : "calendar-toggle-switch-inner"
                }
                data-yes={this.props.Text[0]}
                data-no={this.props.Text[1]}
              />
              <span
                className={
                  this.props.disabled
                    ? "calendar-toggle-switch-switch calendar-toggle-switch-disabled"
                    : "calendar-toggle-switch-switch"
                }
                ><AddAlertOutlined style={{color: "black", fontSize: 18}}/>
              </span>
            </label>
          </Tooltip>
        ) : null}
      </div>
    );
  }
  // Set text for rendering.
  static defaultProps = {
    Text: ["yes", "no"]
  };
}
