import React, {Component} from "react";
import {userCanRequestAllClients} from "../../../api-client/core/authentication/utils";
import {request} from "../../../api-client";
import {Chip} from "@material-ui/core";

class FilterByClient extends Component {

  static makeAvailablePerClientFromPaginatedResult = requestResult => requestResult.data.filter(x => x.client).reduce((acc, next) => ({ ...acc, [next.client.id]: acc[next.client.id] ? acc[next.client.id] + 1 : 1 }), {})

  constructor(props) {
    super(props)
    this.state = { clients: [], selected: null, }
  }

  componentDidMount = () => {
    userCanRequestAllClients() && request.client.getAll().then(clients => this.setState({ clients }))
  }

  render = () => {

    const { clients, selected } = this.state
    const { onRequestChange, requestAll, requestForClient } = this.props

    if (clients.length === 0) {
      return null
    }

    const makeLabel = client => client.name

    return (
      <div style={{ margin: '20px 0', display: 'flex', flexWrap: 'wrap' }}>{clients.map(client =>
        <Chip
          style={{
            margin: '2px',
            backgroundColor: selected === client && '#3C4858',
            color: selected === client && 'white'
          }}
          label={makeLabel(client)}
          onClick={() => {
            if (selected === client) {
              this.setState({ selected: null })
              onRequestChange(requestAll)
            } else {
              this.setState({ selected: client })
              onRequestChange(requestForClient(client))
            }
          }}
        />)}
      </div>
    )

  }

}

export default FilterByClient