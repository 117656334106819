import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client';
import moment from 'moment';
import localization from '../../../config/localization';
import {makeUrlWithTableParams} from "../../../utils";

const states = [
  {id: 'trying', name: localization.get('trial.state.trying')},
  {id: 'dealing', name: localization.get('trial.state.dealing')},
  {id: 'dealt', name: localization.get('trial.state.dealt')},
  {id: 'not_dealt', name: localization.get('trial.state.not_dealt')},
]

const getStateName = stateId => states.find(state => state.id === stateId).name

class TrialsTable extends Component {

  render = () => {
    return (
      <div>
        <ServerSidePaginationTable
          ref={r => this.table = r}
          columns={this.makeTableColumns()}
          requestTableData={paginationConfig => request.trial.getAll(paginationConfig)}
        />
      </div>
    )
  }

  makeTableColumns = () => {

    const columns = []

    const nameColumn = {
      Header: localization.get('table.name'),
      id: "name",
      accessor: trial => trial.user ? trial.user.name : '-',
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const emailColumn = {
      Header: localization.get('table.email'),
      id: "email",
      accessor: trial => trial.user ? trial.user.email : '-',
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const stateColumn = {
      Header: localization.get('table.state'),
      id: "state",
      accessor: trial => getStateName(trial.state),
      Filter: ({filter, onChange}) =>
        <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected/>
          {states.map(state => {
            return <option value={state.id}>{state.name}</option>
          })}
        </select>,
    }

    const creationColumn = {
      id: 'created_at',
      Header: localization.get('table.date'),
      accessor: trial => moment(trial.created_at).locale('es').format('ll'),
      resizeable: false,
      filterable: false,
    }

    const actionsColumn = {
      Header: "Acciones", id: 'actions', sortable: false, filterable: false, accessor: trial => {
        const builder = new TableButtonsBuilder()
        builder
          .withEdit(makeUrlWithTableParams(`/trials/${trial.id}/edit`))
          .withDelete(trial.user && trial.user.email, () => request.trial.delete(trial.id), status => {
            if (status === 'passed') {
              this.table.removeById(trial.id)
            }
          })
        return builder.build()
      }
    }

    columns.push(nameColumn)
    columns.push(emailColumn)
    columns.push(stateColumn)
    columns.push(creationColumn)
    columns.push(actionsColumn)

    return columns
  }
}

export default TrialsTable