import React from "react";
import localization from "../../../../config/localization";

import {
    setPageTitle
} from "../../../../api-client/core/authentication/utils";
import { Work } from "@material-ui/icons";
import ContentCreationList from "./ContentCreationList";
import queryString from 'querystring';

export default class extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        wizardStep: null,
      }
    }
  
    componentDidMount = () => {
      setPageTitle(localization.get('title.talent_contents'))
      document.getElementsByTagName('html')[0].scrollTop = 0;
      let wizardStep = queryString.parse(window.location.search)['?wizardStep'];
      this.setState({wizardStep: wizardStep});
    }

    render = () => {
      return (
        <div className="talent-profile-section">
          <div className="talent-profile-section-title" style={ this.state.wizardStep ? {justifyContent: 'space-between'} : {}}>
            {this.state.wizardStep && <div className="talent-profile-section-title-label">{localization.get("talent_profile_wizard_step", this.state.wizardStep)}</div>}
            <div className="talent-profile-section-title">
              <div className="talent-profile-section-title-logo">
                <Work style={{ fontSize: "16px", color: "#676767" }} />
              </div>
              <div className="talent-profile-section-title-label">{localization.get("talent_profile.contents")}</div>
            </div>
          </div>
          <div style={{marginTop: '20px'}}>
            <ContentCreationList/>
          </div>
        </div>
      );
    }
}