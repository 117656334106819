import React, {Component} from "react";
import {Add, ArrowDropDown, ArrowDropUp, Remove, Done, Edit, Save, Search, FormatListNumbered, Info} from "@material-ui/icons";
import localization from "../../../../config/localization";
import ClientSidePaginationTable from "../../../../components/Letflow/Table/ClientSidePaginationTable";
import {TableButton, TableButtonsBuilder} from "../../../../components/Letflow/Table";
import {request} from "../../../../api-client";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import arrayMove from "array-move";
import {getStoredChannel, userIsContentSupervisor} from "../../../../api-client/core/authentication/utils";
import {makeImageUrlWithSize, onImageError} from "../../../../layouts/Home/HomeElements/utils";
import {get} from "lodash"

export default class ContentCreationTable extends Component{
  constructor(props) {
    super(props);
    this.state = {
      contentCreations: props.creations,
      contentCreatorId: props.contentCreatorId,
      orderingMaterials: false,
      searchInput: ''
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.creations) {
      this.setState({contentCreations: nextProps.creations})
    }
  }

  getCreationType = (creation) => {
    if (creation.video) {
      return localization.get('video')
    }
    if (creation.audio) {
      return localization.get('audio')
    }
    if (creation.link) {
      return localization.get('link')
    }
    if (creation.binary) {
      return localization.get('pdf')
    }
    if (creation.embededContent) {
      switch (creation.embededContent.source) {
        case "youtube": return localization.get("content_creation.embeded_youtube");
        case "spotify": return localization.get("content_creation.embeded_spotify");
        case "vimeo": return localization.get("content_creation.embeded_vimeo");
        default: return localization.get('form.embed_short');
      }
    }
    if (creation.image && !creation.binary && !creation.link && !creation.video && !creation.embededContent) {
      return localization.get('image')
    }
  }

  buttons = (contentCreation) => {
    const {id, title, status} = contentCreation;

    const builder = new TableButtonsBuilder();

    
    if (["declined", "pending"].includes(status)) {
      builder
        .withOther(
          <TableButton
            title={localization.get(status == "declined" ? 'content_creation.declined' : 'content_creation.pending')}
            onClick={() => null}
          >
            <Info/>
          </TableButton>
        )
    }

    builder
      .withOther(
        <TableButton
          title={localization.get('edit')}
          onClick={() => this.props.onEditCreations(id)}
        >
          <Edit/>
        </TableButton>
      )

     
    {!userIsContentSupervisor() && !!get(getStoredChannel(), "community.can_delete_materials") &&
      builder
        .withDelete(
          title,
          () => request.contentCreation.delete(id),
          closeState => {
            if (closeState === 'passed') {
              this.refs.table.removeById(id)
            }
          }
        )
    }

    return builder.build()
  };

  onOrderChange = () => {
    const contentCreatorData = {
      id: this.props.creatorId,
      selectedContentCreations: this.state.contentCreations.map(contentCreation => contentCreation.id),
    };

    return request.channelContentCreator
      .update(contentCreatorData)
      .then(() => this.setState({orderingMaterials: false}));
  };

  render() {
    const {contentCreations} = this.state
    let isMobile = window.innerWidth < 450;
    return (          
      <React.Fragment>
        <div style={{width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{width: "100%", maxWidth: "300px"}}>
            <div style={{position: 'relative', width: "100%", margin: "0", backgroundColor: "white", border: "1px solid #ccc", borderRadius: "30px"}}>
              <Search style={{position: "absolute", top:4, left: 8, fontSize: "20px"}}/>
              <input
                style={{ border: 'none', width: "100%", background: 'none', padding: "5px 45px"}}
                autoComplete={'off'}
                value={this.state.searchInput}
                id="search-input"
                name="search-input"
                onChange={e =>
                  this.setState({
                    searchInput: e.target.value,
                    contentCreations: this.props.creations.filter(i => i.title.toLowerCase().includes(e.target.value.toLowerCase()))
                  })
                }
              />
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{display: isMobile ? 'none' : 'flex', whiteSpace: 'break-spaces', fontWeight: 'bold'}}>{localization.get("content_creations.upload_material")}<div style={{fontSize: '0.8em'}}>{localization.get("content_creations.upload_material.types")}</div></div>
            <Button
              onClick={this.props.onCreateCreations}
              style={{ height: '26px', width: '26px', minHeight: '0', backgroundColor: "black", marginLeft: "10px"}}
              variant="fab" color="primary" aria-label="Add"
            >
              <Tooltip
                title={localization.get('view.content_creation.create')}
                placement='top'>
                <Add />
              </Tooltip>
            </Button>

            {!this.state.orderingMaterials &&
              <IconButton style={{marginLeft: '10px'}} onClick={() => this.setState({orderingMaterials: true})}>
              <Tooltip placement='top' title={localization.get('reorder')}>
                <FormatListNumbered/>
              </Tooltip>
            </IconButton>
            }

            {this.state.orderingMaterials &&
              <IconButton style={{marginLeft: '10px'}} onClick={() => this.onOrderChange() }>
              <Tooltip placement={"top"} title={localization.get('store')}>
                <Save/>
              </Tooltip>
            </IconButton>
            }
          </div>
        </div>
        <div style={{border: "1px solid #ccc", borderRadius: "20px"}}>
          <ClientSidePaginationTable
            style={{width: "100%", background: "white", borderRadius: "20px", padding: "10px 0"}}
            showPagination={false}
            noDataText={(this.state.searchInput && this.state.searchInput.length > 0) ? localization.get('table.no_results') : localization.get('table.no_material_yet')}
            perPage={999}
            ref='table'
            columns={[
              {
                id: 'order',
                Cell: ({ original }) => {
                  const ReorderButton = makeReorderButtonComponent(
                    contentCreations,
                    original,
                    reorderedSelected => this.setState({contentCreations: reorderedSelected})
                  );
                  return (
                    <div style={{ display: 'grid' }}>
                      <ReorderButton Icon={ArrowDropUp} nextIndex={index => index - 1} tooltipKey='move_up'/>
                      <ReorderButton Icon={ArrowDropDown} nextIndex={index => index + 1} tooltipKey='move_down'/>
                    </div>
                  )
                },
                sortable: false,
                filterable: false,
                width: 50,
                show: this.state.orderingMaterials
              },
              {
                id: "image",
                accessor: contentCreation => (<img alt="Content creation" style={{borderRadius: '5px'}} onError={onImageError} src={makeImageUrlWithSize(contentCreation, 'md-16:9')} width={120} height={75}/>
                ),
                style: {
                  float: "left"
                },
                resizeable: false,
                sortable: false,
                filterable: false,
                width: 130
              },
              {
                Header: () =>
                  <div
                    style={{
                      textAlign:"left"
                    }}
                  >{localization.get('table.title')}</div>,
                accessor: "title",
                style: {
                  float: "left"
                },
                filterable: false,
                sortable: false
              },
              {
                Header: localization.get('table.type'),
                id: 'type',
                accessor: contentCreation => this.getCreationType(contentCreation),
                style: {
                  float: 'left',
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                Header: localization.get('table.active'),
                id: 'active',
                accessor: contentCreation => (contentCreation.active ? <Done/> : <Remove/>),
                style: {
                  float: 'left',
                },
                resizeable: false,
                sortable: false,
                filterable: false,
              },
              {
                id: 'actions',
                Header: () =>
                  <div
                    style={{
                      textAlign:"right",
                      paddingRight: '10px'
                    }}
                  >{localization.get('table.actions')}</div>,
                sortable: false,
                filterable: false,
                accessor: (contentCreation) => this.buttons(contentCreation),
                width: 145,
              }
            ]}
            data={contentCreations}
          />
        </div>
      </React.Fragment>
    );
  }
}

const makeReorderButtonComponent = (selected, original, onReorder) => ({ tooltipKey, Icon, nextIndex }) => (
  <Tooltip title={localization.get(tooltipKey)}>
    <Icon style={{ cursor: 'pointer' }} onClick={() => {
      const index = selected.indexOf(original);
      const reorderedSelected = arrayMove(selected, index, nextIndex(index));
      onReorder(reorderedSelected)
    }} />
  </Tooltip>
);