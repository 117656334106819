import moment from "moment";
import {isNullOrUndefined} from "util";

export const makeFormData = ({
  translations,
  streamId,
  startTime,
  endTime,
  videoId,
  startImageId,
  endImageId,
  fake,
  source,
  contentCreatorId,
  timer,
  showCountdown,
  hasSuggestedCarousel,
  suggestedCarouselPlaylist,
  suggestedView,
  allowedTokens,
  allowedRegions,
}) => {
  const form = new FormData();
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description)
    })
  }
  if (streamId) form.append('stream_id', streamId)
  if (startTime) form.append('start_time', moment.utc(startTime).format('YYYY-MM-DD HH:mm:ss'))
  if (endTime) form.append('end_time', moment.utc(endTime).format('YYYY-MM-DD HH:mm:ss'))
  if (videoId) form.append('videoId', videoId)
  if (startImageId) form.append('startImageId', startImageId)
  if (endImageId) form.append('endImageId', endImageId)
  if (!isNullOrUndefined(fake)) form.append('fake', fake ? 1 : 0)
  if (source) form.append('source', source)
  if (contentCreatorId) form.append('content_creator_id', contentCreatorId)
  if (timer) form.append('timer', moment.utc(timer).format('YYYY-MM-DD HH:mm:ss'))
  if (!isNullOrUndefined(showCountdown)) form.append('show_countdown', showCountdown ? 1 : 0)
  if (!isNullOrUndefined(hasSuggestedCarousel)) form.append('has_suggested', hasSuggestedCarousel ? 1 : 0)
  if (suggestedCarouselPlaylist) form.append('suggested_playlist', suggestedCarouselPlaylist.value)
  if (suggestedView) form.append('suggested_view', suggestedView)
  if (allowedTokens) form.append('allowed_tokens', allowedTokens.replaceAll(" ", "").toLowerCase())
  if (allowedRegions && allowedRegions.length > 0) allowedRegions.forEach(x => form.append("available_countries[]", x))
  if (!allowedRegions || allowedRegions.length == 0) form.append("region_restricted", 0)

  return form
};
