import React, {Component} from "react";
import {ServerSidePaginationTable, TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeUrlWithTableParams} from '../../../utils'
import localization from '../../../config/localization'
import AlbumSubcomponent from "./TableSubComponent";
import {
  getActiveClient,
  getStoredUser,
  userIsArtist,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import CatalogSelection from "../../../components/Letflow/CatalogSelection";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import queryString from "querystring";
import browserHistory from "../../../utils/browserHistory";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";
import {CloudDownload} from "@material-ui/icons";

class AlbumsTable extends Component {

  state = {
    total: '',
    catalogCategories: [],
    showAlbumsWithoutTags: false,
    albumsMusicsCount: "all"
  }

  componentDidMount = () => {
    request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }));
    let queryParams = queryString.parse(this.props.location.search)
    if (queryParams['?pending_tags']) {
      this.setState({albumsMusicsCount: 'pending_tags'})
    }
    if (queryParams['music_count']) {
      this.setState({albumsMusicsCount: queryParams['music_count']})
    }
  }

  handleAlertClosed = albumId => status => {
    if (status === 'passed') this.refs.table.removeById(albumId)
  }

  reloadTable = () => this.refs.table.forceUpdate();

  buttons = album => {

    const {
      id,
      title,
    } = album

    const editUrl = makeUrlWithTableParams(`/panel/albums/${id}/edit`)

    const buttonBuilder = new TableButtonsBuilder()

    if (userIsSupervisor() || userIsSysAdmin()) {
      buttonBuilder.withOther(
          <TableButton
            title={localization.get('download')}
            onClick={() => {
              request.album.getDownloadLink(id)
                .then(link => window.open(link, '_blank'))
            }}

          >
            <CloudDownload/>
          </TableButton>
        )
    }
    buttonBuilder.withShare('album', album.id)

    if(!userIsArtist() && !getStoredUser().artist_view) {
      buttonBuilder.withEdit(editUrl)
      buttonBuilder.withDelete(title, () => request.album.delete(id), this.handleAlertClosed(id))
    }

    return buttonBuilder.build()
  }

  columns = () => {
    let columns = [
      {
        id: 'image',
        accessor: album => <img alt="Album" src={makeImageUrlWithSize(album, 'xs')} width={50} height={50} onError={onImageError} />,
        style: {
          float: 'left',
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60
      },
      {
        Header: localization.get('table.title'),
        accessor: "title",
        Filter: ({ filter, onChange }) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            placeholder={localization.get('table.search')}
          />
        ),
        Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
      },
      {
        Header: localization.get('table.catalog'),
        id: 'catalog[name]',
        accessor: album => album.catalog ? album.catalog.name : '-',
        width: 120,
        sortable: false,
        filterable: false
      },
      {
        Header: localization.get('table.type'),
        id: 'type',
        accessor: album => localization.get(album.type),
        Filter: ({filter, onChange}) =>
          <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected/>
            <option value={'artist'}>{localization.get('artist')}</option>
            <option value={'compiled'}>{localization.get('compiled')}</option>
          </select>,
        width: 150,
      },
      {
        Header: localization.get('table.artist'),
        id: 'artist[name]',
        accessor: album => album.artist ? album.artist.name : '-',
        width: 120,
        Filter: ({ filter, onChange }) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            placeholder={localization.get('table.search')}
          />
        ),
        sortable: false
      },
      {
        id: 'actions',
        sortable: false,
        filterable: false,
        accessor: this.buttons,
        width: 150,
        Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total:  </strong>{this.state.total}</span>
      }
    ]

    if(userIsArtist() || getStoredUser().artist_view) {
      columns = columns.filter((column) => !(['album_category_id', 'type', 'visibility'].includes(column.id)))
    }

    return columns
  }

  makeSubComponent = row => <AlbumSubcomponent {...this.props} album={row.original} />

  render = () => {
    return (
      <React.Fragment>
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <RadioGroup
            name="showAlbumsWithTracks"
            value={this.state.albumsMusicsCount}
            onChange={event => {
              if (event.target.value === 'pending_tags') {
                browserHistory.push({
                  pathname: window.location.pathname,
                  search:  `?pending_tags=true`
                })
              } else {
                browserHistory.push({
                  pathname: window.location.pathname,
                  search:  `?&music_count=${event.target.value}`
                })
              }
              this.setState({ albumsMusicsCount: event.target.value }, () =>  this.refs.table.forceUpdate())
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('albums_table.show_all')} />
            <FormControlLabel value="5" control={<Radio color="default" />} label={localization.get('albums_table.five_or_less_tracks')} />
            <FormControlLabel value="2" control={<Radio color="default" />} label={localization.get('albums_table.two_or_less_tracks')} />
            <FormControlLabel value="none" control={<Radio color="default" />} label={localization.get('albums_table.no_tracks')} />
            <FormControlLabel value="pending_tags" control={<Radio color="default" />} label={localization.get('albums_table.pending_tags')} />

          </RadioGroup>
        </FormControl>
        <CatalogSelection
          catalog={this.state.catalog}
          onChange={(catalog) => this.setState({catalog}, () => this.refs.table.forceUpdate())}
          hasRelation={'has_albums'}
        />
        <div style={{width: "100%", float: "left", position: "relative"}}>
          <ServerSidePaginationTable
            ref='table'
            columns={this.columns()}
            subComponent={this.makeSubComponent}
            requestTableData={paginationConfig => {
              if (this.state.catalog) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'catalog_id', filter: this.state.catalog.value }]) };
              }
              if (this.state.albumsMusicsCount !== 'all') {
                if (this.state.albumsMusicsCount === 'pending_tags') {
                  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'pending_tags', filter: "true" }]) };
                } else {
                  paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'music_count', filter: this.state.albumsMusicsCount }]) };
                }
              }
              return request.album.getAll(paginationConfig).then((response) => {
                this.setState({total: response.meta.total})
                return response
              })
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default AlbumsTable
