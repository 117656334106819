import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery} from './utils';
import {getActiveClient} from "../authentication/utils";

const url = (host, options, include) => appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/catalogs?${include}`, {...options, alreadyHasQueryParams: true})

export default host => (options, include = includeQuery) =>
  get(url(host, options, include))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
