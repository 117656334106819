import React, {Component} from 'react'
import ViewContainer from '../ViewContainer';
import PropTypes from 'prop-types'
import Top from './Top';

class All extends Component {

  requestDeletion = id => {

    const {
      viewContainer: {
        openAsyncCommandAlert,
        reInitialize,
      }
    } = this.refs

    const promiseFactory = () => this.props.requestDeletion(id)
    const onClose = state => {
      if (state === 'passed') {
        reInitialize()
      }
    }

    openAsyncCommandAlert(promiseFactory, onClose)

  }

  render = () =>
    <ViewContainer
      ref='viewContainer'
      initializationRequest={this.props.initializationRequest}>
      <Top onAdd={this.props.onAdd} icon={this.props.viewIcon} />
      <div style= {{ marginTop: "-40px" }}>
        {this.props.children}
      </div>
    </ViewContainer>

}

All.propTypes = {
  initializationRequest: PropTypes.func,
  requestDeletion: PropTypes.func,
  children: PropTypes.any.isRequired,
  onAdd: PropTypes.func,
  viewIcon: PropTypes.object,
}

export default All