import React, {Component} from 'react';
import FormView from './FormView'
import {FormViewContainer} from '../../../components/Letflow/ViewContainer';
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import localization from "../../../config/localization";
import {getStoredUser, userIsArtist} from "../../../api-client/core/authentication/utils";
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import {get} from 'lodash';
import {request} from "api-client";
class MusicFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: true,
      showInRecents: !(userIsArtist() || getStoredUser().artist_view),
      id: null,
      code: '',
      title: '',
      audio: {src: null, file: null},
      audioAiff: {src: null, aiffFile: null},
      video: {src: null, videoFile: null},
      selectedTags: [],
      selectedAlbums: '',
      tagGroups: [],
      submitProgress: 0,
      bichannel: !(userIsArtist() || getStoredUser().artist_view),
      artist: '',
      inactiveReason: '',
      inactiveReasonDropdown: 'other',
      owner: (userIsArtist() || getStoredUser().artist_view) ? 'artist' : '',
      addAudioAiff: false,
      addVideo: false,
      waveformId: null,
      albumsRadio: '',
      disableForm: false,
      loaded: false,
      musicUploadRadio: 'filepond',
      selectedBatchAudioLink: '',
      selectedBatchAudioFile: '',
      client: null,
      availableClients: [],
      catalogId: null,
      mainTagGroups: [],
      taggingSystem: "main",
      tagSystem: null,
      selectedMainTags: [],
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevState.catalogId !== this.state.catalogId && this.state.catalogId) {
      request.catalog.get(this.state.catalogId)
        .then(catalog => this.setState({tagSystem: get(catalog,"musicDealer.tagSystem")}))
        .then(() => this.state.tagSystem && this.props.requestTagGroups(this.state.tagSystem.id))
    }
  }

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
      }))
    }))
  }

  setTagGroups = (tagGroups, isMain = false) => 
      this.setState(state => ({
        ...state,
        ...(isMain ? 
          {mainTagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)} :
          {tagGroups: this.updateTagGroups(tagGroups, this.state.selectedTags)}
        ),
        loaded: true,
      }))

  setMusic = music => {
    const selectedTags = music.tags.map(tag => tag.id)

    this.setState(state => ({
      ...state,
      id: music.id,
      active: music.active,
      showInRecents: music.show_in_recents,
      code: music.code ? music.code.toString() : '',
      title: music.title,
      audio: music.audio ? {
        src: music.audio.url,
        file: {
          name: music.audio.original_name
        }
      }: {src: null, file: null},
      waveformId: (music.audio && music.audio.waveform) && music.audio.waveform.id,
      selectedTags,
      selectedMainTags: selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags),
      selectedAlbums: music.albums.length > 0 && {value: {albumId: music.albums[0].id, catalogId: music.catalog && music.catalog.id}, label: music.albums[0].title},
      bichannel: music.bichannel,
      artist: (music.artist) ? {value: music.artist.id, label: music.artist.name} : '',
      inactiveReason: music.inactive_reason,
      inactiveReasonDropdown: ["Exclusividad", "En Uso", "Sotano", "Observada"].includes(music.inactive_reason) ? music.inactive_reason : "Otros",
      owner: music.owner,
      client: null,
      audioAiff: {
        src: music.audioAiff && music.audioAiff.url,
        aiffFile: {
          name: music.audioAiff && music.audioAiff.original_name
        }
      },
      addAudioAiff: !!music.audioAiff,
      video: {
        src: music.video && music.video.url,
        videoFile: { name: music.audioAiff && music.audioAiff.name }
      },
      addVideo: !!music.video,
      albumsRadio: music.albums.length !== 0 && music.albums[0].type,
      music: music,
      catalogId: music.catalog && music.catalog.id,
      tagSystem: get(music, 'catalog.musicDealer.tagSystem'),  
    }))
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  onChange = e => this.setState({[e.target.id]: e.target.value})

  onAudioChanged = audio =>
    audio ?
      this.setState({waveformId: null, audioId: audio.id, audio: {src: null}}) :
      this.setState({waveformId: null, audioId: null, audio: {src: null}})

  onAudioAiffChanged = audio =>
    audio ?
      this.setState({audioAiffId: audio.id, audioAiff: {src: null}}) :
      this.setState({audioAiffId: null, audioAiff: {src: null}})

  onVideoChange = video =>
    video ?
      this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)})
      : this.setState({videoId: null, video: {src: null}})

  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)
  
  onSelectedTagsChanged = (selectedTags, isMain = false) => 
      this.setState({
      ...(!isMain ? 
        {tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags), selectedTags: selectedTags} :
        {mainTagGroups: this.updateTagGroups(this.state.mainTagGroups, selectedTags), selectedMainTags: selectedTags}
      ),
    })
  

  onSelectedAlbumsChanged = selectedAlbums => this.setState({selectedAlbums, catalogId: selectedAlbums.catalogId})

  onAlbumsRadioChange = e => this.setState({albumsRadio: e.target.value, selectedAlbums: ''})

  onAsyncArtistChange = (artist) => this.setState({artist})

  onMusicUploadRadioChange = (e) => this.setState({musicUploadRadio: e.target.value})

  onClientChange = (e) => this.setState({client: parseInt(e.target.value), selectedAlbums: ''})


  onBatchAudioLinkChange = (selectedBatchAudioLink) => {
    this.setState({selectedBatchAudioLink: '', selectedBatchAudioFile: ''}, () => this.setState({selectedBatchAudioLink}))
  }

  handleSubmitProgress = (current, total) => this.setState({submitProgress: Math.floor(current / total * 100)})

  submitRequest = () => {
    let mergedTags = this.state.selectedMainTags;
    
    if (this.state.tagSystem) {
      mergedTags = this.state.selectedMainTags.concat(this.state.selectedTags);
    }
    

    if (!this.state.active) {
      this.state.inactiveReason = this.state.inactiveReasonDropdown != "Otros" ? this.state.inactiveReasonDropdown : this.state.inactiveReason;
    }

    return this.props.submitRequest({...this.state, selectedTags: [...new Set(mergedTags)]})
  }

  onSubmitValidation = () => {
    if (userIsArtist() || getStoredUser().artist_view) {
      return true
    } else if (this.state.selectedMainTags.length === 0) {
      GlobalSnackbar.show({message: localization.get('music.must_have_tags'), type: GlobalSnackbarTypes.ERROR})
      return false
    } else if (!this.state.audioId && !this.state.selectedBatchAudioFile && !this.state.id) {
      GlobalSnackbar.show({message: localization.get('music.must_have_audio'), type: GlobalSnackbarTypes.ERROR})
      return false
    }
    if (!this.state.artist) {
      GlobalSnackbar.show({message: localization.get('must_complete_artist'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }

  render = () => {
    const {
      id, code, title, audio, tagGroups, selectedAlbums, active,
      showInRecents, submitProgress, bichannel, artist, inactiveReason,
      owner, addAudioAiff, audioAiff, waveformId,
      addVideo, video, albumsRadio, client, availableClients, mainTagGroups,
      taggingSystem, tagSystem
    } = this.state

    return (
      <FormViewContainer
        title={this.props.title}
        submitRequest={this.submitRequest}
        validation={this.onSubmitValidation}
        onBack={() => this.props.history.goBack()}
        initializationRequest={() => this.props.initializationRequest()}
        onRequestingStateChanged={value => this.setState({disabled: value})}
        disabled={this.state.disableForm}
      >
        {this.state.loaded &&
        <FormView
          disabled={this.state.disabled}
          active={active}
          showInRecents={showInRecents}
          id={id}
          code={code}
          title={title}
          audio={audio}
          tagGroups={tagGroups}
          onAudioChanged={this.onAudioChanged}
          onChange={this.onChange}
          onActiveChange={active => this.setState({active})}
          selectedAlbums={selectedAlbums}
          bichannel={bichannel}
          onBichannelChange={bichannel => this.setState({bichannel})}
          onShowInRecentsChange={showInRecents => this.setState({showInRecents})}
          onSelectedItemsChanged={this.onSelectedAlbumsChanged}
          onSelectedTagsChanged={tags => this.onSelectedTagsChanged(tags)}
          onMainSelectedTagsChanged={tags => this.onSelectedTagsChanged(tags, true)}
          artist={artist}
          submitProgress={submitProgress}
          inactiveReason={inactiveReason}
          inactiveReasonDropdown={this.state.inactiveReasonDropdown}
          owner={owner}
          onAsyncArtistChange={this.onAsyncArtistChange}
          addAudioAiff={addAudioAiff}
          onAddAudioAiff={addAudioAiff => this.setState({addAudioAiff})}
          audioAiff={audioAiff}
          onAudioAiffChanged={this.onAudioAiffChanged}
          waveformId={waveformId}
          addVideo={addVideo}
          onAddVideo={addVideo => this.setState({addVideo})}
          video={video}
          onVideoChange={this.onVideoChange}
          albumsRadio={albumsRadio}
          onAlbumsRadioChange={this.onAlbumsRadioChange}
          onSetPreview={this.setPreview}
          disableForm={this.state.disableForm}
          updateDisableForm={value => this.setState({disableForm: value})}
          musicUploadRadio={this.state.musicUploadRadio}
          onMusicUploadRadioChange={this.onMusicUploadRadioChange}
          onBatchAudioLinkChange={this.onBatchAudioLinkChange}
          selectedBatchAudioLink={this.state.selectedBatchAudioLink}
          onBatchAudioFileChange={selectedBatchAudioFile => this.setState({selectedBatchAudioFile})}
          selectedBatchAudioFile={this.state.selectedBatchAudioFile}
          client={client}
          availableClients={availableClients}
          onClientChange={this.onClientChange}
          mainTagGroups={mainTagGroups}
          taggingSystem={taggingSystem}
          tagSystem={tagSystem}
        />
        }
      </FormViewContainer>

    )
  }
}

export default MusicFormContainer
