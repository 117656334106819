import React, { Component } from "react";
import localization from '../../../config/localization';
import { request } from "api-client";
import Form from './Form';

export default class UpdateAd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ad:  null,
    } 
  }

  componentDidMount() {
    request.ads.get(this.props.match.params.id)
      .then(ad => {
        this.setState({ad: ad})
        this.refs.form.setAd(ad)}
      );
  }

  requestUpdate = (state) => {
    const {
      name,
      link,
      duration,
      type,
      fileId,
      mobileImageId,
    } = state;

    return request.ads.update({
      id: this.props.match.params.id, 
      name,
      link,
      duration,
      type,
      fileId,
      mobileImageId
    }).then(() => this.props.history.goBack());
  }

  render = () => {
    if (!this.state.ad) return null;

    return (
      <Form
        {...this.props}
        ref='form'
        title={localization.get('ad.update_ad')}
        submitRequest={this.requestUpdate}
      />
    );
  }
}
