import {post} from '../utils'
import {getActiveChannel, getContentCreationFavorites, setContentCreationFavorites} from "../authentication/utils";

export default host => ( id ) =>
  post(`${host}/channels/${getActiveChannel()}/contentCreations/${id}/favorite`)
    .then(() => {
      const favs = getContentCreationFavorites();
      favs.push(id);

      setContentCreationFavorites(favs);
    })
