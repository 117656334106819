import {post} from '../utils'

export default host => (packId, {
  name,
  productIds,
  imageId,
}) => {
  return post(`${host}/contentPacks/${packId}`,
    JSON.stringify(makeUpdateJson(name, productIds, imageId)))
    .then(response => response.json())
}

const makeUpdateJson = (name, productIds, imageId) => {
  const json = {
    "name": name,
    "product_ids": productIds,
    "_method": "PUT",
  };

  if(imageId) json.imageId = imageId;

  return json
}