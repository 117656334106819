import React, {Component} from "react";
import {Menu, MenuItem} from "@material-ui/core";
import LicenseListingElement from "./LicenseListingElement";
import {getMostImportantAlbum, makeWaveformUrl} from "../../../../../utils";
import {request} from "../../../../../api-client";
import {makeImageUrlWithSize} from "../../../HomeElements/utils";
import GlobalSoundBar from "../../../../../sound-bar/GlobalSoundBar";
import GlobalConfirmationDialog from "../../../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import history from "../../../../../utils/browserHistory";
import browserHistory from "../../../../../utils/browserHistory";
import GlobalLicenseWizardDialog from "../../../../../components/Letflow/Dialog/GlobalLicenseWizardDialog";
import {
  Exclusivity,
  InternetBudgets,
  LicenseTypeIds,
  MediaTypeIds,
  SadaicCategories,
  SadaicDurations,
} from "../../../../../components/Letflow/LicenseWizard/utils/constants";
import {extentIdToRegionIdAndCountriesForRegion} from "../../../../../components/Letflow/LicenseWizard/utils/mappers";
import LicenseWizard from "../../../../../components/Letflow/LicenseWizard/LicenseWizard";
import {
  homePathGenerator,
  userCanDownloadTracks,
  userCanViewLicenses
} from "../../../../../api-client/core/authentication/utils";
import EventManager, {events} from "../../../../../utils/EventManager";
import localization from "../../../../../config/localization"

class LicenseListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadMenuAnchor: null,
      selectedLicense: null,
      licenseToRenew: this.props.licenseToRenew
    };
  }

  render = () => {
    if (this.state.licenseToRenew) {
      this.openLicenseWizard(this.props.licenseToRenew)
    }

    return (
      <div className="home-licenses-container">
        {this.makeDownloadMenu()}
        {this.props.licenses.map(license => {
          const album = getMostImportantAlbum(license.music.albums);
          return (
            <LicenseListingElement
              key={license.id}
              license={license}
              onClick={() => browserHistory.push(window.location.pathname + `/${license.id}`)}
              onPlay={() => {
                GlobalSoundBar.loadSingleTrackAndPlay({
                  fetchAudioUrl: () => request.music.audioUrl(license.music.id),
                  title: license.music.title,
                  subtitle: album && album.title,
                  image: album && makeImageUrlWithSize(album, 'xs'),
                  waveform: makeWaveformUrl(license.music),
                  onAnyClick: () => history.push(homePathGenerator(`album/${album.id}`, license.client)),
                });
              }}
              onDownload={e => {
                this.setState({ selectedLicense: license });
                this.openDownloadMenu(e.currentTarget);
              }}
              onUploadReceipts={() => {
                GlobalConfirmationDialog.showFileUploader(
                  "Comprobante",
                  "Seleccione el comprobante a subir",
                  "image/*",
                  file =>
                    request.license.uploadReceipt(license.id, file).then(() => {
                      this.props.requestLicenses();
                      EventManager.getInstance().dispatch(events.UPDATE_HOME_NOTIFICATIONS);
                    })
                );
              }}
              onRenewLicense={() => this.openLicenseWizard(license)}
            />
          );
        })}
      </div>
    );
  };

  openLicenseWizard = license => {
    const album = getMostImportantAlbum(license.music.albums);
    const exclusivity =
      license.exclusivity &&
      license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL &&
      license.medias.length === 1 &&
      license.medias.map(x => x.id).includes(MediaTypeIds.INTERNET)
        ? Exclusivity.THREE_MONTHS
        : Exclusivity.NONE;

    const sadaicDuration =
      license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA && license.duration
        ? license.duration == SadaicDurations.SIX_MONTHS
        ? SadaicDurations.SIX_MONTHS
        : SadaicDurations.TWELVE_MONTHS
        : SadaicDurations.SIX_MONTHS;

    const sadaicVersions = LicenseWizard.makeDefaultSadaicVersions().map((version, i) => {
      const licenseVersion = license.versions[i];
      if (licenseVersion) {
        version.seconds = licenseVersion.length.id;
        version.reductions = licenseVersion.reductions;
      }
      return version;
    });

    GlobalLicenseWizardDialog.show({
      track: {
        id: license.music.id,
        title: license.music.title,
        owner: license.music.owner,
        waveform: makeWaveformUrl(license.music),
        image: makeImageUrlWithSize(album, 'xs'),
      },
      client: GlobalLicenseWizardDialog.mapApiClient(license.client),
      initializeWithValues: {
        licenseType: license.type.id,
        regions: license.extents.map(extent => extentIdToRegionIdAndCountriesForRegion(extent.id)),
        medias: license.medias.map(media => media.id),
        reductions: license.reductions,
        exclusivity,
        extraDuration: license.extension,
        biChannel: license.bichannel,
        editionAccordingToSeconds: license.edition,
        clientName: license.project.customer || '',
        productName: license.project.product || '',
        campaignName: license.project.title || '',
        brandName: license.project.brand || '',
        sadaicDuration,
        sadaicCategory: license.category ? license.category.id : SadaicCategories.RESTRINGIDO_I,
        sadaicVersions,
        internetBudget: license.budgets.length > 0 ? license.budgets[0].id : InternetBudgets.UP_TO_2000,
        unlimitedReductions: license.unlimitedReductions,
      },
    });
  };

  makeDownloadMenu = () => {
    const { downloadMenuAnchor, selectedLicense } = this.state;
    if (!selectedLicense) {
      return null;
    }
    const expired = selectedLicense.state === "expired";
    const hasSheet = selectedLicense.sheet !== null;
    const hasReceipts = selectedLicense.receipts.length > 0;
    const hasContract = selectedLicense.contract !== null;
    const hasAudioAiff = selectedLicense.music && selectedLicense.music.audioAiff;
    const audioIsWav = selectedLicense.music && selectedLicense.music.audioAiff && selectedLicense.music.audioAiff.original_name.endsWith('.wav');
    const hasVersions = selectedLicense.music.versions_count > 0;

    return (
      <Menu anchorEl={downloadMenuAnchor} open={Boolean(downloadMenuAnchor)} onClose={this.closeDownloadMenu}>
        {hasSheet && (
          <a target="_blank" href={selectedLicense.sheet.url}>
            <MenuItem>{localization.get('account.license.button.download_sadaic_form')}</MenuItem>
          </a>
        )}
        {hasReceipts && (
          <a target="_blank" href={request.license.getLicenseReceiptsZipUrl(selectedLicense.id)}>
            <MenuItem>{localization.get('account.license.button.download_ticket')}</MenuItem>
          </a>
        )}
        {hasContract && userCanViewLicenses() && (
          <a target="_blank" href={selectedLicense.contract.url}>
            <MenuItem>{localization.get('account.license.button.download_licence')}</MenuItem>
          </a>
        )}
        {!expired && userCanDownloadTracks() && (
          <MenuItem
            onClick={() => {
              request.music.download(selectedLicense.music.id);
              this.closeDownloadMenu();
            }}
          >{localization.get('account.license.button.download_track')}</MenuItem>
        )}
        {!expired && userCanDownloadTracks() && hasAudioAiff && (
          <MenuItem
            onClick={() => {
              request.music.downloadAiff(selectedLicense.music.id, audioIsWav);
              this.closeDownloadMenu();
            }}
          >{localization.get('account.license.button.download_track_aiff')}</MenuItem>
        )}
        {hasVersions && userCanDownloadTracks() &&
        <MenuItem
          onClick={() => {
            window.open(request.license.downloadZip(selectedLicense.id), '_blank')
            this.closeDownloadMenu();
          }}
        >{localization.get('account.license.button.download_track_zip')}</MenuItem>
        }
      </Menu>
    );
  };

  openDownloadMenu = openingButton => {
    this.setState({ downloadMenuAnchor: openingButton });
  };

  closeDownloadMenu = () => {
    this.setState({ downloadMenuAnchor: null });
  };
}

export default LicenseListing;
