import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  contactMessageId,
  state,
  }) =>
  post(`${host}/contactMessages/${contactMessageId}`, addPutMethodToForm(makeFormData({
    state
  })))
  .then(response => response)