import React, {Component} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import {Add, AddShoppingCart, CloudDownload, Publish, PlayArrow, Remove, Tv} from "@material-ui/icons";
import {STATE_COLOR_OPACITY, StateColors} from "../utils";
import {getMostImportantAlbum, mapMediaFromApiLicense, monthDiff} from "../../../../../utils";
import browserHistory from "../../../../../utils/browserHistory";
import localization from '../../../../../config/localization'
import {
  channelPathGenerator,
  clientCanLicense,
  getActiveChannel,
  homePathGenerator,
  userIsSysAdmin
} from "../../../../../api-client/core/authentication/utils";
import {LicenseTypeIds,} from "../../../../../components/Letflow/LicenseWizard/utils/constants";
import moment from "moment";
import {Link} from "react-router-dom";
import "../HomeLicenses.css";
import {makeImageUrlWithSize, onImageError} from "../../../HomeElements/utils";

class LicenseListingElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: this.isMobile() ? true : false,
      isExpanded: false,
    };
  }

  // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  isMobile = () => {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  render = () => {
    const {license, onClick, onDownload, onPlay, onUploadReceipts, onRenewLicense} = this.props;
    const {hover} = this.state;

    return (
      <>
        <div
          className="home-license-element"
          style={{margin: "5px 10px", display: "flex", justifyContent: "space-between", cursor: "pointer"}}
          onMouseOver={() => this.toggleHover(true)}
          onMouseLeave={() => this.toggleHover(false)}
          onClick={onClick}
        >
          <div>
            {this.state.isExpanded ?
              <IconButton onClick={(e) => {
                e.stopPropagation();
                this.setState({isExpanded: false})
              }} style={{height: '40px', width: '40px', top: 5, color: "black"}}>
                <Remove/>
              </IconButton>
              :
              <IconButton onClick={(e) => {
                e.stopPropagation();
                this.setState({isExpanded: true})
              }} style={{height: '40px', width: '40px', top: 5, color: "black"}}>
                <Add/>
              </IconButton>
            }
          </div>
          <div style={{display: "flex", flex: 1, alignItems: "center"}}>
            {this.makeExpirationElement(license)}
            {license.music.albums.length > 0 && this.makeLicenseImage(license)}
            <div style={{marginLeft: "10px"}}>
              <div style={{color: "black"}}>{license.music.title}</div>
              <div style={{fontSize: 10, color: "dimgray"}}>
                {license.music.albums.length > 0 && getMostImportantAlbum(license.music.albums).title}
              </div>
            </div>
          </div>
          <div style={{display: "flex", opacity: hover ? 1 : 0.3}}>
            {this.canUploadReceipt() && this.makeButton(localization.get('account.license.button.file_upload'), Publish, onUploadReceipts, "red")}
            {(clientCanLicense() || userIsSysAdmin()) && this.makeButton(localization.get('account.license.button.re_license'), AddShoppingCart, onRenewLicense)}
            {this.makeButton(localization.get('account.license.button.download'), CloudDownload, onDownload)}
            {this.makeButton(localization.get('account.license.button.materials'), Tv, () => browserHistory.push(getActiveChannel() ? channelPathGenerator(`account/licenses/${license.id}`) : homePathGenerator(`account/licenses/${license.id}`, license.client)))}
            {this.makeButton(localization.get('account.license.button.play'), PlayArrow, onPlay)}
          </div>
        </div>
        {this.state.isExpanded &&
          this.makeLicenseElementSubcomponent(license)
        }
      </>
    );
  };

  canUploadReceipt = () => {
    const {license, onUploadReceipts} = this.props;
    return (
      onUploadReceipts &&
      (license.state === "requested" || license.state === "accepted") &&
      license.sheet !== null &&
      (!license.receipts || license.receipts.length === 0)
    );
  };

  toggleHover = value => {
    if (!this.isMobile()) {
      this.setState({hover: value});
    }
  };

  makeExpirationElement = license => {
    let expirationColor;

    if (license.state === "expired") {
      expirationColor = StateColors.EXPIRED;
    } else if (false) {
      // TODO: near expiration
      expirationColor = StateColors.NEAR_EXPIRATION;
    } else {
      expirationColor = StateColors.ON_GOING;
    }

    return (
      <div style={{minWidth: "5px", minHeight: 50, backgroundColor: expirationColor, opacity: STATE_COLOR_OPACITY}}/>
    );
  };

  makeLicenseImage = license => {
    return (
      <img style={{width: 50}} src={makeImageUrlWithSize(getMostImportantAlbum(license.music.albums), 'sm')} onError={onImageError}/>
    );
  };

  makeButton = (tooltip, icon, onClick, color = undefined) => {
    const Icon = icon;
    return (
      onClick && (
        <Tooltip title={tooltip}>
          <div
            className={"home-license-element-button"}
            style={{cursor: "pointer", alignSelf: "center", width: "40px", textAlign: "center", color: "black"}}
            onClick={e => {
              e.stopPropagation();
              onClick(e);
            }}
          >
            <Icon style={{position: "relative", top: "4px", color: color}}/>
          </div>
        </Tooltip>
      )
    );
  };

  makeLicenseElementSubcomponent = license =>
    <div style={{float: "left", position: "relative", width: "98%", margin: "0 10px", border: "2px solid darkgray"}}>
      <div style={{
        float: "left",
        position: "relative",
        width: "100%",
        borderBottom: "2px solid darkgray",
        textAlign: "center",
        height: "35px",
        fontSize: "22px",
        paddingTop: "7px",
        color: "black"
      }}
      >
        {localization.get('music.license_dialog.details')} {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ? 'SADAIC' : null}
      </div>

      <div style={{float: "left", position: "relative", width: "60%", padding: "20px"}}>
        {license.starts_at &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.starts_at')}:</b>{moment(license.starts_at).format("D-MM-YYYY")}
        </div>
        }

        {license.ends_at &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.ends_at')}:</b>{moment(license.ends_at).format("D-MM-YYYY")}
        </div>
        }

        {license.music &&
        <div className="licence-listing-element-subcomponent-text">
          <b style={{
            fontSize: "16px",
            float: "left", width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.track')}:</b>
          <Link
            style={{color: "black",textDecoration: "underline"}}
            to={homePathGenerator(`music/${license.music.id}`)}
          >
            {`${license.music.title} (${license.music.code})`}
          </Link>
        </div>
        }

        {license.artist &&
        <div className="licence-listing-element-subcomponent-text">
          <b style={{
            fontSize: "16px",
            float: "left", width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.artist')}:</b>
          <Link
            style={{color: "black", textDecoration: "underline"}}
            to={homePathGenerator(`artist/${license.artist.id}`)}
          >
            {`${license.artist.name} (${localization.get(license.artist.type)})`}
          </Link>
        </div>
        }

        {license.music.albums &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{
            fontSize: "16px",
            float: "left", width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.albums')}:</b>{license.music.albums.map(album => {
          return <div style={{float: "left",marginRight: 10}}><Link style={{color: "black", textDecoration: "underline"}} to={homePathGenerator(`album/${album.id}`)}>{album.title}</Link>,</div>
        })}
        </div>
        }

        {license.client &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{
            fontSize: "16px",
            float: "left",
            width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.client')}:</b>{`${license.client.name} ${license.user ? `(${license.user.name})` : ''}`}
        </div>
        }

        {license.type &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.license_type')}:</b>{license.type.name}
        </div>
        }

        {(license.medias.length > 0 || license.type) &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.media')}:</b>{mapMediaFromApiLicense(license)}
        </div>
        }

        {license.regions.length > 0 &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{
            fontSize: "16px",
            float: "left", width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.regions')}:</b>{license.regions.map(region => (`${region.name}`)).join(", ")}
        </div>
        }

        {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA &&
        <div className="licence-listing-element-subcomponent-text" style={{color: "black"}}>
          <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.validity')}:</b>{monthDiff(license.starts_at, license.ends_at)} Meses
        </div>
        }

        {license.versions && license.versions.length > 0 &&
        <div className="licence-listing-element-subcomponent-text">
          <b style={{
            fontSize: "16px",
            float: "left", width: "200px", color: "black"
          }}>{localization.get('music.license_dialog.versions')}:</b>
          <div style={{float: "left"}}>
            {license.versions.map((version, key) =>
              <div style={{color: "black"}}>{`${key === 0 ? localization.get('music.license_dialog.comercial')
                : `${localization.get('music.license_dialog.version')} ${key}`} / ${localization.get('music.license_dialog.second')}: ${version.length.name} / ${localization.get('music.license_dialog.reductions')}: ${version.reductions}`}</div>)}
          </div>
        </div>
        }

        {license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ?
          <div className="licence-listing-element-subcomponent-text">
            <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.especial_campaign')}:</b>
            {!license.versions || license.versions.length < 3 ? localization.get('music.license_dialog.none') : license.versions.length >= 6 ? localization.get('music.license_dialog.6_or_more') : localization.get('music.license_dialog.3_or_more')}
          </div>
          : null}

        {license.unlimited_reductions || license.reductions ?
          <div className="licence-listing-element-subcomponent-text">
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px", color: "black"
            }}>{localization.get('music.license_dialog.reductions')}:</b>{license.unlimited_reductions ? localization.get('music.license_dialog.unlimited') : `${license.reductions} ${localization.get('music.license_dialog.reductions')}`}
          </div>
          : license.versions ? null :
            <div className="licence-listing-element-subcomponent-text">
              <b style={{fontSize: "16px", float: "left", width: "200px", color: "black"}}>{localization.get('music.license_dialog.reductions')}:</b>{localization.get('music.license_dialog.no_reductions')}
            </div>
        }
      </div>

      {license.project && window.innerWidth > 765 &&
      <div style={{float: "left", position: "relative", width: "40%", textAlign: "center"}}>
        <div style={{display: "inline-block", border: "2px solid darkgray", height: "200px", width: "300px",marginTop: "55px", textAlign: "left", padding: "30px 0 0 30px",backgroundColor: "lightgray"}}>
          <div className="licence-listing-element-subcomponent-text">
            <b style={{fontSize: "16px", float: "left", width: "100px", color: "black"}}>{localization.get('music.license_dialog.brand')}:</b>{license.project.brand}
          </div>
          <div className="licence-listing-element-subcomponent-text">
            <b style={{fontSize: "16px", float: "left", width: "100px", color: "black"}}>{localization.get('music.license_dialog.campaign')}:</b>{license.project.title}
          </div>
          {license.exclusivity_reason &&
            <>
              <div className="licence-listing-element-subcomponent-text">
                <b style={{
                  fontSize: "16px",
                  float: "left",
                  color: "red"
                }}>{localization.get('license_wizard.exclusivity_reason')}:</b>
              </div>
              <p style={{position: "relative", float: "left", color: "red"}}>{license.exclusivity_reason}</p>
            </>
          }
        </div>
      </div>
      }
    </div>

}

export default LicenseListingElement;
