import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  email,
  phone,
  translations,
  imageId,
  coverImageId,
  selectedTags,
  selectedContentCreations,
  socialLinks,
  isDemo = 1,
  countryId,
  observation,
  active,
  selectedCatalog,
  contentCreatorTypeId,
  unsplashCoverImage,
  unsplashProfileImage,
  startActivity
}) =>
  post(
    `${host}/public/contentCreators/demos`,
    makeFormData({
      email,
      phone,
      translations,
      imageId,
      coverImageId,
      selectedTags,
      selectedContentCreations,
      socialLinks,
      isDemo,
      countryId,
      observation,
      active,
      selectedCatalog,
      contentCreatorTypeId,
      unsplashCoverImage,
      unsplashProfileImage,
      startActivity
    })
  ).then(response => response.json());
