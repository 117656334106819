import React, {Component} from "react";

import {request} from "../../../api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";


export default class extends Component {
  state = {
    clients: [],
    client: '',
    selectedClientIds: [],
    disabled: false,
    userLibrary: false,
    selectedMusics: [],
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      subtitle: "",
    })),
  };

  requestPlaylist = () => {
    const id = this.props.match.params.id;
    return request.playlist.get(id).then(playlist => {

      const translations = AvailableLanguages.map(language => {
        const translation = playlist.translations.find(tr => tr.locale ===language)
        if (translation) {
          return {
            language: language,
            title: translation.title,
            subtitle: translation.subtitle,
          };
        }

        return  {
          language: language,
          title: "",
          subtitle: "",
        }
      })

      this.setState({
        translations: translations,
        userLibrary: playlist.user_library,
        client: playlist.client ? {value: playlist.client.id, label: playlist.client.name} : null,
        selectedMusics: playlist.musics,
      })
    });
  };

  requestClients = () => request.client.getAll().then(clients => this.setState({clients}));

  initializationRequest = () => this.requestClients().then(() => this.requestPlaylist());

  requestUpdate = () => {
    const {client, translations, userLibrary, selectedMusics} = this.state;
    return request.playlist
      .update({
        playlistId: this.props.match.params.id,
        translations: translations,
        clientId: client.value,
        userLibrary,
        musicIds: selectedMusics,
        visibility: 'public',
        clearMusics: selectedMusics.length === 0
      })
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/playlists")));
  };

  render = () => {
    const {disabled, clients, translations, userLibrary, selectedMusics, client} = this.state;

    return (
      <div>
        <FormViewContainer
          title={localization.get('playlist.update_playlist')}
          initializationRequest={this.initializationRequest}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestUpdate}
          onRequestingStateChanged={value => this.setState({disabled: value})}
          hasTranslatableInputs
        >
          <FormView
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            clients={clients}
            client={client}
            onClientChange={client => this.setState({client})}
            onSelectedItemsChanged={selectedClientIds => this.setState({selectedClientIds})}
            onUserLibraryChange={e => this.setState({userLibrary: e})}
            userLibrary={userLibrary}
            onChange={e => this.setState({[e.target.id]: e.target.value})}
            disabled={disabled}
            selectedMusics={selectedMusics}
            onSelectedMusicsChanged={selectedMusics => this.setState({selectedMusics})}
          />
        </FormViewContainer>
      </div>
    );
  };
}
