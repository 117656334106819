import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import noImage from 'assets/img/Letflow/no-image.jpg'
import {IconButton, Tooltip} from "@material-ui/core";
import {Close, CloudDownload} from "@material-ui/icons";
import localization from "../../../config/localization"
import {request} from "../../../api-client";
import {getActiveClient} from "../../../api-client/core/authentication/utils";

export default ({ onImageChange, buttonText, disabled = false, image = noImage, style = null, color = 'dark', accept = "image/*", previewOnly = false, id = null }) => {

  let imageInput

  const onChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file instanceof Blob) {
      reader.onloadend = () => { onImageChange({ src: reader.result, file }) }
      reader.readAsDataURL(file);
    }
  }

  const button = () => <div style={{ textAlign: 'center' }}>
    <Button style={previewOnly ? {display: 'none'} : style} color={color} onClick={() => imageInput.click()} disabled={disabled}>{buttonText}</Button>
  </div>

  const removeImage = () => {
    imageInput.val = ""
    onImageChange({ src: null, file: null })
    let urlElements = image.match(new RegExp('images/' + "(.*)" + '/crop'))
    if (urlElements && urlElements.length > 0) {
      let imageId = parseInt(urlElements[1])
      if (imageId) {
        request.file.image.delete(imageId)
      }
    }
  }

  return (
    <div style={{textAlign: "center"}}>
      <input ref={(r => imageInput = r)} style={{ display: 'none' }} type="file" accept={accept} onChange={onChange} />
      {button()}
      <br />
      <div style={{display: "inline-block", position: "relative"}}>
        <img
          style={{ width: '100%', borderRadius: 6, maxWidth: '200px' }}
          src={image === null ? noImage : image}
          alt="..."
        />

        {image && id &&
          <Tooltip title={localization.get('download')} placement="top">
            <IconButton
              title={localization.get('download')}
              style={{position: "absolute", width: 30, height: 30, top: -15, left: -15, backgroundColor: getActiveClient() ? "black" : "#1f1f1f"}}
              onClick={() => request.file.image.getDownloadLink(id)
                  .then(link => window.open(link, '_blank'))}
            >
              <CloudDownload style={{color: "white"}}/>
            </IconButton>
          </Tooltip>
        }
        {image && !previewOnly &&
          <Tooltip title={localization.get('delete')} placement="top">
            <IconButton
              style={{position: "absolute", width: 30, height: 30, top: -15, right: -15, backgroundColor: getActiveClient() ? "black" : "#1f1f1f"}}
              onClick={removeImage}
            >
              <Close style={{color: "white"}} />
            </IconButton>
          </Tooltip>
        }
      </div>
    </div>
  )
}
