import React, {Component} from "react";
import {ServerSidePaginationTable, TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import FilterByClient from "../../../components/Letflow/Table/FilterByClient";
import {request} from '../../../api-client'
import {
  getStoredUser,
  getStoredUserRoleId,
  ROLES,
  ROLES_IMPORTANCE, userisMultiClient, userIsSupervisor, userIsSysAdmin,
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";
import AsyncSelect from "react-select/lib/Async";
import {Dialog, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, DialogActions, DialogContent, TextField, Button} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Autorenew, GetApp, LockOpen} from "@material-ui/icons";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";

const requestUsers = paginationConfig => request.user.getAll(paginationConfig)
  .then(excludeCurrentUserFromResult)
  .then(excludeTrialUsersFromResult)
  .then(addCustomPropertiesToUsersInResult)

const excludeCurrentUserFromResult = result => {
  if (showStoreUserIfWhitelisted()) {
    return result
  }
  return ({ ...result, data: result.data.filter(user => user.id !== getStoredUser().id) })
}

const excludeTrialUsersFromResult = result => ({ ...result, data: result.data.filter(user => user.role.id !== ROLES.trialId) })

const showStoreUserIfWhitelisted = () => {
  let whitelistedEmails = process.env.REACT_APP_WHITELIST_PROFILE_EDITION
  if (!whitelistedEmails) {
    return false
  }
  whitelistedEmails = whitelistedEmails.split(',')
  return whitelistedEmails.find(whitelistedEmail => whitelistedEmail === getStoredUser().email)
}

const addCustomPropertiesToUsersInResult = result => ({
  ...result,
  data: result.data.map(user => ({
    ...user,
    canHandle: ROLES_IMPORTANCE[getStoredUserRoleId()] >= ROLES_IMPORTANCE[user.role.id],
    inactive: !user.last_login_at,
  }))
})

class UsersTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      requestTableData: this.makeInitialTableDataRequest(),
      availablePerClient: 0,
      total: '',
      channel: "",
      showActivated: "all",
      name: '',
      setPasswordDialogOpen: false,
      password: "",
      edittingUser: null,
    }
  }

  componentDidMount = () => {
    document.title = localization.get( 'title.channels') + ' - Feater'
  }


  makeInitialTableDataRequest = () => paginationConfig => {
    paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "role", filter: 'channel_user'}])}

    if(this.state.channel) {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "channel_id", filter: this.state.channel.value}])}
    }

    if(this.state.showActivated !== "all") {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "activated", filter: this.state.showActivated}])}
    }

    return requestUsers(paginationConfig)
      .then(result => {
        this.setState({ availablePerClient: FilterByClient.makeAvailablePerClientFromPaginatedResult(result) })
        return result
      })
      .then((response) => {
        this.setState({ total: response.meta.total })
        return response
      })
  }

  makeDownloadLink = () => {
    let  downloadLink = `?`

    if (this.state.channel) downloadLink = downloadLink + `channel_id=${this.state.channel.value}&`
    if (this.state.showActivated !== 'all') downloadLink = downloadLink + `activated=${this.state.showActivated}`
    if (this.state.name !== '') downloadLink = downloadLink + `name=${this.state.name}`

    request.user.getDownloadLink(downloadLink).then(url => {
      window.open(url, '_blank')
    })
  };

  onAdd = () => this.props.history.push(makeUrlWithTableParams( `/panel/channel_users/create`))

  componentDidUpdate = (_, prevState) => {
    if (prevState.requestTableData !== this.state.requestTableData) this.table && this.table.forceUpdate()
  }

  changePass = () => request.user.resetPassword(this.state.edittingUser.id, this.state.password)
    .then(() => {
      
      GlobalSnackbar.show({
        message: localization.get('password.reset_success'),
        status: GlobalSnackbarTypes.SUCCESS,
      })

      return this.setState({edittingUser: null})
    })
  
  setPasswordDialog = () => 
    <Dialog open={!!this.state.edittingUser && this.state.edittingUser.id} onEscapeKeyDown={() => this.setState({edittingUser: null})}>
        <DialogTitle>{localization.get('reset_password')}</DialogTitle>
        <DialogContent style={{width: 300}}>
          <TextField
            fullWidth
            label={localization.get('new_password')}
            value={this.state.password}
            onChange={event =>  this.setState({ password: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({edittingUser: null})}>{localization.get('cancel')}</Button>
          <Button onClick={this.changePass} >{localization.get('send')}</Button>
        </DialogActions>
    </Dialog>
  

  render = () => {

    const { requestTableData, edittingUser } = this.state

    return (
      <AllViewContainer onAdd={this.onAdd} >
        {!!edittingUser && this.setPasswordDialog()}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <div>
                  <div style={{float: "left", position: "relative", width: "100%", textAlign: "center", margin: "0 0 30px 0"}}>
                    <FormControl style={{width: "100%", float: "left", position: "relative"}}>
                      <RadioGroup
                        name="showClients"
                        value={this.state.showActivated}
                        onChange={event => {
                          this.setState({ showActivated: event.target.value })
                          this.table.forceUpdate();
                        }}
                        style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
                      >
                        <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('clients.all')} />
                        <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="activated" control={<Radio color="default" />} label={localization.get('clients.active')} />
                        <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="unactivated" control={<Radio color="default" />} label={localization.get('clients.inactive')} />
                      </RadioGroup>
                    </FormControl>
                    <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
                      <span>{localization.get('channel')}</span>
                      <AsyncSelect
                        styles={{
                          menu: base => ({
                            ...base,
                            zIndex: 10
                          })
                        }}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 5,
                        })}
                        placeholder={localization.get('conversation.filter_users')}
                        loadingMessage={() => localization.get('loading')}
                        noOptionsMessage={() => localization.get('no_options')}
                        onChange={(e) => {
                          this.setState({channel: e}, () => this.table.forceUpdate())
                        }}
                        value={this.state.channel}
                        defaultOptions
                        isClearable={true}
                        loadOptions={inputValue => {
                          let filters = [{ column: "name", filter: inputValue }]

                          return request.channel
                            .getAll({filterBy: filters})
                            .then(channels => channels.map(catalog => ({value: catalog.id, label: `${catalog.name} (${localization.get(catalog.visibility)})`})))
                            .then(channels => channels.sort((a, b) => a.label.localeCompare(b.label)))
                        }
                        }
                      />
                    </div>
                    {this.state.channel ?
                      <IconButton
                        onClick={() => this.makeDownloadLink()}
                        style={{position: "absolute", top: 0, right: -5, color: 'inherit'}}
                      >
                        <Tooltip
                          title={localization.get('download_csv')}
                          placement='top'>
                          <GetApp/>
                        </Tooltip>
                      </IconButton>
                      :
                      <IconButton
                        style={{position: "absolute", top: 0, right: -5, color: 'inherit'}}
                      >
                        <Tooltip
                          title={localization.get('download_csv.requirement')}
                          placement='top'>
                          <GetApp/>
                        </Tooltip>
                      </IconButton>
                    }
                  </div>
                  <div style={{ width: "100%", float: "left", position: "relative" }}>
                    <ServerSidePaginationTable
                      ref={r => this.table = r}
                      columns={this.makeTableColumns()}
                      requestTableData={requestTableData}
                      subComponent={this.subComponent}
                    />
                  </div>
                 </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    )
  }

  makeTableColumns = () => {

    const columns = []

    const nameColumn = {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            this.setState({name: event.target.value || ""})
          }}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const emailColumn = {
      Header: localization.get('table.email'),
      accessor: "email",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const lastLoginColumn = {
      Header: localization.get('table.last_login'),
      id: "last_login_at",
      accessor: user => user.last_login_at ? localization.moment(user.last_login_at).fromNow() : localization.get('never'),
      filterable: false,
      maxWidth: 150,
    }

      const purchaseCountColumn = {
        Header: localization.get('table.purchases'),
        id: "activePurchases_count",
        accessor: user => user.activePurchases.length,
        filterable: false,
        sortable: false,
        maxWidth: 80,
      }

    const actionsColumn = {
      Header: localization.get('table.actions'), id: 'actions', sortable: false, filterable: false, accessor: user => {
        const builder = new TableButtonsBuilder()

        
        if(userIsSupervisor() || userIsSysAdmin() || userisMultiClient()) {
          builder.withOther(
            <TableButton
              title={localization.get('reset_password')}
              onClick={() => this.setState({edittingUser: user})}
            >
              <LockOpen />
            </TableButton>
          )
        }

        if(userIsSupervisor() || userIsSysAdmin() || userisMultiClient()) {
          builder.withOther(
            <TableButton
              title={localization.get('select')}
              onClick={() =>
                GlobalConfirmationDialog.show({
                  title: localization.get("confirmation_dialog.migrate_user"),
                  content: <div dangerouslySetInnerHTML={{ __html: localization.get("confirmation_dialog.migrate_user_message", user.name) }} />,
                  request: () => request.user.makeAdmin(user.id).then(this.table.forceUpdate),
                })
              }
            >
              <Autorenew/>
            </TableButton>
          )
        }

        user.inactive && builder
          .withNotify(user.name, () => request.user.notifyChannel(user.id))
        if (!getStoredUser().can_edit_admins && getStoredUserRoleId() === ROLES.multiClientId && user.role.id === ROLES.multiClientId) {
          return builder.build()
        }
        user.canHandle && builder
          .withEdit(makeUrlWithTableParams(`/panel/channel_users/${user.id}/edit`))
          .withDelete(user.name, () => request.user.delete(user.id), status => {
            if (status === 'passed') {
              this.table.removeById(user.id)
            }
          })
        return builder.build()
      },
      minWidth: 190,
      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }


    columns.push(nameColumn);
    columns.push(emailColumn);
    columns.push(lastLoginColumn);
    columns.push(purchaseCountColumn);
    columns.push(actionsColumn)

    return columns
  }

  subComponent = (row) => {
    return (
      <React.Fragment>
        {row.original.channels.map(channel => {
          if (getStoredUserRoleId() === ROLES.supervisorId ||
            getStoredUserRoleId() === ROLES.sysAdminId
          )
            return (
              <div style={{ width: "100%", float: "left", position: "relative", padding: "20px", display:"grid", gridTemplateColumns: "repeat(6, auto)" }}>
                <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>Canal:</div>
                <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "130px" }}>{channel.channel.name}</div>
                <div style={{ float: "left", position: "relative", fontWeight: "bold" }}>{localization.get('created_at')}:</div>
                <div style={{ float: "left", position: "relative", marginLeft: "30px", width: "200px" }}>{row.original.created_at.split(' ')[0]}</div>
              </div>
            )
        })}

      </React.Fragment>
    )
  }
}

export default UsersTable
