import React from 'react'
import {FormControl, IconButton, MenuItem, Select, TextField} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import localization from "../../../config/localization";
import {request} from "../../../api-client";
import Button from "../../../components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import {Close} from "@material-ui/icons";
import {ReactComponent as Facebook } from "../../../components/Letflow/SocialIcons/Svg/facebook.svg";
import {ReactComponent as Instagram } from "../../../components/Letflow/SocialIcons/Svg/instagram.svg";
import {ReactComponent as Spotify } from "../../../components/Letflow/SocialIcons/Svg/spotify.svg";
import {ReactComponent as Youtube } from "../../../components/Letflow/SocialIcons/Svg/youtube.svg";
import {ReactComponent as Vimeo } from "../../../components/Letflow/SocialIcons/Svg/vimeo.svg";
import {ReactComponent as SoundCloud } from "../../../components/Letflow/SocialIcons/Svg/soundcloud.svg";
import {ReactComponent as Twitter } from "../../../components/Letflow/SocialIcons/Svg/twitter.svg"
import {ReactComponent as Web } from "../../../components/Letflow/SocialIcons/Svg/web.svg"
import {ReactComponent as LinkedIn } from "../../../components/Letflow/SocialIcons/Svg/linkedin.svg"
import {ReactComponent as Behance } from "../../../components/Letflow/SocialIcons/Svg/behance.svg"
import {ReactComponent as Email } from "../../../components/Letflow/SocialIcons/Svg/email.svg"
import {ReactComponent as Tiktok } from "../../../components/Letflow/SocialIcons/Svg/tiktok.svg"
import {ReactComponent as Twitch } from "../../../components/Letflow/SocialIcons/Svg/twitch.svg"
import {ReactComponent as Linktree } from "../../../components/Letflow/SocialIcons/Svg/linktree.svg"
import {ReactComponent as Apple } from "../../../components/Letflow/SocialIcons/Svg/apple.svg"
import {ReactComponent as Android } from "../../../components/Letflow/SocialIcons/Svg/android.svg"
import {ReactComponent as Telegram } from "../../../components/Letflow/SocialIcons/Svg/telegram.svg"

class SocialLinks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      availableTypes: [],
      socialLinks: (this.props.socialLinks && this.props.socialLinks.length > 0) ? this.props.socialLinks : [{type: "", url: ""}]
    }
  }

  componentDidMount = () => {
    request.socialLink.getAvailableTypes()
      .then(availableTypes => this.setState({availableTypes: availableTypes.sort((a, b) => (a < b ? -1 : a === b ? 0 : 1))}))
  };

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (nextProps.socialLinks && nextProps.socialLinks.length > 0 && this.state.socialLinks !== nextProps.socialLinks) {
      this.setState({socialLinks: nextProps.socialLinks})
    }
  };

  updateSocialLink = (index, itemAttributes) => {
    const socialLinks = [
      ...this.state.socialLinks.slice(0,index),
      Object.assign({}, this.state.socialLinks[index], itemAttributes),
      ...this.state.socialLinks.slice(index+1)
    ];
    this.setState({socialLinks});
    if (this.props.onSocialLinksChange) {
      let updateParentComponent = true;
      socialLinks.map(socialLink => {
        if (!socialLink.type || !socialLink.url) {
          updateParentComponent = false;
        }
      })
      updateParentComponent && this.props.onSocialLinksChange(socialLinks)
    }
  };

  addSocialLink = () => {
    const {socialLinks} = this.state;
    const lastSocialLink = socialLinks[socialLinks.length - 1];
    if (lastSocialLink.type && lastSocialLink.url) {
      this.setState(prevState => ({socialLinks: prevState.socialLinks.concat([{type: "", url: ""}])}))
    }
  };

  removeSocialLink = (index) => {
    if (index === 0 && this.state.socialLinks.length <= 1) {
      this.setState({socialLinks: [{type: "", url: ""}] } , () =>  this.props.onSocialLinksChange([]));
      return
    }
    const socialLinks = this.state.socialLinks.filter((socialLink, socialLinkIndex) => index !== socialLinkIndex);
    this.setState({socialLinks});
    if (this.props.onSocialLinksChange) {
      this.props.onSocialLinksChange(socialLinks)
    }
  };

  render = () => {
    const {socialLinks, availableTypes} = this.state;
    let isMobile = window.innerWidth < 450;

    return(
      <React.Fragment>
        {(!this.props.bloomCloudsView && !this.props.homeView && !this.props.formView) && <h4 className={this.props.publicView && "web-like-h4"}>{localization.get('social_links')}</h4>}
        {socialLinks.map((socialLink, index) => {
          return (
            <div style={{float: "left", position: "relative", width: "100%", marginBottom: 10}}>
              <div style={!isMobile ? {float: "left", position: "relative", width: "30%"}: {float: "left", position: "relative", width: "34%"}}>
                {this.props.publicView ?
                  <select
                    className="web-like-select"
                    style={{
                      height: 40,
                      fontSize: 14,
                      backgroundColor: "#fff"
                    }}
                    onChange={e => this.updateSocialLink(index, {type: e.target.value})}
                  >
                    <option value="" defaultChecked={true}>{localization.get('select')}</option>
                    {availableTypes.length > 0 && availableTypes.map(availableType => {
                      return <option selected={availableType === socialLink.type} value={availableType}>{availableType.capitalize()}</option>;
                    })}
                  </select>
                  :
                  (this.props.bloomCloudsView ?
                    <select
                      className="bloomclouds-select"
                      style={{
                        backgroundColor: "#fff"
                      }}
                      onChange={e => this.updateSocialLink(index, {type: e.target.value})}
                    >
                      <option value="" defaultChecked={true}>{localization.get('select')}</option>
                      {availableTypes.length > 0 && availableTypes.map(availableType => {
                        return <option selected={availableType === socialLink.type} value={availableType}>{availableType.capitalize()}</option>;
                      })}
                    </select>
                    :
                    <FormControl fullWidth>
                      <InputLabel>{localization.get('form.social_link')}</InputLabel>
                      <Select
                        style={{width: "100%"}}
                        value={socialLink.type}
                        onChange={e => this.updateSocialLink(index, {type: e.target.value})}
                      >
                        {availableTypes.length > 0 && availableTypes.map(availableType => {
                          return <MenuItem value={availableType}>{availableType.capitalize()}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  )
                }

              </div>
              <div style={!isMobile ? {float: "left", position: "relative", width: !socialLink.url && !socialLink.type && index === 0 ? '70%' : `calc(70% - ${this.props.bloomCloudsView ? '36px': '50px'})`, padding: !this.props.bloomCloudsView ? "0 20px 0 30px": "0 0 0 20px"} : {float: "left", position: "relative", width: !socialLink.url && !socialLink.type && index === 0 ? '66%' : `calc(66% - ${this.props.bloomCloudsView ? '36px': '66px'})`, paddingLeft: 20}}>
                {this.props.publicView ?
                  <input
                    id={`public-input-${index}`}
                    className="web-like-input"
                    placeholder="Url"
                    onChange={e => this.updateSocialLink(index, {url: e.target.value})}
                    onBlur={this.props.onBlur}
                    value={socialLink.url}
                    disabled={!socialLink.type}
                  />
                  :
                  (this.props.bloomCloudsView ?
                    <input
                      id={`bloomclouds-input-${index}`}
                      className="bloomclouds-input"
                      placeholder="Url"
                      onChange={e => this.updateSocialLink(index, {url: e.target.value})}
                      onBlur={this.props.onBlur}
                      value={socialLink.url}
                      disabled={!socialLink.type}
                    />
                    :
                    <TextField
                      style={{width: "100%", marginTop: 16}}
                      placeholder="Url"
                      value={socialLink.url}
                      type={socialLink.type === "email" ? "email" : "text"}
                      onChange={e => this.updateSocialLink(index, {url: e.target.value})}
                    />
                  )
                }
              </div>
              <div style={{float: "left", position: "relative", width: this.props.bloomCloudsView ? "36px" : "50px", top: this.props.publicView ? 2 : (this.props.bloomCloudsView ? 6 : 8), display: !socialLink.url && !socialLink.type && index === 0 && 'none'}}>
                <IconButton style={this.props.bloomCloudsView && {height: "36px", width: "36px"}} onClick={() => this.removeSocialLink(index)}>
                  <Close />
                </IconButton>
              </div>
            </div>
          )
        })}
        <Button
          style={{
            padding: 8,
            width: this.props.bloomCloudsView && "30%",
            backgroundColor: (this.props.publicView || this.props.homeView || this.props.bloomCloudsView) && "black",
            boxShadow: (this.props.publicView || this.props.homeView || this.props.bloomCloudsView) && "none",
            textTransform: "capitalize"
          }}
          color={this.props.buttonColor ? this.props.buttonColor : "dark"}
          onClick={() => this.addSocialLink()}
        >
          {localization.get('form.add_social_link')}
        </Button>
      </React.Fragment>
    )
  }
}

SocialLinks.propTypes = {
  socialLinks: PropTypes.array,
  publicView: PropTypes.bool,
  homeView: PropTypes.bool,
  formView: PropTypes.bool,
  bloomCloudsView: PropTypes.bool,
  onSocialLinksChange: PropTypes.func
};

export default SocialLinks

export const getSocialLinkIcon = (socialLinkType, color = "var(--footer-font-color, white)") => {
  switch (socialLinkType) {
    case "facebook":
      return <Facebook style={{width: 25, height: 25, fill: color}}/>
    case "instagram":
      return <Instagram style={{width: 25, height: 25, fill: color}}/>
    case "youtube":
      return <Youtube style={{width: 25, height: 25, fill: color}}/>
    case "spotify":
      return <Spotify style={{width: 25, height: 25, fill: color}}/>
    case "soundcloud":
      return <SoundCloud style={{width: 25, height: 25, fill: color}}/>
    case "twitter":
      return <Twitter style={{width: 25, height: 25, fill: color}}/>
    case "vimeo":
      return <Vimeo style={{width: 25, height: 25, fill: color}}/>
    case "web":
      return <Web style={{width: 25, height: 25, fill: color}}/>
    case "linkedin":
      return <LinkedIn style={{width: 25, height: 25, padding:2, fill: color}}/>
    case "behance":
      return <Behance style={{width: 25, height: 25, fill: color}}/>
    case "twitch":
      return <Twitch style={{width: 25, height: 25, padding:2, fill: color}}/>
    case "tiktok":
      return <Tiktok style={{width: 25, height: 25, padding:2, fill: color}}/>
    case "linktree":
      return <Linktree style={{width: 25, height: 25, fill: color}}/>
    case "apple":
      return <Apple style={{width: 25, height: 25, fill: color}}/>
    case "android":
      return <Android style={{width: 25, height: 25, fill: color}}/>
    case "telegram":
      return <Telegram style={{width: 25, height: 25, padding:1, fill: color}}/>
    default:
      return <Email style={{width: 25, height: 25, padding:2, fill: color}}/>
  }
};