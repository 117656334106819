import React from 'react';
import {request} from "../../../api-client";
import { Dialog, withStyles, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import { get } from 'lodash';
import FormViewContainer from '../ViewContainer/FormViewContainer';
import TranslatableInputs from '../TranslatableInputs';
import localization from "../../../config/localization";
import GlobalSnackbar, { GlobalSnackbarTypes } from '../Snackbar/GlobalSnackbar';
import TagSelection from '../TagSelection';
import { getStoredChannel } from '../../../api-client/core/authentication/utils';


const styles = {
  dialogPaper: {
      minHeight: '100vh',
      minWidth: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      backgroundColor: "rgba(0,0,0,0.8)",
      padding: window.innerWidth > 600 ? '40px' : "0px",
      height: '100vh',
      width: '100vw'
  },
  dialogPaperMobile: {
    minHeight: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#EFEFEF',
    borderRadius: '10px',
    padding: '20px',
    height: '80vh',
    fontSize: '0.8em'
  }
};

class EditContentCreationDialog extends React.Component{
  state = {
    open: get(this.props, 'open', false),
    disabled: false,
    translations: get(this.props, 'contentCreation.translations', []),
    active: get(this.props, 'contentCreation.active', true) ? "1" : "0",
    tagGroups: [],
    selectedTags: [],
  }

  setCreation = contentCreation => { 
    const languages = getStoredChannel().languages.map(lang => lang.language);
    const translations = languages.map(language => {
        const contentCreationTranslations = get(contentCreation, 'translations', []);
        const translation = contentCreationTranslations.find(tr => tr.locale ===language)

        return  {
            language: language,
            title: get(translation, "title", ""),
            description: get(translation, "description", "")
        }
    })
    const contentCreationTags = get(contentCreation, 'tags', []);
    const selectedTags = contentCreationTags.map(tag => tag.id);

    this.setState({
        id: contentCreation.id,
        translations: translations,
        active: contentCreation.active ? "1" : "0" ,
        selectedTags: selectedTags,
        requireLogin: contentCreation.require_login,
    })
  }

  componentWillReceiveProps = nextProps => {
    if(get(nextProps, "contentCreation.id") !== this.state.id) {
      this.setCreation(nextProps.contentCreation)
       request.tagGroup.getAll({filterBy: [
          { column: 'type', filter: nextProps.type},
          { column: 'inheritable', filter: '1'},
        ]})
        .then((tagGroups) => {
          const contentCreationTags = get(nextProps.contentCreation, 'tags', []);
          this.setState({tagGroups: this.updateTagGroups(tagGroups, contentCreationTags.map(tag => tag.id))})
        })
    }
  }

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

    requestUpdate = () => {
      const {
        id,
        selectedTags,
        active,
        requireLogin,
        translations,
      } = this.state;

      const contentCreationData = {
        id,
        selectedTags,
        active,
        requireLogin,
        translations,
      };
  
      return request.contentCreation.updateInClient(contentCreationData)
        .catch(console.error)
        .then(() => GlobalSnackbar.show({message: localization.get('snackbar.content_creator_updated'), type: GlobalSnackbarTypes.SUCCESS}))
        .then(this.props.onClose)
    };
  

  handleClose = () => {
    this.setCreation(this.props.contentCreation)

    this.props.onClose();
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onChangeTranslation = (value, target, language) => {
    const translations = this.state.translations.map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }
      return tr
    });

    this.setState({ translations });
  }

    render = () => {
    const { id, translations, disabled, active } = this.state;
    const languages = getStoredChannel().languages.map(lang => lang.language);

    if (!id) return <></>;

    return (
      <Dialog
        open={this.props.open} 
        // classes={{paper: this.props.classes.dialogPaper}} 
        onBackdropClick={this.handleClose}
        onEscapeKeyDown={this.handleClose}
        onClose={this.handleClose}
        disableEnforceFocus
      >
        <FormViewContainer
            title={localization.get("view.content_creation.update")}
            submitRequest={() => this.requestUpdate()}
            onBack={this.handleClose}
            onRequestingStateChanged={value => this.setState({ disabled: value })}
            hasTranslatableInputs
            validation={this.onSubmitValidation}
            disabled={this.state.disabled}
            actionsExtraSpace={true}
            sucessMessage={localization.get('snackbar.entity_update_success_on_client')}
            color={"dark"}
          >
            <TranslatableInputs
          color="dark"
          children={languages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);
            if (!currentLangTranslation) return;
            return {
              language: language,
              content: [
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`title${language}`}
                  label={localization.get('form.title')}
                  name={`title${language}`}
                  value={currentLangTranslation.title}
                  onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                  disabled={disabled}
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                />,
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`description${language}`}
                  label={localization.get('form.description')}
                  name={`description${language}`}
                  value={currentLangTranslation.description}
                  onChange={e => this.onChangeTranslation(e.target.value, "description", language)}
                  disabled={disabled}
                />
              ]
            }
          })}
        />

          <Card style={{overflow: 'visible'}}>
            <CardContent>
              <h4>{localization.get('form.availability')}</h4>
              <div style={{marginTop: 10}}>
                <FormControl>
                  <RadioGroup
                    name="active"
                    id="active"
                    value={active}
                    onChange={e => this.setState({ active: e.target.value })}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.allowed_content')}/>
                    <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.disallowed_content')}/>
                  </RadioGroup>
                </FormControl>
              </div>
            </CardContent>
          </Card>
          <br/>
          {this.state.tagGroups.length > 0 &&
            <Card>
                <CardContent>
                <TagSelection cardView data={this.state.tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged} disabled={disabled} showFilter color={"#353535"}/>
                </CardContent>
            </Card>
            }
        </FormViewContainer>
      </Dialog>
    );
  }
};

export default withStyles(styles)(EditContentCreationDialog);
