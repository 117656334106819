import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import Button from "../../../components/CustomButtons/Button";

class ContentCreations extends Component {
  state = {
    artists: [],
    perPage: 20,
    page: 0,
    openDialog: false,
    selectedContentCreator: null
  };

  componentDidMount = () => {
    document.title = localization.get("content_creations") + " - Feater";
  };

  handleCloseDialog = () => this.setState({openDialog: false})

  talentSelectionDialog = () => {
    let createUrl = ''
    if (this.state.selectedContentCreator) {
      if (this.props.isTalent) {
        createUrl = makeUrlWithTableParams(`/panel/talents/content_creations/create?contentCreator=${this.state.selectedContentCreator.value}&`);
      } else {
        createUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creations/create?contentCreator=${this.state.selectedContentCreator.value}&`);
      }
    }
    return (
     <Dialog
       open={this.state.openDialog}
       onBackdropClick={this.handleCloseDialog}
       onEscapeKeyDown={this.handleCloseDialog}
       style={{padding: 20}}
     >
       <DialogTitle>{localization.get('talent.selection')}</DialogTitle>
       <DialogContent style={{maxWidth: "100%", minHeight: 250}}>
         <FormControl fullWidth>
           <span>{localization.get('content_creator')}</span>
           <AsyncSelect
             styles={{
               menu: base => ({
                 ...base,
                 zIndex: 10
               })
             }}
             theme={theme => ({
               ...theme,
               borderRadius: 0
             })}
             name="content_creator"
             placeholder={localization.get('content_creator')}
             loadingMessage={() => localization.get('loading')}
             noOptionsMessage={() => localization.get('no_options')}
             onChange={(selectedContentCreator) => {this.setState({selectedContentCreator})}}
             value={this.state.selectedContentCreator}
             loadOptions={inputValue => {
               return request.contentCreator.getAll(
                 {filterBy: [{column: "title", filter: inputValue}, {column: "is_talent", filter: this.props.isTalent ? 1 : 0}]})
                 .then(contentCreators => contentCreators.map(contentCreator =>
                   ({value: contentCreator.id, label: `${contentCreator.name} - ${contentCreator.type && contentCreator.type.name}`})))
             }}
           />
         </FormControl>
       </DialogContent>
       <DialogActions>
         <Button onClick={this.handleCloseDialog} color='default' style={{ backgroundColor: "black" }}>
           {localization.get("cancel")}
         </Button>
         <Button
           onClick={() => this.props.history.push(createUrl)}
           color='default'
           disabled={this.state.selectedContentCreator === null}
         >
           {localization.get("send")}
         </Button>
       </DialogActions>
     </Dialog>
    )
  }


  onAdd = !this.props.isDemo && (() =>  this.setState({openDialog: true}));

  render = () => (
    <AllViewContainer onAdd={this.onAdd} ref="viewContainer">
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              {this.talentSelectionDialog()}
              <Table {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default ContentCreations;
