import { getActiveClient } from '../authentication/utils';
import {addPutMethodToForm, post} from '../utils'
import { isNullOrUndefined } from 'util';

export default host => ({
    id,
    selectedTags,
    active,
    requireLogin,
    translations,
}) => 
post(`${host}/clients/${getActiveClient()}/contentCreation/${id}`, addPutMethodToForm(makeFormData({
    id,
    selectedTags,
    active,
    requireLogin,
    translations,
})))
  .then(response => response.json())
  .then(json => json.data)


export const makeFormData = ({
    selectedTags,
    active,
    requireLogin,
    translations,
  }) => {
    const form = new FormData();
    if (!isNullOrUndefined(active)) form.append("active", active);
    if (translations) {
      translations.forEach(translation => {
        if (!isNullOrUndefined(translation.title)) form.append(`translations[${translation.language}][title]`, translation.title);
        if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description);
      });
    }
  
    if (!isNullOrUndefined(selectedTags)) {
      if(selectedTags.length > 0) {
        selectedTags.forEach(x => form.append("tags_ids[]", x));
      }else {
        form.append("clear_all", 1);
      }
    }

    // if(!isNullOrUndefined(requireLogin)) form.append("require_login", requireLogin);

    return form;
  };