import {post} from '../utils'
import {getActiveChannel} from '../authentication/utils';

export default host => ( id ) => {
  console.log(id)
  const form = new FormData()
  let channelId = getActiveChannel()
  if (channelId) {
    form.append('channel_id', channelId)
  }
  return post(`${host}/channels/${getActiveChannel()}/musics/${id}/favorite`, form)
}
