import React, {Component} from 'react'
import localization from '../../../../config/localization';
import {Delete} from '@material-ui/icons'
import TableButton from './TableButton';
import PropTypes from 'prop-types'
import GlobalConfirmationDialog from '../../Dialog/GlobalConfirmationDialog';

class TableDeleteButton extends Component {

  handleOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    GlobalConfirmationDialog.showDelete({
      elementName: this.props.itemInformation,
      deleteRequest: () => this.props.requestDelete(),
      onSuccess: () => this.props.alertClosed('passed'),
      onFail: () => this.props.alertClosed('failed'),
      doubleConfirmation: this.props.doubleConfirmation,
      auxiliaryInformation: this.props.auxiliaryInformation,
    })
  }

  render = () => {
    return (
      <span>
        <TableButton title={localization.get('tooltip.delete')} onClick={this.handleOnClick}>
          <Delete style={this.props.color ? {color: this.props.color} : {}}/>
        </TableButton>
      </span>
    )
  }
}

TableDeleteButton.propTypes = {
  itemInformation: PropTypes.string.isRequired,
  requestDelete: PropTypes.func.isRequired,
  alertClosed: PropTypes.func.isRequired,
}

export default TableDeleteButton
