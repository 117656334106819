import React, {Component} from "react";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import ContentCreator from "../../layouts/Home/ContentCreator";

class PreviewContentCreator extends Component {

  render = () => {
    return (
      <div>
        <PublicNavBar />
        <div id={"inner"}>
          <ContentCreator preview={true} contentCreatorId={this.props.match.params.contentCreatorId}/>
        </div>
        <PublicFooter {...this.props} noSocials={true} hideSupport={true}/>
      </div>
    );
  };
}

export default PreviewContentCreator;
