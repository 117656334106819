import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import localization from "../../../config/localization";

class CatalogsReport extends Component {

  componentDidMount = () => {
      document.title = localization.get('catalogs.report') + ' - Feater'
  }

  render = () =>
    <AllViewContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Table {...this.props}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default CatalogsReport;
