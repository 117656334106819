import React, {Component} from "react";
import {request} from "../../../api-client";
import ClientFormView from "../ClientForm";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";
import {getActiveSoundProducer, userisMultiClient} from "../../../api-client/core/authentication/utils";
import {EditorState} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

export default class extends Component {
  state = {
    name: "",
    type: userisMultiClient() ? "brand" : "",
    image: { src: null, file: null },
    types: [],
    disabled: false,
    cuit: "",
    address: "",
    email: "",
    country: "",
    licensePlan: '',
    excludeFromReports: false,
    demo: false,
    selectedPermissions: [],
    managedBy: (userisMultiClient() && getActiveSoundProducer()) ? {value: getActiveSoundProducer().id} : {value: null, label: "Feater"},
    prefix: '',
    reelUrl: '',
    useLogo: false,
    socialLinks: [],
    expanded: false,
    aboutEs: new EditorState.createEmpty(),
    aboutEn: new EditorState.createEmpty(),
    showAbout: false,
    keywordsSEO: '',
    titleSEO: '',
    descriptionSEO: '',
    contentCreatorTypes: [],
    selectedContentCreatorTypes: [],
    canManageClients: false,
    searchPreferences: 'content_creator',
    blacklistTypeContentCreatorTypes: '2',
    allowFakeLivestreams: false,
    secure: true,
    transcodeVideos: true,
    hasOwnTags: false,
    useAccessLevel: false,
    accessLevel: "0",
    useRekognition: false
  };

  onSubmitValidation = () => {
    if (!this.state.country) {
      GlobalSnackbar.show({message: localization.get('form.country_is_required'), type: GlobalSnackbarTypes.ERROR});
      this.setState({countryIsCompleted: false})
      return false;
    }
    return true;
  }

  requestClientCreation = () => request.client
      .create({
        ...this.state,
        type: this.state.type,
        categoryId: 3,  //default gold category
        image: this.state.image.file,
        countryId: this.state.country && this.state.country.value,
        permissions: this.state.selectedPermissions.map(permission => (permission.value)),
        managedBy: this.state.managedBy.value && this.state.managedBy.value,
        prefix: this.state.prefix,
        reelUrl: this.state.reelUrl,
        useLogo: this.state.useLogo,
        canManageClients: this.state.canManageClients,
        socialLinks: this.state.socialLinks,
        contentCreatorTypes: this.state.selectedContentCreatorTypes,
        searchPreferences: this.state.searchPreferences,
        blacklistTypeContentCreatorTypes: this.state.blacklistTypeContentCreatorTypes,
        secure: this.state.secure,
        transcodeVideos: this.state.transcodeVideos,
        useAccessLevel: this.state.useAccessLevel,
        accessLevel: this.state.accessLevel,
        translations: [
          {language: 'es', about: this.state.aboutEs && stateToHTML(this.state.aboutEs.getCurrentContent())},
          {language: 'en', about: this.state.aboutEn && stateToHTML(this.state.aboutEn.getCurrentContent())},
        ],
        allowFakeLivestreams: this.state.allowFakeLivestreams,
        hasOwnTags: this.state.hasOwnTags,
        useRekognition: this.state.useRekognition
      })
      .then((client) => {
        this.setState({clientId: client.data.id})
        //Create catalog for client after creation
        /*return request.catalog.create({
          name: this.state.name,
          visibility: 'public',
          status: 'approved',
          catalogCategoryId: 1,
          createdBy: client.data.id,
        }).then(catalog => this.confirmCatalog(catalog.id))*/
      })
      .then(() => request.authentication.getUser())
      .then(() => this.props.history.push(makeUrlWithTableParams("/panel/clients")));

  confirmCatalog = catalogId => {
    return request.client.updateCatalogs({
      id: this.state.clientId,
      confirmedCatalogsIds: [catalogId]
    })
  }

  onChange = e => {
    let { target } = e;
    this.setState({ [target.id]: target.type === 'checkbox' ? target.checked : target.value });
  };

  onImageChange = image => this.setState({ image });
  onAsyncPermissionsChange = selectedPermissions => this.setState({ selectedPermissions });
  onAsyncContentCreatorTypesChange = selectedContentCreatorTypes => this.setState({ selectedContentCreatorTypes });
  onAsyncManagedBy = managedBy => this.setState({managedBy});
  onAsyncCountriesChange = country => this.setState({ country });
  expanderOnClick = () => this.setState({expanded: !this.state.expanded})

  render = () => {
    const {
      disabled,
      name,
      type,
      image,
      cuit,
      address,
      email,
      country,
      licensePlan,
      excludeFromReports,
      selectedPermissions,
      demo,
      managedBy,
      prefix,
      reelUrl,
      useLogo,
      expanded,
      aboutEs,
      aboutEn,
      showAbout,
      keywordsSEO,
      titleSEO,
      descriptionSEO,
      selectedContentCreatorTypes,
      canManageClients,
      searchPreferences,
      blacklistTypeContentCreatorTypes,
      allowFakeLivestreams,
      secure,
      transcodeVideos,
      useAccessLevel,
      accessLevel,
      hasOwnTags,
      useRekognition
    } = this.state;

    return (
      <div>
        <FormViewContainer
          title={localization.get('client.create_client')}
          submitRequest={this.requestClientCreation}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          validation={this.onSubmitValidation}
        >
          <ClientFormView
            disabled={disabled}
            name={name}
            type={type}
            image={image}
            cuit={cuit}
            address={address}
            email={email}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            country={country}
            licensePlan={licensePlan}
            excludeFromReports={excludeFromReports}
            demo={demo}
            prefix={prefix}
            reelUrl={reelUrl}
            selectedPermissions={selectedPermissions}
            onAsyncPermissionsChange={this.onAsyncPermissionsChange}
            onAsyncCountriesChange={this.onAsyncCountriesChange}
            managedBy={managedBy}
            onAsyncManagedBy={this.onAsyncManagedBy}
            useLogo={useLogo}
            socialLinks={this.state.socialLinks}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            catalogClient={this.state.type === 'catalog'}
            expanded={expanded}
            expanderOnClick={this.expanderOnClick}
            onContentChange={(e, field) => this.setState({[field]: e})}
            aboutEs={aboutEs}
            aboutEn={aboutEn}
            showAbout={showAbout}
            keywordsSEO={keywordsSEO}
            titleSEO={titleSEO}
            descriptionSEO={descriptionSEO}
            contentCreatorTypes={selectedContentCreatorTypes}
            onAsyncContentCreatorTypesChange={this.onAsyncContentCreatorTypesChange}
            canManageClients={canManageClients}
            searchPreferences={searchPreferences}
            blacklistTypeContentCreatorTypes={blacklistTypeContentCreatorTypes}
            onChangeRadio={value => this.setState({searchPreferences: value.target.value})}
            onChangeBlacklistTypeContentCreatorTypes={value => this.setState({blacklistTypeContentCreatorTypes: value.target.value})}
            allowFakeLivestreams={allowFakeLivestreams}
            secure={secure}
            transcodeVideos={transcodeVideos}
            useAccessLevel={useAccessLevel}
            hasOwnTags={hasOwnTags}
            accessLevel={accessLevel}
            useRekognition={useRekognition}
          />
        </FormViewContainer>
      </div>
    );
  };
}
