import React from 'react'
import {ImagePicker, VideoPicker} from '../../components/Letflow/Form';
import localization from '../../config/localization';
import {TextValidator} from "react-material-ui-form-validator";
import {
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormLabel,
  IconButton,
  TextField,
  Tooltip
} from "@material-ui/core";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import 'filepond/dist/filepond.min.css';
import {FormControl, Radio, RadioGroup} from "@material-ui/core/index";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../api-client";
import FileImg from "../../assets/img/Letflow/FileExtentions/file.png";
import {CloudDownload, Event, ExpandLess, ExpandMore, Help} from "@material-ui/icons";
import {DateTimePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {ErrorMessage} from "formik";
import Unsplash from "../../components/Letflow/Unsplash";
import TagSelection from '../../components/Letflow/TagSelection';
import RegionSelector from "../../components/Letflow/RegionSelector";
import DriveFileSelector from "../../components/Letflow/DriveFileSelector";
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import {userIsSupervisor} from "../../api-client/core/authentication/utils";
import {get} from 'lodash'

const hideDivs = (divId, ArrowId) => {
  let x = document.getElementById(divId);
  let up = document.getElementById(`${ArrowId}-up`);
  let down = document.getElementById(`${ArrowId}-down`);

  if (x.style.display === "none") {
    x.style.display = "inline-block";
    down.style.display = "none";
    up.style.display = "block";
  } else {
    x.style.display = "none";
    down.style.display = "block";
    up.style.display = "none";
  }
}

const FormContent = ({
  id,
  titleEs,
  titleEn,
  subtitle,
  active,
  descriptionEs,
  descriptionEn,
  video,
  onChange,
  onVideoChange,
  disabled,
  onActiveChange,
  image,
  onImageChange,
  tagGroups,
  onSelectedTagsChanged,
  audio,
  onAudioChange,
  allowImage,
  allowVideo,
  allowAudio,
  allowBinary,
  allowLink,
  allowEmbed,
  binary,
  onBinaryChange,
  link,
  onSetPreview,
  updateDisableForm,
  disableForm,
  videosRadio,
  imagesRadio,
  audiosRadio,
  onBatchRadiosChange,
  onBatchVideoLinkChange,
  selectedBatchVideoLink,
  onBatchVideoFileChange,
  selectedBatchVideoFile,
  onBatchImageLinkChange,
  selectedBatchImageLink,
  onBatchImageFileChange,
  selectedBatchImageFile,
  onBatchAudioLinkChange,
  selectedBatchAudioLink,
  onBatchAudioFileChange,
  selectedBatchAudioFile,
  isTalent,
  fileSelector,
  onRadioChange,
  subtitles,
  onSubtitlesChange,
  spanishSubtitles,
  englishSubtitles,
  onCheckBoxChange,
  spanishSubtitlesFile,
  englishSubtitlesFile,
  italianSubtitles,
  italianSubtitlesFile,
  germanSubtitles,
  germanSubtitlesFile,
  frenchSubtitles,
  frenchSubtitlesFile,
  releaseDate,
  expireDate,
  hasExpiredDate,
  onHasExpiredDateChange,
  isPermanentContent,
  onIsPermanentContentChange,
  embedUrl,
  embedSource,
  downloadable,
  onlyArgentina,
  onChangeOnlyArgentina,
  onSetImageFromUnsplash,
  requireDrm,
  requireLogin,
  showRequireLoginHelper,
  updateHelper,
  unsplashImage,
  hasPreRoll,
  onBatchPreRollVideoLinkChange,
  selectedBatchPreRollVideoLink,
  onBatchPreRollVideoFileChange,
  selectedBatchPreRollVideoFile,
  preRollVideo,
  showDRMDialog,
  updateDRMDialog,
  onLimitedChange,
  limited,
  viewLimit,
  timeToTrack,
  allowDownload,
  contentCreator,
  views,
  hasRegionBlock,
  onHasRegionBlockChange,
  allowedRegions,
  onChangeAllowedRegions,
  inappropriateContent,
  onDriveFileChange,
  client,
  translations,
  onChangeTranslation,
  extraInformation,
  hasExtraInfo,
  createdAt,
  isProtected,
  accessLevel,
}) => {
  return (
    <div>
      <Dialog open={showRequireLoginHelper} onBackdropClick={updateHelper} onEscapeKeyDown={updateHelper}>
        <DialogContent>
          <p>{localization.get('content_creation.require_login_helper')}</p>
        </DialogContent>
      </Dialog>
      <Dialog open={showDRMDialog} onBackdropClick={updateDRMDialog} onEscapeKeyDown={updateDRMDialog}>
        <DialogContent>
            <p>{localization.get('content_creation.require_login_helper')}</p>
        </DialogContent>
      </Dialog>
      
      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creation-general-information', 'arrow-general')}>
        {localization.get('content_creation.general_info')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-general-up"}/>
        <ExpandMore style={{display: !!id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-general-down"}/>
      </h3>
      <br/>
      <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creation-general-information'}>
        <TranslatableInputs
          color="dark"
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`title${language}`}
                  label={localization.get('form.title')}
                  name={`title${language}`}
                  value={currentLangTranslation.title}
                  onChange={e => onChangeTranslation(e.target.value, "title", language)}
                  disabled={disabled}
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                />,
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`description${language}`}
                  label={localization.get('form.description')}
                  name={`description${language}`}
                  value={currentLangTranslation.description}
                  onChange={e => onChangeTranslation(e.target.value, "description", language)}
                  disabled={disabled}
                  // multiline
                />
              ]
            }
          })}
        />
        {allowAudio && !allowImage && !allowVideo &&
        <TextValidator
          style={{width: "100%", marginBottom: "20px"}}
          id="subtitle"
          label={localization.get('form.subtitle')}
          name="subtitle"
          value={subtitle}
          onChange={onChange}
          disabled={disabled}
          errorMessages={[localization.get('validator.is_required')]}
        />
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={hasExtraInfo}
              style={{color: "#4cc2d5"}}
              onChange={() => {
                const e = {
                  target: {
                    id: "hasExtraInfo",
                    value: !hasExtraInfo,
                  },
                };
                onChange(e);
              }}
            />
          } label={localization.get('content_creation.use_extra_information')}
        />
        {hasExtraInfo &&
          <TextField
            multiline
            rows={7}
            fullWidth
            id={"extraInformation"}
            name={"extraInformation"}
            value={extraInformation}
            onChange={onChange}
            label={localization.get('content_creation.extra_information')}
          />
        }
        {!!id &&
          <>
            <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                autoOk
                onlyCalendar
                id={"createdAt"}
                defaultValue={createdAt}
                dateRangeIcon={<Event/>}
                format={"dd/MM/yyyy HH:mm:ss"}
                label={localization.get('form.created_at')}
                value={createdAt}
                onChange={e => {
                  onChange({
                    target: {
                      id: "createdAt",
                      value: e,
                    }
                  })
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        }
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creation-content', 'arrow-content')}>
        {localization.get('content_creation.content')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-content-up"}/>
        <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-content-down"}/>
      </h3>
      <br/>
      <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creation-content'}>

        {!id &&
        <FormControl>
          <FormLabel style={{color: 'black'}} focused={false}>{localization.get('creation.select')}</FormLabel>
          <RadioGroup
            name="fileSelector"
            value={fileSelector}
            onChange={e => onRadioChange(e)}
            style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
          >
            {allowVideo && <FormControlLabel value="video" control={<Radio color="default"/>}
                                            label={localization.get('creation.videos_file')}/>}
            {allowAudio && <FormControlLabel value="audio" control={<Radio color="default"/>}
                                            label={localization.get('creation.audios_file')}/>}
            {allowImage && <FormControlLabel value="image" control={<Radio color="default"/>}
                                            label={localization.get('creation.images_file')}/>}
            {allowBinary && <FormControlLabel value="binary" control={<Radio color="default"/>}
                                              label={localization.get('creation.downloadable')}/>}
            {allowLink &&
            <FormControlLabel value="link" control={<Radio color="default"/>} label={localization.get('creation.link')}/>}
            {allowEmbed &&
            <FormControlLabel value="embed" control={<Radio color="default"/>} label={localization.get('form.embed')}/>}
          </RadioGroup>
        </FormControl>
        }

        {fileSelector !== "default" &&
        <>
          <h4>{localization.get('image')}</h4>
          <FormControl>
            <RadioGroup
              name="imagesRadio"
              value={imagesRadio}
              onChange={e => onBatchRadiosChange(e)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="filepond" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_computer')}/>
              <FormControlLabel value="batch" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_platform')}/>
              <FormControlLabel value="unsplash" control={<Radio color="default"/>}
                                label={localization.get('form.upload_from_unsplash')}/>
              {client &&
                <FormControlLabel value="drive" control={<Radio color="default"/>} label={localization.get('batch_upload.upload_from_drive')}/>
              }
            </RadioGroup>
          </FormControl>
          {imagesRadio === 'filepond' ?
            <>
              {(image && image.src) && <ImagePicker image={image.src} previewOnly={true} id={image.id} />}
              {image && image.id &&
              <div style={{textAlign: 'center'}}>
                <IconButton
                  title={localization.get('download')}
                  onClick={() => {
                    request.file.image.getDownloadLink(image.id)
                      .then(link => window.open(link, '_blank'))
                  }}
                >
                  <CloudDownload/>
                </IconButton>
              </div>
              }
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={image}
                id={"image"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={onImageChange}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
              <div style={{
                color: "#757575",
                textAlign: 'center',
                marginTop: 5
              }}>{localization.get('helper_text.images_width_2048')}</div>
            </>
            : imagesRadio === 'unsplash' ?
              <Unsplash imageAmount={9} onSelectImage={onSetImageFromUnsplash}/>
              : imagesRadio === 'drive' ?
                <DriveFileSelector
                  clientId={get(contentCreator, 'type.client_id')}
                  onSelectFile={id => onDriveFileChange('driveImage', id)}
                  fileType={"image"}
                />
                  :
              <>
                <FormControl fullWidth>
                  <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                  <AsyncSelect
                    styles={{menu: base => ({...base, zIndex: 10})}}
                    theme={theme => ({...theme, borderRadius: 0})}
                    placeholder={localization.get('conversation.filter_users')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={onBatchImageLinkChange}
                    value={selectedBatchImageLink}
                    loadOptions={inputValue => {
                      let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent}];
                      if(contentCreator && contentCreator.type && contentCreator.type.client) {
                        filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                      }
                      return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                        .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                    }}
                    defaultOptions
                  />
                </FormControl>

                {selectedBatchImageLink &&
                <FormControl style={{marginTop: 20}} fullWidth>
                  <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 10
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('conversation.filter_users')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={onBatchImageFileChange}
                    value={selectedBatchImageFile}
                    loadOptions={inputValue => {
                      let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'image'}];

                      if(contentCreator && contentCreator.type && contentCreator.type.client) {
                        filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                      }
                      return request.batchUpload.getAllFiles({
                        orderBy: {column: "created_at", type: "DESC"},
                        filterBy
                      }, selectedBatchImageLink.token)
                        .then(files => files.map(file => ({value: file.id, label: file.filename})))
                    }}
                    defaultOptions
                  />
                </FormControl>
                }
              </>
          }
        </>
        }
        {fileSelector === "video" &&
        <>
          <h4>{localization.get('video')}</h4>
          <FormControl>
            <RadioGroup
              name="videosRadio"
              value={videosRadio}
              onChange={e => onBatchRadiosChange(e)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="filepond" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_computer')}/>
              <FormControlLabel value="batch" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_platform')}/>
              {client &&
                <FormControlLabel value="drive" control={<Radio color="default"/>}
                                  label={localization.get('batch_upload.upload_from_drive')}/>
              }
            </RadioGroup>
          </FormControl>

          {videosRadio === 'filepond' ?
            <>
              {!!(video.hls || video.dash || video.src) &&
              (isProtected && !!video.src) ?
                <>
                  <br/>
                <span>Se estan procesando las version SD, HD, FullHD del material.</span>
                </>
                : <VideoPicker video={(video.hls || video.dash) ? video : video.src} previewOnly={true}/>
              }
              {!isProtected && id &&
                <div style={{textAlign: 'center'}}>
                  <IconButton
                    title={localization.get('download')}
                    onClick={() => request.contentCreation.getDownloadLink(id)
                      .then(response => {
                        GlobalSnackbar.show({message: localization.get("downloading"), type: GlobalSnackbarTypes.SUCCESS})
                        window.open(response.url, "_blank");
                      })
                    }
                  >
                    <CloudDownload/>
                  </IconButton>
                </div>
              }
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={video}
                id={"video"}
                type={"video"}
                allowedTypes={["video/mp4", "video/x-m4v", "video/*", ".mov"]}
                onIdChange={onVideoChange}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            : videosRadio === 'drive' ?
              <DriveFileSelector
                clientId={get(contentCreator, 'type.client_id')}
                onSelectFile={id => onDriveFileChange('driveVideo', id)}
                fileType={"video"}
              />
            :
            <>
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchVideoLinkChange}
                  value={selectedBatchVideoLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent}];

                    if(contentCreator && contentCreator.type && contentCreator.type.client) {
                      filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                    }

                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({
                        value: link.id,
                        label: link.name,
                        token: link.token,
                        protected: link.protected
                      })))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchVideoLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchVideoFileChange}
                  value={selectedBatchVideoFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'video'}];

                    if(contentCreator && contentCreator.type && contentCreator.type.client) {
                      filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                    }
                    return request.batchUpload.getAllFiles({
                      orderBy: {column: "created_at", type: "DESC"},
                      filterBy
                    }, selectedBatchVideoLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
          }
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={hasPreRoll}
                onChange={() => {
                  onCheckBoxChange('hasPreRoll', !hasPreRoll);
                }}
              />
            }
            label={localization.get('form.pre_roll')}
          />

          {/*---------------Subtitles---------------*/}
          <div style={{display: 'flex', alignItems: 'center'}}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={subtitles}
                  onChange={() => {
                    onCheckBoxChange('subtitles', !subtitles);
                  }}
                />
              }
              label={localization.get("form.subtitles")}
            />
            <Tooltip title={localization.get("form.subtitles.vtt.help")} placement="bottom">
              <Help style={{fontSize: 15, color: "rgba(4, 175, 196, 1)", cursor: 'pointer'}}/>
            </Tooltip>
          </div>

          {subtitles &&
          <>
            {/*Spanish*/}
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={spanishSubtitles}
                  onChange={() => {
                    onCheckBoxChange('spanishSubtitles', !spanishSubtitles);
                  }}
                />
              }
              label={localization.get("form.spanish")}
            />

            {spanishSubtitles &&
            <>
              {spanishSubtitlesFile && <span>{spanishSubtitlesFile.filename}</span>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                id={"spanishSubtitles"}
                type={"other"}
                customProp={'text/vtt'}
                allowedTypes={["text/vtt"]}
                onIdChange={file => onSubtitlesChange('spanishSubtitlesId', file)}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            }

            {/*English*/}
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={englishSubtitles}
                  onChange={() => {
                    onCheckBoxChange('englishSubtitles', !englishSubtitles);
                  }}
                />
              }
              label={localization.get("form.english")}
            />

            {englishSubtitles &&
            <>
              {englishSubtitlesFile && <span>{englishSubtitlesFile.filename}</span>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                id={"englishSubtitles"}
                type={"other"}
                customProp={'text/vtt'}
                allowedTypes={["text/vtt"]}
                onIdChange={file => onSubtitlesChange('englishSubtitlesId', file)}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            }

            {/*Italian*/}
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={italianSubtitles}
                  onChange={() => {
                    onCheckBoxChange('italianSubtitles', !italianSubtitles);
                  }}
                />
              }
              label={localization.get("form.language.italian")}
            />

            {italianSubtitles &&
            <>
              {italianSubtitlesFile && <span>{italianSubtitlesFile.filename}</span>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                id={"italianSubtitles"}
                type={"other"}
                customProp={'text/vtt'}
                allowedTypes={["text/vtt"]}
                onIdChange={file => onSubtitlesChange('italianSubtitlesId', file)}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            }

            {/*German*/}
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={germanSubtitles}
                  onChange={() => {
                    onCheckBoxChange('germanSubtitles', !germanSubtitles);
                  }}
                />
              }
              label={localization.get("form.language.german")}
            />

            {germanSubtitles &&
            <>
              {germanSubtitlesFile && <span>{germanSubtitlesFile.filename}</span>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                id={"germanSubtitles"}
                type={"other"}
                customProp={'text/vtt'}
                allowedTypes={["text/vtt"]}
                onIdChange={file => onSubtitlesChange('germanSubtitlesId', file)}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            }

            {/*French*/}
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={frenchSubtitles}
                  onChange={() => {
                    onCheckBoxChange('frenchSubtitles', !frenchSubtitles);
                  }}
                />
              }
              label={localization.get("form.language.french")}
            />

            {frenchSubtitles &&
            <>
              {frenchSubtitlesFile && <span>{frenchSubtitlesFile.filename}</span>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                id={"frenchSubtitles"}
                type={"other"}
                customProp={'text/vtt'}
                allowedTypes={["text/vtt"]}
                onIdChange={file => onSubtitlesChange('frenchSubtitlesId', file)}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </>
            }


          </>
          }
        </>
        }
        {fileSelector === "audio" &&
        <>
          <h4>{localization.get('audio')}</h4>
          <FormControl>
            <RadioGroup
              name="audiosRadio"
              value={audiosRadio}
              onChange={e => onBatchRadiosChange(e)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="filepond" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_computer')}/>
              <FormControlLabel value="batch" control={<Radio color="default"/>}
                                label={localization.get('batch_upload.upload_from_platform')}/>
              {client &&
                <FormControlLabel value="drive" control={<Radio color="default"/>}
                                  label={localization.get('batch_upload.upload_from_drive')}/>
              }
            </RadioGroup>
          </FormControl>

          {audiosRadio === 'filepond' ?
            <div>
              {audio.src && <audio src={audio.src} controls/>}
              <FilePondUploader
                disableForm={disableForm}
                updateDisableForm={updateDisableForm}
                file={audio}
                id={'audio'}
                type={"audio"}
                allowedTypes={["audio/*"]}
                onIdChange={onAudioChange}
                setPreview={onSetPreview}
                clientId={get(contentCreator, 'type.client_id')}
              />
            </div>
            : audiosRadio === 'drive' ?
              <DriveFileSelector
                clientId={get(contentCreator, 'type.client_id')}
                onSelectFile={id => onDriveFileChange('driveAudio', id)}
                fileType={"audio"}
              />
              :
            <>
              <FormControl fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_link')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchAudioLinkChange}
                  value={selectedBatchAudioLink}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "name", filter: inputValue}, {column: "is_talent", filter: isTalent}];

                    if(contentCreator && contentCreator.type && contentCreator.type.client) {
                      filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                    }
                    return request.batchUpload.getAll({orderBy: {column: "name", type: "ASC"}, filterBy})
                      .then(links => links.map(link => ({value: link.id, label: link.name, token: link.token})))
                  }}
                  defaultOptions
                />
              </FormControl>

              {selectedBatchAudioLink &&
              <FormControl style={{marginTop: 20}} fullWidth>
                <span>{localization.get('batch_upload.form.select_batch_upload_file')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={onBatchAudioFileChange}
                  value={selectedBatchAudioFile}
                  loadOptions={inputValue => {
                    let filterBy = [{column: "filename", filter: inputValue}, {column: "type", filter: 'audio'}];

                    if(contentCreator && contentCreator.type && contentCreator.type.client) {
                      filterBy.push({column: "client_id", filter: contentCreator.type.client.id})
                    }
                    return request.batchUpload.getAllFiles({
                      orderBy: {column: "created_at", type: "DESC"},
                      filterBy
                    }, selectedBatchAudioLink.token)
                      .then(files => files.map(file => ({value: file.id, label: file.filename})))
                  }}
                  defaultOptions
                />
              </FormControl>
              }
            </>
          }
        </>
        }

        {fileSelector === "binary" &&
        <div>
          <h4>{localization.get('creation.downloadable')}</h4>
          {binary &&
          <img
            style={{width: '100%', borderRadius: 6, maxWidth: '100px', marginBottom: 10, marginLeft: "calc(50% - 50px)"}}
            src={FileImg}
          />}
          <FilePondUploader
            disableForm={disableForm}
            updateDisableForm={updateDisableForm}
            file={binary}
            id={'binary'}
            type={"other"}
            onIdChange={onBinaryChange}
            setPreview={onSetPreview}
            clientId={get(contentCreator, 'type.client_id')}
          />
        </div>
        }

        {fileSelector === "link" &&
        <TextValidator
          style={{width: "100%", marginBottom: "20px"}}
          id="link"
          label={localization.get('form.link')}
          name="link"
          value={link}
          onChange={onChange}
          disabled={disabled}
          errorMessages={[localization.get('validator.is_required')]}
        />
        }

        {fileSelector === "embed" &&
        <>
          <FormLabel style={{marginRight: 20}} focused={false}>{localization.get('form.source')}</FormLabel>
          <RadioGroup
            name="embedSource"
            value={embedSource}
            onChange={e => onBatchRadiosChange(e)}
            style={{flexDirection: "row", display: "contents"}}
          >
            <FormControlLabel value="youtube" control={<Radio color="default"/>}
                              label={localization.get('form.youtube')}/>
            <FormControlLabel value="vimeo" control={<Radio color="default"/>} label={localization.get('form.vimeo')}/>
            <FormControlLabel value="spotify" control={<Radio color="default"/>}
                              label={localization.get('form.spotify')}/>
            <FormControlLabel value="tiktok" control={<Radio color="default"/>}
                              label={localization.get('TikTok')}/>
            <FormControlLabel value="twitter" control={<Radio color="default"/>}
                              label={localization.get('Twitter')}/>
          </RadioGroup>


          <TextValidator
            style={{width: "100%", marginBottom: "20px"}}
            id="embedUrl"
            label={`${localization.get('form.embed_url')} ${embedSource && localization.get(`form.embed_${embedSource}`)}`}
            name="embedUrl"
            value={embedUrl}
            onChange={onChange}
            disabled={disabled}
            errorMessages={[localization.get('validator.is_required')]}
          />
        </>
        }
        {(fileSelector === "video" || fileSelector === "audio" ||fileSelector === "image") &&
          <FormControlLabel
            style={{width: "100%", position: "relative", float: "left"}}
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={allowDownload}
                disabled={isProtected}
                onChange={() => {
                  onCheckBoxChange('allowDownload', !allowDownload);
                }}
              />
            }
            label={localization.get('form.allow_download')}
          />
        }
        <br/>
        <br/>
      </div>

      <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creation-availability', 'arrow-availability')}>
        {localization.get('content_creation.availability')}
        <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-availability-up"}/>
        <ExpandMore style={{display: !!id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-availability-down"}/>
      </h3>
      <br/>
      <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creation-availability'}>
        <Card>
          <CardContent>
            {userIsSupervisor() &&
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={isProtected}
                    disabled={requireDrm}
                    onChange={() => {
                      if (!isProtected) {
                        onChange({
                          target: {
                            value: 0,
                            id: "allowDownload"
                          }
                        })
                      }
                      onChange({
                        target: {
                          value: !isProtected,
                          id: "isProtected"
                        }
                      })
                    }}
                  />
                }
                label={localization.get('content_creation.protected')}
              />
            }
            <h4>{localization.get('content_creation.visibility')}</h4>
            <div style={{marginTop: 10}}>
              <FormControl>
                <RadioGroup
                  name="active"
                  id="active"
                  value={active}
                  onChange={e => onActiveChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>}
                                    label={localization.get('form.allowed_material')}/>
                  <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>}
                                    label={localization.get('form.disallowed_material')}/>
                </RadioGroup>
              </FormControl>
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={inappropriateContent}
                  onChange={() => {
                    onCheckBoxChange('inappropriateContent', !inappropriateContent);
                  }}
                />
              }
              label={localization.get('form.inappropriate_content')}
            />

            {fileSelector === "video" && active === "1" && !isTalent &&
              <>
                <div style={{marginTop: 10}}>
                  <h4>{localization.get('form.region')} {allowedRegions.length > 0 && <span style={{fontSize: 12}}>{localization.get('continents.country.selected', allowedRegions.length)}</span>}</h4>
                  <FormControl>
                    <RadioGroup
                      name="hasRegionBlock"
                      value={hasRegionBlock}
                      onChange={e => onHasRegionBlockChange(e.target.value)}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel style={{width: '200px'}} value="false" control={<Radio color="default"/>} label={localization.get('form.all_world')}/>
                      <FormControlLabel style={{width: '200px'}} value="true" control={<Radio color="default"/>} label={localization.get('form.has_region_block')}/>
                    </RadioGroup>
                  </FormControl>

                  {hasRegionBlock === "true" &&
                    <RegionSelector selectedCountries={allowedRegions} onChange={onChangeAllowedRegions} />
                  }
                </div>
                <div style={{marginTop: 10}}>
                  <h4>{localization.get('form.limit_views')}</h4>
                  <FormControl>
                    <RadioGroup
                      name="limited"
                      id="limited"
                      value={limited}
                      onChange={e => {
                        if ((image && !image.src) && !selectedBatchImageFile && !unsplashImage) {
                          updateHelper()
                        }else{
                          onLimitedChange(e.target.value)
                        }
                      }}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>}
                                        label={localization.get('form.disabled')}/>
                      <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>}
                                        label={localization.get('form.active')}/>
                    </RadioGroup>
                  </FormControl>
                </div>
                {limited === "1" &&
                <FormControl style={{marginTop: 20}} fullWidth>
                  <TextValidator
                    style={{width: "100%", marginBottom: "20px"}}
                    label={`${localization.get('form.view_limit')} ${viewLimit >0 && views >0 ? localization.get('form.actual_views', viewLimit < views ? viewLimit : views) : ""}`}
                    name="viewLimit"
                    id="viewLimit"
                    type={"number"}
                    maxLength="3"
                    min="0"
                    value={viewLimit}
                    onChange={onChange}
                  />
                  <TextValidator
                    style={{width: "100%", marginBottom: "20px"}}
                    label={localization.get('form.time_to_track')}
                    name="timeToTrack"
                    id="timeToTrack"
                    type={"number"}
                    maxLength="3"
                    min="0"
                    value={timeToTrack}
                    onChange={onChange}
                  />
                </FormControl>
                }
              </>
            }
          
            {get(contentCreator, 'type.client.has_access_level') &&
            <>
              <br/>
              <FormControl>
                <h4>{localization.get('form.access_level')}</h4>
                <RadioGroup
                  name="accessLevel"
                  id="accessLevel"
                  value={accessLevel}
                  onChange={e => onChange({target: {id: "accessLevel", value: e.target.value}})}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel
                      value={"0"}
                      control={<Radio color="default"/>}
                      label={localization.get('smart_search.none')}
                    />
                  {["1","2","3","4","5"].map(level =>
                    <FormControlLabel
                      value={level}
                      control={<Radio color="default"/>}
                      label={level}
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <br/>
              </>
            }
            {active === "1" && !isTalent &&
            <>
              <h4>{localization.get('form.material_duration')}</h4>
              <FormControl>
                <RadioGroup
                  name="isPermanentContent"
                  value={isPermanentContent}
                  onChange={e => onIsPermanentContentChange(e.target.value)}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>}
                                    label={localization.get('form.permanent_material')}/>
                  <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>}
                                    label={localization.get('form.not_permanent_material')}/>
                </RadioGroup>
              </FormControl>
              {isPermanentContent === "0" &&
              <div style={{width: "50%", display: "flex", flexDirection: "column", marginTop: 10}}>
                <h4>{localization.get('form.range_disponibility')}</h4>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{marginBottom: 15}}
                    autoOk
                    autoSubmit
                    disablePast
                    format={"dd/MM/yyyy HH:mm a"}
                    helperText={localization.get("form.helper_datepicker_material_active")}
                    dateRangeIcon={<Event/>}
                    id={"releaseDate"}
                    defaultValue={releaseDate}
                    label={localization.get('form.release_date')}
                    value={releaseDate}
                    onChange={e => {
                      onChange({
                        target: {
                          id: "releaseDate",
                          value: e,
                        }
                      })
                    }}
                  />
                  <ErrorMessage name="startTime" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </MuiPickersUtilsProvider>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={!hasExpiredDate}
                      onChange={() => onHasExpiredDateChange(!hasExpiredDate)}
                    />
                  }
                  label={localization.get('has_expired_date')}
                />
                {hasExpiredDate &&
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    autoOk
                    clearable
                    onlyCalendar
                    id={"expireDate"}
                    disablePast
                    defaultValue={expireDate}
                    dateRangeIcon={<Event/>}
                    format={"dd/MM/yyyy HH:mm a"}
                    disabled={!hasExpiredDate}
                    label={localization.get('form.expire_date')}
                    helperText={localization.get("form.helper_datepicker_material_disactive")}
                    value={expireDate}
                    onChange={e => {
                      onChange({
                        target: {
                          id: "expireDate",
                          value: e,
                        }
                      })
                    }}
                  />
                </MuiPickersUtilsProvider>
                }
              </div>
              }
            </>
            }
          </CardContent>
        </Card>
        {fileSelector === "video" &&
          <>
            <br/>
            <Card>
              <CardContent>
                <h4>{localization.get('form.material_security')}</h4>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={!!requireLogin}
                      onChange={() => {
                        if ((image && !image.src) && !selectedBatchImageFile && !unsplashImage) {
                          updateHelper()
                        } else {
                          onCheckBoxChange('requireLogin', !requireLogin)
                        }
                      }}
                    />
                  }
                  label={localization.get('form.require_login')}
                />
                {contentCreator && contentCreator.type && contentCreator.type.client && contentCreator.type.client.transcode_videos &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={requireDrm}
                        onChange={() => {
                          if (!requireDrm && (((image && !image.src) && !selectedBatchImageFile && !unsplashImage))) {
                            updateDRMDialog()
                          } else {
                            if (!requireDrm) {
                              if (!isProtected) {
                                onChange({
                                  target: {
                                    value: 0,
                                    id: "allowDownload"
                                  }
                                })
                              }
                              onChange({
                                target: {
                                  value: 1,
                                  id: "isProtected"
                                }
                              })
                            }
                            onCheckBoxChange('requireDrm', !requireDrm);
                          }
                        }}
                      />
                    }
                    label={localization.get('form.require_drm')}
                  />
                }
                {/*{(fileSelector === "video" || fileSelector === "image") &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={downloadable}
                        onChange={() => {
                          onCheckBoxChange('downloadable', !downloadable);
                        }}
                      />
                    }
                    label={localization.get("form.downloadable_material")}
                  />
                }*/}
              </CardContent>
            </Card>
          </>
        }
      </div>

      {
        tagGroups && tagGroups.length > 0 &&
        (
          <>
            <h3 style={{background: "var(--dashboard-section-header-background-color, #ccc)",  color: "var(--dashboard-section-header-font-color, #FFF)",position: "relative", borderRadius: 5,padding: 5, cursor: "pointer"}} onClick={() => hideDivs('content-creation-tags', 'arrow-tags')}>
              {localization.get('content_creation.tags')}
              <ExpandLess style={{display: !id ? "block": "none",position:"absolute", right:15, top:12}} id={"arrow-tags-up"}/>
              <ExpandMore style={{display: id ? "block": "none", position:"absolute", right:15, top:12}} id={"arrow-tags-down"}/>
            </h3>
            <br/>
            <div style={{width: '100%', display: !id ? "inline-block": "none"}} id={'content-creation-tags'}>
              {titleEs && <h4><b>{localization.get("content")}: </b> {titleEs}</h4>}
              <TagSelection cardView data={tagGroups} onSelectedTagsChanged={onSelectedTagsChanged} disabled={disabled} showFilter useParents/>
            </div>
          </>
        ) 
      }

    </div>
  )
}

export default FormContent
