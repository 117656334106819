import React from "react";
import facebooksvg from "./Svg/facebook.svg";
import instagramsvg from "./Svg/instagram.svg";
import spotifysvg from "./Svg/spotify.svg";
import youtubesvg from "./Svg/youtube.svg";
import vimeosvg from "./Svg/vimeo.svg";
import soundcloudsvg from "./Svg/soundcloud.svg";
import twittersvg from "./Svg/twitter.svg"
import websvg from "./Svg/web.svg"
import linkedinsvg from "./Svg/linkedin.svg"
import behancesvg from "./Svg/behance.svg"
import emailsvg from "./Svg/email.svg"
import tiktoksvg from "./Svg/tiktok.svg"
import twitchsvg from "./Svg/twitch.svg"
import linktreesvg from "./Svg/linktree.svg"
import applesvg from "./Svg/apple.svg"
import androidsvg from "./Svg/android.svg"
import telegramsvg from "./Svg/telegram.svg"

export const Facebook = () => <img style={{width: 25}} alt={"FacebookLogo"} src={facebooksvg} />;
export const Instagram = () => <img style={{width: 25}} alt={"InstagramLogo"} src={instagramsvg} />;
export const Spotify = () => <img style={{width:25}} alt={"SpotifyLogo"} src={spotifysvg} />;
export const Youtube = () => <img style={{width:25}} alt={"YoutubeLogo"} src={youtubesvg} />;
export const SoundCloud = () => <img style={{width:25}} alt={"SoundCloudLogo"} src={soundcloudsvg} />;
export const Twitter = () => <img style={{width:25}} alt={"TwitterLogo"} src={twittersvg} />;
export const Vimeo = () => <img style={{width:25}} alt={"VimeoLogo"} src={vimeosvg} />;
export const Web = () => <img style={{width:25}} alt={"WebLogo"} src={websvg} />;
export const LinkedIn = () => <img style={{width:25, padding:2}} alt={"LinkedingLogo"} src={linkedinsvg} />;
export const Behance = () => <img style={{width:25, height: 25}} alt={"BehanceLogo"} src={behancesvg} />;
export const Email = () => <img style={{width:24, padding:2}} alt={"EmailLogo"} src={emailsvg} />;
export const Tiktok = () => <img style={{width:25, padding:2}} alt={"TiktokLogo"} src={tiktoksvg} />;
export const Twitch = () => <img style={{width:25, padding:2}} alt={"TwitchLogo"} src={twitchsvg} />;
export const Linktree = () => <img style={{width:25}} alt={"LinktreeLogo"} src={linktreesvg} />;
export const Apple = () => <img style={{width:25}} alt={"AppleLogo"} src={applesvg} />;
export const Android = () => <img style={{width:25, padding:1}} alt={"AndroidLogo"} src={androidsvg} />;
export const Telegram = () => <img style={{width:25, padding: 1}} alt={"TelegramLogo"} src={telegramsvg} />;