import React, {PureComponent} from 'react';
import {Storage} from '@material-ui/icons'
import "./StorageChart.css"
import localization from "../../../config/localization";

export default class extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || [],
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.data !== this.props.data) {
      this.setState({data: nextProps.data})

    }
  };

  render() {
    let used = this.state.data.length > 1 ?  Math.round( this.state.data[0].value*100/this.state.data[1].value, -1) : 0

    return (
      <div className="storage-chart">
        <div className="chart-icon"><Storage/></div>
        <div className="chart">
          <h4 className="title" style={{marginTop: 0}}>Storage</h4>
          <div className="complete-bar">
            <div className="used-bar" style={{width: `${used}%`}}/>
          </div>
          <div className="percentage">{localization.get('file.storage_used', used)}</div>
        </div>
      </div>
    );
  }
}
