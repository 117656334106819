import React, {Component} from 'react'
import {Card, CardContent, Grid, IconButton, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import localization from '../../../../../config/localization';
import HomeBannerElement from './HomeBannerElement';
import HomeBannerElementForm from './HomeBannerElementForm';
import {request} from '../../../../../api-client';
import GlobalConfirmationDialog from '../../../../../components/Letflow/Dialog/GlobalConfirmationDialog';

class HomeBanners extends Component {

  constructor(props) {
    super(props)
    this.state = {
      banners: props.items || [],
      showForm: false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.items && nextProps.items.length > 0 && this.state.banners !== nextProps.items) {
      this.setState({banners: nextProps.items})
    }
  }

  makeBannerForm = () => this.state.showForm && <HomeBannerElementForm
    formTitle={localization.get('form.title')}
    onClose={() => this.setState({ showForm: false })}
    submitRequest={(active, title, subtitle, link, newTab, image, video, preview, hasButton, button, onProgress) => request.client.home.elements.createBannerItem(
      this.props.clientId,
      this.props.id,
      { active, title, subtitle, link, newTab, image, video, preview, hasButton, button },
      onProgress
    )
      .then(() => {
        this.setState({showForm: false})
        this.props.onUpdateSuccess()
      })
      .catch(this.props.onUpdateFailed)}
  />

  requestDelete = () => request.client.home.elements.delete(this.props.clientId, this.props.id).then(this.props.onUpdateSuccess).catch(this.props.onUpdateFailed)

  handleDelete = () => GlobalConfirmationDialog.showDelete({ elementName: 'Banner', deleteRequest: this.requestDelete })

  render = () =>
    <Card style={{ width: '100%' }}>
      {this.makeBannerForm()}
      <CardContent>
        <Typography variant='display1'>{localization.get('home_editor.banners')}</Typography>
        <Grid style={{ marginTop: '20px' }} spacing={16} container>
          {this.state.banners.length === 0 && <Typography style={{width: "100%", marginBottom: 15}}>{localization.get('home_editor.banners_element_empty')}</Typography>}
          {this.state.banners.map((banner, i) =>
            <Grid xs={12} sm={6} md={4} item>
              <HomeBannerElement {...this.props} banner={banner} clientId={this.props.clientId} elementId={this.props.id} itemIndex={i} />
            </Grid>
          )}
          <Grid style={{ cursor: "pointer" }} onClick={() => this.setState({ showForm: true })} xs={12} sm={6} md={4} item>
            <div>
              <Card>
                <CardContent style={{ textAlign: 'center', width: "100%", float: "left", position: "relative", minHeight: "195px" }}>
                  <IconButton style={{ marginTop: "35px" }}><Add style={{ fontSize: "36px" }} /></IconButton>
                  <Typography style={{ marginTop: "10px" }}>{localization.get('account.banner.add')}</Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
}

export default HomeBanners
