import React, {Component} from "react";
import {getMostImportantAlbum, makeUrlWithTableParams} from "../../../../utils";
import localization from "../../../../config/localization";
import matchSorter from "match-sorter";
import {TableButtonsBuilder} from "../../../../components/Letflow/Table";
import {request} from "../../../../api-client";
import ServerSidePaginationTable from "../../../../components/Letflow/Table/ServerSidePaginationTable";
import moment from "moment";
import {CopyToClipboard} from "react-copy-to-clipboard";
import TableButton from "../../../../components/Letflow/Table/Buttons/TableButton";
import {FilterNone} from "@material-ui/icons";
import {addHttpsToUrl, channelPathGenerator, slugify} from "../../../../api-client/core/authentication/utils";

class Table extends Component {

  state = {
    total: '',
    copied_grid: localization.get('copy_link_grid'),
    copied_carousel: localization.get('copy_link_carousel'),
  }

  columns = () => [
    {
      Header: localization.get('table.title'),
      accessor: "title",
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["title"] }),
      filterAll: true,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "created_at",
      Header: localization.get("audit_log.date"),
      accessor: row => moment(row.created_at).format("DD-MM-YYYY"),
      resizeable: false,
      sortable: true,
      filterable: false,
      width: 100
    },
    {
      id: "client[name]",
      Header: localization.get("table.client"),
      accessor: "client.name",
      sortable: false,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "channel[name]",
      Header: localization.get("table.channel"),
      accessor: "channel.name",
      sortable: false,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "elements_count",
      Header: localization.get("batch_upload.content_amount"),
      accessor: "elements_count",
      sortable: false,
      filterable: false,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: playlist => {
        const buttonBuilder = new TableButtonsBuilder()

        buttonBuilder.withOther(
          <CopyToClipboard
            onCopy={() => this.setState({copied_grid: localization.get('copied')},
              () => setTimeout(() => this.setState({copied_grid: localization.get('copy_link_grid')}), 1000))}
            text={!!playlist.channel.domain ? `${addHttpsToUrl(playlist.channel.domain.url)}/lista/${playlist.id}-${slugify(playlist.title)}?format=grid` : `${process.env.REACT_APP_FRONT_HOST}${channelPathGenerator(`lista/${playlist.id}-${slugify(playlist.title)}`, playlist.channel)}?format=grid`}
          >
            <TableButton
              title={localization.get(this.state.copied_grid)}
            >
              <FilterNone/>
            </TableButton>
          </CopyToClipboard>
        )

        buttonBuilder.withOther(
          <CopyToClipboard
            onCopy={() => this.setState({copied_carousel: localization.get('copied')},
              () => setTimeout(() => this.setState({copied_carousel: localization.get('copy_link_carousel')}), 1000))}
            text={!!playlist.channel.domain ? `${addHttpsToUrl(playlist.channel.domain.url)}/lista/${playlist.id}-${slugify(playlist.title)}?format=carousel` : `${process.env.REACT_APP_FRONT_HOST}${channelPathGenerator(`lista/${playlist.id}-${slugify(playlist.title)}`, playlist.channel)}?format=carousel`}
          >
            <TableButton
              title={localization.get(this.state.copied_carousel)}
            >
              <FilterNone/>
            </TableButton>
          </CopyToClipboard>
        )

        buttonBuilder.withEdit(makeUrlWithTableParams(`/panel/lists/channels/${playlist.id}/edit`))
        buttonBuilder.withDelete(
          playlist.title,
          () => request.playlist.delete(playlist.id),
          state => {
            if (state === "passed") {
              this.refs.table.removeById(playlist.id)
            }
          }
        )
        return buttonBuilder.build()
      },

      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }

  ]

  render = () => {
    return (
      <React.Fragment>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            requestTableData={paginationConfig => {
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'channel_lists', filter: true }]) }

              return (
                request.playlist.getAll(paginationConfig).then((response) => {
                  this.setState({ total: response.meta.total })
                  return response
                })
              )
            }}
            ref="table"
            columns={this.columns()}
          />
        </div>
      </React.Fragment>
    );
  }


}

export default Table;
