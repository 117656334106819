import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import { channelPathGenerator, getStoredUser, setPageTitle } from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import browserHistory from "../../../utils/browserHistory";
import PurchasesTable from "./PurchasesTable";

class MyPurchases extends React.Component {

  constructor (props) {
    super(props); 
    this.state = {
      purchases: [],
      loaded: false,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.my_purchases'))
    document.getElementsByTagName('html')[0].scrollTop = 0
    if (getStoredUser()) {
      request.products.getUserPurchasesForChannel().then((purchases) => this.setState({purchases: purchases, loaded: true}));
    }else {
      browserHistory.push(channelPathGenerator('login'));
    }
  }


  render() {
    const {purchases, loaded} = this.state;
    return (
      <div className="favorites-container">
        <div style={{marginBottom: "20px", float: "left", width: "100%"}}>
          <h1 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{localization.get('title.my_purchases')}</h1>
          <div style={{marginTop: '20px'}}>
            <PurchasesTable purchases={this.state.purchases}/>
          </div>
        </div>
      </div>
    )
  }
}

export default MyPurchases
