import React, {Component} from 'react';
import continentsJson from './continents.json'
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TextField from "@material-ui/core/TextField/TextField";


const continents = [
  {id: "latam", name: "Latam"},
  {id: "northamerica", name: localization.get('continents.northamerica')},
  {id: "europe", name: localization.get('continents.europe')},
  {id: "asia", name: localization.get('continents.asia')},
  {id: "oceania", name: localization.get('continents.oceania')},
  {id: "africa", name: localization.get('continents.africa')},
];

export default class RegionSelector extends Component{
  constructor(props) {
    super(props);
    const usingContinents = continents.reduce((acc, cur) => {
      if (continentsJson[cur.id].every(r => props.selectedCountries.includes(r))) acc.push(cur.id);

      return acc;
    }, [])

    this.state = {
      selectedContinents: usingContinents,
      countries: [],
      searchInput: "",
    }
  }


  componentDidMount() {
    request.country.getAll()
      .then(countries => this.setState({ countries }))
  }

  componentWillReceiveProps(nextProps) {
    this.setState({selectedContinents:
        continents.reduce((acc, cur) => {
          if (continentsJson[cur.id].every(r => nextProps.selectedCountries.includes(r))) acc.push(cur.id);

          return acc;
        }, [])
    })
  }

  onContinentSelect = continentId => {
    let newContinents = this.state.selectedContinents;
    let newCountries = this.props.selectedCountries;
    if (newContinents.includes(continentId)) {
      newContinents = newContinents.filter(continent => continent !== continentId)
      newCountries = newCountries.filter(country => !continentsJson[continentId].includes(country))
    }else {
      newCountries = continentsJson[continentId].reduce((acc, cur) => {
        if(!acc.includes(cur)) acc.push(cur)

        return acc;
      }, this.props.selectedCountries)

      newContinents.push(continentId);
    }

    this.setState({selectedContinents: newContinents})
    this.props.onChange(newCountries);

  }

  onCountrySelect = countryId => {
    let newCountries = this.props.selectedCountries;
    if (newCountries.includes(countryId)) {
      newCountries = newCountries.filter(country => country !== countryId)
    }else {
      newCountries.push(countryId);
    }

    this.props.onChange(newCountries);
  }

  render = () => {
    const { countries, searchInput, selectedContinents } = this.state;
    const { selectedCountries } = this.props;

    return (
      <Card>
        <CardContent  style={{ height: 400, maxHeight: 400, overflowY: 'scroll' }} >
          <h4>{localization.get('continents.country.selector')}</h4>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between"
          }}>
            {continents.map(continent =>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={selectedContinents.includes(continent.id)}
                    onChange={() => this.onContinentSelect(continent.id)}
                  />
                }
                label={continent.name}
              />
            )}
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div>
              <TextField
                autoComplete={false}
                value={searchInput}
                onChange={e => this.setState({searchInput: e.target.value})}
                style={{width: "33%", margin: "10px 10px 10px 0"}}
                placeholder={localization.get('search')}
              />
              <span>{localization.get('continents.country.selected', selectedCountries.length)}</span>
            </div>
            <div style={{width: "100%", display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: 10, gridAutoRows: "minmax(70px, auto)"}}>
              {countries.filter(country => country.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1).map(country =>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={selectedCountries.includes(country.key)}
                      onChange={() => this.onCountrySelect(country.key)}
                    />
                  }
                  label={country.name}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}