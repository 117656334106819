import {post} from "../utils";

export default host => (albumId, emails, clientId) =>
  post(
    `${host}/albums/${albumId}/share`,
    JSON.stringify({
      emails,
      client_id: clientId
    })
  )
    .then(response => response.json())
    .then(json => json.data);
