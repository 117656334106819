import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import moment from "moment";

class Table extends Component {
  state = {
    total: "",
  };

  columns = () => [
    {
      id: "created_at",
      Header: localization.get("audit_log.date"),
      accessor: log => moment(log.created_at).format("DD-MM-YYYY"),
      resizeable: false,
      sortable: true,
      filterable: false,
      width: 100
    },
    {
      id: "email",
      Header: localization.get("table.email"),
      accessor: 'email',
      resizeable: true,
      sortable: true,
      filterable: true,
    },
    {
      Header: localization.get('table.status'),
      id: 'status',
      accessor: "status",
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          <option value="Logged">Logged</option>
          <option value="Error">Error</option>
          <option value="Invalid Email">Invalid Email</option>
          <option value="Blocked">Blocked</option>
        </select>,
      sortable: true
    },
    {
      id: "user_role",
      Header: localization.get("table.role"),
      accessor: log => log.user_role ? <span>{localization.get(`role.${log.user_role}`)}</span> : '',
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          <option value="supervisor">{localization.get('role.supervisor')}</option>
          <option value="sysadmin">{localization.get('role.sysadmin')}</option>
          <option value="artist">{localization.get('role.artist')}</option>
          <option value="multiclient">{localization.get('role.multiclient')}</option>
          <option value="content_supervisor">{localization.get('role.content_supervisor')}</option>
          <option value="channel_user">{localization.get('role.channel_user')}</option>
          <option value="admin">{localization.get('role.admin')}</option>
          <option value="user">{localization.get('role.user')}</option>
          <option value="readonly">{localization.get('role.readonly')}</option>
        </select>,
      sortable: false,
    },
    {
      id: "client[name]",
      Header: localization.get("table.client"),
      accessor: log => log.client ? log.client.name : '',
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value ? `like:${event.target.value}` : '')}
          placeholder="Buscar"
        />
      ),
      sortable: false,
    },
    {
      id: "ip",
      Header: localization.get("table.ip"),
      accessor: 'ip',
      resizeable: true,
      sortable: true,
      filterable: true,
      width: 130
    },
  ];

  render = () => {
    return (
      <div>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            requestTableData={paginationConfig => {

              return request.loginAttempt
                .getAll(paginationConfig)
                .then(response => {
                  this.setState({ total: response.meta.total });
                  return response;
                });
            }}
          />
        </div>
      </div>
    )
  };
}

export default Table;
