import React, {Component} from "react";
import {request} from "api-client";
import FormView from "./FormView";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization"
import {userisMultiClient} from "../../../api-client/core/authentication/utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      name: props.name || '',
      visibility: props.visibility || 'personalized',
      status: props.status || '',
      catalogCategoryId: props.catalogCategoryId || '1',
      clients: (props.clients && props.clients.length > 0) ? props.clients : [],
      audio: props.audio || {src: null, file: null},
      personalizedWatermark: props.personalizedWatermark || false,
      createdBy: !props.createdBy ? this.props.location.pathname.includes('music') ? {value: 20, label: 'Twins Music'} : !userisMultiClient() && {value: 0, label: 'Flowlike'} : props.createdBy,
      weight: props.weight || 0,
      content: props.content || (this.props.location.pathname.includes('music') ? 'music' : 'talents'),
      personalizedContent: props.personalizedContent ? this.mapPersonalizedContent(props.personalizedContent) : [],
      supervised: props.supervised,
      musicDealer: props.musicDealer,
    };
  }

  mapPersonalizedContent = (content) => {
    let contents = []
    request.contentCreatorType.getAll()
      .then(res => res.map(contentCreatorType => {
        if (content.includes(contentCreatorType.slug)) {
          contents.push({value: contentCreatorType.slug, label: contentCreatorType.name})
        }
      }))
    return contents
}

  requestAlbumCategories = () => request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }));

  handleSubmitRequest = () => {
    let data = {
      ...this.state,
      content: this.state.content === 'all' ? '' : this.state.content,
      personalizedContent: this.state.personalizedContent.map(item => item.value),
      createdBy: this.state.createdBy.value,
      clientsIds: this.state.clients.map(x => x.value),
      audioFile: this.state.audio.file,
      musicDealer: this.state.musicDealer && this.state.musicDealer.value,
    }
    return this.props.submitRequest(data).then(() => this.props.history.goBack());
  }

  onSubmitValidation = () => {
    if (userisMultiClient() && !this.state.createdBy) {
      GlobalSnackbar.show({
        message: localization.get('catalog.owner_required'),
        type: GlobalSnackbarTypes.ERROR,
      })
      return false
    } else if (this.state.content === 'personalized' && this.state.personalizedContent.length === 0) {
      GlobalSnackbar.show({
        message: localization.get('form.errors_present'),
        type: GlobalSnackbarTypes.ERROR,
      })
      return false
    } else if (!this.state.musicDealer && this.state.content == 'music') {
      GlobalSnackbar.show({
        message: localization.get('form.errors_must_complete_music_dealer'),
        type: GlobalSnackbarTypes.ERROR,
      })
      return false
    } else {
      return true
    }
  };

  onAudioChanged = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let src = URL.createObjectURL(file)
    this.setState({audio: {src, file}})
  }

  render = () => {
    const {
      name,
      visibility,
      status,
      clients,
      audio,
      personalizedWatermark,
      createdBy,
      weight,
      content,
      personalizedContent,
      supervised,
      musicDealer,
    } = this.state;

    return (
      <div>
        <FormViewContainer
          title={this.props.formTitle}
          initializationRequest={() => this.requestAlbumCategories()}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.handleSubmitRequest}
          validation={this.onSubmitValidation}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          {this.state.catalogCategories &&
          <FormView
            name={name}
            visibility={visibility}
            status={status}
            clients={clients}
            onClientsChange={clients => this.setState({clients})}
            onChange={e => this.setState({ [e.target.id || e.target.name]: e.target.value })}
            audio={audio}
            weight={weight}
            onAudioChanged={this.onAudioChanged}
            personalizedWatermark={personalizedWatermark}
            onPersonalizedWatermarkChange={(personalizedWatermark) => this.setState({personalizedWatermark})}
            createdBy={createdBy}
            onCreatedByChange={value => this.setState({createdBy: value})}
            content={content}
            onCatalogContentChange={e => this.setState({personalizedContent: []}, () => this.setState({content: e.target.value}))}
            personalizedContent={personalizedContent}
            onPersonalizedContentChange={personalizedContent => this.setState({personalizedContent})}
            from={this.props.location.pathname.includes('music') ? 'music' : 'talents'}
            supervised={supervised}
            musicDealer={musicDealer}
          />
          }
        </FormViewContainer>
      </div>
    );
  };
}

export default FormContainer;
