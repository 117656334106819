import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TableSubcomponent from "./TableSubcomponent";
import {ArrowForward, Block, FilterNone, Person} from "@material-ui/icons";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {getStoredUser, userIsClientAdmin} from "../../../api-client/core/authentication/utils";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,} from "@material-ui/core";
import SearchInput from "../../../components/Letflow/SearchInput";
import {red} from "@material-ui/core/colors";
import {addHttpToUrl} from "../../../utils";
import {Link} from "react-router-dom";

class LinksTable extends Component {
  state = {
    total: "",
    showLinks: "all",
    links: this.props.links,
    search: '',
    checked: getStoredUser().show_link_notification,
    copied: localization.get('copy_link')
  };

  handleAlertClosed = artistId => status => {
    if (status === "passed") this.refs.table.removeById(artistId);
  };

  writeTooltipText = () => {
    this.setState({copied: localization.get('copied')})
    setTimeout(
      () => this.setState({copied: localization.get('copy_link')}),
      2000
    )
  }

  buttons = link => {
    const { url, user} = link;
    const id = link.shortLinkTargets[0].id;

    const buttonBuilder = new TableButtonsBuilder();


    if (link.shortLinkTargets.length === 1) {
      buttonBuilder.withOther(
        <TableButton
          title={this.state.copied}
        >
          <CopyToClipboard
            text={`${url}:${link.shortLinkTargets[0].name}`}
            style={{bottom: 5, fontSize: 20}}
            onCopy={this.writeTooltipText}
            title={this.state.copied}
          >
            <FilterNone/>
          </CopyToClipboard>
        </TableButton>
      );

      buttonBuilder.withOther(
        <TableButton
          title={localization.get('link_expire')}
          onClick={() => request.shareLink.expire(id).then(this.refs.table.forceUpdate())}
        >
          <Block/>
        </TableButton>
      );

      buttonBuilder.withOther(
        <Link
          to={addHttpToUrl(url)}
          target={"_blank"}
        >
          <TableButton
            title={localization.get('links.go_to')}
          >
            <ArrowForward/>
          </TableButton>
        </Link>
        );
    }

    if (window.innerWidth > 425) {
      buttonBuilder.withOther(
        <TableButton
          title={user ? user.name : ''}
          style={{cursor: "default"}}
        >
          <Person/>
        </TableButton>
      )
    }

    return buttonBuilder.build();
  };

  columns = () => [
    {
      Header: localization.get("table.share_material"),
      accessor: "material",
      filterable: false,
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}>{(shortLink.value === "Content creator" && shortLink.original.material_description) ? shortLink.original.material_description.name : shortLink.value}</span>
    },
    {
      Header: localization.get("table.detail"),
      id: 'owner',
      accessor: shortLink =>  (shortLink.owner) ? shortLink.owner.name || shortLink.owner.title : '',
      filterable: false,
      sortable: false,
      show: window.innerWidth > 635,
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}>{shortLink.value}</span>
    },
    {
      Header: localization.get("table.created_at"),
      id: "created_at",
      accessor: shortLink => localization.moment(shortLink.created_at).fromNow(),
      filterable: false,
      sortable: true,
      show: window.innerWidth > 935,
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}> {shortLink.value} </span>
    },
    {
      Header: localization.get("table.to"),
      id: 'to',
      filterable: false,
      sortable: false,
      accessor: shortLink => (shortLink.shortLinkTargets ) && ( (shortLink.shortLinkTargets.length === 1) ? shortLink.shortLinkTargets[0].to : 'Total vistas' ),
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}> {shortLink.value} </span>
    },
    {
      Header: localization.get('table.views'),
      id: 'views',
      accessor: "views",
      filterable: false,
      sortable: true,
      minWidth: 70,
      show: window.innerWidth > 715,
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}> {shortLink.value} </span>
    },
    {
      Header: localization.get("table.last_view"),
      id: "updated_at",
      filterable: false,
      sortable: true,
      show: window.innerWidth > 825,
      Cell: shortLink => <span style={{ color: this.rowExpired(shortLink.original.shortLinkTargets)}}> {this.lastView(shortLink.original.shortLinkTargets)} </span>
    },
    {
      id: "actions",
      Header: () => <div style={{paddingRight: "10px"}}>{localization.get("table.actions")}</div>,
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      maxWidth: 145,
      minWidth: window.innerWidth > 425 ? 120 : 79,
      Footer: (
        <span style={{ float: "right", fontSize: "18px", marginTop: "15px", paddingRight: "5px" }}>
          <strong>Total: </strong>
          {this.state.total}
        </span>
      )
    }
  ];

  rowExpired = links => {
    if (links.length === 1){
      if (links[0].expired){
        return red['500']
      } else {
        return '#3C4858'
      }
    }
  };

  lastView = links => {
    let lastView = localization.moment(links[0].updated_at).fromNow();

    if (links.length === 1) {
      return lastView
    } else {
      links.forEach( (link) => {
        if (localization.moment(link.updated_at).fromNow() < lastView ) {
          lastView = localization.moment(link.updated_at).fromNow()
        }
      });
      return lastView
    }
  };

  makeSubComponent = row => <TableSubcomponent {...this.props} link={row.original} index={row.index}/>

  handleSearch = (search) => {
    this.state.search = search;
    this.refs.table.forceUpdate()
  };

  render = () => {
    return (
      <div>
        <div style={{margin: "30px 0"}}>
          <SearchInput handleSearch={this.handleSearch} helperText={localization.get("links.search_input")} style={{margin: 20}}/>
        </div>

        {userIsClientAdmin() &&
          <FormControl className="links-filter">
            <RadioGroup
              name="showLinks"
              value={this.state.showLinks}
              onChange={event => {
                this.state.showLinks = event.target.value;
                this.handleSearch();
              }}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel style={{width: "fit-content", float: "left", position: "relative"}} value="all" control={<Radio color="default"/>} label={localization.get('links.all')}/>
              <FormControlLabel style={{width: "fit-content", float: "left", position: "relative"}} value="mine" control={<Radio color="default"/>} label={localization.get('links.mine')}/>
            </RadioGroup>
          </FormControl>
        }

        <div className="allow-notificacion" style={{position: "absolute", top: "30px", right: "30px"}}>
          <FormControl className="links-filter">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checked}
                  onChange={() => this.setState( {checked: !this.state.checked}, () => request.user.updateLinkNotification())}
                  color="default"
                />
              }
              label={localization.get("links.allow_notificate")}
            />
          </FormControl>
        </div>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            subComponent={this.makeSubComponent}
            showPaginationBottom={true}
            showCustomPagination={true}
            requestTableData={paginationConfig => {
              if (this.state.search) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'query', filter: this.state.search}])
                }
              }
              if (this.state.showLinks === "mine") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'my_links', filter: true }]) }
              }
              return request.shareLink
                .getAllForClient(paginationConfig)
                .then(response => {
                  response.data.forEach(shortLink => shortLink.expanded = (shortLink.shortLinkTargets.length > 1));
                  this.setState({ total: response.meta.total });
                  return response;
                });
            }}
          />
        </div>
      </div>
    )
  };
}

export default LinksTable;
