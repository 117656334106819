import {get} from "../utils";

const getMusic = host => (clientId, musicId) => {

  const resource = `/clients/${clientId}/musics/${musicId}?include=audio:url;albums`
  const url = host + resource

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
}

export default getMusic