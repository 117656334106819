import React, {Component} from "react";
import {request} from "../../../api-client";
import {makeFitImageUrl, makeImageUrl} from "../../../utils";
import PropTypes from "prop-types";
import browserHistory from "../../../utils/browserHistory";
import * as VCS from "../../../components/Letflow/ContentCreatorViewComponents";
import localization from "../../../config/localization"
import TrackResult from "../../../components/Letflow/TrackResult";
import TrackTable from "../../../components/Letflow/TrackTable";
import moment from "moment";
import '../Artist/Artist.css'
import {
  catalogClientView,
  getActiveChannel,
  getActiveClient, getStoredChannel,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import ProfilePicture from "../../../assets/img/default-avatar.png";
import Logo from "../../../assets/img/logo-black.png";
import { onImageError } from "../HomeElements/utils";

export default class Speaker extends Component {
  state = {
    speaker: null,
    voices: [],
    musics: [],
    favorite: false
  };

  constructor() {
    super();
  }

  componentDidMount = () => {
    this.fetchSpeaker();
    getActiveClient() && request.client.get(getActiveClient()).then(response => this.setState({client: response}))
    document.getElementsByTagName("html")[0].scrollTop = 0;
  }

  fetchSpeaker = () =>
    request.speaker.getForClient(this.props.match.params.speakerId || this.props.speakerId, 'include=image;voices.audio:duration,url,id;voices.audio.waveform;favorite;voices.speaker.image;country;socialLinks')
      .then(speaker => {
        setPageTitle(speaker.name)
        this.setState({
          speaker: {
            id: speaker.id,
            name: speaker.name,
            description: speaker.description,
            imageUrl: makeImageUrl(speaker, window.innerWidth, window.innerHeight, 'grayscale'),
            country: speaker.country,
            birthdate: speaker.birthdate,
            socialLinks: speaker.socialLinks
          },
          favorite: speaker.favorite,
          voices: speaker.voices
        });
      });

  toggleSpeakerFavorite = () => {
    if (this.state.favorite) {
      this.setState({favorite: false})
      request.speakerFavorite.remove(this.state.speaker.id)
    } else {
      this.setState({favorite: true})
      request.speakerFavorite.add(this.state.speaker.id)
    }
  }

  render = () => {
    if (!this.state.speaker) {
      return null;
    }
    const {speaker, voices} = this.state
    return (
      <VCS.ViewContainer bgImage={speaker.imageUrl}>
        <VCS.TopContainer>
          {this.props.modal &&
          <div style={
            window.innerWidth > 600 ? 
              {
                left: (catalogClientView() && this.state.width > 1200) && "66px",
                position: "absolute",
                float: "left",
                width: "auto",
                cursor: "pointer",
                top: 0,
                zIndex: 1,
              }
            :
              {
                position: "absolute",
                cursor: "pointer",
                top: 0,
                left: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }
          }>
            <div className="navbar-image-container-link" style={{width: "auto"}} onClick={() => this.props.onModalClose()}>
              <img
               className="navbar-image" style={{filter: "invert(1)"}}
                src={(this.state.client && this.state.client.use_logo && this.state.client.image) ?
                  makeFitImageUrl(this.state.client, 300, 35, '', 'png') :
                    Logo}
                alt={`Logo ${getActiveChannel() ? getStoredChannel().name : "Feater"}`}
                onError={onImageError}
              />
            </div>
          </div>
          }
          <VCS.TopContainerInfoContainer style={{maxWidth: window.innerWidth > 600 ? '58%' : '70%'}} InfoContainer>
            <VCS.TopContainerName style={{overflow: 'hidden', textOverflow:'ellipsis'}} Name>{speaker.name}</VCS.TopContainerName>
            {speaker.country && <VCS.TopContainerCountry>{`${localization.get('nationality')}: ${speaker.country.name}`}</VCS.TopContainerCountry>}
            {speaker.birthdate && <VCS.TopContainerBirthdate>{`${localization.get('age')}: ${moment().diff(speaker.birthdate, 'years')}`}</VCS.TopContainerBirthdate>}
            <VCS.TopContainerDescription>{speaker.description}</VCS.TopContainerDescription>
          </VCS.TopContainerInfoContainer>
          <VCS.SocialBanner
            socialLinks={speaker.socialLinks}
            name={speaker.name}
            picture={speaker.imageUrl || ProfilePicture}
            onClose={() => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose()}
            favorite={this.state.favorite}
            onToggleFavorite={this.toggleSpeakerFavorite}
            isModal={this.props.modal}
          />
        </VCS.TopContainer>


        <div style={{float: "left", position: "relative", width: "100%"}}>
          <VCS.WorkContainer WorkContainer title={localization.get('work')}>
            <div className="artist-musics-container">
              <div className="artist-musics-title">{localization.get('artist.new_releases')}</div>
              {voices.length > 0 &&
              <TrackResult
                rows={<TrackTable {...this.props} hideImage={true} type={'voice'} hideSuggestions={true} tracks={voices} fromHome={false}/>}
              />
              }
            </div>
          </VCS.WorkContainer>
        </div>
      </VCS.ViewContainer>
    );
  };
}

Speaker.propTypes = {
  id: PropTypes.number.isRequired
};

