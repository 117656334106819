import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveClient} from "../authentication/utils";

const url = (host, id, options) => appendPaginationQueryParamsToUrl(`${host}/clients/${id}/tags?`, {
  ...options,
  alreadyHasQueryParams: true
})

export default host => (id, options) =>
  get(url(host, id, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)