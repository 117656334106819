import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types'
import {Card, CardContent, TextField} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import "./TagSelection.css";
import localization from "../../../config/localization";
import {get} from "lodash";
import CustomTabs from "../../CustomTabs/CustomTabs";

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

class TagSelection extends React.Component {

  constructor(props) {
    super(props);
    let tagGroups = props.data;

    this.state = {
      amountBanners: 4,
      tagGroups: tagGroups,
      filteredTagGroups: tagGroups,
      searchInput: "",
    }
    
    this.setState({filteredTagGroups: this.addAvailability()});
  }

  componentWillReceiveProps = props => {
    let tagGroups = props.data;
    this.setState({ tagGroups: tagGroups, filteredTagGroups: this.state.searchInput ? tagGroups.map(tg => ({...tg, tags: tg.tags.filter(t => t.name.toLowerCase().includes(this.state.searchInput.toLowerCase()))})).filter(tg => tg.tags.length > 0) : tagGroups })
  }
  
  onSearch = searchInput => {
    this.setState({searchInput: searchInput, filteredTagGroups: (searchInput && searchInput.length > 0) ? this.state.tagGroups.map(tg => ({...tg, tags: tg.tags.filter(t => t.name.toLowerCase().includes(searchInput.toLowerCase()))})).filter(tg => tg.tags.length > 0) : this.state.tagGroups})
  }

  handleClickTag = (e, tagGroupId, tagId) => {
    let tagGroup = this.state.tagGroups.find((tagGroup) => tagGroup.id === tagGroupId);

    let tag = tagGroup.tags.find((tag) => tag.id === tagId);
    if (tag.state === 'available') {
      tag.state = 'selected'
      this.setState(this.state)
    } else if (tag.state === 'selected' && this.props.allowUnselected) {
      tag.state = 'unselected'
      this.setState(this.state)
    } else if (tag.state === 'unselected' && this.props.allowUnselected) {
      tag.state = 'available'
      this.setState(this.state)
    } else if (tag.state === 'selected' && !this.props.allowUnselected) {
      tag.state = 'available'
      this.setState(this.state)
    }
  }

  handleChange = () => {
    let selected = [], unselected = []
    this.state.tagGroups.forEach(tagGroup => {
      tagGroup.tags.forEach(tag => {
        if (tag.state === "selected") {
          selected.push(tag.id)
        }
        if (tag.state === 'unselected') {
          unselected.push(tag.id)
        }
      })
    });
    if (this.props.onSelectedTagsChanged) {
      this.props.onSelectedTagsChanged(selected, unselected);
    }
  }

  addAvailability = () => 
    this.state.tagGroups.forEach(tagGroup => {
      tagGroup.tags.forEach(tag => {
        if (!tag.state) {
          tag.state = "available"
        }
      })
    });
  

  renderTagGroup = (tagGroup, tags, i) => {
    const { classes } = this.props;
    const selectedColor = this.props.color || "#9d4d5c";

    const hideDivs = (divId, ArrowId) => {
      let x = document.getElementById(divId);
      let up = document.getElementById(`${ArrowId}-up`);
      let down = document.getElementById(`${ArrowId}-down`);
    
      if (x.style.display === "none") {
        x.style.display = "inline-block";
        down.style.display = "none";
        up.style.display = "block";
      } else {
        x.style.display = "none";
        down.style.display = "block";
        up.style.display = "none";
      }
    }

    return (
      <div key={i}>
        <div style={{ width: "100%" }}>
          <h4 style={this.props.bloomCloudsView ? {fontSize: '14px', textTransform: 'uppercase', marginBottom: '0px', position: "relative",}: {position: "relative",}} onClick={() => this.props.colapsable && hideDivs(`card-tg-${tagGroup.id}`, `tg-id-${tagGroup.id}`)}>
            {tagGroup.name}
            <ExpandLess style={{display: "none", position:"absolute", right:15, top:12}} id={`tg-id-${tagGroup.id}-up`}/>
            <ExpandMore style={{display: this.props.colapsable ? "block" : 'none', position:"absolute", right:15, top:12}} id={`tg-id-${tagGroup.id}-down`}/>
          </h4>
        </div>
        <div id={`card-tg-${tagGroup.id}`} style={this.props.bloomCloudsView ? { width: '100%', marginBottom: '30px', display: this.props.colapsable ? "none" : 'block'} : { width: "100%", display: this.props.colapsable ? "none" : 'block' }}>
          {tags.length > 0 && tags.map((tag, j) => {
            return (
              <Chip key={j}
                style={
                  this.props.bloomCloudsView
                  ? 
                    {
                      minWidth: '120px',
                      marginLeft: '0px',
                      marginBottom: "5px",
                      fontWeight: "400",
                      backgroundColor: tag.state === "selected" ? '#141414' : tag.state === 'available' ? 'white' :'#e0e0e0',
                      color: tag.state === "selected" ? 'white' : tag.state === 'available' ? '#141414' : 'grey',
                      cursor: tag.state === "disable" ? 'auto' : 'pointer',
                      border: '1px solid #e4e4e4'
                    } 
                  :
                    this.props.cardView 
                  ?
                    {
                      minWidth: '100px',
                      marginLeft: "0px",
                      marginBottom: "5px",
                      fontWeight: "400",
                      backgroundColor: tag.state === "selected" ? selectedColor : tag.state === 'unselected' ? '#e68484' :'#e0e0e0',
                      color: (tag.state === "selected" || tag.state === 'unselected') ? 'white' : tag.state === "disable" ? 'grey' : 'black',
                      cursor: tag.state === "disable" ? 'auto' : 'pointer'
                    }
                  :
                    {
                      marginLeft: "10px",
                      marginBottom: "5px",
                      fontWeight: "400",
                      backgroundColor: tag.state === "selected" ? selectedColor : tag.state === 'unselected' ? '#e68484' :'#e0e0e0',
                      color: (tag.state === "selected" || tag.state === 'unselected') ? 'white' : tag.state === "disable" ? 'grey' : 'black',
                      cursor: tag.state === "disable" ? 'auto' : 'pointer'
                    }
                }
                label={tag.name}
                className={classes.chip}
                onClick={(e) => {
                  if (this.props.disabled) return
                  if(this.props.homeElementTagSelection) {
                    this.props.onSelectedTagsChanged(tag.id)
                  }else {
                    this.handleClickTag(e, tagGroup.id, tag.id);
                    this.handleChange()
                  }
                  }
                }
              />
            )
          })}
        </div>
      </div>
    );
  }

  render() {    
    const categories = this.state.filteredTagGroups.reduce((acc, tagGroup) => {
      if (get(tagGroup.types[0], "type") == 'music') return acc;
      if (tagGroup.types.length == 0) return acc;
      if (acc.some(c => c.id == get(tagGroup.types[0], 'contentCreatorType.id'))) return acc;
      
      acc.push(tagGroup.types[0].contentCreatorType)

      return acc
    }, [])

    return (
      <div style={{ float: "left", position: "relative", width: "100%" }}>
        {this.props.showFilter &&
          <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection:"column", maxWidth: "100%", marginBottom: 30, width: "100%" }}>
              <TextField
                inputProps={{
                  className: "dashboard-tag-selector-search",
                  spellCheck: false,
                }}
                autoComplete={false}
                placeholder={localization.get("search.tags")}
                value={this.state.searchInput}
                onChange={e => this.onSearch(e.target.value)}
              />
            </div>
          </div>
        }

        {(this.props.tabsView && categories.length > 0) ?
          <CustomTabs
            headerColor="dark"
            tabs={
              categories.map(category => ({
                tabName: category.name,
                tabIcon: category.icon,
                tabContent: (
                  <>
                  {this.state.filteredTagGroups.filter(tg => tg.types[0].contentCreatorType.id == category.id).map((tagGroup, i) => {
                    let tags = tagGroup.tags;
                    
                    if (this.props.useParents) {
                      const selectedTags = this.state.tagGroups.map(tg => tg.tags.filter(t => t.state === 'selected')).flat();
          
                      tags = tags.filter(tag => {
                        if (tag.state == "selected" || !tagGroup.parent) return true;
          
                        return tag.parents.some(p => selectedTags.some(st => st.id == p.id))
                      })
                    }

                    return this.renderTagGroup(tagGroup, tags, i)
                  })}
                  </>
                )
              }))
            }
          /> 
            :
          <>
            {this.state.filteredTagGroups.map((tagGroup, i) => {
              let tags = tagGroup.tags;
              
              if (this.props.useParents) {
                const selectedTags = this.state.tagGroups.map(tg => tg.tags.filter(t => t.state === 'selected')).flat();

                tags = tags.filter(tag => {
                  if (tag.state == "selected" || !tagGroup.parent) return true;

                  return tag.parents.some(p => selectedTags.some(st => st.id == p.id))
                })
              }

              return this.props.cardView ? (
                <>
                  <Card style={{overflow: 'visible'}}>
                    <CardContent>
                      {this.renderTagGroup(tagGroup, tags, i)}
                    </CardContent>
                  </Card>
                  <br/>
                </>
              ) : this.renderTagGroup(tagGroup,tags, i)
            })}
          </>
      }

      </div>
    )
  }

}

TagSelection.propTypes = {
  data: PropTypes.array.isRequired,
}

export default withStyles(styles)(TagSelection);
