import React, {Component} from "react";

import {request} from "../../../api-client";
import ClientFormView, {feeCurrencies, feePaymentTypes} from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import {isNullOrUndefined} from "util";
import localization from '../../../config/localization'

export default class extends Component {
  state = {
    name: "",
    hasFee: false,
    fee_amount: 0,
    fee_currency: feeCurrencies.ARS,
    fee_payment_type: feePaymentTypes.CARD,
    own_channels_discount: 0,
    publicity_internet_discount: 0,
    publicity_radio_discount: 0,
    publicity_tv_discount: 0,
    publicity_all_discount: 0,
    bichannel_discount: 0,
    duration_radio_discount: 0,
    duration_tv_all_discount: 0,
    duration_internet_discount: 0,
    edition_discount: 0,
    reductions_discount: 0,
    own_channels_reductions_discount: 0,
    license_type: 'no_licenses',
    license_quantity: 0
  };

  requestPlan = () =>
    request.licensePlan.get(this.props.match.params.id).then(plan =>
        this.setState({
          name: plan.name,
          hasFee: !isNullOrUndefined(plan.fee_amount),
          fee_currency: plan.fee_currency || feeCurrencies.ARS,
          fee_amount: plan.fee_amount || 0,
          fee_payment_type: plan.fee_payment_type || feePaymentTypes.CARD,
          own_channels_discount: plan.own_channels_discount * 100,
          publicity_internet_discount: plan.publicity_internet_discount * 100,
          publicity_radio_discount: plan.publicity_radio_discount * 100,
          publicity_tv_discount: plan.publicity_tv_discount * 100,
          publicity_all_discount: plan.publicity_all_discount * 100,
          bichannel_discount: plan.bichannel_discount * 100,
          duration_radio_discount: plan.duration_radio_discount * 100,
          duration_tv_all_discount: plan.duration_tv_all_discount * 100,
          duration_internet_discount: plan.duration_internet_discount * 100,
          edition_discount: plan.edition_discount * 100,
          reductions_discount: plan.reductions_discount * 100,
          own_channels_reductions_discount: plan.own_channels_reductions_discount * 100,
          license_type: plan.only_own_channels == plan.only_other_purpose && plan.only_own_channels > 0 ? 'both' : plan.only_own_channels == 1 ? 'own_channels' : plan.only_other_purpose ? 'other_purpose' :  'no_licenses',
          license_quantity: plan.license_quantity || 0
        })
    );

  requestUpdate = () =>
    request.licensePlan
      .update(this.props.match.params.id, this.state)
      .then(() => this.props.history.push("/panel/licensePlans"));

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onImageChange = image => this.setState({ image });

  render = () => {
    const {
      disabled,
      name,
      hasFee,
      fee_currency,
      fee_amount,
      fee_payment_type,
      own_channels_discount,
      publicity_internet_discount,
      publicity_radio_discount,
      publicity_tv_discount,
      publicity_all_discount,
      bichannel_discount,
      duration_radio_discount,
      duration_tv_all_discount,
      duration_internet_discount,
      edition_discount,
      reductions_discount,
      own_channels_reductions_discount,
      only_own_channels,
      license_type,
      license_quantity
    } = this.state;
    return (
      <div>
        <FormViewContainer
          title={localization.get('license_plans.update')}
          initializationRequest={() => this.requestPlan()}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestUpdate}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <ClientFormView
            disabled={disabled}
            onChange={this.onChange}
            name={name}
            hasFee={hasFee}
            allowFee={() => this.setState({hasFee: true})}
            disallowFee={() => this.setState({hasFee: false})}
            feeCurrency={fee_currency}
            feeAmount={fee_amount}
            feePaymentType={fee_payment_type}
            licenseQuantity={license_quantity}
            ownChannelsDiscount={own_channels_discount}
            publicityInternetDiscount={publicity_internet_discount}
            publicityRadioDiscount={publicity_radio_discount}
            publicityTvDiscount={publicity_tv_discount}
            publicityAllDiscount={publicity_all_discount}
            bichannelDiscount={bichannel_discount}
            durationRadioDiscount={duration_radio_discount}
            durationTvAllDiscount={duration_tv_all_discount}
            durationInternetDiscount={duration_internet_discount}
            editionDiscount={edition_discount}
            reductionsDiscount={reductions_discount}
            ownChannelsReductionsDiscount={own_channels_reductions_discount}
            onlyOwnChannels={only_own_channels}
            licenseType={license_type}
          />
        </FormViewContainer>
      </div>
    );
  };
}
