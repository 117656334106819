import {isNullOrUndefined} from "util";
import moment from "moment";

export const makeFormData = ({
  subtitle,
  translations,
  video,
  active,
  contentCreatorId,
  image,
  audio,
  selectedTags,
  videoId,
  imageId,
  audioId,
  binaryId,
  link,
  batchVideoId,
  batchImageId,
  batchAudioId,
  spanishSubtitlesId,
  englishSubtitlesId,
  italianSubtitlesId,
  germanSubtitlesId,
  frenchSubtitlesId,
  englishSubtitles,
  spanishSubtitles,
  italianSubtitles,
  germanSubtitles,
  frenchSubtitles,
  demo,
  releaseDate,
  expireDate,
  embedSource,
  embedUrl,
  downloadable,
  onlyArgentina,
  unsplashImage,
  requireDrm,
  requireLogin,
  hasPreRoll,
  limited,
  viewLimit,
  timeToTrack,
  allowDownload,
  allowedRegions,
  hasRegionBlock,
  inappropriateContent,
  driveImage,
  driveVideo,
  driveAudio,
  driveBinary,
  extraInformation,
  createdAt = null,
  isProtected,
  createdWhere,
  channelId,
  accessLevel,
}) => {
  const form = new FormData();
  if (subtitle) form.append('subtitle', subtitle)
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`translations[${translation.language}][title]`, translation.title)
      if (!isNullOrUndefined(translation.description)) form.append(`translations[${translation.language}][description]`, translation.description)
    })
  }
  if (contentCreatorId) form.append('content_creator_id', contentCreatorId);
  if (video) form.append('video', video, video.name);
  if (image) form.append('image', image, image.name);
  if (audio) form.append('audio', audio, audio.name);
  if (!isNullOrUndefined(active)) form.append('active', active);
  if (selectedTags) selectedTags.forEach(x => form.append("tags_ids[]", x));
  if(videoId) form.append('videoId', videoId)
  if(imageId) form.append('imageId', imageId)
  if(audioId) form.append('audioId', audioId)
  if(binaryId) form.append('binaryId', binaryId)
  if(link) form.append('link', link)
  if(batchVideoId) form.append('batchVideoId', batchVideoId)
  if(batchImageId) form.append('batchImageId', batchImageId)
  if(batchAudioId) form.append('batchAudioId', batchAudioId)
  if(spanishSubtitlesId) form.append('spanishClosedCaptionsId', spanishSubtitlesId)
  if(englishSubtitlesId) form.append('englishClosedCaptionsId', englishSubtitlesId)
  if(italianSubtitlesId) form.append('italianClosedCaptionsId', italianSubtitlesId)
  if(germanSubtitlesId) form.append('germanClosedCaptionsId', germanSubtitlesId)
  if(frenchSubtitlesId) form.append('frenchClosedCaptionsId', frenchSubtitlesId)
  if (!isNullOrUndefined(spanishSubtitles)) form.append('spanishClosedCaptions', spanishSubtitles ? 1 : 0);
  if (!isNullOrUndefined(englishSubtitles)) form.append('englishClosedCaptions', englishSubtitles ? 1 : 0);
  if (!isNullOrUndefined(italianSubtitles)) form.append('italianClosedCaptions', italianSubtitles ? 1 : 0);
  if (!isNullOrUndefined(germanSubtitles)) form.append('germanClosedCaptions', germanSubtitles ? 1 : 0);
  if (!isNullOrUndefined(frenchSubtitles)) form.append('frenchClosedCaptions', frenchSubtitles ? 1 : 0);
  if (!isNullOrUndefined(demo)) form.append('demo', demo ? 1 : 0);
  if (releaseDate) form.append("release_date", moment.utc(releaseDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (expireDate) form.append("expire_date", moment.utc(expireDate).set({minute: 0,second:0}).format('YYYY-MM-DD HH:mm:ss'));
  if (embedSource) form.append('embed_source', embedSource)
  if (embedUrl) form.append('embed_url', embedUrl)
  if (!isNullOrUndefined(downloadable)) form.append('downloadable', downloadable ? 1 : 0);
  if (!isNullOrUndefined(onlyArgentina)) form.append('only_argentina', onlyArgentina);
  if (!isNullOrUndefined(requireDrm)) form.append('require_drm', requireDrm  ? 1 : 0 );
  if (!isNullOrUndefined(requireLogin)) form.append('require_login', requireLogin  ? 1 : 0);
  if (unsplashImage) {
    form.append('image_url', unsplashImage.url)
    form.append('image_name', unsplashImage.name)
  }
  if (!isNullOrUndefined(hasPreRoll)) form.append('has_pre_roll', hasPreRoll  ? 1 : 0);
  if (!isNullOrUndefined(limited)) form.append("limited", limited);
  if (!isNullOrUndefined(viewLimit)) form.append("view_limit", viewLimit);
  if (!isNullOrUndefined(timeToTrack)) form.append("time_to_track", timeToTrack);
  if (!isNullOrUndefined(allowDownload)) form.append("allow_download", allowDownload? 1 : 0);
  if (!isNullOrUndefined(hasRegionBlock)) form.append("region_restricted", hasRegionBlock == "true" ? 1 : 0);
  if (allowedRegions && allowedRegions.length > 0) allowedRegions.forEach(x => form.append("available_countries[]", x))
  if (!isNullOrUndefined(inappropriateContent)) form.append('inappropriate_content', inappropriateContent ? 1 : 0)
  if (driveImage) form.append("drive_image_file_id", driveImage);
  if (driveVideo) form.append("drive_video_file_id", driveVideo);
  if (driveAudio) form.append("drive_audio_file_id", driveAudio);
  if (!isNullOrUndefined(extraInformation)) form.append('extra_information', extraInformation)
  if (createdAt) form.append('created_at', moment.utc(createdAt).format('YYYY-MM-DD HH:mm:ss'))
  if (!isNullOrUndefined(isProtected)) form.append('protected', isProtected ? 1: 0)
  if (createdWhere) form.append('created_where', createdWhere)
  if (channelId) form.append('channel_id', channelId)
  if (accessLevel) form.append('access_level', accessLevel)
  
  
  return form
};

export const includeQuery = 'include=video.thumbnail;contentCreator;audio;binary;tags.group';
