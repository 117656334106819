import {post} from "../utils";
import {makeFormData} from "./utils";

export default host => ({
  filename,
  type,
  label,
  token,
  videoId,
  imageId,
  audioId,
  fileId,
}) => {
  return post(`${host}/public/batchUpload`,
    makeFormData({
      filename,
      type,
      label,
      token,
      videoId,
      imageId,
      audioId,
      fileId,
    }))
    .then(response => response.json())
    .then(response => response.data)
}
