import React, {Component} from "react";
import {ServerSidePaginationTable, TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeUrlWithTableParams, makeWaveformUrl} from '../../../utils'
import localization from '../../../config/localization'
import EventManager from "../../../utils/EventManager/EventManager";
import {events} from "../../../utils/EventManager";
import {
  getStoredUser,
  userIsArtist,
  userisMultiClient,
  userIsSupervisor
} from "../../../api-client/core/authentication/utils";
import {deepOrange} from "@material-ui/core/colors";
import {Done} from "@material-ui/icons";

class MusicsTable extends Component {

  state = {
    total: 0
  }

  handleAlertClosed = musicId => status => {
    this.refs.table.removeById(musicId)
  }

  handleAlertClosedApproved = musicId => status => {
      this.refs.table.removeById(musicId)
      EventManager.getInstance().dispatch(events.UPDATE_DASHBOARD_NOTIFICATIONS)
  }

  buttons = music => {
    const {id, title,} = music

    const editUrl = makeUrlWithTableParams(`/panel/musics/${id}/edit`);

    const builder = new TableButtonsBuilder();

    if(!userIsArtist() && !getStoredUser().artist_view) {
      builder.withEdit(editUrl)
      builder.withDelete(title, () => request.music.delete(id), this.handleAlertClosed(id))
      if(music.created_by_artist) {
        builder.withOther(
          <TableButton
            title={localization.get('music.artist_approve')}
            onClick={() => this.props.history.push(`/panel/musics/${music.id}/edit`)}
          >
            <Done style={{color: "red"}}/>
          </TableButton>
        )
      } else if(!music.created_by_artist && userIsSupervisor() || !music.created_by_artist && userisMultiClient()) {
        builder.withApproved(title, () => request.music.approve(id), this.handleAlertClosedApproved(id), false, music.albums.length)
      } else {
        builder.withApproved(title, () => request.music.approve(id), this.handleAlertClosedApproved(id), true, music.albums.length)
      }
    }

    const tableMusics = this.refs.table.getData()
    const providedMusicIndex = tableMusics.findIndex(x => x.id === music.id)

    const buttonMusics = tableMusics.map(tableMusic => ({
      title: tableMusic.title,
      subtitle: '',
      fetchAudioUrl: () => request.music.audioUrl(tableMusic.id),
      onAnyClick: () => this.props.history.push(this.makeEditUrl(tableMusic)),
      waveform: makeWaveformUrl(tableMusic)
    }))

    builder.withNewPlay(buttonMusics, providedMusicIndex)
    return builder.build()
  }

  columns = () => [
    {
      Header: localization.get("music.reference"),
      accessor: "code",
      Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder="Buscar" />,
      width: 110
    },
    {
      Header: localization.get("music.title"),
      accessor: "title",
      Filter: ({ filter, onChange }) => <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder="Buscar" />,
      maxWidth: 400,
      Cell: cell => (
        <span style={{ color: cell.original.exclusive ? deepOrange['600'] : undefined }}>
          {cell.original.exclusive && <div style={{ fontSize: 10, lineHeight: "normal" }}>exclusivo</div>}
          {cell.value}
        </span>
      )
    },
    {
      Header: localization.get("music.album"),
      id: 'album[title]',
      accessor: music => music.albums.length > 0 ? music.albums[0].title : '-' ,
      maxWidth: 300,
      sortable: false,
      filterable:false,
    },
    {
      Header: localization.get('table.artist'),
      id: 'artist_id',
      accessor: music => music.artist ? music.artist.name : '-',
      width: 120,
      sortable: false,
      filterable: false
    },
    {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145,
      Footer: <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>{localization.get('table.total')}:  </strong>{this.state.total}</span>
    }
  ]


  render = () => {
    return (
      <div style={{width: "100%", float: "left", position: "relative"}}>
        <ServerSidePaginationTable
          ref='table'
          columns={this.columns()}
          requestTableData={paginationConfig => {
            return request.music.getAllPendingApproval(paginationConfig).then((response) => {
              this.setState({ total: response.meta.total })
              return response
            })
          }}
        />
      </div>
    )
  }
}

export default MusicsTable
