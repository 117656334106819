import React, {Component} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import localization from "../../../../config/localization";
import {Delete, Edit, OpenInNew, Share} from "@material-ui/icons";
import {request} from "../../../../api-client";
import GlobalConfirmationDialog from "../../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import ShareOnSocials from "../../../../components/Letflow/ShareOnSocials";
import {getMainTranslation, getUseTools} from "../../../../api-client/core/authentication/utils";
import GlobalSnackbar from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import analyticsInstance from "../../../../analytics/analyticsInstance";

class ReelTable extends Component {
  state = {
    total: "",
    search: "",
    showShareDialog: false,
    shareUrl: false,
  };

  componentDidMount() {
    this.getReels()
  }

  getReels = () =>
  request.clientReels.getAll()
      .then(reels => this.setState({reels}));

  handleShareDialogClose = () => this.setState({showShareDialog: false, shareUrl: false});

  openShareDialog = reel => {
    analyticsInstance.share({
      name: getMainTranslation(reel, "title"),
      type: "Reel",
    })
    if (getUseTools()) {
      request.clientReels.shareByChannel(reel.id)
        .then(rest => this.setState({showShareDialog: true, shareUrl: rest.url }))
        .catch(GlobalSnackbar.showGenericError)
    } else {
      this.setState({reelToShare: reel, showShareDialog: true})
    }
  }

  handleDelete = (reel) => GlobalConfirmationDialog.showDelete(
    { elementName: reel.title,
      deleteRequest: () => request.clientReels.delete(reel.id).then(() => this.getReels())
    })

  render = () => {
    return (
      <div style={{ width: "100%", float: "left", position: "relative", marginTop: 20 }}>
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={this.handleShareDialogClose}
          url={this.state.shareUrl ? this.state.shareUrl :
            `${window.location.protocol}//${window.location.hostname}/public/reel/${this.state.reelToShare && this.state.reelToShare.share_token}`}
          darkMode={true}
        />
        {this.state.reels &&
          this.state.reels.map(reel =>
            <div className="reel-table-row">
              <div className="reel-table-name" style={{color: "black"}}>{reel.title}</div>
              <div className="reel-table-actions">
                <div className="reel-table-button">
                  <IconButton style={{color: "grey", fontSize: 15}} onClick={() => window.open(`${window.location.origin}/public/reel/${reel.share_token}`, "_blank")}>
                    <Tooltip  title={localization.get('reel.preview')} leaveDelay={1} placement='top'>
                      <OpenInNew/>
                      </Tooltip>
                  </IconButton>
                </div>
                <div className="reel-table-button">
                  <IconButton style={{color: "grey"}} onClick={() => this.props.handleEdit(reel)}>
                    <Tooltip  title={localization.get('reel.edit')} leaveDelay={1} placement='top'>
                      <Edit/>
                    </Tooltip>
                  </IconButton>
                </div>
                <div className="reel-table-button">
                  <IconButton style={{color: "grey"}} onClick={() => this.openShareDialog(reel)}>
                     <Tooltip  title={localization.get('reel.share')} leaveDelay={1} placement='top'>
                      <Share/>
                    </Tooltip>
                  </IconButton>
                </div>
                <div className="reel-table-button">
                  <IconButton style={{color: "grey"}} onClick={() => this.handleDelete(reel)}>
                    <Tooltip  title={localization.get('reel.delete')} leaveDelay={1} placement='top'>
                      <Delete/>
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  };
}

export default ReelTable;
