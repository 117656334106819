import React from "react";
import { request } from "../../../../api-client";
import { getStoredChannel, getStoredUser } from "../../../../api-client/core/authentication/utils";
import browserHistory from "../../../../utils/browserHistory";
import ContentCreationTable from "./ContentCreationTable";

export default class extends React.Component {

  constructor(props) {
    super(props);
    let currentUser = getStoredUser();
    let currentChannel = getStoredChannel();
    let creator = currentUser.contentCreators.find(c => c.channel_id == currentChannel.id);
    this.state = {
      contentCreatorId: creator ? creator.id : null,
      creations: []
    }
  }

  componentDidMount() {
    this.requestCreations();
  }
  
  requestCreations = () => request.contentCreator.get(this.state.contentCreatorId,"include=allContentCreations.audio;allContentCreations.image;allContentCreations.binary;allContentCreations.embededContent;allContentCreations.video;downloadableMaterial.video;downloadableMaterial.image;downloadableMaterial.audio;downloadableMaterial.binary;mainMaterial").then(response => {
    this.setState({ creations: response.allContentCreations})
  })

  handleEdit = (c) => {
    browserHistory.push(`contents/${c}/edit`);
  }

  handleCreate = () => {
    browserHistory.push("contents/new");
  }

  render = () => {
    return (
      <ContentCreationTable
        creatorId={this.state.contentCreatorId}
        creations={this.state.creations}
        onEditCreations={this.handleEdit}
        onCreateCreations={this.handleCreate}
      />
    )
  }
}