import React from 'react'
import {request} from '../../../api-client';
import {
  FormControl,
} from "@material-ui/core";
import localization from "../../../config/localization"
import {TextValidator} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import FilePondUploader from "../../../components/Letflow/FilePondUploader";
import {ImagePicker} from "../../../components/Letflow/Form";


const FormContent = ({
   id,
   name,
   products,
   onProductsChange,
   channel,
   onChannelChange,
   onChange,
   disableForm,
   updateDisableForm,
   image,
   onImageChange,
   onSetPreview,
   onMainProductChange,
   mainProduct,
}) => {
  return (

    <div>
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get("form.name")}
        name="name"
        value={name}
        onChange={onChange}
        validators={["required"]}
        errorMessages={[localization.get("validator.is_required")]}
      />
      <br />
      <br />
      {!id ?
        <FormControl fullWidth>
          <span>{localization.get('channel')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('channel')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onChannelChange}
            value={channel}
            loadOptions={inputValue => {
              let filterBy = [{column: "name", filter: inputValue}];
              return request.channel.getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                .then(res => res.map(content => ({value: content.id, label: `${content.name}`})))
            }}
            defaultOptions
          />
        </FormControl>
        :
        <TextValidator
          style={{ width: "100%" }}
          label={localization.get('channel')}
          name="channelName"
          value={channel.name}
          disabled={true}
        />
      }
      {channel &&
        <>
        <FormControl fullWidth>
          <br />
          <span>{localization.get('products')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('products')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onProductsChange}
            value={products}
            isMulti
            loadOptions={inputValue => {
              let filterBy = [
                { column: "name", filter: inputValue},
                { column: "forPacks", filter: 1}
              ];

              return request.products.getAll(channel.value, {filterBy, orderBy: {column: "name", type: "ASC"}})
                .then(json => json.data)
                .then(res => res.map(content => ({value: content.id, label: `${content.content.name || content.content.title}`})))

            }}
            defaultOptions
          />
        </FormControl>
          {products.length > 0}
          <FormControl fullWidth>
            <br />
            <span>{localization.get('product.main')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('products')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onMainProductChange}
              value={mainProduct}
              loadOptions={(inputValue, callback) => {
                callback(products.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())))
              }}
              defaultOptions={products}
            />
          </FormControl>
        </>
      }
      <br />
      <br />
      <span>{localization.get('image')}</span>
      {(image && image.src) && <ImagePicker image={image.src} previewOnly={true} id={image.id}/>}
      <FilePondUploader
        disableForm={disableForm}
        updateDisableForm={updateDisableForm}
        file={image}
        id={"image"}
        type={"image"}
        allowedTypes={["image/*"]}
        onIdChange={onImageChange}
        setPreview={onSetPreview}
        channelId={channel && channel.value}
      />
      <div className="filepond-clarification">{localization.get('creation.filepond_clarification.image_size', "500x500 px")}</div>
    </div>
  )
}

export default FormContent
