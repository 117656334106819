import React, {Component} from "react";
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import queryString from "querystring";
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: "",
      description: "",
    })),
    subtitle: "",
    active: "1",
    video: { src: null, file: null, hls: null, dash: null },
    image: { src: null, file: null },
    audio: { src: null, file: null },
    contentCreator: '',
    tagGroups: [],
    videoId: null,
    imageId: null,
    audioId: null,
    binaryId: null,
    link: '',
    disableForm: false,
    videosRadio: 'filepond',
    imagesRadio: 'filepond',
    audiosRadio: 'filepond',
    selectedBatchVideoLink: '',
    selectedBatchVideoFile: '',
    selectedBatchImageLink: '',
    selectedBatchImageFile: '',
    selectedBatchAudioLink: '',
    selectedBatchAudioFile: '',
    fileSelector: 'default',
    subtitles: false,
    spanishSubtitlesId: null,
    englishSubtitlesId: null,
    italianSubtitlesId: null,
    germanSubtitlesId: null,
    frenchSubtitlesId: null,
    spanishSubtitles: false,
    englishSubtitles: false,
    italianSubtitles: false,
    germanSubtitles: false,
    frenchSubtitles: false,
    releaseDate: null,
    expireDate: null,
    hasExpiredDate: false,
    isPermanentContent: "1",
    embedSource: "",
    embedUrl: "",
    downloadable: false,
    unsplashImage: '',
    requireDrm: false,
    requireLogin: false,
    showRequireLoginHelper: false,
    hasPreRoll: false,
    showDRMDialog: false,
    limited: "0",
    viewLimit: 0,
    timeToTrack: 1,
    allowDownload: false,
    hasRegionBlock: "false",
    allowedRegions: [],
    inappropriateContent: false,
    driveImage: null,
    driveVideo: null,
    driveAudio: null,
    driveBinary: null,
    client: null,
    extraInformation: "",
    hasExtraInfo: false,
    contentCreatorType: null,
    isProtected: false,
    accessLevel: null,
  };

  requestContentCreationCreation = () => {

    const {
      translations,
      subtitle,
      video,
      image,
      audio,
      active,
      selectedTags,
      videoId,
      imageId,
      audioId,
      binaryId,
      link,
      selectedBatchVideoFile,
      selectedBatchImageFile,
      selectedBatchAudioFile,
      spanishSubtitlesId,
      englishSubtitlesId,
      italianSubtitlesId,
      germanSubtitlesId,
      frenchSubtitlesId,
      spanishSubtitles,
      englishSubtitles,
      italianSubtitles,
      germanSubtitles,
      frenchSubtitles,
      releaseDate,
      expireDate,
      embedSource,
      embedUrl,
      downloadable,
      unsplashImage,
      requireDrm,
      requireLogin,
      hasPreRoll,
      limited,
      viewLimit,
      timeToTrack,
      allowDownload,
      hasRegionBlock,
      allowedRegions,
      inappropriateContent,
      driveImage,
      driveVideo,
      driveAudio,
      driveBinary,
      extraInformation,
      isProtected,
      accessLevel,
    } = this.state;

    const contentCreatorId = queryString.parse(this.props.location.search)['?contentCreator'];

    const contentCreationData = {
      subtitle,
      active,
      translations,
      contentCreatorId,
      video: video.file,
      image: image.file,
      audio: audio.file,
      selectedTags,
      videoId,
      imageId,
      audioId,
      binaryId,
      link,
      batchVideoId: selectedBatchVideoFile.value,
      batchImageId: selectedBatchImageFile.value,
      batchAudioId: selectedBatchAudioFile.value,
      spanishSubtitlesId,
      englishSubtitlesId,
      italianSubtitlesId,
      germanSubtitlesId,
      frenchSubtitlesId,
      spanishSubtitles,
      englishSubtitles,
      italianSubtitles,
      germanSubtitles,
      frenchSubtitles,
      releaseDate,
      expireDate,
      embedSource,
      embedUrl,
      downloadable,
      unsplashImage,
      requireDrm,
      requireLogin,
      hasPreRoll,
      limited,
      viewLimit,
      timeToTrack,
      allowDownload,
      hasRegionBlock,
      allowedRegions,
      inappropriateContent,
      driveImage,
      driveVideo,
      driveAudio,
      driveBinary,
      extraInformation,
      isProtected,
      accessLevel,
  };

    return request.contentCreation.create(contentCreationData)
      .then(() => this.props.history.goBack())
  };

  requestContentCreator = () => request.contentCreator.get(queryString.parse(this.props.location.search)['?contentCreator'], 'include=type.client')
      .then(contentCreator => this.setState({
        contentCreator: contentCreator,
        contentCreatorType: contentCreator.type.slug,
        allowImage: contentCreator.type.allow_image,
        allowAudio: contentCreator.type.allow_audio,
        allowVideo: contentCreator.type.allow_video,
        allowBinary: contentCreator.type.allow_binary,
        allowLink: contentCreator.type.allow_link,
        allowEmbed: contentCreator.type.allow_embed,
        client: contentCreator.type.client,
        downloadable: contentCreator.type.downloadable,
        allowDownload: contentCreator.type.downloadable,
      }))

  requestTags = () => request.tagGroup.getAll({filterBy: [{ column: 'type', filter: `${this.state.contentCreatorType}`},{column: 'inheritable', filter: true}]}).then((tagGroups) => this.setState({tagGroups}))

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onSetImageFromUnsplash = (url, name) => this.setState({unsplashImage: {url, name}})

  onVideoChange = video => video ? this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)}) : this.setState({videoId: null, video: {src: null}})
  onImageChange = image => image ? this.setState({imageId: image.id, image: { src:null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onAudioChange = audio => audio ? this.setState({audioId: audio.id, audio: {src: null}}) : this.setState({audioId: null, audio: {src: null}})
  onBinaryChange = binary => binary ? this.setState({binaryId: binary.id, binary: {src: null, filename: null }, hasBinary: true}) : this.setState({binaryId: null, binary: { src: null, filename: null }})
  onSubtitlesChange = (name, subtitles) => subtitles ? this.setState({[name]: subtitles.id}) : this.setState({[name]: null})

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {

      this.setState({image : {src: this.state.imagePreview}})
    }
  }

  onSelectedTagsChanged = selectedTags => this.setState({ selectedTags, tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags) });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
      }))
    }))
  };

  onSubmitValidation = () => {
    if (!this.state.imageId && !this.state.image.src && !this.state.selectedBatchImageFile
      && !this.state.audioId && !this.state.audio.src && !this.state.selectedBatchAudioFile
      && !this.state.videoId && !this.state.video.src && !this.state.selectedBatchVideoFile
      && !this.state.driveAudio && !this.state.driveVideo && !this.state.driveImage
    ) {
      GlobalSnackbar.show({message: localization.get('form.validate_files'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'youtube' && !this.state.embedUrl.includes('youtu.be/')) {
      GlobalSnackbar.show({message: localization.get('error_youtube'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'vimeo' && !this.state.embedUrl.includes('vimeo.com/')) {
      GlobalSnackbar.show({message: localization.get('error_vimeo'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else if (this.state.embedSource === 'spotify' && !this.state.embedUrl.includes('open.spotify.com/')) {
      GlobalSnackbar.show({message: localization.get('error_spotify'), type: GlobalSnackbarTypes.ERROR});
      return false
    } else {
      return true
    }
  }

  onBatchRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'videosRadio') {
      this.setState({selectedBatchVideoLink: '', selectedBatchVideoFile: '', videoId: null, video: {src: null}})
    }
    if (e.target.name === 'imagesRadio') {
      this.setState({selectedBatchImageLink: '', selectedBatchImageFile: '', imageId: null, image: {src: null}})
    }
    if (e.target.name === 'audiosRadio') {
      this.setState({selectedBatchAudioLink: '', selectedBatchAudioFile: '', audioId: null})
    }
  }

  onBatchVideoLinkChange = (selectedBatchVideoLink) => {
    this.setState({selectedBatchVideoLink: '', selectedBatchVideoFile: ''},
      () => {
        if(selectedBatchVideoLink.protected) {
          this.setState({
            selectedBatchVideoLink,
            isProtected: 1,
            allowDownload: 0,
          })
        }else {
          this.setState({ selectedBatchVideoLink })
        }
      })
  }

  onBatchImageLinkChange = (selectedBatchImageLink) => {
    this.setState({selectedBatchImageLink: '', selectedBatchImageFile: ''}, () => this.setState({selectedBatchImageLink}))
  }
  onBatchAudioLinkChange = (selectedBatchAudioLink) => {
    this.setState({selectedBatchAudioLink: '', selectedBatchAudioFile: ''}, () => this.setState({selectedBatchAudioLink}))
  }
  onBatchPreRollVideoLinkChange = (selectedBatchPreRollVideoLink) => {
    this.setState({selectedBatchPreRollVideoLink: '', selectedBatchPreRollVideoFile: ''}, () => this.setState({selectedBatchPreRollVideoLink}))
  }

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('view.content_creation.create')}
          submitRequest={() => this.requestContentCreationCreation()}
          validation={this.onSubmitValidation}
          onBack={() => this.props.history.goBack()}
          initializationRequest={() => this.requestContentCreator().then(() => this.requestTags())}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          disabled={this.state.disableForm}
          actionsExtraSpace={true}
        >
          <FormContent
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });


              this.setState({ translations });
            }}
            translations={this.state.translations}
            subtitle={this.state.subtitle}
            active={this.state.active}
            onChange={this.onChange}
            onActiveChange={(active) => this.setState({active}, () => this.state.active && this.setState({releaseDate: null, expireDate: null}))}
            onImageChange={this.onImageChange}
            image={this.state.image}
            video={this.state.video}
            audio={this.state.audio}
            allowAudio={this.state.allowAudio}
            allowImage={this.state.allowImage}
            allowVideo={this.state.allowVideo}
            allowBinary={this.state.allowBinary}
            allowLink={this.state.allowLink}
            allowEmbed={this.state.allowEmbed}
            tagGroups={this.state.tagGroups}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            onVideoChange={this.onVideoChange}
            onAudioChange={this.onAudioChange}
            onBinaryChange={this.onBinaryChange}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            videosRadio={this.state.videosRadio}
            imagesRadio={this.state.imagesRadio}
            audiosRadio={this.state.audiosRadio}
            onBatchRadiosChange={this.onBatchRadiosChange}
            isTalent={this.props.location.pathname.includes('talents')}
            selectedBatchVideoLink={this.state.selectedBatchVideoLink}
            selectedBatchVideoFile={this.state.selectedBatchVideoFile}
            onBatchVideoLinkChange={this.onBatchVideoLinkChange}
            onBatchVideoFileChange={selectedBatchVideoFile => this.setState({selectedBatchVideoFile})}
            selectedBatchImageLink={this.state.selectedBatchImageLink}
            selectedBatchImageFile={this.state.selectedBatchImageFile}
            onBatchImageLinkChange={this.onBatchImageLinkChange}
            onBatchImageFileChange={selectedBatchImageFile => this.setState({selectedBatchImageFile})}
            selectedBatchAudioLink={this.state.selectedBatchAudioLink}
            selectedBatchAudioFile={this.state.selectedBatchAudioFile}
            onBatchAudioLinkChange={this.onBatchAudioLinkChange}
            onBatchAudioFileChange={selectedBatchAudioFile => this.setState({selectedBatchAudioFile})}
            onRadioChange={radio => this.setState({fileSelector: radio.target.value, downloadable: false})}
            fileSelector={this.state.fileSelector}
            subtitles={this.state.subtitles}
            spanishSubtitles={this.state.spanishSubtitles}
            englishSubtitles={this.state.englishSubtitles}
            italianSubtitles={this.state.italianSubtitles}
            germanSubtitles={this.state.germanSubtitles}
            frenchSubtitles={this.state.frenchSubtitles}
            onCheckBoxChange={(name, value) => this.setState({[name]: value})}
            onSubtitlesChange={this.onSubtitlesChange}
            releaseDate={this.state.releaseDate}
            expireDate={this.state.expireDate}
            hasExpiredDate={this.state.hasExpiredDate}
            onHasExpiredDateChange={hasExpiredDate => this.setState({hasExpiredDate}, () => !this.state.hasExpiredDate && this.setState({expireDate: null}))}
            isPermanentContent={this.state.isPermanentContent}
            onIsPermanentContentChange={isPermanentContent => this.setState({isPermanentContent}, () => this.state.isPermanentContent && this.setState({expireDate: null, releaseDate: null}))}
            embedSource={this.state.embedSource}
            embedUrl={this.state.embedUrl}
            downloadable={this.state.downloadable}
            onSetImageFromUnsplash={this.onSetImageFromUnsplash}
            requireDrm={this.state.requireDrm}
            requireLogin={this.state.requireLogin}
            showRequireLoginHelper={this.state.showRequireLoginHelper}
            updateHelper={() => this.setState({showRequireLoginHelper: !this.state.showRequireLoginHelper})}
            unsplashImage={this.state.unsplashImage}
            hasPreRoll={this.state.hasPreRoll}
            showDRMDialog={this.state.showDRMDialog}
            updateDRMDialog={() => this.setState({showDRMDialog: !this.state.showDRMDialog})}
            limited={this.state.limited}
            viewLimit={this.state.viewLimit}
            timeToTrack={this.state.timeToTrack}
            onLimitedChange={limited => this.setState({limited: limited}, () => this.setState({mainMaterial: 0, viewLimit: 0, timeToTrack: 1}))}
            allowDownload={this.state.allowDownload}
            contentCreator={this.state.contentCreator}
            views={0}
            hasRegionBlock={this.state.hasRegionBlock}
            onHasRegionBlockChange={hasRegionBlock => {
              if(hasRegionBlock == "false") {
                this.setState({ allowedRegions: [] })
              }
              this.setState({hasRegionBlock})
            }}
            allowedRegions={this.state.allowedRegions}
            onChangeAllowedRegions={allowedRegions => this.setState({allowedRegions})}
            inappropriateContent={this.state.inappropriateContent}
            updateInappropriateContent={() => this.setState({inappropriateContent: !this.state.inappropriateContent})}
            driveImage={this.state.driveImage}
            driveVideo={this.state.driveVideo}
            driveAudio={this.state.driveAudio}
            driveBinary={this.state.driveBinary}
            onDriveFileChange={(target, id) => this.setState({ [target]: id })}
            client={this.state.client}
            extraInformation={this.state.extraInformation}
            hasExtraInfo={this.state.hasExtraInfo}
            isProtected={this.state.isProtected}
            accessLevel={this.state.accessLevel}
          />
        </FormViewContainer>
      </div>
    )
  }
}
