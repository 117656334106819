import {appendPaginationQueryParamsToUrl, post} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => (filters) => {
  const options = {filterBy: filters}

  return post(appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}/reel`, {...options, alreadyHasQueryParams: false}))
    .then(response => response.json())
    .then(response => response.data)
}
