import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import {getMostImportantAlbum, makeWaveformUrl} from "../../../utils";
import localization from "../../../config/localization";
import moment from "moment";
import LicenseSubcomponent from "../LicenseSubcomponent";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {AddShoppingCart, Clear} from "@material-ui/icons";
import {
  Exclusivity,
  InternetBudgets,
  LicenseTypeIds,
  MediaTypeIds,
  SadaicCategories,
  SadaicDurations
} from "../../../components/Letflow/LicenseWizard/utils/constants";
import LicenseWizard from "../../../components/Letflow/LicenseWizard";
import GlobalLicenseWizardDialog from "../../../components/Letflow/Dialog/GlobalLicenseWizardDialog";
import {makeImageUrlWithSize} from "../../../layouts/Home/HomeElements/utils";
import {extentIdToRegionIdAndCountriesForRegion} from "../../../components/Letflow/LicenseWizard/utils/mappers";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";

class PendingTable extends Component {
  state = {
    total: "",
    showPendingTags: "all",
    openLicenseDialog: false,
    licenseId: '',
    selectedCatalog: '',
    openDeclineDialog: false,
    decliningLicenseId: null,
  };

  buttons = license => {
    const builder = new TableButtonsBuilder();
    const tableLicense = this.refs.table.getData()

    const buttonMusics = tableLicense.map(license => ({
      title: license.music.title,
      subtitle: '',
      fetchAudioUrl: () => request.music.audioUrl(license.music.id),
      onAnyClick: () => this.props.history.push(this.makeEditUrl(license.music)),
      waveform: makeWaveformUrl(license.music)
    }))

    const providedMusicIndex = tableLicense.findIndex(x => x.id === license.id)

    builder.withNewPlay(buttonMusics, providedMusicIndex);

    builder.withOther(
      <TableButton
        title={localization.get("license.finish_license")}
        onClick={() => this.openLicenseWizard(license)}
      >
        <AddShoppingCart/>
      </TableButton>
    );

    builder.withOther(
      <TableButton
        title={localization.get("license.decline")}
        onClick={() => this.openDeclineDialog(license)}
      >
        <Clear/>
      </TableButton>
    );


    return builder.build();
  };

  openLicenseWizard = license => {
    const album = getMostImportantAlbum(license.music.albums);
    const exclusivity =
      license.exclusivity &&
      license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL &&
      license.medias.length === 1 &&
      license.medias.map(x => x.id).includes(MediaTypeIds.INTERNET)
        ? Exclusivity.THREE_MONTHS
        : Exclusivity.NONE;

    const sadaicDuration =
      license.type.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA && license.duration
        ? license.duration == SadaicDurations.SIX_MONTHS
        ? SadaicDurations.SIX_MONTHS
        : SadaicDurations.TWELVE_MONTHS
        : SadaicDurations.SIX_MONTHS;

    const sadaicVersions = LicenseWizard.makeDefaultSadaicVersions().map((version, i) => {
      const licenseVersion = license.versions[i];
      if (licenseVersion) {
        version.seconds = licenseVersion.length.id;
        version.reductions = licenseVersion.reductions;
      }
      return version;
    });

    GlobalLicenseWizardDialog.show({
      track: {
        id: license.music.id,
        title: license.music.title,
        owner: license.music.owner,
        waveform: makeWaveformUrl(license.music),
        image: makeImageUrlWithSize(album, 'xs'),
      },
      client: GlobalLicenseWizardDialog.mapApiClient(license.client),
      initializeWithValues: {
        licenseType: license.type.id,
        regions: license.extents.map(extent => extentIdToRegionIdAndCountriesForRegion(extent.id)),
        medias: license.medias.map(media => media.id),
        reductions: license.reductions,
        exclusivity,
        extraDuration: license.extension,
        biChannel: license.bichannel,
        editionAccordingToSeconds: license.edition,
        clientName: license.project.customer || '',
        productName: license.project.product || '',
        campaignName: license.project.title || '',
        brandName: license.project.brand || '',
        sadaicDuration,
        sadaicCategory: license.category ? license.category.id : SadaicCategories.RESTRINGIDO_I,
        sadaicVersions,
        internetBudget: license.budgets && license.budgets.length > 0 ? license.budgets[0].id : InternetBudgets.UP_TO_2000,
        unlimitedReductions: license.unlimitedReductions,
      },
      toApprove: {toApprove: license.id}
    });
  };

  openDeclineDialog = license => this.setState({openDeclineDialog: true, decliningLicenseId: license.id})
  handleCloseDeclineDialog = () => this.setState({openDeclineDialog: false, decliningLicenseId: null, declineMessage: ""}, () => this.refs.table.forceUpdate())
  declineLicense = () => request.license.decline(this.state.decliningLicenseId, {reason: this.state.declineMessage}).then(() => this.handleCloseDeclineDialog())

  decliningDialog = () =>
    <Dialog open={this.state.openDeclineDialog} onBackdropClick={this.handleCloseDeclineDialog} onEscapeKeyDown={this.handleCloseDeclineDialog}>
      <DialogTitle>{localization.get('licenses.decline_title')}</DialogTitle>
      <DialogContent>
        <div>{localization.get('licenses.decline_instructions')}</div>
        <div style={{ display: 'flex', alignItems: 'baseline', width: "100%", minWidth: 400, margin: "30px 0" }}>
          <TextField
            style={{ flex: 1, width: "100%" }}
            name="declineMessage"
            id="declineMessage"
            autoComplete={false}
            multiline={true}
            value={this.state.declineMessage}
            onChange={e => this.setState({declineMessage: e.target.value})}
          />
        </div>
        <DialogActions>
          <Button onClick={this.handleCloseDeclineDialog}>
            {localization.get("cancel")}
          </Button>
          <Button onClick={this.declineLicense}>
            {localization.get("send")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>

  columns = () => [
    {
      id: "starts_at",
      Header: localization.get('table.starts_at'),
      accessor: "starts_at",
      filterable: false,
      Cell: cell => {
        return <span>{moment(cell.value).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      id: "client[name]",
      Header: localization.get('table.client'),
      accessor: "client.name",
      sortable: false,
    },
    {
      id: "music[title]",
      Header: localization.get('table.track_title'),
      accessor: license => license.music.title,
      sortable: false,
    },
    {
      id: "user[name]",
      Header: localization.get('table.user'),
      accessor: license => license.user.name,
      sortable: false,
    },
    {
      id: "music.catalog[name]",
      Header: localization.get('table.catalog'),
      accessor: license => license.music.catalog.name,
      sortable: false,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 145,
      Footer: (
        <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>Total: </strong>
          {this.state.total}
        </span>
      )
    }
  ];

  render = () => {
    if (this.state.openLicenseDialog) {
      this.openLicenseWizard(this.props.openLicenseDialog)
    }
    return (
      <div>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          {this.decliningDialog()}
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            requestTableData={paginationConfig => {
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'state', filter: "pending" }]) };

              return request.license.getAll(paginationConfig).then(response => {
                this.setState({ total: response.meta.total });
                return response;
              });
            }}
            subComponent={row => <LicenseSubcomponent row={row} license={row.original}/>}
          />
        </div>
      </div>
    );
  };
}

export default PendingTable;