import React, {Component} from "react";
import {request} from "../../../api-client";
import Form from "../Form";
import localization from '../../../config/localization'

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalog: null
    };
  }

  componentDidMount = () => {
    this.requestCatalog();
  };

  requestCatalog = () => request.catalog.get(this.props.match.params.id).then((catalog) => this.setState({catalog}));

  requestUpdate = data => request.catalog.update(data);

  render = () => {

    if (!this.state.catalog) {
      return null
    }

    const {
      id,
      name,
      visibility,
      status,
      clients,
      createdBy,
      weight,
      content,
      personalized_content,
      supervised,
      musicDealer,
    } = this.state.catalog;

    const audio = this.state.catalog.watermark && {
      src: this.state.catalog.watermark.url,
      file: {
        name: this.state.catalog.watermark.original_name
      }
    };

    return (
      <Form
        {...this.props}
        submitRequest={this.requestUpdate}
        formTitle={localization.get('catalog.update_catalog')}
        id={id}
        name={name}
        visibility={visibility}
        status={status}
        clients={clients.map(client => ({value: client.id, label: client.name}))}
        audio={audio}
        weight={weight}
        personalizedWatermark={!!audio}
        createdBy={createdBy && {value: createdBy.id, label: createdBy.name}}
        content={content}
        personalizedContent={personalized_content}
        supervised={supervised}
        musicDealer={musicDealer && {value: musicDealer.id, label: musicDealer.name}}
      />
    );
  };
}
