import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import Form from '../Form'
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  requestCreation = state => {
    this.setState({ disabled: true })

    const {
      type,
      order,
      selectedTypes,
      groupType,
      client,
      isAdmin,
      translations,
      childrens,
    } = state

    return request.tagGroup.create({
      translations,
      type,
      order,
      inheritable: groupType === 'both',
      creation_group: groupType === 'material',
      selectedTypes,
      clientId: client && client.value,
      isAdmin,
      childrensIds: childrens ? childrens.map(t => t.value) : [],
    }).then(() => {
      let url =  ''
      if(this.props.location.pathname.includes('music')) {
        url = makeUrlWithTableParams(`/panel/music/tag_groups`);
      } else if (this.props.location.pathname.includes('talents')) {
        url = makeUrlWithTableParams(`/panel/talents/tag_groups`);
      } else if (this.props.location.pathname.includes('audiovisual')) {
        url = makeUrlWithTableParams(`/panel/audiovisual/tag_groups`);
      } else {
        url = makeUrlWithTableParams(`/panel/editorial/tag_groups`);
      }
      return this.props.history.push(url)
    })
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })

  onImageChange = image => this.setState({ image })

  render = () => {
    return (
      <Form
        {...this.props}
        title={localization.get('tag_group.create_tag_group')}
        submitRequest={this.requestCreation}
        isMusic={this.props.location.pathname.includes('music')}
        isTalent={this.props.location.pathname.includes('talents')}
        isAudiovisual={this.props.location.pathname.includes('audiovisual')}
        isEditorial={this.props.location.pathname.includes('editorial')}
      />
    )
  }
}
