import {post} from "../utils";
import {makeFormData} from "./utils";

/**
 * @typedef {object} Client
 * @property {*} client_id
 * @property {*} role_id
 * @property {*} can_license
 */

/**
 * @typedef {object} RequestData
 * @property {string} name
 * @property {string} email
 * @property {string} description
 * @property {*} imageFile
 * @property {*} roleId
 * @property {Client[]} client_roles
 */

const create = host => {
  /**
   * @param {RequestData} data
   */
  const request = data => post(`${host}/users`, makeFormData(data)).then(response => response.json());
  return request;
};

export default create;
