import React from "react";
import "./Drive.css";
import localization from "../../../config/localization";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import Sidebar from "./Sidebar";
import {request} from "../../../api-client";
import HomeAddButton from "../../../components/Letflow/Button/HomeAddButton";
import FileUploader from "../../../components/Letflow/ClientFileUploader";
import {swap} from "react-grid-dnd";
import {FormControl, FormControlLabel, Radio, RadioGroup, TextField, Tooltip} from "@material-ui/core";
import Paginator from "../../../components/Letflow/Paginator";
import FileStorageItem from "../../../components/Letflow/FileStorageItem";
import {Attachment, Clear, PlusOne, Settings, Share} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import browserHistory from "../../../utils/browserHistory";
import ClientSocialLinksDialog from "../HomeElements/ClientSocialLinksDialog";
import {makeThumbnailUrl} from "../HomeElements/utils";
import {getMainTranslation, getUseTools, setPageTitle} from "../../../api-client/core/authentication/utils";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import {UploadMany} from "./UploadMany";
import {get} from "lodash";
import analyticsInstance from "../../../analytics/analyticsInstance";

const styles = theme => ({
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

class Drive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      client: props.client || null,
      contentHeight: "100vh",
      selectedTags: [],
      files: [],
      showFileUploadDialog: false,
      data: [],
      search: '',
      newTag: '',
      newOrderFiles: [],
      sidebarTags: [],
      fileType: "all",
      openTagDialog: false,
      showSocialLinkDialog: false,
      showShareDialog: false,
      selectedFiles: [],
      reel: null,
      creatingFile: false,
      sharedWithMe: false,
      shareUrl: false,
      showUploadManyFileUploadDialog: false,
      selectedFilesCheck: false,
      paginationConfig: {},
      totalFiles: 0,
    };
  }

  componentDidMount = () => {
    setPageTitle(localization.get("title.my_drive"))
    document.getElementsByTagName("html")[0].scrollTop = 0;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.has('search') ?
      request.clientFile.get(urlParams.get('search'))
        .then(file => {
          if (file.data.type === 'video') {
            file.data = {
              ...file.data,
              thumbnail: makeThumbnailUrl(file.data.video, 600, 300)
            }
          }
          this.setState({
            files: file,
            newOrderFiles: [file.data],
            paginationConfig: {search: urlParams.get('search')},
          })
        })
      :
    this.requestFiles({})

    this.requestTags()
  };

  requestTags = () => {
    let filter = [];
    // filter.push({ column: "files", filter: true }) Filter tags where has files

    request.clientTags.getAll({
      filterBy: filter
    })
      .then(tags => this.setState({tags, sidebarTags: tags}, () => this.addSelectedToTagList())
  )};


  addSelectedToTagList = () =>
    this.setState({
      sidebarTags:
        this.state.sidebarTags.map(tag => ({
          ...tag,
          state: this.state.selectedTags.find(selectedTag => selectedTag == tag.id)
            ? "selected" : "available"
        }))
    });

  handleTagSearch = (e) =>
    this.setState({
      sidebarTags: this.state.tags.filter(tag => {
        return tag.name.indexOf(e.target.value) !== -1;
      })
    });

  // target id will only be set if dragging from one dropzone to another.
   onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
     const nextState = swap(this.state.newOrderFiles, sourceIndex, targetIndex);
     this.setState({newOrderFiles: nextState})
   };

  socialLinkDialog = () =>
    <ClientSocialLinksDialog
      open={this.state.openClientSettingDialog}
      handleClose={() => this.setState({openClientSettingDialog: false})}
    />

  showSocialLinkDialog = () => this.setState({openClientSettingDialog: true});
  handleDrawerToggle = () => this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  handleShareDialogClose = () => this.setState({ showShareDialog: false, shareUrl: false });
  handleCloseDialog = () => this.setState({showFileUploadDialog: false, creatingFile: false}, ()=> this.requestTags());
  handleSharedWithMeChange = () => this.setState({sharedWithMe: !this.state.sharedWithMe }, () => this.requestFiles({}));
  selectAllFiles = () => this.setState({selectedFiles: [], selectedFilesCheck: true})
  handleCloseUploadManyDialog = () => this.setState({showUploadManyFileUploadDialog: false},
    () => this.requestFiles({}).then(this.clearAllTags))

  onSelectedTagChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag)) {
      this.setState(
        {selectedTags:
            this.state.selectedTags.filter(tags => tags !== selectedTag)
        },
        () => this.requestFiles({}))
    }else {
      this.state.selectedTags.push(selectedTag);

      this.setState({selectedTags: this.state.selectedTags}, () => this.requestFiles({}))
    }
  };

  onSelectedFilesChanged = selectedFile => {
    if (this.state.selectedFiles.includes(selectedFile.id)) {
      this.setState({selectedFiles: this.state.selectedFiles.filter(files => files !== selectedFile.id), selectedFilesCheck: false})
    }else {
      this.setState({selectedFiles: this.state.selectedFiles.concat([selectedFile.id]), selectedFilesCheck: false})
    }
  };

  onEditFile = (file) => {
    this.setState({showFileUploadDialog: true, editingFile: file})
  }

  requestFiles = ({page = 0, perPage = 12}) => {
    browserHistory.push({pathname: window.location.pathname});
    let filter = [];
    this.state.selectedTags.length > 0 && filter.push({column: "tags_ids", filter: this.state.selectedTags || ""});
    this.state.search !== "" && filter.push({ column: "q", filter: this.state.search || "" });
    this.state.fileType !== "all" && filter.push({column: "type", filter: this.state.fileType || ""});
    this.state.sharedWithMe && filter.push({column: "shared_with_me", filter: true});

    if(this.state.fileType !== 'selected') {
      return request.clientFile.getAll(
        {
          page,
          perPage,
          filterBy: filter
        }
      ).then(files => {
        this.setState({
          files,
          totalFiles: get(files, "meta.total", 0),
          paginationConfig: filter,
          newOrderFiles: files.data.map((file, index) => {
            if (file.type === 'video') {
              file = {
                ...file,
                thumbnail: makeThumbnailUrl(file.video, 600, 300)
              }
            }
            return file
          }
        )})
      })
    }else {
      request.clientFile.getMany({page, perPage}, this.state.selectedFiles.join(','))
        .then(files => {
          this.setState({
            files,
            totalFiles: get(files, "meta.total", 0),
            newOrderFiles: files.data.map((file, index) => {
              if (file.type === 'video') {
                file = {
                  ...file,
                  thumbnail: makeThumbnailUrl(file.video, 600, 300)
                }
              }
              return file
            }
          )})
        })
    }
  };

  openShareDialog = () => {
    const shareMethod = this.state.selectedFilesCheck ? "createByFilters" : "create"

    return request.clientReels[shareMethod](this.state.selectedFiles.length < 1 ? this.state.paginationConfig : this.state.selectedFiles)
      .then(reel => {
        analyticsInstance.share({
          name: getMainTranslation(reel, "title"),
          type: "Reel",
        })
        if (getUseTools()) {
          request.clientReels.shareByChannel(reel.id)
            .then(rest => this.setState({showShareDialog: true, shareUrl: rest.url, reel}))
            .catch(GlobalSnackbar.showGenericError)
        } else { this.setState({reel, showShareDialog: true}) }
      })
  }


  render = () => {
    const { classes } = this.props;
    return (
      <div id="innerinner" className="home-playlists" style={{backgroundColor: "white"}}>
        {this.socialLinkDialog()}
        <UploadMany
          open={this.state.showUploadManyFileUploadDialog}
          handleClose={this.handleCloseUploadManyDialog}
          tags={this.state.tags}
          onSelectedTagDeleted={ id => this.setState({tags: this.state.tags.filter(tag => tag.id !== id)})}
          requestTags={this.requestTags}
        />
        <FileUploader
          show={this.state.showFileUploadDialog}
          handleClose={this.handleCloseDialog}
          requestFiles={this.requestFiles}
          creatingFile={this.state.creatingFile}
          file={this.state.editingFile || false}
          isReel={false}
          onSelectedTagDeleted={ id => this.setState({tags: this.state.tags.filter(tag => tag.id !== id)})}
          />
        <ShareOnSocials
          show={this.state.showShareDialog}
          handleClose={this.handleShareDialogClose}
          url={this.state.shareUrl ? this.state.shareUrl :
            `${window.location.protocol}//${window.location.hostname}/public/reel/${this.state.reel && this.state.reel.share_token}`}
          darkMode={true}
        />
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={this.handleDrawerToggle}
          className={classes.navIconHide}
          style={{
            zIndex:5
          }}
        >
          <MenuIcon />
        </IconButton>

        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <Sidebar
              handleDrawerToggle={this.handleDrawerToggle}
              handleViewChange={this.handleSharedWithMeChange}
              sharedWithMe={this.state.sharedWithMe}
              classes={classes}
              tags={this.state.sidebarTags}
              onSelectedTagChanged={this.onSelectedTagChanged}
              handleTagSearch={this.handleTagSearch}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            PaperProps={{
              style: {
                position: "relative",
                float: "left",
                width: "250px",
                boxShadow: "0 1px 10px 0 rgba(0,0,0,.1)",
                borderRight: "none",
                backgroundColor: "rgb(247,247,247)",
                zIndex: "2",
                minHeight: "100vh",
                height: this.state.contentHeight
              }
            }}
          >
            <Sidebar
              handleDrawerToggle={this.handleDrawerToggle}
              handleViewChange={this.handleSharedWithMeChange}
              sharedWithMe={this.state.sharedWithMe}
              classes={classes}
              tags={this.state.sidebarTags}
              onSelectedTagChanged={this.onSelectedTagChanged}
              handleTagSearch={this.handleTagSearch}
            />
          </Drawer>
        </Hidden>
        <div className="drive-content">
          {/*Content*/}
          <div style={{height: 70}}>
            <FormControl style={{ marginTop: 5, width: 180, padding: "0 15px 0 0" , float: "left", position: "relative"}}>
              <TextField
                style={{ marginBottom: 20 }}
                value={this.state.search}
                label={localization.get("search.files")}
                onChange={e => this.setState({search: e.target.value}, () => setTimeout(this.requestFiles({}), 200))}
                fullWidth
              />
            </FormControl>
            <FormControl style={{float: "left", position: "relative"}}>
              <RadioGroup
                name="showFiles"
                value={this.state.fileType}
                onChange={event => {
                  this.setState({ fileType: event.target.value },
                    () => this.requestFiles({}))
                }}
                style={{ width: "100%", float: "left", position: "relative", flexDirection: "row", marginTop: 15}}
              >
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('files.all')} />
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="video" control={<Radio color="default" />} label={localization.get('files.videos')} />
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="audio" control={<Radio color="default" />} label={localization.get('files.audios')} />
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="image" control={<Radio color="default" />} label={localization.get('files.images')} />
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="other" control={<Radio color="default" />} label={localization.get('files.others')} />
                <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} disabled={this.state.selectedFiles.length == 0} value="selected" control={<Radio color="default" />} label={localization.get('files.selected')} />
              </RadioGroup>
            </FormControl>
            <div style={{position: "relative", float: "right", padding: "20px 0" }}>
              {(this.state.selectedFiles.length > 0 || !!this.state.selectedFilesCheck) &&
                <Chip
                  deleteIcon={<Clear />}
                  onDelete={() => this.setState({selectedFiles: [], fileType: 'all', selectedFilesCheck: false}, () => this.requestFiles({}))}
                  variant="outlined"
                  style={{
                    margin: "0 4px",
                    border: "1px solid #0000003d",
                    backgroundColor: 'darkgray',
                    color: 'white',
                    width: 125,
                  }}
                  label={localization.get('files.to_share') + ' ' + (this.state.selectedFiles.length === 0 ? this.state.totalFiles : this.state.selectedFiles.length)}
                />
              }
              <IconButton
                style={{height: 35, width: 35}}
                onClick={this.selectAllFiles}
              >
                <Tooltip  title={localization.get('files.select_all')} leaveDelay={1} placement='top'>
                  <Attachment />
                </Tooltip>
              </IconButton>
              <IconButton
                style={{height: 35, width: 35}}
                onClick={this.openShareDialog}
                >
                <Tooltip  title={localization.get('share')} leaveDelay={1} placement='top'>
                  <Share />
                </Tooltip>
              </IconButton>
              <IconButton
                style={{ height: 35, width: 35 }}
                onClick={this.showSocialLinkDialog}
              >
                <Tooltip  title={localization.get('social_links')} leaveDelay={1} placement='top'>
                <Settings />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          {this.state.files.data &&
            <React.Fragment>
              <div className="file-container" style={{
              }}>
                {this.state.newOrderFiles.map(item => (
                  <FileStorageItem
                    item={item}
                    selectedFiles={this.state.selectedFiles}
                    updateGrid={this.requestFiles}
                    selected={this.state.selectedFiles.includes(item.id) || this.state.selectedFilesCheck}
                    changeFileSelected={this.onSelectedFilesChanged}
                    onEdit={this.onEditFile}
                  />
                ))}
              </div>
              {this.state.files.meta && this.state.files.meta.last_page !== 1 &&
                <Paginator
                  style={{marginTop: 10}}
                  pages={this.state.files.meta.last_page}
                  onPageChange={page => this.requestFiles({page: page})}
                  pagination={this.state.files.meta}
                />
              }
            </React.Fragment>
          }
          <HomeAddButton
            onClick={() => this.setState({ showUploadManyFileUploadDialog: true })}
            right={125}
            icon={PlusOne}
          />
          <HomeAddButton onClick={() => this.setState({ showFileUploadDialog: true, creatingFile: true })}/>
        </div>
      </div>
    );
  };
}

export default withStyles(styles, { withTheme: true })(Drive);
