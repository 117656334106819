import _ViewContainer from './ViewContainer'
import {
  Birthdate as _TopContainerBirthdate,
  Country as _TopContainerCountry,
  Description as _TopContainerDescription,
  BottomDescription as _TopContainerBottomDescription,
  InfoContainer as _TopContainerInfoContainer,
  Name as _TopContainerName,
  TopContainer as _TopContainer
} from "./TopContainer";
import _SocialBanner from "./SocialBanner";
import _EventSchedule from "./EventSchedule";
import {
  Carousel as _WorkContainerCarousel,
  Grid as _WorkContainerGrid,
  SelectedWork as _WorkContainerSelectedWork,
  WorkContainer as _WorkContainer
} from "./WorkContainer";

export const ViewContainer = _ViewContainer;
export const TopContainer = _TopContainer;
export const TopContainerDescription = _TopContainerDescription;
export const TopContainerName = _TopContainerName;
export const TopContainerCountry = _TopContainerCountry;
export const TopContainerBirthdate = _TopContainerBirthdate;
export const TopContainerInfoContainer = _TopContainerInfoContainer;
export const SocialBanner = _SocialBanner;
export const WorkContainer = _WorkContainer;
export const WorkContainerSelectedWork = _WorkContainerSelectedWork;
export const WorkContainerCarousel = _WorkContainerCarousel;
export const WorkContainerGrid = _WorkContainerGrid;
export const TopContainerBottomDescription = _TopContainerBottomDescription;
export const EventSchedule = _EventSchedule;
