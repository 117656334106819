import { get } from "lodash";
import {addPutMethodToForm, post} from "../utils";
import {makeFormData} from "./utils";
import {
  getStoredUser,
} from "../../core/authentication/utils";

/**
 * @typedef {object} Client
 * @property {*} client_id
 * @property {*} role_id
 * @property {*} can_license
 */

/**
 * @typedef {object} RequestData
 * @property {string} name
 * @property {string} old_password
 * @property {string} password
 * @property {string} email
 * @property {string} description
 * @property {*} imageFile
 * @property {*} roleId
 * @property {Client[]} client_roles
 */

const update = host => {
  /**
   * @param {RequestData} data
   */
  const request = data => {
    let formData = makeFormData(data);
    formData = addPutMethodToForm(formData);

    const self = data.userId === get(getStoredUser(), "id");
    return post(`${host}/users/${self ? "updateSelf" :data.userId}`, formData).then(response => response.json());
  };
  return request;
};

export default update;
