import React, {PureComponent} from "react";
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams, parseDate} from "../../../utils";
import {TryAgainSnackbar} from "../../../components/Letflow/Snackbar";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {get} from "lodash";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";


const updateTagGroups = (tagGroups, selectedTags) => {
  return tagGroups.map(tagGroup => ({
    ...tagGroup,
    tags: tagGroup.tags.map(tag => ({
      ...tag,
      state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
    }))
  }))
}

export default class extends PureComponent {
  state = {
    image: { src: null, file: null },
    selectedClients: [],
    selectedMusics: [],
    removedMusics: [],
    hideInHome: false,
    featured: false,
    disabled: false,
    artist: '',
    type: '',
    createdAt: null,
    selectedCatalog: '',
    showTagSelection: false,
    hasNextStep: true,
    selectedTags: [],
    tagGroups: [],
    disableForm: false,
    unsplashImage: '',
    imageRadio: 'filepond',
    firstLoad: true,
    translations: AvailableLanguages.map(language => ({
      language: language,
      title: '',
      subtitle: '',
      shortDescription: '',
    })),
    mirrorTags: false,
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevState.selectedCatalog !== this.state.selectedCatalog && !this.state.firstLoad) {
      this.requestTagGroups(get(this.state.selectedCatalog, 'createdBy.id', null))
        .then(tagGroups => {
          this.setState({tagGroups: updateTagGroups(tagGroups, this.state.selectedTags)})
        })
    }
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  requestTagGroups = (clientId) => request.tagGroup.getAllOfTypeMusic()
    .then(tagGroups => {
      this.setState(state => ({...state, tagGroups}));
      return tagGroups;
    })

  requestAlbum = () => request.album.get(this.props.match.params.id, 'include=catalog.createdBy;artist;image;allMusics.artist;allMusics.tags;allMusics.albums;allMusics.audio.waveform;translations;tags')
    .then(album => {
      const translations = AvailableLanguages.map(language => {
        const translation = album.translations.find(tr => tr.locale ===language)

        return {
          language: language,
          title: translation ? translation.title : "",
          subtitle: translation ? translation.subtitle : "",
          shortDescription: translation ? translation.short_description : "",
        };
      })

      this.setState({
        id: album.id,
        translations: translations,
        image: album.image
          ? { id: album.image.id, src: getUpdateFormImageUrlFromItem(album), filename: album.image.original_name }
          : { src: getUpdateFormImageUrlFromItem(album), file: null },
        hideInHome: album.hide_in_home,
        featured: album.featured,
        selectedMusics: album.allMusics,
        artist: album.artist ? {value: album.artist.id, label: album.artist.name} : '',
        type: album.type,
        createdAt: album.created_at,
        selectedCatalog: {value: album.catalog.id, label: album.catalog.name},
        selectedTags: album.tags.map(tag => tag.id),
      }, () => {
        const clientId = get(album, 'catalog.createdBy.id', null);
        this.requestTagGroups(clientId)
          .then(tagGroups => {
            this.setState({
              tagGroups: updateTagGroups(tagGroups, album.tags.map(tag => tag.id)),
              firstLoad: false,
            })
          })
      })
  })

  requestUpdate = () => {

    let {
      translations,
      imageId,
      selectedClients,
      selectedMusics,
      hideInHome,
      featured,
      artist,
      type,
      createdAt,
      selectedCatalog,
      selectedTags,
      unsplashImage,
      mirrorTags
    } = this.state

    const data = {
      id: this.props.match.params.id,
      translations,
      imageId,
      selectedClients: selectedClients.map(x => x.value),
      selectedMusics: selectedMusics.map(x => x.id),
      hideInHome,
      featured,
      artistId: artist ? artist.value : '',
      type,
      createdAt: parseDate(createdAt),
      selectedCatalog: selectedCatalog && selectedCatalog.value,
      tagsIds: selectedTags,
      unsplashImage,
      mirrorTags
    }

    return request.album.update(data)
      .then(_ => this.props.history.push(makeUrlWithTableParams('/panel/albums')))
  }

  checkTagGroupsSelectedTags = (tagGroups) => {
    let count = 0;
    tagGroups.forEach(tagGroup => {
      if (tagGroup.tags.find(tag => tag.state === 'selected'))
        count++
    });
    return count === tagGroups.length
  };

  onSubmitValidation = () => {
    if (!this.checkTagGroupsSelectedTags(this.state.tagGroups) && this.state.selectedMusics.length !== 0) {
      GlobalSnackbar.show({message: localization.get('validation.must_have_tags'), type: GlobalSnackbarTypes.ERROR})
      return false
    } else {
      return true
    }
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})

  onSetImageFromUnsplash = (url, name) => this.setState({unsplashImage: {url, name}})

  onImageRadioChange = (e) => {
    this.setState({imageRadio: e.target.value})
    if (e.target.value === 'unsplash') {
      this.setState({imageId: '', image: {src: null, filename: null }})
    } else {
      this.setState({unsplashImage: ''})
    }
  }

  onSelectedTagsChanged = selectedTags => this.setState({
    selectedTags,
    tagGroups: updateTagGroups(this.state.tagGroups, selectedTags)
  })

  countSelectedMusicsTags = () => {
    let tags = []
    this.state.selectedMusics.forEach(selectedMusic => {
      selectedMusic.tags.forEach(selectedMusicTag => {
        let index = tags.findIndex(tag => tag.id.toString() === selectedMusicTag.id.toString())
        if (index !== -1){
          tags[index].count++
        } else {
          tags.push({...selectedMusicTag, count: 1})
        }
      })
    })
    return tags
  }

  mapMusicTagsToSelectedTags = () => {
    let countedTags = this.countSelectedMusicsTags()
    let tags = []

    this.state.tagGroups.forEach(tagGroup => {
      let selectedTags = []
      let fallbackSelectedTags = []
      tagGroup.tags.forEach(tag => {
        countedTags.forEach(countedTag => {
          if (countedTag.id === tag.id && countedTag.count > 2) {
            selectedTags.push(countedTag.id)
          } else if (countedTag.id === tag.id && countedTag.count === 2) {
            fallbackSelectedTags.push(countedTag.id)
          }
        })
      })
      if (selectedTags.length !== 0) {
        tags.push(...selectedTags)
      } else {
        tags.push(...fallbackSelectedTags)
      }

    })
    return tags
  }

  onSuggestTags = () => {
    this.setState({selectedTags: this.mapMusicTagsToSelectedTags()},
      () => {
        if (this.state.selectedTags.length === 0) {
          GlobalSnackbar.show({message: localization.get('tags.suggestions_not_found'), type: GlobalSnackbarTypes.ERROR})
        } else {
          this.setState({tagGroups: updateTagGroups(this.state.tagGroups, this.state.selectedTags)})
        }
      })
  }

  render = () => {
    const hideInHomeProps = {
      hideInHome: this.state.hideInHome,
      onHideInHomeChange: hideInHome => { this.setState({ hideInHome }) }
    }

    const featuredProps = {
      featured: this.state.featured,
      onFeaturedChange: featured => { this.setState({ featured }) }
    }

    const musicSelectionProps = {
      selectedMusics: this.state.selectedMusics,
      onSelectedItemsChanged: items => this.setState({ selectedMusics: items }),
      removedMusics: this.state.removedMusics,
      onRemovedItemsChanged: items => this.setState({ removedMusics: items }),
    }

    return (
      <div>
        <FormViewContainer
          title={localization.get('view.album.update')}
          submitRequest={this.requestUpdate}
          validation={this.onSubmitValidation}
          onBack={() => this.state.hasNextStep ? this.props.history.goBack() : this.setState({showTagSelection: false, hasNextStep:true})}
          initializationRequest={this.requestAlbum}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          hasNextStep={this.state.hasNextStep}
          onNextStep={() => this.setState({showTagSelection: true, hasNextStep: false})}
          disabled={this.state.disableForm}
        >
          {this.state.createdAt &&
            <FormContent
              {...hideInHomeProps}
              {...featuredProps}
              {...musicSelectionProps}
              formType="update"
              disabled={this.state.disabled}
              image={this.state.image}
              onChange={this.onChange}
              onImageChange={this.onImageChange}
              albumId={this.props.match.params.id}
              artist={this.state.artist}
              categoryId={this.state.category}
              categories={this.state.categories}
              onArtistChange={(artist) => this.setState({artist})}
              type={this.state.type}
              onTypeChange={type => this.setState({type: type.target.value, artist: null})}
              createdAt={this.state.createdAt}
              handleDateChange={date => this.setState({createdAt: date})}
              selectedCatalog={this.state.selectedCatalog}
              onCatalogChange={selectedCatalog => this.setState({selectedCatalog})}
              showTagSelection={this.state.showTagSelection}
              onSelectedTagsChanged={this.onSelectedTagsChanged}
              tagGroups={this.state.tagGroups}
              onSuggestTags={this.onSuggestTags}
              updateDisableForm={value => this.setState({disableForm: value})}
              onSetPreview={this.setPreview}
              disableForm={this.state.disableForm}
              imageRadio={this.state.imageRadio}
              onSetImageFromUnsplash={this.onSetImageFromUnsplash}
              onImageRadioChange={this.onImageRadioChange}
              translations={this.state.translations}
              onChangeTranslation={(value, target, language) => {
                const translations = this.state.translations.map(tr => {
                  if (tr.language === language) {
                    return {...tr, [target]: value}
                  }
                  return tr
                });

                this.setState({translations});
              }}
              mirrorTags={this.state.mirrorTags}
            />
          }
        </FormViewContainer>
        <TryAgainSnackbar ref='snackbar' />
      </div>
    )
  }
}
