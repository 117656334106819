import React, {Component} from "react";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import matchSorter from "match-sorter";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import PlaylistSubcomponent from "./TableSubComponent";
import {Close, Done} from "@material-ui/icons";
import ServerSidePaginationTable from "../../../components/Letflow/Table/ServerSidePaginationTable";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";

class Table extends Component {

  state = {
    total: '',
    categories: this.props.categories,
    clients: this.props.clients,
    showPlaylists: "all"
  }

  columns = () => [
    {
      Header: localization.get('table.title'),
      accessor: "title",
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["title"] }),
      filterAll: true,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      accessor: "subtitle",
      Header: localization.get("table.subtitle"),
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "client[name]",
      Header: localization.get("table.clients"),
      accessor: "client.name",
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.client
            .getAll({
              filterBy: [{
                column: 'name',
                filter
              }]
            })
            .then(clients => clients.map(client => client.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    },

    {
      Header: localization.get("table.home"),
      id: "user_library",
      accessor: playlist => (playlist.user_library ? <Done /> : <Close />),
      Filter: ({ filter, onChange }) =>
        <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value="" selected />
          <option value={true}>{localization.get('table.yes')}</option>
          <option value={false}>{localization.get('table.no')}</option>
        </select>,
      style: {
        textAlign: "left"
      },
      minWidth: 70
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      minWidth: 180,
      accessor: playlist => {
        const buttonBuilder = new TableButtonsBuilder()
        buttonBuilder.withEdit(makeUrlWithTableParams(`/panel/playlists/${playlist.id}/edit`))
        buttonBuilder.withShare('playlist', playlist.id)
        buttonBuilder.withDuplicatePlaylist(this.state.clients, playlist.id)
        buttonBuilder.withDelete(
          playlist.title,
          () => request.playlist.delete(playlist.id),
          state => {
            if (state === "passed") {
              this.refs.table.removeById(playlist.id)
            }
          }
        )
        return buttonBuilder.build()
      },

      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }

  ]


  render = () => {
    return (
      <React.Fragment>
        <FormControl style={{ width: "100%", float: "left", position: "relative" }}>
          <RadioGroup
            name="showPlaylists"
            value={this.state.showPlaylists}
            onChange={event => {
              this.setState({ showPlaylists: event.target.value })
              this.refs.table.forceUpdate();
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('playlists.all')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="clients" control={<Radio color="default" />} label={localization.get('playlists.clients')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="flowlike" control={<Radio color="default" />} label={localization.get('playlists.flowlike')} />
          </RadioGroup>
        </FormControl>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            requestTableData={paginationConfig => {
              if (this.state.showPlaylists === "clients") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'created_by_admin', filter: false }]) }
              } else if (this.state.showPlaylists === "flowlike") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'created_by_admin', filter: true }]) }
              }

              return (
                request.playlist.getAll(paginationConfig).then((response) => {
                  this.setState({ total: response.meta.total })
                  return response
                })
              )
            }}
            ref="table"
            subComponent={row => <PlaylistSubcomponent {...this.props} playlist={row.original} />}
            columns={this.columns()}
          />
        </div>
      </React.Fragment>
    );
  }


}

export default Table;
