import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update";
import Ranking from "./Ranking";
import NewMusics from "./NewMusics";

const ArtistsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/artists/:id/edit" component={Update} />
        <Route path="/panel/artists/create" component={Create} />
        <Route path="/panel/ranking/artists" component={Ranking} />
        <Route path="/panel/artists/newMusics" component={NewMusics} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default ArtistsRouting
