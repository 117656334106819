import image from './image'
import upload from './upload'
import del from './delete'
import abortMultipart from './abortMultipart'
import completeMultipart from './completeMultipart'

export default host => ({
  image: image(host),
  upload: upload(host),
  delete: del(host),
  abortMultipart: abortMultipart(host),
  completeMultipart: completeMultipart(host),
})
