import getAll from './getAll'
import del from './delete'
import update from './update'
import get from './get'
import searchFor from './searchFor'

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  update: update(host),
  get: get(host),
  searchFor: searchFor(host)
})