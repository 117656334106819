import React from 'react'
import {request} from '../../../api-client';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import PropTypes from "prop-types";
import localization from "../../../config/localization"
import {Radio, RadioGroup} from "@material-ui/core/index";
import {userIsSupervisor} from "../../../api-client/core/authentication/utils";

const initialState = {
  catalogs: [],
  selectedCatalogs: [],
  clientName: '',
  catalogContent: 'music'
}

class AddCatalogDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.clientId !== this.props.clientId) {
      if (eval(localStorage.getItem("catalogContent"))) {
        this.setState({...initialState, clientId: nextProps.clientId, catalogContent: eval(localStorage.getItem("catalogContent")) },
         () => this.requestClient().then(() => this.requestCatalogs()))
      } else {
        this.setState({...initialState, clientId: nextProps.clientId}, 
          () => this.requestClient()
          .then(() => this.requestCatalogs()))
      }
    }
  }

  requestClient = () => request.client.get(this.state.clientId, 'include=catalogs')
    .then(client => this.setState({ selectedCatalogs: client.catalogs.filter(catalog => !!catalog.confirmed).map(catalog => catalog.id), clientName: client.name}))

  requestCatalogs = () =>  {
    let paginationConfig = {filterBy: [{column: "client_id", filter: this.state.clientId}], orderBy: {column: "name", type: "asc"}}

    paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: 'filter_content', filter: this.state.catalogContent}])}

    return request.catalog.getAll(paginationConfig, 'include=catalogCategory')
      .then((catalogs) => this.setState({catalogs}))
  }


  handleChange = id => () => {
    let selectedCatalogs = this.state.selectedCatalogs
    if (id === 0) {
      if (selectedCatalogs.includes(id)) {
        selectedCatalogs = []
      } else {
        selectedCatalogs = this.state.catalogs.map(client => client.id).concat([0])
      }
    } else {
      if (selectedCatalogs.includes(id)) {
        selectedCatalogs = selectedCatalogs.filter(selectedClient => selectedClient !== id)
      } else {
        selectedCatalogs.push(id)
      }
      selectedCatalogs = selectedCatalogs.filter(catalog => catalog !== 0)
    }
    this.setState({selectedCatalogs})
  }

  handleSubmit = () => {
    const {catalogs, selectedCatalogs} = this.state

    request.client.updateCatalogs({
      id: this.state.clientId,
      confirmedCatalogsIds: selectedCatalogs.filter(catalog => catalog !== 0),
      notConfirmedCatalogsIds: catalogs.map(catalog => catalog.id).filter(catalog => !selectedCatalogs.includes(catalog))
    }).then(() => this.props.handleClose())
  }

  render = () => {
    const {catalogs, selectedCatalogs} = this.state
    return (
      <Dialog open={this.props.show} onBackdropClick={this.props.handleClose} onEscapeKeyDown={this.props.handleClose}>
        <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={this.props.handleClose}>
          <Close />
        </IconButton>
        <DialogTitle>{this.state.clientName+' '+localization.get('catalogs')}</DialogTitle>
        <DialogContent style={{width: 400, maxWidth: "100%"}}>
          <FormControl style={{width: "100%", float: "left", position: "relative"}}>
            <RadioGroup
              name="catalogContent"
              value={this.state.catalogContent}
              onChange={e => {
                localStorage.setItem("catalogContent", `"${e.target.value}"` );
                this.setState({catalogContent: e.target.value}, () => this.requestCatalogs())
              }}
              style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
            >
              <FormControlLabel value="music" control={<Radio color="default" />} label={localization.get('music')} />
              <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('talents_audiovisual')} />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCatalogs.includes(0)}
                    onChange={this.handleChange(0)}
                    value={0}
                  />
                }
                label={localization.get('catalog.select_all')}
              />
              {catalogs.map(catalog =>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCatalogs.includes(catalog.id)}
                      onChange={this.handleChange(catalog.id)}
                      value={catalog.id}
                      disabled={!userIsSupervisor() && catalog.supervised}
                    />
                  }
                  label={catalog.name}
                />
              )}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit}>{localization.get('form.submit')}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddCatalogDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default AddCatalogDialog
