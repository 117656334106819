import {post} from "../utils";
import {storeTokens} from "./utils";

const form = trial_token => {
  const f = new FormData()
  f.append('trial_token', trial_token)
  return f
}

export default host => ({
    trialToken
  }) => post(`${host}/login/trial`, form(trialToken), {}, () => {}, false)
  .then(result => result.json())
  .then(storeTokens)