import React from 'react';
import {request} from "../../../api-client";
import { Dialog, IconButton, withStyles, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import { get } from 'lodash';
import FormViewContainer from '../ViewContainer/FormViewContainer';
import TranslatableInputs from '../TranslatableInputs';
import localization from "../../../config/localization";
import { ShortText } from '@material-ui/icons';
import {getUpdateFormImageUrlFromItem} from "../../../utils";
import  FilePondUploader  from '../FilePondUploader';
import  RegionSelector from '../RegionSelector';
import EditorialEditor from "../RichTextEditor/EditorialEditor";
import AsyncSelect from "react-select/lib/Async";
import {ImagePicker} from '../Form';
import {EditorState, convertFromRaw, convertToRaw} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import {stateToHTML} from "draft-js-export-html";
import SocialLinks from "../SocialLinks";
import GlobalSnackbar, { GlobalSnackbarTypes } from '../Snackbar/GlobalSnackbar';
import { clearUnwantedStateStyles } from "../RichTextEditor/utils";
import TagSelection from '../TagSelection';
import { getStoredChannel } from '../../../api-client/core/authentication/utils';

const styles = {
  dialogPaper: {
      minHeight: '100vh',
      minWidth: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      backgroundColor: "rgba(0,0,0,0.8)",
      padding: window.innerWidth > 600 ? '40px' : "0px",
      height: '100vh',
      width: '100vw'
  },
  dialogPaperMobile: {
    minHeight: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#EFEFEF',
    borderRadius: '10px',
    padding: '20px',
    height: '80vh',
    fontSize: '0.8em'
  }
};

class EditContentCreatorDialog extends React.Component{
  state = {
    open: get(this.props, 'open', false),
    contentCreator: get(this.props, 'contentCreator', null),
    disabled: false,
    translations: get(this.props, 'contentCreator.translations', []),
    contentCreatorType: get(this.props, 'contentCreator.type', {}),
    socialLinks: get(this.props, 'contentCreator.socialLinks', []),
    email: get(this.props, 'contentCreator.email', ''),
    phone: get(this.props, 'contentCreator.phone', ''),
    country: get(this.props, "contentCreator.country") ? {value: this.props.contentCreator.country.id, label: this.props.contentCreator.country.name} : '',
    image: get(this.props, "contentCreator.image") && {
      id: get(this.props, "contentCreator.image.id"),
      src: getUpdateFormImageUrlFromItem(get(this.props, "contentCreator")),
      image: get(this.props, "contentCreator.image")
    },
    coverImage: get(this.props, "contentCreator.hasCoverImage") && {
      id: get(this.props, "contentCreator.coverImage.id"),
      image: get(this.props, "contentCreator.coverImage")
    },
    featuredImage: get(this.props, "contentCreator.featuredImage") && {
      id: get(this.props, "contentCreator.featuredImage.id"),
      image: get(this.props, "contentCreator.featuredImage")
    },
    hasRegionBlock: get(this.props, "contentCreator.region_restricted") ? "true" : "false",
    allowedRegions: get(this.props, "contentCreator.allowedRegions", []),
    tagGroups: [],
    selectedTags: [],
  }

  setCreator = creator => {
    const languages = getStoredChannel().languages.map(lang => lang.language);
    const translations = languages.map(language => {
      const translation = creator.translations.find(tr => tr.locale ===language)

      if (translation) {
        const description = translation.description ? (get(creator, 'type.type', "audiovisual") == "editorial" ? convertFromRaw(JSON.parse(translation.description)) : stateFromHTML(translation.description)) : new EditorState.createEmpty();

        return {
          language: translation.locale,
          name: translation.name,
          description: translation.description ? EditorState.createWithContent(description) : description,
          author: translation.additions && translation.additions.split(';')[0] || "",
          category: translation.additions && translation.additions.split(';')[1] || "",
          synopsis: translation.synopsis,
        };
      }

      return  {
        language: language,
        name: "",
        description: new EditorState.createEmpty(),
        author: "",
        category: "",
        synopsis: "",
      }
    })

    const selectedTags = creator.tags.map(tag => tag.id);

    this.setState({
      contentCreator: creator,
      id: creator.id,
      translations: translations,
      active: get(creator, 'active', true) ? "1" : "0",
      contentCreatorType: get(creator, 'type', {}),
      socialLinks: get(creator, 'socialLinks', []),
      email: get(creator, 'email', ''),
      phone: get(creator, 'phone', ''),
      country: get(creator, "country") ? {value: creator.country.id, label: creator.country.name} : '',
      image: creator.imageUrl && {
        id: null,
        src: creator.imageUrl,
        file: null
      },
      coverImage: creator.hasCoverImage && {
        id: creator.coverImage.id,
        src: creator.coverImageUrl,
        file: null
      },
      hasRegionBlock: get(creator, "region_restricted") ? "true" : "false",
      allowedRegions: get(creator, "allowedRegions", []),
      selectedTags: selectedTags,
    })
  }

  componentWillReceiveProps = nextProps => {
    if(get(nextProps, "contentCreator.id") !== this.state.id) {
      this.setCreator(nextProps.contentCreator)
      
       request.tagGroup.getAll({filterBy: [
          { column: 'type', filter: nextProps.contentCreator.type.slug},
          { column: 'creation_group', filter: '0'},
          { column: 'dashboard', filter: '1'}
        ]})
        .then((tagGroups) => this.setState({tagGroups: this.updateTagGroups(tagGroups, nextProps.contentCreator.tags.map(tag => tag.id))}))
    }
  }

  onSelectedTagsChanged = selectedTags =>
    this.setState({
      selectedTags,
      tagGroups: this.updateTagGroups(this.state.tagGroups, selectedTags)
    });

  updateTagGroups = (tagGroups, selectedTags) => {
    return tagGroups.map(tagGroup => ({
      ...tagGroup,
      tags: tagGroup.tags.map(tag => ({
        ...tag,
        state: selectedTags.find(
          selectedTag => selectedTag.toString() === tag.id.toString()
        )
          ? "selected"
          : "available"
      }))
    }));
  };

    requestUpdate = () => {
      const {
        id,
        email,
        phone,
        imageId,
        coverImageId,
        selectedTags,
        country,
        active,
        socialLinks,
        requireLogin,
        allowedRegions,
        hasRegionBlock,
        translations,
        contentCreatorType,
      } = this.state;
    
      const contentCreatorTypeIsEditorial = get(contentCreatorType, 'type', "audiovisual") == "editorial"

     const newTranslations = translations.map(tr => ({
      language: tr.language,
      description: tr.description && (contentCreatorTypeIsEditorial ?
        (stateToHTML(tr.description.getCurrentContent()) === '<p><br></p>' ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.description.getCurrentContent())))) :
        stateToHTML(tr.description.getCurrentContent())),
      name: tr.name,
      additions: `${tr.author};${tr.category}`,
      synopsis: tr.synopsis,
    }))

      const contentCreatorData = {
        id,
        email,
        phone,
        translations: newTranslations,
        imageId,
        coverImageId,
        selectedTags,
        countryId: country.value,
        active,
        socialLinks,
        requireLogin,
        allowedRegions,
        hasRegionBlock,
        selectedTags,
      };
  
      return request.contentCreator.updateInClient(contentCreatorData)
        .catch(console.error)
        .then(() => GlobalSnackbar.show({message: localization.get('snackbar.content_creator_updated'), type: GlobalSnackbarTypes.SUCCESS}))
        .then(this.props.onClose)
    };
  

  handleClose = () => {
    this.setCreator(this.props.contentCreator);

    this.props.onClose();
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value });
  onChangeTranslation = (value, target, language) => {
    const translations = this.state.translations.map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }
      return tr
    });

    this.setState({ translations });
  }
  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path);
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onCoverImageChange = coverImage => coverImage ? this.setState({coverImageId: coverImage.id, coverImage: {src: null, filename: null }, imagePreview: this.formNewUrl(coverImage.path)}) : this.setState({coverImageId: null, coverImage: { src: null, filename: null }})
  onFeaturedImageChange = featuredImage => featuredImage ? this.setState({featuredImageId: featuredImage.id, featuredImage: {src: null, filename: null }, imagePreview: this.formNewUrl(featuredImage.path)}) : this.setState({featuredImageId: null, featuredImage: { src: null, filename: null }})
  getSynopsis = description => {
    const blocks = convertToRaw(description.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

    if (value.length < 247) return value+ "...";
    const shortDescription = value.substring(0, 300)
    const textInChars = shortDescription.split("")
    let text = "";
    let spaceText = "";

    for (let index = textInChars.length; index > 0; index--) {
      if(textInChars[index] === ".") {
        text = shortDescription.substring(0, index+1)
        break;
      }
      if(textInChars[index] === ",") {
        if (shortDescription.substring(0, index).length < 300) {
          text = shortDescription.substring(0, index) + "..."
          break;
        }
      }
      if(!spaceText && textInChars[index] === " " && shortDescription.substring(0, index).length < 300) {
        spaceText = shortDescription.substring(0, index) + "..."
      }
    }

   if (text.length < 100) text = spaceText;

   return text;
  }

    render = () => {
    const { contentCreator, translations, disabled, active, contentCreatorType, socialLinks, email, phone, country, image, coverImage, featuredImage, hasRegionBlock, allowedRegions } = this.state;
    const languages = getStoredChannel().languages.map(lang => lang.language);
    
    if (!contentCreator) return <></>;

    return (
      <Dialog
        open={this.props.open}
        // classes={{paper: this.props.classes.dialogPaper}}
        onEscapeKeyDown={this.handleClose}
        onClose={this.handleClose}
        disableEnforceFocus
      >
        <FormViewContainer
            title={localization.get("view.content_creator.update")}
            submitRequest={() => this.requestUpdate()}
            onBack={this.handleClose}
            onRequestingStateChanged={value => this.setState({ disabled: value })}
            hasTranslatableInputs
            validation={this.onSubmitValidation}
            disabled={this.state.disabled}
            actionsExtraSpace={true}
            sucessMessage={localization.get('snackbar.entity_update_success_on_client')}
            color={"dark"}
          >
            <TranslatableInputs
              color={"dark"}
              children={languages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language == language);
                if (!currentLangTranslation) return;
                return {
                  language: language,
                  content: [
                    <TextValidator
                      style={{width: "100%", marginBotton: 15}}
                      id={`name${language}`}
                      label={localization.get(contentCreatorType.type === 'editorial' ? 'form.title' : 'form.name')}
                      name={`name${language}`}
                      value={get(currentLangTranslation, "name", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "name", language)}
                      disabled={disabled}
                      validators={['required']}
                      errorMessages={[localization.get('validator.is_required')]}
                    />,
                    <TextValidator
                      style={{width: "100%", marginBotton: 15}}
                      id={`category${language}`}
                      label={localization.get('form.section')}
                      name={`category${language}`}
                      value={get(currentLangTranslation, "category", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "category", language)}
                      disabled={disabled}
                    />,
                    <TextValidator
                      style={{width: "100%", marginBotton: 15}}
                      id={`author${language}`}
                      label={localization.get(contentCreatorType.type !== 'editorial' ? 'form.extras' : 'form.author')}
                      name={`author${language}`}
                      value={get(currentLangTranslation, "author", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "author", language)}
                      disabled={disabled}
                    />,
                    <div style={{width: "100%", marginBotton: 15, display: "flex"}}>
                    <textarea
                      style={{width: "100%", border: "none", marginTop: 20, borderBottom: "1px solid #ccc"}}
                      id={`synopsis${language}`}
                      name={`synopsis${language}`}
                      label={localization.get('form.synopsis')}
                      helperText={localization.get("form.synopsis_helper")}
                      value={get(currentLangTranslation, "synopsis", "")}
                      onChange={e => this.onChangeTranslation(e.target.value, "synopsis", language)}
                      disabled={disabled}
                      multiline
                      rows={4}
                    />
                      <div style={{textAlign: 'center', top: 10}}>
                        <IconButton
                          title={localization.get('form.generate_synopsis')}
                          style={{top: 30}}
                          onClick={() => {
                            const e = {
                              target: {
                                id: "synopsis",
                                value: this.getSynopsis(get(currentLangTranslation, "description", "")),
                              },
                            };

                            this.onChangeTranslation(e.target.value, "synopsis", language)
                          }}
                        >
                          <ShortText style={{
                            border: "2px solid",
                            borderRadius: "50%",
                            width: 30,
                            height: 30,
                          }}/>
                        </IconButton>
                      </div>
                    </div>
                    ,
                    (contentCreatorType.type === 'audiovisual' &&
                      <>
                        <TextValidator
                          style={{display: "none"}}
                          id={`description${language}`}
                          label={localization.get('form.description')}
                          name={`description${language}`}
                          value={get(currentLangTranslation, "description", "")}
                          onChange={e => this.onChangeTranslation(e.target.value, "description", language)}
                          disabled={disabled}
                          isHtml={true}
                        />
                        <div id="ignore">
                          <br/>
                          <div style={{marginBottom: "-2rem"}}>{localization.get('form.description')}</div>
                          <EditorialEditor
                            toolbarOptions={['inline', 'blockType', 'list', 'link', 'remove', 'history']}
                            editorState={get(currentLangTranslation, "description", "")}
                            onChange={e => this.onChangeTranslation(e, "description", language)}
                            noColors
                          />
                        </div>
                      </>
                    )
                  ]
                }
              })}
            />

        {contentCreatorType.type == 'editorial' && translations.length > 0 &&
          <TranslatableInputs
            color={"dark"}
            isEditorialContent
            children={languages.map(language => {
              const currentLangTranslation = translations.find(tr => tr.language == language);

              if (!currentLangTranslation) return;
              return {
                language,
                content: [
                  <TextValidator
                    style={{display: "none"}}
                    id={`description${language}`}
                    label={localization.get('form.note')}
                    name={`description${language}`}
                    value={currentLangTranslation.description}
                    onChange={e => this.onChangeTranslation(e, "description", language)}
                    disabled={disabled}
                  />,
                  <div id="ignore">
                    <div style={{marginBottom: "-2rem"}}>{localization.get('form.note')}</div>
                    <EditorialEditor
                      editorState={currentLangTranslation.description}
                      scrollable
                      onChange={e => this.onChangeTranslation(e, "description", language)}
                      noColors
                    />
                  </div>
                ]
              }
            })}
          />
        }
        {!!contentCreatorType.is_talent  &&
          <Card style={{overflow: 'visible'}}>
            <CardContent>
              <TextValidator
                style={{ width: "100%", marginBottom: 20 }}
                id="email"
                label={localization.get('form.email')}
                name="email"
                value={email}
                onChange={this.onChange}
                disabled={disabled}
              />
              <TextValidator
                style={{ width: "100%", marginBottom: 20 }}
                id="phone"
                label={localization.get('form.phone')}
                name="phone"
                value={phone}
                onChange={this.onChange}
                disabled={disabled}
              />
              <FormControl fullWidth style={{marginBottom: "10px"}}>
                <span>{localization.get('form.country')} *</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    }),
                    control: (base, state) => ({
                      ...base,
                      // state.isFocused can display different borderColor if you need it
                      borderColor: '#ddd',
                      // overwrittes hover style
                      '&:hover': {
                        borderColor: '#ddd'
                      }
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={e => this.setState({ country: e })}
                  value={country}
                  name={"country"}
                  defaultOptions
                  loadOptions={inputValue =>
                    request.country
                      .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                      .then(res => res.sort((a, b) => a.name.localeCompare(b.name)).map(country => ({ value: country.id, label: `${country.name}` })))
                  }
                  errorMessages={[localization.get("validator.is_required")]}
                />
              </FormControl>
            </CardContent>
          </Card>
        }
        <br/>
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <h4>{localization.get('form.select_image_profile')}</h4>
            {image && <ImagePicker image={image.src} id={image.id} previewOnly={true} />}
            <FilePondUploader
              disableForm={disabled}
              updateDisableForm={value => this.setState({disableForm: value})}
              file={image}
              id={"image"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={this.onImageChange}
              setPreview={this.onSetPreview}
              clientId={get(contentCreatorType, "client.id")}
            />
            <div style={{
              color: "#757575",
              textAlign: 'center',
              marginTop: 5
            }}>{localization.get('helper_text.images_width_2048')}</div>
            <br/>
            <h4>{localization.get('form.select_image_cover')}</h4>
            {coverImage && <ImagePicker image={coverImage.src} previewOnly={true} id={coverImage.id} />}
            <FilePondUploader
              disableForm={disabled}
              updateDisableForm={value => this.setState({disableForm: value})}
              file={coverImage}
              id={"cover"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={this.onCoverImageChange}
              setPreview={this.onSetCoverPreview}
              clientId={get(contentCreatorType, "client.id")}
            />
            <div style={{color: "#757575", textAlign: 'center', marginTop: 5}}>{localization.get('helper_text.images_width_2048')}</div> 
            </CardContent>
          </Card>
          <br/>
          <Card style={{overflow: 'visible'}}>
          <CardContent>
            <SocialLinks buttonColor={"#353535"} socialLinks={socialLinks} onSocialLinksChange={socialLinks => this.setState({ socialLinks })}/>
            <br/>
          </CardContent>
          </Card>
          <br/>
          <Card style={{overflow: 'visible'}}>
            <CardContent>
              <h4>{localization.get('form.availability')}</h4>
              <div style={{marginTop: 10}}>
                <FormControl>
                  <RadioGroup
                    name="active"
                    id="active"
                    value={active}
                    onChange={e => this.setState({ active: e.target.value })}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel style={{width: '200px'}} value="1" control={<Radio color="default"/>} label={localization.get('form.allowed_content')}/>
                    <FormControlLabel style={{width: '200px'}} value="0" control={<Radio color="default"/>} label={localization.get('form.disallowed_content')}/>
                  </RadioGroup>
                </FormControl>
              </div>
              {!!(active === "1" && !contentCreatorType.is_talent) &&
              <>
                <h4>{localization.get('form.region')} {allowedRegions.length > 0 && <span style={{fontSize: 12}}>{localization.get('continents.country.selected', allowedRegions.length)}</span>}</h4>
                <FormControl>
                  <RadioGroup
                    name="hasRegionBlock"
                    value={hasRegionBlock}
                    onChange={e => this.setState({hasRegionBlock: e.target.value})}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel style={{width: '200px'}} value="false" control={<Radio color="default"/>} label={localization.get('form.all_world')}/>
                    <FormControlLabel style={{width: '200px'}} value="true" control={<Radio color="default"/>} label={localization.get('form.has_region_block')}/>
                  </RadioGroup>
                </FormControl>

                {hasRegionBlock === "true" &&
                  <RegionSelector selectedCountries={allowedRegions} onChange={regions => this.setState({allowedRegions: regions})} />
                }
              </>
              }
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <TagSelection cardView data={this.state.tagGroups} onSelectedTagsChanged={this.onSelectedTagsChanged} disabled={disabled} showFilter color={"#353535"}/>
            </CardContent>
          </Card>
        </FormViewContainer>
      </Dialog>
    );
  }
};

export default withStyles(styles)(EditContentCreatorDialog);
