import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {includeQuery} from './utils';
import {getActiveChannel, getActiveClient} from "../authentication/utils";

const url = (host, id, tags, notTagsIds, options) =>
  appendPaginationQueryParamsToUrl(
    `${host}/clients/${getActiveClient()}/${getActiveChannel() ? `channels/${getActiveChannel()}/` : ""}smartSearchCatalogs?tags_ids=${ tags.join(',')}&not_tags_ids=${ notTagsIds.join(',')}`,
    {...options, alreadyHasQueryParams: true})

export default host => ( id, tags, notTagsIds, options = includeQuery) =>
  get(url(host, id, tags, notTagsIds, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
