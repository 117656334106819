import Dashboard from "../../layouts/Dashboard";
import Home from "../../layouts/Home";

import {makeRoutes, publicRoutes, route} from "./utils";
import Channel from "../../layouts/Channel/index.jsx";

const allowed = publicRoutes.concat([
  route("/channel/:channelId-:channelName", Channel),
  route("/home/:clientId-:clientName", Home),
  route("/", Dashboard)]);

export default makeRoutes(allowed, [], route("/", Dashboard));
