import React, {Component} from "react";
import {request} from "../../api-client";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../components/Letflow/Table";
import localization from "../../config/localization";
import {getMostImportantAlbum, makeUrlImageFromAlbums, makeWaveformUrl} from "../../utils";
import Tabs from "../../components/CustomTabs/CustomTabs";
import Button from "../../components/CustomButtons/Button";
import {Typography} from "@material-ui/core";
import {isNullOrUndefined} from "util";
import SoundWave from "assets/img/Letflow/sound-wave.png";
import noImage from "assets/img/Letflow/no-image.jpg";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import qs from "qs";
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import {makeImageUrlWithSize, onImageError, emptyImage} from "../../layouts/Home/HomeElements/utils";

class PublicAlbum extends Component {
  state = {
    album: null,
  };

  componentDidMount = () => {
    let search = qs.parse(this.props.location.search.slice(1))
    let {client_id} = search
    if (client_id) {
      request.client.getPublic(client_id).then((client) => this.setState({client}))
    }
    this.requestPublicAlbum();
  };

  requestPublicAlbum = () => {
    request.public
      .album(this.props.token)
      .then(album => this.setState({ album }))
  };


  render = () => {
    const {album} = this.state

    if (this.state.playlistNotFound) {
      return (
        <React.Fragment>
          <PublicNavBar client={this.state.client && this.state.client} showLinks={false}/>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 215px)",
              marginTop: 100
            }}
          >
            <Typography variant="display2">{localization.get("public_playlist.non_existent")}</Typography>
            <Typography style={{ marginTop: "10px" }}>
              {localization.get("public_playlist.non_existent_extra")}
            </Typography>

            <Button
              style={{ backgroundColor: "black", marginTop: '40px', maxWidth: "140px" }}
              color="default"
              onClick={() => window.location.href = process.env.REACT_APP_WEB_HOST + '?section=contact_us' }
              simple
              size="lg"
              block
            >{localization.get("trial_login.contact")}</Button>

          </div>
          <PublicFooter {...this.props}/>
        </React.Fragment>
      );
    }

    if (!album) {
      return null;
    }

    const returnHere = () => this.props.history.push(`/public/albums/${this.props.token}`);

    const makeSoundPlayerData = type =>
      album[type].map(track => {
        const audio = track.audio;
        return {
          fetchAudioUrl: () => request.public.albumAudio(this.props.token, audio.id).then(a => a.url),
          title: track.title,
          image: makeImageUrlWithSize(getMostImportantAlbum(track.albums), 50),
          waveform: makeWaveformUrl(track),
          subtitle: album.title,
          onAnyClick: returnHere,
        };
      });

    const soundPlayerMusicsData = makeSoundPlayerData("musics");

    const makeTable = (type, soundPlayerData) => {
      if (album[type].length === 0) {
        return null;
      }
      return (
        <div>
          <ClientSidePaginationTable
            perPage={999}
            filterable={false}
            sortable={false}
            showPagination={false}
            zebraStyle={false}
            data={album[type]
              .map((audio, index) => ({ ...audio, index }))
              .filter(audio => !isNullOrUndefined(audio.audio))}
            columns={[
              {
                id: "image",
                accessor: track =>
                  <div
                    className="track-table-image"
                    style={{
                      top: "0px",
                      backgroundImage: track.speaker
                        ? `url(${makeImageUrlWithSize(track.speaker, 'xs')}), url(${emptyImage})`
                        : track.albums && track.albums.length > 0
                          ? `url(${makeUrlImageFromAlbums(track.albums)}), url(${emptyImage})`
                          :`url(${noImage})`,
                    }}
                  />,
                width: 100
              },
              { accessor: "title" },
              {
                id: "waveform",
                show: window.innerWidth > 600,
                accessor: track => <div
                  className="track-table-sound-wave"
                  style={{
                    width: "100%",
                    height: "45px",
                    backgroundImage:
                      track.audio && track.audio.waveform
                        ? `url(${`${process.env.REACT_APP_CDN_HOST}/files/images/${
                          track.audio.waveform.id
                          }/crop/300x50/waveform.png`})`
                        : `url(${SoundWave})`,
                  }}
                />
              },
              {
                id: "actions",
                accessor: track => {
                  const buttonBuilder = new TableButtonsBuilder()
                  buttonBuilder.withNewPlay(soundPlayerData, track.index)
                  buttonBuilder.withDownload(() => request.album.downloadDemo({
                    token: this.props.token,
                    audioId: track.demo.id,
                    fileName: `${track.id}-${track.title}`,
                  }))
                  return buttonBuilder.build()
                },
              },
            ]}
          />
        </div>
      );
    };

    return (
      <React.Fragment>
        <PublicNavBar client={this.state.client && this.state.client} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "calc(100vh - 170px)", margin: "30px 0"}}>
          <img
            src={makeImageUrlWithSize(album, 'md')}
            style={{ borderRadius: "6px" }}
            width={150}
            height={150}
            alt="..."
            onError={onImageError}
          />
          <h3>{album.title}</h3>
          <p>{album.subtitle}</p>
          <div id="public-playlist-tab" style={{ width: "100%", maxWidth: "800px", padding: "5px 15px" }}>
            <Tabs
              headerColor="info"
              tabs={[
                {
                  tabName: localization.get("musics"),
                  tabIcon: null,
                  tabContent: <div>{makeTable("musics", soundPlayerMusicsData)}</div>,
                }
              ]}
            />
          </div>
        </div>
        <PublicFooter {...this.props}/>
      </React.Fragment>
    );
  };
}

export default PublicAlbum;
