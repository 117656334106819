import getAll from './getAll'
import update from './update'
import create from './create'
import get from './get'
import del from './delete'
import getAllForEntity from './getAllForEntity'
import checkIfValid from "./checkIfValid";
import storeMany from "./storeMany";

export default host => ({
  getAll: getAll(host),
  update: update(host),
  get: get(host),
  create: create(host),
  delete: del(host),
  getAllForEntity: getAllForEntity(host),
  checkIfValid: checkIfValid(host),
  storeMany: storeMany(host),
})