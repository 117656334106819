import React, {Component} from "react";
import {request} from "../../api-client";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../components/Letflow/Table";
import localization from "../../config/localization";
import {
  getMostImportantAlbum,
  makeUrlImageFromAlbums,
  makeWaveformUrl,
  pushIfDoesNotExist
} from "../../utils";
import Tabs from "../../components/CustomTabs/CustomTabs";
import Button from "../../components/CustomButtons/Button";
import {
  Card,
  FormControlLabel, Grid,
  IconButton, Radio, RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {isNullOrUndefined} from "util";
import CloudDownload from "@material-ui/icons/CloudDownload";
import SoundWave from "assets/img/Letflow/sound-wave.png";
import noImage from "assets/img/Letflow/no-image.jpg";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import qs from 'qs'
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import MaterialGrid from "../../layouts/Home/Playlists/MaterialGrid";
import TableButton from "../../components/Letflow/Table/Buttons/TableButton";
import * as VCS from "../../components/Letflow/ContentCreatorViewComponents";
import './PublicPlaylist.css'
import styled from "styled-components";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize, onImageError, emptyImage} from "../../layouts/Home/HomeElements/utils";
import {
  getMainTranslation,
  getStoredChannel,
} from "../../api-client/core/authentication/utils";
import ContentCreationPlayer from "../../components/Letflow/NewContentCreationPlayer";
import TextField from "@material-ui/core/TextField/TextField";
import {
  GridOn,
  List,
  PlayArrow,
  Search,
} from "@material-ui/icons";
import CreationItem from "../../components/Letflow/CreationItem";
import analyticsInstance from "../../analytics/analyticsInstance";
import {get} from 'lodash'
import InfiniteScroll from "react-infinite-scroll-component";

class PublicPlaylist extends Component {
  state = {
    playlist: null,
    selectedTab: 0,
    searchInput: "",
    typeRadio: "all",
    viewType: "grid",
    currentContentCreations: [],
    brand: null,
    hasPrev: false,
    hasNext: false,
    currentPage: 0,
    itemsPerPage: 18, 
    hasMore: true,
  };

  componentDidMount = () => {
    let search = qs.parse(this.props.location.search.slice(1))
    let {materialsViewType, brand} = search

    if (materialsViewType) {
      this.setState({viewType: materialsViewType})
    }

    if (brand) {
      request.brands.getPublic(brand)
        .then(brand => this.setState({ brand }))
    }
    
    this.requestPublicPlaylist()
      .then(() => {
        document.title = this.state.playlist.title + ' - Feater'
        analyticsInstance.visitShareContent({
          type: "Playlist",
          name: this.state.playlist.title
        })
      });
  };

  requestPublicPlaylist = () =>
    request.public
      .playlist(this.props.token)
      .then(playlist => this.setState({ playlist, currentContentCreations: playlist.contentCreations.map((c,i) => ({ ...c, index: i })) }))
      .catch(_ => this.setState({ playlistNotFound: true }));


  openDownloadDialog = (track, trackContainer) => this.handleDownload(track, trackContainer, 'music')

  handleDownload = (track, trackContainer, type) => {
    const audioId = (['contentCreation', 'voice'].includes(type)) ? track.audio.id : track.demo.id;
    analyticsInstance.download({
      name: getMainTranslation(track, "title"),
      type: "Share_Playlist",
      from: type === "contentCreation" ? "Original_Material" : "Music",
    })

    return request.playlist.downloadDemo({
      token: this.props.token,
      audioId,
      fileName: `${track.id}-${track.title}`,
      image: this.makeSoundbarTrackImage(track, trackContainer),
    })
  };

  makeSoundbarTrackImage = (track, trackContainer) => {
    if (track.albums && track.albums.length > 0) {
      return makeUrlImageFromAlbums(track.albums);
    }
    return makeImageUrlWithSize(trackContainer, 'xs');
  };

  getFilteredCreations = (materials) => materials
    .filter(creation => creation.title.toLowerCase().includes(this.state.searchInput.toLowerCase()) || creation.contentCreator.name.toLowerCase().includes(this.state.searchInput.toLowerCase()))
    .filter(creation => {
      switch (this.state.typeRadio) {
        case "video":
          return !!creation.video;
        case "audio":
          return !!creation.audio;
        case "binary":
          return !!creation.binary;
        case "image":
          return !!creation.image && !creation.video && !creation.audio && !creation.binary;
        default:
          return true;
      }
    })

  getColumns = () => {
    const columns = [
      {
        id: "image",
        accessor: contentCreation => (
          <img
            alt="ContentCreator"
            src={(contentCreation.image || (contentCreation.video && contentCreation.video.thumbnail)) ?
              (!contentCreation.image ? makeThumbnailUrlWithSize(contentCreation.video) : makeImageUrlWithSize(contentCreation, 'xs')):
              makeImageUrlWithSize(contentCreation.contentCreator, 'xs')}
            width={50}
            height={50}
            onError={onImageError}
          />
        ),
        style: {
          float: "left"
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60
      },
      {
        Header: localization.get("table.name"),
        id: "title",
        accessor: contentCreation => <Tooltip placement="bottom" title={contentCreation.title}><span>{contentCreation.title}</span></Tooltip>,
        Filter: ({filter, onChange}) => (
          <input
            onChange={event => {
              onChange(event.target.value)
              this.setState({title: event.target.value || ""})
            }}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        )
      },
      {
        Header: localization.get("content_creator"),
        id: "contentCreator[name]",
        accessor: content => content.contentCreator ? <Tooltip placement="bottom" title={content.contentCreator.name}><span>{content.contentCreator.name}</span></Tooltip> : "",
        Filter: ({filter, onChange}) => (
          <input
            onChange={event => {
              this.setState({content_creator_id: event.target.value || ""})
              onChange(event.target.value)
            }}
            value={filter ? filter.value : ""}
            placeholder={localization.get("table.search")}
          />
        ),
        sortable: false,
        filterable: false,
        width: 300,
      },

      {
        id: "actions",
        Header: localization.get("table.actions"),
        sortable: false,
        filterable: false,
        accessor: contentCreation => {
          const buttonBuilder = new TableButtonsBuilder();

          if (contentCreation.allow_download || this.state.playlist.allow_download) {
            buttonBuilder.withOther(
              <TableButton
                title={localization.get('tooltip.play')}
                onClick={() => request.contentCreation.getDownloadLink(contentCreation.id, this.props.token, true)
                  .then(response => {
                    analyticsInstance.download({
                      name: getMainTranslation(contentCreation, "title"),
                      type: "Share",
                      from: "Material_For_Download",
                    })

                    window.open(response.url, '_blank')
                  })}>
                <CloudDownload/>
              </TableButton>
            )
          }


          buttonBuilder.withOther(
            <TableButton
              title={localization.get('tooltip.play')}
              onClick={() => this.setCurrentPlayingContent(contentCreation)}>
              <PlayArrow/>
            </TableButton>
          )


          return buttonBuilder.build();
        },
        width: 180,
        Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.currentContentCreations.length}</span>
      }];

    return columns;
  }

  fetchMoreData = (materials) => {
    const { currentPage, itemsPerPage } = this.state;
    const totalCreations = this.getFilteredCreations(materials);

    if (currentPage * itemsPerPage >= totalCreations.length) {
      this.setState({ hasMore: false }); 
      return;
    }

    this.setState({ currentPage: currentPage + 1 }); 
  };

  renderContentCreators = (contentCreators) => {
    return contentCreators.sort((a, b) => a.country && b.country && a.country.key.localeCompare(b.country.key)).map(contentCreator => {
      let hasAudio = false
      let hasVideo = false
      let hasImage = false
      contentCreator.contentCreations.forEach(creation => {
        if (creation.audio) hasAudio = true
        if (creation.video) hasVideo = true
        if (creation.image) hasImage = true
      });
      return this.renderContentCreatorView({
        name: contentCreator.name,
        country: contentCreator.country ? contentCreator.country.name : '',
        image: makeImageUrlWithSize(contentCreator, 'sm'),
        work:
          <React.Fragment>
            {(contentCreator.type.allow_audio) && hasAudio &&
              <ClientSidePaginationTable
                perPage={999}
                filterable={false}
                sortable={false}
                disableCustomLength
                showPagination={false}
                zebraStyle={false}
                data={contentCreator.contentCreations
                  .map((audio, index) => ({ ...audio, index }))
                  .filter(audio => !isNullOrUndefined(audio.audio))}
                columns={[
                  {
                    id: "image",
                    accessor: track =>
                      <div
                        className="track-table-image"
                        style={{
                          top: "0px",
                          backgroundImage: track.contentCreator
                            ? `url(${makeImageUrlWithSize(track.contentCreator, 'sm')}), url(${emptyImage})`
                              :`url(${noImage})`,
                        }}
                      />,
                    width: 100
                  },
                  { accessor: "title" },
                  {
                    id: "waveform",
                    show: window.innerWidth > 600,
                    accessor: track => <div
                      className="track-table-sound-wave"
                      style={{
                        width: "100%",
                        height: "45px",
                        backgroundImage:
                          track.audio && track.audio.waveform
                            ? `url(${`${process.env.REACT_APP_CDN_HOST}/files/images/${
                              track.audio.waveform.id
                            }/crop/300x50/waveform.png`})`
                            : `url(${SoundWave})`,
                      }}
                    />
                  },
                  {
                    id: "actions",
                    accessor: track => {
                      const buttonBuilder = new TableButtonsBuilder()

                      let soundPlayerData = contentCreator.contentCreations.map(track => {
                        let audio = track.audio;
                        return {
                          fetchAudioUrl: () => request.public.playlistAudio(this.props.token, audio.id).then(a => a.url),
                          title: track.title,
                          image: makeImageUrlWithSize(track.albums ? getMostImportantAlbum(track.albums) : track.speaker, 'xs'),
                          waveform: makeWaveformUrl(track),
                          subtitle: contentCreator.name,
                        };
                      })

                      buttonBuilder.withNewPlay(soundPlayerData, track.index)
                      buttonBuilder.withOther(
                        <TableButton
                          title={localization.get('download')}
                          onClick={() => this.handleDownload(track, track.contentCreator, 'contentCreation')}
                        >
                          <CloudDownload/>
                        </TableButton>
                      )

                      return buttonBuilder.build()
                    },
                  },
                ]}
              />
            }
            {(contentCreator.type.allow_image || contentCreator.type.allow_video) && (hasVideo || hasImage) &&
              <VCS.WorkContainerCarousel
                shape={(getStoredChannel() && getStoredChannel().preferences && getStoredChannel().preferences.content_creator) ? getStoredChannel().preferences.content_creator.card.shape : "rounded"}
                items={contentCreator.contentCreations}
                allowDownload={this.state.playlist.allow_download}
                handleDownload={id => request.contentCreation.getDownloadLink(id, this.props.token, true)
                  .then(response => {
                    analyticsInstance.download({
                      name: getMainTranslation(contentCreator, "name"),
                      type: "Share",
                      from: "Material_For_Download",
                    })

                    window.open(response.url, '_blank')
                  })}
                renderItem={contentCreation =>
                  <div>
                    {(contentCreation.video && !contentCreation.image) ?
                    <video
                      style={{
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        padding: 5
                      }}
                      poster={makeThumbnailUrlWithSize(contentCreation.video, 'md')}
                      src={contentCreation.video.url}
                    />
                    :
                    <img alt="photo"
                      style={{
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        padding: 5
                      }}
                      src={makeImageUrlWithSize(contentCreation, 'md')}
                      onError={onImageError}
                    />}
                  </div>
                }
                onItemClick={contentCreation => this.setState({selectedContentCreation: contentCreation, showContentCreationDialog: true})}
              />
            }
          </React.Fragment>,
        link: `/public/content_creators/${contentCreator.hashcode}${this.state.client ? `?client_id=${this.state.client.id}` : ''}`,
      })
    })
  };

  setCurrentPlayingContent = (creation, materialList = this.state.currentContentCreations) => {
      let hasPrev = false;
      let hasNext = false;

  
      if (materialList.length > 0) {
        const currIndex = materialList.find(e => creation.id === e.id).index
        
        hasPrev = currIndex > 0 ;
        hasNext = currIndex < (materialList.length - 1);
      }

    return this.setState({
      selectedContentCreation: creation,
      showContentCreationDialog: true, 
      currentContentCreations: materialList,
      hasPrev,
      hasNext
    })
  }


  handleCreationNavigation = (direction) => {
    const currIndex = this.state.currentContentCreations.find(e => this.state.selectedContentCreation.id === e.id).index

    this.setCurrentPlayingContent(this.state.currentContentCreations[currIndex + direction])
  }


  renderMaterialsView = (materials) => {
    let contentCreationsFilters = [];
    const filters = ["video", "audio", "binary", "image"];

    if (materials.length > 0) {
      // Generate array using contentCreationsFilters
      contentCreationsFilters = filters.filter(filter => {
        if (filter == "image") {
          return materials.some(creation => creation.image && !creation.video && !creation.audio && !creation.binary)
        }

        return materials.some(creation => creation[filter])
      });
    }

    return (<div>
      <h3 className="content-creator-title">{localization.get('content_creations')}</h3>
      <MaterialContainer>
        <div style={{ margin: 10, position: "relative", float: "left", display: "flex", maxWidth: 185}}>
          <TextField
            inputProps={{
              ref: input => {
                this.searchInput = input;
              },
              spellCheck: false,
              style: {
                color: "var(--main-font-color, black)",
                borderBottom: `2px solid var(--main-font-color, rgba(0,0,0, .5))`,
                width: 250,
                maxWidth: 250,
              },
            }}
            InputProps={{disableUnderline: true}}
            autoComplete={false}
            className="smart-search-input-search"
            placeholder={localization.get("searchbar.content_creators")}
            value={this.state.searchInput}
            onChange={e => this.setState({searchInput: e.target.value})}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.writeQueryStringParams(true)
              }
            }}
            style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
          />
          {window.innerWidth < 600 &&
            <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}}
                    onClick={this.writeQueryStringParams}/>
          }
        </div>
        <TableButton title={localization.get("searchbar.change_view")} onClick={() => this.setState({viewType: this.state.viewType === "grid" ? "list" : "grid"}, () => localStorage.setItem('playlistViewType', this.state.viewType))}>
          {this.state.viewType === "grid" ?
            <List  style={{color: "var(--main-font-color, black)"}} /> :
            <GridOn  style={{color: "var(--main-font-color, black)"}} />
          }
        </TableButton>
        <RadioGroup
          name="typeRadio"
          value={this.state.typeRadio}
          onChange={event => this.setState({typeRadio: event.target.value})}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row", marginLeft: 15}}
        >
          <FormControlLabel value="all" control={<Radio color="default"/>} label={localization.get('all')}/>
          {filters.map(filter => <FormControlLabel value={filter} disabled={!contentCreationsFilters.includes(filter)} control={<Radio color="default"/>} label={localization.get(filter)}/>)}
        </RadioGroup>
      </MaterialContainer>
      {this.state.viewType === 'grid' ?
        <Grid container fullWidth>
          {this.getFilteredCreations(materials).length > 0 ?
                    <InfiniteScroll
                    dataLength={(this.state.currentPage + 1) * this.state.itemsPerPage} 
                    next={() => this.fetchMoreData(materials)} 
                    hasMore={this.state.hasMore}
                  >
                  <Grid container fullWidth>
                    {this.getFilteredCreations(materials)
                      .slice(0, (this.state.currentPage + 1) * this.state.itemsPerPage)
                      .map((creation, key) =>
                  <Grid key={key} xs={6} sm={6} md={4} lg={4} xl={2} item style={{width: "100%", padding: 10}}>
                    <CreationItem
                      isPublic
                      onAddComment={item => this.openCommentDialog(item)}
                      removeFromPlaylist={() => request.playlist.removeFromPlaylist({
                        playlistId: this.state.playlist.id,
                        musicId: creation.id,
                        type: 'contentCreations'
                      })
                        .then(() => {
                          const filteredContentCreations = this.state.playlist.contentCreations.filter((newContentCreation) => creation.id !== newContentCreation.id)
                          this.setState(prevState => ({
                            playlist: {
                              ...prevState.playlist,
                              contentCreations: filteredContentCreations,
                            }
                          }))
                        })
                      }
                      contentCreation={creation}
                      onFavorite={() => this.onFavorite(creation)}
                      onPlay={() => this.setCurrentPlayingContent(creation)}
                      onShare={creation => request.contentCreation.shareByChannel(creation.id)
                        .then(response => this.setState({
                          showShareDialog: true,
                          shareUrl: response.url,
                          sharingCreation: creation.id,
                          sharingCreationTitle: creation.title,
                        }))
                      }
                    />
              
                  </Grid>
                )}
              </Grid>
            </InfiniteScroll>
          :
          <div style={{
            minHeight: 320,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <p>{localization.get('creation.not_found')}</p>
          </div>
        }
        </Grid>
        :
        <div style={{float: "left", position: "relative", width: "100%", padding: "0px 5px 15px"}}>
          <ClientSidePaginationTable
            data={this.getFilteredCreations(materials).map((creation, index) => ({...creation, index}))}
            columns={this.getColumns()}
            filterable={false}
            sortable={false}
            showPagination={true}
            zebraStyle={false}
            perPage={18}
            showPaginationBottom={true}
          />
        </div>
      }
    </div>
  )}

  renderContentCreatorView = ({name, country, image, link, work}) =>
    <div style={{float: "left", position: "relative", width: "100%", padding: "20px 10px"}}>
      <div className="public-content-creator-container">
        <div className="public-content-creator-image-container">
          <img style={{width: "100%", borderRadius: "50%"}} alt="director" src={image}/>
        </div>
        <div className="public-content-creator-info-container">
          <div className="public-content-creator-name" onClick={() => window.open(link)}>{name}</div>
          <div className="public-content-creator-country">{country}</div>
        </div>
      </div>
      <div className="public-content-creator-content-container">
        {work}
      </div>
    </div>

  renderContentCreationDialog = () => {
    const {selectedContentCreation, showContentCreationDialog} = this.state;

    return (
      <ContentCreationPlayer
        isPublic
        show={showContentCreationDialog}
        currentContentCreation={selectedContentCreation}
        handleClose={() => this.setState({showContentCreationDialog: false, selectedContentCreation: {}})}
        allowDownload={this.state.playlist.allow_download}
        onNextArrow={this.state.hasNext ? () => this.handleCreationNavigation(1) : null}
        onPrevArrow={this.state.hasPrev ? () => this.handleCreationNavigation(-1) : null}
        noAuthorLink
        handleDownload={() => request.contentCreation.getDownloadLink(selectedContentCreation.id, this.props.token, true)
          .then(response => {
            analyticsInstance.download({
              name: getMainTranslation(selectedContentCreation, "title"),
              type: "Share",
              from: "Material_For_Download"
            })
            window.open(response.url, '_blank')
          })}
      />
    )
  };

  setStylePreferences = (channel) => {
    document.documentElement.style.setProperty('--main-font', channel.preferences.main_font);
    document.documentElement.style.setProperty('--title-normal', channel.preferences.container_title.color);
    document.documentElement.style.setProperty('--title-hover', channel.preferences.container_title.color_hover || '#fff');
    document.documentElement.style.setProperty('--text-normal', channel.preferences.container_description.color);
    document.documentElement.style.setProperty('--text-hover', channel.preferences.container_description.color_hover || '#fff');
    document.documentElement.style.setProperty('--card-hover', channel.preferences.interaction_color || '#000');
    let interactionColor = channel.preferences.interaction_color ? channel.preferences.interaction_color : "rgba(0,0,0,1)";
    var match = interactionColor.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    let interactionColorRGB = match ? `${match[1]},${match[2]},${match[3]}` : '0,0,0';
    document.documentElement.style.setProperty('--card-hover-rgb', interactionColorRGB || '0,0,0');
    document.documentElement.style.setProperty('--main-color', channel.preferences.main_color || '#fff');
    document.documentElement.style.setProperty('--main-font-color', channel.preferences.main_font_color || '#000');
    document.documentElement.style.setProperty('--secondary-color', channel.preferences.secondary_color || '#fff');
    document.documentElement.style.setProperty('--secondary-font-color', channel.preferences.secondary_font_color || '#000');
    document.documentElement.style.setProperty('--footer-color', channel.preferences.footer_color || '#1E1E1E');
    document.documentElement.style.setProperty('--footer-font-color', channel.preferences.footer_font_color || 'darkgrey');
    document.documentElement.style.setProperty('--navbar-button-text-size', channel.preferences.navbar_button_size ? channel.preferences.navbar_button_size+"px" : '16px');
    document.documentElement.style.setProperty('--navbar-text-color', channel.preferences.navbar ? channel.preferences.navbar.font_color :  "rgb(0,0,0, 1)");
    let navbarBorderColor = (channel.preferences.navbar ? channel.preferences.navbar.font_color :  "rgb(0,0,0, 1)").split(',');
    document.documentElement.style.setProperty('--navbar-border-color', [navbarBorderColor[0],navbarBorderColor[1],navbarBorderColor[2], " .5)"].toString());
    document.documentElement.style.setProperty('--sub-navbar-background-color', get(channel, "preferences.sub_navbar.background_color", "rgb(255,255,255,1)"));
    document.documentElement.style.setProperty('--sub-navbar-font-color', get(channel, "preferences.sub_navbar.font_color", "rgb(0,0,0,1)"));
    document.documentElement.style.setProperty('--home-padding', `0px ${get(channel, "preferences.home_padding", 70)}px`)
    document.documentElement.style.setProperty('--sidebar-font-color', get(channel, "preferences.navbar.sidebar.font_color", "rgba(0,0,0,1)"))
    document.documentElement.style.setProperty('--sidebar-background-color', get(channel, "preferences.navbar.sidebar.background_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-button-font-color', get(channel, "preferences.navbar.sidebar.buttons.font_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-button-background-color', get(channel, "preferences.navbar.sidebar.buttons.background_color", "rgba(0,0,0,1)"))
    document.documentElement.style.setProperty('--sidebar-selector-font-color', get(channel, "preferences.navbar.sidebar.selectors.font_color", "rgb(255,255,255,1)"))
    document.documentElement.style.setProperty('--sidebar-selector-background-color', get(channel, "preferences.navbar.sidebar.selectors.background_color", "rgba(0,0,0,1)"))
  }



  render = () => {
    const {playlist, brand} = this.state

    if (this.state.playlistNotFound) {
      return (
        <React.Fragment>
          <PublicNavBar isPlaylist={true} showLinks={false} client={this.state.client && this.state.client} channel={playlist.channel} brand={brand}/>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 215px)",
              margin: 100
            }}
          >
            <Typography variant="display2">{localization.get("public_playlist.non_existent")}</Typography>
            <Typography style={{ marginTop: "10px" }}>
              {localization.get("public_playlist.non_existent_extra")}
            </Typography>

            <Button
              style={{ backgroundColor: "black", marginTop: '40px', maxWidth: "140px" }}
              color="default"
              onClick={() => window.location.href = process.env.REACT_APP_WEB_HOST + '?section=contact_us' }
              simple
              size="lg"
              block
            >{localization.get("trial_login.contact")}</Button>

          </div>
          <PublicFooter {...this.props}/>
        </React.Fragment>
      );
    }

    if (!this.state.playlist) {
      return null;
    }

    const returnHere = () => this.props.history.push(`/public/playlists/${this.props.token}`);

    const makeSoundPlayerData = type =>
      this.state.playlist[type].map(track => {
        let audio = track.audio;
        return {
          fetchAudioUrl: () => request.public.playlistAudio(this.props.token, audio.id).then(a => a.url),
          title: track.title,
          image: makeImageUrlWithSize(getMostImportantAlbum(track.albums), 'xs'),
          waveform: makeWaveformUrl(track),
          subtitle: this.state.playlist.title,
          onAnyClick: returnHere,
        };
      });

    const soundPlayerMusicsData = makeSoundPlayerData("musics");

    const makeTable = (type, soundPlayerData) => {
      if (this.state.playlist[type].length === 0) {
        return null;
      }

      return (
        <div>
          <ClientSidePaginationTable
            perPage={999}
            filterable={false}
            sortable={false}
            showPagination={false}
            disableCustomLength
            zebraStyle={false}
            data={this.state.playlist[type]
              .map((audio, index) => ({ ...audio, index }))
              .filter(audio => !isNullOrUndefined(audio.audio))}
            columns={[
              {
                id: "image",
                accessor: track =>
                  <div
                    className="track-table-image"
                    style={{
                      top: "0px",
                      backgroundImage: track.speaker
                        ? `url(${makeImageUrlWithSize(track.speaker, 'xs')})`
                        : track.albums && track.albums.length > 0
                          ? `url(${makeUrlImageFromAlbums(track.albums)}), url(${emptyImage})`
                          :`url(${noImage})`,
                    }}
                  />,
                width: 100
              },
              { accessor: "title" },
              {
                id: "waveform",
                show: window.innerWidth > 600,
                accessor: track => <div
                  className="track-table-sound-wave"
                  style={{
                    width: "100%",
                    height: "45px",
                    backgroundImage:
                      track.audio && track.audio.waveform
                        ? `url(${`${process.env.REACT_APP_CDN_HOST}/files/images/${
                          track.audio.waveform.id
                          }/crop/300x50/waveform.png`})`
                        : `url(${SoundWave})`,
                  }}
                />
              },
              {
                id: "actions",
                accessor: track => {
                  const buttonBuilder = new TableButtonsBuilder()

                  buttonBuilder.withNewPlay(soundPlayerData, track.index, "var(--main-font-color, black)")

                  buttonBuilder.withOther(
                    <TableButton
                      title={localization.get('download')}
                      onClick={() => {
                          this.openDownloadDialog(track, getMostImportantAlbum(track.albums))
                      }}
                    >
                      <CloudDownload style={{color: "var(--main-font-color, black)"}}/>
                    </TableButton>
                  )

                  return buttonBuilder.build()
                },
              },
            ]}
          />
        </div>
      );
    };

    let tabs = []
    if (soundPlayerMusicsData.length > 0) {
      tabs.push({
        tabName: localization.get("musics"),
        tabIcon: null,
        tabContent: <div>{makeTable("musics", soundPlayerMusicsData)}</div>,
      })
    }
    const contentCreatorTypes = []
    if ((playlist.contentCreators && playlist.contentCreators.length > 0) || (playlist.contentCreations && playlist.contentCreations.length > 0)) {
      playlist.contentCreators && playlist.contentCreators.length > 0 && playlist.contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type))
      playlist.contentCreations && playlist.contentCreations.length > 0 && playlist.contentCreations.forEach(contentCreation => pushIfDoesNotExist(contentCreatorTypes, contentCreation.contentCreator.type))


      contentCreatorTypes.forEach(contentCreatorType => {
        tabs.push({
          tabName: contentCreatorType.name,
          tabIcon: null,
          tabContent:<div>
            {playlist.contentCreators && playlist.contentCreators.length > 0 && this.renderContentCreators(playlist.contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id))}
            {playlist.contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id).length > 0 &&
              this.renderMaterialsView(playlist.contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id))
            }
          </div>
        })
      });
    }
    const backgroundColor = get(playlist, 'channel.preferences.secondary_color', 'white')
    const color = get(playlist, 'channel.preferences.navbar.font_color', 'black')

    if (playlist.channel) {
      this.setStylePreferences(playlist.channel)
    }

    return (
      <React.Fragment>
        {playlist.channel && <PublicNavBar isPlaylist={true} client={this.state.client && this.state.client} channel={playlist.channel} brand={brand}/>}
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "calc(100vh - 140px)", padding: "70px 0 50px" }}>
          <h3 >{this.state.playlist.title}  
          {(this.state.playlist.allow_download && get(this.state, "playlist.type", "client") == "tags") &&
            <Tooltip title={localization.get('download.zip')}>
              <IconButton style={{ zIndex: 10 }}
                onClick={() => window.open(request.public.downloadZip(this.props.token, ""), '_blank')}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          }
          </h3>
          <p >{this.state.playlist.subtitle}</p>
          {(get(this.state, "playlist.type", "client") != "tags" && get(this.state, "playlist.createdBy.name")) && <p>By {get(this.state, "playlist.createdBy.name")}</p>}
          {(get(this.state, "playlist.type", "client") != "tags") ?
          <div id="public-playlist-tab" style={{ width: "100%", maxWidth: "800px", position: "relative", marginTop: 30, border: `1px solid ${color}`, backgroundColor: backgroundColor, borderRadius: 15 }}>
            <Tabs
              headerColor="info"
              onTabChange={selectedTab => this.setState({selectedTab})}
              tabs={tabs}
              value={this.state.selectedTab}
              color={color}
              backgroundColor={backgroundColor}
            />
              <Tooltip title={localization.get('download.zip')}>
                <IconButton style={{
                  position: "absolute",
                  top: 25,
                  right: 25,
                  zIndex: 10
                }}
                  onClick={() => {
                    let type;

                    if (this.state.selectedTab === 0 && this.state.playlist.musics.length > 0) {
                      type = "music";
                    }else if (this.state.selectedTab === 0 && this.state.playlist.musics.length === 0) {
                      type = contentCreatorTypes[this.state.selectedTab].id
                    }else {
                      type = contentCreatorTypes[this.state.selectedTab-1].id
                    }

                    return window.open(request.public.downloadZip(this.props.token, type), '_blank')
                  }}
                >
                  <CloudDownload style={{color: "white"}}/>
                </IconButton>
              </Tooltip>
          </div>
        
        :
          <Card style={{ width: "100%", maxWidth: "1000px", position: "relative", padding: 15, marginTop: 30, border: `1px solid ${color}`, backgroundColor: backgroundColor, borderRadius: 15}}>
            <MaterialGrid
              {...this.props}
              token={this.props.token}
              playlistId={playlist.id}
              isPublic
              onPlay={this.setCurrentPlayingContent}
              useTagFilters={playlist.use_filters}
              allowDownload={this.state.playlist.allow_download}
            />
          </Card>
        }
      </div>
        {this.renderContentCreationDialog()}

        {playlist.channel && <PublicFooter {...this.props} noSocials hideSupport />}
      </React.Fragment>
    );
  };
}

export default PublicPlaylist;

const MaterialContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  color: var(--main-font-color);
  font-family: var(--main-font);
  @media (max-width: 460px) {
    flex-direction: column;
  }
`