import React, {Component} from 'react'
import '../../Home/SmartSearch/index.css'
import MusicsImage from "../../../assets/img/Letflow/SmartSearch/musics.jpg"
import localization from "../../../config/localization"
import {Link} from 'react-router-dom'
import {
  channelPathGenerator, slugify,
  setChannelPageTitle,
} from "../../../api-client/core/authentication/utils";
import {makeImageUrlWithSize, emptyImage} from "../../Home/HomeElements/utils";
import { request } from '../../../api-client'

class SmartSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      searchOptions: this.getSearchOptions(props.channel.channelContentCreatorTypes),
      gettingCreators: true,
      channel: props.channel
    }
  }

  getSearchOptions = (creatorTypes) => {
    let contentCreatorTypes = creatorTypes
      .filter(channelCCType => channelCCType.show_on_smart_search);
    let searchOptions = [];
    contentCreatorTypes.forEach(ccType => {
      ccType.allow_content_search && searchOptions.push({type: 'content', contentCreatorType: ccType.contentCreatorType, imageId: ccType.content_image_id, title: ccType.content_title});
      ccType.allow_creation_search && searchOptions.push({type: 'material', contentCreatorType: ccType.contentCreatorType, imageId: ccType.material_image_id, title: ccType.material_title});
      ccType.allow_event_search && searchOptions.push({type: 'event', contentCreatorType: ccType.contentCreatorType, imageId: ccType.event_image_id, title: ccType.event_title});
    })
    return searchOptions;
  }

  componentDidMount = () => {
    setChannelPageTitle(localization.get('title.smart_search'))

    document.getElementsByTagName("html")[0].scrollTop = 0;
    window.addEventListener('resize', this.setWindowsWidth)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setWindowsWidth)
  }

  setWindowsWidth = () => this.setState({width: window.innerWidth})

  getSearchOptionUrl = (searchOption) => {
    if (!searchOption) return '';
    let ccType = searchOption && searchOption.contentCreatorType;
    switch (searchOption.type) {
      case 'content':
        return channelPathGenerator(`busqueda-inteligente/contenido/${ccType.id}-${slugify(ccType.name)}?type=${ccType.slug}`)
      case 'event':
        return channelPathGenerator(`busqueda-inteligente/eventos/${ccType.id}-${slugify(ccType.name)}?type=${ccType.slug}`)
      case 'material': 
        return channelPathGenerator(`busqueda-inteligente/content_creations/${ccType.id}-${slugify(ccType.name)}?type=${ccType.slug}`)
      default:
        return '';
    }
  }

  getSearchOptionTitle = (searchOption) => {
    let title = searchOption.contentCreatorType.name;
    if (searchOption.title) {
      return searchOption.title;
    }
    switch (searchOption.type) {
      case 'event':
        return localization.get("smart_search.event_category", title);
      case 'material': 
        return localization.get("smart_search.material_category", title);
      default:
        return title;
    }
  }

  render = () => {
    if(this.props.channel) {
      if (this.props.channel.type == "music" || (this.props.channel.type == "mixed" && this.state.searchOptions.length === 0)) {
        this.props.history.push(channelPathGenerator(`busqueda-inteligente/musica`))
      }else if (this.props.channel.type == "content" && this.state.searchOptions.length === 1) {
        this.props.history.push(this.getSearchOptionUrl(this.state.searchOptions[0]))
      }
    }

    return (
      <div id="innerinner" style={{ marginBottom: '10px', marginTop: '25px' }}>
        <div className="smart-search-category-container" style={{textAlign: this.state.searchOptions.length > 2 && "left"}}>

          {this.state.channel.type !== 'content' &&
          <Link className="smart-search-item" to={channelPathGenerator(`busqueda-inteligente/musica`)}>
            <div className="smart-search-category">
              <div className="smart-search-category-title"
                   style={{color: "black"}}>{localization.get('smart_search.musics')}</div>
              <div className="smart-search-category-image" style={{backgroundImage: `url(${MusicsImage})`}}/>
            </div>
          </Link>
          }
          {this.state.searchOptions.sort((a, b) => a.contentCreatorType.name.localeCompare(b.contentCreatorType.name)).map(searchOption =>
            <Link className="smart-search-item" to={this.getSearchOptionUrl(searchOption)}>
              <div className="smart-search-category">
                <div className="smart-search-category-title" style={{color: `var(--main-font-color, ${this.state.width > 900 ? "black" : "white"})`}}>{this.getSearchOptionTitle(searchOption)}</div>
                <div className="smart-search-category-image" style={{backgroundImage: `url(${searchOption.imageId ? request.file.image.makeCroppedUrl(searchOption.imageId, 400 , 400) : makeImageUrlWithSize(searchOption.contentCreatorType, 'md')}), url(${emptyImage})` }}/>
              </div>
            </Link>

          )}
        </div>
      </div>
    )
  }



}

export default SmartSearch
