import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../../../components/Letflow/Table";
import {request} from '../../../../../api-client'
import localization from "../../../../../config/localization"
import {getActiveClient, homePathGenerator} from "../../../../../api-client/core/authentication/utils";

class UsersTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      clients: [],
    }
  }

  render = () => {

    return (
      <div>
        <ServerSidePaginationTable
          ref={r => this.table = r}
          columns={this.makeTableColumns()}
          requestTableData={paginationConfig => request.user.getAllForClient(getActiveClient(), paginationConfig)}
          perPage={999}
          showPagination={false}
        />
      </div>
    )
  }

  makeTableColumns = () => {

    const columns = []

    const nameColumn = {
      Header: localization.get('table.name'),
      accessor: "name",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder="Buscar"
        />
      ),
    }

    const emailColumn = {
      Header: localization.get('table.email'),
      accessor: "email",
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder="Buscar"
        />
      ),
    }

    const roleColumn = {
      Header: localization.get('table.role'),
      accessor: "role.description",
      filterable: false,
      sortable: false
    }

    const actionsColumn = {
      Header: localization.get('table.actions'),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: user => {
        const builder = new TableButtonsBuilder()
        user.inactive && builder
          .withNotify(user.name, () => request.user.notify(user.id))
        builder
          .withEdit(homePathGenerator(`account/users/${user.id}/edit`))
          .withDelete(user.name, () => request.user.delete(user.id), status => {
            if (status === 'passed') {
              this.table.removeById(user.id)
            }
          })
        return builder.build()
      }
    }

    columns.push(nameColumn)
    columns.push(emailColumn)
    window.innerWidth > 600 && columns.push(roleColumn)
    columns.push(actionsColumn)

    return columns
  }

}

export default UsersTable