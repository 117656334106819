

export const communityValidationOptions = ["country", "tel", "description", "profileImage", "coverImage", "creationDescription", "creationYear", "creationDate"]
export const channelPurposes = [
    { value :'citi', label: "Citi Channel"},
    { value: 'cms', label: "CMS"},
    { value :'ecommerce', label: "eCommerce"},
    { value :'festival', label: "Festival Channel"},
    { value :'futbol', label: "Futbol Club"},
    { value :'internal', label: "Internal Use"},
    { value :'landing', label: "Landing Page"},
    { value: 'music', label: "Music Channel"},
    { value :'news', label: "News"},
    { value :'political', label: "Political Channel"},
    { value :'tv', label: "TV Channel"},
]