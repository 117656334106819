import {makeRoutes, publicRoutes, route} from "./utils";
import Channel from "../../layouts/Channel";
import Dashboard from "../../layouts/Dashboard";
import AuthorizationCode from "../../layouts/Channel/Authorization/Code";

const allowed = publicRoutes.concat([
  route(`/panel/login-code`, AuthorizationCode),
  route(`/panel/gcba/login-code`, AuthorizationCode),
  route(`/panel/miba/login-code`, AuthorizationCode),
  route("/panel", Dashboard),
  route("/", Channel)
]);

export default makeRoutes(allowed, [], route("/", Channel));