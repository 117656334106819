import React from "react";
import { ReactTypeformEmbed } from 'react-typeform-embed';


export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoOpen: this.props.name && this.props.email && this.props.userid && this.props.typeformUrl
    }
    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    if (!this.props.name && this.props.email && this.props.typeformUrl) {
      return;
    }
    this.typeformEmbed.typeform.open();
  }

  onSubmit = () => {
    console.log('done');
    this.props.onSubmit && this.props.onSubmit();
  }


  render() {
    return (
      <div className="ExamplePopup">
        {this.props.open &&
          <ReactTypeformEmbed
            popup
            autoOpen={this.state.autoOpen}
            autoClose={this.props.closeTime || 1}
            url={`${this.props.typeformUrl}?name=${this.props.name}&email=${this.props.email}&userid=${this.props.userid}`}
            hideHeaders
            hideFooter
            onSubmit={this.onSubmit}
            // style={{ top: 100 }}
            ref={tf => {
              this.typeformEmbed = tf;
            }}
          />
        }
      </div>
    );
  }
}