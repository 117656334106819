import React from "react"
import {Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis} from 'recharts';
import Paper from "@material-ui/core/Paper";
import getRandomColor from "randomcolor";
import {resolveKey} from "./utils";

const getColor = (id, colors) => {
  if (colors && id in colors) {
    return colors[id];
  }

  return getRandomColor({ seed: id, luminosity: 'bright' })
}

const RankingChart = ({title, data, idKey, labelKey, valueKey, name, reference, formatter, colors}) =>
  <div className="dashboard-graph">
    {title && <h4 style={{ marginTop: '0px' }}>{title}</h4>}
    <BarChart width={500} height={250} data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <CartesianGrid strokeDasharray="3 3"/>
       <XAxis dataKey={labelKey}/>
       <YAxis/>
       <Tooltip formatter={formatter} />
       <Bar dataKey={valueKey} name={name} fill="#8884d8">
        {data.map((entry, i) => <Cell key={`cell-${i}`} fill={getColor(resolveKey(idKey, entry), colors)}/>)}
       </Bar>
    </BarChart>
    { reference && <Paper className="recharts-legend" style={{ marginLeft: "calc(50% - 125px)", textAlign: 'left', float: "left" }}>{reference}</Paper> }
    </div>

export default RankingChart
