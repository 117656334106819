import React, {Component} from 'react'
import _ from 'lodash'
import localization from '../../../config/localization';
import Message from './Message';
import styled from "styled-components";

const containerStyle = (isSearching) => ({
  height: '67vh',
  margin: '15px 0',
  overflowY: 'scroll',
  padding: '0 15px',
  paddingTop: 15,
  border: 'solid 1px #dcdcdc',
  backgroundColor: isSearching ? '#eaeaea' : 'white'
})

class MessagesContainer extends Component {

  scrollToMessage = messageId => {
    document.getElementById(`pitch-message-${messageId}`).scrollIntoView({block: 'nearest'})
  }

  scrollToBottom = () => (this.rootDivRef.scrollTop = this.rootDivRef.scrollHeight)

  DateSeparator = ({date}) => {

    const Line = () =>
      <div style={{flex: 4, display: 'flex', alignItems: 'center', width: '100%'}}>
        <div style={{height: 1, backgroundColor: '#999', width: 'inherit'}}/>
      </div>

    const Date = () => <DivR><b style={{fontSize: "0.9em"}}>{localization.moment(date).format('DD-MM-YYYY')}</b></DivR>

    return (
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
        <Line/>
        <Date/>
        <Line/>
      </div>
    )
  }

  render = () => {

    let {
      clients,
      pitchId,
      onlineVideosPath,
      pitchMessages,
      onlineVideos,
      isSearching,
      isAdministrator,
      hasPrevious,
      hasNext,
      onMessageClick,
      onPrevious,
      onDelete,
      onNext,
    } = this.props

    pitchMessages = _.uniqBy(pitchMessages, x => x.id)

    pitchMessages = pitchMessages.map(message => ({
      ...message,
      created_at: localization.moment(message.created_at)
        .subtract(new Date().getTimezoneOffset(), 'minutes')
    }))

    const pitchMessagesGroupedByDate = _.groupBy(pitchMessages, message => message.created_at.format('YYYY-MM-DD'))

    const handleRootDivRef = ref => (this.rootDivRef = ref)

    return (
      <div ref={handleRootDivRef} style={containerStyle(isSearching)}>
        {isSearching && <h3>{localization.get('search_results')}</h3>}
        {hasPrevious &&
          <ViewMoreButton onClick={onPrevious} text={localization.get('pitches.view_previous_messages')}/>
        }
        {
          _.entries(pitchMessagesGroupedByDate)
            .map(([date, messages]) =>
              <div key={date}>
                <this.DateSeparator date={date}/>
                {messages.map(pitchMessage =>
                  <Message
                    clients={clients}
                    pitchId={pitchId}
                    key={pitchMessage.id}
                    message={pitchMessage}
                    onlineVideos={onlineVideos}
                    onlineVideosPath={onlineVideosPath}
                    isSearching={isSearching}
                    onClick={() => onMessageClick(pitchMessage)}
                    isAdministrator={isAdministrator}
                    onDelete={onDelete}
                  />
                )}
              </div>
            )
        }
        {hasNext && <ViewMoreButton onClick={onNext} text={localization.get('pitches.view_next_messages')}/>}
      </div>
    )
  }
}

const ViewMoreButton = ({onClick, text}) =>
  <div style={{
    marginBottom: 10,
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: 10,
    backgroundColor: '#b7b7b7',
    color: 'white',
    textAlign: 'center'
  }} onClick={onClick}>{text}</div>

export default MessagesContainer

const DivR = styled.div`
  flex: 1.25;
  text-align: center;
  margin: 0 10px;
@media (max-width: 1250px) {
   flex: 1.5;
@media (max-width: 605px) {
   flex: 3;
}
`;
