import {appendPaginationQueryParamsToUrl, get} from '../utils'
import { getDateInt } from '../../../utils';

const url = (host,id, tags, notTagsIds, energy, tempo, duration, orderBy, sortType, onlyGold, catalogId, catalogIds, options, channelId = null) => {
  const seed = orderBy === 'default' ? `&seed=${getDateInt()}` : ''
  return appendPaginationQueryParamsToUrl(`${host}/clients/${id}${!!channelId ? `/channels/${channelId}` : ""}/musics?tags_ids=${tags.join(',')}&not_tags_ids=${notTagsIds.join(',')}${addFilters(energy, tempo, duration)}${seed}&include=recentLicenses;versions_count;tags;artist;audio;audio.waveform;favorite;channelFavorite;albums.image&order_by=${orderBy}&sort_type=${sortType}${onlyGold ? '&album_category_id=4' : ''}${catalogId ? `&catalog_id=${catalogId}` : ''}${catalogIds ? `&${catalogIds}` : ''}`, {...options, alreadyHasQueryParams: true})
}
const addFilters = (energy, tempo, duration) => {
  let filters = '';
  if (energy) filters += `&${energy}`
  if (tempo) filters += `&${tempo}`
  if (duration) filters += `&${duration}`
  return filters
}

export default host => (id, tags, notTagsIds, energy, tempo, duration, orderBy, sortType, onlyGold, catalogId, catalogIds, options, channelId) =>
  get(url(host, id, tags, notTagsIds, energy, tempo, duration, orderBy, sortType, onlyGold, catalogId, catalogIds, options, channelId))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)


