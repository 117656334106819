import React, {Component} from "react";
import localization from "../../config/localization";
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import {Formik} from "formik";
import {validateEmail} from "../../utils";
import {request} from "../../api-client";
import * as WLFH from "../../components/Letflow/Form/WebLikeFormHelpers";
import GlobalSnackbar from "../../components/Letflow/Snackbar/GlobalSnackbar";
import GlobalConfirmationDialog from "../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {Dialog, DialogContent} from "@material-ui/core";
import {BounceLoader} from "react-spinners";
import PublicFooter from "../../layouts/Home/HomeElements/PublicFooter";
import SocialLinks from "../../components/Letflow/SocialLinks";

class PublicCreateSpeaker extends Component {
  render = () => {
    return (
      <React.Fragment>
        <PublicNavBar publicLogo={"FlowlikeMusic"} showLinks={false} showPanel={false} />
        <div
          style={{
            minHeight: "100vh",
            width: "100%"
          }}
        >
          <FormController />
          <PublicFooter {...this.props} />
        </div>
      </React.Fragment>
    );
  };
}

class FormController extends Component {
  state = {
    tagGroups: [],
    sendingCurrentStep: 0,
    sendingSteps: 0,
    submitted: false,
    countries: []
  };

  componentDidMount = () => {
    request.country.getAll().then(countries => this.setState({countries}))

    request.tagGroup.getAllOfTypeSpeaker().then(res =>
      this.setState({
        tagGroups: res.map(tg => ({
          label: tg.name,
          tags: tg.tags.map(t => ({
            value: t.id,
            label: t.name,
            tagGroupId: tg.id
          }))
        }))
      })
    );
  };

  validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = localization.get("form.validation.required");
    }
    if (!validateEmail(values.email)) {
      errors.email = localization.get("form.validation.invalid_email");
    }
    if (!values.countryId) {
      errors.countryId = localization.get("form.validation.required");
    }
    if (!values.birthDate) {
      errors.birthDate = localization.get("form.validation.required");
    }
    if (values.demos.length === 0 || values.demos.length > 3) {
      errors.demos = localization.get(
        "form.validation.files_required_range",
        1,
        3
      );
    }
    if (values.comercials.length === 0 || values.comercials.length > 4) {
      errors.comercials = localization.get(
        "form.validation.files_required_range",
        1,
        4
      );
    }
    if (values.photos.length === 0 || values.photos.length > 1) {
      errors.photos = localization.get(
        "form.validation.files_required_range",
        1,
        1
      );
    }
    return errors;
  };

  initialValues = () => ({
    name: "",
    email: "",
    countryId: '',
    country: [],
    birthDate: undefined,
    description: "",
    demos: [],
    comercials: [],
    photos: [],
    selectedTags: []
  });

  createSpeaker = values => {
    this.setState({
      sendingCurrentStep: 0,
      sendingSteps: 1 + values.demos.length + values.comercials.length
    });
    return request.speaker.create(
      {
        name: values.name,
        email: values.email,
        birthdate: values.birthDate,
        countryId: values.countryId,
        translations: [
          {
            language: localization.getLanguage(),
            description: values.description
          }
        ],
        imageFile: values.photos[0],
        selectedTags: values.selectedTags.map(x => x.value),
        socialLinks: values.socialLinks
      },
      { path: "/public/speakers/demos" }
    );
  };

  createVoices = (createdSpeaker, files) => {
    return files.reduce((acc, next) => {
      return acc.then(() => {
        this.setState({
          sendingCurrentStep: this.state.sendingCurrentStep + 1
        });
        // Return an always resolving promise because i dont care about the upload results
        return new Promise(resolve => {
          request.voice
            .create({
              title: next.name,
              active: false,
              speakerId: createdSpeaker.data.id,
              audioFile: next
            })
            .finally(resolve);
        });
      });
    }, Promise.resolve());
  };

  showUpdateSuccessful = () => {
    const returnToWeb = () =>
      (window.location.href = process.env.REACT_APP_WEB_HOST);
    GlobalConfirmationDialog.show({
      title: localization.get("demo.uploaded_dialog_title"),
      content: localization.get("demo.uploaded_dialog_content"),
      onClose: returnToWeb,
      hideCancel: true,
      request: () => Promise.resolve().then(returnToWeb)
    });
  };

  render = () => {
    return (
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          this.createSpeaker(values)
            .then(cs =>
              this.createVoices(
                cs,
                [...values.demos].concat([...values.comercials])
              )
            )
            .then(() => {
              this.showUpdateSuccessful();
              this.setState({ submitted: true });
            })
            .catch(() => GlobalSnackbar.showGenericError())
            .finally(() => actions.setSubmitting(false));
        }}
      >
        {formikProps => (
          <React.Fragment>
            <FormView
              tagGroups={this.state.tagGroups}
              selectedTags={formikProps.values.selectedTags}
              errors={formikProps.errors}
              touched={formikProps.touched}
              onChange={(field, value) => {
                formikProps.setFieldValue(field, value);
              }}
              onSubmit={formikProps.handleSubmit}
              submitted={this.state.submitted}
              countries={this.state.countries}
            />
            <Dialog open={formikProps.isSubmitting}>
              <DialogContent>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ paddingRight: "1rem" }}>{`${localization.get(
                    "demo.uploading"
                  )} ${this.state.sendingCurrentStep} / ${
                    this.state.sendingSteps
                  }`}</div>
                  <BounceLoader size={26} />
                </div>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
      </Formik>
    );
  };
}

const FormView = ({
  tagGroups,
  selectedTags,
  errors,
  touched,
  submitted,
  onChange,
  onSubmit,
  countries
}) => {
  return (
    <WLFH.FormContainer errors={errors} touched={touched} onChange={onChange}>
      <WLFH.Title>{localization.get("voice_talent.upload_title")}</WLFH.Title>
      <WLFH.SectionTitle>{localization.get("form.info")}</WLFH.SectionTitle>
      {/** Name */}
      <WLFH.Input
        field="name"
        type="text"
        placeholder={localization.get("form.name") + " *"}
      />
      {/** Email */}
      <WLFH.Input
        field="email"
        type="text"
        placeholder={localization.get("form.email") + " *"}
      />
      {/** Country */}
      <WLFH.FieldTitle>
        {localization.get("form.country_of_origin")}
      </WLFH.FieldTitle>
      <WLFH.Select
        field="countryId"
        options={countries.map(x => ({ value: x.id, label: x.name }))}
      />
      {/** Birthdate */}
      <WLFH.FieldTitle>{localization.get("form.birth_date")}</WLFH.FieldTitle>
      <WLFH.DatePicker field="birthDate" type="date" />

      {/** Description */}
      <WLFH.TextArea
        field="description"
        type="text"
        placeholder={`${localization.get(
          "form.description"
        )} (${localization.get("form.optional")})`}
      />

      <SocialLinks
        onSocialLinksChange={(socialLinks) => onChange('socialLinks', socialLinks)}
        publicView={true}
      />

      {/** Photos */}
      <WLFH.MediaPicker
        field="photos"
        title={localization.get("form.pick_high_quality_pictures_range")}
        accept="image/*"
      />
      <WLFH.SectionTitle>{localization.get("form.media")}</WLFH.SectionTitle>
      {/** Demos */}
      <WLFH.MediaPicker
        field="demos"
        title={localization.get("voice_talent.demo_picker_title", 3)}
        accept=".mp3"
      />
      {/** Comercials */}
      <WLFH.MediaPicker
        field="comercials"
        title={localization.get("voice_talent.comercial_picker_title", 4)}
        accept=".mp3"
      />
      <WLFH.SectionTitle>{localization.get("form.tags")}</WLFH.SectionTitle>
      {/** Tags */}
      {tagGroups.map((tg, key) => (
        <WLFH.TagGroup
          key={key}
          field="selectedTags"
          label={tg.label}
          tags={tg.tags}
          selectedTags={selectedTags}
        />
      ))}
      <WLFH.SubmitButton
        onClick={onSubmit}
        disabled={submitted}
        hasErrors={Object.keys(errors).length > 0}
      />
    </WLFH.FormContainer>
  );
};

export default PublicCreateSpeaker;
