import "layouts/Home/Home.css";
import React, {Component} from "react";
import {request} from "../../api-client";
import Button from "components/CustomButtons/Button.jsx";
import localization from "../../config/localization";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip
} from "@material-ui/core";
import {validateEmail} from "../../utils";
import {Add, Close, Email, FilterNone, Link, Remove} from '@material-ui/icons'
import GlobalSnackbar, {GlobalSnackbarTypes} from "./Snackbar/GlobalSnackbar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getActiveClient, userIsSupervisor} from "../../api-client/core/authentication/utils";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

class ShareDialog extends Component {

  state = {
    emails: [],
    newEmail: '',
    submiting: false,
    shareUrl: null,
    errorMessage: '',
    copied: localization.get('copy_link'),
    showEmailInput: true,
    downloadWithOutWatermark: false,
    receivers: [],
    newReceiver: '',
    showReceiverInput: true,
    shortLinkTarget: [],
    hasInputError: false,
    showShareEmailCompleted: false,
    disabled: true,
    value: 0
  };

  handleEmailChange = emailIndex => e => {
    this.setState({
      emails: this.state.emails
        .map((email, index) => {
          if (emailIndex === index) {
            return e.target.value
          }
          return email
        })
        .filter(email => typeof email === 'string' && email !== '')
    })
  };

  handleNewEmailChange = e => this.setState({newEmail: e.target.value})

  handleEmailRemove = emailIndex => {
    this.state.emails.splice(emailIndex, 1)
    this.setState({emails: this.state.emails})
  }

  handleReceiverChange = receiverIndex => e => {
    this.setState({
      receivers: this.state.receivers
        .map((receiver, index) => {
          if (receiverIndex === index) {
            return ({index, name: e.target.value})
          }
          return receiver
        })
        .filter(receiver => typeof receiver === 'string' && receiver != '')
    })
  }

  handleNewReceiverChange = e =>
    this.setState({
      newReceiver: {id: this.state.receivers.length + 1, value: e.target.value}
    })


  handleClose = () => {
    if (!this.state.submiting) {
      this.props.handleClose && this.props.handleClose()
      this.setState({
        emails: [],
        receivers: [],
        newEmail: '',
        submiting: false,
        shareUrl: null,
        showShareEmailCompleted: false,
        hasInputError: false,
        value: 0
      })
    }
  }


  submit = () => {
    const emails = typeof this.state.newEmail === 'string' && this.state.newEmail !== '' ? this.state.emails.concat([this.state.newEmail]) : this.state.emails
    const {downloadWithOutWatermark} = this.state

    for (let i = 0; i < emails.length; i++) {
      const email = emails[i]
      if (!validateEmail(email)) {
        GlobalSnackbar.show({
          message: localization.get('dialog.invalid_email', email),
          type: GlobalSnackbarTypes.ERROR,
        })

        return
      }
    }

    this.setState({submiting: true})

    this.entityProperties().apiRequest(emails, downloadWithOutWatermark)
      .then(response => {
        this.setState({submiting: false, shareUrl: response.url, disabled: false})
        if (emails.length > 0) {
          this.setState({showShareEmailCompleted: true, emails})
        }
      })
      .catch(() => {
        this.setState({submiting: false, errorMessage: localization.get('dialog.playlist_share_failed')})
      })
  }

  validateEmailAndProvideFeedback = email => {
    const valid = validateEmail(email)
    if (!valid) {
      this.setState({hasInputError: true})
      GlobalSnackbar.show({message: localization.get('dialog.invalid_email', email), type: GlobalSnackbarTypes.ERROR})
    }else {
      this.setState({hasInputError: false})
    }
    return valid
  }

  validateInputAndProvideFeedback = input => {
    const valid = (!!input);
    if (!valid) {
      this.setState({hasInputError: true})
      GlobalSnackbar.show({message: localization.get('dialog.invalid_input', input), type: GlobalSnackbarTypes.ERROR})
    }else {
      this.setState({hasInputError: false})
    }
    return valid
  }


  entityProperties = () => {

    const entity = this.props.entity;
    const errorMsg = 'Non configured entitity for shareLink dialog: ' + this.props.entity;

    let title = '';
    let instructions = '';
    let apiRequest = () => Promise.reject(errorMsg);
    let receivedTitle = '';

    switch (entity) {
      case 'playlist':
        title = localization.get('dialog.share_playlist')
        instructions = localization.get('dialog.share_playlist_instructions')
        apiRequest = (emails, downloadWithOutWatermark) => request.playlist.share(this.props.id, emails, getActiveClient(), downloadWithOutWatermark)
        receivedTitle = localization.get('share_dialog.active_url')
        break;
      case 'album':
        title = localization.get('dialog.share_album')
        instructions = localization.get('dialog.share_album_instructions')
        apiRequest = (emails) => request.album.share(this.props.id, emails, getActiveClient())
        receivedTitle = localization.get('share_dialog.active_url')
        break;
      case 'material':
        title = localization.get('dialog.share_material')
        instructions = localization.get('dialog.share_material_instructions')
        apiRequest = (emails) => request.pitch.shareMaterial(this.props.id, this.props.pitchId, emails, getActiveClient())
        break;
      case 'onlineMaterial':
        title = localization.get('dialog.share_material')
        instructions = localization.get('dialog.share_material_instructions')
        apiRequest = (emails) => request.pitch.shareApprovedMaterial(this.props.id, this.props.pitchId, emails, getActiveClient())
        break;
      case 'contentCreator':
        title = localization.get('dialog.share_content_creator')
        instructions = localization.get('dialog.share_content_creator_instructions')
        apiRequest = (emails) => request.contentCreator.share(this.props.id, emails, getActiveClient())
        break;
      case 'reel':
        title = localization.get('dialog.share_reel');
        instructions = localization.get('dialog.share_reel_instructions');
        apiRequest = (emails) => request.clientFile.share(this.props.id, emails, getActiveClient());
        break;
      default:
        console.warn(errorMsg)
    }

    return {
      title,
      instructions,
      apiRequest,
      receivedTitle
    }
  };

  copyStringToClipboard = (text) => {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
    return;
  }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  };

  fallbackCopyTextToClipboard = (text) =>  {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  addNewEmailToEmailsCollection = () => {
    if (this.validateEmailAndProvideFeedback(this.state.newEmail)) {
      this.setState({emails: this.state.emails.concat([this.state.newEmail]), newEmail: ''})
    }
  };

  addNewReceiverToReceiversCollection = () => {
    if (!this.state.showReceiverInput){
      this.setState({showReceiverInput: true})
    } else {
      if (this.validateInputAndProvideFeedback(this.state.newReceiver.value)) {
        this.setState({receivers: this.state.receivers.concat([this.state.newReceiver]), newReceiver: {value: ''}})
        request.shareLink.create(this.state.shareUrl, this.state.newReceiver.value).then(response =>
          this.setState({[this.state.receivers.length - 1]: response.url}))
      }
    }
  };

  generateNewShareLink = () => {
    if (this.validateInputAndProvideFeedback(this.state.newReceiver.value)) {
      this.setState({receivers: this.state.receivers.concat([this.state.newReceiver]), newReceiver: {value: ''}, showReceiverInput: false})
      request.shareLink.create(this.state.shareUrl, this.state.newReceiver.value).then(response => {
        this.setState({[this.state.receivers.length - 1]: response.url, copied: localization.get('copied')})
        this.copyStringToClipboard(response.url)
      })
    }
  };

  handleChange = (event, value) => {
    this.setState({ value })
    if (value === 1) {
      this.setState({emails: [], newEmail: ''}, () => this.submit())
    }
  }

  makeEmailDialogContent = () =>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
        <div>{this.entityProperties().instructions}</div>
        {
          this.state.emails.map((email, index) =>
            <div style={{ display: 'flex', alignItems: 'baseline', width: "100%" }}>
              <TextField
                style={{ flex: 1 }}
                name="email"
                disabled={true}
                id="email"
                autoComplete={false}
                value={email}
                onChange={this.handleEmailChange(index)}
              />
              <IconButton onClick={() => this.handleEmailRemove(index)} ><Remove /></IconButton>
            </div>
          )
        }
        {this.state.showEmailInput &&
        <div style={{ display: 'flex', width: "100%" }}>
          <TextField
            style={{ flex: 1 }}
            label={localization.get('form.email')}
            value={this.state.newEmail}
            autoComplete={false}
            error={this.state.hasInputError}
            onChange={this.handleNewEmailChange}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.addNewEmailToEmailsCollection()
              }
            }}
          />
          <IconButton onClick={this.addNewEmailToEmailsCollection}><Add /></IconButton>
        </div>
        }

        {(userIsSupervisor() && this.props.entity === 'playlist') &&
        <FormControlLabel
          style={{float: "left", width: "100%",marginTop: 30}}
          control={
            <Checkbox
              style={{color: "black"}}
              checked={this.state.downloadWithOutWatermark}
              onChange={() => this.setState({downloadWithOutWatermark: !this.state.downloadWithOutWatermark})}
            />
          }
          label={localization.get('share_dialog.download_demo')}
        />
        }
      </div>

  makeLinkDialogContent = () =>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center"}}>
      <div>{localization.get('dialog.share_url_instructions')}</div>
      <div style={{width: "100%", marginBottom: "10px"}}>{this.entityProperties().receivedTitle}</div>
      <div style={{textAlign: 'center', width: "100%"}}>

        {
          this.state.receivers.map((receiver, index) =>
            <div style={{display: 'flex', width: "100%"}}>
              <TextField
                style={{flex: 1, marginRight: "20px"}}
                name="receiver"
                id="receiver"
                disabled={true}
                autoComplete={false}
                label={localization.get('form.receiver')}
                value={receiver.value}
                onChange={this.handleReceiverChange(index)}
              />
              <p style={{paddingTop: "20px"}}>
                {this.state[index]}
              </p>
              <div>
                <CopyToClipboard
                  onCopy={() => this.setState({copied: localization.get('copied')})}
                  onMouseEnter={() => this.setState({copied: localization.get('copy_link')})}
                  text={this.state[index]}
                >
                  <IconButton style={{marginTop: "5px"}}>
                    <Tooltip title={localization.get(this.state.copied)} placement="top">
                      <FilterNone/>
                    </Tooltip>
                  </IconButton>
                </CopyToClipboard>
              </div>
            </div>
          )
        }

        {this.state.showReceiverInput &&
        <div style={{display: 'flex', width: "100%"}}>
          <TextField
            style={{flex: 1}}
            label={localization.get('form.receiver')}
            value={this.state.newReceiver.value}
            onChange={this.handleNewReceiverChange}
            disabled={this.state.disabled}
            autoComplete={false}
            error={this.state.hasInputError}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.addNewReceiverToReceiversCollection()
              }
            }}
          />
          <div  >
            <CopyToClipboard
              onCopy={() => {
                this.generateNewShareLink()
                //this.setState({copied: localization.get('copied')})
              }
              }
              onMouseEnter={() => this.setState({copied: localization.get('generate_link')})}
            >
              <IconButton>
                <Tooltip title={localization.get(this.state.copied)} placement="top">
                  <FilterNone/>
                </Tooltip>
              </IconButton>
            </CopyToClipboard>
          </div>
        </div>
        }
        <IconButton onClick={this.addNewReceiverToReceiversCollection}><Add/></IconButton>

      </div>
    </div>

  makeEmailCompleteSharingDialog = () =>
    <Dialog open={this.state.showShareEmailCompleted} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={this.handleClose}>
        <Close />
      </IconButton>
      <DialogTitle>{this.entityProperties().title}</DialogTitle>
      <DialogContent>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: "center"}}>
          <div>{localization.get('dialog.share_track_instructions', this.state.emails.join(', '))}</div>
        </div>
      </DialogContent>
    </Dialog>

  makeShareDialog = () =>
    <Dialog open={this.props.show} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
    <Paper square style={{ width: "100%" }}>
      <Tabs
        value={this.state.value}
        onChange={this.handleChange}
        centered
        fullWidth
        indicatorColor="secondary"
        textColor="#F2000000"
      >
        <Tab icon={<Email />} label={localization.get('share.by_email')} />
        <Tab icon={<Link />} label={localization.get('link_no_sharing')} />
      </Tabs>
      <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={this.handleClose}>
        <Close />
      </IconButton>
    </Paper>
      <DialogContent style={this.props.entity === 'playlist' ? {minHeight: this.state.value === 0 ? '272px' : '337px'} : {minHeight: this.state.value === 0 ? '208px' : '265px'}}>
        <h3 style={{fontSize: '1.3125rem', fontWeight: 500, color: '#000000de'}}>{this.entityProperties().title}</h3>
        {this.state.value === 0 ? this.makeEmailDialogContent() : this.makeLinkDialogContent()}
      </DialogContent>
      {this.state.value === 0 &&
      <DialogActions style={{margin: "0 4% 4%"}}>
          <Button onClick={this.submit} color='default' style={{ backgroundColor: "black" }} disabled={this.state.submiting}>
          {localization.get("share")}
        </Button>
      </DialogActions>
      }
    </Dialog>

  render = () => <span>
    {this.makeShareDialog()}
    {this.makeEmailCompleteSharingDialog()}
  </span>
}

export default ShareDialog

