import {post} from '../utils'
import {getActiveClient} from "../authentication/utils";

export default host => (files, reel= {}) => {
  const fileFormData = new FormData()
    files.forEach(file => {
      fileFormData.append('files_ids[]', file.id ? file.id : file)
    })

    reel.title && fileFormData.append('title', reel.title)
    reel.color && fileFormData.append('color', reel.color)
    reel.secondColor && fileFormData.append('secondColor', reel.secondColor)
    reel.thirdColor && fileFormData.append('thirdColor', reel.thirdColor)
    reel.fourthColor && fileFormData.append('fourthColor', reel.fourthColor)
    reel.viewType && fileFormData.append('viewType', reel.viewType)

  return post(`${host}/clients/${getActiveClient()}/reel`, fileFormData)
    .then(response => response.json())
    .then(response => response.data)
}