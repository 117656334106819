import React, {Component} from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization"
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {CardActions, TextField} from "@material-ui/core";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {validateEmail} from "../../../utils";
import {getStoredUser, homePathGenerator} from "../../../api-client/core/authentication/utils";


export default class extends Component {
  state = {
    name: getStoredUser() ? getStoredUser().name : '',
    email: (getStoredUser() && getStoredUser().email) ? getStoredUser().email : '',
    description: '',
    disabled: false,
  };

  onSubmit = () => {
    const { name, email, description } = this.state;
    this.setState({disabled: true});

    if (!validateEmail(email)) {
      GlobalSnackbar.show({ message: localization.get('dialog.invalid_email', email), type: GlobalSnackbarTypes.ERROR,});
      this.setState({ disabled: false })
    } else {
      request.channel.contact(name, email, description)
        .then(() => {
          GlobalSnackbar.show({ message: localization.get('snackbar.contact_catalog_client_success'), type: GlobalSnackbarTypes.SUCCESS });
          this.setState({ disabled: false, name: null, email: null, description: null });
          this.props.afterSubmit()
        })
        .catch(() => GlobalSnackbar.showGenericError())
    }
  };


  render = () => {
    const { disabled, email, name, description} = this.state;
    
    return (
      <div style={{textAlign: "center"}}>
        <Card className={!this.props.modal ? "catalog-client-info" : "contact-modal"} style={{backgroundColor:  "var(--main-color, black)", border: "1px solid var(--main-font-color, black)"}}>
          <ValidatorForm onSubmit={this.onSubmit}>
            <CardBody>
              <span style={{  color: "var(--main-font-color, black)"}}>{localization.get('form.name')}</span>
              <br />
              <TextValidator
                style={{ width: "100%", color: "var(--main-font-color, black)"}}
                id="name"
                name="name"
                value={name}
                onChange={e => this.setState({name:  e.target.value})}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
                inputProps={{
                  ref: input => {
                    this.searchInput = input;
                  },
                  spellCheck: false,
                  style:{color: "var(--main-font-color, black)", borderBottom: "2px solid var(--main-font-color, black)"}
                }}
                InputProps={{disableUnderline: true}}
              />
              <br />
              <br />
              <span style={{  color: "var(--main-font-color, black)"}}>{localization.get('form.contact_message_email')}</span>
              <br />
              <TextValidator
                style={{ width: "100%", borderRadius: "5px",  color: "var(--main-font-color, black)", }}
                name="email"
                value={email}
                onChange={e => this.setState({email: e.target.value})}
                validators={['required']}
                backgroundColor={"white"}
                errorMessages={[localization.get('validator.is_required')]}
                inputProps={{
                  ref: input => {
                    this.searchInput = input;
                  },
                  spellCheck: false,
                  style:{color: "var(--main-font-color, black)", borderBottom: "2px solid var(--main-font-color, black)"}
                }}
                InputProps={{disableUnderline: true}}
              />
              <br />
              <br />
              <span style={{  color: "var(--main-font-color, black)"}}>{localization.get('form.query')}</span>

              <br />
              <TextField
                style={{ width: "100%",  color: "var(--main-font-color, black)"}}
                id="description"
                name="description"
                value={description}
                onChange={e => this.setState({description:  e.target.value})}
                multiline
                rows={!this.props.modal ? "14" : "10"}
                variant="outlined"
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
                inputProps={{
                  ref: input => {
                    this.searchInput = input;
                  },
                  spellCheck: false,
                  style:{
                    color: "var(--main-font-color, black)",
                    borderBottom: "2px solid var(--main-font-color, black)"
                  }
                }}
                InputProps={{disableUnderline: true}}
              />
            </CardBody>
            <CardActions style={{ display: "block", padding:"15px 12px" }}>
              <Button type="submit" style={{ float: "right", top: "-4px", backgroundColor: "var(--main-font-color, black)", color: "var(--main-color, white)"}} color={"default"} disabled={disabled}>{localization.get('send')}</Button>
            </CardActions>
          </ValidatorForm>
        </Card >
      </div>
    )
  }
}
