import "layouts/Home/Home.css";
import React from "react";
import {Chip} from "@material-ui/core";
import localization from "../../../config/localization";
import styled from "styled-components";

const HomeElementItemStateX4 = ({ recentlyCreated, recentlyUpdated}) => {

  if (!recentlyCreated && !recentlyUpdated) {
    return null
  }

  const defaultStyle = {
    borderRadius: "0px",
    backgroundColor: "transparent",
    border: "1px solid black",
    color: "black",
    fontSize: "12px",
    height: "26px"
  }

  const chip =
    <Chip
      style={{...defaultStyle}}
      label={ recentlyCreated ? localization.get('recently_created') : localization.get('recently_updated')}
    />


  return (
    <MobileDiv>
      {chip}
    </MobileDiv>
  )

}

export default HomeElementItemStateX4

const MobileDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 12px;
  bottom: 12px;
 @media (max-width: 470px){
    left: 155px;
 }
`;