import React, {Component} from "react";
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import {getMainTranslation} from "../../../api-client/core/authentication/utils";
import analyticsInstance from "../../../analytics/analyticsInstance";
import { TikTokEmbed, TwitterEmbed } from 'react-social-media-embed';

export default class EmbededContent extends Component {

  state = {
    disabled: false,
    tracked: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentIndex !== this.props.currentIndex) {
      this.stopVideos()
    }
  }

  //https://youtu.be/M1OGNLAH_XM
  //https://vimeo.com/474019351
  //https://open.spotify.com/artist/1Z7q2glJYLsPpPoPIgBjdE?si=jYSbkmK6TsSPLFV4VUAZIw
  //https://open.spotify.com/album/2EHM1mx9pn3uCY8dONDpub?si=meW5yqOHS6mlji8kRmFYAQ
  //https://open.spotify.com/track/6EHrppRT1Uj4cyShAujxFu?si=3YnAsa9GS8O9BwrVfeGTAg
  //spotify:track:6EHrppRT1Uj4cyShAujxFu
  //spotify:album:2EHM1mx9pn3uCY8dONDpub
  //spotify:artist:1Z7q2glJYLsPpPoPIgBjdE

  makeUrl = () => {
    let videoId = this.props.content.embed_url.split('/')
    switch (this.props.content.source) {
      case 'youtube':
        return `https://www.youtube.com/embed/${videoId[videoId.length - 1]}?modestbranding=1&rel=0&enablejsapi=1`
      case 'vimeo':
        return 'https://player.vimeo.com/video/' + videoId[videoId.length - 1]
      case 'spotify':
        return `https://open.spotify.com/embed/${videoId[videoId.length - 2]}/${videoId[videoId.length - 1].split('?')[0]}`
    }
  }

  getVideoId = () => {
    let videoUrl = this.props.content.embed_url.split('/');
    return this.props.content.source != 'spotify' ? videoUrl[videoUrl.length - 1] : videoUrl[videoUrl.length - 1].split('?')[0];
  }

  trackPlay = () => {
    if (!this.state.tracked) {
      // GAevent('Contenido', "Play", this.props.trackingTitle, this.props.trackingTag);
      analyticsInstance.playContent({
        name: getMainTranslation(this.props.material, "title"),
        type: "Embed",
        content: this.props.material && !!this.props.material.contentCreator && getMainTranslation(this.props.material.contentCreator, "name")
      })
      this.setState({tracked: true});
    }
  }

  stopVideos = () => {
    let iframe = document.getElementById(`embed${this.props.index}`) //element.querySelector( 'iframe');
    if (iframe && this.props.index !== this.props.currentIndex) {
      let iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
  };

  render = () => {
    let width = window.innerWidth*0.65;
    return (
      <div style={{textAlign: "center", width: "100%", display: "flex", width: "100%", justifyContent: "center",  alignItems: "center"}} className={this.props.blur ? "blurred-container" : ""}>

        {this.props.content.source == 'youtube' ?
        <YouTube id={`embed${this.props.index}`} videoId={this.getVideoId()} opts={{height: width*(9/16), width: width}} onPlay={() => this.trackPlay()}></YouTube>

        : this.props.content.source == 'vimeo' ?
        <Vimeo id={`embed${this.props.index}`} video={this.getVideoId()} height={width*(9/16)} width={width} onPlay={() => this.trackPlay()}></Vimeo>
        
        : this.props.content.source == 'tiktok' ?
        <TikTokEmbed id={`embed${this.props.index}`} url={this.props.content.embed_url} style={{maxWidth: 350}} />

        : this.props.content.source == 'twitter' ?
        <TwitterEmbed id={`embed${this.props.index}`} url={this.props.content.embed_url} width="100%" style={{maxWidth: 500}} />
      
        :
        <iframe style={{maxWidth: '100%'}} id={`embed${this.props.index}`} width={width} height={window.innerHeight*0.7} src={this.makeUrl()} frameBorder="0" allowFullScreen allow={this.props.content.source == 'spotify' && "encrypted-media"}/>
        }
      </div>
    )
  }
}