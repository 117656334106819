import {isNullOrUndefined} from "util";

export const makeFormData = (code, title, tagIds, audioId, audioAiffId, videoId, albumIds, active, showInRecents, bichannel, artistId, inactiveReason, owner, catalogId, batchAudioId, deleteAiff) => {
  const form = new FormData()

  if (code) form.append('code', code)
  if (title) form.append('title', title)
  if (tagIds) tagIds.forEach(tid => form.append('tags_ids[]', tid))
  if (albumIds) albumIds.forEach(x => form.append('albums_ids[]', x))
  if (artistId) form.append('artist_id', artistId)
  if (inactiveReason) form.append('inactive_reason', inactiveReason)
  if (owner) form.append('owner', owner)
  if (!isNullOrUndefined(active)) form.append('active', active ? 1 : 0)
  if (!isNullOrUndefined(showInRecents)) form.append('show_in_recents', showInRecents ? 1 : 0)
  if (!isNullOrUndefined(bichannel)) form.append('bichannel', bichannel ? 1 : 0)
  if (catalogId) form.append('catalog_id', catalogId)
  if(videoId) form.append('videoId', videoId)
  if(audioAiffId) form.append('audioAiffId', audioAiffId)
  if(audioId) form.append('audioId', audioId)
  if(batchAudioId) form.append('batchAudioId', batchAudioId)
  if (deleteAiff) form.append('delete_aiff', deleteAiff)
  return form
}

export const includeQuery = 'include=recentLicenses;versions_count;video;tags.group;catalog.musicDealer.tagSystem;albums.catalog;audio:original_name,url,duration;audio.waveform;albums.image;favorite;artist;lowLevelFeature;midLevelFeature;audioAiff;channelFavorite'
