import React from "react"
import MonthlyChart from './MonthlyChart'

const getReference = (lines, totals, formatter) =>
  <div style={{ display: 'inline-grid', gridTemplateColumns: 'auto auto', padding: '10px', width: "300px" }}>
    {lines.filter(({ hideInReference = false }) => !hideInReference)
        .map(({key, name}, i) => [
      <div key={`key_${i}`} style={{ fontWeight: 'bold', fontSize: 'small' }}>{name}:</div>,
      <div key={`value_${i}`} style={{ textAlign: 'right' }}>{formatter(totals[key])}</div>
    ])}
  </div>

const TotalizedMonthlyChart = ({title, values, totals, lines, formatter}) =>
  <MonthlyChart title={title} data={values} lines={lines} formatter={formatter} reference={getReference(lines, totals, formatter)} />

export default TotalizedMonthlyChart