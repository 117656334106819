import {post} from '../utils'

export default host => ({
    playlistId,
    type,
    entityIds
  }) =>
  post(`${host}/playlists/many/${playlistId}/${type}`, makeFormData({entityIds}))

const makeFormData = ({entityIds }) => {
  const form = new FormData()
  if (entityIds) entityIds.forEach(x => form.append('resource_ids[]', x))
  return form
}
