import React, {Component} from "react";
import {Checkbox, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Add, CheckCircle, Delete, Edit, PhotoLibrary, PlayCircleFilled} from "@material-ui/icons";
import localization from "../../../config/localization";
import {makeThumbnailUrlWithSize} from "../../../layouts/Home/HomeElements/utils";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: this.props.video || [],
      showDialog: false,
      selected: false,
    }
  }

  componentDidMount = () => {
    document.addEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.addEventListener('MSFullscreenChange', this.handleEscapeKeyPress);

  };

  componentWillUnmount = () => {
    document.removeEventListener('fullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('webkitfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('mozfullscreenchange', this.handleEscapeKeyPress);
    document.removeEventListener('MSFullscreenChange', this.handleEscapeKeyPress);

  };


  componentWillReceiveProps(nextProps) {
    this.setState({video: nextProps.video});
    if(this.state.selectedFiles !== nextProps.selectedFiles){
      this.setState({selectedFiles: nextProps.selectedFiles})
    }
    if(this.state.addingFiles !== nextProps.addingFiles){
      this.setState({addingFiles: nextProps.addingFiles})
    }
  }



  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
    this.fullScreen()
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  fullScreen = () => {
    this.videoRef.requestFullscreen()
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()

    }
  }


  toggleVideoState = (toPlayingState, action, event) => {
    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
  }


  render = () =>
    <div className="file-table-row-parent">
      <div className="file-table-row">
        <div className="file-table-favorite">
          {this.props.addingFiles &&
          <Checkbox
            checked={this.props.addedFiles.includes(this.state.video)}
            onChange={() => this.props.addToChecked(this.state.video)}
            color="default"
          />
          }
        </div>
        <div className="file-table-image-container" onClick={() => (this.state.playing) ? this.pause() : this.play()}>
          <div className="file-image">
            <div className={'min-play-button'}><PlayCircleFilled/></div>
            <video
              ref={ref => this.videoRef = ref} loop={false}
              preload={this.state.video.video.thumbnail ? "none" : "metadata"}
              poster={makeThumbnailUrlWithSize(this.state.video.video, 'reel-sm')}
              className="video"
              style={{overflow: "hidden", width : "100%", minHeight: "100%"}}
            >
              <source src={`${this.state.video.video.url}#t=1`}/>
            </video>
          </div>
        </div>
        <div className={`file-table-name-container`}>
          <div className="file-table-name" style={{color: "black"}}>
            {this.state.video.title}
          </div>
          <React.Fragment>
            <div className="file-table-name">
              {this.state.video.subtitle}
            </div>
          </React.Fragment>
        </div>
        <div className="file-table-tags" style={{display: window.innerWidth < 550 && "none"}}>
          {this.state.video.tags.map((tag, index) =>
            index < 5 &&
              <div
                className="track-table-tags-link-selected track-table-tags-link"
                onClick={() => this.props.onSelectTag(tag.id)}
              >
                {tag.name}
              </div>
            )
          }
        </div>
        {this.props.addingFiles ?
          <div className="file-table-actions">
            {this.props.selectedFiles.includes(this.state.video.id) ?
              <div className="file-table-action-button">
                <IconButton style={{color: "grey"}}>
                  <CheckCircle/>
                </IconButton>
              </div>
              :
              <div className="file-table-action-button" onClick={() => this.props.onSelectFile(this.state.video)}>
                <IconButton>
                  <Tooltip  title={localization.get('reel.add_file')} leaveDelay={1} placement='top'>
                    <Add/>
                  </Tooltip>
                </IconButton>
              </div>
            }
          </div>
          :
          <div className="file-table-actions">
            <div className="reel-table-button" style={{width: "33.3%"}}>
              <IconButton onClick={() => this.props.handleThumbnailEdit(this.state.video)} style={{color: "grey"}}>
                <Tooltip title={localization.get('reel.edit_thumbnail_tooltip')} leaveDelay={1} placement='top'>
                  <PhotoLibrary/>
                </Tooltip>
              </IconButton>
            </div>
            <div className="reel-table-button" style={{width: "33.3%"}}>
              <IconButton onClick={() => this.props.handleEdit(this.state.video)} style={{color: "grey"}}>
                <Tooltip title={localization.get('edit')} leaveDelay={1} placement='top'>
                  <Edit/>
                </Tooltip>
              </IconButton>
            </div>
            <div className="reel-table-button" style={{width: "33.3%"}}>
              <IconButton onClick={() => this.props.handleDelete(this.state.video)} style={{color: "grey"}}>
                <Tooltip title={localization.get('delete')} leaveDelay={1} placement='top'>
                  <Delete/>
                </Tooltip>
              </IconButton>
            </div>
          </div>
        }
      </div>
    </div>

}