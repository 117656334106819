import React, {Component} from "react";
import localization from "../../config/localization";
import {Link} from "react-router-dom";
import {request} from 'api-client';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AsyncSelect from "react-select/lib/Async";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

class ExportContentCreators extends Component {

  state = {
    selectedEntity: false,
    clients: [],
    client: null,
    eventRangeOpen: false,
    startRange: null,
    endRange: null,
  }

  generateDownloadLink = entities => {
    if (this.state.selectedEntity == "user") {
      return request.authentication.refreshAccessToken().then(() => request.user.exportUsers(entities))
    }

    return window;
  }

  componentDidMount = () => {
    document.title = localization.get('comercial.export_entities') + ' - Feater';
  }

  render = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <h4>Rango</h4>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                  <DatePicker
                    style={{marginBottom: 15}}
                    autoOk
                    keyboard
                    clearable
                    onlyCalendar
                    defaultValue={this.state.startRange}
                    format={"dd/MM/yyyy"}
                    label={localization.get('form.range_start')}
                    value={this.state.startRange}
                    onChange={(val) => this.setState({startRange: val})}
                  />
                  <DatePicker
                    style={{marginBottom: 15}}
                    autoOk
                    disabled={!this.state.startRange}
                    keyboard
                    clearable
                    minDate={this.state.startRange}
                    onlyCalendar
                    defaultValue={this.state.endRange}
                    format={"dd/MM/yyyy"}
                    label={localization.get('form.range_end')}
                    value={this.state.endRange}
                    onChange={(val) => this.setState({endRange: val})}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                })}
                placeholder={localization.get('client')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={(client) => this.setState({client})}
                value={this.state.client}
                defaultOptions
                isClearable={true}
                loadOptions={inputValue => {
                  let filters = [{column: "name", filter: inputValue}]

                  return request.client.getAll({filterBy: filters})
                    .then(clients => clients.map(client => ({
                      value: client.id,
                      label: client.name
                    })))
                    .then(clients => clients.sort((a, b) => a.label.localeCompare(b.label)))
                }}
              />
                <br/><br/>
              <Link
                to="#"
                onClick={e =>{
                  e.preventDefault();
                  window.open(request.contentCreator.exportByDate({
                    client: this.state.client && this.state.client.value,
                    startDate: this.state.startRange && moment(this.state.startRange).format('YYYY-MM-DD'),
                    endDate: this.state.endRange && moment(this.state.endRange).format('YYYY-MM-DD'),
                  }), "_blank")
                }}>
                <Button style={{float: "right", top: "-4px"}}  color={'info'} disabled={this.state.startRange && !this.state.endRange}>{localization.get('download')}</Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default ExportContentCreators
