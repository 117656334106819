import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LinkTable from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import localization from "../../../config/localization";

class Links extends Component {
  state = {
    links: [],
    perPage: 20,
    page: 0
  };

  componentDidMount = () => {
    document.title = localization.get("links") + " - Feater";
  };

  render = () => {
    return (
      <AllViewContainer onAdd={this.onAdd} ref="viewContainer">
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <LinkTable {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    );
  };
}

export default Links;
