import React, {Component} from "react";
import {makeUrlWithTableParams} from "../../../utils";
import localization from "../../../config/localization";
import matchSorter from "match-sorter";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import PlaylistSubcomponent from "./TableSubComponent";
import ServerSidePaginationTable from "../../../components/Letflow/Table/ServerSidePaginationTable";
import {ArrowDropDown, ArrowDropUp, Close, Done} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";

class Table extends Component {

  state = {
    total: '',
    showDialog: false,
    catalogCategories: [],
    selected: [],
    typeFilter: null
  }

  componentDidMount = () => {
    request.catalogCategory.getAll().then(catalogCategories => this.setState({ catalogCategories }));
  }

  getClientsToPreview = async (playlist) => {
    if (playlist.clients.length > 0) {
      return playlist.clients
    }
    return await request.client.getAll({filterBy: [{column: 'catalog_category_id', filter: playlist.category.id}]});
  }

  columns = () => {
    let columns = [
      {
        id: 'order',
        Cell: ({ original }) => {
          return (
            <div style={{ display: 'grid' }}>
              <Tooltip title={localization.get('move_up')}>
                <ArrowDropUp
                  style={{ cursor: 'pointer' }}
                  onClick={() => request.playlist.updateGlobal(original.id, {order: original.order - 1}).then(() => this.refs.table.forceUpdate())}
                />
              </Tooltip>
              <Tooltip title={localization.get('move_down')}>
                <ArrowDropDown
                  style={{ cursor: 'pointer' }}
                  onClick={() => request.playlist.updateGlobal(original.id, {order: original.order + 1}).then(() => this.refs.table.forceUpdate())}
                />
              </Tooltip>
            </div>
          )
        },
        sortable: false,
        filterable: false,
        width: 50,
      },
      {
        id: 'image',
        accessor: playlist => <img src={makeImageUrlWithSize(playlist, 'xs')} width={50} height={50} alt='' onError={onImageError}/>,
        sortable: false,
        filterable: false,
        width: 60,
      },
      {
        Header: localization.get('table.title'),
        id: "title",
        accessor: "title",
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
        Filter: ({ filter, onChange }) => (
          <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
        ),
        sortable: false,
      },
      {
        Header: localization.get('table.type'),
        id: 'ignore',  //This is so that the request ignores react table's own filter and uses state instead
        sortable: false,
        accessor: playlist => (playlist.tags.length !== 0 ? localization.get('tags') : playlist.musics.length !== 0 && localization.get('musics')),
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: "100%" }}
            onChange={event => {
              this.setState({typeFilter: event.target.value})
              onChange(event.target.value)
            }}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            <option value={'has_tags'}>{localization.get('tags')}</option>
            <option value={'has_musics'}>{localization.get('musics')}</option>
          </select>
        ),
      },
      {
        Header: localization.get('table.visibility'),
        id: "has_clients",
        accessor: playlist => (playlist.clients.length !== 0 ? localization.get('visibility.private') : localization.get('visibility.public')),
        sortable: true,
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: "100%" }}
            onChange={event => {
              onChange(event.target.value)
            }}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            <option value={'true'}>{localization.get('visibility.private')}</option>
            <option value={'false'}>{localization.get('visibility.public')}</option>
          </select>
        ),
      },
      {
        Header: localization.get("table.active"),
        id: "user_library",
        accessor: playlist => (playlist.user_library ? <Done /> : <Close />),
        Filter: ({ filter, onChange }) =>
          <select style={{ width: "100%" }} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
            <option value="" selected />
            <option value={true}>{localization.get('table.yes')}</option>
            <option value={false}>{localization.get('table.no')}</option>
          </select>,
        style: {
          textAlign: "left"
        },
        sortable: false,
        width: 80
      },
      {
        Header: localization.get('table.favorite_count'),
        id: 'favorite_count',
        accessor: 'favorite_count',
        width: 80,
        filterable: false,
        sortable: false
      },
      {
        id: "actions",
        Header: localization.get("table.actions"),
        sortable: false,
        filterable: false,
        width: 150,
        accessor: playlist =>
          new TableButtonsBuilder()
            .withEdit(makeUrlWithTableParams(`/panel/global_playlists/${playlist.id}/edit`))
            .withShare('playlist', playlist.id)
            .withDelete(
              playlist.title,
              () => request.playlist.deleteGlobal(playlist.id),
              state => {
                if (state === "passed") {
                  this.refs.table.removeById(playlist.id)
                }
              }
            )
            .build(),
        Footer: <span
          style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
      }
    ]


    return columns
  }

  render = () => {
    return (
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            requestTableData={paginationConfig => {
              if (this.state.typeFilter) {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: this.state.typeFilter, filter: true }]) };
              }
              return (
                request.playlist.getAllGlobal(paginationConfig).then((response) => {
                  this.setState({ total: response.meta.total })
                  return response
                })
              )
            }}
            ref="table"
            subComponent={row => <PlaylistSubcomponent {...this.props} playlist={row.original} />}
            columns={this.columns()}
          />
        </div>
    );
  }


}

export default Table;
