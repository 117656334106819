import {isNullOrUndefined} from "util";

export const makeJsonData = ({
    name,
    link,
    duration,
    type,
    fileId,
    client,
    mobileImageId
}) => {
  const form = {};
  if (name) form.name = name
  if (link) form.link = link
  if (duration) form.duration = duration
  if (type) form.type = type
  if (fileId) form.asset_id = fileId
  if (client) form.client_id = client.value
  if (mobileImageId) form.mobile_image_id = mobileImageId

  return JSON.stringify(form)
}


export const includeQuery = 'include=image;video;client;mobileImage'
