import download from 'downloadjs'
import {get} from "./utils";
import {getActiveClient, getStoredUser} from './authentication/utils';

const downloadTrack = (host, trackId, trackType, demo = false, aiff = false, isWav = false) => {

  let downloadFileName

  return requestTrack(host, trackId, trackType, demo, aiff)
    .then(track => trackDownload(track, trackType, demo))
    .then(track => extractDownloadFileNameFromTrack(track, extractedDownloadFileName => downloadFileName = extractedDownloadFileName))
    .then(track => getAudioUrlFromTrack(track, demo, aiff))
    .then(audioUrl => requestAudio(audioUrl))
    .then(audio => downloadAudio(audio, downloadFileName, aiff, isWav))
}

const trackDownload = (track, trackType, demo) => {
  if (trackType === 'music' || trackType === 'musicVersion') {
    if (demo) {
      // analyticsInstance.downloadDemo(track.title)
    } else {
      // analyticsInstance.downloadMusic(track.title)
    }
  }
  return track
}

const requestTrack = (host, trackId, trackType, demo, aiff) => {
  let url = `${host}/${trackType + 's'}/${trackId}?include=${(['music', 'contentCreation', 'musicVersion'].includes(trackType)) && demo ? 'demo:url' : aiff ? 'audioAiff' : 'audio:url'}`
  let clientId = getActiveClient();
  if(trackType !== 'voice' && clientId != null && demo) {
    url = `${host}/clients/${clientId}/${trackType + 's'}/${trackId}?include=${(['music', 'contentCreation', 'musicVersion'].includes(trackType)) && demo ? 'demo:url' : aiff ? 'audioAiff' : 'audio:url'}`
  }else if (trackType === 'voice' && clientId != null ) {
    url = `${host}/clients/${clientId}/${trackType + 's'}/${trackId}?include=${demo ? 'demo:url' : aiff ? 'audioAiff' : 'audio:url'}`
  }

  if(!getStoredUser()) {
    if (clientId != null) {
      url += `&client_id=${clientId}`
    } else {
      if (trackType !== 'voice') {
        url = `${host}/public/musics/${trackId}?include=demo:url`
      } else {
        url = `${host}/public/${trackType + 's'}/${trackId}?include=demo:url`
      }
    }
  }

  return get(url)
    .then(response => response.json())
    .then(json => json.data)
}

const extractDownloadFileNameFromTrack = (track, downloadFileNameExtracted) => {
  downloadFileNameExtracted(`${track.title}`)
  return track
}

const getAudioUrlFromTrack = (track, demo, aiff) => {
  return demo ? track.demo.url : aiff ? track.audioAiff.url : track.audio.url
}

const requestAudio = audioUrl =>
  fetch(audioUrl, { headers: { 'Accept': 'audio/mpeg' } })
    .then(response => response.arrayBuffer())

const downloadAudio = (audio, fileName, aiff, isWav) => download(audio, `${fileName}${isWav ? '.wav' : aiff ? '.aiff' : '.mp3'}`, 'audio/mpeg')

export default downloadTrack
