import {post} from '../utils'
import {makeJsonData} from './utils'

export default host => ({
   name,
    link,
    duration,
    type,
    fileId,
    client,
    mobileImageId
  }) => post(`${host}/banners`, makeJsonData({
    name,
    link,
    duration,
    type,
    fileId,
    client,
    mobileImageId
  }))
  .then(response => response.json()) 
  .catch(error => {
    console.error(error);
    throw error;
  });
