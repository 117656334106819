import React, {Component} from "react";
import {Save} from "@material-ui/icons";
import Input from '@material-ui/core/Input'
import TableButton from "../Table/Buttons/TableButton";
import localization from "../../../config/localization";

class InlineInput extends Component {

  state = {
    value: this.props.defaultValue,
    dirty: false
  }

  componentWillReceiveProps = (next) => {
    this.setState({ value: next.defaultValue, dirty: false })
  }

  onChange = event => {
    const value = event.target.value;
    const dirty = value !== this.props.defaultValue;

    this.setState({ value, dirty })
  }

  render = () => {
    const { defaultValue, onSave } = this.props;
    const { value, dirty } = this.state;

    return (
      <div>
        <Input
          ref='input'
          value={value}
          defaultValue={defaultValue}
          onChange={this.onChange} />
        <TableButton
          title={localization.get('button.save')}
          disabled={!dirty}
          onClick={() => onSave(value)}>
          <Save/>
        </TableButton>
      </div>
    );
  }
}

export default InlineInput;