import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Form/Create";
import Update from "./Form/Update";

const Ads = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/ads/:id/edit" component={Update} />
        <Route path="/panel/ads/create" component={Create} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default Ads
