import React from "react";
import localization from '../../config/localization'
import RichTextEditor from "../../components/Letflow/RichTextEditor";

const uploadUrl = (id = 0) => {
  return `${process.env.REACT_APP_API_HOST}/messaging/conversations/${id}/upload_file`;
};
const uploadMethod = "POST";
const uploadParam = "file";

export default ({ content, onChange, conversationId }) => (
<RichTextEditor
  editorState={content}
  onChange={onChange}
/>
);
