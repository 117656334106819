import React from 'react'
import { channelPathGenerator, getStoredChannel, getStoredUser, setPageTitle, slugify } from '../../../api-client/core/authentication/utils';
import GlobalRedirectLoginConfirmationDialog from '../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog';
import localization from '../../../config/localization';
import browserHistory from '../../../utils/browserHistory';
import "./index.css";
import GiftImage from '../../../assets/img/gift.png';
import { Card, CardActions, Dialog, Button as MuiButton } from '@material-ui/core';
import Button from '../../../components/CustomButtons/Button';
import { getValueFromQueryParam } from '../../../utils';
import { request } from '../../../api-client';
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import NoImage from "../../../assets/img/no_image.png"
import GlobalSnackbar, { GlobalSnackbarTypes } from '../../../components/Letflow/Snackbar/GlobalSnackbar';
import {onImageError} from '../..//Home/HomeElements/utils';

class ClaimGift extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: null,
      token: null,
      loaded: false,
      error: false,
    }
  }

  componentDidMount = () => {
    setPageTitle(localization.get('title.claim_gift'))
    document.getElementsByTagName('html')[0].scrollTop = 0
    let token = getValueFromQueryParam("token");
    if (token) {
      request.gifts.getFromToken(token)
        .then((res) => {
          this.setState({loaded: true, gift: res, product: res && res.product, error: false});
        }).catch((err) => {
          this.setState({loaded: true, product: null, gift: null, error: true});
        });
    }
  }

  showLoginDialog = () => GlobalRedirectLoginConfirmationDialog.show({
    title: localization.get("login_redirect"),
    content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
    request: () => new Promise(resolve => {
      browserHistory.push(channelPathGenerator(`login?redirect_to=${window.location.pathname+window.location.search}`))
      resolve()
    }), 
    confirmationLabel: localization.get("login_continue")
  });

  claimGift = () => {
    if (!getStoredUser()) {
      this.showLoginDialog();
      return;
    }
    this.setState({showClaimDialog: true});
  }

  render = () => {
    let {loaded, product, gift, error} = this.state;
    if (!loaded) {
      return (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
          <img style={{width: 100, height: 100, marginTop: "calc(50vh - 50px)"}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
      )
    }
    return (
      <div style={{width: "100%", height: "calc(100vh - )", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ClaimDialog
          show={this.state.showClaimDialog}
          handleClose={() => this.setState({showClaimDialog: false})}
          gift={this.state.gift}
        />
        <Card className={"claim-gift-card"}>
          {error ? 
          <>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10, textAlign: 'center'}}>
              <h2 style={{marginBottom: 0}}>{localization.get("gift.claim.error.title")}</h2>
              <h3>{localization.get("gift.claim.error.subtitle")}</h3>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: "-20px", textAlign: 'justify'}}>
              <h4 style={{padding: "0 40px"}}><i>{localization.get("gift.claim.error.message")}</i></h4>
            </div>
            <CardActions style={{marginBottom: 20, marginTop: 50}}>
              <Button style={{ float: "right", backgroundColor: "var(--navbar-text-color, black)", color: "var(--secondary-color, white)" }} color={"default"} onClick={() => browserHistory.push(channelPathGenerator(''))}>{localization.get('go_to.home')}</Button>
            </CardActions>
          </>
          :
          <>
            <div style={{display: 'flex', alignItems: 'center', padding: 10}}>
              <img style={{height: 60, width: 60, padding: 10}} src={GiftImage}/>
              <h3>{(gift && gift.from  && gift.from.length > 0) ? localization.get("gift.claim.title", gift.from) : localization.get("gift.claim.default_title")}</h3>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: "-20px", textAlign: 'justify'}}>
              <h4 style={{padding: "0 40px"}}><i>{(gift && gift.dedication && gift.dedication.length > 0) ? gift.dedication : localization.get("gift.no_gift_message")}</i></h4>
            </div>
            {product && 
              <div style={{display: 'flex', alignItems: 'center', margin: "20px 0", border: "solid", width: "90%", borderRadius: 10, }}>
                <img style={{width: '33%', objectFit: 'cover', height: '100px', borderTopLeftRadius: 7, borderBottomLeftRadius: 7}} src={(product && product.content && product.content.image) ? request.file.image.makeFittedUrl(product.content.image.id, 350, 350, 'file', 'png') : NoImage} onError={onImageError}/>            
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 100, paddingLeft: "5%"}}>
                  <h4 style={{marginTop: 10}}>{product.content.name || product.content.title}</h4>
                  <p style={{maxHeight: 40, overflow: "hidden"}}>{product.description}</p>
                </div>
              </div>
            }
            {gift &&
              <CardActions style={{marginBottom: 20}}>
                {gift.userReceiver ?
                  <span>{localization.get("gift.claim.accepted_by", gift.userReceiver.name)}</span>
                :
                  <Button style={{ float: "right", backgroundColor: "var(--navbar-text-color, black)", color: "var(--secondary-color, white)" }} color={"default"} onClick={this.claimGift}>{localization.get('gift.claim.accept_gift')}</Button>
                }
              </CardActions>
            }
          </>
          }
        </Card>
      </div>
    )
  }
}

class ClaimDialog extends React.Component {

  constructor (props) {
    super(props)
  }
  
  handleClose = () => {
    this.props.handleClose && this.props.handleClose();
  }

  claimGift = () => {
    let gift = this.props.gift;
    console.log(gift);
    if (gift && gift.hashcode && gift.product) {
      request.gifts.claim(gift.hashcode)
        .then(() => {
          GlobalSnackbar.show({message: localization.get("gift.claim.success"), type: GlobalSnackbarTypes.SUCCESS});
          request.products.getForChannel(gift.product.id).then((product) => goToProduct(product));
        })
        .catch(() => GlobalSnackbar.show({message: localization.get("gift.claim.claim_error"), type: GlobalSnackbarTypes.ERROR}))
    }
  }

  render = () => {
    let {gift} = this.props;
    return (
      <Dialog open={this.props.show} onEscapeKeyDown={this.handleClose} onBackdropClick={this.handleClose}>
        <div className={"share-gift-dialog"}>
          <h3>{localization.get('gift.claim.accept_gift')}</h3>
          {!!(gift && gift.userSender && getStoredUser() && gift.userSender.id == getStoredUser().id) &&
            <p style={{marginBottom: "10px!important"}}>{localization.get('gift.claim.same_user_warning')}</p>
          }
          {!!(gift && gift.product && gift.product.days) &&
            <p style={{margin: "20px 0"}}>{localization.get('gift.claim.days_clarification', gift.product.days)}</p>
          }
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 30}}>
            <MuiButton variant="text" style={{fontSize: 12}} onClick={this.handleClose}>{localization.get("form.back")}</MuiButton>
            <Button style={{ float: "right", width: 150, backgroundColor: "var(--navbar-text-color, black)", color: "var(--secondary-color, white)" }} color={"default"} onClick={this.claimGift}>{localization.get('gift.claim.accept_gift')}</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const goToProduct = (product) => {
  let productUrl;
  switch(product.content_type) {
    case "App\\ContentCreator":
      let contentCreator = product.content;
      if (contentCreator) {
        productUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
      }
      break;
    case "App\\ContentCreation":
      let contentCreation = product.content;
      if (contentCreation) {
        productUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
      }
      break;
    case "App\\ContentPack": 
      if (product.content && product.content.products) {
        let targetProduct = product.content.products[0];
        if (targetProduct.content_type == "App\\ContentCreator") {
          let contentCreator = targetProduct.content;
          if (contentCreator) {
            productUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
          }
        }
        if (targetProduct.content_type == "App\\ContentCreation") {
          let contentCreation = targetProduct.content;
          if (contentCreation) {
            productUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
          }
        }
      }
  }
  if (productUrl) {
    browserHistory.push(productUrl);
  } 
}

export default ClaimGift
