import React, {Component} from "react";
import {request} from "api-client";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import FormContent from "../FormContent";
import localization from "../../../config/localization";
import {ROLES} from "../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {getUpdateFormImageUrlFromItem, makeUrlWithTableParams} from "../../../utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    type: '',
    image: { src: null, file: null },
    profile: { src: null, file: null },
    disabled: false,
    selectedUsers: [],
    createUser: 1,
    userName: '',
    userEmail: '',
    country: '',
    socialLinks: [],
    disableForm: false,
    unsplashCoverImage: '',
    unsplashProfileImage: '',
    coverImageRadio: 'filepond',
    profileImageRadio: 'filepond',
    translations: AvailableLanguages.map(language => ({
      language: language,
      description: "",
    })),
  }

  requestArtistCreation = () => {
    const {
      name,
      email,
      phone,
      imageId,
      translations,
      profileId,
      type,
      selectedUsers,
      userName,
      userEmail,
      createUser,
      socialLinks,
      country,
      unsplashCoverImage,
      unsplashProfileImage
    } = this.state

    const artistData = {
      name,
      email,
      phone,
      translations,
      imageId,
      profileId,
      type,
      socialLinks,
      countryId: country && country.value,
      selectedUsers: selectedUsers.map(x => x.value),
      unsplashCoverImage,
      unsplashProfileImage
    }

    const userData = {
      name: userName,
      email: userEmail,
      roleId: ROLES.artistId
    }

    if(createUser === 2) {
      return request.user.create(userData)
        .then((user) => {
          request.artist.create({...artistData, selectedUsers: [user.data.id]})
            .then(() => {
              GlobalConfirmationDialog.show({
                title: localization.get("user.created"),
                content: localization.get("user.email_sent", user.data.email),
                request: () => Promise.resolve(),
                confirmationLabel: localization.get("accept"),
                onClose: this.props.history.push(makeUrlWithTableParams('/panel/artists')),
              })
            })
        })
    }
    return request.artist.create(artistData)
      .then(() => this.props.history.push(makeUrlWithTableParams('/panel/artists')))
  }

  formNewUrl = (path) => {
    return process.env.REACT_APP_CDN_HOST.concat('/' + path)
  }

  setPreview = (type) => {
    if (type === 'video') {
      this.setState({video: { src: this.state.videoPreview, filename: null}})
    } else if (type === 'image') {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
    }
  }

  onChange = e => this.setState({ [e.target.id]: e.target.value })
  onAsyncUserChange = selectedUsers => this.setState({selectedUsers})
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  onProfileChange = profile => profile ? this.setState({profileId: profile.id, profile: {src: null, filename: null }, imagePreview: this.formNewUrl(profile.path)}) : this.setState({profileId: null, profile: { src: null, filename: null }})

  onSetCoverImageFromUnsplash = (url, name) => this.setState({unsplashCoverImage: {url, name}})
  onSetProfileImageFromUnsplash = (url, name) => this.setState({unsplashProfileImage: {url, name}})

  onImageRadiosChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
    if (e.target.name === 'coverImageRadio') {
      if (e.target.value === 'unsplash') {
        this.setState({imageId: '', image: {src: null, filename: null }})
      } else {
       this.setState({unsplashCoverImage: ''})
      }
    }
    if (e.target.name === 'profileImageRadio') {
      if (e.target.value === 'unsplash'){
        this.setState({profileId: '', image: {src: null, filename: null }})
      } else {
        this.setState({unsplashProfileImage: ''})
      }
    }
  }

  render = () => {
    return (
      <div>
        <FormViewContainer
          title={localization.get('view.artist.create')}
          submitRequest={() => this.requestArtistCreation()}
          onBack={() => this.props.history.goBack()}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
          hasTranslatableInputs
          disabled={this.state.disableForm}
        >
          <FormContent
            name={this.state.name}
            email={this.state.email}
            phone={this.state.phone}
            type={this.state.type}
            image={this.state.image}
            profile={this.state.profile}
            onChange={this.onChange}
            onImageChange={this.onImageChange}
            onProfileChange={this.onProfileChange}
            selectedUsers={this.state.selectedUsers}
            onAsyncUserChange={this.onAsyncUserChange}
            createUser={this.state.createUser}
            userName={this.state.userName}
            userEmail={this.state.userEmail}
            onCreateUserChange={createUser => this.setState({ createUser })}
            socialLinks={this.state.socialLinks}
            onSocialLinksChange={socialLinks => this.setState({socialLinks})}
            country={this.state.country}
            onAsyncCountriesChange={country => this.setState({country})}
            updateDisableForm={value => this.setState({disableForm: value})}
            onSetPreview={this.setPreview}
            disableForm={this.state.disableForm}
            coverImageRadio={this.state.coverImageRadio}
            profileImageRadio={this.state.profileImageRadio}
            onSetCoverImageFromUnsplash={this.onSetCoverImageFromUnsplash}
            onSetProfileImageFromUnsplash={this.onSetProfileImageFromUnsplash}
            onImageRadiosChange={this.onImageRadiosChange}
            translations={this.state.translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
          />
        </FormViewContainer>
      </div>
    )
  }
}
