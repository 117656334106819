import "./Home.css";
import React, {Component} from "react";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import HomeElementGridContainer from "./HomeElements/HomeElementGridContainer";
import {request} from '../../api-client'
import localization from '../../config/localization';
import GridItem from "./HomeElements/GridItem";
import EventManager, {events} from "../../utils/EventManager";
import HomeElementCarousel from "./HomeElements/HomeElementCarousel";
import _ from 'lodash'
import ErrorBoundary from "../../components/Letflow/ErrorBoundary";
import DefaultBanner from "./HomeElements/DefaultBanner";
import {
  clientCanSeeContentCreators,
  getActiveClient,
  isPublicClient,
  setPageTitle
} from "../../api-client/core/authentication/utils";
import {Helmet} from "react-helmet/es/Helmet";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      banner: {},
      loading: true,
      failedLoading: false,
      homeElements: [],
      client: props.client ? props.client : null,
      favorites: [],
      speakersFavorites: [],
      artistsFavorites: [],
      playlistFavorites: [],
      contentCreatorsFavorites: [],
      contentCreationsFavorites: [],
      pageReady: false,
    }
  }

  clientId = () => getActiveClient()

  componentDidMount = () => {

    new Promise(resolve => {
      if (isPublicClient()) {
        this.requestHomeElements()
      } else {
        this.requestFavorites()
        this.requestHomeElements().then(() => this.goToLastScrollPosition())
      }

      resolve()
    })
    //document.getElementsByTagName('html')[0].scrollTop = 0
    setPageTitle(localization.get('title.home'))
    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)

    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      this.setState({client: nextProps.client})
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    sessionStorage.setItem("home_scroll_position", document.documentElement.scrollTop)
  }

  goToLastScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('home_scroll_position')
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 1000)
    }
  }

  addToFavorites = ({id, type = 'album'}) => {
    let {favorites, speakersFavorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites} = this.state
    switch (type) {
      case 'album':
        favorites.push(id)
        request.favorite.add(id)
        break
      case 'speaker':
        speakersFavorites.push(id)
        request.speakerFavorite.add(id)
        break
      case 'artist':
        artistsFavorites.push(id)
        request.artistFavorite.add(id)
        break
      case 'playlist':
        playlistFavorites.push(id)
        request.playlist.addFavorite(id);
        break
      case 'content_creator':
        contentCreatorsFavorites.push(id)
        request.contentCreatorFavorite.add(id)
        break
      case 'video_content_creation':
        contentCreationsFavorites.push(id)
        request.contentCreationFavorite.add(id)
        break
    }
    this.setState({ favorites, speakersFavorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites })
  }

  removeFromFavorites = ({id, type = 'album'}) => {
    let {favorites, speakersFavorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites} = this.state
    switch (type) {
      case 'album':
        if (favorites.indexOf(id) > -1) {
          favorites.splice(favorites.indexOf(id), 1)
          request.favorite.remove(id)
        }
        break
      case 'speaker':
        if (speakersFavorites.indexOf(id) > -1) {
          speakersFavorites.splice(speakersFavorites.indexOf(id), 1)
          request.speakerFavorite.remove(id)
        }
        break
      case 'artist':
        if (artistsFavorites.indexOf(id) > -1) {
          artistsFavorites.splice(artistsFavorites.indexOf(id), 1)
        }
        request.artistFavorite.remove(id)
        break
      case 'playlist':
        if (playlistFavorites.indexOf(id) > -1) {
          playlistFavorites.splice(playlistFavorites.indexOf(id), 1)
        }
        request.playlist.removeFavorite(id)
        break
      case 'content_creator':
        if (contentCreatorsFavorites.indexOf(id) > -1) {
          contentCreatorsFavorites.splice(contentCreatorsFavorites.indexOf(id), 1)
        }
        request.contentCreatorFavorite.remove(id)
        break
      case 'video_content_creation':
        if (contentCreationsFavorites.indexOf(id) > -1) {
          contentCreationsFavorites.splice(contentCreationsFavorites.indexOf(id), 1)
        }
        request.contentCreationFavorite.remove(id)
        break
    }
    this.setState({ favorites, speakersFavorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites})
  }

  requestHomeElements = () =>
    request.client.home.elements.getMetadata(this.clientId())
      .then(elements => {
        elements.forEach(element => {
          if(element.type === "banner") {
            if( this.state.client.show_banner !== 'none') {
              this.setState({banner: element.items.data[0], loading: false})
            }
          }else {
            request.client.home.elements.getById(element.id).then(homeElement => {
              const homeElements = this.state.homeElements
              homeElements.push(homeElement)
              homeElements.sort((a, b) => a.order - b.order)

              if( this.state.client.show_banner !== 'none') {
                !this.state.loading && this.setState({homeElements: []}, () => this.setState({homeElements}))
              }else {
                this.setState({homeElements: [] }, () => this.setState({homeElements, loading: false}))
              }
            })
          }
        })
      }).catch(() => this.setState({ failedLoading: true }))



  requestPlatformVideos = (changeDay)  =>
    request.featuredVideo.getForClient(getActiveClient()).then(response => {
      const videos = response
      let video
      let homeElements = this.state.homeElements;
      if (videos.length > 0) {
        video = videos.find((video, index) => index === (changeDay % videos.length))
      }

      homeElements.unshift({
        data_source: "banner",
        type: "default_banner",
        items: {
          data: video
        }
      })

      this.setState({homeElements, loading: false })
    })

  requestFavorites = () => new Promise(resolve => {
    request.favorite.getAll().then((favorites) => this.setState({ favorites }))
    request.artistFavorite.getAll().then((artistsFavorites) => this.setState({ artistsFavorites }))
    request.playlist.getFavorites().then(playlistFavorites => this.setState({ playlistFavorites }))
    clientCanSeeContentCreators(this.state.client) && request.contentCreatorFavorite.getAll().then(contentCreatorsFavorites => this.setState({ contentCreatorsFavorites }))
    clientCanSeeContentCreators(this.state.client) && request.contentCreationFavorite.getAll().then(contentCreationsFavorites => this.setState({ contentCreationsFavorites }))
    resolve()
  })

  addAboutToHomeElements = client => {
      let homeElements = this.state.homeElements;

      if (client.about && client.show_about_in_home && window.innerWidth > 500 && !homeElements.find(homeElement => homeElement.type === 'about')) {
        homeElements.splice(1, 0, {type: 'about', content: client.about})
      }
      if(homeElements.length > 0) {
        return homeElements.map(homeElement => {
          if (homeElement.type === 'about') {
            return <div style={{display: !this.state.pageReady && "none"}}>
              <div style={{padding: "50px 66px 0", overflowWrap: "break-word"}}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: homeElement.content
                  }}
                />
              </div>
            </div>
          }
          return homeElementToComponent(
            this.props,
            this.state.favorites,
            this.state.artistsFavorites,
            this.state.playlistFavorites,
            this.state.contentCreatorsFavorites,
            this.state.contentCreationsFavorites,
            () => !this.state.pageReady && this.setState({pageReady: true})
          )(homeElement)
        })
      }
    };


  render = () => {
    const client = this.props.client;

    if (this.state.failedLoading) {
      return <div>Algo salio mal, refresque la pagina</div>
    } else {
      return (
        <>
          <Helmet>
            <meta name="ROBOTS" content="INDEX, FOLLOW"/>
            {client.seo_keywords && <meta name="keywords" content={client.seo_keywords}/>}
            {client.seo_title && <meta name="title" content={client.seo_title}/>}
            {client.seo_description && <meta name="description" content={client.seo_description}/>}
          </Helmet>
          <div style={{ paddingBottom: "40px"}} id="innerinner">
            {!this.state.loading ?
              <>
                {(this.state.banner.video || this.state.banner.image) && <DefaultBanner client={client} banner={this.state.banner} /> }
                {this.addAboutToHomeElements(client)}
              </>:
              <SkeletonTheme color={"#ddd"}>
                {this.state.client.show_banner !== 'none' &&
                  <Skeleton height={550} duration={1.5}/>
                }
                <br/><br/>
                <br/><br/>
                <Skeleton height={50} duration={1.5}/>
                <br/>
                <br/>
                <span style={{marginLeft: "1%",marginRight: ".5%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%",marginRight:".5%" }}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%",marginRight:".5%" }}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
                <span style={{marginLeft: ".5%",marginRight: "1%"}}>
                  <Skeleton height={250} duration={1.5} width={"23.5%"}/>
                </span>
              </SkeletonTheme>
            }
          </div>
        </>
      )
    }
  }
}

const makeHomeElementProp = (homeElement, favorites,  artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites) => {
  switch (homeElement.data_source) {
    case 'user_library':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            recentlyCreated: item.recently_created,
            recentlyUpdated: item.recently_updated,
            shortDescription: item.subtitle,
            description: item.description,
            favorite: (favorites && item.kind === 'album') ? favorites.includes(item.id) : (playlistFavorites && item.kind === "playlist") ? playlistFavorites.includes(item.id) : false,
            tracks: _.concat(item.musics || [], item.voices || [])
          }))
        }
      }
    case 'artist':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.name,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: artistsFavorites ? artistsFavorites.includes(item.id) : false,
          })).sort(compareValues('favorite', 'desc'))
        }
      }
    case 'content_creator':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.name,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: (contentCreatorsFavorites &&  homeElement.data_source === "content_creator")
              ? contentCreatorsFavorites.includes(item.id)
                : false,
          })).sort(compareValues('favorite', 'desc'))
        }
      }

    case 'video_content_creation':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.title,
            shortDescription: item.description,
            kind: homeElement.data_source,
            favorite: (contentCreationsFavorites &&  homeElement.data_source === "video_content_creation")
              ? contentCreationsFavorites.includes(item.id)
              : false,
          })).sort(compareValues('favorite', 'desc'))
        }
      }
    case 'production':
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            title: item.project_name,
            shortDescription: item.project_details,
            kind: homeElement.data_source
          }))
        }
      }
    case 'recommended':
      const data = homeElement.items.data.map(item => ({
        ...item,
        recentlyCreated: item.recently_created,
        recentlyUpdated: item.recently_updated,
        shortDescription: item.short_description,
        description: item.description,
        longDescription: item.long_description,
        tracks: item.musics,
        favorite: playlistFavorites.includes(item.id),
      }))

      const nonPlaylists = data.filter(x => x.kind !== 'playlist')
      const playlists = data.filter(x => x.kind === 'playlist').sort(compareValues('favorite', 'desc'))

      return { ...homeElement, items: { data: nonPlaylists.concat(playlists) } }

    default:
      return {
        ...homeElement,
        items: {
          data: homeElement.items.data.map(item => ({
            ...item,
            recentlyCreated: item.recently_created,
            recentlyUpdated: item.recently_updated,
            shortDescription: item.short_description,
            description: item.description,
            longDescription: item.long_description,
            kind: 'album',
            tracks: item.musics,
            favorite: favorites.includes(item.id),
          })).sort(compareValues('favorite', 'desc'))
        }
      }
  }
}

const compareValues = (key, order = 'asc') => {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === 'string') ?
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ?
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

const makeTrackTypeProp = homeElement => {
  switch (homeElement.data_source) {
    case 'user_library':
      return (itemType) => itemType
    default:
      return () => 'music'
  }
}

const makeDataTypeProp = homeElement => {
  switch (homeElement.data_source) {
    case 'voice_over':
      return 'speaker';
    default:
      return 'album';
  }
}

const makeRequestElementProp = (homeElement) => {
  switch (homeElement.data_source) {

    case 'recommended':
      return async (id, itemKind) => {

        if (itemKind === 'recents') {
          const recents = await request.music.recents(getActiveClient())
          return {
            title: localization.get('recents'),
            tracks: recents,
            kind: itemKind
          }
        }

        if (itemKind === 'playlist') {
          const playlist = await request.playlist.getGlobal(id)

          const selectedTags = playlist.tags.filter(tag => tag.wanted === 1).map(tag => tag.id)
          const unselectedTags = playlist.tags.filter(tag => tag.wanted === 0).map(tag => tag.id)

          const tracks = await request.music.getAllByPlaylistForClient(getActiveClient(), id, selectedTags, unselectedTags, playlist.query || '', "recentLicenses;audio:id,duration,url")
          return {
            ...playlist,
            tracks,
            kind: itemKind,
          }
        }
      }

    case 'user_library':

      return async (id, itemKind, clientId, savedQuery) => {

        const fetchElement = async () => {

          if (itemKind === 'favorites' || itemKind === 'recents') {
            return await request.music[itemKind](clientId)
          }

          if (itemKind === 'album') {
            const album = await request.album.getForClient(id, 'include=image');
            const musics = await request.music.getAllByAlbumForClient(clientId, id, 'include=recentLicenses.user;versions_count;audio:id,duration,url;tags;favorite;channelFavorite;audio.waveform;albums.image;artist')
            return { ...album, musics }
          }

          if (itemKind === 'savedQuery') {
            const savedQuery = await request.savedQuery.get(id);
            const musics =  await request.music.getAllFromQuery(clientId, savedQuery.tags.map(tag => tag.id), savedQuery.query)
            return {...savedQuery, musics}
          }

          if (itemKind === 'playlist') {
            const playlist = await request.playlist.get(id)
            const musics = await request.music.getAllByPlaylistForClient(clientId, id)
            return {...playlist, musics}
          }

          return await request[itemKind].get(id)
        }

        const element = await fetchElement()

        return {
          id: Array.isArray(element) ? null : element.id,
          title: !Array.isArray(element) ? element.title : itemKind === 'favorites' ? localization.get('my_favorites') : itemKind === 'savedQuery' ? savedQuery.title : localization.get('recents'),
          image: Array.isArray(element) ? null : element.image || null,
          kind: itemKind,
          tracks: _.concat(
            element.musics ? element.musics.map(music => ({...music, type: 'music'})) : [],
            element.voices ? element.voices.map(voice => ({...voice, type: 'voice'})) : [],
            Array.isArray(element) ? element : []
          ),
          longDescription: Array.isArray(element) ? null : element.description,
          shortDescription: Array.isArray(element) ? null : element.subtitle,
          featuredImage: element.featuredImage || null,
          created_by_admin: element.created_by_admin || null
        }
      }
    default:
      return albumId => request.album.getForClient(albumId, 'include=image;artist')
        .then(album =>
          request.music.getAllByAlbumForClient(getActiveClient(), albumId, 'include=recentLicenses.user;versions_count;audio:id,duration,url;tags;favorite;channelFavorite;audio.waveform;albums.image;artist', album.type === 'library' ? 'created_at' : null, album.type === 'library' ? 'desc' : null)
            .then(musics => ({
              ...album,
              tracks: musics.map(music => ({ ...music, type: 'music' })),
              longDescription: album.long_description,
              shortDescription: album.short_description,
            }))
        )
  }
}

const homeElementToComponent = (props, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites, setPageReady) => homeElement => {

  let component
  switch (homeElement.type) {
    case 'banner':
      component = <DefaultBanner client={props.client} banner={homeElement.items.data} />
      break
    case 'default_banner':
      component = <DefaultBanner client={props.client} banner={homeElement.items.data} />
      break
    case 'grid':
      switch (homeElement.columns) {
        case 6:
          component = <HomeElementGridContainer
            {...props}
            favorites={favorites}
            maxPerRow={6}
            shape={homeElement.shape}
            fixedTitle={homeElement.fixed_title}
            withBackground={homeElement.with_background}
            homeElement={makeHomeElementProp(homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
            trackType={makeTrackTypeProp(homeElement)}
            dataType={makeDataTypeProp(homeElement)}
            requestElement={makeRequestElementProp(homeElement)}
            sizes={{
              xs: 6,
              sm: 4,
              md: 3,
              lg: 2,
              xl: 2,
            }}
            itemComponent={GridItem}
            showMore={!!homeElement.rows_to_show}
            rowsToShow={homeElement.rows_to_show}
            home={true}
            hasMargin={homeElement.has_margin}
            spacing={homeElement.spacing}
            rounded={homeElement.rounded}
            backgroundColor={homeElement.background_color}
            backgroundStyle={homeElement.background_style}
          />
          break
        case 4:
          component = <HomeElementGridContainer
            {...props}
            favorites={favorites}
            maxPerRow={4}
            shape={homeElement.shape}
            fixedTitle={homeElement.fixed_title}
            withBackground={homeElement.with_background}
            homeElement={makeHomeElementProp(homeElement, favorites,  artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
            trackType={makeTrackTypeProp(homeElement)}
            dataType={makeDataTypeProp(homeElement)}
            requestElement={makeRequestElementProp(homeElement)}
            sizes={{
              xs: 12,
              sm: 4,
              md: 3,
              lg: 3,
              xl: 3,
            }}
            itemComponent={GridItem}
            showMore={!!homeElement.rows_to_show}
            rowsToShow={homeElement.rows_to_show}
            home={true}
            hasMargin={homeElement.has_margin}
            spacing={homeElement.spacing}
            rounded={homeElement.rounded}
            backgroundColor={homeElement.background_color}
            backgroundStyle={homeElement.background_style}
          />
          break
        default:
          component = null
      }
      break
    case 'featured':
    case 'carousel':
      component = <HomeElementCarousel
        {...props}
        favorites={favorites}
        playlistFavorites={playlistFavorites}
        contentCreatorsFavorites={contentCreatorsFavorites}
        contentCreationsFavorites={contentCreationsFavorites}
        onlyImage={homeElement.only_image}
        numbered={homeElement.numbered}
        shape={homeElement.shape}
        inverted={homeElement.inverted}
        editorial={homeElement.editorial}
        editorialInverted={homeElement.editorial_inverted}
        columns={homeElement.columns}
        homeElement={makeHomeElementProp(homeElement, favorites, artistsFavorites, playlistFavorites, contentCreatorsFavorites, contentCreationsFavorites)}
        trackType={makeTrackTypeProp(homeElement)}
        dataType={makeDataTypeProp(homeElement)}
        requestElement={makeRequestElementProp(homeElement)}
        square={homeElement.square}
        hasMargin={homeElement.has_margin}
        spacing={homeElement.spacing}
        rounded={homeElement.rounded}
        halfHeight={homeElement.half_height}
        mobileHalfHeight={homeElement.mobile_half_height}
        backgroundColor={homeElement.background_color}
        backgroundStyle={homeElement.background_style}
      />
      break
    default:
      component = null
  }

  // If the element does not have elements to show, dont display it unless its a banner.
  // Banners should still be shown as they will be rendered with another provided media.
  if (homeElement.type !== 'banner' && homeElement.items.data.length === 0) {
    component = null
  }

  setPageReady()

  return <ErrorBoundary>{component}</ErrorBoundary>
}

export default withStyles(pagesStyle)(Home);
