import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    name,
    code
  }) =>
  post(`${host}/blogLocations`, makeFormData({
    name,
    code
  }))
  .then(response => response.json()) 