import React, {Component} from "react";
import localization from "../../../config/localization";
import matchSorter from "match-sorter";
import {TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import ServerSidePaginationTable from "../../../components/Letflow/Table/ServerSidePaginationTable";
import moment from "moment";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {OpenInNew, Visibility} from "@material-ui/icons";

class Table extends Component {

  state = {
    total: '',
  }

  columns = () => [
    {
      id: "client[name]",
      Header: localization.get("table.client"),
      accessor: "client.name",
      sortable: false,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      Header: localization.get('table.title'),
      accessor: "title",
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["title"] }),
      filterAll: true,
      sortable: true,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
      id: "created_at",
      Header: localization.get("created_at"),
      accessor: row => moment(row.created_at).format("DD-MM-YYYY"),
      resizeable: false,
      sortable: true,
      filterable: false,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: reel => {
        const buttonBuilder = new TableButtonsBuilder()
        buttonBuilder.withOther(<TableButton title={localization.get('button.see_reel')} onClick={() => window.open(`${window.location.origin}/public/reel/${reel.share_token}` , "_blank")}><OpenInNew/></TableButton>)
        return buttonBuilder.build()
      },

      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }

  ]

  render = () => {
    return (
      <React.Fragment>
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            requestTableData={paginationConfig => {
              return (
                request.clientReels.getForAllClients(paginationConfig).then((response) => {
                  this.setState({ total: response.meta.total })
                  return response
                })
              )
            }}
            ref="table"
            columns={this.columns()}
          />
        </div>
      </React.Fragment>
    );
  }


}

export default Table;
