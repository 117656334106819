import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import {makeUrlWithTableParams} from "../../../utils";

let index

class ContentPackTable extends Component {
  state = {
    channel: '',
    containerType: 'all',
    copied: localization.get('copy_link'),
    loaded: true
  };

  componentDidMount() {
    let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));

    if (selectedChannelFromLocalStorage) {
      this.setState({channel: selectedChannelFromLocalStorage});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.channel !== this.state.channel) {
      index.forceUpdate();
    }
  }

  columns = () => {

    const columns =
      [
        {
          Header: localization.get("table.name"),
          id: 'name',
          accessor: 'name',
        },
        {
          Header: localization.get("products"),
          id: "productsCount",
          accessor: 'productsCount',
          sortable: false,
          filterable: false
        },
        {
          Header: localization.get('table.actions'),
          id: 'actions',
          sortable: false,
          filterable: false,
          accessor: product => {
            const builder = new TableButtonsBuilder()

            builder.withEdit(makeUrlWithTableParams(`/panel/products/contentPacks/${product.id}/edit`))

            return builder.build();
          }
        }
      ]


    return columns;
  }
  render = () => {
    const { loaded } = this.state;

    return !loaded ? <div></div> :
      (
        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref={r => index = r}
            columns={this.columns()}
            requestTableData={paginationConfig => {
              if (this.props.channel) {
                paginationConfig = {
                  ...paginationConfig,
                  filterBy: paginationConfig.filterBy.concat([{column: 'channel_id', filter: this.props.channel.value}])
                }
              }
              return request.contentPacks.getAll(paginationConfig)
            }}
          />
        </div>
      )
  }
}


export default ContentPackTable
