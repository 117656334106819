import PropTypes from "prop-types";
import React from 'react';
import Button from "../../../components/CustomButtons/Button.jsx";
import localization from "../../../config/localization/index.js";
import AddressDialog from "./AddressDialog/index.jsx";

class AddressInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      address: this.props.address
    }
  }

  componentWillReceiveProps = (nextProps, nextContext) => {
    if (nextProps.address && this.state.address !== nextProps.address) {
      this.setState({address: nextProps.address})
    }
  };

  updateAddress = (address) => {
    this.setState({address: address, showDialog: false}, () => this.onAddressChange())
  }

  onAddressChange = () => {
    return this.props.onAddressChange && this.props.onAddressChange(this.state.address);
  }
  
  open = () => {
    this.setState({showDialog: true});
  }

  renderButton = () => (
    <Button
      style={{
        padding: 8,
        textTransform: "capitalize",
        marginLeft: 5
      }}
      color={this.props.buttonColor ? this.props.buttonColor : "dark"}
      onClick={() => this.setState({showDialog: true})}
      size="sm"
    >
      {this.state.address ? localization.get("form.address.update") : localization.get("form.address.new")}
    </Button>
  )

  render = () => {
    const {address} = this.state;

    return(
      <React.Fragment>
        <AddressDialog showDialog={this.state.showDialog} handleCloseDialog={() => this.setState({showDialog: false})} onSubmit={(address) => this.updateAddress(address)} address={address} canDisable={this.props.canDisable} isDashboard={this.props.isDashboard} withEmail={this.props.withEmail} onEmailChange={this.props.onEmailChange}/>
        <div style={{width: "100%", display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: "space-between"}}>
          <div>
            {address ? 
              `${address.name} (${address.address})`
              :
              (this.props.noAddressText || localization.get("form.address.no_address"))
            }
          </div>
          {this.renderButton()}
        </div>
      </React.Fragment>
    )
  }
}

AddressInput.propTypes = {
  address: PropTypes.object,
  onAddressChange: PropTypes.func
};

export default AddressInput