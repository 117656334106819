import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {makeImageUrl, makeWaveformUrl} from '../../../utils'
import localization from '../../../config/localization'

class AlbumSubcomponent extends Component {

  state = {
    musics: [],
  }

  componentDidMount = () => {
    request.album.get(this.props.album.id, 'include=image;allMusics.tags:name;allMusics.audio:url,duration.waveform:id').then(album => this.setState({ musics: album.allMusics }))
  }

  render = () => {

    const {album} = this.props

    const gotoAlbum = () => this.props.history.push(`/albums/${this.props.album.id}/edit`)

    const soundplayerSongs = this.state.musics.map(music => ({
      fetchAudioUrl: () => request.music.audioUrl(music.id),
      image: makeImageUrl(this.props.album, 50),
      waveform: makeWaveformUrl(music),
      title: music.title,
      subtitle: this.props.album.title,
      onAnyClick: gotoAlbum,
    }))


    return <div style={{float: "left", position: "relative", width: "100%"}}>
      {album.catalog && (
        <div style={{ float: "left", position: "relative", width: "100%", padding: "20px 25px" }}>
          <div style={{ float: "left", position: "relative", fontWeight: "bold"}}>{localization.get('table.catalog')}</div>
          <div style={{ float: "left", position: "relative", marginLeft: "30px" }}>{`${album.catalog.name} (${localization.get(album.catalog.visibility)})`}</div>
        </div>
      )}
      {album.created_at &&
        <div style={{ width: "100%", float: "left", position: "relative", padding: "0px 25px 25px"}}>
          <div style={{ float: "left", position: "relative", fontWeight: "bold", marginRight: "30px"}}>{localization.get('table.created_at')}</div>
          {localization.moment(album.created_at).fromNow()}
        </div>
      }
      <h4 style={{marginLeft: "25px"}}>{localization.get('musics')}</h4>
      {this.state.musics.length > 0 && <div style={{float: "left", position: "relative", width: "100%", padding: "25px", paddingTop: "0"}}>
        <ClientSidePaginationTable
          data={this.state.musics.map((music, index) => ({...music, index}))}
          columns={[
            {
              id: 'title',
              accessor: music => music.title
            },
            {
              id: 'actions',
              accessor: music => new TableButtonsBuilder()
                .withNewPlay(soundplayerSongs, music.index, '10px')
                .build()
            },
          ]}
          filterable={false}
          sortable={false}
          showPagination={false}
          zebraStyle={false}
          perPage={999}
        />
      </div>
    }
    </div>
  }
}

export default AlbumSubcomponent
