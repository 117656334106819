import core from './core'
const validateEnv = (name) => {
  if (!process.env[name]) throw new Error(`no ${name} environment var declared`)
}

validateEnv('REACT_APP_API_HOST');
validateEnv('REACT_APP_CDN_HOST');

let url;

if (process.env.REACT_APP_ENV === 'local') {
  url = process.env.REACT_APP_API_HOST;
} else {
  url = window.location.protocol + '//' + window.location.hostname + '/api';
}

export let request = core(url)