import {Button, TextField} from '@material-ui/core';
import {Search as SearchIcon} from '@material-ui/icons';
import React, {Component} from "react";

class SearchInput extends Component {

  state = {
    search: '',
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleKeyPress = e => {
    if(e.key === 'Enter'){
      this.props.handleSearch(this.state.search)
    }
  }

  render = () => {
    const { search } = this.state
    const { handleSearch } = this.props
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField id='search' onKeyPress={this.handleKeyPress} placeholder="Buscar" helperText={this.props.helperText} value={search} onChange={this.handleChange} style={{marginRight:'10px'}}/>
          <Button onClick={() => handleSearch(this.state.search)} disabled={search.length === 0} disableRipple ><SearchIcon /></Button>
        </div>
      </div>
    )
  }
}

export default SearchInput