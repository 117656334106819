import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";
import Create from "./Create";
import Update from "./Update"

const ContentCreatorTypesRouting = (props) => {
    return (
        <Router {...props}>
            <Switch>
                <Route path="/panel/talents/content_creator_types/create" component={Create}/>
                <Route path="/panel/talents/content_creator_types/:id/edit" component={Update}/>
                <Route path="/panel/audiovisual/content_creator_types/create" component={Create}/>
                <Route path="/panel/audiovisual/content_creator_types/:id/edit" component={Update}/>
              <Route path="/panel/editorial/content_creator_types/create" component={Create}/>
              <Route path="/panel/editorial/content_creator_types/:id/edit" component={Update}/>
                <Route
                  render={props => (
                    <All
                      isTalent={window.location.pathname.startsWith("/panel/talents")}
                      isAudiovisual={window.location.pathname.startsWith("/panel/audiovisual")}
                      isEditorial={window.location.pathname.startsWith("/panel/editorial")}
                      {...props}
                    />
                  )}
                />
            </Switch>
        </Router>
    )
}

export default ContentCreatorTypesRouting
