import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
// import './login.css'
import queryString from 'querystring'
import Footer from "../../../layouts/Home/HomeElements/Footer";
import ChannelPublicNavBar from "../../../layouts/Channel/ChannelPublicNavBar";
import {
  channelPathGenerator,
  getActiveChannel,
  getStoredUser,
  getStoredChannel
} from "../../../api-client/core/authentication/utils";
import browserHistory from "../../../utils/browserHistory";
import {validateEmail} from "../../../utils";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import analyticsInstance from "../../../analytics/analyticsInstance";

class LoginChannel extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimation: "cardHidden",
      channel: {},
      requesting: true,
      loading: true,
      inputType: "password"
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );

    if (!!getStoredUser()) {
      browserHistory.push(channelPathGenerator(''))
    }


    let loginLocation = "channel"
    if (window.location.search.includes('/panel')) loginLocation = "panel"

    if(getStoredChannel() && process.env.REACT_APP_MIBA_LOGIN === "allowed" &&
      (loginLocation==="channel" && getStoredChannel().login_type !== "default") ||
        (loginLocation==="panel" && getStoredChannel().dashboard_login_type !== "default"))
    {
      let redirectUrl = queryString.parse(this.props.location.search)['?redirect_to'] || queryString.parse(this.props.location.search)['redirect_to']
      if(redirectUrl) sessionStorage.setItem("returnUrl", redirectUrl)
      window.location.href = request.authentication.loginMiBA(getStoredChannel().id, loginLocation === "channel" ? getStoredChannel().login_type : getStoredChannel().dashboard_login_type, loginLocation)
    }else {
      request.channel.getPublic(getActiveChannel() ? getActiveChannel() : window.location.pathname.split('/')[2])
        .then(channel => {
          document.title = localization.get('title.login') + ' - ' + channel.name
          this.setState({channel, requesting: false, loading: false})
        })
    }

    if(queryString.parse(this.props.location.search)['?activation_complete']) {
      GlobalSnackbar.show({
        message: localization.get("activate.success"),
        type: GlobalSnackbarTypes.SUCCESS
      });
    }

  }

  login = () => {
    if(this.state.username && this.state.password) {
      if (validateEmail(this.state.username)) {
        this.setState({requesting: true})
        request.authentication.login({username: this.state.username, password: this.state.password})
          .then(() => {
            // GAevent('Usuario', "Login", "")
            analyticsInstance.login({type: "Feater", from: "failed"})
            let redirectTo = queryString.parse(this.props.location.search)['?redirect_to'] || queryString.parse(this.props.location.search)['redirect_to']
            if (redirectTo) {
              window.location.href = redirectTo
            } else {
              window.location.href = getActiveChannel() ? channelPathGenerator('') : channelPathGenerator('', this.state.channel)
            }
          })
          .catch(e => {
            analyticsInstance.login({type: "Feater", from: e.status === 429 ? "blocked" : "failed"})
            this.setState({requesting: false})
            GlobalSnackbar.show({
              message: e.status === 429 ? localization.get('login.user_blocked', e.error) : (e.status === 500 ? localization.get('login.user_unactivated') : localization.get('login.login_failed')),
              type: GlobalSnackbarTypes.ERROR,
            })
          })
      }else {
        GlobalSnackbar.show({
          message: localization.get('login.email_invalid'),
          type: GlobalSnackbarTypes.ERROR,
        })
      }
    }
  }

  getRegisterUrl = () => {
    let redirectTo = queryString.parse(this.props.location.search)['?redirect_to'] || queryString.parse(this.props.location.search)['redirect_to']
    if (getActiveChannel()) { 
      if (redirectTo) {
        return (this.props.publicChannel ? channelPathGenerator(`register?redirect_to=${redirectTo}`) : `/register?redirect_to=${redirectTo}`);
      } else {
        return (this.props.publicChannel ? channelPathGenerator(`register`) : `/register`);
      }
    } else {
      if (redirectTo) {
        return `/channel/${this.state.channel.id}/register?redirect_to=${redirectTo}`;
      } else {
        return `/channel/${this.state.channel.id}/register`;
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { channel, requesting, loading } = this.state;
    if (loading) return ('')

    return (
      <React.Fragment>
        <ChannelPublicNavBar channel={channel} />
        <div className={classes.content} style={{minHeight: "calc(100vh - 147px)"}}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimation]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>Log in</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ username: e.target.value })
                        }}
                      />
                      <CustomInput
                        labelText={localization.get('password')}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ password: e.target.value }),
                          type: this.state.inputType,
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.login()
                            }
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="inputType"
                            checked={this.state.inputType === "text"}
                            onChange={() => {
                              this.setState({ inputType: this.state.inputType === "text" ? "password" : "text"})
                            }}
                          />
                        }
                        label={localization.get('show_password')}
                      />
                      {!requesting &&
                      <>
                        <Link to={getActiveChannel() ? (this.props.publicChannel ? channelPathGenerator(`password/forgot`) : `/password/forgot`) : `/channel/${this.state.channel.id}/password/forgot`}>
                          <p style={{
                            paddingTop: 15,
                            textAlign: "center",
                            color: "black",
                            fontSize: "0.875rem"
                          }}>{localization.get('forgot_password')}</p>
                        </Link>
                        {channel && channel.visibility !== 'closed' &&
                        <Link to={this.getRegisterUrl()}>
                          <p style={{
                            paddingTop: 15,
                            textAlign: "center",
                            color: "black",
                            fontSize: "0.875rem"
                          }}>{localization.get('first_time')}</p>
                        </Link>
                        }
                      </>
                      }
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.login} color="default"
                              style={{ backgroundColor: "black" }} simple size="lg"
                              block disabled={this.state.requesting}>{localization.get('enter')}
                      </Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer channel={this.state.channel} loginChannel={true} activeChannel={!!getActiveChannel()}/>
      </React.Fragment>
    )
      ;
  }
}

LoginChannel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginChannel);
