import React, {Component} from 'react';
import AudioImage from "../../../assets/img/Letflow/audio-image.png";
import Doc from "../../../assets/img/Letflow/FileExtentions/doc.png";
import Pdf from "../../../assets/img/Letflow/FileExtentions/pdf.png";
import Ppt from "../../../assets/img/Letflow/FileExtentions/ppt.png";
import Zip from "../../../assets/img/Letflow/FileExtentions/zip.png";
import Xls from "../../../assets/img/Letflow/FileExtentions/xls.png";
import {makeImageUrlWithSize, makeThumbnailUrlWithSize, emptyImage} from "../../../layouts/Home/HomeElements/utils";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || [],
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.item !== this.props.item) {
      this.setState({item: nextProps.item})
    }

    if (nextProps.publicView !== this.props.publicView) {
      this.setState({publicView: nextProps.publicView})
    }
  }


  getFileExtentionLogo = () => {
    switch (this.state.item.type) {
      case "audio":
        return AudioImage;
      case "video":
        return makeThumbnailUrlWithSize(this.state.item, 'md');
      case "image":
        return makeImageUrlWithSize(this.state.item, 'md')
      case "other":
      default:
        const pdf = ["pdf"]
        const doc = ["doc", "docx", "txt"]
        const xls = ["xls", "xlsx", "xlsm", "xltx", "csv"]
        const zip = ["rar", "zip"]
        const ppt = ["ppt", "pptx"]

        if (pdf.includes(this.state.item.binary.mime_type)) {
          return Pdf
        } else if (doc.includes(this.state.item.binary.mime_type)) {
          return Doc
        } else if (xls.includes(this.state.item.binary.mime_type)) {
          return Xls
        } else if (zip.includes(this.state.item.binary.mime_type)) {
          return Zip
        } else if (ppt.includes(this.state.item.binary.mime_type)) {
          return Ppt
        }
        //default
        return Zip
    }
  }

  render() {
    return (
        <div
          style={{
            position: "relative",
            float: "left",
            width: "100%",
            height: 125,
            cursor: "pointer",
          }}
          onClick={() => this.props.onClick(this.state.item)}
        >
          <div
            style={{
              border: this.props.selected ? "5px solid #58b9cc" : "0px",
              overflow: "hidden",
              backgroundImage: `url(${this.getFileExtentionLogo()}), url(${emptyImage})`,
              position: "relative",
              backgroundPosition: "center",
              float: "left",
              width: "100%",
              height: 115
            }}
          />
          <p style={{padding: "0 5px"}}>{this.state.item.title}</p>
        </div>
    );
  }
}
