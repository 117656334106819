import React, {Component} from 'react'


class ComercialSupport extends Component {

  componentDidMount = () => {
    window.open('https://web.freshchat.com', '_blank');
    this.props.history.goBack()
  }

  render = () => <div/>
}

export default ComercialSupport
