import {put} from "../utils";

export default host => ({
  id,
  confirmedCatalogsIds,
  notConfirmedCatalogsIds
}) =>
  put(
    `${host}/clients/${id}`,
    makeJsonData({
      confirmedCatalogsIds,
      notConfirmedCatalogsIds
    })
  ).then(response => response.json());

export const makeJsonData = ({
 confirmedCatalogsIds,
 notConfirmedCatalogsIds,
}) => {
  let object = {};
  confirmedCatalogsIds && confirmedCatalogsIds.length > 0 ? object.confirmed_catalogs_ids = confirmedCatalogsIds : object.confirmed_catalogs_ids = [];
  notConfirmedCatalogsIds && notConfirmedCatalogsIds.length > 0 ? object.not_confirmed_catalogs_ids = notConfirmedCatalogsIds : object.not_confirmed_catalogs_ids = [];
  return JSON.stringify(object)
};
