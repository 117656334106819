import {post} from "../utils";


const form = (username, channelId, token) => {
    const f = new FormData()

    f.append('username', username)
    if(channelId) f.append('channel_id', channelId)
    if(token) f.append('g-recaptcha-response', token)
    return f
}

export default host => ({
    username, channelId
}, token = null) => post(`${host}/password/forgot`, form(username, channelId, token))