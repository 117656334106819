import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Add, Clear, Menu, PhotoLibrary, PlayCircleFilled} from "@material-ui/icons";
import {FormControl, TextField, Tooltip} from "@material-ui/core";
import localization from "../../../../config/localization";
import InputLabel from "@material-ui/core/InputLabel";
import SelectMaterial from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {request} from "../../../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import ColorPicker from "../../../../components/Letflow/ColorPicker";
import {makeThumbnailUrlWithSize} from "../../HomeElements/utils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class ReelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.reel.id || null,
      reelFiles: props.selectedFiles || [],
      showConfiguration: true,
      reelTitle: props.reel.title || "",
      reelColor: props.reel.color || "rgba(255, 255, 255, .8)",
      reelSecondColor: props.reel.secondColor || "rgba(0, 0, 0, .8)",
      reelThirdColor: props.reel.thirdColor || "rgba(255, 255, 255, 1)",
      reelFourthColor: props.reel.fourthColor || "rgba(0, 0, 0, .8)",
      reelViewType: props.reel.viewType || "grid_with_banner",
      requestingVideos: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.reel && nextProps.reel != "") {
      this.setState({
        id: nextProps.reel.id,
        reelTitle: nextProps.reel.title,
        reelColor: nextProps.reel.color,
        reelSecondColor: nextProps.reel.secondColor,
        reelThirdColor: nextProps.reel.thirdColor,
        reelFourthColor: nextProps.reel.fourthColor,
        reelViewType: nextProps.reel.viewType,
      })
    }

    if (nextProps.selectedFiles) {
      this.setState({reelFiles: nextProps.selectedFiles})
    }
  }

  handleSubmit = () => {
    let reel = {
      id: this.state.id,
      title: this.state.reelTitle,
      color: this.state.reelColor,
      secondColor: this.state.reelSecondColor,
      thirdColor: this.state.reelThirdColor,
      fourthColor: this.state.reelFourthColor,
      viewType: this.state.reelViewType,
    };

    let method = "create"
    let message = localization.get('reel.created');
    if (reel.id) {
      method = "update"
      message = localization.get('reel.updated');
    }

    if(this.state.reelFiles.length == 0 || !this.state.reelTitle) {
      GlobalSnackbar.show({type: GlobalSnackbarTypes.ERROR, message: localization.get('client_file.upload_error')})
    }else {
      return request.clientReels[method](this.state.reelFiles, reel)
        .then(() => this.handleClose(), GlobalSnackbar.show({type: GlobalSnackbarTypes.SUCCESS, message: message}))
    }
  };

  play = () => {
    this.toggleVideoState(true, 'play', 'onPlay')
    this.fullScreen()
  }

  pause = () => {
    this.toggleVideoState(false, 'pause', 'onPause')
  }

  fullScreen = () => {
    this.videoRef.requestFullscreen()
  }

  handleEscapeKeyPress = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.pause()

    }
  }

  onClickVideo = () => {
    if(this.state.playing) {
      this.pause()
    }else {
      this.play()
    }
  }


  toggleVideoState = (toPlayingState, action, event) => {
    if (this.state.playing === toPlayingState) {
      return
    }

    this.setState({ playing: toPlayingState })
    this.videoRef[action]()
  }

  handleClose = () =>
    this.setState({
      id: null,
      reelTitle: "",
      reelColor: "rgba(255, 255, 255, .3)",
      reelSecondColor: "rgba(0, 0, 0, .3)",
      reelThirdColor: "rgba(255, 255, 255, 1)",
      reelFourthColor: "rgba(0, 0, 0, .3)",
      reelViewType: "grid_with_banner",
    }, () => this.props.changeViewMode());

  showReelFiles = (file) =>
    <div className="file">
      <div className="grab-file">
        <Menu/>
      </div>
      <div className="reel-file-image" onClick={this.onClickVideo}>
        <div className={'min-play-button'}><PlayCircleFilled/></div>
        <video
          ref={ref => this.videoRef = ref} loop={false}
          preload={file.video.thumbnail ? "none" : "metadata"}
          poster={makeThumbnailUrlWithSize(file.video, 'reel-sm')}
          className="video"
          style={{overflow: "hidden", width : "100%", minHeight: "100%"}}
        >
          <source src={`${file.video.url}#t=1`}/>
        </video>
      </div>
      <div className="file-description">
        <p className="title-file">{file.title}</p>
        <p className="subtitle-file">{file.subtitle}</p>
      </div>
      <div className="file-table-actions" style={{top: 10}} onClick={() => this.props.removeFileFromReel(file)}>
        <IconButton>
          <Tooltip title={localization.get('delete')} leaveDelay={1} placement='top'>
           <Clear />
          </Tooltip>
        </IconButton>
      </div>
      <div className="file-table-actions" style={{top: 10}} onClick={() => this.props.handleThumbnailEdit({...file, reelId: this.state.id})}>
        <IconButton style={{color: "grey"}}>
          <Tooltip title={localization.get('reel.edit_thumbnail_tooltip')} leaveDelay={1} placement='top'>
            <PhotoLibrary/>
          </Tooltip>
        </IconButton>
      </div>
    </div>

    showReel= () =>
      <div className="reel">
        {this.state.reelViewType === "grid_with_banner" &&
        <div style={{
          color: "#757575",
          margin: "0 0 10px 0 ",
          textAlign: "center",
          width: "100%"
        }}>{localization.get('helper_text.grid_banner')}*</div>
        }
        {this.state.reelFiles.length > 0 ?
          <>
            <DragDropContext
              onDragEnd={result => {
                if (result.destination) {
                  const destination = result.destination.index;
                  const source = result.source.index;

                  if (destination !== source) {
                    const videos = this.state.reelFiles;
                    const direction = destination < source ? -1 : 1;
                    const involved = direction === -1 ? videos.slice(destination, source) : videos.slice(source + 1, destination + 1);

                    this.setState({reelFiles: videos.sort((a,b) => a.order - b.order)})
                    videos[source].order = videos[destination].order;
                    involved.forEach(x => (x.order -= direction));
                  }
                }
              }}
            >
              <Droppable
                droppableId="reel-videos"
                direction="vertical"
                isDropDisabled={this.state.requestingVideos}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 10,
                      overflow: "auto",
                    }}
                    {...provided.droppableProps}
                  >
                    {this.state.reelFiles.sort((a,b) => a.order - b.order).map((file, index) =>
                      <Draggable key={file.id.toString()} draggableId={file.id.toString()} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {this.showReelFiles(file)}
                          </div>
                        )}
                      </Draggable>
                    )}
                    <div className="reels-button add-file-reel"
                      style={{
                      width: 105,
                      position: "relative",
                      float: "left",
                      marginTop: 20,
                      marginLeft: "calc( 50% - 75px )",
                      textAlign: "center"}}
                      onClick={this.props.handleAddFile}>
                      {localization.get('add.file')}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
          :
          <div className="empty-reel" onClick={this.props.handleAddFile}>
            <div className="add-file-reel"><Add style={{width: 100, height: 100, marginLeft: "calc( 50% -  50px)"}}/></div>
            <p style={{textAlign: "Center", padding: 20, fontSize: 16}}>{localization.get('reel.add_text')}</p>
          </div>
        }
      </div>

  showReelForm = () =>
    <div style={{ marginTop: 5, width: "100%", padding: "0 15px" , float: "left", position: "relative"}}>
      <TextField
        style={{ marginBottom: 20, position: "relative", float: "left", width: "49%"}}
        value={this.state.reelTitle}
        label={localization.get("reel.title")}
        onChange={e => this.setState({reelTitle: e.target.value})}
      />
      <FormControl style={{width: "49%", float:"left", marginLeft: 5}} >
        <InputLabel>{localization.get("reel.view_type")}</InputLabel>
        <SelectMaterial
          value={this.state.reelViewType}
          onChange={(e) => this.setState({reelViewType: e.target.value})}
          style={{marginBottom: "20px"}}
        >
          <MenuItem value='grid_with_banner' >{localization.get('reel.grid_with_banner')}</MenuItem>
          <MenuItem value='grid' >{localization.get('reel.grid')}</MenuItem>
        </SelectMaterial>
      </FormControl>
      <FormControl style={{width: "23%", float:"left"}}>
      <label> {localization.get('reel.background_color')} </label>
        <ColorPicker
          handleColorChange={color => this.setState({reelColor: color})}
          startColor={this.state.reelColor}
        />
      </FormControl>
      <FormControl style={{width: "24%", float:"left", marginLeft: 5}}>
        <label> {localization.get('reel.second_color')} </label>
        <ColorPicker
          handleColorChange={color => this.setState({reelSecondColor: color})}
          startColor={this.state.reelSecondColor}
        />
      </FormControl>
      <FormControl style={{width: "24%", float:"left", marginLeft: 5}}>
        <label> {localization.get('reel.third_color')} </label>
        <ColorPicker
          handleColorChange={color => this.setState({reelThirdColor: color})}
          startColor={this.state.reelThirdColor}
        />
      </FormControl>
      <FormControl style={{width: "24%", float:"left", marginLeft: 5}}>
        <label> {localization.get('reel.fourth_color')} </label>
        <ColorPicker
          handleColorChange={color => this.setState({reelFourthColor: color})}
          startColor={this.state.reelFourthColor}
        />
      </FormControl>
    </div>

  render = () => {
    return(
      <div className="flap-container">
        <div className="form-body">
          {this.showReelForm()}
          {this.showReel()}
          <div className="reel-footer">
            <div className="reels-button minor-priority" onClick={this.handleClose}>{localization.get("cancel").toUpperCase()}</div>
            <div className="reels-button" onClick={this.handleSubmit}>{localization.get("button.save").toUpperCase()}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default ReelForm
