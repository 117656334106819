import React, {Component} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";
import localization from "../../../../config/localization";
import Table from "./table";
import TablePlayButton from "../../../../components/Letflow/Table/Buttons/TablePlayButton";
import {request} from "../../../../api-client";
import {makeWaveformUrl} from "../../../../utils";

class OrderUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tracks: props.tracks || [],
      selectedMusics: props.tracks || [],
      creators: props.creators || [],
      submitting: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({tracks: nextProps.tracks, creators: nextProps.creators})
  }

  handleClose = () => {
    if (!this.state.submiting) {
      this.props.onClose && this.props.onClose()
      this.setState({
        tracks: [],
        creators: [],
        submitting: false
      })
    }
  }

  onSubmit = () =>
    request.playlist.updateOrder(
      this.props.playlistId,
      {
        musicIds: this.state.tracks,
        contentIds: this.state.creators,
      }
    )
      .then(this.props.requestData)
      .then(this.handleClose)

  render = () => {
    return(
      <Dialog open={this.props.show} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
        <DialogContent style={{minHeight: 337, width: 600}}>
          <h3 style={{fontSize: '1.3125rem', fontWeight: 500, color: '#000000de'}}>Orden</h3>
          {this.state.tracks && this.state.tracks.length > 0 &&
            <>
               <h4 style={{fontWeight: 500, color: '#000000de'}}>{localization.get('music')}</h4>
              <Table
                extraButtonFactories={[music => <TablePlayButton tracks={{
                  fetchAudioUrl: () => request.music.getForClient(music.id).then(music => music.audio.url),
                  title: music.title,
                  waveform: makeWaveformUrl(music)
                }}/>]}
                selectedItemsChanged={tracks => this.setState({tracks})}
                selectedTableTitle={<h4>{localization.get('form.selected_musics')}</h4>}
                selected={this.state.tracks}
              />
            </>
          }
          {this.state.creators && this.state.creators.length > 0 &&
            <>
              <h4 style={{fontWeight: 500, color: '#000000de'}}>{localization.get('content')}</h4>
              <Table
                selectedItemsChanged={creators => this.setState({ creators })}
                selectedTableTitle={<h4>{localization.get('form.selected_creators')}</h4>}
                selected={this.state.creators}
                contents={true}
              />
            </>
          }
        </DialogContent>
        <DialogActions style={{margin: "0 4% 4%"}}>
          <Button onClick={this.onSubmit} color='default' disabled={this.state.submitting} style={{ backgroundColor: "black" }}>
            {localization.get("save")}
          </Button>
        </DialogActions>
      </Dialog>
  )}
}

export default OrderUpdate;