import React, {Component} from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import ServerSidePaginationTable from "../../../components/Letflow/Table/ServerSidePaginationTable";
import TableButtonsBuilder from "../../../components/Letflow/Table/Buttons/TableButtonsBuilder";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import { makeUrlWithTableParams} from "../../../utils";

class Table extends Component {
    state = {
    total: '',
    ads: [],
    type: "all"
  }


  columns = () => [
    {
      Header: localization.get('table.name'),
      accessor: "name",
      filterAll: true,
      Filter: ({ filter, onChange }) => (
        <input onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""} placeholder={localization.get('table.search')} />
      )
    },
    {
    id: "client[name]",
    Header: localization.get("table.client"),
    accessor: "client.name",
    sortable: false,
    Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
        fetchItems={filter => request.client
            .getAll({
            filterBy: [{
                column: 'name',
                filter
            }]
            })
            .then(clients => clients.map(client => client.name))
        }
        onChange={onChange}
        filter={filter ? filter.value : ''}
        />
    },
    {
        Header: localization.get("table.type"),
        accessor: "type",
        filterable: true,
        sortable: false,
        style: {
          textAlign: "left"
        },
        minWidth: 70,
        maxWidth: 100,
      },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      accessor: ads => {

        const {
          id,
          name,
        } = ads
    
        const editUrl = makeUrlWithTableParams(`/panel/ads/${id}/edit`)
    
        const buttonBuilder = new TableButtonsBuilder()

        buttonBuilder.withEdit(editUrl)
        buttonBuilder.withDelete(
            name,
            () => request.ads.delete(id),
            state => {
              if (state === "passed") {
                this.refs.table.removeById(id)
              }
            }, true
          )
    
        return buttonBuilder.build()
      },
      minWidth: 100,
      filterable: false,
      sortable: false,
    }
  ]

  handleDelete = (id) => {
    // Lógica para eliminar el anuncio
    request.ad.delete(id).then(() => {
      // Actualizar el estado después de eliminar
      this.setState(prevState => ({
        ads: prevState.ads.filter(ad => ad.id !== id)
      }));
    });
  }

  render = () => {
    return (
      <div style={{ width: "100%", float: "left", position: "relative", display: "flex", flexDirection: "column" }}>
        <ServerSidePaginationTable
          requestTableData={paginationConfig => {
            return request.ads.getAll(paginationConfig).then((response) => {
              this.setState({ total: response.meta.total });

              return response;
            });
          }}
          ref="table"
          columns={this.columns()}
        />
      </div>
    );
  }
}

export default Table;
