import React, {Component} from "react";
import {request} from "../../api-client";
import {ServerSidePaginationTable, TableButton, TableButtonsBuilder} from "../../components/Letflow/Table";
import localization from "config/localization";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import {Message, NewReleases} from "@material-ui/icons";
import browserHistory from "../../utils/browserHistory";
import {getStoredUser} from "../../api-client/core/authentication/utils";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";

const UserFilter = ({ onChange }) => (
  <AsyncSelect
    styles={{
      control: provided => ({
        ...provided,
        borderStyle: "none",
        borderBottomStyle: "solid",
        backgroundColor: 'transparent',
        "&:hover": {
          borderStyle: "none",
          borderBottomStyle: "solid"
        },
        "&:focus-within": {
          boxShadow: "none",
          borderColor: "inherit"
        }
      }),
      container: base => ({
        ...base,
        position: null,
        marginBottom: 20
      }),
      menuPortal: base => ({
        ...base,
        backgroundColor: "red",
        borderStyle: "none"
      }),
      menu: base => ({
        ...base,
        zIndex: 10,
        top: null,
        maxWidth: 500
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: "none"
      })
    }}
    theme={theme => ({
      ...theme,
      borderRadius: 0
    })}
    placeholder={localization.get('conversation.filter_users')}
    loadingMessage={() => localization.get('loading')}
    noOptionsMessage={() => localization.get('no_options')}
    onChange={users => onChange(users.map(user => user.value).join(","))}
    loadOptions={inputValue => request.messaging.usersForNewConversation('user', inputValue).then(res => res.map(user => ({ value: user.id, label: user.name })))}
    defaultOptions
    isMulti
  />
);

class Table extends Component {

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  fetchConversations = async pagination => {
    if (!pagination.orderBy) {
      pagination.orderBy = { column: "updated_at", type: "desc" };
    }
    return await request.messaging.conversations(pagination);
  };

  goToConversation = conversationId => browserHistory.push(this.props.threadPathFactory(conversationId))

  columns = () => [
    {
      Header: localization.get('table.subject'),
      accessor: "subject",
      Cell: ({ original, value }) => (
        <div>
          {original.has_unseen_messages && (
            <div style={{ position: "absolute", left: -32 }}>
              <NewReleases style={{ color: "red" }} />
            </div>
          )}
          <span>{value}</span>
        </div>
      )
    },
    {
      id: "user_ids",
      Header: localization.get('table.users'),
      accessor: "users",
      sortable: false,
      Cell: ({ value }) => {
        return <span>{value.map(x => x.name).join(", ")}</span>;
      },
      Filter: ({ onChange }) => <UserFilter onChange={onChange} />
    },
    {
      Header: localization.get('table.created_at'),
      accessor: "created_at",
      filterable: false,
      Cell: ({ value }) => localization.moment(value).format("DD-MM-YYYY")
    },
    {
      Header: localization.get('table.last_message'),
      accessor: "updated_at",
      filterable: false,
      Cell: ({ value }) => localization.moment(value).fromNow()
    },
    {
      id: "actions",
      Header: localization.get('table.actions'),
      sortable: false,
      filterable: false,
      Cell: ({ original }) => {
        const tb = new TableButtonsBuilder();
        original.created_by.id === getStoredUser().id &&
          tb.withDelete(
            original.subject,
            () => request.messaging.deleteConversation({ conversationId: original.id }),
            status => {
              if (status === "passed") {
                this.tableRef.current.forceUpdate();
              }
            }
          );
        tb.withOther(
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              this.goToConversation(original.id)
            }}
            title={localization.get('button.watch_conversation')}
          >
            <Message />
          </TableButton>
        );
        return tb.build();
      }
    }
  ];

  render = () => {
    return (
      <ServerSidePaginationTable
        ref={this.tableRef}
        requestTableData={pagination => this.fetchConversations(pagination)}
        columns={this.columns()}
        getTheadFilterThProps={() => ({
          style: { position: "unset" } // IMPORTANTISIMO PARA QUE LA BASURA DEL ASYNC SELECT NO SE MUESTRE ATRAS DE LA MALDITA TABLA
        })}
        getTrProps={(_, row) => ({
          onClick: () => {
            if (row) {
              this.goToConversation(row.original.id)
            }
          },
          style: {
            cursor: row ? 'pointer' : undefined
          }
        })}
      />
    );
  };
}

export default class extends Component {
  //
  state = { writter: null };

  componentDidMount = () => {
    document.title = localization.get("title.messaging") + " - Feater";
  };

  onAdd = () => browserHistory.push(this.props.createConversationPath);

  render = () => (
    <AllViewContainer onAdd={this.props.createConversationPath && this.onAdd} ref="viewContainer">
      <div style={{ marginTop: 20 }}></div>
      <ReceiveEmailsCheckbox />
      <div style={{ marginTop: 20 }}></div>
      <Table threadPathFactory={this.props.threadPathFactory} />
    </AllViewContainer>
  );
}

class ReceiveEmailsCheckbox extends Component {
  
  state = { writter: null };

  componentDidMount = () => request.messaging.writter().then(writter => this.setState({ writter }));

  onChange = writter => () => {
    
    const next_receive_emails = !writter.receive_emails;

    this.setState({ writter: { ...writter, receive_emails: next_receive_emails } });

    request.messaging
      .updateWritter({
        receive_emails: next_receive_emails,
        email: getStoredUser().email
      })
      .then(updatedWritter => this.setState({ writter: updatedWritter }));
  };

  render = () => {
    
    const { writter } = this.state;

    return (
      Boolean(writter) && (
        <FormGroup style={{position: 'relative', left: 2}}>
          <FormControlLabel
            control={<Checkbox color="default" checked={writter.receive_emails} onChange={this.onChange(writter)} />}
            label={localization.get("conversations.subscribe_to_emails")}
          />
        </FormGroup>
      )
    );
  };
}
