import React, {Component} from "react";
import EventManager, {events} from "../../../utils/EventManager";
import GoToLoginDialog from "./GoToLoginDialog";

class GlobalRedirectLoginConfirmationDialog extends Component {
  static _ref = null;

  static show = ({ title, content, request, onClose, confirmationLabel, hideCancel }) => {
    EventManager.getInstance().dispatch(events.SHOW_GLOBAL_LOGIN_DIALOG, {
      title,
      content,
      request,
      onClose,
      confirmationLabel,
      hideCancel
    });
  }

  static hide = () => EventManager.getInstance().dispatch(events.HIDE_GLOBAL_LOGIN_DIALOG);

  constructor(props) {
    super(props);
    GlobalRedirectLoginConfirmationDialog._ref = this;
    this.state = {
      open: false,
      disableSubmit: false
    };
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.SHOW_GLOBAL_LOGIN_DIALOG, this.show);
    EventManager.getInstance().subscribe(events.HIDE_GLOBAL_LOGIN_DIALOG, this.hide);
    EventManager.getInstance().subscribe(events.LOGIN_DIALOG_CLOSED, this.onInstanceClosed);
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SHOW_GLOBAL_LOGIN_DIALOG, this.show);
    EventManager.getInstance().unsubscribe(events.HIDE_GLOBAL_LOGIN_DIALOG, this.hide);
    EventManager.getInstance().unsubscribe(events.LOGIN_DIALOG_CLOSED, this.onInstanceClosed);
  };

  show = data => this.setState({ open: true, ...data });

  hide = () => this.setState({ open: false });

  onInstanceClosed = confirmationDialog => {
    if (confirmationDialog === this.confirmationDialog) {
      GlobalRedirectLoginConfirmationDialog.hide();
    }
  };

  disableSubmit = value => this.setState({ disableSubmit: value });

  render = () => {
    const { open, title, content, request, onClose, confirmationLabel, disableSubmit, hideCancel, doubleConfirmation, elementName, auxiliaryInformation } = this.state;

    return (
      <GoToLoginDialog
        ref={r => (this.confirmationDialog = r)}
        open={open}
        title={title}
        content={content}
        request={request}
        onClose={onClose}
        hideCancel={hideCancel}
        confirmationLabel={confirmationLabel}
        disableSubmit={disableSubmit}
        auxiliaryInformation={auxiliaryInformation}
        doubleConfirmation={doubleConfirmation}
        elementName={elementName}
      />
    );
  };
}

export default GlobalRedirectLoginConfirmationDialog;
