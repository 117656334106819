import "layouts/Home/HomeElements/Styles/Banner.css";
import React from "react";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {OpenInNew, Pause} from "@material-ui/icons"
import EventManager, {events} from '../../../utils/EventManager'
import VolumeUp from "@material-ui/icons/VolumeUp";
import {addHttpsToUrl} from "../../../api-client/core/authentication/utils";
import localization from '../../../config/localization';
import {request} from "../../../api-client";
import ShakaPlayer from "../../../components/Letflow/ShakaPlayer";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {onImageError} from "./utils";
import {get} from "lodash";

class DefaultBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: props.banner,
      playingButton: false,
      client: props.client ? props.client : null,
      previewEnded: false,
      showPreview: true,
      language: localization.getLanguage(),
      muted: true,
    }
  }

  componentDidMount() {
    EventManager.getInstance().subscribe(events.OPEN_CONTENT_MODAL, this.pauseVideoWhenOpenModal)
    EventManager.getInstance().subscribe(events.CHANGE_VIDEO_SLIDER_BANNER, this.pauseVideoWhenOpenModal)
  }

  componentWillUnmount() {
    EventManager.getInstance().unsubscribe(events.OPEN_CONTENT_MODAL, this.pauseVideoWhenOpenModal)
    EventManager.getInstance().unsubscribe(events.CHANGE_VIDEO_SLIDER_BANNER, this.pauseVideoWhenOpenModal)
  }

  pauseVideoWhenOpenModal = () => {
    if (this.state.playingButton === true) {
      this.setState({ playingButton: false }, () => {
        this.setState({muted: true})
      })
    }
  }

  handleClickPlay = () => {
    if (this.state.playingButton === true) {
      this.setState({ playingButton: false }, () => {
        this.setState({muted: !this.state.muted, showPreview: false})
      })
    } else {
      this.setState({ playingButton: true }, () => {
        this.setState({muted: !this.state.muted, showPreview: false})
      })
    }
  }

  playVideo = (type, refName) => this.refs[`${type}-${refName}`].play()

  pauseVideo = (type, refName) => this.refs[`${type}-${refName}`].pause()

  makeCustomButton = (banner) => {
    let text = this.state.language == 'en' ? banner.textEn || banner.textEs || banner.text : banner.textEs || banner.text;
    return (
      <a
        target={!!banner.new_tab && "_blank"}
        className={`${banner.shape === 'square' ? 'banner-link-button banner-link-button-square' : 'banner-link-button'}`}
        href={addHttpsToUrl(banner.link)}
        style={{backgroundColor: banner.background_color || 'white', color: banner.font_color || 'black'}}
      >
        <span style={{display: 'flex', alignItems: "center", padding: banner.padding ? `${banner.padding}px calc(${banner.padding}px / 2 * ${banner.shape == 'square' ? 3 : 5})` : banner.shape == 'square' ? '4px 6px' : '4px 10px', fontSize: `${banner.fontSize}px` || 14}}>{banner.icon == 'show' && <OpenInNew style={{marginRight: 5, color: banner.font_color || 'black', fontSize: '1.5em'}}/>} {(text && text.length > 0) ? text : localization.get('go_to.link')}</span>
      </a>
    )
  }

  getBannerImage = () => {
    if (get(this.state, "banner.image.original_name") && this.state.banner.image.original_name.endsWith('.gif')) {
      return this.state.banner.image.url;
    }

    const height = this.state.banner.half_height ? 420 : 840;
    return this.state.banner.image && request.file.image.makeFittedUrl(this.state.banner.image.id, 1500, height);
  }

  render() {
    let { banner } = this.state
    let hasCustomButton = banner && !!banner.position;

    let bannerElement = 
        (<div className={`home-banner-video ${(banner && banner.half_height) ? "home-banner-video-half-height" : ""}`}>
          <div className="default-home-banner-cover" style={{zIndex: this.state.playingButton ? 0 : 12}}/>
          <ul className={"home-banner-ul"} style={{ height: "100%" }}>
            <div className="home-banner-item">
              {banner.link && banner.has_button && (banner.video) && !hasCustomButton &&
                <div className="banner-top-center-link-button-container">
                  <a href={addHttpsToUrl(banner.link)}
                   className="default-home-banner-link-button"
                   target={!!banner.new_tab && "_blank"}
                   style={{textDecoration: "none"}}
                  >
                    <p style={{padding: "2px 7px"}}>{localization.get('go_to.link')}</p> <OpenInNew/>
                  </a>
                </div>
              }
              {banner.link && banner.has_button && hasCustomButton && (banner.position == 'top-center') && <div className="banner-top-center-link-button-container">{this.makeCustomButton(banner)}</div>}
              {banner.video ?
                <div style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  zIndex: banner.state === "hover" ? "10" : banner.state === "left" ? "9" : "8",
                }}>
                  <ShakaPlayer
                    video={banner.video}
                    preview={false}
                    id="banner-video"
                    muted={this.state.muted}
                    ref={`video-${banner.id}`}
                    loop={true}
                    preload={"auto"}
                    className={"default-home-banner-video"}
                    autoPlay={true}
                    style={{
                      zIndex: banner.state === "hover" ? "10" : "8",
                      minWidth: "100%",
                      minHeight: "100%",
                      left: "50%",
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%, -50%)"
                    }}
                    controls={false}
                    isBanner
                  />
                </div>
                :
                <div style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  zIndex: banner.state === "hover" ? "10" : banner.state === "left" ? "9" : "8",
                }}>
                  <img
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        objectFit: "cover"
                      }}
                     src={this.getBannerImage()}
                     onError={onImageError}
                     />
                </div>
              }
              <li className="item-home-banner">
                <div
                  className="video-caption default-home-banner-video-container"
                  style={{left: 0, width: "100%"}}
                >
                  <div className="home-banner-video-title">
                    {!(window.innerWidth < 650 && this.state.playingButton) &&
                    <>
                      {!banner.link ?
                        <div className="home-banner-video-title-content"
                             style={{bottom: !this.state.showPreview ? 20 : "20%"}}>
                          <h3
                            className={`home-banner-title ${(!banner.video) ? "home-banner-title-image-banner" : ""}`}>{localization.getMainLocalizatonResource(banner, "title", "title")}</h3>
                          <h5 className="home-banner-subtitle mb15 mt0">
                            <small
                              className={(!banner.video) ? "home-banner-subtitle-image-banner" : ""}>{localization.getMainLocalizatonResource(banner, "subtitle", "subtitle")}</small>
                          </h5>
                        </div>
                        :
                        <div
                          className="home-banner-video-title-content"
                          style={{bottom: !this.state.showPreview ? 20 : "20%"}}
                        >
                          <a href={addHttpsToUrl(banner.link)} target={!!banner.new_tab && "_blank"}><h3 className="home-banner-title " style={{fontSize: 40}}>{localization.getMainLocalizatonResource(banner, "title", "title")}</h3></a>
                          {!!localization.getMainLocalizatonResource(banner, "subtitle", "subtitle") && 
                          <a href={addHttpsToUrl(banner.link)} target={!!banner.new_tab && "_blank"}>
                            <h5 className="home-banner-subtitle mb15 mt0">
                              <small style={{fontSize: 28}}>{localization.getMainLocalizatonResource(banner, "subtitle", "subtitle")}</small>
                            </h5>
                          </a>}
                          {banner.has_button && hasCustomButton && banner.position == 'bottom-center' && <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>{this.makeCustomButton(banner)}</div>}
                        </div>
                      }
                    </>
                    }
                    {(banner.video) &&
                    <>
                      {this.state.playingButton ?
                        <a onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          this.handleClickPlay()
                        }}
                           className="default-home-banner-video-button"
                           style={{display: (this.state.playingButton) ? 'block' : this.state.playingButton ? 'none' : 'block'}}
                        >
                          <Pause className="default-home-banner-video-icon"/>
                        </a>
                        :
                        <a onClick={(e) => {
                          analyticsInstance.banner({
                            name: localization.getMainLocalizatonResource(banner, "title", "title"),
                            type: "Play",
                          })
                          e.preventDefault()
                          e.stopPropagation()
                          this.handleClickPlay()
                        }}
                           className="default-home-banner-video-button"
                        >
                          <VolumeUp className="default-home-banner-video-icon"/>
                        </a>
                      }
                    </>
                    }
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>)
    if (banner && banner.link) {
      return <a
        href={addHttpsToUrl(banner.link)}
        onClick={() => analyticsInstance.banner({
          name: localization.getMainLocalizatonResource(banner, "title", "title"),
          type: "Link",
        })}
        target={!!banner.new_tab && "_blank"}
      >
        {bannerElement}
      </a>
    } else {
      return bannerElement;
    }
  }
}

export default withStyles(pagesStyle)(DefaultBanner);
