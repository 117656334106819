import React, {Component} from "react";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import browserHistory from "../../../utils/browserHistory";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

class TableSubcomponent extends Component {
  state = {
    contentCreations: []
  };

  componentDidMount = () => {
    request.contentCreator
      .get(this.props.contentCreator.id, 'include=contentCreations')
      .then(contentCreator => this.setState({ contentCreations: contentCreator.contentCreations }));
  };

  makeEditUrl = (v) => {
    if (this.props.isTalent) {
      return "/talents/content_creations/" + v.original.id + "/edit"
    } else {
     return "/audiovisual/content_creations/" + v.original.id + "/edit"
    }
  }

  buttons = contentCreation => {
      let editUrl = '';
      if(this.props.isDemo) {
        editUrl = makeUrlWithTableParams(`/panel/demos/content_creations/${contentCreation.id}/edit?is_demo=true`);
      } else if (this.props.isTalent) {
        editUrl = makeUrlWithTableParams(`/panel/talents/content_creations/${contentCreation.id}/edit`);
      } else {
        editUrl = makeUrlWithTableParams(`/panel/audiovisual/content_creations/${contentCreation.id}/edit`);
      }

      const buttonBuilder = new TableButtonsBuilder();
      buttonBuilder.withEdit(editUrl);

      return buttonBuilder.build();
    };

  render = () => {
    return (
      <div style={{ margin: "20px" }}>
        <h4>{this.props.contentCreator.name}</h4>
        {this.state.contentCreations.length > 0 && (
          <ClientSidePaginationTable
            data={this.state.contentCreations.map((contentCreation, index) => ({
              ...contentCreation,
              index
            }))}
            columns={[
              {
                accessor: "title",
                Cell: v => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => browserHistory.push(this.makeEditUrl(v))}
                  >
                    {v.value}
                  </div>
                )
              },
              {
              id: "actions",
              sortable: false,
              filterable: false,
              accessor: this.buttons,
              width: 145,
              }
            ]}
            filterable={false}
            sortable={false}
            showPagination={false}
            zebraStyle={false}
          />
        )}
        {this.props.contentCreator.observation &&
        <div>
          <b style={{width: "100%", position: "relative", float: "left"}}>{localization.get('form.observations')}</b>
          {this.props.contentCreator.observation }
        </div>
        }
      </div>
    );
  };
}

export default TableSubcomponent;
