import React, {Component} from 'react'
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {request} from '../../../../api-client';
import localization from '../../../../config/localization';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import HomeBanners from './HomeBanners';
import {getActiveClient} from "../../../../api-client/core/authentication/utils";
import styled from "styled-components";

const homeElementViewFactories = {
  'banner': ({ clientId, homeElement, onUpdateSuccess, onUpdateFailed }) =>
    <HomeBanners {...homeElement} clientId={clientId} onUpdateSuccess={onUpdateSuccess} onUpdateFailed={onUpdateFailed} />,
}

class HomeEditor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      creatingNewHomeElement: false,
      orderOfCreation: -1,
      showBanner: props.client ? props.client.show_banner : false,
      homeElements: []
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    this.requestHomeElements()
  }

  makeHomeElements = () => {
    let homeElement = this.state.homeElements.find(homeElement => homeElement.data_source === "banner")
    return this.makeHomeElementView(homeElement)
  }

  requestHomeElements = () => request.client.home.elements.get(getActiveClient())
    .then(homeElements => this.setState({homeElements: homeElements.map(homeElement => ({ ...homeElement, items: homeElement.items.data }))}))

  makeHomeElementView = homeElement => this.appendHomeElementView(homeElementViewFactories['banner']({
    homeElement,
    onUpdateSuccess: this.requestHomeElements,
    onUpdateFailed: () => console.log('failed'),
    clientId: getActiveClient(),
  }))

  appendHomeElementView = (homeElementView) =>
    <div style={{ width: '100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "10px" }}>
      {homeElementView}
    </div>


  render = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <H2>{localization.get('account.banner.configuration')}</H2>
        <FormControl style={{width: "300px", margin: "30px 0 30px 0"}}>
          <RadioGroup
            name="showClients"
            value={this.state.showBanner}
            onChange={(e) => {
              let value = e.target.value
              if(this.state.showBanner !== value) {
                request.client.update(getActiveClient(), {showBanner: value})
                  .then(() => this.setState({showBanner: value}))
              }
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="none" control={<Radio color="default" />} label={localization.get('account.banner.none')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="default" control={<Radio color="default" />} label={localization.get('account.banner.default')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="owned" control={<Radio color="default" />} label={localization.get('account.banner.owned')} />

          </RadioGroup>
          <FormHelperText style={{lineHeight: "1.4em"}}>{this.state.showBanner === 'none' ? 'No se mostraran banners en la home del cliente' : this.state.showBanner === 'default' ? 'Se mostraran banners realizados por el equipo artistico de Feater' : ''}</FormHelperText>
        </FormControl>

        {this.state.showBanner === 'owned' &&
          <React.Fragment>
            {this.makeHomeElements()}
          </React.Fragment>
        }
      </div>
    )
  }
}

export default HomeEditor

const H2 = styled.h2`
@media (max-width: 360px){
font-size: 2.2em;
}
`
