import download from 'downloadjs'
import {get} from "../utils";

export default host => ({audioId, token, fileName, image}) => downloadTrack(host, audioId, token, fileName, image)

const downloadTrack = (host, audioId, token, fileName, image) => {
  return get(`${host}/public/playlists/${token}/audios/${audioId}/download`)
    .then(response => response.json())
    .then(json => json.data)
    .then(track => getAudioUrlFromTrack(track))
    .then(audioUrl => requestAudio(audioUrl))
    .then(audio => download(audio, `${fileName}.mp3`, 'audio/mpeg'))
}


const getAudioUrlFromTrack = (track) => {
  return track.demo.url
}

const requestAudio = audioUrl =>
  fetch(audioUrl, { headers: { 'Accept': 'audio/mpeg' } })
    .then(response => response.arrayBuffer())


