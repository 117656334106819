import {post} from "../utils";
import {makeFormData} from "./utils";
import {getActiveClient} from "../authentication/utils";

export default host => (
  name,
  email,
  description,
) =>
  post(
    `${host}/clients/${getActiveClient()}/contact`,
    makeFormData({
      name,
      email,
      description,
    })
  ).then(response => response.json());