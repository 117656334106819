import React, {Component} from "react";
import {request} from "api-client";
import localization from '../../../../config/localization'
import FormHandler from "../Form/FormHandler";
import {makeUrlWithTableParams} from "../../../../utils";


export default class extends Component {
  constructor(props){
    super(props);
    this.state = {
      client: '',
      channel: '',
      titleEs: '',
      subtitleEs: '',
      titleEn: '',
      subtitleEn: '',
      loaded: false,
      translationEs: '',
      translationEn: '',
      selectedContent: [],
      orderType: "manual"
    }
  }

  componentDidMount = () => {
    request.playlist.get(this.props.match.params.id, 'include=translations;client;channel&for_channel=true')
      .then((playlist) => {
        this.setState({
          translationEs: playlist.translations.find(translation => translation.locale === "es"),
        })
        return playlist
      })
      .then((playlist) => {
        this.setState({
          id: playlist.id,
          client: { value: playlist.client.id, label: playlist.client.name },
          channel: { value: playlist.channel.id, label: playlist.channel.name },
          titleEs: this.state.translationEs && this.state.translationEs.title,
          selectedContent: playlist.contentCreators,
          loaded: true,
          orderType: playlist.order_type || "manual"
        })
      })
  }

  submitRequest = data => {
    return request.playlist.update({
      playlistId: this.props.match.params.id,
      ...data
    })
      .then(() => {
        this.props.history.push(makeUrlWithTableParams('/panel/lists/channels'))
      });
  };

  render = () => {
    if (this.state.loaded) {
      return <FormHandler {...this.props} {...this.state} submitRequest={this.submitRequest} formTitle={localization.get('home_playlist.update')} />;
    } else {
      return <div></div>
    }
  };
}
