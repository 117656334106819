import React, {Component} from "react";
import {request} from "api-client";
import Form from "../Form";
import localization from '../../../config/localization'

export default class extends Component {
  submitRequest = requestData => {
    return request.catalog.create(requestData);
  };

  render = () => {
    return <Form {...this.props} submitRequest={this.submitRequest} formTitle={localization.get('catalog.create_catalog')}/>;
  };
}
