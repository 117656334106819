import {post} from '../utils'
import {makeFormData, root} from './utils'

export default host => ({
  translations,
  slug,
  image,
  allowAudio,
  allowImage,
  allowVideo,
  allowBinary,
  allowLink,
  allowEmbed,
  limit,
  isTalent,
  visibility,
  clientId,
  type,
  synonyms,
                          downloadable,
}) =>
  post(`${host}${root}`, makeFormData({
    translations,
    slug,
    image,
    allowAudio,
    allowImage,
    allowVideo,
    allowBinary,
    allowLink,
    allowEmbed,
    limit,
    creating: true,
    isTalent,
    visibility,
    clientId,
    type,
    synonyms,
    downloadable,
  }))
    .then(response => response.json())
