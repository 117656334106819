import {post} from "../utils";
import {getActiveClient} from "../authentication/utils";

export default host => (socials) => {
  let object = {};
  object.socials = []

  Object.keys(socials).map(key =>
    object.socials.push({type: key , url: !socials[key].match(/^https?:\/\//i) && socials[key] !== "" ? 'http://' + socials[key] : socials[key]})
  )

  return post(
    `${host}/clients/${getActiveClient()}/socials`, JSON.stringify(object))
    .then(response => response.json());
}