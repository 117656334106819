import {isNullOrUndefined} from "util";
import moment from "moment";

export const makeFormData = ({
     code,
     discount,
     quantity,
     expiresAt,
     description,
     products,
     includeAll,
   }) => {
  const form = new FormData();
  if (code) form.append("code", code);
  if (!isNullOrUndefined(discount)) form.append("discount", discount);
  if (quantity) form.append("quantity", quantity);
  if (expiresAt) form.append("expires_at", moment(expiresAt).format("YYYY-MM-DD"));
  if (description) form.append("description", description);
  if (products.length > 0) products.map(product => form.append("product_ids[]", product.value));
  if (!isNullOrUndefined(includeAll)) form.append("include_all", includeAll ? 1 : 0);


  return form;
};

export const includeQuery = "include=channel;products.content";
