import React, {Component} from 'react'
import {Card, CardActions, CardContent, IconButton, Typography,} from '@material-ui/core';
import {Delete, Edit, Videocam} from '@material-ui/icons';
import {getPropertySafe} from '../../../../../utils';
import HomeBannerElementVideoDialog from './HomeBannerElementVideoDialog';
import localization from '../../../../../config/localization';
import HomeBannerElementForm from './HomeBannerElementForm';
import {request} from '../../../../../api-client';
import GlobalConfirmationDialog from '../../../../../components/Letflow/Dialog/GlobalConfirmationDialog';
import {makeImageUrlWithSize} from "../../../HomeElements/utils";

class HomeBannerElement extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
      showForm: false,
    }
  }

  makeVideoDialog = () => this.state.showVideo && <HomeBannerElementVideoDialog
    videoUrl={this.props.banner.video.url}
    onClose={() => this.setState({ showVideo: false })}
  />

  makeBannerForm = () => this.state.showForm && <HomeBannerElementForm
    formTitle={localization.get('form.title')}
    active={this.props.banner.active}
    title={this.props.banner.title}
    subtitle={this.props.banner.subtitle}
    image={makeImageUrlWithSize(this.props.banner, 'md')}
    video={getPropertySafe(this.props.banner, 'video.url', null)}
    preview={getPropertySafe(this.props.banner, 'preview.url', null)}
    onClose={() => this.setState({ showForm: false })}
    submitRequest={(active, title, subtitle, link, newTab, image, video, preview, hasButton, button, onProgress) => request.client.home.elements.updateBannerItem(
      this.props.clientId,
      this.props.elementId,
      this.props.itemIndex,
      { active, title, subtitle, link, newTab, image, video, preview, hasButton, button },
      onProgress
    )
      .then(this.props.onUpdateSuccess)
      .catch(this.props.onUpdateFailed)}
  />
  
  requestDelete = () => request.client.home.elements.deleteBannerItem(this.props.clientId, this.props.elementId, this.props.itemIndex).then(this.props.onUpdateSuccess).catch(this.props.onUpdateFailed)

  handleDelete = () => GlobalConfirmationDialog.showDelete({ elementName: this.props.banner.title, deleteRequest: this.requestDelete })

  render = () => {
    const { banner } = this.props
    return (
      <div>
        {this.makeVideoDialog()}
        {this.makeBannerForm()}
        <Card>
          <CardContent style={{ textAlign: 'left' }}>
            <Typography style={{lineHeight: "1.5em", height: "3em", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{banner.title}</Typography>
            <Typography style={{lineHeight: "1.5em", height: "3em", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} variant='caption'>{banner.subtitle || '-'}</Typography>
            <Typography>Estado: {banner.active ? 'Activo' : 'Inactivo'}</Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.setState({ showVideo: true })} disabled={!this.props.banner.video}><Videocam /></IconButton>
            <IconButton onClick={() => this.setState({ showForm: true })}><Edit /></IconButton>
            <IconButton onClick={this.handleDelete}><Delete /></IconButton>
          </CardActions>
        </Card>
      </div>
    )
  }
}

export default HomeBannerElement