import { post } from '../utils';

const makePurchaseForm = (gateway, code, callback, shipment) => {
    const form = new FormData();
    if (gateway) form.append("gateway", gateway);
    if (code) form.append("code", code);
    if (callback) form.append("callback", callback);
    if (shipment && (shipment.address || shipment.channelShipment)) {
      form.append("shipment_type", shipment.address ? "withdrawal" : "delivery");
      form.append("shipment_id", shipment.address ? shipment.address.id : shipment.channelShipment.id);
    }
    return form;
}

export default host => (product, {gateway = "mercadopago", code, callback, shipment=null}) =>
  post(`${host}/products/${product}/purchases`, makePurchaseForm(gateway, code, callback, shipment))
    .then(response => response.json())
    .then(json => json.data)
