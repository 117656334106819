import React, {Component} from "react";
import TypeUsersSelector from "./TypeUsersSelector";
import ParamUserSelector from "./ParamUserSelector";
import {wrapArray} from "../../../utils";
import qs from 'qs'

export default class extends Component {

  constructor(props) {
    super(props);
    this.selectUserRef = React.createRef();


    const params = qs.parse(props.location.search.slice(1));
    if (params.user_id) {
      this.state = { usersIds: wrapArray(params.user_id) }
    } else {
      this.state = {}
    }
  }

  getUsers = async () => await this.selectUserRef.current.getUsers()

  render = () => {
    if (this.state.usersIds) {
      return <ParamUserSelector ref={this.selectUserRef} usersIds={this.state.usersIds} />
    } else {
      return <TypeUsersSelector ref={this.selectUserRef} />
    }
  }
}