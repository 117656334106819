import {makeRoutes, publicRoutes, redirectTo, route} from "./utils";
import Channel from "../../layouts/Channel";
import Dashboard from "../../layouts/Dashboard";

const homeRedirect = channel => redirectTo(`/channel/${channel.id}-${channel.name}`);

const allowed = () => publicRoutes.concat([
  route(`/channel/:channelId-:channelName`, Channel),
  route("/panel", Dashboard)
]);

const excluded = channel => [
  route(`/home/:channelId-:channelName/playlist/:playlistId/edit`, homeRedirect(channel))
];

const def = channel => route("/", homeRedirect(channel));

export default channel => makeRoutes(allowed(channel), excluded(channel), def(channel));
