import getAll from './getAll'
import del from './del'
import create from './create'
import get from './get'
import update from './update'
import getAdForChannel from './getAdForChannel'
import trackAdView from './trackAdView'
import getReport from './getReport'

export default host => ({
  getAll: getAll(host),
  delete: del(host),
  create: create(host),
  get: get(host),
  update: update(host),
  getAdForChannel: getAdForChannel(host),
  trackAdView: trackAdView(host),
  getReport: getReport(host),
})
