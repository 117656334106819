import {addPutMethodToForm, post,} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  blogTagId,
  translations,
  }) =>
  post(`${host}/blogTags/${blogTagId}`, addPutMethodToForm(makeFormData({
    translations,
  })))
  .then(response => response.json())