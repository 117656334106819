import React, {Component} from "react";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";
import {request} from "../../../api-client";
import localization from "../../../config/localization";

export default class extends Component {

  typeOptions = [
    { value: 'administrator', label: 'Flowlike' },
    { value: 'representative', label: localization.get('representative') },
    { value: 'artist', label: localization.get('artist') },
    { value: 'user', label: localization.get('user') },
  ]

  state = {
    type: this.typeOptions[0],
    client: null,
    sendToAllAdministrators: false,
    users: []
  };

  selectUsersKey = () => 'select-users-' + this.state.type.value + (this.state.client ? this.state.client.value : '')

  administratorSelected = () => ['administrator'].some(type => type === this.state.type.value)

  sendToAllAdministratorsSelected = () => this.state.sendToAllAdministrators

  getUsers = async () => {
    const fetchAllAdministratorUsers = () => request.messaging.usersForNewConversation('administrator').then(res => res.map(user => user.id))

    if (this.administratorSelected() && this.sendToAllAdministratorsSelected()) {
      return await fetchAllAdministratorUsers()
    } else {
      return this.state.users.map(user => user.value)
    }
  }

  render = () => {
    const administratorSelected = this.administratorSelected()

    const artistNotSelected = !['artist'].some(type => type === this.state.type.value)

    const noClientSelected = !this.state.client

    const administratorNotSelected = !administratorSelected

    const sendToAllAdministratorsSelected = this.sendToAllAdministratorsSelected()

    const selectUserIsDisabled =
      (artistNotSelected && administratorNotSelected && noClientSelected) ||
      (administratorSelected && sendToAllAdministratorsSelected)

    const selectClientLoadOptions = clientName =>
      request.client
        .getAll({ filterBy: [{ column: "name", filter: clientName }] })
        .then(res => res.map(client => ({ value: client.id, label: client.name })))

    const selectUserLoadOptions = userName =>
      request.messaging
        .usersForNewConversation(this.state.type.value, userName, this.state.client && this.state.client.value)
        .then(res => res.map(user => ({ value: user.id, label: user.name })))

    const selectType = (
      <div>
        <span>{localization.get('type')}</span>
        <Select
          styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
          value={this.state.type}
          onChange={type => {
            if (type.value === 'administrator') {
              this.setState({ users: [] })
            }
            this.setState({ type, client: null })
          }}
          options={this.typeOptions}
        />
      </div>
    )

    const selectClient = (
      <div>
        <span>{localization.get('client')}</span>
        <AsyncSelect
          styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
          value={this.state.client}
          placeholder={localization.get('select_client')}
          defaultOptions
          onChange={client => this.setState({ client })}
          isDisabled={!['user', 'representative'].some(type => type === this.state.type.value)}
          backspaceRemovesValue
          loadOptions={selectClientLoadOptions}
        />
      </div>
    )

    const selectUser = (
      <div>
        <span>{localization.get('users')}</span>
        <AsyncSelect
          styles={{ menu: base => ({ ...base, zIndex: 10 }) }}
          key={this.selectUsersKey()}
          placeholder={localization.get('select_users')}
          value={this.state.users}
          onChange={users => this.setState({ users })}
          isDisabled={selectUserIsDisabled}
          loadOptions={selectUserLoadOptions}
          defaultOptions
          isMulti
        />
      </div>
    )

    const sendToAllAdministratorsView = administratorSelected && (
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={this.state.sendToAllAdministrators}
            onChange={() => this.setState({ sendToAllAdministrators: !this.state.sendToAllAdministrators })}
          />
        }
        label={localization.get("send_to_all_flowlike_administrators")}
      />
    )

    return (
      <div>
        <Typography variant="title">{localization.get('conversation_writters_selection')}</Typography>
        <Sep />
        {selectType}
        <Sep />
        {selectClient}
        <Sep />
        {selectUser}
        {sendToAllAdministratorsView}
      </div>
    )
  }
}

const Sep = () => <div style={{ marginBottom: 20 }} />;