import React from "react";
import {Close} from "@material-ui/icons";
import classNames from "classnames";
import {
  Exclusivity,
  LicenseTypeIds,
  MediaTypeIds,
  RegionIds,
  SadaicCategories,
  SadaicDurations,
} from "./utils/constants";
import "./style/LicenseWizard.css";
import {
  countriesForRegionToString,
  internetBudgetToString,
  licenseTypeIdToString,
  mediaIdToString,
  regionIdToString,
  sadaicCategoryToDescriptionString,
  sadaicCategoryToString,
  sadaicMonthsToString,
  sadaicSecondsToString,
} from "./utils/mappers";
import PriceCalculator from "./utils/PriceCalculator";
import _ from "lodash";
import SadaicPriceCalculator from "./utils/SadaicPriceCalculator";
import localization from "../../../config/localization"
import {userIsSysAdmin} from "../../../api-client/core/authentication/utils";
import { onImageError } from "../../../layouts/Home/HomeElements/utils"

const LicenseWizardSidebar = ({
  track,
  licenseType,
  medias,
  internetBudget,
  regions,
  exclusivity,
  extraDuration,
  editionAccordingToSeconds,
  biChannel,
  reductions,
  unlimitedReductions,
  enableLicenseButton,
  sadaicCategory,
  sadaicDuration,
  sadaicVersions,
  prices,
  onClose,
  onLicenseClick,
  licensePreviewUrl,
  client,
  discount,
  onChangeDiscount
}) => {
  const makeLicenseTypeContent = () => {
    if (
      licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL &&
      medias.some(media => MediaTypeIds.includesInternet(media))
    ) {
      return (
        licenseTypeIdToString(licenseType) + ", " + licenseTypeIdToString(LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS)
      );
    }
    return licenseTypeIdToString(licenseType);
  };

  const makeMediasContent = () => {
    if (medias.length === 0) {
      return "-";
    }
    if (medias.includes(MediaTypeIds.ALL_MEDIA)) {
      return mediaIdToString(MediaTypeIds.ALL_MEDIA);
    }
    if (medias.includes(MediaTypeIds.TELEVISION)) {
      return mediaIdToString(MediaTypeIds.TELEVISION) + ` (${localization.get('license_wizard.include_internet')})`;
    }
    return medias
      .map(x => {
        if (x === MediaTypeIds.INTERNET && !medias.includes(MediaTypeIds.TELEVISION)) {
          return mediaIdToString(x) + ` (${localization.get('license_wizard.budget_of')} ${internetBudgetToString(internetBudget)} USD)`;
        }
        if (x === MediaTypeIds.RADIO) {
          return sadaicCategoryToDescriptionString(SadaicCategories.RESTRINGIDO_II_III);
        }
        return mediaIdToString(x);
      })
      .join(", ");
  };

  const canShowRegions = () => licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA || regions.length > 0;

  const makeRegionsContent = () => {
    if (licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA) {
      return regionIdToString(RegionIds.ARGENTINA);
    }
    if (regions.length === 0) {
      return "-";
    }
    return regions
      .map(region => {
        const regionAsString = regionIdToString(region.id);
        const showCountriesForRegion =
          region.id !== RegionIds.ARGENTINA && region.id !== RegionIds.NORTH_AMERICA && region.id !== RegionIds.GLOBAL;
        if (showCountriesForRegion) {
          return `${regionAsString} (${countriesForRegionToString(region.countriesForRegion)})`;
        }
        return regionAsString;
      })
      .join(", ");
  };

  const makeSadaicSidebarContent = () => {
    if (sadaicCategory === SadaicCategories.VPNT) {
      return makeSidebarElement(
        localization.get('license_wizard.campaign'),
        <div>
          <div>{`${localization.get('license_wizard.medias')}: ${sadaicCategoryToString(sadaicCategory)}`}</div>
          <div>{localization.get('license_wizard.3_year_life')}</div>
        </div>
      );
    }
    if (sadaicCategory === SadaicCategories.INCLUSION_EN_TV) {
      return makeSidebarElement(
        localization.get('license_wizard.campaign'),
        <div>
          <div>{`${localization.get('license_wizard.medias')}: ${sadaicCategoryToString(sadaicCategory)}`}</div>
          <div>{localization.get('license_wizard.1_year_life')}</div>
        </div>
      );
    }
    if (sadaicVersions.length === 0) {
      return null;
    }
    return (
      <div>
        {makeSidebarElement(
          localization.get('license_wizard.campaign'),
          <div>
            <div>{`${localization.get('license_wizard.medias')}: ${sadaicCategoryToDescriptionString(sadaicCategory)}`}</div>
            <div>{`${localization.get('license_wizard.life')}: ${sadaicMonthsToString(sadaicDuration)}`}</div>
            {makeRemovedFromPlatformMessage()}
          </div>
        )}
        {makeSidebarElement(
          localization.get('license_wizard.version'),
          <div>
            {sadaicVersions.length >= 3 && (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "6px",
                  textAlign: "center",
                  color: "black",
                  margin: "10px 0",
                }}
              >
                {sadaicVersions.length >= 6
                  ? localization.get('license_wizard.campaign_discount_x6')
                  : sadaicVersions.length >= 3
                    ? localization.get('license_wizard.campaign_discount_x3')
                    : null}
              </div>
            )}
            {sadaicVersions.map((version, i) => (
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>{version.label}</div>
                <div style={{ flex: 1, textAlign: "center" }}>{sadaicSecondsToString(version.seconds)}</div>
                <div style={{ flex: 1, textAlign: "right" }}>{version.reductions} {localization.get('license_wizard.reductions')}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const showVigency =
    _.intersection(medias, [MediaTypeIds.ALL_MEDIA, MediaTypeIds.TELEVISION, MediaTypeIds.RADIO]).length > 0;

  const showAdditionals = () =>
    exclusivity !== Exclusivity.NONE ||
    extraDuration ||
    editionAccordingToSeconds ||
    biChannel ||
    reductions > 0 ||
    unlimitedReductions ||
    showVigency;

  const showRemovedFromPlatformMessage = () => {
    switch (licenseType) {
      case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL:
        if (medias.length > 0 && medias.some(x => x !== MediaTypeIds.INTERNET)) {
          return true;
        }
        if (medias.length > 0 && medias.includes(MediaTypeIds.INTERNET) && exclusivity === Exclusivity.THREE_MONTHS) {
          return true;
        }
        return false;
      case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA:
        return true;
      default:
        return false;
    }
  };

  const makeRemovedFromPlatformMessage = () => {
    if (!showRemovedFromPlatformMessage()) return;
    let months = 6;
    if (extraDuration || sadaicDuration === SadaicDurations.TWELVE_MONTHS) months = 12;
    if (exclusivity === Exclusivity.THREE_MONTHS) months = 3;
    return <div>{localization.get('license_wizard.track_remove_from_platform', months)}</div>;
  };

  const makeAdditionalsContent = () => (
    <div>
      {showVigency && <div>{localization.get('license_wizard.life_in_months', 12)}</div>}
      {exclusivity === Exclusivity.FOREVER && <div>{localization.get('license_wizard.forever_exclusivity')}</div>}
      {exclusivity === Exclusivity.THREE_MONTHS && <div>{localization.get('license_wizard.3_months_exclusivity')}</div>}
      {makeRemovedFromPlatformMessage()}
      {editionAccordingToSeconds && <div>{localization.get('license_wizard.second_edition')}</div>}
      {biChannel && <div>BiCanal</div>}
      {reductions > 0 && <div>{reductions} {localization.get('license_wizard.reductions')}</div>}
      {unlimitedReductions && <div>{localization.get('license_wizard.unlimited_reductions')}</div>}
    </div>
  );

  const makeSidebarElement = (title, content) => (
    <div style={{ marginTop: "10px" }}>
      <div>{title}</div>
      <div style={{ fontSize: "12px", color: "#cacaca" }}>{content}</div>
    </div>
  );

  const makePreviewLicenseLink = () => (
    <div>
      {enableLicenseButton && <div style={{ height: 1, margin: "10px 0", backgroundColor: "white" }} />}
      <a
        href={licensePreviewUrl()}
        target="_blank"
        className={classNames("license-wizard-sidebar-preview-license-button", { disabled: !enableLicenseButton })}
      >
        {localization.get('license_wizard.see_license')}
      </a>
    </div>
  );

  return (
    <div className="license-wizard-sidebar">
      <div className="track-description">
        <img className="track-description-image" src={track.image} onError={onImageError} alt="" />
        <div className="track-description-right">
          <div style={{ float: "left", position: "relative", width: "100%" }}>
            <div style={{ float: "left", position: "relative", width: "auto", maxWidth: "calc(100% - 50px)" }}>
              {track.title}
            </div>
          </div>
          <img className="track-description-waveform" src={track.waveform} alt="" onError={onImageError}/>
        </div>
      </div>
      <div style={{ marginTop: "20px", overflowY: "auto", maxHeight: "360px" }}>
        {LicenseTypeIds.isLicenseType(licenseType) && makeSidebarElement(localization.get('license_wizard.use'), makeLicenseTypeContent())}
        {medias.length > 0 && makeSidebarElement(localization.get('license_wizard.medias'), makeMediasContent())}
        {canShowRegions() && makeSidebarElement(localization.get('license_wizard.regions'), makeRegionsContent())}
        {makeSadaicSidebarContent()}
        {showAdditionals() && makeSidebarElement(localization.get('license_wizard.additional'), makeAdditionalsContent())}
      </div>
      <div className="license-wizard-sidebar-bottom">
        {/*         
          {userIsSysAdmin() &&
            <>
              {enableLicenseButton &&
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginBottom: 10}}>
                  <div style={{fontSize: 12}}>{localization.get('license_wizard.especial_discount')}</div>
                  <div className="license-wizard-sidebar-price-value">
                    <span style={{fontSize: 22, color: "white"}}>
                        <input style={{background: "none", border: 0, color: "white", borderBottom: "1px solid white", webkitAppearance: "textfield"}} type={"number"} value={discount} onChange={e => onChangeDiscount(e.target.value)}/>
                      </span>
                  </div>
                </div>
              }
              <PlatformPricing prices={prices} discount={discount} data={{
                licenseType,
                reductions,
                unlimitedReductions,
                medias,
                internetBudget,
                regions,
                exclusivity,
                biChannel,
                editionAccordingToSeconds,
                extraDuration,
                client
              }}/>
            </>
          }
          <SadaicPricing
            prices={prices}
            versions={sadaicVersions}
            category={sadaicCategory}
            duration={sadaicDuration}
            discount={discount}
          /> 
        */}
        <div
          className={classNames("license-wizard-sidebar-license-button", { disabled: !enableLicenseButton })}
          onClick={onLicenseClick}
        >
          {licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL && !userIsSysAdmin() ? localization.get('license_wizard.get_quote') : localization.get('license_wizard.license')}
        </div>
      </div>
      <div className="license-wizard-close-button-main" onClick={onClose}>
        <Close style={{ color: "white" }} />
      </div>
    </div>
  );
};

const PlatformPricing = ({ prices, discount, data }) => {
  if (!prices) return null

  const { total } = new PriceCalculator(prices).calculate(data);
  const final = total - discount

  return <Pricing total={total} discount={discount} final={final} currency='USD'/>
}

const SadaicPricing = ({ prices, versions, category, duration, discount}) => {
  if (!prices) return null

  const calculator = new SadaicPriceCalculator(prices, versions, category, duration);
  const total = calculator.calculate();
  //const discounted = calculator.calculateSadaicDiscountedBasePriceFromVersions();
  const final = total - discount

  return <Pricing total={total} discount={discount} final={final} currency='ARS'/>
}

const Pricing = ({ total, discount, final, currency }) =>
  <React.Fragment>
    <Price
      price={total}
      text={localization.get('license_wizard.total')}
      currency={currency}
      hideWhen={() => total === final}
    />
    <Price
      price={-discount}
      text={localization.get('license_wizard.especial_discount') + ` (${(discount / total * 100).toFixed(2)}%)`}
      currency={currency}
      hideWhen={() => discount / total * 100 < 0 ||  discount / total * 100 > 100}
    />
    <Price
      price={final}
      text={localization.get('license_wizard.final')}
      currency={currency}
      alsoShowWhen={() => discount > 0 && final === 0}
      hideWhen={() => final<0}
    />
  </React.Fragment>

const Price = ({ price, text, currency, alsoShowWhen = () => false, hideWhen = () => false }) =>
  ((price !== 0 || alsoShowWhen()) && !hideWhen()) && (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginBottom: 10 }}>
      <div style={{ fontSize: 12 }}>{text}</div>
      <div className="license-wizard-sidebar-price-value">
        <span>{currency}</span> <span style={{ fontSize: 22, color: "white" }}>{Math.round(price)}</span>
      </div>
    </div>
  )

export default LicenseWizardSidebar;
