import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AlbumsTable from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {userIsArtist} from "../../../api-client/core/authentication/utils";
import {makeUrlWithTableParams} from "../../../utils";

class Artists extends Component {

  state = {
    artists: [],
    perPage: 20,
    page: 0,
  }

  componentDidMount = () => {
      document.title = localization.get('title.artists') + ' - Feater'
  }

  onAdd = () => this.props.history.push(makeUrlWithTableParams(`/panel/artists/create`))

  render = () =>
    <AllViewContainer
      onAdd={!userIsArtist() && this.onAdd}
      ref='viewContainer'>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <AlbumsTable {...this.props} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
}

export default Artists;