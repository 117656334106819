import React from "react"
import {Route, Router, Switch} from "react-router-dom"

import All from './All'
import Create from './Create'
import Update from './Update'
import HomeEditor from "../HomeEditor";

const ClientsRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route path="/panel/clients/create" component={Create} />
        <Route path="/panel/clients/:id/edit" component={Update} />
        <Route path="/panel/clients/:id/home/edit" render={props => <HomeEditor {...props} clientId={props.match.params.id}/>} />
        <Route component={All} />
      </Switch>
    </Router>
  )
}

export default ClientsRouting
