import React from "react"
import {Route, Router, Switch} from "react-router-dom"
import All from "./All";

const PeopleRouting = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route render={props => (<All {...props}/>)} />
      </Switch>
    </Router>
  )
}

export default PeopleRouting
