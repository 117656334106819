import React from "react";
import {request} from "../../../api-client";
import noImage from '../../../assets/img/Letflow/no-image.jpg'
import ItemView, {ItemInfo,} from './../HomeItemView'
import TrackTable from "../../../components/Letflow/TrackTable";
import TrackResult from "../../../components/Letflow/TrackResult";
import {compareValues} from "../../../utils";
import {
  channelPathGenerator,
  getActiveChannel,
  getActiveClient,
  setPageTitle
} from "../../../api-client/core/authentication/utils";
import {makeImageUrlWithSize} from "../HomeElements/utils";
import browserHistory from "../../../utils/browserHistory";

class Album extends React.Component {

  state = {
    album: undefined,
    playlists: [],
    loading: true,
    loadingFailed: false,
    showAddToPlaylistDialog: false,
    tags: [],
    selectedTags: [],
    favorite: false
  }


  componentDidMount = () => {
    this.requestData(this.props.match.params.albumId || this.props.albumId)

    if(!this.props.modal) {
      window.scrollTo(0,0);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.match.params !== this.props.match.params && !this.props.modal) {
      const { albumId } = nextProps.match.params
      this.requestData(albumId)
    }
  }


  getTagsFromTracks = (tracks) => {
    let tags = [];
    if (tracks && tracks.length > 0) {
      tracks.forEach((track) => {
        track.tags.forEach((tag) => {
          if(!tags.find(t => t.id === tag.id)){
            tags.push(tag)
          }
        })
      })
    }
    tags = tags.sort(compareValues('name', 'asc'))
    return tags
  }

  requestData = (albumId) => {
    this.setState({ loading: true })
    this.requestAlbum(albumId)
      .then(() => {
        this.setState({
          loading: false,
          loadingFailed: false
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
          loadingFailed: true
        })
      })
  }

  requestAlbum = albumId => {
    return (
      request.album.getForClient(albumId, `include=image;${getActiveChannel() ? "channelFavorite" : "favorite"};artist`)
        .then(album =>
          request.music.getAllByAlbumForClient(getActiveClient(), albumId, `include=recentLicenses;audio:duration,url,id;tags;audio.waveform;${getActiveChannel() ? "channelFavorite" : "favorite"};artist;versions_count`, album.type === 'library' ? 'created_at' : null, album.type === 'library' ? 'desc' : null)
            .then(musics => {
              this.setState({ album: { ...album, musics }, favorite: getActiveChannel() ? album.channelFavorite : album.favorite  })
              setPageTitle(`${album.title}`)
            })
            .then(() => this.setState({ tags: this.getTagsFromTracks(this.state.album.musics) }))
        )
    )
  }

  addToFavorites = (id) => {
    this.setState({favorite: true})

    request.albumChannelFavorite.add(id).catch(() => this.setState({favorite: false}))
  }

  removeFromFavorites = (id) => {
    this.setState({favorite: false})

    request.albumChannelFavorite.remove(id).catch(() => this.setState({favorite: false}))  
  }

  updateTags = (tags, selectedTags) => {
    return tags.map(tag => ({
      ...tag,
      state: selectedTags.find(selectedTag => selectedTag.toString() === tag.id.toString()) ? 'selected' : 'available'
    }))
  }

  onSelectedTagsChanged = selectedTags => {
    this.setState({ selectedTags, tags: this.updateTags(this.state.tags, selectedTags) })
  };

  renderItemInfo = () =>
    <ItemInfo
      id={this.state.album.id}
      clientId={getActiveClient()}
      image={this.state.album.image ? request.file.image.makeCroppedUrl(this.state.album.image.id, 400, 400, 'album', 'jpg') : noImage}
      title={this.state.album.title}
      artist={this.state.album.artist && this.state.album.artist}
      description={this.state.album.long_description}
      tags={this.state.tags}
      onSelectedTagsChanged={this.onSelectedTagsChanged}
      singleTagSelection={true}
      isAlbum={true}
      favorite={this.state.favorite}
      onAddFavorite={() => this.addToFavorites(this.state.album.id)}
      onRemoveFavorite={() => this.removeFromFavorites(this.state.album.id)}
      modal={this.props.modal}
      onHandleClose={!this.props.modal ? () => browserHistory.push(channelPathGenerator('')) : this.props.onModalClose}
      {...this.props}
    />

  renderItemTable = () => {

    const album = this.state.album
    const {selectedTags} = this.state

    const tracksFiltered = album.musics.filter(track => selectedTags.every(selectedTag => track.tags.map(tag => tag.id).includes(selectedTag)))

    return (
      <TrackResult
        rows={<TrackTable {...this.props} fromHome={false} hideImage={true} channel={this.props.channel} trackContainer={{id: album.id, title: album.title, image: album.image}} type="music" tracks={selectedTags.length > 0 ? tracksFiltered : album.musics}/>}
      />
    )
  }


  render = () => {
    if (this.state.loading) {
      return null
    } else if (this.state.loadingFailed) {
      return null
    } else {
      return (
        <div style={{ height: '100%' }}>
          <ItemView
            containerImage={makeImageUrlWithSize(this.state.album, 'lg')}
            renderItemInfo={this.renderItemInfo}
            renderItemTable={this.renderItemTable}
          />
        </div>
      )
    }
  }
}

export default Album;
