import React, {Component} from "react";
import {ServerSidePaginationTable} from "../../components/Letflow/Table";
import {request} from "../../api-client";
import localization from "../../config/localization";
import moment from 'moment';
import {Tooltip} from '@material-ui/core';
import {ROLES} from "../../api-client/core/authentication/utils";

const actions = ['created', 'updated', 'deleted']

const getActionName = actionId => localization.get(`audit_log.${actionId}`)

const getModelName = modelType => {
  var i = modelType.lastIndexOf("\\");
  var name = modelType.substring(i + 1);
  var key = `audit_log.${name.toLowerCase()}`;

  return localization.has(key) ? localization.get(key) : name;
}

const getModelTip = model => {
  if (model) {
    return model.name || model.title || model.description;
  }
  return null;
}

const getClientName = log => {
    if (log.client) {
        return log.client.name;
    } else if (log.user && log.user.role) {
        if ([ROLES.SUPERVISOR, ROLES.SYS_ADMIN, ROLES.MULTI_CLIENT].includes(log.user.role.id)) {
            return 'Flowlike';
        }
    }
    return '';
}

class AuditLogsTable extends Component {
  state = {
    modelTypes: []
  };

  componentDidMount = () => {
    request.auditLog.getModelTypes().then(modelTypes => this.setState({ modelTypes }));
  };

  tooltip = (title, value, placement = 'right') => title ? <Tooltip title={title} placement={placement}><span>{value}</span></Tooltip> : value;

  columns = () => [
    {
      id: "created_at",
      Header: localization.get("audit_log.date"),
      accessor: log => moment(log.created_at).format("DD-MM-YYYY"),
      resizeable: false,
      sortable: true,
      filterable: false,
      width: 100
    },
    {
      id: "user[name]",
      Header: localization.get("table.user"),
      accessor: log => log.user ? this.tooltip(log.user.id, log.user.name) : '',
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value ? `like:${event.target.value}` : '')}
          placeholder="Buscar"
        />
      ),
      sortable: false,
    },
    {
      id: "client[name]",
      Header: localization.get("table.client"),
      accessor: log => this.tooltip(log.client ? log.client.id : null, getClientName(log)),
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value ? `like:${event.target.value}` : '')}
          placeholder="Buscar"
        />
      ),
      sortable: false,
    },
    {
      id: "ip",
      Header: localization.get("table.ip"),
      accessor: 'ip',
      resizeable: true,
      sortable: true,
      filterable: true,
      width: 130
    },
    {
      id: "action",
      Header: localization.get("audit_log.action"),
      accessor: log => getActionName(log.action),
      Filter: ({filter, onChange}) =>
        <select style={{width: "100%"}} onChange={event => onChange(event.target.value)} value={filter ? filter.value : ""}>
          <option value=""/>
          {actions.map(actionId => {
            return <option key={actionId} value={actionId}>{getActionName(actionId)}</option>
          })}
        </select>
    },
    {
      id: "model_type",
      Header: localization.get("audit_log.model_type"),
      accessor: log => getModelName(log.model_type),
      Filter: ({ filter, onChange }) => (
        <select
          style={{ width: "100%" }}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ""}
        >
          <option value="" />
          {this.state.modelTypes.map(modelType => <option key={modelType} value={modelType}>{getModelName(modelType)}</option>)}
        </select>
      ),
    },
    {
      id: "model_id",
      Header: localization.get("audit_log.model_id"),
      accessor: log => this.tooltip(getModelTip(log.model), log.model_id, "left"),
      width: 100,
      style: {
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  render = () =>
    <ServerSidePaginationTable
      ref="table"
      columns={this.columns()}
      requestTableData={paginationConfig => request.auditLog.getAll(paginationConfig)}
      defaultPageSize={20}
      showPaginationTop
      showPaginationBottom={false}
      showPageSizeOptions={false}
    />
}

export default AuditLogsTable;
