import {isNullOrUndefined} from "util";

export const makeFormData = (translations, types, order, inheritable, selectedTypes, creation_group, clientId, isAdmin, childrensIds) => {
  const form = new FormData()
  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.name)) form.append(`translations[${translation.language}][name]`, translation.name)
    })
  }
  if (selectedTypes.length > 0) {
    selectedTypes.forEach(type => form.append('types[]', type.value))
  }else {
    form.append('types[]', selectedTypes.value)
  }

  if (order) form.append('order', order)
  if (!isNullOrUndefined(isAdmin)) form.append('is_admin', isAdmin ? 1 : 0)
  if (!isNullOrUndefined(creation_group)) form.append('creation_group', creation_group ? 1 : 0)
  form.append('inheritable', inheritable ? 1 : 0)
  if (clientId) form.append('client_id', clientId)

  if (childrensIds.length > 0) {
    childrensIds.map((children) => form.append(`children_ids[]`, children))
  } else {
    form.append('children_ids', 'empty')
  }

  return form
}

export const root = '/tagGroups'

export const includeQuery = 'include=tags.parents;types.contentCreatorType;parent;childrens'