import React, {Component} from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core';
import {Close, Done} from '@material-ui/icons'
import localization from '../../../../../config/localization';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../../../../components/Letflow/Snackbar/GlobalSnackbar';

class HomeBannerElementForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: props.active ? true : props.title ? false : true ,
      title: props.title || '',
      subtitle: props.subtitle || '',
      image: {
        src: props.image || null,
        file: null
      },
      video: {
        src: props.video || null,
        file: null
      },
      preview: {
        src: props.preview || null,
        file: null
      },
      submitting: false,
    }
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value })

  handleUploadProgress = (current, total) => this.setState({ uploadProgress: Math.floor(current / total * 100) })

  handleSubmit = () => {
    this.setState({ submitting: true })
    const { active, title, subtitle, image, video, preview } = this.state
    this.props.submitRequest(active, title, subtitle, image.file, video.file, preview.file, this.handleUploadProgress)
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.banner_updated'),
          type: GlobalSnackbarTypes.SUCCESS,
        })
        this.setState({ submitting: false })
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.banner_update_failed'),
          type: GlobalSnackbarTypes.ERROR,
        })
        this.setState({ submitting: false })
      })
  }

  render = () => {

    let videoInput
    let previewInput

    const onFileSelected = property => e => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      if((property != "preview" && file.size > 300000000) || (property == "preview" && file.size > 10000000)) {
          GlobalSnackbar.show({
            message: localization.get('home_editor.video_banner_max_size', property == "preview" ? "10" : "300" ),
              type: GlobalSnackbarTypes.ERROR,
              topDistance: 100,
          })
      } else {
          this.setState({ [property]: { src: null, file: null } })
          reader.onloadend = () => {
              this.setState({ [property]: { src: reader.result, file } })
          }
          reader.readAsDataURL(file);
      }

    }
    return (
      <Dialog style={{margin: "-35px"}} open={true}>
        <DialogTitle>
          <Typography style={{fontSize: "24px"}}>{localization.get('banners.new_banner')}</Typography>
        </DialogTitle>
        <DialogContent style={{ width: '500px', maxWidth: '100%'}}>
          <FormControl style={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.active}
                  onChange={() => {
                    this.setState({ active: !this.state.active })
                  }}
                  style={{color: "black"}}
                />
              }
              label={localization.get('form.active')}
            />
            <TextField id='title' label={localization.get('form.title')} value={this.state.title} onChange={this.handleChange} disabled={this.state.submitting} />
            <TextField style={{ marginTop: '10px' }} id='subtitle' label={localization.get('form.subtitle')} value={this.state.subtitle} onChange={this.handleChange} disabled={this.state.submitting} />
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <input type='file' accept='video/mp4,video/x-m4v,video/*' style={{ display: 'none' }} ref={r => videoInput = r} onChange={onFileSelected('video')} />
              <Button onClick={() => videoInput.click()} disabled={this.state.submitting}>{localization.get('form.select_video')}</Button>
            </div>
            {this.state.video.src && <video width='100%' controls>
              <source src={this.state.video.src} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>}
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <input type='file' accept='video/mp4,video/x-m4v,video/*' style={{ display: 'none' }} ref={r => previewInput = r} onChange={onFileSelected('preview')} />
              <Button onClick={() => previewInput.click()} disabled={this.state.submitting}>{localization.get('form.select_video_preview')}</Button>
            </div>
            {this.state.preview.src && <video width='100%' controls>
              <source src={this.state.preview.src} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {this.state.submitting && <Typography style={{ position: 'absolute', left: '20px' }}>{localization.get('form.uploading')}: {this.state.uploadProgress || 0}%</Typography>}
          <Button onClick={this.props.onClose} disabled={this.state.submitting}><Close /></Button>
          <Button onClick={this.handleSubmit} disabled={this.state.submitting}><Done /></Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default HomeBannerElementForm