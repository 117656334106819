import React from "react";
import {ImagePicker} from "components/Letflow/Form";
import PropTypes from "prop-types";
import localization from "../../../config/localization";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import {Close as Cancel, Style} from "@material-ui/icons";
import {
  getStoredUser,
  ROLES,
  userCanCreateUserWithClientRole,
  userCanCreateUserWithGeneralRole, userisMultiClient,
  userIsSupervisor, userIsSysAdmin,
} from "../../../api-client/core/authentication/utils";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {request} from "../../../api-client";
import AutoCompleteSelect from "react-select";
import AsyncSelect from "react-select/lib/Async";
import AddressDialog from "../../../components/Letflow/AddressInput/AddressDialog";
import Button from "../../../components/CustomButtons/Button";
import AvailableLanguages from '../../../config/localization/availableLanguages.json';
import {get} from "lodash";

/**
 * roles deberia ser un array de roles con id y typo (general, cliente)
 */
const UsersFormView = ({
  name = "",
  email = "",
  description = "",
  client = "",
  roleInClient = ROLES.CLIENT_ADMIN,
  represents = [],
  clients = [],
  image,
  onChange,
  onImageChange,
  roles = [],
  roleId = "",
  language,
  selectedArtists = [],
  isArtist = "",
  disabled = false,
  canLicense = false,
  onIsArtistChange,
  onCanLicenseChange,
  canCreateClients = false,
  onCanCreateClientsChange,
  onAsyncArtistChange,
  canSeeLicenses,
  onCanSeeLicensesChange,
  onClientChange,
  onSoundProducerChange,
  onAsyncCloudsChange,
  selectedClouds,
  onCatalogsChange,
  selectedCatalogs,
  canCreateContentCreators,
  onCanCreateContentCreatorsChange,
  onCanEditAdminsChange,
  canEditAdmins,
  onChannelChange,
  selectedChannels,
  isChannel,
  onClientForEditorChange,
  clientForEditor,
  providers,
  mibaId,
  gcbaId,
  showAddressDialog,
  handleAddressDialog,
  address,
  onAddressChange,
  canAccessClients,
  canAccessRanking,
  canAccessConfig,
  canAccessLicense,
  canAccessReports,
  canAccessMusic,
  createPassword,
  password,
  creating =false,
  selectedBrand,
  onBrandChange,
  accessLevel,
  canEditContentCreators,
  identityNumber,
  openRestrictAccessDialog
}) => {

  ValidatorForm.addValidationRule('clientExist', () => represents.length !== 0)

  return (
    <div>
      <AddressDialog
        showDialog={showAddressDialog}
        handleCloseDialog={handleAddressDialog}
        onSubmit={(address) => onAddressChange(address)}
        address={address}
        isDashboard
      />
      <TextValidator
        style={{ width: "100%" }}
        id="name"
        label={localization.get("form.name")}
        name="name"
        value={name}
        onChange={onChange}
        disabled={disabled}
        validators={["required"]}
        errorMessages={[localization.get("validator.is_required")]}
      />
      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="email"
        label={localization.get("form.email")}
        name="email"
        value={email}
        onChange={onChange}
        disabled={disabled}
        validators={providers === "default" ? ["required", "isEmail"] : []}
        errorMessages={[localization.get("validator.is_required"), localization.get("validator.is_email")]}
      />

      <br />
      <br />
      <TextValidator
        style={{ width: "100%" }}
        id="identityNumber"
        label={localization.get("form.identity_number")}
        name="identityNumber"
        value={identityNumber}
        onChange={onChange}
        disabled={disabled}
      />

      <br />
      <br />
      <FormControl fullWidth>
        <SelectValidator
          label={localization.get("form.language")}
          id="language"
          name="language"
          value={language}
          onChange={e => {
            e.target.id = "language";
            onChange(e);
          }}
          validators={["required"]}
          errorMessages={[localization.get("validator.is_required")]}
        >
          {AvailableLanguages.map(lang => <MenuItem value={lang}>{localization.get(`language.${lang}`)}</MenuItem>)}
        </SelectValidator>
      </FormControl>
      <br />
      <br />
      {!isChannel &&
        <>
          <FormControl fullWidth>
            <SelectValidator
              label={localization.get("form.role")}
              id="roleId"
              name="roleId"
              value={roleId}
              onChange={e => {
                e.target.id = "roleId";
                onChange(e);
                onSoundProducerChange('');
              }}
              validators={["required"]}
              errorMessages={[localization.get("validator.is_required")]}
            >
              {roles
                .filter(role => role.type === "general")
                .filter(role => role.id !== 9) //remove artists
                .filter(role => role.id !== 1) //remove sysadmin
                .filter(role => userCanCreateUserWithGeneralRole(role.id))
                .sort((a, b) => (a.order > b.order) ? 1 : -1)
                .map(role => (
                  <MenuItem value={role.id} key={role.id}>
                    {role.description}
                  </MenuItem>
                ))}
            </SelectValidator>
          </FormControl>
          <br/>
          <br/>
        </>
      }

      { [ROLES.supervisorId, ROLES.sysAdminId].includes(roleId) &&
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={isArtist}
                onChange={() => {
                  onIsArtistChange(!isArtist);
                }}
              />
            }
            label={localization.get("form.is_artist")}
          />
      }

      {((isArtist && [ROLES.supervisorId, ROLES.sysAdminId].includes(roleId)) || roleId === ROLES.artistId) &&
        <React.Fragment>
          <FormControl fullWidth>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={onAsyncArtistChange}
              value={selectedArtists}
              isMulti
              defaultOptions
              loadOptions={inputValue =>
                request.artist
                  .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                  .then(res => res.map(artist => ({ value: artist.id, label: `${artist.name}` })))
              }
            />
          </FormControl>
          <br />
          <br />
        </React.Fragment>
      }

      {(roleId === ROLES.contentCreatorId || roleId === ROLES.contentSupervisorId) &&
        <React.Fragment>
          {roleId === ROLES.contentCreatorId &&
          <>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canCreateContentCreators}
                  onChange={() => {
                    onCanCreateContentCreatorsChange(!canCreateContentCreators);
                  }}
                />
              }
              label={localization.get("form.can_create_content_creators")}
            />
            <br/>

            <FormControl fullWidth>
              <span>{localization.get('form.catalogs.content_creator')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onCatalogsChange}
                value={selectedCatalogs}
                isDisabled={!canCreateContentCreators}
                isMulti
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}];
                  if (userIsSysAdmin() || userIsSupervisor()) {
                    filterBy = filterBy.concat([{column: "created_by_admin", filter: true}])
                  }
                  return request.catalog
                    .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                    .then(res => res.map(catalog => ({
                      value: catalog.id,
                      label: `${catalog.name} (${localization.get(catalog.visibility)})`
                    })))
                }}
                defaultOptions
              />
            </FormControl>
            <br/>
            <br/>

          </>
          }

          {roleId === ROLES.contentSupervisorId &&
          <>
            <FormControl fullWidth>
              <span>{localization.get('form.client')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('conversation.filter_users')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onClientForEditorChange}
                value={clientForEditor}
                loadOptions={(inputValue, callback) => {
                  callback(clients.map(client => ({value: client.id, label: client.name, hasAccessLevel: client.has_access_level})).filter(i =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                  ))
                }}
                defaultOptions={clients.map(client => ({value: client.id, label: client.name, hasAccessLevel: client.has_access_level}))}
              />
            </FormControl>
            {get(clientForEditor, "hasAccessLevel") &&  accessLevelForm({accessLevel, onChange})}
          </>
          }

          <br/>
          {clientForEditor &&
            <FormControl fullWidth>
              <br/>
              <span>{localization.get('clouds')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('clouds')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onAsyncCloudsChange}
                value={selectedClouds}
                isMulti
                defaultOptions
                loadOptions={inputValue =>
                  request.clouds.getAll({
                    filterBy: [
                      {column: "name", filter: inputValue},
                      {column: "client_id", filter: clientForEditor.value}
                    ]
                  })
                    .then(res => res.map(contentCreator => ({value: contentCreator.id, label: contentCreator.name})))
                }
              />
            </FormControl>
          }
          <br />

        </React.Fragment>
      }

      {(userIsSysAdmin() || (userisMultiClient() && getStoredUser().can_edit_admins)) && [ROLES.multiClientId].includes(roleId) &&
        <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)"}}>
          <h4>{localization.get('form.permissions')}</h4>
          {(userIsSysAdmin() || getStoredUser().can_access_clients) &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canAccessClients}
                  onChange={() => onChange({target: {id: "canAccessClients", value: !canAccessClients}})}
                />
              }
              label={localization.get("form.can_access_clients")}
            />
          }
          {(userIsSysAdmin() || getStoredUser().can_create_clients) &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canCreateClients}
                  onChange={() => onCanCreateClientsChange(!canCreateClients)}
                />
              }
              label={localization.get("form.can_create_clients")}
            />
          }
          {(userIsSysAdmin() || getStoredUser().can_access_music) &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canAccessMusic}
                  onChange={() => {
                    onChange({target: {id: "canAccessMusic", value: !canAccessMusic}})
                    onChange({target: {id: "canAccessReports", value: !canAccessMusic}})
                    onChange({target: {id: "canAccessLicense", value: !canAccessMusic}})
                    onChange({target: {id: "canAccessRanking", value: !canAccessMusic}})
                    onCanSeeLicensesChange(!canAccessMusic)
                  }}
                />
              }
              label={localization.get("form.can_access_music")}
            />
          }

          {(userIsSysAdmin() || getStoredUser().can_access_reports) &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canAccessReports}
                  onChange={() => {
                    onChange({target: {id: "canAccessReports", value: !canAccessReports}})
                    onCanSeeLicensesChange(!canAccessReports)
                  }}
                />
              }
              label={localization.get("form.can_access_reports")}
            />
          }

          {(userIsSysAdmin() || getStoredUser().can_access_config) &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={canAccessConfig}
                  onChange={() => onChange({target: {id: "canAccessConfig", value: !canAccessConfig}})}
                />
              }
              label={localization.get("form.can_access_config")}
            />
          }
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={canEditAdmins}
                onChange={() => onCanEditAdminsChange(!canEditAdmins)}
              />
            }
            label={localization.get("form.can_edit_admins")}
          />
          {roleId === ROLES.USER &&
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#4cc2d5" }}
                    checked={canLicense}
                    onChange={() => {
                      onCanLicenseChange(!canLicense);
                    }}
                  />
                }
                label={localization.get("form.can_license")}
              />
              <br />
              <br />
            </div>
          }
        </div>
      }

      {(roleId === ROLES.MULTI_CLIENT) && (
        <div>
          <Typography variant="title" style={{ marginBottom: 10 }}>
            {localization.get('user.represented_clients')}
          </Typography>
          <table style={{ width: "100%" }}>
            {represents.map(represented => (
              <tr>
                <td>
                  <Typography>{represented.name}</Typography>
                </td>
                <td>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#4cc2d5" }}
                        checked={represented.can_license}
                        onChange={() => {
                          represented.can_license = !represented.can_license;
                          const e = {
                            target: {
                              id: "represents",
                              value: represents,
                            },
                          };
                          onChange(e);
                        }}
                        disabled={represented.role_id !== ROLES.USER}
                      />
                    }
                    label={localization.get("form.can_license")}
                  />
                </td>
                <td>
                  <FormControl>
                    <InputLabel>{localization.get('user.client_role')}</InputLabel>
                    <Select
                      SelectDisplayProps={{ style: { minWidth: 100 } }}
                      id="represents"
                      value={represented.role_id}
                      disabled={true}
                      onChange={e => {
                        const role_id = e.target.value;
                        if (role_id === ROLES.READ_ONLY) {
                          represented.can_license = false;
                        }
                        if (role_id === ROLES.CLIENT_ADMIN) {
                          represented.can_license = true;
                        }
                        represented.role_id = role_id;
                        e.target.value = represents;
                        onChange(e);
                      }}
                    >
                      {roles
                        .filter(role => role.type === "client")
                        .filter(role => userCanCreateUserWithClientRole(represented.client_id, role.id))
                        .map(role => (
                          <MenuItem value={role.id}>{role.description}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </td>
                <td>
                    <IconButton
                    style={{float: "right", color: represented.canEdit ? "rgba(0, 0, 0, 0.54)" : "#ccc",}}
                    onClick={e => {
                      if (represented.canEdit) {
                        e.target.id = "represents";
                        e.target.value = represents.filter(x => x.client_id !== represented.client_id);
                        onChange(e);
                      }
                    }}
                  >
                    <Cancel/>
                  </IconButton>
                </td>
              </tr>
            ))}
          </table>
          {(() => {
            const clientsNotRepresented = clients.filter(client => represents.every(represented => client.id !== represented.client_id))
            if (clientsNotRepresented.length === 0) {
              return null
            }
            return (
              <div style={{marginTop: "10px"}}>

                <AutoCompleteSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('user.add_client')}
                  value={''}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={client => {
                    client.value = represents.concat([
                      {
                        client_id: client.value,
                        name: clients.find(c => c.id === client.value).name,
                        can_license: true,
                        role_id: ROLES.CLIENT_ADMIN,
                        canEdit: true
                      },
                    ]);
                    onChange({target: {id: 'represents', value: client.value}});
                  }}
                  options={clientsNotRepresented.map(client => ({value: client.id, label: client.name}))}
                />
              </div>
            );
          })()}
        </div>
      )}


      {roleId && roleId === ROLES.SINGLE_CLIENT && (
        <div>
          <div style={{ marginRight: 10, minWidth: 400 }}>

            <AutoCompleteSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('user.add_client')}
              value={client}
              noOptionsMessage={() => localization.get('no_options')}
              onChange={e => onClientChange(e)}
              options={clients.map(client => ({value: client.id, label: client.name, hasAccessLevel: client.has_access_level}))}
            />

          </div>

          {client && (
            <React.Fragment>
              <br />
              <div style={{ marginRight: 10 }}>
                <FormControl fullWidth>
                  <SelectValidator
                    label={localization.get('user.client_role')}
                    style={{ width: "100%" }}
                    name="represents"
                    value={roleInClient}
                    onChange={e => {
                      e.target.id = "roleInClient";

                      if (e.target.value === ROLES.READ_ONLY) {
                        onCanLicenseChange(false);
                      }

                      if (e.target.value === ROLES.MUSIC_SUPERVISOR) {
                        onCanLicenseChange(true);
                      }
                      
                      onChange(e);
                    }}
                    validators={represents.length === 0 && ['required']}
                    errorMessages={represents.length === 0 && [localization.get('validator.is_required')]}
                  >
                    {roles
                      .filter(role => role.type === "client")
                      .filter(role => userCanCreateUserWithClientRole(client.value, role.id))
                      .map(role => (
                        <MenuItem value={role.id}>{role.description}</MenuItem>
                      ))}
                  </SelectValidator>
                </FormControl>
              </div>
            </React.Fragment>
          )}
          {roleInClient === ROLES.USER && roleInClient !== ROLES.MUSIC_SUPERVISOR &&
            <React.Fragment>
              <br />
              <CanLicenseCheckbox
                canLicense={canLicense}
                onCanLicenseChange={onCanLicenseChange}
                roleId={roleInClient}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#4cc2d5" }}
                    checked={canEditContentCreators}
                    onChange={() => {
                      onChange({target: {id: "canEditContentCreators", value: !canEditContentCreators}});
                    }}
                  />
                }
                label={localization.get("form.can_edit_content_creators")}
              />
              <br />
            </React.Fragment>
          }
          
          {get(client, "hasAccessLevel") && (roleInClient !== ROLES.CLIENT_ADMIN || ROLES.contentSupervisorId == roleId) && accessLevelForm({accessLevel, onChange})}
            <FormControl fullWidth >
              <br/>
              <span>{localization.get('brands')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                style={{ width: "100%" }}
                placeholder={localization.get('brands')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={onBrandChange}
                value={selectedBrand}
                loadOptions={inputValue => {
                  let filterBy = [{column: "name", filter: inputValue}];
                  
                  return request.brands.getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                    .then(res => res.map(channel => ({value: channel.id, label: channel.name, purpose: channel.purpose})))
                }}
                defaultOptions
              />
              <span>{localization.get('brand.disclaimer')}</span>
            </FormControl>
        </div>
      )}

      <br />
      {roleId && ![ROLES.supervisorId, ROLES.sysAdminId].includes(roleId) &&
      <React.Fragment>
        <FormControl fullWidth>
          <span>{localization.get('form.catalogs.channel')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            onChange={onChannelChange}
            value={selectedChannels}
            isMulti
            loadOptions={inputValue => {
              let filterBy = [{column: "name", filter: inputValue}];
              if (userIsSysAdmin() || userIsSupervisor()) {
                filterBy = filterBy.concat([{column: "created_by_admin", filter: true}])
              }

              return request.channel
                .getAll({filterBy, orderBy: {column: "name", type: "ASC"}})
                .then(res => res.map(channel => ({value: channel.id, label: channel.name, purpose: channel.purpose, restricted:false, restrictedTags: []})))
            }}
            defaultOptions
          />
        </FormControl>
        <br/>
        <br/>
      </React.Fragment>
      }

      {!isChannel &&
      <>
        <>
          <span style={{color: "#97877f"}}>{localization.get('form.provider')}</span>
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={true}
                disabled={true}
              />
            }
            label={localization.get("default")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={providers.includes("miba")}
                onChange={() => {
                  if (providers.includes("miba")) {
                    providers = providers.filter(p => p !== "miba")
                  }else {
                    providers.push("miba")
                  }

                  const e = {
                    target: {
                      id: "providers",
                      value: providers,
                    },
                  };
                  onChange(e);
                }}
              />
            }
            label={"MiBA"}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#4cc2d5" }}
                checked={providers.includes("gcba")}
                onChange={() => {
                  if (providers.includes("gcba")) {
                    providers = providers.filter(p => p !== "gcba")
                  }else {
                    providers.push("gcba")
                  }

                  const e = {
                    target: {
                      id: "providers",
                      value: providers,
                    },
                  };
                  onChange(e);
                }}
              />
            }
            label={"GCBA"}
          />
          {providers.includes( "miba") &&
          <TextValidator
            style={{width: "100%"}}
            id="mibaId"
            label={"MiBA ID"}
            name="mibaId"
            value={mibaId}
            onChange={onChange}
            disabled={disabled}
            helperText={localization.get("form.helper_update_provider")}
          />
          }
          {providers.includes("gcba") &&
          <TextValidator
            style={{width: "100%"}}
            id="gcbaId"
            label={"GCBA ID"}
            name="gcbaId"
            value={gcbaId}
            onChange={onChange}
            disabled={disabled}
            helperText={localization.get("form.helper_update_provider")}
          />
          }
        </>


        <ImagePicker
          buttonText={localization.get("form.select_image")}
          image={image != null ? image.src : null}
          onImageChange={onImageChange}
          disabled={disabled}
        />
        <div style={{
          color: "#757575",
          textAlign: 'center',
          marginTop: 5
        }}>{localization.get('helper_text.user_image')}</div>
      </>
      }
      {isChannel &&
        <div style={{display: "flex", flexDirection: "column" }}>
          <table>
            {selectedChannels && selectedChannels.filter(c => c.purpose == "cms")
            .map(channel => (
              <tr>
                <td >
                  <Typography>{channel.label}</Typography>
                </td>
                <td>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={channel.restricted}
                        onChange={() => {
                          channel.restricted = !channel.restricted;

                          onChange({target: {id: "selectedChannels", value: selectedChannels}});
                        }}
                      />
                    }
                    label={localization.get("form.has_restricted_access")}
                  />
                </td>
                <td style={{display: "flex", justifyContent: "end"}}>
                  <IconButton disabled={!channel.restricted} onClick={() => openRestrictAccessDialog(channel)}>
                    <Tooltip
                      title={localization.get('edit')}
                      placement='top'>
                      <Style />
                    </Tooltip>
                  </IconButton>
                </td>
              </tr>
            ))}
          </table>
          <br/>
          <br/>
        <Button
          style={{
            padding: 8,
            textTransform: "capitalize",
            marginLeft: 5
          }}
          color={"dark"}
          onClick={handleAddressDialog}
          size="sm"
        >
          {address ? localization.get("form.address.update") : localization.get("form.address.new")}
        </Button>

        </div>
      }
      {creating &&
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={createPassword}
                onChange={() => {
                  if (createPassword) {
                    onChange({target: {id: "password", value: ""}});
                  }
                  onChange({target: {id: "createPassword", value: !createPassword}});
                }}
              />
            }
            label={localization.get("form.create_password")}
          />
        }
      {createPassword &&
        <TextValidator
        style={{width: "100%"}}
        id="password"
        label={localization.get("form.password")}
        name="password"
        value={password}
        onChange={onChange}
        disabled={disabled}
        />
      }
    </div>
  );
};

export const CanLicenseCheckbox = ({ canLicense, onCanLicenseChange, roleId, checkboxStyle = {} }) => (
  <FormControlLabel
    control={
      <Checkbox
        style={{ color: "#4cc2d5", ...checkboxStyle }}
        checked={canLicense}
        onChange={() => {
          onCanLicenseChange(!canLicense);
        }}
        disabled={roleId !== ROLES.USER}
      />
    }
    label={localization.get("form.can_license")}
  />
);

export const accessLevelForm = ({accessLevel, onChange}) =>
  <FormControl>
    <h4>{localization.get('form.access_level')}</h4>
    <RadioGroup
      name="accessLevel"
      id="accessLevel"
      value={accessLevel}
      onChange={e => onChange({target: {id: "accessLevel", value: e.target.value}})}
      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
    >
      <FormControlLabel
          value={"0"}
          control={<Radio color="default"/>}
          label={localization.get('smart_search.none')}
        />
      {["1","2","3","4","5"].map(level =>
        <FormControlLabel
          value={level}
          control={<Radio color="default"/>}
          label={level}
        />
      )}
    </RadioGroup>
  </FormControl>
  
  
UsersFormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  roleId: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default UsersFormView;
