import React, {Component} from 'react'
import {Card, CardActions, CardContent, IconButton, Typography,} from '@material-ui/core';
import {Delete, Edit, Videocam} from '@material-ui/icons';
import {getPropertySafe} from '../../../utils';
import HomeBannerElementVideoDialog from './HomeBannerElementVideoDialog';
import localization from '../../../config/localization';
import HomeBannerElementForm from './HomeBannerElementForm';
import {request} from '../../../api-client';
import GlobalConfirmationDialog from '../../../components/Letflow/Dialog/GlobalConfirmationDialog';
import AvailableLanguages from "../../../config/localization/availableLanguages.json";
class HomeBannerElement extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
      showForm: false,
    }
  }

  makeVideoDialog = () => this.state.showVideo && <HomeBannerElementVideoDialog
    videoUrl={this.props.banner.video.url}
    onClose={() => this.setState({ showVideo: false })}
  />

  getButton = () => {
    let banner = this.props.banner;
    if (banner && banner.position) {
      return {
        textEs: banner.textEs || banner.text,
        textEn: banner.textEn,
        position: banner.position,
        background_color: banner.background_color,
        font_color: banner.font_color,
        shape: banner.shape,
        icon: banner.icon,
        padding: banner.padding,
        fontSize: banner.fontSize
      }
    }
    return null;
  }
  makeBannerForm = () => {
    if (!this.state.showForm) return null;

    const translations = AvailableLanguages.map(language => {
      const translation = { 
        language, 
        title: this.props.banner[`title_${language}`] || "", 
        subtitle: this.props.banner[`subtitle_${language}`] || "" 
      };
      return translation;
    });

    return (
    <HomeBannerElementForm
      editing={true}
      formTitle={localization.get('form.title')}
      active={this.props.banner.active}
      title={this.props.banner.title}
      subtitle={this.props.banner.subtitle}
      link={this.props.banner.link}
      newTab={this.props.banner.new_tab}
      halfHeight={this.props.banner.half_height}
      hasButton={this.props.banner.has_button}
      translations={translations}
      image={getPropertySafe(this.props.banner, 'image.url', null)}
      video={getPropertySafe(this.props.banner, 'video.url', null)}
      preview={getPropertySafe(this.props.banner, 'preview.url', null)}
      onClose={() => this.setState({ showForm: false })}
      button={this.getButton()}
      submitRequest={(active, title, subtitle, link, newTab, imageId, video, preview, hasButton, button, halfHeight, translations, onProgress) => request.client.home.elements.updateBannerItem(
        this.props.clientId,
        this.props.elementId,
        this.props.itemIndex,
        { active, title, subtitle, link, newTab, imageId, video, preview, hasButton, button, halfHeight, translations },
        onProgress
      )
        .then(this.props.onUpdateSuccess)
        .catch(this.props.onUpdateFailed)}
    />
  )
  }
  
  requestDelete = () => request.client.home.elements.deleteBannerItem(this.props.clientId, this.props.elementId, this.props.itemIndex).then(this.props.onUpdateSuccess).catch(this.props.onUpdateFailed)

  handleDelete = () => GlobalConfirmationDialog.showDelete({ elementName: this.props.banner.title, deleteRequest: this.requestDelete })

  render = () => {
    const { banner } = this.props
    return (
      <div>
        {this.makeVideoDialog()}
        {this.makeBannerForm()}
        <Card>
          <CardContent style={{ textAlign: 'left' }}>
            <Typography style={{lineHeight: "1.5em", height: "3em", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{localization.getMainLocalizatonResource(banner, "title",  "title")}</Typography>
            <Typography style={{lineHeight: "1.5em", height: "3em", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} variant='caption'>{localization.getMainLocalizatonResource(banner, "subtitle",  "subtitle")}</Typography>
            <Typography>Estado: {banner.active ? 'Activo' : 'Inactivo'}</Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton onClick={() => this.setState({ showVideo: true })} disabled={!this.props.banner.video}><Videocam /></IconButton>
            <IconButton onClick={() => this.setState({ showForm: true })}><Edit /></IconButton>
            <IconButton onClick={this.handleDelete}><Delete /></IconButton>
          </CardActions>
        </Card>
      </div>
    )
  }
}

export default HomeBannerElement