import React from 'react'
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";

export const makePageSizeFrom = (perPage, dataLength, disableCustomLength = false) => {
  if (dataLength > perPage) return perPage
  if (dataLength === 1 && !disableCustomLength) return 3
  return dataLength
}

export const makeColumnImage = () => ({
  id: 'image',
  accessor: playlist => <img src={makeImageUrlWithSize(playlist, 'xs')} onError={onImageError} width={50} height={50} alt='' />,
  sortable: false,
  filterable: false,
  width: 60,
})