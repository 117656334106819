import React, {Component} from "react";
import localization from "../../../../config/localization";
import {
  Dialog,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Button, TextField
} from "@material-ui/core";
import { Event} from "@material-ui/icons";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import RRule from 'rrule';
import {Formik, ErrorMessage} from "formik";
import styled from "styled-components";

let fixDay = (dayDif, days) => {
  let mapFunc = (d) => d;
  if (dayDif == 1) {
    mapFunc = (d) => {
      switch(d) {
        case RRule.MO: return RRule.SU;
        case RRule.TU: return RRule.MO;
        case RRule.WE: return RRule.TU;
        case RRule.TH: return RRule.WE;
        case RRule.FR: return RRule.TH;
        case RRule.SA: return RRule.FR;
        case RRule.SU: return RRule.SA;
        default: return d;
      }
    }
  }
  if (dayDif == -1) {
    mapFunc = (d) => {
      switch(d) {
        case RRule.MO: return RRule.TU;
        case RRule.TU: return RRule.WE;
        case RRule.WE: return RRule.TH;
        case RRule.TH: return RRule.FR;
        case RRule.FR: return RRule.SA;
        case RRule.SA: return RRule.SU;
        case RRule.SU: return RRule.MO;
        default: return d;
      }
    }
  }
  return days.map(mapFunc);
}

class RecurrentEventDialog extends Component {
  daysOfWeek = [
    {value: 'monday', label: localization.get("recurrent_events.monday"), rruleDay: RRule.MO}, 
    {value: 'tuesday', label: localization.get("recurrent_events.tuesday"), rruleDay: RRule.TU},
    {value: 'wednesday', label: localization.get("recurrent_events.wednesday"), rruleDay: RRule.WE},
    {value: 'thursday', label: localization.get("recurrent_events.thursday"), rruleDay: RRule.TH},
    {value: 'friday', label: localization.get("recurrent_events.friday"), rruleDay: RRule.FR},
    {value: 'saturday', label: localization.get("recurrent_events.saturday"), rruleDay: RRule.SA},
    {value: 'sunday', label: localization.get("recurrent_events.sunday"), rruleDay: RRule.SU},
  ];

  updateEventDays = (formikProps, day) => {
    let days = formikProps.values.days;
    days[day] = !days[day];
    return formikProps.setFieldValue('days', days);
  }

  selectAllDays = (formikProps) => {
    let days = formikProps.values.days;
    this.daysOfWeek.forEach(d => days[d.value] = true);
    return formikProps.setFieldValue('days', days);
  }

  handleCloseDialog = () => {
    this.props.handleCloseDialog()
  }

  validate = values => {
    const errors = {};

    if (!values.from) {
      errors.from = localization.get("validator.is_required");
    }

    if (values.repeatable) {
      if (!values.to) {
        errors.to = localization.get("validator.is_required");
      }
      if (Object.keys(values.days).filter(k => values.days[k]).length == 0) {
        errors.days = localization.get("validator.is_required");
      }
    }

    return errors;
  };

  initialValues = () => ({
    from: null,
    to: null,
    repeatable: false,
    days: {monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false},
  });

  getDates = (event) => {
    let dates = [];
    // RRule uses byweekday for UTC date, fixing byweekday to consider local weekday instead of utc.
    let dtstart = new Date(Date.parse(event.from));
    let difUTCDate = dtstart.getDate() - dtstart.getUTCDate();
    if (event.repeatable && event.to) {
      let rrule = new RRule({
        freq: RRule.WEEKLY,
        dtstart: dtstart,
        until: new Date(Date.parse(event.to)),
        interval: 1,
        byweekday: fixDay(difUTCDate, Object.keys(event.days).filter(k => event.days[k]).map(d => this.daysOfWeek.find(dow => dow.value == d).rruleDay))
      })
      rrule.all().forEach(d => dates.push(d))
    } else {
      dates.push(new Date(Date.parse(event.from)));
    }

    return dates;
  }

  onSubmit= (values) => {
    let dates = this.getDates(values);
    this.props.onSubmit({dates, duration: values.duration});
  }

  render = () => (
    <Dialog
      open={this.props.showDialog}
      onBackdropClick={this.handleCloseDialog}
      onEscapeKeyDown={this.handleCloseDialog}
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>
        <span>
          {localization.get('recurrent_events.new_date')}
        </span>
      </DialogTitle>
      <Formik
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={(values, actions) => {
          this.onSubmit(values)
          actions.resetForm();
        }}
      >
        {formikProps => (
          <div>
            <div style={{float: "left", position: "relative", width: "100%", marginBottom: 10, display: 'flex', justifyContent: 'space-between'}}>
              <div style={{float: "left", position: "relative", display: "flex", flexDirection: "row", width: "350px"}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateDiv style={{marginLeft: 45}}>
                    <DateTimePicker
                      style={{marginBottom: 15}}
                      autoOk
                      autoSubmit
                      disablePast
                      ampm={false}
                      format={"dd/MM/yyyy HH:mm a"}
                      dateRangeIcon={<Event/>}
                      label={localization.get("recurrent_events.from")}
                      value={formikProps.values.from}
                      onChange={value => formikProps.setFieldValue('from', value)}
                    />
                    <ErrorMessage name="from" className="web-like-error">
                      {msg => <div className="web-like-error font12">{msg}</div>}
                    </ErrorMessage>
                  </DateDiv>
                </MuiPickersUtilsProvider>
                <TextField
                  style={{marginBottom: 20, marginLeft: 15}}
                  value={formikProps.values.duration}
                  type={'number'}
                  label={localization.get("recurrent_events.duration")}
                  onChange={e => formikProps.setFieldValue('duration', e.target.value)}
                />
                <ErrorMessage name="duration" className="web-like-error">
                  {msg => <div className="web-like-error font12">{msg}</div>}
                </ErrorMessage>
              </div>
              <div style={{float: "left", position: "relative", width: "150px"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={formikProps.values.repeatable}
                      onChange={e => formikProps.setFieldValue('repeatable', e.target.checked)}
                    />
                  }
                  label={localization.get('recurrent_events.repeatable')}
                />
              </div>
            </div>
            {
              formikProps.values.repeatable && 
              <div style={{position: "relative", width: "100%", marginBottom: 10, display: 'flex', justifyContent: 'space-between'}}>
                <div style={{float: "left", position: "relative", width: "150px"}}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateDiv style={{marginLeft: 45}}>
                      <DatePicker
                        style={{marginBottom: 15}}
                        fullWidth
                        autoOk
                        clearable
                        onlyCalendar
                        disablePast
                        minDate={formikProps.values.from}
                        format={"dd/MM/yyyy"}
                        label={localization.get("recurrent_events.to")}
                        value={formikProps.values.to}
                        onChange={value => formikProps.setFieldValue('to', value)}
                      />
                      <ErrorMessage name="to" className="web-like-error">
                        {msg => <div className="web-like-error font12">{msg}</div>}
                      </ErrorMessage>
                    </DateDiv>
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between', marginRight: 65}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h4>{localization.get('recurrent_events.repeat_days')}</h4>
                    <div style={{color: '#4cc2d5', textDecoration: "underline"}} onClick={() => this.selectAllDays(formikProps)}>{localization.get('recurrent_events.select_all')}</div>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        {this.daysOfWeek.map((d, i)=> <td key={i} style={{textAlign: 'center', fontWeight: 'bold'}}>{d.label.charAt(0)}</td>)}
                      </tr>
                      <tr>
                        {this.daysOfWeek.map((d, i)=> <td key={i}><Checkbox size="small" style={{color: "#4cc2d5", height: 30, width: 30}} checked={formikProps.values.days[d.value]} onChange={e => this.updateEventDays(formikProps, d.value)}/></td>)}
                      </tr>
                    </tbody>
                  </table>
                  <ErrorMessage name="days" className="web-like-error">
                    {msg => <div className="web-like-error font12">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            }
            <div style={{margin: '20px 0 5px', float: 'right'}}>
              <Button onClick={() => this.handleCloseDialog()} color='default'>{localization.get("cancel")}</Button>
              <Button onClick={() => formikProps.handleSubmit()} color='primary'>{localization.get("recurrent_events.create_date")}</Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
}

export default RecurrentEventDialog;

const DateDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
`