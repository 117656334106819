import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "./Table";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import {LocalOffer} from '@material-ui/icons'
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

export default class extends Component {

  componentDidMount = () => {
      document.title = localization.get('title.tags') + ' - Feater'
  }

  onAdd = () => {
    let url =  ''
    if(this.props.isMusic) {
      url = makeUrlWithTableParams(`/panel/music/tags/create`)
    } else if (this.props.isTalent) {
      url = makeUrlWithTableParams(`/panel/talents/tags/create`);
    } else if (this.props.isEditorial) {
      url = makeUrlWithTableParams(`/panel/editorial/tags/create`);
    } else {
      url = makeUrlWithTableParams(`/panel/audiovisual/tags/create`);
    }
    return this.props.history.push(url)
  }

  render = () =>
    <AllViewContainer
      onAdd={this.onAdd}
      viewIcon={<LocalOffer />}
      ref='viewContainer'>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Table {...this.props}/>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </AllViewContainer>

}
