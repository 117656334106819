import create from './create'
import del from './delete'
import getAll from './getAll'
import get from './get'
import update from './update'
import getElementsMetadata from './getElementsMetadata'
import getElementById from './getElementById'
import usersToApprove from './usersToApprove'
import approve from './approve'
import publicChannel from './publicChannel'
import getPublic from './getPublic'
import help from './help'
import contact from './contact'
import updatePreferences from './updatePreferences'
import getMetadata from './getMetadata'
import getForDashboard from './getForDashboard'
import updateBanner from './updateBanner'

export default host => ({
  create: create(host),
  delete: del(host),
  getAll: getAll(host),
  publicChannel: publicChannel(host),
  getMetadata: getMetadata(host),
  getElementsMetadata: getElementsMetadata(host),
  //getAllForClient: getAllForClient(host),
  get: get(host),
  getForDashboard: getForDashboard(host),
  update: update(host),
  updateBanner: updateBanner(host),
  getElementById: getElementById(host),
  usersToApprove: usersToApprove(host),
  approve: approve(host),
  getPublic: getPublic(host),
  help: help(host),
  contact: contact(host),
  updatePreferences: updatePreferences(host),
})