import {post} from "../utils";


const form = (password) => {
  const f = new FormData()
  f.append('password', password)
  return f
}

export default ({
  url,
  password
}) => post(`${url}`, form(password))
  .then(result => result.json())