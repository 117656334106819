import React from "react";
import Checkbox from "../components/Checkbox";
import {Exclusivity} from "../utils/constants";
import Reductions from "../components/Reductions";
import localization from "../../../../config/localization"

const AdditionalsScreen = ({
  exclusivity,
  biChannel,
  reductions,
  editionAccordingToSeconds,
  dontShow = [],
  onChange
}) => {
  return (
    <div>
      <h3 style={{marginLeft: 20}}>{localization.get('license_wizard.additional')}</h3>
      {!dontShow.includes("threeMonthExclusivity") && (
        <Checkbox
          content={localization.get("license_wizard.3_months_exclusivity")}
          checked={exclusivity === Exclusivity.THREE_MONTHS}
          onClick={() =>
            onChange({ exclusivity: exclusivity === Exclusivity.NONE ? Exclusivity.THREE_MONTHS : Exclusivity.NONE })
          }
        />
      )}
      {/*{!dontShow.includes("biChannel") && (*/}
      {/*  <Checkbox content={localization.get('license_wizard.bichannel')} checked={biChannel} onClick={() => onChange({ biChannel: !biChannel })} />*/}
      {/*)}*/}
      {!dontShow.includes("editionAccordingToSeconds") && (
        <Checkbox
          content={localization.get('license_wizard.edition_according_to_seconds')}
          checked={editionAccordingToSeconds}
          onClick={() => onChange({ editionAccordingToSeconds: !editionAccordingToSeconds })}
        />
      )}
      {!dontShow.includes("reductions") && (
        <Reductions value={reductions} onChange={v => onChange({ reductions: v })} />
      )}
    </div>
  );
};

export default AdditionalsScreen;
