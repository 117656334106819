import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => (channelId, {
    code,
    discount,
    uses,
    quantity,
    expiresAt,
    active,
    description,
    products,
    includeAll,
}) =>
  post(`${host}/channels/${channelId}/code`, makeFormData({
      code,
      discount,
      uses,
      quantity,
      expiresAt,
      active,
      description,
      products,
      includeAll,
}))
  .then(response => response.json())
  .then(json => json.data)
