import "layouts/Home/Home.css";
import React from "react";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import './myList.css'
import TrackResult from "../../../components/Letflow/TrackResult";
import TrackTable from "../../../components/Letflow/TrackTable";
import MyListCarousel from "./MyListCarousel";
import {channelFavoriteListName, getActiveChannel, getStoredChannel, setPageTitle} from "../../../api-client/core/authentication/utils";
import {pushIfDoesNotExist} from "../../../utils";
import analyticsInstance from "../../../analytics/analyticsInstance";

class MyList extends React.Component {

  state = {
    albums: [],
    artists: [],
    musics: [],
    contentCreators: [],
    playlists: [],
    contentCreations: [],
    message: "",
  };

  componentDidMount = () => {
    analyticsInstance.visitContent({
      name: getStoredChannel().name,
      content_type: "Favorites",
    })
    setPageTitle(!!channelFavoriteListName() ? channelFavoriteListName() : localization.get('title.favorites'))
    document.getElementsByTagName('html')[0].scrollTop = 0
    request.user.channelFavorites()
      .then((user) => {
        if (
          !(
            user.musicsChannelFavorites.length === 0 &&
            user.albumsChannelFavorites.length === 0 &&
            user.artistsChannelFavorites.length === 0 &&
            user.playlistsChannelFavorites.length === 0 &&
            user.contentCreatorsChannelFavorites.length === 0 &&
            user.contentCreationsChannelFavorites.length === 0
          )
        ) {
          this.setState({
            albums: user.albumsChannelFavorites,
            musics: user.musicsChannelFavorites,
            artists: user.artistsChannelFavorites,
            contentCreators: user.contentCreatorsChannelFavorites,
            playlists: user.playlistsChannelFavorites,
            contentCreations: user.contentCreationsChannelFavorites,
          })
        } else {
          this.setState({message: "my_list.no_result"})
        }
      })
  }

  getChannelContentCreatorTypeLabel = (contentCreatorType, type='content') => {
    let creatorTypes = getActiveChannel() && getStoredChannel().channelContentCreatorTypes;
    let label = contentCreatorType.name;
    if (creatorTypes) {
      let channelContentCreatorType = creatorTypes.find(ct => ct.contentCreatorType.id == contentCreatorType.id);
      if (channelContentCreatorType) {
        switch (type) {
          case 'content': 
            label = channelContentCreatorType.content_title || label;
            break;
          case 'event':
            label = channelContentCreatorType.event_title || localization.get("smart_search.event_category", label);
            break;
          case 'material':
            label = channelContentCreatorType.material_title || localization.get("smart_search.material_category", label);
            break;
        }
      }
    };
    return label;
  }


  render() {
    const {albums, artists, musics, contentCreators, playlists, contentCreations} = this.state;
    const contentCreatorTypes = [];
    contentCreators.forEach(contentCreator => pushIfDoesNotExist(contentCreatorTypes, contentCreator.type));
    const contentCreationTypes = [];
    contentCreations.forEach(contentCreations => pushIfDoesNotExist(contentCreationTypes, contentCreations.contentCreator.type));

    return (
      <div className="favorites-container">

        {musics.length > 0 &&
          <div style={{marginBottom: "20px", float: "left", width: "100%"}}>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{localization.get('musics')}</h2>
            <TrackResult
              rows={<TrackTable {...this.props} type={'music'} hideSuggestions={true} tracks={musics} />}
            />
          </div>
        }

        {albums.length > 0 &&
          <React.Fragment>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{localization.get('albums')}</h2>
            <MyListCarousel {...this.props} channel={this.props.channel} elements={albums} type={'album'}/>
          </React.Fragment>
        }

        {playlists.length > 0 &&
          <React.Fragment>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{localization.get('playlists')}</h2>
            <MyListCarousel {...this.props} channel={this.props.channel} elements={playlists} type={'playlist'}/>
          </React.Fragment>
        }

        {artists.length > 0 &&
          <React.Fragment>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{localization.get('artists')}</h2>
            <MyListCarousel {...this.props} channel={this.props.channel} elements={artists} type={'artist'}/>
          </React.Fragment>
        }

        {contentCreatorTypes.map(contentCreatorType =>
          <React.Fragment>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{this.getChannelContentCreatorTypeLabel(contentCreatorType, 'content')}</h2>
            <MyListCarousel {...this.props} channel={this.props.channel} elements={contentCreators.filter(contentCreator => contentCreator.type.id === contentCreatorType.id)} type={'content_creator'}/>
          </React.Fragment>
        )}

        {contentCreationTypes.map(contentCreatorType =>
          <React.Fragment>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, black)"}}>{this.getChannelContentCreatorTypeLabel(contentCreatorType, 'material')}</h2>
            <MyListCarousel {...this.props} channel={this.props.channel} elements={contentCreations.filter(contentCreation => contentCreation.contentCreator.type.id === contentCreatorType.id)} type={'video_content_creation'}/>
          </React.Fragment>
        )}

        {(musics.length === 0 && albums.length === 0 && artists.length === 0 && contentCreators.length === 0) &&
          <div
            style={{
              float: "left",
              position: "relative",
              width: "100%",
              textAlign: "center",
              marginTop: "30px",
              fontSize: "20px",
              padding: "40px",
              color: "var(--main-font-color, black)"
            }}>{localization.get(this.state.message)}</div>
        }

      </div>
    )
  }
}

export default MyList
