import React, {Component} from "react";

import {request} from "api-client";
import Index from "../Form";
import {getActiveClient, ROLES} from "../../../../../api-client/core/authentication/utils";
import localization from "../../../../../config/localization"

export default class extends Component {
  submitRequest = data => {
    const client_roles = [
      {
        client_id: getActiveClient(),
        role_id: data.roleId,
        can_license:
          data.roleId === ROLES.supervisorId || data.roleId === ROLES.sysAdminId || data.roleId === ROLES.clientAdminId
            ? true
            : data.roleId === ROLES.userId
            ? data.canLicense
            : false,
      },
    ];
    return request.user.create({
      name: data.name,
      email: data.email,
      roleId: ROLES.SINGLE_CLIENT,
      imageFile: data.image.file,
      language: data.language,
      client_roles,
    });
  };

  render = () => {
    return <Index {...this.props} submitRequest={this.submitRequest} formTitle={localization.get('account.create_user')} type={"create"} />;
  };
}
