import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio, RadioGroup
} from "@material-ui/core";
import localization from "../../../config/localization";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../api-client";
import {copyStringToClipboard} from "../../../utils";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";

class CustomShareContent extends React.Component {
    state = {
      selectedChannel: null,
      allCreation: false,
      linkType: "content"
    }

    handleClose = () => this.setState({ selectedChannel: null, allCreation: false, linkType: "content" }, this.props.onClose)

    handleSubmit = () =>
      request.contentCreator.customShare(this.props.sharingCreator.id, this.state.selectedChannel.value, this.state.allCreation)
        .then(res => {
          copyStringToClipboard(res.url)
          this.handleClose();
        })
        .catch(GlobalSnackbar.showGenericError)

  handleGoTo = () =>
    request.contentCreator.customShare(this.props.sharingCreator.id, this.state.selectedChannel.value, this.state.allCreation)
      .then(res => {
        window.open(res.url, '_blank')
        this.handleClose();
      })
      .catch(GlobalSnackbar.showGenericError)

    render = () => {
        const { open, sharingCreator } = this.props;

        return (
            <Dialog open={open} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
                <DialogTitle>{localization.get('preview')}</DialogTitle>
                <DialogContent style={{width: '400px', height: '250px'}}>
                  {sharingCreator && sharingCreator.type.type !== "editorial" &&
                    <RadioGroup
                      name="linkType"
                      value={this.state.linkType}
                      onChange={event => this.setState({
                        linkType: event.target.value,
                        allCreation: false,
                        selectedChannel: null
                      })}
                      style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                    >
                      <FormControlLabel style={{width: "fit-content", float: "left", position: "relative"}}
                                        value="content" control={<Radio color="default"/>}
                                        label={localization.get('links.content')}/>
                      <FormControlLabel style={{width: "fit-content", float: "left", position: "relative"}}
                                        value="creations" control={<Radio color="default"/>}
                                        label={localization.get('links.materials')}/>
                    </RadioGroup>
                  }
                  {this.state.linkType === 'content' ?
                  <>
                    <label style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)'}}>{localization.get('channel.to_share')}</label>
                    <AsyncSelect
                      placeholder={localization.get('channel')}
                      cacheOptions
                      defaultOptions
                      onChange={val => this.setState({selectedChannel: val})}
                      value={this.state.selectedChannel}
                      loadOptions={inputValue => request.channel.getAll({ filterBy: [
                          { column: "name", filter: inputValue },
                          { column: "content_creator_type_id", filter: sharingCreator.type.id }
                        ]})
                        .then(channels => channels.map(channel => ({value: channel.id, label: channel.name})))
                      }
                    />
                  </>
                    :
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="secure"
                            style={{ color: "#4cc2d5" }}
                            checked={this.state.allCreation}
                            onChange={() => this.setState({allCreation: !this.state.allCreation})}
                          />
                        }
                        label={localization.get('form.all_creations')}
                      />
                    <p style={{marginTop: 10}}>{localization.get('links.materials.extra')}</p>
                    </>
                  }


                </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleClose}>{localization.get("form.close")}</Button>
                  {this.state.linkType === 'creations' && <Button onClick={() => window.open(`${window.location.origin}/preview/content_creators/${sharingCreator.hashcode}${this.state.allCreation ? "?allCreations=true": ""}` , "_blank")}>{localization.get("visit_without_channel")}</Button>}
                  {this.state.selectedChannel && this.state.linkType === 'content' && <Button onClick={this.handleSubmit} disabled={!this.state.selectedChannel}>{localization.get("copy_link")}</Button>}
                  {this.state.linkType === 'content' && <Button onClick={this.handleGoTo} disabled={!this.state.selectedChannel}>{localization.get("visit")}</Button>}
              </DialogActions>
            </Dialog>
        )
    }
}

export default CustomShareContent;