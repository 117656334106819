import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {request} from "api-client";
import Table from "./Table";
import {AllViewContainer} from "components/Letflow/ViewContainer";
import {LibraryMusic} from "@material-ui/icons";
import localization from "../../../config/localization";
import {makeUrlWithTableParams} from "../../../utils";

class All extends Component {
  state = {
    categories: [],
    clients: []
  }

  componentDidMount = () => {
    document.title = localization.get("title.playlists") + " - Feater";
    request.catalogCategory.getAll().then(categories => this.setState({categories}))
      .then(() => request.client.getAll().then(clients => this.setState({clients})))
  };

  requestDelete = id => request.playlist.delete(id);

  onAdd = () => this.props.history.push(makeUrlWithTableParams(`/panel/playlists/create`));

  onDelete = playlist => this.refs.viewContainer.requestDeletion(playlist.id);

  render = () => (
    <AllViewContainer
      requestDeletion={this.requestDelete}
      onAdd={this.onAdd}
      viewIcon={<LibraryMusic />}
      ref="viewContainer"
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              {(this.state.clients.length > 0 && this.state.categories.length > 0) &&
                <Table
                {...this.props}
                onDelete={this.onDelete}
                clients={this.state.clients}
                categories={this.state.categories}
                />
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default All;
