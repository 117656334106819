import {isNullOrUndefined} from "util";

export const makeFormData = ({
  name,
  isTalent,
  clientId,
  filename,
  type,
  label,
  token,
  videoId,
  imageId,
  audioId,
  fileId,
  cloudId,
  emails,
  hint,
  isProtected,
}) => {
  const form = new FormData();
  if (name) form.append('name', name)
  if (clientId) form.append('client_id', clientId)
  if (cloudId) form.append('cloud_id', cloudId)
  if (!isNullOrUndefined(isTalent)) form.append('is_talent', isTalent ? 1 : 0)
  if (filename) form.append('filename', filename)
  if (type) form.append('type', type)
  if (label) form.append('label', label)
  if (token) form.append('token', token)
  if (!isNullOrUndefined(videoId)) form.append('videoId', videoId)
  if (!isNullOrUndefined(imageId)) form.append('imageId', imageId)
  if (!isNullOrUndefined(audioId)) form.append('audioId', audioId)
  if (!isNullOrUndefined(fileId)) form.append('fileId', fileId)
  if (!isNullOrUndefined(emails)) form.append('notify_emails', emails)
  if (!isNullOrUndefined(isProtected)) form.append('protected', isProtected ? 1: 0)
  if (hint) form.append('hint', hint)
  return form
};

export const includeQuery = "include=files";
