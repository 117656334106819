import "layouts/Home/Home.css";
import React, {Component} from "react";
import localization from "../../../config/localization";
import LinkTable from "./Table";
import styled from "styled-components";
import {setPageTitle} from "../../../api-client/core/authentication/utils";

class Links extends Component {

  componentDidMount = () => {
    setPageTitle(localization.get('links'))
    document.getElementsByTagName('html')[0].scrollTop = 0;
  };

  render() {
    return (
        <React.Fragment>
            <RespDiv>
              <h3 className="links-title" style={{margin: 30}}>{localization.get('links')}</h3>
                <div style={{margin: "0 5%"}}>
                  <LinkTable {...this.props} />
                </div>
             </RespDiv>
      </React.Fragment>
    )
  }
}
export default Links;

const RespDiv = styled.div`
background-color: white;
position: relative;
float: left;
height: 90%;
padding: 0 40px 5px;
@media (max-width: 450px){
  padding: 0;
}
`;