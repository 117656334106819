import React from "react";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import localization from '../../../../config/localization'

const makeTextField = (label, value, valueName, onChange) => (
  <TextField
    style={{ marginBottom: 10 }}
    helperText={localization.get('license_wizard.required')}
    label={label}
    value={value}
    onChange={e => onChange({ [valueName]: e.target.value })}
    fullWidth
  />
);

const PersonalizeLicenseScreen = ({ clientName, campaignName, brandName, requestExclusivity, exclusivityReason, onChange }) => {
  return (
    <div style={{ margin: "20px" }}>
      <h3>{localization.get('license_wizard.personalize_license')}</h3>
      {makeTextField(localization.get('license_wizard.client'), clientName, "clientName", onChange)}
      {makeTextField(localization.get('license_wizard.brand'), brandName, "brandName", onChange)}
      {makeTextField(localization.get('license_wizard.campaign'), campaignName, "campaignName", onChange)}
      <FormControlLabel
        style={{float: "left", width: "100%",marginTop: 30}}
        control={
          <Checkbox
            style={{color: "black"}}
            checked={requestExclusivity}
            onChange={() => onChange({ requestExclusivity: !requestExclusivity })}
          />
        }
        label={localization.get('license_wizard.request_exclusivity')}
      />
      {requestExclusivity && makeTextField(localization.get('license_wizard.exclusivity_reason'), exclusivityReason, "exclusivityReason", onChange)}
    </div>
  );
};

export default PersonalizeLicenseScreen;
