import React from 'react'
import {request} from '../../../api-client';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import PropTypes from "prop-types";
import localization from "../../../config/localization"
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import SoundFilePicker from "../../../components/Letflow/Form/SoundFilePicker";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";

const initialState = {
  title: '',
  musicId: '',
  addAudioAiff: false,
  audio: {src: null, file: null},
  audioAiff: {src: null, aiffFile: null},
  version: null,
  disableSubmit: false
}

class MusicVersionDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = initialState
    this.state.musicId = props.musicId
    this.state.version = props.version
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.musicId !== this.props.musicId) {
      this.setState({musicId: nextProps.musicId})
    }

    if (nextProps.version && nextProps.version !== this.props.version) {
      this.setState({
        title: nextProps.version.title,
        audio: nextProps.version.audio ? {src: nextProps.version.audio.url, file: {name: nextProps.version.audio.original_name}} : {src: null, aiffFile: null},
        audioAiff: nextProps.version.audioAiff ? {src: nextProps.version.audioAiff.url, file: {name: nextProps.version.audioAiff.original_name}} : {src: null, aiffFile: null},
        addAudioAiff: !!nextProps.version.audioAiff
      })
    }
  }

  handleSubmit = () => {
    this.setState({disableSubmit: true})

    const {title, musicId, audio, audioAiff} = this.state
    if (!audio.src && !audio.file) {
      GlobalSnackbar.show({message: localization.get('music.must_have_audio'), type: GlobalSnackbarTypes.ERROR})
      return false
    }

    request.musicVersion.create({
      title,
      musicId,
      audioFile: audio.file,
      audioAiffFile: audioAiff.aiffFile
    })
      .then(() => GlobalSnackbar.show({message: localization.get('snackbar.entity_update_success'), type: GlobalSnackbarTypes.SUCCESS}))
      .then(() => this.handleClose())
  }

  onChange = e => this.setState({[e.target.id]: e.target.value})

  onAudioChanged = e => {
    e.preventDefault();
    let file = e.target.files[0];
    let src = URL.createObjectURL(file)
    this.setState({audio: {src, file}})
  }

  onAudioAiffChanged = e => {
    e.preventDefault();
    let aiffFile = e.target.files[0];
    let src = URL.createObjectURL(aiffFile)
    this.setState({audioAiff: {src, aiffFile}})
  }

  handleClose = () => {
    this.props.handleClose()
    this.setState(initialState)
  }

  render = () => {

    const {title, addAudioAiff, audio, audioAiff, disableSubmit} = this.state

    return (
      <Dialog open={this.props.show} onBackdropClick={this.handleClose} onEscapeKeyDown={this.handleClose}>
        <ValidatorForm>
          <IconButton style={{ position: "absolute", top: "5px", right: "5px" }} onClick={this.handleClose}>
            <Close />
          </IconButton>
          <DialogTitle>{localization.get('music.add_version')}</DialogTitle>
          <DialogContent style={{width: 400, maxWidth: "100%"}}>

            <TextValidator
              style={{ width: "100%" }}
              id="title"
              label={localization.get('form.title')}
              name="title"
              value={title}
              onChange={this.onChange}
              validators={['required']}
              errorMessages={[localization.get('validator.is_required')]}
            />

            <SoundFilePicker audio={audio} onAudioChanged={this.onAudioChanged} audioRulesLabel='form.audio_rules' showAudioBar={false} format='.mp3' />

            <br/>
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={addAudioAiff}
                  onChange={() => this.setState({addAudioAiff: !addAudioAiff})}
                />
              }
              label={localization.get('form.aiff_checkbox')}
            />

            {addAudioAiff && <SoundFilePicker audio={audioAiff} onAudioChanged={this.onAudioAiffChanged} audioRulesLabel='form.audio_rule_aiff' format='.aiff' />}

          </DialogContent>
          <DialogActions>
            <Button disabled={disableSubmit} onClick={this.handleSubmit}>{localization.get('form.submit')}</Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

MusicVersionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default MusicVersionDialog
