import {get, post,} from "../utils";

export const monthsToDays = months => months * 30

export const daysToMonths = days => Math.floor(days / 30) 

const makePaginationQueryParams = (prefix, page, perPage) => {
  if (typeof page === 'number' && typeof perPage === 'number')
    return `${prefix}page=${page + 1}&per_page=${perPage}`
  return ''
}

const makeFilterQueryParams = (prefix, filterBy) => {
  if (Array.isArray(filterBy) && filterBy.length > 0)
    return prefix + filterBy.map(f => `${f.column}=${f.filter}`).join('&')
  return ''
}

const makeDefaultIncludeQueryParam = prefix => `${prefix}include=music.audio.waveform;client;children:parent_id,state;parent`

const makeOrderByQueryParams = prefix => prefix + 'order_by=created_at&sort_type=desc'

const makeGetByState = (host, state, paginationConfig) => {

  const uri = `${host}/reservations`
  const stateQueryParam = `?state=${state}`
  const includeQueryParam = makeDefaultIncludeQueryParam('&')
  const paginationQueryParams = makePaginationQueryParams('&', paginationConfig.page, paginationConfig.perPage)
  const filterQueryParams = makeFilterQueryParams('&', paginationConfig.filterBy)
  const orderByQueryParams = makeOrderByQueryParams('&')
  
  return get(`${uri}${stateQueryParam}${includeQueryParam}${paginationQueryParams}${filterQueryParams}${orderByQueryParams}`).then(res => res.json())
}

const makeGetByStateForClient = (host, state, clientId, paginationConfig, useReservationsEndpoint = false) => {

  const stateQueryParam = `?state=${state}`
  const includeQueryParam = makeDefaultIncludeQueryParam('&')
  const paginationQueryParams = makePaginationQueryParams('&', paginationConfig.page, paginationConfig.perPage)
  const filterQueryParams = makeFilterQueryParams('&', paginationConfig.filterBy)
  const orderByQueryParams = makeOrderByQueryParams('&')

  if (useReservationsEndpoint) {

    const uri = `${host}/reservations`
    const clientIdQueryParam = `&client_id=${clientId}`

    return get(`${uri}${stateQueryParam}${includeQueryParam}${clientIdQueryParam}${paginationQueryParams}${filterQueryParams}${orderByQueryParams}`).then(res => res.json())
  }

  const uri = `${host}/clients/${clientId}/reservations`

  return get(`${uri}${stateQueryParam}${includeQueryParam}${paginationQueryParams}${filterQueryParams}${orderByQueryParams}`).then(res => res.json())
}

const requests = host => ({

  get: reservationId => {

    const uri = `${host}/reservations/${reservationId}`
    const includeQueryParam = makeDefaultIncludeQueryParam('?')

    return get(`${uri}${includeQueryParam}`).then(res => res.json())
  },

  getPending: paginationConfig => makeGetByState(host, 'requested', paginationConfig),

  getPendingForClient: (clientId, paginationConfig, useReservationsEndpoint = false) => makeGetByStateForClient(host, 'requested', clientId, paginationConfig, useReservationsEndpoint),

  getAccepted: paginationConfig => makeGetByState(host, 'accepted', paginationConfig),

  getAcceptedForClient: (clientId, paginationConfig, useReservationsEndpoint = false) => makeGetByStateForClient(host, 'accepted', clientId, paginationConfig, useReservationsEndpoint),

  accept: (reservationId) => {

    const uri = `${host}/reservations/${reservationId}/accept`

    return post(uri)
  },

  reject: (reservationId, reason) => {

    const uri = `${host}/reservations/${reservationId}/reject`
    const body = JSON.stringify({ reject_cause: reason })

    return post(uri, body)
  },

  create: (duration, musicId, description, clientId) => {

    const uri = `${host}/reservations`
    const body = JSON.stringify({ duration, description, music_id: musicId, client_id: clientId })

    return post(uri, body)
  },

  renew:  (reservationId, duration, description) => {

    const uri = `${host}/reservations/${reservationId}/renew`
    const body = JSON.stringify({ duration, description })

    return post(uri, body)
  },

  cancel: (reservationId) => {
    
    const uri = `${host}/reservations/${reservationId}/cancel`

    return post(uri)
  } 

})


export default requests