import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import {withStyles} from '@material-ui/core';
import {Done} from '@material-ui/icons'
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const Passed = ({ title, message, onConfirm, classes }) => <SweetAlert
  success
  style={{ display: "block" }}
  title={title}
  onConfirm={onConfirm}
  confirmBtnCssClass={classes.button + " " + classes.success}
  confirmBtnText={<Done />}>
  {message}
</SweetAlert>

export default withStyles(sweetAlertStyle)(Passed)