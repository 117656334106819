import React, { Component } from 'react';
import { getStoredChannel } from '../../../api-client/core/authentication/utils';
import { makeFitImageUrl } from '../../../utils';
import {get} from "lodash"
import EventManager, { events } from "../../../utils/EventManager";

class FloatingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({ isVisible: scrollTop > 0 });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }


  render() {
    const { isVisible } = this.state;
    const channel = getStoredChannel();
    const image = channel.floatingButtonImage || channel.image;

    const buttonStyle = {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      cursor: 'pointer',
      opacity: isVisible ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
      zIndex: 200
    };

    return (
      <div
        style={buttonStyle}
        onClick={() => EventManager.getInstance().dispatch(events.OPEN_CONTACT_DIALOG)}
      >
        <img src={makeFitImageUrl({ image }, 150, 150, '', 'png')}/>
      </div>
    );
  }
}

export default FloatingButton;