import moment from "moment";
import localization from "../../config/localization";
import {monthDiff} from "../../utils";
import React from "react";
import {LicenseTypeIds} from "../../components/Letflow/LicenseWizard/utils/constants";
import {Link} from "react-router-dom"
import {getStoredUser, userIsArtist} from "../../api-client/core/authentication/utils";
import {get} from 'lodash'

export default ({row}) => {
  !row.original.licenseType && (row.original = {...row.original, licenseType: row.original.type})

  return (
    <div style={{float: "left", position: "relative", width: "100%", border: "2px solid darkgray"}}>
      <div style={{
        float: "left",
        position: "relative",
        width: "100%",
        borderBottom: "2px solid darkgray",
        textAlign: "center",
        height: "35px",
        fontSize: "22px",
        paddingTop: "7px"
      }}
      >
        {localization.get('music.license_dialog.details')} {row.original.licenseType.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ? 'SADAIC' : null}
      </div>
      <div style={{float: "left", position: "relative", width: "60%", padding: "20px"}}>

        {row.original.starts_at ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.starts_at')}:</b>{moment(row.original.starts_at).format("D-MM-YYYY")}
          </div>
          : null}

        {row.original.ends_at ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.ends_at')}:</b>{moment(row.original.ends_at).format("D-MM-YYYY")}
          </div>
          : null}

        {row.original.track ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.track')}:</b>
            <Link
              style={{color: "black",textDecoration: "underline"}}
              to={`/panel/musics/${row.original.track.id}/edit`}
            >
              {`${row.original.track.title} (${row.original.track.code})`}
            </Link>
          </div>
          : null}

        {row.original.artist ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.artist')}:</b>
            <Link
              style={{color: "black", textDecoration: "underline"}}
              to={`/panel/artists/${row.original.artist.id}/edit`}
            >
              {`${row.original.artist.name} (${localization.get(row.original.artist.type)})`}
            </Link>
          </div>
          : null}

        {row.original.albums ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.albums')}:</b>{row.original.albums.map(album => {
              return <div style={{float: "left",marginRight: 10}}>
                <Link style={{color: "black", textDecoration: "underline"}} to={`/panel/albums/${album.id}/edit`}>{album.title}</Link>,
                </div>
            })} {get(row, 'original.track.catalog.musicDealer.name') ? `(${get(row, 'original.track.catalog.musicDealer.name')})`: ""}
          </div>
          : null}

        {row.original.brand ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left",
              width: "200px"
            }}>{localization.get('music.license_dialog.client')}:</b>{`${row.original.brand.name} ${row.original.user ? `(${row.original.user.name || row.original.user})` : ''}`}
          </div>
          : null}

        {row.original.licenseType ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.license_type')}:</b>{row.original.licenseType.name}
          </div>
          : null}

        {row.original.media ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.media')}:</b>{row.original.media}
          </div>
          : null}

        {row.original.extents.length > 0 ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.regions')}:</b>{row.original.extents.map(extent => (`${extent.region.name} (${extent.name})`)).join(", ")}
          </div>
          : null}

        {row.original.licenseType.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.validity')}:</b>{monthDiff(row.original.starts_at, row.original.ends_at)} Meses
          </div>
          : null}

        {row.original.exclusive ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px", float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.exclusivity')}:</b>{monthDiff(row.original.starts_at, row.original.exclusive_until)} Meses
          </div>
          : <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.exclusivity')}</b> No exclusivo
          </div>}

        {row.original.versions && row.original.versions.length > 0 ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.versions')}:</b>
            <div style={{float: "left"}}>
              {row.original.versions.map((version, key) =>
                <div>{`${key === 0 ? localization.get('music.license_dialog.comercial')
                  : `${localization.get('music.license_dialog.version')} ${key}`} / ${localization.get('music.license_dialog.second')}: ${version.length.name} / ${localization.get('music.license_dialog.reductions')}: ${version.reductions}`}</div>)}
            </div>
          </div>
          : null}

        {row.original.licenseType.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.especial_campaign')}:</b>
            {!row.original.versions || row.original.versions.length < 3 ? localization.get('music.license_dialog.none') : row.original.versions.length >= 6 ? localization.get('music.license_dialog.6_or_more') : localization.get('music.license_dialog.3_or_more')}
          </div>
          : null}

        {row.original.unlimited_reductions || row.original.reductions ?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>{localization.get('music.license_dialog.reductions')}:</b>{row.original.unlimited_reductions ? localization.get('music.license_dialog.unlimited') : `${row.original.reductions} ${localization.get('music.license_dialog.reductions')}`}
          </div>
          : row.original.versions ? null :
            <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
              <b style={{fontSize: "16px", float: "left", width: "200px"}}>{localization.get('music.license_dialog.reductions')}:</b>{localization.get('music.license_dialog.no_reductions')}
            </div>}

        {row.original.price && !userIsArtist() && !getStoredUser().artist_view?
          <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
            <b style={{
              fontSize: "16px",
              float: "left", width: "200px"
            }}>Total:</b>
            { row.original.price !== row.original.discount ?
              `${row.original.licenseType.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ? 'ARS' : 'USD'} ${row.original.price} ${row.original.discount ? `(${localization.get('music.license_dialog.discount')} ${row.original.licenseType.id === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA ? 'ARS' : 'USD'} ${row.original.discount})` : ''}`
              :
              "Fee"
            }
          </div>
          : null}

      </div>
      {row.original.project ?
        <div style={{float: "left", position: "relative", width: "40%", textAlign: "center"}}>
          <div style={{display: "inline-block", border: "2px solid darkgray", height: "200px", width: "300px",marginTop: "55px", textAlign: "left", padding: "30px 0 0 30px",backgroundColor: "lightgray"}}>
            <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
              <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.brand')}:</b>{row.original.project.brand}
            </div>
            <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
              <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.product')}:</b>{row.original.project.product}
            </div>
            <div style={{float: "left", position: "relative", width: "100%", margin: "5px 0 5px 0"}}>
              <b style={{fontSize: "16px", float: "left", width: "100px"}}>{localization.get('music.license_dialog.campaign')}:</b>{row.original.project.title}
            </div>
          </div>
        </div>
      : null}

    </div>
  )
}