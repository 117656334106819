import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
    translations,
    visibility,
    selectedClient,
    selectedClients,
    selectedBlogLocations,
    image,
  }) =>
  post(`${host}/blogPosts`, makeFormData({
      translations,
      visibility,
      selectedClient,
      selectedClients,
      selectedBlogLocations,
      image,
  }))
  .then(response => response.json())