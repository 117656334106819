import React from "react";
import _ from "lodash";
import Checkbox, {SecondaryCheckbox} from "../components/Checkbox";
import {countriesForRegionToString, regionIdToString} from "../utils/mappers";
import {CountriesForRegion, RegionIds} from "../utils/constants";
import localization from "../../../../config/localization"

const RegionSelectionScreen = ({ selectedRegions, onChange }) => {
  const makeCheckbox = regionId => {
    const region = selectedRegions.find(selectedRegion => selectedRegion.id === regionId);
    const checked = Boolean(region);

    const label = (
      <div className="license-wizard-main-checkbox-label-with-description">
        <div className="description" />
        <div className="label">{regionIdToString(regionId)}</div>
      </div>
    );

    const makeCountriesPerRegionCheckbox = countriesForRegion => (
      <SecondaryCheckbox
        label={countriesForRegionToString(countriesForRegion)}
        onClick={() => {
          region.countriesForRegion = countriesForRegion;
          onChange(selectedRegions);
        }}
        checked={region.countriesForRegion === countriesForRegion}
      />
    );

    const showCountriesPerRegionCheckboxes =
      checked &&
      region.id !== RegionIds.ARGENTINA &&
      region.id !== RegionIds.NORTH_AMERICA &&
      region.id !== RegionIds.GLOBAL;

    const globalRegionSelected = selectedRegions.some(sr => sr.id === RegionIds.GLOBAL);

    return (
      <div>
        <Checkbox
          checked={globalRegionSelected || checked}
          content={label}
          disabled={regionId !== RegionIds.GLOBAL && globalRegionSelected}
          onClick={() => {
            if (checked) {
              onChange(_.filter(selectedRegions, selectedRegion => selectedRegion.id !== regionId));
            } else {
              if (regionId === RegionIds.GLOBAL) {
                onChange([{ id: regionId }]);
              } else {
                onChange(
                  _.concat(selectedRegions.filter(sr => sr.id !== RegionIds.GLOBAL), {
                    id: regionId,
                    countriesForRegion: CountriesForRegion.ONE
                  })
                );
              }
            }
          }}
        />
        {showCountriesPerRegionCheckboxes && (
          <div className="license-wizard-countries-per-region-checkbox-container">
            {makeCountriesPerRegionCheckbox(CountriesForRegion.ONE)}
            {makeCountriesPerRegionCheckbox(CountriesForRegion.TWO)}
            {makeCountriesPerRegionCheckbox(CountriesForRegion.THREE)}
            {makeCountriesPerRegionCheckbox(CountriesForRegion.ALL)}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h3 style={{ marginLeft: 20 }}>{localization.get('license_wizard.regions')}</h3>
      {makeCheckbox(RegionIds.NORTH_AMERICA)}
      {makeCheckbox(RegionIds.LATAM)}
      {makeCheckbox(RegionIds.EUROPE)}
      {makeCheckbox(RegionIds.ASIA)}
      {makeCheckbox(RegionIds.PACIFIC)}
      {makeCheckbox(RegionIds.GLOBAL)}
    </div>
  );
};

export default RegionSelectionScreen;
