import {CountriesForRegion, Exclusivity, LicenseTypeIds, MediaTypeIds, RegionIds} from "./constants";
import _ from "lodash";
import fakePrices from "./fakePrices";
import {regionIdToExtents} from "./mappers";
import SadaicPriceCalculator from "./SadaicPriceCalculator";
import {isNullOrUndefined} from "util";

class PriceCalculator {
  
  static makeReductionRangesFrom = obj =>
    _.entries(obj).map(x => {
      const split = x[0].split("-");
      return {
        from: parseInt(split[0]),
        to: parseInt(split[1]),
        surcharge: x[1].surcharge,
      };
    });

  static calculateReductionsPrice = (originalPrice, reductions, ranges) => {
    const reductionsArray = [];
    for (let i = 0; i <= reductions; i++) {
      reductionsArray.push(i);
    }
    return reductionsArray.reduce((acc, next) => {
      const range = ranges.find(x => next >= x.from && (isNaN(x.to) || next <= x.to));
      if (range) {
        return acc + range.surcharge * originalPrice;
      } else {
        return acc;
      }
    }, 0);
  };

  constructor(prices = fakePrices) {
    this.prices = prices;
  }

  calculateSadaicPrice = ({ sadaicVersions, sadaicCategory, sadaicDuration }) =>
    new SadaicPriceCalculator(this.prices, sadaicVersions, sadaicCategory, sadaicDuration).calculate();

  calculate = ({
    licenseType,
    reductions,
    unlimitedReductions,
    medias,
    internetBudget,
    regions,
    exclusivity,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client,
  }) => {

    const prices = this.prices;

    const ownChannelsOutput = this.makeOwnChannelsOutput(
      licenseType,
      unlimitedReductions,
      reductions,
      prices,
      client
    );

    const publicityInstitutionalOutput = this.makePublicityOutput(
      licenseType,
      medias,
      internetBudget,
      prices,
      reductions,
      exclusivity,
      biChannel,
      editionAccordingToSeconds,
      regions,
      extraDuration,
      client
    );

    return this.sumOutputs(ownChannelsOutput, publicityInstitutionalOutput);
  };

  makeOwnChannelsOutput = (licenseType, unlimitedReductions, reductions, prices, client) => {

    let output = this.makeEmptyOutput()

    if (licenseType === LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS) {

      if (unlimitedReductions) {

        const unlimitedReductionsPrice = prices.license_type[1].unlimited_reductions.credits;
        
        output = this.makeOutput(unlimitedReductionsPrice, client, 'own_channels_discount');

      } else {

        const credits = prices.license_type[1].credits;
        const reductionRanges = PriceCalculator.makeReductionRangesFrom(prices.license_type[1].reductions);
  
        output = this.sumOutputs(
          this.makeOutput(credits, client, 'own_channels_discount'),
          this.makeOutput(PriceCalculator.calculateReductionsPrice(credits, reductions, reductionRanges), client, 'own_channels_reductions_discount')
        )
      }

      if (!isNullOrUndefined(client.licensePlan.fee_amount)) {
        output.final = 0
        output.discounted = output.total
      }
    }

    return output
  };

  makePublicityOutput = (
    licenseType,
    medias,
    internetBudget,
    prices,
    reductions,
    exclusivity,
    biChannel,
    editionAccordingToSeconds,
    regions,
    extraDuration,
    client
  ) => {

    if (licenseType === LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL) {

      let internetPrice = this.makeEmptyOutput(), radioPrice = this.makeEmptyOutput();

      if (medias.includes(MediaTypeIds.INTERNET) && medias.includes(MediaTypeIds.RADIO)) {

        radioPrice = this.makeRadioAndInternetOutput(
          internetBudget,
          regions,
          prices,
          reductions,
          biChannel,
          editionAccordingToSeconds,
          extraDuration,
          client
        );
      } else {

        internetPrice = this.makeInternetOutput(
          medias,
          internetBudget,
          prices,
          reductions,
          exclusivity,
          biChannel,
          editionAccordingToSeconds,
          client
        );

        radioPrice = this.makeRadioOutput(
          medias,
          regions,
          prices,
          reductions,
          biChannel,
          editionAccordingToSeconds,
          extraDuration,
          client
        );
      }

      const televisionPrice = this.makeTelevisionOutput(
        medias,
        regions,
        prices,
        reductions,
        biChannel,
        editionAccordingToSeconds,
        extraDuration,
        client
      );

      const allMediaPrice = this.makeAllMediaOutput(
        medias,
        regions,
        prices,
        reductions,
        biChannel,
        editionAccordingToSeconds,
        extraDuration,
        client
      );

      return this.sumOutputs(internetPrice, radioPrice, televisionPrice, allMediaPrice)
    }

    return this.makeEmptyOutput();
  };

  makeInternetOutput = (
    medias,
    internetBudget,
    prices,
    reductions,
    exclusivity,
    biChannel,
    editionAccordingToSeconds,
    client
  ) => {

    if (medias.includes(MediaTypeIds.INTERNET)) {

      const internet = prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].media[MediaTypeIds.INTERNET];

      const internetPrice = internet.budget[internetBudget].credits

      const outputs = [
        this.makeOutput(internetPrice, client, 'publicity_internet_discount'),
        this.makeAdditionalsOutput(internet, biChannel, editionAccordingToSeconds, client)
      ]

      exclusivity === Exclusivity.THREE_MONTHS && outputs.push(this.makeOutput(internet.budget[internetBudget].exclusivity.credits, client, 'duration_internet_discount'))
      reductions > 0 && outputs.push(this.makeOutput(PriceCalculator.calculateReductionsPrice(internetPrice, reductions, PriceCalculator.makeReductionRangesFrom(internet.reductions)), client, 'reductions_discount'))

      return this.sumOutputs(...outputs)
    }

    return this.makeEmptyOutput();
  };

  makeRadioAndInternetOutput = (
    internetBudget,
    regions,
    prices,
    reductions,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client
  ) => {

    const internet = prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].media[MediaTypeIds.INTERNET];
    const radio = prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].media[MediaTypeIds.RADIO];

    const radioPrice = this.calculateMediaRegionsPrice(radio, regions)
    const extraDurationPrice = radioPrice * prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].extension.surcharge
    const reductionsPrice = PriceCalculator.calculateReductionsPrice(radioPrice, reductions, PriceCalculator.makeReductionRangesFrom(radio.reductions))

    const outputs = [
      this.makeOutput(internet.budget[internetBudget].credits, client, 'publicity_internet_discount'),
      this.makeOutput(radioPrice, client,"publicity_radio_discount"),
      this.makeAdditionalsOutput(radio, biChannel, editionAccordingToSeconds, client)
    ]

    extraDuration && outputs.push(this.makeOutput(extraDurationPrice, client, "duration_radio_discount"))
    reductions > 0 && outputs.push(this.makeOutput(reductionsPrice,client,"reductions_discount"))

    return this.sumOutputs(...outputs)
  };

  makeRadioOutput = (
    medias,
    regions,
    prices,
    reductions,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client
  ) =>
    this.makeMediaWithRegionsOutput(
      MediaTypeIds.RADIO,
      medias,
      regions,
      prices,
      reductions,
      biChannel,
      editionAccordingToSeconds,
      extraDuration,
      client
    );

  makeTelevisionOutput = (
    medias,
    regions,
    prices,
    reductions,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client
  ) =>
    this.makeMediaWithRegionsOutput(
      MediaTypeIds.TELEVISION,
      medias,
      regions,
      prices,
      reductions,
      biChannel,
      editionAccordingToSeconds,
      extraDuration,
      client
    );

  makeAllMediaOutput = (
    medias,
    regions,
    prices,
    reductions,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client
  ) =>
    this.makeMediaWithRegionsOutput(
      MediaTypeIds.ALL_MEDIA,
      medias,
      regions,
      prices,
      reductions,
      biChannel,
      editionAccordingToSeconds,
      extraDuration,
      client
    );

  makeMediaWithRegionsOutput = (
    mediaType,
    medias,
    regions,
    prices,
    reductions,
    biChannel,
    editionAccordingToSeconds,
    extraDuration,
    client
  ) => {

    if (medias.includes(mediaType)) {
  
      const media = prices.license_type[2].media[mediaType];

      const mediaPrice = this.calculateMediaRegionsPrice(media, regions)

      const publicityDiscountName = (() => {
        switch(mediaType) {
          case MediaTypeIds.TELEVISION:
            return 'publicity_tv_discount'
          case MediaTypeIds.RADIO:
            return 'publicity_radio_discount'
          case MediaTypeIds.ALL_MEDIA:
            return 'publicity_all_discount'
        }
      })()

      const outputs = [
        this.makeOutput(mediaPrice, client, publicityDiscountName),
        this.makeAdditionalsOutput(media, biChannel, editionAccordingToSeconds, client)
      ]

      const durationDiscountName = (() => {
        switch(mediaType) {
          case MediaTypeIds.RADIO:
            return 'duration_radio_discount'
          case MediaTypeIds.TELEVISION:
          case MediaTypeIds.ALL_MEDIA:
            return 'duration_tv_all_discount'
        }
      })()

      if (extraDuration) {
        outputs.push(this.makeOutput(mediaPrice * prices.license_type[LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL].extension.surcharge, client, durationDiscountName))
      }

      if (reductions > 0) {
        outputs.push(this.makeOutput(PriceCalculator.calculateReductionsPrice(mediaPrice, reductions, PriceCalculator.makeReductionRangesFrom(media.reductions)), client, 'reductions_discount'))
      }

      return this.sumOutputs(...outputs)
    }

    return this.makeEmptyOutput()
  };

  calculateMediaRegionsPrice = (media, regions) => {

    let price = 0;

    const calculateRegionPrice = (regionId, extendId) => this.calculateRegionPrice(media, regions, regionId, extendId)
    const calculateRegionWithCountriesForRegionPrice = (regionId, extendIds) => this.calculateRegionWithCountriesForRegionPrice(media, regions, regionId, extendIds);

    price += calculateRegionPrice(RegionIds.GLOBAL, regionIdToExtents(RegionIds.GLOBAL)[0]);
    price += calculateRegionPrice(RegionIds.NORTH_AMERICA,regionIdToExtents(RegionIds.NORTH_AMERICA)[0]);

    price += calculateRegionWithCountriesForRegionPrice(RegionIds.LATAM, regionIdToExtents(RegionIds.LATAM));
    price += calculateRegionWithCountriesForRegionPrice(RegionIds.EUROPE,regionIdToExtents(RegionIds.EUROPE));
    price += calculateRegionWithCountriesForRegionPrice(RegionIds.ASIA, regionIdToExtents(RegionIds.ASIA));
    price += calculateRegionWithCountriesForRegionPrice(RegionIds.PACIFIC, regionIdToExtents(RegionIds.PACIFIC));

    return price;
  };

  calculateRegionPrice = (configMedia, regions, regionId, extendId) => {
    if (regions.some(x => x.id === regionId)) {
      return configMedia.extent[extendId].credits;
    }
    return 0;
  };

  calculateRegionWithCountriesForRegionPrice = (configMedia, regions, regionId, extendIds) => {

    if (regions.some(x => x.id === regionId)) {

      const region = regions.find(x => x.id === regionId);

      const extendId = (() => {
        switch (region.countriesForRegion) {
          case CountriesForRegion.ONE:
            return extendIds[0]
          case CountriesForRegion.TWO:
            return extendIds[1]
          case CountriesForRegion.THREE:
            return extendIds[2]
          case CountriesForRegion.ALL:
            return extendIds[3]
        }
      })()

      return configMedia.extent[extendId].credits    
    }

    return 0;
  };

  makeAdditionalsOutput = (configMedia, biChannel, editionAccordingToSeconds, client) =>
    this.sumOutputs(
      biChannel ? this.makeOutput(configMedia.bichannel.credits, client, "bichannel_discount") : {},
      editionAccordingToSeconds ? this.makeOutput(configMedia.edition.credits, client, "edition_discount") : {}
    );

  makeOutput = (total, client, discountName) => {
    const output = {};
    output.total = total;
    output.final = output.total * (1 - client.licensePlan[discountName]);
    output.discounted = output.total - output.final;
    return output;
  };

  makeEmptyOutput = () => ({ total: 0, final: 0, discounted: 0 })

  sumOutputs = (...outputs) =>{
    return outputs.reduce(
      (acc, next) => ({
        total: acc.total + (next.total || 0),
        final: acc.final + (next.final || 0),
        discounted: acc.discounted + (next.discounted || 0),
      }),
      this.makeEmptyOutput()
    );}
}

export default PriceCalculator;
