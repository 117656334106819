import {post} from '../utils'
import {makeFormData} from './utils'

export default host => ({
  artistDemoId,
  content
}) => post(`${host}/artists/${artistDemoId}/comments`, makeFormData({
  content
}))
  .then(response => response.json())
