import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";
import localization from "../../../config/localization";
import { Delete,  Edit } from "@material-ui/icons";
import {request} from "../../../api-client";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@material-ui/core";
import TextField from "../../../components/Letflow/Form/TextField";
import AsyncSelect from "react-select/lib/Async";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar.jsx";
import {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar.jsx";
import {get} from "lodash";

class MusicDealers extends Component {
  state = {
    perPage: 20,
    page: 0,
    openFormDialog: false,
    editingMusicDealer: null,
    name: "",
    selectedTagSystem: null,
    selectedTagSystems: []
  };

  componentDidMount = () => {
    document.title = localization.get("channel.community") + " - Feater";
  };

  buttons = musicDealer => {
    const {id, name} = musicDealer;

    const buttonBuilder = new TableButtonsBuilder();
  

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('edit')}
        onClick={() => {
          const selectedTagSystems = [{value: 1, label: "Generico", cleareable: false}];    
          if (musicDealer.tagSystem) {
            selectedTagSystems.push({value: musicDealer.tagSystem.id, label: musicDealer.tagSystem.name})
          }
            this.setState({
              openFormDialog: true,
              editingMusicDealer: musicDealer,
              name: musicDealer.name,
              selectedTagSystems: selectedTagSystems,  
            })
          }
        }
      >
        <Edit/>
      </TableButton>
    )

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('delete')}
        onClick={() =>
          GlobalConfirmationDialog.showDelete({
            dialogTitle: `${localization.get('tooltip.delete')} ${name}`,
            content: localization.get('confirmation_dialog.delete_content_message'),
            doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
            elementName: localization.get("delete"),
            deleteRequest: () => request.musicDealer.delete(id)
              .then(this.refs.table.forceUpdate),
            doubleConfirmation: true,
            auxiliaryInformation: "",
          })
        }
      >
        <Delete />
      </TableButton>
    )

    return buttonBuilder.build();
  }

  onCloseDialog= () => this.setState({openFormDialog: false, name: "", editingMusicDealer: null, selectedTagSystem: null})
  
  makeFormDialog = () => 
  <Dialog 
      open={this.state.openFormDialog} 
      onBackdropClick={this.onCloseDialog} 
      onEscapeKeyDown={this.onCloseDialog}
    >
      <DialogTitle>{localization.get(this.state.editingMusicDealer ? "table.edit_music_dealer" : 'table.create_music_dealer')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", minWidth: 400, minHeight: 250}}>
          <TextField
            id="name"
            name="name"
            onChange={e => this.setState({name: e.target.value})} 
            value={this.state.name}
            label={localization.get("name")}
          />
          <br/>
          <div style={{ width: "100%"}}>
            <span style={{textAlign: "left"}}>{localization.get('tag_system')}</span>
            <AsyncSelect
              style={{width: "100%"}}
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              fullWidth
              placeholder={localization.get('tag_groups')}
              loadingMessage={() => localization.get('loading')}
              value={this.state.selectedTagSystems}
              noOptionsMessage={() => localization.get('no_options')}
              defaultOptions
              isMulti
              onChange={(values) => {
                if (! values.find(v => v.value == 1)) {
                    GlobalSnackbar.show({ 
                      message: localization.get('form.music_dealer_default_required'),
                      type: GlobalSnackbarTypes.ERROR,
                    });
                    return;
                } 
                const oldTagSystem = this.state.selectedTagSystems.find(v => v.value != 1);

                if (oldTagSystem) {
                  return this.setState({selectedTagSystems: values.filter(v => v.value != oldTagSystem.value) });
                }
                
                return this.setState({selectedTagSystems: values});
              }}
              loadOptions={inputValue => {
                let filters = [{column: "name", filter: inputValue}]

                return request.tagSystem.getAll({filterBy: filters})
                  .then(tagSystems => tagSystems.filter(tagSystem => tagSystem.id != 1).map(tagSystem => ({
                    value: tagSystem.id,
                    label: tagSystem.name
                  })))
                  .then(tagSystems => tagSystems.sort((a, b) => a.label.localeCompare(b.label)))
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCloseDialog}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.handleSubmit()}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>


  handleSubmit = () => {
    const selectedTagSystem = this.state.selectedTagSystems.find(v => v.value != 1);

    if(this.state.editingMusicDealer) {
      return request.musicDealer.update(this.state.editingMusicDealer.id, {name: this.state.name, tag_system_id: get(selectedTagSystem, "value", null)})
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
    } 

    return request.musicDealer.create({name: this.state.name, tag_system_id: get(selectedTagSystem, "value", null)})
        .then(this.refs.table.forceUpdate)
        .then(this.onCloseDialog)
  }

  columns = () => [
    {
      Header: localization.get("table.name"),
      accessor: musicDealer =>  <Tooltip title={musicDealer.name}><p>{musicDealer.name}</p></Tooltip>,
      id: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            if(event.keyCode == 13) {
              onChange(this.state.title)
            }else {
              this.setState({title: event.target.value || ""})
            }
          }}
          value={this.state.title ? this.state.title : ""}
          placeholder={localization.get("table.search")}
        />
      ),
    },
    {
      id: "tagSystem[name]",
      Header: localization.get("tag_system"),
      accessor: "tagSystem.name",
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.tagSystem
            .getAll({
              filterBy: [{
                column: 'name',
                filter
              }]
            })
            .then(tagSystems => tagSystems.map(tagSystem => tagSystem.name))
          }
          onChange={onChange}
          filter={filter ? filter.value : ''}
        />
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  render = () => (
    <AllViewContainer ref="viewContainer" onAdd={() => this.setState({openFormDialog: true, selectedTagSystems: [{value: 1, label: "Generico"}]})}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
                {this.makeFormDialog()}
                <div style={{ width: "100%", float: "left", position: "relative" }}>
                <ServerSidePaginationTable
                    ref="table"
                    columns={this.columns()}
                    requestTableData={async paginationConfig => {
                    paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'dashboard', filter: "true" }])};

                    this.setState({ paginationConfig })

                    return request.musicDealer.getAll(paginationConfig, "")
                        .then(response => {
                          this.setState({total: response.meta.total});
                          return response;
                      });
                    }}
                />
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </AllViewContainer>
  );
}

export default MusicDealers;
