import React, {Component} from "react";
import {request} from "../../api-client";
import MessageCreator from "./MessageCreator";
import {Button} from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import {NewReleases} from "@material-ui/icons";
import {getStoredUser} from "../../api-client/core/authentication/utils";
import EventManager from "../../utils/EventManager";
import events from "../../utils/EventManager/events";
import localization from "../../config/localization"

const PER_PAGE = 999; // Don't paginate

export default class extends Component {
  state = {
    conversation: null,
    messages: [],
    responseContent: "",
    requestingMessages: false,
    sendingMessage: false
  };

  componentDidMount = () => {
    this.fetchMessages();
    this.fetchConversation();
  };

  conversationId = () => this.props.conversationId;

  fetchConversation = () => {
    request.messaging.conversation(this.conversationId()).then(res => this.setState({ conversation: res }));
  };

  fetchMessages = () => {
    this.setState({ requestingMessages: true });
    return request.messaging
      .messages(this.conversationId(), { page: 0, perPage: PER_PAGE })
      .then(res => {
        this.setState({ messages: res.data });
        if (res.data.some(message => !message.seen)) {
          request.messaging
            .markMessagesAsSeen({
              message_ids: res.data.filter(message => !message.seen).map(message => message.id)
            })
            .then(() => {
              EventManager.getInstance().dispatch(events.UPDATE_HOME_NOTIFICATIONS);
              EventManager.getInstance().dispatch(
                events.UPDATE_DASHBOARD_NOTIFICATIONS,
                "conversationsWithUnseenMessages"
              );
            });
        }
      })
      .finally(() => this.setState({ requestingMessages: false }));
  }

  sendMessage = () => {
    this.setState({ sendingMessage: true });
    return request.messaging
      .sendMessage({
        conversation: this.state.conversation.id,
        content: this.state.responseContent
      })
      .then(() => this.fetchMessages())
      .finally(() => this.setState({ sendingMessage: false, responseContent: "" }));
  };

  Message = ({ message, userNameContainerStyle, userNameColor, cardBodyStyle }) =>
    <div style={{ padding: "5px 0" }}>
      <Card>
        <CardHeader color="primary" text>
          <CardText style={userNameContainerStyle} color="primary">
            {!message.seen && (
              <div style={{ position: "absolute", right: -32 }}>
                <NewReleases style={{ color: "red" }} />
              </div>
            )}
            <span style={{ marginRight: 20, color: userNameColor }}>{message.user.name}</span>
          </CardText>
        </CardHeader>
        <CardBody style={cardBodyStyle}>
          <div>
            <i>{message.created_at}</i>
          </div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: message.content }} />
        </CardBody>
      </Card>
    </div>;

  MyMessage = ({ message }) =>
    <this.Message
      message={message}
      userNameContainerStyle={{
        background: "linear-gradient(60deg, #000000, #1f1f1f)",
        boxShadow: "none"
      }}
      userNameColor='white'
    />

  OtherMessage = ({ message }) =>
    <this.Message
      message={message}
      userNameContainerStyle={{
        background: "white",
        boxShadow: "0 0 3px 0 grey",
        float: 'right'
      }}
      userNameColor='black'
      cardBodyStyle={{
        textAlign: 'right'
      }}
    />

  Loader = () => (
    <div
      style={{
        backgroundColor: "#00000014",
        width: "100%",
        height: "100%",
        position: "absolute",
        borderRadius: "10px",
        transform: "scale(1.03)",
        zIndex: 200
      }}
    />
  );

  MessageInput = () => (
    <div>
      <MessageCreator
        content={this.state.responseContent}
        onChange={e => this.setState({ responseContent: e })}
        conversationId={this.conversationId()}
      />
      <br />
      <Button
        style={{ width: '100%' }}
        variant="contained"
        disabled={this.state.responseContent.length === 0 || this.state.sendingMessage}
        onClick={this.sendMessage}
      >
        {localization.get('conversation.send')}
      </Button>
    </div>
  );

  render = () => {
    return (
      <div style={{ marginBottom: 100 }}>
        {this.state.conversation && (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40 }}>
            <div>
              <b>{localization.get('conversation.subject')}:</b> {this.state.conversation.subject}
            </div>
            <div >
              <b>{localization.get('conversation.participants')}:</b> <u><i>{this.state.conversation.created_by.name}</i></u>, {this.state.conversation.users.map(x => x.name).join(", ")}
            </div>
          </div>
        )}
        <this.MessageInput />
        <div style={{ position: "relative" }}>
          {this.state.requestingMessages && <this.Loader />}
          {this.state.messages.map(message => {

            const MessageComponent =
              message.user.id === getStoredUser().id
                ? this.MyMessage
                : this.OtherMessage

            return <MessageComponent key={message.id} message={message} />
          })}
        </div>
      </div>
    );
  };
}
