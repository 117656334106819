import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {request} from "../../api-client";
import localization from "../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import './login.css'
import queryString from 'querystring'
import PublicNavBar from "../../layouts/Home/HomeElements/PublicNavBar";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {validateEmail} from "../../utils";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      keepLogged: "keep",
      inputType: "password"
    };
  }

  componentDidMount() {
    if(!localStorage.getItem("keepLogged")) {
      localStorage.setItem("keepLogged", this.state.keepLogged)
    }else {
      this.setState({keepLogged: localStorage.getItem("keepLogged")})
    }
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    document.title = localization.get('title.login') + ' - Feater'
  }

  login = () => {
    if(this.state.username && this.state.password) {
      if (validateEmail(this.state.username)) {
        this.setState({ requesting: true })
        request.authentication.login({username: this.state.username, password: this.state.password})
          .then(() => {
            let redirectTo = queryString.parse(this.props.location.search)['?redirect_to']
            if (redirectTo) {
              window.location.href = redirectTo
            } else {
              window.location.reload()
            }
          })
          .catch(e => {
            this.setState({requesting: false})
              GlobalSnackbar.show({
                message: e.status === 429 ? localization.get('login.user_blocked', e.error) : localization.get('login.login_failed'),
                type: GlobalSnackbarTypes.ERROR,
              })
          })
    }else {
        GlobalSnackbar.show({
          message: localization.get('login.email_invalid'),
          type: GlobalSnackbarTypes.ERROR,
        })
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PublicNavBar/>
        <div className={classes.content} style={{minHeight: "calc(100vh - 147px)"}}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="info"
                      style={{ background: "black", boxShadow: "none" }}
                    >
                      <h4 className={classes.cardTitle}>Log in</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ username: e.target.value })
                        }}
                      />
                      <CustomInput
                        labelText={localization.get('password')}
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          onChange: e => this.setState({ password: e.target.value }),
                          type: this.state.inputType,
                          onKeyPress: e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              this.login()
                            }
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="inputType"
                            checked={this.state.inputType === "text"}
                            onChange={() => {
                              this.setState({ inputType: this.state.inputType === "text" ? "password" : "text"})
                            }}
                          />
                        }
                        label={localization.get('show_password')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="keepLogged"
                            checked={this.state.keepLogged === "keep"}
                            onChange={() => {
                              this.setState({ keepLogged: this.state.keepLogged === "keep" ? "notKeep" : "keep"},
                                () => localStorage.setItem("keepLogged", `${this.state.keepLogged}`))
                            }}
                          />
                        }
                        style={{marginBottom: 10}}
                        label={localization.get('keep_logged')}
                      />
                      <Link to="/password/forgot">
                        <p style={{ textAlign: "center", color: "black", fontSize: "0.875rem" }}>{localization.get('forgot_password')}</p>
                      </Link>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button onClick={this.login} color="default" style={{ backgroundColor: "black" }} simple size="lg" block disabled={this.state.requesting}>{localization.get('enter')}</Button>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {/* <Footer noSocials={true}/> */}
      </React.Fragment>
    )
      ;
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
