import React, {Component} from "react";
import {ServerSidePaginationTable} from "../../components/Letflow/Table";
import {request} from "../../api-client";
import localization from "../../config/localization";
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip} from "@material-ui/core";
import "./table.css";
import AddCatalogDialog from "../Clients/All/AddCatalogDialog";
import {Done, CloudDownload} from "@material-ui/icons";

class CatalogAccessTable extends Component {
  state = {
    total: "",
    managedBy: eval(localStorage.getItem("managedBy")) || 'all',
    type: eval(localStorage.getItem("type")) || 'all',
    catalogs: [],
    clients: []
  };

  componentDidMount = () => {
    this.requestCatalogs(this.state.type)
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if(this.state.type !== nextState.type) {
      if(nextState.type !== 'all') {
        this.requestCatalogs(nextState.type)
      }else {
        this.requestCatalogs('all')
      }
    }
  }

  requestCatalogs = type => {
    if(type !== 'all') {
      return request.catalog.getAll({
        filterBy: [
          {column: 'type', filter: type},
          {column: "for_reports", filter: "1"}
        ],
        orderBy:  {column: "name", type: "asc"}
      }).then(catalogs => this.setState({catalogs}))
    }else {
      return request.catalog.getAll({orderBy: {column: "name", type: "asc"}, filterBy: [{column: "for_reports", filter: "1"}]}).then(catalogs => this.setState({catalogs}));
    }
  }

  reloadTable = () => this.refs.table.forceUpdate();

  handleAlertClosed = musicId => status => {
    if (status === "passed") this.refs.table.removeById(musicId);
  };

  generateDownloadLink = () =>  {
    request.authentication.refreshAccessToken();
    let paginationConfig = {filterBy: []}

    if(this.state.managedBy !== 'all') {
      paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'managedBy[name]', filter: this.state.managedBy === 'twins'? 'Twins%20Music' : 'Faeter' }]) }
    }

    if(this.state.type !== 'all') {
      paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'type', filter: this.state.type }]) }
    }

    paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([ {column: "for_reports", filter: "1"} ]) }

    return request.client.exportCatalogAccess(paginationConfig)
      .then(url => window.open(url, "_blank"))
  }

  columnHint = (e, clientName=null) => {
    let left = e.clientX + "px";
    let top = e.clientY + "px";

    let div = document.getElementById('column-hint');
    div.style.left = left;
    div.style.top = top;

    if(clientName) {
      div.innerHTML = `<span>${clientName}</span>`
      setTimeout(() => div.style.display = "block", 300)
    }else {
      div.style.display = "none"
    }

    return false;
  }

  hideColumnHint = () => document.getElementById('column-hint').style.display = "none";

  columns = () => {
    let columns = this.state.catalogs.map(catalog => {
      const catalogId = catalog.id
      return {
        Header: <span className={'wordwrap-span'}>{catalog.name}</span>,
        headerClassName: "wordwrap",
        id: `${catalog.id}`,
        accessor: catalog =>  catalog[catalogId] ? <Done /> : "-",
        style: { textAlign: 'center'},
        sortable: false,
        filterable: false
      }
    })

    const nameColumn =
      {
        Header: localization.get("clients"),
        headerClassName: "wordwrap",
        accessor: "name",
        sortable: false,
        filterable: false,
        width: 250
      };

    columns.unshift(nameColumn);

    return columns
  }

  render = () => {
    return (
      <div>
        <FormControl style={{float: "left", position: "relative"}}>
          <RadioGroup
            name="managedBy"
            value={this.state.managedBy}
            onChange={event => {
              localStorage.setItem("managedBy", `"${event.target.value}"`)
              this.setState({ managedBy: event.target.value })
              this.refs.table.forceUpdate();
            }}
            style={{ float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('client.all')} />
            <FormControlLabel value="bloomclouds" control={<Radio color="default" />} label={localization.get('client.flowlike')} />
            <FormControlLabel value="twins" control={<Radio color="default" />} label={localization.get('client.twins')} />
          </RadioGroup>
        </FormControl>
        <FormControl style={{ float: "left", position: "relative", marginLeft: 80}}>
          <RadioGroup
            name="type"
            value={this.state.type}
            onChange={event => {
              localStorage.setItem("type", `"${event.target.value}"`)
              this.setState({ type: event.target.value })
              this.refs.table.forceUpdate();
            }}
            style={{ float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('clients.all')} />
            <FormControlLabel value="music" control={<Radio color="default" />} label={localization.get('table.music')} />
            <FormControlLabel value="content" control={<Radio color="default" />} label={localization.get('table.content')} />
          </RadioGroup>
        </FormControl>
        <div style={{position: "relative", margin: 20}}>
          <Button onClick={this.generateDownloadLink} style={{float: "right"}}>
            <Tooltip title={localization.get('download_csv')}  placement='top'>
              <CloudDownload style={{float: "right", height: 40, marginTop: 7, width: 40, color: "rgb(51, 51, 51)"}} />
            </Tooltip>
          </Button>
      </div>
        <div style={{ width: "100%", float: "left", position: "relative", marginTop: 30 }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            showPaginationBottom={true}
            onMouseLeave={this.hideColumnHint}
            showPagination={false}
            requestTableData={paginationConfig => {
              if(this.state.managedBy !== 'all') {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'managedBy[name]', filter: this.state.managedBy === 'twins'? 'Twins%20Music' : 'Bloomclouds' }]) }
              }

              if(this.state.type !== 'all') {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'type', filter: this.state.type }]) }
              }
              paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([ {column: "for_reports", filter: "1"} ]) }

              return request.client.clientCatalogs(paginationConfig);
            }}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e) => {
                    this.hideColumnHint()
                    this.setState({
                      selected: rowInfo.index,
                      // clientId: rowInfo.original.id,
                      // showAddCatalogDialog: true
                    })
                  },
                  onMouseEnter: (e) => {
                    const event  = e
                    const name = rowInfo.original.name
                    this.columnHint(event, name)
                  },
                  onMouseLeave: this.hideColumnHint,
                  style: {
                    background: rowInfo.index === this.state.selected  && 'rgb(51, 51, 51, .8)',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black',
                    cursor: "pointer",
                    height: 30,
                  },
                }
              }else{
                return {}
              }
            }}
          />
        </div>
        <div id={'column-hint'} style={{position: "fixed", zIndex:100, background: "rgba(51,51,51,0.5)", padding: 5, color: "white", display: 'none'}}>

        </div>
        <AddCatalogDialog
          show={this.state.showAddCatalogDialog}
          clientId={this.state.clientId}
          handleClose={() => this.setState({showAddCatalogDialog: false}, this.reloadTable)}
        />
      </div>
    );
  };
}

export default CatalogAccessTable;
