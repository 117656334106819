import React, {Component} from "react";
import {request} from '../../../api-client'
import Index from "../Form";
import localization from '../../../config/localization'

export default class extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      trial: null,
    }
  }

  componentDidMount = () => {
    this.requestTrial()
  }

  trialId = () => this.props.match.params.id

  requestTrial = () => request.trial.get(this.trialId())
    .then(trial => {
        this.setState({ trial, showForm: true })
    })

  requestUpdate = data => request.trial.update({
    trialId: this.trialId(),
    state: data.state,
    comments: data.comments
  })

  render = () => {

    if (!this.state.showForm) {
      return null
    }

    const { user, state, comments } = this.state.trial

    return <Index
      {...this.props}
      name={user.name}
      email={user.email}
      state={state}
      comments={comments}
      submitRequest={this.requestUpdate}
      formTitle={localization.get('trial.update_trial')}
    />
  }
}
