import React, {Component} from "react";
import localization from '../../../config/localization'
import {request} from "api-client";
import FormView from "../Form";
import FormViewContainer from "../../../components/Letflow/ViewContainer/FormViewContainer";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {makeUrlWithTableParams} from "../../../utils";
import AvailableLanguages from "../../../config/localization/availableLanguages.json";

export default class extends Component {

  state = {
    channel: null,
    owner: '',
    productType: 'contentCreator',
    days: 30,
    currency: 'ARS',
    active: true,
    channels: [],
    priceArs: 0,
    priceUsd: 0,
    hasShipments: false,
    shipments: 'none',
    addresses: [],
    channelShipments: [],
    giftable: false,
    translations: AvailableLanguages.map(language => ({
      language: language,
      description: "",
    })),
  }

  onSubmitValidation = () => {
    if(!this.state.channel) {
      GlobalSnackbar.show({message: localization.get('must_complete_client'), type: GlobalSnackbarTypes.ERROR});
      return false
    }
    if(!this.state.owner) {
      GlobalSnackbar.show({message: localization.get('must_complete_owner'), type: GlobalSnackbarTypes.ERROR});
      return false
    }
    if(!this.state.priceUsd || this.state.priceUsd == 0) {
      GlobalSnackbar.show({message: localization.get('product.must_complete_price'), type: GlobalSnackbarTypes.ERROR});
      return false
    }

    if (!this.state.priceArs || this.state.priceArs == 0 ) {
      GlobalSnackbar.show({message: localization.get('product.must_complete_price'), type: GlobalSnackbarTypes.ERROR});
      return false
    }
    return true
  }

  requestCreation = () =>
    request.products.create(this.state.channel.value,
      {
        ...this.state,
        prices: [
          {
            price: this.state.priceArs,
            currency: "ARS"
          },
          {
            price: this.state.priceUsd,
            currency: "USD"
          }
        ],
        translations: this.state.translations,
        shipments: !this.state.hasShipments ? 'none' : this.state.shipments,
        addresses: (this.state.hasShipments && ['withdrawal', 'both'].includes(this.state.shipments)) ? this.state.addresses : [],
        channelShipments: (this.state.hasShipments && ['delivery', 'both'].includes(this.state.shipments)) ? this.state.channelShipments : [],
      }
    )
      .then(() => GlobalSnackbar.show({
        message: localization.get('snackbar.entity_update_success'),
        type: GlobalSnackbarTypes.SUCCESS
      }))
      .catch(() => GlobalSnackbar.showGenericError)
      .finally(() => this.props.history.push(makeUrlWithTableParams('/panel/products')))

  requestChannels = () =>
    request.channel.getAll({}, "include=addresses;channelShipments.prices")
      .then(res => res.map(channel => ({...channel, value: channel.id, label: `${channel.name}`})))
      .then(channels => {
        let selectedChannelFromLocalStorage = JSON.parse(localStorage.getItem("selectedChannel"));

        this.setState({channels: channels, channel: selectedChannelFromLocalStorage || null })
      })

  togglePresence = (target, id) => {
    let array = this.state[target];
    if (array.includes(id)) {
      array = array.filter(a => a != id);
    } else {
      array.push(id);
    }
    this.setState({[target]: array});
  }

  render = () => {
    const { disabled, channel, owner, productType, priceArs, priceUsd, days,  active, channels, translations, shipments, hasShipments, addresses, channelShipments, giftable } = this.state

    return (
      <div>
        <FormViewContainer
          title={localization.get('form.create_product')}
          onBack={() => this.props.history.goBack()}
          submitRequest={this.requestCreation}
          validation={this.onSubmitValidation}
          initializationRequest={this.requestChannels}
          onRequestingStateChanged={value => this.setState({ disabled: value })}
        >
          <FormView
            channel={channel}
            channels={channels}
            owner={owner}
            productType={productType}
            priceArs={priceArs}
            priceUsd={priceUsd}
            days={days}
            onChange={e => this.setState({[e.target.id]: e.target.value})}
            onChannelChange={channel => this.setState({ channel, owner: null, addresses: [], channelShipments: [] })}
            onOwnerChange={owner => this.setState({ owner })}
            onTypeChange={type => this.setState({productType: type, owner: null })}
            disabled={disabled}
            active={active}
            onActiveChange={() => this.setState({active: !active})}
            translations={translations}
            onChangeTranslation={(value, target, language) => {
              const translations = this.state.translations.map(tr => {
                if (tr.language === language) {
                  return {...tr, [target]: value}
                }
                return tr
              });

              this.setState({ translations });
            }}
            shipments={shipments}
            onShipmentsChange={shipments => this.setState({shipments: shipments })}
            hasShipments={hasShipments}
            onHasShipmentsChange={() => this.setState({hasShipments: !hasShipments})}
            addresses={addresses}
            channelShipments={channelShipments}
            onAddressChange={(id) => this.togglePresence('addresses', id)}
            onChannelShipmentChange={(id) => this.togglePresence('channelShipments', id)}
            onGiftableChange={() => this.setState({giftable: !giftable})}
            giftable={giftable}
          />
        </FormViewContainer>
      </div>
    )
  }
}
