import React from "react";
import noImage from "assets/img/Letflow/no-image.jpg";
import {ImagePicker, TextField} from "components/Letflow/Form";
import {getStoredUser, userIsSupervisor} from "../../api-client/core/authentication/utils";
import localization from "../../config/localization";
import {request} from "../../api-client";
import {getUpdateFormImageUrlFromItem} from "../../utils";
import {FormViewContainer} from "../../components/Letflow/ViewContainer";
import {Checkbox, FormControl, FormControlLabel, MenuItem} from "@material-ui/core";
import {getRedirectToDashboardStoredValue, setRedirectToDashboardStoredValue} from "../../routes/dashboard";
import {SelectValidator} from "react-material-ui-form-validator";
import AvailableLanguages from '../../config/localization/availableLanguages.json';

class UserProfile extends React.Component {
  state = {
    name: "",
    email: "",
    image: { src: null, file: null },
    user: undefined,
    disabled: false,
    oldPassword: "",
    password: "",
    language: "",
    redirectToDashboard: getRedirectToDashboardStoredValue()
  };

  componentDidMount = () => {
    document.title = localization.get("title.profile") + " - Feater";
  };

  requestUser = () =>
    new Promise((resolve, reject) => {
      const user = getStoredUser();
      if (!user) {
        reject();
      } else {
        this.setState(state => ({
          ...user,
          image: {
            ...state.image,
            src: getUpdateFormImageUrlFromItem(user)
          }
        }));
        resolve();
      }
    });

  requestUpdate = () => {

    const {
      id,
      name,
      image: { file },
      language,
    } = this.state;

    const data = {
      userId: id,
      name,
      imageFile: file,
      language,
    };

    return request.user.update(data).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  handleImageChange = image => this.setState({ image });

  imageProvider = () => this.state.image.src || noImage;

  handleRequestingStateChanged = disabled => this.setState({ disabled });

  RedirectToDashboardCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          checked={this.state.redirectToDashboard}
          onChange={() => {
            this.setState({ redirectToDashboard: !this.state.redirectToDashboard }, () => setRedirectToDashboardStoredValue(this.state.redirectToDashboard))
          }}
          style={{ color: "#4cc2d5" }}
        />
      }
      label={localization.get("dashboard.redirect_to_dashboard")}
    />
  );

  render = () => {

    const { name, email, disabled, oldPassword, password, language } = this.state;

    return (
      <div>
        <FormViewContainer
          onBack={() => this.props.history.goBack()}
          title={localization.get("form.update_profile")}
          initializationRequest={this.requestUser}
          submitRequest={this.requestUpdate}
          onRequestingStateChange={this.handleRequestingStateChanged}
        >
          {userIsSupervisor() && <this.RedirectToDashboardCheckbox />}
          <TextField
            label={localization.get("form.name")}
            id="name"
            value={name}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <br />
          <br />
          <FormControl fullWidth>
            <SelectValidator
              label={localization.get("form.language")}
              id="language"
              name="language"
              value={language}
              onChange={e => {
                e.target.id = "language";
                this.handleChange(e);
              }}
              validators={["required"]}
              errorMessages={[localization.get("validator.is_required")]}
            >
              {AvailableLanguages.map(lang => <MenuItem value={lang}>{localization.get(`language.${lang}`)}</MenuItem>)}
            </SelectValidator>
          </FormControl>
          <br />
          <br />
          <ImagePicker
            buttonText={localization.get("form.select_image")}
            onImageChange={this.handleImageChange}
            image={this.imageProvider()}
            disabled={disabled}
          />
        </FormViewContainer>
      </div>
    );
  };
}

export default UserProfile;
