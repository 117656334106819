import {request} from "../../../api-client";
import HomeElementGridContainer from "../../Home/HomeElements/HomeElementGridContainer";
import GridItem from "../../Home/HomeElements/GridItem";
import React, {Component} from "react";
import {
  channelPathGenerator,
  getStoredUser, getStoredChannel
} from "../../../api-client/core/authentication/utils";
import EventManager, {events} from "../../../utils/EventManager";
import localization from "../../../config/localization";
import browserHistory from "../../../utils/browserHistory";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import HomeElementCarousel from "../../Home/HomeElements/HomeElementCarousel";
import queryString from "querystring";

class ChannelPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: props.channel,
      contentCreatorsFavorites: [],
      playlist: null,
      format: 'grid',
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0
    let format = queryString.parse(this.props.location.search)['?format']

    this.requestPlaylist()
      .then(() => request.contentCreatorChannelFavorite.getAll())
      .then((contentCreatorsFavorites) => this.setState({format: format ? format : 'grid', contentCreatorsFavorites}))

    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)
  }

  requestPlaylist = () => request.playlist.getForChannel(this.props.match.params.playlistId, 'include=contentCreators;channel').then(playlist => this.setState({playlist}))

  addToMyList = ({id, type = 'album'}) => {
    if(!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator('login'))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    }else {
      let {favorites, contentCreatorsFavorites} = this.state
      switch (type) {
        case 'content_creator':
          contentCreatorsFavorites.push(id)
          request.contentCreatorChannelFavorite.add(id)
          break
      }
      this.setState({
        favorites,
        contentCreatorsFavorites,
      })
    }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToMyList)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromMyList)
  }

  removeFromMyList = ({id, type = 'album'}) => {
    let {contentCreatorsFavorites} = this.state
    switch (type) {
      case 'content_creator':
        if (contentCreatorsFavorites.indexOf(id) > -1) {
          contentCreatorsFavorites.splice(contentCreatorsFavorites.indexOf(id), 1)
        }
        request.contentCreatorChannelFavorite.remove(id)
        break
    }
    this.setState({ contentCreatorsFavorites})
  }

  renderPlaylistElements = () => {
    const Container = ({ children }) => <div ref={r => this.resultsTableTags = r} style={{
      float: 'left',
      position: 'relative',
      width: '100%',
      backgroundColor: "var(--main-color, white)",
    }}>{children}</div>
    let content;
    switch (this.state.format) {
      case "grid":
        content =
          <div className="smart-search-result-container">
            <HomeElementGridContainer
              {...this.props}
              maxPerRow={6}//
              homeElement={this.makeHomeElementProp(this.state.playlist.contentCreators)}
              dataType={'content_creator'}//
              showSectionTitle={false}
              shape={'square'}//
              fixedTitle={false}//
              withBackground={false}//
              sizes={{
                xs: 12,
                sm: 4,
                md: 3,
                lg: 3,
                xl: 3,
              }}
              showMore={!!this.state.playlist.contentCreators.length}
              rowsToShow={3}
              itemComponent={GridItem}//
            />
          </div>
        break;
      case "carousel":
        content = <HomeElementCarousel
          {...this.props}
          homeElement={this.makeHomeElementProp(this.state.playlist.contentCreators)}
          dataType={'content_creator'}
          onlyImage={false}
          numbered={false}
          shape={'square'}
          columns={4}
        />
        break;
    }

    return <Container>{content}</Container>
  }

  isFavorite = (item) => this.state.contentCreatorsFavorites ? this.state.contentCreatorsFavorites.includes(item.id) : false

  makeHomeElementProp = (contentCreators) => {
    return {
      ...contentCreators,
      items: {
        data: contentCreators.map(item => ({
          ...item,
          title: item.name,
          shortDescription: item.description,
          kind: "content_creator",
          favorite: this.isFavorite(item)
        }))
      }
    }
  }

  render = () => {
    return (
      <div id="innerinner" style={{marginBottom: '10px'}}>
        {!!this.state.playlist &&
          <>
            <h2 style={{textAlign: "center", fontSize: 30, marginTop: 40, color: "var(--main-font-color, inherit)"}}>{this.state.playlist.title}</h2>
            {
              this.renderPlaylistElements()
            }
          </>
        }
      </div>
    )
  }
}

export default ChannelPlaylist;