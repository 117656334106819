import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveChannel, getActiveClient, getStoredChannel} from "../authentication/utils";

const url = (host, contentCreatorId, options) => 
  appendPaginationQueryParamsToUrl(`${host}${(getStoredChannel() && getStoredChannel().visibility === "public") ? "/public": ""}/clients/${getActiveClient()}/channels/${getActiveChannel()}/contentCreations?&content_creator_id=${contentCreatorId}&include=tags.group;image;binary;video.thumbnail;audio.waveform;embededContent;closedCaptions.binary;translations`, {...options, alreadyHasQueryParams: true});

export default host => (contentCreatorId,  options) => 
  get(url(host, contentCreatorId, options))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
