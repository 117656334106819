import {post, put} from '../utils'
import {getActiveClient} from "../authentication/utils";
import {request} from "../../index";

export default host =>
  async (material, onProgress) => {
    const presignedUrlForm = new FormData()

    presignedUrlForm.append('fileId', material.fileId)

    if (material.contentCreationId){
      presignedUrlForm.append("content_creation_id", material.contentCreationId);
    }
    if (material.tags && material.tags.length > 0) {
      presignedUrlForm.append('tags', material.tags)
    }

    presignedUrlForm.append("title", material.title);
    presignedUrlForm.append("type", material.type);
    material.subtitle && presignedUrlForm.append('subtitle', material.subtitle)
    presignedUrlForm.append('public', material.public ? 1 : 0)
    material.visibility && presignedUrlForm.append('visibility', material.visibility)
    material.fileId && presignedUrlForm.append('fileId', material.fileId)
    material.batchFileId && presignedUrlForm.append('batch_file_id', material.batchFileId)

  return await post(
    `${host}/clients/${getActiveClient()}/files`,
    presignedUrlForm,
    null
  ).then(res => res.json())
}