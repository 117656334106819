import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import { Delete, Done, Clear, Visibility, CloudDownload } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  FormControl, FormControlLabel, Radio, RadioGroup,
} from "@material-ui/core";
import {makeImageUrlWithSize, onImageError} from "../../../layouts/Home/HomeElements/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import CustomShareContent from "../../ContentCreators/All/CustomShareContent";
import TableAutocompleteFilter from "../../../components/Letflow/Table/TableAutocompleteFilter";
import TextField from "../../../components/Letflow/Form/TextField";
import GlobalSnackbar, { GlobalSnackbarTypes } from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import TableSubcomponent from "./Subcomponent";
import {get} from "lodash"

class Table extends Component {
  state = {
    total: "",
    openCatalogDialog: false,
    openIntegrateDialog: false,
    contentCreatorId: '',
    contentCreatorType: '',
    selectedCatalog: '',
    types: [],
    showTagSelection: false,
    regionLock: 'false',
    showTagDialog: false,
    tagGroups: [],
    selectedTags: [],
    unselectedTags: [],
    loaded: false,
    name: null,
    active: null,
    content_creator_type_id: null,
    cloud: null,
    clouds: [],
    updateVisible: "keep",
    updateWeight: false,
    selectedContentCreatorType: null,
    weight: "keep",
    selectedRetags: [],
    updateCategory: false,
    openShareDialog: false,
    sharingCreator: null,
    updateCategoryCheck: false,
    declineReason: "",
    channel: null,
    withPending: "all",
  };

  handleAlertClosed = artistId => status => {
    if (status === "passed") this.refs.table.removeById(artistId);
  };

  buttons = contentCreator => {
    const {id, name, status} = contentCreator;

    const buttonBuilder = new TableButtonsBuilder();

    if (status == "pending" || status == "declined") {
        buttonBuilder.withOther(
          <TableButton
            title={localization.get("table.approve")}
            onClick={() => {
            this.setState({openIntegrateDialog: true, contentCreatorId: id})
            }}
          >
            <Done/>
        </TableButton>
        );
    }
        
    if (status == "pending" || status == "approved") {
        buttonBuilder.withOther(
          <TableButton
            title={localization.get("table.decline")}
            onClick={() => this.setState({openDeclineDialog: true, contentCreatorId: id})}
          >
            <Clear />
          </TableButton>
        );
    }
  

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('preview')}
        onClick={() =>
          this.setState({
            openShareDialog: true,
            sharingCreator: contentCreator,
          })
        }
      >
        <Visibility/>
      </TableButton>
    )

    buttonBuilder.withOther(
      <TableButton
        title={localization.get('delete')}
        onClick={() =>
          GlobalConfirmationDialog.showDelete({
            dialogTitle: `${localization.get('tooltip.delete')} ${name}`,
            content: localization.get('confirmation_dialog.delete_content_message'),
            doubleConfirmationText: localization.get('confirmation_dialog.delete_confirmation'),
            elementName: localization.get("delete"),
            deleteRequest: () => request.contentCreator.delete(id)
              .then(this.refs.table.forceUpdate),
            doubleConfirmation: true,
            auxiliaryInformation: "",
          })
        }
      >
        <Delete />
      </TableButton>
    )

    return buttonBuilder.build();
  };

  makeDeclineDialog = () =>
    <Dialog 
      open={this.state.openDeclineDialog} 
      onBackdropClick={() => this.setState({openDeclineDialog: false})} 
      onEscapeKeyDown={() => this.setState({openDeclineDialog: false})}
    >
      <DialogTitle>{localization.get('table.decline')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('table.decline.text')}</div>
          <TextField
            id="declineReason"
            name="declineReason"
            onChange={e => this.setState({declineReason: e.target.value})} 
            value={this.state.declineReason}
            multiline
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openDeclineDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.manageContentCreator('declined').then(() => this.setState({openDeclineDialog: false}))}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>

  makeIntegrateDialog = () =>
    <Dialog open={this.state.openIntegrateDialog} onBackdropClick={() => this.setState({openIntegrateDialog: false})} onEscapeKeyDown={() => this.setState({openIntegrateDialog: false})}>
      <DialogTitle>{localization.get('demo.integrate_to_platform')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('table.approve.text')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openIntegrateDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.manageContentCreator('approved').then(() => this.setState({openIntegrateDialog: false}))}>{localization.get('accept')}</Button>
      </DialogActions>
    </Dialog>

  manageContentCreator = status => 
    request.contentCreator.approve(this.state.contentCreatorId, {status, reason: this.state.declineReason})
      .then(() => GlobalSnackbar.show({
        type: GlobalSnackbarTypes.SUCCESS,
        message: localization.get('table.approved'),
      }))
      .then(() => this.refs.table.forceUpdate())

  columns = () => [
    {
      id: "image",
      accessor: contentCreator => (
        <Tooltip title={contentCreator.name}>
          <img
            alt="ContentCreator"
            src={makeImageUrlWithSize(contentCreator, 'xs')}
            width={50}
            height={50}
            onError={onImageError}
          />
        </Tooltip>
      ),
      style: {
        float: "left"
      },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get("table.name"),
      accessor: creator =>  <Tooltip title={creator.name}><p>{creator.name}</p></Tooltip>,
      id: "title",
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => {
            onChange(event.target.value)
            if(event.keyCode == 13) {
              onChange(this.state.title)
            }else {
              this.setState({title: event.target.value || ""})
            }
          }}
          value={this.state.title ? this.state.title : ""}
          placeholder={localization.get("table.search")}
        />
      ),
    },
    {
      id: "channel[name]",
      Header: localization.get("table.channel"),
      accessor: creator => get(creator, "channel.name"),
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <TableAutocompleteFilter
          fetchItems={filter => request.channel
            .getAll({
              filterBy: [{ column: 'name', filter }, { column: 'has_community', filter: 1 }]
            })
            .then(channels => channels.map(channel => channel.name))
          }
          onChange={(e) => {
            this.setState({channel: e});

            return onChange(e);
          }}
          filter={filter ? filter.value : ''}
        />
    },
    {
      Header: localization.get("table.status"),
      accessor: creator => localization.get("table." + creator.status),
      id: "status",
      Filter: ({filter, onChange}) => (
        <select
          style={{width: "100%"}}
          onChange={event => {
            onChange(event.target.value)
            this.setState({is_event: event.target.value})
          }}
          value={filter ? filter.value : ""}
        >
          <option value="" selected/>
          <option value={"approved"}>{localization.get('table.approved')}</option>
          <option value={"pending"}>{localization.get('table.pending')}</option>
          <option value={"declined"}>{localization.get('table.declined')}</option>
        </select>
      ),
      sortable: true,
    },
    {
      Header: localization.get("table.created_at"),
      accessor: creator =>  creator.created_at,
      id: "created_at",
      filterable: false,
      sortable: true,
    },
    {
      Header: localization.get("userId"),
      accessor: creator =>  creator.user ? creator.user.id : localization.get('deleted'),
      id: "userId",
      filterable: false,
      sortable: false,
    },
    {
      id: "actions",
      Header: localization.get("table.actions"),
      sortable: false,
      filterable: false,
      accessor: this.buttons,
      width: 180,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }
  ];

  generateDownloadLink = () => request.channel.getAll({
      filterBy: [{ column: 'name', filter: this.state.channel }, { column: 'has_community', filter: 1 }]
    })
      .then(channels => window.open(request.contentCreator.exportForCommunity(channels[0].id), "_blank"))

  generateDownloadLinkForCreations = () => request.channel.getAll({
    filterBy: [{ column: 'name', filter: this.state.channel }, { column: 'has_community', filter: 1 }]
  })
    .then(channels => window.open(request.contentCreation.exportForCommunity(channels[0].id), "_blank"))

  makeSubComponent = row => <TableSubcomponent {...this.props} contentCreator={row.original}/>

  render = () => {
    return (
      <div>
        {this.makeIntegrateDialog()}
        {this.makeDeclineDialog()}
        <CustomShareContent open={this.state.openShareDialog} sharingCreator={this.state.sharingCreator} onClose={() => this.setState({openShareDialog: false, sharingCreator: null})} />
        <div style={{ width: "100%", display: "flex", flexDirection: "column"}}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
            <FormControl style={{float: "left", position: "relative"}}>
                <RadioGroup
                  name="withPending"
                  value={this.state.withPending}
                  onChange={event => {
                    this.setState({ withPending: event.target.value })
                    this.refs.table.forceUpdate();
                  }}
                  style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
                >
                  <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('all')} />
                  <FormControlLabel value="withPending" control={<Radio color="default" />} label={localization.get('content_creators.with_pendings')} />
                </RadioGroup>
              </FormControl>

            {this.state.channel &&
              <div>
                <IconButton onClick={this.generateDownloadLink} style={{float: "right"}}>
                  <Tooltip title={localization.get('download_csv')}  placement='top'>
                    <CloudDownload />
                  </Tooltip>
                </IconButton>

                <IconButton onClick={this.generateDownloadLinkForCreations} style={{float: "right"}}>
                  <Tooltip title={localization.get('download_csv.creations')}  placement='top'>
                    <CloudDownload  />
                  </Tooltip>
                </IconButton>
              </div>
            }
          </div>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            subComponent={this.makeSubComponent}
            requestTableData={async paginationConfig => {
              paginationConfig.filterBy.push({column: "is_community", filter: 1});
              paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'dashboard', filter: "true" }])};

              if(!paginationConfig.orderBy) {
                paginationConfig.orderBy = {column: "updated_at", type: "desc"};
              }

              if (this.state.withPending === "withPending") { 
                paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'with_pending', filter: "true" }])};
              }

              this.setState({ paginationConfig })

              return request.contentCreator.getAll(paginationConfig, "image;type.image;channel;user:id,name")
                .then(response => {
                  this.setState({total: response.meta.total});
                  return response;
                });
            }}
          />
        </div>
      </div>
    );
  };
}

export default Table;