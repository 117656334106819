import {request} from "../../index";

export const canAccessClientHome = (clientId) =>
  new Promise(resolve => {
    let status = false;

    let x = new XMLHttpRequest();
    x.open("GET", request.client.publicHome(clientId), false);
    x.onerror = e => status = false;
    x.onprogress = () => status = x.status == 200;
    x.onload = () => status = x.status == 200;

    x.send();
    resolve(status)
  })
