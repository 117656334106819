import "../Home.css";
import "./index.css";
import React, {Component} from "react";

import {Drawer, IconButton} from "@material-ui/core";
import {Close, Delete, Edit} from "@material-ui/icons";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import StoreSearchDialogForm from "./StoreSearchDialog";
import {userIsClientAdmin, userIsSysAdmin, userIsTrial} from "../../../api-client/core/authentication/utils";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import SavedQueryImage from "../../../assets/img/Letflow/savedQuery.jpg";
import {makeImageUrlWithSize} from "../HomeElements/utils";

class StoredSearchesDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      storedSearches: [],
      updateForm: null,
    };
  }

  componentDidMount = () => {
    this.requestStoredSearches();
  };

  requestStoredSearches = () => {
    request.savedQuery
      .getAllForClient(this.props.clientId)
      .then(storedSearches => {
        this.setState({ storedSearches }, () => this.setState({loading: false}));
      })
      .catch(() => {
        GlobalSnackbar.show({
          message: localization.get("saved_queries_could_not_be loaded"),
          type: GlobalSnackbarTypes.ERROR,
          topDistance: 150,
        });
        this.setState({ loading: false });
      });
  };

  showUpdateForm = storedSearch => {
    request.savedQuery
      .get(storedSearch.id)
      .then(savedQuery => {
        this.setState({
          updateForm: (
            <StoreSearchDialogForm
              handleClose={() => this.setState({ updateForm: null })}
              type={savedQuery.type}
              tags={savedQuery.tags}
              title={savedQuery.title}
              subtitle={savedQuery.subtitle}
              description={savedQuery.description}
              image={makeImageUrlWithSize(savedQuery, 'sm')}
              submitType={"update"}
              storedSearchId={savedQuery.id}
              visibility={savedQuery.visibility}
              userLibrary={savedQuery.user_library}
            />
          ),
        });
      })
      .catch(e => {
        
      });
  };

  canShowButtons = () => userIsSysAdmin() || userIsClientAdmin() || userIsTrial();

  render = () => (
    <div style={{ position: "absolute" }}>
      <Drawer PaperProps={{ style: { width: window.innerWidth < 600 ? "100%" : "350px" }}} anchor="left" open={true} onClose={this.props.handleClose}>
        {this.state.updateForm}
        <IconButton onClick={this.props.handleClose} style={{position: "absolute", right: 10, top: 10}}>
          <Close />
        </IconButton>
        {!this.state.loading &&
        <div style={{position: "relative", float:"left", width: "100%", marginTop: 65}}>
          <div>
            {this.state.storedSearches.length === 0 && (
              <div
                style={{
                  float: "left",
                  position: "relative",
                  width: "100%",
                  textAlign: "center",
                  marginTop: "10px",
                  fontSize: "20px",
                  padding: "40px"
                }}>{localization.get("no_stored_searches_for_this_client")}</div>
            )}
          </div>
          {this.state.storedSearches.map(storedSearch => (
            <div
              className={"stored-searched-drawer-button"}
              style={{
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              key={storedSearch.id}
              onClick={() => this.props.onStoredSearchClick(storedSearch)}
            >
              <div style={{ float: "left", position: "relative", width: "25%", paddingLeft: "7px" }}>
                <img
                  alt="SavedQuery"
                  src={`${
                    storedSearch.image
                      ? request.file.image.makeCroppedUrl(storedSearch.image.id, 50, 50)
                      : SavedQueryImage
                  }`}
                  width={50}
                  height={50}
                />
              </div>
              <div style={{ float: "left", position: "relative", width: "50%" }}>
                <h5 style={{ fontWeight: "500" }}>{storedSearch.title}</h5>
              </div>
              <div style={{ paddingRight: "2px", float: "left", position: "relative", width: "25%" }}>
                {this.canShowButtons() && (
                  <IconButton
                    style={{ width: "30px", height: "30px" }}
                    onClick={e => {
                      e.stopPropagation();
                      GlobalConfirmationDialog.showDelete({
                        elementName: storedSearch.title,
                        deleteRequest: () =>
                          request.savedQuery.delete(storedSearch.id).then(() => {
                            // analyticsInstance.removeSavedQuery(storedSearch.title);
                          }),
                        onSuccess: () => this.requestStoredSearches(),
                      });
                    }}
                  >
                    <Delete style={{ fontSize: "20px" }} />
                  </IconButton>
                )}
                {(this.canShowButtons() && window.innerWidth > 600) &&(
                  <IconButton
                    style={{ width: "30px", height: "30px" }}
                    onClick={e => {
                      e.stopPropagation();
                      this.showUpdateForm(storedSearch);
                    }}
                  >
                    <Edit style={{ fontSize: "20px" }} />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
        </div>
        }
      </Drawer>
    </div>
  );
}

export default StoredSearchesDrawer;
