export const makeFormData = ({ title, active, speakerId, audioFile }) => {
  const form = new FormData()
  if (title) form.append('title', title)
  form.append('active', active ? 1 : 0)
  if (speakerId) form.append('speaker_id', speakerId)
  if (audioFile instanceof Blob) form.append('audio', audioFile, audioFile.name)
  return form
}

export const root = '/voices'

export const includeQuery = 'include=speaker.image:id;audio:original_name,url,duration;audio.waveform'
