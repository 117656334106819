import React from "react";

import ConversationListing from "./ConversationListing";
import ConversationMessages from "./ConversationMessages";
import CreateConversation from "./CreateConversation";
import MessagingRoutes from "./MessagingRoutes";

const DashboardMessagingRoutes = props => (
  <MessagingRoutes
    {...props}
    conversationsComponent={ConversationsComponent}
    threadComponent={ThreadComponent}
    createComponent={CreateConversation}
    threadPath="/conversations/:id/thread"
    createPath="/conversations/create"
  />
);

const ConversationsComponent = () => (
  <ConversationListing
    createConversationPath="/conversations/create"
    threadPathFactory={conversationId => `/conversations/${conversationId}/thread`}
  />
)

const ThreadComponent = props => (
  <ConversationMessages conversationId={props.match.params.id} />
)

export default DashboardMessagingRoutes;
