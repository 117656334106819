import React, {Component} from "react";
import localization from "../../../config/localization"
import {request} from "../../../api-client";
import {Search} from "@material-ui/icons";
import "./Unsplash.css";
import {TextField} from "@material-ui/core";



export default class Unsplash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      photosSearch: [],
      query: [],
      imageSelected: '',
    }
  }

  componentDidMount = () => {
    request.unsplash.getEditorialPhotos(this.props.imageAmount)
      .then((res) => this.setState({photos: res}))
  }

  searchPhotos = (query) => {
    request.unsplash.getSearchPhotos(encodeURI(query), this.props.imageAmount)
      .then((res) => this.setState({photosSearch: res}))
  }

  handleSearch = (e) => {
    this.setState({query: e.target.value})
    this.searchPhotos(e.target.value)
  }

  handleSelect = (id, url, description, alt_description) => {
    url = url + '&fm=jpg&fit=crop&w=2048&q=80&fit=max'
    this.setState({imageSelected :id});
    if (description) {
      this.props.onSelectImage(url, description);
    } else {
      this.props.onSelectImage(url, alt_description);
    }
  }

  render = () => {
    return (
      <>
        <div>
          <TextField
            style={{ marginBottom: 20 }}
            className="search"
            label={localization.get("search")}
            onChange={e => this.handleSearch(e)}
          />
          <Search/>
        </div>
        {(this.state.photos.length > 0
          && this.state.photosSearch.length === 0
          && this.state.query.length === 0)
          ? <div style={this.props.scrollable && {maxHeight: '400px', overflow: 'auto'}}>
            <div className={this.props.profile ? "profile-grid" : !this.props.public ? "grid" : "public-grid"}>
              {this.state.photos.map((photo) => (
                <div>
                  <div className={`grid-item-image ${this.state.imageSelected === photo.id ? 'unsplash-active' : ''}`} onClick={() => this.handleSelect(photo.id, photo.urls.raw, photo.description, photo.alt_description)}>
                    <img src={photo.urls.thumb} alt={photo.description || 'Cover Option'}/>
                  </div>
                  <div>
                    <span>by </span>
                    <a href={photo.user.links.html} target="blank">
                      <span>{photo.user.name}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          : this.state.photosSearch && this.state.photosSearch.length > 0
            ? <div style={this.props.scrollable && {maxHeight: '400px', overflow: 'auto'}}>
              <div className={this.props.profile ? "profile-grid" : !this.props.public ? "grid" : "public-grid"}>
                {this.state.photosSearch.map((photo) => (
                  <div>
                    <div className={`grid-item-image ${this.state.imageSelected === photo.id ? 'unsplash-active' : ''}`} onClick={() => this.handleSelect(photo.id, photo.urls.raw, photo.alt_description)}>
                      <img src={photo.urls.thumb} alt={photo.description || 'Cover Option'}/>
                    </div>
                    <div>
                      <span>by </span>
                      <a href={photo.user.links.html} target="blank">
                        <span>{photo.user.name}</span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            : <span>No results</span>
        }
      </>
    )
  }
}