import { post } from '../utils'

const url = (host, channelId) => `${host}/channels/${channelId}/contentCreations/retagMasively`;

export default host => (channelId, data) =>
  post(url(host, channelId), makeJsonData(data))


const makeJsonData = data => {
  let json = {}

  if (data.reTags && !!data.reTags.length) json.retags_ids = data.reTags.join(',');
  json.retag_method = data.retagMethod || "add";
  json.update_visibility = data.updateVisible;
  json.update_downloadable = data.updateDownloadable;
  json.contentCreatorType = data.contentCreatorTypeId;
  json.creationIds = data.contentCreationsIds
  json.access_level = data.updateAccessLevel

  return JSON.stringify(json);
}
