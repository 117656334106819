import {getRefreshToken, shouldUseTokensInHeader, storeTokens, isLoggedUser} from "./utils";
import {Subject} from "rxjs";
import {request} from "../../index";

let refreshing = false;

const refreshingFinishedSubject = new Subject();

export const isRefreshingAccessToken = () => refreshing;

export const accessTokenRefreshFinished$ = refreshingFinishedSubject.asObservable();

const form = () => {
  const f = new FormData();
  // TODO: Remove getRefreshToken() after deploy
  (shouldUseTokensInHeader() || getRefreshToken()) && f.append("refresh_token", getRefreshToken());
  return f;
};

const requestAccessTokenRefresh = (host) => {
  let request = {
    method: "POST",
    body: form(),
    headers: {
      Accept: "application/json"
    },
    credentials: "include"
  }

  return fetch(`${host}/login/refresh`, request);
};

const handleRequestAccessTokenRefreshResponse = response => {
  if (!response.ok) {
    return Promise.reject("api-client-error : refreshAccessToken : " + response.statusText);
  } else {
    return response.json();
  }
};

const refreshAccessToken = host => () => {
  if (refreshing) {
    return Promise.resolve()
  }

  if (!isLoggedUser()) {
    request.authentication.logout();
  } else {
    refreshing = true;
    return requestAccessTokenRefresh(host)
      .then(handleRequestAccessTokenRefreshResponse)
      .then(tokens => {
        storeTokens(tokens);
        refreshingFinishedSubject.next({});
        refreshing = false;
      }, () => {
        request.authentication.logout();
      }
    );
  }
};

export default refreshAccessToken;
