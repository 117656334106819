import getAll from './getAll'
import create from './create'
import del from './delete'
import get from './get'
import audioUrl from './audioUrl'
import download from './download'
import update from './update'
import searchForClient from './searchForClient';
import searchFor from './searchFor';
import forClientWithTags from './forClientWithTags';
import getForClient from './getForClient';

export default host => ({
  getAll: getAll(host),
  create: create(host),
  delete: del(host),
  get: get(host),
  audioUrl: audioUrl(host),
  download: download(host),
  update: update(host),
  searchFor: searchFor(host),
  searchForClient: searchForClient(host),
  forClientWithTags: forClientWithTags(host),
  getForClient: getForClient(host)
})
