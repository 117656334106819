import React from "react";
import {Card, Grid} from "@material-ui/core";

const MultiItemSelectionButton = ({ selected, id, value, onClick, disabled, sizes = {} }) =>
  <Grid xs={sizes.xs || 6} sm={sizes.sm || 4} md={sizes.md || 3} item>
    <Card style={{ cursor: 'pointer', padding: '10px', textAlign: 'center', backgroundColor: selected ? '#9d4d5c' : '#DFDFDF' }} onClick={() => {
      if (disabled) return
      onClick(!selected, id)
    }}>
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: selected ? 'white' : 'black', fontWeight: '400' }}>{value}</div>
    </Card>
  </Grid>

const MultiItemSelection = ({ items, selectedIds, onSelectedItemsChanged, title, sizes, disabled, rootStyle = {}, gridStyle = {} }) => {
  return (
    <div style={rootStyle}>
      <h4>{title}</h4>
      <Grid style={gridStyle} spacing={16} container>
        {
          items
            .map(x => {
              return (
                <MultiItemSelectionButton key={x.id} disabled={disabled} id={x.id} value={x.value} sizes={sizes} onClick={(selected, id) => {
                  let ret
                  if (selected) {
                    selectedIds.push(id)
                    ret = selectedIds
                  } else {
                    ret = selectedIds.filter(x => x !== id)
                  }
                  onSelectedItemsChanged(ret)
                }} selected={selectedIds.includes(x.id)} />
              )
            })
        }
      </Grid>
    </div>
  )
}
// items, selectedIds, onSelectedItemsChanged, title, sizes, rootStyle = {}, gridStyle = {}
export const SingleItemSelection = ({ items, selectedId, onSelectedItemChanged, title, sizes, disabled, rootStyle, gridStyle }) => {
  return (
    <MultiItemSelection
      items={items}
      title={title}
      sizes={sizes}
      rootStyle={rootStyle}
      gridStyle={gridStyle}
      selectedIds={typeof selectedId === 'number' ? [selectedId] : []}
      disabled={disabled || false}
      onSelectedItemsChanged={selectedIds => {
        onSelectedItemChanged(typeof selectedIds[selectedIds.length - 1] === 'number' ? selectedIds[selectedIds.length - 1] : undefined)
      }}
    />
  )
}


export default MultiItemSelection