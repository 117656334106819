import React, { Component } from 'react';
import "./index.css";

class CustomDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: window.innerWidth > 600 ? 'text' : 'date',
      hideInput: true,
    };
  }

  handleFocus = () => {
    this.setState({ inputType: 'date' });
  };

  handleBlur = (e) => {
    if (!e.target.value) {
      this.setState({ inputType: 'text' });
    }
  };



  render() {
    if (window.innerWidth < 600 && !this.props.value && this.state.hideInput) {
      return (<div className='search-datepicker'
      onClick={() => this.setState({ hideInput: false })}
      style={{
        width: "100%" ,
        margin: "10px 0 0" ,
        padding: '10px',
        borderRadius: '4px',
      }}>{this.props.placeholder}</div>) 
    }

    return (
      <input
        className='search-datepicker'
        type={this.state.inputType}
        placeholder={this.props.placeholder}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.props.onChange}
        value={this.props.value}
        min={this.props.minDate}
        style={{
          margin: window.innerWidth > 600 ? "10px 0" : "10px 0 0" ,
          padding: '10px',
          borderRadius: '4px',
          fontStyle: this.state.inputType === 'text' && !this.state.placeholder ? 'italic' : 'normal',
          display: "flex",
          ...this.props.style,
        }}
      />
    );
  }
}

export default CustomDateInput;
