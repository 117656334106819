import React, {Component} from "react";

import Opened from "./Opened";
import Failed from "./Failed";
import Passed from "./Passed";

import PropTypes from 'prop-types'

class PromiseModal extends Component {

  state = {
    executingAction: false,
  }

  handlePromise = () => {
    this.setState({ executingAction: true })
    this.props.promiseFactory()
      .then(_ => this.setState({
        current: 'passed',
        executingAction: false
      }))
      .catch(_ => this.setState({
        current: 'failed',
        executingAction: false
      }))
  }

  handleClose = () => {
    this.props.onClose(this.state.current)
  }

  render = () => {

    const {
      openedTitle,
      failedTitle,
      passedTitle,
      openedMessage,
      failedMessage,
      passedMessage
    } = this.props

    const {
      executingAction
    } = this.state


    switch (this.state.current) {
      case 'failed':
        return <Failed
          title={failedTitle}
          message={failedMessage}
          onConfirm={this.handleClose}
        />
      case 'passed':
        return <Passed
          title={passedTitle}
          message={passedMessage}
          onConfirm={this.handleClose}
        />
      default:
        return <Opened
          title={openedTitle}
          message={openedMessage}
          showButtons={!executingAction}
          onConfirm={this.handlePromise}
          onCancel={this.handleClose}
        />
    }
  }

}

PromiseModal.propTypes = {

  openedTitle: PropTypes.string.isRequired,
  failedTitle: PropTypes.string.isRequired,
  passedTitle: PropTypes.string.isRequired,

  openedMessage: PropTypes.string.isRequired,
  failedMessage: PropTypes.string.isRequired,
  passedMessage: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired,
  promiseFactory: PropTypes.func.isRequired

}

export default PromiseModal;