import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {BigSpinner} from "../Loading";
import PrevArrow from "../Arrows/PrevArrow";
import NextArrow from "../Arrows/NextArrow";
import {KeyboardArrowLeft, KeyboardArrowRight, PlaylistAdd} from "@material-ui/icons";
import {IconButton, Grid as MuiGrid} from "@material-ui/core";
import {
  addHttpsToUrl,
  checkIfMobile,
  getActiveClient,
  getMainTranslation,
  userHasRestrictedAccess
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import "layouts/Home/Home.css";
import AudiovisualTagSelector from "../AudiovisualTagSelector";
import {downloadFromUrl} from "../../../utils";
import AddToPlaylist from "../AddToPlaylist";
import CreationItem from "../CreationItem";
import analyticsInstance from "../../../analytics/analyticsInstance";

export const WorkContainer = ({ title, children, color, shape }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <InnerContainer color={color} shape={shape} >{children}</InnerContainer>
    </React.Fragment>
  );
};

const sliderSettings = {
  dots: true,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  prevArrow: <PrevArrow overrideStyle={{transform: `translate(0, -${window.innerWidth < 1300 ? 155 : 130}%)`, left: "-35px", color: "var(--main-font-color, #00000099)"}}/>,
  nextArrow: <NextArrow overrideStyle={{transform: `translate(0, -${window.innerWidth < 1300 ? 155 : 130}%)`, right: "-35px", color: "var(--main-font-color, #00000099)"}}/>,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};

export const Grid = ({ items, onPlay, onFavorite, onShare, tags, onSelectedTagChanged, handleFilters, showSearchInput, color, showEmpty=false}) => (
  <div style={{ textAlign: "center", width: "100%", padding:"0 1%", margin: "auto", position: "relative", float: "left" }}>
    {tags  &&
      <div style={{marginTop: 30, marginBottom: 30}}>
        <AudiovisualTagSelector outlined tags={tags} onSelectedTagChanged={onSelectedTagChanged} handleFilters={handleFilters} showSearchInput={showSearchInput} color={color}/>
      </div>
    }
    {items.length > 0 ? (
      <MuiGrid container style={{width: "calc(100% + 20px)", marginLeft: "-10px", marginRight: "-10px"}}>
        {items.map((creation, key) =>
          <MuiGrid key={key} xs={12} sm={6} md={4} lg={3} xl={3} item style={{padding: 10, width: "100%"}}>
            <CreationItem
              contentCreation={creation}
              onFavorite={() => onFavorite && onFavorite(creation)}
              onPlay={() => onPlay && onPlay(key)}
              onShare={creation => onShare && onShare(creation.id)}
              noAuthorLink={userHasRestrictedAccess()}
            />
          </MuiGrid>
        )}
      </MuiGrid>
    )
    :
      showEmpty && 
        <div style={{color: 'rgba(0,0,0,0.87)', fontSize: "2em"}}>
          {localization.get("table.no_results")}
        </div>
    }
  </div>
);

export const Carousel = ({ items, renderItem, onItemClick, tags, onSelectedTagChanged, showAddToPlaylist, handleFilters, showSearchInput, color, allowDownload=false, handleDownload = () =>null, showEmpty=false }) => (
  <div style={{ textAlign: "center", width: "100%", padding:"0 1%", margin: "auto", position: "relative", float: "left" }}>
    {tags  &&
      <div style={{marginTop: 30, marginBottom: 30}}>
        <AudiovisualTagSelector outlined tags={tags} onSelectedTagChanged={onSelectedTagChanged} handleFilters={handleFilters} showSearchInput={showSearchInput} color={color}/>
      </div>
    }
    {items.length > 0 ? (
      <Slider
        style={{width: "100%"}}
        {...sliderSettings}>
        {items.map((item, key) => (
          <div key={key}>
            <div
              style={{ padding: "5px", cursor: "pointer" }}
              onClick={() => onItemClick({...item, itemIndex: key})}
            >
              <CarouselItem className="category-item">
                {showAddToPlaylist &&
                <div className="album-fav" style={{display: "none"}}>
                  <div className="home-elements-add-to-playlist-button" style={{top: 5}}>
                    <AddToPlaylist
                      icon={<PlaylistAdd style={{fontSize: "25px", color: "var(--main-font-color, rgb(0, 0, 0))", marginLeft: 4}}/>}
                      clientId={getActiveClient()}
                      content='contentCreations'
                      item={item}
                      extraStyle={{height: 50, width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                      tooltipTitle={localization.get("add_to_playlist")}
                    />
                  </div>
                </div>
                }
                {renderItem(item)}
              </CarouselItem>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "10px 0px",
                  color: color,
                  overflow: "hidden"
                }}
              >
                {item.title}
              </div>
              {allowDownload &&
                <div style={{height: 30}}>
                  <p style={{textAlign: "center", color: "#000"}}>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (item.link || item.binary) {
                          analyticsInstance.download({
                            name: getMainTranslation(item, "title"),
                            type: "Channel",
                            from: "Material_For_Download",
                          })
                          item.link ? window.open(addHttpsToUrl(item.link, '_blank')) : window.open(item.binary, '_blank')
                        } else if (item.image && item.image.original_name && !item.video && !item.audio && !item.binary) {
                          analyticsInstance.download({
                            name: getMainTranslation(item, "title"),
                            type: "Channel",
                            from: "Material_For_Download",
                          })
                          downloadFromUrl(item.image.url, encodeURI(item.image.original_name), item.image.original_name.split('.').pop(), `image/${item.image.original_name.split('.').pop()}`)
                        } else {
                          analyticsInstance.download({
                            name: getMainTranslation(item, "title"),
                            type: "Channel",
                            from: "Playlist",
                          })

                          handleDownload(item.id)
                        }
                      }}
                      style={{
                        overflow: "hidden",
                        height: 21,
                        background: "var(--secondary-color, #ccc)",
                        color: "var(--navbar-text-color, white)",
                        width: 100,
                        margin: "auto",
                        borderRadius: 25,
                        padding: 6,
                      }}
                    >
                      {localization.get('download')}
                    </span>
                  </p>
                </div>
              }
            </div>
          </div>
        ))}
      </Slider>)
      :
      showEmpty && 
        <div style={{color: 'rgba(0,0,0,0.87)', fontSize: "2em"}}>
          {localization.get("table.no_results")}
        </div>
    }
  </div>
);

export const SelectedWork = ({ item, renderItem, showSpinner, showArrows = false, onPrevArrow, onNextArrow, disablePrev, disableNext}) => (
  <section style={{ marginTop: "90px", textAlign: "center" }}>
    {showSpinner && <BigSpinner />}
    {item &&
      <div style={{ display: showSpinner ? "none" : "block" }}>
        <div style={{display: "flex", minHeight: 500}}>
          {showArrows &&
          <IconButton style={{alignSelf: "center"}} onClick={onPrevArrow} disabled={disablePrev}>
            <KeyboardArrowLeft style={{height: "2.5rem", width: "2.5rem"}}/>
          </IconButton>
          }
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            maxWidth: "800px",
            margin: "auto",
            position: "relative",
            display: "inline-block",
            minWidth: checkIfMobile() ? 200 : 600,
            minHeight: checkIfMobile() ? 200 : 400
          }}
        >
          {renderItem(item)}
        </div>
          {showArrows &&
          <IconButton style={{alignSelf: "center"}} onClick={onNextArrow} disabled={disableNext}>
            <KeyboardArrowRight style={{height: "2.5rem", width: "2.5rem"}}/>
          </IconButton>
          }
        </div>
        <h2 style={{
          fontWeight: "bold",
          color: "black",
          fontSize: "2em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>{item.title}</h2>
        {item.description && <p>{item.description}</p>}
      </div>
    }
  </section>
);

const Title = styled.h4`
  font-weight: bold;
  color: white;
  margin: 30px 0;
  font-size: 1.7em;
 
`;

const InnerContainer = styled.section`
  //background-color: #ffffffc9; color shape
  background-color: ${props => props.color || "rgba(255, 255, 255, 0.4)" };
  min-height: 100px;
  float: left;
  border-radius: ${ props => props.shape === 'square' ? "0px" : "30px" };
  padding: 0px;
  width: 100%;
  @media (min-width: 479px) {
    padding: 10px 40px 40px 40px;
  }
`;

const CarouselItem = styled.div`
  background-color: white;
  height: 0;
  overflow: hidden;
  padding-top: calc(591.44 / 1127.34 * 100%);
  position: relative;
  &:hover {
    box-shadow: 0 0 5px 0 dimgray;
    display: block!important;
  }
`
