import React from 'react'
import localization from "../../../config/localization";
import Tabs from "../../../components/CustomTabs/CustomTabs";
import {request} from "api-client";
import {IconButton, Tooltip} from "@material-ui/core";
import {Help, Translate} from "@material-ui/icons";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import EventManager from "../../../utils/EventManager";
import events from "../../../utils/EventManager/events";
import {EditorState} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import { stateToHTML } from 'draft-js-export-html';

class FormikTranslatableInputs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      from: "es",
      to: "all",
      activeTab: 0,
      responseText: '',
    }
  }

  componentDidMount = () => {
    EventManager.getInstance().subscribe(events.CHECK_MISSING_TRANSLATABLE_INPUTS, this.checkAndTranslateMissingFields);
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.CHECK_MISSING_TRANSLATABLE_INPUTS, this.checkAndTranslateMissingFields);
  }

  checkAndTranslateMissingFields = async () => {
    if (!this.props.isEditorialContent) {
      if (this.findMissingFields()) {
        await this.handleTranslate()
      }

      await EventManager.getInstance().dispatch(events.MISSING_FIELDS_TRANSLATED)
    }
  }

  findMissingFields = () => {
    let missingFields = false
    this.props.children.forEach(child => {
      child.content.forEach((content) => {
        if (content && ((content.props.hasOwnProperty('value') && !content.props.value) || (content.props.isEditor && !content.props.value.getCurrentContent().hasText()))) {
          missingFields = true
        }
      })
    })
    return missingFields
  }

  handleTranslate = async () => {
    let fromLanguage = this.state.from;
    let toLanguage = this.state.to;
    let sender = this.props.children.find(child => (child.language === fromLanguage))
    for (const child of this.props.children) {
      if (child.language !== fromLanguage) {
        for (const [index, content] of child.content.entries()) {
          if (sender.content[index].props.value) {
            toLanguage = child.language
            await this.requestTranslation(sender.content[index], content, fromLanguage, toLanguage)
          }
        }
      }
    }
  }

  requestTranslation = (sender, destination, fromLanguage, toLanguage) => {
    let senderValue = sender.props.isEditor ? stateToHTML(sender.props.value.getCurrentContent()) : sender.props.value;

    return request.translate.translate(senderValue, fromLanguage, toLanguage)
      .then((response) => {
        if (sender.props.isEditor && destination.props.isEditor) {
          let destinationValue = stateFromHTML(response.text);
          destinationValue = EditorState.createWithContent(destinationValue);
          destination.props.onChange(destinationValue);
        } else {
          destination.props.onChange(destination.props.field, response.text)
        }
      })
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('translatable_input.translated'),
          type: GlobalSnackbarTypes.SUCCESS
        })
        return true;
      })
      .then(() => {
        if (this.props.translateFromHomeElementRequest) {
          this.props.translateFromHomeElementRequest()
        }
        return true;
      })
      .catch((e) => {
        GlobalSnackbar.show({
          message: localization.get('translatable_input.failed'),
          type: GlobalSnackbarTypes.ERROR
        })
        return false;
      })
  }


  render = () => {
    const {children} = this.props
    return (
      <div>
        {!this.props.isEditorialContent &&
        <div style={{position: "relative", float: "right", zIndex: 4, top: 40, right: 30}}>
          <Tooltip placement={"top"} title={localization.get('translate')}>
            <IconButton onClick={this.handleTranslate}>
              <Translate style={{color: "white"}}/>
            </IconButton>
          </Tooltip>

          <Tooltip placement={"top"} title={localization.get('form.inputs_to_translate_description')}>
            <IconButton>
              <Help style={{color: "white"}}/>
            </IconButton>
          </Tooltip>
        </div>
        }

        <Tabs
          headerColor={this.props.bloomCloudsView ? "dark" : "dark"}
          value={this.state.activeTab}
          onTabChange={value => this.setState({activeTab: value})}
          tabs={
            children.map(child => {
              return ({
                tabName: localization.get(`language.${child.language}`),
                tabContent:
                  <React.Fragment>
                    {child.content}
                  </React.Fragment>
              })
            })
          }
        />
      </div>
    )
  }

}

export default FormikTranslatableInputs
