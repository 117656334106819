import create from "./create";
import getAll from "./getAll";
import getAllForClient from "./getAllForClient";
import get from "./get";
import expire from "./expire";

export default host => ({
    get: get(host),
    getAll: getAll(host),
    getAllForClient: getAllForClient(host),
    create: create(host),
    expire: expire(host)
})