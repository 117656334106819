import React, {Component} from "react";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import MusicsTable from "./Table";
import MusicsTableWithPendingApproval from "./TableWithPendingApproval";
import {AllViewContainer} from '../../../components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import {request} from "../../../api-client";
import {
  getStoredUser,
  userIsArtist,
  userisMultiClient,
  userIsSupervisor,
  userIsSysAdmin
} from "../../../api-client/core/authentication/utils";
import {makeUrlWithTableParams} from "../../../utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  FormControlLabel, Radio,
  RadioGroup
} from "@material-ui/core";
import browserHistory from "../../../utils/browserHistory";
import queryString from "querystring";

class Musics extends Component {

  state = {
    musics: [],
    tagGroups: [],
    perPage: 20,
    page: 0,
    countMusicArtist: 0,
    openCatalogDialog: false,
    openAlbumDialog: false,
    openArtistDialog: false,
    showTracks: 'all',
  };

  componentDidMount = () => {
    document.title = localization.get('title.musics') + ' - Feater';
    let queryParams = queryString.parse(this.props.location.search)
    if (queryParams['?pending']) {
      this.setState({showTracks: 'pending_approve'}, () => localStorage.setItem("showTracks", '"pending_approve"'))
    } else if (eval(localStorage.getItem("showTracks"))) {
      this.setState({ showTracks: eval(localStorage.getItem("showTracks")) });
    }
  };

  onAdd = () => {
    if(userisMultiClient()){
      request.catalog.getAll()
        .then(res => {
          if (res.length === 0){
            this.setState({openCatalogDialog: true})
          } else {
            request.artist.getAll()
              .then(res => {
                if(res.length === 0){
                  this.setState({openArtistDialog: true})
                } else {
                  request.album.getAll()
                    .then(res => {
                      if (res.length === 0){
                        this.setState({openAlbumDialog: true})
                      } else {
                        this.props.history.push(makeUrlWithTableParams(`/panel/musics/create`))
                      }
                    })
                }
                })
          }
        })
    } else {
      this.props.history.push(makeUrlWithTableParams(`/panel/musics/create`))
    }
  };

  makeCatalogCreateDialog = () =>
    <Dialog open={this.state.openCatalogDialog} onBackdropClick={() => this.setState({openCatalogDialog: false})} onEscapeKeyDown={() => this.setState({openCatalogDialog: false})}>
      <DialogTitle>{localization.get('catalog.is_required_dialog.title')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('catalog.is_required_dialog.instructions')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openCatalogDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.props.history.push(makeUrlWithTableParams(`/panel/catalogs/create`))}>{localization.get('catalog.is_required_dialog.create_catalog')}</Button>
      </DialogActions>
    </Dialog>

  makeAlbumCreateDialog = () =>
    <Dialog open={this.state.openAlbumDialog} onBackdropClick={() => this.setState({openAlbumDialog: false})} onEscapeKeyDown={() => this.setState({openAlbumDialog: false})}>
      <DialogTitle>{localization.get('album.is_required_dialog.title')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('album.is_required_dialog.instructions')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openAlbumDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.props.history.push(makeUrlWithTableParams(`/panel/albums/create`))}>{localization.get('album.is_required_dialog.create_album')}</Button>
      </DialogActions>
    </Dialog>

  makeArtistCreateDialog = () =>
    <Dialog open={this.state.openArtistDialog} onBackdropClick={() => this.setState({openArtistDialog: false})} onEscapeKeyDown={() => this.setState({openArtistDialog: false})}>
      <DialogTitle>{localization.get('artist.is_required_dialog.title')}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
          <div>{localization.get('artist.is_required_dialog.instructions')}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({openArtistDialog: false})}>{localization.get('cancel')}</Button>
        <Button onClick={() => this.props.history.push(makeUrlWithTableParams(`/panel/artists/create`))}>{localization.get('artist.is_required_dialog.create_artist')}</Button>
      </DialogActions>
    </Dialog>

  render = () =>
    <div>

      {this.makeCatalogCreateDialog()}
      {this.makeAlbumCreateDialog()}
      {this.makeArtistCreateDialog()}

      <AllViewContainer
        onAdd={this.onAdd}
        ref='viewContainer'>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <FormControl style={{width: "100%", float: "left", position: "relative"}}>
                  <RadioGroup
                    name="showTracks"
                    value={this.state.showTracks}
                    onChange={event => {
                       browserHistory.push({
                         pathname: window.location.pathname,
                         search:  ''
                       })
                      localStorage.setItem("showTracks", `"${event.target.value}"`)
                      this.setState({ showTracks: event.target.value })
                    }}
                    style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
                  >
                    <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('albums_table.show_all')} />
                    {(userIsSupervisor() || userIsSysAdmin() || userIsArtist() || userisMultiClient()) && <FormControlLabel value="pending_approve" control={<Radio color="default" />} label={localization.get('music.pending_approval')} /> }
                    <FormControlLabel value="no_albums" control={<Radio color="default" />} label={localization.get('form.show_tracks_without_albums')} />
                    <FormControlLabel value="no_aiff" control={<Radio color="default" />} label={localization.get('table.no_aiff')} />
                  </RadioGroup>
                </FormControl>

                {this.state.showTracks === 'all' &&
                  <MusicsTable ref="musicTable" {...this.props} />
                }
                {this.state.showTracks === 'pending_approve' &&
                  <MusicsTableWithPendingApproval {...this.props} />
                }
                {this.state.showTracks === 'no_albums' &&
                  <MusicsTable ref="musicTable" {...this.props} showNoAlbumTracks={true} />
                }
                {this.state.showTracks === 'no_aiff' &&
                <MusicsTable ref="musicTable" {...this.props} showNoAiffTracks />
                }

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    </div>
}

export default Musics;
