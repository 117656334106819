import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import {request} from "../../../../../api-client";
import {getMostImportantAlbum, makeUrlImageFromAlbums} from "../../../../../utils";
import {AddMediaSampleDialogContent} from "./AddMediaSampleDialogContent";
import GlobalConfirmationDialog from "../../../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import {
  userCanDeleteProjectMaterials,
  userCanUploadProjectMaterials,
  userisMultiClient,
  userIsSupervisor,
  userIsSysAdmin,
} from "../../../../../api-client/core/authentication/utils";
import localization from "../../../../../config/localization"

class LicensePage extends Component {
  state = {
    license: null,
    showAddProjectElementDialog: false,
    uploading: false,
  };

  componentDidMount = () => {
    this.fetchLicense().then(license => {
      if (userIsSupervisor() || userIsSysAdmin() || userisMultiClient())
        request.license.projectMaterialsViewed(license.project.id);
    });
  };

  fetchLicense = () =>
    new Promise((resolve, reject) =>
      request.license
        .get(this.props.match.params.licenseId)
        .then(license => this.setState({ license }, () => resolve(this.state.license)))
        .catch(reject)
    );

  requestElementUpload = () => {
    const projectId = this.state.license.project.id;
    const itemType = this.addProjectElementDialogContent.state.type;

    this.setState({ uploading: true });

    return request.license
      .uploadProjectElement(projectId, this.makeRequestElementUploadItem(), itemType)
      .then(() => this.fetchLicense())
      .finally(() => this.setState({ showAddProjectElementDialog: false, uploading: false }));
  };

  makeRequestElementUploadItem = () => {
    const { type, url, video, audio } = this.addProjectElementDialogContent.state;

    return type === "url" ? url : type === "video" ? video : audio;
  };

  renderAddProjectElementDialog = () =>
    this.state.license && (
      <Dialog open={this.state.showAddProjectElementDialog}>
        <DialogTitle>{localization.get('account.banner.material.add_comercial')}</DialogTitle>
        <DialogContent style={{ width: 500, maxWidth: "100%" }}>
          <AddMediaSampleDialogContent
            ref={r => (this.addProjectElementDialogContent = r)}
            disabled={this.state.uploading}
          />
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Button disabled={this.state.uploading} onClick={() => this.setState({ showAddProjectElementDialog: false })}>
            {localization.get('cancel')}
          </Button>
          <Button disabled={this.state.uploading} onClick={this.requestElementUpload}>
            {localization.get('accept')}
          </Button>
        </DialogActions>
      </Dialog>
    );

  render = () => {
    const { license } = this.state;

    if (!license) {
      return null;
    }

    const { music, project } = license;

    return (
      <div>
        {this.renderAddProjectElementDialog()}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "40px 0",
            backgroundColor: "black",
          }}
        >
          <img
            src={makeUrlImageFromAlbums(music.albums, 200)}
            style={{ margin: "0 20px", borderStyle: "solid", borderWidth: 1, borderColor: "white" }}
          />
          <div style={{ color: "white" }}>
            <span style={{ fontSize: 26 }}>{music.title}</span>
            <br />
            <span style={{ color: "#e0e0e0" }}>{getMostImportantAlbum(music.albums).title}</span>
          </div>
        </div>
        <div style={{ margin: "0 20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1 style={{ marginRight: 20, color: "black" }}>Comercial / Spot</h1>
            <div>
              <IconButton
                onClick={() => this.setState({ showAddProjectElementDialog: true })}
                disabled={!userCanUploadProjectMaterials()}
              >
                <Add />
              </IconButton>
            </div>
          </div>
          {project.urls.length === 0 && project.videos.length === 0 && project.audios.length === 0 && (
            <p style={{color: "black" }}>{localization.get('account.license.material.no_proyect')}</p>
          )}
          {project.urls.length > 0 && (
            <React.Fragment>
              <Paper style={{ padding: "0 15px" }}>
                <br />
                <Typography variant="title" style={{color: "black" }}>URLs</Typography>
                <br />
                {project.urls.map((x, i) => (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <a style={{ color: "black", textDecoration: "underline" }} href={x}>
                      {x}
                    </a>
                    <IconButton
                      onClick={() => this.handleDelete(project, i, "url")}
                      disabled={!userCanDeleteProjectMaterials()}
                    >
                      <Remove style={{color: "black" }}/>
                    </IconButton>
                  </div>
                ))}
                <div style={{ height: 20 }} />
              </Paper>
              <br />
              <br />
            </React.Fragment>
          )}
          {this.renderMediaGrid(
            "Videos",
            project.videos,
            "video",
            x => (
              <video style={{ maxWidth: 300 }} src={x.url} controls />
            ),
            project
          )}
          {this.renderMediaGrid(
            "Audios",
            project.audios,
            "audio",
            x => (
              <audio style={{ maxWidth: 300 }} src={x.url} controls />
            ),
            project
          )}
        </div>
      </div>
    );
  };

  renderMediaGrid = (title, medias, type, content, project) =>
    medias.length > 0 && (
      <React.Fragment>
        <Paper style={{ padding: "0 15px" }}>
          <br />
          <Typography variant="title">{title}</Typography>
          <br />
          <Grid spacing="32" container>
            {medias.map(x => (
              <Grid item>
                <Card>
                  <CardContent>{content(x)}</CardContent>
                  <CardActions>
                    <Button
                      onClick={() => this.handleDelete(project, x.id, type)}
                      disabled={!userCanDeleteProjectMaterials()}
                    >
                      Eliminar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
        <br />
        <br />
      </React.Fragment>
    );

  handleDelete = (project, id, type) =>
    GlobalConfirmationDialog.show({
      title: "Eliminar",
      content: (() => {
        switch (type) {
          case "url":
            return "Desea eliminar la url?";
          case "video":
            return "Desea eliminar el video?";
          case "audio":
            return "Desea eliminar el audio?";
        }
      })(),
      confirmationLabel: "Aceptar",
      request: () => request.license.deleteProjectElement(project.id, id, type).then(this.fetchLicense),
    });
}

export default LicensePage;
