import React, {Component} from 'react'
import PropTypes from 'prop-types'
import defaultWaveform from '../assets/img/no-waveform.jpg'
import {ScaleLoader} from 'react-spinners'

const Progress = ({ imageSrc, currentSeconds, totalSeconds, onManualChange, isLoading, hideSeconds = false }) => {

  const maxWidth = hideSeconds ? 300 : computeMaxWidthFromScreenWidth()
  const widthRelativeToCurrentAndTotalSeconds = currentSeconds * maxWidth / totalSeconds

  return <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px', color: 'var(--secondary-font-color, rgb(60, 72, 88))' }}>
    {!hideSeconds && <CurrentTime seconds={currentSeconds} />}
    <div style={{ width: maxWidth, height: '40px', maxWidth: "100%" }}>
      {showLoadingOrProgress(
        isLoading,
        makeLoading(),
        makeProgress(imageSrc, widthRelativeToCurrentAndTotalSeconds, maxWidth, onManualChange)
      )}
    </div>
    {!hideSeconds && <TotalTime seconds={totalSeconds} /> }
  </div>
}

Progress.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  currentSeconds: PropTypes.number.isRequired,
  totalSeconds: PropTypes.number.isRequired,
  onManualChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

const computeMaxWidthFromScreenWidth = () => window.innerWidth > 630 ? 500 : window.innerWidth * 0.6

const CurrentTime = ({ seconds }) =>
  <div style={{ marginRight: '20px', userSelect: 'none' }}>{secondsToMMSS(seconds)}</div>

const secondsToMMSS = totalSeconds => {
  const truncatedTotalSeconds = Math.floor(totalSeconds)
  const minutes = Math.floor(truncatedTotalSeconds / 60)
  const seconds = truncatedTotalSeconds - (minutes * 60)

  return prependZeroToOneDigitNumber(minutes) + ':' + prependZeroToOneDigitNumber(seconds)
}

const prependZeroToOneDigitNumber = number => number < 10 ? '0' + number : number.toString()

const showLoadingOrProgress = (isLoading, loading, progress) => isLoading ? loading : progress

const makeLoading = () => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ScaleLoader /></div>

const makeProgress = (imageSrc, currentWidth, maxWidth, onManualChange) => [
  <BackImage key={0} src={imageSrc || defaultWaveform} />,
  <FillableImage key={1} src={imageSrc || defaultWaveform} currentWidth={currentWidth} maxWidth={maxWidth} />,
  <Slider key={2} max={maxWidth} value={currentWidth} onChange={onManualChange} />
]

const TotalTime = ({ seconds }) =>
  <div style={{ marginLeft: '20px', userSelect: 'none' }}>{secondsToMMSS(seconds)}</div>

const BackImage = ({ src }) =>
  <img alt="..." style={{ width: 'inherit', height: 'inherit', opacity: '0.4', filter: 'brightness(90%)', maxWidth: "100%" }} src={src} onDragStart={preventDrag} />

const preventDrag = e => {
  e.preventDefault()
}

const FillableImage = ({ src, maxWidth, currentWidth }) =>
  <div style={{ position: 'relative', top: '-40px', width: currentWidth || 0, height: 'inherit', overflow: 'hidden' }}>
    <img alt="..." style={{ width: maxWidth, height: 'inherit', filter: 'brightness(60%)'}} src={src} onDragStart={preventDrag} />
  </div>

class Slider extends Component {

  inputStyle = {
    width: 'inherit',
    position: 'relative',
    bottom: '90px',
    height: '40px',
    WebkitAppearance: 'none',
    opacity: '0',
    maxWidth: "100%" 
  }

  constructor(props) {
    super(props)
    this.state = {
      changeTargetValue: props.value
    }
  }

  render = () => {

    const { max, value, onChange } = this.props
    const { changeTargetValue } = this.state

    return (
      <input style={this.inputStyle}
        type='range'
        min={0}
        max={max}
        value={value || 0}
        onChange={e => {
          this.setState({ changeTargetValue: e.target.value })
        }}
        onMouseUp={this.handlePressEnd(changeTargetValue, onChange, max)}
        onTouchEnd={this.handlePressEnd(changeTargetValue, onChange, max)}
      />
    )
  }

  handlePressEnd = (changeTargetValue, onChange, max) => () => {
    const normalizedValue = changeTargetValue / max
    onChange(normalizedValue)
  }
}

export default Progress