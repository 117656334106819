import {addPutMethodToForm, post} from '../utils'
import {makeFormData} from './utils'

export default host => ( id,
{
  code,
  discount,
  uses,
  quantity,
  expiresAt,
  active,
  description,
  products,
  includeAll,
}) => post(`${host}/code/${id}`, addPutMethodToForm(makeFormData({
  code,
  discount,
  uses,
  quantity,
  expiresAt,
  active,
  description,
  products,
  includeAll,
})))
  .then(response => response.json())
  .then(json => json.data)
